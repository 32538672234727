// @flow
import styled from "styled-components";

export const SvgIconContainer = styled.svg`
	height: ${({ height }) => height || "1em"};
	width: ${({ width }) => width || "1em"};
	font-size: ${({ size, unit }) => size ? `${size}${unit}` : "1em"};
	fill: ${({ color }) => color || "currentColor"};
	cursor: ${({ cursor }) => cursor || "inherit"};
`;

export default SvgIconContainer;