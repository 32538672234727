// @flow
import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import _ from "lodash";
import { compose } from "redux";
import type { TRootStore, TPLeague, TPlayersById } from "../../../modules/types";
import { below } from "../../../assets/css/media";
import colors from "../../../assets/css/colors";
import withCoachesBox from "../../../components/utils/withCoachesBox";

import * as actions from "../../../modules/actions";
import * as selectors from "../../../modules/selectors";

import JsonFetcher from "../../../components/utils/JsonFetcher";
import {
	Ad,
	AdsContainer,
	CountdownTimer,
	Footer,
	PageContentWrapper as PageContentWrapperUnstyled,
	PageTitle,
	TradeContent,
	TradeFromUser,
	TradeProposed,
	TradeRFA,
	TradeToUser,
	TwoColumnLayout,
	withDraftPlayerModal,
	MatchCentreBar,
	Tabs as TabsUnstyled,
	TabItem,
	WidgetSpacer,
	WidgetRfaOrder,
	WidgetNews,
	OfferToLeague,
	LeagueGameBar,
	ModalContainer,
	ModalInnerTitle,
} from "../../../components";
import Bell from "../../../components/Icons/Bell";
import TransactionHistory from "./transactions/transactionHistory";
import TradeTitleWrapper from "./transactions/tradeTitleWrapper";
import TradeTitleStyled from "./transactions/tradeTitleStyled";

const EmptyMessage = styled.p`
	font-family: SourceSansPro, sans-serif;
	font-size: 13px;
`;

const PageContentWrapper = styled(PageContentWrapperUnstyled)`
	${below.tablet`
		padding: 0;
	`};
`;

const Tabs = styled(TabsUnstyled)`
	li{
		height: 35px;
		padding: 0 10px;
	}
	margin-bottom: 20px;
	border-bottom: 1px solid #CAD2D8;
	${below.desktop`
		margin-left: 10px;
		margin-right: 10px;
	`}
`;



const Heading = styled.h3`
	font-family: TitilliumUpright;
	font-size: 24px;
	line-height: 1;
	margin-bottom: 0.25em;
	color: ${colors.primary.primary};
	font-weight: normal;

	${below.desktop`
		font-size: 18px;
		margin-left: 10px;
		margin-right: 10px;
		padding: 12px 0;
	`};
`;

// const Placeholder = styled.div`
// 	padding: 20px;
// 	background: #F8F8FA;
// 	min-height: 50px;
// 	margin-bottom: 20px;
// `;

type Props = {
	showDraftLeague: typeof actions.leagueDraft.showDraftLeague,
	fetchTrades: typeof actions.teamsDraft.fetchTrades,
	fetchRejectTrade: typeof actions.teamsDraft.fetchRejectTrade,
	fetchAcceptTrade: typeof actions.teamsDraft.fetchAcceptTrade,
	fetchBlockTrade: typeof actions.teamsDraft.fetchBlockTrade,
	fetchLadder: typeof actions.leagueDraft.ladderDraftLeague,
	fetchApproveTrade: typeof actions.teamsDraft.fetchApproveTrade,
	fetchWaiverRequests: typeof actions.leagueDraft.fetchWaiverRequests,
	fetchRemoveWaiverTrade: typeof actions.teamsDraft.fetchRemoveWaiverTrade,
	postOrderWaiverTrade: typeof actions.teamsDraft.postOrderWaiverTrade,
	fetchFavourites: typeof actions.fetchFavourites,
	fetchPlayersOnOfferList: typeof actions.teamsDraft.fetchPlayersOnOfferList,
	is_rfa_order_saving: boolean,
	ordered_player_ids: Array<number>,
	user_id: number,
	user: Object,
	error: string,
	clearError: typeof actions.teamsDraft.clearTeamErrors,
	match: {
		params: {
			league_id: string,
			history: string
		}
	},
	league: TPLeague,
	players_by_id: TPlayersById,
	trades: {
		completed: Array<Object>,
		my: Array<Object>,
		offer: Array<Object>,
		proposed: Array<Object>
	},
	waiver_requests: {
		by_id: Object,
		ordered_ids: Array<number>
	},
	waiver_requests_by_team_id: Object,
	players_on_offer: Array<Object>
}

type State = {
	rfa_order_widget_open: boolean,
	active_tab: string,
	interval?: IntervalID | null
};
const TradeTitleTimer = styled.div`
	display: flex;
	/* TODO: change back to space-between once Bell Icon is added back */
	justify-content: flex-end;
	align-items: center;
	width: 220px;
	.timer-unit {
		font-size: 20px;
	}
	.timer-label {
		font-size: 8px;
	}
`;
const ProposedSubtitle = styled.div`
	font-family: SourceSansPro;
	color: ${colors.secondary.accentGrey};
	font-size: 14px;
	margin-left: 10px;
    display: inline-block;
    font-weight: 300;
`;
type TitleProps = {
	is_rfa: boolean,
	unblock_at: any,
	onTimerComplete: Function,
	is_proposed?: boolean,
	vote?: "yes" | "no" | void,
	league:TPLeague,
	isAnyPlayerLocked: boolean,
	status:string
}

const approvalText = (approve_trade:string) => {
	if(approve_trade === "free"){
		return "There are no restrictions on this trade";
	}
	else {
		const approvalLabel = approve_trade === "league" ? "majority":approve_trade;
		return `This trade will go ahead pending ${approvalLabel} approval.`;
	}
};
const getSubTitle = (
	is_proposed?: boolean,
	vote?: "yes" | "no" | void,
	leagueApproval:string
) => is_proposed
	? <ProposedSubtitle>
		{vote === undefined
			? approvalText(leagueApproval)
			: `You have voted to ${vote === "yes" ? "approve" : "reject"} this trade.`}
	</ProposedSubtitle>
	: null;

const getIsAcceptedStatus = (status:string) => status === "accepted";
const getTitle = (is_rfa:boolean) => is_rfa ? "RFA request" : "Team to team trade";

const TradeTitle = ({ 
	is_rfa, 
	unblock_at, 
	onTimerComplete, 
	is_proposed, 
	isAnyPlayerLocked,
	vote, 
	league,
	status
}: TitleProps) => {
	const title = getTitle(is_rfa);
	const leagueApproval = _.get(league, "approve_trade", "league");
	const subtitle = getSubTitle(is_proposed, vote, leagueApproval);
	const isAccepted = (getIsAcceptedStatus(status) 
		&& !["league", "free"].includes(leagueApproval)) || isAnyPlayerLocked;
	
	return (
		<TradeTitleWrapper>
			<TradeTitleStyled>
				{title}
				{isAccepted ? " - Trade will be processed after round is complete.":subtitle}
			</TradeTitleStyled>
			{unblock_at && !isAccepted ? (
				
				<TradeTitleTimer>
					<CountdownTimer
						is_uppercase
						date={unblock_at}
						onComplete={onTimerComplete}
						remove_outer_padding
					/>
					{ /** TODO: put bell back in once notification are implemented
						* Also, change TradeTitleTimer flex justify back to space-between
					    */
						null && <Bell color={colors.primary.accent} /> }
				</TradeTitleTimer>
			) : null}

		</TradeTitleWrapper>
	);
};

class TransactionsComponent extends React.Component<Props,State> {

	constructor(props, state) {
		super(props, state);

		_.bindAll(this, [
			"onToggleWaiverOrderWidget",
			"getMyTrade",
			"getProposedTrade",
			"getWaiver",
			"rejectTrade",
			"acceptTrade",
			"blockTrade",
			"approveTrade",
			"removeWaiver",
			"onTimerComplete",
			"onChangeRequestsOrder",
			"updateRequestsOrder",
			"setActiveTab",
			"fetchData",
			"handleErrorModalClose"
		]);
	}
	state = {
		rfa_order_widget_open: false,
		active_tab: "active",
		interval: null
	};
	componentDidMount() {
		const {
			showDraftLeague,
			fetchTrades,
			fetchWaiverRequests,
			fetchLadder,
			match: { params: { league_id, history } },
			fetchFavourites
		} = this.props;

		showDraftLeague({ id: league_id });
		fetchTrades({ league_id });
		fetchWaiverRequests({ league_id });
		fetchLadder({league_id});
		fetchFavourites();
		if (history === "history") {
			this.setState({
				active_tab: "history"
			});
		}

		const interval = setInterval(this.fetchData, 10000);
		this.setState({ interval });
	}

	componentWillUnmount() {
		// $FlowFixMe
		clearInterval(this.state.interval);
	}

	fetchData() {
		const {
			fetchTrades,
			fetchWaiverRequests,
			fetchPlayersOnOfferList,
			match: { params: { league_id } },
		} = this.props;

		fetchTrades({ league_id });
		fetchWaiverRequests({ league_id });
		fetchPlayersOnOfferList({ league_id });
	}

	// Sections
	get transactions_section() {
		return (
			<React.Fragment>
				<Heading>
					Your Restricted Free Agent (RFA) requests:
				</Heading>
				{this.waivers}
				<Heading>
					Your trade offers
				</Heading>
				{this.trade_offers}
				<Heading>
					Pending trades
				</Heading>
				{this.trade_propose}
				<Heading>
					Players on offer
				</Heading>
				{this.players_on_offer}
			</React.Fragment>
		);
	}
	get history_section() {
		const {
			match: { params: { league_id } }
		} = this.props;

		return <TransactionHistory league_id={league_id} />;
	}
	// Blocks
	get trade_offers() {
		const { trades } = this.props;
		const { my } = trades;

		if (!_.size(my)) {
			return <TradeContent empty>
				<EmptyMessage>You currently don't have any trade offers available.</EmptyMessage>
			</TradeContent>;
		}

		return my.map(this.getMyTrade) || <TradeContent />;
	}

	get trade_propose() {
		const { trades } = this.props;
		const { proposed } = trades;

		if (!_.size(proposed)) {
			return <TradeContent empty>
				<EmptyMessage>
					Your league currently has no pending trades for you to review.
				</EmptyMessage>
			</TradeContent>;
		}

		return proposed.map(this.getProposedTrade) || <TradeContent />;
	}

	get players_on_offer() {
		const {
			players_on_offer,
			players_by_id,
			user_id
		} = this.props;
		if(!_.size(players_on_offer)) {
			return <TradeContent empty>
				<EmptyMessage>
					Your league currently has no players on offer for you to review.
				</EmptyMessage>
			</TradeContent>;
		}
		// users offers
		return (
			<React.Fragment>
				{players_on_offer.filter(o => o.old_user_id === user_id).map(offer => (
					<TradeContent key={offer.id}>
						<TradeTitleStyled>
							Player offer
						</TradeTitleStyled>
						<OfferToLeague
							offer={offer}
							players_by_id={players_by_id}
							users_offer
						/>
					</TradeContent>
				))}
				{players_on_offer.filter(o => o.old_user_id !== user_id).map(offer => (
					<TradeContent key={offer.id}>
						<TradeTitleStyled>
							Player offer
						</TradeTitleStyled>
						<OfferToLeague
							offer={offer}
							players_by_id={players_by_id}
						/>
					</TradeContent>
				))}
			</React.Fragment>
		);
	}

	get waivers() {
		const { waiver_requests, league, waiver_requests_by_team_id } = this.props;
		const { ordered_ids } = waiver_requests;
		const { rfa_privacy, team_id } = league;

		if (!_.size(ordered_ids)) {
			return <TradeContent empty>
				<EmptyMessage>
					You currently have no pending Restricted Free Agent requests.
				</EmptyMessage>
			</TradeContent>;
		}
		if (rfa_privacy === "private" && team_id) {
			const team_requests = _.get(waiver_requests_by_team_id, team_id, []);
			return team_requests.map(this.getWaiver) || <TradeContent />;
		}

		return _.map(
			waiver_requests_by_team_id,
			trades => trades.map(this.getWaiver)
		) || <TradeContent />;
	}

	isAnyPlayerLocked(trade) {
		const {players_by_id} = this.props;
		const {new_player_id, old_player_id} = trade;
		const playerInTradeArr = (new_player_id.length 
			&& old_player_id.length) ? [...new_player_id, ...old_player_id] : [];

		const lockedPlayers = playerInTradeArr.filter(playerId => {
			const player = players_by_id[playerId];
			if(player){
				return player.locked;
			}
			return false;
		});
		return lockedPlayers.length > 0;
	}

	getMyTrade(trade) {
		const { user_id, players_by_id, league } = this.props;
		const { id, author, unblock_at, status } = trade;
		const is_user_trade = user_id === author;
		if (_.isEmpty(players_by_id)) {
			return null;
		}
		const isAnyPlayerLocked = this.isAnyPlayerLocked(trade);
		return (
			<TradeContent key={id}>
				<TradeTitle
					is_rfa={false}
					unblock_at={unblock_at}
					onTimerComplete={this.onTimerComplete}
					is_proposed={trade.status === "accepted"}
					isAnyPlayerLocked={isAnyPlayerLocked}
					league={league}
					status={status}
				/>
				{ is_user_trade ? (
					<TradeFromUser
						rejectTrade={this.rejectTrade}
						trade={trade}
						players_by_id={players_by_id}
					/>
				) : (
					<TradeToUser
						acceptTrade={this.acceptTrade}
						rejectTrade={this.rejectTrade}
						trade={trade}
						players_by_id={players_by_id}
					/>
				) }
			</TradeContent>
		);
	}

	proposedTradeVote(trade)  {
		const { user_id } = this.props;

		const votes = _.get(trade, "votes", {});

		return _.findKey(votes, users => {
			return _.includes(users, user_id);
		});
	}

	getProposedTrade(trade) {
		const { players_by_id, league, user } = this.props;
		const { id, unblock_at, status } = trade;

		const vote = this.proposedTradeVote(trade);
		const isAnyPlayerLocked = this.isAnyPlayerLocked(trade);


		return (
			<TradeContent key={id}>
				<TradeTitle
					is_rfa={false}
					unblock_at={unblock_at}
					onTimerComplete={this.onTimerComplete}
					isAnyPlayerLocked={isAnyPlayerLocked}
					vote={vote}
					is_proposed
					league={league}
					status={status}
				/>
				<TradeProposed
					approveTrade={this.approveTrade}
					blockTrade={this.blockTrade}
					vote={vote}
					trade={trade}
					players_by_id={players_by_id}
					league={league}
					user={user}
				/>
			</TradeContent>
		);
	}

	getWaiver(trade, index) {
		const {
			waiver_requests: { ordered_ids }, players_by_id, is_rfa_order_saving, league
		} = this.props;
		const { team_id } = league;

		const { remain, status } = trade;
		const last = _.size(ordered_ids) - 1;
		const isAnyPlayerLocked = this.isAnyPlayerLocked(trade);

		return (
			<TradeContent key={trade.id} no_margin={index !== last}>
				{index === 0 ? (
					<TradeTitle
						is_rfa
						unblock_at={_.toString(new Date(new Date().getTime() + (remain * 1000)))}
						onTimerComplete={this.onTimerComplete}
						isAnyPlayerLocked={isAnyPlayerLocked}
						league={league}
						status={status}
					/>
				) : null}

				<TradeRFA
					is_rfa_order_saving={is_rfa_order_saving}
					removeWaiver={this.removeWaiver}
					trade={trade}
					is_user={parseInt(team_id, 10) === parseInt(trade.team_id, 10)}
					players_by_id={players_by_id}
					onChangeRequestsOrder={this.onChangeRequestsOrder}
					index={index}
					updateRequestsOrder={this.updateRequestsOrder}
					last={last}
				/>
			</TradeContent>
		);
	}

	//Handlers
	onToggleWaiverOrderWidget() {
		this.setState(state => ({
			rfa_order_widget_open: !state.rfa_order_widget_open
		}));
	}

	acceptTrade({ trade_id }) {
		const {
			fetchAcceptTrade,
			match: {
				params: { league_id },
			},
			trades
		} = this.props;
		fetchAcceptTrade({ trade_id, league_id, trades });
	}
	rejectTrade({ trade_id }) {
		const {
			fetchRejectTrade,
			match: {
				params: { league_id },
			},
			trades
		} = this.props;
		fetchRejectTrade({ trade_id, league_id, trades });
	}
	blockTrade({ trade_id }) {
		const {
			fetchBlockTrade,
			trades
		} = this.props;

		fetchBlockTrade({ trade_id, trades });
	}
	approveTrade({ trade_id }) {
		const {
			fetchApproveTrade,
			trades
		} = this.props;

		fetchApproveTrade({ trade_id, trades });
	}
	removeWaiver({ trade_id }) {
		const {
			fetchRemoveWaiverTrade
		} = this.props;

		fetchRemoveWaiverTrade({ id: trade_id });
	}
	onTimerComplete() {
		const { fetchTrades, match: { params: { league_id } } } = this.props;
		fetchTrades({ league_id });
	}

	onChangeRequestsOrder({ currentTarget }) {
		const { id } = currentTarget.dataset;
		const order = currentTarget.value;
		const new_order = parseInt(order, 10);
		if (!_.isFinite(new_order)) {
			return;
		}
		this.updateRequestsOrder({
			id: parseInt(id, 10),
			order: new_order
		});
	}
	updateRequestsOrder({ id, order }) {
		const { postOrderWaiverTrade } = this.props;
		postOrderWaiverTrade({ id, position: order });
	}
	setActiveTab({ currentTarget }) {
		const { tab } = currentTarget.dataset;
		const { active_tab } = this.state;
		if (active_tab !== tab) {
			this.setState({
				active_tab: tab
			});
		}
	}

	handleErrorModalClose() {
		const { clearError } = this.props;
		clearError();
	}



	get error_modal() {
		const { error } = this.props;

		return (
			<ModalContainer header_text="Error" onClick={this.handleErrorModalClose}>
				<ModalInnerTitle>
					{error}
				</ModalInnerTitle>
			</ModalContainer>
		);
	}

	render() {
		const { league, ordered_player_ids, error } = this.props;
		const { active_tab } = this.state;
		const is_history_tab = active_tab === "history";
		return (
			<React.Fragment>
				{error && this.error_modal}
				<JsonFetcher fetch={["rounds", "players", "squads"]} />
				<LeagueGameBar no_fetch={true} />
				<AdsContainer>
					<Ad />
				</AdsContainer>
				
				<PageContentWrapper>
					<PageTitle>
						Transactions for {league && league.name}
					</PageTitle>
					<TwoColumnLayout>
						<div>
							{ !_.isEmpty( ordered_player_ids) ?
								<Tabs>
									<TabItem
										onClick={this.setActiveTab}
										data-tab="active"
										className={!is_history_tab ? "active-tab" : ""}>
										Active transactions
									</TabItem>
									<TabItem
										onClick={this.setActiveTab}
										data-tab="history"
										className={is_history_tab ? "active-tab" : ""}>
										Transaction history
									</TabItem>
								</Tabs>  : null}
							{is_history_tab ? this.history_section : this.transactions_section}
						</div>
						<aside>
							<Ad type="mrec" />
							<WidgetSpacer />
							<WidgetRfaOrder />
							<WidgetSpacer />
							<WidgetNews />
						</aside>
					</TwoColumnLayout>
				</PageContentWrapper>
				<MatchCentreBar />
				<Footer show_key />
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: TRootStore, props) => ({
	league: selectors.leagueDraft.getLeague(state, props),
	user_id: state.user.data.id,
	trades: state.teamsDraft.trades,
	players_by_id: selectors.players.getExtendedPlayersById(state),
	waiver_requests: state.leagues.waiver_requests,
	waiver_requests_by_team_id: selectors.leagueDraft.getWaiverRequestsByTeamId(state),
	is_rfa_order_saving: state.teamsDraft.is_rfa_order_saving,
	ordered_player_ids: state.players.ordered_ids,
	players_on_offer: state.teamsDraft.offer_trades,
	error: state.teamsDraft.error
});

const mapDispatchToProps = {
	showDraftLeague: actions.leagueDraft.showDraftLeague,
	fetchTrades: actions.teamsDraft.fetchTrades,
	fetchRejectTrade: actions.teamsDraft.fetchRejectTrade,
	fetchAcceptTrade: actions.teamsDraft.fetchAcceptTrade,
	fetchBlockTrade: actions.teamsDraft.fetchBlockTrade,
	fetchApproveTrade: actions.teamsDraft.fetchApproveTrade,
	fetchWaiverRequests: actions.leagueDraft.fetchWaiverRequests,
	fetchRemoveWaiverTrade: actions.teamsDraft.fetchRemoveWaiverTrade,
	postOrderWaiverTrade: actions.teamsDraft.postOrderWaiverTrade,
	fetchFavourites: actions.fetchFavourites,
	fetchLadder: actions.leagueDraft.ladderDraftLeague,
	fetchPlayersOnOfferList: actions.teamsDraft.fetchPlayersOnOfferList,
	clearError: actions.teamsDraft.clearTeamErrors
};

export const Transactions = compose(
	withDraftPlayerModal,
	withCoachesBox,
	connect(
		mapStateToProps,
		mapDispatchToProps,
	)
)(TransactionsComponent);

export default Transactions;