/* eslint max-len: 0 */
// @flow
import React from "react";
import SvgIconContainer from "../SvgIconContainer";

export default (
	{
		unit = "em",
		...rest
	}: Object = {}) => (
	<SvgIconContainer viewBox="0 0 24 24" {...rest} unit={unit}>
		<title>Cells Condensed</title>
		<g><path d="M3 14h18v2h-18zM3 11h18v2h-18zM3 8h18v2h-18zM3 5h18v2h-18zM3 17h18v2h-18z" /></g>
	</SvgIconContainer>
);