// @flow
import styled from "styled-components";
import * as React from "react";
import * as _ from "lodash";
import { isMobile } from "../../utils";

import { below } from "../../assets/css/media";

const AdWrapper = styled.div`
	${({ type }) => type === "mrec" && below.desktop`
		display: none;
	`}
	span:last-child {
		display: none;
	}
	${({ type }) => type === "leaderboard" && below.desktop`
		span:last-child {
			display: block;
		}
		span:first--child {
			display: none;
		}
	`}
`;

const DesktopAd = styled.div`
	display: block;
	${below.tablet`
		display: none;
	`};
`;

const MobileAd = styled.div`
	display: none;
	${below.tablet`
		display: block;
	`};
`;

type Props = {
	id: string,
	area: Object,
	type: 'leaderboard' | 'mrec' | 'mobile'
}

const types = {
	leaderboard: "728x90, 300x50",
	mrec: "300x250",
	mobile: "320x50"
};

const DEFAULT_ID = "telstra-ad";

export class Ad extends React.PureComponent<Props> {
	static defaultProps = {
		type: "leaderboard",
		id: DEFAULT_ID,
		area: {
			classic: "Fantasy/Classic",
			draft: "Fantasy/Draft",
		},
	};

	constructor() {
		super();

		this.uniqueId = _.uniqueId();
	}

	componentDidMount() {
		const { area, type } = this.props;
		const { id } = this;
		const targeting = isMobile ? "mobile" : "desktop";
		const create_ads = [id];

		if(type === "leaderboard") {
			create_ads.push(`${id}-mobile`);
		}

		setTimeout(() => {
			window._telstra = window._telstra || {};
			window._telstra.after = window._telstra.after || [];
			window._telstra.after.push(lib => {
				lib.publisher("afl");
				lib.area = this.isClassic ? area.classic : area.draft;
				lib.targeting("device", targeting);
				lib.createAds(create_ads);
			});
		}, 2000);
	}
	
	uniqueId: string;

	get id() {
		const { id, type } = this.props;
		if (id === DEFAULT_ID) {
			return `${DEFAULT_ID}-${type}-${this.uniqueId}`;
		}
		return id;
	}

	get isClassic() {
		return window.location.pathname.substr(0, 8) === "/classic";
	}

	render() {
		const { type } = this.props;
		const { id } = this;
		return (
			<AdWrapper type={type} className="playwright-mask-hidden">
				<DesktopAd>
					<span className="tmframe" title={types[type]} id={id} />
				</DesktopAd>
				<MobileAd>
					<span className="tmframe" title={types.mobile} id={`${id}-mobile`} />
				</MobileAd>
			</AdWrapper>
		);
	}
}

export default Ad;