// @flow
import * as React from "react";
import styled, { css } from "styled-components";
import { connect } from "react-redux";
import _ from "lodash";
import * as selectors from "../../modules/selectors";
import type { TPosition, TSquad } from "../../modules/types";
import {
	FormCircleCheckbox,
	SearchField,
	SearchInputWrapper,
	SearchSubmitButton,
	PoolSelect
} from "../";
import Search from "../Icons/Search";
import above, { below } from "../../assets/css/media";
import colors from "../../assets/css/colors";


const ControlsWrapper = styled.div`
	display: flex;
	align-items: flex-end;
	background-color: ${colors.form.base};

	flex: 1;

	> div {
		flex: 3 1 0px;

		&:last-child {
			margin-left: 20px;
			flex: 2 1 0px;

			label > span.long_name {
				display: none;
			}

			label > span.short_name {
				display: inline;
			}
		}


		${below.desktop`
			display: flex;
		`}
	}

	${below.desktop`
		padding: 0;
	`}

	@media screen and (max-width: 600px) {
		flex-direction: column;
		padding-top: 0;
		margin-top: -10px;
		> div {
			width: 100%;
			margin: 10px 0 0 0;

			:last-child {
				margin-left: 0;
			}
		}
	}
`;

const FilterForm = styled.form`
	padding: 10px;
	box-sizing: border-box;
	background: #F8F8FA;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	${({ from_trades }) => from_trades 
		? css`
			padding: 10px 20px 20px;
			min-height: 190px;
			${below.desktop`
				padding: 10px;
				margin-bottom: 20px;
				min-height: 240px;
			`}

			${below.tablet`
				min-height: 320px;
			`}

			@media screen and (max-width: 600px) {
				min-height: 380px;
			}
		` : css`
			${SearchInputWrapper} {
				margin-bottom: 1em;
			}
		`}

	${above.desktop`
		${({ has_bye_detector }) => has_bye_detector && css`
			> div:last-child {
				display: none;
			}
		`}
	`}

	${below.desktop`
		${({ has_bye_detector }) => has_bye_detector && css`
			> div:last-child {
				
			}
		`}

		min-height: 420px;
	`}
`;
const FilterFormSectionWrapper = styled.div`
	${({ from_trades }) => from_trades && css`
		display: flex;
		justify-content: space-between;
		${FilterFormSection} {
			width: calc(50% - 5px);
			> div {
				width: calc(50% - 5px);
				flex: 0 0 auto;
			}
		}

		${below.tablet`
			margin-top: -10px;
			margin-left: -10px;
			flex-wrap: wrap;

			> div {
				min-width: 120px;
				margin: 10px 0 0 10px;
				flex: 1 0 0px;
			}

			${below.tablet`
				width: calc(100% + 10px);
				> div {
					flex: 0 0 calc(100% - 10px);
				}
			`};
		`}
	`}
`;
const FilterFormSection = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: ${({ from_trades }) => from_trades ? "5px" : "1em"};
	> div {
		flex-basis: 48%;
	}
`;
const FilterFormSectionCheckboxes = styled(FilterFormSection)`
	display: flex;
	justify-content: flex-start;
	> div {
		flex-basis: auto;
	}
`;
type Props = {
	positions: Array<TPosition>,
	squads: Array<TSquad>,
	onSubmit: Function,
	onChange: Function,
	has_assistant_coach: boolean,
	remaining_salary: number,
	player_pool_statistic: Array<Object>,
	by_position: string,
	from_trades?: boolean,
	sort_by: string,
	controls: React.Node,
	bye_detector_controls?: React.Node,
	handleSwitchFilterChange: Function
};
type State = {
	by_dpp: boolean,
	by_favourites: boolean,
};
export class PoolFilterContainer extends React.Component<Props, State> {
	constructor(props: Props, state: State) {
		super(props, state);

		_.bindAll(this, [
			"handleSwitchChange",
		]);
	}

	state = {
		by_dpp: false,
		by_favourites: false
	};

	//Handlers
	handleSwitchChange({ currentTarget }: Object) {
		const { name } = currentTarget;
		this.setState({ ...this.state, [name]: !this.state[name] });
		this.props.handleSwitchFilterChange(name, !this.state[name]);
	}
	renderControls() {
		const { player_pool_statistic, sort_by, controls } = this.props;
		const onSelectChange = e => e.preventDefault();

		return controls === null 
			? <PoolSelect
				name="sort_by"
				label="Statistic"
				description="Select a statistic to sort the player pool"
				onChange={onSelectChange}
				value={sort_by}
				show_on_left={true}
			>
				{player_pool_statistic.map(({ key, value }) => (
					<option value={key} key={key}>{value}</option>
				))}
			</PoolSelect>
			: controls;
	}
	render() {
		const {
			positions, squads, onSubmit, onChange, remaining_salary,
			by_position, from_trades, bye_detector_controls
		} = this.props;
		const { by_dpp, by_favourites } = this.state;
		const onSelectChange = e => e.preventDefault();

		return (
			<FilterForm 
				onSubmit={onSubmit} 
				onChange={onChange} 
				from_trades={from_trades}
				has_bye_detector={bye_detector_controls !== undefined}
			>
				<SearchInputWrapper>
					<SearchField
						type="text"
						name="search"
						component="input"
						placeholder="Search for a player"
					/>
					<SearchSubmitButton type="submit">
						<Search />
					</SearchSubmitButton>
				</SearchInputWrapper>
				<FilterFormSectionWrapper from_trades={from_trades}>
					<FilterFormSection from_trades={from_trades}>
						<PoolSelect
							onChange={onSelectChange}
							value={by_position}
							name="by_position"
							label="Position"
						>
							<option value="">All Positions</option>
							{positions.map(({ id, name }) => (
								<option value={id} key={id}>
									{name}
								</option>
							))}
						</PoolSelect>
						<PoolSelect name="by_squad" label="Clubs" onChange={onSelectChange}>
							<option value="">All Clubs</option>
							{squads.map(({ id, name }) => (
								<option value={id} key={id}>{name}</option>
							))}
						</PoolSelect>
					</FilterFormSection>
					<FilterFormSection from_trades={from_trades}>
						<PoolSelect name="by_cost" label="Price range" onChange={onSelectChange}>
							<option value="">All Prices</option>
							<option value={`0-${remaining_salary / 1000}-by_locked`}>
								Players I Can Afford
							</option>
							<option value="200-299">$200K - $299K</option>
							<option value="300-399">$300K - $399K</option>
							<option value="400-499">$400K - $499K</option>
							<option value="500-599">$500K - $599K</option>
							<option value="600-699">$600K - $699K</option>
							<option value="700-799">$700K - $799K</option>
							<option value="800-899">$800K - $899K</option>
							<option value="900-100000">$900K+</option>
						</PoolSelect>
						<PoolSelect name="by_status" label="Status" onChange={onSelectChange}>
							<option value="">All Statuses</option>
							<option value="playing">Selected to Play</option>
							<option value="emergency">Selected as Emergency</option>
							<option value="uncertain">Uncertain</option>
							<option value="not-playing">Not Selected to Play</option>
						</PoolSelect>
					</FilterFormSection>
				</FilterFormSectionWrapper>

				<FilterFormSectionCheckboxes>
					<FormCircleCheckbox
						id="dpp"
						name="by_dpp"
						checked={by_dpp}
						onChange={this.handleSwitchChange}
						isActive={by_dpp}
						label="DPP's only"
					/>
					<FormCircleCheckbox
						id="favourites"
						name="by_favourites"
						checked={by_favourites}
						isActive={by_favourites}
						onChange={this.handleSwitchChange}
						label="Favourites only"
					/>
				</FilterFormSectionCheckboxes>
				{
					bye_detector_controls
						? <ControlsWrapper>
							{ this.renderControls() }
							{ bye_detector_controls }
						</ControlsWrapper>
						: this.renderControls() 
				}
				
			</FilterForm>
		);
	}
}

const mapStateToProps = state => ({
	positions: selectors.positions.getPositionsArray(state),
	squads: selectors.squads.getSquads(state),
	remaining_salary: selectors.getMyClassicTeamRemainingSalary(state),
	has_assistant_coach: Boolean(selectors.getUser(state).assistant_coach),
	player_pool_statistic: state.teamsClassic.player_pool_statistic
});

export const PlayerPoolFilters = connect(
	mapStateToProps
)(PoolFilterContainer);
export default PlayerPoolFilters;