// @flow
import * as React from "react";
import styled from "styled-components";
import colors from "../../../assets/css/colors";
import Tick from "../../../components/Icons/Tick";
import { default_transition } from "../../../assets/css/vars";
import { below } from "../../../assets/css/media";

const CheckboxItem = styled.label`
	display: block;
	height: 16px;
	width: ${({ tnc }) => tnc ? "40px" : "16px"};
	margin-right: 1em;
	margin-left: ${({ pre_draft }) => pre_draft ? "16px" : ""};
	cursor: pointer;
	border: 1px solid ${colors.primary.primary};
	border-radius: 2px;
  ${({ tnc }) => tnc && `
   
  `}
  ${below.phone`
    width: ${({ tnc }) => tnc ? "75px" : "16px"};
    margin-right: ${({ tnc }) => tnc ? "10px" : "1em"};
  `}

	${default_transition}

	background-color: #FFFFFF;
	:hover {
		background-color: ${colors.form.base};
		border-width: 2px;
	}

	.checkmark {
		display: none;
		pointer-events: none;
		padding: 1px;
		box-sizing: border-box;
	}

	input {
		display: none;

		&:checked ~ .checkmark {
			display: block;
			background: ${colors.primary.primary};
			height: 100%;
			width: 100%;
		}
	}
  
`;

type Props = {
	name: string,
	checked?: boolean,
	value: any,
	onChange: Function,
	onBlur?: Function,
	onFocus?: Function,
	className?: string,
	pre_draft?: boolean,
  tnc?: boolean,
};

export const Checkbox = ({ name, checked, value, onChange, 
	onBlur, onFocus, pre_draft, tnc }: Props) => (
	<CheckboxItem checked={checked} pre_draft={pre_draft} tnc={tnc}>
		<input
			type='checkbox'
			name={name}
			checked={checked}
			value={value}
			onChange={onChange}
			onBlur={onBlur}
			onFocus={onFocus}
			id={name}
		/>
		<Tick className='checkmark' color='#ffffff' />
	</CheckboxItem>
);

Checkbox.defaultProps = {
	onFocus: () => {
	},
	onBlur: () => {
	},
	checked: false,
};

export default Checkbox;