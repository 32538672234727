// @flow
import * as _ from "lodash";
import { createSelector } from "reselect";
import { ROUNDS_IN_AFL_SEASON } from "../../../helpers";


const setRoundCostChanges = ({ round_id = 1, filed_name = "cost_round_diff" } = {}, player) => {
	const
		price = _.get(player, `stats.prices[${round_id}]`, player.cost),
		diff = (player.cost - price) || "-",
		new_stat = {};

	new_stat[filed_name] = diff;

	return {
		...player,
		stats: {
			...player.stats,
			...new_stat
		}
	};
};

const setSeasonCostChanges = player => (
	setRoundCostChanges({ filed_name: "cost_season_diff", round_id: 1 }, player)
);

const setCostChanges = (player, last_round_id) => {
	const getCostDiff = _.flow([
		setSeasonCostChanges,
		_.partial(setRoundCostChanges, { round_id: last_round_id, filed_name: "cost_round_diff" })
	]);

	return getCostDiff(player);
};
const getPositions = (player, positions_by_id) => ({
	...player,
	positions_name: player.positions.map(id => positions_by_id[id].short_name),
	positions_full_name: player.positions.map(id => positions_by_id[id].name),
});
const getStatsCostDividedByAveragePoints = player => {
	const { cost, stats } = player,
		{ avg_points } = stats;

	return parseInt(avg_points ? (cost / avg_points) : 0, 10);
};
const getOffSeasonPlayers = state => state.offSeasonPlayers.players;
const setStatsCostDividedByAveragePoints = player => ({
	...player,
	stats: {
		...player.stats,
		cost_divided_by_points: getStatsCostDividedByAveragePoints(player)
	}
});
export const offSeasonPlayersWithStats = createSelector(
	getOffSeasonPlayers,
	state => state.positions.by_id,
	(
		players,
		positions_by_id,
	) => {
		let cost_round = ROUNDS_IN_AFL_SEASON;
		const setPlayerCostChanges = _.partial(
			setCostChanges,
			_,
			cost_round
		);
		const setPlayerPositions = _.partial(
			getPositions,
			_,
			positions_by_id
		);
		return players.map(_.flow([
			setPlayerPositions,
			setPlayerCostChanges,
			setStatsCostDividedByAveragePoints
		]));
	}
);