// @flow
import _, { isNumber } from "lodash";
import { createSelector } from "reselect";
import leaguesDraftUtil from "../leaguesDraft";
import * as playersUtil from "../players";
import {
	getSquadSizeArray, getLadderRoundFinals, transactionPlayerName
} from "../../../helpers";
import * as roundsUtil from "../rounds";
import type { TRootStore, TPLeague, TDraftTeamsById } from "../../types";
import {
	createWinLossForm,
	createWinLossRecord,
	gamesPlayed,
	leagueStatsSelected,
} from "../../../helpers/team";


export const getCurrentLeague = ({ leagues }: Object) => {
	const { by_id, show: { league_id } } = leagues;
	return _.get(by_id, league_id, {});
};

export const getWaiversList = (
	state: TRootStore
) => _.get(state, "leagues.waiver_list.by_id", {});

export const getWaiversListIds = (
	state: TRootStore
) => _.get(state, "leagues.waiver_list.ordered_ids", {});

export const getWaiversFree = (
	state: TRootStore
) => _.get(state, "leagues.waiver_free.ordered_ids", []);

export const getTeamsById = (
	state: TRootStore
) => {
	const teams_by_id = _.get(state, "leagues.teams_by_id", {});
	const cleanUpKeepers = Object.keys(teams_by_id).map(teamID => {
		const team = teams_by_id[teamID];
		return {
			...team,
			keepers: typeof team.keepers === "string" ? 
				JSON.parse(team.keepers):team.keepers,
			keeper_list : typeof team.keeper_list === "string" ? 
				JSON.parse(team.keeper_list) : team.keeper_list
		};
	});
	return cleanUpKeepers.length ? _.keyBy(cleanUpKeepers, "id"):{};
};

export const getTeamsByIdWithPureLineups = createSelector(
	getTeamsById,
	teams_by_id => _.reduce(teams_by_id, (result, team) => {
		return {
			...result,
			[team.id]: {
				...team,
				lineup_ids: _.chain(team.lineup)
					.omit(["captain", "vice_captain", "emergency"])
					.values()
					.flatten()
					.value()
			}
		};
	}, {})
);

export const getDraftOrder = createSelector(
	leaguesDraftUtil.getLeague,
	leaguesDraftUtil.getLeagueDraftOrder,
	getTeamsById,
	(league: TPLeague, ordered_teams: Array<number>, teams_by_id: TDraftTeamsById) => {
		const is_league_empty = _.isEmpty(league);

		if(is_league_empty) {
			league = { size: 20	};
		}
		const is_teams_exist = !(_.isEmpty(ordered_teams) || _.isEmpty(teams_by_id));

		if(!is_teams_exist) {
			const { size } = league;
			ordered_teams = _.range(1, size + 1).fill(0);
		}

		let pick_counter = 0;
		const { draft_order } = league,
			max_squad_size = 30,
			teams_reversed = ordered_teams.slice().reverse(),
			total_positions = (
				is_league_empty ? max_squad_size : _.sum(getSquadSizeArray(league))
			),
			is_snake = _.eq(draft_order, "snake"),
			rounds_range = _.range(1, total_positions + 1),
			getTeamsArray = (is_snake, round_id) => {
				if(!is_snake) {
					return ordered_teams;
				}

				return round_id % 2 ? ordered_teams : teams_reversed;
			},
			createOrder = round_id => ([
				{ round_id, is_label: true },
				...(getTeamsArray(is_snake, round_id).map(team_id => ({
					...teams_by_id[team_id],
					pick: ++pick_counter,
					round_id,
				}))),
			]);

		return _.flatten(rounds_range.map(createOrder));
	}
);

export const getChunkedDraftOrder = createSelector(
	leaguesDraftUtil.getLeague,
	getDraftOrder,
	(league, order) => _.chunk(
		order.filter(_.negate(_.property("is_label"))),
		_.get(league, "size", 1000)
	)
);

export const orderedLadder = (state: Object): Array<Object> =>
	state.leagues.ladder.ordered_ids.map(id => state.leagues.teams_by_id[id]);

export const getSelectedRoundId = (state: Object, props: Object) => props.selected_round_id;

export const getLadderRound = createSelector(
	roundsUtil.getLastCompleteRound,
	getSelectedRoundId,
	leaguesDraftUtil.getLeague,
	(last_complete_round, selected_round_id, league) => {
		const last_complete_round_id = _.get(last_complete_round, "id", 0);
		const ladder_round_id = selected_round_id < last_complete_round_id
			? selected_round_id : last_complete_round_id;

		const finals = _.get(league, "finals", false);
		if (finals) {
			return getLadderRoundFinals(league, ladder_round_id);
		}

		return ladder_round_id;
	}
);

const getLeagueTeamScoreFor = (league_scoreflow, scoreflow, round_id) => {

	if(!league_scoreflow){
		return 0;
	}
	const startRound = Object.keys(league_scoreflow)[0];
	
	let score = 0;
	for(let r = Number(startRound); r <= round_id; r++ ){
		const addition = _.get(scoreflow, r, 0);
		score += addition;
	}

	return score;
};

export const orderedLadderCalculated = createSelector(
	leaguesDraftUtil.ladder.getOrdered,
	getLadderRound,
	(ladder, ladder_round_id) => {
		return ladder.filter(team => team !== undefined).map(team => {
			const points_for = getLeagueTeamScoreFor(
				team.league_scoreflow, team.scoreflow, ladder_round_id
			);
			const points_against = leagueStatsSelected(team.league_against, ladder_round_id);
			const isPercentageAvailable = isNumber(points_for) && isNumber(points_against);
			const percentage_selected_round =  isPercentageAvailable 
				? ((points_for / points_against )* 100).toFixed(2) : "-";
			return {
				...team,
				games_played: gamesPlayed(team, ladder_round_id),
				...createWinLossRecord(team, ladder_round_id),
				form: createWinLossForm(team, ladder_round_id),
				league_points_selected_round:
					leagueStatsSelected(team.league_scoreflow, ladder_round_id),
				points_for_selected_round: 
					getLeagueTeamScoreFor(team.league_scoreflow, team.scoreflow, ladder_round_id),
				points_against_selected_round:
					leagueStatsSelected(team.league_against, ladder_round_id),
				points_percent_selected_round:
					percentage_selected_round,
				rank_selected_round: team.rank_history ? _.get(team.rank_history, ladder_round_id) :0
			};
		}).sort((teamA, teamB) => teamA.rank_selected_round > teamB.rank_selected_round ? 1 : -1);
	});

const transactionHistory = state => state.leagues.transaction_history.result;

export const getTransactionPlayerNames = createSelector(
	transactionHistory,
	playersUtil.getPlayerById,
	(transaction, player) => {
		if (_.isEmpty(player)) {
			return null;
		}
		return transaction ? transaction.map(trans => ({
			...trans,
			player_name: transactionPlayerName(trans, player),
		})) : null;
	}
);

export const getGroupedTransactions = createSelector(
	getTransactionPlayerNames,
	transaction => {
		return _(transaction)
			.groupBy(x => x.date_iso)
			.map((value, key) => ({ value }))
			.value();
	}
);

export const getNumberOfNotifications = createSelector(
	state => state.teamsDraft.trades,
	state => state.leagues.waiver_requests.ordered_ids,
	state => state.user.data.id,
	state => state.leagues.show.league_id,
	state => state.leagues.by_id,
	(trades = {}, waiver_requests = [], user_id, leagueID, leaguesById) => {
		const league = _.get(leaguesById, leagueID);
		const commissioner = _.get(league, 'commissioner', 0);
		const trade_approval = _.get(league, 'approve_trade');
		const my_trades = _.get(trades, "my", []);

		const trade_alerts = my_trades.filter(trade =>{
			return (trade.status !== "accepted" && trade.author !== user_id);
		} );
		const trades_proposed  = _.get(trades, "proposed", []);
		const not_voted_proposed = trades_proposed.filter(trade => {
			const tradeVotesNo = _.get(trade, "votes.no", []);
			const tradeVotesYes = _.get(trade, "votes.yes", []);
			const dontShowNotifications = commissioner !== user_id 
				&& trade_approval === "commissioner"; 
			return (![...tradeVotesNo, ...tradeVotesYes].includes(user_id) 
				&& !dontShowNotifications);
		});
		const alert_count = _.size(trade_alerts) + _.size(not_voted_proposed) ;
		return alert_count;
	}
);