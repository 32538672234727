// @flow
import React from "react";
import styled from "styled-components";

import { Group } from "@vx/group";
import { GlyphDot } from "@vx/glyph";
import { Line } from "@vx/shape";
import { Point } from "@vx/point";
import { scaleLinear, scaleBand } from "@vx/scale";
import { AxisLeft, AxisBottom } from "@vx/axis";
import { GridColumns } from "@vx/grid";
import { withTooltip, TooltipWithBounds } from "@vx/tooltip";
import { localPoint } from "@vx/event";
import type { TRound } from "../../modules/types";

import colors from "../../assets/css/colors";
import { isMobile } from "../../utils";


const GraphWrapper = styled.div`
	position: relative;
	overflow: auto;
	width: 100%;
    .vx-tooltip-portal{
        padding: 0 !important;
        background-color: transparent !important;
        width: 128px;
    }
	div.vx-tooltip-portal:before{
		content:'';
		display: block;
		width: 0;
		height: 0;
		position: absolute;

		border: 4px solid transparent;
		border-bottom: 4px solid #CAD2D8;
		${({ right_align }) => right_align ? "left" : "right"}: 57px;
		top: -8px;
	}
`;

const GraphSVG = styled.svg`
    background: #F0F3F7;
    border: 1px solid #CAD2D8;
    
	.vx-axis.vx-axis-left {
		.vx-axis-tick {
			text {
				font-family: inherit;
				font-weight: 500;
				fill: #89969F;
			}
		}
	}
    .glyph-hover{
        position: relative;
        cursor: pointer;
    }
    .glyph-hover:hover{
        .hover-section{
            display: flex !important;
            position: absolute;
            top: -10px;
        }
    }
`;

const RoundText = styled.text`
	font-size: 10px;
	font-weight: 500;
	fill: ${colors.secondary.paleGrey};
`;

const RoundDataHover = styled.div`
    flex-direction: column;
    justify-content: center;
    width: 130px;
    height: 28px;
    background: #CAD2D8;
    border-radius: 4px;
    padding: 6px 3px;
    display: flex;
`;

const HoverRow = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    font-family: TitilliumUpright;
    font-size: 12px;
    font-weight: bold;
    color: ${colors.primary.primary};
    p{
        font-weight: 400;
    }
`;


// accessors
const id = d => d.id;
const round = d => d;
const value = d => d.points;

type Props = {
	parentWidth: number,
	parentHeight: number,
	rounds: Array<TRound>,
	data: Array<Object>,
	show_data: boolean,
    showTooltip: Function,
    hideTooltip: Function,
    tooltipTop: number,
    tooltipLeft: number,
    tooltipOpen: boolean,
    tooltipData: Object,
    team_avatar: React.Component<void, {}, void>
}

const RoundByRound = ({
	parentWidth, 
	parentHeight, 
	rounds, 
	data, 
	show_data, 
	showTooltip, 
	tooltipTop, 
	tooltipLeft, 
	hideTooltip, 
	tooltipOpen, 
	tooltipData,
	team_avatar
}: Props) => {

	
	const joint_teams_data = data.map(round => round.points);
	// scales
	const xScale = scaleBand({
		domain: rounds.map(id)
	});

	const yScale = scaleLinear({
		domain: [0, Math.max(...joint_teams_data)]
	});



	// positions
	const x = d => xScale(round(d));
	const y = d => yScale(value(d));

	const is_small = parentWidth < 700;

	const margin = {
		top: 80,
		right: 60,
		bottom: is_small ? 140 : 120,
		left: is_small ? 50 : 100
	};
	const width = parentWidth - margin.left - margin.right;
	const height = parentHeight - margin.top - margin.bottom;

	// bounds
	const xMax = width;
	const yMax = height;

	// update scale range to match bounds
	xScale.range([20, xMax + 40]);
	yScale.range([yMax, 0]);

	return (
		<GraphWrapper>
			{team_avatar}
			<GraphSVG
				width={isMobile() ? width : "100%"}
				height={height + margin.bottom}
			>
				<Group left={margin.left} top={margin.top}>
					<AxisLeft
						scale={yScale}
						hideTicks
						hideZero
						hideAxisLine
						numTicks={6}
						left={-(margin.left - 60)}
					/>
					<AxisBottom scale={xScale} top={23}>
						{axixData => (
							<React.Fragment>


								{axixData.ticks.map(tick => {
									const round_id = tick.value;
									return (
										<React.Fragment key={round_id}>
										
											<RoundText
												y={yMax + 10}
												x={xScale(round_id)}
												style={{
													transform: `translateX(-15px)
													translateY(${is_small ? "16px" : "0px"})`
												}}
											>
										    {isMobile() ? "R" : "Round"} {round_id}
											</RoundText>
										
										</React.Fragment>
									);
								})}
							</React.Fragment>
						)}
					</AxisBottom>
					<GridColumns
						className="column-grid"
						style={{ opacity: 0.2 }}
						scale={xScale}
						width={xMax}
						height={yMax}
						stroke="#D8D8D8"
						strokeWidth={2}
					/>

					{/* Render Lines */}
					{show_data && data.map((round, index) => {
					

						const current_x = x(index + 1);
						const current_y = y(round);


						const previous = data[index - 1];

						if (!previous) {
							return null;
						}

						const previous_x = x(index);
						const previous_y = y(previous);

						const current_point = new Point({ x: current_x, y: current_y });
						const previous_point = new Point({ x: previous_x, y: previous_y });

						return (
							<g key={`line-${round.id}`}>
								<Line
									stroke={colors.primary.primary}
									strokeWidth={1}
									strokeDasharray={ "" }
									opacity={1}
									from={previous_point}
									to={current_point}
								/>
							</g>
						);
					})}

					{/* Render Points */}
					{show_data && data.map((round, index) => {

						const cx = x(index + 1);
						const cy = y(round);

						// const current_is_projected = d.is_projected;
						return (
							<g key={`line-point-actual-${round.points}`} 
								className="glyph-hover" onMouseOver={event => {
									const coords = localPoint(event.target.ownerSVGElement, event);
									showTooltip({
										tooltipData: round,
										tooltipTop: coords.y + 10,
										tooltipLeft: coords.x,
									});
								} }
								onMouseOut={hideTooltip}>
								<GlyphDot
									cx={cx}
									cy={cy}
									r={6}
									fill={colors.primary.primary}
									stroke={colors.primary.light}
									strokeWidth={2}
									opacity={1}
									onMouseOver={event => {
										const coords = 
                                            localPoint(event.target.ownerSVGElement, event);
										showTooltip({
											tooltipData: round,
											tooltipTop: coords.y,
											tooltipLeft: coords.x + 40,
										});
									} }
									onMouseOut={hideTooltip}
								/>
							
							}
							
							
							</g>
						);
					})}

				</Group>
				
			</GraphSVG>
			{tooltipOpen && 
                <TooltipWithBounds key={Math.random()} top={tooltipTop} left={tooltipLeft - 75}>
                	<RoundDataHover>
                		<HoverRow>
                        Round Score 
                			<p>{tooltipData.points}</p>  
                		</HoverRow>
                	</RoundDataHover>
                </TooltipWithBounds>
			}
		</GraphWrapper>
		
	);
};

export default withTooltip(RoundByRound);