// @flow
import React from "react";
import styled from "styled-components";
import { NavLink, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { get, isEmpty } from "lodash";
import { below, above } from "../../assets/css/media";
import * as actions from "../../modules/actions";

import LeagueAvatar from "../Leagues/LeagueAvatar";

import { Exist } from "../Exist";

const SwitchLeaguesContainer = styled.div`
	text-align: right;
	${below.tablet`
		padding-left: 20px;
	`}

	${above.tablet`
		padding: 25px 0;
		> div {
			max-height: calc(100vh - 400px);
			overflow: auto;
			overflow-x: hidden;
			overflow-y: auto;
		}
	`};

`;

const LeagueItem = styled(NavLink)`
	text-align: right;
	display: block;
	padding: 0 50px 0 0;
	margin: 0 0 15px;
	position: relative;
	height: 40px;
	line-height: 40px;
	font-size: 14px;
	color: #2E3134;
	text-decoration: none;
	&.active {
		font-weight: 700;
	}
	&:hover {
		text-decoration: underline;
	}

	${below.tablet`
		text-align: left;
		padding: 0 0 0 50px;

		&:first-child {
			margin-top: 0;
		}
	`};
`;

const LeagueAndTeamName = styled.p`
	font-size: 14px;
	line-height: 1.3;
`;

const AvatarWrapper = styled.div`
	display: block;
	position: absolute;
	height: 40px;
	width: 40px;
	top: 0;
	right: 0;
	border-radius: 50%;

	${below.tablet`
		right: auto;
		left: 0;
	`};
`;

const NoLeaguesMessage = styled.p`
	color: #2E3134;
	font-size: 14px;
	line-height: 1.7;
	text-align: right;
	margin: 15px 0;
	position: relative;
	height: 40px;
	line-height: 40px;
	${below.tablet`
		text-align: left;
	`};
`;

type Props = {
	fetchMyDraftLeagues: typeof actions.leagueDraft.showMyDraftLeagues,
	fetchMyClassicLeagues: typeof actions.showMyClassicLeagues,
	fetchMyDraftTeams: typeof actions.teamsDraft.fetchMyTeams,
	my_draft_leagues: Array<Object>,
	my_classic_leagues: Array<Object>,
	my_draft_teams: Array<Object>
};


class SwitchLeagues extends React.PureComponent<Props> {
	componentDidMount() {
		const {
			fetchMyDraftTeams } = this.props;
		
		this.fetchLeagues();
		if(!this.isDraftMatchupPage && this.isDraft && !isEmpty(this.props.my_draft_teams) ){
			fetchMyDraftTeams();
		}
	
	}

	get isDraft() {
		return window.location.pathname.substr(0, 6) === "/draft";
	}

	get isDraftMatchupPage(){
		return window.location.pathname.includes("draft") && window.location.pathname.includes("fantasy-matchups");
	}

	get isClassic() {
		return window.location.pathname.substr(0, 8) === "/classic";
	}

	fetchLeagues(){
		const {
			fetchMyDraftLeagues,
			fetchMyClassicLeagues,
			my_draft_leagues,
			my_classic_leagues
		} = this.props;
		const draftLeaguesExist = my_draft_leagues.length >1;
		const classicLeaguesExist = my_classic_leagues.length > 1;
		if(this.isDraft && !draftLeaguesExist){
			fetchMyDraftLeagues();
		}
		if(this.isClassic && !classicLeaguesExist){
			fetchMyClassicLeagues();
		}

	}
	render() {
		const { my_draft_leagues, my_classic_leagues } = this.props;
		const leagues = this.isDraft ? my_draft_leagues : my_classic_leagues;
		const link_start = `/${this.isDraft?"draft":"classic"}/league/`;
		const loadedLeagues = leagues
			.filter(item => {
				const leagueID = get(item,"id",0);
				return item !== undefined 
				&& leagueID > 0;
			});
		return (
			<SwitchLeaguesContainer>
				<div>
					<Exist when={loadedLeagues.length === 0}>
						<NoLeaguesMessage>
							You are not in any leagues!
						</NoLeaguesMessage>
					</Exist>
					{loadedLeagues.map(league => {
						if(!league.id) {
							return null;
						}
						const leagueName = get(league,"name","");
						const leagueFirstName = get(league,"firstname","");
						const leagueLastName = get(league,"lastname","");
						let secondary_text = leagueFirstName;
						if(leagueLastName.length > 0) {
							secondary_text += `.${leagueLastName.charAt(0)}`;
						}

						return (
							<LeagueItem to={`${link_start}${league.id}/`} key={league.id}>
								<LeagueAndTeamName>
									{leagueName}<br />
									{secondary_text}
								</LeagueAndTeamName>
								<AvatarWrapper>
									<LeagueAvatar
										league={league}
										size="40px"
										is_classic={this.isClassic}
									/>
								</AvatarWrapper>

							</LeagueItem>
						);
					}
					)}
				</div>
			</SwitchLeaguesContainer>
		);
	}
}
const mapStateToProps = state => ({
	my_draft_leagues: state.leagues.show_my.ordered_ids.map(id => state.leagues.by_id[id]),
	my_draft_teams: state.teamsDraft.user_all_teams,
	my_classic_leagues: state.leaguesClassic.show_my.ordered_ids.map(id =>
		state.leaguesClassic.by_id[id])
});

const mapDispatchToProps = {
	fetchMyDraftLeagues: actions.leagueDraft.showMyDraftLeagues,
	fetchMyClassicLeagues: actions.showMyClassicLeagues,
	fetchMyDraftTeams: actions.teamsDraft.fetchMyTeams
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SwitchLeagues));