// @flow
import React from "react";
import * as _ from "lodash";
import {
	Exist,
	PlayerActionButton,
	UndoPlayerActionButton
} from "../../components";

import {TPlayer} from "../../modules/types";
import { isMobile } from "../../utils";
import colors from "../../assets/css/colors";
import AddPlayer from "../Icons/AddPlayer";
import Trash from "../Icons/Trash";
import Close from "../Icons/Close";
import More from "../Icons/More";
import Stop from "../Icons/Stop";
import Trade from "../Icons/Trade";
import Undo from "../Icons/Undo";

type Props = {
    player: TPlayer,
    compressed_view: boolean, 
    buttons_open: number[],
    current_user_id: number,
    handleRemoveButton: Function,
    handleButtons: Function,
    addInTrade: Function,
    addOutTrade: Function,
	isDisabled?:boolean,
	removeInPlayer:Function,
	fa?:Object
}


export const PlayerRowButtons = (props: Props) => {
	
	const {
		player,
		compressed_view, 
		buttons_open,
		current_user_id,
		handleRemoveButton,
		handleButtons,
		addInTrade,
		addOutTrade,
		isDisabled,
		fa,
		removeInPlayer
	} = props;
	const { 
		id, 
		owner, 
		waiver 
	} = player;

	const is_user_team = (_.get(owner, "user_id", 0)  === current_user_id);

	const data_for_trade = {
		id,
		is_user_team,
		owner,
		waiver,
		is_fa: false
	};

	// AFD21-442 remove restriction on selecting player
	// const is_trade_locked = !this.checkIsManagementAvailable(id);
	const is_trade_locked = false;
	const is_mobile = isMobile();

	const closeButton = () => {
		return(
			<PlayerActionButton onClick={_.partial(handleRemoveButton, id)}>
				<Close />
			</PlayerActionButton>
		);
	};

	if(isMobile() && !buttons_open.includes(id)){
		return(
			<PlayerActionButton onClick={_.partial(handleButtons, id)} disabled={isDisabled}>
				<More />
			</PlayerActionButton>
		);
	}
		
	if (is_trade_locked) {
		return (
			<div className="buttons-open-mob">
					
				<PlayerActionButton disabled="disabled" compressed_view={compressed_view}>
					<Stop color={colors.secondary.paleGrey} />
				</PlayerActionButton>
				<Exist when={is_mobile}>
					{closeButton()}
				</Exist>
			</div>
				
		);
	}

	if (is_user_team) {
		return (
			<div className="two-buttons-open-mob">
				<PlayerActionButton
					is_shadow
					onClick={() => addOutTrade(data_for_trade)}
					compressed_view={compressed_view}
				>
					<Trade color={colors.primary.primary} />
				</PlayerActionButton>
				<Exist when={!is_trade_locked}>
					<PlayerActionButton
						onClick={() => addOutTrade({
							...data_for_trade,
							is_fa: true
						})}
						color={colors.warning}
						border={colors.warning}
						compressed_view={compressed_view}
					>
						<Trash color={colors.buttons.cancel} />
					</PlayerActionButton>
				</Exist>
				<Exist when={is_mobile}>
					{closeButton()}
				</Exist>
			</div>
		);
	}

	if (owner) {
		return (
			<div className="buttons-open-mob">
				<PlayerActionButton
					is_shadow
					onClick={() => addInTrade(data_for_trade)}
					compressed_view={compressed_view}
					disabled={isDisabled}
				>
					<Trade />
				</PlayerActionButton>
				<Exist when={is_mobile}>
					{closeButton()}
				</Exist>

			</div>
		);
	}


	return (
		<div className="buttons-open-mob">
			{id === _.get(fa,'new_player_id',"")? 
				<UndoPlayerActionButton
					className="remove-in-player"
					onClick={()=>removeInPlayer(data_for_trade) }
					compressed_view={compressed_view}
					disabled={isDisabled}>
					<Undo />
				</UndoPlayerActionButton>
				: 
				<PlayerActionButton
					className="add-in-trade"
					background={colors.secondary.lightGrey}
					onClick={() =>  addInTrade(data_for_trade)}
					compressed_view={compressed_view}
					disabled={isDisabled}
				>
					<AddPlayer />
				</PlayerActionButton>
			}
			

			<Exist when={is_mobile}>
				{closeButton()}
			</Exist>
		</div>
	);
};