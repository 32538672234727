// @flow
import styled from "styled-components";
import { below } from "../../assets/css/media";

export const DraftTitleContainer = styled.div`
	padding-top: 30px;
	width: auto !important;
	max-width: calc(78% - 3vw);

	${below.desktop`
		padding: 30px 15px 0;
	`};
`;

export default DraftTitleContainer;