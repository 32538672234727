//@flow
const deluxeArr = [
	"Advanced Custom Scoring",
	"Proj. Season Averages",
];

const liteArr = [
	"Customisable Emergency Settings",
	"Customisable Draft Order",
	"Disable Position Changes",
	"Edit Teams (Commissioner Tool)",
	"Edit Match Results",
	"Custom Finals System",
	"Edit Scores (Free trial until Round 6)"
];

export const subscriptionFeatures = (activeTab: string) => {
	if(activeTab === "full"){
		return [...deluxeArr, ...liteArr];
	}
	return liteArr;
};