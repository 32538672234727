/* eslint max-len: 0 */
// @flow
import React from "react";
import SvgIconContainer from "../SvgIconContainer";

export default (
	{
		unit = "em",
		direction = "left",
		...rest
	}: Object = {}) => {

	const paths = {
		right: "M16.656 12l-6.71 4.602v-9.203z",
		left: "M7.344 12l6.71-4.602v9.203z",
		down: "M7 10l5 5 5-5z",
		up: "M7 14l5-5 5 5z"
	};

	return (
		<SvgIconContainer viewBox="0 0 24 24" {...rest} unit={unit}>
			<title>Arrow {direction}</title>
			<path d={paths[direction]} />
		</SvgIconContainer>
	);
};