// @flow
export const shouldHideTrash = (
	is_sub: boolean, 
	is_locked: boolean, 
	is_team_started: boolean, 
	is_first_round_partial: boolean,
	is_draft: boolean = false
) => {

	/*
		removed in AFD21-78 to allow for editing locked players.
		const  is_sub_or_locked = is_sub || is_locked;
	*/
	const is_sub_or_locked = is_sub;

	if (is_draft) {
		return is_sub_or_locked;
	}

	return is_first_round_partial ? is_sub_or_locked : is_sub_or_locked || is_team_started;
};