// @flow
import * as React from "react";
import * as _ from "lodash";
import styled from "styled-components";
import * as moment from "moment";
// import UFLogo from "../../assets/images/uf-logo.svg";
import colors from "../../assets/css/colors";
import { 
	getDefaultDraftLeagueRouteButtonText, 
	getDefaultDraftLeagueRoute, 
	getShortName 
} from "../../helpers/league";
import type { TPLeague, TRound, TUser } from "../../modules/types";
import LeagueLateLabel from "../../utils/leagueLate";

import { 
	ButtonAlertLink, ButtonPrimaryLink, LeagueAvatar, PrimaryButtonStyles 
} from "../../components";
import { IS_DISABLE_LIVE_DRAFT, WRAPPED_VIDEO_LINK } from "../../modules/constants";
// import { isAllTrue } from "../../helpers";
import BoxLeague, { 
	BoxLeagueNameText, 
	BoxLeagueNameSubText, 
	BoxInner as BoxInnerUnstyled 
} from "./boxLeague";
import { FavouriteButton, FavouriteStarIcon } from "./favouriteButton";

const getWrappedVideoUrl = code => {
	return `${WRAPPED_VIDEO_LINK}${code}`;
};

const ButtonWrapper = styled.div`
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: space-between;
`;

const HalfSizeButton = styled.a`
	${PrimaryButtonStyles}
	width: 49%;
	text-decoration: none;
	${({isRecap}) => isRecap && `	
		background-color: #0BFAFF;
		color: ${colors.primary.primary};
		border: none;
		&:hover:not(:disabled) {
			background-color: #b1feff;
			color: ${colors.primary.primary};
		}
	`}
`;

const HalfSizeLink = styled(ButtonPrimaryLink)`
	width: 49%;
	background-color: ${({ live_draft }) => live_draft ? "#7ED321;" : ""};
	border: ${({ live_draft }) => live_draft ? "none;" : ""};
`;

const BoxInner = styled(BoxInnerUnstyled)`
	display: flex;
	flex-direction: column;
	align-items: center;
	> a, > button {
		width: 100%;
    	box-sizing: border-box;
	}
	.uf-logo{
		width: 100px;
		height: 40px;
		margin-bottom: 15px;
	}
`;

const RegenInnerBoxSubText = styled(BoxLeagueNameSubText)`
	margin-bottom: 10px;
`;

const LeagueStatusText = styled.div`
	color: ${colors.primary.accentGreen};
	font-size: 14px;
	font-weight: 500;
	text-align: center;
`;

const LeagueAvatarStyled = styled(LeagueAvatar)`
	width: 60px;
	height: 60px;
	margin: -50px auto 10px;
`;
const StyledButtonPrimaryLink = styled(ButtonPrimaryLink)`
	background-color: ${({ live_draft }) => live_draft ? "#7ED321;" : ""};
	border: ${({ live_draft }) => live_draft ? "none;" : ""};
`;

const RegenerateButton = styled(ButtonPrimaryLink)`
	background: transparent;
	color: #1D4073;
	border-color: #1D4073;
`;

// const RegenerateLabel = styled.div`
// 	font-size: 15px;
// 	font-weight: 500;
// 	text-align: center;
// 	line-height: 1.2;
// 	color: #7F8A90;
// 	margin-bottom: 20px;
// `;

type Props = {
	league: TPLeague,
	is_draft: boolean,
	game_selection_favourite: Object,
	user: TUser,
	clearFavourite: Function,
	setFavourite: Function,
	actual_round: TRound,
	is_regenerate: boolean,
	hide_regenerate_button: boolean,
}

export class SelectLeague extends React.PureComponent<Props> {
	
	get favouriteFunction() {
		const { clearFavourite, setFavourite } = this.props;
		
		return this.is_favourite ? clearFavourite : () => setFavourite(this.favourite_object);
	}

	get favourite_object() {
		const { is_draft, league } = this.props;		
		return {
			game: is_draft ? "draft" : "classic", 
			type: "league", 
			id: league.id
		};
	}

	get is_favourite() {
		const { game_selection_favourite } = this.props;
		const favourite_object = this.favourite_object;

		return _.isEqual(favourite_object, game_selection_favourite);
	}

	get hide_live_draft() {
		const { user, league, is_draft } = this.props;
		return is_draft 
			&& league.draft_type === "commissioner" 
			&& league.draft_status === "active" 
			&& !IS_DISABLE_LIVE_DRAFT
			&& league.commissioner !== user.id;
	}

	get is_commissioner() {
		const { user, league } = this.props;

		const user_id = _.get(user, "id");
		const commish = _.get(league, "commissioner");
		return user_id === commish;
	}

	get is_league_late() {
		const { league } = this.props;

		return _.every([
			!_.isEmpty(league),
			this.league_scheduled,
			this.missed_start
		]);
	}

	get league_scheduled() {
		const { league, is_draft } = this.props;
		const league_status = _.get(league, "status");
		const draft_status = _.get(league, "draft_status");

		return is_draft
			? draft_status === "scheduled" 
			: league_status === "scheduled";
	}

	get missed_start() {
		const { is_draft } = this.props;
		if (is_draft) {
			return this.draft_missed_start;
		}
		else {
			return this.classic_missed_start;
		}

	}

	get classic_missed_start() {
		const { league, actual_round } = this.props;
		const league_start_id = _.get(league, "start_round");
		const actual_round_started = _.get(actual_round, "status", "scheduled") !== "scheduled";
		const actual_round_id = _.get(actual_round, "id");

		if (actual_round_started) {
			return league_start_id <= actual_round_id;
		}
		return league_start_id < actual_round_id;
	}

	get draft_missed_start() {
		const { league } = this.props;
		const draft_start = _.get(league, "draft_start");

		try {
			if (!draft_start) {
				return false;
			}
	
			// Give a 1 min buffer before saying draft has been missed
			const draft_moment = moment(draft_start).add(1, "m");
			const now = moment();
	
			return draft_moment.isBefore(now);
		}
		catch (e) {
			// If anything goes wrong, just return false
			return false;
		}
	}

	get reschedule_link() {
		const { league, is_draft } = this.props;		
		return `/${
			is_draft ? "draft" : "classic"
		}/league/${_.get(league, "id")}/settings`;
	}

	get is_league_video_available() {
		const {league} = this.props;
		return Boolean(league.video_id);
	}

	renderLateSubheading() {
		const { is_draft } = this.props;

		return <BoxLeagueNameSubText>
			{is_draft
				? LeagueLateLabel.DRAFT
				: LeagueLateLabel.CLASSIC
			}
		</BoxLeagueNameSubText>;
	}

	renderLateButton() {
		const {is_draft} = this.props;
		const isDraftDisabled = is_draft && IS_DISABLE_LIVE_DRAFT;
		return <ButtonAlertLink to={this.reschedule_link} reschedule={true}>
			{isDraftDisabled ? "League Settings" : "Reschedule league"} 
		</ButtonAlertLink>;
	}

	renderClassicButton() {
		const { league } = this.props;
		if(this.is_league_video_available){
			const VIDEO_URL = getWrappedVideoUrl(league.video_id);
			return <ButtonWrapper>
				<HalfSizeLink to={`/classic/league/${league.id}`}>
					Go to the league overview
				</HalfSizeLink>
				<HalfSizeButton href={VIDEO_URL} target="_blank" isRecap={true}>
					AFL Fantasy Recap
				</HalfSizeButton>
			</ButtonWrapper>;
		}
		return <ButtonPrimaryLink to={`/classic/league/${league.id}`}>
			Go to the league overview
		</ButtonPrimaryLink>;
	}

	renderRegenerateButton() {
		const {is_draft, league} = this.props;
		const leagueID = _.get(league, "id");
		
		const buttonString =  !is_draft ? 'Classic' : 'Draft';

		const gameType = "AFL Fantasy";
		const pathString = !is_draft ? 'classic' : 'draft';
		const url = `/${pathString}/leagues/create/${leagueID}`;
		return <RegenerateButton to={url}>
			Regenerate {gameType} {buttonString} League
		</RegenerateButton>;
	}

	renderDraftButton() {
		const { league } = this.props;

		const league_route_text = getDefaultDraftLeagueRouteButtonText(league);
		const league_route = getDefaultDraftLeagueRoute(league);
		const is_draft_active = league.draft_status === "active";
		const draft_button = this.hide_live_draft
			? <LeagueStatusText>
			Draft to be completed by the commissioner
			</LeagueStatusText>
			: <StyledButtonPrimaryLink to={league_route} live_draft={is_draft_active}>
				{league_route_text}
			</StyledButtonPrimaryLink>;
		const half_size_draft_button = this.hide_live_draft
			? <HalfSizeButton>
		Draft to be completed by the commissioner
			</HalfSizeButton>
			: <HalfSizeLink to={league_route} live_draft={is_draft_active}>
				{league_route_text}
			</HalfSizeLink>;
		if(this.is_league_video_available){
			const VIDEO_URL = getWrappedVideoUrl(league.video_id);
			return <ButtonWrapper>
				{half_size_draft_button}
				<HalfSizeButton href={VIDEO_URL} target="_blank" isRecap={true}>
					AFL Fantasy Recap
				</HalfSizeButton>
			</ButtonWrapper>;
		}
		return draft_button;
	}



	renderLeagueButton() {
		const { is_draft, is_regenerate } = this.props;
		if(is_regenerate){
			return this.renderRegenerateButton();
		}
		
		if (this.is_league_late && this.is_commissioner) {
			return this.renderLateButton();
		}

		if (is_draft) {
			return this.renderDraftButton();
		}
		else {
			return this.renderClassicButton();
		}
	}

	renderLeagueSubheading() {
		const { league, user } = this.props;

		if (this.is_league_late) {
			return this.renderLateSubheading();
		}
		if(this.props.is_regenerate){
			return <RegenInnerBoxSubText>
				{getShortName(user)}
			</RegenInnerBoxSubText>;

		}

		return <BoxLeagueNameSubText>
			{getShortName(league)}
		</BoxLeagueNameSubText>;
	}

	

	render() {
		const { league, is_draft, is_regenerate } = this.props;
		if(!league.play_times && is_regenerate){
			return null;
		}

		return <BoxLeague key={league.id} no_hover={this.hide_live_draft}>
			<FavouriteButton
				onClick={this.favouriteFunction}
				active={this.is_favourite}
			>
				<FavouriteStarIcon />
			</FavouriteButton>
			<BoxInner>
				<LeagueAvatarStyled
					is_classic={!is_draft}
					league={league}
					className="playwright-mask-hidden"
				/>
				<BoxLeagueNameText>{league.name}</BoxLeagueNameText>
				{ this.renderLeagueSubheading() }
				{ this.renderLeagueButton() }
			</BoxInner>
		</BoxLeague>;
	}
}