// @flow
import * as React from "react";
import map from "lodash/map";
import { Field } from "redux-form";
import styled, { css } from "styled-components";
import colors from "../../../assets/css/colors";

import Tick from "../../Icons/Tick";

const FormCircleCheckboxLabel = styled.label`
	font-size: 13px;
	line-height: 1.5;
	color: ${colors.primary.primary};
	margin-left: 5px;
	cursor: pointer;
	font-family: SourceSansPro;
	font-weight: 600;
	font-family: TitilliumUpright;
	font-weight: 400;
	letter-spacing: -0.03em;
	vertical-align: middle;
	margin-top: 0.1em;
`;

const FormCircleCheckboxStyled = styled.div`
	display: flex;
	align-items: center;
	margin-right: 1em;

	.custom-radio {
		position: relative;
		cursor: pointer;
		height: 16px;
		width: 16px;
		border-radius: 50%;
		box-sizing: border-box;
		border: 1px solid ${colors.primary.primary};
		background: #fff;
		display: flex;
		justify-content: center;
		align-items: center;

		> svg {
			color: #fff;
			width: 14px;
			height: 14px;
		}
	}

	${ ({ is_disabled }) => is_disabled ? css`
		label {
			color: ${colors.form.disabled};
		}

		.custom-radio {
			border: 1px solid ${colors.form.disabled};
			background: ${colors.form.base};;
			> svg {
				fill: ${colors.form.base};
			}
		}
	` : null }

	input {
		visibility: hidden;
		width: 0;
		margin: 0;

		&:checked + label.custom-radio {
			background: ${colors.primary.primary};
		}
	}
`;

type Props = {
	name: string,
	onChange?: Function,
	label?: string,
	className?: string,
	disabled?: boolean,
	elements: Array<Object>,
	selected?: string
};

const tick_icon = <Tick/>;

export const FormCircleRadio = ({
	name,
	onChange,
	label,
	className,
	disabled: all_disabled = false,
	elements,
	selected
}: Props) => (
	<React.Fragment>
		{
			map(elements, ({ value, text, icon = tick_icon, after, disabled }, index) => {
				const is_disabled = disabled || all_disabled;
				return [
					<FormCircleCheckboxStyled
						className={className}
						key={index}
						is_disabled={is_disabled}
					>
						<Field
							name={name}
							id={`${name}_${value}`}
							value={value}
							component='input'
							type='radio'
							key={index}
							disabled={is_disabled}
						/>
						<label className='custom-radio' htmlFor={`${name}_${value}`}>
							{ icon }
						</label>

						<FormCircleCheckboxLabel htmlFor={`${name}_${value}`}>
							{text}
							{
								after ? after : null
							}
						</FormCircleCheckboxLabel>
					</FormCircleCheckboxStyled>
				];
			})
		}
	</React.Fragment>
);

export default FormCircleRadio;