// @flow
import * as React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import styled from "styled-components";
import _ from "lodash";
import { Redirect } from "react-router-dom";
import * as selectors from "../../../modules/selectors";
import * as actions from "../../../modules/actions";
import { below, above } from "../../../assets/css/media";
import ArrowUpSmall from "../../../components/Icons/ArrowUpSmall";
import ArrowDownSmall from "../../../components/Icons/ArrowDownSmall";
import LockCircle from "../../../components/Icons/LockCircle";
import { Tr } from "../../../components/TableX";
import {
	AdsContainer,
	ButtonAlert,
	ConfirmationModal,
	PageTitle,
	Footer,
	PageContentWrapper,
	StandardSidebar,
	TwoColumnLayout,
	SwitchButton,
	LeagueGameBar,
	DragIcon,
	withDraftCore,
	FlexDivColCenter,
	TextModal
} from "../../../components";
import type { TDraftPassedProps } from "../../../components/Draft/withDraftCore";
import Ad from "../../../components/Ad";
import TeamUserAvatar from "../../../components/Team/TeamUserAvatar";

import TdStat from "../../../components/Draft/TdStat";
import colors from "../../../assets/css/colors";
import { getShortName, restoreFullDraftOrder } from "../../../helpers/league";
import { immutableMove, isAllTrue } from "../../../helpers";
import type { TPLeague, TDraftTeamsById, TDraftUserTeam } from "../../../modules/types";

type Props = TDraftPassedProps & {
	selected_league_id: number,
	showDraftOrder: typeof actions.leagueDraft.showDraftOrder,
	changeDraftOrder: actions.leagueDraft.changeDraftOrder,
	leaveDraftLeague: typeof actions.leagueDraft.leaveDraftLeague,
	removeTeamDraftLeague: typeof actions.leagueDraft.removeTeamDraftLeague,
	saveCustomFullDraftOrder: typeof actions.leagueDraft.showCustomFullDraftOrder,
	resetShowDraftOrder: typeof actions.leagueDraft.resetShowDraftOrder,
	fetchLeague: typeof actions.leagueDraft.showDraftLeague,
	is_pending_show: boolean,
	user_id: number,
	league: TPLeague,
	teams_by_id: TDraftTeamsById,
	teams: Array<TDraftUserTeam>,
	draft_order: Array<number>,
	error?: string,
	match: {
		params: {
			league_id: string,
		},
	},
	leave_league_success: boolean,
	remove_team_success: boolean,
};

const NamesWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
`;

const AvatarWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 0 10px 0 0;

	> div {
		height: 40px;
		width: 40px;
	}
`;

const TeamInfoWrapper = styled.div`
	display: flex;
	justify-content: flex-start;
	height: 40px;
	margin: 10px 0;
`;

const DraftOrderRow = styled(Tr)`
	height: 60px;
	&:nth-child(odd) {
		background-color: ${colors.form.base};
	}
	${below.phone`
		height: 50px;
		td:first-child {
			
		}
		td{
			padding: 0px;
			vertical-align: top;
		}
	`}
`;

const DraftOrderTh = styled.th`
	color: ${colors.primary.primary};
	font-size: 12px;
	line-height: 10px;
	text-align: left;
	text-transform: capitalize;
	border-bottom: none;
	padding-bottom: 10px;
	${below.phone`
		text-align: none;
	`}
`;

const TeamTh = styled(DraftOrderTh)``;

export const Table = styled.table`
	width: 100%;
	thead th:first-child {
		text-align: left;
	}

`;

const NoOrderDiv = styled.div`
	text-align: center;
	font-size: 16px;
	padding: 30px;

	font-family: SourceSansPro;
	background-color: ${colors.secondary.lightGrey};
	border-radius: 5px;
	height: auto;
`;

const TeamName = styled.div`
	line-height: 1.125;
	text-align: left;
	color: ${colors.primary.primary};
	text-overflow: ellipsis;
	overflow: hidden;
	max-width: 60px;
	font-weight: 400;
	font-family: TitilliumUpright;
	font-size: 12px;
	letter-spacing: 0;
  }

	${below.tablet`
		font-size: 14px;
	`}

	${above.phone`
		max-width: 150px;
	`}

	${above.tablet`
		max-width: 100px;
	`}

	${above.desktop`
		max-width: 200px;
	`}
`;
const UserName = styled.div`

	font-family: "SourceSansPro";
	font-size: 12px;
	letter-spacing: 0;
	line-height: 12px;

	text-align: left;
	color: ${colors.secondary.accentGrey};
	text-overflow: ellipsis;
	overflow: hidden;
	max-width: 60px;
	font-weight: 400;

	${above.phone`
		max-width: 150px;
	`}

	${above.tablet`
		max-width: 100px;
	`}

	${above.desktop`
		max-width: 200px;
	`}

	${below.phone`
		display: none;
	`}
`;

const Commish = styled(UserName)`
	font-size: 14px;
	text-align: center;
`;

const SwitchButtonTeams = styled(SwitchButton)`
	padding: 0;
	height: 21px;
	width: 25px;
`;

const DraftOrderNoSpan = styled.span`
	height: 40px;
	width: 40px;
	border: 1px solid ${colors.secondary.paleGrey};
	background-color: ${colors.white};
	
	color: ${colors.secondary.accentGrey};
	font-family: "SourceSansPro";
	font-size: 12px;
	font-weight: 600;
	letter-spacing: 0;
	line-height: 40px;
	text-align: center;
`;

const DraftOrderWrap = styled.div`
	display: flex;
	${below.phone`
		margin-left: 0px;
	`}
`;

const DragFlexDivColCenter = styled(FlexDivColCenter)`
    margin: 0 10px;
		${below.phone`
			display: none;
		`}
`;

const RestorePicksBG = styled.div`
	height: ${({ customHeight }) => customHeight ? `${customHeight}px` : "auto"};
	margin-top: ${({ customHeight }) => customHeight ? `-${customHeight}px` : "auto"};
	position: relative;
	background-color: rgba(240,243,247,0.9);
	width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
`;

const RestorePicksIcon = styled.div`
  margin: 0 auto;
`;
const RestorePicksTitle = styled.div`
	color: ${colors.primary.primary};
	font-family: TitilliumUpright;
	font-weight: bold;
	font-size: 32px;
	letter-spacing: 0;
	line-height: 32px;
	text-align: center;
    margin: 10px 0 0;
`;
const RestorePicksContent = styled.div`
	color: ${colors.primary.primary};
	font-family: "SourceSansPro";
	font-size: 14px;
	letter-spacing: 0;
	line-height: 20px;
	text-align: center;
    margin: 10px 0;
`;
const RestorePicksButtons = styled.div`
	width: 300px;
	margin: 0 auto;
	display: flex;
	justify-content: center;
`;

type State = {
	show_leave_modal: boolean,
	show_remove_modal: boolean,
	left_the_league: boolean,
	remove_the_team: boolean,
	remove_id: number,
	show_restore_picks: boolean,
};

class LeagueDraftOrderComponent extends React.Component<Props, State> {
	constructor(props, state) {
		super(props, state);

		_.bindAll(this, [
			"leaveThisLeague",
			"removeFromLeague",
			"confirmLeaveleague",
			"confirmRemoveTeamleague",
			"closeLeaveModal",
			"closeRemoveModal",
			"updateArrowsDraftOrder",
			"updateTextDraftOrder",
			"handleReorderPicks"
		]);
	}

	state = {
		show_remove_modal: false,
		show_leave_modal: false,
		left_the_league: false,
		remove_the_team: false,
		show_restore_picks: true,
		remove_id: 0,
	};

	componentDidMount() {
		this.fetchLeague();
		const { league_id } = this.props.match.params;
		this.props.showDraftOrder({ league_id });
	}

	componentDidUpdate(prev_props: Props) {
		const { remove_team_success: success } = this.props;
		const { remove_team_success: old_success } = prev_props;

		if (!old_success && success) {
			window.location.reload();
		}
	}

	handleReorderPicks() {
		const { league, saveCustomFullDraftOrder } = this.props;
		saveCustomFullDraftOrder({
			order:restoreFullDraftOrder(
				league.custom_draft_order,
				league.custom_full_draft_order.length / league.num_teams,
				league.draft_order
			),
			id: league.id
		});
		this.setState({ show_restore_picks: false });
	}

	confirmLeaveleague() {
		const {
			leaveDraftLeague,
			league: { id },
		} = this.props;
		leaveDraftLeague({ league_id: id });
		this.setState({ left_the_league: true });
	}

	confirmRemoveTeamleague() {
		const {
			removeTeamDraftLeague,
			league: { id },
		} = this.props;
		const team_id = this.state.remove_id;
		removeTeamDraftLeague({ league_id: id, team_id: team_id });
		this.setState({ remove_the_team: true });
	}

	leaveThisLeague() {
		this.setState({ show_leave_modal: true });
	}

	removeFromLeague({ currentTarget }) {
		const { id } = currentTarget.dataset;
		this.setState({ show_remove_modal: true, remove_id: parseInt(id, 10) });
	}

	closeLeaveModal() {
		this.setState({ show_leave_modal: false });
	}

	closeRemoveModal() {
		this.setState({ show_remove_modal: false });
	}

	updateTextDraftOrder({ currentTarget }) {
		const { draft_order, league, changeDraftOrder } = this.props;
		const { id } = currentTarget.dataset;
		const to =
			currentTarget.value <= draft_order.length
				? currentTarget.value - 1
				: draft_order.length - 1;
		const from = draft_order.indexOf(parseInt(id, 10));
		draft_order.splice(from, 1);
		draft_order.splice(to, 0, parseInt(id, 10));
		changeDraftOrder({
			team: draft_order,
			id: parseInt(league.id, 10),
		});
	}

	updateArrowsDraftOrder({ currentTarget }) {
		const { changeDraftOrder, league, draft_order } = this.props;

		const { from, to } = currentTarget.dataset;
		
		// draft_order.splice(from, 1);
		// draft_order.splice(to, 0, parseInt(id, 10));
		changeDraftOrder({
			team: immutableMove(draft_order, Number(from), Number(to)),
			id: parseInt(league.id, 10),
		});
		
	}

	get leave_modal() {
		return (
			<ConfirmationModal
				header_text="Leave League?"
				body_text="Are you sure you want to leave this league ?"
				confirmClick={this.confirmLeaveleague}
				cancelClick={this.closeLeaveModal}
				closeClick={this.closeLeaveModal}
			/>
		);
	}

	get remove_modal() {
		return (
			<ConfirmationModal
				header_text="Remove Team?"
				body_text="Are you sure you want to remove this user from league ?"
				confirmClick={this.confirmRemoveTeamleague}
				cancelClick={this.closeRemoveModal}
				closeClick={this.closeRemoveModal}
			/>
		);
	}

	get status_and_time_until_draft() {
		const { league } = this.props;
		return (
			league.status === "scheduled" &&
			Date.parse(league.draft_start) - Date.parse(Date()) > 1800000
		);
	}

	fetchLeague() {
		const { fetchLeague, selected_league_id } = this.props;
		fetchLeague({
			id: selected_league_id,
		});
	}

	get table_header() {
		return (
			<thead>
				<tr>
					<DraftOrderTh width="140">Draft Order</DraftOrderTh>
					<TeamTh>Team / Owner</TeamTh>
				</tr>
			</thead>
		);
	}
	get draft_status () {
		const { league } = this.props;
		return ((league.status === "scheduled"
      && (Date.parse(league.draft_start) - Date.parse(Date())) > 1800000));
	};

	commissionerButton(team) {
		const { user_id, league } = this.props;
		if (user_id !== league.commissioner && team.user_id === league.commissioner) {
			return (
				<TdStat>
					<Commish>Commisioner</Commish>
				</TdStat>
			);
		}
		return <TdStat></TdStat>;
	}

	get table_body() {
		const {
			// user_id, league,
			teams_by_id, onDragEnd, onDragEnter, onDragLeave,
			onDropTeam, onDragOver, onDragStart, draft_order,
			onEnableDrag, onDisableDrag, user_id, league,
		} = this.props;

		const is_ignored = !draft_order;

		const is_dnd_enabled = user_id === league.commissioner ? true : false;
		return (
			<tbody>
				{draft_order ? draft_order.map((teamID, index) =>  {
					const teamByID = teams_by_id[teamID];
					const draftOrderNo = index + 1;
					return teamByID ? <DraftOrderRow	
						draggable="false"
						data-drag-and-drop-enabled={is_dnd_enabled}
						data-team-id={teamByID.id}
						onDrop={onDropTeam}
						onDragEnd={onDragEnd}
						onDragEnter={onDragEnter}
						onDragLeave={onDragLeave}
						onDragOver={onDragOver}
						onDragStart={onDragStart}
						className={is_ignored ? "is-ignored" : null}
						key={teamID}
					>
						<td>
							<DraftOrderWrap>
								<DragFlexDivColCenter>
									<DragIcon
										onMouseEnter={onEnableDrag}
										onMouseLeave={onDisableDrag}
										disabled={!is_dnd_enabled}
									/>
								</DragFlexDivColCenter>
								<TeamInfoWrapper>
									<DraftOrderNoSpan>{draftOrderNo}</DraftOrderNoSpan>
									<div>
										<SwitchButtonTeams
											bgColor={colors.secondary.paleGrey}
											noSvgMargin={true}
											up={true}
											disabled={
												is_ignored || 
											draftOrderNo === 1 || 
											!is_dnd_enabled}
											data-from={index}
											data-to={index - 1}
											data-id={teamID}
											onClick={this.updateArrowsDraftOrder}>
											<ArrowUpSmall color={colors.white} size="1.5" />
										</SwitchButtonTeams>
										<SwitchButtonTeams
											bgColor={colors.secondary.paleGrey}
											noSvgMargin={true}
											disabled={
												is_ignored || 
											draft_order.length === draftOrderNo || 
											!is_dnd_enabled
											}
											data-from={index}
											data-to={index + 1}
											data-id={teamID}
											onClick={this.updateArrowsDraftOrder}>
											<ArrowDownSmall color={colors.white} size="1.5" />
										</SwitchButtonTeams>
									</div>
									
								</TeamInfoWrapper>
							</DraftOrderWrap>
						</td>
						<td>
							<TeamInfoWrapper>
								<AvatarWrapper>
									<TeamUserAvatar
										user_id={teamByID.user_id}
										avatar_version={teamByID.avatar_version}
										firstname={teamByID.firstname}
										lastname={teamByID.lastname}
									/>
								</AvatarWrapper>
								<NamesWrapper>
									<TeamName>{teamByID.name}</TeamName>
									<UserName>{getShortName(teamByID)}</UserName>
								</NamesWrapper>
							</TeamInfoWrapper>
						</td>
					</DraftOrderRow>: null;
				}):null}
			</tbody>
		);
	}

	get restore_picks() {
		const { league, user_id } = this.props;
		const { show_restore_picks } = this.state;
		if(
			isAllTrue([
				league.custom_draft_order,
				JSON.stringify(league.custom_full_draft_order) !== 
				JSON.stringify(restoreFullDraftOrder(
					league.custom_draft_order,
					league.custom_full_draft_order.length / league.num_teams,
					league.draft_order
				)),
				show_restore_picks,
				league.commissioner === user_id
			])
		) {
			return <RestorePicksBG customHeight={league.num_teams * 60}>
				<RestorePicksIcon>
					<LockCircle color={colors.primary.primary} size={4} />
				</RestorePicksIcon>
				<RestorePicksTitle>
				Restore custom picks
				</RestorePicksTitle>
				<RestorePicksContent>
				Your league has made changes to round-by-round picks. 
				Do you with to reset your draft order?
				</RestorePicksContent>
				<RestorePicksButtons>
					<ButtonAlert 
						onClick={() => this.handleReorderPicks()
						}>Yes, please reset my draft picks</ButtonAlert>
				</RestorePicksButtons>
			</RestorePicksBG>;
		}
		else {
			return null;
		}
	}

	get error_modal() {
		const { error, resetShowDraftOrder } = this.props;
		return (
			<TextModal
				onClick={() => resetShowDraftOrder()}
				header_text='Error'
				text={ error }
			/>
		);
	}

	get show_draft_order_table() {
		const { league } = this.props;
		if (league.num_teams >= league.size) {
			return (
				<div>
					<Table>
						{this.table_header}
						{this.table_body}
					</Table>
					{this.restore_picks}
				</div>
			);
		}
		else {
			return (
				<div>
					<NoOrderDiv>League is not full. There is currently no draft order.</NoOrderDiv>
				</div>
			);
		}
	}

	render() {
		const { 
			draft_order_status,
			leave_league_success,
			league,
			user_id
		} = this.props;
		const { league_id } = this.props.match.params;
		const { show_leave_modal, show_remove_modal, left_the_league } = this.state;
		const draftOrderTitle = () => (
			user_id === league.commissioner ? "Manage draft order" : "View draft order"
		);
		if(!this.draft_status){
			return <Redirect to={`/draft/league/${league_id}`} />; 
		}
		return (
			<React.Fragment>
				<LeagueGameBar />
				<AdsContainer>
					<Ad />
				</AdsContainer>

				{left_the_league && leave_league_success ? <Redirect to="/draft/leagues" /> : null}
				{show_leave_modal ? this.leave_modal : null}
				{show_remove_modal ? this.remove_modal : null}
				{draft_order_status === "failed" ? this.error_modal : null}
				<PageContentWrapper>
					<PageTitle>{draftOrderTitle()}</PageTitle>
					<TwoColumnLayout>
						{this.show_draft_order_table}
						<StandardSidebar />
					</TwoColumnLayout>
				</PageContentWrapper>
				<Footer />
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state, props) => {
	const {
		leagues: {
			show_order: { draft_order_status, error },
			teams_by_id,
		},
	} = state;
	const selected_league_id = props.match.params.league_id;
	const teams = selectors.leagueDraft
		.getLeagueDraftOrder(state)
		.map(team_id => teams_by_id[team_id])
		.filter(_.identity);
	return {
		selected_league_id,
		league: selectors.leagues.getLeague(state, props),
		is_pending_show: selectors.leagues.show.isPending(state),
		user_id: selectors.getUser(state).id,
		leave_league_success: state.leagues.leave.success,
		remove_team_success: state.leagues.remove_team.success,
		draft_order: state.leagues.show_order.ordered_ids,
		error,
		draft_order_status,
		teams_by_id,
		teams,
	};
};

const mapDispatchToProps = {
	fetchLeague: actions.leagueDraft.showDraftLeague,
	leaveDraftLeague: actions.leagueDraft.leaveDraftLeague,
	removeTeamDraftLeague: actions.leagueDraft.removeTeamDraftLeague,
	showDraftOrder: actions.leagueDraft.showDraftOrder,
	saveCustomFullDraftOrder: actions.leagueDraft.saveCustomFullDraftOrder,
	changeDraftOrder: actions.leagueDraft.changeDraftOrder,
	resetShowDraftOrder: actions.leagueDraft.resetShowDraftOrder
};

export const LeagueDraftOrder = compose(
	withDraftCore,
	connect(
		mapStateToProps,
		mapDispatchToProps,
	)
)(LeagueDraftOrderComponent);

export default LeagueDraftOrder;