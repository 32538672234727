// @flow
import * as React from "react";
import styled from "styled-components";
import _ from "lodash";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import {getLineupPlayersCount} from "../../helpers/complex/team";
import * as selectors from "../../modules/selectors";
import colors from "../../assets/css/colors";

import Trade from "../Icons/Trade";
import Undo from "../Icons/Undo";
import Lock from "../Icons/Lock";
import Stop from "../Icons/Stop";
import {TPLeague, TRound, ById, TPlayer,TClassicTeamsByID, TTeam} from "../../modules/types";

import {
	DraftTableHeader,
	PlayerInfo,
	TeamSelect,
	PlayerActionButton,
	BenchLabel,
	EmptyTeamTable,
	TableHead,
	ThStat
} from "../";
import TableUnstyled, { Tr, TBody, Td, Th as ThUnstyled } from "../TableX";
import { getSquadSize } from "../../helpers/league";
import type { TCoachStatsListReducer, TTeamsDraftTradesReducer } from "../../modules/types";
import { coach_player_stats } from "../../helpers/stats";
import UndoButton from "../UndoButton";
import { renderTableHeaderCell } from "../../helpers/tables";
import { score_settings } from "../../helpers";
import { below } from "../../assets/css/media";

const StyledTr = styled(Tr)`
	position: relative;
	z-index: 1;
	box-shadow: 0 0 0px rgba(0, 0, 0, 0);

	transition: transform 0.2s linear,
		box-shadow 0.2s linear;

	> td {
		transition: background-color 0.2s linear,
			border-radius 0.2s linear;
      min-width: 50px;
	}
  td.absolute-group{
    position: sticky;
    left: 0;
    background-color: #f8f8fa;
    min-width: 230px;
    ${below.phone`
      min-width: 50px;
      width: 50px
    `}
  }
  th.absolute-group{
    background-color: #f8f8fa;
  }
  td.absolute-group-right{
    position: sticky;
    right: 0;
    background-color: #f8f8fa;
    ${below.phone`
        min-width: 30px;
        width: 30px;
    `}
  }
  .player-info{
    box-shadow: ${({ is_scrollable }) => 
		is_scrollable ? "rgb(202 210 216) 15px 0px 15px -5px" : 0 }; 
    ${below.desktop`
      box-shadow: rgb(202 210 216) 15px 0px 15px -5px
    `}
  }
  .shadow-left {
    box-shadow: ${({ is_scrollable }) => 
		is_scrollable ? "rgb(202 210 216) -10px 0px 20px 0px" : 0}; 
    ${below.desktop`
      box-shadow: rgb(202 210 216) -10px 0px 20px 0px;
    `}
  }
`;
const StyledThStat = styled(ThStat)`
  background-color: #f8f8fa;
  width: 50px;
  min-width: 50px;
`;

const Th = styled(ThUnstyled)`
  
`;
const Table = styled(TableUnstyled)`
	border-collapse: separate;
	/* Need to cover the traded player which sticks out a bit */
	& > thead > tr > ${Th}:first-child {
		display: flex!important;
		padding: 0 0 0 8px;
		align-items: center;
	}
  .empty-header{
    width: 110px;
    min-width: 110px;
    background: white;
  }
  .empty-header-right{
    width: 50px;
    min-width: 50px;
    background: white;
  }
  .empty-description{
    min-width: 110px;
    width: 110px;
  }
  .empty-description-right{
    min-width: 10px;
    width: 10px;
  }
  ${below.phone`
    & td,
		& th {
			display: table-cell;
		}
  `}
`;

const TableWrapper = styled.div`
  width: 100%;
  overflow: auto hidden;
  position: relative;
`;

const TableTitleFlexDiv = styled.div`
	display: flex;
	justify-content: space-between;
`;

const TableTitleFlexColDiv = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

type TableColumn = { key: string, prop: string, is_fc?: boolean, tooltip: string };
type Props = {
	team: TTeam,
  user_id: number,
	team_id: number,
	league: TPLeague,
	table: {
		basic: TableColumn[],
		coach: TableColumn[],
	},
	actual_round: TRound,
	lineup_players: Object,
	emergency_arr: Array<number>,
	addInTrade: Function,
	addOutTrade: Function,
	removeInPlayer: Function,
	removeOutPlayer: Function,
	team_trade: TTeamsDraftTradesReducer,
	is_league_started: boolean,
	has_assistant_coach: boolean,
	coach_stats_list: TCoachStatsListReducer,
	is_user: boolean,
	teams_by_id: TClassicTeamsByID,
	handleTeamSelect?: Function,
	empty_lineup: Object,
	changeView: Function,
	table_header_title: string,
  active_stats_type: string,
  all_stats: Object,
  players: ById<TPlayer>,
  is_classic: boolean,
};

export class TeamForTradePage extends React.Component<Props> {
	static defaultProps = {
		players: [],
		table: {
			basic: [
				{ key: "GP", prop: "stats.games_played", tooltip: "	Games Played" },
				{ key: "AVG", prop: "stats.avg_points", tooltip: "Average Points" },
				{ key: "TP", prop: "stats.total_points", tooltip: "Total Points" },
				{ key: "PROJ", prop: "stats.proj_score_actual_round", is_fc: true,
					tooltip: "Project Score" },
			],
			coach: { ...coach_player_stats("stats", 0, false) }
		},
		team: {},
		teams_by_id: {}
	};

	constructor(props: Props) {
		super(props);

		_.bindAll(this, [
			"getTableSection",
			"renderTableRow",
			"isCanTrade",
			"tableColumnToCellInfo"
		]);
	}

	get user_team() {
		return (
			<React.Fragment>
				{this.team_table}
			</React.Fragment>
		);
	}

	get team_table() {
		const { is_user } = this.props;
		return (
			<TableWrapper>
				<Table
					show_border_bottom
					
				>
					<TableHead is_user_team={is_user}>
						{this.table_header}
					</TableHead>
					<TBody>
						{this.table_body}
					</TBody>
				</Table>
			</TableWrapper>
		);
	}

	get empty_team_table() {
		const { empty_lineup } = this.props;
		return <div>
			<EmptyTeamTable empty_lineup={empty_lineup} table_keys={this.table_keys} />
		</div>;
	}
	get league_teams() {
		const { team_id } = this.props;
		/**
		 * remove empty_team_table in the below ticket.
		 * https://fanhub.atlassian.net/browse/AFD21-300
		 */

		return (
			<React.Fragment>
				{team_id ? this.team_table : null}
			</React.Fragment>
		);
	}

	get team_select() {
		const { teams_by_id, team_id, handleTeamSelect } = this.props;
		if (_.isEmpty(teams_by_id)) {
			return null;
		}
		return (
			<React.Fragment>
				<TeamSelect value={team_id} handleTeamSelect={handleTeamSelect} show_label={false}>
					<option value="0">Select a team to trade with</option>
					{_.map(teams_by_id, ({ id, name }) => (
						<option key={id} value={id}>
							{name}
						</option>
					))}
				</TeamSelect>
			</React.Fragment>
		);
	}

	get draft_table_header() {
		const draft_table_header_title = this.props.table_header_title
			.replace("|OTHERTEAM|", this.props.team.name);

		const { team_id, is_user } = this.props;
		const title = is_user ? draft_table_header_title:
			<TableTitleFlexDiv>
				<TableTitleFlexColDiv>
					{draft_table_header_title}
				</TableTitleFlexColDiv>
				<div>{this.team_select}</div>
			</TableTitleFlexDiv>;
		return (
			<DraftTableHeader
				reverseColors={is_user ? false:true}
				selectOptions={[
					{ key: "general-stats",value: "General Stats" },
					...this.props.coach_stats_list
				]}
				onSelectChange={null}
				active_stats_type={this.props.active_stats_type}
				title={title}
				team_to_team={true}
				panel_open_on_mount={this.props.team_id === 0}
				panelArea={team_id !== undefined ? this.team_select : null}
			/>
		);
	}

	get team_size() {
		const { team, league } = this.props;

		if (_.isEmpty(team) || _.isEmpty(league)) {
			return null;
		}
		const { lineup } = team,
			current = getLineupPlayersCount({ lineup }),
			total = getSquadSize(league);

		return (<p>{current}/{total}</p>);
	}

	//eslint-disable-next-line complexity
	get table_keys() {
		const { active_stats_type, league, actual_round, is_classic, table } = this.props;
		const statsBasis = league.custom_scoring_enabled ? "custom_stats" : "stats";


		if(active_stats_type === "custom-stats"){
			const customStats = [{
				key: "GP",
				prop: "stats.games_played",
				tooltip: "Games Played",
				is_fc: false
			}];

			if(league.custom_scoring && Object.keys(league.custom_scoring).length > 0){
				Object.keys(league.custom_scoring).forEach(i => {
					customStats.push({ 
						key: i,
						prop: i,
						tooltip: _.get(score_settings[i],"title", "" ), 
						is_fc: false
					});
				});
			}
			else{
				for(let i = 0; i < 9; i++){
					customStats.push({ 
						key: Object.keys(score_settings)[i],
						prop:  Object.keys(score_settings)[i],
						tooltip: _.get(
							score_settings[ Object.keys(score_settings)[i]],"title", "" 
						), 
						is_fc: false
					});
				}
				
			}
			return customStats;
		}
		if (league.custom_scoring_enabled && active_stats_type === "general-stats") {
			return table.basic.map<TableColumn >(item => {
				// if (item.prop==="stats.proj_score_actual_round") {
				// 	return {
				// 		...item,
				// 		prop: "custom_stats.proj_score_next_round"
				// 	};
				// }
				// else {
				return {
					...item,
					prop: `custom_${item.prop}`
				};
				
				
			});
		}
		return active_stats_type === "general-stats" ?
			this.props.table.basic :
			coach_player_stats(statsBasis, actual_round, is_classic)[active_stats_type];
	}

	tableColumnToCellInfo(column: TableColumn) {
		return {
			table: {
				order_by_desc: false,
				sort_key: ""
			},
			cell: {
				sort_key: column.prop,
				short_name: column.key,
				rel: column.prop,
				sortable: false,
				onClick: () => null,
				name: column.key,
				is_fc: column.is_fc || false,
				tooltip: column.tooltip || ""
			}
		};
	}

	get table_header() {
		const cells =_(this.table_keys)
			.map(this.tableColumnToCellInfo)
			.map(cell => renderTableHeaderCell(cell, StyledThStat, "player-stats"))
			.value();

		return (
			<Tr className="table-header-row">
				<Th className="absolute-group-left">
					{this.props.is_user? "Your team": "Team"}

				</Th>

				{ cells }
				<Th className="absolute-group-right" >
					{"Action"}
				</Th>
			</Tr>
		);
	}

	get table_body() {
		const { team, lineup_players, active_stats_type } = this.props;
		const { lineup } = team;
		const is_scrollable = active_stats_type !== "general-stats";

		if (!lineup || _.isEmpty(lineup_players)) {
			return <Tr><Td colSpan={this.table_keys.length + 2}>Loading</Td></Tr>;
		}
		const noZeros = arr => arr.filter(item => item !== 0);
		const linupNoZero = {
			"1": noZeros(lineup[1]),
			"2": noZeros(lineup[2]),
			"3": noZeros(lineup[3]),
			"4": noZeros(lineup[4]),
			bench: noZeros(lineup.bench)
		};
		const lineup_pure = _.omit(linupNoZero, ["captain", "vice_captain", "bench", "emergency"]);
		const bench = _.get(linupNoZero, "bench");
		return (
			<React.Fragment>
				{_.map(lineup_pure, this.getTableSection)}
				<StyledTr is_scrollable={is_scrollable}>
					<Td colSpan={7} className="absolute-group">
						<BenchLabel>
							Interchange
						</BenchLabel>
					</Td>
				</StyledTr>
				{_.map(bench, id => this.renderTableRow(
					{ id, is_bench: true, is_agent: false }
				))}
			</React.Fragment>
		);
	}

	getTableSection(line: Array<number>) {
		return _.map(line, id => this.renderTableRow({
			id,
			is_bench: false
		}));
	}

	isCanTrade({ id }: Object) {
		// AF22-480 Changing to always allow trades on draft:
		// will keep logic below in case we need to revert
		return true;

		// const {
		// 	league: { lockout },
		// 	is_league_started,
		// 	actual_round: { status }
		// } = this.props;
		// const is_not_scheduled_round = status !== 'scheduled';

		/* 
		const player = lineup_players[id];
		const is_captain = captain === id;
		const is_vice_captain = vice_captain === id;
		
		removed in AFD21-78 to allow anytime trading
		const is_locked = player.locked || is_captain || is_vice_captain;
		*/
		// 
		// const is_locked = false;
		// const is_lockout = 
		// lockout ? (is_not_scheduled_round && is_locked) : is_not_scheduled_round;
		// if (!is_league_started) {
		// 	return true;
		// }

		// return !is_lockout;
	}

	renderControls({ id }: Object) {
		const {
			removeInPlayer,
			removeOutPlayer,
			addInTrade,
			addOutTrade,
			team_trade,
			is_user
		} = this.props;
		const is_on_trade = team_trade[is_user ? "old_player_id" : "new_player_id"].includes(id);

		if (!this.isCanTrade({ id })) {
			return (
				<Td className="absolute-group shadow-left">
					<PlayerActionButton disabled="disabled">
						<Stop color={colors.secondary.paleGrey} />
					</PlayerActionButton>
				</Td>
			);
		}

		return is_on_trade ? (
			<Td className="absolute-group-right shadow-left">
				<UndoButton
					onClick={() => is_user ? removeOutPlayer({ id }) : removeInPlayer({ id })}>
					<Undo color="#fff" />
				</UndoButton>
			</Td>
		) : (
			<Td className="absolute-group-right shadow-left"> 
				<PlayerActionButton
					onClick={() => is_user ? addOutTrade({ id }) : addInTrade({ id })}>
					<Trade color={colors.primary.primary} />
				</PlayerActionButton>
			</Td>
		);
	}
	getPlayerSeasonStat(player: TPlayer, playerAllStats: Object, prop: string) {
		if (_.includes(prop, "games_played")) {
			return _.get(player, prop, "- -");
		}

		return _.get(playerAllStats, prop, "- -");
	}

	renderTableRow({ id, is_bench }: Object) {
		const {
				lineup_players,
				team_trade,
				has_assistant_coach,
				is_user,
				all_stats,
				active_stats_type,
				players, 
				league
			} = this.props,
			is_on_trade = team_trade[is_user ? "old_player_id" : "new_player_id"].includes(id);
		const custom_scoring_enabled = Boolean(_.get(league, "custom_scoring_enabled", 0));
		const player = is_user && !custom_scoring_enabled ?  lineup_players[id] : players[id] ;
		const is_scrollable = active_stats_type !== "general-stats";
		if (!player) {
			return (
				<Tr key={id}>
					<Td>Loading</Td>
				</Tr>
			);
		}
		const playerAllStats = _.get(all_stats, `${player.id}`, {});
		return (
			<StyledTr 
				key={id} 
				is_on_trade={is_on_trade} 
				is_user={is_user} 
				is_scrollable={is_scrollable}>
				<Td className="absolute-group">
					<PlayerInfo short_opponent player={player} is_classic={false} />
				</Td>
				{_.map(this.table_keys, ({ key, prop, is_fc, tooltip }) => (
					<Td key={key} rel={prop}>
						{active_stats_type === "custom-stats"?
							this.getPlayerSeasonStat(player, playerAllStats, prop) :
							is_fc && !has_assistant_coach ? (
								<Link to="/coach-subscription" title="Coach Subscription">
									<Lock color={colors.coach} />
								</Link>
							) : _.get(player, prop, "--")
						}
						
					</Td>
				))}
				{this.renderControls({ id })}

			</StyledTr>
		);
	}

	render() {
		const { is_user, changeView } = this.props;
		return (
			<div
				onClick={() => changeView ? changeView(is_user) : null}
				onKeyPress={() => changeView ? changeView(is_user) : null}
				role="button"
			>
				{this.draft_table_header}
				{is_user ? this.user_team : this.league_teams}
			</div>
		);
	}
}

const mapStateToProps = (state, props) => {
	const { team_id } = props;
	const current_user_id = state.user.data.id;
	const teams_by_id = _.filter(
		selectors.complex.team.getTeamsWithFullLineup(state, props),
		({ user_id }) => user_id !== current_user_id
	);
	const all_stats = state.players.all_stats;
	const is_classic = _.get(window.location, "pathname", "").includes("classic");
	
	const lineup_players = selectors.complex.team.getUserPlayersFromLineup(state, props);
	Object.keys(lineup_players)
		.forEach(playerID => {
			lineup_players[playerID].custom_stats = _.get(all_stats, `${playerID}`, {});
		});
	const all_players_arr = 
    selectors.players.getExtendedPlayersArrayWithOwners(state, props)
    	.map(player => ({
    		...player,
    		custom_stats: {
    			...player.custom_stats,
    			..._.get(all_stats, `${player.id}`, {})
    		}
    	}));
	const all_players = all_players_arr.reduce((obj, item) => {
		obj[item.id] = item;
		return obj;
	}, {});
  
  
	return {
		teams_by_id,
		user_id: current_user_id,
		empty_lineup: selectors.complex.team.getEmptyLineup(state, props),
		isPending: state.leagues.show.isPending,
		team: selectors.complex.team.getSelectedTeam(state, team_id),
		has_assistant_coach: Boolean(selectors.getUser(state).assistant_coach),
		actual_round: selectors.rounds.getSelectedRound(state),
		lineup_players: lineup_players,
		emergency_arr: selectors.complex.team.getEmergencyArray(state),
		is_league_started: selectors.leagueDraft.isLeagueStarted(state, props),
		coach_stats_list: state.coachStatsList,
		all_stats,
		players: all_players,
		is_classic
	};
};

export const TeamForTeamToTeamTrade = withRouter(
	connect(mapStateToProps)(TeamForTradePage)
);

export default TeamForTeamToTeamTrade;