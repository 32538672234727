/* eslint max-len: 0 */
// @flow
import React from "react";
import SvgIconContainer from "../SvgIconContainer";

export default (
	{
		unit = "em",
		...rest
	}: Object = {}) => (
	<SvgIconContainer viewBox="0 0 24 24" {...rest} unit={unit}>
		<title>Player</title>
		<path d="M23.2155588,23.9459899 C23.4338143,23.9466324 23.6424108,23.8560497
        23.7909426,23.6961305 C23.9394744,23.5362112 24.0144294,23.3215035 23.9976955,23.1038895
        C23.9604865,21.9075573 23.4000584,20.787845 22.4647077,20.0410424 C20.661622,18.6988959
        18.0221715,17.6278901 16.1440007,16.9208386 C15.6419349,16.7248605 15.2903299,16.2661667
        15.2315079,15.7304266 L15.1235731,14.326752 C15.1069244,14.1658822 15.1658659,14.0063934
        15.283129,13.8950126 C15.8579817,13.3259669 16.1825787,12.5514371 16.1851932,11.7425725
        L16.1851932,11.2545193 C16.6576038,11.2545193 17.1680783,10.0776643 17.1680783,9.23139247
        C17.1680783,8.38512061 16.8656521,8.15517244 16.6341397,8.15517244 C16.5730686,8.15551781
        16.5120929,8.16004743 16.4516411,8.16872947 C16.5808395,7.52357395 16.6456404,6.86718118
        16.6450896,6.20921646 C16.6450896,3.36901757 16.3186779,2.24639077 14.8779822,2.24639077
        C14.158231,1.41400614 13.0975562,0.956166435 11.998155,1.00331492 C7.67606791,1.00331492
        7.35747759,3.0707628 7.35747759,6.23737338 C7.3563713,6.88525529 7.42048204,7.53160335
        7.54884035,8.16664378 C7.48838856,8.15796173 7.4274129,8.15343211 7.3663418,8.15308674
        C7.13482935,8.15308674 6.83292461,8.38251349 6.83292461,9.22930677 C6.83292461,10.0761
        7.34339913,11.2524336 7.81528824,11.2524336 L7.81528824,11.7446582 C7.81613166,12.5526446
        8.13844165,13.3270891 8.71109541,13.8970983 C8.82870308,14.008497 8.88785597,14.1682291
        8.87117271,14.3293591 L8.76323786,15.7325123 C8.70441587,16.2682524 8.35281088,16.7269462
        7.8507451,16.9229243 C5.97361714,17.6299758 3.33312382,18.7009816 1.53003813,20.0431281
        C0.595320174,20.7900976 0.0365652539,21.9104679 0.00226454484,23.1064966
        C-0.0143164116,23.3240241 0.0607073216,23.5385914 0.209221837,23.698393
        C0.357736352,23.8581947 0.566243635,23.9487067 0.784401193,23.9480756 L23.2134731,23.9480756
        L23.2155588,23.9459899 Z" />
	</SvgIconContainer>
);