// @flow
import React from "react";
import { Redirect } from "react-router-dom";



type Props = {
	match: {
		params: {
			type: string,
			base64: string
		}
	}
}

export default ({ match }:Props) => {

	const { params: { base64, type } } = match;
	const uri_encoded = atob(base64);
	const json = JSON.parse(decodeURIComponent(uri_encoded));
	const url_base = type === "draft_league_invite"? "draft": "classic";

	if(!json.code) {
		return <Redirect to={`/${url_base}/leagues/join/`} />;
	}
	return <Redirect to={`/${url_base}/leagues/join/${json.code}`} />;
};