// @flow
import * as React from "react";
import { connect } from "react-redux";
import * as _ from "lodash";
import { Redirect } from "react-router-dom";
import { compose } from "redux";
import { withRouter } from "react-router-dom";


import * as selectors from "../../../modules/selectors";
import type { TPLeague } from "../../../modules/types";

import {
	PageContentWrapper,
	TwoColumnLayout,
	PageTitle,
	AdsContainer,
	Ad,
	Footer,
	StandardSidebar,
	LeagueGameBar,
} from "../../../components";
import { LeagueManagerComponent } from "../../../components/utils/LeagueManager/index";
import RemoveForm from "./leagueRemove/Remove";


type Props = {
	removeLeague: Function,
	league: TPLeague,
	league_show:  {
		error: string,
		league_id: number,
	},
	match: {
		params: {
			league_id: number,
		}
	},
	user: {
		id: number,
	},
}

class DraftLeagueRemoveComponent extends React.Component<Props> {

	componentDidMount() {
		window.scrollTo(0, 0);
	}

	checkAccessToLeague() {
		const {
			league_show: {
				league_id
			},
			user: {
				id: user_id
			},
			league,
		} = this.props;

		if(league.commissioner && league.commissioner !== user_id) {
			return <Redirect to={`/draft/league/${league_id}/about`} />;
		}
		if(league.draft_status === "complete"){
			return <Redirect to={`/draft/league/${league_id}/about`} />;
		}
		else {
			return null;
		}
	}

	render() {
		const {
			match,
			league
		} = this.props;
		if (_.isEmpty(league)) {
			return null;
		}

		return (
			<div>
				{this.checkAccessToLeague()}
				<LeagueGameBar />
				<AdsContainer>
					<Ad/>
				</AdsContainer>
				<LeagueManagerComponent from="update" league_id={match.params.league_id}>
					{({ ...props }: Props) => (
						<PageContentWrapper>
							<PageTitle>
								Remove League
							</PageTitle>
							<TwoColumnLayout>
								<RemoveForm {...props}/>
								<StandardSidebar />
							</TwoColumnLayout>
						</PageContentWrapper>
					)}
				</LeagueManagerComponent>
				<Footer />
			</div>
		);
	}
}

const mapStateToProps = (state, props) => ({
	league_show: state.leagues.show,
	user: state.user.data,
	league: selectors.leagueDraft.getLeague(state, props),
});

export const DraftLeagueRemove = compose(
	withRouter,
	connect(mapStateToProps),
)(DraftLeagueRemoveComponent);

export default DraftLeagueRemove;