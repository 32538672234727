// @flow
import styled from "styled-components";
import OpenTeamFieldButton from "../OpenTeamFieldButton";
import above, { below } from "../../assets/css/media";

export const SectionHeader = styled.div`
	height: 60px;
	width: 100%;
	border-radius: 2px;
	background-color: #7F8A90;
	font-size: 16px;
	box-sizing: border-box;
	padding: 20px 10px;
	color: #fff;
	line-height: 1.25;
	display: flex;
	align-items: center;
	justify-content: space-between;

	${above.desktop`
		${OpenTeamFieldButton} {
			display: none;
		}
	`}

	${below.tablet`
		border-radius: 0;
	`}
`;

export default SectionHeader;