// @flow
import * as React from "react";
import styled from "styled-components";
import { SwitchToggle } from "..";
import colors from "../../assets/css/colors";

const ToggleLabel = styled.label`
	color: #8D979C;
	font-size: 14px;
	line-height: 1.2;
	text-align: right;
	text-transform: uppercase;
	padding-left: 5px;
	& span.short_name {
		display: none;
	}
`;

const FlipTogglerWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
  	-webkit-user-select: none; /* Chrome/Safari */        
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* IE10+ */
  	
  	.toggle {
  		input + label {
  			background: ${({ active }) => active ? colors.coach : colors.secondary.paleGrey};
 			&:before {
				background: white;
			}
		}
	}
	
	div {
		text-align: center;
		margin-right: 0;
	}
	
	p {
		width: 100%;
		padding-top: 4px;
		margin-right: 8px;
		label {
			cursor: pointer;
		}
	}
`;

type Props = {
	onChange: Function,
	is_checked: boolean,
	label: string,
	name?: string,
	short_label?: string
}

export const CoachToggle = ({ 
	onChange, 
	is_checked, 
	name = "coach-toggle",
	label,
	short_label = label
}: Props) => (
	<FlipTogglerWrapper active={is_checked}>
		<SwitchToggle
			onChange={onChange}
			id={name}
			name={name}
			className="toggle"
			value={is_checked}
		/>
		<p>
			<ToggleLabel htmlFor={name}>
				<span className="long_name">{ label }</span>
				<span className="short_name">{ short_label }</span>
			</ToggleLabel>
		</p>
	</FlipTogglerWrapper>
);

export default CoachToggle;