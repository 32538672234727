// @flow
import styled from "styled-components";
import { Th } from "../../components/TableX";

export const ThIgnore = styled(Th)`
	width: 62px;
	font-size: 12px;
	text-align: center;
`;

export default ThIgnore;