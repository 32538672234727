export const onDragOver = (event: any) => {
	event.preventDefault();
};

export const onDragEnter = (event: any) => {
	event.preventDefault();

	if (event.currentTarget.contains(event.relatedTarget)) {
		return;
	}

	event.currentTarget.classList.add("hover-over");
};

export const onDragLeave = (event: any) => {
	event.preventDefault();

	if (event.currentTarget.contains(event.relatedTarget)) {
		return;
	}

	event.currentTarget.classList.remove("hover-over");
};