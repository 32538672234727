// @flow
import * as React from "react";
import styled from "styled-components";
import _ from "lodash";

import type {
	TPosition,
} from "../../../modules/types";
import { findMatch } from "../../../helpers/rounds";
import { getYetToPlay } from "../../../helpers/team";
import { below } from "../../../assets/css/media";
import { 
	PlayerCount, 
	PositionHeader, 
	PositionTitle, 
} from "../playerRow";
import AbstractComparisonTable from "../comparisonTable";
import { getFields, getField, getBench } from "./players";

const Container = styled.div`
	button[rel="compressed-view"], button[rel="uncompressed-view"] {
		${below.desktop`
			display: none;
		`};
	}
`;

const PositionsPlayerList = styled.div``;

export class UniqueComparisonTable extends AbstractComparisonTable {
	/**
	 * Sorts the given list of player ids by:
	 *  - match status: live > upcoming > complete
	 *  - match datetime
	 *  - lastname
	 *  - firstname
	 * @param {number[]} player_ids List of players to sort
	 */
	sortByMatch(player_ids: number[]) {
		const { round, players } = this.props;
		const matches = _.get(round, "matches", []);

		const unsorted_statuses = _(player_ids)
			.map(id => _.get(players, id))
			.reduce((all, player) => {
				const squad = _.get(player, "squad_id");
				const match = findMatch(matches, squad);
				const status = _.get(match, "status");

				if (!status) {
					return all;
				}

				return {
					...all,
					[status]: [
						..._.get(all, status, []),
						{
							player,
							match_date: new Date(match.date)
						}
					]
				};
			}, {});
		
		const statuses = _.mapValues(unsorted_statuses, (players, status) => {
			return _(players).orderBy().orderBy([
				"match_date",
				"player.squad_id",
				"player.last_name",
				"player.first_name",
			]).map(player => player.player.id);
		});


		const playing = _.get(statuses, "playing", []);
		const scheduled = _.get(statuses, "scheduled", []);
		const complete = _.get(statuses, "complete", []);

		return [
			...playing,
			...scheduled,
			...complete
		];
	}

	renderPlayerCount(player_pos_ids: number[], position: TPosition) {
		const { players, round } = this.props;
		const yet_to_play = getYetToPlay(player_pos_ids, players, round);

		return (
			<PlayerCount>
				{yet_to_play} yet to play
			</PlayerCount>
		);
	}

	renderPositionHeader(
		position: TPosition, 
		home_pos_player_ids: number[],
		away_pos_player_ids: number[]
	) {
		return (
			<PositionHeader>
				{this.renderPlayerCount(home_pos_player_ids, position)}
				<PositionTitle>{position.full_name}</PositionTitle>
				{this.renderPlayerCount(away_pos_player_ids, position)}
			</PositionHeader>
		);
	}

	isCaptainDNP(team: any) {
		const { players, round } = this.props;
		const captain_id = _.get(team, "lineup.captain");

		const captain = _.get(players, captain_id);
		if (captain) {
			// Can't be DNP if his team hasn't played yet
			const match = findMatch(_.get(round, "matches", []), captain.squad_id);
			const match_status = _.get(match, "status", "scheduled");
			if (match_status === "scheduled") {
				return false;
			}

			// Captain is DNP if his score is undefined
			return _.get(captain, ["stats", "scores", _.get(round, "id")]) === undefined;
		}

		return true;
	}

	renderField(is_unique: boolean) {
		const {
			home_team,
			away_team,
		} = this.props;

		// VCs are unique when one team's captain didn't play
		const is_vc_unique = this.isCaptainDNP(home_team) !== this.isCaptainDNP(away_team);
		
		const makeFieldArgs = team => ({
			players: getField(team),
			captain: _.get(team, "lineup.captain", 0),
			vice_captain: _.get(team, "lineup.vice_captain", 0),
			is_vc_unique
		});
		
		let { home, away } = getFields(
			makeFieldArgs(home_team),
			makeFieldArgs(away_team),
			is_unique
		);

		home = this.sortByMatch(home);
		away = this.sortByMatch(away);

		const num_rows = Math.max(_.size(home), _.size(away));

		return <React.Fragment>
			{this.renderPositionHeader(
				{
					full_name: is_unique ? "Unique Scorers" : "Matching Scorers"
				},
				home,
				away
			)}
			{_.times(num_rows, index =>
				this.renderPlayerRow(
					_.get(home, index),
					_.get(away, index)
				)
			)}
		</React.Fragment>;
	}
	renderBench(is_unique: boolean) {
		const {
			home_team,
			away_team,
		} = this.props;
		
		let { home, away } = getFields(
			{
				players: getBench(home_team),
				emergency: _.get(home_team, "lineup.bench.emergency"),
			},
			{
				players: getBench(away_team),
				emergency: _.get(home_team, "lineup.bench.emergency")
			},
			is_unique
		);

		home = this.sortByMatch(home);
		away = this.sortByMatch(away);

		const num_rows = Math.max(_.size(home), _.size(away));

		return <React.Fragment>
			{this.renderPositionHeader(
				{
					full_name: "Interchange"
				},
				home,
				away
			)}
			{_.times(num_rows, index =>
				this.renderPlayerRow(
					_.get(home, index),
					_.get(away, index)
				)
			)}
		</React.Fragment>;
	}

	render() {
		return (
			<Container>
				<PositionsPlayerList>
					{this.renderField(true)}
					{this.renderBench(true)}
					{this.renderField(false)}
					{this.renderBench(false)}
				</PositionsPlayerList>
			</Container>
		);
	}
}

export default UniqueComparisonTable; 