// @flow
import styled from "styled-components";

export const TeamViewSelectorContainer = styled.div`
	padding: 10px;
	
	div {
		display: flex;
		align-items: center;
		margin-bottom: 5px;
		
		span {
			color: #1D4073;
		}
	}
`;

export default TeamViewSelectorContainer;