import * as _ from "lodash";

export const isBothTrue = (bool1, bool2) => {
	return bool1 && bool2;
};

export const substituteIndexID = (index, int_index, substitute_id, id) => {
	return index === int_index ? substitute_id : id;
};


export const isEitherTrue = (bool1, bool2) => {
	return bool1 || bool2;
};
export const getPointsLabel = (
	isPlayingNow: boolean, 
	isMatchScheduled: boolean, 
	hasAssistantCoach: boolean
):string => {
	let label = "";
	if(isPlayingNow){
		label = "live";
	}
	else if (isMatchScheduled){
		label = hasAssistantCoach ? "projected":"";
	}
	else {
		label = "complete";
	}
	return label;
};

export const getPointsLastRound = (label, player, double_score?, sel_round, actual_round) => {
	const { stats } = player;
	const is_actual_selected = actual_round.id === sel_round.id;
	if(isBothTrue(is_actual_selected, actual_round.status === "scheduled")){
		let score = stats.points_last_round;
		return double_score ? 2*score : score;
	}
	if(label === "projected") {
		return _.get(stats, "proj_score", 0);
	}
	let points_last_round = _.get(stats, `scores[${sel_round.id}]`, 0);

	if(isBothTrue(player.is_match_scheduled, is_actual_selected)){
		return "-";
	}
	if(double_score){
		points_last_round = 2 * points_last_round;
	}
	return points_last_round;
};