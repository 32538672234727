// @flow
import { createReducer } from "redux-act";

const initialState = [
	{ key: "coaches-choice", value: "Coaches Choice" },
	{ key: "consistency", value: "Consistency (Fantasy Coach)" },
	{ key: "opposition", value: "Opposition (Fantasy Coach)" },
	{ key: "venues", value: "Venues (Fantasy Coach)" },
	{ key: "form", value: "Form (Fantasy Coach)" },
	{ key: "history", value: "Recent Scores" }
];

export const coachStatsList = createReducer({}, initialState);

export default coachStatsList;