// @flow
import * as React from "react";
// import * as _ from 'lodash';
import styled, { css } from "styled-components";
import Color from "color";

import { default_transition } from "../../assets/css/vars";
import colors from "../../assets/css/colors";

import getByeColor from "./colors";

const getHoverColor = ({ round, selected }) => Color(getByeColor(round))
	.mix(Color("white"), selected ? 0.2 : 0.95)
	.hex();

const ButtonWrapper = styled.button`
	outline: none;
	border-radius: ${({ is_classic }) => is_classic ? "5px" : "2px"};
	height: 30px;
	width: 30px;
	box-sizing: border-box;
	font-size: 13px;

	padding-top: ${({ is_classic }) => is_classic ? "4px" : "2px"};

	display: flex;
	align-items: center;
	justify-content: center;

	border: 1px solid  ${({ round, selected, is_classic }) => 
		(selected || is_classic) ? getByeColor(round) : "#CAD2D8"};
	color: ${({ round, selected, is_classic }) => 
		selected ? "white" : is_classic ? getByeColor(round) : "#CAD2D8"};
	background-color: ${({ round, selected }) => selected ? getByeColor(round) : "white"};

	user-select: none;

	&:disabled {
		border: none;
		background-color: white;
		color: ${colors.secondary.accentGrey};
		border-radius: 2px;
	}

	${default_transition};

	${({ clickable }) => clickable && css`
		&:not(:disabled) {
			cursor: pointer;

			&:hover {
				background-color: ${getHoverColor};
			}
		}
	`}
`;

type Props = {
	round: number,
	clickable?: boolean,
	selected?: boolean,
	toggleRound?: Function,
	disabled?: boolean,
	is_classic?: boolean
}
export const ByeButton = ({
	round,
	selected = false,
	clickable = false,
	toggleRound,
	disabled = false,
	is_classic = true
}: Props) => {
	return <ButtonWrapper 
		round={round}
		selected={selected}
		clickable={clickable}
		onClick={() => toggleRound && toggleRound(round)}
		disabled={disabled}
		is_classic={is_classic}
	>
		{round}
	</ButtonWrapper>;
};

export default ByeButton;