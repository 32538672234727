// @flow
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import colors from "../../assets/css/colors";
import { below } from "../../assets/css/media";

export const LinkStyled = styled(NavLink)`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 200px;
	height: 40px;
	line-height: .9;
	outline: none;
	border: none;
	background: ${colors.primary.accent};
	border: 1px solid ${colors.primary.accent};
	color: #ffffff;
	font-size: 12px;
	font-family: SourceSansPro;
	font-weight: 600;
	letter-spacing: 0.5px;
	cursor: pointer;
	border-radius: 3px;
	text-decoration: none;
	margin: 1em 0;
	${below.phone`
		width: 335px;
	`};
`;

export default LinkStyled;