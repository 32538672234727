// @flow
import React from "react";
import styled from "styled-components";
import * as _ from "lodash";

import type { TWinner } from "../../modules/types/cms";
import { PageSubTitle as PageSubTitleUnstyled } from "../../components";
import colors from "../../assets/css/colors";

const WinnerWrapper = styled.div`
	font-weight: 300;
	margin: 10px 0;
	color: ${colors.primary.primary};
	> span {
		font-weight: 500;
	}
`;
const WinnersWrapper = styled.div`
	margin-bottom: 30px;
`;
const PageSubTitle = styled(PageSubTitleUnstyled)`
	margin-top: -40px;
`;

type Props = {
	winners: TWinner[]
}

const displayWinner = true;

const renderWinner = (winner: TWinner) => {
	if (!winner) {
		return null;
	}
	return <WinnerWrapper>
		<span>Round {winner.round} Winner:</span> {winner.firstname} {winner.lastname}
	</WinnerWrapper>;
};

export const RoundWinners = ({ winners }: Props) => {
	if (displayWinner) {
		return <React.Fragment>
			<PageSubTitle>AFL Classic Round Winners</PageSubTitle>
			<WinnersWrapper>
				{_.map(winners, renderWinner)}
			</WinnersWrapper>
		</React.Fragment>;
	}
	else {
		return null;
	}
};

export default RoundWinners;