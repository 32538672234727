// @flow
import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";

import type {
	TRootStore,
	TSquad,
	TRound
} from "../../modules/types";

import * as selectors from "../../modules/selectors";
import { getClubLogoIndigenous } from "../../utils/clubLogos";
// import colors from '../../assets/css/colors';
import { Td } from "../TableX";
import byeLogo from "../../assets/images/bye-light.svg";

const StyledTd = styled(Td)`
	div{
		margin: 0 auto;
		width: 30px;
		vertical-align: middle;
		img {
			vertical-align: middle;
			width: 30px;
			height: 30px;
		}
	}	
`;

// const NextBadge = styled.p`
// 	display: inline-flex;
// 	flex-direction: row;
// 	justify-content: center;
// 	align-items: center;
// 	width: 40px;
// 	height: 16px;
// 	border-radius: 1px;
// 	box-sizing: border-box;
// 	padding-top: 1px;
// 	background: ${colors.form.base};
// 	color: ${colors.secondary.accentGrey};
// 	font-size: 12px;
// 	font-family: TitilliumUpright;
// 	font-weight: 700;
// 	margin-top: ${({ is_compressed }) => is_compressed ? `0`: `5px`};
// 	span {
// 		display: block;
// 		font-size: 10px;
// 		color: ${colors.secondary.paleGrey};
// 		&:after {
// 			content: "\00a0\00a0";
// 			font-size: 0.8em;
// 		}
// 	}
// `;

// const getNextRound = (actual_round, rounds) => {
// 	const next_round_id = parseInt(actual_round.id, 10) + 1;

// 	return _.find(rounds, { id: next_round_id }) || null;
// };


// const getExtendedMatch = (squad_id, matches, squads) => {
// 	// get the match
// 	const match = _.find(matches, match =>
// 		match.away_squad_id === squad_id || match.home_squad_id === squad_id);

// 	if (match) {
// 		const is_home_team = match.home_squad_id === squad_id;
// 		const opponent_id = is_home_team ? match.away_squad_id : match.home_squad_id;

// 		return {
// 			...match,
// 			is_home_team,
// 			opponent: _.find(squads, s => s.id === opponent_id) || {}
// 		};
// 	}
// };

// const nextMatchIsBye = (squad_id, rounds, actual_round) => {
// 	if(actual_round.status === 'scheduled' && actual_round.bye_squads.includes(squad_id)) {
// 		return true;
// 	}

// 	const next_round = getNextRound(actual_round, rounds);
// 	return next_round && next_round.bye_squads.includes(squad_id);
// };

type Props = {
	is_compressed: boolean,
	squad_id: number,
	actual_round: TRound,
	squads: Array<TSquad>,
	rounds: Array<TRound>,
};

const Next = ({
	is_compressed,
	squad_id,
	actual_round,
	squads,
	rounds = []
}:Props) => {
	const imgSrc = squad_id ? getClubLogoIndigenous(actual_round.id, squad_id) : byeLogo;
	// COMMENTED OUT TO MATCH NEW DESIGNS BUT WILL KEEP CODE IN CASE BADGE IS INCLUDED AGAIN
	// const extended_match = getExtendedMatch(squad_id, actual_round.matches, squads);
	// // let prefix = _.get(extended_match, 'is_home_team') ? 'V': '@';
	// // let team_abbr = _.get(extended_match, ['opponent', 'short_name'], '');
	// const status = _.get(extended_match, 'status');
	// // if(nextMatchIsBye(squad_id, rounds, actual_round)) {
	// // 	prefix = '-';
	// // 	team_abbr = 'BYE';
	// // }

	// if(!_.isEmpty(extended_match) && status !== 'scheduled') {
	// 	const next_round = getNextRound(actual_round, rounds);
	// 	if(next_round) {
	// 		const extended_next_match = getExtendedMatch(squad_id, next_round.matches, squads);
	// 		prefix = _.get(extended_next_match, 'is_home_team') ? 'V': '@';
	// 		team_abbr = _.get(extended_next_match, ['opponent', 'short_name'], '');
	// 	}
	// 	else {
	// 		prefix = '';
	// 		team_abbr = '';
	// 	}
	// }
	return (
		<StyledTd className="td-next">
			{!is_compressed && (
				<div>
					<img src={imgSrc} alt="AFL club logo" />
				</div>
			)}
		</StyledTd>
	);
};

const mapStateToProps = (state: TRootStore, props) => ({
	squads: selectors.squads.getSquads(state),
	rounds: selectors.rounds.getRoundsWithMatches(state),
	actual_round: selectors.rounds.getActualRound(state),
});

export const TdNext = connect(mapStateToProps)(Next);

export default TdNext;