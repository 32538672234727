// @flow
import * as React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { below } from "../../assets/css/media";
import { dataLayer } from "../../utils/GTM";
import colors from "../../assets/css/colors";
import { header_height } from "../../assets/css/vars";
import SwitchLeagues from "./SwitchLeagues";


const Container = styled.div`
	color: ${colors.primary.dark};
	background: #fff;
	width: 370px;
	box-sizing: border-box;
	padding: 42px 30px;
	min-height: 230px;
	box-shadow: 0 5px 5px 0 rgba(0,0,0,0.25);
	position: absolute;
	top: ${header_height}px;
	left: 0;
	pointer-events: none;
	visibility: hidden;
	opacity: 0;
	transform: translateY(-5px);
	transition: all 0.2s linear;

	*:hover > & {
		opacity: 1;
		visibility: visible;
		pointer-events: auto;
		transform: translateY(0);
	}

	/* Make sure subnav doesn't break the width of the screen */
	@media screen and (max-width: 840px) and (min-width: 800px) {
		width: 300px;
	}
	@media screen and (max-width: 800px) and (min-width: 768px) {
		width: 270px;
	}

	${props => props.sub_align === "right" && `
		left: auto;
		right: 0;
		text-align: right;
	`};

	${below.tablet`
		position: relative;
		opacity: 1;
		visibility: visible;
		pointer-events: auto;
		transform: translateY(0);
		top: auto;
		left: auto;
		min-height: auto;
		box-shadow: unset;
		width: 100%;
		z-index: 150;
		max-height: 0;
		padding: 0;
		overflow: hidden;
		transition: all 0.3s linear;

		input[type="checkbox"]:checked ~ & {
			max-height: 600px;
			overflow-x: hidden;
			overflow-y: auto;
		}

		${props => props.sub_align === "right" && `
			text-align: left;
		`};

	`};
`;

const Title = styled.h5`
	font-size: 18px;
	font-family: TitilliumUpright;
	border-bottom: 1px solid currentColor;
	padding-bottom: 0.5em;

	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;

	${below.tablet`
		display: none;
	`};
`;

const List = styled.ul`
	margin: 24px 0 0;
	font-family: SourceSansPro;
	${below.tablet`
		margin-top: 0;
		> li {
			height: 50px;
		}
		> li:last-child {
			padding-bottom: 10px;
		}
	`};
`;

const StyledLink = styled(NavLink)`
	color: #2E3134;
	font-size: 14px;
	line-height: 1.7;
	text-decoration: none;
	&:hover {
		text-decoration: underline;
	}
	&.active {
		font-weight: 700;
	}
	${below.tablet`
		height: 50px;
		line-height: 50px;
		padding-left: 30px;
		display: block;
		box-sizing: border-box;
	`};
`;
const StyledFakeLink = styled.button`
	background-color: transparent;
	padding: 0;
	border: none;
	color: #2E3134;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	text-decoration: none;
	white-space: nowrap;
	font-size: 14px;
	font-family: SourceSansPro;
	font-weight: 400;
	letter-spacing: 0;
	cursor: pointer;
	:hover{
		text-decoration: underline;
	}
`;

const StyledExternalLink = styled.button`
	background-color: transparent;
	line-height: 1.7;
	padding: 0;
	border: none;
	color: #2E3134;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	text-decoration: none;
	white-space: nowrap;
	font-size: 14px;
	font-family: SourceSansPro;
	font-weight: 400;
	letter-spacing: 0;
	cursor: pointer;
	
	${below.phone`
		padding-left: 30px;
		
	`}
`;

const ExternalLi = styled.li`	
	${below.phone`
		display: flex;
		
	`}
`;

const NewBadge = styled.div`
	font-size: 12px;
	font-family: TitilliumUpright;
	font-weight: 400;
	color: white;
	background: red;
	width: 40px;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 10px;
	padding-top: 2px;
	border-radius: 2px;
	text-decoration: none;
`;



const StyledLi = styled.li`
	height: 23px;
	display: flex;
	align-items: center;
`;
type Props = {
	closeSubModal: Function,
	openSubModal: Function,
	sub_title: string,
	sub_align?: 'right' | 'left',
	show_league_switcher?: boolean,
	sub_items: Array<{
		to: string,
		title: string,
		external?: boolean;
		show_new?: boolean;
	}>,
}


const NavItem = ({ sub_items, sub_title, sub_align, 
	show_league_switcher, openSubModal }: Props) => (
	<Container sub_align={sub_align}>
		{show_league_switcher &&
			<React.Fragment>
				<Title>
				Switch Leagues
				</Title>
				<SwitchLeagues />
			</React.Fragment>
		}
		<Title>
			{sub_title}
		</Title>
		{sub_items &&
			<List>
				{sub_items.map(item => {
					const handleAFLWClick = (link: string) => {
						dataLayer({
							'event': 'navigate to aflw'
						});
						window.open(link, "_blank");
					};
					if(item.to === "prompt-subscription-modal"){
						return(
							<StyledLi>
								<StyledFakeLink onClick={() => openSubModal()}>
									{item.title}
								</StyledFakeLink>
							</StyledLi>
							
						);
					}
					if(item.external){
				
						return <ExternalLi key={item.to + item.title}>
							<StyledExternalLink 
								onClick={() => handleAFLWClick(item.to)}
								aria-label={`Click ${item.to}`}
								id={"aflw_link"}
								name={`Click ${item.to}`}
							> 
								{item.title} {item.show_new && <NewBadge>NEW</NewBadge>}
							</StyledExternalLink>
						</ExternalLi>;
					}
					return(
						<li key={item.to + item.title}>
							<StyledLink to={item.to} title={item.title}>{item.title}</StyledLink>
						</li>
					);
					
				})}
			</List>
		}
	</Container>
);



export default NavItem;