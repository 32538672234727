// @flow
import React, {useState} from "react";
import queryString from "query-string";
import { NavLink, Redirect, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Landing } from "../../../components/Landing";
import { Exist, InputItem, Preloader } from "../../../components";
import { changePassword} from "../../../modules/actions";
import {
	// getAfliDIdentityToken,
	isChangePasswordError,
	isChangePasswordPending,
	isChangePasswordSuccess,
} from "../../../modules/selectors";
import { isAuthenticated } from "../../../utils/auth";
import { SEASON_YEAR } from "../../../modules/constants";
import {
	AFLiDButonWrap,
	AFLIDButtonDark,
	AFLiDModalContentWrapper,
	ButtonAsLink,
	ErrorContent,
	InputWrap,
	ModalContent,
	ModalInputWrap,
	ModalWrap,
	PageTitleStyled
} from "./../afliDModalStyles";

const AFLiDButonWrapFullWidth = styled(AFLiDButonWrap)`
    width: 100%:
    button {
        width: 200px;
    }
`;

const LinkHomeWrap = styled(ModalContent)`
    a {
        color: #262424;
    }
`;

function AfliDChangePassword () {
	const dispatch = useDispatch();
	const history = useHistory();
	const { search } = history.location;
	const { token } = queryString.parse(search);
	// const afliDIdentityToken = useSelector(getAfliDIdentityToken);
	const changePasswordSuccess = useSelector(isChangePasswordSuccess);	
	const changePasswordPending = useSelector(isChangePasswordPending);	
	const changePasswordError = useSelector(isChangePasswordError);
	const [newPassword,setNewPassword] = useState("");
	const [confirmPassword,setConfrimPassword] = useState("");
	const handleNewPassword = event => {
		setNewPassword(event.target.value);
	};
	const handleConfirmPassword = event => {
		setConfrimPassword(event.target.value);
	};
	const handleConfirmPasswordValidation = event => {
		if(newPassword !== event.target.value) {
			event.target.setCustomValidity("Passwords do not match"); 
		}
		else {
			event.target.setCustomValidity(""); 
		}
	};

	const forgetOldPassword = event => {
		event.preventDefault();
		history.push("/login/forgot-password");
	};

	const sendForgetPasswordReq = event => {
		event.preventDefault();
		dispatch(
			changePassword({
				password:newPassword,
				token
			})	
		);
		localStorage.setItem('change_password_flow', "true");
	};

	/*
	useEffect(() => {
		if(afliDIdentityToken !== "" && changePasswordSuccess){
			history.push("/login/mini-rego");
		}
	}, [afliDIdentityToken, changePasswordSuccess, history]);
    */

	// if(isAuthenticated() && changePasswordSuccess){
	// 	return <Redirect to="/login/mini-rego" />;
	// }
	if(isAuthenticated() || !token){
		return <Redirect to="/" />;
	}

	// 1 number, 1 special char at least 8
	// const patternCheck = "/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,50}$/"
	return <Landing> 
		<AFLiDModalContentWrapper>
			<PageTitleStyled>
                Reset your password
			</PageTitleStyled>
			<ModalWrap>
				<ModalContent>
                    Please enter a new password for your AFL Fantasy account. <br />
                    This password should include at least eight characters,{" "}
                    a mixture of upper and lower case letters,{" "}
                    at least one special character and at least one number.
				</ModalContent>
				<form onSubmit={sendForgetPasswordReq}>
					<ModalInputWrap>
						<InputWrap>
							<InputItem
								name="password"
								placeholder="Enter Password"
								type="password"
								required
								onChange={handleNewPassword}
								value={newPassword}
							/>
						</InputWrap>
					</ModalInputWrap>
					<ModalInputWrap>
						<InputWrap>
							<InputItem
								name="password"
								placeholder="Confirm Password"
								type="password"
								required
								onChange={handleConfirmPassword}
								onBlur={handleConfirmPasswordValidation}
								value={confirmPassword}
							/>
						</InputWrap>
					</ModalInputWrap>
					<Exist when={!changePasswordSuccess}>
						<AFLiDButonWrapFullWidth>
							<AFLIDButtonDark>Confirm New Password</AFLIDButtonDark>
						</AFLiDButonWrapFullWidth>
					</Exist>
					<Exist when={changePasswordPending}>
						<Preloader />
					</Exist>
				</form>
				<Exist when={changePasswordSuccess}>
					<br />
					<LinkHomeWrap>
                            Password updated, please{" "}
						<NavLink to="/">click here</NavLink>{" "}
                        to continue your registration for season {SEASON_YEAR}.
					</LinkHomeWrap>
					<br />
				</Exist>
				<Exist when={!["",null].includes(changePasswordError)}>
					<br />
					<ErrorContent>{changePasswordError}</ErrorContent>
					<br />
				</Exist>
				<Exist when={JSON.stringify(changePasswordError).includes("forgot password link")}>
					<LinkHomeWrap>
						<ButtonAsLink 
							onClick={forgetOldPassword}
						>
                            Click to generate new password reset email.
						</ButtonAsLink>
					</LinkHomeWrap>
				</Exist>
			</ModalWrap>
		</AFLiDModalContentWrapper>
	</Landing>;
}

export default AfliDChangePassword;