// @flow
import { createReducer } from "redux-act";

const initialState = [
	{ key: "playing", value: "Playing" },
	{ key: "not-playing", value: "Not Playing" },
	{ key: "injured", value: "Injured" },
	{ key: "uncertain", value: "Uncertain" },
	{ key: "is-bye", value: "On Bye" },
];

export const playerStatuses = createReducer({}, initialState);

export default playerStatuses;