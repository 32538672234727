// @flow
import _ from "lodash";
import * as React from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import smoothscroll from "smoothscroll-polyfill";
import * as actions from "../../modules/actions";
import * as selectors from "../../modules/selectors";
import {
	Tooltip,
	Widget,
	WidgetBody,
	WidgetHeader,
	WidgetHeaderTitle,
} from "../../components";
import ArrowLeft from "../../components/Icons/ArrowLeft";
import ArrowRight from "../../components/Icons/ArrowRight";
import ArrowDownSmall from "../../assets/images/ArrowDownSmall.svg";
import type { TRootStore } from "../../modules/types/store";
import type { TPlayersById } from "../../modules/types/player";
import type { TPLeague, TDraftTeamsById } from "../../modules/types";
import { chunkArray, getIsRegenerated } from "../../helpers";
import { below } from "../../assets/css/media";
import Keeper from "../Icons/Keeper";
import { Exist } from "../Exist";
import { isMobile } from "../../utils";

smoothscroll.polyfill();

const StyledWidgetHeaderTitle = styled(WidgetHeaderTitle)`
	width: 100%;
	justify-content: space-between;
`;
const StyledWidgetHeader = styled(WidgetHeader)`
	border-radius: 0px;
`;

const Error = styled.div`
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-transform: uppercase;
`;

const Controls = styled.div`
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid #fff;
	padding: 10px;
	background-color: #F0F3F7;
	select {
		width: 190px;
		height: 40px;
		border: 1px solid #CAD2D8;
		padding-left: 10px;
		color: #7F8A90;
		font-family: SourceSansPro;
		font-size: 12px;
		font-weight: 600;
		-webkit-appearance: none;
		-moz-appearance: none;
		-o-appearance: none;
		appearance: none;
		background-image: url(${ArrowDownSmall});
		background-position: 98% center;
		background-repeat: no-repeat;
		background-size: 15px;
		outline: none;
		option {
			width: 190px;
			height: 40px;
			color: #7F8A90;
			font-family: SourceSansPro;
			font-size: 12px;
		}
	}
`;




const MoveLeft = styled.a`
	cursor: pointer;
	svg{
		height: 20px;
		width: 20px;
		g{
			path{
				fill: #1D4073
			}
		}
	}
`;

const MoveRight = styled.a`
	cursor: pointer;
	svg{
		height: 20px;
		width: 20px;
		g{
			path{
				fill: #1D4073
			}
		}
	}
`;

const ControlRoundItem = ({ round_id, current_round, ...rest }) => (
	<option className={current_round === round_id ? "active" : ""} {...rest}>
		{ round_id }
	</option>
);

const getTurnItemClassName = ({ league, turn, current_pick }) => {
	const class_names = ["turn-item"];

	if(parseInt(league.team_id, 10) === parseInt(turn.id, 10)) {
		class_names.push("is-me");
	}

	if(parseInt(current_pick, 10) > parseInt(turn.pick, 10)) {
		class_names.push("was-picked");
	}

	if(current_pick === turn.pick) {
		class_names.push("current-pick");
	}

	return class_names.join(" ");
};

const TurnItemContainer = styled.div`
	background: #F8F8FA;
	border-radius: 3px;
	margin-bottom: 1px;
	display: flex;
	align-items: center;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	color: #1D4073;
	font-size: 12px;
	font-weight: 700;
	height: 20px;

	.widget-turn-number {
		color: #1D4073;
		padding: 0px;
		margin-left: 5px;
		margin-right: 10px;
		text-align: center;
		position: relative;
		font-family: SourceSansPro;
		font-weight: 600;
		width: 10px;
		
	}

	.turn-team-name {
		margin-right: 5px;
		font-family: SourceSansPro;
		font-weight: 700;
	}
	.keeper-label {
		margin-left: 5px;
		font-weight: 400;
		font-family: SourceSansPro;
		svg{
			height: 12px;
			width: 12px;
		}
	}

	.turn-user-name {
		font-weight: 400;
		font-family: SourceSansPro;
	}

	&.is-me {
		color: #198DE1;

		.turn-number {
			color: #198DE1;
		}
	}

	.turn-to-text {
		font-weight: 400;
		color: #1D4073;
		margin-top: 2px;
		margin-right: 5px;
		&.keeper{
			margin-left: 0px;
		}
	}

	&.was-picked {
		background: #F8F8FA;
		color: #1D4073;

		.turn-number {
			background: #CAD2D8;

		}

		
		.turn-to-text{
			margin-left: 5px;
		}
		.turn-team-name {
			margin: 0;
		}

		.turn-user-name {
			font-weight: 700;
			text-overflow: ellipsis;
    		overflow: hidden;
		}
	}
`;

const ContentContainer = styled.div`
	border: solid #F0F3F7;
	border-width: 0 1px 1px 1px;
	border-radius: 0 0 3px 3px;
	background-color: #fff;
	.turn-item:first-child {
		margin-top: 1px;
	}
	.turn-item:last-child {
		margin-bottom: 0;
	}
`;


const getAltPickIndex = (roundIDNum, turnIndex, adjustedIndex, leagueSize) => {
	return roundIDNum === 1 ? turnIndex + adjustedIndex
		:((roundIDNum-1) * leagueSize) + (turnIndex + adjustedIndex);
};

const isKeeperPickData = (isKeeperandRegen, leagueKeeperPicks, isCurrPickKeeper) => {
	return isKeeperandRegen && !_.isEmpty(leagueKeeperPicks) && isCurrPickKeeper;
};

const getTeamNameContent = ({
	turn,
	current_pick,
	picks,
	players_by_id,
	turnIndex,
	current_round_id,
	leagueSize,
	league
}) => {
	const roundIDNum = Number(current_round_id.split(" ")[1]) || 1;
	const isRegenerated = getIsRegenerated(league.regenerated_from,league.uf_regenerated_from);
	const isKeeperandRegen = Boolean(league.keeper) && isRegenerated;
	const leagueKeeperPicks = _.get(league, "keeper_draft_picks", {});
	const adjustedIndex = 2;
	const altPickIndex = getAltPickIndex(roundIDNum, turnIndex, adjustedIndex, leagueSize);
	const isCurrPickKeeper = _.get(leagueKeeperPicks, `${altPickIndex - 1}`);

	
	if(isKeeperPickData(isKeeperandRegen, leagueKeeperPicks, isCurrPickKeeper)){
		const keeperPlayer = players_by_id[isCurrPickKeeper];
		const playerFirstName = _.get(keeperPlayer, "first_name", "...");
		const playerLastName = _.get(keeperPlayer, "last_name", "");
		const player_name = `${playerFirstName} ${playerLastName}`;
		// const isDraftScheduled = league.draft_status === "scheduled";
		return (
			<React.Fragment>
				{/* <Exist when={isDraftScheduled}>
					<div className="widget-turn-number">{ altPickIndex - 1 }</div>
				</Exist> */}
			
				<div className="turn-team-name">{ player_name }</div>
				<div className="turn-to-text keeper">to</div>
				<div className="turn-user-name">{ turn.name }</div>
				<div className='keeper-label'>
					<Keeper />
				</div>
			</React.Fragment>
		);
	}
	if(turn.pick < current_pick) {
		const player_id = _.get(picks, `[${turn.pick + 1}].picked_player_id`, 0);
		const player = _.get(players_by_id, player_id, {});
		const playerFirstName = _.get(player, "first_name", "...");
		const playerLastName = _.get(player, "last_name", "");
		const player_name = `${playerFirstName} ${playerLastName}`;

		return (
			<React.Fragment>
				<div className="turn-team-name">{ player_name }</div>
				<div className="turn-to-text">to</div>
				<div className="turn-user-name">{ turn.name }</div>
			</React.Fragment>
		);
	}

	/**
	 * AFD21-342 turn.pick is not defined, if using custom draft order
	 */

	if(!turn.pick) {
		/**
		 * + 2 here because pubnub seem to be 
		 * skipping first pick
		 */
		const adjustedIndex = 2;
		const altPickIndex = getAltPickIndex(roundIDNum, turnIndex, adjustedIndex,
			leagueSize);
		const altPlayerID = _.get(picks, `[${altPickIndex}].picked_player_id`, 0);
		const altPlayer = _.get(players_by_id, altPlayerID, {});
		const playerFirstName = _.get(altPlayer, "first_name", "...");
		const playerLastName = _.get(altPlayer, "last_name", "");
		const altPlayerName = `${playerFirstName} ${playerLastName}`;
		if(altPlayerID !== 0) {
			return(
				<React.Fragment>
					<div className="widget-turn-number">{ altPickIndex - 1 }</div>
					
					<div className="turn-team-name">{ altPlayerName }</div>
						
					
					<div className="turn-to-text">to</div>
						
					<div className="turn-user-name">{ turn.name }</div>
				</React.Fragment>
			);
		}
		return (
			<React.Fragment>
				<div className="widget-turn-number">{ altPickIndex - 1 }</div>
					
				<div className="turn-team-name">{ turn.name }</div>
						
				<div className="turn-user-name">{ turn.firstname }</div>
			</React.Fragment>
		);

	}

	return (
		<React.Fragment>
			<div className="turn-team-name">{ turn.name }</div>
			<div className="turn-user-name">{ turn.firstname }</div>
		</React.Fragment>
	);
};

const TurnItem = ({ turn, league, current_pick, 
	picks, players_by_id, turnIndex, current_round_id }) => (
	<TurnItemContainer className={getTurnItemClassName({ turn, league, current_pick })}>
		{turn.pick ? <div className="widget-turn-number">{ turn.pick }</div> : null}
		{ getTeamNameContent({ turn, current_pick, picks, players_by_id, 
			turnIndex, current_round_id, leagueSize: league.size, league }) }
	</TurnItemContainer>
);

const WidgetWrapper = styled(Widget)`
	margin-bottom: 10px;
	${below.phone`
		width: 100%;
		margin-top:10px;
	`}
`;

type Props = {
	showDraftOrder: typeof actions.leagueDraft.showDraftOrder,
	match: {
		params: {
			league_id: string
		}
	},
	league: TPLeague,
	error: string,
	teams_by_id: TDraftTeamsById,
	draft_order: Array<Array<Object>>,
	current_pick: number,
	picks: Array<Object>,
	players_by_id: TPlayersById,
}

type State = {
	widget_open: boolean,
	current_round_id: string,
}

class DraftOrderWidgetContainer extends React.Component<Props, State> {
	static defaultProps = {
		current_pick: 1
	};

	constructor(props, state) {
		super(props, state);

		_.bindAll(this, [
			"onToggle",
			"onChangeRound",
			"onSelectChangeRound"
		]);

		this.scrollLeft = this.onClickScrollControl.bind(this, -1);
		this.scrollRight = this.onClickScrollControl.bind(this, 1);
	}

	state = {
		widget_open: true,
		current_round_id: "Round 1",
	};

	componentDidMount() {
		const {
			match: { params: { league_id } },
			showDraftOrder,
			current_pick,
			league:{size},
		} = this.props;

		showDraftOrder({ league_id });
		const current_round_id = Math.ceil(current_pick / size);
		this.onChangeRound(`Round ${current_round_id}`);
	}

	componentDidUpdate(prevProps, prevState) {
		const pick_is_diff = !_.eq(this.props.current_pick, prevProps.current_pick),
			league_is_diff = !_.eq(this.props.league, prevProps.league);

		if (pick_is_diff || league_is_diff) {
			const { current_pick, league: { size } } = this.props;

			if(current_pick && size) {
				const current_round_id = Math.ceil(current_pick / size);
				const prev_round_id = Math.ceil(prevProps.current_pick / size);
				current_round_id !== prev_round_id 
					&& this.onChangeRound(`Round ${current_round_id}`);
			}
		}
	}

	scrollLeft: Function;
	scrollRight: Function;
	list = React.createRef();

	onToggle() {
		this.setState({ widget_open: !this.state.widget_open });
	}

	onChangeRound(id) {
		this.setState({ current_round_id: id });
	}
	onSelectChangeRound(e){
		this.setState({ current_round_id: e.target.value });
	}

	get turns() {
		const { current_round_id } = this.state;
		const id = Number(current_round_id.split(" ")[1]);
		const { draft_order } = this.props;
		return draft_order[id - 1] || [];
	}

	get content() {
		const { error } = this.props;

		if(error) {
			return <Error>{error}</Error>;
		}

		const turns = this.turns;
		

		if(_.isEmpty(turns)) {
			return <Error>Loading, please wait...</Error>;
		}

		const { league, current_pick, picks, players_by_id } = this.props;
		const { current_round_id } = this.state;
		return turns
			.filter(item => item !== undefined)
			.map((turn,turnIndex) => {
				return (
					<TurnItem
						turnIndex={turnIndex}
						current_round_id={current_round_id}
						key={turn.pick}
						turn={turn}
						league={league}
						current_pick={current_pick}
						picks={_.keyBy(picks, "pick")}
						players_by_id={players_by_id}
					/>
				);
			});
	}

	onClickScrollControl(direction){
		const current_round = Number(this.state.current_round_id.split(" ")[1]);
		if(current_round === 1 && direction === -1){
			return null;
		}
		if(current_round === this.props.draft_order.length && direction === 1){
			return null;
		}
		const new_round = current_round + direction;

		this.setState({ current_round_id: `Round ${new_round}` });
	}


	get controls() {
		const { draft_order, error } = this.props;

		if(error) {
			return null;
		}

		const round_ids = draft_order.map((round, index) => `Round ${index + 1}`);
		const rounds_size = _.size(round_ids);

		if(!rounds_size) {
			return null;
		}

		const { current_round_id } = this.state;
		const current_round_value = Number(current_round_id.split(" ")[1]);
		const is_mobile = isMobile();
		return (
			<Controls>
				<Exist when={!is_mobile}>
					<select ref={this.list} 
						onChange={this.onSelectChangeRound} 
						value={current_round_id} >
						{round_ids.map(id => (
							<ControlRoundItem
								key={id}
								round_id={id}
								id={`pick-item-control-${id}`}
								current_round={current_round_id}
								value={id}
							> Round {id}</ControlRoundItem>)
						)}
					</select>
					<MoveLeft
						is_locked={_.eq(current_round_value, 1)}
						onClick={this.scrollLeft}
					><ArrowLeft /></MoveLeft>
					<Exist when={current_round_value!==draft_order.length}>
						<MoveRight
							is_locked={_.eq(current_round_value, rounds_size)}
							onClick={this.scrollRight}
						><ArrowRight /></MoveRight>
					</Exist>
				</Exist>
				<Exist when={is_mobile}>
					<MoveLeft
						is_locked={_.eq(current_round_value, 1)}
						onClick={this.scrollLeft}
					><ArrowLeft /></MoveLeft>
					<select ref={this.list} 
						onChange={this.onSelectChangeRound} 
						value={current_round_id} >
						{round_ids.map(id => (
							<ControlRoundItem
								key={id}
								round_id={id}
								id={`pick-item-control-${id}`}
								current_round={current_round_id}
								value={id}
							> Round {id}</ControlRoundItem>)
						)}
					</select>
					
					<Exist when={current_round_value!==draft_order.length}>
						<MoveRight
							is_locked={_.eq(current_round_value, rounds_size)}
							onClick={this.scrollRight}
						><ArrowRight /></MoveRight>
					</Exist>
				</Exist>
				
				
			</Controls>
		);
	}

	render() {
		const msg = "Below displays the complete " +
			"draft order, pick by pick the entire draft. " +
			"Please refer to this to see where you will " +
			"be drafting for each specific round";
		const { league } = this.props;
		const { widget_open } = this.state;
		if(league.draft_type === "commissioner"){
			return null;
		}
		return (
			<WidgetWrapper>
				<StyledWidgetHeader>
					<StyledWidgetHeaderTitle>
						<span>Draft order</span>
						<Tooltip show_on_left description={msg} />
					</StyledWidgetHeaderTitle>
				</StyledWidgetHeader>
				<WidgetBody open={widget_open}>
					{ this.controls }
					<ContentContainer>
						{ this.content }
					</ContentContainer>
				</WidgetBody>
			</WidgetWrapper>
		);
	}
}

const mapDispatchToProps = ({
	showDraftOrder: actions.leagueDraft.showDraftOrder,
});

const mapStateToProps = (state: TRootStore, props) => {
	const league = selectors.leagueDraft.getLeague(state, props);
	const numTeams = league.num_teams || 0;
	const teams_by_id = selectors.getTeamsById(state);
	const full_draft_order = _.get(league, 'custom_full_draft_order', []);
	const draft_order = full_draft_order.length ? chunkArray(
		league.custom_full_draft_order, 
		numTeams
	).map(rounds => rounds.map(id => teams_by_id[id])):
		selectors.getChunkedDraftOrder(state, props);
	return {
		league,
		error: _.get(state, "leagues.show_order.error", ""),
		teams_by_id,
		draft_order,
		players_by_id: state.players.by_id,
	};
};

export const DraftOrderWidget = withRouter(connect(
	mapStateToProps,
	mapDispatchToProps,
)(DraftOrderWidgetContainer));

export default DraftOrderWidget;