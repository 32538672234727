// @flow
import styled from "styled-components";
import { below } from "../../assets/css/media";
import colors from "../../assets/css/colors";

export const ModalInnerTitle = styled.h2`
	text-align: center;
	margin: .25em auto .8em;
	font-size: 28px;
	font-weight: bold;
	line-height: 1.2;
	color: ${colors.primary.primary};
	max-width: 80%;

	${below.tablet`
		font-size: 22px;
	`}
`;

export default ModalInnerTitle;