/* eslint max-len: 0 */
// @flow
import React from "react";
import SvgIconContainer from "../SvgIconContainer";

export default (
	{
		unit = "em",
		...rest
	}: Object = {}) => (
	<SvgIconContainer viewBox="0 0 24 24" {...rest} unit={unit}>
		<title>Clock</title>
		<path d="M12,22 C6.4771525,22 2,17.5228475 2,12 C2,6.4771525 6.4771525,2 12,2 C17.5228475,2
			22,6.4771525 22,12 C22,17.5228475 17.5228475,22 12,22 Z M12,20 C16.418278,20 20,16.418278
			20,12 C20,7.581722 16.418278,4 12,4 C7.581722,4 4,7.581722 4,12 C4,16.418278 7.581722,20
			12,20 Z M11.4059316,11.6821851 L15.3422857,7.15392762 L16.4743501,8.13801616
			L11.5539074,13.798338 L7.0256499,9.86198384 L8.00973844,8.72991947 L11.4059316,11.6821851 Z"
		/>
	</SvgIconContainer >
);