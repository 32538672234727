// @flow
import React from "react";
import * as _ from "lodash";
import styled  from "styled-components";
import ordinal from "ordinal";
import type { 
	TDraftLineup, 
	TDraftUserTeam, 
	TPlayersArrayWithFixtureById,
	TRound,
	TPLeague
} from "../../../../modules/types";
import { below } from "../../../../assets/css/media";
import colors from "../../../../assets/css/colors";
import { getTeamProjectedScore, getTeamScoreFromLadder } from "../../../../helpers/team";

const DEFAULT_VAL = "- -";

const StatsStack = styled.div`
	position: relative;
	padding: 0 20px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	align-content: flex-start;
	height: auto;
	text-align: center;
	${below.phone`
		padding: 0px;
	`}
`;

const StatsLabel = styled.h3`
	color: ${({ is_coach }) => is_coach ? colors.coach : colors.primary.primary};
	font-size: 12px;
	line-height: 15px;
	margin-bottom: 10px;
	font-weight: 400;
	white-space: nowrap;
`;

const StatsValue = styled.h2`
	color: ${({ is_coach }) => is_coach ? colors.coach : colors.primary.primary };
	font-size: 12px;
	font-weight: 400;
`;

const GameBarWrapper = styled.div`
	background: #F8F8FA;
	box-sizing: border-box;
	@supports (height: fit-content) {
		height: fit-content;
	}

	> div {
		flex: 1 1 0px;
	}

	${below.desktop`
		height: auto;
		width: 100%;
	`}

	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
`;

type Props = {
	lineup: TDraftLineup,
	players_by_id: TPlayersArrayWithFixtureById,
	field_stat_value: string,
	changeFieldStat: Function,
	league: TPLeague,
	team: TDraftUserTeam,
	ladder_teams: Array<TDraftUserTeam>,
	selected_round: TRound,
	last_round: TRound,
	has_assistant_coach: boolean,
	is_static: boolean,
	is_current: boolean,
	bye_rounds: number[],
	bye_detector_selected: number[],
	bye_detector_active: boolean,
	setByeDetectorActive: Function,
	toggleByeDetectorRound: Function
}

type State = {};

export class TeamGameBar extends React.PureComponent<Props, State> {
	static defaultProps = {
		is_static: false,
		is_current: true
	};

	renderLeagueRank() {
		const { ladder_teams, team } = this.props;
		if (!team || !ladder_teams) {
			return null;
		}

		const ladderTeam = ladder_teams[_.findIndex(ladder_teams, t => t.id === team.id)];
		const rank = 1 + _.findIndex(ladder_teams, t => t.id === team.id);
		const record = ladderTeam ? `(${ladderTeam.win}-${ladderTeam.draw}-${ladderTeam.loss})`:"";
		return <StatsStack>
			<StatsLabel>League Ranking</StatsLabel>
			<StatsValue>{`${ordinal(rank)} ${record}`}</StatsValue>
		</StatsStack>;
	}

	renderRoundScore() {
		const { team, selected_round, players_by_id } = this.props;
		const round_id = _.get(selected_round, "id");
		const is_active_match = _.get(selected_round, "status") === "active";

		const score = getTeamScoreFromLadder({
			round: selected_round,
			team,
			players_by_id,
			is_active_match,
		});
		return <StatsStack>
			<StatsLabel>Round {round_id} Score</StatsLabel>
			<StatsValue>{score}</StatsValue>
		</StatsStack>;
	}

	getProjectedScore() {
		const { lineup, players_by_id, league, selected_round, has_assistant_coach } = this.props;
		if (!has_assistant_coach) {
			return 0;
		}
		return getTeamProjectedScore(
			lineup, 
			players_by_id, 
			league, 
			selected_round,
			false
		);
	}

	renderProjectedScore() {
		const { selected_round } = this.props;
		const round_id = _.get(selected_round, "id");
		const proj_score = this.getProjectedScore();
		return <StatsStack>
			<StatsLabel is_coach>{`Round ${round_id} Projection`}</StatsLabel>
			<StatsValue is_coach>{proj_score || DEFAULT_VAL}</StatsValue>
		</StatsStack>;
	}

	render() {
		return <GameBarWrapper>
			{ this.renderLeagueRank() }
			{ this.renderRoundScore() }
			{ this.renderProjectedScore() }
		</GameBarWrapper>;
	}
}

export default TeamGameBar;