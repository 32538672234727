// @flow
import * as _ from "lodash";
import { convertLineupForBE } from "../../modules/sagas/teamsClassic";
import type {TTeam} from "../../modules/types";

const findEmgPos = (emg_in, p_id) => {
	const key = _.findKey(emg_in, i => i === p_id);
	return key !== undefined ? parseInt(key, 10) : undefined;
};

const setPositionEmergency = (
	emergency_in,
	player_id,
	position_id
) => {
	let emg_out = emergency_in;
	const emg_pos = findEmgPos(emergency_in, player_id);
	if (emg_pos) {
		emg_out = removePlayerEmergency(emergency_in, player_id);
		if (emg_pos === position_id) {
			return emg_out;
		}
	}
	return { ...emg_out, [position_id]: player_id };
};

const removePlayerEmergency = (
	emergency_in,
	player_id
) => {
	const emg_pos = findEmgPos(emergency_in, player_id);
	if (emg_pos) {
		return { ...emergency_in, [emg_pos]: 0 };
	}
	return emergency_in;
};

export class LineupSetter {
	static setCaptain({
		event: { currentTarget }, 
		team, 
		updateMyTeam, 
		checkIsPlayerChangeAvailable,
		is_draft,
	}: Object) {
		const { id, locked } = currentTarget.dataset;
		if (locked === "true") {
			return null;
		}

		LineupSetter.handleLeader(
			{
				selected_leader: "captain",
				opposite_leader: "vice_captain",
				player_id: parseInt(id, 10),
			},
			team,
			updateMyTeam,
			checkIsPlayerChangeAvailable,
			is_draft
		);
	}

	static setViceCaptain({
		event: { currentTarget }, 
		team, 
		updateMyTeam, 
		checkIsPlayerChangeAvailable,
		is_draft,
	}: Object) {
		const { id, locked } = currentTarget.dataset;
		if (locked === "true") {
			return null;
		}
		LineupSetter.handleLeader(
			{
				selected_leader: "vice_captain",
				opposite_leader: "captain",
				player_id: parseInt(id, 10)
			},
			team,
			updateMyTeam,
			checkIsPlayerChangeAvailable,
			is_draft
		);
	}

	static setEmergencyDraft({
		player_id, 
		position,
		team, 
		lineup,
		updateMyTeam, 
		checkIsPlayerChangeAvailable,
		player
	}: Object) {
		const { emergency } = lineup;
		// const position = pos_string ? parseInt(pos_string, 10) : player.positions[0];

		if (!checkIsPlayerChangeAvailable(player_id)) {
			return;
		}

		const new_emergency = setPositionEmergency(emergency, player_id, position);

		updateMyTeam({
			id: team.id,
			old_lineup: {
				...lineup
			},
			lineup: {
				...lineup,
				emergency: new_emergency,
			}
		});
	}

	static setUtilityEmergency({
		is_utility, is_this_is_position, position_id
	}: Object, lineup: Object) {
		const is_utility_boolean = JSON.parse(is_utility);

		if (is_utility_boolean) {
			const utility_id = lineup.bench.utility.id;

			const utility_position = _.get(lineup, "bench.utility.position", 0);

			if (parseInt(utility_position, 0) !== parseInt(position_id, 0)) {
				lineup.bench.utility.position = position_id;
				const utility_key_in_emergency = lineup.bench.emergency.indexOf(utility_id);
				lineup.bench.emergency[utility_key_in_emergency] = 0;
			}
		}

		return false;
	}

	static setEmergencyClassic({
		event: { currentTarget }, 
		team, 
		updateMyTeam, 
		checkIsPlayerChangeAvailable,
	}: Object) {
		const { id } = currentTarget.dataset;
		const { lineup: current_lineup } = team;

		LineupSetter.setUtilityEmergency(currentTarget.dataset, current_lineup);

		// Update lineup to valid format for query to BE and define formation
		const { lineup, formation } = convertLineupForBE(current_lineup);

		const { bench: { emergency } } = lineup;
		const zero_index = emergency.indexOf(0);
		const int_id = parseInt(id, 10);
		const id_index = emergency.indexOf(int_id);
		let new_emergency = [ ...emergency ];
		const available_ids = _.map(new_emergency, checkIsPlayerChangeAvailable);
		const true_index = _.lastIndexOf(available_ids,true);

		if (!checkIsPlayerChangeAvailable(int_id)) {
			return null;
		}
		if (~id_index) {
			new_emergency[id_index] = 0;
		}
		else if (~zero_index) {
			new_emergency[zero_index] = int_id;
		}
		else {
			new_emergency[true_index] = int_id;
		}

		if (~zero_index || ~id_index || ~true_index) {
			updateMyTeam({
				old_lineup: {
					...lineup
				},
				lineup: {
					...lineup,
					bench: {
						...lineup.bench,
						emergency: new_emergency
					}
				},
				formation
			});
		}
	}

	static handleLeader(
		{ selected_leader, opposite_leader, player_id }: Object,
		team: TTeam, 
		updateMyTeam: Function, 
		checkIsPlayerChangeAvailable: Function,
		is_draft: boolean,
	) {
		const { lineup: current_lineup } = team;
		const captain = _.get(current_lineup, 'captain');
		const vice_captain = _.get(current_lineup, 'vice_captain');

		// Update lineup to valid format for query to BE and define formation
		const { lineup, formation } = convertLineupForBE(current_lineup);

		let selected_leader_id = selected_leader === "captain" ? captain : vice_captain;
		let opposite_leader_id = opposite_leader === "captain" ? captain : vice_captain;
		if (!checkIsPlayerChangeAvailable(player_id)) {
			return null;
		}

		if (opposite_leader_id === player_id) {
			opposite_leader_id = selected_leader_id || 0;
		}

		updateMyTeam({
			old_lineup: {
				...lineup
			},
			lineup: {
				...lineup,
				[selected_leader]: player_id,
				[opposite_leader]: opposite_leader_id
			},
			formation: formation,
			id: is_draft ? team.id : undefined
		});
	}
}

export default LineupSetter;