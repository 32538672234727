// @flow
import * as React from "react";
import styled from "styled-components";
import logoAflFantasy from "../../assets/images/logo-afl-fantasy.svg";
import bgTrophy from "../../assets/images/afl-trophy.png";
import colors from "../../assets/css/colors";
import { getShortName } from "../../helpers/league";
import type {TTeam} from "../../modules/types";
import { below } from "../../assets/css/media";

const PremiersWrapper = styled.div`
	background-color: ${colors.primary.accent};
	height: 320px;
	${below.tablet`
		height: 270px;
	`}
	${below.phone`
		height: 220px;
	`}
	width: 100%;

	overflow: hidden;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	border-radius: 5px;
	margin-bottom: 20px;

	position: relative;

	> * {
		position: relative;
		z-index: 1;
	}

	h3 {
		font-size: 50px;
		font-weight: 600;

		/* Max line-length of 3 */
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;

		/* Fallback for IE/Firefox long names */
		max-height: 155px;
		overflow: hidden;
		text-overflow: ellipsis;

		${below.tablet`
			font-size: 40px;
		`}
		${below.phone`
			font-size: 30px;
		`}
	}

	h4 {
		font-size: 30px;
		font-weight: 200;

		${below.tablet`
			font-size: 24px;
		`}
		${below.phone`
			font-size: 16px;
		`}
	}

	h3, h4 {
		max-width: 400px;
		color: white;
		text-align: center;
		margin-bottom: 16px;
	}

	.logo {
		height: 70px;
		margin-bottom: 16px;
	}
	.trophy {
		position: absolute;
		z-index: 0;
		opacity: 0.1;

		width: 100%;

		filter: saturate(0%) brightness(30%) contrast(112%);
		transform: translateY(8%);
		${below.desktop`
			width: 110%;
		`}
		${below.tablet`
			width: 120%;
		`}
		${below.phone`
			width: 140%;
			transform: translate3d(-4px, 2%, 0);
		`}
	}
`;

type Props = {
	team: TTeam
};

export const Premiers = ({
	team
}: Props) => {
	return <PremiersWrapper>
		<img src={logoAflFantasy} alt="AFL Fantasy Logo" className="logo" />
		<img src={bgTrophy} alt="Trophy" className="trophy" />
		<h3>{team.name} are the Premiers!</h3>
		<h4>Congratulations {getShortName(team)}</h4>
	</PremiersWrapper>;
};
export default Premiers;