// @flow
import * as React from "react";
import styled from "styled-components";
import _ from "lodash";
import type { TPosition } from "../../modules/types";
import { getYetToPlay } from "../../helpers/team";
import { below } from "../../assets/css/media";
import {
	PlayerCount,
	PositionHeader,
	PositionTitle,
} from "./playerRow";

import AbstractComparisonTable from "./comparisonTable";

const Container = styled.div`
	button[rel="compressed-view"], button[rel="uncompressed-view"] {
		${below.desktop`
			display: none;
		`};
	}
`;

const PositionsPlayerList = styled.div``;

export class TeamsComparisonTable extends AbstractComparisonTable {
	renderPlayerCount(player_pos_ids: number[], position: TPosition) {
		const { players, round  } = this.props;
		const yet_to_play = getYetToPlay(player_pos_ids, players, round);
		const position_total = player_pos_ids ? player_pos_ids.length:0;
	
		return (
			<PlayerCount>
				{position_total - yet_to_play} / {position_total}
			</PlayerCount>
		);
	}

	renderPositionHeader(
		position: TPosition, home_pos_player_ids: number[], away_pos_player_ids: number[]
	) {
		const { is_compressed } = this.props;
		return (
			<PositionHeader is_compressed={is_compressed} >
				{this.renderPlayerCount(home_pos_player_ids, position)}
				<PositionTitle>{position.full_name}</PositionTitle>
				{this.renderPlayerCount(away_pos_player_ids, position)}
			</PositionHeader>
		);
	}

	updateBench(bench: Object) {
		/**
			* Reduce classic interchange object down to array of
			* player IDs
			*
			* Pos 1 === FWD -> Pos 4 === DEF
			* Use reduceRight so that interchange is ordered
			* defenders at top, to forwards at bottom
		 */

		if (!bench && _.size(bench)) {
			return [];
		}

		return _(bench)
			.omit("emergency", "utility")
			.reduceRight((pos, all) => {
				return pos.concat(all);
			}, []);
	}

	renderPositionPlayerSection(position: Object) {
		const {
			home_team = {},
			away_team  = {},
			is_classic
		} = this.props;

		let home_position_ids = position.id === "utility" ?
			[_.get(home_team, "lineup.bench.utility.id")] :
			_.get(home_team, ["lineup", position.id]);

		let away_position_ids = position.id === "utility" ?
			[_.get(away_team, "lineup.bench.utility.id")] :
			_.get(away_team, ["lineup", position.id]);

		if (position.id === "bench" && is_classic) {
			home_position_ids = this.updateBench(home_position_ids);
			away_position_ids = this.updateBench(away_position_ids);
		}

		const home_team_lineup_position_player_count = _.size(home_position_ids);
		const away_team_lineup_position_player_count = _.size(away_position_ids);

		const num_rows = _.max([
			home_team_lineup_position_player_count,
			away_team_lineup_position_player_count
		]);

		return (
			<React.Fragment key={position.id}>
				{this.renderPositionHeader(
					position,
					home_position_ids,
					away_position_ids
				)}
				{_.times(num_rows, index =>
					this.renderPlayerRow(
						_.get(home_position_ids, index),
						_.get(away_position_ids, index)
					)
				)}
			</React.Fragment>
		);
	}

	render() {
		return (
			<Container>
				<PositionsPlayerList className="team-comparison">
					{this.positions_list && this.positions_list.map(position =>
						this.renderPositionPlayerSection(position)
					)}
				</PositionsPlayerList>
			</Container>
		);
	}
}

export default TeamsComparisonTable;