// @flow
import * as React from "react";
import { connect } from "react-redux";
import _ from "lodash";

import * as selectors from "../../../modules/selectors";
import type {TRoundById} from "../../../modules/types";

type Props = {
	rounds_ordered: Array<Object>,
	rounds_by_id: TRoundById,
	actual_round_id: ?number
}

type State = {
	selected_round_id: ?number
}

export const withRoundsSelector = (WrappedComponent: any) => {
	class WithRoundsSelector extends React.PureComponent<Props, State> {
		constructor(props) {
			super(props);

			_.bindAll(this, ["setSelectedRoundId"]);
		}

		state = {
			selected_round_id: null
		};

		setSelectedRoundId(id: number, callback?: Function) {
			this.setState({
				selected_round_id: id
			}, callback);
		}

		render() {
			const { rounds_ordered, rounds_by_id, actual_round_id, ...rest } = this.props;
			const { selected_round_id: selected_round_id_state } = this.state;

			return (
				<WrappedComponent
					{...rest}
					rounds_ordered={rounds_ordered}
					rounds_by_id={rounds_by_id}
					selected_round_id={selected_round_id_state === null 
						? actual_round_id 
						: selected_round_id_state}
					setSelectedRoundId={this.setSelectedRoundId}
				/>
			);
		}
	}

	const mapStateToProps = (state: Object) => {
		return {
			rounds_ordered: selectors.rounds.getRounds(state),
			rounds_by_id: selectors.rounds.getRoundsById(state),
			actual_round_id: selectors.rounds.getActualRound(state).id
		};
	};

	return connect(mapStateToProps)(WithRoundsSelector);
};

export default withRoundsSelector;