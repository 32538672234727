
// @flow
import * as React from "react";
import * as _ from "lodash";
import type { TPlayerStatus } from "../../modules/types";
import Injured from "../Icons/Injured";
import Bye from "../Icons/Bye";
import NotSelected from "../Icons/NotSelected";
import Uncertain from "../Icons/Uncertain";
import Available from "../Icons/Available";
import InjurySub from "../Icons/InjurySub";
import SelectedEmergency from "../Icons/SelectedEmergency";

type Props = {
	// TPlayerStatus does not include AFL statuses of 'emergency' or 'playing'
	status?: TPlayerStatus | 'emergency' | 'playing' | 'medical_sub',
	is_bye?: 0 | 1
}

export const PlayerStatusIcon = ({ status, is_bye }: Props) => {

	if (is_bye) {
		return (<Bye />);
	}
	const statusObject = {
		"injured": <Injured />,
		"is-bye": <Bye />,
		"not-playing": <NotSelected />,
		"uncertain": <Uncertain />,
		"medical_sub": <InjurySub />,
		"emergency": <SelectedEmergency />,
	};

	return _.get(statusObject, `[${status ? status : ""}]`, <Available />);

};
export default PlayerStatusIcon;