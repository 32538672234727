/* eslint max-len: 0 */
// @flow
import React from "react";
import SvgIconContainer from "../SvgIconContainer";

export default (
	{
		unit = "em",
		...rest
	}: Object = {}) => (
	<SvgIconContainer viewBox="0 0 24 24" {...rest} unit={unit}>
		<title>Link</title>
		<path d="M16.6666667,8 C17.5833379,8 18.3680523,8.32638563 19.0208333,8.97916667
			C19.6736144,9.63194771 20,10.4166621 20,11.3333333 L20,12.6666667 C20,13.5833379
			19.6736144,14.3680523 19.0208333,15.0208333 C18.3680523,15.6736144 17.5833379,16
			16.6666667,16 L12,16 C10.5833262,16 9.5694475,15.33334 8.95833333,14 C8.76388792,13.5277754
			8.66666667,13.0833354 8.66666667,12.6666667 L8.66666667,11.3333333 L10.6666667,11.3333333
			L10.6666667,12.6666667 C10.6666667,13.0555575 10.7916654,13.3749988 11.0416667,13.625
			C11.2916679,13.8750012 11.6111092,14 12,14 L16.6666667,14 C17.0555575,14
			17.3749987,13.8750012 17.625,13.625 C17.8750012,13.3749988 18,13.0555575 18,12.6666667
			L18,11.3333333 C18,10.9444425 17.8750012,10.6250012 17.625,10.375 C17.3749987,10.1249988
			17.0555575,10 16.6666667,10 L16,10 C15.9166662,9.61110917 15.76389,9.25000167
			15.5416667,8.91666667 C15.3194433,8.58333167 15.1111121,8.34722292 14.9166667,8.20833333
			L14.6666667,8 L16.6666667,8 Z M15.0833333,10 C15.2500008,10.4166688 15.3333333,10.8611087
			15.3333333,11.3333333 L15.3333333,12.6666667 L13.3333333,12.6666667 L13.3333333,11.3333333
			C13.3333333,10.9444425 13.2083346,10.6250012 12.9583333,10.375 C12.7083321,10.1249988
			12.3888908,10 12,10 L7.33333333,10 C6.9444425,10 6.62500125,10.1249988 6.375,10.375
			C6.12499875,10.6250012 6,10.9444425 6,11.3333333 L6,12.6666667 C6,13.0555575
			6.12499875,13.3749988 6.375,13.625 C6.62500125,13.8750012 6.9444425,14 7.33333333,14 L8,14
			C8.08333375,14.3888908 8.23611,14.7499983 8.45833333,15.0833333 C8.68055667,15.4166683
			8.88888792,15.6527771 9.08333333,15.7916667 L9.33333333,16 L7.33333333,16 C6.41666208,16
			5.63194771,15.6736144 4.97916667,15.0208333 C4.32638562,14.3680523 4,13.5833379 4,12.6666667
			L4,11.3333333 C4,10.4166621 4.32638562,9.63194771 4.97916667,8.97916667
			C5.63194771,8.32638563 6.41666208,8 7.33333333,8 L12,8 C12.66667,8 13.2847194,8.18749812
			13.8541667,8.5625 C14.423614,8.93750188 14.8333321,9.41666375 15.0833333,10 Z" />
	</SvgIconContainer>
);