// @flow
import * as React from "react";
import { isEmpty } from "lodash";
import { getShortName, getSquadSize } from "../../../helpers/league";
import { LeagueFormWrap } from "../leagues/leaguesCreate/LeagueFormWrap";
import { FormUploadImage } from "../../../components";
import { LeagueSettings } from "../leagues/leaguesCreate/forms/LeagueSettings";
import { SeasonSettings } from "../leagues/leaguesCreate/forms/SeasonSettings";
import { GameplaySettings } from "../leagues/leaguesCreate/forms/GameplaySettings";
import { DraftSettings } from "../leagues/leaguesCreate/forms/DraftSettings";
import { TradersSettings } from "../leagues/leaguesCreate/forms/TradersSettings";
import { ScoringSettings } from "../leagues/leaguesCreate/forms/ScoringSettings";
import { KeeperSettings } from "../leagues/leaguesCreate/forms/KeeperSettings";
import {
	FormItem,
	UploadAvatarWrapper,
	LeagueNameDraft,
	UserName,
} from "../leagues/leaguesCreate/LeaguesCreateFormStyles";
import { isAllTrue } from "../../../helpers";

const get_league_avatar = ({ league_id, avatar_version }) => {
	return process.env.REACT_APP_DRAFT_LEAGUE_AVATARS_PATH+league_id+".png?v="+avatar_version;
};

export const LeaguesTabArr = (props:any, handleProOpen?: Function) => {
	const leagueName = props.league_name ? props.league_name:"";
	const leagueSubscription = props.league.subscription ? props.league.subscription:"";
	const leagueSize = !isEmpty(props.league) 
		? getSquadSize(props.league) + 1 
		: getSquadSize({ squad_size: props.squad_size }) + 1;
	const getProSub = () => {
		if(props.is_pro_sub === undefined){
			return false;
		}
		return props.is_pro_sub;
	};
	const isDeluxe = leagueSubscription === "full";
	const showProTxt = isAllTrue([
		leagueName !== "",
		!getProSub(),
		!["full", "lite"].includes(leagueSubscription)
	]);
	return(
		[
			{
				key: "basic",
				title: "Basic",
				alertText: "",
				content: (
					<LeagueFormWrap
						title="Basic Settings"
						handleProOpen={handleProOpen}
						showProTxt={showProTxt}
						subTitle="You can create a league via the basic settings above
										 or fine tune your Draft League with our 
										 advanced settings.">
						<FormItem className="base-options">
							<UploadAvatarWrapper>
								<FormUploadImage
									name="league_avatar"
									size="60px"
									onChange={props.onChangeFile}
									preview_img={
										props.previewImg ?
											props.previewImg :
											props.league.avatar_version ?
												get_league_avatar({
													league_id: props.league.id,
													avatar_version: props.league.avatar_version,
												}) : ""
									}
									add_text=""
								/>
								<div>
									{!props.league_name ? (
										<LeagueNameDraft
											disabled={true}>League Name</LeagueNameDraft>
									) : (
										<LeagueNameDraft>{props.league_name}</LeagueNameDraft>
									)}
									<UserName>{getShortName(props.user)}</UserName>
								</div>
							</UploadAvatarWrapper>
		
							<LeagueSettings {...props} />
						</FormItem>
					</LeagueFormWrap>
				),
			},
			{
				key: "season",
				title: "Season",
				alertText: "",
				content: (
					<LeagueFormWrap
						title="Season Settings"
						handleProOpen={handleProOpen}
						showProTxt={showProTxt}
						subTitle="Fine tune your Draft League with our advanced settings.">
						<SeasonSettings {...props} handleProOpen={handleProOpen} 
							is_pro_sub={getProSub()}/>
					</LeagueFormWrap>
				),
			},
			{
				key: "draft",
				alertText: "",
				title: "Draft",
				content: (
					<LeagueFormWrap
						title="Draft Settings"
						handleProOpen={handleProOpen}
						showProTxt={showProTxt}
						subTitle="Fine tune your Draft League with our advanced settings.">
						<DraftSettings {...props} />
					</LeagueFormWrap>
				),
			},
			{
				key: "lineup",
				alertText: "",
				title: "Lineup",
				content: (
					<LeagueFormWrap
						title="Lineup Settings"
						handleProOpen={handleProOpen}
						showProTxt={showProTxt}
						subTitle="Fine tune your Draft League with our advanced settings.">
						<GameplaySettings 
							{...props} 
							is_pro_sub={getProSub()} 
							handleProOpen={handleProOpen}
						/>
					</LeagueFormWrap>
				),
			},
			{
				key: "trade",
				alertText: "",
				title: "Trade & RFA",
				content: (
					<LeagueFormWrap
						title="Trade & RFA Settings"
						handleProOpen={handleProOpen}
						showProTxt={showProTxt}
						subTitle="Fine tune your Draft League with our advanced settings.">
						<TradersSettings 
							{...props} 
							handleProOpen={handleProOpen} 
							is_pro_sub={getProSub()}
						/>
					</LeagueFormWrap>
				),
			},
			{
				key: "scoring",
				alertText: "",
				title: "Scoring",
				content: (
					<LeagueFormWrap
						title="Scoring Settings"
						handleProOpen={handleProOpen}
						showProTxt={showProTxt}
						subTitle="Fine tune your Draft League with our advanced settings.">
						<ScoringSettings 
							{...props} 
							handleProOpen={handleProOpen} 
							is_pro_sub={getProSub()}
							isDeluxe={isDeluxe}
						/>
					</LeagueFormWrap>
				),
			},
			{
				key: "keeper",
				alertText: "",
				title: "Keeper League",
				content: (
					<LeagueFormWrap
						title="Keeper Settings"
						handleProOpen={handleProOpen}
						showProTxt={showProTxt}
						subTitle="Fine tune your Draft League with our advanced settings.">
						<KeeperSettings 
							{...props} 
							draft_status={props.draft_status}
							keeper={props.keeper}
							leagueSize={leagueSize} />
					</LeagueFormWrap>
				),
			},
		]
	);
};