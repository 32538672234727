// @flow
export * from "./graph";
export * from "./playersInfo";
export * from "./summaryPanel";
export * from "./teamAvatar";
export * from "./teamInfo";
export * from "./teamsComparisonTable";

export const matchup_field_stats = [
	{
		key: "avg_points",
		value: "Average"
	},
	{
		key: "proj_score",
		value: "Projected"
	},
	{
		key: "round_points",
		value: "Live"
	},
	{
		key: "last_round",
		value: "Last Round"
	}
];

export const initialScoreStat = (round_started: boolean, has_coach: boolean) => {
	if (round_started) {
		return "round_points";
	}
	return has_coach ? "proj_score" : "avg_points";
};