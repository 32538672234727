// @flow
import styled from "styled-components";
import { Td } from "../../components/TableX";
import { below } from "../../assets/css/media";

export const TdStat = styled(Td)`
	${below.phone`
		.player-avatar {
			display: none;
		}
	`};
	min-width: calc(4em - 10px);
`;

export default TdStat;