// @flow
import styled from "styled-components";

export const LabelWrapper = styled.div`
	display: flex;
	justify-content: space-between;

	> .disabled-icon {
		margin-bottom: 2px;
	}
`;

export default LabelWrapper;