/* eslint max-len: 0 */
// @flow
import React from "react";
import SvgIconContainer from "../SvgIconContainer";

export default (
	{
		unit = "em",
		...rest
	}: Object = {}) => (
	<SvgIconContainer viewBox="0 0 24 24" {...rest} unit={unit}>
		<title>Caution</title>
		<path d="M20.9000362,14.4417513 L23.7312746,19.3455994 C24.4948742,20.6683487
			23.5402032,22.3217853 22.0130041,22.3217853 L1.9869959,22.3217853 C0.459796785,22.3217853
			-0.494874179,20.6683487 0.268725378,19.3455994 L3.09996388,14.4417512 L3.08732974,14.4422337
			L10.2753195,1.99206196 C10.6583318,1.32858686 11.329602,0.997902877 12,1.00001001
			C12.670398,0.997902877 13.3416682,1.32858686 13.7246805,1.99206196 L20.9126703,14.4422337
			L20.9000362,14.4417512 Z M11.7666274,5.70817786 C10.9934228,5.70817786 10.3738987,6.34862726
			10.3996264,7.12148881 L10.639066,14.3074203 C10.6637646,15.044606 11.2681952,15.6294835
			12.006067,15.6294835 C12.7439388,15.6294835 13.3483694,15.044606 13.373068,14.3074203
			L13.6125076,7.12148881 C13.6382353,6.34862726 13.0187111,5.70817786 12.2455066,5.70817786
			L11.7666274,5.70817786 Z M12.006067,20.5897933 C12.9195736,20.5897933 13.6595036,19.8495202
			13.6595036,18.9363567 C13.6595036,18.0231932 12.9192305,17.2829201 12.006067,17.2829201
			C11.0929035,17.2829201 10.3526304,18.0231932 10.3526304,18.9363567 C10.3526304,19.8495202
			11.0929035,20.5897933 12.006067,20.5897933 Z" />

	</SvgIconContainer >
);