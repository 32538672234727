// @flow
import * as React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import * as _ from "lodash";
import styled from "styled-components";
import type { TRootStore, TSquad, TRound, TRoundsById, TUser } from "../../modules/types";
import {
	AdsContainer,
	Ad,
	Footer,
	PageContentWrapper,
	PageTitle,
	StandardSidebar,
	RoundSelector,
	TwoColumnLayout,
	Select,
	Tooltip,
	Label,
	ButtonLoadMore,
	LeagueGameBar,
} from "../../components";
import JsonFetcher from "../../components/utils/JsonFetcher";
import * as selectors from "../../modules/selectors";
import withRoundsSelector from "../../components/utils/withRoundsSelector";
import * as actions from "../../modules/actions";
import { below } from "../../assets/css/media";
import ClassicRankings from "../../components/ClassicRankings";
import { getRankingsRounds } from "../../helpers/league";
import { isOffSeason } from "../../utils";
import colors from "../../assets/css/colors";

const OFF_SEASON = isOffSeason();


const StyledLabel = styled(Label)`
	margin-bottom: 5px;
	${below.tablet`
		flex: 0 0 auto;
		margin-right: 10px;
	`}
`;

const FilterOption = styled.div`
	min-width: 200px;
	${below.tablet`
		min-width: unset;
	`};
`;

const FilterOptionsWrapper = styled.div`
	display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: unset;
    flex-basis: 100%;

	${below.tablet`
		flex-direction: column;
		align-items: flex-start;
		padding-left: 10px;
		box-sizing: border-box;
	`};

	> ${FilterOption} {
		margin: 0 5px;
		> div {
			margin-right: 0;
		}
		&:first-child {
			margin-left: 0;
		}
		&:last-child {
			margin-right: 0;
		}

		${below.tablet`
			margin: 15px 0 0;
			display: flex;
			align-items: baseline;
			justify-content: space-between;
			width: 100%;
			> div {
				flex: 1 0 0px;
				max-width: 250px;
			}
		`};
	}
`;

const SelectsWrapper = styled.div`
	display: flex;

	${below.tablet`
		width: 100%;
	`}

	> div {
		flex: 1 0 0px;
		margin: 0 5px;
		> div {
			margin-right: 0;
		}
		&:first-child {
			margin-left: 0;
		}
		&:last-child {
			margin-right: 0;
		}
	}
`;

const HoldingMessageWrapper = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	height: 80px;
	padding: 20px;
	background-color: ${colors.secondary.lightGrey};

	p{
		font-family: SourceSansPro;
		font-size: 16px;
		font-weight: 600;
		color: ${colors.primary.primary};
	}

`;

const MainContentWrapper = styled.div`
`;

const ORDER_BY_ASC = ["rank"];

export const STATES = [
	{
		id: 1,
		short_name: "ACT",
		name: "Australian Capital Territory",
	},
	{
		id: 2,
		short_name: "NT",
		name: "Northern Territory",
	},
	{
		id: 3,
		short_name: "NSW",
		name: "New South Wales",
	},
	{
		id: 4,
		short_name: "QLD",
		name: "Queensland",
	},
	{
		id: 5,
		short_name: "SA",
		name: "South Australia",
	},
	{
		id: 6,
		short_name: "TAS",
		name: "Tasmania",
	},
	{
		id: 7,
		short_name: "VIC",
		name: "Victoria",
	},
	{
		id: 8,
		short_name: "WA",
		name: "Western Australia",
	},
];

const TAB_COLS = [
	"rank", "rank_change", "avatar", "total_points",
	"avg_points", "round_points", "value"
];
const MOB_COLS = [
	"rank", "avatar", "total_points",
	"avg_points", "round_points", "value"
];

const undefinedIfZero = value => value === 0 ? undefined : value;

type Props = {
	is_preseason: boolean,
	rounds_ordered: Array<TRound>,
	rounds_by_id: TRoundsById,
	selected_round_id: number,
	setSelectedRoundId: Function,
	fetchRankings: Function,
	fetchMyClassicTeam: Function,
	loadMoreRankings: Function,
	teams_ordered: Object[],
	squads: Array<TSquad>,
	user: TUser,
}

type TFilters = {
	team_state: number,
	squad_id: number
}

type TSortOrder = {
	by: string,
	direction: 'ASC' | 'DESC'
}

type State = {
	filters: TFilters,
	order: TSortOrder,
	showing_teams: number,
	offset: number
}

const OFFSET_STEP = 50;

export class RankingsPage extends React.Component<Props, State> {
	constructor() {
		super();

		_.bindAll(this, [
			"updateTeamState",
			"updateSquadId",
			"updateOrdering",
			"loadMore"
		]);
	}

	state = {
		filters: {
			team_state: 0,
			squad_id: 0,
		},
		order: {
			by: "rank",
			direction: "ASC",
		},
		showing_teams: 20,
		offset: 0
	};

	componentDidMount() {
		const { setSelectedRoundId } = this.props;
		setSelectedRoundId(0);

		this.refreshRankings();
	}
	componentDidUpdate(prev_props: Props) {
		const { selected_round_id } = this.props;
		const { selected_round_id: old_round } = prev_props;

		if (selected_round_id !== old_round) {
			this.refreshRankings();
		}
	}

	refreshRankings() {
		const { fetchRankings } = this.props;
		this.getRankings(fetchRankings);
	}
	loadMore() {
		const { loadMoreRankings, teams_ordered } = this.props;
		const { showing_teams } = this.state;
		let { offset } = this.state;

		if (showing_teams + 20 > _.size(teams_ordered)) {
			offset += OFFSET_STEP;
			this.getRankings(loadMoreRankings, offset);
		}

		this.setState({
			showing_teams: showing_teams + 20,
			offset
		});
	}
	getRankings(rankingsAction: Function, offset?: number = 0) {
		const { selected_round_id } = this.props;
		const { filters, order } = this.state;
		rankingsAction({
			club: undefinedIfZero(filters.squad_id),
			state: undefinedIfZero(filters.team_state),
			round: undefinedIfZero(selected_round_id),
			order: order.by,
			order_direction: order.direction,
			offset,
		});
	}

	updateTeamState({ currentTarget }: any) {
		let team_state = parseInt(currentTarget.value, 10);
		if (_.isNaN(team_state) || !_.isNumber(team_state)) {
			team_state = 0;
		}
		this.setState({
			filters: {
				squad_id: 0, /* Reset Club being shown */
				team_state,
			}
		}, () => this.refreshRankings());
	}
	updateSquadId({ currentTarget }: any) {
		let squad_id = parseInt(currentTarget.value, 10);
		if (_.isNaN(squad_id) || !_.isNumber(squad_id)) {
			squad_id = 0;
		}

		this.setState({
			filters: {
				team_state: 0, /* Reset state being shown */
				squad_id,
			}
		}, () => this.refreshRankings());
	}
	updateOrdering(new_by: string) {
		const { order: current_order } = this.state;

		if (current_order.by === new_by) {
			const new_dir = current_order.direction === "ASC" ? "DESC" : "ASC";
			this.setState({
				order: {
					...current_order,
					direction: new_dir
				}
			}, () => this.refreshRankings());
		}
		else {
			const new_dir = _.includes(ORDER_BY_ASC, new_by) ? "ASC" : "DESC";

			this.setState({
				order: {
					direction: new_dir,
					by: new_by,
				}
			}, () => this.refreshRankings());
		}
	}

	get rounds_ordered() {
		const { rounds_ordered, is_preseason } = this.props;

		const rounds = getRankingsRounds(rounds_ordered);

		if (is_preseason) {
			_.set(_.first(rounds), "disabled", true);
		}

		return rounds;
	}
	get show_load_more() {
		const { teams_ordered } = this.props;
		const { showing_teams } = this.state;
		return showing_teams <= _.size(teams_ordered);
	}
	get display_teams() {
		const { teams_ordered } = this.props;
		const { showing_teams } = this.state;

		return _.take(teams_ordered, showing_teams);
	}
	get ladder_stats() {
		const { selected_round_id } = this.props;
		return [
			{
				key: "Total Points",
				short_key: "Total",
				prop: "total_points",
				sort_key: "total_points",
			 },
			{
				key: "Average Points",
				short_key: "Avg",
				prop: "avg_points",
				sort_key: "avg_points",
			},
			{
				key: selected_round_id
					? `Round ${selected_round_id} Points`
					: "Points Last Round",
				short_key: "Points",
				prop: "round_points",
				sort_key: "round_points",
			},
			{
				key: "Highest Round Score",
				short_key: "HI RND",
				prop: "highest_round_score",
				sort_key: ""
			},
			{
				key: "Value",
				short_key: "Value",
				prop: "value",
				format: "$0,0",
				sort_key: "team_value",
			}
		];
	}

	renderRoundSelector() {
		const { selected_round_id, setSelectedRoundId } = this.props;
		return <RoundSelector
			selected={selected_round_id}
			rounds={this.rounds_ordered}
			show_all_button
			handleClick={setSelectedRoundId}
		/>;
	}
	renderSearchForm() {
		return <FilterOptionsWrapper>
			<SelectsWrapper>
				{ this.renderClubOptions() }
				{ this.renderStateOptions() }
			</SelectsWrapper>
		</FilterOptionsWrapper>;
	}
	renderClubOptions() {
		const { squads } = this.props;
		const { filters } = this.state;
		return <FilterOption>
			<StyledLabel htmlFor="rankings_club">
				Club
				<Tooltip
					title="Club to Display"
					description={
						"Change the club that teams "
						+ "displayed in the rankings are from"
					}
				/>
			</StyledLabel>
			<Select
				name="rankings_club"
				value={filters.squad_id}
				onChange={this.updateSquadId}
			>
				<option value={0}>
					All Clubs
				</option>
				{_.map(squads, squad => {
					return <option value={squad.id} key={squad.id}>
						{squad.name}
					</option>;
				})}
			</Select>
		</FilterOption>;
	}
	renderStateOptions() {
		const { filters } = this.state;
		return <FilterOption>
			<StyledLabel htmlFor="rankings_state">
				State
				<Tooltip
					title="State to Display"
					description={
						"Change the state that teams displayed"
						+ " in the rankings are from"
					}
				/>
			</StyledLabel>
			<Select
				name="rankings_state"
				value={filters.team_state}
				onChange={this.updateTeamState}
			>
				<option value={0}>
					All States
				</option>
				{
					_.map(STATES, state => {
						return <option value={state.id} key={state.id}>
							{state.name}
						</option>;
					})
				}
			</Select>
		</FilterOption>;
	}
	renderRankingsTable() {
		const {
			selected_round_id,
			rounds_by_id,
			user
		} = this.props;
		const {
			order
		} = this.state;
		return <ClassicRankings
			ladder_teams_by_id={{}}
			ladder_teams_ordered={this.display_teams}
			rounds_by_id={rounds_by_id}
			selected_round_id={selected_round_id}
			user_id={user.id}
			ladder_stats={this.ladder_stats}
			updateSortBy={this.updateOrdering}
			order={order}
			mobile_columns={MOB_COLS}
			tablet_columns={TAB_COLS}
		/>;
	}
	renderLoadMore() {
		return this.show_load_more
			&& <ButtonLoadMore onClick={this.loadMore}>Load More</ButtonLoadMore>;
	}

	renderHoldingMessage() {
		return(
			<HoldingMessageWrapper>
				<p>
					Please note that rankings will appear after Round 1 is complete
				</p>
			</HoldingMessageWrapper>
		);
	}
	renderMainSection() {
		if(this.display_teams.length <= 1){
			return this.renderHoldingMessage();
		}
		return (
			<MainContentWrapper>
				{ this.renderRoundSelector() }
				{ this.renderSearchForm() }
				{ this.renderRankingsTable() }
				{ this.renderLoadMore() }
			</MainContentWrapper>
		);
	}

	render() {
		return (
			<React.Fragment>
				<JsonFetcher fetch={["rounds", "squads"]} />
				{!OFF_SEASON &&
					<LeagueGameBar />
				}

				<AdsContainer>
					<Ad />
				</AdsContainer>

				<PageContentWrapper>
					<PageTitle>
						Rankings
					</PageTitle>
					<TwoColumnLayout>
						{this.renderMainSection()}
						<StandardSidebar />
					</TwoColumnLayout>
				</PageContentWrapper>

				<AdsContainer>
					<Ad />
				</AdsContainer>

				<Footer />
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: TRootStore, props) => ({
	is_preseason: selectors.rounds.isPreseason(state),
	teams_ordered: selectors.getRankings(state, props),
	user: selectors.getUser(state),
	squads: selectors.squads.getSquads(state),
});

const mapDispatchToProps = {
	loadMoreRankings: actions.loadMoreRankings,
	fetchRankings: actions.fetchRankings,
	fetchMyClassicTeam: actions.fetchMyClassicTeam
};


const Rankings = compose(
	withRoundsSelector,
	connect(
		mapStateToProps,
		mapDispatchToProps
	)
)(RankingsPage);

export default Rankings;