// @flow
import { createAction } from "redux-act";

export const getCoachesBoxSettings = createAction("Get Coaches Box Settings");
export const getCoachesBoxSettingsFailed = createAction("Get Coaches Box Settings Failed");
export const getCoachesBoxSettingsSuccess = createAction("Get Coaches Box Settings Success");

export const setCoachesBoxSettings = createAction("Set Coaches Box Settings");
export const setCoachesBoxSettingsFailed = createAction("Set Coaches Box Settings Failed");
export const setCoachesBoxSettingsSuccess = createAction("Set Coaches Box Settings Success");

export const sendCoachesBoxMessage = createAction("Send Coaches Box Message");
export const sendCoachesBoxMessageFailed = createAction("Send Coaches Box Message Failed");
export const sendCoachesBoxMessageSuccess = createAction("Send Coaches Box Message Success");

export const getCoachesBoxMessages = createAction("Get Coaches Box Messages");
export const getCoachesBoxMessagesFailed = createAction("Get Coaches Box Messages Failed");
export const getCoachesBoxMessagesSuccess = createAction("Get Coaches Box Messages Success");

export const clearCoachesBoxErrors = createAction("Clear Coaches Box Errors");