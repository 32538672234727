// @flow
import styled, { css } from "styled-components";
export const BenchOverlay = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;

	border-radius: 5px;
	opacity: 0.9;
	background-color: black;
	
	${({ is_overflow }) => is_overflow && css`
		background: repeating-linear-gradient(
			45deg,
			#D92425,
			#D92425 15px,
			#d22d2d 16px,
			#d22d2d 21px
		);
	`}
`;