import React from "react";
import { connect } from "react-redux";
import bindAll from "lodash/bindAll";

import styled from "styled-components";
import * as actions from "../modules/actions";
import colors from "../assets/css/colors";
import CautionReset from "../components/Icons/CautionReset";

import {
	changePassword,
	changePasswordFailed,
	changePasswordSuccess,
	clearLoginErrors,
} from "../modules/actions";
import {TUser} from "../modules/types";
import {
	Footer,
	PageTitle,
	TextModal,
	TwoColumnLayout,
	PageContentWrapper,
	Ad,
	AdsContainer,
	StandardSidebar,
} from "../components";

import UpdatePasswordForm from "./updatePassword/updatePasswordForm";

const PageTitleStyled = styled(PageTitle)`
	font-size: 32px;
	display: flex;
	align-items: flex-end;

	svg{
		margin: 0 10px 8px 0;
	}
`;

type Props = {
	user: TUser,
	updateUser: typeof actions.updateUser,
	clearUpdateUserData: typeof actions.clearUpdateUserData,
	user_update: {
		error: {
			message: string,
		},
		success: boolean,
	}
};

class UpdatePasswordComponent extends React.Component<Props> {
	constructor() {
		super();

		bindAll(this, [
			"handleSubmit",
			"handleModalClose",
		]);
	}

	get error_modal() {
		const { user } = this.props;

		return (
			<TextModal
				onClick={this.handleModalClose}
				header_text='Error'
				text={ user.update.error.message } />
		);
	}

	get success_modal() {
		return (
			<TextModal
				onClick={this.handleModalClose}
				header_text='Success'
				text='Password has been successfully changed!' />
		);
	}

	handleModalClose() {
		const { clearUpdateUserData } = this.props;
		clearUpdateUserData();
	}

	handleSubmit({ password }) {
		const { updateUser } = this.props;
		updateUser({
			password,
			confirmPassword: password,
		});
	}

	render() {
		const { user } = this.props;
		return (
			<React.Fragment>
				{user.update.error ?
					this.error_modal : null}

				{!user.update.error && user.update.success ?
					this.success_modal : null}
				<AdsContainer>
					<Ad />
				</AdsContainer>
				<PageContentWrapper>
					<PageTitleStyled>
						<CautionReset color={colors.title} size='1.3' />
							Change your password
					</PageTitleStyled>
					<TwoColumnLayout>
						<UpdatePasswordForm onSubmit={this.handleSubmit} />
						<StandardSidebar />
					</TwoColumnLayout>
				</PageContentWrapper>
				<Footer />
			</React.Fragment>
		);
	}
}

const mapStateToProps = state => ({
	user: state.user,
});

const mapDispatchToProps = {
	changePassword,
	changePasswordFailed,
	changePasswordSuccess,
	clearLoginErrors,
	updateUser: actions.updateUser,
	clearUpdateUserData: actions.clearUpdateUserData,
};

export const UpdatePassword = connect(
	mapStateToProps,
	mapDispatchToProps
)(UpdatePasswordComponent);

export default UpdatePassword;