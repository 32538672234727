// @flow
import { createReducer } from "redux-act";
import * as actions from "../../actions";
import { arrayToObjectById } from "./../../../helpers";

export const initial_state = {
	is_pending: false,
	error: null,
	by_id: {},
	ordered_ids: [],
};

export const venues = createReducer({}, initial_state);
venues.on(actions.fetchVenues, state => ({
	...state,
	is_pending: true,
	error: null,
}));
venues.on(actions.fetchVenuesFailed, (state, payload) => ({
	...state,
	is_pending: false,
	error: payload,
}));
venues.on(actions.fetchVenuesSuccess, (state, payload: any) => {
	// build by id
	const by_id = arrayToObjectById(payload, state.by_id);

	return {
		...state,
		is_pending: false,
		error: null,
		by_id,
		ordered_ids: payload.map(item => item.id),
	};
});

export default venues;