// @flow
import React from "react";
import SvgIconContainer from "../SvgIconContainer";

export default (
	{
		unit = "em",
		...rest
	}: Object = {}) => (
	<SvgIconContainer viewBox="0 0 24 24" {...rest} unit={unit}>
		<g>
			<polygon points="11 11 5 11 5 13 11 13 13 13 19 13 19 11 13 11" />
		</g>
	</SvgIconContainer>
);