// @flow
import { createReducer } from "redux-act";
import { fetchOffSeasonRankingsSuccess } from "../../actions/offSeason";

const initialState = {
	rankings: {},
};

export const offSeasonRankings = createReducer({}, initialState);

offSeasonRankings.on(fetchOffSeasonRankingsSuccess, (state, rankings: Object) => ({
	rankings,
}));

export default offSeasonRankings;