// @flow
import * as React from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import {
	SearchInputWrapper, RoundSelector,
	SearchField,
	SearchSubmitButton, Tooltip, Select, Label, FormCircleCheckbox,

} from "../../../../../components";
import Search from "../../../../../components/Icons/Search";
import { below } from "../../../../../assets/css/media";
import { getLeagueRoundAliases } from "../../../../../helpers/league";
import type { TRound, TPLeague, TDraftTeamsById } from "../../../../../modules/types";


const RoundSelectorWrap = styled.div`
	margin: auto 5px 0 0;
	& > div {
		margin: 0;
	}
`;

const FiltersForm = styled.form`
	${below.desktop`
		margin-left: 10px;
		margin-right: 10px;
	`}
`;

const FiltersWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-end;
	> div {
		width: 140px;
	}
	${below.desktop`
		width: 100%;
	`};
`;

const FilterWrapper = styled.div`
	flex: 1;
	margin: 0 10px;
	${below.desktop`
		&:last-of-type {
			margin-right: 0;
		}
		margin: 0 5px;
	`};
	&:first-of-type {
		margin-left: 0;
	}
`;

const SearchWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 20px 0;
`;
const SelectWrapper = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-top: 20px;

	> div {
		min-width: 140px;
	}

	${below.tablet`
		flex-wrap: wrap;
		> div {
			margin-bottom: 10px;
		}
	`};
`;
const StyledLabel = styled(Label)`
	margin-bottom: 4px;
	vertical-align: middle;
`;
const StyledFormCircleCheckbox = styled(FormCircleCheckbox)`
	margin-top: 20px;
	min-width: auto !important;
	margin-left: 5px;
`;

type Props = {
	by_favourites: boolean,
	teams: TDraftTeamsById,
	handleOwnerChange: Function,
	handleTransactionChange: Function,
	handlePlayerChange: Function,
	handleSubmit: Function,
	handleFavourites: Function,
	league_rounds: Array<TRound>,
	league: TPLeague,
	selected: number,
	handleRoundChange: Function,

}
type State = {
	by_favourites: boolean
}
class TransactionHistoryFiltersComponent extends React.Component<Props, State> {
	constructor(props: Props, state: State) {
		super(props, state);

		_.bindAll(this, [
			"handleSwitchChange",
			"handleFavouriteChange"
		]);
	}

	state = {
		by_favourites: false,
	};

	handleSwitchChange({ currentTarget }: Object) {
		const { name, checked } = currentTarget;
		this.setState({ [name]: checked });
	}

	handleFavouriteChange({currentTarget}: Object) {
		const {handleFavourites} = this.props;
		const {by_favourites} = this.state;
		this.setState({ by_favourites: !by_favourites });
		handleFavourites();
	}

	get renderPlayerFilter() {
		const { handlePlayerChange } = this.props;
		return(
			<SearchWrapper>
				<SearchInputWrapper>
					<SearchField
						type="text"
						name="search"
						component="input"
						placeholder="Search for a player"
						onChange={handlePlayerChange}
					/>
					<SearchSubmitButton type="submit">
						<Search />
					</SearchSubmitButton>
				</SearchInputWrapper>
			</SearchWrapper>
		);
	}

	get renderTransactionFilter() {
		const { handleTransactionChange } = this.props;
		return (
			<FilterWrapper>
				<StyledLabel>
					Transaction type
					<Tooltip
						title="Transaction type"
						description="The type of transaction to filter by"
					/>
				</StyledLabel>
				<Select
					name="by_squad"
					is_disabled={false}
					onChange={handleTransactionChange}
				>
					<option value="">
						All Transactions
					</option>
					<option value="free_agent">
						Free Agents
					</option>
					<option value="trade">
						Trades
					</option>
				</Select>
			</FilterWrapper>
		);
	}

	get renderOwnerFilter() {
		const { teams, handleOwnerChange } = this.props;
		const paired_teams = _.toPairs(teams);
		const owner_options = paired_teams
			.map( team => <option key={team[1].id} value={team[1].id}>{team[1].name}</option>);
		return (
			<FilterWrapper>
				<StyledLabel htmlFor="by_owner">
					Owner
					<Tooltip
						title="Owner"
						description="Team to filter transactions by"
					/>
				</StyledLabel>
				<Select
					is_disabled={false}
					name="by_owner"
					defaultValue={false}
					onChange={handleOwnerChange}
				>
					<option value={0}>
						All Owners
					</option>
					{owner_options}
				</Select>
			</FilterWrapper>
		);
	}

	get renderFavouritesFilter() {
		const { by_favourites } = this.state;
		return (
			<StyledFormCircleCheckbox
				id="favourites"
				name="by_favourites"
				checked={by_favourites}
				value={by_favourites}
				isActive={by_favourites}
				onChange={this.handleFavouriteChange}
				label="Favourites only"
			/>
		);
	}

	get roundFilter() {
		const { league, league_rounds, selected, handleRoundChange } = this.props;
		const finals_aliases = getLeagueRoundAliases(league, league_rounds);

		return league_rounds.length ? (
			<RoundSelectorWrap>
				<RoundSelector
					selected={selected}
					rounds={league_rounds}
					handleClick={handleRoundChange}
					show_all_button
					round_aliases={finals_aliases}
				/>
			</RoundSelectorWrap>
		) : null;
	}


	render() {
		return (
			<FiltersForm action="">
				<div>
					{this.renderPlayerFilter}
					<SelectWrapper>
						<FiltersWrapper>
							{this.renderTransactionFilter}
							{this.renderOwnerFilter}
						</FiltersWrapper>
						{this.roundFilter}
						{this.renderFavouritesFilter}
					</SelectWrapper>
				</div>
			</FiltersForm>
		);
	}
}

export const TransactionHistoryFilters = withRouter(TransactionHistoryFiltersComponent);

export default withRouter(TransactionHistoryFilters);