// @flow
import styled from "styled-components";
import { header_height } from "../../assets/css/vars";
import { below } from "../../assets/css/media";

export const TopBarWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	max-width: 100%;
	padding: 0 calc(50% - 640px);
	background-color: ${({ is_your_pick }) => is_your_pick ? "#198DE1" : "#F0F3F7"};
	box-shadow: 0 5px 8px rgba(0, 0, 0, .25);
	position: relative;
	top: 0;
	z-index: 2;
	box-sizing: border-box;
	overflow: hidden;
	height: 80px;

	@supports(position: sticky) {
		& {
			position: sticky;
			top: ${header_height}px;
			left: 0;
			z-index: 99;
		}
	}

	.timer-label {
		text-transform: uppercase;
	}

	.control-arrow {
		color: ${({ is_your_pick }) => is_your_pick ? "#FFF" : "#1D4073"};
		svg{
			g{
				path{
					fill: ${({ is_your_pick }) => is_your_pick ? "#FFF" : ""};
				}
			}
		}
	}
	${below.phone`
		height: 110px;
	`}
`;

export default TopBarWrapper;