// @flow

export const getRostersById = (state: Object): Object => 
	state.leaguesClassic.rosters.ordered_ids.reduce(
		(obj, id) => {
			obj[id] = state.leaguesClassic.rosters.teams_by_id[id];
			return obj;
		},
		{}
	);

export default getRostersById;