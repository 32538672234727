// @flow
import styled from "styled-components";
import colors from "../../assets/css/colors";


export const BenchLabel = styled.h5`
	font-family: TitilliumUpright;
	font-weight: 400;
	font-size: 12px;
	line-height: 1;
	padding: 10px 5px;
	color: ${colors.secondary.accentGrey};
	text-align: left;
`;