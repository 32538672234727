// @flow
import styled from "styled-components";
import * as React from "react";

type Props = {
    isBlue:boolean;
};

const NewIconDiv = styled.div`
  background: ${props => props.isBlue ? "#198DE1" : "#000"};
  height: 20px;
  width: 45px;
  border-radius: 2px;
  color: #FFFFFF;
  font-family: "SourceSansPro";
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  margin: 0 0 0 10px;
`;

export class NewIcon extends React.PureComponent<Props> {
	render() {
		const { isBlue } = this.props;
		return (
			<NewIconDiv isBlue={isBlue}>New</NewIconDiv>
		);
	}
}

export default NewIcon;