/* eslint max-len: 0 */
// @flow
import React from "react";
import SvgIconContainer from "../SvgIconContainer";

export default ({ unit = "em", color = "#fff", ...rest }: Object = {}) => (
	<SvgIconContainer viewBox="0 0 8 16" {...rest} unit={unit} height='16px' width='16px'>
		<title>Facebook</title>
		<g transform="translate(-8.000000, -4.000000)" fill={color}>
			<path
				d="M12.9777778,9.33333333 L15.4666667,9.33333333 L15.1555556,12 L12.9777778,12 L12.9777778,20 L9.75,20 L9.75,12 L8,12 L8,9.33333333 L9.75,9.33333333 L9.75,7.54166667 C9.75,6.37499417 10.0222195,5.49305854 10.5666667,4.89583333 C11.1111138,4.29860812 12.0185122,4 13.2888889,4 L15.4666667,4 L15.4666667,6.66666667 L14.1444444,6.66666667 C13.6518494,6.66666667 13.33426,6.74999917 13.1916667,6.91666667 C13.0490734,7.08333417 12.9777778,7.36110917 12.9777778,7.75 L12.9777778,9.33333333 Z" />
		</g>
	</SvgIconContainer>
);