// @flow
import React from "react";
import styled from "styled-components";

import colors from "../../assets/css/colors";
import Tick from "../Icons/Tick";

const Wrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	color: ${colors.primary.accentGreen};
	vertical-align: middle;

	> svg {
		display: inline-block;
		vertical-align: middle;
		height: 2em;
		width: 2em;
		transform: translateY(-.1em)
	}
`;

type Props = {
	children: string
}

export const TickMessage = ({ children }: Props) => {
	return (
		<Wrapper>
			<Tick /> {children}
		</Wrapper>
	);
};

export default TickMessage;