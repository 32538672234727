// @flow
import styled from "styled-components";

export const WidgetBody = styled.div`
	background: #F8F8FA;
	color: #7F8A90;
	font-size: 14px;
	width: 100%;
	border-radius: 0 0 3px 3px;
	/* Make sure there's no gap between bottom radius of header and body */
	margin-top: -3px;
	display: ${({ open }) => open ? "block" : "none"};
`;

WidgetBody.defaultProps = {
	open: true
};

export default WidgetBody;