// @flow
import styled from "styled-components";

export const Widget = styled.div.attrs({
	className: "playwright-mask-hidden"
})`
	position: relative;
	width: 300px;
	margin-bottom: 10px;
`;

export * from "./WidgetBody";
export * from "./WidgetHeader";
export * from "./WidgetHeaderTitle";
export * from "./WidgetHeaderCloseBtn";
export * from "./WidgetSpacer";

export default Widget;