// @flow
import * as React from "react";
import styled from "styled-components";
import Drag from "../Icons/Drag";
import DragInvalid from "../Icons/DragInvalid";

const DragValid = styled(Drag)`
	font-size: 2.2em;
	color: #CAD2D8;
	cursor: grab;
`;

const DragNotAllowed = styled(DragInvalid)`
	font-size: 1.6em;
	color: #CAD2D8;
	cursor: no-drop;
`;

type Props = {
	disabled?: boolean
}

export const DragIcon = ({ disabled, ...rest }: Props) => (
	disabled ? <DragNotAllowed {...rest} /> : <DragValid {...rest} />
);

export default DragIcon;