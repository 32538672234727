// @flow

import { identity } from "lodash";
import { isAllTrue } from "../../helpers";
import {SEASON_YEAR} from "./../../modules/constants";

const
	FB_SHARE_URL = process.env.REACT_APP_PUBLIC_URL || "";

const getEncodedJson = (object: Object) => btoa(encodeURIComponent(JSON.stringify(object)));

const getLinkForShare = (object: Object, type) =>
	`${FB_SHARE_URL}${type}/${getEncodedJson(object)}`;
const getDynamicImgForShare = (object: Object, type) =>
	`${FB_SHARE_URL}${type}/${getEncodedJson(object)}`;

const getTimestamp = () => window.Date.now().toString().substr(-4);

const share_endpoints = {
	classic: {
		league: "share/classic_league_invite",
		team: "share/classic_team",
		pre_season_team_img: "share/preseason_classic_team_image",
		pre_season_team: "share/preseason_classic_team",
		classic_matchup: "share/classic_matchup",
		classic_matchup_img: "share/classic_matchup_image"
	},
	draft: {
		league: "share/draft_league_invite",
		team: "",
		draft_matchup: "share/draft_matchup",
		draft_matchup_img: "share/draft_matchup_image"
	}
};

const shareTwitter = data => {
	const {
		is_classic,
		is_scheduled,
		league_id,
		league_name,
		round_id,
		share_type,
		team_id,
		user_id
	} = data;

	const endpoints = is_classic ? 
		share_endpoints.classic : share_endpoints.draft;
	const data_for_share = {
		league_id,
		team_id,
		user_id,
		tc: getTimestamp()
	};
	const team_data_for_share = {
		team_id,
		round_id
	};
	const is_league_share = share_type === "league";
	let share_endpoint = is_league_share ?
		endpoints.league : endpoints.team;
	if(share_type === "team_preseason"){
		share_endpoint = share_endpoints.classic.pre_season_team;
	}
	const share_data = is_league_share ? 
		data_for_share : team_data_for_share;
	const href = getLinkForShare(share_data, share_endpoint);
	const teamMessage = isAllTrue([round_id <=1, is_scheduled]) ? 
		`I've created my team for AFL Fantasy ${SEASON_YEAR}! #AFLFantasy`:
		"How well did you score this round!? #AFLFantasy";
	const messages = {
		league_share_message:
		"I have entered in " + league_name + " comp on the new and improved " +
		`AFL Classic game! Looking forward to season ${SEASON_YEAR}? ` +
		"Sign up and join me here #AFLFantasy",
		team_inseason_share_message:
			"I scored <256 in round 5 in leaguename. How did you go this week? #AFLFantasy",
		team_preseason_message:
			"I have just created my team for AFL Classic! Sign up and join in here #AFLFantasy",
		team: teamMessage,
		team_preseason: 
			`I've created my team for AFL Fantasy ${SEASON_YEAR}! #AFLFantasy`
	};
	const message = messages[share_type] || messages.league_share_message;

	data.tc = getTimestamp();
	window.open(
		"https://twitter.com/intent/tweet?url=" + encodeURIComponent(href) +
		"&text=" + encodeURIComponent(message),
		"twitter-share-dialog",
		"width=626,height=436",
	);
};

const shareFacebook = data => {
	const {
		is_classic,
		league_id,
		share_type,
		team_id,
		user_id
	} = data;

	const endpoints = is_classic ? 
		share_endpoints.classic : share_endpoints.draft;
	let share_endpoint = share_type === "league" ?
		endpoints.league : endpoints.team;
	if(share_type === "team_preseason"){
		share_endpoint = share_endpoints.classic.pre_season_team;
	}
	const FB = window.FB;
	const data_for_share = {
		league_id,
		team_id,
		user_id,
		tc: getTimestamp(),
	};
	if (FB) {
		FB.ui({
			method: "share",
			display: "popup",
			href: getLinkForShare(data_for_share, share_endpoint),
		},identity);
	}
};

export const shareFbMatchup = (data: Object) => {
	const {
		is_classic,
		league_id,
		round_id,
		team_id,		
	} = data;
	const endpoint = is_classic ? share_endpoints.classic.classic_matchup : share_endpoints.draft.draft_matchup;
	const FB = window.FB;
	const data_for_share = {
		league_id,
		team_id,
		sc: "fb",
		round_id,
		tc: getTimestamp(),
	};
	if (FB) {
		FB.ui({
			method: "share",
			display: "popup",
			href: getLinkForShare(data_for_share, endpoint),
		},identity);
	}
};
export const shareTwitterMatchup = (data: Object) => {
	const {
		is_classic,
		league_id,
		round_id,
		team_id,
	} = data;

	const endpoint = is_classic ? share_endpoints.classic.classic_matchup : share_endpoints.draft.draft_matchup;
	const data_for_share = {
		league_id,
		team_id,
		round_id,
		sc: "tw",
		tc: getTimestamp()
	};
	
	
	const share_data = data_for_share;
	const href = getLinkForShare(share_data, endpoint);

	const classicOrDraft = is_classic ? "Classic" : "Draft";

	
	const message = `Check out my matchup results in AFL Fantasy ${classicOrDraft} from Round ${round_id}`;

	data.tc = getTimestamp();
	window.open(
		"https://twitter.com/intent/tweet?url=" + encodeURIComponent(href) +
		"&text=" + encodeURIComponent(message),
		"twitter-share-dialog",
		"width=626,height=436",
	);
};

export const shareDynamicPreseasonImg = (data: Object) => {
	const {
		share_type = "fb",
		team_id,
	} = data;
	const endpoint = share_endpoints.classic.pre_season_team_img;
	const data_for_share = {
		team_id,
		sc: share_type,
		t: getTimestamp()
	};
	return getDynamicImgForShare(data_for_share, endpoint);
};

export const shareDynamicMatchupImg = (data: Object) => {
	const {
		share_type,
		team_id,
		round_id,
		league_id,
		is_classic,
	} = data;
	let endpoint = "";

	if(isAllTrue([
		is_classic,
		!share_type
	])){
		endpoint = share_endpoints.classic.classic_matchup_img;
	}
	if(!is_classic && !share_type){
		endpoint = share_endpoints.draft.draft_matchup_img;
	}
	const data_for_share = {
		team_id,
		round_id,
		league_id,
		sc: "fb",
	};
	return getLinkForShare(data_for_share, endpoint);

};

type Props = {
	is_classic: boolean,
	team_id: number,
	round_id: number,
	league_id?: number,
	share_type: string,
	is_scheduled: boolean,
	user_id?: number,
};

interface Navigator {	
	share: (data?: ShareData)=> Promise<void>;
}

const navigator: Navigator = window.navigator;

export const handleMobileShare = async (data: Props) => {
	const {
		is_classic,
		is_scheduled,
		league_id,
		round_id,
		share_type,
		team_id,
		user_id
	} = data;

	const endpoints = is_classic ? 
		share_endpoints.classic : share_endpoints.draft;
	const data_for_share = {
	 	league_id,
	 	team_id,
	 	user_id,
	 	tc: getTimestamp()
	 };
	const team_data_for_share = {
		team_id,
		round_id
	};
	const is_league_share = share_type === "league";
	let share_endpoint = is_league_share ?
		endpoints.league : endpoints.team;
	if(share_type === "team_preseason"){
		share_endpoint = share_endpoints.classic.pre_season_team;
	}
	const share_data = is_league_share ? 
		data_for_share : team_data_for_share;
	const href = getLinkForShare(share_data, share_endpoint);
	const teamMessage = isAllTrue([[0, 1].includes(round_id), is_scheduled]) ? 
		`I've created my team for AFL Fantasy ${SEASON_YEAR}! #AFLFantasy`:
		"How well did you score this round!? #AFLFantasy";

	const shareData = {
		title: 'Fantasy Team Invites',
		text: `${teamMessage}`,
		url: `${href}`,
	};
	
	try{
		await navigator.share(shareData);
	}
	catch(error){
		console.error(error);
	}
};


export const socialShare = function (data: Object) {
	if(data.isImgShare){
		return shareDynamicPreseasonImg(data);
	}
	if (data.sc_type === "tw") {
		shareTwitter(data);
	}
	else {
		shareFacebook(data);
	}
};


export default socialShare;