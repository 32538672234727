// @flow
import styled, { css } from "styled-components";
import _ from "lodash";
import * as React from "react";
import numeral from "numeral";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { FantasyCoachModal } from "../";
import * as actions from "../../modules/actions";
import * as selectors from "../../modules/selectors";
import colors from "../../assets/css/colors";
import { currencyFormat } from "../../utils";
import Lock from "../Icons/Lock";
import type { TClassicTeam, TRound, TRoundsWithMatches, TPlayersById } from "../../modules/types";
import { getTeamProjectedScore, getTeamScoreFromLadder } from "../../helpers/team";
import { below } from "../../assets/css/media";

const getGameBarColor = ({ is_coach, is_negative, is_live }) => {
	if (is_coach) {
		return colors.coach;
	}
	if (is_live) {
		return colors.success;
	}
	return is_negative ? colors.warning : "#1D4073";
};

const TeamGameBarStyled = styled.div`
	background: #F8F8FA;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: space-between;
	${({ is_mobile }) => !is_mobile ? css`
		height: 100px;
		${GameBarSection} {
			padding: 0 10px;
		}
	` : css`
		height: auto;
		flex-wrap: nowrap;

		${GameBarSection} {
			padding: 0 5px;
		}

		${GameBarLabel} {
			font-size: 10px;
			${below.phone`
				font-size: 8px;
			`}
		}

		${GameBarValue} {
			font-size: 12px;
		}

		.unlim-symbol {
			font-size: 18px;
		 	margin-top: -2px;
    		display: inline-block;
		}
	`}
`;

const GameBarSection = styled.div`
	position: relative;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	align-content: flex-start;
	flex-direction: column;
	height: 70px;

	border-right: 1px solid ${colors.secondary.lightGrey};
	flex: 1 1 auto;
	box-sizing: border-box;

	${below.tablet`
		height: auto;
	`}

	&:last-child {
		border-right: none;
	}
`;

const GameBarLabel = styled.h3`
	color: ${({ is_coach }) => is_coach ? colors.coach : "#8D979C"};
	font-size: 10px;
	line-height: 13px;
	margin-bottom: 8px;
	text-align: center;
	text-transform: uppercase;
	font-weight: 400;
	height: 26px; /* Some labels are split across 2 lines - make consistent height */

	${below.tablet`
		height: auto;
		white-space: nowrap;
		overflow: none;
		text-overflow: ellipsis;
		max-width: 100%;
	`}

	> span {
		${below.tablet`
			display: none;
		`}
		&.mobile {
			display: none;

			${below.tablet`
				display: inline;
			`}
		}
	}
`;

const GameBarValue = styled.p`
	color: ${getGameBarColor};
	font-size: 18px;
	margin-bottom: 5px;
`;

const GameBarSubLabel = styled(GameBarLabel)`
	margin-bottom: 0;

	${below.phone`
		display: none;
	`}
`;

const FcLink = styled.a`
	color: ${colors.coach};
	text-decoration: none;
	cursor: pointer;
`;

type Props = {
	fetchClassicTeamSnapshot: typeof actions.fetchClassicTeamSnapshot,
	snapshot: Object,
	is_mobile: boolean,
	user: Object,
	team_value: number,
	remaining_salary: number,
	is_team_started: boolean,
	team: TClassicTeam,
	onChange?: Function | typeof undefined,
	is_type_field_list: boolean,
	player_points: string,
	has_assistant_coach: boolean,
	selected_round: TRound,
	selected_round_id: number,
	data_loading_complete: boolean,
	players_by_id: TPlayersById,
	label_less_padding?: boolean,
	rounds_with_matches: TRoundsWithMatches,
	show_last_round_score: boolean,
	team_rank: number,
	round_rank: number,
	is_static: boolean,
	is_current_round: boolean,
	bye_detector: React.Node,
}

type State = {
	show_fantasy_coach_modal: boolean,
};

export class TeamGameBarContainer extends React.Component<Props, State> {
	static defaultProps = {
		is_first_round_with_partial_lockout: false,
		is_static: false,
		is_current_round: false,
	};
	constructor() {
		super();

		_.bindAll(this, [
			"toggleFantasyCoachModal",
		]);
	}

	state = {
		show_fantasy_coach_modal: false,
	};

	componentDidMount(): void {
		const { fetchClassicTeamSnapshot, team } = this.props;
		 fetchClassicTeamSnapshot({
			user_id: team.user_id
		});
	}

	shouldComponentUpdate(next_props: Props) {
		return next_props.data_loading_complete;
	}

	get trades_remaining() {
		const
			{ is_team_started, team, is_mobile, selected_round } = this.props,
			{ week_trades_left, week_trades_limit } = team,
			unlimited_text = is_mobile ? (
				<span className="unlim-symbol">{String.fromCharCode(8734)}</span>
			) : "Unlimited";

		const start_round = _.get(team, "start_round", 1);
		const round_id = _.get(selected_round, "id", 1);
		const lockout = _.get(selected_round, "lockout");

		/* Should show unlimited trades:
		 *  - before team has started
		 *  - in partial lockout of team's first round
		 */
		const show_unlimited = !is_team_started
			|| (lockout === "partial" && start_round >= round_id);

		return (
			<GameBarSection>
				<GameBarLabel>
					{`TRADES ${is_mobile ? "REM" : "REMAINING"}`}
				</GameBarLabel>
				<GameBarValue>
					{show_unlimited ? unlimited_text : week_trades_left}
				</GameBarValue>
				{show_unlimited
					? null
					: <GameBarSubLabel>
						OUT OF {week_trades_limit}
					</GameBarSubLabel>}
			</GameBarSection>
		);
	}

	get score_round_id() {
		const { selected_round, show_last_round_score } = this.props;
		const round_id = _.get(selected_round, "id", 1);
		const round_status = _.get(selected_round, "status", "scheduled");

		if (show_last_round_score) {
			return Math.max(round_id - 1, 1); /* Make sure no round 0 */
		}

		// Before the round starts, show score of previous round
		return round_status === "scheduled"
			? Math.max(round_id - 1, 1) /* Make sure no round 0 */
			: round_id;
	}

	get round_score() {
		const { is_mobile, rounds_with_matches, players_by_id, team, is_team_started } = this.props;

		const round_id = this.score_round_id;
		const score_round = _.find(rounds_with_matches, { id: round_id });

		const is_active_match = _.get(score_round, "status") === "active";
		const team_score = getTeamScoreFromLadder({
			team,
			is_active_match,
			round: score_round,
			players_by_id,
			is_classic: true
		});

		return (
			<GameBarSection>
				<GameBarLabel>
					{is_mobile ? "RD" : "ROUND"} {round_id} SCORE
				</GameBarLabel>
				<GameBarValue is_live={is_team_started ? is_active_match : false}>
					{is_team_started ? team_score : "- -"}
				</GameBarValue>
			</GameBarSection>
		);
	}

	get projected_score() {
		const { team, selected_round, players_by_id, user } = this.props;
		const lineup = _.get(team, "lineup", {});
		const userId = _.get(user, 'data.id');
		if(team.user_id !== userId){
			return 0;
		}
		return getTeamProjectedScore(
			lineup,
			players_by_id,
			{ captains: true, emergency: true },
			selected_round,
			true
		);
	}

	toggleFantasyCoachModal() {
		const { show_fantasy_coach_modal } = this.state;
		this.setState({ show_fantasy_coach_modal: !show_fantasy_coach_modal });
	}

	get proj_score() {
		const
			{ has_assistant_coach, is_mobile } = this.props,
			{ show_fantasy_coach_modal } = this.state,
			{ projected_score } = this,
			min_proj = Math.round(projected_score * .95),
			max_proj = Math.round(projected_score * 1.05);

		return (
			<GameBarSection>
				{
					show_fantasy_coach_modal ?
						<FantasyCoachModal
							closeClick={this.toggleFantasyCoachModal}
						/>
						: null
				}
				<GameBarLabel is_coach>
					{is_mobile ? "PROJ." : "PROJECTED"} SCORE
				</GameBarLabel>
				<GameBarValue is_coach>
					{ has_assistant_coach ? projected_score	: (
						<FcLink
							onClick={this.toggleFantasyCoachModal}
						>
							<Lock />
						</FcLink>
					)}
				</GameBarValue>
				{has_assistant_coach ? (
					<GameBarSubLabel is_coach>
						{min_proj} - {max_proj}
					</GameBarSubLabel>
				) : null}
			</GameBarSection>
		);
	}

	get team_value() {
		const { team_value, is_mobile } = this.props;

		return (
			<GameBarSection>
				<GameBarLabel>
					{is_mobile ? "VALUE" : "TEAM VALUE"}
				</GameBarLabel>
				<GameBarValue>
					{currencyFormat({ input: team_value })}
				</GameBarValue>
			</GameBarSection>
		);
	}

	get remaining_salary() {
		const {	remaining_salary, is_mobile } = this.props;

		return (
			<GameBarSection>
				<GameBarLabel>
					{is_mobile ? "REM" : "REMAINING"} SALARY
				</GameBarLabel>
				<GameBarValue is_negative={remaining_salary < 0}>
					{currencyFormat({ input: remaining_salary })}
				</GameBarValue>
			</GameBarSection>
		);
	}

	get overall_rankings() {
		const { team_rank, is_mobile, is_static } = this.props;

		if(is_mobile && !is_static) {
			return null;
		}

		return (
			<GameBarSection>
				<GameBarLabel>
					<span>OVERALL RANKING</span>
					<span className='mobile'>Ovr Rank</span>
				</GameBarLabel>
				<GameBarValue>
					{numeral(team_rank).format("0,0")}
				</GameBarValue>
			</GameBarSection>
		);
	}

	get round_rankings() {
		const {	round_rank, is_mobile, selected_round_id, is_static } = this.props;

		if(is_mobile && !is_static) {
			return null;
		}

		return (
			<GameBarSection>
				<GameBarLabel>
					<span>Round</span>
					<span className='mobile'>Rd</span>
					{" "}
					{selected_round_id}
					{" "}
					<span>Ranking</span>
					<span className='mobile'>Rank</span>
				</GameBarLabel>
				<GameBarValue>
					{numeral(round_rank).format("0,0")}
				</GameBarValue>
			</GameBarSection>
		);
	}

	renderStats() {
		const { is_static, is_current_round } = this.props;

		return <React.Fragment>
			{ !is_static && this.trades_remaining }
			{ this.round_score }
			{ (!is_static || is_current_round) && this.proj_score }
			{ this.team_value }
			{ is_current_round && this.remaining_salary }
			{ is_static && this.round_rankings }
			{ this.overall_rankings }
		</React.Fragment>;
	}

	render() {
		const { is_mobile, label_less_padding } = this.props;

		const stats = this.renderStats();
		return (
			<TeamGameBarStyled label_less_padding={label_less_padding} is_mobile={is_mobile}>
				{ stats }
			</TeamGameBarStyled>
		);
	}
}
const mapStateToProps = (state, props) => {
	const { selected_round_id } = props;
	const selected_round = selectors.rounds.getSelectedRound(
		state, selected_round_id
	);

	const snapshot = state.teamsClassic.snapshot;
	const team_rank = _.get(snapshot, ["rank_history", selected_round_id])
		|| _.get(snapshot, "rank", "- -");
	const round_rank = _.get(snapshot, ["round_rank_history", selected_round_id], "- -");

	return {
		user: state.user,
		snapshot,
		team_value: props.is_static
			? _.get(props.team, "value")
			: selectors.getMyClassicTeamValue(state),
		selected_round,
		team_rank,
		round_rank,
	};
};

const mapDispatchToProps = {
	fetchClassicTeamSnapshot: actions.fetchClassicTeamSnapshot,
};

export const TeamGameBar = withRouter(connect(
	mapStateToProps,
	mapDispatchToProps
)(TeamGameBarContainer));

export default TeamGameBar;