import { ROUNDS_IN_AFL_SEASON } from "../../../helpers";
import * as format from "../../../helpers/numeralFormats";
export const DEFAULT_VALUE = "— —";

export const FINAL_ROUND = ROUNDS_IN_AFL_SEASON;

export const CURRENT_YEAR = process.env.REACT_APP_SEASON_YEAR;

export const FIXTURE_STATS: TStatsTemplate = {
	played: [
		{
			name: "Score",
			id: "stats.score",
			tooltip: "Round Score"
		},
		{
			name: "$ Change",
			id: "stats.price_change",
			format: format.SHORT_PRICE,
			not_draft: true,
			tooltip: "Round Price Change"
		},
		{
			name: "% Change",
			id: "stats.price_change_pct",
			format: format.PERCENTAGE,
			not_draft: true,
			tooltip: "Round Percentage Price Change"
		},
		{
			name: "Price",
			id: "stats.price",
			format: format.TWO_DEC_PRICE,
			not_draft: true,
			tooltip: "Price After Round"
		},
		{
			name: "Average",
			id: "stats.avg_points",
			tooltip: "Average After Round"
		},
		{
			name: "Total",
			id: "stats.total_points",
			tooltip: "Total Points After Round"
		}
	],
	unplayed: [
		{
			name: "Projected",
			id: "stats.proj_score",
			is_coach: true,
			tooltip: "Projected Score"
		},
		{
			name: "Proj $ Change",
			id: "stats.proj_price_change",
			format: format.SHORT_PRICE,
			is_coach: true,
			not_draft: true,
			tooltip: "Projected Round Price Change"
		},
		{
			name: "BE",
			id: "stats.break_even",
			is_coach: true,
			not_draft: true,
			tooltip: "Break Even"
		},
		{
			name: "BE %",
			id: "stats.break_even_pct",
			format: format.PERCENTAGE,
			is_coach: true,
			not_draft: true,
			tooltip: "Percentage Chance of Reaching Break Even"
		},
		{
			name: "Avg vs Opp",
			id: "stats.avg_vs_opp",
			is_coach: true,
			tooltip: "Average Score Against Opponent"
		},
		{
			name: "Venue Avg",
			id: "stats.avg_at_venue",
			is_coach: true,
			tooltip: "Average Score At Venue"
		},
	]
};

export const FIXTURE_CUSTOM_STATS: TStatsTemplate = {
	played: [
		{
			name: "Score",
			id: "custom_stats.score",
			tooltip: "Round Score"
		},
		{
			name: "$ Change",
			id: "stats.price_change",
			format: format.SHORT_PRICE,
			not_draft: true,
			tooltip: "Round Price Change"
		},
		{
			name: "% Change",
			id: "stats.price_change_pct",
			format: format.PERCENTAGE,
			not_draft: true,
			tooltip: "Round Percentage Price Change"
		},
		{
			name: "Price",
			id: "stats.price",
			format: format.TWO_DEC_PRICE,
			not_draft: true,
			tooltip: "Price After Round"
		},
		{
			name: "Average",
			id: "custom_stats.avg_points",
			tooltip: "Average After Round"
		},
		{
			name: "Total",
			id: "custom_stats.total_points",
			tooltip: "Total Points After Round"
		}
	],
	unplayed: [
		{
			name: "Projected",
			id: "custom_stats.proj_score_next_round",
			is_coach: true,
			tooltip: "Projected Score"
		},
		{
			name: "Proj $ Change",
			id: "stats.proj_price_change",
			format: format.SHORT_PRICE,
			is_coach: true,
			not_draft: true,
			tooltip: "Projected Round Price Change"
		},
		{
			name: "BE",
			id: "stats.break_even",
			is_coach: true,
			not_draft: true,
			tooltip: "Break Even"
		},
		{
			name: "BE %",
			id: "stats.break_even_pct",
			format: format.PERCENTAGE,
			is_coach: true,
			not_draft: true,
			tooltip: "Percentage Chance of Reaching Break Even"
		},
		{
			name: "Avg vs Opp",
			id: "custom_stats.avg_vs_opp",
			is_coach: true,
			tooltip: "Average Score Against Opponent"
		},
		{
			name: "Venue Avg",
			id: "custom_stats.avg_at_venue",
			is_coach: true,
			tooltip: "Average Score At Venue"
		},
	]
};