/* eslint max-len: 0 */
// @flow
import React from "react";
import SvgIconContainer from "../SvgIconContainer";

export default (
	{
		unit = "em",
		...rest
	}: Object = {}) => (
	<SvgIconContainer viewBox="0 0 16 17" {...rest} unit={unit}>
		<title>UnChecked</title>
		<rect width="15"height="15"x="0.5"y="0.833"fill="#fff"stroke="#1D4073"rx="7.5" />
	</SvgIconContainer>
);