import { createReducer } from "redux-act";
import * as actions from "../../actions";


const initialState = {
	redirect: "",
};

export const globalRedirect = createReducer( {}, initialState );

globalRedirect.on( actions.globalRedirect, ( _state: any, payload: string ) => ( {
	redirect: payload
} ));