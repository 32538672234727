// @flow
import { PASSWORD_REGEX } from "../../";
export const ChangePassword = (values: Object) => {
	const errors = {};

	if (!values.password) {
		errors.password = "Required";
	}
	else if(!PASSWORD_REGEX.test(values.password)) {
		errors.password = "Please enter a new password for "
			+ "your AFL Fantasy account. This password should "
			+ "include at least eight characters, a mixture of "
			+ "upper and lower case letters, at least one special "
			+ "character (for example $ or !) and at least one number.";
	}

	if(!values.confirm_password){
		errors.confirm_password = "Required";
	}
	else if(values.confirm_password !== values.password) {
		errors.confirm_password = "Passwords must match!";
	}

	return errors;
};