// @flow
import * as React from "react";
import styled from "styled-components";
import default_image from "../../assets/images/LeagueLogo.svg";

import { getPlayerAvatar } from "../../helpers";

const TeamAvatarPicture = styled.img`
	display: block;
	border-radius: 50%;
	overflow: hidden;
	height: ${({ size }) => size || "100%"};
	width: ${({ size }) => size || "100%"};
	object-fit: cover;
`;

type Props = {
	team: {
		user_id: number,
		avatar_version: number,
		is_club?: boolean,
		name: string
	},
	size?: string,
};

type State = {
	error: boolean
}

export class TeamAvatar extends React.Component<Props, State> {
	state = {
		error: false,
	};

	render() {
		const { team, size } = this.props;
		const { error } = this.state;

		const { user_id, avatar_version, name } = team;
		const avatar = getPlayerAvatar({ id: user_id, avatar_version });

		return error 
			? <TeamAvatarPicture 
				src={default_image} 
				alt={`Avatar for ${name}`} 
				size={size} 
			/> 
			: <TeamAvatarPicture 
				src={avatar}
				alt={`Avatar for ${name}`}
				size={size} 
				onError={() => this.setState({ error: true })} 
			/>;
	}
};

export default TeamAvatar;