// @flow
import React from "react";
import styled, { css } from "styled-components";
import * as _ from "lodash";
import type { TUser } from "../../modules/types";
import ButtonPrimary from "../ButtonPrimary";
import colors from "../../assets/css/colors";

// import colors from '../../assets/css/colors';

const AddCommentWrapper = styled.div`
	textarea {
		height: 80px;
		width: 100%;
		padding: 12px 15px;
		font-size: 14px;
		box-sizing: border-box;
		font-family: SourceSansPro;
		border-radius: 2px;

		${({ error }) => error && css`
			border: 1px solid ${colors.buttons.cancel};
			color: ${colors.buttons.cancel};
		`};
	}

	${ButtonPrimary} {
		width: 100%;
		background-color: #00529C;
		border-color: #00529C;

		:hover {
			background: #006dcc;
		}
	}

	margin-bottom: 40px;
`;

const SendError = styled.p`
	color: ${colors.buttons.cancel};
	font-weight: 400;
`;

type Props = {
	user: TUser,
	sendMessage: Function,
	clearErrors: Function,
	send_error: any,
};

type State = {
	message_text: string
}

export class AddComment extends React.PureComponent<Props, State> {
	constructor() {
		super();

		_.bindAll(this, [
			"submitMessage",
			"updateText"
		]);
	}
	state = {
		message_text: ""
	};

	updateText({ currentTarget }: Object) {
		const { clearErrors, send_error } = this.props;
		const new_text = _.get(currentTarget, "value", "");
		if (new_text.length <= 41) {
			this.setState({
				message_text: new_text
			});
		}

		if (send_error) {
			clearErrors();
		}
	}

	submitMessage() {
		const { message_text } = this.state;
		const { sendMessage } = this.props;

		sendMessage(message_text);

		this.setState({
			message_text: ""
		});
	}

	render() {
		const { send_error } = this.props;
		return <AddCommentWrapper error={!_.isEmpty(send_error)}>
			<textarea 
				placeholder="Your Comments..." 
				value={this.state.message_text}
				onChange={this.updateText}
			/>
			{send_error && <SendError>{send_error}</SendError>}
			<ButtonPrimary 
				onClick={this.submitMessage}
			>
				Post your comment
			</ButtonPrimary>
		</AddCommentWrapper>;
	}
}

export default AddComment;