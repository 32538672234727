/* eslint max-len: 0 */
// @flow
import React from "react";
import SvgIconContainer from "../SvgIconContainer";

export default (
	{
		unit = "em",
		...rest
	}: Object = {}) => (
	<SvgIconContainer viewBox="0 0 24 24" {...rest} unit={unit}>
		<title>CautionReset</title>
		<path d="M20.6346667,14.3523333 C16.5823333,14.193 14.0776667,18.635 16.212,22.009
			L2.24433333,22.009 C0.760333333,22.009 -0.167333333,20.4023333 0.574666667,19.117
			L10.3103333,2.25433333 C11.0526667,0.969 12.908,0.969 13.65,2.25433333
			L20.6346667,14.3523333 Z M11.7473333,5.86533333 C10.996,5.86533333 10.394,6.48766667
			10.419,7.23866667 L10.6516667,14.2213333 C10.6756667,14.9376667 11.263,15.506 11.98,15.506
			C12.697,15.506 13.2843333,14.9376667 13.3083333,14.2213333 L13.541,7.23866667
			C13.566,6.48766667 12.964,5.86533333 12.2126667,5.86533333 L11.7473333,5.86533333 Z
			M11.98,20.326 C12.8676667,20.326 13.5866667,19.6066667 13.5866667,18.7193333
			C13.5866667,17.832 12.8673333,17.1126667 11.98,17.1126667 C11.0926667,17.1126667
			10.3733333,17.832 10.3733333,18.7193333 C10.3733333,19.6066667 11.0926667,20.326
			11.98,20.326 Z M20.916,22.0266667 C20.9776667,22.0883333 20.9776667,22.1883333 20.916,22.25
			L20.0663333,23.0996667 C20.009,23.157 19.9113333,23.1163333 19.9113333,23.0353333
			L19.9113333,22.548 C18.3643333,22.294 17.187,20.9353333 17.187,19.3423333 C17.187,18.3033333
			17.69,17.3186667 18.5323333,16.7083333 C18.7363333,16.5613333 19.02,16.6066667
			19.1666667,16.81 C19.314,17.0133333 19.2686667,17.297 19.0656667,17.4443333
			C18.4583333,17.884 18.0956667,18.5933333 18.0956667,19.3423333 C18.0956667,20.4383333
			18.8713333,21.3743333 19.9113333,21.6163333 L19.9113333,21.241 C19.9113333,21.16
			20.0093333,21.1196667 20.0663333,21.1766667 L20.916,22.0266667 Z M23.6866667,19.3423333
			C23.6866667,20.3833333 23.1823333,21.369 22.337,21.979 C22.2566667,22.037 22.164,22.065
			22.0716667,22.065 C21.9306667,22.065 21.7916667,21.9996667 21.703,21.8766667
			C21.556,21.6733333 21.602,21.3893333 21.8053333,21.2423333 C22.414,20.8023333 22.778,20.092
			22.778,19.342 C22.778,18.245 22.0103333,17.3116667 20.9623333,17.07 L20.9623333,17.4433333
			C20.9623333,17.5243333 20.8643333,17.5646667 20.8073333,17.5076667 L19.9576667,16.658
			C19.896,16.5963333 19.896,16.4963333 19.9576667,16.4346667 L20.8073333,15.585
			C20.8646667,15.5276667 20.9623333,15.5683333 20.9623333,15.6493333 L20.9623333,16.1376667
			C22.5223333,16.3923333 23.6866667,17.7483333 23.6866667,19.3423333 Z" />

	</SvgIconContainer >
);