// @flow
import React, { Component } from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Cookies from "js-cookie";

import Preloader from "../Preloader";
import { isAuthenticated } from "../../utils/auth";

import * as selectors from "../../modules/selectors";
import { loginToken } from "../../modules/actions";
import {TUser} from "../../modules/types";
import { isAllTrue } from "../../helpers";

type Props = {
	component: Object,
	is_authenticated: boolean,
	has_user: boolean,
	user: TUser,
	render?: Function,
	location: {
		pathname: string
	},
	loginToken: Function
};

type State = {
	user_requested_url?: string
}

const mapStateToProps = state => ({
	is_authenticated: isAuthenticated(),
	has_user: selectors.hasUser(state),
	user: selectors.getUser(state)
});

class PrivateRouteContainer extends Component<Props, State> {
	componentDidUpdate(prevProps){
		if(prevProps.is_authenticated !== this.props.is_authenticated){
			window.location.reload();
		}
	}

	get check_is_from_app() {
		const params = new URLSearchParams(window.location.search);
		const from_app = params.get("from_app");
		return Boolean(from_app);

	}

	
	loginWithToken() {
		if(!this.check_is_from_app){
			return;
		}
		const params = new URLSearchParams(window.location.search);
		const token = params.get("token");
		this.props.loginToken({ token });
	}

	render() {
		const {
			component: Component,
			is_authenticated,
			has_user,
			user,
			render,
			location: {
				pathname
			},
			...rest
		} = this.props;

		const first_attempt_not_logged_user = Cookies.get("first_attempt_not_logged_user");
		if(isAllTrue([this.check_is_from_app, !is_authenticated])){
			this.loginWithToken();
			return (
				<Route {...rest} render={(props: Object) => (
					is_authenticated ? <Component {...props} /> : <Preloader />
				)} />
			);
		}
		if(isAllTrue([this.check_is_from_app, is_authenticated])){
			return (
				<Route {...rest} render={(props: Object) => (
					 <Component {...props} />
				)} />
			);
		}
		if(isAllTrue([has_user, is_authenticated, !user.recovered])){
			return <Redirect to="/login/mini-rego" />;
		}
		if (!is_authenticated) {
			Cookies.set("first_attempt_not_logged_user", pathname, { expires: 3 });
			return <Redirect to="/" />;
		}

		if (isAllTrue([is_authenticated, first_attempt_not_logged_user])) {
			Cookies.remove("first_attempt_not_logged_user");
			return <Redirect to={first_attempt_not_logged_user} />;
		}

		// if (isAllTrue([has_user, user.recovered === 0])) {
		// 	return <Redirect to="/recover" />;
		// }
		
		if (render) {
			return (
				<Route {...rest} render={(props: Object) => (
					is_authenticated ? render(props) : <Preloader />
				)} />
			);
		}

		return (
			<Route {...rest} render={(props: Object) => (
				is_authenticated ? <Component {...props} /> : <Preloader />
			)} />
		);
	}
}

const mapDispatchToProps = {
	loginToken
};

export const PrivateRoute = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(PrivateRouteContainer));

export default PrivateRoute;