// @flow
import React from "react";
import styled from "styled-components";

import colors from "../../assets/css/colors";
import { below } from "../../assets/css/media";
import Tick from "../../components/Icons/Tick";

const CoachListWrapper = styled.div`
	display: flex;
	margin: 1em 0;

	${below.tablet`
		display: block;
	`}
`;

const CoachList = styled.ul`
	li {
		display: flex;
		width: 300px;
		align-items: center;
		min-height: 30px;
		padding: 0 .5em;
		background-color: #FFFFFF;
		margin: .5em .5em .5em 0;
		${({ isAccountPage }) => isAccountPage && `
			background-color: #FEF7ED;
		`}

		${below.tablet`
			width: auto;
		`}

		span {
			color: ${colors.coach};
			text-decoration: none;
			font-size: 15px;

			&.more-info {
				padding-left: 2em;
			}
		}

		svg {
			margin-right: .5em;
		}
	}
`;

type Props = {
	isAccountPage?: boolean
}

export default ({ isAccountPage }: Props) => (
	<CoachListWrapper>
		<CoachList isAccountPage={isAccountPage}>
			<li>
				<Tick color={colors.coach} size='1.2'/>
				<span>Detailed stats insights</span>
			</li>
			<li>
				<Tick color={colors.coach} size='1.2'/>
				<span>Score projections</span>
			</li>
			<li>
				<Tick color={colors.coach} size='1.2'/>
				<span>Break even analysis</span>
			</li>
		</CoachList>
		<CoachList isAccountPage={isAccountPage}>
			<li>
				<Tick color={colors.coach} size='1.2'/>
				<span>Venue and opposition stats</span>
			</li>
			<li>
				<Tick color={colors.coach} size='1.2'/>
				<span>Bye detector</span>
			</li>
			<li>
				<span className='more-info'>...And more</span>
			</li>
		</CoachList>
	</CoachListWrapper>
);