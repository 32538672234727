// @flow
import * as React from "react";
import { connect } from "react-redux";
import * as actions from "../../modules/actions";

type Props = {
	match: {
		params: {league_id: string, game: string}
	},
	setSelectedLeague: Function,
	setSelectedClassicLeague: Function
};

class SetSelectedLeagueComponent extends React.Component<Props> {
	componentDidMount() {
		const {
			match: { params },
			setSelectedLeague,
			setSelectedClassicLeague
		} = this.props;

		if (params && params.league_id && params.game === "draft") {
			setSelectedLeague(params.league_id);
		}
		if (params && params.league_id && params.game === "classic") {
			setSelectedClassicLeague(params.league_id);
		}
	}

	componentDidUpdate(prevProps) {
		const {
			match: { params },
			setSelectedLeague,
			setSelectedClassicLeague
		} = this.props;

		if (params.league_id !== prevProps.match.params.league_id &&
			params.game === "draft") {
			setSelectedLeague(params.league_id);
		}

		if (params.league_id !== prevProps.match.params.league_id &&
			params.game === "classic") {
			setSelectedClassicLeague(params.league_id);
		}
	}

	render() {
		return null;
	}
}

const mapStateToProps = null;

const mapDispatchToProps = ({
	setSelectedLeague: actions.uiSelectedLeague.setSelectedLeague,
	setSelectedClassicLeague: actions.uiSelectedLeague.setSelectedClassicLeague
});

export const SetSelectedLeague = connect(
	mapStateToProps,
	mapDispatchToProps,
)(SetSelectedLeagueComponent);

export default SetSelectedLeague;