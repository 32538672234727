// @flow
import _, { get } from 'lodash';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { isAllTrue } from '../../../helpers';
import * as actions from "../../actions/leaguesDraft";

const checkErrorsIn = errors => {
	if (_.size(errors)) {
		throw new Error(_.get(_.first(errors), 'text', 'Unknown error'));
	}
};
export const createSagas = (API: Object): Object => {

	function* createDraftLeague(action) {
		try {
			const { success, result, errors } = yield call(
				API.leaguesDraft.create,
				{ ...action.payload }
			);
			if (!success) {
				throw new Error(errors[0].text);
			}

			const { scoreValues, subscribeValues } = action.payload;

			const customScoring = yield call(API.leaguesDraft.customScoring, {
				...scoreValues,
				league_id: result.id,
			});

			if (!customScoring.success) {
				throw new Error(customScoring.errors[0].text);
			}
			
			if(isAllTrue([
				!_.isEmpty(subscribeValues),
				subscribeValues.plan !== ""
			])) {
				const subscribeData = yield call(API.leaguesDraft.subscribe, {
					...subscribeValues,
					league_id: result.id
				});
				if (!subscribeData.success) {
					throw new Error(subscribeData.errors[0].text);
				}
			}

			yield put(actions.createDraftLeagueSuccess(result));
		}
		catch (e) {
			if (process.env.NODE_ENV !== 'test') {
				console.error(e);
			}
			yield put(actions.createDraftLeagueFailed(e.message));
		}
	}

	function* updateDraftLeague(action) {
		try {
			const { success, result, errors } =
				yield call(API.leaguesDraft.update, { ...action.payload });

			if (!success) {
				throw new Error(errors[0].text);
			}

			const { scoreValues, subscribeValues } = action.payload;
			if(!action.payload.skipCustomScoring){
				const customScoring = yield call(API.leaguesDraft.customScoring, {
					...scoreValues,
					league_id: result.id,
				});
				if (!customScoring.success) {
					throw new Error(customScoring.errors[0].text);
				}
			}
			

			if(isAllTrue([
				!_.isEmpty(subscribeValues),
				get(subscribeValues, 'plan') !== ""
			])) {
				const subscribeData = yield call(API.leaguesDraft.subscribe, {
					...subscribeValues,
					league_id: result.id
				});
				if (!subscribeData.success) {
					throw new Error(subscribeData.errors[0].text);
				}
			}

			yield put(actions.updateDraftLeagueSuccess(result));
		}
		catch (e) {
			
			console.error(e);
			
			yield put(actions.updateDraftLeagueFailed(e.message));
		}
	}
	function* createDraftLeagueUF(action) {
		try {
			const { success, result, errors } = yield call(
				API.leaguesDraft.create_uf,
				{ ...action.payload }
			);
			if (!success) {
				throw new Error(errors[0].text);
			}

			const { scoreValues, subscribeValues } = action.payload;

			const customScoring = yield call(API.leaguesDraft.customScoring, {
				...scoreValues,
				league_id: result.id,
			});

			if (!customScoring.success) {
				throw new Error(customScoring.errors[0].text);
			}
			
			if(!_.isEmpty(subscribeValues) && subscribeValues.plan !== "") {
				const subscribeData = yield call(API.leaguesDraft.subscribe, {
					...subscribeValues,
					league_id: result.id
				});
				if (!subscribeData.success) {
					throw new Error(subscribeData.errors[0].text);
				}
			}

			yield put(actions.createDraftLeagueUFSuccess(result));
		}
		catch (e) {
			yield put(actions.createDraftLeagueUFFailed(e.message));
		}
	}


	function* showDraftLeague(action) {
		try {
			const { success, result, errors } =
				yield call(API.leaguesDraft.show, { ...action.payload });
			if (success === 1) {
				yield put(actions.showDraftLeagueSuccess(result));
			}
			else {
				yield put(actions.showDraftLeagueFailed(errors[0].text));
			}
		}
		catch (e) {
			if (process.env.NODE_ENV !== 'test') {
				console.error(e);
			}
			yield put(actions.showDraftLeagueFailed(e.message));
		}
	}

	function* showMyDraftLeagues(action) {
		try {
			const { success, result, errors } =
				yield call(API.leaguesDraft.showMy, { ...action.payload });
			if (success === 1) {
				yield put(actions.showMyDraftLeaguesSuccess(result));
			}
			else {
				yield put(actions.showMyDraftLeaguesFailed(errors[0].text));
			}
		}
		catch (e) {
			if (process.env.NODE_ENV !== 'test') {
				console.error(e);
			}
			yield put(actions.showMyDraftLeaguesFailed(e.message));
		}
	}

	function* postWaiverAddDropPlayers({payload}) {
		try {
			const { result, errors } = yield call(
				API.leaguesDraft.waiver_add_drop_players,
				payload
			);
			
			checkErrorsIn(errors);
			yield put(actions.postWaiverAddDropPlayersSuccess({
				team_id: payload.team_id, 
				team: result
			}));
		} 
		catch (error) {
			yield put(actions.postWaiverAddDropPlayersFailed(error.message));
		}
	}

	function* showToJoinDraftLeagues(action) {
		try {
			const { success, result, errors } =
				yield call(API.leaguesDraft.showToJoin, { ...action.payload });

			if (!success) {
				throw new Error(errors[0].text);
			}

			yield put(actions.showToJoinDraftLeaguesSuccess({
				offset: action.payload.offset,
				result,
			}));
		}
		catch (e) {
			if (process.env.NODE_ENV !== 'test') {
				console.error(e);
			}
			yield put(actions.showToJoinDraftLeaguesFailed(e.message));
		}
	}

	function* showToJoinLoadMoreDraftLeagues(action) {
		try {
			const { success, result, errors } =
				yield call(API.leaguesDraft.showToJoin, { ...action.payload });

			if (!success) {
				throw new Error(errors[0].text);
			}

			yield put(actions.showToJoinLoadMoreDraftLeaguesSuccess({
				offset: action.payload.offset,
				result,
			}));
		}
		catch (e) {
			if (process.env.NODE_ENV !== 'test') {
				console.error(e);
			}
			yield put(actions.showToJoinLoadMoreDraftLeaguesFailed(e.message));
		}
	}

	function* removeDraftLeague(action) {
		try {
			const { success, errors } =
				yield call(API.leaguesDraft.remove, { ...action.payload });

			if (!success) {
				throw new Error(errors[0].text);
			}

			yield put(actions.removeDraftLeagueSuccess(action.payload.id));
		}
		catch (e) {
			if (process.env.NODE_ENV !== 'test') {
				console.error(e);
			}
			yield put(actions.removeDraftLeagueFailed(e));
		}
	}

	function* leaveDraftLeague(action) {
		try {
			const { success, errors } =
				yield call(API.leaguesDraft.leave, { ...action.payload });

			if (!success) {
				throw new Error(errors[0].text);
			}

			yield put(actions.leaveDraftLeagueSuccess(
				action.payload.league_id,
			));
		}
		catch (e) {
			if (process.env.NODE_ENV !== 'test') {
				console.error(e);
			}
			yield put(actions.leaveDraftLeagueFailed(e.message));
		}
	}

	function* removeTeamDraftLeague(action) {
		try {
			const { success, errors } =
				yield call(API.leaguesDraft.removeTeam, { ...action.payload });

			if (!success) {
				throw new Error(errors[0].text);
			}

			yield put(actions.removeTeamDraftLeagueSuccess({
				team_id: action.payload.team_id,
			}));
		}
		catch (e) {
			if (process.env.NODE_ENV !== 'test') {
				console.error(e);
			}
			yield put(actions.removeTeamDraftLeagueFailed(e.message));
		}
	}

	function* joinToDraftLeague(action) {
		try {
			const { success, result, errors } =
				yield call(API.leaguesDraft.joinTo, { ...action.payload });

			if (!success) {
				throw new Error(errors[0].text);
			}

			yield put(actions.joinToDraftLeagueSuccess({
				team_id: result.team_id,
				league_id: result.league_id,
			}));
			const leaguesUpdate = yield call(API.leaguesDraft.showMy);
			if(!leaguesUpdate.success){
				throw new Error(leaguesUpdate.errors[0].text);
			}
			yield put(actions.showMyDraftLeaguesSuccess(leaguesUpdate.result));
		}
		catch (e) {
			if (process.env.NODE_ENV !== 'test') {
				console.error(e);
			}
			yield put(actions.joinToDraftLeagueFailed(e.message));
		}
	}

	function* showTeamsDraftLeague(action) {
		try {
			const { success, result, errors } =
				yield call(API.leaguesDraft.showTeams, { ...action.payload });

			if (!success) {
				throw new Error(errors[0].text);
			}

			yield put(actions.showTeamsDraftLeagueSuccess({
				league_id: action.payload.league_id,
				result,
			}));
		}
		catch (e) {
			if (process.env.NODE_ENV !== 'test') {
				console.error(e);
			}
			yield put(actions.showTeamsDraftLeagueFailed(e.message));
		}
	}

	function* inviteToDraftLeague(action) {
		try {
			const { success, result, errors } =
				yield call(API.leaguesDraft.inviteTo, { ...action.payload });

			if (!success) {
				throw new Error(errors[0].text);
			}

			yield put(actions.inviteToDraftLeagueSuccess({ result }));
		}
		catch (e) {
			if (process.env.NODE_ENV !== 'test') {
				console.error(e);
			}
			yield put(actions.inviteToDraftLeagueFailed(e.message));
		}
	}

	function* ladderDraftLeague(action) {
		try {
			const { success, result, errors } =
				yield call(API.leaguesDraft.ladder, { ...action.payload });

			if (!success) {
				throw new Error(errors[0].text);
			}

			yield put(actions.ladderDraftLeagueSuccess({
				league_id: action.payload.league_id,
				result,
			}));
		}
		catch (e) {
			if (process.env.NODE_ENV !== 'test') {
				console.error(e);
			}
			yield put(actions.ladderDraftLeagueFailed(e.message));
		}
	}

	function* changeDraftOrder(action) {
		try {
			const { success, result, errors } =
				yield call(API.leaguesDraft.customDraftOrder, { ...action.payload });

			if (!success) {
				throw new Error(errors[0].text);
			}

			yield put(actions.changeDraftOrderSuccess({
				draft_order: action.payload.team,
				result,
			}));
		}
		catch (e) {
			if (process.env.NODE_ENV !== 'test') {
				console.error(e);
			}
			yield put(actions.changeDraftOrderFailed(e.message));
		}
	}

	function* showDraftOrder(action) {
		try {
			const { success, result, errors } =
				yield call(API.leaguesDraft.showOrder, { ...action.payload });

			if (!success) {
				throw new Error(errors[0].text);
			}

			yield put(actions.showDraftOrderSuccess({
				league_id: action.payload.league_id,
				result,
			}));
		}
		catch (e) {
			if (process.env.NODE_ENV !== 'test') {
				console.error(e);
			}
			yield put(actions.showDraftOrderFailed(e.message));
		}
	}

	function* showCustomFullDraftOrder(action) {
		try {
			const { success, result, errors } =
				yield call(API.leaguesDraft.showCustomFullDraftOrder, { ...action.payload });

			if (!success) {
				throw new Error(errors[0].text);
			}

			yield put(actions.showCustomFullDraftOrderSuccess({
				league_id: action.payload.league_id,
				result,
			}));
		}
		catch (e) {
			if (process.env.NODE_ENV !== 'test') {
				console.error(e);
			}
			yield put(actions.showCustomFullDraftOrderFailed(e.message));
		}
	}

	function* saveCustomFullDraftOrder(action) {
		try {
			const { success, result, errors } =
				yield call(API.leaguesDraft.saveCustomFullDraftOrder, { ...action.payload });
			if (!success) {
				throw new Error(errors[0].text);
			}

			yield put(actions.saveCustomFullDraftOrderSuccess({
				league_id: action.payload.league_id,
				result,
			}));
		}
		catch (e) {
			if (process.env.NODE_ENV !== 'test') {
				console.error(e);
			}
			yield put(actions.saveCustomFullDraftOrderFailed(e.message));
		}
	}

	function* myList(action) {
		try {
			const { success, result, errors } =
			yield call(API.leaguesDraft.myList, { ...action.payload });

			if (!success) {
				throw new Error(errors[0].text);
			}

			yield put(actions.myListSuccess(result));
		}
		catch (e) {
			if (process.env.NODE_ENV !== 'test') {
				console.error(e);
			}
			yield put(actions.myListFailed(e.message));
		}
	}

	function* fetchWaiverOrder(action) {
		try {
			const { success, result, errors } =
				yield call(API.leaguesDraft.waiverOrder, { ...action.payload });

			if (!success) {
				throw new Error(errors[0].text);
			}

			yield put(actions.fetchWaiverOrderSuccess(result));
		}
		catch (e) {
			if (process.env.NODE_ENV !== 'test') {
				console.error(e);
			}
			yield put(actions.fetchWaiverOrderFailed(e.message));
		}
	}

	function* fetchWaiverList(action) {
		try {
			const { success, result, errors } =
				yield call(API.leaguesDraft.waiverList, { ...action.payload });

			if (!success) {
				throw new Error(errors[0].text);
			}

			yield put(actions.fetchWaiverListSuccess(result));
		}
		catch (e) {
			if (process.env.NODE_ENV !== 'test') {
				console.error(e);
			}
			yield put(actions.fetchWaiverListFailed(e.message));
		}
	}

	function* fetchWaiverFree(action) {
		try {
			const { success, result, errors } =
				yield call(API.leaguesDraft.waiverFree, { ...action.payload });

			if (!success) {
				throw new Error(errors[0].text);
			}

			yield put(actions.fetchWaiverFreeSuccess(result));
		}
		catch (e) {
			if (process.env.NODE_ENV !== 'test') {
				console.error(e);
			}
			yield put(actions.fetchWaiverFreeFailed(e.message));
		}
	}

	function* fetchWaiverRequests(action) {
		try {
			const { success, result, errors } =
				yield call(API.leaguesDraft.waiverRequests, { ...action.payload });

			if (!success) {
				throw new Error(errors[0].text);
			}

			yield put(actions.fetchWaiverRequestsSuccess(result));
		}
		catch (e) {
			if (process.env.NODE_ENV !== 'test') {
				console.error(e);
			}
			yield put(actions.fetchWaiverRequestsFailed(e.message));
		}
	}

	function* fetchLeagueRosters(action) {
		try {
			const { success, result, errors } =
				yield call(API.leaguesDraft.rosters, { ...action.payload });

			if (!success) {
				throw new Error(errors[0].text);
			}

			yield put(actions.fetchLeagueRostersSuccess(result));
		}
		catch (e) {
			if (process.env.NODE_ENV !== 'test') {
				console.error(e);
			}
			yield put(actions.fetchLeagueRostersFailed(e.message));
		}
	}

	function* requestServerAndLocalTimeDiff() {
		try {
			const
				start_time = Date.now(),
				{ success, result, errors } = yield call(API.leaguesDraft.server_time);

			if (!success) {
				throw new Error(errors[0].text);
			}

			const
				end_time = Date.now(),
				request_perform_time = end_time - start_time,
				server_time = (new Date(result)).valueOf() + request_perform_time,
				server_time_diff = Date.now() - server_time;

			yield put(actions.requestServerAndLocalTimeDiffSuccess(server_time_diff));
		}
		catch (e) {
			yield put(actions.requestServerAndLocalTimeDiffFailed(e.message));
		}
	}

	function* fetchTransactionHistory({ payload }) {
		try {
			const { result, errors } = yield call(API.leaguesDraft.transactionHistory, payload);
			checkErrorsIn(errors);
			yield put(actions.fetchTransactionHistorySuccess(result));
		}
		catch (error) {
			yield put(actions.fetchTransactionHistoryFailed(error.message));
		}
	}

	function* regenerateListDraftLeagues() {
		try {
			const { result, errors } = yield call(API.leaguesDraft.regenerateList, { limit: 999 });
			checkErrorsIn(errors);
			yield put(actions.regenerateListDraftLeaguesSuccess(result));
		}
		catch (error) {
			yield put(actions.regenerateListDraftLeaguesFailed(error.message));
		}
	}

	function* regenerateShowDraftLeague({ payload }) {
		try {
			const { result, errors } = yield call(API.leaguesDraft.regenerateShow, payload);
			checkErrorsIn(errors);
			yield put(actions.regenerateShowDraftLeagueSuccess(result));
		}
		catch (error) {
			yield put(actions.regenerateShowDraftLeagueFailed(error.message));
		}
	}
	function* regenerateShowDraftLeagueUF({ payload }) {
		try {
			const { result, errors } = yield call(API.leaguesDraft.regenerateShowUF, payload);
			checkErrorsIn(errors);
			yield put(actions.regenerateShowDraftLeagueUFSuccess(result));
		}
		catch (error) {
			yield put(actions.regenerateShowDraftLeagueUFFailed(error.message));
		}
	}
	function* subscribeLeague({ payload }) {
		try {
			const { result, errors } = yield call(
				API.leaguesDraft.subscribe,
				payload
			);
			if (errors.length) {
				yield put(
					actions.subscribeLeagueFailed({ message: errors[0].text })
				);
			}
			else {
				yield put(actions.subscribeLeagueSuccess(result));
				const newPayload = {
					id: payload.league_id,
					value: payload.plan,
					key: 'subscription'
				};
				yield put(actions.draftLeagueSettingsUpdate(newPayload));
				const {
					token,
					plan,
					league_id
				} = payload;
				const costObj = {
					'lite': 29.99,
					'full': 49.99,
				};
				window.gtag('event', 'purchase', {
					transaction_id: token,
					affiliation: "Fantasy Web | League Subscription",
					value: _.get(costObj, `[${plan}]`, 0),
					currency: "AUD",
					items: [
						{
							id: plan,
							name: `AFL Pro Subscription (league) - ${plan}`,
							category: `league ${league_id}` 
						}
					]
				});
			}
		}
		catch (error) {
			console.log(error);
		}
	}
	function* changeTeamCoach({ payload }) {
		try {
			const { result, errors } = yield call(
				API.leaguesDraft.changeCoach,
				payload
			);
			if(errors.length){
				yield put(
					actions.changeTeamCoachFailed({ message: errors[0].text })
				);
			}
			else{
				const { team_id } = payload;
				const team_result = { ...result, team_id: team_id, league_id: payload.league_id };
				yield put(actions.changeTeamCoachSuccess(team_result));
			}
		}
		catch(error){
			console.log(error);
		}
	}
	function* fetchTeamOverrideScores(action) {
		try {
			const { result, errors } = 
        yield call(API.leaguesDraft.teamOverrideScores, 
        	{ ...action.payload });
			if(errors.length){
				throw new Error(errors[0].text);
			}
			yield put(actions.fetchTeamOverrideScoresSuccess(result));
		}
		catch (error) {
			yield put(actions.fetchTeamOverrideScoresFailed(error));
		}
	}

	function* teamOverrideScoresUpdate(action) {
		try {
			const { result, errors } = 
        yield call(API.leaguesDraft.updateTeamScores, 
        	{ ...action.payload });
			if(errors.length){
				throw new Error(errors[0].text);
			}
			yield put(actions.teamOverrideScoresUpdateSuccess(result));
		}
		catch (error) {
			yield put(actions.teamOverrideScoresUpdateFailed(error));
		}
	}

	function* saveKeeperDraftPicks(action) {
		try {
			const { result, errors } = 
        yield call(API.leaguesDraft.saveKeeperDraftPicks, 
        	{ ...action.payload });
			if(errors.length){
				throw new Error(errors[0].text);
			}
			yield put(actions.saveKeeperDraftPicksSuccess(result));
		}
		catch (error) {
			yield put(actions.saveKeeperDraftPicksFailed(error));
		}
	}

	function* watch() {
		yield takeLatest(actions.createDraftLeague, createDraftLeague);
		yield takeLatest(actions.createDraftLeagueUF, createDraftLeagueUF);
		yield takeEvery(actions.updateDraftLeague, updateDraftLeague);
		yield takeLatest(actions.showDraftLeague, showDraftLeague);
		yield takeLatest(actions.showMyDraftLeagues, showMyDraftLeagues);
		yield takeLatest(actions.showToJoinDraftLeagues, showToJoinDraftLeagues);
		yield takeLatest(actions.showToJoinLoadMoreDraftLeagues, showToJoinLoadMoreDraftLeagues);
		yield takeEvery(actions.removeDraftLeague, removeDraftLeague);
		yield takeEvery(actions.leaveDraftLeague, leaveDraftLeague);
		yield takeEvery(actions.removeTeamDraftLeague, removeTeamDraftLeague);
		yield takeLatest(actions.joinToDraftLeague, joinToDraftLeague);
		yield takeLatest(actions.showTeamsDraftLeague, showTeamsDraftLeague);
		yield takeEvery(actions.inviteToDraftLeague, inviteToDraftLeague);
		yield takeLatest(actions.ladderDraftLeague, ladderDraftLeague);
		yield takeEvery(actions.changeDraftOrder, changeDraftOrder);
		yield takeLatest(actions.showDraftOrder, showDraftOrder);
		yield takeLatest(actions.showCustomFullDraftOrder, showCustomFullDraftOrder);
		yield takeLatest(actions.saveCustomFullDraftOrder, saveCustomFullDraftOrder);
		yield takeLatest(actions.myList, myList);
		yield takeLatest(actions.fetchWaiverOrder, fetchWaiverOrder);
		yield takeLatest(actions.fetchWaiverList, fetchWaiverList);
		yield takeLatest(actions.fetchWaiverFree, fetchWaiverFree);
		yield takeLatest(actions.fetchWaiverRequests, fetchWaiverRequests);
		yield takeEvery(actions.fetchLeagueRosters, fetchLeagueRosters);
		yield takeLatest(actions.requestServerAndLocalTimeDiff, requestServerAndLocalTimeDiff);
		yield takeLatest(actions.fetchTransactionHistory, fetchTransactionHistory);
		yield takeLatest(actions.regenerateListDraftLeagues, regenerateListDraftLeagues);
		yield takeEvery(actions.regenerateShowDraftLeague, regenerateShowDraftLeague);
		yield takeEvery(actions.regenerateShowDraftLeagueUF, regenerateShowDraftLeagueUF);
		yield takeEvery(actions.subscribeLeague, subscribeLeague);
		yield takeEvery(actions.changeTeamCoach, changeTeamCoach);
		yield takeEvery(actions.fetchTeamOverrideScores, fetchTeamOverrideScores);
		yield takeEvery(actions.teamOverrideScoresUpdate, teamOverrideScoresUpdate);
		yield takeEvery(actions.saveKeeperDraftPicks, saveKeeperDraftPicks);
		yield takeEvery(actions.postWaiverAddDropPlayers, postWaiverAddDropPlayers);
	}

	return {
		createDraftLeague,
		updateDraftLeague,
		showDraftLeague,
		showMyDraftLeagues,
		showToJoinDraftLeagues,
		showToJoinLoadMoreDraftLeagues,
		joinToDraftLeague,
		showTeamsDraftLeague,
		inviteToDraftLeague,
		ladderDraftLeague,
		changeDraftOrder,
		postWaiverAddDropPlayers,
		showDraftOrder,
		showCustomFullDraftOrder,
		saveCustomFullDraftOrder,
		myList,
		fetchWaiverOrder,
		fetchWaiverRequests,
		fetchLeagueRosters,
		requestServerAndLocalTimeDiff,
		fetchTransactionHistory,
		regenerateListDraftLeagues,
		regenerateShowDraftLeague,
		subscribeLeague,
		watch,
	};
};


export default createSagas;