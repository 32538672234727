// @flow
import styled from "styled-components";
import colors from "../../assets/css/colors";

export const Tabs = styled.ul`
	display: flex;
	align-items: center;
	border-bottom: 1px solid ${colors.secondary.lightGrey};
	height: 40px;
	box-sizing: border-box;
	margin-bottom: 20px;
`;

export default Tabs;