// @flow
import React from "react";
import _ from "lodash";
import { getFirstRoundFinalTemplate } from "../../helpers/league";
import { TPLeague, TLadderTeam, TDraftTeamsById } from "../../modules/types";
import BracketFixture from "./Fixture";

import {
	BracketWrapper,
	LinesTopT,
	LinesTopEnd,
	TwoIntoOneLine,
	LinesCross,
	StraightLine,
	FinalsHeader,
	FinalsWeekWrapper,
	FinalsBracketWrapper,
} from "./FinalsBracketStyles";

import {
	FINALS_EIGHT_TEAMS,
	FINALS_SIX_TEAMS,
	FINALS_FIVE_TEAMS,
	FINALS_FOUR_TEAMS
} from "./lineComponentHelpers";

type EmptyFixtureT = {
  home: string,
  away: string,
}

const emptyFixture: EmptyFixtureT = { home: "", away: "" };

const arrayToObject = array =>
	array.reduce((obj, item) => {
		obj[item.id] = item;
		return obj;
	}, {});



type Props = {
  ladder_teams_by_id: TDraftTeamsById,
  league_current_round_fixtures: Object,
  finals: Object,
  ladder_teams_ordered: {
	  [teamID: number | string]: TLadderTeam,
  },
  league: TPLeague, 
	selected_round_id: Object,
	rounds_with_matches: Array<Object>,
	has_assistant_coach: boolean,
	consolation?: boolean,
  actual_round:Object,
}



type State = {
	current_edit_team_id: number,
};

class FinalsBracket extends React.Component<Props, State> {

	get fixture_teams() {
		const { 
			ladder_teams_ordered, 
			league,
			consolation,
			finals,
			
		} = this.props;
		let template = getFirstRoundFinalTemplate(league); 
		// need to rewrite this part
		if(this.is_finals){
			const finalists = _.orderBy(ladder_teams_ordered, ["rank"])
				.slice(0, (league.finals_format));
			const consolation_finalists = _.orderBy(ladder_teams_ordered, ["rank"])
				.slice(league.finals_format);
			const finalist_group = consolation ? consolation_finalists : finalists;
			const finalists_obj = arrayToObject(finalist_group);
			const finalistsArr = finalist_group.map(finalObj => {
				return finalObj.id;
			});

			const { fixture } = league;
			// fixtures too many need to filter out consolation finals
			const finals_fixture = Object.keys(finals).map((week_id, index) => {
				const getFixture = _.get(fixture, week_id, []);
				const fixturesFiltered = getFixture.filter(fixArr => {
					const homeTeam = fixArr[0];
					const awayTeam = fixArr[1];
					return finalistsArr.includes(homeTeam) || finalistsArr.includes(awayTeam);
				});
				
				return getFixture ? fixturesFiltered : "";
			});
			let finals_template = [];

			// convert finals fixture into the same format as that template
			finals_fixture.forEach((round, index)=> {
				const expectedRoundLength = template[index].fixtures.length;
				if(!round || round.length === 0){
					const matches_in_empty_round = expectedRoundLength;
					const empty_fixture_arr: Array<EmptyFixtureT> = 
            Array.from(Array(matches_in_empty_round).keys()).map((item: any) => emptyFixture);
          
					finals_template.push({ round: index + 1,  
						fixtures: empty_fixture_arr });
					return;
				}
				finals_template.push({ round: index +1, fixtures: [] });
				if(round){
					const currentFinalsTemplate = finals_template[index].fixtures;
					round.forEach((match, m_index) => {
						if(match.length === 1){
							currentFinalsTemplate
								.push({ home: finalists_obj[match[0]], away: "" });
						}
						if(match.length === 2){
							currentFinalsTemplate
								.push({ home: finalists_obj[match[0]], 
									away: finalists_obj[match[1]] });
						}
						if(expectedRoundLength !== round.length){
							const diff = expectedRoundLength - round.length;
							for(let i = 0; i < diff; i++){
								currentFinalsTemplate.push(emptyFixture);
							}
						}
					});
				}			
			});
			return finals_template;	
		}
		
		const current_finalists = _.orderBy(ladder_teams_ordered, 
			["rank_selected_round"]).slice(0, (league.finals_format));

		const current_eliminated = _.orderBy(ladder_teams_ordered,
			["rank_selected_round"]).slice(league.finals_format);

		const finalist_group = consolation ? current_eliminated : current_finalists;

		template.forEach((round_obj, t_index) => {
			round_obj.fixtures.forEach((match, m_index) => {
				finalist_group.forEach((team, f_index) => {
					if(match.home === `${f_index + 1}`){
						template[t_index].fixtures[m_index].home = team;
					};
					if(match.away === `${f_index + 1}`){
						template[t_index].fixtures[m_index].away = team;
					};
				});
			});
		});
		return template;
	};

	get is_finals() {
		const { selected_round_id, finals } = this.props;
		const firstFinalsWeek = Object.keys(finals)[0];
		const finalsCompare = firstFinalsWeek < selected_round_id ? 
			firstFinalsWeek : selected_round_id;
		
		return !_.isEmpty(_.get(finals, finalsCompare));
	}

	get round() {
		const { finals } = this.props;
		return({ id: Object.keys(finals)[0] });
	}
	get finals_teams() {
		const { league } = this.props;
		const format = _.get(league, "finals_format", 0);

		return _(this.fixture_teams)
			.filter(({ home }) => _.get(home, "rank") <= format)
			.map(({ home, away }) => [ _.get(home, "id", 0), _.get(away, "id", 0) ])
			.value();
	}
	get consolation_teams() {
		const { league } = this.props;
		const format = _.get(league, "finals_format", 0);

		return _(this.fixture_teams)
			.filter(({ home }) => _.get(home, "rank") > format)
			.map(({ home, away }) => [ _.get(home, "id", 0), _.get(away, "id", 0) ])
			.value();
	}
	get uneliminated_teams() {
		const { league_current_round_fixtures, league, 
			selected_round_id, ladder_teams_ordered } = this.props;
		const fixture = _.get(league, "fixture", {});
		if(league_current_round_fixtures.length === 0){
			const current_finalists = ladder_teams_ordered.slice(0, (league.finals_format));
			const arr = current_finalists.map(team => team.id);
			return arr;
		}

		const teams = _(fixture)
			.filter((_, round) => round > selected_round_id)
			.reduce(
				(all, round_matches) => [...all, ..._.flatten(round_matches)],
				_.flatten(league_current_round_fixtures)
			);
		return _.uniq(teams);
	}
	get season_finished() {
		const { league } = this.props;
		const round = this.round;

		const status = _.get(round, "status");
		const id = _.get(round, "id", 0);

		const end_round = _.get(league, "end_round", 0);

		return id > end_round || (status === "complete" && id === end_round);
	}

	get eliminated_teams() {
		const { ladder_teams_by_id, league_current_round_fixtures } = this.props;
		const finals_teams = this.uneliminated_teams;
		if(league_current_round_fixtures.length === 0){
			return _(ladder_teams_by_id)
				.map(t => t)
				.filter(({ id }) => !_.includes(finals_teams, id))
				.orderBy("rank_selected_round", "asc")
				.value();
		}
		return _(ladder_teams_by_id)
			.map(t => t)
			.filter(({ id }) => !_.includes(finals_teams, id))
			.orderBy("rank", "asc")
			.value();
	}
	fiveWeekFinalLabel(final: string) {
		switch (final) {
			case "Semi Finals":
				return "Preliminary Final 1 / Semi Final";
			case "Preliminary Finals":
				return "Preliminary Final 2";
			default:
				return final;
		}
	}

	
	renderFinalsHeader(final: string) {
		const { league } =this.props;
		const isFiveFormat = league.finals_format === 5;
		const finalLabel = isFiveFormat ? this.fiveWeekFinalLabel(final) : final;
		return(
			<FinalsHeader>
				{finalLabel}
			</FinalsHeader>
		);
		
	}
	getFinalsBracketComponentArray() {
		const { league: { finals_length, finals_format } } = this.props;
		switch (finals_format) {
			case 4:
				return finals_length === 3 ? FINALS_FOUR_TEAMS["1"] : FINALS_FOUR_TEAMS["0"];
			case 6:
				return finals_length === 4 ? FINALS_SIX_TEAMS["1"] : FINALS_SIX_TEAMS["0"];
			case 5:
				return FINALS_FIVE_TEAMS;
			case 8:
				return FINALS_EIGHT_TEAMS;
			default: 
				return [];
		}
	}

	getLineComponent(component: string) {
		const isTop8 = this.props.league.finals_format === 8;
		const componentObject = {
			"straight_line": <StraightLine />,
			"two_in_one": <TwoIntoOneLine />,
			"line_top": <><LinesTopT isTop8={isTop8}/> <LinesTopEnd isTop8={isTop8}/> </>,
			"line_top_reverse":  <><LinesTopT reverse /> <LinesTopEnd reverse /> </>,
			"line_top_no_middle": <><LinesTopT no_middle /> <LinesTopEnd no_middle /> </>,
			"lines_cross": <LinesCross />,
			"lines_cross_down": <LinesCross down_only />,
			"lines_cross_up": <LinesCross up_only />
		};
		return componentObject[component];
	}

	renderSetFinalsTemplate(round_id: number, fixture_number: number) {
		const lines_template = this.getFinalsBracketComponentArray();

		let components = [];
		lines_template.forEach(component_obj => {
			const include1 = (component_obj.fixture_index === fixture_number);
			const include2 = (component_obj.round_index === round_id);

			
			if(include1 && include2){
				
				components.push(component_obj.component);
			}
		});

		return components;
	}

	renderFinalsWeek(final: string, fixtures: Array<Object>, round_id: number){
		const { league, has_assistant_coach  } = this.props;

		const finals_ids = Object.keys(this.props.finals);
		
		return(
			<FinalsWeekWrapper>
				
				{/* {this.renderFinalsHeader(final)} */}
				{fixtures[round_id].fixtures.map((fixture, index) => {
	
					return(
						<div>	
							{index===0 && this.renderFinalsHeader(final)}
							<>
								{this.renderSetFinalsTemplate(round_id, index).map(component => {
									return this.getLineComponent(component);
								})}
							</>
							<BracketFixture 
								fixture={fixture}
								league={league}
								round={finals_ids[round_id]}
								actual_round={this.props.actual_round}
								game_type={"draft"}
								league_complete={this.season_finished}
								compact={true}
								has_assistant_coach={has_assistant_coach}
							/>

						</div>
					
					);
				})}
			</FinalsWeekWrapper>
		);
	}
	renderFinalsBracket(){
		const { finals } = this.props;

		return(
			<FinalsBracketWrapper> 
				{Object.keys(finals).map( (final, index) => {
					return(
						<>
							{this.renderFinalsWeek(finals[final], this.fixture_teams, index)}
						</>
					);
				})}
			</FinalsBracketWrapper>
			
		);
		
	}

	render() {
		return (
			<BracketWrapper>
				{this.renderFinalsBracket()}
			</BracketWrapper>
		);
	}
}




export default FinalsBracket;