/* eslint max-len: 0 */
// @flow
import React from "react";
import SvgIconContainer from "../SvgIconContainer";

export default (
	{
		unit = "em",
		...rest
	}: Object = {}) => (
	<SvgIconContainer viewBox="0 0 24 24" {...rest} unit={unit}>
		<title>Calendar</title>
		<path d="m18.5454545 4.72727273h-1.090909c-.2008309 0-.3636364.16280554-.3636364.36363636v1.09090909c0 .80332327-.6512222 1.45454546-1.4545455 1.45454546-.8033232 0-1.4545454-.65122219-1.4545454-1.45454546v-1.09090909c0-.20083082-.1628056-.36363636-.3636364-.36363636h-3.6363636c-.20083084 0-.36363638.16280554-.36363638.36363636v1.09090909c0 .80332327-.65122218 1.45454546-1.45454546 1.45454546-.80332327 0-1.45454545-.65122219-1.45454545-1.45454546v-1.09090909c0-.20083082-.16280555-.36363636-.36363636-.36363636h-1.0909091c-.80332327 0-1.45454545.65122218-1.45454545 1.45454545v11.63636362c0 .8033233.65122218 1.4545455 1.45454545 1.4545455h13.09090905c.8033233 0 1.4545455-.6512222 1.4545455-1.4545455v-11.63636362c0-.80332327-.6512222-1.45454545-1.4545455-1.45454545zm0 12.69418177c.0084254.2097086-.1539887.3869023-.3636363.3967273h-12.36363638c-.2096476-.009825-.37206168-.1870187-.36363637-.3967273v-7.93381814c-.00842531-.20970851.15398877-.38690227.36363637-.39672727h12.36363638c.2096476.009825.3720617.18701876.3636363.39672727zm-10.18181814-10.51236359c.40166164 0 .72727273-.32561109.72727273-.72727273v-1.45454545c0-.40166164-.32561109-.72727273-.72727273-.72727273-.40166163 0-.72727272.32561109-.72727272.72727273v1.45454545c0 .40166164.32561109.72727273.72727272.72727273zm7.27272724 0c.4016617 0 .7272728-.32561109.7272728-.72727273v-1.45454545c0-.40166164-.3256111-.72727273-.7272728-.72727273-.4016616 0-.7272727.32561109-.7272727.72727273v1.45454545c0 .40166164.3256111.72727273.7272727.72727273z"/>
	</SvgIconContainer>
);