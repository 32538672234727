import styled from "styled-components";
import colors from "../../../assets/css/colors";
import { below } from "../../../assets/css/media";

export const PlayerPoolMobileWrapper = styled.div`
	display: none;
	${({ is_open }) => is_open && `
		display: block;
	`}
`;
export const PlayerPoolWebWrapper = styled.div`
	.player-avatar{
		height: 70px;
		width: 35%;
	}
	
`;

export const MyTeamMobileWrapper = styled.div`
	display: none;
	${({ is_open }) => is_open && `
		display: block;
	`}
`;

export const FlexRow = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
	${below.phone`
		display: none;
	`}
`;

export const PoolSelectWrapper = styled.div`
	width: ${({ is_mobile }) => is_mobile ? "100%" : "230px"};
	box-sizing: border-box;
	margin: 0 20px 20px;

	${below.desktop`
		width: 100%;
	`};
`;

export const GameBarWrapper = styled.div`
	align-items: center;
    display: flex;

	> div {
		:first-child {
			flex: 0 1 auto;
			margin-left: 0;
		}
		:last-child {
			flex: 1 1 auto;
		}
	}

	${below.desktop`
		flex: 1;
		> div {
			:first-child {
				display: none;
			}
		}
	`}
`;
export const SelectsWrapper = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;

	margin-top: -10px;
    margin-left: -10px;

	> div {
		min-width: 120px;
		margin: 10px 0 0 10px;
		flex: 1 0 0px;
	}

	${below.tablet`
		> div {
			flex: 0 0 calc(50% - 10px);
		}
	`};

	${below.phone`
		flex-wrap: wrap;
	`}
`;

export const MobileControlsWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	box-sizing: border-box;

	> div {
		display: none;
	}

	${below.desktop`
		width: 100%;
		padding-top: 10px;
		flex-direction: column;
		align-items: flex-start;
		flex: 1;
		> div {
			display: flex;

			width: 100%;
			:first-child {
				margin-bottom: 10px;
			}
		}
		> ${SelectsWrapper} {
			width: calc(100% + 10px);
		}

		> div:last-child {
			margin-left: 0px;
		}

		padding-bottom: 0;
	`}

	${below.phone`
		label > span.long_name {
			display: none;
		}
		label > span.short_name {
			display: inline;
		}
	`}
`;

export const TeamInfoWrapper = styled.div`
	display: flex;
	align-items: center;
	padding: 10px 0 0 0;

	${below.desktop`
		display: none;
	`};

	> div {
		flex: 1 0 auto;

		&:last-child {
			margin-left: 20px;
			flex: 0 1 auto;
		}
		@media screen and (max-width: 1125px) {
			&:last-child {
				label span.short_name {
					display: inline;
				}
				label span.long_name {
					display: none;
				}
			}
		}
	}
`;

export const TeamPanel = styled.div`
	display: flex;
	flex-direction: column;
	background-color: ${colors.form.base};

	padding: 20px;


	${below.desktop`
		min-height: 250px;
	`}
	${below.tablet`
		padding: 10px;
		min-height: 220px;
	`}
`;