import styled from "styled-components";
import Arrow from "../../../../components/Icons/Arrow";
import colors from "../../../../assets/css/colors";
import { below } from "../../../../assets/css/media";
import { ButtonPrimary } from "../../../../components";


export const LeagueSubmitButton = styled(ButtonPrimary)`
	height: 40px;
	padding: 0 20px;
`;

export const RedTextButton = styled.button`
	border: none;
	background: transparent;
	color: ${colors.buttons.cancel};
	font-family: "SourceSansPro";
	font-size: 12px;
	letter-spacing: 0;
	margin: 20px 0 0 0;
	padding: 0;
	&:hover {
		cursor: pointer;
		text-decoration: underline;
	}

	&:disabled {
		cursor: inherit;
		color: ${colors.form.disabled};
		text-decoration: none;
	}
	
	${below.desktop`
		width: 100%;
	`}
`;

export const CreateForm = styled.form`
	margin: 0 0 10px;
`;

export const FormItem = styled.div`
	padding: 1em;
	background: ${colors.form.advanced_color};

	&.base-options {
		background: transparent;
		padding: 0;
	}

	${below.desktop`
		margin: 0;
	`}
`;

export const SubTitle = styled.h4`
	display: flex;
	font-size: 22px;
	align-items: center;
	color: ${colors.primary.primary};
	font-weight: bold;
	margin: 1em 0 1em 0;

	div {
		margin-right: 0.5em;
	}
`;

export const AdvancedSettings = styled.p`
	color: ${colors.form.textDarkColor};
	line-height: 1.5;
	font-size: 16px;
	max-width: 560px;
`;

export const UploadAvatarWrapper = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	height: 130px;
	color: ${colors.form.textColor};

	picture {
		margin: 1em 0.5em 0.5em 0;
	}
	
	${below.tablet`
		height: 40px;
		margin: 0 0 20px 0;
		label {
			height: inherit;
			picture {
				margin: 0;
				img {
					height: 40px;
					width: 40px;
				}
			}
		}
	`}
`;

export const LeagueNameDraft = styled.h3`
	color: ${colors.primary.primary};
	font-size: 16px;
	margin-bottom: 5px;
	font-weight: bold;
	opacity: ${({ disabled }) => (disabled ? ".15" : "1")};
	max-width: 300px;
	overflow: hidden;
	text-overflow: ellipsis;
	&:valid { outline: solid blue 2px; }
`;

export const AdvancedSettingsWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1em;
	border-radius: 3px;
	background: ${colors.form.advanced_color};
	border-radius: 3px 3px 0 0;
	box-shadow: inset 0px 15px 20px -10px #c7c7c7;

	.sub-title {
		margin-bottom: 0.5em;
	}
`;

export const ToggleForm = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	background: ${colors.buttons.baseColor};
	width: 50px;
	height: 50px;
	border-radius: 0;
	color: #ffffff;
`;

export const UserName = styled.div`
	color: ${colors.primary.primary};
	font-size: 12px;
	font-family: SourceSansPro;
`;

export const ArrowBottom = styled(Arrow)`
	transform: rotate(178deg);
`;

export const ButtonWrapper = styled.div`
	display: flex;
	padding: 1em 0;
	${below.desktop`
		flex-direction: column;
		padding: 0;
		height: 100px;
		justify-content: space-around;
		margin: 10px 0 0 0;
	`}
`;

export const NextStepDiv = styled.div`
	height: 38px;
	border: ${({ isDisabled }) =>
		isDisabled
			? `1px solid ${colors.secondary.paleGrey}`
			: `1px solid ${colors.primary.primary}`};
	border-radius: 2px;
	line-height: 40px;
	margin: 0 20px 0 0;
	padding: 0;
	background-color: ${({ isDisabled }) =>
		isDisabled ? `${colors.secondary.paleGrey}` : `${colors.white}`};
	color: ${({ isDisabled }) => (isDisabled ? `${colors.white}` : `${colors.primary.primary}`)};
	font-family: "SourceSansPro";
	font-size: 12px;
	font-weight: 600;
	letter-spacing: 0;
	text-align: center;
	width: 140px;
	&:hover {
		background-color: ${({ isDisabled }) =>
		isDisabled ? `${colors.secondary.paleGrey}` : 
			`${colors.buttons.baseHover}`};
		color: ${colors.white};
		cursor: ${({ isDisabled }) => (isDisabled ? "inherit" : "pointer")};
	}
	${below.desktop`
		margin: 0;
		padding: 0;
		width: 100%;
	`}
`;