// @flow
import { Redirect } from "react-router-dom";
import React from "react";
import _ from "lodash";
import styled from "styled-components";
import { connect } from "react-redux";
import moment from "moment/moment";

import { below } from "../../../assets/css/media";
import * as actions from "../../../modules/actions";
import * as selectors from "../../../modules/selectors";
import { league_tooltips, lockoutContent } from "../../../helpers";
import { getShortName, getStatKeyName } from "../../../helpers/league";
import type { TPLeague } from "../../../modules/types";
import { Exist, Tooltip } from "../../../components";

import {
	Ad,
	AdsContainer,
	ButtonAlert,
	ConfirmationModal,
	Footer,
	LeagueAvatar,
	LeagueGameBar,
	PageContentWrapper,
	PageTitle,
	StandardSidebar,
	TwoColumnLayout,
} from "../../../components";

import colors from "../../../assets/css/colors";
import LeagueItem from "./leagueAbout/leagueItem";


const StyledToolTip = styled(Tooltip)`

`;

const AboutPageStyled = styled.div`
	border: 1px solid #ecf1f5;
	margin: 1em 0;
	background: ${colors.form.base};


	button {
		margin: 0 0 2em 4.5em;
		${below.tablet`margin-left: 20px`}
		${below.phone`margin-left: 10px`}
	}

	.title {
		margin-bottom: 10px;
	}

	svg {
		width: 1.1em;
	}

	&:first-child {
		margin-top: 0;
	}
`;

const AlertText = styled.p`
	margin-left: 4.5em;
	color: ${colors.primary.darkGrey};
	font-size: 14px;
	font-family: "SourceSansPro";
	margin-bottom: 1em;
	${below.tablet`margin-left: 20px`}
	${below.phone`margin-left: 10px`}
`;


const AvatarWrapper = styled.div`
	display: flex;
	align-items: center;
	height: 100px;
	color: ${colors.form.textColor};
	margin: 0 4em;

	picture {
		margin: 1em .5em .5em 0;
	}

	${below.tablet`margin-left: 20px`}
	${below.phone`margin-left: 10px`}
`;
const CustomScoringWrapper = styled.div`
	margin: 0em 4em;
	>:nth-child(odd){
		background-color: #F8F8FA;
	}
`;
const CustomScoreRow = styled.div`
	font-size: 13px;
	font-family: TitilliumUpright;
	height: 20px;
	display: flex;
	flex-direction: row;
`;
const CustomScorePosition = styled.p`
	font-weight: 400;
	color: #7F8A90;
	margin-right: 30px;
	width: 130px;
`;

const CustomScoreValue = styled.p`
	font-weight: 400;
	color: #7F8A90;
`;
const ScoringHeaderWrapper = styled.div`
	display: flex;
	align-items: center;
	height: 30px;
	margin-bottom: 5px;
`;


const ScoringSubHeading = styled.h4`
	font-family: TitilliumUpright;
	font-weight: normal;
	color: #1D4073;
	font-size: 14px;
	margin-top: 5px;
	margin-bottom: 5px;
`;

const LeagueName = styled.h3`
	color: ${colors.primary.primary};
	font-size: 20px;
	margin-bottom: .2em;
	line-height: 1.3;
	font-weight: bold;
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const UserName = styled.div`
	color: ${colors.form.textColor};
	font-weight: bold;
`;

const DataWrapper = styled.div`
	label {
		margin: 0 0 0.2em;
	}
`;

const SubTitle = styled.h4`
	display: flex;
	align-items: center;
	color: ${colors.primary.primary};
	font-weight: bold;
	margin: 15px 0 15px 4em;

	div {
		margin-right: .5em;
	}

	${below.tablet`
		margin-left: 20px;
	`};
	${below.phone`
		margin-left: 10px;
	`};
`;

const ButtonWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 1em 0;
`;

type Props = {
	showDraftLeague: typeof actions.leagueDraft.showDraftLeague,
	leaveDraftLeague: typeof actions.leagueDraft.leaveDraftLeague,
	showTeamsDraftLeague: typeof actions.leagueDraft.showTeamsDraftLeague,
	match: {
		params: {
			league_id: number,
		}
	},
	league_show: {
		error: string,
		league_id: number,
	},
	league: TPLeague
}

type State = {
	show_leave_modal: boolean,
	left_the_league: boolean,
};

class LeagueAboutComponent extends React.Component<Props, State> {
	constructor(props, state) {
		super(props, state);

		_.bindAll(this, [
			"leaveThisLeague",
			"confirmLeaveleague",
			"closeLeaveModal",
		]);
	}

	state = {
		show_leave_modal: false,
		left_the_league: false,
	};

	componentDidMount() {
		window.scrollTo(0, 0);

		const {
			match: {
				params: {
					league_id,
				}
			},
			showDraftLeague,
			showTeamsDraftLeague
		} = this.props;

		showDraftLeague({ id: league_id });
		showTeamsDraftLeague({ league_id });
	}

	confirmLeaveleague() {
		const { leaveDraftLeague, league: { id } } = this.props;
		leaveDraftLeague({ league_id: id });
		this.setState({ left_the_league: true });
	}

	leaveThisLeague() {
		this.setState({ show_leave_modal: true });
	}

	closeLeaveModal() {
		this.setState({ show_leave_modal: false });
	}

	get leave_modal() {
		return (
			<ConfirmationModal
				header_text='League About'
				body_text='Are you sure you want to leave this league ?'
				confirmClick={this.confirmLeaveleague}
				cancelClick={this.closeLeaveModal}
				closeClick={this.closeLeaveModal}
			/>
		);
	}

	get season_settings() {
		const { league } = this.props;
		
		return (
			<div>
				<LeagueItem
					name='League Size'
					content={league.size}
					tooltip={league_tooltips.size}
				/>
				<LeagueItem
					name='League Start Round'
					content={league.start_round}
					tooltip={league_tooltips.start_round}
				/>
				<LeagueItem
					name='Play Each Other'
					content={league.play_times}
					tooltip={league_tooltips.play_times}
				/>
				<LeagueItem
					name='Play Bye Rounds'
					content={league.bye_rounds ? "Yes" : "No"}
					tooltip={league_tooltips.bye_rounds}
				/>
				<LeagueItem
					name='Ladder Tiebreaker'
					content={league.rank_by === "percentage" ? "Percentage" : "Points For"}
					tooltip={league_tooltips.ladder_tiebreaker}
				/>
				<LeagueItem
					name='Rolling Lockout'
					content={lockoutContent(league)}
					tooltip={league_tooltips.lockout}
				/>
				<LeagueItem
					name='Play a Finals Series'
					content={league.finals ? "Yes" : "No"}
					tooltip={league_tooltips.finals}
				/>
				<Exist when={league.finals}>
					<LeagueItem
						name='Finals Format'
						content={"Top "+league.finals_format}
						tooltip={league_tooltips.finals_format}
					/>

				</Exist>
				{(league.finals && ["full", "lite"].includes(league.subscription) ) ? 
					<>
						<LeagueItem
							name='Finals Start Round'
							content={`Round ${league.finals_start_round}`}
							tooltip={league_tooltips.finals_start_round}
						/>
						<LeagueItem
							name='Finals Length'
							content={`${league.finals_length} Weeks` }
							tooltip={league_tooltips.finals_length}
						/>
					</>
					: null
				}
				<LeagueItem 
					name='Coaches Box'
					content={league.coaches_box ? "On" : "Off"}
					tooltip={league_tooltips.coaches_box}
				/>

			</div>
		);
	}
	get basic_settings() {
		const { league } = this.props;

		return (
			<div>
				<LeagueItem
					name='League Name'
					content={league.name}
					tooltip={league_tooltips.name}
				/>
				<LeagueItem
					name='Privacy Settings'
					content={league.privacy === 1 ? "Public" : "Private"}
					tooltip={league_tooltips.privacy}
				/>
				<LeagueItem
					name='Draft type'
					content={league.draft_type}
					tooltip={league_tooltips.draft_type}
				/>
			</div>
		);
	}
	draft_turn_value(turn_in_seconds) {
		const options = {
			"60": "60 SECONDS",
			"120": "2 minutes",
			"300": "5 minutes",
			"900": "15 minutes",
			"3600": "60 minutes",
			"43200": "12 hours",
			"86400": "24 hours"

		};

		return _.get(options, turn_in_seconds);
	};


	get draft_settings() {
		const { league } = this.props;
		return(
			<div>
				<LeagueItem
					name='Draft turn time'
					content={this.draft_turn_value(league.draft_turn)}
					tooltip={league_tooltips.draft_turn}
				/>
				<LeagueItem
					name='Draft date and time'
					content={moment(league.draft_start).format("DD/MM/YY HH:mm")}
					tooltip={league_tooltips.draft_start}
				/>
				<LeagueItem
					name='Draft turn type'
					content={league.draft_order}
					tooltip={league_tooltips.draft_order }
				/>
			</div>
		);
	}

	get game_play_settings() {
		const { league } = this.props;
		return(
			<div>
				<LeagueItem
					name='Captains'
					content={league.captains ? "On" : "Off"}
					tooltip={league_tooltips.captains}
				/>
				<LeagueItem
					name='Squad Size (DEF-MID-RUC-FWD/INT)'
					content={league.squad_size}
					tooltip={league_tooltips.squad_size}
				/>
				
				<LeagueItem
					name='Enable emergencies'
					content={league.emergency ? "On" : "Off"}
					tooltip={league_tooltips.emergency}
				/>
				{["full", "lite"].includes(league.subscription)  ? 
					<>
						<LeagueItem
							name='Enable auto updating of Dual Position Players (DPP)'
							content={league.original_positions ? "Off" : "On"}
							tooltip={league_tooltips.original_positions}
						/>
						<LeagueItem
							name='Number of Emergencies'
							content={league.emergency_limit}
							tooltip={league_tooltips.num_emergencies}
						/>
						<LeagueItem
							name='Emergency TOG Threshold'
							content={league.tog_threshold}
							tooltip={league_tooltips.tog_threshold_emergencies}
						/>
					</>
					: null
				}
			</div>
		);
	}


	approve_trade_options(item) {
		const options = {
			league: "League Approved",
			commissioner: "Commissioner Approved",
			free: "Free for all",
		};

		return _.get(options, item);
	}

	get trades_restricted_settings() {
		const { league } = this.props;

		return(
			<div>
				<LeagueItem
					name='Restricted Free Agents'
					content={league.trade_waivers ? "On" : "Off"}
					tooltip={league_tooltips.trade_waivers}
				/>
				{league.trade_waivers ? 
					<>
						<LeagueItem
							name='Restricted Free Agent Time Period'
							content={league.waiver_period === 1 ? "1 day" : "2 days"}
							tooltip={league_tooltips.waiver_period}
						/>
						<LeagueItem
							name='Restricted Free Agent Order Policy'
							content={league.waiver_order === 1
								? "Last Pick to Bottom" : "Reverse Ladder Order"}
							tooltip={league_tooltips.waiver_order}
						/>
						<LeagueItem
							name='Restricted Free Agent Privacy'
							content={league.rfa_privacy}
							tooltip={league_tooltips.rfa_privacy}
						/>
					</>
					: null
				}
				
				<LeagueItem
					name='Trade Approval Options'
					content={this.approve_trade_options(league.approve_trade)}
					tooltip={league_tooltips.approve_trade}
				/>
			</div>
		);
	}
	
	get scoring_settings() {
		const { league } = this.props;
		if(league.custom_scoring){
			return(
				<CustomScoringWrapper>
					<ScoringHeaderWrapper>
						<ScoringSubHeading>
						Scoring Values
						</ScoringSubHeading>
						<StyledToolTip 
							title="General Scoring" 
							description = 
								"The points awarded for specific statistics a player can receive."/>
					</ScoringHeaderWrapper>
				

					{Object.keys(league.custom_scoring).map(key => (
						
						<CustomScoreRow key={key}>
							<CustomScorePosition>
								{getStatKeyName(key)}:
							</CustomScorePosition>
							<CustomScoreValue>
								{Number(league.custom_scoring[key]) > 0 ? "+" : ""}
								{league.custom_scoring[key]}
							</CustomScoreValue>
						</CustomScoreRow>
					)
					)}
				</CustomScoringWrapper>
			);
		}
		else return null;
		
	}
	get keeper_settings() {
		const { league } = this.props;
		return(
			<div>
				<LeagueItem
					name='Enable Keeper League '
					content={league.keeper ? "Yes" : "No"}
					tooltip={league_tooltips.keeper}
				/>
			</div>
		);
	}

	render() {
		const {
			league,
			league_show,
		} = this.props;

		const {
			show_leave_modal,
			left_the_league,
		} = this.state;

		return (
			<div>
				{league_show.error ? <Redirect to='/draft/leagues' /> : null}
				{left_the_league ? <Redirect to='/draft/leagues' /> : null}
				{show_leave_modal ? this.leave_modal : null}
				<LeagueGameBar no_fetch={true} />
				<AdsContainer>
					<Ad/>
				</AdsContainer>
				<PageContentWrapper>
					<PageTitle>

						About {league.name}
					</PageTitle>
					<TwoColumnLayout>
						<div>
							<AboutPageStyled>

								<AvatarWrapper>
									<LeagueAvatar league={league} size="60px" />
									<div>
										<LeagueName>{league.name}</LeagueName>
										<UserName>{getShortName(league)}</UserName>
									</div>
								</AvatarWrapper>
								<DataWrapper>
									<SubTitle>
										Basic Settings
									</SubTitle>

									{this.basic_settings}
									<SubTitle>
										Season Settings
									</SubTitle>
									{this.season_settings}

									<SubTitle>
										Draft Settings
									</SubTitle>
									{this.draft_settings}

									<SubTitle>
										Lineup Settings
									</SubTitle>
									{this.game_play_settings}

									<SubTitle>
										Trades & Restricted Free Agents
									</SubTitle>
									{this.trades_restricted_settings}
									<SubTitle>
										Scoring Settings
									</SubTitle>
									{this.scoring_settings}
									<SubTitle>
										Keeper Settings
									</SubTitle>
									{this.keeper_settings}
								</DataWrapper>
							</AboutPageStyled>

							{league.status === "scheduled" ? (
								<AboutPageStyled>
									<SubTitle className='title'>
										Would you like to leave this league?
									</SubTitle>
									<AlertText>This action is permanent</AlertText>
									<ButtonWrapper>
										<ButtonAlert onClick={this.leaveThisLeague} width='200px'>
											Leave your league
										</ButtonAlert>
									</ButtonWrapper>
								</AboutPageStyled>
							) : null}
						</div>
						<StandardSidebar />
					</TwoColumnLayout>

				</PageContentWrapper>

				<Footer />
			</div>
		);
	}
}

const mapStateToProps = (state, props) => ({
	league_show: state.leagues.show,
	league: selectors.leagueDraft.getLeague(state, props),
	league_leave: state.leagues.leave,
});

const mapDispatchToProps = {
	showDraftLeague: actions.leagueDraft.showDraftLeague,
	leaveDraftLeague: actions.leagueDraft.leaveDraftLeague,
	showTeamsDraftLeague: actions.leagueDraft.showTeamsDraftLeague,
};

export const DraftLeagueAbout = connect(
	mapStateToProps,
	mapDispatchToProps
)(LeagueAboutComponent);

export default DraftLeagueAbout;