
// @flow
import _, { get } from "lodash";
import type { TRoundWithMatches } from "../../modules/types";
import { isAllTrue, isAnyTrue } from "..";
import type { TPlayer, TTeamClassicLineup, ById, TTeam, TPlayersById } from "../../modules/types";
import { findMatch } from "../rounds";

type TLineupLineByPosition = {
	lineup: TTeamClassicLineup,
	position: number
}
const getLineupLineByPosition = ({ lineup, position }: TLineupLineByPosition) => {
	const line = lineup[position];
	const bench_line = lineup.bench[position];

	return line.includes(0) || bench_line.includes(0) ? position : 0;
};
type TClassicTeamAvailablePositions = {
	lineup: TTeamClassicLineup,
	positions: Array<number>
}
export const getClassicTeamAvailablePositions = (
	{ lineup, positions }: TClassicTeamAvailablePositions,
) => {
	return _.chain(positions)
		.reduce((available_positions, id) => {
			const position = getLineupLineByPosition({ lineup, position: id });
			available_positions.push(position);
			return available_positions;
		}, [])
		.filter(_.identity)
		.value();
};
type TIsTeamComplete = {
	lineup: TTeamClassicLineup,
	complete_first_time: boolean
}
export const isTeamComplete = ({ lineup, complete_first_time }: TIsTeamComplete) => {
	if (!complete_first_time) {
		return true;
	}
	const { bench } = lineup;
	const bench_ids = _.chain(bench).omit(["emergency"]).values().flatten().value();

	return !_.chain(lineup)
		.omit(["captain", "vice_captain", "bench"])
		.values()
		.flatten()
		.concat(bench_ids)
		.includes(0)
		.value();
};

type TIsLocked = {
	player: TPlayer,
	remaining_salary: number,
	actual_round: any,
	is_team_started: number,
	available_positions: number[]
};
export const classicIsLocked = ({
	player,
	remaining_salary,
	actual_round,
	is_team_started,
	available_positions,
}: TIsLocked) => {
	const { cost, locked, positions, squad_id } = player;
	const lockout = _.get(actual_round, "lockout");
	const matches = _.get(actual_round, "matches", []);

	if (actual_round.status === "active" && is_team_started && locked) {
		return true;
	}

	const no_positions_for_player = _.intersection(available_positions, positions).length === 0;
	const no_salary = remaining_salary < cost;
	let locked_out = false;

	if (lockout === "partial") {
		const match = findMatch(matches, squad_id);
		const match_status = _.get(match, "status", "scheduled");
		locked_out = match_status !== "scheduled";
	}

	return no_salary || no_positions_for_player || locked_out;
};

export const getRemainingSalary = (team: TTeam) => {
	const salary = _.get(team, "salary_cap");
	const value = _.get(team, "value");
	return _.some([salary, value], _.isNil) ? 0 : salary - value;
};

export const getClassicEmergencyScore = (
	emergency: Object,
	position_id?: number,
	score_key: string,
	players_by_id: TPlayersById,
) => {
	let score;

	if (!position_id) {
		return null;
	}

	const position_bench = _.get(emergency, position_id, []);
	const utility = _.get(emergency, "utility", {});
	const utilityPosition = _.get(utility, "position", 0);
	
	const emgs = _.get(emergency, "emergency", []);
	// account for utility emergency in the same position
	let positionBenchToUse = 
		(utility && utilityPosition === position_id) 
			? [...position_bench, utility.id] : position_bench;
	

	const emergency_player = _(emgs)
		// Remove anyone not in correct position
		.filter(p_id => _.includes(positionBenchToUse, p_id))
		.map(p_id => players_by_id[p_id])
		// map to a list of scores with ids
		.map(player => {
			const id = _.get(player, "id");
			return {
				score: _.get(player, score_key, 0),
				id,
			};
		})
		// Make sure that you're taking the worst emergency, as per AF22-463
		// if there's more than one
		.orderBy("score", "asc")
		// Take the lowest score
		.first();
	if (!_.isEmpty(emergency_player)) {
		score = _.get(emergency_player, "score", 0);
		// Remove this player from the list of emergencies so they're not used again
		emergency.emergency = _.without(emgs, _.get(emergency_player, "id"));
	}

	return score;
};
const getEmergencyPlayerAndScore = (
	emergency: Object,
	position_id?: number,
	score_key: string,
	players_by_id: TPlayersById,
) => {
	// eslint-disable-next-line no-unused-vars
	let score;

	const position_bench = _.get(emergency, position_id, []);
	const emgs = _.get(emergency, "emergency", []);

	const emergency_player = _(emgs)
		// Remove anyone not in correct position
		.filter(p_id => _.includes(position_bench, p_id))
		.map(p_id => players_by_id[p_id])
		// map to a list of scores with ids
		.map(player => {
			const id = _.get(player, "id");
			return {
				score: _.get(player, score_key, 0),
				id,
			};
		})
		// Make sure that you're taking the best emergency,
		// if there's more than one
		.orderBy("score", "desc")
		// Take the highest score
		.first();

	if (!_.isEmpty(emergency_player)) {
		score = _.get(emergency_player, "score", 0);
		// Remove this player from the list of emergencies so they're not used again
		emergency.emergency = _.without(emgs, _.get(emergency_player, "id"));
    
	}
	if(_.isEmpty(emergency_player) || emergency_player.score === 0){
		return { id: 0, score: 0 };
	}
	return emergency_player;
};

const getLeaderScore = (
	lineup: TTeamClassicLineup,
	players_by_id: TPlayersById,
	round: TRoundWithMatches
):number => {
	if(!lineup.captain) {
		return 0;
	}

	const captain = players_by_id[lineup.captain];
	const captains_match = round.matches.find(m =>
		m.home_squad_id === captain.squad_id ||
		m.away_squad_id === captain.squad_id
	);

	// if(!captains_match) {
	// 	return 0;
	// }

	const captains_score = _.get(captain, `stats.scores.${round.id}`, null);
	if(_.get(captains_match, "status") !== "scheduled" && captains_score !== null) {
		return captains_score;
	}
	else if(_.get(captains_match, "status") === "scheduled") {
		return 0;
	}

	if(!lineup.vice_captain) {
		return 0;
	}

	const vice_captain = players_by_id[lineup.vice_captain];
	return _.get(vice_captain, `stats.scores.${round.id}`, 0);
};

const getLeaderId = (
	lineup: TTeamClassicLineup,
	players_by_id: TPlayersById,
	round: TRoundWithMatches
): number => {
	if(!lineup.captain) {
		return 0;
	}

	const captain = players_by_id[lineup.captain];
	const captains_match = round.matches.find(m =>
		m.home_squad_id === captain.squad_id ||
		m.away_squad_id === captain.squad_id
	);

	// if(!captains_match) {
	// 	return 0;
	// }

	const captains_score = _.get(captain, `stats.scores.${round.id}`, null);
	if(_.get(captains_match, "status") !== "scheduled" && captains_score !== null) {
		return captain.id;
	}
	else if(_.get(captains_match, "status") === "scheduled") {
		return captain.id;
	}

	if(!lineup.vice_captain) {
		return 0;
	}

	const vice_captain = players_by_id[lineup.vice_captain];
	return _.get(vice_captain, `id`, 0);
};
type TTeamScore = {
	lineup: TTeamClassicLineup,
	players_by_id: TPlayersById,
	round: TRoundWithMatches,
	team?: Object,
}
const compareNumbers = (a, b) => b - a;

const comparePlayers = (a, b) =>  get(b, 'score') - get(a, 'score');

export const getTeamScore = ({ lineup, players_by_id, round, team }: TTeamScore) => {
	if (
		isAnyTrue([
			_.isEmpty(lineup),
			_.isEmpty(players_by_id),
			_.isEmpty(round)
		])
	) {
		return 0;
	}
	const { is_bye, matches, is_partial_bye } = round;

	const pure_lineup = _.chain(lineup)
		.omit(["captain", "vice_captain", "bench", "emergency"])
		.value();

	const team_players_ids = _.chain(pure_lineup)
		.values()
		.flatten()
		.filter(_.identity)
		.value();

	if(team_players_ids.length === 0){
		return _.get(team, `scoreflow[${round.id}]`, 0);
	}
	const { id = 0 } = round;
	const score_key = `stats.scores.${id}`;

	let emergency = { ..._.get(lineup, "bench", {}) };

	let score = getLeaderScore(lineup, players_by_id, round) * 2;

	const is_leader_scored = score !== 0;
	const leader_id = getLeaderId(lineup,players_by_id, round);

	const scores = team_players_ids.filter(playerID => playerID !== leader_id).map(player_id => {
		const player = _.get(players_by_id, player_id, null);

		if (player === null) {
			return 0;
		}

		const players_match = matches
			.find(m => [m.home_squad_id, m.away_squad_id].includes(player.squad_id));

		const match_status = _.get(players_match, "status", "scheduled");

		let player_score = _.get(players_by_id[player_id], score_key, null);
		// Add emergency score if exsits

		if (isAllTrue([isAnyTrue([
			match_status !== "scheduled",
			!players_match
		]) ,(player_score === null || player_score === undefined),  !_.isEmpty(emergency)])) {
			// get player position key
			const position_id = _.keys(pure_lineup)
				.find(player_ids => pure_lineup[player_ids].includes(player_id));
			player_score = getClassicEmergencyScore(
				emergency,
				position_id,
				score_key,
				players_by_id,
			);
		}
		player_score = !Boolean(player_score) ? 0 : player_score;

		return player_score;
	});
	if (is_bye || is_partial_bye) {
		let final_scores_arr = scores.sort(compareNumbers).slice(0, 17);
		const last_score = _.last(final_scores_arr);
		if (is_leader_scored && last_score > score) {
			final_scores_arr.splice(17, 1, score);
		}

		return final_scores_arr.reduce((value, score) => {
			return value + score;
		}, score);
	}

	score += scores.filter(score => Boolean(score)).reduce((value, score) => {
		return value + score;
	}, 0);
	return score;
};

export const getScoringPlayers = ( 
	lineup: Object, 
	players_by_id: 
  Object, 
	round: TRoundWithMatches): Array<Object> => {
	if (
		_.isEmpty(lineup) ||
		_.isEmpty(players_by_id) ||
		_.isEmpty(round)
	) {
		return [{ player_id: 0, score: 0 }];
	}
	const { matches } = round;

	const pure_lineup = _.chain(lineup)
		.omit(["captain", "vice_captain", "bench", "emergency"])
		.value();

	const team_players_ids = _.chain(pure_lineup)
		.values()
		.flatten()
		.filter(_.identity)
		.value();
	const { id = 0 } = round;
	const score_key = `stats.scores.${id}`;

	let emergency = { ..._.get(lineup, "bench", {}) };

	const not_scores = team_players_ids.map(player_id => {
		const player = _.get(players_by_id, player_id, null);

		if (player === null) {
			return null;
		}

		const players_match = matches
			.find(m => [m.home_squad_id, m.away_squad_id].includes(player.squad_id));

		const match_status = _.get(players_match, "status", "complete");
    
		let score = _.get(players_by_id[player_id], score_key, null);

		if(isAllTrue([score !== 0, score !== null])){
			return ({ player_id: player_id, score: score });
		}

		// Add emergency score if exsits
		if (isAllTrue([match_status !== "scheduled", score === null, !_.isEmpty(emergency)])) {	
			// get player position key
			const position_id = _.keys(pure_lineup)
				.find(player_ids => pure_lineup[player_ids].includes(player_id));
			
			const emergency_player = getEmergencyPlayerAndScore(
				emergency,
				position_id,
				score_key,
				players_by_id,
			);
			// if(_.isEmpty(emergency_player)){
			// 	return null;
			// }
			if(isAllTrue([emergency_player.score !== 0, emergency_player.score !== null])){
				return { player_id: emergency_player.id, score: emergency_player.score };
			}
		}
		score = _.isNil(score) ? 0 : score;

		return (isAllTrue([score !== 0, score !== null])) ? 
			{ player_id: player_id, score: score } : { player_id: 0, score: 0 };
	});
	const END_VALUE = round.is_bye || round.is_partial_bye ? 18 : 22;
	let final_scores_arr = not_scores.sort(comparePlayers).slice(0, END_VALUE);
	return final_scores_arr;
};

function hasPlayerMatchStarted(
	player_id: number, 
	players_by_id:Object, 
	selected_round: TRoundWithMatches
){

	const player = players_by_id[player_id];
	let has_player_started = false;
	if(player && selected_round && selected_round.matches){
		selected_round.matches.forEach(match => {
			if(match.home_squad_id === player.squad_id 
        || match.away_squad_id === player.squad_id){
				if(match.status !== "scheduled"){
					has_player_started = true;
				}
			}
		}) ;
    
	}
	return has_player_started;
  
}


// map through positions and bench[positions] find spot via key of where the difference is 
// if the difference player's game has started for the latest round
// use them in the returned lineup, if not use the previous player in that spot
const POSITION_KEYS = ["1", "2", "3", "4", "bench"];
export const getOpponentLineup = (
	previousLineup: Object, 
	currentLineup: Object, 
	round: TRoundWithMatches, 
	players: ById<TPlayer>) => {
	let returning_lineup = previousLineup;
	POSITION_KEYS.forEach(position => {
		if(position === "bench"){
			["1", "2", "3", "4", "emergency"].forEach(bench_pos => {
				if(currentLineup["bench"][bench_pos]){
					currentLineup["bench"][bench_pos].forEach((player_id, index) => {
						if(player_id !== previousLineup["bench"][bench_pos][index] 
              && hasPlayerMatchStarted(player_id, players, round)){
							returning_lineup[position][bench_pos][index] = player_id;
						}
						if(player_id !== previousLineup[position][index] 
              && !hasPlayerMatchStarted(player_id, players, round)){
							returning_lineup[position][bench_pos][index] = 0;
						}
					});
				}
			});
			return;
		}
		if(currentLineup[position]){
			currentLineup[position].forEach((player_id, index) => {
				if(player_id !== previousLineup[position][index] 
          && hasPlayerMatchStarted(player_id, players, round)){
					returning_lineup[position][index] = player_id;
				}
				if(player_id !== previousLineup[position][index] 
          && !hasPlayerMatchStarted(player_id, players, round)){
					returning_lineup[position][index] = 0;
				}
			});
		}
		
	});
	const currentUtility = _.get(currentLineup, "bench.utility", { id: 0, position: "3" });
	const previousUtility = _.get(previousLineup, "bench.utility", { id: 0, position: "3" });
	if(currentUtility.id !== previousUtility.id
    && hasPlayerMatchStarted(currentUtility, players, round)){
		returning_lineup["bench"]["utility"] = currentUtility;
	}
	if(currentUtility.id !== previousUtility.id
    && !hasPlayerMatchStarted(currentUtility, players, round)){
		returning_lineup["bench"]["utility"] = 0;
	}
  
	return returning_lineup;
};

export const isEditTradePage = () => {
	return window.location.pathname.includes('edit-advanced');
};