// @flow
import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { compose } from "redux";

import * as selectors from "../../../modules/selectors";
import * as actions from "../../../modules/actions";

import PageContentWrapper from "../../../components/PageContentWrapper";
import TwoColumnLayout
from "../../../components/TwoColumnLayout";
import JsonFetcher from "../../../components/utils/JsonFetcher";
import withRoundsSelector from "../../../components/utils/withRoundsSelector";
import RoundSelector from "../../../components/RoundSelector";
import AdsContainer from "../../../components/AdsContainer";
import Ad from "../../../components/Ad";
import Footer from "../../../components/Footer";
import TeamBreakdowns from "../../../components/TeamBreakdowns";
import { PageTitle } from "../../../components/PageTitle";
import { StandardSidebar, withClassicPlayerModal } from "../../../components";
import withCoachesBox from "../../../components/utils/withCoachesBox";
import LeagueGameBar from "../../../components/LeagueGameBar";
import type { TPLeague } from "../../../modules/types";
import { isRoundActive, isRoundComplete } from "../../../helpers/rounds";

type Props = {
	selected_league_id: number,
	fetchLeague: typeof actions.leagueDraft.showDraftLeague,
	fetchLeagueRosters: typeof actions.leagueDraft.fetchLeagueRosters,
	user_id: number,
	rounds_ordered: Array<Object>,
	selected_round_id: number,
	setSelectedRoundId: Function,
	league: TPLeague,
	rosters: Array<Object>,
	team_breakdown_players: Object,
	showModalForPlayerId: Function,
	actual_round: Object,
}

type State = {}

class LeagueTeamBreakdownsPage extends React.Component<Props, State> {
	componentDidMount() {
		const { selected_round_id } = this.props;

		this.fetchLeague();

		if (selected_round_id) {
			this.fetchLeagueRosters();
		}
	}

	componentDidUpdate(prev_props) {
		const { selected_league_id, selected_round_id, setSelectedRoundId, league } = this.props;
		const ifRoundGreaterThan0 = selected_round_id !== prev_props.selected_round_id && Boolean(prev_props.selected_round_id);

		if (
			selected_league_id &&
			selected_league_id !== prev_props.selected_league_id
		) {
			this.fetchLeague();
			this.fetchLeagueRosters();
		}
		else if (ifRoundGreaterThan0) {
			this.fetchLeagueRosters();
		}

		if (
			selected_league_id !== prev_props.selected_league_id ||
			league !== prev_props.league
		) {
			setSelectedRoundId(this.league_selected_round);
		}
	}

	get league_rounds() {
		const {
			rounds_ordered,
			league
		} = this.props;

		if (rounds_ordered && league && league.start_round && league.end_round) {
			return rounds_ordered.filter(round =>
				(round.id >= league.start_round && round.id <= league.end_round) &&
				(
					round.id === league.start_round ||
					isRoundActive(round) ||
					isRoundComplete(round)
				)
			);
		}

		return rounds_ordered;
	}

	get league_selected_round() {
		const { selected_round_id, league } = this.props;

		if (selected_round_id && league) {
			const start_round = _.first(this.league_rounds).id;
			const end_round = _.last(this.league_rounds).id;

			if ( selected_round_id < start_round) {
				return start_round;
			}
			else if (selected_round_id > end_round) {
				return end_round;
			}

			return selected_round_id;
		}

		return  null;
	}

	fetchLeagueRosters() {
		const { fetchLeagueRosters, selected_league_id, selected_round_id, league } = this.props;
		const league_type = _.get(league, "type");
		if(league_type !== "open"){
			fetchLeagueRosters({
				league_id: selected_league_id,
				round: selected_round_id
			});
		}
		
	}

	fetchLeague() {
		const { fetchLeague, selected_league_id } = this.props;
		fetchLeague({
			id: selected_league_id
		});
	}

	renderMainSection() {
		const {
			league,
			selected_round_id,
			setSelectedRoundId,
			user_id,
			rosters,
			team_breakdown_players,
			showModalForPlayerId,
			actual_round
		} = this.props;

		 return (
			<div>
				<RoundSelector
					selected={selected_round_id}
					rounds={this.league_rounds}
					handleClick={setSelectedRoundId}
				/>
				<TeamBreakdowns
					rosters={rosters}
					team_breakdown_players={team_breakdown_players}
					league_id={league.id}
					round_id={selected_round_id}
					is_classic
					user_id={user_id}
					showModalForPlayerId={showModalForPlayerId}
					actual_round_id={_.get(actual_round, 'id')}
				/>
			</div>
		 );
	}

	render() {
		const {
			league,
		} = this.props;
		const title_league_name = league ? league.name : "-";
		return (
			<React.Fragment>
				<JsonFetcher fetch={["rounds", "players", "coach_players"]} />

				<LeagueGameBar no_fetch={true} />

				<AdsContainer>
					<Ad />
				</AdsContainer>

				<PageContentWrapper>
					<PageTitle>
						Teams Breakdowns for {title_league_name}
					</PageTitle>
					<TwoColumnLayout>
						{this.renderMainSection()}
						<StandardSidebar />
					</TwoColumnLayout>
				</PageContentWrapper>
				<Footer />
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state, props) => {
	const selected_league_id = props.match.params.league_id;

	return {
		selected_league_id,
		league: selectors.leaguesClassic.show.getLeague(state, props),
		team_breakdown_players: selectors.players.getTeamBreakdownPlayersById(state),
		rosters: selectors.leaguesClassic.getRostersById(state),
		user_id: selectors.getUser(state).id,
		actual_round: selectors.rounds.getActualRound(state)
	};
};

const mapDispatchToProps = {
	fetchLeague: actions.showClassicLeague,
	fetchLeagueRosters: actions.getClassicLeagueRosters,
	showModalForPlayerId: actions.showModalForPlayerId,
};

export const LeagueTeamBreakdowns = compose(
	withRoundsSelector,
	withCoachesBox,
	withClassicPlayerModal,
	connect(
		mapStateToProps,
		mapDispatchToProps
	)
)(LeagueTeamBreakdownsPage);

export default LeagueTeamBreakdowns;