// @flow
import type { Saga } from "redux-saga";
import { all, fork } from "redux-saga/effects";
import Api from "../../utils/api";
import * as teamsClassicSagasUtil from "./teamsClassic";
import * as leaguesClassicSagasUtil from "./leaguesClassic";
import * as teamsDraft from "./teamsDraft";
import * as leaguesDraft from "./leaguesDraft";
import * as userSagas from "./user";
import * as roundsSagasUtil from "./rounds";
import * as squadsSagasUtil from "./squads";
import * as contactUsSagasUtil from "./contactUs";
import * as newsSagaUtil from "./news";
import * as playerNewsSagaUtil from "./playerNews";
import * as helpSagaUtil from "./help";
import * as coachesBoxSagasUtil from "./coachesBox";
import * as cmsSagasUtil from "./cms";
import * as offSeasonUtil from "./offSeason";
import * as userSagaUtil from "./user";
import * as venuesSagasUtil from "./venues";
import * as playersSagasUtil from "./players";
import * as checksumsUtil from "./checksums";
import countriesRootSaga from "./countries";

const leaguesSagas = leaguesDraft.createSagas(Api);
const playersSagas = playersSagasUtil.createSagas(Api);
const squadsSagas = squadsSagasUtil.createSagas(Api);
const venuesSagas = venuesSagasUtil.createSagas(Api);
const roundsSagas = roundsSagasUtil.createSagas(Api);
const checksumsSagas = checksumsUtil.createSagas(Api);
const teamsDraftInternalSagas = teamsDraft.createSagas(Api);
const teamsClassicSagas = teamsClassicSagasUtil.createSagas(Api);
const leaguesClassicSagas = leaguesClassicSagasUtil.createSagas(Api);
const contactUsSagas = contactUsSagasUtil.createSagas(Api);
const newsSaga = newsSagaUtil.createSagas(Api);
const playerNewsSaga = playerNewsSagaUtil.createSagas(Api);
const helpSaga = helpSagaUtil.createSagas(Api);
const cmsSaga = cmsSagasUtil.createSagas(Api);
const coachesBoxSagas = coachesBoxSagasUtil.createSagas(Api);
const offSeasonSaga = offSeasonUtil.createSagas(Api);
const userSaga = userSagaUtil.createSagas(Api);

export default function* rootSaga(): Saga<void> {
	yield all([
		...Object.values(userSagas),
		countriesRootSaga,
		leaguesSagas.watch,
		leaguesClassicSagas.watch,
		playersSagas.watch,
		squadsSagas.watch,
		venuesSagas.watch,
		checksumsSagas.watch,
		teamsDraftInternalSagas.watch,
		teamsClassicSagas.watch,
		contactUsSagas.watch,
		newsSaga.watch,
		helpSaga.watch,
		coachesBoxSagas.watch,
		cmsSaga.watch,
		offSeasonSaga.watch,
		userSaga.watch,
		roundsSagas.watch,
		playerNewsSaga.watch
	].map(saga => fork(saga)));
}