// @flow
import styled from "styled-components";

export const TradeTitleWrapper = styled.div`
	${({ compressed }) => compressed && "height: 40px;"} 
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export default TradeTitleWrapper;