import styled from "styled-components";
import above, { below } from "../../../../assets/css/media";
import colors from "../../../../assets/css/colors";
import { RedTextButton } from "../../leagues/leaguesCreate/LeaguesCreateFormStyles";

export const AssignTeamWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-right: 10px;
	${below.phone`
		${({ isOpen }) => isOpen && `
			position: absolute;
			left: 0;
			height: 50px;
			width: 100%;
			padding-left: 5px;
			background: white;
		`}
	`}
`;

export const ActionsWrapper = styled.div`
	display: flex;
	align-items: center;
`;



export const NamesWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	position: relative;
`;

export const AvatarWrapper = styled.div`
	height: 40px;
	width: 40px;
	margin: 5px 10px 5px 0;

	> div {
		height: 40px;
		width: 40px;
	}
`;
export const PostDraftButtonsWrap = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
`;

export const TeamInfoWrapper = styled.div`
	display: flex;
	justify-content: flex-start;
`;
export const TeamCellWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const RedTextButtonWithMargin = styled(RedTextButton)`
	margin-top: 0;
	margin-right: 10px;
`;
export const RemoveButton = styled(RedTextButtonWithMargin)`
	margin-top: 0px;
`;

export const LeaveButton = styled(RedTextButtonWithMargin)``;

export const ReplaceButton = styled(RedTextButton)`
	padding-left: 0;
	margin-top: 0px;
	margin-left: 10px;
	margin-right: 20px;
`;
export const InviteTextButton = styled.button`
	background-color: transparent;
	border: none;
	cursor: pointer;
	font-family: SourceSansPro;
	color: #1D4073;
	font-weight: 400;
	font-size: 12px;
	padding-right: 10px;
	&:hover{
		text-decoration: underline;
	}
`;
export const EditTeamButton = styled(InviteTextButton)`
	border-right: 1px solid #CAD2D8;
	${props => props.no_border === true && `
		border-right: none;
	`}
	${props => props.disabled && `
		opacity: 0.5;
		color: grey;
		&:hover{
			text-decoration: none;
		}
	`}
`;

export const FinaliseButtonContainer = styled.div`
	padding-top: 60px;
`;

export const TeamName = styled.div`
	font-size: 12px;
	line-height: 1.125;
	text-align: left;
	color: ${colors.primary.primary};
	text-overflow: ellipsis;
	overflow: hidden;
	max-width: 60px;
	font-weight: 600;
	font-family: TitilliumUpright;
	${below.tablet`
		font-size: 14px;
		max-width: 120px;
	`}

	${above.phone`
		max-width: 150px;
	`}

	${above.tablet`
		max-width: 100px;
	`}

	${above.desktop`
		max-width: 200px;
	`}
`;

export const UserNameIndicatorWrapper = styled.div`
	display: flex;
	align-items: center;
`;

export const UserName = styled.div`
	font-size: 12px;
	line-height: 1.28;
	text-align: left;
	color: ${colors.primary.primary};
	text-overflow: ellipsis;
	overflow: hidden;
	max-width: 60px;
	font-family: SourceSansPro;
	font-weight: 400;

	${above.phone`
		max-width: 150px;
	`}

	${above.tablet`
		max-width: 100px;
	`}

	${above.desktop`
		max-width: 200px;
	`}

	${below.phone`
		display: none;
	`}
`;

export const JoinedWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-left: 5px;
	font-family: SourceSansPro;
	font-size: 12px;
	color: ${({ color }) => color};
	font-weight: 400;
	opacity: 0.9;
`;

export const JoinedIndicator = styled.div`
	height: 6px;
	width: 6px;
	border-radius: 50%;
	background-color: ${({ color }) => color};
	margin-right: 5px;
`;

export const Commish = styled(UserName)`
	font-size: 14px;
	text-align: center;
	padding-right: 10px;
`;

export const HeadingDiv = styled.div `
	text-transform: inherit;
	color: ${colors.primary.primary};
	font-family: "SourceSansPro";
	font-size: 12px;
	font-weight: 600;
	letter-spacing: 0;
	line-height: 12px;
	margin-bottom: 10px;
`;

export const DraftBody = styled.div`
	>:nth-child(odd){
		background-color: #F8F8FA;
	}
`;

export const CloseButton = styled.button`
	width: 25px;
	height: 25px;
	background: none;
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	padding: 0;
	outline: none;
	margin-right: 5px;
	cursor: pointer;
	svg{
		width: 25px;
		height: 25px;
		fill: ${colors.primary.primary};
	}
`;

export const InviteOpenWrapper = styled.div`
	display: flex;
	align-items: center;
	${({ isOpen }) => isOpen && `
		position: absolute;
		height: 50px;
		background: white;
		padding-left: 5px;
		left: 0;
	`}
`;