// @flow
import styled from "styled-components";

export const TransactionPlayersWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;

	@media (max-width: 374px) {
		flex-wrap: wrap;
	};
		
`;
export default TransactionPlayersWrapper;