// @flow
import * as React from "react";
import styled  from "styled-components";

import { below } from "../../assets/css/media";

type Props = {
	show_sidebar_mobile?: boolean
}

export const TwoColumnLayout = (styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	& > :first-child {
		width: calc(100% - 320px);
		margin-top: 0;
	}

	& > :last-child {
		width: 300px;
	}

	${below.desktop`
		flex-direction: column;
		& > :last-child {
			width: 100%;
			${({ show_sidebar_mobile }) => !show_sidebar_mobile && "display: none;"}
		}

		& > :first-child {
			width: 100%;
		}
	`};
`: React.ComponentType<Props> );

export default TwoColumnLayout;