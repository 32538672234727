// @flow
import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import bindAll from "lodash/bindAll";
import * as actions from "../../modules/actions";
import * as selectors from "../../modules/selectors";
import isAuthenticated from "../../utils/auth/isAuthenticated";
import { redirectTo } from "../../utils/url";
import {
	TextModal,
	Preloader,
	FantasyCoachBanner,
} from "../../components";

import CheckoutForm from "../coachSubscription/CheckoutForm";
import SuccessModal from "../coachSubscription/successModal";
import CoachSubscriptionSettings from "../CoachSubscriptionSettings";

type Props = {
	subscribeUser: typeof actions.subscribeUser,
	clearSubscribeUserErrors: typeof actions.clearSubscribeUserErrors,
	is_authorized: boolean,
	subscribe: {
		error: {message: string},
		success: boolean,
		is_pending: boolean,
	},
	has_coach_subscription: boolean,
	fetchUserSubscription: Function,
	subscription: Object,
};

type State = {
	show_success_modal: boolean,
	has_coach: boolean
}

class CoachSubscriptionComp extends React.Component<Props, State> {
	constructor() {
		super();

		bindAll(this, [
			"handleSubmit",
			"handleErrorModalClose",
			"handleSuccessModalClose"
		]);
	}

	state = {
		show_success_modal: false,
		has_coach: false
	};

	componentDidMount() {
		this.props.fetchUserSubscription();
	}

	componentDidUpdate(old_props: Props) {
		this.checkSubscriptionFetch(old_props);
		const { subscribe: old_sub } = old_props;
		const { subscribe: new_sub } = this.props;
		const didntHave = !(old_sub && old_sub.success);
		const haveNow = (new_sub && new_sub.success);
		if (didntHave && haveNow) {
			this.setState({
				show_success_modal: true
			});
			this.props.fetchUserSubscription();
		}
	}

	checkSubscriptionFetch(old_props: Props) {
		const { show_success_modal, has_coach } = this.state;
		if(this.has_subscription
			&& !has_coach
			&& !show_success_modal) {
			/* After fetching sub details, check if has
			 * coach and, therefore, should redirect
			 * (after modal is closed)
			 */
			this.setState({ has_coach: true });
		}
	}

	handleSubmit(values) {
		const { subscribeUser } = this.props;
		subscribeUser(values);
	}

	get has_subscription() {
		const { subscription } = this.props;
		return subscription && subscription.status && subscription.status !== "unsubscribed";
	}

	get error_modal() {
		const { subscribe } = this.props;

		let message = subscribe.error.message;

		if (message.includes("is already activated")) {
			message = "You're already subscribed to Fantasy Coach. "
				+ "You'll be able to re-subscribe once your current period has ended.";
		}

		return (
			<TextModal
				onClick={this.handleErrorModalClose}
				header_text='Error'
				text={message} />
		);
	}

	get success_modal() {
		return (
			<SuccessModal closeModal={this.handleSuccessModalClose} />
		);
	}

	handleSuccessModalClose() {
		this.setState({
			show_success_modal: false,
			has_coach: true
		});
		redirectTo("/game-selection");
	}

	handleErrorModalClose() {
		const { clearSubscribeUserErrors } = this.props;
		clearSubscribeUserErrors();
	}

	get check_is_authorized() {
		const { is_authorized } = this.props;
		return !is_authorized ? (<Redirect to="/" />) : null;
	}

	get get_content() {
		if(this.has_subscription && this.state.has_coach) {
			return <CoachSubscriptionSettings from_account />;
		}

		return <CheckoutForm
			isAccountPage={true}
			handleSubmit={this.handleSubmit}
			title="Subscribe to Fantasy Coach"
			is_active={false}
		/>;
	}

	showModals() {
		const { subscribe } = this.props;
		const { show_success_modal } = this.state;

		return (<React.Fragment>
			{ subscribe && subscribe.error ? this.error_modal : null }
			{ show_success_modal ? this.success_modal : null}
		</React.Fragment>);
	}

	render() {
		const { subscribe } = this.props;
		return (
			<React.Fragment>

				{ this.check_is_authorized }
				{ subscribe && subscribe.is_pending ? (<Preloader />) : null}
				{ this.showModals() }
				<FantasyCoachBanner />
				
				{this.get_content}
				
				
			</React.Fragment>
		);
	}
}

const mapStateToProps = state => ({
	is_authorized: isAuthenticated(),
	subscribe: state.user.subscribe,
	subscription: state.user.subscription,
	has_coach_subscription: Boolean(selectors.getUser(state).assistant_coach)
});

const mapDispatchToProps = {
	subscribeUser: actions.subscribeUser,
	clearSubscribeUserErrors: actions.clearSubscribeUserErrors,
	fetchUserSubscription: actions.fetchUserSubscription
};

const CoachSubscriptionContent = connect(
	mapStateToProps,
	mapDispatchToProps,
)(CoachSubscriptionComp);

export default CoachSubscriptionContent;