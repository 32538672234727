// @flow
import * as React from "react";
import { reduxForm, Field } from "redux-form";
import styled from "styled-components";

import { ForgotPassword } from "../../utils/FormValidations";

import {
	ButtonPrimary,
	SimpleFormField,
} from "../../components";
import { below } from "../../assets/css/media";
import colors from "../../assets/css/colors";

const ButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	margin: 0 1em;
	padding: 1em 0;

	button {
		min-width: 200px;
		margin: 0 1em;
		${below.desktop`
			min-width: 85px;
		`}
	}
`;

const ButtonCancel = styled(ButtonPrimary)`
	background: transparent;
	color: ${colors.buttons.cancel};
	border-color: ${colors.buttons.cancel};
`;

const FormItem = styled.div`
	display: flex;
	width: 100%;
	margin: 20px 0 10px 0;
	text-align: center;

    input{
       width: 60%;
       margin: 0 auto;
    }
`;

type Props = {
	handleSubmit: Function,
	closeClick: Function,
}

const ResetPasswordFormComponent = ({ handleSubmit, closeClick }: Props) => (
	<form onSubmit={handleSubmit}>
		<FormItem>
			<Field
				type='text'
				name='email'
				max_length={120}
				placeholder='Enter your email*'
				component={SimpleFormField}
			/>
		</FormItem>
		<ButtonWrapper>
			<ButtonCancel onClick={closeClick}>
				Cancel
			</ButtonCancel>
			<ButtonPrimary>
				Reset password
			</ButtonPrimary>
		</ButtonWrapper>
	</form>
);
export const ResetPasswordForm = reduxForm({
	form: "reset_password_form",
	validate: ForgotPassword,
})(ResetPasswordFormComponent);

export default ResetPasswordForm;