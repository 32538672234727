// @flow
import * as React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { above } from "../../assets/css/media";

import { ButtonPrimaryLink } from "../ButtonPrimary";
import TeamUserAvatar from "../Team/TeamUserAvatar";
import { getShortName } from "../../helpers/league";

const TeamUserAvatarWrapper = styled.div`
	flex: 0 0 40px;
	height: 40px;
	width: 40px;
`;

const TeamInfoBarContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px 0;
	border-bottom: 1px solid #F0F3F7;

	${above.tablet`
		padding: 20px 0 10px;
	`}
`;

const TeamName = styled.div`
	font-size: 14px;
	font-weight: 700;
	color: #1D4073;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 120px;

	${above.phone`
		max-width: 350px;
	`}
`;

const UserName = styled.div`
	font-size: 12px;
	font-weight: 500;
	color: #7F8A90;
	max-width: 120px;

	${above.phone`
		max-width: 350px;
	`}
`;

const Names = styled(Link)`
	display: flex;
	flex-direction: column;
	flex: 1 2 auto;
	margin-left: 10px;
	text-decoration: none;

	&:hover > ${TeamName} {
		text-decoration: underline;
	}
`;

const ViewTeamButton = styled(ButtonPrimaryLink)`
	${above.tablet`
		display: none;
	`}
`;

const getTeamPageLink = (
	is_classic: boolean,
	league_id: number,
	id: number,
	round_id: number,
	is_user: boolean
) => {
	if (is_classic) {
		return is_user ? "/classic/team" : `/classic/team/user/${id}`;
	}
	return `/draft/league/${
		league_id
	}/team/${
		id
	}/${
		round_id
	}`;
};

type Props = {
	id: number,
	user_id: number,
	avatar_version: number,
	name: string,
	firstname: string,
	lastname: string,

	league_id: number,
	round_id: number,
	is_classic: boolean,
	is_user?: boolean,
}

const TeamInfoBar = ({
	id, user_id, name, avatar_version, firstname, lastname,
	league_id, round_id, is_classic, is_user = false,
}: Props) => {
	const team_link = getTeamPageLink(is_classic, league_id, id, round_id, is_user);

	return <TeamInfoBarContainer>
		<TeamUserAvatarWrapper>
			<TeamUserAvatar
				user_id={user_id}
				avatar_version={avatar_version}
				firstname={firstname}
				lastname={lastname}
			/>
		</TeamUserAvatarWrapper>
		<Names to={team_link}>
			<TeamName>{name}</TeamName>
			<UserName>{getShortName({ firstname, lastname })}</UserName>
		</Names>
		<ViewTeamButton to={team_link}>
			View team
		</ViewTeamButton>
	</TeamInfoBarContainer>;
};

TeamInfoBar.displayName = "TeamInfoBar";

export default TeamInfoBar;