// @flow
import styled from "styled-components";
import React from "react";
import numeral from "numeral";
import * as _ from "lodash";
import type { 
	TPlayer, TMatch
} from "../../modules/types";
import * as format from "../../helpers/numeralFormats";
import colors from "../../assets/css/colors";

const DEFAULT_VALUE = "— —";

export const Price = styled.span`
	color: ${ props => props.positive ? colors.primary.accentGreen : "red"};
`;

export type TStat = {
	id: string,
	name: string,
	format?: string,
	tooltip?: string,
	is_coach?: boolean,
	not_draft?: boolean
};

export type TStatsTemplate = {
	played: Array<TStat>,
	unplayed?: Array<TStat>
};

export const priceDiff = (a: number, b: number) => {
	const abs_diff = Math.round(Math.abs(a - b) / 1000) * 1000;
	const sign = a >= b ? "+" : "-";
	return <Price positive={a >= b}>{sign + numeral(abs_diff).format(format.SHORT_PRICE)}</Price>;
};

const round_cum_sum = (collection, current_round) => (
	_.reduce({ ...collection }, (sub_total, next, key) => {
		if (Number(key) > current_round) {
			return sub_total;
		}
		return sub_total + next;
	}) || 0
);

const round_count = (collection, current_round) => {
	let count = 0;
	Object.keys(collection).forEach(round_id => {
		if(Number(round_id) <= current_round){
			count += 1;
		}
	});
	return count;
};

const percentage = p => numeral(p / 100).format(format.PERCENTAGE);

type TPrintFunction = (stat: TStat, player: TPlayer, round_id: number, match: TMatch) => any;

const getLastRoundId = (array: {[number]: any}) => {
	return _(array).keys().sortBy().last();
};

const totalPrinter: {[id: string]: (stat: TStat, player: TPlayer) => any} = {
	"stats.score": (stat, player) => {
		return _.get(player, "stats.total_points");
	},
	"stats.price_change": (stat, player) => {
		const { prices } = player.stats;
		const lastRound = getLastRoundId(prices);
		return priceDiff(prices[lastRound], prices[1]);
	},
	"stats.price_change_pct": (stat, player) => {
		const { prices } = player.stats;
		const lastRound = getLastRoundId(prices);
		const diff = prices[lastRound] - prices[1];
		return (
			<Price positive={diff >= 0}>
				{numeral(diff / prices[1]).format(format.PCT_DIFF)}
			</Price>
		);
	},
	"stats.price": (stat, player) => {
		return numeral(player.cost).format(format.SHORT_PRICE);
	},
	"stats.avg_points": (stat, player) => {
		return numeral(player.stats.avg_points).format(format.DEFAULT);
	},
	"stats.total_points": (stat, player) => {
		return numeral(player.stats.total_points).format(format.DEFAULT);
	},
	"custom_stats.avg_points": (stat, player) => {
		return numeral(player.custom_stats.avg_points).format(format.DEFAULT);
	},
	"custom_stats.score": (stat, player) => {
		return _.get(player, "custom_stats.total_points");
	},
	"custom_stats.total_points": (stat, player) => {
		return numeral(player.custom_stats.total_points).format(format.DEFAULT);
	},
};

const pastStatPrinter: {[id: string]: TPrintFunction} = {
	"stats.score": (stat, player, round_id) => {
		const { scores } = player.stats;
		if (!scores.hasOwnProperty(round_id)) {
			return "DNP";
		}
		return numeral(player.stats.scores[round_id]).format(format.DEFAULT);
	},
	"stats.price_change": (stat, player, round_id) => {
		const { prices } = player.stats;
		return priceDiff(prices[round_id + 1], prices[round_id]);
	},
	"stats.price_change_pct": (stat, player, round_id) => {
		const { prices } = player.stats;
		const diff = prices[round_id + 1] - prices[round_id];
		return (
			<Price positive={diff >= 0}>
				{numeral(diff / prices[round_id]).format(format.PCT_DIFF)}
			</Price>
		);
	},
	"stats.price": (stat, player, round_id) => {
		const { prices } = player.stats;
		return numeral(prices[round_id + 1]).format(format.SHORT_PRICE);
	},
	"stats.season_price_change": (stat, player, round_id) => {
		const { prices } = player.stats;
		return priceDiff(prices[round_id + 1], prices[1]);
	},
	"stats.avg_points": (stat, player, round_id) => {
		const { scores } = player.stats;
		const cum_sum = round_cum_sum(scores, round_id);
		const played = round_count(scores, round_id);
		if (played === 0) {
			return DEFAULT_VALUE;
		}
		const cum_avg = cum_sum / played;
		return numeral(cum_avg).format(format.DEFAULT);
	},
	"stats.total_points": (stat, player, round_id) => {
		const { scores } = player.stats;
		if (round_count(scores, round_id) === 0) {
			return DEFAULT_VALUE;
		}
		return numeral(round_cum_sum(scores, round_id)).format(format.DEFAULT);
	},
	"stats.played_opp": (stat, player, round_id) => {
		return DEFAULT_VALUE;
	},
	"stats.played_venue": (stat, player, round_id) => {
		return DEFAULT_VALUE;
	},
	"stats.break_even": (stat, player, round_id) => {
		const { break_evens } = player.stats;
		if (break_evens && break_evens[round_id]) {
			return numeral(break_evens[round_id]).format(format.DEFAULT);
		}
		return DEFAULT_VALUE;
	},
	"stats.break_even_pct": (stat, player, round_id) => {
		const { be_pct } = player.stats;
		if (be_pct && be_pct[round_id]) {
			return percentage(be_pct[round_id]);
		}
		return DEFAULT_VALUE;
	},
};

const statPrinter: {[id: string]: TPrintFunction} = {
	"stats.score": (stat, player, round_id, match) => {
		const { scores } = player.stats;
		if (!scores.hasOwnProperty(round_id)) {
			return "DNP";
		}
		return numeral(player.stats.scores[round_id]).format(format.DEFAULT);
	},
	"custom_stats.score": (stat, player, round_id, match) => {
		const { scores } = player.custom_stats;
		if (!scores || !scores.hasOwnProperty(round_id)) {
			return "DNP";
		}
		return numeral(player.custom_stats.scores[round_id]).format(format.DEFAULT);
	},
	"stats.price_change": (stat, player, round_id, match) => {
		const { prices } = player.stats;
		return priceDiff(prices[round_id + 1], prices[round_id]);
	},
	"stats.price_change_pct": (stat, player, round_id, match) => {
		const { prices } = player.stats;
		const diff = prices[round_id + 1] - prices[round_id];
		return (
			<Price positive={diff >= 0}>
				{numeral(diff / prices[round_id]).format(format.PCT_DIFF)}
			</Price>
		);
	},
	"stats.price": (stat, player, round_id, match) => {
		const { prices } = player.stats;
		return numeral(prices[round_id + 1]).format(format.SHORT_PRICE);
	},
	"stats.season_price_change": (stat, player, round_id, match) => {
		const { prices } = player.stats;
		return priceDiff(prices[round_id + 1], prices[1]);
	},
	"stats.avg_points": (stat, player, round_id, match) => {
		const { scores } = player.stats;
		const cum_sum = round_cum_sum(scores, round_id);
		const played = round_count(scores, round_id);
		if(round_id === 0){
			return scores[0];
		}
		if (played === 0) {
			return DEFAULT_VALUE;
		}
		const cum_avg = cum_sum / played;
		return numeral(cum_avg).format(format.DEFAULT);
	},
	"custom_stats.avg_points": (stat, player, round_id, match) => {
		const { scores } = player.custom_stats;
		const cum_sum = round_cum_sum(scores, round_id);
		const played = round_count(scores, round_id);
		if (played === 0) {
			return DEFAULT_VALUE;
		}
		const cum_avg = cum_sum / played;
		return numeral(cum_avg).format(format.DEFAULT);
	},
	"stats.total_points": (stat, player, round_id, match) => {
		const { scores } = player.stats;
		if (round_count(scores, round_id) === 0) {
			return DEFAULT_VALUE;
		}
		return numeral(round_cum_sum(scores, round_id)).format(format.DEFAULT);
	},
	"custom_stats.total_points": (stat, player, round_id, match) => {
		const { scores } = player.custom_stats;
		if (round_count(scores, round_id) === 0) {
			return DEFAULT_VALUE;
		}
		return numeral(round_cum_sum(scores, round_id)).format(format.DEFAULT);
	},
	"stats.proj_score": (stat, player, round_id, match) => {
		const { proj_scores } = player.stats;
		if (proj_scores && proj_scores[round_id]) {
			return numeral(proj_scores[round_id]).format(format.DEFAULT);
		}
		return DEFAULT_VALUE;
	},
	"custom_stats.proj_score": (stat, player, round_id, match) => {
		const { proj_scores } = player.custom_stats;
		if (proj_scores && proj_scores[round_id]) {
			return numeral(proj_scores[round_id]).format(format.DEFAULT);
		}
		return DEFAULT_VALUE;
	},
	"custom_stats.proj_score_next_round": (stat, player, round_id, match) => {
		const { proj_scores } = player.custom_stats;
		if (proj_scores && proj_scores[round_id]) {
			return numeral(proj_scores[round_id]).format(format.DEFAULT);
		}
		return DEFAULT_VALUE;
	},
	"stats.proj_price_change": (stat, player, round_id, match) => {
		const { proj_prices, prices } = player.stats;
		const all_prices = { ...proj_prices, ...prices };
		if (all_prices && all_prices[round_id + 1] && all_prices[round_id]) {
			return priceDiff(all_prices[round_id + 1], all_prices[round_id]);
		}
		return DEFAULT_VALUE;
	},
	"stats.played_opp": (stat, player, round_id, match) => {
		return DEFAULT_VALUE;
	},
	"stats.played_venue": (stat, player, round_id, match) => {
		return DEFAULT_VALUE;
	},
	"stats.avg_vs_opp": (stat, player, round_id, match) => {
		const { opponents } = player.stats;
		const opp_id = player.squad_id === match.home_squad_id 
			? match.away_squad_id 
			: match.home_squad_id;
		if (opponents && opponents[opp_id]) {
			return numeral(opponents[opp_id]).format(format.DEFAULT);
		}
		return DEFAULT_VALUE;
	},
	"custom_stats.avg_vs_opp": (stat, player, round_id, match) => {
		const { opponents } = player.custom_stats;
		const opp_id = player.squad_id === match.home_squad_id 
			? match.away_squad_id 
			: match.home_squad_id;

		if (opponents && opponents[opp_id]) {
			return numeral(opponents[opp_id]).format(format.DEFAULT);
		}
		return DEFAULT_VALUE;
	},
	"stats.avg_at_venue": (stat, player, round_id, match) => {
		const { venues } = player.stats;
		const { venue_id } = match;

		if (venues && venues[venue_id]) {
			return numeral(venues[venue_id]).format(format.DEFAULT);
		}
		return DEFAULT_VALUE;
	},
	"custom_stats.avg_at_venue": (stat, player, round_id, match) => {
		const { venues } = player.custom_stats;
		const { venue_id } = match;

		if (venues && venues[venue_id]) {
			return numeral(venues[venue_id]).format(format.DEFAULT);
		}
		return DEFAULT_VALUE;
	},
	"stats.break_even": (stat, player, round_id, match) => {
		const { break_evens } = player.stats;
		if (break_evens && break_evens[round_id]) {
			return numeral(break_evens[round_id]).format(format.DEFAULT);
		}
		return DEFAULT_VALUE;
	},
	"stats.break_even_pct": (stat, player, round_id, match) => {
		const { be_pct } = player.stats;
		if (be_pct && be_pct[round_id]) {
			return percentage(be_pct[round_id]);
		}
		return DEFAULT_VALUE;
	},
};

export const printStat = (stat: TStat, player: TPlayer, round_id: number, match: TMatch) => {
	if (player && match ) {
		if (statPrinter && statPrinter.hasOwnProperty(stat.id)) {
			return statPrinter[stat.id](stat, player, round_id, match);
		}
	}
	return DEFAULT_VALUE;
};

export const printPastStat = (stat: TStat, player: TPlayer, round_id: number) => {
	if (!_.isEmpty(player) ) {
		if (pastStatPrinter && pastStatPrinter.hasOwnProperty(stat.id)) {
			return pastStatPrinter[stat.id](stat, player, round_id);
		}
	}
	return DEFAULT_VALUE;
};


export const printTotal = (stat: TStat, player: TPlayer) => {
	if (!_.isEmpty(player)) {
		if (totalPrinter.hasOwnProperty(stat.id)) {
			return totalPrinter[stat.id](stat, player);
		}
	}
	return DEFAULT_VALUE;
};