// @flow
import React from "react";
import SvgIconContainer from "../SvgIconContainer";

export default (
	{
		unit = "em",
		...rest
	}: Object = {}) => (
	<SvgIconContainer viewBox="0 0 16 16" {...rest} unit={unit}>
		<g stroke="none" strokeWidth="1" fillRule="evenodd">
			<path d="M11.8186736,4 L11.8186736,9.32978015 L9.90949589,9.32978015 L12.4550661,
				12 L15,9.32978015 L13.0914587,9.32978015 L13.0914587,4 L11.8186736,4 Z M1,
				5.33244504 L9.90949589,5.33244504 L9.90949589,4 L1,4 L1,5.33244504 Z M1,
				8.66355763 L8.63671076,8.66355763 L8.63671076,7.33044637 L1,7.33044637 L1,
				8.66355763 Z M1,11.9946702 L6.72753307,11.9946702 L6.72753307,10.6622252 L1,
				10.6622252 L1,11.9946702 Z" />
		</g>
	</SvgIconContainer>
);