// @flow
import * as _ from "lodash";
import type { TMatch, TPlayer, ById } from "../../modules/types";

export type TFilterBy =  {
	squad_id: number,
	position: number,
	unlisted: boolean,
	matchup: boolean,
	favourites: boolean
}

const bySquad = (match: TMatch, filter_squad_id?: number) => (player: TPlayer) => {
	const squad_id = _.get(player, "squad_id");
	const home_id = _.get(match, "home_squad_id", 0);
	const away_id = _.get(match, "away_squad_id", 0);

	if (filter_squad_id) {
		return filter_squad_id === squad_id;
	}

	return squad_id === home_id || squad_id === away_id;
};

const byPlaying = (match: TMatch) => (player: TPlayer) => {
	const match_status = _.get(match, "status", "scheduled");
	const match_round = _.get(match, "round");

	if (match_status === "scheduled") {
		// return !_.includes(['injured', 'not-playing'], _.get(player, 'status'));
		return true;
	}

	const score = _.get(player, ["stats", "scores", match_round]);

	return score !== undefined;
};

const byPosition = (match: TMatch, position: number) => (player: TPlayer) => {
	if (position) {
		return _.includes(player.positions, position);
	}

	return true;
};

const byUnlisted = (
	match: TMatch, 
	unlisted: boolean, 
	owners?: Object
) => (player: TPlayer) => {
	if (unlisted && !_.isEmpty(owners)) {
		return _.get(owners, [player.id, "user_id"], 0) !== 0;
	}

	return true;
};

const byInMatchup = (
	match: TMatch, 
	in_matchup: boolean, 
	owners?: Object,
	matchup_ids?: number[]
) => (player: TPlayer) => {
	if (in_matchup && !_.isEmpty(owners) && !_.isEmpty(matchup_ids)) {
		const owner_id = _.get(owners, [player.id, "user_id"], 0);
		return _.includes(matchup_ids, owner_id);
	}

	return true;
};

const byFavourited = (match: TMatch, favourited: boolean) => (player: TPlayer) => {
	if (favourited) {
		return player.is_favourite;
	}

	return true;
};

export const filterPlayersByMatch = (
	match: TMatch, 
	players: ById<TPlayer>, 
	filters?: TFilterBy =  {
		squad_id: 0,
		position: 0,
		unlisted: false,
		matchup: false,
		favourites: false
	},
	owners?: Object,
	matchup_ids?: number[],
) => {
	return _(players)
		// Filter down to the set of players playing in the matchup
		.filter(bySquad(match, filters.squad_id))
		.filter(byPlaying(match))
		// Filter down to list of players wanted by user
		.filter(byPosition(match, filters.position))
		.filter(byUnlisted(match, filters.unlisted, owners))
		.filter(byInMatchup(match, filters.matchup, owners, matchup_ids))
		.filter(byFavourited(match, filters.favourites))
		// Map to ids, to give toplayers table
		.map(player => _.get(player, "id"))
		.value();
};

export default filterPlayersByMatch;