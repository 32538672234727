import React from 'react';

export const IconShare = ({ title, width, height, color, onClick }) => (
	<svg width={`${width}px`} height={`${height}px`} viewBox="0 0 19 21" onClick={onClick}>
		<title>{title}</title>
		<path
			d="M14.3181818,0 C12.5672034,0 11.1363636,1.42006805 11.1363636,3.15789391 
            C11.1363636,3.57078853 11.2134485,3.97165685 11.3600852,4.3338936 L5.31131629,
            7.66447167 C4.74613523,7.15628759 4.00048674,6.84210346 3.18181818,6.84210346 
            C1.43083977,6.84210346 0,8.26218204 0,10 C0,11.737818 1.43083977,13.1578965 
            3.18181818,13.1578965 C4.00372159,13.1578965 4.75364697,12.8472914 5.31960227,
            12.3355283 L11.3683712,15.6661064 C11.2192765,16.0309484 11.1363636,16.4255799 
            11.1363636,16.8421061 C11.1363636,18.5799214 12.5672034,20 14.3181818,20 
            C16.0691602,20 17.5,18.5799214 17.5,16.8421061 C17.5,15.1042644 16.0691602,
            13.6842122 14.3181818,13.6842122 C13.3648587,13.6842122 12.5078015,14.1057384 
            11.9235322,14.7697382 L5.98248106,11.4967128 C6.22580795,11.0508971 6.36363636,
            10.5403972 6.36363636,10 C6.36363636,9.45692383 6.22804583,8.95065554 5.98248106,
            8.50328724 L11.9235322,5.23026178 C12.5078254,5.89499845 13.3642462,6.31578781 
            14.3181818,6.31578781 C16.0691602,6.31578781 17.5,4.89570924 17.5,3.15789391 
            C17.5,1.42006805 16.0691602,0 14.3181818,0 L14.3181818,0 Z M14.3181818,1.0526313 
            C15.495961,1.0526313 16.4393939,1.98895737 16.4393939,3.15789391 C16.4393939,
            4.32684097 15.495961,5.26315651 14.3181818,5.26315651 C13.1404027,5.26315651 
            12.1969697,4.32684097 12.1969697,3.15789391 C12.1969697,1.98895737 13.1404027,
            1.0526313 14.3181818,1.0526313 Z M3.18181818,7.89473476 C4.35959735,7.89473476 
            5.3030303,8.83105031 5.3030303,10.0000026 C5.3030303,11.1689497 
            4.35959735,12.1052652 3.18181818,12.1052652 C2.00403902,12.1052652 1.06060606,
            11.1689497 1.06060606,10.0000026 C1.06060606,8.83105031 2.00403902,7.89473476 
            3.18181818,7.89473476 Z M14.3181818,14.7368435 C15.495961,14.7368435 16.4393939,
            15.673159 16.4393939,16.8421061 C16.4393939,18.0110268 15.495961,18.9473687 
            14.3181818,18.9473687 C13.1404027,18.9473687 12.1969697,18.0110268 12.1969697,
            16.8421061 C12.1969697,15.673159 13.1404027,14.7368435 14.3181818,14.7368435 
            L14.3181818,14.7368435 Z"
			id="share-icon"
			fillRule="nonzero"
			fill={color}
		/>
	</svg>
);

IconShare.defaultProps = {
	title: 'Share',
	width: 19,
	height: 21,
	color: '#fff',
};