// @flow
import React from "react";
import * as _ from "lodash";
import {
	TRoundWithMatches,
	TPlayersArrayWithFixtureById,
	TPosition,
	TDraftUserTeam,
	TPLeague,
	TMatch,
	TRound
} from "../../../../modules/types";
import { matchStarted } from "../../../../helpers/rounds";
import { shouldDoubleScore } from "../../../../helpers/stats/displayRound";
import FieldPlayer from "../../../../components/FieldPlayer";
import EmptyPlayer from "../../../../components/FieldPlayer/empty";
import { getIsLockedFromLockout, getPartialByeRound, lockoutKey } from "../../../../helpers";

const fieldStatHandler = (customScoringEnabled, field_stat) => {
	return customScoringEnabled && field_stat.includes("proj_score") 
		? "custom_stats.proj_score_next_round" : field_stat;
};

type Props = {
	player_id: number,
	players_by_id: TPlayersArrayWithFixtureById,
	has_assistant_coach: boolean,
	selected_round: TRoundWithMatches,
	position: TPosition,
	team: TDraftUserTeam,
	league: TPLeague,
	is_mobile: boolean,
	is_bench: boolean,
	field_stat: string,
	position_index: number,
	removePlayer: Function,
	setCaptain: Function,
	setViceCaptain: Function,
	rounds: TRound[],
	setEmergency: Function,
	openPlayerPopup: Function,
	areSubstitutesActive: Function,
	isOutSubstitute: Function,
	isInSubstitute: Function,
	substitutePlayer: Function,
	cancelSubstitute: Function,
	matches: Array<TMatch>,
	locked_personnel: any[],
	is_static: boolean,
	is_current: boolean,
	bye_detector_selected: number[],
	bye_detector_active: boolean,
	mobile_stat: Object,
	flip: boolean,
	toggleFantasyCoachModal: Function,
	setPlayerAction: Function,
	is_team_full?: boolean,
  actual_round: TRound,
  is_overflow?: boolean,
};

type State = {

};

export class DraftFieldPlayer extends React.Component<Props, State> {
	get emergency_position() {
		const { team: { lineup }, player_id } = this.props;

		if (this.is_emergency) {
			return parseInt(_.findKey(lineup.emergency, id => id === player_id), 10);
		}
		return undefined;
	}

	get is_emergency() {
		const { team: { lineup }, player_id } = this.props;

		return player_id !== 0 && player_id !== undefined
			&& lineup.emergency
			&& _.some(_.map(lineup.emergency, id => id === player_id));
	}

	get player() {
		return this.props.players_by_id[this.props.player_id] || {};
	}
	
	hasPlayerMatchStarted(player_id: number){
		const { players_by_id, selected_round } = this.props;

		const player = players_by_id[player_id];
		let has_player_started = false;
		if(selected_round && selected_round.matches){
			selected_round.matches.forEach(match => {
				const matchHomeSquad = _.get(match, 'home_squad_id');
				const playerSquad = _.get(player, 'squad_id');
				const matchAwaySquad = _.get(match, "away_squad_id");
				if(matchHomeSquad === playerSquad
          || matchAwaySquad === playerSquad){
					if(match.status !== "scheduled"){
						has_player_started = true;
					}
				}
			}) ;
			
		}
		return has_player_started;
		
	}
	


	render() {
		const {
			player_id,
			position,
			is_bench,
			removePlayer,
			setCaptain,
			setViceCaptain,
			setEmergency,
			substitutePlayer,
			cancelSubstitute,
			openPlayerPopup,
			has_assistant_coach,
			league,
			is_mobile,
			field_stat,
			areSubstitutesActive,
			isOutSubstitute,
			isInSubstitute,
			position_index,
			selected_round,
			players_by_id,
			team: { lineup, players_margin },
			matches,
			locked_personnel,
			is_static,
			is_current,
			bye_detector_selected,
			bye_detector_active,
			mobile_stat,
			flip,
			toggleFantasyCoachModal,
			setPlayerAction,
			is_team_full,
			actual_round,
			rounds,
			is_overflow
		} = this.props;
		if (!player_id) {
			const is_substitute = areSubstitutesActive();
			const in_substitute = isInSubstitute(null, position.id, position_index);
			const out_substitute = isOutSubstitute(null, position.id, position_index);
			return <EmptyPlayer
				key={`empty-${position_index}`}
				pos_id={position.id}
				pos_name={position.name}
				is_bench={is_bench}
				in_substitute={in_substitute}
				index={position_index}
				is_substitute={is_substitute}
				onClick={() => null}
				is_draft
				position_index={position_index}
				out_substitute={out_substitute}
				substitutePlayer={substitutePlayer}
				free_agent_link={`/draft/league/${league.id}/players/free-agent`}
				is_team_full={is_team_full}
			/>;
		}
    

		const player = this.player;
		const is_substitute = areSubstitutesActive();
		const out_substitute = isOutSubstitute(player, position.id);
		const in_substitute = isInSubstitute(player, position.id);

		const match_not_started = !matchStarted({ matches, id: 0 }, player.squad_id);

		const captain_id = _.get(lineup, "captain");
		const vice_captain_id = _.get(lineup, "vice_captain");
		const captain = _.get(players_by_id, captain_id);
		const double_score = shouldDoubleScore(player, lineup, selected_round, captain) 
			&& league.captains;
		
		const customScoringEnabled = _.get(league, "custom_scoring_enabled", false);
		const fieldStatHandled = fieldStatHandler(customScoringEnabled, field_stat);

		const player_bye = _.get(player, "stats.bye_round_id");
		const playerSquadId = _.get(player, 'squad.id', 0);
		const player_partial_bye = getPartialByeRound(playerSquadId, rounds);
		const lockout = lockoutKey(league);
		let is_locked = false;
		if(lockout === "rolling") {
			is_locked = this.hasPlayerMatchStarted(player.id);
		} 
		else if(lockout === "standard"){
			is_locked = _.get(selected_round, "status") !== "scheduled";
		}
		else {
			is_locked = getIsLockedFromLockout(
				Boolean(player.locked),
				is_static,
				selected_round,
				false
			);
		}
		return <FieldPlayer
			key={player_id}
			player={player}
			position={position.id}

			has_captain={league.captains}
			has_emergencies={league.emergency}
			is_locked={is_locked}
			is_bench={is_bench}
			captain={captain_id}
			player_partial_bye={player_partial_bye}
			vice_captain={vice_captain_id}
			is_emergency={this.is_emergency}
			locked_personnel={locked_personnel}
			customScoringEnabled={customScoringEnabled}

			emergency_position={this.emergency_position}
			has_assistant_coach={has_assistant_coach}
			is_classic={false}
			is_mobile={is_mobile}

			selected_round={selected_round}
			delist_link={`/draft/league/${league.id}/players/${player_id}/delist`}
			trade_link={`/draft/league/${league.id}/players/${player_id}/trade`}

			is_substitute={is_substitute}
			out_substitute={out_substitute}
			in_substitute={in_substitute}
			is_team_started={false}
			player_points={fieldStatHandled}

			removePlayer={removePlayer}
			setCaptain={setCaptain}
			setViceCaptain={setViceCaptain}
			setEmergency={setEmergency}
			substitutePlayer={substitutePlayer}
			cancelSubstitute={cancelSubstitute}
			showModalForPlayerId={openPlayerPopup}
			setPlayerAction={setPlayerAction}
			
			match_not_started={match_not_started}
			players_margin={players_margin}
			double_score={double_score}

			is_static={is_static}
			is_current={is_current}

			bye_detector_selected={bye_detector_selected}
			bye_detector_active={bye_detector_active}
			player_bye={player_bye}

			mobile_stat={_.get(mobile_stat, "key")}
			flip={flip || in_substitute || out_substitute}
			fantasyCoachModal={toggleFantasyCoachModal}
			actual_round={actual_round}
			is_overflow={is_overflow}
		/>;
	}
};

export default DraftFieldPlayer;