// @flow
import { createReducer } from "redux-act";
import _ from "lodash";
import * as actions from "../../actions";
import type { TNews } from "../../types";

const initialState = [];

export const news = createReducer({}, initialState);

news.on(actions.fetchNews, () => initialState);
news.on(actions.fetchNewsSuccess, (state, news: TNews) => (
	_.slice(_.orderBy(news, ({ date }) => new Date(date), "desc"),0,3)
));

export default news;