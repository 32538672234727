// @flow
import styled from "styled-components";
import { below } from "../../assets/css/media";
import { default_transition } from "../../assets/css/vars";

export const SwitchButton = styled.button`
	display: block;
    cursor: pointer;
	height: 50px;
	width: 25px;
	background-color: ${({ bgColor }) => bgColor ? bgColor : "#7F8A90"};
	border: none;
	color: #ffffff;
	border-radius: ${({ up }) => up ? "2px 2px 0 0;" : "0 0 2px 2px;"}
	outline: none;
	${default_transition}

	&:disabled {
		opacity: .1;
		cursor: default;
	}

	:hover:not(:disabled) {
		background-color: #a0a9ac;
	}

	svg {
		pointer-events: none;
		margin-top: ${({ noSvgMargin }) => noSvgMargin ? "0" : "5px"}
	}

	${below.phone`
		width: 40px;
		height: 20px;
		font-size: 9px;
	`};
`;

export default SwitchButton;