// @flow
import styled from "styled-components";
import { below } from "../../../../assets/css/media";

export const PoolPlayerStatus = styled.div`
	position: relative;
	display: flex;
	width: 30px;
	height: 60px;
	margin-left: 5px;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	z-index: 3;
	button {
		position: relative;
		z-index: 4;
	}
	&:before {
		content: '';
		display: block;
		width: 28px;
		height: 60px;
		background-image: radial-gradient(rgba(202,210,216, 0.6) 0%,
			rgba(202,210,216,0.00) 80%, rgba(202,210,216,0.00) 100%);
		background-size: contain;
		position: absolute;
		left: 0;
		top: 5px;
		z-index: 1;
	}
	&:after {
		content: '';
		display: block;
		width: 13px;
		height: 60px;
		background: #fff;
		position: absolute;
		left: -3px;
		top: 5px;
		z-index: 2;
	}

	${below.phone`
		margin-left: 0;
		width: 25px;
		&:after {
			width: 8px;
			left: 0px;
		}
	`}
`;
export default PoolPlayerStatus;