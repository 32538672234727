// @flow
import { createReducer } from "redux-act";
import { fetchOffSeasonPlayersSuccess } from "../../actions/offSeason";

const initialState = {
	players: [],
};

export const offSeasonPlayers = createReducer({}, initialState);

offSeasonPlayers.on(fetchOffSeasonPlayersSuccess, (state, players: Object) => ({
	players,
}));



export default offSeasonPlayers;