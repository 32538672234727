// @flow
import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

import { leagueDraft } from "../../modules/actions";

type Props = {
	match: {
		params: {league_id: string}
	},
	showDraftLeague: typeof leagueDraft.showDraftLeague,
	show_state: Object
}


class RedirectOnInvalidDraftLeagueComponent extends React.Component<Props> {
	componentDidMount() {
		const { showDraftLeague, match } = this.props;

		// check league id is valid
		if(/^\d+$/.test(match.params.league_id) === false) {
			window.location = "/draft/leagues/join";
		}

		showDraftLeague({ id: match.params.league_id });
	}

	render() {
		const { show_state, match } = this.props;
		if(
			show_state.error !== null &&
			match.params.league_id === show_state.league_id &&
			!show_state.is_pending
		) {
			return <Redirect to="/draft/leagues/join" />;
		}
		return null;
	}
}

const mapStateToProps = state => ({
	show_state: state.leagues.show
});

const mapDispatchToProps = {
	showDraftLeague: leagueDraft.showDraftLeague
};

const RedirectOnInvalidDraftLeague =
	connect(mapStateToProps, mapDispatchToProps)(RedirectOnInvalidDraftLeagueComponent);

export default RedirectOnInvalidDraftLeague;