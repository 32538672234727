// @flow
import React, {useEffect,useState} from "react";
import styled, { css } from "styled-components";
import { get } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Trade from "../../../components/Icons/Trade";
import Undo from "../../../components/Icons/Undo";
import PlayerInfo from "../../../components/PlayerInfo";
import colors from "../../../assets/css/colors";
import {
	Exist,
	Footer,
	OneColumnLayout,
	PageTitle,
	Preloader,
	Select,
	SectionHeader,
	ButtonDisabled,
	ButtonAccent,
	TextModal,
	ButtonAlert
} from "../../../components";
import { below } from "../../../assets/css/media";
import * as actions from "../../../modules/actions";
import {
	getPlayerById,
	getTeamsById,
	getUser
} from "../../../modules/selectors";
import { 
	changeDraftTeamNameError,
	getIsCommishTradeError,
	getIsCommishTradeLoading,
	getIsCommishTradeSuccess
} from "../../../modules/selectors/teamsDraft";
import { isAllTrue } from "../../../helpers";
import { isMobile } from "../../../utils";

export const PureButton = styled.button`
	border: 0;
	padding: 0;
	margin: 0;
	background: none;
	cursor: pointer;
	appearance: none;
`;

const StyledOneColumnLayout = styled(OneColumnLayout)`
	${below.tablet`
		margin-top: 0;
	`}
`;

const TeamWrap = styled.div`
    display: flex;
    justify-content: space-between;
    select {
    	-webkit-appearance: auto;
	    -moz-appearance: auto;
        margin-bottom: 20px;
    }
    .arrow {
        display:none;
    }
`;

const PlayerList = styled.div`
    margin: 20px 0;
`;

const PlayerWrap = styled.div`
    display:flex;
    justify-content: space-between;
`;

const TeamContent = styled.div`
    display:flex;
    flex-direction: column;
    max-width: 600px;
    width: 100%;
`;

const TradeCellsWrapper = styled.section`
    border-radius: 2px;
    background-color: #F8F8FA;
    width: 100%;
    box-sizing: border-box;
    padding: 30px 50px;
    margin-bottom: 20px;
	display: flex;
    justify-content: space-around;
	margin-bottom: 1em;
	${below.desktop`
		${css`
			justify-content: space-between;
		`}
	`}
`;
const TradeColumn = styled.div`
	width: 440px;
	${below.desktop`
		${css`
			width: 49%;
			.player-table-cell {
				height: 50px;
			}
			.player-details-info {
				flex-direction: column;
			}
		`}
	`}
`;

const TradeTitle = styled.p`
	font-family: "SourceSansPro";
	color: ${colors.secondary.accentGrey};
	font-size: 13px;
	margin-bottom: .5em;
	text-align: left;
	font-weight: 600;
`;


const ButtonsWrapper = styled.div`
	margin: 0 50px;
	${below.desktop`
		${css`
			margin: 0;
			display: flex;
			justify-content: space-between;
			flex-direction: column;
			align-items: center;
			padding-bottom: 20px;
		`}
	`}
	button {
		width: 160px;
		height: 40px;
		display: block;
		margin: 2.5em 0 1em;
		${below.desktop`
			${css`
				margin: 0 auto .5em;
			`}
		`}
	}
`;

const EmptyCell = styled.div`
	border-radius: 5px;
	box-shadow: 0 0 10px 0 rgba(127,138,144,0.5);
	color:  "#01285E"};;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 80px;
	margin: 0 0 10px;
	box-sizing: border-box;
	font-size: 14px;
	background:  "#fff"};
	
	&:last-child {
		margin-bottom: 0;
	}
	${below.desktop`
		${css`
			height: 50px;
		`}
	`}
`;

const PlayerToTradeWrap = styled.div`
    margin: 0 0 10px 0;
`;

const MobileMsg = styled.div`
    text-align: center;
    margin: 10px 0;
`;

type PlayerToTradeProps = {
    team_trades:number[],
    setTeamTrades: (trade:number[]) => void
};

function PlayerToTrade ({
	team_trades,
	setTeamTrades
}: PlayerToTradeProps) {
	const playersByID = useSelector(getPlayerById);
	return team_trades.map(item => {
		const player = get(playersByID,item,null);
		if(player === null) {
			return null;
		}
		const handleRemoveFromTeam = () => setTeamTrades(
			team_trades.filter((_, i) => 
				team_trades[i] !== Number(item)
			)
		);
		return <PlayerToTradeWrap key={item}>
			<PlayerWrap>
				<PlayerInfo player={player} />
				<PureButton aria-label="Undo" onClick={handleRemoveFromTeam}>
					<Undo size={2} />
				</PureButton>
			</PlayerWrap>
		</PlayerToTradeWrap>;
	});

}

type TradeOverviewProps = {
    team1_trades:number[],
    team2_trades:number[],
	setTeam1Trades: (trade:number[]) => void,
    setTeam2Trades: (trade:number[]) => void,
    handleTeamTrade: () => void
};

function TradeOverview ({
	team1_trades,
	team2_trades,
	setTeam1Trades,
	setTeam2Trades,
	handleTeamTrade
}: TradeOverviewProps) {
	const team1HasTrades = Boolean(team1_trades.length);
	const team2HasTrades = Boolean(team2_trades.length);
	const validTrade = team1HasTrades || team2HasTrades;
	const resetTrade = () => {
		setTeam1Trades([]);
		setTeam2Trades([]);
	};
    
	return <TradeCellsWrapper>
		<TradeColumn>
			<TradeTitle>
            Team 1 trades out:
			</TradeTitle>
			{team1_trades.length ? <PlayerToTrade 
				team_trades={team1_trades}
				setTeamTrades={setTeam1Trades}
			/>
				:<EmptyCell>Add a player to trade</EmptyCell>
			}
        
		</TradeColumn>
		<ButtonsWrapper>
			{validTrade ? <ButtonAccent onClick={handleTeamTrade}>
            Trade
			</ButtonAccent>:<ButtonDisabled>
            No Trade
			</ButtonDisabled>}
			<ButtonAlert disabled={!validTrade} onClick={resetTrade} inverted>
				Reset trade
			</ButtonAlert>
		</ButtonsWrapper>
		<TradeColumn>
			<TradeTitle>
            Team 2 trades out:
			</TradeTitle>
			{team2_trades.length ? <PlayerToTrade 
				team_trades={team2_trades}
				setTeamTrades={setTeam2Trades}
			/>:<EmptyCell>Add a player to trade</EmptyCell>
			}
		</TradeColumn>

	</TradeCellsWrapper>;
}

type TeamContentComponentProps = {
	team_number: number,
	team_id: number,
	other_team_id: number,
    team_trades:number[],
	setTeamID: (trade:number) => void,
	setTeamTrades: (trade:number[]) => void
};

function TeamContentComponent ({
	team_number,
	team_id,
	other_team_id,
	team_trades,
	setTeamID,
	setTeamTrades
}: TeamContentComponentProps) {
	const teamsByID = useSelector(getTeamsById);
	const playersByID = useSelector(getPlayerById);
	const lineup = get(teamsByID[team_id],"lineup",{});
	const lineupArr = Object.keys(lineup)
		.map(item => lineup[item]).flat()
		.filter(item => !isNaN(item));

	return <TeamContent>
		<SectionHeader>
			<span>Team {team_number}</span>
		</SectionHeader>
		<Select 
			name={`team_${team_number}`}
			is_disabled={false}
			onChange={setTeamID}
			value={team_id}>
			<option>Select Team {team_number}</option>
			{Object.keys(teamsByID).map(teamID => {
				const team = teamsByID[teamID];
				return <option 
					key={team.id} 
					value={team.id}
					disabled={other_team_id === Number(team.id)}
				>{team.name}</option>;
			})}
		</Select>
		<Exist when={Boolean(lineupArr.length)}>
			<PlayerList>
				{
					lineupArr.map(item => {
						const player = get(playersByID,item,null);
						if(player === null) {
							return null;
						}
						const isInTrade = team_trades.includes(item);
						const handleAddTeam = () => setTeamTrades([
							...team_trades,
							Number(item)
						]);
						const handleRemoveFromTeam = () => setTeamTrades(
							team_trades.filter((_, i) => 
								team_trades[i] !== Number(item)
							)
						);
						return <PlayerWrap key={item}>
							<PlayerInfo player={player} />
							{isInTrade ? <PureButton
								aria-label="Undo"
								onClick={handleRemoveFromTeam}
							>
								<Undo size={2} />
							</PureButton>:
								<PureButton aria-label="Trade" onClick={handleAddTeam}>
									<Trade size={2} />
								</PureButton>}
						</PlayerWrap>;
					})
				}
			</PlayerList>
		</Exist>
	</TeamContent>;
}

const handleEmptyTrade = (arr:number[]) => arr.length ? arr:[0];
function TeamSwap () {
	// const history = useHistory();
	const dispatch = useDispatch();
	const { league_id } = useParams();
	const user = useSelector(getUser);
	const errorMsg = useSelector(changeDraftTeamNameError);
	const isCommishTradeLoading = useSelector(getIsCommishTradeLoading);
	const isCommishTradeSuccess = useSelector(getIsCommishTradeSuccess);
	const isCommishTradeError = useSelector(getIsCommishTradeError);
    
	const [team1ID, setTeam1ID] = useState(0);
	const [team1Trades, setTeam1Trades] = useState([]);
	const [team2ID, setTeam2ID] = useState(0);
	const [team2Trades, setTeam2Trades] = useState([]);
	const league = useSelector(state => get(state.leagues.by_id, league_id, {}));
	const commissionerID = get(league,"commissioner",0);
	const userID = get(user,"id",0);
	const isCommisioner = isAllTrue([
		userID > 0, commissionerID===userID
	]);
	const handleTeamTrade = () => {
		const formData = {
			league_id,
			team_1_id: team1ID,
			team_1_player_ids:handleEmptyTrade(team1Trades),
			team_2_id:team2ID,
			team_2_player_ids:handleEmptyTrade(team2Trades),
		};
		dispatch(actions.teamsDraft.makeCommissionerTrade(formData));
	};

	const handleCloseSuccess = () => {
		setTeam1ID(0);
		setTeam2ID(0);
		setTeam1Trades([]);
		setTeam2Trades([]);
		dispatch(actions.teamsDraft.clearCommissionerTradeState());
		window.location.reload(false);
	};

	const handleCloseError = () => {
		dispatch(actions.teamsDraft.clearCommissionerTradeState());
	};
    
	const handleSetTeam1ID = (e: any) => {
		setTeam1ID(Number(e.target.value));
		setTeam1Trades([]);
	};
	const handleSetTeam2ID = (e: any) => {
		setTeam2ID(Number(e.target.value));
		setTeam2Trades([]);
	};

	useEffect(() => {
		dispatch(actions.leagueDraft.showDraftLeague({ id: league_id }));
		dispatch(actions.fetchPlayers());
	}, [dispatch, league_id]);
    
	if(!isCommisioner) {
		return null;
	}

	if(isMobile()){
		return <MobileMsg>This feature is currently only available on desktop.</MobileMsg>;
	}

	return  <>
		<StyledOneColumnLayout>
			<PageTitle>
                Team to Team Swap
			</PageTitle>
			<p>
                On this page you can manually perform trades{" "}
                on behalf of any two teams in the league.</p>
			<br />
			<br />
			<TradeOverview 
				team1_trades={team1Trades}
				team2_trades={team2Trades}
				setTeam1Trades={setTeam1Trades}
				setTeam2Trades={setTeam2Trades}
				handleTeamTrade={handleTeamTrade}
			/>
			<TeamWrap>
				<TeamContentComponent
					team_number={1}
					team_id={team1ID}
					other_team_id={team2ID}
					team_trades={team1Trades}
					setTeamID={handleSetTeam1ID}
					setTeamTrades={setTeam1Trades}
				/>
				<TeamContentComponent
					team_number={2}
					team_id={team2ID}
					other_team_id={team1ID}
					team_trades={team2Trades}
					setTeamID={handleSetTeam2ID}
					setTeamTrades={setTeam2Trades}
				/>
			</TeamWrap>
		</StyledOneColumnLayout>
		<Footer show_key />
		<Exist when={isCommishTradeLoading}>
			<Preloader/>
		</Exist>
		<Exist when={isCommishTradeSuccess}>
			<TextModal
				onClick={handleCloseSuccess}
				header_text="Trade Completed"
				text={"Your Commisioner Trade was successful"} 
			/>
		</Exist>
		<Exist when={isCommishTradeError}>
			<TextModal
				onClick={handleCloseError}
				header_text="Trade Error"
				text={errorMsg} 
			/>
		</Exist>
	</>;
}
	
export default TeamSwap;