// @flow
import { createReducer } from 'redux-act';
import * as actions from "../../actions/index";

export const initial_state = {
	is_pending: false,
	error: null,
	by_id: {},
};

export const checksums = createReducer({}, initial_state);

checksums.on(actions.fetchChecksums, state => ({
	...state,
	is_pending: true,
	error: null,
}));
checksums.on(actions.fetchChecksumsFailed, (state, payload) => ({
	...state,
	is_pending: false,
	error: payload,
}));
checksums.on(actions.fetchChecksumsSuccess, (state, payload) => ({
	...state,
	is_pending: false,
	error: null,
	by_id: payload,
}));


export default checksums;