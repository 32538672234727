import { createSelector } from "reselect";
import _ from 'lodash';

export const getTeamsDraftInternal = state => state.teamsDraft;

export const getIsCommishTradeIdle = createSelector(
	getTeamsDraftInternal,
	state => _.get(state, 'commissioner_trade_request') === "idle"
);
export const getIsCommishTradeLoading = createSelector(
	getTeamsDraftInternal,
	state => _.get(state, 'commissioner_trade_request') === "loading"
);
export const getIsCommishTradeSuccess = createSelector(
	getTeamsDraftInternal,
	state => _.get(state, 'commissioner_trade_request') === "success"
);
export const getIsCommishTradeError = createSelector(
	getTeamsDraftInternal,
	state => _.get(state, 'commissioner_trade_request') === "error"
);

export const isChangeDraftTeamNameIdle = createSelector(
	getTeamsDraftInternal,
	state => _.get(state, 'team_name_request') === "idle"
);
export const isChangeDraftTeamNameLoading = createSelector(
	getTeamsDraftInternal,
	state => _.get(state, 'team_name_request') === "loading"
);
export const isChangeDraftTeamNameSuccess = createSelector(
	getTeamsDraftInternal,
	state => _.get(state, 'team_name_request') === "success"
);
export const isChangeDraftTeamNameError = createSelector(
	getTeamsDraftInternal,
	state => _.get(state, 'team_name_request') === "error"
);

export const changeDraftTeamNameError = createSelector(
	getTeamsDraftInternal,
	state => _.get(state, 'error')
);

export const getPreDraftList = (state: Object) => _.get(state, 'teamsDraft.pre_draft_list', []);

export const getShowTeam = (state: Object) => _.get(state, 'teamsDraft.user_team', []);

export const isPDLReceived = (state: Object) => (
	_.get(state, 'teamsDraft.is_pre_draft_list_received', false)
);