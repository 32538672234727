// @flow
import styled from "styled-components";
import colors from "../../assets/css/colors";

import StarIcon from "../../components/Icons/Star";

export const FavouriteStarIcon = styled(StarIcon)``;

export const FavouriteButton = styled.button`
	background: ${({ active }) => active ? colors.favourite_background : colors.secondary.paleGrey};
	border: 0 none;
	margin: 0;
	padding: 0;
	position: absolute;
	z-index: 9;
	top: 10px;
	right: 10px;
	display: flex;
	font-size: 16px;
	border-radius: 50%;
	width: 30px;
	height: 30px;
	align-items: center;

    svg {
        margin-left: 7px;
    }

	${FavouriteStarIcon} {
		color: ${({ active }) => active ? colors.favourite_forground : "#FFFFFF"};
	}

	&:hover {
		${FavouriteStarIcon} {
			stroke-width: 1;
			stroke: ${colors.favourite_forground};
		}
	}
`;