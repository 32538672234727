// @flow
import * as React from "react";

import styled from "styled-components";
import { Label, Tooltip, Checkbox } from "../../Form";
import ErrorField from "../ErrorField";
import colors from "../../../assets/css/colors";

const LabelStyled = styled(Label)`
	color: ${colors.primary.darkGrey};
	font-size: 14px;
	line-height: 20px;
	flex-basis: ${({ noBasis, tnc }) => (noBasis || tnc ? "auto" : "80%")};
	font-family: "SourceSansPro";
	a {
		color: ${colors.primary.darkGrey};
	}
`;

const CheckboxWrapper = styled.div`
	display: flex;
`;

type Props = {
	noBasis: boolean,
	label: string,
	type: string,
	input: Object,
	tooltip?: {
		title: string,
		description: string,
	},
	meta: {
		touched: any,
		error: string,
		warning: string,
	},
  tnc?: boolean,
};

const is_error = meta => {
	return !!(meta.touched && meta.error);
};

export const FormCheckbox = ({
	noBasis=false,
	label,
	input,
	tooltip,
	meta: { touched, error, warning },
	tnc
}: Props ) => (
	<div>
		{ touched && ((
			error && <ErrorField>{error}</ErrorField>) ||
			(warning && <ErrorField>{warning}</ErrorField>
			))
		}
		<CheckboxWrapper>
			<Checkbox {...input} tnc={tnc}/>
			<LabelStyled is_error={is_error({ touched, error })} noBasis={noBasis} tnc={tnc}>
				{label}

				{tooltip ? (
					<Tooltip {...tooltip} />
				): null}
			</LabelStyled>
		</CheckboxWrapper>
	</div>
);

export default FormCheckbox;