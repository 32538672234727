// @flow
import _ from "lodash";
import * as React from "react";
import moment from "moment";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import styled from "styled-components";
import colors from "../../assets/css/colors";
import type { TCellInfo } from "../../helpers/tables";
import { below } from "../../assets/css/media";
import * as actions from "../../modules/actions";
import * as selectors from "../../modules/selectors";
import type { TRootStore, TPlayer, TExtendedDraftTeam } from "../../modules/types";
import Lock from "../Icons/Lock";
import withDraftCore from "../Draft/withDraftCore";
import type { TDraftPassedProps } from "../Draft/withDraftCore";
import PlayerInfo from "../PlayerInfo";
import IgnoreCheckbox from "../Draft/IgnoreCheckbox";
import TdStat from "../Draft/TdStat";
import TrRow from "../Draft/TrRow";
import Table, { TBody, Td, Tr } from "../../components/TableX";
import { 
	FantasyCoachModal, 
	ThStat, 
	TableHead, 
	DraftFilters,
	DraftTableHeader,
	ThIgnore,
	ButtonPrimary,
	Exist
} from "../../components";
import { renderTableHeaderCell } from "../../helpers/tables";
import EmptyPlayer from "../Icons/EmptyPlayer";
// import { isMobile } from '../../utils';
import SaveButton from "../../pages/draft/league/preDraft/saveButton";
import ControlButtonsContainer from "../../pages/draft/league/preDraft/controlButtonsContainer";
import NoDrop from "../Icons/NoDrop";
import LockCircle from "../Icons/LockCircle";
import { isMobile } from "../../utils";
import { getKeeperDeadlinePassed, threeDaysPriorKeeperDeadline } from "../../helpers";
import NoKeepersModal from "./NoKeepersModal";

const CURRENT_YEAR = Number(process.env.REACT_APP_SEASON_YEAR);

const PAST_YEAR = CURRENT_YEAR - 1;


const DisableTableWrapper = styled.div`
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: 99;
	background-color: rgba(240, 243, 247, 0.9);
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	padding-top: 150px;
	align-items: center;
	font-size: 20px;
	color: ${colors.primary.primary};
	font-family: SourceSansPro;
	font-weight: 600;
	svg{
		height: 60px;
		width: 60px;
		path{
			fill: ${colors.primary.primary};
		}
	}
	${below.phone`
		padding-top: 100px;
	`}
`;

const DisableKeeperText = styled.div`
	text-align: center;
	color: ${colors.primary.primary};
	font-family: SourceSansPro;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	width: 600px;
	margin-bottom: 20px;
	${below.phone`
		width: 100%;
	`}
`;

const DisableKeeperHeader = styled.h1`
	font-family: TitilliumUpright;
	font-weight: bold;
	font-size: 32px;
	line-height: 32px;
	margin: 20px 0;
	color: ${colors.primary.primary};
	text-align: center;
`;

const RetiredPlayerWrap = styled.div`
	display: flex;
	height: 100%;
	.empty-player{
		height: 90%;
		width: 45px;
		align-self: flex-end
	}
	.no-drop{
		align-self: center;
		width: 16px;
		height: 16px;
		margin: 0 10px 0 5px;
		${({is_compressed}) => is_compressed && `
			width: 12px;
			height: 12px;
			margin: 0;
		`}
	}

`;

const PlayerInfoWrap = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	height: 100%;
	${({is_compressed}) => is_compressed && `
		flex-direction: row;
		gap: 5px;
		align-items: center;
	`}
	span{
		color: #1D4073;
		font-family: SourceSansPro;
		font-weight: 400;
		font-size: 12px;
		text-align: left;
		white-space: nowrap;
		max-width: 12em;
		text-overflow: ellipsis;
		overflow: hidden;
		}
`;

const KeepMobileButton = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	outline: none;
	border: none;
	padding: 0px;
	background: none;
	box-shadow: -8px 0px 35px -14px #333;
`;

const NoKeepersWrapper = styled.div`
	display: flex;
	${below.phone`
		height: 100vh;
		width: 100%;
	`}
	
`;

const PlayerName = styled.p`
	color: #1D4073;
	font-family: TitilliumUpright;
	font-weight: 700;
	font-size: 16px;
	cursor: pointer;
	text-align: left;
	margin-bottom: 5px;
	line-height: 1.3;
	white-space: nowrap;
	max-width: 12em;
	text-overflow: ellipsis;
	overflow: hidden;
	${({is_compressed})=> is_compressed && `
		font-size: 12px;
		margin-bottom: 0;
		margin-top: 3px;
		margin-left: 5px;
	`}
`;

const TdShowAvatar = styled(TdStat)`
	${below.phone`
		.player-avatar {
			display: inline;
		}
	`};
`;


const PreDraftTable = styled(Table)`
	.align-center{
		.column-name{
			text-align: center;
		}
	}
	${below.tablet`
		.player-stats-head{
			background-color: #f8f8fa;
		}
		& td,
		& th {
			position: relative;
			display: table-cell;
		}
	
		.last-right-th{
			position: sticky;
			right: -2px;
			min-width: 50px;
			background: white;
			box-shadow: -3px 2px 30px -17px #333;
			z-index: 2;
			text-transform: capitalize;
			font-family: SourceSansPro;
			font-weight: 600;
			padding-bottom: 2px;
		}
		.last-right-td {
			position: sticky;
			right: -2px;
			min-width: 50px;
			background: white;
			box-shadow: -3px 2px 30px -17px #333;
			z-index: 10;
		}
		.absolute-group th{
			height: 59px;
			position: sticky;
			left: 0;
			width: 162px;

		}
		.align-center th{
			height: 56px;
		}
		.absolute-group{
			.column-name{
				span{
					text-transform: capitalize;
					font-family: SourceSansPro;
					font-weight: 600;
				}
			}
		}
	`};
`;

const PlayerTableContainer = styled.div`
	position: relative;
	overflow-x: auto;
    width: 100%;
    overflow-y: hidden;
	th{

	}
	${below.desktop`
    overflow-y: auto;
		${({ isNoOverflow }) => isNoOverflow && `
			overflow: hidden;
		`}
	`}
`;

const ManageKeepersWrap = styled.div`
	${below.tablet`
		width: 100%;
	`}
`;

const StyledTrRow = styled(TrRow)`
	
	${below.tablet`
		position: relative;
	`}
`;

const AbsoluteDiv = styled.div`
	${({ is_retired }) => is_retired && `
		
	`}
	position: sticky;
	left: 0;
	background-color: ${({ is_even }) => is_even ? "#f8f8f8" : "#fff"};
	z-index: 2;
	${below.phone`
		max-width: 210px;
		box-shadow: 10px 0 14px -14px;
		.player-names{
			max-width: 70px;
		}
		input{
			height: 40px;
		}
	`}
`;

const FcLink = styled.a`
	color: ${colors.coach};
	cursor: pointer;
`;

type Props = TDraftPassedProps & {
	fetchTeams: typeof actions.leagueDraft.showTeamsDraftLeague,
	userTeam: TExtendedDraftTeam,
	all_stats: Object,
	coachSelectValue: string,
	addKeeper: Function,
	removeKeeper: Function,
	onResetAllFilters: Function,
	toggleKeeper: Function,
	is_keeper_saving: boolean,
	team_keepers: Object[],
	toggleKeeperModal: Function,
	fetchPastYearPlayers: typeof actions.fetchPastYearPlayers,
	past_years: Object,
}

type State = {
	show_fantasy_coach_modal: boolean,
	selected_squad: number,
	show_no_keepers_modal: boolean,
};

class ManageKeepersComponent extends React.Component<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {
			selected_squad: -1,
			show_fantasy_coach_modal: false,
			show_no_keepers_modal: false
		};

		_.bindAll(this, [
			"toggleFantasyCoachModal",
			"columnToCellInfo",
			"orderByComparator"
		]);
	}

	componentDidMount() {
		const { fetchPastYearPlayers } = this.props;
		fetchPastYearPlayers(`${PAST_YEAR}`);
	}

	componentDidUpdate(prevProps) {
		
	}

	getPlayerSeasonStat(player: TPlayer, playerAllStats: Object, prop: string) {
		if (_.includes(prop, "games_played")) {
			return _.get(player, prop, "- -");
		}

		return _.get(playerAllStats, prop, "- -");
	}

	toggleFantasyCoachModal() {
		const { show_fantasy_coach_modal } = this.state;
		this.setState({ show_fantasy_coach_modal: !show_fantasy_coach_modal });
	}

	columnToCellInfo(column: Object): TCellInfo {
		return {
			table: {
				order_by_desc: this.props.order_by_desc,
				sort_key: this.props.sort_by
			},
			cell: {
				sort_key: column.prop,
				name: column.key,
				short_name: column.key,
				rel: column.prop,
				sortable: true,
				onClick: this.props.onSortColumnChange,
				...column,
			}
		};
	}
	orderByComparator(player_one, player_two) {

		const { sort_by, order_by_desc } = this.props;

		let value_a = this.getPlayerStatValue(player_one, sort_by);
		let value_b = this.getPlayerStatValue(player_two, sort_by);
		if(sort_by === 'dob'){
			value_a = new Date(value_a).getTime();
			value_b = new Date(value_b).getTime();
		}
			
		const 
			order = order_by_desc ? 1 : -1,
			minimal = Number.MAX_SAFE_INTEGER * order,
			getResult = (condition, min, value) => condition(value) ? min : value,
			isStringOrZero = val => _.isString(val) || _.eq(val, 0),
			getResultOrMin = _.partial(getResult, isStringOrZero, minimal * -1),
			a_result = getResultOrMin(value_a),
			b_result = getResultOrMin(value_b),
			is_bigger = a_result < b_result,
			is_less = a_result > b_result;
		if(sort_by)
			if (is_bigger) {
				return order;
			}

		if (is_less) {
			return -1 * order;
		}

		return 0;
	}

	get keeper_list_players() {
		const { 
			userTeam, 
			players_by_id, 
			has_filters,
			players_filter,  
		} = this.props;
		const { keeper_list } = userTeam;
		if(_.isEmpty(keeper_list)){
			return [];
		}
		const playerList = keeper_list.map((player => {
			const playerData = _.get(players_by_id, player.id, {});
			const handledData = _.omit(playerData, ["id", "first_name", "last_name", "squad_id"]);
			return {
				...player,
				...handledData
			};
		}));
		if(has_filters){
			return playerList
				.filter(players_filter)
				.sort(this.orderByComparator);
		}
		return playerList.sort(this.orderByComparator);
	}
	renderRetiredPlayer(player, is_even){
		const { is_compressed, coachSelectValue, table_keys, isActiveTab } = this.props;
		return <StyledTrRow
			is_odd={!is_even}
			key={player.id}
			is_compressed={is_compressed}
			scrollable={coachSelectValue === "custom-stats"}
		>
			<AbsoluteDiv is_even={is_even} is_retired>
				<TdShowAvatar is_compressed={is_compressed} pre_draft={true} 
					player_info={true} className="player-info-stat">
					<RetiredPlayerWrap is_compressed={is_compressed}>
						<Exist when={!is_compressed}>
							<EmptyPlayer className="empty-player"/>
						</Exist>
					
						<NoDrop className="no-drop" />
						<PlayerInfoWrap is_compressed={is_compressed}>
							<PlayerName is_compressed={is_compressed}>
								{player.first_name} {" "} {player.last_name}
							</PlayerName>
							<span>Retired</span>
						</PlayerInfoWrap>

					</RetiredPlayerWrap>
					
				</TdShowAvatar>
			</AbsoluteDiv>
			{table_keys.map(({ key, prop, is_fc }) => (
				<TdStat is_compressed={is_compressed}
					className={`player-stats ${isActiveTab(prop)}`}
					key={key}
					is_fc={is_fc}
				/>
			))}
			<Td is_compressed={is_compressed} className="last-right-td" />
		</StyledTrRow>;
	}

	get table_header() {
		const { table_keys, show_kept_players } = this.props;
		const classNameFirstBlock = show_kept_players ? " align-center" : "";
		table_keys.unshift({ key: "AGE", prop: "dob", tooltip: "Date of Birth" });
		const cells = _(table_keys)
			.map(this.columnToCellInfo)
			.map(cell => renderTableHeaderCell(cell, ThStat, "player-stats-head"))
			.value();
		const { is_coach_active } = this.props;
		const player_info_column = {
			prop: "player-info",
			key: "",
			is_fc: false,
			tooltip: undefined,
			width: isMobile() ? "162px" : "181px",
			sortable: false,
		};

		return (
			<TableHead className={is_coach_active ? "coach-stats" : "basic-stats"}>
				<Tr className="table-header-row">
					
					<th className={"absolute-group" + classNameFirstBlock}>
						{renderTableHeaderCell(this.columnToCellInfo(player_info_column))}
					</th>
					{ cells }
					<ThIgnore className="last-right-th"> 
							Keep
					</ThIgnore> 

				</Tr>
			</TableHead>
		);
	}

	get keeper_deadline_passed(){
		const { league } = this.props;
		const { draft_start, keeper_deadline } = league;
		return getKeeperDeadlinePassed(
			draft_start,
			keeper_deadline
		);
	}

	get keeper_deadline_met() {
		const { league } = this.props;
		const { 
			draft_start, 
			keeper_deadline 
		} = league;

		const threeDaysPriorDate = threeDaysPriorKeeperDeadline(draft_start);
		const countdownTime = new Date(keeper_deadline) || threeDaysPriorDate;
		return countdownTime === new Date();
	}

	tableInactive(){
		const { league } = this.props;
		const isFinalised = _.get(league, "teams_finalised", 0) === 1;
		
		if(this.keeper_deadline_passed){
			return (
				<DisableTableWrapper>
					<LockCircle />
					<DisableKeeperHeader>
						Keeper selections locked
					</DisableKeeperHeader>
					
					<DisableKeeperText>
						Your league's keepers selections have now locked! 
						Time to shift your focus to Draft planning. 
						All the kept players have now been removed 
						from the pre draft list. Go set your rankings!
					</DisableKeeperText>
					<ButtonPrimary onClick={_.partial(this.props.toggleKeeperModal)}>
						Pre Draft List
					</ButtonPrimary>
					
				</DisableTableWrapper>
			);
		}
		if(!isFinalised){
			return(
				<DisableTableWrapper>
					<LockCircle />
					<DisableKeeperHeader>
					Keeper selections locked
					</DisableKeeperHeader>
				
					<DisableKeeperText>
						Participants must be finalised by the 
						league commissioner before you can select keepers.
					</DisableKeeperText>
					<ButtonPrimary onClick={_.partial(this.props.toggleKeeperModal)}>
					Pre Draft List
					</ButtonPrimary>
				
				</DisableTableWrapper>
			);
		}
		return null;
		
	}

	getPlayerStatValue(player: TPlayer, prop) {
		const { coachSelectValue, all_stats } = this.props;
		const playerAllStats = _.get(all_stats, `${player.id}`, {});
		// const playerHistoric = _.get(past_years, `${PAST_YEAR}.${player.id}`, {});
		if(coachSelectValue === "custom-stats" ){
			return this.getPlayerSeasonStat(player, playerAllStats, prop);
		}
		
		return _.get(player, prop, "--");
	}

	render(){
		const { 
			coachSelectValue,
			userTeam,
			table_keys,
			isActiveTab,
			has_assistant_coach,
			onFiltersChange,
			onSearchSubmit,
			onToggleOrder,
			league,
			order_by_desc,
			squads,
			positions,
			playerStatuses,
			onSelectChange,
			stats_options_list,
			onResetAllFilters,
			filters,
			show_kept_players,
			toggleKeptPlayers,
			is_coach_active,
			onCompressedViewClick,
			onPlayerShowLimitClick,
			team_keepers,
			toggleKeeperModal,
			is_compressed
		} = this.props;
		const { show_fantasy_coach_modal } = this.state;
		const { keeper_num } = league;
		const tableHeader = `You can keep up to ${keeper_num} players`;

		const player_list = this.keeper_list_players;

		const { active, keeper_list } = userTeam;

		const no_keeper = keeper_list === null;

		const isActiveAndNoKeeper = Boolean(active) && no_keeper;
		
		if(!userTeam) {
			return null;
		}

		if(isActiveAndNoKeeper){
			// When modal is open prevent scrolling elsewhere
			if(document.body !== null){
				document.body.style.position = "fixed";
				document.body.style.top = `-${window.scrollY}px`;
			}
			return(
				<NoKeepersWrapper>
			 		<NoKeepersModal toggleModal={toggleKeeperModal} />
				</NoKeepersWrapper>
			);
		}

		if(this.keeper_deadline_met){
			window.location.reload(false);
		}
		
		const cells = player_list.map((player, index) => {
			const playerDOB = _.get(player, "dob");
			const playerAge = Math.floor(moment().diff(playerDOB, "years", true));
			const is_even = (index + 1) % 2 === 0;
			const isSelected = team_keepers.find(keptPlayer => 
				Number(keptPlayer.id) === Number(player.id));
			if(!Boolean(player.squad_id)){
				return this.renderRetiredPlayer(player, is_even);
			}
			return (
				<StyledTrRow
					is_odd={!is_even}
					key={player.id}
					is_compressed={is_compressed}
					scrollable={coachSelectValue === "custom-stats"}
				>
					<AbsoluteDiv is_even={is_even}>
						<TdShowAvatar is_compressed={is_compressed} pre_draft={true} 
							player_info={true} className="player-info-stat">
							<PlayerInfo
								className="player-info"
								player={player}
								is_compressed={is_compressed}
								is_classic={false}
								pre_draft={true}
							/>
						</TdShowAvatar>
					</AbsoluteDiv>
					<TdStat is_compressed={is_compressed}
						className={`player-stats ${isActiveTab("dob")}`}
						key={`dob-${player.id}`}
						is_fc={false}
					>
						{playerAge}		
					</TdStat>
					{table_keys.map(({ key, prop, is_fc }) => (
						<TdStat is_compressed={is_compressed}
							className={`player-stats ${isActiveTab(prop)}`}
							key={key}
							is_fc={is_fc}
						>
							{is_fc && !has_assistant_coach ?
								(<FcLink onClick={this.toggleFantasyCoachModal}>
									<Lock color={colors.coach} />
								</FcLink>):	this.getPlayerStatValue(player, prop)}		
						</TdStat>
					))}
					<Td is_compressed={is_compressed} className="last-right-td">
						{isMobile() ?
							<KeepMobileButton>
								<IgnoreCheckbox
									onChange={this.keeper_deadline_passed 
										? null 
										: this.props.toggleKeeper}
									checked={isSelected}
									name={`keeper-${player.id}`}
									pre_draft={true}
								/>
							</KeepMobileButton>:
							<IgnoreCheckbox
								onChange={this.keeper_deadline_passed 
									? null 
									: this.props.toggleKeeper}
								checked={isSelected}
								name={`keeper-${player.id}`}
								pre_draft={true}
							/>
						}
					</Td>
				</StyledTrRow>
			);
		});

		return (
			<React.Fragment>
				{
					show_fantasy_coach_modal ?
						<FantasyCoachModal
							closeClick={this.toggleFantasyCoachModal}
						/>
						: null
				}
				<ManageKeepersWrap>
					<DraftFilters
						handleChange={onFiltersChange}
						handleSubmit={onSearchSubmit}
						onToggleOrder={onToggleOrder}
						league={league}
						order_by_desc={order_by_desc}
						squads={squads}
						positions={positions}
						statuses={playerStatuses}
						is_show_drafted={false}
						onSelectChange={onSelectChange}
						selectOptions={stats_options_list}
						onResetAllFilters={onResetAllFilters}
						show_keeper_countdown={true}
						filters={filters}
						show_kept_players={show_kept_players}
						toggleKeptPlayers={toggleKeptPlayers}
						show_ignored
					/>
					<DraftTableHeader
						title={tableHeader}
						onSelectChange={onSelectChange}
						isCoachActive={is_coach_active}
						selectOptions={stats_options_list}
						is_compressed={is_compressed}
						onCompressedViewClick={onCompressedViewClick}
						onPlayerShowLimitClick={onPlayerShowLimitClick}
						manage_keepers={true}
						middleArea={(
							<ControlButtonsContainer>
								<SaveButton
									onClick={null}
									is_keeper={true}
									is_saved={!this.props.is_keeper_saving}
								/>
							</ControlButtonsContainer>
						)}
						show_fantasy_ad
					/>
					<PlayerTableContainer isNoOverflow={this.keeper_deadline_passed}>
						{this.tableInactive()}
						<PreDraftTable>
						
							{ this.table_header }
							<TBody> 
								
								{ cells }
							</TBody>
						</PreDraftTable>
					</PlayerTableContainer>
					
				</ManageKeepersWrap>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: TRootStore, props: Props) => {
	const league = selectors.leagueDraft.getLeague(state, props);
	const past_years = state.players.past_years;
		
	const all_stats = state.players.all_stats;
	return {
		league,
		all_stats,
		past_years
	};
};

const mapDispatchToProps = {
	fetchTeams: actions.leagueDraft.showDraftOrder,
	fetchPastYearPlayers: actions.fetchPastYearPlayers
};

export const ManageKeepers = compose(
	withRouter,
	withDraftCore,
	connect(
		mapStateToProps,
		mapDispatchToProps,
	)
)(ManageKeepersComponent);

export default ManageKeepers;