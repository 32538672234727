// @flow
import * as React from "react";
import styled, { css } from "styled-components";
import { connect } from "react-redux";
import _ from "lodash";
import * as actions from "../../modules/actions";
import colors from "../../assets/css/colors";
import { below } from "../../assets/css/media";
import { isMobile } from "../../utils";
import { FavButton, PlayerInfoShadow, StatusButton } from "../";
import Star from "../Icons/Star";
import PlayerStatusIcon from "../PlayerStatusIcon";
import type { TPlayer } from "../../modules/types";
import PlayerCost from "../PlayerCost";
import { CommandButton as CmdBtnUnstyled } from "../MyTeam/ListView/commandButton";
import Captain from "../Icons/Captain";
import ViceCaptain from "../Icons/ViceCaptain";
import Emergency from "../Icons/Emergency";
import { Exist } from "../Exist";
import { isAllTrue, isAnyTrue } from "../../helpers";
import { IS_OFFSEASON } from "../../modules/constants";
import PlayerAvatar from "./playerAvatar";



const PlayerDetailsInfo = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: ${({ reverse }) => reverse ? "flex-end;" : "flex-start"};
	position: relative;
	z-index: 2;
	margin-top: ${({ is_compressed }) => is_compressed ? "3px" : "0px"};
	.player-positions{
		text-transform: capitalize;
		font-family: SourceSansPro;
		font-weight: 400;
		font-size: 12px
	}
	${({ is_compressed, reverse })=> is_compressed && css`
		flex-direction: ${ reverse ? "row-reverse;" : "row"};
		align-items: center;
		justify-content: flex-start;
		.player-positions{
			margin-top: 0px;
		}
		> p {
			margin-bottom: 0;
			margin-${reverse ? "right" : "left"}: 4px;
		}
	`};
	${({is_compressed}) => is_compressed && `
		.player-names{
			margin-bottom: 0px;
			margin-left: 2px;
		}
		.player-positions{
			margin-bottom: 3px;
		}
	`}

`;

export const PlayerName = styled.button`
	color: ${colors.primary.primary};
	font-family: TitilliumUpright;
	font-weight: 700;
	font-size: ${({ is_compressed }) => is_compressed ? "12px;" : "16px;"}
	font-size: ${({ edit_teams_view }) => edit_teams_view ? "12px" : ""};
	padding: 0;
	background: none;
	border: none;
	text-align: left;
	margin-bottom: 5px;
	line-height: 1.3;
	white-space: nowrap;
	max-width: 12em;
	text-overflow: ellipsis;
	overflow: hidden;
	
	${({ no_hover }) => no_hover ? css`
		cursor: default;
	` : css`
		:hover {
			text-decoration: underline;
		}
	`}
	${IS_OFFSEASON && `padding-top: 5px`}

	span + span {
		display: none;
	}
	.period {
		display: none;
	}
	${below.desktop`
		span {
			display: none;
		}

		span + span {
			display: inline;
		}
		.period {
			display: inline;
		}
	`};

	${below.tablet`
		max-width: 6em;
	`}
	cursor: pointer !important;
`;

const getButtonColour = ({ is_captain, is_vice_captain, is_emergency }) => {
	if (is_captain) {
		return "#F5A623";
	}

	if (is_vice_captain) {
		return "#198DE1";
	}

	if (is_emergency) {
		return "#B55DAE";
	}

	return "#fff";
};

const showButton = ({ is_captain, is_vice_captain, is_emergency }) => 
	is_captain || is_vice_captain || is_emergency 
		? "flex" 
		: "none";

const CommandButton = styled(CmdBtnUnstyled)`
	background-color: ${getButtonColour};
	display: ${showButton};
	color: #fff;
	cursor: default;

	height: 24px;
	width: 24px;
	margin-top: 4px;
  ${({ above_name, reverse }) => above_name && `
      margin-top: -5px;
      margin-${reverse ? "right" : "left"}: 7px;
  `}
	${below.phone`
		display: ${({ league_matchup, above_name }) => 
		(league_matchup && !above_name) ? "none" : ""};
    
	`}
	${({ league_matchup }) => league_matchup && `
		height: 16px;
		width: 16px;
   
		svg{
			height: 12px;
			width: 12px;
		}
	`}
  ${({ display_absolute, reverse }) => display_absolute && `
    position: absolute;
    ${reverse ? "right" : "left"}: 46px;
    top: 12px;
    margin: 0;
  `}

	${below.tablet`
		${({ is_compressed }) => is_compressed ? css`
			height: 12px;
			width: 12px;
			svg {
				height: 12px;
				width: 12px;
			}
		` : "" }
	`};
`;


export const PlayerDetails = styled.p`
	font-family: TitilliumUpright;
	color: ${colors.primary.primary};
	font-weight: 400;
	font-size: 12px;
	text-align: left;
	line-height: 1;
	text-transform: uppercase;
	${below.tablet`
		font-size: 10px;
		span.show-desktop {
			display: none;
		}

		span.show-mobile {
			display: inline-block;
		}
	`}

	.show-mobile {
		display: none;
	}
`;

export const PlayerStatus = styled.div`
	position: relative;
	display: flex;
	width: ${({ league_matchup }) => league_matchup ? "25px" : "35px"};
	${below.tablet`
		width: ${({ edit_teams_view }) => edit_teams_view ? "18px;" : "30px;"}
	`};

	height: 60px;
	margin-left: ${({ is_compressed, pre_draft }) => is_compressed && pre_draft ? "-5px" : "10px"};
	margin-left: ${({ league_matchup, reverse }) => league_matchup && !reverse ? "-7px" : "1px"};

	${({ edit_teams_view }) => edit_teams_view && `
		margin-left: 0px;
	`}
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	button {
		position: relative;
		z-index: 2;
	}
	${({ is_compressed, reverse })=> is_compressed && `
		height: 30px;
		width: 10px;
		margin-${reverse ? "right" : "left"}: 0px;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	`};
`;

const WrapperPlayerInfo = styled.div`
	display: flex;
	margin: ${({ short_opponent }) => short_opponent ? "0 .5em 0 0" : "0 .5em"};
	margin-left: ${({ pre_draft }) => pre_draft ? "0px" : "0.5em"};
	color: ${colors.primary.darkGrey};
	font-size: 12px;
	margin-bottom: -1px;
	${({ edit_teams_view }) => edit_teams_view  ? "min-width: 320px;" : ""}
	${below.tablet`
		min-width: 160px
	`}
	${below.phone`
		min-width: 140px;
	`}
	${({ short_opponent }) => short_opponent ? "overflow: hidden;" : ""}
	${({ reverse }) => reverse ? "flex-direction: row-reverse;" : ""}
	${PlayerStatus} {
		align-items: ${({ reverse, is_compressed }) => 
		is_compressed
			? "center" 
			: reverse 
				? "flex-end;" 
				: "flex-start"};
	}
`;

const PlayerCostDetails = styled.div`
	margin-top: .5em;
	display: flex;
	align-items: center;
	${({ is_compressed }) => is_compressed && "display: none;"}

	${below.phone`
		> p {
			display: none;

			&:first-child {
				display: flex;
			}
		}
	`}
	${IS_OFFSEASON && `padding-bottom: 5px`}
`;

const getCommandIcon = (cap, vc, emg) => {
	if (cap) {
		return <Captain size="1.1" />;
	} 
	if (vc) {
		return <ViceCaptain size="1.1" />;
	} 
	if (emg) {
		return <Emergency size="1.1"/>;
	} 
	return null;
};

type Props = {
	player: TPlayer,
	short_opponent?: boolean,
	reverse?: boolean,
	is_compressed?: boolean,
	hide_favourite?: boolean,
	postRemoveFromFavourites: typeof actions.postRemoveFromFavourites,
	postAddToFavourites: typeof actions.postAddToFavourites,
	showModalForPlayerId: typeof actions.showModalForPlayerId,
	player_is_favourite: boolean,
	className: string,
	is_captain?: boolean,
	is_vice_captain?: boolean,
	is_emergency?: boolean,
	short_names?: boolean,
	is_classic: boolean,
	no_hover?: boolean,
	status_show?: boolean,
	stat_center?: boolean,
	pre_draft?: boolean,
	edit_teams_view?: boolean,
	league_matchup?: boolean
};

class PlayerInfoComponent extends React.Component<Props> {
	static defaultProps = {
		is_compressed: false,
		className: "player-info",
		// is_classic: true,
	};

	constructor(props) {
		super(props);

		_.bindAll(this, [
			"openPlayerPopUp"
		]);
	}

	get player_pos_key() {
		const { short_opponent, short_names, league_matchup } = this.props;

		

		if(isMobile() || short_opponent || short_names) {
			return "positions_name";
		}
		if(league_matchup){
			return "positions_full_name";
		}

		return "positions_full_name";
	}

	openPlayerPopUp() {
		const { player, showModalForPlayerId } = this.props;
		showModalForPlayerId(player.id);
	}
	
	renderPriceChanges() {
		const { player, is_compressed, is_classic } = this.props;
		let round_diff = _.get(player, "stats.cost_round_diff", 0);
		let season_diff = _.get(player, "stats.cost_season_diff", 0);

		if (!is_classic) {
			return null;
		}

		const has_one = Boolean(
			(round_diff || season_diff)
			&& (round_diff !== "-" || season_diff !== "-")
		);

		return <PlayerCostDetails 
			className="player-cost" 
			is_compressed={is_compressed}
		>
			<PlayerCost cost={player.cost}/>
			{has_one && <React.Fragment>
				<PlayerCost
					cost={round_diff}
					is_diff
				/>
				<PlayerCost
					cost={season_diff}
					is_diff
				/>
			</React.Fragment>}
		</PlayerCostDetails>;
	}

	render(){
		const {
			player,
			short_opponent,
			reverse,
			is_compressed,
			player_is_favourite,
			postAddToFavourites,
			postRemoveFromFavourites,
			is_captain,
			is_vice_captain,
			is_emergency,
			className,
			no_hover,
			status_show,
			stat_center,
			pre_draft,
			edit_teams_view,
			league_matchup
		} = this.props;

		if(_.isEmpty(player)) {
			return null;
		}
		const cmd_btn_icon = getCommandIcon(is_captain, is_vice_captain, is_emergency);

		return <WrapperPlayerInfo
			className={className}
			short_opponent={short_opponent}
			reverse={reverse}
			is_compressed={is_compressed}
			stat_center={stat_center}
			pre_draft={pre_draft}
			edit_teams_view={edit_teams_view}
			display_absolute={isMobile() && !is_compressed}
		>
			<PlayerAvatar
				reverse={reverse}
				is_compressed={is_compressed}
				player={player}
				openPlayerPopup={this.openPlayerPopUp}
				edit_teams_view={edit_teams_view}
				league_matchup={league_matchup}
			/>
			<Exist when={Boolean(status_show)}>
				<StatusButton
					status={player.status}
					is_bye={player.is_bye}
					is_compressed={is_compressed}
					status_show={status_show}
					edit_teams_view={edit_teams_view}
					className="player-status-show"
				>
					<PlayerStatusIcon status={player.status} is_bye={player.is_bye} />
				</StatusButton>
			</Exist>
			
			<PlayerStatus 
				is_compressed={is_compressed} 
				pre_draft={pre_draft} 
				edit_teams_view={edit_teams_view}
				league_matchup={league_matchup}
				reverse={reverse}
				className="player-details-status">
				<Exist when={!is_compressed}>
					<PlayerInfoShadow reverse={reverse}/>
				</Exist>
				
				<StatusButton
					status={player.status}
					is_bye={player.is_bye}
					is_compressed={isAnyTrue([Boolean(is_compressed), Boolean(edit_teams_view)]) }
					status_show={status_show}
					edit_teams_view={edit_teams_view}
					league_matchup={league_matchup}
				>
					<PlayerStatusIcon status={player.status} is_bye={player.is_bye} />
				</StatusButton>
				<Exist when={!is_compressed}>
					<CommandButton
						is_captain={is_captain}
						is_vice_captain={is_vice_captain}
						is_emergency={is_emergency}
						is_compressed={is_compressed}
						reverse={reverse}
						league_matchup={league_matchup}
					>
						{cmd_btn_icon}
					</CommandButton>
				</Exist>
				<Exist when={isAllTrue([!is_compressed, !edit_teams_view]) }>
					<FavButton
						active={player_is_favourite}
						className="favourite-button"
						onClick={() => {
							player_is_favourite?
								postRemoveFromFavourites(player.id):
								postAddToFavourites(player.id);
						}
						}>
						<Star />
					</FavButton>
				</Exist>
			
			</PlayerStatus>
			<PlayerDetailsInfo
				is_compressed={is_compressed}
				className="player-details-info"
				reverse={reverse}
				edit_teams_view={edit_teams_view}
			>
				<PlayerName
					status={player.status}
					onClick={this.openPlayerPopUp}
					no_hover={no_hover}
					className="player-names"
					is_compressed={is_compressed}
					edit_teams_view={edit_teams_view}
				>
					<>
						{player.first_name[0]}<span>{player.first_name.substring(1)} </span>
						<span className="period">.</span>
						{player.last_name}
					</>
					
				</PlayerName>
				<PlayerDetails className="player-positions" league_matchup={league_matchup}>
					<>
						<span className="show-desktop">
							{_.get(player, this.player_pos_key, []).join(" / ")}
						</span>
						<span className="show-mobile">
							{_.get(player,"positions_name", []).join(" / ")}
						</span>
					</>
        
					
				</PlayerDetails>
				{((is_compressed || isMobile()) && league_matchup) &&
          <CommandButton is_emergency={is_emergency}
          	is_captain={is_captain}
          	is_vice_captain={is_vice_captain}
          	is_compressed={is_compressed}
          	league_matchup={league_matchup}
          	reverse={reverse}
          	above_name
          	display_absolute={isMobile() && !is_compressed}
          >
          	{cmd_btn_icon}
          </CommandButton>
				}
				{ this.renderPriceChanges() }
			</PlayerDetailsInfo>
		</WrapperPlayerInfo>;
	}
}

const mapStateToProps = (state, props) => ({
	favs: state.players.favourites,
	player_is_favourite: state.players.favourites.includes(props.player && props.player.id)
});

const mapDispatchToProps = {
	postRemoveFromFavourites: actions.postRemoveFromFavourites,
	postAddToFavourites: actions.postAddToFavourites,
	showModalForPlayerId: actions.showModalForPlayerId,
};

export const PlayerInfo = connect(mapStateToProps, mapDispatchToProps)(PlayerInfoComponent);

export default PlayerInfo;