// @flow
import * as React from "react";
import styled from "styled-components";
import type { TPLeague } from "../../../../modules/types";

const RemoveButton = styled.button`
  background-color: #D92425;
  width: 140px;
  height: 40px;
  padding: 10px 16px;
  color: #fff;
  font-family: SourceSansPro;
  font-weight: 500;
  font-size: 12px;
  border: none;
  border-radius: 2px;
  margin-top: 20px;
  cursor: pointer;
`;
const RemoveText = styled.p`
  color: #1D4073;
  font-size: 14px;
  font-family: SourceSansPro;
  font-weight: 400;
  
`;
type RemoveProps = {
  removeLeague?: Function,
  league: TPLeague,
}


class RemoveComponent extends React.Component<RemoveProps> {
  
	get status_and_time_until_draft() {
		const
			{
				league,
			} = this.props;
		return (league.status === "scheduled"
			&& (Date.parse(league.draft_start) - Date.parse(Date())) > 1800000);
	}
	render() {
		const status = this.status_and_time_until_draft;
		const { removeLeague } = this.props;
		const { league } = this.props;
		return (
			<div>
				<RemoveText>
          Are you sure? This action is permanent
				</RemoveText>
				{ (status || league.draft_status !== "complete") ? (
					<RemoveButton onClick={removeLeague}>
						Yes, remove league
					</RemoveButton>
				) : null}
			</div>
		);
	}
}


export default RemoveComponent;