// @flow
import * as React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Slider from "react-slick";
import smoothscroll from "smoothscroll-polyfill";
import _ from "lodash";
import ArrowRight from "../Icons/ArrowRight";
import ArrowLeft from "../Icons/ArrowLeft";
import { Rotate, RotatedLabel } from "..";
import type { TRootStore } from "../../modules/types";
import * as selectors from "../../modules/selectors";
import type { TUser } from "../../modules/types/user";
import type { TPLeague, TPlayersById } from "../../modules/types";
import DraftTeamListOrderItem from "../../pages/draft/league/liveDraft/draftTeamListOrderItem";
import { isMobile } from "../../utils";
import { below } from "../../assets/css/media";
import { getIsRegenerated } from "../../helpers";
import colors from "../../assets/css/colors";
import { mergeArrays } from "./../../helpers/league";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


smoothscroll.polyfill();

const shadows = ({ is_empty }) => is_empty ? "" : `
	content: '';
	display: block;
	position: absolute;
	top: 0;
	z-index: 2;
	width: 90px;
	background: radial-gradient(circle closest-side, rgba(0, 0, 0, .4) 0%, transparent 100%);
	height: 100%;
	background-position: left center;
	background-repeat: no-repeat;
	background-size: auto 100%;
`;

const DraftTeamListOrderIndicatorWrapper = styled.div`
	width: 100%;
	height: 100%;
	position: relative;
	overflow: hidden;
	display: flex;
	flex: 1;
	justify-content: space-between;

	& > div {
		background-color: ${({ is_empty }) => is_empty ? "transparent" : "#FFF"};
		overflow: hidden;
		position: relative;
		height: 100%;
		max-width: 100%;
		width: 100%;

		&:before {
		    ${shadows};
		    left: -65px;
		}

		&:after {
		    ${shadows};
		    right: -65px;
		}
	}

	.control-arrow {
		color: ${({ is_empty, is_my_turn }) => is_empty || is_my_turn ? "#FFF" : "#1D4073"};
	}
	.slick-list{
		top: 15px;
	}

	.slick-prev{
		height: 90px;
		position: absolute;
		left: 0;
		top: 45px;
		z-index: 20;
		opacity: 0.75;
		background-color: ${({ is_my_turn }) => is_my_turn ? "#1D4073" : "#fff"};
		&:before{
			content: none;
		}
	}
	.slick-next{
		height: 90px;
		position: absolute;
		right: 0;
		top: 45px;
		z-index: 20;
		opacity: 0.75;
		background-color: ${({ is_my_turn }) => is_my_turn ? "#1D4073" : "#fff"};
		&:before{
			content: none;
		}
	}
	
	ul {
		display: flex;
		flex-wrap: nowrap;
		position: relative;
		margin: 0;
		list-style: none;
		overflow: hidden;
		height: 100%;
		background: #ffffff;

		li {
		  position: relative;
		  display: inline-flex;
		  align-items: center;
		  padding: 0;
		  text-align: center;
		  margin: 0 4px;
		  transition: all 0.5s;


		  

		  &:first-child {
		  	margin-left: 0;
		  }

		  &:last-child {
		  	margin-right: 0;
		  }
		}
	}
	li.active{
		transform: scale(1.1);
		z-index: 2;
		margin: 0 10px;
		>div{
			border-color: ${colors.player.live};
			background: #edfadf;
			color: ${colors.player.live};
		}  
		div, .turn-number{
			color: ${colors.primary.primary};
		}
	}
	${below.phone`
		.pick-item{
			margin: 0 5px 10px 5px;
		}
	`}

`;

const ArrowButton = styled.button`
	background: transparent;
	height: 100%;
	width: 30px;
	color: #1D4073;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 0;
	outline: 0;

	& > svg {
		width: 14px;
		height: 15px;
	}

	&:hover{
		opacity: 0.75;
		transition: all 0.2s ease;
	}
`;

const RoundNumberDevider = styled(RotatedLabel)`
	background: #ffffff;
	height: 100%;
	position: relative;
	display: block;
	font-family: SourceSansPro;
	font-weight: 700;
	font-size: 12px;
	text-transform: uppercase;
	${Rotate} {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%) rotateZ(-90deg);
	}

	div {
		color: #1D4073;
		font-size: 12px;
		font-weight: bold;
		white-space: nowrap;
		font-family: SourceSansPro;
	}
	${below.phone`
		top: 15px;
	`}
`;

const RoundDeviderLi = styled.li`
	height: 100%;
	width: 30px;
`;

type ArrowProps = {
	className: string,
	style?: Object,
	onClick?: Function
}

const PreviousArrow = (props: ArrowProps) => {
	const { className, style, onClick } = props;
	return <ArrowButton 
		className={className + " control-arrow"} 
		onClick={onClick}
		style={{ ...style }}
	>
		<ArrowLeft color="#1d4072" />
	</ArrowButton>;
	
};

const NextArrow = (props: ArrowProps) => {
	const { className, style, onClick } = props;
	return <ArrowButton className={className + " control-arrow"} 
		onClick={onClick}
		style={{ ...style }}>
		<ArrowRight color="#1d4072" />
	</ArrowButton>;
};

type Props = {
	currentPick?: Object,
	draft_order: Array<Object>,
	commissioner_picks: number[],
	user: TUser,
	is_my_turn: boolean,
	league: TPLeague,
	preloader: boolean,
	players_by_id: TPlayersById,
	drafted_picks: Object[],
	teams_by_id: Object,
	pre_draft?: boolean
};

class DraftTeamListOrderIndicatorContainer extends React.Component<Props> {
	
	constructor(props){
		super(props);
		this.list = React.createRef();
	}
	
	componentDidMount(){
		this.scrollLeft = this.onClickScrollControl.bind(this, -1);
		this.scrollRight = this.onClickScrollControl.bind(this, 1);
		this.list = React.createRef();
	}

	componentDidUpdate() {
		const { currentPick, draft_order } = this.props;
		const new_pick = _.get(currentPick, "pick", this.last_pick);
		this.last_pick = Math.max(1, Math.min(new_pick, new_pick - 1));

		if(!this.total_picks) {
			this.total_picks = _.size(draft_order.filter(_.negate(_.property("is_label"))));
		}

		this.updateSliderPosition();
	}

	last_pick = 1;
	visible_elements_size = 0;
	list: { current: null | HTMLUListElement };
	total_picks = 0;
	scrollLeft: Function;
	scrollRight: Function;
	
	updateSliderPosition(){
		const element = document.getElementById(`pick-item-${this.last_pick}`);
		const list = this.list.current;

		if(element && list) {
			const offset =  isMobile() ? element.offsetLeft - 14 : element.offsetLeft - 34;

			try {
				list.scroll({
					top: 0,
					left: offset,
					behavior: "smooth",
				});
			}
			catch(err) {
				list.scrollLeft = offset;
			}
		}
	}

	onClickScrollControl(direction){
		if(!this.visible_elements_size) {
			const elem = document && document.getElementById("pick-item-1");
			const list = this.list.current;

			if(elem && list) {
			 	const container_width = list.offsetWidth || list.clientWidth;
				const elem_width = elem.offsetWidth || elem.clientWidth;
				this.visible_elements_size = parseInt(container_width / elem_width, 10);
			}
		}

		this.last_pick = Math.max(1, Math.min(
			this.last_pick + direction * (this.visible_elements_size - 1),
			this.total_picks
		));

		this.updateSliderPosition();
	}

	get list_items() {
		const {
			currentPick,
			draft_order,
			user,
			is_my_turn,
			players_by_id,
			league,
			drafted_picks,
			pre_draft,
			teams_by_id,
			commissioner_picks
		} = this.props;
		const curr_pick = _.get(currentPick, "pick");
		const isRegenerated = getIsRegenerated(league.regenerated_from,league.uf_regenerated_from);
		const isLeagueKeeperRegen = Boolean(league.keeper) && isRegenerated;
		const keeperDraftPicks = _.get(league, "keeper_draft_picks", {});
		const draftedPicksToUse = pre_draft ? [] : _.filter(
			drafted_picks, pick => _.get(pick, "picked_player_id", 0) !== 0
		);

	
		
		return draft_order.map(({ pick, ...item }, index) => {
			if(item.is_label) {
				return (
					<RoundDeviderLi key={index}>
						<RoundNumberDevider
							text={`Round ${item.round_id}`}
							rotate="-90"
							color="#777777"
							background="#E5EBEA"
						/>
					</RoundDeviderLi>
				);
			}
			const keeperAccessor = _.get(keeperDraftPicks, `${pick}`);
			const keeperPlayer = isLeagueKeeperRegen ? _.get(players_by_id, keeperAccessor) : null;
			const selectedPlayerID = _.get(draftedPicksToUse, `[${pick - 1}].picked_player_id`, 0);
			const draftedPlayerAtPick = _.get(players_by_id, `[${selectedPlayerID}]`, {});
			const pickHistoryTeam  = commissioner_picks ? commissioner_picks[pick - 1] : 0;
			const teamObj = _.get(teams_by_id, pickHistoryTeam);
			if(teamObj){
				// Override for users viewing commissioner draft
				item.name = teamObj.name;
				item.user_id = teamObj.user_id;
			}

			
			return (
				<li
					className={`pick-item ${(_.eq(pick, curr_pick)) ? "active" : ""}`}
					id={`pick-item-${pick}`}
					key={index}
				>
					<DraftTeamListOrderItem
						league={this.props.league}
						team_name={item.name}
						first_name={item.firstname}
						user_id={item.user_id}
						pick_number={pick}
						this_is_you={_.eq(item.user_id, user.id)}
						is_your_pick={_.eq(pick, curr_pick) && is_my_turn}
						current_pick_number={curr_pick}
						is_past_pick={pick < curr_pick}
						is_your_team={user.id === item.user_id}
						keeperPlayer={keeperPlayer}
						draftedPlayer={draftedPlayerAtPick}
					/>
				</li>
			);
		});
	}

	get previous_arrow() {
		return <ArrowButton className="control-arrow" onClick={this.scrollLeft}>
			<ArrowLeft color="#1d4072" />
		</ArrowButton>;
	}
	get next_arrow() {
		return <ArrowButton className="control-arrow" onClick={this.scrollRight}>
			<ArrowRight color="#1d4072" />
		</ArrowButton>;
	}

	render() {
		const { currentPick, is_my_turn, preloader } = this.props;
	
		const currentRound = _.get(currentPick, "round", 1);

		const new_pick = _.get(currentPick, "pick", this.last_pick) + currentRound;
		const focusPick = Math.max(1, new_pick);


		const settings = {
			slidesToShow: 3,
			slidesToScroll: 2,
			infinite: true,
			speed: 500,
			initialSlide: focusPick,
			nextArrow: <NextArrow className="" />,
			prevArrow: <PreviousArrow className="" />,
			variableWidth: true,
		};

		

		if(preloader){
			return null;
		}
		if(isMobile()){
			return(
				<DraftTeamListOrderIndicatorWrapper is_empty={!currentPick} is_my_turn={is_my_turn}>
					<div>
						<Slider {...settings}>
							{this.list_items}
						</Slider>
					</div>
					
				</DraftTeamListOrderIndicatorWrapper>
			);
		
		}
		return (
			<DraftTeamListOrderIndicatorWrapper is_empty={!currentPick} is_my_turn={is_my_turn}>
				{this.previous_arrow}
				<div>
					<ul ref={this.list}>
						{ this.list_items }
					</ul>
				</div>
				{this.next_arrow}
			</DraftTeamListOrderIndicatorWrapper>
		);
	}
}

const mapStateToProps = (state: TRootStore, props) => {
	const league = selectors.leagueDraft.getLeague(state, props);


	const numTeams = league.num_teams || 0;
	const teams_by_id = selectors.getTeamsById(state);
	const players_by_id = selectors.players.getExtendedPlayersById(state);

	const draft_order = league.custom_full_draft_order.length ? 
		mergeArrays(
			league.custom_full_draft_order
				.map((teamID, teamIDIndex) => {
					const roundID = (teamIDIndex/numTeams) + 1;
					const teamData = {
						...teams_by_id[teamID],
						missed_turn: 0,
						pick: teamIDIndex + 1,
						round_id: roundID
					};
					return teamIDIndex % numTeams === 0 ? [
						{ round_id: roundID, is_label: true },
						teamData
					]:teamData;
				})
		):selectors.getDraftOrder(state, props);
	const preloader = 
		_.isEmpty(league) 
		|| numTeams === 0 
		|| _.isEmpty(teams_by_id);

	return {
		league: selectors.leagueDraft.getLeague(state, props),
		draft_order,
		user: selectors.getUser(state),
		preloader,
		teams_by_id,
		players_by_id
	};
};

export const DraftTeamListOrderIndicator = withRouter(
	connect(mapStateToProps)(DraftTeamListOrderIndicatorContainer)
);

export default DraftTeamListOrderIndicator;