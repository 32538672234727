// @flow
import { createReducer } from "redux-act";
import * as actions from "../../actions";
import { AFLIdModalStatus } from "../../types/store/aflIdModalReducer";

const lsAFLiDInfo = localStorage.getItem("aflid_info") || "0";

const initialState = {
	currentAFLiDModal: Number(lsAFLiDInfo) > 0 ? AFLIdModalStatus.Off:AFLIdModalStatus.Info
};

export const aflIdModal = createReducer({}, initialState);

aflIdModal.on(actions.setAflIdModalStatus, (
	state, currentAFLiDModal
) => ({ ...state, currentAFLiDModal }));

export default aflIdModal;