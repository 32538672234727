// @flow
import React from "react";
import styled from "styled-components";
import * as _ from "lodash";
import Cookies from "js-cookie";
import colors from "../../assets/css/colors";
import { default_transition } from "../../assets/css/vars";
import Close from "../Icons/Close";
import { below } from "../../assets/css/media";

const hashCode = (value: string) => {
	var hash = 0, i, chr;
	if (value.length === 0) return hash;
	for (i = 0; i < value.length; i++) {
		chr = value.charCodeAt(i);
		hash = ((hash << 5) - hash) + chr;
		hash |= 0; // Convert to 32bit integer
	}
	return hash;
};

const CloseContainer = styled.div`
	border-radius: 1em;
	height: 2em;
	width: 2em;

	${default_transition};

	${({ show }) => !show && "display: none;"}
	
	svg {
		${default_transition};
	}

	&:hover {
		background-color: #fff;
		> svg {
			fill: ${colors.primary.accent};
		}
	}
`;

const NoteContainer = styled.div`
	${default_transition}

	width: ${({ show }) => show ? "1024px" : "0"};
	max-width: ${({ show }) => show ? "80%" : "0px"};
	min-height: ${({ show }) => show ? "80px" : "0"};
	padding: ${({ show }) => show ? "28px 30px 26px" : "0"};
	opacity: ${({ show }) => show ? "1" : "0"};
	height: ${({ show }) => show ? "auto" : "0px;"};
	margin: 0 auto;

	box-sizing: border-box;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	box-shadow: 0 0 20px 0 rgba(127,138,144,0.5);
	background-color: white;
	border-radius: 5px;
	i {
		font-style: italic;
	}

	b {
		font-weight: 600;
	}

	
`;

const NoteWrapper = styled.div`
	${default_transition}
	position: fixed;
	bottom: ${({ show }) => show ? "20px" : "0px"};
	left: 0;
	width: 100%;
	height: ${({ show }) => show ? "auto" : "0px"};
	z-index: 100;
`;

const NoteText = styled.div`
	${default_transition}
	font-size: ${({ show }) => show ? "18px" : "0px"};
	color: ${colors.primary.primary};
	opacity: ${({ show }) => show ? "1" : "0"};

	${below.tablet`
		font-size: 14px;
	`}
`;

type Props = {
	note_text: string,
	should_show: boolean,
	is_paragraph: boolean,
	user_id: any
}

type State = {
	closed: boolean,
}

export class GlobalNote extends React.PureComponent<Props, State> {
	static defaultProps = {
		is_paragraph: false,
		should_show: true,
		user_id: "default"
	};
	constructor(props: Props) {
		super(props);

		_.bindAll(this, [
			"closeNote"
		]);
	}
	state = {
		closed: true,
	};
	componentDidMount() {
		const closed = Cookies.get(this.key) === "closed"; 

		this.setState({
			closed
		});
	}
	get key() {
		const { user_id, note_text } = this.props;
		return `${hashCode(note_text)}-${user_id}`;
	}
	get shouldShow() {
		const { should_show } = this.props;
		const { closed } = this.state;

		return should_show && !closed;
	}
	closeNote() {
		this.setState({
			closed: true
		});

		Cookies.set(this.key, "closed", { expires: 3 });
	}
	render() {
		const text = this.props.note_text || "";
		const shouldShow = this.shouldShow;
		return (
			<NoteWrapper show={shouldShow} className="playwright-mask-hidden">
				<NoteContainer show={shouldShow}>
					<NoteText show={shouldShow} dangerouslySetInnerHTML={{
						__html: text
					}} />
					<CloseContainer onClick={this.closeNote} show={this.shouldShow}>
						<Close cursor="pointer" size='2' color={colors.primary.primary} />
					</CloseContainer>
				</NoteContainer>
			</NoteWrapper>
		);
	}
}

export default GlobalNote;