// @flow
import styled from "styled-components";
import colors from "../../assets/css/colors";

export const IconCircled = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${({ background }) => background || colors.white};
	border-radius: 50%;
	width: ${({ size }) => size || "20px"};
	height: ${({ size }) => size || "20px"};
`;

export default IconCircled;