// @flow
import _ from "lodash";
import { createSelector } from "reselect";
import * as roundsSelector from "../rounds";
import type { TClassicTeam, TRootStore, TRound, TPlayersById, TTeam } from "../../types";
import { convertLineupForBE } from "../../../modules/sagas/teamsClassic";
import { getPositionOfPlayerinLineup } from "../../../components/Trades/helpers";
import { isAllTrue, isEditTradePage } from "../../../helpers";

export const setUtilityPosition = (team: TTeam, trade_in_ids?: number[]) => {
	if(!_.get(team, "lineup.bench", null)) {
		return {};
	}
	let { lineup } = team;
	// remove any players on trade in edit advance trades page
	const SKIP = true;
	const isEditPage = isEditTradePage() && !SKIP;
	if(isAllTrue([isEditPage, Boolean(trade_in_ids)])){
		const firstPlayer = _.get(trade_in_ids, '[0]');
		const firstPosition = getPositionOfPlayerinLineup(firstPlayer, lineup);
		const secondPlayer = _.get(trade_in_ids, '[1]');
		const secondPosition = getPositionOfPlayerinLineup(secondPlayer, lineup);
		if(firstPosition){
			lineup = {
				...lineup,
				[firstPosition]: lineup[firstPosition].filter(playerId => playerId !== firstPlayer)
			};
		}
		if(secondPosition){
			lineup = {
				...lineup,
				[secondPosition]: 
					lineup[secondPosition].filter(playerId => playerId !== secondPlayer)
			};
		}
		team.lineup = lineup;
	}

	if (_.get(team, "lineup.bench.utility", null)) {
		return team;
	}


	const { bench } = lineup; 
	// Set default utility
	bench.utility = {
		id: 0,
		position: "3",
	};

	
	_.filter(bench, (items, key) => {
		const if_utility_is_rook = key === "3" && items.length === 2;
		const if_utility_is_other_position = parseInt(key, 0) <= 4 && items.length > 2;

		if (if_utility_is_rook || if_utility_is_other_position) {
			const position = key;

			bench.utility = {
				id: items.pop(),
				position: position,
			};
		}
	});

	return team;
};

export const getPlayersOnTrade = (
	state: TRootStore
) => _.get(state, "teamsClassic.trades.players_on_trade", []);

export const getMyClassicTeam = (
	state: TRootStore,
) => {
	const players_on_trade = _.get(state, "teamsClassic.trades.players_on_trade", []);
	const in_players_ids = _.chain(players_on_trade)
		.map(trade => trade.in || 0)
		.filter(_.identity)
		.uniq()
		.value();
	const team = _.get(state, "teamsClassic.show_my.team", {});
	return setUtilityPosition(team, in_players_ids);
};

export const isMyClassicTeamComplete = createSelector(
	getMyClassicTeam,
	({ complete, valid }) => Boolean(complete) && Boolean(valid)
);


export const getMyClassicTeamPlayersIds = createSelector(
	getMyClassicTeam,
	getPlayersOnTrade,
	(team: TClassicTeam, players_on_trade) => {
		if (_.isEmpty(team)) {
			return [];
		}
		const { lineup: current_lineup } = team;
		const { lineup } = convertLineupForBE(current_lineup);
		const { bench } = lineup;
		const bench_ids = _.chain(bench).omit(["emergency"]).values().flatten().value();
		const player_ids = _.chain(lineup)
			.omit(["captain", "vice_captain", "bench"])
			.values()
			.flatten()
			.concat(bench_ids)
			.value();
		const isEditPage = isEditTradePage();
		if(isEditPage){
			const in_players_ids = _.chain(players_on_trade)
				.map(trade => trade.in || 0)
				.filter(_.identity)
				.uniq()
				.value();
			return player_ids.filter(playerID => !in_players_ids.includes(playerID));
		}
		
		return player_ids;
	}
);
export const getTrades = (
	state: TRootStore
) => _.get(state, "teamsClassic.trades.trades", []);

export const getTradesError = (
	state: TRootStore
) => _.get(state, "teamsClassic.trades.error", []);
export const getInitialLineup = (
	state: TRootStore
) => _.get(state, "teamsClassic.trades.lineup", []);

export const getUserTrades = createSelector(
	getMyClassicTeam,
	getTrades,
	getPlayersOnTrade,
	(team, trades, players_on_trade) => {

		if (_.isEmpty(team)) {
			return [];
		}
		const { week_trades_limit } = team;
		const done_trades_length = trades.length;

		if(isEditTradePage()){
			return _.range(week_trades_limit).map((v, index) => {
				
				return {
					...players_on_trade[index]
				};
			});
		}
		return _.range(week_trades_limit).map((v, index) => {
			if (trades[index]) {
				return trades[index];
			}
			return {
				...players_on_trade[index - done_trades_length]
			};
		});
	}
);
export const getClassicTradePlayerOutIds = createSelector(
	getPlayersOnTrade,
	players_on_trade => {
		return _.chain(players_on_trade)
			.map(trade => trade.out || 0)
			.filter(_.identity)
			.value();
	}
);
export const getMyClassicTeamValue = createSelector(
	getMyClassicTeam,
	getMyClassicTeamPlayersIds,
	state => state.players.by_id,
	getPlayersOnTrade,
	(team, team_ids: Array<number>, players_by_id: TPlayersById, players_on_trade) => {
		if (_.isEmpty(team_ids) || _.isEmpty(players_by_id)) {
			return 0;
		}
		const sum = (value, player_id) => {
			const cost = _.result(players_by_id, `[${player_id}].cost`, 0);

			return value + cost;
		};
		const in_players_ids = _.chain(players_on_trade)
			.map(trade => trade.in || 0)
			.filter(_.identity)
			.uniq()
			.value();
		let team_value = team_ids.reduce(sum, 0);

		const teamPlayerInTrade = team_ids.reduce((inTrade, teamPlayer) => {
			if(in_players_ids.includes(teamPlayer)){
				return [...inTrade, teamPlayer];
			}
			return [...inTrade];
		}, []);
		if(teamPlayerInTrade.length && isEditTradePage()){
			const playersInTradeCost = teamPlayerInTrade.reduce((sumValue, playerID) => {
				const cost = _.result(players_by_id, `[${playerID}].cost`, 0);
				return sumValue += cost;
			}, 0);
			team_value -= playersInTradeCost;
		}

		return in_players_ids.reduce(sum, team_value);
	}
);
export const getMyClassicTeamRemainingSalary = createSelector(
	getMyClassicTeam,
	getMyClassicTeamValue,
	(team: TClassicTeam, value: number) => {
		const salary = team.salary_cap - value;
		return _.isNaN(salary) ? 0 : salary;
	}
);

export const isMyTeamSaving = (
	state: TRootStore
) => _.get(state, "teamsClassic.show_my.is_team_saving", false);

export const isMyTeamSaved = (
	state: TRootStore
) => _.get(state, "teamsClassic.show_my.is_team_saved", false);

export const isMyTeamStarted = createSelector(
	roundsSelector.getLastRound,
	getMyClassicTeam,
	(round: TRound, team: TClassicTeam) => {
		const { id, status } = round;
		const { start_round, complete, valid } = team;
		const is_team_complete_valid = complete && valid;

		if (status === "scheduled") {
			return Boolean(start_round < id && is_team_complete_valid);
		}
		return Boolean(start_round <= id && is_team_complete_valid);
	}
);

export const isFirstRoundWithPartialLockout = createSelector(
	roundsSelector.getLastRound,
	getMyClassicTeam,
	(round: TRound, team: TClassicTeam) => {
		const { id, status, lockout } = round;
		const { start_round } = team;
		return id === start_round && status === "active" && lockout === "partial";
	}
);

export const getAvailableTeamPositions = createSelector(
	getMyClassicTeam,
	(team: TClassicTeam) => {
		if (_.isEmpty(team)) {
			return [];
		}
		const { lineup } = team;
		const { bench } = lineup;
		const utility = _.get(bench, "utility", null);
		const pure_lineup = _.omit(lineup, ["bench", "captain", "vice_captain"]);
		const pure_bench = _.omit(bench, ["emergency", "utility"]);

		const available_positions = _.chain(pure_lineup)
			.reduce((result, value, key) => {
				return {
					...result,
					[key]: value.concat(pure_bench[key]),
				};
			}, {})
			.map((value, key) => value.includes(0) ? parseInt(key, 10) : false)
			.filter(_.identity)
			.value();

		return utility.id === 0 ? available_positions.concat([1,2,3,4]) : available_positions;
	}
);
// Trade history endpoint gives old/new_player
// Needs to be old/new_player_id
const addIdToKey = (trade, id) => _.reduce(
	trade,
	(collected, value, key) => ({ ...collected, [`${key}_id`]: value }),
	{ id: id + 1 }
);

export const getTradeHistory = createSelector(
	state => state.teamsClassic.trade_history.rounds,
	raw_trades => {
		return _.reduce(raw_trades,
			(collected, trades, round) => ({
				...collected,
				[round]: _.map(trades, addIdToKey)
			}),
			{}
		);
	}
);

export const getSelectedTradeObject = createSelector(
	getMyClassicTeam,
	(state, props) => _.get(props, "player_id" , 0),
	(team, player_id) => {
		const int_player_id = parseInt(player_id,10);

		if (_.isEmpty(team) || !int_player_id) {
			return {};
		}
		const { lineup: current_lineup } = team;
		const { lineup } = convertLineupForBE(current_lineup);
		const { bench, ...lineup_without_bench } = lineup;
		const utility = _.get(bench, "utility");
		const pure_lineup = _.omit(lineup_without_bench, ["captain", "vice_captain"]);
		const pure_bench = _.omit(bench, ["emergency", "utility"]);

		let is_bench = false,
			position_index = -1,
			position = 0;

		const found_id = _.find(pure_lineup, (line, pos) => {
			const index = line.indexOf(int_player_id);
			const found = ~index;
			if (found) {
				position_index = index;
				position = parseInt(pos, 10);
			}
			return found;
		});

		if (!found_id) {
			_.find(pure_bench, (line, pos) => {
				const index = line.indexOf(int_player_id);
				const found = ~index;
				if (found) {
					position_index = index;
					position = parseInt(pos, 10);
					is_bench = true;
				}
				return found;
			});
		}

		if (!position && _.get(utility, "id", 0) === int_player_id) {
			position_index = -1;
			position = parseInt(utility.position, 10);
			is_bench = true;
		}

		return {
			id: parseInt(player_id, 10),
			position_index,
			position,
			is_bench
		};
	}
);

export const isLockout = createSelector(
	isMyTeamStarted,
	(state, round) => round ? round : roundsSelector.getActualRound(state),
	(is_team_started, round) => {
		const { status, lockout } = round;
		const is_active = status === "active";

		if (!is_team_started) {
			return false;
		}

		return is_active && lockout === "full";
	}
);

export const getRankings = createSelector(
	state => state.teamsClassic.rankings.teams,
	(state, props) => props && props.selected_round_id ? props.selected_round_id : 0,
	(rankings, selected_round_id) => {
		return _.map(rankings, team => {
			let avg_points, round_points, total_points, rank_selected_round;

			const scoreflow = _.get(team, "league_scoreflow", {});
			const last_round_id = _.reduce(scoreflow, (current_max, _, round_id) => {
				return Math.max(round_id, current_max);
			}, 0);
			const num_of_rounds = selected_round_id
				? selected_round_id
				: _.size(scoreflow);

			total_points = _.reduce(
				scoreflow,
				(total, round_score, round_id) => {
					if (selected_round_id && round_id > selected_round_id) {
						return total;
					}
					return total + round_score;
				},
				0
			);
			avg_points = total_points / num_of_rounds;
			round_points = selected_round_id
				? _.get(scoreflow, selected_round_id, "- -")
				: _.get(scoreflow, last_round_id, "- -");

			if (selected_round_id  === 0) {
				rank_selected_round = team.rank;
			}
			else {
				rank_selected_round = _.get(
					team,
					["rank_history", selected_round_id ],
					"- -"
				);
			}

			return {
				...team,
				avg_points,
				round_points,
				total_points,
				rank_selected_round
			};
		});
	}
);