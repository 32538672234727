import React from "react";
import styled from "styled-components";
import {
	AdsContainer,
	Ad,
	Footer,
	PageContentWrapper,	PageTitle,
	StandardSidebar,
	TwoColumnLayout,
	Exist,
} from "../../components";
import { SEASON_YEAR } from "../../modules/constants";

// import clubLogos from '../../utils/clubLogos';
const PositionWrap =styled.div`
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    margin-right:10px;
`;
const ArticleWrap = styled.div`
    display:flex;
    margin-bottom: 10px;
`;
const ArticleContent = styled.div`
    min-width:150px;
`;
const ArticleH3 = styled.h3`
    margin-top:20px;
    width: 100%;
`;
const ArticleH4 = styled.h4`
    width: 100%;
`;

const ArticleP = styled.p`
	margin: 10px 0;
`;

const ArticleImg = styled.img`
	max-width: 150px;
`;

const ArticleUL = styled.ul`
    display: flex;
    flex-wrap: wrap;
`;

const UAT_BASE_URL = "https://tds-uat-data.s3-ap-southeast-2.amazonaws.com";
const PLAYER_NEWS_JSON = `${UAT_BASE_URL}/data/afl/news/${SEASON_YEAR}/team-of-the-round.json`;
const IS_UAT = process.env.REACT_APP_GTM_ENVIRONMENT === "uat";

const getPlayerName = player => `${player.first_name} ${player.last_name}`;
const getPlayerPic = player => `${process.env.REACT_APP_PLAYERS_PATH + player}.webp`;
const PlayerInfo = ({name,data}) => <ArticleWrap>
	<div>
		<ArticleImg src={getPlayerPic(data.id)} alt={name} />
	</div>
	<ArticleContent>
		<ArticleH4>{name}</ArticleH4>
		<ArticleP><strong>Score: </strong>{data.score}</ArticleP>
		<ArticleP><strong>Kicks: </strong>{data.K}</ArticleP>
		<ArticleP><strong>Handballs: </strong>{data.H}</ArticleP>
		<ArticleP><strong>Marks: </strong>{data.M}</ArticleP>
		<ArticleP><strong>Goals: </strong>{data.G}</ArticleP>
	</ArticleContent>
</ArticleWrap>;

const Ads = () => (
	<AdsContainer>
		<Ad />
	</AdsContainer>
);
export class TeamOfRound extends React.Component {
	
	state = {
		news_data:null,
	};

	componentDidMount() {
		if(IS_UAT) {
			fetch(PLAYER_NEWS_JSON)
				.then(res => res.json())
				.then(
					result => {
				  this.setState({
							news_data: result
				  });
					},
					error => {
				  this.setState({
							news_data: null,
				  });
					}
			  )
				.catch(function(err) {
					console.log("Fetch Error :-S", err);
				});
		}
	}
	render() {
		const { news_data } = this.state;
		const playerOfTheRoundData = news_data !== null ? news_data.highest_rank[0]:null;
		const playerOfTheRound = news_data !== null ? getPlayerName(playerOfTheRoundData):"";
		const defenders1 = news_data !== null ? [news_data.d_1,news_data.d_2,news_data.d_3]:[];
		const defenders2 = news_data !== null ? [news_data.d_4,news_data.d_5,news_data.d_6]:[];
		const articleData = news_data !== null ? news_data.teams_with_3_or_more_str.join(","):"";
		return (
			<React.Fragment>
				<Ads />
				<PageContentWrapper>
					<PageTitle>Team of the Round</PageTitle>
					<TwoColumnLayout>
						<Exist when={IS_UAT}>
							<div>
								{news_data !== null ? (
									<>
										<ArticleH3>Highest Rank</ArticleH3>
										<PlayerInfo 
											name={playerOfTheRound} 
											data={playerOfTheRoundData} 
										/>
										<ArticleH3>Teams with most Players</ArticleH3>
										<ArticleWrap>
											<ArticleP>{articleData}</ArticleP>
										</ArticleWrap>
										<ArticleH3>Defenders</ArticleH3>
										<PositionWrap>
											{defenders1.map(item => (
												<PlayerInfo 
													name={getPlayerName(item)} 
													data={item} 
												/>
											))}
										</PositionWrap>
										<PositionWrap>
											{defenders2.map(item => (
												<PlayerInfo 
													name={getPlayerName(item)} 
													data={item} 
												/>
											))}
										</PositionWrap>
										<ArticleH3>Midfielders</ArticleH3>
										<PositionWrap>
											<PlayerInfo
												name={getPlayerName(news_data.m_1)}
												data={news_data.m_1}
											/>
											<PlayerInfo
												name={getPlayerName(news_data.m_2)}
												data={news_data.m_2}
											/>
											<PlayerInfo
												name={getPlayerName(news_data.m_3)}
												data={news_data.m_3}
											/>
										</PositionWrap>
										<PositionWrap>
											<PlayerInfo
												name={getPlayerName(news_data.m_4)}
												data={news_data.m_4}
											/>
											<PlayerInfo
												name={getPlayerName(news_data.m_5)}
												data={news_data.m_5}
											/>
											<PlayerInfo
												name={getPlayerName(news_data.m_6)}
												data={news_data.m_6}
											/>
										</PositionWrap>
										<PositionWrap>
											<PlayerInfo
												name={getPlayerName(news_data.m_7)}
												data={news_data.m_7}
											/>
											<PlayerInfo
												name={getPlayerName(news_data.m_8)}
												data={news_data.m_8}
											/>
										</PositionWrap>
										<ArticleH3>Rucks</ArticleH3>
										<PositionWrap>
											<PlayerInfo
												name={getPlayerName(news_data.r_1)}
												data={news_data.r_1}
											/>
											<PlayerInfo
												name={getPlayerName(news_data.r_2)}
												data={news_data.r_2}
											/>
										</PositionWrap>
										<ArticleH3>Forwards</ArticleH3>
										<PositionWrap>
											<PlayerInfo
												name={getPlayerName(news_data.f_1)}
												data={news_data.f_1}
											/>
											<PlayerInfo
												name={getPlayerName(news_data.f_2)}
												data={news_data.f_2}
											/>
											<PlayerInfo
												name={getPlayerName(news_data.f_3)}
												data={news_data.f_3}
											/>
										</PositionWrap>
										<PositionWrap>
											<PlayerInfo
												name={getPlayerName(news_data.f_4)}
												data={news_data.f_4}
											/>
											<PlayerInfo
												name={getPlayerName(news_data.f_5)}
												data={news_data.f_5}
											/>
											<PlayerInfo
												name={getPlayerName(news_data.f_6)}
												data={news_data.f_6}
											/>
										</PositionWrap>
									</>
								) : null}
								<ArticleUL></ArticleUL>
							</div>
						</Exist>
						<StandardSidebar />
					</TwoColumnLayout>
				</PageContentWrapper>
				<Ads />
				<Footer />
			</React.Fragment>
		);
	}
}


export default TeamOfRound;