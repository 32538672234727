// @flow
import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { getKeeperDeadlinePassed, isAllTrue } from "../../helpers";
import { getUser } from "../../modules/selectors";
import getLeague from "../../modules/selectors/leaguesDraft/getLeague";
import ButtonPrimary from "../ButtonPrimary";
import LeagueTeamsUnlockModal from "../../pages/draft/league/leagueInvite/LeagueTeamsUnlockModal";
import { showDraftLeague } from "../../modules/actions/leaguesDraft";

const FinaliseButtonContainer = styled.div`
	padding-top: 60px;
`;

type Props = {
    finaliseInviteEmails: Function,
    handleTeamsUnlockClick: Function
}

const FinaliseOrUnlockLeagueComponent = (props: Props) => {
	const {
		finaliseInviteEmails,

	} = props;
	const [showModal, setShowModal] = useState(false);
	const dispatch = useDispatch();
	const user = useSelector(getUser);
	const userID = _.get(user,"id",0);
	const league = useSelector(state => getLeague(state,props));
	const { 
		num_teams,
		size,
		teams_finalised,
		commissioner,
		draft_start,
		id,
		keeper_deadline
	} = league;
	const [checkingCounter, setCheckingCounter] = useState(false);
	const draftStartMoment = moment(draft_start);
	const threeDaysPrior = moment(draft_start).subtract(3, "days");
	const oneHourPrior = moment(draft_start).subtract(1, "hour");
	const isLeagueValidNotFinalised = isAllTrue([
		num_teams === size,
		!teams_finalised
	]);
	const isWithin10MinOneHourPrior = oneHourPrior.diff(moment(), "minutes") <= 10;
	const isWithin10MinThreeDayPrior = threeDaysPrior.diff(moment(), "minutes") <= 10;
	const isNowThreeDaysPrior = draftStartMoment.diff(moment(), "days") === 3;
	const isNowOneHourPrior = draftStartMoment.diff(moment(), "hours") === 1;
	const isNow59MinsPrior = draftStartMoment.diff(moment(), "minutes") === 59;
	
	useEffect(() => {
		const valueToCheck = isWithin10MinOneHourPrior 
			? oneHourPrior.diff(moment(), "minutes") : isWithin10MinThreeDayPrior 
				? threeDaysPrior.diff(moment(), "minutes") : -1;
		const timer = setTimeout(() => {
			if(checkingCounter !== -1 && 
				(
					isNowThreeDaysPrior 
				|| isNowOneHourPrior 
				|| isNow59MinsPrior
				) && !isLeagueValidNotFinalised){
				dispatch(showDraftLeague({id}));
			}
			setCheckingCounter(valueToCheck);
		}, 5000);
		return () => clearTimeout(timer);
		
		
	   }, [
		dispatch, 
		id, 
		isNowOneHourPrior, 
		isNowThreeDaysPrior, 
		isWithin10MinOneHourPrior, 
		isWithin10MinThreeDayPrior, 
		checkingCounter, 
		oneHourPrior, 
		isLeagueValidNotFinalised, 
		threeDaysPrior, 
		isNow59MinsPrior
	]);
	
	if(_.isEmpty(league) || userID !== commissioner){
		return null;
	}

	const openShowModal = () => setShowModal(true);

	const toggleModal = () => setShowModal(!showModal);

	const UnlockModal = () => {
		return showModal && 
			<LeagueTeamsUnlockModal 
				league={league} 
				toggleModal={toggleModal} 
			/>;
	};
	const isKeeperDisabledFromUnlock = isAllTrue([
		league.keeper,
		league.regenerated_from !== 0,
		getKeeperDeadlinePassed(
			draft_start,keeper_deadline
		)
	]);
	
	if(Boolean(teams_finalised)){
		return (
			<React.Fragment>
				<UnlockModal />
				<FinaliseButtonContainer>
					<ButtonPrimary
						onClick={openShowModal} 
						disabled={!teams_finalised || isKeeperDisabledFromUnlock}
					>
                Unlock League
					</ButtonPrimary>
				</FinaliseButtonContainer>
			</React.Fragment>
			
		);
	}

	return (
		<React.Fragment>
			<UnlockModal />
			<FinaliseButtonContainer>
				<ButtonPrimary 
					onClick={finaliseInviteEmails} 
					disabled={teams_finalised || num_teams !== size }
				>
				Finalise Participants
				</ButtonPrimary>
			</FinaliseButtonContainer>

		</React.Fragment>
		
	);
};

export const FinaliseOrUnlockLeague = withRouter(FinaliseOrUnlockLeagueComponent);

export default FinaliseOrUnlockLeague;