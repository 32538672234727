// @flow
import * as React from "react";
import styled from "styled-components";
import type { TPlayersById } from "../../modules/types";
import { below } from "../../assets/css/media";
import {
	TradeInCell,
	TransactionPlayers,
	TransactionPlayersWrapper,
	ButtonAlert,
	SwitchButton,
} from "../";
import ArrowUp from "../Icons/ArrowUp";
import ArrowDown from "../Icons/ArrowDown";
import { TransactionButtonsWrapper } from "./TransactionButtonsWrapper";

type Props = {
	players_by_id: TPlayersById,
	trade: Object,
	removeWaiver: Function,
	onChangeRequestsOrder: Function,
	index: number,
	updateRequestsOrder: Function,
	last: number,
	is_rfa_order_saving: boolean,
	is_user: boolean
}

const RequestOrderWrapper = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 4px;
`;
const CancelTradeButton = styled(ButtonAlert)`
	padding: 10px 8px 8px;
`;


const TransactionButtonsWrapperStyled = styled(TransactionButtonsWrapper)`
	&${CancelTradeButton}{
		margin: none;
	}
	button {
		min-width: 60px;

		&${ButtonAlert} {
			min-width: 150px;
		}
	}
`;
const SwitchButtonStyled = styled(SwitchButton)`
	height: 20px;
	font-size: 9px;
`;

const DraftOrderInput = styled.input`
	display: flex;
	font-size: 16px;
	align-items: center;
	justify-content: center;
	height: 40px;
	width: 85px;
	border: 1px solid #C0C0C0;
	font-weight: bold;
	color: #1D4073;
	background: #ffffff;
	margin-right: .3em;
	text-align: center;
	border-radius: 2px;
	box-sizing: border-box;

	${below.phone`
		width: 40px;
		height: 40px;
		font-size: 14px;
	`};
`;

export const TradeRFA = (
	{
		trade,
		players_by_id,
		removeWaiver,
		onChangeRequestsOrder,
		index,
		updateRequestsOrder,
		last,
		is_rfa_order_saving,
		is_user
	}: Props
) => {
	const {
		id: trade_id, new_player_id, old_player_id, name
	} = trade;
	const rfa_order_controls = (
		<React.Fragment>
			<RequestOrderWrapper>
				<DraftOrderInput
					disabled
					onChange={onChangeRequestsOrder}
					name="draft_order"
					data-id={trade_id}
					value={index + 1 || "-"}
				/>
				<div>
					<SwitchButtonStyled
						up
						disabled={index === 0}
						onClick={() => updateRequestsOrder(
							{ id: trade_id, order: parseInt(index, 10)  - 1 }
						)}
					>
						<ArrowUp size='1.5'/>
					</SwitchButtonStyled>
					<SwitchButtonStyled
						disabled={index === last}
						onClick={() => updateRequestsOrder(
							{ id: trade_id, order: parseInt(index, 10) + 1 }
						)}
					>
						<ArrowDown size='1.5'/>
					</SwitchButtonStyled>
				</div>
				
			</RequestOrderWrapper>
			<CancelTradeButton inverted onClick={() => removeWaiver({ trade_id })}>
				Cancel request
			</CancelTradeButton>
			
		</React.Fragment>
	);

	const transaction_controls = !is_user || is_rfa_order_saving ? null : rfa_order_controls;

	return (
		<React.Fragment>
			<TransactionPlayersWrapper>
				<TransactionPlayers>
					<p>
						{is_user ? "You" : name} have requested:
					</p>
					<TradeInCell 
						player={players_by_id[new_player_id]} 
						is_classic={false}
					/>
				</TransactionPlayers>
				<TransactionButtonsWrapperStyled>
					{transaction_controls}
				</TransactionButtonsWrapperStyled>
				<TransactionPlayers>
					<p>For:</p>
					<TradeInCell 
						player={players_by_id[old_player_id]} 
						is_classic={false}
					/>
				</TransactionPlayers>
			</TransactionPlayersWrapper>
			<TransactionButtonsWrapperStyled show_mobile>
				{transaction_controls}
			</TransactionButtonsWrapperStyled>
		</React.Fragment>
	);
};

export default TradeRFA;