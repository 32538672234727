// @flow
import React from "react";
import SvgIconContainer from "../SvgIconContainer";

export default (
	{
		unit = "em",
		...rest
	}: Object = {}) => (
	<SvgIconContainer viewBox="0 0 16 16" {...rest} unit={unit}>
		<g stroke="none" strokeWidth="1" fillRule="evenodd">
			<path
				d="M4.18620646,12.63479 C3.56974296,13.2512535 3.58047168,
					14.2174334 4.18301058,14.8199723 C4.78975027,15.426712 5.7638889,
					15.4210803 6.36819283,14.8167764 L11.816767,
					9.36820224 C12.1227983,9.06217091 12.2730798,8.66780903 12.2725107,
					8.27358879 C12.2740189,7.87511397 12.1207117,7.48376882 11.8199629,
					7.18301999 L6.36819283,1.73124994 C5.75172934,1.11478645 4.78554948,
					1.12551516 4.18301058,1.72805406 C3.57627089,2.33479375 3.58190253,
					3.30893238 4.18620646,3.91323631 L8.54698332,8.27401318 L4.18620646,12.63479 Z"
				transform="
					translate(8.000000, 8.272522)
					rotate(-270.000000)
					translate(-8.000000, -8.272522)"
			/>
		</g>
	</SvgIconContainer>
);