// @flow
import styled from "styled-components";

export const TableGridHeading = styled.div.attrs({
	role: "row"
})`
	display: grid;
	grid-template-columns: ${({ columns }) => columns || "auto"};
	height: ${({ height }) => height || "auto"};
	align-items: center;

	> div:first-child {
		padding-left: 20px;
	}
`;

export default TableGridHeading;