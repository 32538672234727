// @flow
import * as React from "react";
import { FormScoreSetting } from "../../../../../components/index";
import { orderedScoreSettingValues } from "../../../../../helpers/index";
import type { ScoreSettingsProps } from "../../../../../helpers/index";
import type { TPLeague } from "../../../../../modules/types";
import {
	FormScoreWrap,
	FormScoreTitleWrap,
	FlexFormScoreTitle,
	FormScoreTitleChild,
	FormScoreTitleRightWrapScoring
} from "./../../../../../components/Form/FormScoreSetting/FormScoreSettingStyle";


type Props  ={
  league: TPLeague,
  handleProOpen: Function,
  is_pro_sub: boolean,
  isDeluxe: boolean
}

export const ScoringSettings = (props: Props): any => {
	const is_draft_starting = (props.league.status === "scheduled"
    && (Date.parse(props.league.draft_start) - new Date().getTime()) < 1800000);
	const is_league_started = props.league.status === "playing";
	

	return <FormScoreWrap>
		<FormScoreTitleWrap>
			<FlexFormScoreTitle>
				<FormScoreTitleChild>Use</FormScoreTitleChild>
				<FormScoreTitleChild>Statistic</FormScoreTitleChild>
			</FlexFormScoreTitle>
			<FormScoreTitleRightWrapScoring>Points Value</FormScoreTitleRightWrapScoring>
		</FormScoreTitleWrap>
		{orderedScoreSettingValues.map((stat: ScoreSettingsProps) => (
			<FormScoreSetting
				showBlankOption={true}
				showCheckBox={true}
				handleProOpen={props.handleProOpen}
				key={stat.key}
				label={`${stat.title} (${stat.stat})`}
				names={[`${stat.key}_show`, `${stat.key}_value`]}
				statType={stat.key}
				tooltip={{
					title: stat.title,
					description: stat.description,
				}}
				minValue={stat.minValue ? stat.minValue : -10}
				maxValue={stat.maxValue ? stat.maxValue : 21}
				is_disabled={(stat.isPremium && !props.isDeluxe) || is_draft_starting || is_league_started}
			/>
		))}
	</FormScoreWrap>;
};

export default ScoringSettings;