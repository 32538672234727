// @flow
import * as React from "react";


import {
	TableGrid,
	TableFlexBody
} from "../../../../components";
import JoinLeagueRow from "./JoinLeagueRow";

type Props = {
	leagues_list: Array<Object>,
	handleLeagueJoinPopup: Function,
	handleLeagueSettingsPopup: Function,
	join: Function
}


export const JoinLeagueList = ({
	leagues_list, handleLeagueJoinPopup, handleLeagueSettingsPopup, join
}: Props) => (
	<TableGrid>
		<TableFlexBody>
			{leagues_list.map(league => (
				<JoinLeagueRow key={league.id}
					league={league}
					join={join}
					handleLeagueSettingsPopup={handleLeagueSettingsPopup}
					handleLeagueJoinPopup={handleLeagueJoinPopup}
				/>
			))}
		</TableFlexBody>
	</TableGrid>

);

export default JoinLeagueList;