import styled from "styled-components";
import colors from "../../assets/css/colors";

const lineThicknessDesktop = 2;

const marginDesktop = 10;


const fixtureHeightDesktop = 70;

const titleHeight = 21;

export const BracketWrapper = styled.div`
  	margin: 20px 0;
	overflow-x: scroll;
	overflow-y: hidden;
	&::-webkit-scrollbar {
		display: none;
	}
	-ms-overflow-style: none;
  	scrollbar-width: none; 
`;
export const LinesTopT = styled.div`
	box-sizing: border-box;
	position: absolute;
	top: ${({ reverse }) => reverse ? "119px" : "-98px"};
	display: flex;
	align-items: center;
	transform: translateY(${titleHeight}px);
	margin: ${marginDesktop}px;
	margin-left: 0px;
	height: 70px;
	width: 195px;

	&:before,
		&:after {
			content: "";
			display: block;
			background: #e2e2e2;
			position: absolute;
			top: 65%;
		}

	&:before {
		height: 1px;
		left: -10px;
		transform: translateY(-50%);
		width: 180%;
		${({ reverse }) => reverse && `
				top: 50%;
		`}
	}

	
	&:after {
			left: 55%;
			height: 50%;
			width: 1px;
			transform: translateX(-50%);
			${({ reverse }) => reverse && `
				top: 0px;
			`}
		}
		&:before,
		&:after {
			${({ isTop8, reverse }) => (isTop8 && !reverse) && `
				top: 50%;
			`}
		}

  
`;
export const LinesTopEnd = styled.div`
	box-sizing: border-box;
  position: absolute;
	top: ${({ reverse }) => reverse ? "119px" : "-98px"};
	right: -136px;
	
  display: flex;
  align-items: center;
	&:before,
  &:after {
    content: "";
    display: block;
    background: #e2e2e2;
    position: absolute;
    top: 65%;
		
  }

	margin: ${marginDesktop}px;
	margin-left: 0px;
	height: ${fixtureHeightDesktop}px;
	transform: translateY(${titleHeight}px);

	&:before {
		height: 1px;
		left: -${marginDesktop}px;
		width: calc(50% + ${marginDesktop + lineThicknessDesktop / 2}px);
		transform: translateY(-50%);
		${({ reverse }) => reverse && `
			top: 50%;
		`}
	}

	&:after {
		width: 1px;
		transform: translateX(-50%);
		left: 50%;

    height: 50%;
		${({ reverse }) =>reverse && `
			top: 0px;
		` };
	}


  &:before,
		&:after {
			${({ isTop8, reverse }) => (isTop8 && !reverse) && `
				top: 50%;
			`}
		}
`;

export const TwoIntoOneLine = styled.div`
	position: absolute;
	margin: 10px;
	margin-left: 0px;
	margin-top: -12px;
	left: -15px;
	height: 70px;
	height: 75px;
	width: 8px;
	border-top: 1px solid #E2E2E2;
	border-bottom: 1px solid #E2E2E2;
	border-right: 1px solid #E2E2E2;
	&:after {
		content: "";
		display: block;
		background: #e2e2e2;
		position: absolute;
		top: 50%;
	}
	&:after {
			left: 100%;
			height: 1px;
			width: 6px;
			transform: translateY(-50%);
			${({ reverse }) => reverse && `
				top: 0px;
			`}
	}
`;


export const LinesCross = styled.div`
		position: absolute;
		width: 26px;
		height: 77px;
		right: -21px;
		top: 36px;
		overflow: hidden;
		&:before,
  	&:after {
		content: "";
		display: block;
		height: 1px;
		width: 100px;
		position: absolute;
		top: 50%;
		background: #e2e2e2;
		transform-origin: 50px 0px;
  	}
    &:before,
    &:after {
      height: 1px;
    }
    &:before {
      left: -38px;
      transform: rotate(-80deg);
    }

    &:after {
      right: -38px;
      transform: rotate(80deg);
    }	
	${({ down_only }) => down_only && `
		&:before{
			display: none;
		}
		&:after {
	transform: rotate(76deg);
	}	
	`}
	${({ up_only }) => up_only && `
		&:after {
	display: none;
	}	
		&:before{
			width: 50px;
			left: -30px;
			transform: rotate(290deg);
		}
	`}
`;

export const StraightLine = styled.div`
	position: relative;
	top: 27px;
	left: -7%;
	height: 1px;
	width: 14px;
	background-color: #e2e2e2;
`;




export const FinalsHeader = styled.div`
	font-family: SourceSansPro;
	font-weight: 600;
	font-size: 12px;
	color: ${colors.primary.primary};
	text-align: left;
`;

export const FinalsWeekWrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	height: 100%;
	margin-right: 17px;
`;


export const FinalsBracketWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	max-height: 280px;
`;