// @flow
import * as React from "react";
import _ from "lodash";

import { ButtonPrimaryLink } from "../../ButtonPrimary";

import Container from "./Container";

type Props = {
	game_type: 'classic' | 'draft',
	league: {
		id: number,
		start_round: number
	}
}

class LeagueYetToStart extends React.Component<Props> {
	render() {
		const { game_type, league } = this.props;
		const text = `Your league will start in Round ${_.get(league, "start_round")}`;

		return <Container show_icon text={text} renderRightSide={() => (
			<ButtonPrimaryLink to={`/${game_type}/league/${league.id}`}>
				League Overview
			</ButtonPrimaryLink>
		)} />;
	}
}

export default LeagueYetToStart;