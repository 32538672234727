// @flow
import * as _ from "lodash";
import type { TPLeague, TDraftLineup } from "../../../../modules/types";
const normalisePosition = (l_in, l_out, pos_id, size) => {
	const actual = l_in[pos_id] || [];
	const extra = _.fill(new Array(size - actual.length), 0);
	return l_out[pos_id] =[...actual, ...extra];
};

export class OverflowUtils {

	static maxSquadSize(league: TPLeague) {
		/* SQUAD_SIZE is of the form D-M-R-F/B */
		let [ field, bench ] = league.squad_size.split("/");
		bench = parseInt(bench, 10);
		const position_sizes = _.map(field.split("-"), s => parseInt(s, 10));
		const total_size = _.sum([...position_sizes, bench]);

		return total_size;
	}

	static actualSquadSize(lineup: TDraftLineup, positions: Object) {
		const bench = _.filter(_.get(lineup, "bench", []), p => p !== 0);
		let actual_size = _.size(bench);

		_.forEach(
			positions,
			({ id }) => {
				actual_size += _.filter(lineup[id], i => i).length;
			}
		);

		return actual_size;
	}

	static overflowAmount(league: TPLeague, lineup: TDraftLineup, positions: Object) {
		return (
			OverflowUtils.actualSquadSize(lineup, positions) 
			- OverflowUtils.maxSquadSize(league)
		);
	}

	static normaliseLineup(
		league: TPLeague, 
		lineup: TDraftLineup, 
		positions: Object
	): TDraftLineup {
		/*
		 * Lineup doesn't necessarily show empty slots.
		 * Normalise it here, so that empty slots are shown
		 * as ids of 0
		 * TODO: this is probably an issue that needs to be
		 * fixed on the backend
		 */
		const lineup_out = {};
		try {
			/* SQUAD_SIZE is of the form D-M-R-F/B */
			let [ field, bench ] = league.squad_size.split("/");
			bench = parseInt(bench, 10);
			const position_sizes = _.map(field.split("-"), s => parseInt(s, 10));
			const total_size = _.sum([...position_sizes, bench]);

			let actual_size = (lineup.bench && lineup.bench.length) || 0;

			_.forEach(
				_.zipWith(position_sizes, _.keys(positions), (size, p) => ({ size, id: p })),
				({ size, id }) => {
					normalisePosition(lineup, lineup_out, id, size);
					actual_size += lineup_out[id].length;
				}
			);

			let bench_out = [...lineup.bench];
			if (total_size > actual_size) {
				bench_out = [...bench_out, ..._.fill(new Array(total_size - actual_size), 0)];
			}
			lineup_out.bench = bench_out;
		} 
		catch (e) {
			console.warn("Error normalising squad", e);
			return lineup;
		}
		return {
			captain: 0,
			vice_captain: 0,
			...lineup,
			...lineup_out,
			emergency: {
				"1": 0,
				"2": 0,
				"3": 0,
				"4": 0,
				...lineup.emergency,
				...lineup_out.emergency
			}
		};
	}
};

export default OverflowUtils;