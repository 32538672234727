// @flow

import type { TPosition, TPositionsById, TPositionStore } from "../../types";

export const getPositionsArray = ({
	positions: { ordered_ids, by_id }
}: TPositionStore): Array<TPosition> => ordered_ids.map(pos_id => by_id[pos_id]);

export const getPositionsById = ({
	positions: { by_id }
}: TPositionStore): TPositionsById => by_id;