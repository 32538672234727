import * as _ from "lodash";

export const setPlayerId = (line, position_index, id, swap_trade_out_id) => {

	const new_id = swap_trade_out_id === id ? 0 : id;

	return line.map((player_id, index) => {
		return position_index === index ? new_id : player_id;
	});
};

export const swapPlayersOnBench = (
	{ 
		new_lineup, 
		bench_player, 
		other_player, 
		swap_trade_out_id 
	}) => {
	const is_bench_utility = bench_player.is_utility;
	let { bench: new_bench } = new_lineup;

	if (is_bench_utility) {
		if (bench_player.position === other_player.position) {
			const line = setPlayerId(
				new_bench[bench_player.position], bench_player.position_index, other_player.id,
				swap_trade_out_id
			);

			return {
				...new_lineup,
				bench: {
					...new_bench,
					[bench_player.position]: line,
					utility: {
						id: swap_trade_out_id === bench_player.id ? other_player.id : 0,
						position: other_player.position
					}
				}
			};
		}

		const old_line = new_bench[bench_player.position].map((player_id, index) => (
			bench_player.position_index === index ? 0 : player_id
		)).filter(_.identity);

		// if (swap_trade_out_id !== other_player.id) {
		// 	new_bench[other_player.position].push(other_player.id);
		// }

		return {
			...new_lineup,
			bench: {
				...new_bench,
				[bench_player.position]: old_line,
				utility: {
					id: swap_trade_out_id === bench_player.id ? other_player.id : 0,
					position: other_player.position
				}
			}

		};
	}


	const line = setPlayerId(
		new_bench[bench_player.position], bench_player.position_index, other_player.id,
		swap_trade_out_id
	);
	return {
		...new_lineup,
		bench: {
			...new_bench,
			[bench_player.position]: line
		}
	};
};