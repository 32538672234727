// @flow
import * as React from "react";
import { change, formValueSelector, reduxForm } from "redux-form";
import { connect } from "react-redux";
import moment from "moment";
import { withRouter } from "react-router-dom";
import bindAll from "lodash/bindAll";
import get from "lodash/get";
import * as selectors from "../../../../modules/selectors";
import * as actions from "../../../../modules/actions";
import type {TRound} from "../../../../modules/types";
import ProSubscriptionModal  from "../../../proSubscription/ProSubscriptionModal";
import { 
	DEFAULT_FINALS_FORMAT,
	FINALS_LENGTHS,
	ROUNDS_IN_AFL_SEASON,
	getStartDraftDate,
	score_settings,
	threeDaysPriorKeeperDeadline
} from "../../../../helpers";
import { leagueValidate } from "../../../../utils/FormValidations/index";
import { TabWrap, Preloader, RestoreSettingsModal } from "../../../../components";
import { LeaguesTabArr } from "./../LeaguesTabArr";
import {
	RedTextButton,
	CreateForm,
	FormItem,
	ButtonWrapper,
	NextStepDiv,
	LeagueSubmitButton
} from "./LeaguesCreateFormStyles";

const POSITION_COUNT_ARR = [
	"DEF_value",
	"MID_value",
	"RUC_value",
	"FWD_value",
	"INT_value"
];



export type LeagueFormProps = {
	reset: any, // comes from redux form
	removeLeague?: Function,
	handleSubmit: Function,
	onChangeFile: Function,
	previewImg: string,
	league_name: string,
	toggleForm: Function,
	submitting: boolean,
	is_finals: boolean,
	league_size: number,
	squad_size: string,
	emergency: number,
	emergency_limit: number,
	original_positions: number,
	is_draft_commissioner: boolean,
	maxDate: string,
	rounds: TRound[],
	is_traders: boolean,
	league_create:{
		league_id?: number;
		error?: string;
		is_pending?: boolean;
	},
	user: {
		firstname: string,
		lastname: string,
	},
	regenerated_leagues_by_id: Object,
	regenerated_leagues_by_id_uf: Object,
	league?:{
		status: string,
		draft_status: string,
		id: number,
	},
	match: {
		params: {
			regenerate_league_id?: string,
		},
	},
	changeFieldValue: Function,
	fetchRegenerateLeague: typeof actions.leagueDraft.regenerateShowDraftLeague,
	initialValues: any,
	handleProOpen: Function,
	is_pro_sub: boolean,
	showDraftLeague: typeof actions.leagueDraft.showDraftLeague,
	leagueValues: any,
	setActiveDraftTab: typeof actions.setDraftTab,
	currentDraftTab: number
};

type State = {
	open_pro_modal: boolean,
	open_settings_modal: boolean,
};

const getGameType = isUFLeague => isUFLeague ? "uf":"af";
const getLeaguesToUse = isUFLeague => isUFLeague ? 
	"regenerated_leagues_by_id_uf":"regenerated_leagues_by_id";
const isRegenLeagueInState = (draftLeague,UFLeague) => draftLeague || UFLeague;
const ifNotEqual = (a,b) => a !== b;
const isRegenLeague = (regenerated_leagues_by_id, leaguesByID ) => regenerated_leagues_by_id &&
	regenerated_leagues_by_id in leaguesByID;


class LeagueCreateForm extends React.Component<LeagueFormProps, State> {
	constructor(props) {
		super(props);
		this.state = {
			open_pro_modal: false,
			open_settings_modal: false,
		};
		bindAll(this, [
			"populateWithRegenLeague",
			"scrollToForm",
			"setActiveTab",
			"openProModal",
			"closeProModal",
			"toggleSettingsModal",
			"restoreAndCloseModal"
		]);
	}
	

	componentDidMount() {
		const regenerated_leagues_by_id = get(this.props, "match.params.regenerate_league_id");
		// check if we have a regen league id && if it's in state,
		// YES populate form
		// NO fetch regen league => compDidUpdate will trigger once fetch is complete
		const regenAFLFantasyDraftLeague = regenerated_leagues_by_id &&
		regenerated_leagues_by_id in this.props.regenerated_leagues_by_id;

		const regenUFLeague = regenerated_leagues_by_id &&
		regenerated_leagues_by_id in this.props.regenerated_leagues_by_id_uf;

		const game = getGameType(regenUFLeague);

		if (isRegenLeagueInState(regenAFLFantasyDraftLeague,regenUFLeague)) {
			this.populateWithRegenLeague(regenerated_leagues_by_id,game);
		}
		else {
			this.props.fetchRegenerateLeague({ id: regenerated_leagues_by_id });
		}

		setTimeout(() => {
			if (get(this.props, "match.params.regenerate_league_id")) {
				this.scrollToForm();
			}
		}, 2500);
	}

	componentDidUpdate(prev_props: LeagueFormProps) {
		const regenerated_leagues_by_id = get(this.props, "match.params.regenerate_league_id");
		
		const regenAFLFantasyDraftLeague = isRegenLeague(
			regenerated_leagues_by_id,
			this.props.regenerated_leagues_by_id
		);

		const regenUFLeague = isRegenLeague(
			regenerated_leagues_by_id,
			this.props.regenerated_leagues_by_id_uf
		);

		const game = getGameType(regenUFLeague);
		const leaguesToUse = getLeaguesToUse(regenUFLeague);
		
		// detect if there is a regen league id
		if (ifNotEqual(
			prev_props.match.params.regenerate_league_id,
			regenerated_leagues_by_id
		)) {
			// check to see if we have that league in our redux state
			if (isRegenLeagueInState(regenAFLFantasyDraftLeague,regenUFLeague)) {
				this.populateWithRegenLeague(regenerated_leagues_by_id,game);
			}
		}

		// I will trigger once fetch regen league is done
		if (
			!get(prev_props, `${leaguesToUse}[${regenerated_leagues_by_id}]`) &&
			get(this.props, `${leaguesToUse}[${regenerated_leagues_by_id}]`)
		) {
			this.populateWithRegenLeague(regenerated_leagues_by_id,game);
		}

		// scroll to form on regen league update
		if (ifNotEqual(
			get(prev_props, "match.params.regenerate_league_id"),
			get(this.props, "match.params.regenerate_league_id")
		)) {
			this.scrollToForm();
		}
	}

	form_ref: HTMLFormElement;

	scrollToForm() {
		if(this.form_ref) {
			window.scrollTo(0, this.form_ref.offsetTop + 50);
		}
	}

	toggleSettingsModal() {
		this.setState({
			open_settings_modal: !this.state.open_settings_modal
		});
	}

	restoreAndCloseModal() {
		this.props.reset();
		this.toggleSettingsModal();
	}

	populateWithRegenLeague(id: string, game:string) {
		if(!id) {
			return null;
		}
		const regenLeague = game === "uf" ?
			this.props.regenerated_leagues_by_id_uf[id]:
			this.props.regenerated_leagues_by_id[id];
		const fields = regenLeague;
		delete fields.start_round;
		delete fields.draft_start;
		delete fields.keeper_deadline;
		delete fields.regenerated_from;
		delete fields.privacy;
		
		this.props.changeFieldValue("regenerated_from", id);
		const isKeeper = Boolean(regenLeague.keeper);

		let default_date = new Date(getStartDraftDate());
		// default_date.setMinutes(default_date.getMinutes() + 240);
		let default_keeper_deadline = moment(default_date);
		
		if(isKeeper){
			default_date = new Date(getStartDraftDate().add(4, "day").add(7, "hours"));
		}
		this.props.changeFieldValue("draft_start", moment(default_date));
		this.props.changeFieldValue("keeper_deadline", default_keeper_deadline);
		
		Object.keys(fields).forEach(field => {
			this.props.changeFieldValue(field, fields[field]);
		});
		const splitPositions = regenLeague.squad_size.split("-");
		const positionArr = splitPositions.reduce((arr, item) => {
			if(item.split("/").length === 2){
				const forwards = Number(item.split("/")[0]);
				const interchange = Number(item.split("/")[1]);
				return arr = [...arr, forwards, interchange];
			}
			return arr = [...arr, Number(item)];
		}, []);
	
		POSITION_COUNT_ARR.forEach((positionField, index) => {
			this.props.changeFieldValue(positionField, positionArr[index]);
		});
	}

	setActiveTab(tabIndex) {
		this.props.setActiveDraftTab(tabIndex);
	}

	openProModal() {
		if(this.props.league_name !== "") {
			this.setState({
				open_pro_modal: true
			});	
		}
	}
	closeProModal(){
		this.setState({
			open_pro_modal: false
		});
	}


	render() {
		const { props } = this;
		const activeTab = props.currentDraftTab;
		const leagueTabs = LeaguesTabArr(props, this.openProModal);
		const isLastTab = leagueTabs.length - 1 === activeTab;
		const handleNextTab = () => {
			window.scrollTo(0, 0);
			if (!isLastTab) {
				this.setActiveTab(activeTab + 1);
			}
		};
		const getProSub = () => {
			if(props.is_pro_sub === undefined){
				return false;
			}
			return props.is_pro_sub;
		};
		const disableSubmitButton = props.league_create.is_pending || !props.league_name;
		return (
			<div>
				{props.league_create.is_pending ? <Preloader />:null}
				{this.state.open_settings_modal && 
					<RestoreSettingsModal 
						closeClick={this.toggleSettingsModal}
						confirmClick={this.restoreAndCloseModal}
						cancelClick={this.toggleSettingsModal}
						header_text={"Restore settings to default"}
						body_text={"Are you sure you wish to reset your settings to default?"}
					/>
				}
				{this.state.open_pro_modal && 
					<ProSubscriptionModal 
						closeClick={this.closeProModal}
						league={this.props.league}
						is_pro_sub={getProSub()}
						leagueCreateSubmit={props.handleSubmit}
						changeFieldValue={props.changeFieldValue}
						create
					/>
				}
				<CreateForm onSubmit={props.handleSubmit} innerRef={x => (this.form_ref = x)}>
					<TabWrap
						activeTab={activeTab}
						setActiveTab={this.setActiveTab}
						tabData={leagueTabs.map(tab => ({
							alertText: tab.alertText,
							key: tab.key,
							title: tab.title,
							content: <div>{tab.content}</div>,
						}))}
					/>
					<FormItem className="base-options">
						<ButtonWrapper>
							<NextStepDiv onClick={handleNextTab} isDisabled={isLastTab}>
								{activeTab === 0 ? "Customise settings" : "More settings"}
							</NextStepDiv>
							<LeagueSubmitButton disabled={disableSubmitButton}>
								Create your league
							</LeagueSubmitButton>
						</ButtonWrapper>
					</FormItem>
				</CreateForm>
				{activeTab !== 0 ? <RedTextButton onClick={() => this.toggleSettingsModal()}>
					Restore settings to default
				</RedTextButton>:null}
			</div>
		);
	}
}



LeagueCreateForm = reduxForm({
	form: "league_manager",
	validate: leagueValidate,
})(LeagueCreateForm);

const selector = formValueSelector("league_manager");

const mapStateToProps = (state, ownProps) => {
	const league_size = selector(state, "size");
	
	const getCustomScoring = stat => score_settings[stat] ? score_settings[stat].defaultValue:0;
	const regenerated_from = selector(state, "regenerated_from");
	const keeper = selector(state, "keeper");
	const showScoringField = stat => Boolean(getCustomScoring(stat));
	const isKeeperAndRegen = Boolean(keeper) && Boolean(regenerated_from);
	
	const getDefaultDraftStart = () => {
		let default_date = new Date(getStartDraftDate());
		default_date.setMinutes(default_date.getMinutes() + 240);
		if(isKeeperAndRegen){
			const handledDate = new Date(getStartDraftDate().add(3, "day"));
			const newDate = handledDate.setMinutes(handledDate.getMinutes() + 240);
			default_date = newDate;
		}
		const THREE_WEEK_BEFORE_SEASON_START = process.env.REACT_APP_THREE_WEEK_PRIOR_START;
		const unixThreeWeek = moment.unix(THREE_WEEK_BEFORE_SEASON_START);
		const default_handled = 
			moment(default_date).unix() >= unixThreeWeek.unix() 
				? moment(default_date) : unixThreeWeek;
		return default_handled;
	};
	const threeDaysPriorDate = threeDaysPriorKeeperDeadline(getDefaultDraftStart());

	const teamNumTotal = [
		selector(state, "DEF_value"),
		selector(state, "MID_value"),
		selector(state, "FWD_value"),
		selector(state, "RUC_value"),
		selector(state, "INT_value"),
	].reduce((total, currVal) => {
		return total += Number(currVal);
	}, 0);
	return {
		is_finals: selector(state, "finals") === 1,
		is_traders: selector(state, "trade_waivers") === 1,
		is_draft_auto: selector(state, "draft_type") === "auto",
		actual_round: selectors.rounds.getActualRound(state),
		league_size,
		is_draft_commissioner: selector(state, "draft_type") === "commissioner",
		league_name: selector(state, "name"),
		emergency: selector(state, "emergency"),
		emergency_limit: selector(state, "emergency_limit"),
		maxDate: ownProps.selected_round.start,
		regenerated_leagues_by_id: state.leagues.regenerate_show.by_id,
		regenerated_leagues_by_id_uf: state.leagues.regenerate_show_uf.by_id,
		currentDraftTab:state.user.currentDraftTab,
		keeper,
		keeper_deadline: threeDaysPriorDate,
		squad_size: selector(state, "squad_size"),
		league_draft_start: selector(state, "draft_start"),
		regenerated_from,
		squadSize: teamNumTotal,
		finalsFormat: selector(state, "finals_format"),
		leagueSizeLive: selector(state, "size"),
		leaguePlayByes: selector(state, "bye_rounds"),
		currentPlayTimes: selector(state, "play_times"),
		currentStartRound: selector(state, "start_round"),
		currentPlayFinals: selector(state, "finals"),
		initialValues: {
			name: "",
			start_round: ownProps.selected_round_id,
			privacy: 0,
			play_times: "flexible",
			finals: 1,
			captains: 1,
			emergency: 1,
			lockout: 1,
			trade_waivers: 0,
			waiver_period: 1,
			waiver_order: 1,
			coaches_box: 1,
			bye_rounds: 0,
			rfa_privacy: "private",
			approve_trade: "league",
			size: 16,
			draft_type: "live",
			finals_format: DEFAULT_FINALS_FORMAT,
			finals_start_round:(ROUNDS_IN_AFL_SEASON + 1) - FINALS_LENGTHS[DEFAULT_FINALS_FORMAT],
			finals_length: FINALS_LENGTHS[DEFAULT_FINALS_FORMAT],
			draft_start: getDefaultDraftStart(),
			keeper_deadline: threeDaysPriorDate,
			emergency_limit: 4,
			original_positions: 0,
			squad_size: "2-3-1-2/4",
			draft_order: "snake",
			draft_turn: 60,
			rank_by: "percentage",
			keeper:0,
			keeper_num:0,
			B_value: getCustomScoring("B"),
			B_show: showScoringField("B"),
			CL_value: getCustomScoring("CL"),
			CL_show: showScoringField("CL"),
			CM_value: getCustomScoring("CM"),
			CM_show: showScoringField("CM"),
			CP_value: getCustomScoring("CP"),
			CP_show: showScoringField("CP"),
			CS_value: getCustomScoring("CS"),
			CS_show: showScoringField("CS"),
			D_value: getCustomScoring("D"),
			D_show: showScoringField("D"),
			ED_value: getCustomScoring("ED"),
			ED_show: showScoringField("ED"),
			FA_value: getCustomScoring("FA"),
			FA_show: showScoringField("FA"),
			FD_value: getCustomScoring("FD"),
			FD_show: showScoringField("FD"),
			FF_value: getCustomScoring("FF"),
			FF_show: showScoringField("FF"),
			G_value: getCustomScoring("G"),
			G_show: showScoringField("G"),
			GA_value: getCustomScoring("GA"),
			GA_show: showScoringField("GA"),
			H_value: getCustomScoring("H"),
			H_show: showScoringField("H"),
			HO_value: getCustomScoring("HO"),
			HO_show: showScoringField("HO"),
			I50_value: getCustomScoring("I50"),
			I50_show: showScoringField("I50"),
			IED_value: getCustomScoring("IED"),
			IED_show: showScoringField("IED"),
			K_value: getCustomScoring("K"),
			K_show: showScoringField("K"),
			M_value: getCustomScoring("M"),
			M_show: showScoringField("M"),
			R50_value: getCustomScoring("R50"),
			R50_show: showScoringField("R50"),
			SP_value: getCustomScoring("SP"),
			SP_show: showScoringField("SP"),
			T_value: getCustomScoring("T"),
			T_show: showScoringField("T"),
			UCP_value: getCustomScoring("UCP"),
			UCP_show: showScoringField("UCP"),

			DEF_show: true,
			DEF_value: 2,
			MID_show: true,
			MID_value: 3,
			RUC_show: true,
			RUC_value: 1,
			FWD_show: true,
			FWD_value: 2,
			INT_show: true,
			INT_value: 4,
			plan: "",
			token: "",
		},
	};
};

const mapDispatchToProps = dispatch => {
	return {
		changeFieldValue: function (field, value) {
			dispatch(change("league_manager", field, value));
		},
		fetchRegenerateLeague: actions.leagueDraft.regenerateShowDraftLeague,
		setActiveDraftTab: (tabIndex:number) => dispatch(actions.setDraftTab(tabIndex))
	};
};

LeagueCreateForm = withRouter(connect(mapStateToProps, mapDispatchToProps)(LeagueCreateForm));

export default LeagueCreateForm;