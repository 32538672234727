// @flow
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import uiGameSelectionReducer from "./uiGameSelection";

export { reducer as form } from "redux-form";
export * from "./user";
export * from "./countries";
export * from "./globalRedirect";
export * from "./leaguesClassic";
// export * from "./playerStatuses";
// export * from "./coachStats";
export * from "./uiMenu";
export * from "./uiSelectedLeague";
export * from "./coachStats";
export * from "./playerStatuses";
export * from "./teamsClassic";
export * from "./contactUs";
export * from "./aflIdModal";
export * from "./playerModal";
export * from "./news";
export * from "./playerNews";
export * from "./help";
export * from "./coachesBox";
export * from "./cms";
export * from "./offSeason";
export * from "./offSeasonPlayers";
export * from "./teamsDraft";
export * from "./rounds";
export * from "./squads";
export * from "./venues";
export * from "./positions";
export * from "./players";
export * from "./teamsDraft";
export * from "./leaguesDraft";
export * from "./checksums";

const uiGameSelectionPersistConfig = {
	key: "game-selection-favourite",
	keyPrefix: "afl-fantasy",
	version: 2,
	storage,
	throttle: 1000,
	whitelist: ["favourite"]
};

// $FlowFixMe
export const uiGameSelection = persistReducer(uiGameSelectionPersistConfig, uiGameSelectionReducer);