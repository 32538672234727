// @flow
import React from "react";
import SvgIconContainer from "../SvgIconContainer";

export default (
	{
		unit = "em",
		...rest
	}: Object = {}) => (
	<SvgIconContainer viewBox="0 0 24 24" {...rest} unit={unit}>
		<title>More</title>
		<g id="Design-(Draft)---[Web]-About-/-Settings" 
			stroke="none" stroke-width="1" fill="none" fillRule="evenodd">
			<g id="Draft-Settings---Draft-History-[D]" 
				transform="translate(-842.000000, -538.000000)">
				<rect fill="#FFFFFF" x="0" y="0" width="1280" height="1720"></rect>
				<g id="5.0-Components-/-About-/-Draft-History-Cell-[D]-Copy-23" 
					transform="translate(20.000000, 520.000000)">
					<rect id="Rectangle" fill="#F8F8FA" fillRule="nonzero" x="0" y="0" 
						width="920" height="60"></rect>
					<g id="3.0-Icons-/-Gameplay-/-Trade-Circle-Copy" 
						transform="translate(822.000000, 18.000000)">
						<circle id="bg" fill="#FFFFFF" 
							fillRule="nonzero" cx="12" cy="12" r="12"></circle>
						<path 
							d="M12,0 C18.627417,0 24,5.372583 24,12 C24,18.627417
								 18.627417,24 12,24 C5.372583,24 0,18.627417 0,12 C0,5.372583 
								 5.372583,0 12,0 Z M15.8194444,8.41030541 L8.18055556,8.41030541 
								 C8.01276071,8.41030541 7.87276483,8.52368841 
								 7.84038765,8.67441604 L7.83333333,8.74107832 
								 L7.83333333,17.6769084 C7.83333333,18.364648 8.38415563,18.9298309 
								 9.08846295,18.9939433 L9.22222222,19 L14.7777778,19 C15.4997186,
								 19 16.093008,18.4752724 16.1603087,17.8043309 
								 L16.1666667,17.6769084 
								 L16.1666667,8.74107832 C16.1666667,8.58123259 
								 16.0476451,8.44786888 
								 15.8894218,8.41702554 L15.8194444,8.41030541 
								 Z M10.1412974,9.6223126 
								 C10.2494547,9.6223126 10.3405466,9.67373346 10.3679062,9.74373278 
								 L10.3763007,9.78769905 L10.3763007,17.3997759 
								 C10.3763007,17.475893 
								 10.3032351,17.54 10.2037706,17.5592546 L10.1412974,17.5651624 
								 L9.67129079,17.5651624 C9.5631335,17.5651624 9.47204161,17.5137415 
								 9.44468202,17.4437422 L9.43628747,17.3997759 
								 L9.43628747,9.78769905 
								 C9.43628747,9.71158204 9.50935315,9.64747501 9.6088176,9.62822036
								L9.67129079,9.6223126 L10.1412974,9.6223126 
								Z M12.2563273,9.6223126 C12.3644846,9.6223126 
								12.4555765,9.67373346 12.4829361,9.74373278 
								L12.4913306,9.78769905 L12.4913306,17.3997759 C12.4913306,17.475893 
								12.4182649,17.54 12.3188005,17.5592546 L12.2563273,17.5651624 
								L11.7863206,17.5651624 C11.6781634,17.5651624 11.5870715,17.5137415 
								11.5597119,17.4437422 L11.5513173,17.3997759 L11.5513173,9.78769905 
								C11.5513173,9.71158204 11.624383,9.64747501 11.7238475,9.62822036 
								L11.7863206,9.6223126 L12.2563273,9.6223126 Z M14.3713571,9.6223126 
								C14.4795144,9.6223126 14.5706063,9.67373346 14.5979659,9.74373278 
								L14.6063605,9.78769905 L14.6063605,17.3997759 C14.6063605,17.475893 
								14.5332948,17.54 14.4338303,17.5592546 L14.3713571,17.5651624 
								L13.9013505,17.5651624 C13.7931932,17.5651624 13.7021013,17.5137415 
								13.6747417,17.4437422 L13.6663472,17.3997759 L13.6663472,9.78769905 
								C13.6663472,9.71158204 13.7394129,9.64747501 13.8388773,9.62822036 
								L13.9013505,9.6223126 L14.3713571,9.6223126 
								Z M13.1125078,4.00019494 
								L13.0044657,4.00671378 L10.3685778,4.48032901 
								C10.0274247,4.55599769 
								9.79371537,4.87963947 9.80378909,5.23818987 L9.81426104,5.34658918 
								L9.9184357,6.09444555 L7.28254785,6.56806078 C7.1183919,6.60439301 
								7.00396916,6.75561319 7.00010117,6.92671689 L7.00538945,7.00098743 
								L7.1095641,7.74884379 C7.11949649,7.84473189 7.16568207,7.93227883 
								7.23759534,7.99153304 C7.29153029,8.0359737 7.35649063,8.06165993 
								7.42367788,8.06610038 L7.49128156,8.06336662 L16.7170812,6.40530641 
								C16.8813735,6.36913755 16.9959718,6.21789377 16.999896,6.04669506 
								L16.994624,5.97237976 L16.890065,5.2245234 C16.8805298,5.1280706 
								16.8343576,5.03986568 16.7621528,4.98016684 C16.7079993,4.9353927 
								16.6426929,4.90953921 16.5751511,4.9051316 L16.5071943,4.90796614 
								L13.8716909,5.38158138 L13.7671318,4.6341319 C13.7467702,4.44286053 
								13.65432,4.26838987 13.5107385,4.15027053 C13.3958733,4.05577506 
								13.2560671,4.00374721 13.1125078,4.00019494 
								L13.1125078,4.00019494 Z" 
							id="Shape" fill="#D92425"></path>
					</g>
				</g>
			</g>
		</g>
	</SvgIconContainer>
);