export const FINALS_FOUR_TEAMS = {
	"0": [{
		fixture_index: 0,
		round_index: 1,
		component: "two_in_one",
	}],
	"1": [
		{
			fixture_index: 0,
			round_index: 0,
			component: "two_in_one"
		},
		{
			fixture_index: 0,
			round_index: 1,
			component: "line_top_no_middle"
		},
		{
			fixture_index: 0,
			round_index: 2,
			component: "straight_line"
		}
	]
};

export const FINALS_SIX_TEAMS = {
	"0": [
		{
			fixture_index: 0,
			round_index: 2,
			component: "two_in_one",
		}
	],
	"1": [
		{
			fixture_index: 0,
			round_index: 0,
			component: "lines_cross_down"
		},
		{
			fixture_index: 1,
			round_index: 1,
			component: "straight_line"
		},
		{
			fixture_index: 1,
			round_index: 1,
			component: "lines_cross_up"
		},
		{
			fixture_index: 0,
			round_index: 2,
			component: "line_top_no_middle"
		},
		{
			fixture_index: 0,
			round_index: 3,
			component: "straight_line"
		}
	]
};

export const FINALS_FIVE_TEAMS = [
	{
		fixture_index: 0,
		round_index: 1,
		component: "straight_line"
	},
	{
		fixture_index: 0,
		round_index: 0,
		component: "lines_cross_down"
	},
	{
		fixture_index: 1,
		round_index: 1,
		component: "straight_line"
	},
	{
		fixture_index: 0,
		round_index: 2,
		component: "line_top"

	},
	{
		fixture_index: 1,
		round_index: 1,
		component: "lines_cross_up"
	},
	{
		fixture_index: 0,
		round_index: 3,
		component: "straight_line"
	}
];

export const FINALS_EIGHT_TEAMS =  [
	{
		fixture_index: 0,
		round_index: 1,
		component: "straight_line"
	},
	{
		fixture_index: 0,
		round_index: 1,
		component: "line_top"
	},
	{
		fixture_index: 0,
		round_index: 1,
		component: "lines_cross",
	},
	{
		fixture_index: 1,
		round_index: 1,
		component: "line_top_reverse",
	},
	{
		fixture_index: 1,
		round_index: 1,
		component: "straight_line"
	},
	{
		fixture_index: 0,
		round_index: 3,
		component: "two_in_one",
	}
];