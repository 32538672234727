/* eslint max-len: 0 */
// @flow
import React from "react";
import SvgIconContainer from "../SvgIconContainer";

export default (
	{
		unit = "em",
		...rest
	}: Object = {}) => (
	<SvgIconContainer viewBox="0 0 24 24" {...rest} unit={unit}>
		<title>Injured</title>
		<path d="M17.0909091,19 L6.90909091,19 C5.85472911,19 5,18.1452709 5,17.0909091
			L5,10.7272727 C5,9.67291093 5.85472911,8.81818182 6.90909091,8.81818182
			L17.0909091,8.81818182 C18.1452709,8.81818182 19,9.67291093 19,10.7272727 L19,17.0909091
			C19,18.1452709 18.1452709,19 17.0909091,19 Z M15.1818182,13.2727273 C15.1818182,13.0970003
			15.0393633,12.9545455 14.8636364,12.9545455 L12.9545455,12.9545455 L12.9545455,11.0454545
			C12.9545455,10.8697276 12.8120906,10.7272727 12.6363636,10.7272727 L11.3636364,10.7272727
			C11.1879094,10.7272727 11.0454545,10.8697276 11.0454545,11.0454545 L11.0454545,12.9545455
			L9.13636364,12.9545455 C8.96063667,12.9545455 8.81818182,13.0970003 8.81818182,13.2727273
			L8.81818182,14.5454545 C8.81818182,14.7211815 8.96063667,14.8636364 9.13636364,14.8636364
			L11.0454545,14.8636364 L11.0454545,16.7727273 C11.0454545,16.9484542 11.1879094,17.0909091
			11.3636364,17.0909091 L12.6363636,17.0909091 C12.8120906,17.0909091 12.9545455,16.9484542
			12.9545455,16.7727273 L12.9545455,14.8636364 L14.8636364,14.8636364 C15.0393633,14.8636364
			15.1818182,14.7211815 15.1818182,14.5454545 L15.1818182,13.2727273 Z M14.5454545,8.18181818
			L14.5454545,6.59090909 C14.5454545,6.41518213 14.4029997,6.27272727 14.2272727,6.27272727
			L9.77272727,6.27272727 C9.68621107,6.26503826 9.60073524,6.29608242 9.53931792,6.35749974
			C9.4779006,6.41891706 9.44685644,6.50439289 9.45454545,6.59090909 L9.45454545,8.18181818
			L8.18181818,8.18181818 L8.18181818,6.27272727 C8.18181818,5.56981941 8.75163759,5
			9.45454545,5 L14.5454545,5 C15.2483624,5 15.8181818,5.56981941 15.8181818,6.27272727
			L15.8181818,8.18181818 L14.5454545,8.18181818 Z" />

	</SvgIconContainer >
);