// @flow
import type {
	TRootStore,
} from "../../types";
import * as userSelectors from "../user";

export const getFavourite = (state: TRootStore) => {
	const user = userSelectors.getUser(state);
	return state.uiGameSelection.favourite &&
		user &&
		state.uiGameSelection.favourite[user.id];
};

export const hasFavourite = (state: TRootStore) => {
	const user = userSelectors.getUser(state);

	return Boolean(
		state.uiGameSelection.favourite &&
		user &&
		state.uiGameSelection.favourite[user.id]
	);
};