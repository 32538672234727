// @flow
import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import bindAll from "lodash/bindAll";
import { withRouter } from "react-router-dom";

import * as actions from "../../../../modules/actions";
import colors from "../../../../assets/css/colors";
import { below } from "../../../../assets/css/media";

import { ButtonPrimary, LeagueName, ConfirmationModal } from "../../../../components";
const SEASON_YEAR = process.env.REACT_APP_SEASON_YEAR || "";;

const Wrapper = styled.section`
	width: 100%;
	margin-bottom: 20px;
`;

const Title = styled.h2`
	font-size: 24px;
	line-height: 1.666;
	color: ${colors.primary.primary};
	margin: 1.25em 0;
`;

const League = styled.div`
	width: 100%;
	box-sizing: border-box;
	padding: 0 10px;
	border: 1px dashed #CAD2D8;
	min-height: 60px;
	margin-bottom: 10px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	
	${below.tablet`
		flex-direction: row;
	`};
`;

const RegenerateButton = styled(ButtonPrimary)`
	font-family: SourceSansPro;
	font-size: 12px;
	font-weight: 600;
	color: #FFFFFF;
	padding: 14px 21.5px;
	border-radius: 2px;
	${below.tablet`
		flex-direction: row;
		padding: 9px 21px
	`};
`;

const LeagueNameWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
`;

const Details = styled.div`
	display: flex;
	flex-direction: row;
	font-family: TitilliumUpright;
	font-weight: 700;
	color: ${colors.secondary.accentGrey};
	> div {
		margin: 0 5px;
		text-align: center;
		font-size: 12px;
		line-height: 2;
		width: 5em;
		p{
			font-family: SourceSansPro;
			font-weight: 600;
			font-size: 12px;
			color: #1D4073;
		}
	}
	span {
		color: ${colors.primary.darkGrey};
		font-family: SourceSansPro;
		font-weight: 400;
		font-size: 12px;
		color: #1D4073;
	}
	${below.tablet`
		display: none;
	`};
`;


type Props = {
	leagues: [{
		id: number,
		name: string,
		firstname: string,
		lastname: string,
		avatar_version: number,
		avatar_prefix: string,
		is_club?: boolean,
		users: Array<Object>,
		size: number,
		privacy: 1 | 0,
		start_round: number
	}],
	fetchRegenerateLeaguesList: typeof actions.leagueDraft.regenerateListDraftLeagues,
	fetchRegenerateLeague: typeof actions.leagueDraft.regenerateShowDraftLeague,
	regenerate_list: Object,
	history: {
		push: Function
	},
	match: {
		params: {
			regenerate_league_id?: string
		}
	}
}

type State = {
	confirm_modal: boolean,
	selected_regenerated_league_id: number,
}

class RegenerateLeaguesComp extends React.Component<Props, State> {
	constructor(props) {
		super(props);
		bindAll(this, ["handleRegnerateLeague"]);
	}

	state = {
		confirm_modal: false,
		selected_regenerated_league_id: 0,
	};

	componentDidMount() {
		this.props.fetchRegenerateLeaguesList();
	}

	componentDidUpdate(prev_props:Props) {
		// fetch each regen league
		if(prev_props.regenerate_list.length === 0 && this.props.regenerate_list.length > 0) {
			this.props.regenerate_list.forEach(v => {
				this.props.fetchRegenerateLeague({ id: v.id });
			});
		}
	}

	handleRegnerateLeague() {
		this.props.history
			 .push(`/draft/leagues/create/${this.state.selected_regenerated_league_id}`);
		this.setState({ confirm_modal: false });
	}

	render () {
		const { leagues } = this.props;
		if(leagues.length === 0) {
			return null;
		}
		return (
			<Wrapper>
				{this.state.confirm_modal && <ConfirmationModal
					body_text="This action will pre-fill the Create a League form with the same settings from last season. You will need to submit the form to complete this process. Do you want to continue?" // eslint-disable-line max-len
					header_text="Regenerate League"
					cancelClick={() => this.setState({ confirm_modal: false })}
					closeClick={() => this.setState({ confirm_modal: false })}
					confirmClick={this.handleRegnerateLeague}
					confirm_button_text="Continue"
				/>}
				<Title>
					Regenerate your {parseInt(SEASON_YEAR, 10) - 1} leagues
				</Title>
				{leagues.map(league => {
					if(
						this.props.match.params.regenerate_league_id &&
						this.props.match.params.regenerate_league_id === league.id.toString()
					) {
						return null;
					}
					return (
						<League key={league.id}>
							<LeagueNameWrapper>
								<LeagueName
									name={league.name}
									width="11.4em"
									lastname=""
									firstname={league.users[0].firstname}
								/>
							</LeagueNameWrapper>
							<Details>
								<div>
									<p>Type</p>
									<span>
										{league.privacy === 0 ? "Private": "Public"}
									</span>
								</div>
								<div>
									<p>Size</p>
									<span>
										{league.size}
									</span>
								</div>
								<div>
									<p>Start</p>
									<span>
									Round {league.start_round}
									</span>
								</div>
							</Details>
							<div>
								<RegenerateButton onClick={() => {
									this.setState({
										confirm_modal: true,
										selected_regenerated_league_id: league.id
									});
								}}>
									Regenerate league
								</RegenerateButton>
							</div>
						</League>
					);
				})}
			</Wrapper>
		);
	}
}

const mapStateToProps = state => ({
	regenerate_list: state.leagues.regenerate_list.result,
	leagues: state.leagues.regenerate_list.result
		.filter(l => l.id in state.leagues.regenerate_show.by_id)
		.map(l => state.leagues.regenerate_show.by_id[l.id])
});

const mapDispatchToProps = {
	fetchRegenerateLeaguesList: actions.leagueDraft.regenerateListDraftLeagues,
	fetchRegenerateLeague: actions.leagueDraft.regenerateShowDraftLeague,
};


export const RegenerateLeagues =
	withRouter(connect(mapStateToProps, mapDispatchToProps)(RegenerateLeaguesComp));
export default RegenerateLeagues;