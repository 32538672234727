// @flow
import styled, { css } from "styled-components";

export const FieldPlayers = styled.div`
	display: flex;
	flex-wrap: wrap;
	width: ${({ widthProp }) => `${widthProp}%` || "auto"};
	align-items: center;
	justify-content: center;
	align-content: center;
	z-index: 3;
	padding: ${({ paddingProp }) => paddingProp ? `0 ${paddingProp}%`: "0"};
	${({ mids }) => mids && "padding-right: 10px"};

	${({ is_draft }) => is_draft && css`
		flex-grow: 1;
	`}
`;

export default FieldPlayers;