import styled from "styled-components";
import colors from "../../../../assets/css/colors";
import { below } from "../../../../assets/css/media";
import { FlexDiv, FlexDivColCenter } from "../../../../components";

export const TitleWrap = styled(FlexDiv)`
	justify-content: space-between;
	${below.tablet`
		flex-direction: column;
		margin: 0 10px;

		& h1 {
			display: block;
		}
	`}

`;

export const TitleRow = styled(FlexDiv)`
	height: 20px;
	line-height: 20px;
	margin: 10px 0;
`;

export const ListItem = styled(FlexDiv)`
	color: ${colors.primary.primary};
	height: 60px;
	
	font-family: "SourceSansPro";
	font-size: 12px;
	letter-spacing: 0;
	line-height: 12px;
	text-align: center;
	&:nth-child(odd) {
		background: #eeeeee;
	}
`;

export const HeadingTitle = styled(FlexDivColCenter)`
	color: ${colors.primary.primary};
	font-family: "SourceSansPro";
	font-size: 12px;
	font-weight: 600;
	letter-spacing: 0;
	line-height: 12px;
`;
export const PickTitleDiv = styled(HeadingTitle)`
	text-align: center;
	width: 60px;
`;
export const TeamTitleDiv = styled(HeadingTitle)`
	width: calc(100% - 210px);
	${below.tablet`
		width: calc(100% - 120px);
	`}
`;
export const SizeTitleDiv = styled(HeadingTitle)`
	text-align: center;
	width: 150px;
	${below.tablet`
		width: 60px;
	`}
`;

export const PickDiv = styled(FlexDivColCenter)`
	text-align: center;
	width: 60px;
`;
export const TeamDiv = styled(FlexDivColCenter)`
	width: calc(100% - 210px);
	${({ isKeeper }) => isKeeper && `
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
	`}
	${below.tablet`
		width: calc(100% - 120px);
	`}
`;
export const SizeDiv = styled(FlexDivColCenter)`
	text-align: center;
	width: 150px;
	${below.tablet`
		width: 60px;
	`}
`;

export const KeeperDiv = styled(FlexDivColCenter)`
	width: 200px;	
	${below.tablet`
		width: 100px;
	`}
`;

export const BaseSelect = styled.select`
	font-family: "SourceSansPro";
	font-size: 12px;
	font-weight: 600;
	letter-spacing: 0;
	line-height: 12px;
	color: ${colors.secondary.accentGrey};
	height: 40px;
	width: 200px;
	border: 1px solid ${colors.form.borderColor};
	${below.tablet`
    	width: 100%;
	`}
`;

export const RoundSelect = styled(BaseSelect)``;
export const PickSelect = styled(BaseSelect)`
	background: ${props => props.hasChanged ? colors.secondary.paleGrey : "white"};
	color: ${props => props.hasChanged ? colors.primary.primary : "inherit"};
`;

export const KeeperSelect = styled(BaseSelect)`
	margin-left: 20px;
	width: 200px;
	background: ${props => props.hasChanged ? colors.secondary.paleGrey : "white"};
	color: ${props => props.hasChanged ? colors.primary.primary : "inherit"};
	&:disabled{
		opacity: 0.3;
	}
`;