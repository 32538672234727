// @flow
import _ from "lodash";
import * as React from "react";
// import { withRouter } from 'react-router-dom';
import styled from "styled-components";
import CommandButton from "../../../src/components/MyTeam/ListView/commandButton.js";

import colors from "../../assets/css/colors";
import { above, below } from "../../assets/css/media";
import { SearchField, SearchInputWrapper, SearchSubmitButton } from "../../components";
import Close from "../../components/Icons/Close";
import More from "../../components/Icons/More";
import Search from "../../components/Icons/Search";
import SortDown from "../../components/Icons/SortDown";
import SortUp from "../../components/Icons/SortUp";
import type { TPlayerStatusesReducer, TPosition, TSquad } from "../../modules/types";
import isMobile from "../../utils/isMobile/index";
import { Select } from "../Form";
import { FormCircleCheckbox } from "../Form/FormCircleCheckbox";
import {TPLeague} from "../../modules/types";

const prices = [
	{ key: "200000-299000", value: "$200K - $299K" },
	{ key: "300000-399000", value: "$300K - $399K" },
	{ key: "400000-499000", value: "$400K - $499K" },
	{ key: "500000-599000", value: "$500K - $599K" },
	{ key: "600000-699000", value: "$600K - $699K" },
	{ key: "700000-799000", value: "$700K - $799K" },
	{ key: "800000-899000", value: "$800K - $899K" },
	{ key: "900000-9999999", value: "$900K+"}
];

const DraftFiltersForm = styled.form`
	margin: 1em 0 20px;

	${below.desktop`
		padding: 0 15px;
	`};
`;

const StyledFormCircleCheckbox = styled(FormCircleCheckbox)`
	min-width: auto !important;
	margin-left: 5px;

	-webkit-user-select:none;
	-khtml-user-select:none;
	-moz-user-select:none;
	-o-user-select:none;
	user-select:none;
	label{
		-webkit-user-select:none;
		-khtml-user-select:none;
		-moz-user-select:none;
		-o-user-select:none;
		user-select:none;
	}


	${below.tablet`
		margin: 24px 0 0 0;
	`}
`;

const StyledCommandButton = styled(CommandButton)`
	> svg{
		height: 100%;
		width: 100%;

		&.filter{
			filter: invert(96%) sepia(8%) saturate(89%) hue-rotate(155deg) brightness(88%) 
				contrast(96%);
		}
	}

	${above.tablet`
		display: none;
	`}

	height: auto;
	width: 25px;
`;

const Filters = styled.div`
	span.resetFilters{
		max-width: 122px;
		color: #D92425;
		font-size: 12px;
		cursor: pointer;

		${below.tablet`
			margin-top: 32px;
		`}

		${above.desktop`
			line-height: 45px;
		`}
	}
`;

const SelectFiltersWrapper = styled.div`
	${below.tablet`
		display: flex;
		flex-flow: column;

		> div{
			margin-right: 0;
			min-width: 100%;
			max-width: 100%;
			max-width: 100%;
		}
	`}

	${above.tablet`
		display: flex;
		flex-flow: row;
		align-items: center;

		> div {
			min-width: 120px;
			max-width: 120px;
			margin-right: 10px;
		}
	`}
`;

const SearchWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 20px 0;
`;

const StyledSearchInputWrapper = styled(SearchInputWrapper)`
	margin-right: 10px;

	${above.tablet`
		min-width: 200px;
		width: 200px;
		margin-top: 5px;
	`};	

	${below.tablet`
		min-width: auto;
		max-width: calc(100% - 38px);
	`};	
`;

const SelectWrapper = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-top: 20px;

	.changed{
		background-color: #F0F3F7;
		color: #1D4073;
	}

	${below.tablet`
		flex-wrap: wrap;
		> div:not(:first-child) {
			flex-basis: 100%;
			margin-bottom: 10px;
		}
	`};
`;

const CheckboxLabel = styled.label`
	cursor: pointer;
	height: 36px;
	width: 36px;
	border: 1px solid ${colors.primary.primary};
	background-color: #FFFFFF;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-left: 1em;
	svg {
		margin: 0 auto;
	}
`;

const HiddenCheckbox = styled.input`
	display: none;
`;

type TFilters = {
	search: string,
	by_squad: string,
	by_position: string,
	by_status: string,
	by_dpp: boolean,
	by_favourites: boolean,
	by_hide_your_players: boolean
}

type Props = {
	handleChange: Function,
	handleSubmit: Function,
	resetFilters: Function,
	onToggleOrder?: Function,
	order_by_desc?: boolean,
	is_show_drafted?: boolean,
	positions: Array<TPosition>,
	squads: Array<TSquad>,
	statuses: TPlayerStatusesReducer,
	is_hide_sort?: boolean,
	is_show_owner?: boolean,
	show_ignored?: boolean,
	match?: Object,
	onSelectChange?: Function,
	active_stats_type?: string,
	selectOptions: Array<{
		key: string,
		value: string
	}>,
	select_info?: {
		label?: string,
		title?: string,
		description?: string,
	},
	hide_stats_list?: boolean,
	league: TPLeague,
	children?: React.Node,
	filters: TFilters,
	years: number[],
	selected_by_price: string,
	handleSwitchFilterChange: Function,
	is_authorized: boolean
};



type State = {
	by_dpp: boolean,
	by_favourites: boolean,
	hideFilters: boolean,
	by_hide_your_players: boolean
};

class StatsCentreFiltersComponent extends React.PureComponent<Props, State> {
	static defaultProps = {
		order_by: true,
		is_hide_sort: true,
		is_show_owner:false,
		filters: {},
	};

	constructor(props: Props, state: State) {
		super(props, state);

		_.bindAll(this, [
			"handleSwitchChange",
			"resetStatFilters",
			"hideFilters",
			"buildFilters"
		]);
	}

	state = {
		by_dpp: false,
		by_favourites: false,
		by_hide_your_players: false,
		hideFilters: isMobile(),
	};

	handleSwitchChange({ currentTarget }: Object) {
		const {handleSwitchFilterChange} = this.props;
		const { name } = currentTarget;
		this.setState({ ...this.state, [name]: !this.state[name] });
		handleSwitchFilterChange(name, !this.state[name]);
	}

	resetStatFilters() {
		const { resetFilters } = this.props;
		this.setState({
			by_dpp: false,
			by_favourites: false,
			by_hide_your_players: false
		});
		resetFilters();
	}

	renderStatsPresetFilter(filtersChanged: Object) {
		const {
			onSelectChange,
			active_stats_type,
			selectOptions,
			hide_stats_list,
			league,
		} = this.props;

		if (hide_stats_list) {
			return null;
		}

		if(league && "custom_scoring" in league){
			selectOptions.push({ key: "custom-stats", value: "Custom Stats" });
		}

		return <div className='stats_type'>
			<Select
				name="stats_type"
				is_disabled={false}
				onChange={onSelectChange}
				value={active_stats_type}
				className={filtersChanged.active_stats_type}
			>
				{selectOptions ? selectOptions.map(({ key, value }) => (
					<option key={key} value={key}>
						{value}
					</option>
				)):null}
			</Select>
		</div>;
	}

	hideFilters(){
		this.setState({ hideFilters: !this.state.hideFilters });
	}

	buildFilters(){
		const {
			positions,
			squads,
			statuses,
			selectOptions,
			filters,
			active_stats_type,
			selected_by_price,
			years,
			is_authorized
		} = this.props;

		const {
			hideFilters
		} = this.state;

		const filtersChanged = _.cloneDeep(filters);
		filtersChanged.active_stats_type = active_stats_type;
		Object.keys(filtersChanged).forEach(i => {
			if(i === "active_stats_type"){
				filtersChanged[i] = filtersChanged[i] !== "general-stats" ? "changed" : "";
			}
			else{
				filtersChanged[i] = !["","false"].includes(filtersChanged[i]) ? "changed" : "";
			}
		});

		return (
			<Filters>
				<SelectWrapper>
					<StyledSearchInputWrapper>
						<SearchField
							type="text"
							name="search"
							component="input"
							placeholder="Search for a player"
							value={filters.search}
							className={filtersChanged.search}
						/>
						<SearchSubmitButton type="submit">
							<Search />
						</SearchSubmitButton>
					</StyledSearchInputWrapper>
					<StyledCommandButton background="none" onClick={() => this.hideFilters()}>
						{hideFilters ? <More /> : <Close className="filter" />}
					</StyledCommandButton>
					{!hideFilters && (
						<React.Fragment>
							<SelectFiltersWrapper>
								<div>
									<Select
										name="by_squad"
										is_disabled={false}
										value={filters.by_squad}
										className={filtersChanged.by_squad}
									>
										<option value="">
											All Clubs
										</option>
										{squads.map(({ id, full_name }) => (
											<option key={id} value={id}>{full_name}</option>
										))}
									</Select>
								</div>
								<div>
									<Select
										readOnly
										is_disabled={false} name="by_position"
										value={filters.by_position}
										className={filtersChanged.by_position}
									>
										<option value="">
											All Positions
										</option>
										{positions.map(({ id, full_name }) => (
											<option key={id} value={id}>{full_name}</option>
										))}
									</Select>
								</div>
								<div>
									<Select
										readOnly
										value={selected_by_price}
										is_disabled={false} name="by_price"
									>
										<option value="">
											All Prices
										</option>
										{prices.map(({ key, value }) => (
											<option key={key} value={key}>{value}</option>
										))}
									</Select>
								</div>
								<div>
									<Select
										is_disabled={false}
										name="by_status"
										value={filters.by_status}
										className={filtersChanged.by_status}
									>
										<option value="">
											All Statuses
										</option>
										{statuses ? statuses.map(({ key, value }) => (
											<option key={key} value={key}>{value}</option>
										)) : null}
									</Select>
								</div>
								<div>
									<Select name="by_year" is_disabled={false}>
										{years.map(year => (
											<option key={year} value={year}>{year}</option>
										))}
									</Select>
								</div>
								{ selectOptions && this.renderStatsPresetFilter(filtersChanged) }
								<StyledFormCircleCheckbox
									id="dpp"
									name="by_dpp"
									checked={this.state.by_dpp}		
									onChange={this.handleSwitchChange}
									label="Show DPP"
									value={this.state.by_dpp}
									isActive={this.state.by_dpp}
								/>
								{is_authorized &&
									<React.Fragment>
										<StyledFormCircleCheckbox
											id="favourites"
											name="by_favourites"
											label="Show your favourites"
											onChange={this.handleSwitchChange}
											checked={this.state.by_favourites}
											value={this.state.by_favourites}
											isActive={this.state.by_favourites}
										/>
										<StyledFormCircleCheckbox
											id="by_hide_your_players"
											name="by_hide_your_players"
											onChange={this.handleSwitchChange}
											checked={this.state.by_hide_your_players}
											label="Hide your players"
											value={this.state.by_hide_your_players}
											isActive={this.state.by_hide_your_players}
										/>
									</React.Fragment>
								}
								
							</SelectFiltersWrapper>
						</React.Fragment>
					)}
				</SelectWrapper>
				<SelectFiltersWrapper>
					<span
						role='button'
						onClick={this.resetStatFilters}
						onKeyDown={this.resetStatFilters}
						className='resetFilters'
					>
						Restore filters to default
					</span>
				</SelectFiltersWrapper>
			</Filters>
		);
	}

	render() {
		const {
			handleChange,
			handleSubmit,
			order_by_desc,
			onToggleOrder,
			is_hide_sort,
		} = this.props;

		return (
			<DraftFiltersForm onChange={handleChange} onSubmit={handleSubmit}>
				{!is_hide_sort &&<SearchWrapper>
					<div>
						<CheckboxLabel htmlFor="order_by_desc" onClick={onToggleOrder}>
							{order_by_desc ? <SortDown /> : <SortUp />}
						</CheckboxLabel>
						<HiddenCheckbox
							type="checkbox"
							checked={order_by_desc}
							value={order_by_desc ? "desc" : "asc"}
							id="order_by_desc"
							name="order_by_desc" />
					</div>
				</SearchWrapper>}
				{this.buildFilters()}
			</DraftFiltersForm>
		);
	}
}

export const StatsCentreFilters = StatsCentreFiltersComponent;

export default StatsCentreFilters;