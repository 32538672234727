import React from "react";
import { connect } from "react-redux";
import bindAll from "lodash/bindAll";
import styled from "styled-components";
import colors from "../assets/css/colors";
import CautionReset from "../components/Icons/CautionReset";

import { contactUs, contactUsClear } from "../modules/actions";

import {
	Footer,
	PageContentWrapper,
	PageTitle,
	TextModal,
	AdsContainer,
	Ad,
	TwoColumnLayout,
	StandardSidebar,
	ButtonPrimary,
} from "../components";

// import ContactUsForm from "./contactUs/contactUsForm";

const PageTitleStyled = styled(PageTitle)`
	font-size: 32px;
	display: flex;
	align-items: flex-end;

	svg{
		margin: 0 10px 8px 0;
	}
`;


const ContactUsMessage = styled.p`
	font-size: 14px;
	font-family: SourceSansPro;
	color: ${colors.primary.primary};
	margin-bottom: 20px;
	font-weight: 600;
	margin-top: 10px;
`;

const ButtonContact = styled(ButtonPrimary)`
	max-width: 330px;
`;

const FlexCol = styled.div`
	display: flex;
	flex-direction: column;
`;
type Props = {
	contactUs: typeof contactUs,
	contactUsClear: typeof contactUsClear,
	contact: {
		error: string,
		success: boolean,
	},
	location: Object
};

class ContactUsComp extends React.Component<Props> {
	constructor() {
		super();

		bindAll(this, [
			"handleSubmit",
			"handleModalClose",
		]);
	}

	get error_modal() {
		const { contact } = this.props;

		return (
			<TextModal
				onClick={this.handleModalClose}
				header_text='Error'
				text={ contact.error } />
		);
	}

	get success_modal() {
		return (
			<TextModal
				onClick={this.handleModalClose}
				header_text='Success'
				text='Thank you. Your email has been sent.' />
		);
	}

	handleModalClose() {
		const { contactUsClear } = this.props;
		contactUsClear();
	}

	handleSubmit(values) {
		const { contactUs } = this.props;
		contactUs(values);
	}

	handleOpenContactZd() {
		window.zE.activate();
	}

	get account_contact() {
		const { contact } = this.props;

		return (
			<React.Fragment>
				<ContactUsMessage>
					Can’t find what you're looking for? 
					If so you can submit a question using the link below. 
				</ContactUsMessage>
				<ButtonContact onClick={this.handleOpenContactZd}>
					Contact Us
				</ButtonContact>
				
				{ contact.error ?
					this.error_modal : null}
				{contact.success ?
					this.success_modal : null}
			</React.Fragment>
		);
	}

	render() {
		const { contact, from_account } = this.props;
		if(from_account){
			return this.account_contact;
		}
		return (
			<React.Fragment>
				<AdsContainer>
					<Ad />
				</AdsContainer>
				<PageContentWrapper>
					<PageTitleStyled>
						<CautionReset color={colors.title} size='1.3' />
							Contact Us
					</PageTitleStyled>
					<TwoColumnLayout>
						<FlexCol>
							<ContactUsMessage>
								Can’t find what you're looking for? 
								If so you can submit a question using the link below. 
							</ContactUsMessage>
							<ButtonContact onClick={this.handleOpenContactZd}>
							Contact Us
							</ButtonContact>

						</FlexCol>
						
						{/* <ContactUsForm onSubmit={this.handleSubmit} /> */}
						<StandardSidebar />
					</TwoColumnLayout>
				</PageContentWrapper>
				<Footer />
				{ contact.error ?
					this.error_modal : null}

				{contact.success ?
					this.success_modal : null}
			</React.Fragment>
		);
	}
}

const mapStateToProps = state => ({
	contact: state.contact,
});

const mapDispatchToProps = {
	contactUs,
	contactUsClear,
};

export const ContactUs = connect(
	mapStateToProps,
	mapDispatchToProps
)(ContactUsComp);

export default ContactUs;