// @flow
import styled from "styled-components";
import { Th } from "../../components/TableX";
import { default_transition } from "../../assets/css/vars";
import colors from "../../assets/css/colors";
import { below } from "../../assets/css/media";

export const ThStat = styled(Th)`
	font-size: 12px;
	position: relative;
	user-select: none;
	background: linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 100%);


	svg {
		font-size: 24px;
		position: absolute;
		left: 50%;
		margin-left: -.5em;
		z-index: 3;

		&.top {
			top: 0;
		}

		&.down {
			bottom: 0;
		}

		&.top, &.down {
			opacity: 0;
		}
	}

	.column-name {
		position: relative;
		z-index: 2;
		display: block;
		min-width: ${({ draft_order }) => draft_order ? "115px" : ""}; 

		> span:first-child {
			${below.tablet`
				display: none;
			`}
		}
		> span:last-child {
			display: none;
			${below.tablet`
				display: block;
			`}
		}
	}

	.highlighter {
		opacity: 0;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(180deg, #FFFFFF 0%, #F0F3F7 100%);
		z-index: 1;
		min-width: ${({ draft_order }) => draft_order ? "115px" : ""}; 

		${default_transition}

		&:before,
		&:after {
			position: absolute;
			left: 50%;
		}
	}

	transition: all 0.2s linear, z-index 0s linear 0.4s;

	&:hover {
		z-index: 4;
		.highlighter {
			opacity: 1;
		}
	}

	> svg {
		${default_transition};
		fill: ${colors.secondary.paleGrey};
	}

	&.sortable {
		cursor: pointer;

		&:hover > svg:not(.show) {
			&.top, &.down {
				opacity: 1;
			}
		}

		> svg.show {
			opacity: 1;
			fill: ${({ is_fc }) => is_fc ? colors.coach : colors.primary.primary};
		}
	}
	

	${below.tablet`
		.tooltip-wrapper {
			display: none;
		}
	`};
`;

export default ThStat;