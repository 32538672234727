// @flow
import styled from "styled-components";

export const ModalOverlay = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100% !important;
	height: 100% !important;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
	background: rgba(10, 34, 98, 0.72);
`;

export default ModalOverlay;