// @flow
import * as React from "react";
import styled from "styled-components";
import * as _ from "lodash";
import empty_player_src from "../../assets/images/EmptyPlayer.png";
import TableUnstyled, { Th as ThUnstyled, Tr, Td, TBody, THead } from "../TableX";
import { BenchLabel } from "../";
import { renderTableHeaderCell } from "../../helpers/tables";
import sticky_headers from "../../assets/css/sticky";
import ThStatUnstyled from "../ThStat";

const StyledTd = styled(Td)`
	img {
		display: block;
		width: auto;
	}
`;
const ThStat = styled(ThStatUnstyled)`
	${sticky_headers}
`;
const Th = styled(ThUnstyled)`
	${sticky_headers}

	&:first-child {
		margin-left: -5px;
	}
	&:last-child {
		margin-right: -5px;
	}
`;
const Table = styled(TableUnstyled)`
	border-collapse: separate;
`;


export const EmptyTeamTable = ({ empty_lineup, table_keys }: Object) => {
	const lineup_pure = _.omit(empty_lineup, ["captain", "vice_captain", "bench", "emergency"]);
	const bench = _.get(empty_lineup, "bench");

	const cells = _(table_keys)
		.map(column => ({
			table: {
				order_by_desc: false,
				sort_key: ""
			},
			cell: {
				sort_key: column.prop,
				name: column.key,
				short_name: column.key,
				rel: column.prop,
				sortable: false,
				onClick: () => null,
				...column
			}
		}))
		.map(cell => renderTableHeaderCell(cell, ThStat))
		.value();

	return <Table show_border_bottom>
		<THead>
			<Tr>
				<Th>Team</Th>
				{cells}
				<Th>Add to<br/>Trade</Th>
			</Tr>
		</THead>
		<TBody>
			{_.map(lineup_pure, line => line.map(() => (
				<Tr key={_.uniqueId()}>
					<StyledTd colSpan={table_keys.length + 2}>
						<img height="60px" width="auto"
							src={empty_player_src} alt="Placeholder player silhouette"/>
					</StyledTd>
				</Tr>
			)))}
			<Tr>
				<StyledTd colSpan={table_keys.length + 2}>
					<BenchLabel>Interchange</BenchLabel>
				</StyledTd>
			</Tr>
			{_.map(bench, () => (
				<Tr key={_.uniqueId()}>
					<StyledTd colSpan={table_keys.length + 2}>
						<img height="60px" width="auto" 
							src={empty_player_src} 
							alt="Placeholder player silhouette"/>
					</StyledTd>
				</Tr>
			))}
		</TBody>
	</Table>;
};

export default EmptyTeamTable;