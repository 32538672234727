// @flow
import styled from "styled-components";
import { below } from "../../../../assets/css/media";

export const ControlButtonsContainer = styled.div`
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding-right: 30px;
	
	button {
		margin-left: 10px;
	}
	${below.phone`
		width: 100%;
	
		align-items: center;
		justify-content: space-evenly;
		padding-right: 0px;
		button{ 
			height: 40px;
			width: 165px;
			margin-left: 0;
			padding-right: 0px;

		}
	`}
`;


export default ControlButtonsContainer;