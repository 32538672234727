// import Cookies from "js-cookie";
import _ from "lodash";
import bindAll from "lodash/bindAll";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

// import styled from "styled-components";
// import { below } from "../assets/css/media";

import {
	// Ad,
	// AdsContainer,
	// Footer,
	// PageContentWrapper,
	// PageTitle,
	// StandardSidebar,
	TextModal,
	// TwoColumnLayout,
} from "../components";

import * as actions from "../modules/actions";
import * as selectors from "../modules/selectors";

import type { TSquad, TUser } from "../modules/types";
import isAuthenticated from "../utils/auth/isAuthenticated";
// import RegistrationForm from "./registration/registrationForm";

const EMAIL_UNIQUE = {
	from: "Email must be unique",
	to: "This email address is already registered. Please go back to the homepage to login."
};


type Props = {
	createUser: typeof actions.createUser,
	fetchSquads: typeof actions.fetchSquads,
	fetchCountries: typeof actions.fetchCountries,
	user: TUser,
	squads: Array<TSquad>,
	countries: Array<Object>,
};

type State = {
	show_sign_up: boolean,
	previewImg: string,
	facebook_data: {
		email: string,
		confirm_email: string,
		firstname: string,
		lastname: string,
		token: string,
	},
}

class RegistrationPage extends React.Component<State, Props> {
	constructor() {
		super();

		bindAll(this, [
			"onChangeFile",
			"handleSubmit",
			"handleErrorModalClose",
			"responseFacebook",
		]);
	}

	state = {
		show_sign_up: false,
		previewImg: null,
		facebook_data: {
			email: null,
			confirm_email: null,
			firstname: null,
			lastname: null,
			token: null
		}
	};

	componentDidMount() {
		const { fetchSquads, fetchCountries } = this.props;
		fetchSquads();
		fetchCountries();
	}

	onChangeFile(e) {
		const
			reader = new FileReader(),
			file = e.target.files[0];

		reader.onload = e => {
			this.setState({ previewImg: e.target.result });
		};
		if(file){
			reader.readAsDataURL(file);
			this.setState({ file });
		}
		
	}

	responseFacebook({ email, first_name, last_name, accessToken }) {
		this.setState({
			facebook_data: {
				email,
				confirm_email: email,
				firstname: first_name,
				lastname: last_name,
				token: accessToken,
			}
		});
	}

	handleSubmit(values) {
		const birthday = values.birthday;
		const values_to_omit = values.token ?
			["birthday", "supported_clubs"] :
			["birthday", "supported_clubs", "token"];
		let data_to_send = _.omit({ ...values }, values_to_omit);

		if (typeof (birthday) !== "string") {
			data_to_send = {
				birthday: birthday.format("YYYY-MM-DD[T]HH:mm:ss"),
				...data_to_send
			};
		}
		else {
			data_to_send = {
				birthday: moment(birthday).format("YYYY-MM-DD[T]HH:mm:ss"),
				...data_to_send
			};
		}
		if(data_to_send.news === 1){
			data_to_send = {
				...data_to_send,
				supported_club_offers: 1
			};
		}
		const
			{ file } = this.state,
			{ createUser } = this.props,
			data = {
				...data_to_send,
				"supported_clubs[1]": [values.supported_clubs],
				avatar: file
			};

		createUser(data);
	}

	get error_modal() {
		const { user } = this.props;
		let { message } = user.login.error;
		if (message.includes(EMAIL_UNIQUE.from)) {
			message = EMAIL_UNIQUE.to;
		}

		return (
			<TextModal
				onClick={this.handleErrorModalClose}
				header_text='Error'
				text={message} />
		);
	}

	handleErrorModalClose() {
		const { clearLoginErrors } = this.props;
		clearLoginErrors();
	}

	get squadsOptions() {
		const { squads } = this.props;
		const squads_options = _.map(squads, squad => {
			return {
				value: squad.id,
				text: squad.full_name,
			};
		});

		return _.concat(squads_options, {
			value: "",
			text: "Select a team",
		});
	}

	get countriesOptions() {
		const { countries } = this.props;
		return _.map(countries, country => {
			return {
				value: country.code,
				text: country.name,
			};
		});
	}

	render() {
	
		return <Redirect to="/" />;

		// if(is_authorized) {
		// 	Cookies.remove("first_attempt_not_logged_user");
		// 	Cookies.set(
		// 		"saved_first_attempt_not_logged_user_after_registration",
		// 		pathname,
		// 		{ expires: 3 }
		// 	);
		// 	return <Redirect to="/coach-subscription" />;
		// }

		// return (
		// 	<React.Fragment>
		// 		<RegistrationWrapper>
		// 			{user.login.error ?
		// 				this.error_modal : null
		// 			}
		// 			<AdsContainer>
		// 				<Ad />
		// 			</AdsContainer>
		// 			<PageContentWrapper>
		// 				<PageTitleStyled>
		// 					Sign up
		// 				</PageTitleStyled>
		// 				<TwoColumnLayout>
		// 					<RegistrationForm
		// 						onSubmit={this.handleSubmit}
		// 						onChangeFile={this.onChangeFile}
		// 						previewImg={previewImg}
		// 						squadsOptions={this.squadsOptions}
		// 						countriesOptions={this.countriesOptions}
		// 						facebook_data={facebook_data}
		// 						responseFacebook={this.responseFacebook}
		// 					/>
		// 					<StandardSidebar />
		// 				</TwoColumnLayout>
		// 			</PageContentWrapper>
		// 		</RegistrationWrapper>
		// 		<Footer />
		// 	</React.Fragment>
		// );
	}
}

const mapStateToProps = state => ({
	is_authorized: isAuthenticated(),
	user_create: state.user,
	countries: state.countries.data,
	squads: selectors.squads.getSquads(state),
	user: state.user,
});

const mapDispatchToProps = {
	createUser: actions.createUser,
	fetchSquads: actions.fetchSquads,
	fetchCountries: actions.fetchCountries,
	clearLoginErrors: actions.clearLoginErrors,
};

export const Registration = connect(
	mapStateToProps,
	mapDispatchToProps,
)(RegistrationPage);

export default Registration;