// @flow
import * as React from "react";
import { connect } from "react-redux";
import _, { get } from "lodash";
import { compose } from "redux";
// import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Cookies from "js-cookie";
import { roundHasStarted, shouldDoubleScore } from "../../helpers/stats/displayRound";
import { getLeagueRounds } from "../../helpers/league";
import { findMatch } from "../../helpers/rounds";
import { getPartialByeRound, isAllTrue } from "../../helpers";
import { getRounds } from "../../modules/selectors/rounds";
import PartialByeDetectorControls from "../../components/ByeDetector/partialByeControls";
import type { TPosition, TRound, TUser, TPlayersArrayWithFixtureById } from "../../modules/types";
import type { ById } from "../../modules/types/byId";
import type { TClassicTeam, TPastTrade } from "../../modules/types/teamClassic";
import { convertLineupForBE } from "../../modules/sagas/teamsClassic";
import * as actions from "../../modules/actions";
import * as selectors from "../../modules/selectors";
import { isMobile } from "../../utils";

import {
	Ad,
	AdsContainer,
	ByeDetectorControls,
	ClassicUserTrades,
	Footer,
	GlobalNote,
	LeagueGameBar,
	ListBenchPlayers,
	ListRow,
	ListRowLabel,
	ListViewField,
	ListViewHeader,
	MatchCentreBar,
	PageContentWrapper,
	PoolSelect,
	Preloader,
	RoundSelector,
	TeamGameBar,
	TeamInfoBlock,
	TextModal,
	TwoColumnLayout,
	UtilityTooltip,
	withClassicPlayerModal,
	ShareBar
} from "../../components";
import EmptyMessage from "../../components/PlayersTable/emptyMessage";
import LineupSetter from "../../components/MyTeam/lineupSetter";
import JsonFetcher from "../../components/utils/JsonFetcher";
import TeamTitle from "../../components/TeamTitle";

import withCoachesBox from "../../components/utils/withCoachesBox";
import withFantasyCoachModal from "../../components/utils/withFantasyCoachModal";
import withRoundsSelector from "../../components/utils/withRoundsSelector";
import withByeDetector from "../../components/utils/withByeDetector";
import FieldPlayer from "../../components/FieldPlayer";
import EmptyPlayer from "../../components/FieldPlayer/empty";
import {
	BenchLabel,
	BenchPlayers,
	Field,
	FieldBackground,
	FieldPlayers,
	FieldRow,
	FieldRowLabel,
} from "../../components/Field";
import {
	PoolSelectWrapper,
	TeamInfoWrapper,
	MobileControlsWrapper,
	SelectsWrapper,
	TeamPanel,
	GameBarWrapper,
	PlayerPoolMobileWrapper,
	PlayerPoolWebWrapper,
	MyTeamMobileWrapper,
	FlexRow
} from "./myTeam/MyTeamStyles";


import { TeamTopControls } from "./myTeam/teamTopControls";
import { PlayersPool } from "./myTeam/playersPool";

import { MyTeamListViewPlayer } from "./myTeam/MyTeamListViewPlayer";
import { substituteIndexID } from "./myTeam/helpers";



const getFieldViewStatus = () => {
	if(localStorage.getItem("field_view")){
		return localStorage.getItem("field_view") === "true" ? true : false;
	}
	localStorage.setItem("field_view", "true");
	return true;
};

type Props = {
	team: TClassicTeam,
	current_team: TClassicTeam,
	fetchMyTeam: typeof actions.fetchMyClassicTeam,
	fetchTeam: Function,
	updateMyTeam: typeof actions.updateMyClassicTeam,
	fetchFavourites: typeof actions.fetchFavourites,
	fetchMyClassicTrades: typeof actions.fetchMyClassicTrades,
	clearErrorMyTeam: Function,
	positions: Array<TPosition>,
	players_by_id: TPlayersArrayWithFixtureById,
	has_assistant_coach: boolean,
	removePlayer: Function,
	errors: string | null,
	closeErrorModal: Function,
	actual_round: TRound,
	is_team_started: boolean,
	player_ids: Array<number>,
	selected_round: TRound,
	last_round: TRound,
	selected_round_id: number,
	setSelectedRoundId: Function,
	rounds_ordered: Array<Object>,
	showModalForPlayerId : Function,
	data_loading_complete: boolean,
	is_pending_players: boolean,
	remaining_salary: number,
	trades: Array<Object>,
	trade_history: ById<TPastTrade>,
	toggleFantasyCoachModal: Function,
	rounds_with_matches: Array<TRound>,
	is_first_round_with_partial_lockout: boolean,
	user: TUser,
	getNotificationBar: Function,
	notification_bar: { show: boolean, content: string },
	is_static: boolean,
	is_current: boolean,
	bye_rounds: number[],
	bye_detector_selected: number[],
	bye_detector_active: boolean,
	setByeDetectorActive: Function,
	toggleByeDetectorRound: Function,
	fetchTradeHistory: Function,
	game_bar_statistic: Array<Object>,
	rounds_by_id: Object,
	is_partial_or_standard: string,
	setPartialActive: Function,
	player_pool_statistic: Array<Object>,
	rounds: TRound[]
}

type State = {
	is_flipped: boolean,
	is_type_field_list: boolean,
	selected_position: string,
	substitute_id: number,
	substitute_position: number,
	substitute_player_positions: Array<number>,
	substitute_is_bench: boolean,
	substitute_is_utility: boolean,
	player_points: string,
	mobile_stat: string,
	is_mobile: boolean,
	show_player_pool: boolean,
	preloader: boolean,
	is_show_utility_tooltip: boolean,
	selected_utility_position: boolean
}
class MyTeamComponent extends React.Component<Props, State> {

	static defaultProps = {
		errors: null
	};

	constructor(props, state) {
		super(props, state);

		_.bindAll(this, [
			"getFieldRow",
			"getRowPlayers",
			"handleFlip",
			"handleViewType",
			"removePlayer",
			"changePositionFilter",
			"getListFieldRow",
			"getListViewPlayersByPosition",
			"setCaptain",
			"setViceCaptain",
			"setEmergency",
			"closeErrorModal",
			"checkIsPlayerChangeAvailable",
			"substitutePlayer",
			"pureSubstitutePlayer",
			"getFieldPlayer",
			"inSubstitute",
			"findLeader",
			"tryToSubstitute",
			"swapPlayersOnField",
			"cancelSubstitute",
			"onGameBarSelectChange",
			"onMobileStatSelectChange",
			"resize",
			"onTogglePlayerPool",
			"onHidePlayerPool",
			"openPopup",
			"swapWithEmptyPlayer",
			"closeUtilityTooltip",
			"setPosition",
			"pureSwapWithEmptyPlayer",
		]);

		this.onShowPlayerPool = this.onTogglePlayerPool.bind(this, true);
	}

	state = {
		is_flipped: false,
		is_type_field_list: getFieldViewStatus(),
		selected_position: "",
		substitute_id: 0,
		substitute_position: 0,
		substitute_player_positions: [],
		substitute_is_bench: false,
		substitute_is_utility: false,
		player_points: roundHasStarted(this.props.selected_round)
			? "stats.round_score"
			: "stats.proj_score",
		mobile_stat: _.get(_.first(this.pool_stats), "key"),
		is_mobile: false,
		show_player_pool: false,
		preloader: true,
		is_show_utility_tooltip: !Boolean(Cookies.get("is_close_tooltip")),
		selected_utility_position: false
	};

	componentDidMount(): void {
		const {
			fetchFavourites,
			fetchMyClassicTrades,
			fetchTradeHistory,
			getNotificationBar,
		} = this.props;
		this.fetchTeam();
		fetchFavourites();
		fetchMyClassicTrades();
		fetchTradeHistory();
		window.addEventListener("resize", this.resize);
		this.resize();
		getNotificationBar();
		if (this.props.data_loading_complete) {
			this.setState({ preloader: false });
		}
	}

	shouldComponentUpdate(next_props) {
		return next_props.data_loading_complete;
	}

	componentDidUpdate(prev_props) {
		if(!prev_props.data_loading_complete && this.props.data_loading_complete) {
			this.setState({
				player_points: roundHasStarted(this.props.selected_round)
					? "stats.round_score"
					: "stats.proj_score",
				mobile_stat: _.get(_.first(this.pool_stats), "key"),
				preloader: false,
			});
		}
		if (prev_props.selected_round_id !== this.props.selected_round_id) {
			this.fetchTeam();
			this.setState({
				show_player_pool: false
			});
		}
		if (isAllTrue([
			!prev_props.bye_detector_active,
			this.props.bye_detector_active,
			this.props.is_partial_or_standard === "partial"
		])) {
			this.setState({
				mobile_stat: "partial_bye_round"
			});
		}
		if (isAllTrue([
			!prev_props.bye_detector_active,
			this.props.bye_detector_active,
			this.props.is_partial_or_standard === "standard"
		])) {
			this.setState({
				mobile_stat: "stats.bye_round_id"
			});
		}
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.resize);
	}

	fetchTeam() {
		const {
			fetchMyTeam,
			fetchTeam,
			current_team,
			is_static,
			selected_round_id
		} = this.props;

		if (is_static) {
			fetchTeam({
				id: current_team.id,
				round: selected_round_id
			});
			this.setState({
				player_points: "stats.round_score"
			});
		}
		else {
			fetchMyTeam();
		}
	}

	onShowPlayerPool: Function;

	onHidePlayerPool() {
		this.onTogglePlayerPool(false);
		this.changePositionFilter({ id: "", is_utility: false });
	}

	resize() {
		this.setState({
			is_mobile: isMobile()
		});
	}

	closeUtilityTooltip() {
		Cookies.set("is_close_tooltip", true);
		this.setState({ is_show_utility_tooltip: false });
	}

	//Blocks
	get team_field() {
		const { positions } = this.props;
		return positions.map(this.getFieldRow);
	}

	get utility_field() {
		const { team: { lineup = {} } } = this.props;
		const { substitute_position, is_show_utility_tooltip } = this.state;
		const { bench } = lineup;

		return (
			<FieldRow height={"135px"}>
				<FieldRowLabel>
					<div>
						Utility
					</div>
				</FieldRowLabel>
				<BenchPlayers>
					{this.getRowPlayers({
						player_id: _.get(bench, "utility.id", 0),
						id: substitute_position || _.get(bench, "utility.position", 0),
						name: "Utility",
						is_bench: true,
						index: 1,
						is_utility: true
					})}
				</BenchPlayers>
				<UtilityTooltip
					is_show={is_show_utility_tooltip}
					onClick={this.closeUtilityTooltip}
				/>
			</FieldRow>
		);
	}

	get list_view_field() {
		const { positions } = this.props;
		return positions.map(this.getListFieldRow);

	}

	get list_utility_view_player() {
		const
			{ team: { lineup = {} } } = this.props,
			{ bench = {}, captain, vice_captain } = lineup,
			{ emergency = [] } = bench;

		const { substitute_position } = this.state;
		const id = substitute_position || _.get(bench, "utility.position", 0);

		if (_.isEmpty(lineup)) {
			return null;
		}

		return (
			<ListRow>
				<ListRowLabel>
					Utility
				</ListRowLabel>
				<ListBenchPlayers>
					{this.getListViewPlayersByPosition(
						{
							player_id: _.get(bench, "utility.id", 0),
							id,
							name: "Utility",
							is_bench: true,
							captain,
							vice_captain,
							emergency,
							is_utility: true,
							utility_position:  _.get(bench, "utility.position", "")
						},
						1
					)}
				</ListBenchPlayers>
			</ListRow>
		);
	}

	getListFieldRow({ id, full_name, name }) {
		const
			{ team: { lineup = {} } } = this.props,
			{ bench = {}, captain, vice_captain } = lineup,
			{ emergency = [] } = bench;

		const is_utility = false;

		if (_.isEmpty(lineup)) {
			return null;
		}

		const position_line = _.get(lineup, id, []);
		const position_bench = _.get(bench, id, []);

		return (
			<ListRow key={id}>
				<ListRowLabel>
					{full_name}
				</ListRowLabel>
				{_.map(position_line, (player_id, index) => this.getListViewPlayersByPosition(
					{
						player_id,
						id, name,
						is_bench: false,
						captain,
						vice_captain,
						emergency,
						is_utility,
						utility_position: ""
					},
					index
				))}
				<ListBenchPlayers>
					{_.map(position_bench, (player_id, index) => this.getListViewPlayersByPosition(
						{
							player_id,
							id,
							name,
							is_bench: true,
							captain,
							vice_captain,
							emergency,
							is_utility,
							utility_position: ""
						},
						index
					))}
				</ListBenchPlayers>
			</ListRow>
		);
	}


	getListViewPlayersByPosition(params, index) {
		if(_.isEmpty(params)){
			return null;
		}
		const {players_by_id,
			has_assistant_coach,
			is_team_started,
			team,
			selected_round,
			is_first_round_with_partial_lockout,
			is_static,
			is_current,
			bye_detector_active,
			bye_detector_selected,
			actual_round,
			is_partial_or_standard,
			rounds,
			last_round
		} = this.props;
		const set_props = {
			players_by_id,
			has_assistant_coach,
			is_team_started,
			team,
			selected_round,
			is_first_round_with_partial_lockout,
			is_static,
			is_current,
			bye_detector_active,
			rounds,
			is_partial_or_standard: is_partial_or_standard,
			bye_detector_selected,
			actual_round,
			game_bar_stats_value: this.game_bar_stats_value,
			locked_personnel: this.locked_personnel,
			is_mobile: this.state.is_mobile,
			mobile_stat: this.mobile_stat,
			substitute_id: this.state.substitute_id,
			substitute_is_utility: this.state.substitute_is_utility,
			substitute_player_positions: this.state.substitute_player_positions,
			substitute_position: this.state.substitute_position,
			last_round,
			player: players_by_id[params.player_id]
		};
		const functions = {
			inSubstitute: this.inSubstitute,
			swapWithEmptyPlayer: this.swapWithEmptyPlayer,
			pureSwapWithEmptyPlayer: this.pureSwapWithEmptyPlayer,
			changePositionFilter: this.changePositionFilter,
			onShowPlayerPool: this.onShowPlayerPool,
			checkIsPlayerChangeAvailable: this.checkIsPlayerChangeAvailable,
			removePlayer: this.removePlayer,
			setCaptain: this.setCaptain,
			setViceCaptain: this.setViceCaptain,
			setEmergency: this.setEmergency,
			substitutePlayer: this.substitutePlayer,
			pureSubstitutePlayer: this.pureSubstitutePlayer,
			cancelSubstitute: this.cancelSubstitute,
			openPopup: this.openPopup,

		};
		return <MyTeamListViewPlayer 
			key={`${index}-player`}
			params={params}
			index={index}
			{...set_props}
			{...functions}
		/>;
	}

	openPopup(player_id) {
		const { showModalForPlayerId } = this.props;
		showModalForPlayerId(player_id);
	}

	getFieldRow({ id, full_name, name }) {
		const { team: { lineup = {} } } = this.props;
		const { bench } = lineup;
		const is_utility = false;

		if (_.isEmpty(lineup)) {
			return null;
		}

		const position_line = _.get(lineup, id, []);
		const position_bench = _.get(bench, id, []);

		const is_mids = id === 2;

		return (
			<FieldRow key={id} height={full_name === "Rucks" ? "100px" : "250px"}>
				<FieldRowLabel>
					<div>
						{full_name}
					</div>
				</FieldRowLabel>
				<FieldPlayers
					widthProp={is_mids ? 80 : 73}
					paddingProp={is_mids ? 0 : 7}
					mids={is_mids}
				>
					{_.map(position_line, (player_id, index) => this.getRowPlayers(
						{ player_id, id, name, is_bench: false, index, is_utility }
					))}
				</FieldPlayers>
				<BenchPlayers>
					{_.map(position_bench, (player_id, index) => this.getRowPlayers(
						{ player_id, id, name, is_bench: true, index, is_utility }
					))}
				</BenchPlayers>
			</FieldRow>
		);
	}

	getRowPlayers({ player_id, id, name, is_bench, index, is_utility = false }) {
		const { players_by_id } = this.props;
		const {
			substitute_id, substitute_player_positions
		} = this.state;
		const is_subs = substitute_id !== 0;
		const out_position = substitute_player_positions.includes(id);
		const in_substitute = this.inSubstitute(is_subs, false, true, out_position);
		const player = get(players_by_id, player_id);

		return (isAllTrue([
			Boolean(player),
			player_id,
			!_.isEmpty(players_by_id)
		]))? (
				this.getFieldPlayer({ player_id, id, is_bench, is_utility })
			) : (
				<EmptyPlayer
					substitutePlayer={this.swapWithEmptyPlayer}
					pureSubstitutePlayer={this.pureSwapWithEmptyPlayer}
					key={index}
					pos_name={name}
					pos_id={id}
					is_bench={is_bench}
					is_utility={is_utility}
					index={index}
					onClick={() => this.changePositionFilter({ id, is_utility })}
					in_substitute={Boolean(in_substitute)}
				/>
			);
	}

	getRoundForScore() {
		const { selected_round, last_round } = this.props;
		const {player_points} = this.state;
		const isPointsLastRound = player_points === "stats.points_last_round";
		return isPointsLastRound ? last_round : selected_round;
	}

	// eslint-disable-next-line complexity
	getFieldPlayer({ player_id, id, is_bench, is_utility }) {
		const {
			players_by_id, team: { lineup = {} },
			showModalForPlayerId,
			selected_round,
			has_assistant_coach,
			is_team_started,
			toggleFantasyCoachModal,
			is_first_round_with_partial_lockout,
			is_static,
			is_current,
			rounds,
			bye_detector_active,
			bye_detector_selected,
			actual_round,
			is_partial_or_standard
		} = this.props;


		const {
			is_flipped,
			substitute_id,
			substitute_position,
			substitute_player_positions,
			substitute_is_utility
		} = this.state;
		const player = players_by_id[player_id];

		const {
			captain = 0,
			vice_captain = 0,
			bench: { emergency = [], utility = {} }
		} = lineup;

		const is_substitute_case =
			substitute_is_utility && substitute_player_positions.find(
				position => player.positions.includes(position)
			);

		const is_emergency = emergency.includes(player_id);
		const is_locked = !this.checkIsPlayerChangeAvailable(player_id);
		const is_subs = substitute_id !== 0;
		const out_substitute = is_subs && substitute_id === player_id;
		const in_position = player.positions.includes(substitute_position) || is_substitute_case;
		const out_position = substitute_player_positions.includes(id);
		const in_substitute = this.inSubstitute(is_subs, is_locked, in_position, out_position);
		const roundForPoints = this.getRoundForScore();
		const double_score = shouldDoubleScore(
			player,
			lineup,
			roundForPoints,
			players_by_id[captain]
		);
		if([290528, 297373].includes(player_id)){
			console.log(player_id);
			console.log(double_score, "DOUBLE");
		}
		

		const utility_position = parseInt(_.get(utility, "position", 0), 0);
		const player_bye = _.get(player, "stats.bye_round_id");
		const squadId = _.get(player, 'squad.id', 0);
		const player_partial_bye = getPartialByeRound(squadId, rounds);

		return (
			<FieldPlayer
				is_locked={is_locked || is_static}
				fantasyCoachModal={toggleFantasyCoachModal}
				key={player_id}
				id={player_id}
				flip={is_flipped || in_substitute || out_substitute}
				player={player}
				is_bench={is_bench}
				captain={captain}
				is_partial_or_standard={is_partial_or_standard}
				vice_captain={vice_captain}
				is_emergency={is_emergency}
				position={id}
				removePlayer={this.removePlayer}
				setCaptain={this.setCaptain}
				setViceCaptain={this.setViceCaptain}
				setEmergency={this.setEmergency}
				setPosition={this.setPosition}
				substitutePlayer={this.substitutePlayer}
				pureSubstitutePlayer={this.pureSubstitutePlayer}
				cancelSubstitute={this.cancelSubstitute}
				out_substitute={out_substitute}
				in_substitute={Boolean(in_substitute)}
				is_substitute={is_subs}
				player_points={this.game_bar_stats_value}
				is_team_started={is_team_started}
				showModalForPlayerId={showModalForPlayerId}
				selected_round={selected_round}
				has_assistant_coach={has_assistant_coach}
				double_score={double_score}
				is_first_round_with_partial_lockout={is_first_round_with_partial_lockout}
				is_static={is_static}
				is_current={is_current}
				bye_detector_active={bye_detector_active}
				bye_detector_selected={bye_detector_selected}
				player_bye={player_bye}
				player_partial_bye={player_partial_bye}
				locked_personnel={this.locked_personnel}
				is_utility={is_utility}
				utility_position={utility_position}
				actual_round={actual_round}
			/>
		);
	}

	/*
	 * Returns a list of the different personnel who are locked
	 * i.e. [ 'captain', 'vice_captain']
	 */
	get locked_personnel() {
		const { team: { lineup }, players_by_id } = this.props;

		// Checks if the player at the given path is locked
		const getPersonnelLocked = path => (
			Boolean(_.get(players_by_id, [_.get(lineup, path), "locked"], false))
		);

		const personnel_paths = [ "captain", "vice_captain" ];

		return _(personnel_paths)
			.map(path => {
				const locked = getPersonnelLocked(path);

				return { path, locked };
			})
			.filter(({ locked }) => locked)
			.map(({ path }) => path)
			.value();
	}

	get error_modal() {
		const { errors } = this.props;

		return errors ? (
			<TextModal onClick={this.closeErrorModal} text={errors} header_text="Error" />
		) : null;
	}

	get score_stat() {
		const { game_bar_statistic } = this.props;
		const { player_points } = this.state;
		return _.find(game_bar_statistic, { key: player_points });
	}

	get mobile_stat() {
		const { player_pool_statistic } = this.props;
		const { mobile_stat } = this.state;
		return _.find(player_pool_statistic, { key: mobile_stat });
	}

	get game_bar_stats_value() {
		const { player_points } = this.state;
		if (!_.isEmpty(player_points)) {
			return player_points;
		}
		return "";
	}

	get trades() {
		const {
			selected_round_id,
			trade_history,
			is_static,
			trades
		} = this.props;

		if (!is_static) {
			return trades;
		}

		return _.get(trade_history, selected_round_id, []);
	}

	get pool_stats() {
		const { game_bar_statistic, player_pool_statistic } = this.props;
		const game_bar_keys = _.map(game_bar_statistic, "key");

		return _.filter(player_pool_statistic, stat => {
			return !_.includes(game_bar_keys, stat.key);
		});
	}

	//Handlers
	handleFlip(is_flipped) {
		this.setState({
			is_flipped
		});
	}

	handleViewType() {
		const { is_type_field_list } = this.state;
		const storageItem = String(!is_type_field_list);
		localStorage.setItem("field_view", storageItem);
		this.setState({
			is_type_field_list: !is_type_field_list
		});
	}

	changePositionFilter({ id, is_utility }) {
		this.setState({
			selected_position: is_utility ? "" : String(id),
			selected_utility_position: is_utility
		});
	}

	removePlayer(params) {
		const { removePlayer } = this.props;
		removePlayer(params);
	}

	setCaptain(event: Object) {
		const { team, updateMyTeam } = this.props;
		const { checkIsPlayerChangeAvailable } = this;
		LineupSetter.setCaptain({
			event,
			team,
			updateMyTeam,
			checkIsPlayerChangeAvailable
		});
	}

	setViceCaptain(event: Object) {
		const { team, updateMyTeam } = this.props;
		const { checkIsPlayerChangeAvailable } = this;
		LineupSetter.setViceCaptain({
			event,
			team,
			updateMyTeam,
			checkIsPlayerChangeAvailable
		});
	}
	setEmergency(event: Object) {
		const { team, updateMyTeam } = this.props;
		const { checkIsPlayerChangeAvailable } = this;
		LineupSetter.setEmergencyClassic({
			event,
			team,
			updateMyTeam,
			checkIsPlayerChangeAvailable
		});
	}

	setPosition(event: Object) {
		const { currentTarget } = event;
		const { is_this_is_position, position_id } = currentTarget.dataset;
		const curent_position = JSON.parse(is_this_is_position);

		if (curent_position) {
			return null;
		}

		const {
			team: {
				lineup: old_lineup
			},
			updateMyTeam
		} = this.props;

		const current_lineup = _.cloneDeep(old_lineup);
		const utility_position = _.get(current_lineup, "bench.utility.position", 0);

		if (utility_position && utility_position !== position_id) {
			current_lineup.bench.utility.position = position_id;
		}

		const { lineup, formation } = convertLineupForBE(current_lineup);

		updateMyTeam({
			old_lineup,
			lineup,
			formation
		});
	}

	inSubstitute(is_subs, is_locked, in_position, out_position) {
		return is_subs && !is_locked && in_position && out_position;
	}

	substitutePlayer({ currentTarget }) {
		const { id, position, is_bench, is_utility } = currentTarget.dataset;
		this.pureSubstitutePlayer({ id, position, is_bench, is_utility });
	}

	pureSubstitutePlayer({ id, position, is_bench, is_utility }) {
		const { players_by_id } = this.props;
		const int_id = parseInt(id, 10);
		const int_position = parseInt(position, 10);
		const bool_is_bench = JSON.parse(is_bench);

		const bool_is_utility = JSON.parse(is_utility);
		const player = players_by_id[int_id];
		const { substitute_id, substitute_position } = this.state;

		if (substitute_id === int_id) {
			return this.cancelSubstitute();
		}
		if (substitute_id) {
			return this.tryToSubstitute({
				player_in: { id: int_id, position: int_position, is_bench: bool_is_bench },
				player_out: {
					id: substitute_id, position: substitute_position, is_bench: true
				}
			});
		}

		this.setState({
			substitute_id: int_id,
			substitute_position: int_position,
			substitute_player_positions: player.positions,
			substitute_is_bench: bool_is_bench,
			substitute_is_utility: bool_is_utility,
		});
	}
	findLeader(id) {
		const { team: { lineup: { captain, vice_captain } } } = this.props;
		return _.findKey({ captain, vice_captain }, player_id => player_id === id);
	}
	tryToSubstitute({ player_in, player_out }) {
		const { team, updateMyTeam } = this.props;
		const { lineup } = team;
		const { bench } = lineup;
		const { emergency } = bench;
		const what_leader_out = this.findLeader(player_out.id);
		const what_leader_in = this.findLeader(player_in.id);

		// If swap utility player
		if (lineup.bench.utility.id === player_in.id || lineup.bench.utility.id === player_out.id) {
			// Change utility position on any swap player position
			lineup.bench.utility.position = player_in.position.toString();
		}

		const { lineup: updated_ineup, formation } = convertLineupForBE(lineup);

		let new_lineup = {
			...updated_ineup,
			...this.swapPlayersOnField({ player_in, player_out })
		};

		new_lineup = {
			...new_lineup,
			bench: {
				...new_lineup.bench,
				emergency: this.swapEmergency(emergency, player_out.id, player_in.id)
			}
		};
		if (what_leader_out && player_in.is_bench) {
			new_lineup = {
				...new_lineup,
				[what_leader_out]: player_in.id
			};
		}
		if (what_leader_in && player_out.is_bench) {
			new_lineup = {
				...new_lineup,
				[what_leader_in]: player_out.id
			};
		}

		updateMyTeam({
			old_lineup: lineup,
			lineup: new_lineup,
			formation
		});

		this.cancelSubstitute();
	}

	swapPlayersOnField({ player_in, player_out }) {
		const { team } = this.props;
		const { lineup: updated_ineup } = team;
		const { lineup } = convertLineupForBE(updated_ineup);
		const { bench } = lineup;
		const players_id = [player_in.id, player_out.id];
		const swapPlayersOnFound = player_id => {
			let found = players_id.includes(player_id),
				inverted_index = players_id.indexOf(player_id) ^ 1;

			return found ? players_id[inverted_index] : player_id;
		};

		const new_lineup = _.chain(lineup)
			.omit(["captain", "vice_captain", "bench"])
			.mapValues(value => value.map(swapPlayersOnFound))
			.value();
		const new_bench = _.chain(bench)
			.omit(["emergency"])
			.mapValues(value => value.map(swapPlayersOnFound))
			.value();

		return {
			...new_lineup,
			bench: { ...bench, ...new_bench }
		};
	}

	swapEmergency(emergency, player_out_id, player_in_id) {
		const out_index = emergency.indexOf(player_out_id);
		const in_index = emergency.indexOf(player_in_id);
		if (~out_index) {
			emergency[out_index] = player_in_id;
		}
		if (~in_index) {
			emergency[in_index] = player_out_id;
		}

		return emergency;
	}

	getCorrectSubstitutePosition({ substitute_position, position }) {
		return substitute_position || position;
	}

	swapWithEmptyPlayer({ currentTarget }) {
		const { position, index, is_bench, is_utility } = currentTarget.dataset;
		this.pureSwapWithEmptyPlayer({ position, index, is_bench, is_utility });
	}

	pureSwapWithEmptyPlayer({ position, index, is_bench, is_utility }) {
		const { team, updateMyTeam } = this.props;
		const int_index = parseInt(index, 10);
		const bool_is_bench = JSON.parse(is_bench);
		const { substitute_id, substitute_position, substitute_is_bench } = this.state;
		const { lineup } = team;
		const { bench } = lineup;
		let { emergency } = bench;
		const what_leader_in = this.findLeader(substitute_id);
		const chack_is_utility = JSON.parse(is_utility);
		const correct_substitute_position =
			this.getCorrectSubstitutePosition({ substitute_position, position });

		let new_lineup = {
			...lineup
		};

		if (new_lineup.bench.utility.id === substitute_id) {
			new_lineup.bench.utility = {
				id: 0,
				position: "2",
			};
		}

		if (substitute_is_bench) {
			const line = new_lineup.bench[correct_substitute_position].map(id => {
				return substitute_id === id ? 0 : id;
			});

			new_lineup = {
				...new_lineup,
				bench: {
					...new_lineup.bench,
					[correct_substitute_position]: line
				}
			};
		}
		else {
			const line = new_lineup[correct_substitute_position].map(id => {
				return substitute_id === id ? 0 : id;
			});
			new_lineup = {
				...new_lineup,
				[correct_substitute_position]: line
			};
		}

		if (bool_is_bench) {
			let line = [];

			if (chack_is_utility) {
				new_lineup.bench.utility = {
					id: substitute_id,
					position
				};
			}
			else {
				line = new_lineup.bench[position].map((id, index) => {
					return index === int_index ? substitute_id : id;
				});

				new_lineup = {
					...new_lineup,
					bench: {
						...new_lineup.bench,
						[position]: line
					}
				};
				
			}
			if (what_leader_in) {
				new_lineup = {
					...new_lineup,
					[what_leader_in]: 0
				};
			}
		}
		else {
			const line = new_lineup[position].map((id, index) => {
				return substituteIndexID(index, int_index, substitute_id, id);
			});
			if (emergency.includes(substitute_id)) {
				emergency = emergency.map(id => id === substitute_id ? 0 : id);
			}
			new_lineup = {
				...new_lineup,
				[position]: line,
				bench: {
					...new_lineup.bench,
					emergency: [ ...emergency ]
				}
			};
		}
		const { lineup: new_updated_lineup, formation } = convertLineupForBE(new_lineup);
		// let length = 0;
		// ['1', '2', '3', '4'].forEach(position_key => {
		// 	new_lineup[position_key].forEach(player_id => {		
		// 		if(player_id !== 0){
		// 			length += 1;
		// 		}
		// 	});
		// });
 		// ['1', '2', '3', '4'].forEach(position_key => {
		// 	new_lineup.bench[position_key].forEach(player_id => {
		// 		if(player_id !== 0){
		// 			length += 1;
		// 		}
		// 	});
		// });
		// if(new_lineup.bench['utility']['id'] !== 0){
		// 	length +=1;
		// }
		// if(length){ // Keep eye on this, was length === 30;
		// 	updateMyTeam({
		// 		old_lineup: lineup,
		// 		lineup: new_updated_lineup,
		// 		formation
		// 	});
		// }
		updateMyTeam({
			old_lineup: lineup,
			lineup: new_updated_lineup,
			formation
		});

		this.cancelSubstitute();
	}

	cancelSubstitute() {
		const { substitute_id } = this.state;
		if (substitute_id) {
			this.setState({
				substitute_id: 0,
				substitute_position: 0,
				substitute_player_positions: [],
				substitute_is_bench: false,
				substitute_is_utility: false,
			});
		}
	}

	renderScoreSelect() {
		const {
			game_bar_statistic,
			is_static
		} = this.props;

		const { is_mobile } = this.state;

		if (!is_static || is_mobile) {
			return <PoolSelectWrapper is_mobile={is_mobile}>
				<PoolSelect
					name="field_statistic"
					label="Scoring"
					description={"Scoring stat that will be shown"}
					onChange={this.onGameBarSelectChange}
					value={this.game_bar_stats_value}
					show_on_right={true}
					isShort={true}
					half_margin
				>
					{game_bar_statistic.map(({ key, value }) => (
						<option value={key} key={key}>{value}</option>
					))}
				</PoolSelect>
			</PoolSelectWrapper>;
		}

		return null;
	}

	renderStatisticSelect() {
		const {
			is_static,
		} = this.props;

		const { is_mobile, is_type_field_list, mobile_stat } = this.state;

		const hide_stats_select = _.some([
			is_type_field_list,
			is_static
		], _.identity);

		if (!hide_stats_select || is_mobile) {
			return <PoolSelectWrapper is_mobile={is_mobile}>
				<PoolSelect
					name="mobile_statistic"
					label="Statistic"
					description={"Stat that will be shown"}
					onChange={this.onMobileStatSelectChange}
					value={mobile_stat}
					half_margin
				>
					{_.map(this.pool_stats, ({ key, value }) => (
						<option value={key} key={key}>{value}</option>
					))}
				</PoolSelect>
			</PoolSelectWrapper>;
		}

		return null;
	}

	renderTradesView() {
		const { is_static, selected_round, selected_round_id } = this.props;

		const trades = this.trades;

		if ( _.size(trades) === 0) {
			return (is_static && selected_round_id !== 1) ? <EmptyMessage>
				You did not make any trades this round.
			</EmptyMessage> : null;
		}

		return <ClassicUserTrades
			trades={trades}
			short_names
			is_static={is_static}
			selected_round={selected_round}
		/>;
	}

	checkIsPlayerChangeAvailable(player_id) {
		const { actual_round, is_team_started, players_by_id } = this.props;
		const player = players_by_id[player_id];
		const squad_id = _.get(player, "squad_id");
		const matches = _.get(actual_round, "matches", []);
		const { status, lockout } = actual_round;
		const is_active = status !== "scheduled";

		const match = findMatch(matches, squad_id);
		const match_status = _.get(match, "status");
		const player_available = !match || match_status === "scheduled";

		if (!is_team_started) {
			if (lockout === "partial") {
				return player_available;
			}
			return true;
		}

		/**
		 * If the round has started, players can only be changed
		 * if they aren't locked and lockout is not yet full
		 */
		if (is_active) {
			if (lockout === "full") {
				return false;
			}
			else if (!player) {
				return true;
			}
			else {
				return player_available;
			}
		}

		return true;
	}

	onMobileStatSelectChange({ currentTarget }: Object) {
		const mobile_stat = currentTarget.value;
		if (mobile_stat) {
			this.setState({
				mobile_stat
			});
		}
	}

	onGameBarSelectChange(event: Object) {
		const player_points = event.currentTarget.value;
		if (player_points) {
			this.setState({
				player_points
			});
		}
	}

	closeErrorModal() {
		this.props.clearErrorMyTeam();
	}

	get field() {
		const {
			player_ids,
			is_static,
			bye_detector_active
		} = this.props;
		const { is_type_field_list, is_mobile, substitute_id } = this.state;
		const is_list_view = is_type_field_list === false || is_mobile;

		if(is_list_view) {

			return (
				<ListViewField>
					<ListViewHeader
						player_ids={player_ids}
						mobile_stat={this.mobile_stat}
						score_stat={this.score_stat}
					/>
					{ this.list_view_field }
					{ this.list_utility_view_player }
				</ListViewField>
			);
		}

		return (
			<Field
				onClick={this.cancelSubstitute}
				is_substitute={substitute_id !== 0}
				is_locked={is_static || bye_detector_active}
			>
				<FieldBackground is_locked={is_static || bye_detector_active} />
				{this.team_field}
				{this.utility_field}
				<BenchLabel>
					<div>INTERCHANGE</div>
				</BenchLabel>
			</Field>
		);
	}

	renderByeDetector() {
		const {
			is_static,
			has_assistant_coach,
			bye_detector_active,
			bye_detector_selected,
			bye_rounds,
			setByeDetectorActive,
			toggleByeDetectorRound,
			toggleFantasyCoachModal,
			is_partial_or_standard,
		} = this.props;
		return !is_static && <ByeDetectorControls
			bye_rounds={bye_rounds}
			selected={bye_detector_selected}
			setActive={setByeDetectorActive}
			active={bye_detector_active}
			is_partial_or_standard={is_partial_or_standard}
			has_coach={has_assistant_coach}
			toggleRound={toggleByeDetectorRound}
			showFantasyCoachModal={toggleFantasyCoachModal}
		/>;
	}

	renderPartialByeDetector() {
		const {
			is_static,
			has_assistant_coach,
			bye_detector_active,
			bye_detector_selected,
			bye_rounds,
			toggleByeDetectorRound,
			toggleFantasyCoachModal,
			is_partial_or_standard,
			setPartialActive
		} = this.props;
		return !is_static && <PartialByeDetectorControls
			bye_rounds={bye_rounds}
			selected={bye_detector_selected}
			setActive={setPartialActive}
			active={bye_detector_active}
			setPartialActive={setPartialActive}
			is_partial_or_standard={is_partial_or_standard}
			has_coach={has_assistant_coach}
			toggleRound={toggleByeDetectorRound}
			showFantasyCoachModal={toggleFantasyCoachModal}
		/>;
	}

	renderTeamInfo() {
		const {
			team = {},
			positions,
			is_static,
		} = this.props;

		return !is_static && <TeamInfoWrapper id="TeamInfoWrapper">
			<TeamInfoBlock
				lineup={team.lineup}
				positions={positions}
				short_name
			/>
		</TeamInfoWrapper>;
	}

	onTogglePlayerPool(state) {
		this.setState({
			show_player_pool: _.isUndefined(state) ? !this.state.show_player_pool : state
		});
	}

	get is_team_complete_and_scored() {
		const { 
			team, 
			last_round, 
			selected_round_id, 
			selected_round
		} = this.props;
		const teamComplete = Boolean(team.complete);
		const is_scheduled = _.get(selected_round, "status", "scheduled") === "scheduled";
		const roundID = is_scheduled ? _.get(last_round, "id", 1) : selected_round_id;
		const isScoreReady = _.get(team, `scoreflow[${roundID}]`);
		return teamComplete && isScoreReady;
	}

	get main_content() {
		const {
			players_by_id, team,
			rounds_with_matches,
			is_pending_players, selected_round,
			has_assistant_coach, is_team_started,
			remaining_salary, data_loading_complete,
			is_static,
			actual_round,
			bye_detector_selected,
			bye_detector_active,
			is_current,
			is_partial_or_standard

		} = this.props;

		const {
			selected_position,
			is_type_field_list,
			is_mobile,
			show_player_pool,
			player_points,
			mobile_stat,
			selected_utility_position
		 } = this.state;

		const selected_round_id = _.get(selected_round, "id", 0);

		const team_top_controls = <TeamTopControls
			onSwitchPlayerPool={this.onShowPlayerPool}
			handleViewType={this.handleViewType}
			handleFlip={this.handleFlip}
			is_type_field_list={is_type_field_list}
			is_mobile={is_mobile}
			team={team}
			is_team_started={is_team_started}
			trades={this.trades}
			is_static={is_static}
			is_classic={true}
		/>;

		const game_bar = <TeamGameBar
			player_points={this.game_bar_stats_value}
			is_type_field_list={is_type_field_list}
			selected_round_id={selected_round_id}
			is_mobile={is_mobile}
			players_by_id={players_by_id}
			remaining_salary={remaining_salary}
			team={team}
			is_team_started={is_team_started}
			has_assistant_coach={has_assistant_coach}
			data_loading_complete={data_loading_complete}
			rounds_with_matches={rounds_with_matches}
			show_last_round_score={player_points === "stats.points_last_round"}
			is_static={is_static}
			is_current_round={is_current}
		/>;

		const getPlayersPool = (props = {}) => (
			<PlayersPool
				team={team}
				is_pending_players={is_pending_players}
				actual_round={actual_round}
				has_assistant_coach={has_assistant_coach}
				is_team_started={is_team_started}
				onSwitchPlayerPool={this.onHidePlayerPool}
				selected_position={selected_position}
				selected_utility_position={selected_utility_position}
				is_type_field_list={is_type_field_list}
				is_mobile={is_mobile}
				players_by_id={players_by_id}
				remaining_salary={remaining_salary}
				is_static={is_static}
				bye_detector_active={bye_detector_active}
				bye_detector_selected={bye_detector_selected}
				is_partial_or_standard={is_partial_or_standard}
				mobile_stat={mobile_stat}
				score_stat={this.score_stat}
				{...props}
			/>
		);

		const score_select = this.renderScoreSelect();
		const stat_select = this.renderStatisticSelect();
		const bye_detector = this.renderByeDetector();
		const partial_detector = this.renderPartialByeDetector();
		const mobile_controls = <MobileControlsWrapper>
			<SelectsWrapper>
				{ score_select }
				{ stat_select }
			</SelectsWrapper>
			{ partial_detector }
			{ bye_detector }
		</MobileControlsWrapper>;

		/**
		 * TODO show past trades using the
		 * teams_classic/show_trades_history endpoint
		 */
		const content = <React.Fragment>
			{ team_top_controls }
			<TeamPanel>
				<GameBarWrapper>
					{ score_select }
					{ game_bar }
				</GameBarWrapper>
				{ mobile_controls }
				{ this.renderTeamInfo() }
				
				<FlexRow>
					{partial_detector}
					{bye_detector}
				</FlexRow>
				
			</TeamPanel>
			{ this.field }
			{ this.renderTradesView() }
		</React.Fragment>;

		if(is_mobile) {
			return (
				<React.Fragment>
					<PlayerPoolMobileWrapper is_open={show_player_pool}>
 						{getPlayersPool({
							stats_block: game_bar,
							controls: is_mobile ? mobile_controls : undefined
						})}
					</PlayerPoolMobileWrapper>
					<MyTeamMobileWrapper is_open={!show_player_pool}>
						{ content }
					</MyTeamMobileWrapper>
					<div />
				</React.Fragment>
			);
		}

		return (
			<React.Fragment>
				<div>
					{ content }
				</div>
				<PlayerPoolWebWrapper>
					{ getPlayersPool() }
				</PlayerPoolWebWrapper>
				
			</React.Fragment>
		);
	}

	render() {
		const {
			user,
			notification_bar,
			team,
			rounds_by_id,
			selected_round_id,
			last_round,
			selected_round,
			rounds_ordered,
			setSelectedRoundId
		} = this.props;
		const is_scheduled = _.get(selected_round, "status", "scheduled");
		const share_round_id = is_scheduled ? _.get(last_round, "id", 1) : selected_round_id;
		const teamStartRound = _.get(rounds_by_id, team.start_round);
		const isTeamStartRoundComplete = _.get(teamStartRound, 'status') === "complete";
		return (
			<React.Fragment>
				<JsonFetcher
					fetch={["rounds", "players", "venues", "squads", "coach_players"]}
					interval={90000} /* Fetch new data once every 90s */
				/>

				{this.state.preloader? (<Preloader />): (
					<React.Fragment>
						<LeagueGameBar />
						<AdsContainer>
							<Ad id="myteam" />
						</AdsContainer>
						
						<PageContentWrapper className="playwright-mask-hidden">
							<TeamTitle
								is_scheduled={is_scheduled}
								team={team}
								isTeamStartRoundComplete={isTeamStartRoundComplete}
								share_round_id={share_round_id}
								user={user}
							/>
							<RoundSelector
								rounds={rounds_ordered}
								selected={selected_round_id}
								handleClick={setSelectedRoundId}
							/>
					
							<ShareBar 
								round_id={share_round_id} 
								team_id={team.id} 
								is_scored_complete={this.is_team_complete_and_scored} 
							/>
							
							<TwoColumnLayout>
								{ this.main_content }
							</TwoColumnLayout>
						</PageContentWrapper>
						{ this.error_modal }
						<MatchCentreBar />
						<Footer show_key />
						<GlobalNote
							user_id={_.get(user, "id")}
							note_text={notification_bar.content}
							should_show={notification_bar.show}
						/>
					</React.Fragment>
				)}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state, props) => {
	const actual_round = selectors.rounds.getActualRound(state);
	const current_team = selectors.getMyClassicTeam(state);
	const players_by_id = selectors.players.getExtendedPlayersById(state);
	const is_static = props.selected_round_id !== _.get(actual_round, "id");
	const is_current = props.selected_round_id >= _.get(actual_round, "id", 0);
	const team = is_static
		? selectors.setUtilityPosition({
			...current_team,
			...state.teamsClassic.show.team
		}) : current_team;

	const rounds_with_matches = selectors.rounds.getRoundsWithMatches(state);
	const selected_round = _.find(rounds_with_matches, { id: props.selected_round_id });

	const round_started = _.get(selected_round, "status", "scheduled") !== "scheduled";

	return {
		user: selectors.getUser(state),
		actual_round,
		players_by_id,
		current_team,
		team,
		positions: selectors.positions.getPositionsArray(state),
		has_assistant_coach: Boolean(selectors.getUser(state).assistant_coach),
		is_pending_players: state.players.is_pending,
		errors: state.teamsClassic.show_my.error,
		last_round: selectors.rounds.getLastRound(state),
		is_team_started: selectors.isMyTeamStarted(state),
		player_ids: selectors.getMyClassicTeamPlayersIds(state),
		selected_round,
		rounds_with_matches,
		rounds_ordered: getLeagueRounds(props.rounds_ordered),
		rounds: getRounds(state),
		remaining_salary: selectors.getMyClassicTeamRemainingSalary(state),
		data_loading_complete: _.every([true,
			!state.players.is_pending,
			!state.rounds.is_pending,
			!state.squads.is_pending,
			!state.venues.is_pending,
			!state.teamsClassic.show_my.is_pending,
			!state.teamsClassic.snap_is_pending,
			(Boolean(selectors.getUser(state).assistant_coach))?
				!_.isEmpty(state.players.coach_by_id): true
		]),
		trades: selectors.getTrades(state),
		trade_history: selectors.getTradeHistory(state),
		is_first_round_with_partial_lockout: selectors.isFirstRoundWithPartialLockout(state),
		notification_bar: state.cms.notification_bar,
		is_static,
		is_current,
		game_bar_statistic: round_started
			? state.teamsClassic.game_bar_statistic
			: _.reject(state.teamsClassic.game_bar_statistic, { key: "stats.round_score" }),
		player_pool_statistic: state.teamsClassic.player_pool_statistic,
	};
};
const mapDispatchToProps = {
	fetchMyTeam: actions.fetchMyClassicTeam,
	fetchTeam: actions.fetchClassicTeam,
	removePlayer: actions.removePlayerFromMyClassicTeam,
	updateMyTeam: actions.updateMyClassicTeam,
	clearErrorMyTeam: actions.clearErrorsMyTeam,
	fetchFavourites: actions.fetchFavourites,
	showModalForPlayerId: actions.showModalForPlayerId,
	fetchMyClassicTrades: actions.fetchMyClassicTrades,
	getNotificationBar: actions.getNotificationBar,
	fetchTradeHistory: actions.fetchTradeHistory,
};

export const MyTeam = compose(
	withRoundsSelector,
	withClassicPlayerModal,
	withCoachesBox,
	withFantasyCoachModal,
	withByeDetector,
	connect(
		mapStateToProps,
		mapDispatchToProps,
	)
)(MyTeamComponent);


export default MyTeam;