/* eslint max-len: 0 */
// @flow
import React from "react";
import SvgIconContainer from "../SvgIconContainer";

export default (
	{
		unit = "em",
		...rest
	}: Object = {}) => (
	<SvgIconContainer viewBox="0 0 24 24" {...rest} unit={unit}>
		<title>Large Cells</title>
		<g fillRule="nonzero"><path d="M4 5h16v4h-16z" /><path d="M4 10h16v4h-16z" /><path d="M4 15h16v4h-16z" /></g>
	</SvgIconContainer>
);