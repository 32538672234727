// @flow
import * as React from "react";
import styled from "styled-components";

import default_image from "../../../assets/images/LeagueLogo.svg";

const LeagueAvatarWrapper = styled.picture`
	img {
		height: ${({ size }) => size || "100%"};
		width: ${({ size }) => size || "100%"};
		border-radius: 50%;
		object-fit: cover;
	}
`;

const EditSpan = styled.span`
	text-align: center;
	color: grey;
	margin-left: 18px;
	font-family: "SourceSansPro";
	font-size: 14px;
`;

const FileInput = styled.input`
	display: none;
`;

const FileLabel = styled.label`
	position: relative;
	height: ${({ size }) => size || "100%"};
	cursor: pointer;

	div {
		position: absolute;
		top: 0;
		right: 0;

		svg {
			cursor: pointer;
			font-size: 20px;
			padding: .17em;
		}
	}
`;

type Props = {
	size: string,
	name: string,
	preview_img: any,
	add_text: string,
	onChange: Function,
	className?: string,
};

export const FormUploadImage =
	({ size, name, preview_img, onChange, className, add_text }: Props) => {

		return (
			<FileLabel htmlFor={name} size={size} className={className}>
				<LeagueAvatarWrapper size={size}>
					<img src={preview_img ? preview_img : default_image} alt='Upload' />
					<EditSpan>{add_text}</EditSpan>
				</LeagueAvatarWrapper>
				<FileInput
					type="file"
					accept=".png, .jpg, .jpeg"
					id={name}
					name={name}
					onChange={onChange}
				/>
			</FileLabel>

		);
	};

export default FormUploadImage;