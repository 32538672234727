// @flow
import * as React from "react";
import styled from "styled-components";

import { ButtonSecondaryLink } from "../ButtonSecondary";

import colors from "../../assets/css/colors";

const MessageText = styled.h4`
	font-size: 15px;
	line-height: 1.5;
	font-weight: bold;
	text-align: center;
	color: ${colors.primary.darkGrey};
	margin-bottom: 20px;
`;

const Wrapper = styled.div`
	background: white;
	padding: 30px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

type Props = {
	league_id: number,
	is_fixture_page?: boolean,
}

const MatchupHoldingMessage = ({ league_id, is_fixture_page }: Props) => (
	<Wrapper>
		<MessageText>League {is_fixture_page ? "fixtures " : "matchups "} 
		will be available at the completion of the draft.</MessageText>
		<ButtonSecondaryLink to={`/draft/league/${league_id}/pre-draft`}>
			Go to Draft Room
		</ButtonSecondaryLink>
	</Wrapper>
);

export default MatchupHoldingMessage;