// @flow
import * as React from "react";
import styled from "styled-components";
import { connect } from "react-redux";

import colors from "../../../assets/css/colors";
import Leagues from "../../../components/Icons/Leagues";

import {
	Footer,
	PageTitle,
	AdsContainer,
	Ad,
	PageContentWrapper,
	TwoColumnLayout,
	StandardSidebar,
} from "../../../components";
import { LeagueManagerComponent } from "../../../components/utils/LeagueManager/index";
import LeagueCreateForm from "./leaguesCreate/LeaguesCreateForm";
import RegenerateLeagues from "./leaguesCreate/RegenerateLeagues";

const PageTitleStyled = styled(PageTitle)`
	font-size: 32px;
	display: flex;
	align-items: flex-end;

	svg{
		margin-right: .2em;
	}
`;

const StyledPageContentWrapper = styled(PageContentWrapper)`
	scroll-behavior: smooth;
`;

type Props = {
	handleSubmit: Function,
	rounds_list: Array<Object>,
	leaguesClassic: Function,
	error_modal: Function,
}

const ClassicLeaguesCreateComp = ({ leaguesClassic }: Props) => (
	<div>
		<AdsContainer>
			<Ad/>
		</AdsContainer>
		<LeagueManagerComponent from="create_classic">
			{({ rounds_list, handleSubmit, error_modal, ...props }: Props) => (
				<StyledPageContentWrapper>
					{ leaguesClassic.error ? error_modal : null }
					<PageTitleStyled>
						<Leagues color={colors.title} size='1.8' />
						Create a Classic league
					</PageTitleStyled>
					<TwoColumnLayout>
						<div>
							<RegenerateLeagues />
							<LeagueCreateForm
								{...props}
								onSubmit={handleSubmit}
								rounds={rounds_list}
							/>
						</div>
						<StandardSidebar />
					</TwoColumnLayout>
				</StyledPageContentWrapper>
			)}
		</LeagueManagerComponent>
		<Footer />
	</div>
);

const mapStateToProps = state => ({
	leaguesClassic: state.leaguesClassic.create,
});

export const ClassicLeaguesCreate = connect(
	mapStateToProps,
	null,
)(ClassicLeaguesCreateComp);

export default ClassicLeaguesCreate;