// @flow
import styled from "styled-components";

export const TeamPositionContent = styled.div`
	margin-bottom: 20px;
	
	&:last-child {
		margin-bottom: 0;
	}
`;

export default TeamPositionContent;