// @flow
import styled from "styled-components";

export const TeamViewContent = styled.div`
	padding: 15px 5px;
	
	ul li {
		height: 30px;
		margin-bottom: 10px;
	
		&:last-child {
			margin-bottom: 0;
		}
	}
`;

export default TeamViewContent;