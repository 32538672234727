// @flow
import * as React from "react";
import styled from "styled-components";
import { below } from "../../assets/css/media";

import Caution from "../Icons/Caution";

import {
	ModalContainer,
} from "../../components";
import ButtonAlert from "../ButtonAlert";
import colors from "../../assets/css/colors";

const ButtonWrapper = styled.div`
	display: flex;
    flex-direction: column;
	justify-content: center;
    align-items: center;
	margin: 0 1em;
	padding: 40px 0;

	button {
		${below.desktop`
			min-width: 85px;
		`}
	}
	${below.desktop`
		padding: 20px 0;
	`}
`;

const CancelButton = styled.button`
  font-family: SourceSansPro;
	font-weight: 400;
	color: #D92425;
	border: none;
	padding: none;
	outline: none;
	background: none;
	width: 40px;
	font-size: 14px;
	margin-top: 20px;
	cursor: pointer;
	:hover{
		text-decoration: underline;
	}
`;

const Text = styled.div`
	font-family: 'SourceSansPro';
	font-size: 14px;
	line-height: 1.43;
	margin: 0 100px;
	color: ${colors.primary.primary};
	${below.tablet`
		margin: 0 20px;
	`};
`;

type Props = {
	closeClick: Function,
	confirmClick: Function,
	cancelClick: Function,
	header_text: React.Node,
	body_text?: React.Node,
	confirm_button_text?: string,
	cancel_button_text?: string,
	warning?: boolean,
};

export const RestoreSettingsModal = ({
	closeClick,
	confirmClick,
	cancelClick,
	header_text,
	body_text,
	confirm_button_text,
	cancel_button_text,
	warning = false,
}: Props) => {

	return <ModalContainer 
		warning={warning} 
		header_text={header_text} 
		onClick={closeClick} 
		top_icon={<Caution />}
	>
		<Text>{body_text}</Text>
		<ButtonWrapper>
			
			<ButtonAlert onClick={confirmClick}>
				{confirm_button_text}
			</ButtonAlert>
			<CancelButton onClick={cancelClick}>
				{cancel_button_text}
			</CancelButton>
		</ButtonWrapper>
	</ModalContainer>;
};

RestoreSettingsModal.defaultProps = {
	confirm_button_text: "Yes, please restore my settings",
	cancel_button_text: "Cancel"
};

export default RestoreSettingsModal;