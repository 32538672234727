// @flow
import * as React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import slugify from "slugify";

import { header_height, default_transition } from "../../assets/css/vars";
import colors from "../../assets/css/colors";
import { below } from "../../assets/css/media";

import Arrow from "../Icons/Arrow";
import ArrowSolid from "../Icons/ArrowSolid";
import SubNav from "../SubNav";


const StyledLink = styled(NavLink)`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 0 0.625em;
	color: inherit;
	text-decoration: none;
	height: ${header_height}px;
	line-height: ${header_height}px;
	white-space: nowrap;
	font-size: 14px;
	font-family: "SourceSansPro";
	font-weight: 600;
	letter-spacing: 0;
	${default_transition};

	&.active {
		background: #fff;
		color: ${colors.primary.dark};
		font-weight: 600;
	}

	${below.tablet`
		color: ${colors.primary.dark};
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding: 0 16px 0 20px;
		> svg {
			margin-right: auto;
		}

		${({ align_left }) => align_left && `
			text-align: left;
			justify-content: flex-start;
		`}
	`};
`;

const StyledNavNonLink = styled(StyledLink.withComponent("div"))`
	> span {
		max-width: 130px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;

		@media screen and (max-width: 840px) and (min-width: 768px) {
			max-width: 70px;
		}
	}
`;

const Item = styled.li`
	font-size: 14px;
	font-family: SourceSansPro;
	height: ${header_height}px;
	display: block;
	position: relative;
	> input {
		position: absolute;
		visibility: hidden;
	}
	${below.desktop`
		font-size: 12px;
	`};

	${below.tablet`
		width: 100%;
		height: auto;
		border-bottom: 1px solid ${colors.secondary.paleGrey};
		font-size: 16px;
		background: white;
		z-index: 16;
	`};

	:hover {
		${StyledLink}, ${StyledNavNonLink} {
			background: #fff;
			color: ${colors.primary.dark};
		}
	}
`;

const ArrowContainer = styled.label`
	height: 1.6em;
	width: 1.6em;
	display: none;
	> svg {
		height: 100%;
		width: 100%;
		transition: all 0.2s linear;
	}

	input[type="checkbox"]:checked ~ & svg {
		transform: rotate(180deg);
	}



	${below.tablet`
		display: block;
		position: absolute;
		top: 25px;
		right: 20px;
		color: ${colors.primary.dark};
		transform: translateY(-50%);
	`};
`;

const StyledLabel = styled.label`
	padding-top: 1.2em;
	font-size: 14px;
	svg{
		width: 15px;
	}
`;

type Props = {
	closeSubModal: Function,
	openSubModal: Function,
	children: string,
	to: string,
	non_link?: boolean,
	show_league_switcher?: boolean,
	sub_align?: 'right' | 'left',
	sub_title?: string,
	sub_items?: Array<{
		to: string,
		title: string,
		external?: boolean,
		show_new?: boolean
	}>
}

const NavItem = ({
	closeSubModal,
	openSubModal,
	children,
	to = "/",
	non_link,
	sub_items,
	sub_title,
	sub_align,
	show_league_switcher }: Props) => (
	<Item>
		{non_link? (
			<StyledNavNonLink align_left>
				<span>{children}</span>
				<StyledLabel htmlFor={slugify(`nav-${children}`)}>
					<ArrowSolid direction="down" size="1.6" />
				</StyledLabel>
			</StyledNavNonLink>
		):(
			<StyledLink to={to} exact>
				{children}
			</StyledLink>
		)}
		{sub_title && sub_items &&
			<React.Fragment>
				<input type="checkbox" id={slugify(`nav-${children}`)} />
				{!non_link &&
					<ArrowContainer htmlFor={slugify(`nav-${children}`)}>
						<Arrow />
					</ArrowContainer>
				}
				<SubNav
					sub_items={sub_items}
					sub_title={sub_title}
					sub_align={sub_align}
					show_league_switcher={show_league_switcher}
					closeSubModal={closeSubModal}
					openSubModal={openSubModal}
				/>
			</React.Fragment>
		}
	</Item>
);

NavItem.defaultProps = {
	non_link: false
};

export default NavItem;