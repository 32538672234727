// @flow
import * as React from "react";
import styled from "styled-components";
import _ from "lodash";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import * as selectors from "../../modules/selectors";
import Table, { TBody, THead, Tr, Td } from "../../components/TableX";
import type { TPLeague, TRound, TDraftTeamsById, TTeam } from "../../modules/types";
import type { TCellInfo } from "../../helpers/tables";
import TeamForm from "../../components/TeamForm";
import ChangeIndicatorUp from "../../components/Icons/ChangeIndicatorUp";
import ChangeIndicatorDown from "../../components/Icons/ChangeIndicatorDown";
import ChangeIndicatorHold from "../../components/Icons/ChangeIndicatorHold";
import TeamUserAvatar from "../../components/Team/TeamUserAvatar";
import { renderTableHeaderCell } from "../../helpers/tables";
import { below, above } from "../../assets/css/media";
import colors from "../../assets/css/colors";
import { getShortName } from "../../helpers/league";
import ThStat from "../ThStat";
import renderCup from "../Fixtures/cup";
import sticky_headers from "../../assets/css/sticky";
import Tabs from "../Tabs";
import TabItem from "../TabItem";
import ClassicRankings from "../ClassicRankings";

const TAB_COLS = [
	"rank", "avatar", "name", "league_points_selected_round",
	"points_percent_selected_round", "win",  "loss", "draw",
	"form", "next"
];

const MOB_COLS = [
	"rank", "avatar", "name", "league_points_selected_round",
	"points_percent_selected_round",
	"win",  "loss", "draw",
];

const StyledTabs = styled(Tabs)`
	li:first-child{
		margin-right: 10px;
	}
`;

const StyledTBody = styled(TBody)`
	tr:nth-child(even) {
		background: #F8F8FA;
	}
`;
const LadderTable = styled(Table)`
	box-shadow: none;
	margin-top: 20px;
	margin-bottom: 50px;
	border-collapse: separate;
`;

export const LadderTHead = styled(THead)`
	background-color: transparent;
	> tr > th:first-child {
		text-align: center;
	}
`;

export const LadderRow = styled(Tr)`
	&:nth-child(${({ finalsFormat }) => finalsFormat}) {
			border-bottom: 3px solid #198DE1;
	}

	${({ is_user_team }) => is_user_team && `background-color: ${colors.secondary.lightGrey};`}

	height: 60px;
`;

export const LadderTh = styled(ThStat)`
	color: #1D4073;
	font-size: 12px;
	line-height: 10px;
	text-align: center;
	cursor: default;

	${sticky_headers}

	&[rel="cup"] {
		padding-left: 0;
		padding-right: 0;
	}
`;


const LadderThSortable = styled(LadderTh)`
	cursor: pointer;
	color: #1D4073;
`;

export const TdStat = styled(Td)`
	position: relative;
	color: ${colors.title};
	font-weight: 200;
	padding: ${({ pre_draft }) => pre_draft ? "0px" : "0px 5px"};
	.player-info{
		margin-left: 0px;
	}
	tr:nth-child(even) > &, tr:nth-child(odd) > & {
		background-color: unset;
	}
	width: ${({ player_info }) => player_info ? "100%" : ""};

	&[rel="cup"] {
		padding-left: 0;
		padding-right: 0;
		> div {
			margin: 0;
			height: 30px;
			width: 30px;

			> svg {
				height: 30px;
				width: 30px;
			}

			${below.desktop`
				height: 20px;
				width: 20px;

				> svg {
					height: 20px;
					width: 20px;
				}
			`}
		}
	}
`;

const TdAvatar = styled(TdStat)`
	width: 40px;
	height: 40px;
	> div {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	${below.desktop`
		padding: 0;
		position: relative;
	`};
`;

export const TeamUserAvatarStyled = styled(TeamUserAvatar)`
	width: 40px;
	height: 40px;
	margin: 0 auto;
	padding: 0;
`;

export const TeamName = styled.div`
	font-size: 12px;
	line-height: 1.125;
	font-weight: bold;
	text-align: left;
	color: ${colors.primary.primary};
	text-overflow: ellipsis;
	overflow: hidden;
	max-width: 60px;

	${above.phone`
		font-size: 14px;
		max-width: 150px;
	`}

	${above.tablet`
		max-width: 100px;
	`}

	${above.desktop`
		max-width: 200px;
	`}
`;

export const UserName = styled.div`
	font-size: 12px;
	line-height: 1.28;
	text-align: left;
	color: #7F8A90;
	text-overflow: ellipsis;
	overflow: hidden;
	max-width: 60px;
	font-weight: 500;

	${above.phone`
		max-width: 150px;
	`}

	${above.tablet`
		max-width: 100px;
	`}

	${above.desktop`
		max-width: 200px;
	`}

	${below.phone`
		display: none;
	`}
`;

const TeamWrapper = styled(Link)`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	text-decoration: none;

	:hover {
		${TeamName} {
			text-decoration: underline;
		}
	}
`;

export const PositionWrapper = styled.div`
	display: flex;
	justify-content: center;
`;

const Rank = styled.div`
	font-family: SourceSansPro;
`;

export const ChangeIndicator = styled.div`
	display: flex;
	margin-left: 10px;
	padding-top: 2px;
`;

const LadderContainer = styled.div`

`;

type TableColumn = {
	key: string,
	prop: string,
	hide_small?: boolean,
	tooltip: string,
};

export const DEFAULT_LADDER_STATS: TableColumn[] = [
	{ key: "GP", prop: "games_played", tooltip: "Games Played" },
	{ key: "PTS", prop: "league_points_selected_round", tooltip: "Points" },
	{ key: "%", prop: "points_percent_selected_round", tooltip: "Percentage" },
	{ key: "W", prop: "win", hide_small: true, tooltip: "Wins" },
	{ key: "L", prop: "loss", hide_small: true, tooltip: "Losses" },
	{ key: "D", prop: "draw", hide_small: true, tooltip: "Draws" },
	{ key: "F", prop: "points_for_selected_round", hide_small: true, tooltip: "Points For" },
	{
		key: "A",
		prop: "points_against_selected_round",
		hide_small: true,
		tooltip: "Points Against"
	},
];

const rank_cell_info: TableColumn = {
	prop: "rank",
	key: "Pos",
	hide_small: false,
	tooltip: "Position",
};

type Props = {
	compact?: boolean,
	user_id: number,
	ladder_stats: TableColumn[],
	ladder_teams_ordered: Array<Object>,
	ladder_teams_by_id: TDraftTeamsById,
	rounds_by_id: {
		[id: string|number]: TRound
	},
	selected_round_id: number,
	league: TPLeague,
	league_current_round_fixtures: Array<Array<number>>,
	only_show_finals: boolean,
	is_classic: boolean,
	premier?: number,
	consolation_premier?: number,
	wooden_spoon?: number,
	show_tabs: boolean,
}

type State = {
	order_by_desc: boolean,
	sort_by: string,
	ladder_sorted: Array<Object>,
	tab: string,
	overall_order: {
		by: string,
		direction: 'DESC' | 'ASC'
	}
}

export class H2HLadder extends React.Component<Props, State> {
	static defaultProps = {
		ladder_stats: DEFAULT_LADDER_STATS,
		only_show_finals: false,
		is_classic: false,
		show_tabs: false,
	};
	constructor(props: Props) {
		super(props);

		_.bindAll(this, [
			"tableColumnToCellInfo",
			"onSortColumnChange",
			"setActiveTab",
			"setOverallSortBy",
		]);
	}
	state = {
		ladder_sorted: [],
		sort_by: "rank",
		order_by_desc: false,
		tab: "season",
		overall_order: {
			by: "rank",
			direction: "ASC",
		}
	};

	componentDidMount() {
		const { ladder_teams_ordered } = this.props;

		this.setState({ ladder_sorted: ladder_teams_ordered });
	}

	componentDidUpdate(prev_props: Props) {
		const { ladder_teams_ordered } = this.props;

		if (ladder_teams_ordered !== prev_props.ladder_teams_ordered) {
			this.setState({ ladder_sorted: ladder_teams_ordered });
		}
	}

	isActiveTab(sort_prop: string){
		const { sort_by } = this.state;
		return _.eq(sort_by, sort_prop);
	}

	defaultSortBy(column: string) {
		return column !== "rank";
	}

	setActiveTab({ currentTarget }: Object) {
		const { tab } = currentTarget.dataset;
		this.setState({
			tab
		});
	}

	get overall_ladder_teams() {
		const { ladder_teams_ordered } = this.props;
		const { overall_order } = this.state;

		return _(ladder_teams_ordered)
			.orderBy("points_for_selected_round", "desc")
			.map((team, i) => ({ ...team, rank_selected_round: i + 1 }))
			.orderBy(this.overall_ladder_sort_key, _.toLower(overall_order.direction))
			.value();
	}

	get overall_ladder_sort_key() {
		const { overall_order } = this.state;

		return _.get(
			_.find(this.overall_ladder_stats, { sort_key: overall_order.by }),
			"prop",
			"rank_selected_round"
		);
	}

	get overall_ladder_stats() {
		const { selected_round_id, rounds_by_id, is_classic } = this.props;

		const round_over = _.get(rounds_by_id, [selected_round_id, "status"]) === "complete";

		const round_id = round_over
			? selected_round_id
			: selected_round_id - 1;

		const last_round_key = round_over ? "Round Points" : "Points Last Round";

		const stats = [
			{
				key: "Total Points",
				short_key: "Total",
				prop: "points_for_selected_round",
				sort_key: "points_for_selected_round"
			 },
			{
				key: "Average Points",
				short_key: "Avg",
				prop: "points_average_selected_round",
				sort_key: "points_average_selected_round"
			},
			{
				key: last_round_key,
				short_key: "Last",
				prop: `scoreflow.${round_id}`,
				rel: "points_last_round_selected_round",
				sort_key: "points_last_round_selected_round",
			},
		];

		return is_classic
			? [
				...stats,
				{
					key: "Overall Ranking",
					short_key: "Ovr Rank",
					prop: `overall_rank_history.${round_id}`,
					rel: "overall_rank",
					sort_key: "overall_rank",
				},
			]
			: stats;
	}

	get overall_ladder_mobile_columns() {
		return [
			"rank",
			"avatar",
			"points_for_selected_round",
			"points_last_round_selected_round",
			"overall_rank"
		];
	}

	get overall_ladder_tablet_columns() {
		return [
			"rank",
			"avatar",
			"points_for_selected_round",
			"points_average_selected_round",
			"points_last_round_selected_round",
			"overall_rank"
		];
	}

	setOverallSortBy(column_value: string) {
		this.setState(state => {
			const { by, direction } = state.overall_order;
			let new_direction = _.includes(["overall_rank", "rank"], column_value) ? "ASC" : "DESC";

			if (by === column_value) {
				new_direction = direction === "ASC" ? "DESC" : "ASC";
			}

			return {
				overall_order: {
					by: column_value,
					direction: new_direction,
				}
			};
		});
	}

	onSortColumnChange(column_value: string) {
		this.setState(state => {
			const order_by_desc = _.eq(state.sort_by, column_value)
				? !state.order_by_desc
				: this.defaultSortBy(column_value);
			let ladder_sorted;

			if (order_by_desc) {
				ladder_sorted = [
					...state.ladder_sorted.sort((a, b) => (b[column_value] - a[column_value]))
				];
			}
			else {
				ladder_sorted = [
					...state.ladder_sorted.sort((a, b) => (a[column_value] - b[column_value]))
				];
			}

			return {
				ladder_sorted,
				sort_by: column_value,
				order_by_desc,
			};

		});
	}

	get finals_teams() {
		const { league } = this.props;
		const { ladder_sorted } = this.state;

		return _.take(
			ladder_sorted,
			this.getFinalsCutoff() || league.num_teams
		);
	}

	get showing_teams() {
		const { compact, only_show_finals, user_id } = this.props;
		const { ladder_sorted } = this.state;

		const user_team = _.find(ladder_sorted, team => team.user_id === user_id);

		return compact || only_show_finals
			? [ user_team, ...this.finals_teams ]
			: ladder_sorted;
	}

	shouldShowCupColumn() {
		const {
			premier,
			consolation_premier,
			wooden_spoon,
		} = this.props;

		if (!_.some([premier, consolation_premier, wooden_spoon])) {
			return false;
		}

		const haveCup = team => {
			const id = _.get(team, "id", 0);

			return id === premier
				|| id === consolation_premier
				|| id === wooden_spoon;
		};

		return _.some(this.showing_teams, haveCup);
	}

	renderCup(team: TTeam) {
		const { premier, consolation_premier, wooden_spoon  } = this.props;
		const id = _.get(team, "id", 0);
		const isCup = (cup_id): boolean => Boolean(cup_id) && cup_id === id;

		return renderCup({
			is_consolation: isCup(consolation_premier),
			is_premier: isCup(premier),
			is_spoon: isCup(wooden_spoon),
		});
	}

	renderRankChangeIndicator(team: TTeam) {
		const { rounds_by_id, selected_round_id } = this.props;

		// Get the round id of the last given ranking
		// If the selected round is after the last given ranking, use that
		const last_rank = Math.max(..._.keys(_.get(team, "rank_history")));
		const round_id = Math.min(selected_round_id, last_rank);
		const round = rounds_by_id[round_id];

		if (round) {
			if (team.rank_history[(round.id)] < team.rank_history[(round.id - 1)]) {
				return <ChangeIndicatorUp />;
			}
			else if (team.rank_history[(round.id)] > team.rank_history[(round.id - 1)]) {
				return <ChangeIndicatorDown />;
			}
		}

		return <ChangeIndicatorHold />;
	}

	tableColumnToCellInfo(column: TableColumn): TCellInfo {
		return {
			table: {
				order_by_desc: this.state.order_by_desc,
				sort_key: this.state.sort_by
			},
			cell: {
				prop: column.prop,
				sort_key: column.prop,
				name: column.key,
				short_name: column.key,
				rel: column.prop,
				sortable: true,
				onClick: this.onSortColumnChange,
				is_fc: false,
				tooltip: column.tooltip
			}
		};
	}

	renderTableHeader(show_cup_col: boolean = false) {
		const { ladder_stats } = this.props;
		const ladder_header_cells = _(ladder_stats)
			.map(this.tableColumnToCellInfo)
			.map(cell_info => renderTableHeaderCell(cell_info, LadderThSortable))
			.value();

		return (
			<LadderTHead>
				<Tr>
					{ renderTableHeaderCell(this.tableColumnToCellInfo(rank_cell_info), LadderTh) }
					<LadderTh rel="avatar">Team</LadderTh>
					<LadderTh rel="name"></LadderTh>
					{show_cup_col && <LadderTh rel="cup" /> }
					{ ladder_header_cells }
					<LadderTh rel="form">Form</LadderTh>
					<LadderTh rel="next" width="40px">Next</LadderTh>
					<LadderTh />
				</Tr>
			</LadderTHead>
		);
	}

	getFinalsCutoff() {
		const { league } = this.props;
		const league_cutoff_line = league.num_teams === league.finals_format
			|| league.finals_format === 0 ? 0 : league.finals_format;

		return league_cutoff_line;
	}

	getTeamPageLink(team: TTeam) {
		const { is_classic, user_id, league } = this.props;
		if (is_classic) {
			if (user_id === team.user_id) {
				return "/classic/team";
			}
			return `/classic/team/user/${team.id}`;
		}
		if (user_id === team.user_id) {
			return `/draft/league/${league.id}/team`;
		}
		return `/draft/league/${league.id}/team/${team.id}`;
	}

	renderTableRow(team: TTeam, show_cup_col: boolean = false) {
		const {
			ladder_stats,
			ladder_teams_by_id,
			league_current_round_fixtures,
			league,
			user_id
		} = this.props;
		const matchup = league_current_round_fixtures
			.find(fixture => fixture.includes(team.id));
		const opponent = matchup ? ladder_teams_by_id[matchup
			.find(team_id => team_id !== team.id)]: null;
		const league_cutoff_line = this.getFinalsCutoff();
		const is_make_finals = team.rank_selected_round <= league.finals_format
			|| league.finals_format === 0;
		const ladder_stats_body_cells = ladder_stats.map(({ key, prop, hide_small }) => {
			let team_value = _.get(team, `[${prop}]`, 0);
			if(isNaN(team_value)){
				team_value = 0;
			};
			return (
				<TdStat
					className={this.isActiveTab(prop)}
					key={prop}
					rel={prop}
				>
					{team_value || "0"}
				</TdStat>
			);
		});

		return (
			<LadderRow key={team.id}
				makeFinals={is_make_finals}
				finalsFormat={league_cutoff_line}
				is_user_team={user_id === _.get(team, "user_id", 0)}
			>
				<TdStat
					width="60px"
					className={`${this.isActiveTab("rank_selected_round")}`}
					rel="rank"
				>
					<PositionWrapper>
						<Rank>{team.rank_selected_round}</Rank>
						<ChangeIndicator>
							{this.renderRankChangeIndicator(team)}
						</ChangeIndicator>
					</PositionWrapper>
				</TdStat>
				<TdAvatar rel="avatar">
					<TeamUserAvatarStyled
						user_id={team.user_id}
						avatar_version={team.avatar_version}
						firstname={team.firstname}
						lastname={team.lastname}
					/>
				</TdAvatar>
				<TdStat rel="name">
					<TeamWrapper to={this.getTeamPageLink(team)}>
						<TeamName>{team.name}</TeamName>
						<UserName>{getShortName(team)}</UserName>
					</TeamWrapper>
				</TdStat>
				{show_cup_col && <TdStat rel="cup">
					{ this.renderCup(team) }
				</TdStat>}
				{ladder_stats_body_cells}
				<TdStat rel="form">
					<TeamForm form={team.form} />
				</TdStat>
				<TdStat rel="next">
					{opponent ? (
						<TeamUserAvatarStyled
							user_id={opponent.user_id}
							avatar_version={opponent.avatar_version}
							firstname={opponent.firstname}
							lastname={opponent.lastname}
							hover_name={opponent.name}
						/>
					) : null}
				</TdStat>
				<TdStat />
			</LadderRow>
		);
	}

	renderTableBodyFinals(show_cup_col: boolean) {
		const { user_id } = this.props;
		const { ladder_sorted } = this.state;

		const ladder_sorted_finals = this.finals_teams;

		const user_team = _.find(ladder_sorted, team => team.user_id === user_id);
		const is_user_team_finals = _.some(
			ladder_sorted_finals,
			team => user_team && team.id === user_team.id
		);

		return (
			<StyledTBody>
				{ladder_sorted_finals.map(team => this.renderTableRow(team, show_cup_col))}
				{!is_user_team_finals && user_team
					? this.renderTableRow(user_team, show_cup_col)
					: null}
			</StyledTBody>
		);
	}

	renderTableBody(show_cup_col: boolean) {
		const { ladder_sorted } = this.state;

		return (
			<TBody>
				{ladder_sorted.map(team => this.renderTableRow(team, show_cup_col))}
			</TBody>
		);
	}

	renderOverallLadder() {
		const {
			user_id,
			rounds_by_id,
			selected_round_id,
			is_classic,
			league
		} = this.props;
		const { overall_order } =  this.state;

		return <ClassicRankings
			ladder_teams_ordered={this.overall_ladder_teams}
			rounds_by_id={rounds_by_id}
			selected_round_id={selected_round_id}
			user_id={user_id}
			ladder_stats={this.overall_ladder_stats}
			updateSortBy={this.setOverallSortBy}
			order={overall_order}
			is_h2h_overall
			is_classic={is_classic}
			league_id={_.get(league, "id")}
			mobile_columns={this.overall_ladder_mobile_columns}
			tablet_columns={this.overall_ladder_tablet_columns}
		/>;
	}

	renderH2HLadder() {
		const { compact, only_show_finals } = this.props;
		const show_cup_col = this.shouldShowCupColumn();

		return <LadderTable
			mobile_columns={MOB_COLS}
			tablet_columns={TAB_COLS}
		>
			{this.renderTableHeader(show_cup_col)}
			{compact || only_show_finals
				? this.renderTableBodyFinals(show_cup_col)
				: this.renderTableBody(show_cup_col)}
		</LadderTable>;
	}

	renderTabs() {
		const { show_tabs } = this.props;
		const { tab } = this.state;

		if (!show_tabs) {
			return null;
		}

		return <StyledTabs>
			<TabItem
				onClick={this.setActiveTab}
				data-tab="season"
				className={tab === "season" ? "active-tab" : ""}>
				Season Ladder
			</TabItem>
			<TabItem
				onClick={this.setActiveTab}
				data-tab="overall"
				className={tab === "overall" ? "active-tab" : ""}>
				Overall Ladder
			</TabItem>
		</StyledTabs>;
	}

	renderLadder() {
		const { tab } = this.state;

		return tab === "season"
			? this.renderH2HLadder()
			: this.renderOverallLadder();
	}

	render() {
		return <LadderContainer>
			{ this.renderTabs() }
			{ this.renderLadder() }
		</LadderContainer>;
	}
}

const mapStateToProps = (state, props) => {
	const isDraft = state.uiMenu.pathname.includes("draft");
	const classicLadder = selectors.leaguesClassic.getOrderedLadderCalculated(state, props);
	const draftLadder = selectors.orderedLadderCalculated(state, props);
	const ladder_teams_ordered = isDraft ? draftLadder : classicLadder;
	return{
		ladder_teams_ordered: ladder_teams_ordered,
	};
};


export default withRouter(connect(mapStateToProps)(H2HLadder));