// @flow
import * as React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import styled from "styled-components";
import type { TUser, TPLeague } from "../../../modules/types";
import type {
	TCoachesBoxComment, TUserSettings, TMessages
} from "../../../modules/types/coachesBox";
import * as selectors from "../../../modules/selectors";
import * as actions from "../../../modules/actions";
import CoachesBox from "../../CoachesBox";
import { default_transition } from "../../../assets/css/vars";

const CoachOverlay = styled.div`
	background-color: black;
	opacity: 0.5;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 98;
	${default_transition}
`;

type Props = {
	location: { pathname: string },
	match: { params: { league_id: string } },
	user: TUser,
	user_settings: {
		types: TUserSettings,
		is_pending: boolean,
		update_pending: boolean,
		error: any
	},
	league: TPLeague,
	is_classic: boolean,
	league_id: string,
	getCoachesBoxSettings: Function,
	updateSettings: Function,
	messages: TMessages,
	send: {
		is_pending: boolean,
		error: any,
	},
	show_teams: {
		is_pending: boolean,
		offset: boolean,
		ordered_ids: number[]
	},
	getMessages: Function,
	sendMessage: Function,
	clearErrors: Function,
	showTeamsClassic: Function,
	showTeamsDraft: Function,
	teams_by_id: {[number]: {id: number, name: string}},
}

type State = {
	opened: boolean,
	box_used: boolean,
	offset: number,
}

const getLeague = match => _.get(match, ["params", "league_id"], "");
const getComments = (
	messages: TMessages, type: string, league_id: string
) => _.get(messages, ["types", type, league_id], []);

const withCoachesBox = (WrappedComponent: any) => {
	class WithCoachesBox extends React.Component<Props, State> {
		static defaultProps = {
			match: { params: { league_id: "" } }
		};

		constructor(props) {
			super(props);

			_.bindAll(this, [
				"toggleOpened",
				"setBoxUsed",
				"sendMessage",
				"getNewMessages",
				"loadMoreMessages",
			]);
		}

		state = {
			opened: false,
			box_used: true,
			offset: 0,
		};

		componentDidMount() {
			const {
				getCoachesBoxSettings,
				is_classic,
				showTeamsClassic,
				showTeamsDraft,
			} = this.props;

			const { league_id } = this;

			getCoachesBoxSettings();
			if (league_id) {
				const showTeams = is_classic ? showTeamsClassic : showTeamsDraft;

				showTeams({
					league_id,
				});
			}
		}

		componentDidUpdate(prev_props: Props, prev_state: State) {
			this.onSettingsUpdate(prev_props);
			this.onMessagesUpdate(prev_props);
			this.onTeamsLoaded(prev_props);
			this.onSent(prev_props);
		}

		get league_id() {
			// Classic may set league in state, but not have it present in url
			const { league_id, league } = this.props;
			return league_id || _.get(league, "id");
		}

		get type() {
			return this.props.is_classic ? "classic" : "draft";
		}

		get is_used() {
			const { league } = this.props;
			const { box_used } = this.state;
			const league_uses = Boolean(_.get(league, "coaches_box", false));
			return league_uses && box_used;
		}

		onSettingsUpdate(prev_props: Props) {
			const { user_settings: settings } = this.props;
			const { user_settings: prev_settings } = prev_props;

			const list_pending = prev_settings.is_pending && !settings.is_pending;
			const update_pending = prev_settings.update_pending && !settings.update_pending;

			if (list_pending || update_pending) {
				const box_used = _.get(
					settings,
					["types", this.type, this.league_id, "coaches_box"],
					false
				);

				this.setState({
					box_used
				});
			}
		}

		onSent(prev_props: Props) {
			const { send: { is_pending, error } } = this.props;

			if(prev_props.send.is_pending && !is_pending && !error) {
				this.getNewMessages();
			}
		}

		onMessagesUpdate(prev_props: Props) {
			const { messages } = this.props;
			const {
				messages: prev_messages
			} = prev_props;
			const {  offset } = this.state;

			const comments = getComments(messages, this.type, this.league_id);
			const prev_comments = getComments(prev_messages, this.type, this.league_id);

			// If the league has totally changed, don't worry
			if (getLeague(this.props.match) !== getLeague(prev_props.match)) {
				return;
			}

			if (_.size(comments) > _.size(prev_comments)) {
				// If new messages have loaded (first time and after that)
				// update the offset amount
				this.setState({
					// If new messages have arrived, update the offset to include
					// them in the  offset count
					offset: Math.max(_.size(comments), offset)
				});
			}
		}

		/**
		 * Only 20 teams are initially loaded from leaguesClassic/show
		 * So, keep loading until, there's no more to load
		 */
		onTeamsLoaded(prev_props: Props) {
			const { show_teams, is_classic, showTeamsClassic } = this.props;
			const { show_teams: prev_teams } = prev_props;
			if (this.is_used && is_classic && !show_teams.is_pending && prev_teams.is_pending) {
				if (show_teams.offset + 20 <= _.size(show_teams.ordered_ids)) {
					showTeamsClassic({
						league_id: this.league_id,
						offset: show_teams.offset + 20
					});
				}
			}
		}

		getNewMessages() {
			const { getMessages } = this.props;

			if (this.league_id) {
				getMessages({
					type: this.type,
					league_id: this.league_id,
					last_id: this.last_message_id
				});
			}
		}

		loadMoreMessages() {
			const { getMessages } = this.props;
			const { offset } = this.state;

			if (this.league_id) {
				getMessages({
					type: this.type,
					league_id: this.league_id,
					offset,
					limit: 10
				});

				this.setState({
					offset: offset + 10
				});
			}
		}

		toggleOpened() {
			const { opened } = this.state;
			this.setState({
				opened: !opened
			}, () => {
				if (this.state.opened) {
					this.getNewMessages();
				}
			});
		}

		setBoxUsed(used: boolean) {
			const { updateSettings } = this.props;

			updateSettings({
				type: this.type,
				league_id: this.league_id,
				coaches_box: used
			});
		}

		sendMessage(text: string) {
			if (!_.isEmpty(text)) {
				const { sendMessage } = this.props;

				sendMessage({
					type: this.type,
					league_id: this.league_id,
					text,
				});

			}
		}

		get last_message_id() {
			const messages = this.messages;
			return _.get(_.last(messages), "id",  undefined);
		}

		get messages(): TCoachesBoxComment[]  {
			return _.orderBy(
				getComments(this.props.messages, this.type, this.league_id),
				"id",
				"asc"
			);
		}

		render() {
			const { opened, box_used, offset } = this.state;
			const { user, league, clearErrors, teams_by_id } = this.props;
			const league_uses = Boolean(_.get(league, "coaches_box", false));

			return <React.Fragment>
				{league_uses && opened && <CoachOverlay onClick={() => this.setState({
					opened: false
				})} />}
				{league_uses && <CoachesBox
					location={this.props.location}
					toggleOpen={this.toggleOpened}
					opened={opened}
					comments={this.messages}
					user={user}
					league={league}
					coaches_box_used={box_used}
					setBoxUsed={this.setBoxUsed}
					sendMessage={this.sendMessage}
					send_error={_.get(this.props, ["send", "error"])}
					clearErrors={clearErrors}
					messages_available={offset <= _.size(this.messages)}
					loadMoreMessages={this.loadMoreMessages}
					unread_messages={false}
					clearUnread={() => null}
					teams_by_id={teams_by_id}
				/>}
				<WrappedComponent {...this.props} />
			</React.Fragment>;
		}
	}

	const mapStateToProps = (state: Object, props: Props) => {
		const is_classic = _.get(props.location, "pathname", "").startsWith("/classic/");
		const league_id = getLeague(props.match);
		const league = is_classic
			? selectors.leaguesClassic.show.getLeague(state, props)
			: selectors.leagues.getLeague(state, props);

		const teams_by_id = is_classic
			? state.leaguesClassic.teams_by_id
			: state.leagues.teams_by_id;

		return {
			user: selectors.getUser(state),
			is_classic,
			league,
			league_id,
			user_settings: state.coachesBox.settings,
			messages: state.coachesBox.messages,
			send: state.coachesBox.send,
			teams_by_id,
			show_teams: state.leaguesClassic.show_teams
		};
	};

	const mapDispatchToProps = {
		getCoachesBoxSettings: actions.getCoachesBoxSettings,
		updateSettings: actions.setCoachesBoxSettings,
		getMessages: actions.getCoachesBoxMessages,
		sendMessage: actions.sendCoachesBoxMessage,
		clearErrors: actions.clearCoachesBoxErrors,
		showTeamsDraft: actions.leagueDraft.showTeamsDraftLeague,
		showTeamsClassic: actions.showTeamsClassic
	};

	return compose(
		withRouter,
		connect(mapStateToProps, mapDispatchToProps)
	)(WithCoachesBox);
};

export default withCoachesBox;