// @flow
import React from "react";
import styled from "styled-components";
import * as _ from "lodash";
import FaqCategory from "./faqCategory";

const categoryItems = (source, cat) => 
	_.filter(source, { zendesk_section_id: cat.zendesk_section_id });

const FaqWrapper = styled.div``;
type FaqSource = {
	game_types: Object[],
	categories: Object[],
	items: Object[],
}

type Props = {
	is_classic: boolean,
	faqs?: FaqSource,
	categories?: Object,
};

class Faq extends React.Component<Props> {

	render() {
		const { faqs, categories } = this.props;

		if (!faqs) {
			return <p>Loading</p>;
		}

		return (<FaqWrapper>
			{_.map(categories, cat => {
				const items = categoryItems(faqs, cat);
				return <FaqCategory key={cat.id} category={cat} items={items} />;
			})}
		</FaqWrapper>);
	}
}

export default Faq;