// @flow
import { isEmpty, get } from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import * as _ from "lodash";
import styled from "styled-components";
import ordinal from "ordinal";
import colors from "../../assets/css/colors";
// import { below } from '../../assets/css/media';
import { Exist, Preloader, TeamUserAvatar } from "../../components";
import HistoryGraph from "../../components/HistoryGraph";
import LeadersByRound from "../../components/HistoryGraph/LeadersByRound";
import RoundByRound from "../../components/HistoryGraph/RoundByRound";
import SquadValue from "../../components/HistoryGraph/SquadValue";
import * as actions from "../../modules/actions";
import * as selectors from "../../modules/selectors";
import type {TRound, TPlayersById, TLeague, TUser} from "../../modules/types";
import RankingsByRound from "../../components/HistoryGraph/RankingsByRound";
import Caution from "../../components/Icons/Caution";
const GraphTitle = styled.h2`
    font-family: SourceSansPro;
    font-size: 20px;
    margin: 20px 0;
    color: ${colors.primary.primary};
    line-height: 20px;
    font-weight: 700;
`;

const HoldingTitle = styled.h2`
	font-family: SourceSansPro;
    font-size: 22px;
    color: ${colors.primary.primary};
    line-height: 20px;
    font-weight: 700;
	
`;

const HoldingContainer = styled.div`
	background: ${colors.primary.light};
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 15px;
	padding: 20px;
	svg{
		fill: ${colors.primary.primary};
		height: 40px;
		width: 40px;
	}

`;

const GraphWrapper = styled.div`
    >div:first-child {
        width: 100% !important;
    }
`;

const AvatarWrapper = styled.div`
    position: absolute;
    left: 20px;
    top: 20px;
    display: flex;
    align-items: center;
    >div:first-of-type{
        width: 40px;
        height: 40px;
    }
    img{
        height: 40px;
        width: 40px;
    }
`;

const FlexCol = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 5px;
`;

const HoldingText = styled.p`
	font-size: 16px;
	font-weight: 400;
	font-family: SourceSansPro;
`;

const TeamName = styled.p`
    font-family: TitilliumUpright;
    font-weight: 700;
    font-size: 12px;
    color: ${colors.primary.primary};
    line-height: 14px;
`;

const TeamRank = styled(TeamName)`
    font-weight: 400;
    font-family: SourceSansPro;
`;

const CaptainRow = styled.div`
    display: flex;
    align-items: center;
    margin: 5px 0;
    color: #FFA200;
    font-family: SourceSansPro;
    font-size: 12px;
    margin-left: 10px;
`;

const VCRow = styled(CaptainRow)`
    color: #0093EF;
`;

const OverallRankRow = styled(CaptainRow)`
	color: #1D4073;
`;

const CaptainIndicator = styled.span`
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #FFA200;
    margin-right: 5px;
`;

const OverallRankIndicator = styled(CaptainIndicator)`
	background-color: #1D4073;
`;



const VCIndicator = styled(CaptainIndicator)`
    background-color: #0093EF;
`;

const RoundRankIndicator = styled(VCIndicator)`

`;

type Props = {
	fetchHistory: typeof actions.fetchSeasonHistory,
    fetchRounds: typeof actions.fetchRounds,
	is_rounds_pending: boolean,
    history_statistic: Object,
    season_data: Object,
    rounds: TRound[],
    actual_round: TRound,
    fetchPlayers: typeof actions.fetchPlayers,
    players: TPlayersById,
    content_width: number,
    leagues: TLeague[],
    fetchClassicTeamSnapshot: typeof actions.fetchClassicTeamSnapshot,
    snapshot: Object,
    user: TUser
};
class ClassicSeasonHistoryComp extends React.Component<Props> {
	componentDidMount() {
		const {
			fetchClassicTeamSnapshot, 
			fetchHistory, 
			fetchRounds, 
			fetchPlayers,
			is_rounds_pending
		} = this.props;
		fetchClassicTeamSnapshot();
		fetchHistory();
		if(!is_rounds_pending){
			fetchRounds();
		}
		
		
		fetchPlayers();
	} 

	get end_round() {
		const {actual_round} = this.props;
		const isActualStarted = actual_round.status !== "scheduled";
		return isActualStarted ? actual_round.id + 1 : actual_round.id;
	}
	get round_by_round_rounds() {
		const {rounds} = this.props;
		return rounds.filter(round => round.status !== "scheduled" && round.id > 0);
	}
	get cumulative_data() {
		const {season_data, rounds} = this.props;
		const endRound = this.end_round;
		return rounds.slice(1, endRound).filter(round => round.id > 0).map(round => {
			const cumulativeValue = get(season_data, `round_score_cumulative.${round.id}`, 0);
			const rank = get(season_data, `ranks.${round.id}`, 0);
			const round_score = get(season_data, `round_scores.${round.id}`, 0);
			const round_rank = get(season_data, `round_ranks.${round.id}`, 0);
			return {
				value: cumulativeValue,
				rank,
				round_score,
				round_rank
                
			};
		});
	}

	get round_by_round_data() {
		const { history_statistic } = this.props;
		return Object.keys(history_statistic).map(round_id => {
			return {
				points: history_statistic[round_id].points
			};
		});
	}

	get leader_data() {
		const {season_data } = this.props;
		const { captain_vice_captain_scores } = season_data;
		return Object.keys(captain_vice_captain_scores).map(round_id => {
			const captain = captain_vice_captain_scores[round_id].captain;
			const vice_captain = captain_vice_captain_scores[round_id].vice_captain;
			const vc_enabled = captain_vice_captain_scores[round_id].vc_enabled;
			return {
				captain,
				vice_captain,
				vc_enabled
			};
		});
	}

	get round_rank_data() {
		const {season_data, rounds} = this.props;
		const roundsToUse = rounds.slice(1, this.end_round).filter(round => round.id > 0);
		return roundsToUse.map(round => {
			const roundID = round.id;
			const rank = _.get(season_data, `ranks.${roundID}`);
			const round_rank = _.get(season_data, `round_ranks.${roundID}`);
			const round_score = _.get(season_data, `round_scores.${roundID}`);
			const round_overall_score = _.get(season_data, `round_score_cumulative.${roundID}`);
			return {
				rank,
				round_rank,
				round_score,
				round_overall_score
			};
		});
	}



	get squad_value_data() {
		const {season_data} = this.props;
		const {squad_value} = season_data;
		return Object.keys(squad_value).map(round => {
			const {team_value, remaining_salary} = squad_value[round];

			return {...squad_value[round], value: team_value + remaining_salary};
            
		});
	}

	teamAvatarContent(is_leader_graph: boolean, is_ranking_graph: boolean) {
		const {snapshot: {
			firstname, 
			lastname, 
			name, 
			rank
		}, 
		user: {avatar_version, id} } = this.props;
		return <AvatarWrapper>
			<TeamUserAvatar 
				firstname={firstname}
				lastname={lastname}
				avatar_version={avatar_version}
				user_id={id}
			/>
			<FlexCol>
				<TeamName>
					{name}
				</TeamName>
				<TeamRank>
					{ordinal(rank)}
				</TeamRank>
			</FlexCol>
			<Exist when={is_ranking_graph}>
				<FlexCol>
					<OverallRankRow>
						<OverallRankIndicator />
                        Overall Rank
					</OverallRankRow>
					<VCRow>
						<RoundRankIndicator />
                        Round Rank
					</VCRow>
                    
				</FlexCol>
			</Exist>
			<Exist when={is_leader_graph}>
				<FlexCol>
					<CaptainRow>
						<CaptainIndicator />
                        Captain's base score
					</CaptainRow>
					<VCRow>
						<VCIndicator />
                        Vice Captain's base score
					</VCRow>
                    
				</FlexCol>
			</Exist>
		</AvatarWrapper>;
	}

	holdingSection() {
		return(
			<HoldingContainer>
				<Caution />
				<HoldingTitle>
					Season History 
				</HoldingTitle>
				<HoldingText>
					Come back after Round 1 to view how your season is progressing!
				</HoldingText>

			</HoldingContainer>
		);
	}
    

	dataNotLoaded(dataArr: Object[]) {
		let not_loaded = false;
		dataArr.forEach(object => {
			if(isEmpty(object)){
				not_loaded = true;
			}
		});
		return not_loaded;
	}
       
	render() {
		const {history_statistic, season_data, rounds, content_width, snapshot, user} = this.props;
		if(rounds[0] && rounds[0].status !== "complete"){
			return this.holdingSection();
		}
		if(this.dataNotLoaded([
			history_statistic, season_data, rounds, snapshot, user
		])
		){
			return <Preloader />;
		}
		const roundsToUse = rounds.slice(0, this.end_round).filter(round => round.id > 0);
		return (
			<div>
				<GraphTitle>
					Rankings
				</GraphTitle>
				<GraphWrapper>
					<RankingsByRound 
						rounds={this.round_by_round_rounds}
						show_data={true}
						data={this.round_rank_data}
						parentHeight={500}
						parentWidth={content_width}
						players={this.props.players}
						team_avatar={this.teamAvatarContent(false, true)}
					/>
				</GraphWrapper>
				<GraphTitle>
                    Cumulative points
				</GraphTitle>
				<GraphWrapper>
					<HistoryGraph 
						rounds={roundsToUse}
						data={this.cumulative_data}
						show_data={true}
						parentHeight={500}
						parentWidth={content_width}
						team_avatar={this.teamAvatarContent(false, false)}
					/>
				</GraphWrapper>
				
				<GraphTitle>
                    Round by round points
				</GraphTitle>
				<GraphWrapper>
					<RoundByRound 
						rounds={this.round_by_round_rounds}
						data={this.round_by_round_data}
						show_data={true}
						parentHeight={500}
						parentWidth={content_width}
						team_avatar={this.teamAvatarContent(false, false)}
					/>

				</GraphWrapper>
				
				<GraphTitle>
                    Captain &#38; Vice Captain
				</GraphTitle>
				<GraphWrapper>
					<LeadersByRound 
						rounds={this.round_by_round_rounds}
						show_data={true}
						data={this.leader_data}
						parentHeight={500}
						parentWidth={content_width}
						players={this.props.players}
						team_avatar={this.teamAvatarContent(true, false)}
					/>
				</GraphWrapper>
				<GraphTitle>
                    Squad value (team value + remaining salary)
				</GraphTitle>
				<GraphWrapper>
					<SquadValue 
						rounds={this.round_by_round_rounds}
						data={this.squad_value_data}
						parentHeight={500}
						parentWidth={content_width}
						show_data={true}
						team_avatar={this.teamAvatarContent(false, false)}
					/>
				</GraphWrapper>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	history_statistic: state.teamsClassic.season_history.history_statistic,
	season_data: state.teamsClassic.season_history.season_data,
	actual_round: selectors.rounds.getActualRound(state),
	rounds: selectors.rounds.getRounds(state),
	is_rounds_pending: selectors.rounds.getRoundsIsPending(state),
	players: selectors.players.getExtendedPlayersById(state),
	leagues: selectors.leaguesClassic.show_my.getLeagues(state),
	snapshot: state.teamsClassic.snapshot,
	user: selectors.getUser(state)
});

const mapDispatchToProps = {
	fetchHistory: actions.fetchSeasonHistory,
	fetchRounds: actions.fetchRounds,
	fetchPlayers: actions.fetchPlayers,
	fetchClassicTeamSnapshot: actions.fetchClassicTeamSnapshot,
};

export const ClassicSeasonHistory = connect(
	mapStateToProps,
	mapDispatchToProps
)(ClassicSeasonHistoryComp);

export default ClassicSeasonHistory;