// @flow
import * as React from "react";
import styled, { css } from "styled-components";
// import _ from 'lodash';
import { ThToolTip } from "../../../../components";
import { below } from "../../../../assets/css/media";
import ArrowSolid from "../../../../components/Icons/ArrowSolid";
import { shouldShowArrow } from "../../../../helpers/tables";
import colors from "../../../../assets/css/colors";
import sticky_headers from "../../../../assets/css/sticky";

const ListViewHeaderWrapper = styled.div`
	display: flex;
	background-color: white;
	justify-content: space-around;
	height: 60px;
	align-items: center;
	border-bottom: 2px solid #CAD2D8;
	& > :first-child{
		flex: 0 0 220px;
		text-align: left;
		box-sizing: border-box;

		${below.desktop`
			flex-basis: 65%;
		`};

		${below.tablet`
			flex-basis: 250px;
			padding: 0 0 0 10px;
		`};
		${below.phone`
			flex-basis: 180px;
		`};
	}
	.last {
		flex: 0 0 70px;
		text-align: center;

		${below.desktop`
			flex-basis: 70px;
		`}
	}

	${sticky_headers}

	${below.desktop`
		display: ${({ show_mobile }) => show_mobile ? "flex" : "none"};
		padding: 0 20px 0 25px;
    	margin: 0 -20px;
	`};

	${below.tablet`
		padding: 0;
		margin: 0;
	`}
`;

const SHTooltip = styled(ThToolTip)``;

const HeaderItem = styled.div`
	color: #1D4073;
	font-size: 12px;
	flex: 1;
	display: flex;
	align-items: center;
	text-align: center;
	justify-content: ${({ is_player }) => is_player ? "flex-start" : "center"};
	${({ is_coach }) => is_coach && "color: #B89344"};
	height: 60px;
	position: relative;
	text-transform: uppercase;
	user-select: none;

	p {
		padding-left: 30px;
	}
	
	&:hover {
		${({ show_highlighter }) => show_highlighter && css`
			background: linear-gradient(180deg, #FFFFFF 0%, #F0F3F7 100%);
		`}
	}
	
	span {
		position: relative;
		z-index: 2;
	}

	> svg {
		font-size: 24px;
		position: absolute;
		left: 50%;
		margin-left: -.5em;
		z-index: 3;

		&.top {
			top: -5px;
		}

		&.down {
			bottom: -3px;
		}

		&.top, &.down {
			opacity: 0;
			&.show {
				opacity: 1;
				fill: ${({ is_coach }) => is_coach ? colors.coach : colors.primary.primary};
			}
		}
	}

	&:hover > svg {
		&.top, &.down {
			opacity: 1;
			fill: ${colors.secondary.paleGrey};
			&.show {
				fill: ${({ is_coach }) => is_coach ? colors.coach : colors.primary.primary};
			}
		}
	}

	${({ only_show_mobile }) => only_show_mobile && "display: none;"};

	${below.desktop`
		display: none;
		${({ show_mobile, only_show_mobile }) => (show_mobile || only_show_mobile) && css`
			display: flex;
			&:not(:first-child) {
				justify-content: center;
			}
		`};
	`}
`;

const StatsHeaderItems = styled.div`
	display: flex;
	justify-content: space-around;
	flex: 1 1 0px;
`;

const renderMobileHeaders = (
	score_stat: Object = {}, 
	mobile_stat: Object = {}, 
	show_mobile: boolean, 
	onToggleOrder: Function,
	sort_by: string,
	order_by_desc: boolean,
) => {
	if (!show_mobile) {
		return null;
	}

	const makeHeaderItem = (stat: Object) => {
		const is_active = stat.key === sort_by;
		const top_class = `top ${shouldShowArrow(is_active, !order_by_desc)}`;
		const down_class = `down ${shouldShowArrow(is_active, order_by_desc)}`;

		return <HeaderItem 
			only_show_mobile 
			is_coach={stat.is_coach}
			onClick={() => onToggleOrder(stat.key)}
			className={is_active ? "active" : ""}
			show_highlighter
		>
			{stat.tooltip && <SHTooltip text={stat.tooltip} />}
			<span>{stat.short_name}</span>

			<ArrowSolid direction="up" className={top_class} />
			<ArrowSolid direction="down" className={down_class} />
		</HeaderItem>;
	};


	return <React.Fragment>
		{ makeHeaderItem(score_stat) }
		{ makeHeaderItem(mobile_stat) }
	</React.Fragment>;
};

type THeader = {
	tooltip: string,
	key: string,
	is_coach: boolean,
	prop: string,
}

const trade_header_titles: THeader[] = [
	{
		prop: "stats.points_last_round",
		tooltip: "Points Last Round",
		key: "LAST",
		is_coach: false
	},
	{ prop: "stats.games_played", tooltip: "Games Played", key: "GP", is_coach: false },
	{ prop: "stats.avg_points", tooltip: "Average Points", key: "AVG", is_coach: false },
	{ prop: "stats.last_3_avg", tooltip: "Last 3 Average", key: "L3", is_coach: false },
	{ prop: "stats.proj_score", tooltip: "Project Score", key: "PROJ.", is_coach: true },
	{ prop: "stats.break_even", tooltip: "Break Even", key: "B.E.", is_coach: true },
	{ prop: "stats.bye_round_id", tooltip: "Bye Round", key: "BYE", is_coach: true },
	{ prop: "partial_bye_round", tooltip: "Early Bye Round", key: "EBR", is_coach: true}
];

type Props = {
	onToggleOrder: Function,
	order_by_desc: boolean,
	sort_by: string,
	score_stat?: Object,
	mobile_stat?: Object,
}

export const TradePlayerPoolHeader = ({
	onToggleOrder, order_by_desc, sort_by, score_stat, mobile_stat 
}: Props) => {
	const show_mobile = mobile_stat !== undefined;

	return <ListViewHeaderWrapper show_mobile={show_mobile}>
		<HeaderItem is_player show_mobile>PLAYER</HeaderItem>
		<StatsHeaderItems>
			{
				trade_header_titles.map(({ key, is_coach, tooltip, prop }) => {
					const is_active = prop === sort_by;
					const top_class = `top ${shouldShowArrow(is_active, !order_by_desc)}`;
					const down_class = `down ${shouldShowArrow(is_active, order_by_desc)}`;

					return (
						<HeaderItem
							className={is_active ? "active" : ""}
							key={key}
							is_coach={is_coach}
							onClick={() => onToggleOrder(prop)}
							show_highlighter
						>

							<SHTooltip text={tooltip} />
							<span>{key}</span>

							<ArrowSolid direction="up" className={top_class} />
							<ArrowSolid direction="down" className={down_class} />
						</HeaderItem>
					);
				})
			}
			{renderMobileHeaders(
				score_stat, 
				mobile_stat, 
				show_mobile, 
				onToggleOrder,
				sort_by, 
				order_by_desc
			)}
		</StatsHeaderItems>
		<HeaderItem className="last" show_mobile>
			ADD
		</HeaderItem>

	</ListViewHeaderWrapper>;
};

export default TradePlayerPoolHeader;