// @flow
import React from "react";
import { InformationAcceptModal } from "../Modal";


type Props = {
    onClose: Function;
}

export const KeeperWarningModal = ({ onClose }: Props) => {
	const text="You have added more teams to your league "+
        "since disabling keepers, when turning these back "+
        "on the new teams will not have any teams/keepers "+
        "assigned to them. Meaning these teams will start "+
        "fresh unless you assign them a team from last year "+
        "from the Manage teams page";
	const header_text="League Settings Updated";
	return(
		<InformationAcceptModal 
			header_text={header_text}
			text={text}
			onClick={onClose}
		/>
	);
};

export default KeeperWarningModal;