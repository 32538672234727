// @flow
import * as React from "react";
import styled, { css } from "styled-components";
import _ from "lodash";
import Flipcard from "@kennethormandy/react-flipcard";
import * as numeral from "numeral";
import type { TPlayerWithFixture } from "../../modules/types";
import type { TRound } from "../../modules/types/rounds";
import { EmptyPlayerStyled, Exist, getByeColor, PlayerStatusIcon, StatusButton } from "..";
import Flip from "../Icons/Flip";
import "@kennethormandy/react-flipcard/dist/Flipcard.css";
import { currencyFormat } from "../../utils";
import Captain from "../Icons/Captain";
import ViceCaptain from "../Icons/ViceCaptain";
import Emergency from "../Icons/Emergency";
import colors from "../../assets/css/colors";
import Lock from "../Icons/Lock";
import { isAllTrue, IS_IE } from "../../helpers";
import positionEmergencyButton from "../utils/positionEmergency";
import { displayRoundLabel, displayRoundScore } from "../../helpers/stats/displayRound";
import { findMatch } from "../../helpers/rounds";
import { below } from "../../assets/css/media";
import swapIcon from "../../assets/images/swap-white.svg";
import { onDragEnter, onDragLeave, onDragOver } from "../../utils/drag_and_drop";
import { FieldPlayerBack } from "./back";


const DEFAULT_VALUE = "- -";
numeral.nullFormat(DEFAULT_VALUE);

const PLAYERS_PATH = process.env.REACT_APP_PLAYERS_PATH || "";
const getPlayerColor = ({ is_captain, is_vice, is_emergency }) => {
	const { captain, vice_captain, emergency } = colors.player;

	if (is_captain) {
		return captain;
	}
	if (is_vice) {
		return vice_captain;
	}
	if (is_emergency) {
		return emergency;
	}
	return "rgba(0,0,0,0)";
};
const getPlayerScoreColor = ({ is_coach, is_live, is_dnp, is_ave }) => {
	if (is_coach) {
		return "#B89344";
	}
	if (is_dnp) {
		return "#CAD2D8";
	}
	if (is_ave) {
		return colors.primary.primary;
	}
	return is_live ? "#7ED321" : "#262424";
};

const makeShadowOutline = (color: string) => `box-shadow: inset 0 3px 0 0 ${color},
	inset 0 -3px 0 0 ${color},
	inset -3px 0 0 0 ${color},
	inset 3px 0 0 0 ${color},
	inset 0 0 0 3px ${color}
`;

const makeByeOutline = ({ bye_selected, player_bye }) => css`
	${_.includes(bye_selected, player_bye) && makeShadowOutline(getByeColor(player_bye))}
`;

type Props = {
	flip: boolean,
	player: TPlayerWithFixture,
	is_bench: boolean,
	captain: number,
	vice_captain: number,
	is_emergency: boolean,
	has_assistant_coach: boolean,
	removePlayer: Function,
	position: number | string,
	setCaptain: Function,
	setViceCaptain: Function,
	setEmergency: Function,
	is_team_started: boolean,
	substitutePlayer: Function,
	pureSubstitutePlayer: Function,
	cancelSubstitute: Function,
	in_substitute: boolean,
	out_substitute: boolean,
	is_locked: boolean,
	is_substitute: boolean,
	player_points: string,
	selected_round: TRound,
	fantasyCoachModal: Function,
	showModalForPlayerId: Function,
	double_score: boolean,
	is_first_round_with_partial_lockout: boolean,
	is_static: boolean,
	is_current: boolean,
	bye_detector_selected: number[],
	bye_detector_active: boolean,
	player_bye?: number,
	player_partial_bye?: number,
	is_classic: boolean,
	trade_link: string,
	delist_link: string,
	has_captain: boolean,
	has_emergencies: boolean,
	setPlayerAction: Function,
	emergency_position: number,
	locked_personnel: any[],
	is_utility: boolean,
	utility_position: number,
	setPosition?: Function,
	customScoringEnabled?: boolean,
  actual_round: TRound,
  is_overflow?: boolean,
  is_partial_or_standard?: string
}
type State = {
	flipped: boolean;
}
const FieldPlayerStyled = styled(EmptyPlayerStyled)`
	position: relative;
	background: rgba(0,0,0,0);
	z-index: 1;
	transition: opacity 0.2s linear;
	opacity: ${({ is_opacity }) => is_opacity ? .4 : 1};

	.Flipcard {
		width: 164px;
		height: 90px;
		position: absolute;
		top: 0;
		left: 0;
		box-sizing: border-box;
		z-index: 2;
		box-shadow: 0px 0px 10px ${colors.primary.darkGrey}40;
		border-radius: ${({ is_draft }) => is_draft ? "2px;" : "5px;"}

		/* Perspective gives flip animation depth but causes fuzziness */
		perspective: none;
		${below.field_view_large`
			width: 144px;
		`}
		${below.field_view_mid`
			width: 134px;
		`}
	}
	.Flipcard-front {
		overflow: visible;
	}
	.Flipcard-back {
		overflow-x: visible;
	}
`;

const PlayerPlaceholder = styled.div`
	opacity: 1;
	width: 164px;
	height: 90px;
	position: absolute;
	top: 0;
	left: 0;
	box-sizing: border-box;
	z-index: 2;
	border-radius: ${({ is_draft }) => is_draft ? "2px;" : "5px;"}
	border: 2px dashed #FFF;
	
	&:after {
		background: url(${swapIcon}) no-repeat center center;
		border: 2px solid #FFF;
		content: '';
		width: 30px;
		height: 30px;
		border-radius: 100%;
		box-sizing: border-box;
		position:absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);	
	}
	
	${below.field_view_large`
		width: 144px;
	`}
	
	${below.field_view_mid`
		width: 134px;
	`}
`;

const PlayerShadow = styled.div`
	opacity: 0.2;
	background-color: ${colors.primary.darkGrey};
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
	border-radius: ${({ is_draft }) => is_draft ? "2px;" : "5px;"};
`;

const FlipStyled = styled(Flip)`
	color: ${colors.secondary.accentGrey};
	position: absolute;
	top: 5px;
	right: 5px;
	z-index: 5;
	cursor: pointer;
`;

const FieldPlayerFront = styled.div`
	background-color: #FFF;
	position: relative;
	width: 164px;
	height: 90px;
	border-radius: ${({ is_draft }) => is_draft ? "2px;" : "5px;"}
	box-sizing: border-box;
	cursor: ${({ draggable }) => draggable ? "grab" : "default"};
	
	&:active {
        cursor: grabbing;
    }
	
	${below.field_view_large`
		width: 144px;
	`}
	${below.field_view_mid`
		width: 134px;
	`}
`;

const ByeHiglighter = styled.div`
	transition: all 0.2s linear;

	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 2;

	border-radius: ${({ is_draft }) => is_draft ? "2px;" : "5px;"};

	${({ bye_active }) => bye_active ? css`${makeByeOutline}` : css` ${makeShadowOutline("#fff0")}`}
`;
const ByeRoundTab = styled.div`
	position: absolute;
	top: ${({ showing }) => showing ? "-12px" : 0};
	${({ reverse }) => reverse ? "left" : "right"}: 15px;
	height: ${({ showing }) => showing ? "13px" : 0};
	width: 20px;

	transition: all 0.2s linear;

	background-color: ${({ player_bye }) => getByeColor(player_bye)};
	color: white;
	border-radius: 3px 3px 0 0;

	display: flex;
	align-items: center;
	justify-content: center;

	> span {
		transition: ${({ showing }) => showing ? (
		"all 0.2s linear, font-size 0s linear 0.2s"
	) : (
		"all 0.2s linear, font-size 0s linear"
	)};
		font-size: ${({ showing }) => showing ? "10px" : "0px"};
		font-weight: 300;
	}
`;

const GradientWrapper = styled.div`
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
`;
const FieldPlayerGradient = styled.div`
	background-color: white;
	background-image: radial-gradient(#7F8B9460 0%, rgba(202,210,216,0) 80%, #ffffff00);
	position: absolute;
	top: -45%;
	left: 20%;
	right: 20%;
	bottom: 75%;
`;

const FieldPlayerBackWrapper = styled(FieldPlayerFront)`
	background-color: #F0F3F7;
	transition: all 0.2s linear;
	box-shadow: 0 0 #fff0;
	${makeByeOutline};

	${({ highlight }) => highlight && css`
		background-color: white;
		${makeShadowOutline(colors.primary.accent)};
		transform: translateY(0);

		&.hover-over {
			transform: translateY(-10%);
		}
	`};
`;

const PlayerNameWrapper = styled.div`
	padding-top: 50px;

	${below.field_view_mid`padding-top: 40px;`}
`;
const PlayerName = styled.div`
	text-align: center;
	color: #1D4073;
	font-size: 12px;
	line-height: 1.6666667;
	font-weight: 700;
	text-transform: capitalize;
	cursor: pointer;
	position: relative;
	z-index: 3;

	:hover {
		text-decoration: underline;
	}
`;
const PlayerAvatarWrapper = styled.div`
	position: absolute;
	top: -10%;
	left: 50%;
	transform: translateX(-50%);
	height: 60px;
	width: 60px;
	z-index: 3;
	cursor: pointer;

	&:hover + ${PlayerNameWrapper} > ${PlayerName} {
		text-decoration: underline;
	}

	${below.field_view_large`
		width: 46px;
		height: 46px;
	`};
`;
const PlayerAvatar = styled.div`
	height: 60px;
	width: 60px;
	border-radius: 100%;
	overflow: hidden;
	box-sizing: border-box;
	border: 3px solid ${getPlayerColor};
	background: #FFFFFF;
	text-align: center;
	position: relative;
	&:after {
		content: '';
		position: absolute;
		bottom: -75px;
		left: 0;
		height: 100px;
		width: 62px;
		background: linear-gradient(180deg, rgba(0,0,0,0) 0%, #000000 100%);
	}
	img {
		width: 100%;
		margin-top: -2px;
	}
	${below.field_view_large`
		width: 46px;
		height: 46px;
	`}
`;
const StatusIconWrapper = styled(StatusButton)`
	height: 16px;
	width: 16px;
	margin: 2.5px 0;
	position: absolute;
	right: -8px;
	top: calc(50% - 2.5px);
	transform: translateY(-50%);
	svg {
		height: 12px;
		width: 12px;
	}
`;
const SecondaryStatusIconWrapper = styled(StatusIconWrapper)`
	top: unset;
	transform: translate(0);
	right: 0;
	bottom: 2.5px;
	background: ${getPlayerColor};

	${below.field_view_large`
		bottom: -2.5px;
	`}
`;
const PlayerStats = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 400;
	font-size: 10px;
`;
const PlayerPos = styled.div`
	color: #1D4073;
	text-transform: uppercase;
`;
const PlayerCost = styled.div`
	color: #7F8A90;
	margin-left: 10px;
`;
const PlayerScore = styled.div`
	position: absolute;
	top: 5px;
	left: 7px;
	z-index: 3;
	color: ${getPlayerScoreColor};
	font-weight: 700;
	text-align: center;
	display: flex;
	align-items: center;
	flex-direction: column;
	min-width: 42px;
	${below.field_view_mid`
		left: 5px;
	`};
	p {
		font-size: 18px;
		${below.field_view_mid`
			font-size: 15px;
		`};
	}
	span {
		font-size: 8px;
		font-weight: 400;
		text-transform: uppercase;

		display: inline-flex;
		align-items: center;

		svg {
			padding-right: 3px;
    		padding-bottom: 2px;
			fill: ${getPlayerScoreColor};
		}
	}
	i {
		font-size: 10px;
	}

`;
const FcLink = styled.a`
	color: ${colors.coach};
	cursor: pointer;
`;

export class FieldPlayer extends React.Component<Props, State> {
	static defaultProps = {
		is_static: false,
		is_first_round_with_partial_lockout: false,
		is_current: true,
		bye_detector_active: false,
		bye_detector_selected: [],
		is_classic: true,
		trade_link: "",
		delist_link: "",
		has_emergencies: true,
		has_captain: true,
		setPlayerAction: () => null,
		locked_personnel: [],
		emergency_position: 0,
		is_utility: false,
		utility_position: 0,
		customScoringEnabled: false,
		cancelSubstitute: _.noop,
		pureSubstitutePlayer: _.noop,
	};

	constructor(props: Props, state: State) {
		super(props, state);

		_.bindAll(this, [
			"handleFlip",
			"openPlayerPopUp",
			"onDragStart",
			"onDrop",
			"onDragEnd",
		]);
	}

	state = {
		flipped: false,
	};

	componentDidMount() {
		const { flip } = this.props;
		this.setState({
			flipped: flip,
		});
	}

	componentDidUpdate(prev_props: Props) {
		const { flip } = this.props;
		const { flipped } = this.state;
		if (flipped !== flip && prev_props.flip !== flip) {
			this.setState({
				flipped: flip,
			});
		}
	}

	is_dnd = false;

	openPlayerPopUp() {
		const { showModalForPlayerId, player } = this.props;
		showModalForPlayerId(_.get(player, "id"));
	}

	handleFlip() {
		this.setState({
			flipped: !this.state.flipped,
		});
	}

	get is_transparent() {
		const {
			is_substitute,
			in_substitute,
			out_substitute,
			bye_detector_active,
			bye_detector_selected,
			is_partial_or_standard,
			player_bye,
			is_classic,
			player_partial_bye
		} = this.props;

		if(!is_classic){
			return false;
		}

		if (is_substitute) {
			return !(in_substitute || out_substitute);
		}

		if(is_partial_or_standard === "partial"){
			return bye_detector_active && !_.includes(bye_detector_selected, player_partial_bye);
		}

		return bye_detector_active &&
			!_.includes(bye_detector_selected, player_bye);
	}

	get get_leader_icon() {
		const {
			player: { id },
			captain,
			vice_captain,
			is_emergency,
		} = this.props;
		const is_captain = captain === id;
		const is_vice = vice_captain === id;

		if (is_captain) {
			return (
				<SecondaryStatusIconWrapper is_captain>
					<Captain/>
				</SecondaryStatusIconWrapper>
			);
		}
		if (is_vice) {
			return (
				<SecondaryStatusIconWrapper is_vice>
					<ViceCaptain/>
				</SecondaryStatusIconWrapper>
			);
		}
		if (is_emergency) {
			return (
				<SecondaryStatusIconWrapper is_emergency>
					{this.renderEmergencySymbol()}
				</SecondaryStatusIconWrapper>
			);
		}

		return null;
	}

	renderEmergencySymbol() {
		const {
			player,
			emergency_position,
			is_classic,
		} = this.props;

		const positions = _.get(player, "positions", []);

		if (!is_classic && emergency_position && _.size(positions) > 1) {
			return positionEmergencyButton(emergency_position);
		}

		return <Emergency/>;
	}

	isPlayerMatchFinished() {
		const { actual_round, player } = this.props;
		const squad_id = _.get(player, "squad_id");
		const matches = _.get(actual_round, "matches", []);

		const match = findMatch(matches, squad_id);
		const status = _.get(match, "status", "scheduled");

		return status === "complete";
	}

	getInitialScoreValue(has_coach: boolean, projected: number) {
		const {fantasyCoachModal} = this.props;
		return has_coach ? projected : <FcLink onClick={fantasyCoachModal}>
			<Lock size={1}/>
		</FcLink>;
	}

	getRoundDefaultValue(round_id: number) {
		return round_id >= 1 ? "DNP" : "- -";
	}

	handleDoubleScore(score_value: any) {
		const {double_score} = this.props;
		if(typeof score_value === "number"){
			return double_score ? score_value * 2 : score_value;
		}
		return score_value;
	}


	// eslint-disable-next-line complexity
	get player_points() {
		const {
			player_points,
			player,
			has_assistant_coach,
			selected_round,
			customScoringEnabled,
			actual_round
		} = this.props;
		const { is_playing_now } = player;
		const selected_round_id = _.get(selected_round, "id", 0);
		const is_current_round = selected_round_id === actual_round.id;
		const is_playing_this_round = isAllTrue([Boolean(is_playing_now), is_current_round]);
		let label: React.Node, opts: Object;
		let score_value = 0;
		if (player_points.includes("proj_score")) {
			let projected = _.get(player, player_points, DEFAULT_VALUE);
			if(customScoringEnabled){
				projected = _.get(player, 'custom_stats.proj_score_actual_round', DEFAULT_VALUE);
			}

			score_value = this.getInitialScoreValue(has_assistant_coach, projected);
			opts = {
				is_coach: true,
			};
			label = "Projected";
			if(this.isPlayerMatchFinished()){
				score_value = displayRoundScore(
					player, 
					selected_round, 
					customScoringEnabled, 
					has_assistant_coach
				);
				label = "FINAL";
				opts = {
					...opts,
					is_coach: false
				};
			}
		}
		else if (player_points.includes("points_last_round")) {
			const round_id = _.get(selected_round, "id", 0) - 1;
			const default_score_value = this.getRoundDefaultValue(round_id);
			const score_stat = 
        customScoringEnabled ? `custom_stats.scores.${round_id}` : `stats.scores.${round_id}`;
			score_value = _.get(player, score_stat, default_score_value);
			const is_dnp = score_value === "DNP";
			label = "LAST ROUND";
			opts = {
				is_dnp,
			};
		}
		else if (player_points.includes("round_score")) {
			score_value = displayRoundScore(
				player, 
				selected_round, 
				customScoringEnabled, 
				has_assistant_coach
			);
			const is_dnp = score_value === "DNP";
			label = this.getLastScoreLabel(player, score_value, has_assistant_coach);
			const checkIsLive = isAllTrue([is_playing_this_round, !is_dnp]);
			opts = {
				is_live: checkIsLive,
				is_dnp,
				is_coach: isAllTrue([!checkIsLive, label !== "FINAL", has_assistant_coach]),
			};
		}
		else if(player_points.includes("avg")){
			
			// Average
			score_value = numeral(_.get(player, player_points)).format("0");
			label = this.aveScoreLabel(player_points);
			opts = {
				is_ave: true,
			};
		
		}
	
		return (
			<PlayerScore {...opts}>
				<p>
					{this.handleDoubleScore(score_value)}
				</p>
				<span>
					{label}
				</span>
			</PlayerScore>
		);
	}

	getLastScoreLabel(player: TPlayerWithFixture, stat_value: any, has_assistant_coach: boolean) {
		const { selected_round } = this.props;
		return displayRoundLabel(player, selected_round, stat_value, has_assistant_coach);
	}

	aveScoreLabel(player_points: string) {
		if (player_points === "stats.avg_points") {
			return "AVERAGE";
		}
		else if (player_points === "stats.last_3_avg") {
			return "L3 AVG";
		}
		else if (player_points === "stats.last_5_avg") {
			return "L5 AVG";
		}
		return "";
	}

	renderByeHighlighter(reverse: boolean = false) {
		const {
			bye_detector_active,
			bye_detector_selected,
			player_bye,
			is_substitute,
			out_substitute,
			is_classic,
		} = this.props;
		const is_sub = is_substitute && out_substitute;
		const showing = !is_sub
			&& bye_detector_active
			&& _.includes(bye_detector_selected, player_bye);
		
		return <ByeHiglighter
			bye_active={!is_sub && bye_detector_active}
			bye_selected={bye_detector_selected}
			player_bye={player_bye}
			is_draft={!is_classic}
		>
			<ByeRoundTab player_bye={player_bye} showing={showing} reverse={reverse}>
				<span>{player_bye}</span>
			</ByeRoundTab>
		</ByeHiglighter>;
	}
	renderPartialByeHighlighter(reverse: boolean = false) {
		const {
			bye_detector_active,
			bye_detector_selected,
			is_substitute,
			out_substitute,
			is_classic,
			player_partial_bye
		} = this.props;
		const is_sub = is_substitute && out_substitute;
		const showing = !is_sub
			&& bye_detector_active
			&&  _.includes(bye_detector_selected, player_partial_bye)
			;
		
		
		return <ByeHiglighter
			bye_active={!is_sub && bye_detector_active}
			bye_selected={bye_detector_selected}
			player_bye={player_partial_bye}
			is_draft={!is_classic}
			is_partial_bye={true}
		>
			<ByeRoundTab 
				player_bye={player_partial_bye} 
				showing={showing} 
				reverse={reverse} 
				is_partial_bye={true} 
			>
				<Exist when={_.includes(bye_detector_selected, player_partial_bye)}>
					<span>{player_partial_bye}</span>
				</Exist>
			</ByeRoundTab>
		</ByeHiglighter>;
	}

	renderFieldPlayerBack() {
		const {
			player,
			is_utility,
			captain,
			vice_captain,
			is_substitute,
			out_substitute,
			utility_position,
			is_classic,
			actual_round,
		} = this.props;

		const { id = 0 } = player;
		const is_captain = captain === id;
		const is_vice = vice_captain === id;

		return out_substitute && this.is_dnd ? <PlayerPlaceholder is_draft={!is_classic} /> : (
			<FieldPlayerBackWrapper
				onDrop={this.onDrop}
				onDragOver={onDragOver}
				onDragEnter={onDragEnter}
				onDragLeave={onDragLeave}
				highlight={is_substitute && !out_substitute}
				is_draft={!is_classic}
			>
				{this.renderByeHighlighter(true)}
				{this.renderPartialByeHighlighter(true)}
				<FlipStyled onClick={this.handleFlip}/>
				<FieldPlayerBack
					{...this.props}
					is_captain={is_captain}
					is_vice={is_vice}
					openPopup={this.openPlayerPopUp}
					is_utility={is_utility}
					utility_position={utility_position}
					is_draft={!is_classic}
					actual_round={actual_round}
				/>
			</FieldPlayerBackWrapper>
		);
	}

	swapPlayers() {
		const { player, position, is_bench, is_utility } = this.props;

		this.props.pureSubstitutePlayer({
			id: player.id,
			position,
			is_bench,
			is_utility,
		});
	}

	onDragStart() {
		this.is_dnd = true;
		this.swapPlayers();
	}

	onDrop() {
		this.swapPlayers();
	}

	onDragEnd() {
		this.is_dnd = false;
		const active = document.activeElement;

		if (active && active.blur) {
			active.blur();
		}

		this.props.cancelSubstitute();
	}

	renderFieldPlayerFront() {
		const {
			player,
			captain,
			vice_captain,
			is_emergency,
			is_static,
			is_current,
			is_classic,
			is_locked,
		} = this.props;

		if (_.isEmpty(player)) {
			return null;
		}

		const { id = 0 } = player;
		const img_src = `${PLAYERS_PATH}${id}_450.png`;
		const img_src_webp = `${PLAYERS_PATH}${id}_450.webp`;
		const first_name = _.get(player, "first_name", [])[0];
		const { last_name = "" } = player;
		const is_captain = captain === id;
		const is_vice = vice_captain === id;

		return <FieldPlayerFront
			onDragStart={this.onDragStart}
			onDragEnd={this.onDragEnd}
			onDragOver={onDragOver}
			draggable={!is_locked}
			is_draft={!is_classic}
		>
			{this.renderByeHighlighter()}
			{this.renderPartialByeHighlighter()}
			{!IS_IE && <GradientWrapper>
				<FieldPlayerGradient/>
			</GradientWrapper>}
			{!is_static && <FlipStyled onClick={this.handleFlip}/>}
			{this.player_points}
			<PlayerAvatarWrapper onClick={this.openPlayerPopUp}>
				<PlayerAvatar
					is_captain={is_captain}
					is_vice={is_vice}
					is_emergency={is_emergency}
				>
					<picture>
						<source srcSet={img_src_webp} type="image/webp"></source>
						<source srcSet={img_src} type="image/png"></source>
						<img
							src={img_src_webp}
							alt={`${first_name}. ${last_name}`}
						/>
					</picture>
				</PlayerAvatar>
				{(!is_static || is_current) && (
					<StatusIconWrapper status={player.status} is_bye={player.is_bye}>
						<PlayerStatusIcon status={player.status} is_bye={player.is_bye}/>
					</StatusIconWrapper>
				)}
				{this.get_leader_icon}
			</PlayerAvatarWrapper>
			<PlayerNameWrapper>
				<PlayerName onClick={this.openPlayerPopUp}>
					{player.first_name} {player.last_name}
				</PlayerName>
			</PlayerNameWrapper>
			<PlayerStats>
				<PlayerPos>
					{_.get(player, "positions_name", []).join(" / ")}
				</PlayerPos>
				{is_classic && <PlayerCost>
					{currencyFormat({ input: player.cost })}
				</PlayerCost>}
			</PlayerStats>
		</FieldPlayerFront>;
	}

	get is_flip_allowed() {
		if (IS_IE) {
			const { out_substitute } = this.props;
			return (this.is_dnd && !out_substitute) || !this.is_dnd;
		}

		return true;
	}

	render() {
		const {
			player,
			is_static,
			is_classic,
		} = this.props;
		if (_.isEmpty(player)) {
			return null;
		}

		const { flipped } = this.state;

		return <FieldPlayerStyled
			is_opacity={this.is_transparent}
			is_draft={!is_classic}
		>
			{!IS_IE && <PlayerShadow is_draft={!is_classic}/>}
			{is_static ? this.renderFieldPlayerFront() : (
				<Flipcard 
					flipped={flipped 
					&& this.is_flip_allowed} 
					type="horizontal" 
					is_draft={!is_classic}>
					{this.renderFieldPlayerFront()}
					{this.renderFieldPlayerBack()}
				</Flipcard>
			)}
		</FieldPlayerStyled>;
	}
}

export default FieldPlayer;