// @flow
import React from "react";
import * as _ from "lodash";
import { Exist } from "../../../../components";
import {TDraftUserTeam, TPLeague} from "../../../../modules/types";
import Close from "../../../../components/Icons/Close";
import ReplaceCoach from "../leagueInvite/ReplaceCoach";
import { getIsRegenerated } from "../../../../helpers";
import {
	AssignTeamWrapper, 
	CloseButton,
	EditTeamButton,

} from "./LeagueTeamsStyles";


type Props = {
    index: number,
    currTeam: TDraftUserTeam,
    league: TPLeague,
    user_id: number,
    opened_assign_teams: number[],
    closeAssignedTeams: Function,
    addCoach: Function,
    new_coaches: any[],
    success: string,
    handleOpenedAssignedTeams: Function,

}
export const AssignTeamButtons = (props: Props) => {

	const { 
		league, 
		user_id, 
		currTeam, 
		opened_assign_teams, 
		index,
		closeAssignedTeams,
		addCoach,
		new_coaches,
		success,
		handleOpenedAssignedTeams
	} = props;
	const isTeamKeepers = _.size(_.get(currTeam, "keeper_list", [])) > 0;
	const isRegenerated = getIsRegenerated(league.regenerated_from,league.uf_regenerated_from);
	const is_league_keeper = 
			Boolean(league.keeper) 
			&& isRegenerated
			&& user_id === league.commissioner
			&& isTeamKeepers
			&& user_id !== currTeam.user_id
			&& league.draft_status !== "complete";

	const isOpen = opened_assign_teams.includes(index);

	const isFinalised = Boolean(league.teams_finalised);

	if(!is_league_keeper){
		return null;
	}

	return <AssignTeamWrapper isOpen={isOpen}>
		<Exist when={opened_assign_teams.includes(index)}>
			<React.Fragment>
				<CloseButton onClick={_.partial(closeAssignedTeams, index)} >
					<Close />
				</CloseButton>

				<ReplaceCoach 
					team={currTeam} 
					league={league} 
					addCoach={addCoach} 
					new_coaches={new_coaches}
					success={success}
				/> 

			</React.Fragment>

		</Exist>
		<Exist when={!opened_assign_teams.includes(index)}>
			<EditTeamButton 
				onClick={_.partial(handleOpenedAssignedTeams, index)}
				no_border={opened_assign_teams.includes(index)} 
				disabled={isFinalised}
			>
				Set New Coach For Keeper Team
			</EditTeamButton>
		</Exist>
		
	</AssignTeamWrapper>;



	
};