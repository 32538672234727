/* eslint max-len: 0 */
// @flow
import React from "react";
import SvgIconContainer from "../SvgIconContainer";

export default (
	{
		unit = "em",
		...rest
	}: Object = {}) => (
	<SvgIconContainer viewBox="0 0 24 24" {...rest} unit={unit}>
		<title>Vice Captain</title>
		<path d="M6,6 L9.66743243,6 L11.0376819,11.2430769 C11.2257563,11.8830801
        11.3869605,12.513843 11.5212994,13.1353846 C11.6556382,13.7569262 11.8168424,14.3938429
        12.0049168,15.0461538 L12.0855198,15.0461538 C12.2735941,14.3938429 12.4381568,13.7569262
        12.5792126,13.1353846 C12.7202684,12.513843 12.8781142,11.8830801 13.0527547,11.2430769
        L14.3827027,6 L17.9292308,6 L14.0602911,18 L9.86893971,18 L6,6 Z" />
	</SvgIconContainer>
);