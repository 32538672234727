// @flow
import styled from "styled-components";

export const WidgetHeader = styled.div`
	background: #818A8F;
	color: #FFF;
	width: 100%;
	box-sizing: border-box;
	min-height: 60px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-family: TitilliumUpright, Arial, sans-serif;
	font-weight: 700;
	font-size: 16px;
	padding: 10px;
	border-radius: 3px;
	margin-bottom: -1px;
	position: relative;
	z-index: 2;
`;

export default WidgetHeader;