import { createReducer } from "redux-act";

import * as actions from "../../actions";

const initial_state = {};

export const countries = createReducer({}, initial_state);

countries.on(actions.fetchCountriesSuccess, (state, payload) => ({
	...state,
	data: payload,
}));