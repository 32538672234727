// @flow
import * as React from "react";
import styled from "styled-components";

import type { TLockoutInfo } from "../../modules/types";

import colors from "../../assets/css/colors";
import { below } from "../../assets/css/media";

import CountdownTimer from "../CountdownTimer";
import LockoutOpen from "../Icons/LockoutOpen";
import LockoutFull from "../Icons/LockoutFull";
// import { Tooltip } from '../Form';

const Container = styled.div`
	box-sizing: border-box;
	flex: 0 0 350px;
	display: flex;
	align-items: center;
	padding: 0 30px;

	${below.field_view_large`
		flex: 0 0 250px;
	`}

	${below.desktop`
		flex: unset;
		padding: 0 10px;
	`}

	${below.phone`
		padding: 0;
	`}
`;

const CounterWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	.tooltip-icon {
		margin: 4px 0 0;
	}

	${below.desktop`
		> div {
			display: none;
			&:first-child {
				display: flex;
			}
		}
	`}
`;

const LockoutIconWrapper = styled.div`
	display: flex;
	align-items: center;
  justify-content: center;
	font-size: 30px;
	flex-direction: column;
	margin-right: 10px;
	color: ${colors.primary.primary};
	${below.tablet`
		font-size: 20px;
		padding-left: 10px;
		margin-right: 8px;
	`}
`;

const LockoutText = styled.div`
	font-size: ${({ small }) => small ? "12px" : "16px"};
	text-align: center;
	color: ${colors.primary.primary};
	margin-top: 10px;
	${({ small }) => small && "margin-top: 4px;"}
	padding-left: ${({ small }) => small ? "3px" : "10px"};
	> span {
		font-weight: 600;
	}
`;

// const rollingTooltip = () => <Tooltip 
// 	title="Rolling Lockout" 
// 	description="Your league has chosen to use a rolling lockout" 
// />;

const getLockoutText = (lockout_info: TLockoutInfo) => {
	const { 
		is_locked, 
		is_rolling, 
		is_first, 
		lockout_type,
		is_loading 
	} = lockout_info;

	if (is_loading) {
		return "";
	}
	if (is_locked) {
		return "Full Lockout";
	}

	if (is_rolling) {
		return is_first 
			? <React.Fragment>Until partial lockout</React.Fragment>
			: <React.Fragment>Until next partial lockout</React.Fragment>;
	}

	return is_first || lockout_type === "full"
		? <React.Fragment>
			Until&nbsp;
			{ lockout_type } lockout
		</React.Fragment>
		:  <React.Fragment>
			Until next&nbsp;
			{ lockout_type } lockout
		</React.Fragment>;
};

type Props = {
	lockout_info: TLockoutInfo
}

const LockoutCountdownTimer = ({ lockout_info }: Props) => {
	const { is_locked, next_match_date, is_loading } = lockout_info;

	return <Container>
		<LockoutIconWrapper>
			{!is_loading && (is_locked ? <LockoutFull /> : <LockoutOpen />) }
		</LockoutIconWrapper>
		<CounterWrapper>
			{ 
				!is_locked && Boolean(next_match_date) 
				&& <CountdownTimer date={next_match_date} small is_uppercase />
			}
			
			{/* { is_rolling && !is_locked && rollingTooltip() } */}
		</CounterWrapper>
		<LockoutText small={!is_locked}>
			{ getLockoutText(lockout_info) }
		</LockoutText>
	</Container>;
};

export default LockoutCountdownTimer;