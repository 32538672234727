// @flow
import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { bindAll, each, filter, get, every, identity } from "lodash";

import colors from "../../../assets/css/colors";
import type { TPLeague } from "../../../modules/types";

import * as actions from "../../../modules/actions";
import * as selectors from "../../../modules/selectors";

import facebookSdk from "../../../utils/facebookSdk";

import {
	ButtonSecondary,
	Footer,
	PageContentWrapper,
	TwoColumnLayout,
	TickMessage,
	Ad,
	StandardSidebar,
	AdsContainer,
	LeagueGameBar,
	ButtonSecondaryLink,
	PageTitle,
} from "../../../components";
import SocialFacebook from "../../../components/Icons/SocialFacebook";
import SocialTwitter from "../../../components/Icons/SocialTwitter";
import Link from "../../../components/Icons/Link";
import { below } from "../../../assets/css/media";
import { getIsRegenerated } from "../../../helpers";
import LeagueInviteEmail from "./leagueInvite/LeagueInviteEmail";

export const InvitePanel = styled.section`
	margin: 20px 20px 40px 0;

	border-radius: 2px;
	box-sizing: border-box;

	font-size: 14px;
	color: ${colors.primary.darkGrey};
	font-family: SourceSansPro;

	h3 {
		font-size: 18px;
		line-height: 2.2;
		color: ${colors.primary.primary};
		margin-top: 1.666em;
		&:first-child {
			margin-top: 0;
		}
	}
	p{
		color: ${colors.primary.primary};
	}


	${below.tablet`
		padding: 20px 30px;
	`};

	${below.phone`
		padding: 10px 15px;
	`};
`;

const StyledButtonSecondary = styled(ButtonSecondary)`
	background: white;
	border: 1px solid ${colors.primary.primary};
	color: ${colors.primary.primary};
`;

const SocialInviteLink = styled.button`
	width: 520px;
	height: 40px;
	line-height: 40px;
	border: 1px solid ${colors.secondary.paleGrey};
	box-sizing: border-box;
	border-radius: 2px;
	background-color: #fff;
	font-weight: 600;
	color: ${colors.secondary.accentGrey};
	font-size: 13px;
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	margin: 0 0 10px;
	padding: 0;
	&:before {
		content: '';
		display: block;
		width: 1px;
		height: 100%;
		position: absolute;
		left: 40px;
		top: 0;
		background-color: ${colors.secondary.paleGrey};
	}

	> svg {
		height: 40px;
		width: 40px;
		padding: 10px;
		box-sizing: border-box;
		margin: 0 11px 0 0;
		color: ${colors.primary.primary};
	}
	${below.phone`
		width: 100%;
	`}
`;

const RightTickMessage = styled.div`
	align-self: flex-end;
	margin: 0 1em 0 auto;
`;

const CopyCodeWrapper = styled.div`
	margin-top: 20px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
`;

type State = {
	code_copied: boolean,
	link_copied: boolean,
	regen_invites: Array<Object>
}

type Props = {
	showDraftLeague: typeof actions.leagueDraft.showDraftLeague,
	inviteToDraftLeague: typeof actions.leagueDraft.inviteToDraftLeague,
	clearDraftLeagueCreateErrors: typeof actions.leagueDraft.clearDraftLeagueCreateErrors,
	clearTeamErrors: typeof actions.teamsDraft.clearTeamErrors,
	league: TPLeague,
	new_league: boolean,
	match: {
		params: {
			league_id: string,
		}
	},
	user_id: number,
	invite_to: {
		is_pending: boolean,
		result: {
			result: boolean
		}
	},
	league_show: {
		error: string,
		league_id: number,
		is_pending: boolean,
	},
	regenerate_show: {
		error: string,
		is_pending: boolean,
		by_id: Object
	},
};

class LeagueInviteComponent extends React.Component<Props, State> {
	static defaultProps = {
		new_league: false,
	};
	constructor() {
		super();

		bindAll(this, [
			"handleSubmitEmails",
			"shareToFacebook",
			"shareToTwitter",
			"getShareUrl",
			"getShareLink",
		]);
	}

	state = {
		code_copied: false,
		link_copied: false,
		regen_invites: []
	};

	componentDidMount() {
		const { 
			clearTeamErrors,
			clearDraftLeagueCreateErrors,
			showDraftLeague,
			match
		} = this.props;
		clearDraftLeagueCreateErrors();
		clearTeamErrors();

		showDraftLeague({ id: match.params.league_id });
		facebookSdk();
	}

	componentDidUpdate(prev_props) {
		const {
			regenerate_show,
			league_show,
			league,
		} = this.props;
		const isRegenerated = getIsRegenerated(league.regenerated_from,league.uf_regenerated_from);
		// when this league is complete fetching
		if(prev_props.league_show.is_pending && !league_show.is_pending) {
			// check if it's a regen league
			if(isRegenerated) {
				// if regen leauge data is available
				if(league.regenerated_from in regenerate_show.by_id) {
					// populate
					const prev_league = regenerate_show.by_id[league.regenerated_from];
					this.setState({
						regen_invites: prev_league.users
					});
				}
				if(
					league.regenerate_show_uf &&
					league.uf_regenerated_from in league.regenerate_show_uf.by_id
				) {
					// populate
					const prev_league = league.regenerate_show_uf.by_id[league.regenerated_from];
					this.setState({
						regen_invites: prev_league.users
					});
				}
			}
		}
	}

	componentWillUnmount() {
		each(this.timeouts, clearTimeout);
	}

	timeouts = [];

	onCopy(key) {
		return () => this.timeouts.push(setTimeout(() => {
			// $FlowFixMe
			this.setState({ [key]: false });
		}, 5000));
	}

	getShareLink() {
		const { league } = this.props;
		const DRAFT_LEAGUE_SHARE_LINK = process.env.REACT_APP_DRAFT_LEAGUE_SHARE_LINK || "";
		return `${DRAFT_LEAGUE_SHARE_LINK}${league.code}`;
	};

	getShareUrl() {
		const SHARE_URL = process.env.REACT_APP_SHARE_URL || "";
		const { league, user_id } = this.props;

		const data_to_encode = {
			share_type: "league",
			league_id: league.id,
			league_name: league.name,
			team_id: league.team_id,
			user_id,
			tc: window.Date.now().toString().substr(-4),
			code: league.code
		};

		const encoded_data = btoa(encodeURIComponent(JSON.stringify(data_to_encode)));
		return `${SHARE_URL}draft_league_invite/${encoded_data}`;
	}

	shareToFacebook() {
		if (window.FB) {
			window.FB.ui({
				method: "share",
				display: "popup",
				href: this.getShareUrl()
			},
			identity
			);
		}
	}

	shareToTwitter() {
		const { league } = this.props;
		window.open(
			`https://twitter.com/intent/tweet?url=${encodeURIComponent(this.getShareUrl())}&text=${encodeURIComponent(`I've entered an AFL Fantasy Draft League, ${league.name}! There's room for more, so join the fun! #AFL #AFLFantasy`)}`, // eslint-disable-line
			"twitter-share-dialog",
			"width=626,height=436",
		);
	}

	handleSubmitEmails(values) {
		const invites = filter(
			values.invites,
			invite => every(
				[invite, get(invite, "email"), get(invite, "firstname")]
			)
		);

		const payload = {
			league_id: parseInt(this.props.match.params.league_id, 10),
			invites,
		};

		this.props.inviteToDraftLeague(payload);
	}

	get renderSocial() {
		return (
			<React.Fragment>
				<SocialInviteLink
					title="Share league on Facebook"
					onClick={this.shareToFacebook}>
					<SocialFacebook/>
					Facebook
				</SocialInviteLink>
				<SocialInviteLink
					title="Share league on X"
					onClick={this.shareToTwitter}>
					<SocialTwitter/>
					X (Formerly Twitter)
				</SocialInviteLink>
				<CopyToClipboard
					text={this.getShareLink()}
					onCopy={() => this.setState(
						{ link_copied: true },
						this.onCopy("link_copied"),
					)}
				>
					<SocialInviteLink title="Share league via Email">
						<Link/>
						Copy league link
						{this.state.link_copied && (
							<RightTickMessage>
								<TickMessage>Link Copied</TickMessage>
							</RightTickMessage>
						)}
					</SocialInviteLink>
				</CopyToClipboard>
			</React.Fragment>
		);
	}

	renderContent() {
		const { league, new_league } = this.props;

		return <InvitePanel>
			<h3>
				{`Great, now that you have created a league,
				invite others to join.`}
			</h3>
			<p>
				{league &&
				`The invitation PIN for ${league.name} is: ${league.code}`}
			</p>
			<CopyCodeWrapper>
				<CopyToClipboard
					text={league && league.code}
					onCopy={() => this.setState(
						{ code_copied: true },
						this.onCopy("code_copied"),
					)}
				>
					<StyledButtonSecondary>
						Copy PIN number
					</StyledButtonSecondary>
				</CopyToClipboard>
				{this.state.code_copied &&
					<TickMessage>Code Copied!</TickMessage>
				}
			</CopyCodeWrapper>

			<h3>
				Invite via email:
			</h3>
			<LeagueInviteEmail
				onSubmit={this.handleSubmitEmails}
				status={this.props.invite_to}
				regen_invites={this.state.regen_invites}
			/>
			<h3>
				Invite via social:
			</h3>
			{this.renderSocial}
			<br/>
			{new_league && <ButtonSecondaryLink
				to={`/draft/league/${this.props.match.params.league_id}/team-name/create`}
			>
				Skip this for now
			</ButtonSecondaryLink>}
		</InvitePanel>;
	}

	render() {
		const { league, user_id } = this.props;

		if (league && league.commissioner === user_id) {
			return (
				<React.Fragment>
					<LeagueGameBar no_fetch={true} />
					<AdsContainer>
						<Ad />
					</AdsContainer>
					<PageContentWrapper>
						<PageTitle>
							Invite people to join {league && league.name}
						</PageTitle>
						<TwoColumnLayout>
							{ this.renderContent() }
							<StandardSidebar />
						</TwoColumnLayout>
					</PageContentWrapper>
					<Footer />
				</React.Fragment>
			);
		}

		return null;
	}
}


const mapStateToProps = (state, props) => ({
	regenerate_show: state.leagues.regenerate_show,
	league_show: state.leagues.show,
	league: selectors.leagueDraft.getLeague(state, props),
	user_id: state.user.data.id,
	invite_to: state.leagues.invite_to,
});

const mapDispatchToProps = {
	clearDraftLeagueCreateErrors: actions.leagueDraft.clearDraftLeagueCreateErrors,
	clearTeamErrors: actions.teamsDraft.clearTeamErrors,
	showDraftLeague: actions.leagueDraft.showDraftLeague,
	inviteToDraftLeague: actions.leagueDraft.inviteToDraftLeague,
};

export const LeagueInvite = connect(mapStateToProps, mapDispatchToProps)(LeagueInviteComponent);

export default LeagueInvite;