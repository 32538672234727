// @flow
import { createReducer } from "redux-act";
import _, { get, uniqBy } from "lodash";
import * as actions from "../../actions";
import type { TClassicTeam } from "../../types";
import { ROUNDS_IN_AFL_SEASON } from "../../../helpers";
import { convertMadeTradeToPending } from "../../../components/Trades/helpers";

const FOUR_TRADE_WEEKS =  process.env.REACT_APP_FOUR_TRADE_ROUNDS || [];
const initial_trade_state = {
	out: 0,
	in: 0,
	position_index: -1,
	position: 0,
	is_bench: false,
	swap_ids: [],
	swap_formations: [],
	swap_position: 0,
	in_position: 0
};

let upperBoundTradeLimit = 3;

const initial_state = {
	show_my: {
		is_pending: false,
		is_team_saved: false,
		is_team_saving: false,
		error: null,
		team: {}
	},
	show: {
		is_pending: false,
		error: null,
		team: {}
	},
	snapshot_is_pending: false,
	snapshot: {},
	player_pool_statistic: [
		{ key: "stats.avg_points", value: "Average", short_name: "AVG" },
		{ key: "stats.total_points", value: "Total Points", short_name: "Tot Pts" },
		{ key: "stats.bye_round_id", value: "Bye Round", short_name: "BYE", is_coach: true },
		{ key: "partial_bye_round", value: "Early Bye", short_name: "EBR", is_coach: true},
		{ key: "cost", value: "Price", short_name: "Price" },
		{ key: "stats.proj_score", value: "Projected score", is_coach: true, short_name: "Proj" },
		{ key: "stats.break_even", value: "Breakeven", is_coach: true, short_name: "BE" },
		{ key: "stats.owned_by", value: "Teams Selected by %", short_name: "TS%" },
		{ key: "stats.cost_divided_by_points", value: "$ / Point", short_name: "$ / Pt" },
		{ key: "stats.points_last_round", value: "Last round", short_name: "Last" },
		{ key: "stats.last_3_avg", value: "3 Round Average", short_name: "L3 AVG" },
		{ key: "stats.last_5_avg", value: "5 Round Average", short_name: "L5 AVG" }
	],
	game_bar_statistic: [
		{ key: "stats.avg_points", value: "Average", short_name: "AVG" },
		{ key: "stats.proj_score", value: "Projected", is_coach: true, short_name: "PROJ" },
		{ key: "stats.points_last_round", value: "Last Round", short_name: "LAST" },
		{ key: "stats.round_score", value: "Live", short_name: "LIVE" },
	],
	trades: {
		filter_position_by_trade_player: null,
		trades: [],
		lineup: {},
		players_on_trade: 
      _.range(0, upperBoundTradeLimit).map(() => _.cloneDeep(initial_trade_state)),
		error: null,
		initial_trades_on_load: []
	},
	trade_history: {
		rounds: {},
		is_pending: false,
		error: null,
	},
	error: null,
	rankings: {
		is_pending: false,
		error: null,
		teams: []
	},
	season_history: {
		is_pending: false,
		error: null,
		season_data: {},
		history_statistic: {}
	}
};

export const teamsClassic = createReducer({}, initial_state);

teamsClassic.on(actions.fetchMyClassicTeam, state => ({
	...state,
	show_my: {
		...state.show_my,
		is_pending: true
	}
}));
teamsClassic.on(actions.fetchMyClassicTeamSuccess, (state, payload: TClassicTeam) => ({
	...state,
	show_my: {
		...state.show_my,
		is_pending: false,
		team: payload
	}
}));
teamsClassic.on(actions.fetchMyClassicTeamFailed, (state, payload) => ({
	...state,
	show_my: {
		...state.show_my,
		is_pending: false,
		error: payload
	}
}));

teamsClassic.on(actions.fetchClassicTeam, state => ({
	...state,
	show: {
		...state.show,
		error: null,
		is_pending: true
	}
}));
teamsClassic.on(actions.fetchClassicTeamSuccess, (state, payload: TClassicTeam) => ({
	...state,
	show: {
		is_pending: false,
		team: payload,
		error: null
	}
}));
teamsClassic.on(actions.fetchClassicTeamFailed, (state, payload) => ({
	...state,
	show: {
		...state.show,
		is_pending: false,
		error: payload
	}
}));

teamsClassic.on(actions.fetchClassicTeamSnapshot, (state, payload) => ({
	...state,
	snapshot_is_pending: true
}));

teamsClassic.on(actions.fetchClassicTeamSnapshotSuccess, (state, payload: TClassicTeam) => ({
	...state,
	snapshot_is_pending: false,
	snapshot: payload
}));
teamsClassic.on(actions.fetchClassicTeamSnapshotFailed, (state, payload) => ({
	...state,
	error: payload
}));

teamsClassic.on(actions.addPlayerToMyClassicTeamSuccess, (state, payload: TClassicTeam) => ({
	...state,
	show_my: {
		...state.show_my,
		team: {
			...state.show_my.team,
			...payload,
			is_team_saved: false
		}
	}
}));
teamsClassic.on(actions.removePlayerFromMyClassicTeamSuccess, (
	state, payload: TClassicTeam
) => ({
	...state,
	show_my: {
		...state.show_my,
		team: {
			...state.show_my.team,
			...payload,
			is_team_saved: false
		}
	}
}));
teamsClassic.on(actions.clearMyClassicTeamSuccess, (state, payload: TClassicTeam) => ({
	...state,
	show_my: {
		...state.show_my,
		team: {
			...state.show_my.team,
			...payload,
			is_team_saved: false
		}
	}
}));
teamsClassic.on(actions.saveMyClassicTeam, state => ({
	...state,
	show_my: {
		...state.show_my,
		is_team_saving: true,
		is_team_saved: false
	}
}));
teamsClassic.on(actions.saveMyClassicTeamSuccess, (state, payload: TClassicTeam) => ({
	...state,
	show_my: {
		...state.show_my,
		is_team_saving: false,
		is_team_saved: true,
		team: {
			...state.show_my.team,
			...payload
		},
	}
}));
teamsClassic.on(actions.saveMyClassicTeamFailed, (state, payload: TClassicTeam) => ({
	...state,
	show_my: {
		...state.show_my,
		is_team_saving: false,
		is_team_saved: false,
		error: payload
	}
}));

teamsClassic.on(actions.autoFillMyClassicTeam, state => ({
	...state,
	show_my: {
		...state.show_my,
		is_team_saving: true,
		is_team_saved: false
	}
}));
teamsClassic.on(actions.autoFillMyClassicTeamSuccess, (state, payload: TClassicTeam) => ({
	...state,
	show_my: {
		...state.show_my,
		is_team_saving: false,
		is_team_saved: true,
		team: payload
	}
}));
teamsClassic.on(actions.autoFillMyClassicTeamFailed, (state, payload: TClassicTeam) => ({
	...state,
	show_my: {
		...state.show_my,
		is_team_saving: false,
		is_team_saved: false,
		error: payload
	}
}));

teamsClassic.on(actions.updateMyClassicTeam, state => ({
	...state,
	show_my: {
		...state.show_my,
		is_team_saving: true,
		is_team_saved: false
	}
}));

teamsClassic.on(actions.forceUpdateMyClassicTeam, (state, payload: TClassicTeam) => ({
	...state,
	show_my: {
		...state.show_my,
		team: {
			...state.show_my.team,
			lineup: payload,
			// formation: payload.formation
		},
		is_team_saving: false,
		is_team_saved: false
	}
}));

teamsClassic.on(actions.clearErrorsMyTeam, state => ({
	...state,
	show_my: {
		...state.show_my,
		error: false
	}
}));

teamsClassic.on(actions.fetchMyClassicTrades, (state, payload) => {
	const from_edit = get(payload, 'from_edit', false);
	if(from_edit){
		return {
			...state,
			trades: {
				...state.trades,
				is_pending: true,
				from_edit,
				trades: [],
				players_on_trade: []
			}
		};
	}
	return {
		...state,
		trades: {
			...state.trades,
			is_pending: true,
			from_edit
		}
	};
});
teamsClassic.on(actions.fetchMyClassicTradesSuccess, (state, payload: Object) => {
	// convert existing to players_on_trade in edit page
	const standardPayload =  {
		...state.trades,
		is_pending: false,
		trades: payload,

	};
	if(state.trades.from_edit){
		const lineup = state.show_my.team.lineup || {};
		const players_on_trade = payload.reduce((tradeArr, trade) => {
			return [...tradeArr, convertMadeTradeToPending(trade, lineup)] ;
		}, []);
		// const initialPayload =  players_on_trade;
		const initialPlayersIn = payload.reduce((players, trade) => {
			return [...players, trade.new_player_id];
		}, []);

		return {
			...state,
			trades: {
				...standardPayload,
				players_on_trade,
				// trades: [],
				from_edit: false,
				initial_trades_on_load: initialPlayersIn
			}
		};
	}
	return{
		...state,
		trades: {
			...standardPayload,
			from_edit: false
		}
	};
});
teamsClassic.on(actions.fetchMyClassicTradesFailed, (state, payload) => ({
	...state,
	trades: {
		...state.trades,
		is_pending: false,
		error: payload
	}
}));

teamsClassic.on(actions.updateCompletedTrade, (state, payload) => {
	const existingTrades = state.trades.trades;

	const tradesOnceRemoved = existingTrades.filter(trade => trade.id !== payload.trade_id);
	const newTradesArr = _.uniqBy([...tradesOnceRemoved, payload.trade], 'id');

	if(newTradesArr.length === 1){
		newTradesArr.push(initial_trade_state);
	}
	return {
		...state,
		trades: {
			...state.trades,
			trades: newTradesArr,
			players_on_trade: newTradesArr
		}
	};
});

teamsClassic.on(actions.initialUpdateOfCompletedTrades, (state, payload) => {
	return {
		...state,
		trades: {
			...state.trades,
			players_on_trade: uniqBy(payload, 'id'),
			initial_load: true
		}
	};
});

teamsClassic.on(actions.fetchTradeHistory, state => ({
	...state,
	trade_history: {
		...state.trade_history,
		is_pending: true
	}
}));
teamsClassic.on(actions.fetchTradeHistorySuccess, (state, payload: Object) => ({
	...state,
	trade_history: {
		...state.trade_history,
		is_pending: false,
		rounds: payload
	}
}));
teamsClassic.on(actions.fetchTradeHistoryFailed, (state, payload) => ({
	...state,
	trade_history: {
		...state.trade_history,
		is_pending: false,
		error: payload
	}
}));

teamsClassic.on(actions.setInitialLineup, (state, payload) =>({
	...state,
	trades: {
		...state.trades,
		is_pending: false,
		lineup: {
			...payload
		}
	}
}));
teamsClassic.on(actions.updatePlayersOnTradeSuccess, (state, payload) => ({
	...state,
	trades: {
		...state.trades,
		is_pending: false,
		players_on_trade: payload,
	}
}));

teamsClassic.on(actions.resetClassicTradeSuccess, state => ({
	...state,
	trades: {
		...state.trades,
		players_on_trade: 
      _.range(0, upperBoundTradeLimit).map(() => _.cloneDeep(initial_trade_state)),
		lineup: {},
	}
}));

teamsClassic.on(actions.makeClassicTradeError, (state, payload) => ({
	...state,
	trades: {
		...state.trades,
		error: payload
	}
}));
teamsClassic.on(actions.clearClassicTradeError, state => ({
	...state,
	trades: {
		...state.trades,
		error: null
	}
}));

teamsClassic.on(actions.fetchRankings, state => ({
	...state,
	rankings: {
		...state.rankings,
		is_pending: true
	}
}));
teamsClassic.on(actions.fetchRankingsSuccess, (state, { result }: Object) => ({
	...state,
	rankings: {
		error: null,
		is_pending: false,
		teams: result
	}
}));
teamsClassic.on(actions.fetchRankingsFailed, (state, payload) => ({
	...state,
	rankings: {
		...state.rankings,
		is_pending: false,
		error: payload
	}
}));

teamsClassic.on(actions.loadMoreRankings, state => ({
	...state,
	rankings: {
		...state.rankings,
		is_pending: true
	}
}));
teamsClassic.on(actions.loadMoreRankingsSuccess, (state, { result }: Object) => ({
	...state,
	rankings: {
		error: null,
		is_pending: false,
		teams: _.uniqBy([ ...state.rankings.teams, ...result], "team_id")
	}
}));
teamsClassic.on(actions.loadMoreRankingsFailed, (state, payload) => ({
	...state,
	rankings: {
		...state.rankings,
		is_pending: false,
		error: payload
	}
}));

teamsClassic.on(actions.addFillerPositionByTradeUser, (state, payload: number) => ({
	...state,
	trades: {
		...state.trades,
		filter_position_by_trade_player: payload
	}
}));

teamsClassic.on(actions.removeFillerPositionByTradeUser, state => ({
	...state,
	trades: {
		...state.trades,
		filter_position_by_trade_player: null
	}
}));
teamsClassic.on(actions.fetchRoundsSuccess, (state, payload) => {
	const currentRound = payload.filter(round => round.status !== "complete")[0];
	const currentRoundPassed = !_.isEmpty(currentRound) 
		? currentRound : { id: ROUNDS_IN_AFL_SEASON }; 
	const currentTradeLimit = 
		FOUR_TRADE_WEEKS.includes(_.get(currentRoundPassed, "id", ROUNDS_IN_AFL_SEASON)) ? 4 : 3;
	upperBoundTradeLimit = currentTradeLimit;
	return ({
		...state,
	  trades: {
			...state.trades,
			players_on_trade: 
        _.range(0, currentTradeLimit).map(() => _.cloneDeep(initial_trade_state)),
			lineup: {}
		}
	});
});
teamsClassic.on(actions.fetchSeasonHistory, state => ({
	...state,
	season_history: {
		...state.season_history,
		is_pending: true
	}
}));
teamsClassic.on(actions.fetchSeasonHistorySuccess, (state, payload) => {
	return{
		...state,
		season_history: {
			...state.season_history,
			is_pending: false,
			season_data: payload.season_data,
			history_statistic: payload.history_statistic
		}
	};
});
teamsClassic.on(actions.fetchSeasonHistoryFailed, (state, payload) => {
	return{
		...state,
		season_history: {
			...state.season_history,
			is_pending: false,
			error: payload
		}
	};
});
teamsClassic.on(actions.editClassicTrade, state => {
	return {
		...state,
		trades: {
			...state.trades,
			is_pending: true,
			success: false
		}
	};
});

teamsClassic.on(actions.editClassicTradeSuccess, (state, payload) => {
	return{
		...state,
		trades: {
			...state.trades,
			is_pending: false,
			lineup: payload.lineup,
			success: true
		},
		show_my: {
			...state.show_my,
			team: payload
		}
	};
});
teamsClassic.on(actions.editClassicTradeFailed, (state, payload) => {
	return{
		...state,
		trades: {
			is_pending: false,
			error: payload.message
		}
	};
});
teamsClassic.on(actions.clearSuccessEditTrade, state => ({
	...state,
	trades: {
		...state.trades,
		success: false
	}
}));
teamsClassic.on(actions.updatePlayerSwap, (state, payload) => {
	let players_on_trade = state.trades.players_on_trade;
	const tradeIndex = players_on_trade.findIndex(pTrade => pTrade.id === payload.trade_id);
	const currentTrade = players_on_trade[tradeIndex];
	const currentSwapIdsEdit = get(currentTrade, "swap_ids_edit") || [];
	const filteredSwaps = [...currentSwapIdsEdit, payload.swap_id];
	if(tradeIndex !== -1){
	
		const updatedTrade = {
			...currentTrade,
			swap_ids_edit: filteredSwaps,
			swap_formations: [...currentTrade.swap_formations, payload.swap_formation]
		};
		players_on_trade[tradeIndex] = updatedTrade;
	}
	return {
		...state,
		trades: {
			...state.trades,
			players_on_trade
		}
	};
});

export default teamsClassic;