import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import * as actions from "../../modules/actions";

class GlobalRedirectComp extends React.Component {

	componentDidUpdate() {
		if(this.props.redirect.length) {
			this.props.globalRedirect( "" );
		}
	}

	render() {
		return this.props.redirect.length ? (
			<Redirect to={ this.props.redirect }/>
		) : null;
	}
}
const mapStateToProps = state => ({
	redirect: state.globalRedirect.redirect,
});

const mapDispatchToProps = {
	globalRedirect: actions.globalRedirect
};

export const GlobalRedirect = connect(
	mapStateToProps,
	mapDispatchToProps,
)(GlobalRedirectComp);

export default GlobalRedirect;