// @flow
import * as _ from "lodash";
export const getById = (state: Object): Object => {
	const in_league = state.leaguesClassic.ladder.ordered_ids;
	return _.pickBy(
		state.leaguesClassic.teams_by_id,
		team => _.includes(in_league, _.get(team, "id"))
	);
};

export const getOrdered = (state: Object): Array<Object> =>
	state.leaguesClassic.ladder.ordered_ids.map(team_id => getById(state)[team_id]);

export const isPending = (state: Object): boolean => state.leaguesClassic.ladder.is_pending;

export const getError = (state: Object) => state.leaguesClassic.ladder.error;

export const getOffset = (state: Object) => state.leaguesClassic.ladder.offset;

export const isLoadMore = (state: Object) => state.leaguesClassic.ladder.is_load_more;