// @flow
import React, {useEffect, useState} from "react";
import styled from "styled-components";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import * as actions from "../../../modules/actions";
import colors from "../../../assets/css/colors";
import {
	Footer,
	PageTitle,
	AdsContainer,
	Ad,
	PageContentWrapper,
	TwoColumnLayout,
	StandardSidebar,
	Preloader,
	Exist,
} from "../../../components";
import { ButtonSecondaryLink } from "../../../components/ButtonSecondary";
import Info from "../../../components/Icons/Info";
import { LeagueManagerComponent } from "../../../components/utils/LeagueManager/index";
import { IS_DISABLE_LIVE_DRAFT, LoadingStatus } from "../../../modules/constants";
import LeagueCreateForm from "./leaguesCreate/LeaguesCreateForm";
import RegenerateLeagues from "./leaguesCreate/regenerateLeagues";

type Props = {
	handleSubmit: Function,
	rounds_list: Array<Object>,
	league_create: {
		league_id: number,
	},
	subscriptionPlan: string,
	resetRegenerateShow: typeof actions.leagueDraft.resetRegenerateShow,
}

const StyledNotice = styled.div`
	width: 100%;
	padding: 20px;
	background: ${colors.primary.light};
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 20px;
	font-size: 14px;
	svg{
		height: 32px;
		width: 32px;
	}
	h1{	
		font-weight: 600;
		font-size: 24px;
		font-family: TitilliumUpright;
	}
	p{
		font-weight: 400;
		font-family: SourceSansPro;
	}
	color: ${colors.primary.primary};
	margin-bottom: 20px;
	

`;

const PageTitleStyled = styled(PageTitle)`
	font-size: 32px;
	display: flex;
	align-items: flex-end;

	svg{
		margin-right: .2em;
	}
`;

export const DraftLeagueCreateCom = ({
	league_create,
	subscriptionPlan
}: Props) => {
	const dispatch = useDispatch();
	const [loadingState,setLoadingState] = useState(LoadingStatus.LOADING);
	
	useEffect(() => {
		dispatch(actions.leagueDraft.resetRegenerateShow());
		setLoadingState(LoadingStatus.SUCCESS);
	}, [dispatch]);

	if(loadingState === LoadingStatus.LOADING) {
		return <Preloader />;
	}
	
	return (
		<div>
			{
				league_create.league_id && subscriptionPlan === "" ?
					<Redirect to={`/draft/league/${league_create.league_id}/invite/new`}/> :
					league_create.league_id && subscriptionPlan !== "" ?
						<Redirect to={`/draft/league/${league_create.league_id}/settings`} />
						:null
			}
			<AdsContainer>
				<Ad/>
			</AdsContainer>
			<PageContentWrapper>
				<PageTitleStyled>Create a Draft league</PageTitleStyled>
				<TwoColumnLayout>
					<div>
						<Exist when={!IS_DISABLE_LIVE_DRAFT}>
							<RegenerateLeagues />
							<LeagueManagerComponent is_draft={true} from="create">
								{({ rounds_list, handleSubmit, ...props }: Props) => (
									<React.Fragment>
										<LeagueCreateForm
											{...props}
											onSubmit={handleSubmit}
											rounds={rounds_list}
										/>
									</React.Fragment>
								)}
							</LeagueManagerComponent>
						</Exist>
						<Exist when={IS_DISABLE_LIVE_DRAFT}>
							<StyledNotice>
								<Info />
								<h1>Live Draft Notice</h1>
								<p>Live drafts are now disabled for season 2024 
									and can no longer be scheduled. 
									Please come back next season to create your next draft league
								</p>
								<ButtonSecondaryLink to="/game-selection">
									Game Selection
								</ButtonSecondaryLink>
							</StyledNotice>
						</Exist>
					</div>
					<StandardSidebar />
				</TwoColumnLayout>
			</PageContentWrapper>
			<Footer />
		</div>
	);
};

const mapStateToProps = state => {
	// eslint-disable-next-line max-len
	const subscriptionPlan = state.form.pro_subscription_form && state.form.pro_subscription_form.values
		? state.form.pro_subscription_form.values.plan:"";
	return ({
		league_create: state.leagues.create,
		subscriptionPlan
	});
};

export const DraftLeagueCreate = connect(
	mapStateToProps,
	null,
)(DraftLeagueCreateCom);

export default DraftLeagueCreate;