import styled from "styled-components";

export const ButtonsWrapper = styled.div`
	display: flex;

	> *:first-child {
		margin-right: 10px;
	}
`;

export default ButtonsWrapper;