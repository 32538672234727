// @flow
import styled from "styled-components";
import { below } from "../../../assets/css/media";

export const ListRowLabel = styled.div`
	height: 50px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	margin: 0;

	${below.desktop`
		margin-left: 10px;
	`};
`;

export default ListRowLabel;