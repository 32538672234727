// @flow
import React from "react";
import colors from "../../assets/css/colors";
import { PlayerWrapper } from "./PlayerWrapper";
import AddCard from "./AddCard";


type Props = {
};

class EmptyComparePlayer extends React.PureComponent<Props> {
	render(){
		return <PlayerWrapper>
			<AddCard 
				color={colors.secondary.paleGrey}
				background={colors.form.base}
				show_plus={true}
			/>
		</PlayerWrapper>;
	}
}

export default EmptyComparePlayer;