// @flow
import * as React from "react";
import _ from "lodash";

import Locked from "../../../components/Icons/Locked";
import { BorderedIcon, FlexDiv } from "../../../components";
import ErrorField from "../ErrorField";

import { Label, Select, FormGroup, Tooltip, LabelWrapper } from "../../Form";
import colors from "../../../assets/css/colors";
import { Exist } from "../../Exist";
import { BorderIconWrap } from "./../GenericFormStyles";

const getOptions = options => {
	return options.map(option => {
		if(_.get(option, "disabled", false)){
			return (
				<option
					key={option.value}
					value={option.value}
					disabled
					selected
					
				>
					{option.text}
				</option>
			);
		}
		return(
			<option
				key={option.value}
				value={option.value}
				className={!option.value ? "hidden" : null}
			>
				{option.text}
			</option>
		);
	});
};

const is_error = meta => {
	return !!(meta.touched && meta.error);
};

type Props = {
	label: string,
	name: string,
	options: Array<Object>,
	input: Object,
	is_disabled: boolean,
	meta: {
		touched: any,
		error: string,
		warning: string,
	},
	tooltip: {
		title: string,
		description: string,
	},
	handleSelectChange: Function
};

export const FormSelect = ( {
	label,
	name,
	options,
	input,
	tooltip,
	is_disabled,
	meta: {
		touched,
		error,
		warning,
	},
	handleSelectChange

}: Props ) => (
	<div>
		<LabelWrapper>
			<FlexDiv>
				<Exist when={is_disabled}>
					<BorderIconWrap>
						<BorderedIcon 
							padding='0' 
							hide_border={true} 
							background={colors.buttons.baseColor}
							size={1}
							className="disable-icon"
						>
							<Locked size={1.1} color='#ffffff' />
						</BorderedIcon>
					</BorderIconWrap>
				</Exist>
				<Label htmlFor={name} is_disabled={is_disabled}>{label}</Label>
			</FlexDiv>
			{tooltip ? (<Tooltip {...tooltip} />) : null}

		</LabelWrapper>

		{ touched && ((
			error && <ErrorField>{error}</ErrorField>) ||
			(warning && <ErrorField>{warning}</ErrorField>
			))
		}
		<FormGroup>
			{handleSelectChange ? <Select
				{...input}
				onChange={value => handleSelectChange(value,input.name)}
				name={name}
				is_disabled={is_disabled}
				is_error={is_error({ touched, error })}
			>
				{getOptions(options)}
			</Select> : <Select
				{...input}
				name={name}
				is_disabled={is_disabled}
				is_error={is_error({ touched, error })}
			>
				{getOptions(options)}
			</Select>}
		</FormGroup>
	</div>
);

export default FormSelect;