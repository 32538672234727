// @flow
import * as React from "react";
import styled from "styled-components";
import {
	ButtonPrimary,
	ButtonSecondary,
	LeagueAvatar,
	ModalContainer,
	ModalInnerTitle,
} from "../../../../components";
import type { TPLeague } from "../../../../modules/types";

import { below } from "../../../../assets/css/media";

const StyledButtonSecondary = styled(ButtonSecondary)`
	background-color: white;

	&:hover{
		background-color: red !important;
		color: white !important;
		border-color: red !important;
	}
`;

const LeagueAvatarWrapper = styled.div`
	text-align: center;
	margin: 1em 0;
	img {
		margin: 0 auto;
	}
`;

const JoinLeaguePopupFooter = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 1em 0 1.5em;

	${below.tablet`
		flex-direction: column-reverse;
	`}

	button {
		margin: 0 .5em;
		width: 200px;

		${below.tablet`
			margin: .5em;
			width: 265px;
			font-size: 10px;
		`}
		
		&.cancel{
			color: red;
			border-color: red;
		}
	}
`;

type Props = {
	league: TPLeague,
	join: Function,
	onClick: Function
};

export const JoinLeaguePopup = ({ league, join, onClick }: Props) => {

	if (!league) {
		return null;
	}

	return (
		<ModalContainer is_sponsored={true} onClick={onClick}>

			<LeagueAvatarWrapper>
				<LeagueAvatar is_classic league={league} size="100px" />
			</LeagueAvatarWrapper>

			<ModalInnerTitle>
				Are you sure you want to join {league.name}?
			</ModalInnerTitle>

			<JoinLeaguePopupFooter>
				<StyledButtonSecondary onClick={onClick} className='cancel'>
					No, cancel
				</StyledButtonSecondary>
				<ButtonPrimary onClick={join.bind(this, league.code)}>
					Yes, join this league
				</ButtonPrimary>
			</JoinLeaguePopupFooter>

		</ModalContainer>
	);
};

export default JoinLeaguePopup;