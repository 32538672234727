// @flow
import * as React from "react";
import styled from "styled-components";
import _ from "lodash";
import type {TPlayer, ById, TTeam} from "../../modules/types";

import PlayerTile from "./PlayerTile";

const TeamLineupContainer = styled.div`
	margin-bottom: 10px;
`;

const ColumnLayout = styled.div`
	display: flex;
	justify-content: space-between;
	flex-flow: wrap;
`;

const Column = styled.div`
	flex: 0 1 24%;
`;

const PositionHeading = styled.div`
	font-size: 12px;
	font-weight: 600;
	line-height: 1.4;
	color: #7F8A90;
	margin-top: 10px;
`;

type Props = {
	lineup: Object,
	team_breakdown_players: Object,
	className: string,
	is_classic: boolean,
	showModalForPlayerId: Function,
	actual_round_id: number
}

const mapPlayers = (player_ids, players): Object[] =>
	(Array.isArray(player_ids) && players) 
		? player_ids.map(player_id => players[player_id]) 
		: [];

const renderPositionPlayers = (
	players: TPlayer[], 
	showModalForPlayerId: Function,
	actual_round_id: number
) => players && players.map(player => {
	if (player) {
		return <PlayerTile 
			key={player.id} 
			showModalForPlayerId={showModalForPlayerId}
			actual_round_id={actual_round_id}
			{...player} 
		/>;
	}
	return null;
});

const renderBenchNoPosition = (bench: Object[], showModalForPlayerId: Function, actual_round_id:number) => {
	if (bench) {
		// This is to ensure that bench length is divisible by 4, for layout purposes.
		// Adds some extra elements if not
		const columns_divisible_by = 4;
		const to_be_divisble_add = columns_divisible_by - bench.length % columns_divisible_by;
		const add_columns = to_be_divisble_add === 4 ? 0 : to_be_divisble_add;
		const bench_extra = add_columns ?
			bench.concat(_.times(add_columns, String)) : bench;

		return bench_extra.map((player, i) => {
			if (player && player.id) {
				return <Column key={player.id}>
					<PlayerTile showModalForPlayerId={showModalForPlayerId} {...player} actual_round_id={actual_round_id}/>
				</Column>;
			}
			// This is to ensure a column is rendered when player
			// is not a player (for layout purposes)
			return <Column key={i} />;
		});
	}
};

const renderTeamPositions = (
	team: TTeam,
	players: ById<TPlayer>, 
	show_heading: boolean = true, 
	showModalForPlayerId: Function,
	actual_round_id: number
) => {
	const defenders = mapPlayers(team[1], players);
	const midfielders = mapPlayers(team[2], players);
	const rucks = mapPlayers(team[3], players);
	const forwards = mapPlayers(team[4], players);

	return <React.Fragment>
		<Column>
			{ show_heading && <PositionHeading>Defenders</PositionHeading> }
			{renderPositionPlayers(defenders, showModalForPlayerId, actual_round_id)}
		</Column>
		<Column>
			{show_heading && <PositionHeading>Midfielders</PositionHeading>}
			{renderPositionPlayers(midfielders, showModalForPlayerId, actual_round_id)}
		</Column>
		<Column>
			{show_heading && <PositionHeading>Rucks</PositionHeading>}
			{renderPositionPlayers(rucks, showModalForPlayerId, actual_round_id)}
		</Column>
		<Column>
			{show_heading && <PositionHeading>Forwards</PositionHeading>}
			{renderPositionPlayers(forwards, showModalForPlayerId, actual_round_id)}
		</Column>
	</React.Fragment>;
};

const TeamLineup = ({ 
	lineup, 
	team_breakdown_players, 
	className, 
	is_classic, 
	showModalForPlayerId,
	actual_round_id
}: Props) => {
	// TODO:: Maybe add message when `lineup` is empty
	if (_.isEmpty(lineup) || _.isEmpty(team_breakdown_players)) return null;

	const bench = is_classic ? [] : mapPlayers(lineup.bench, team_breakdown_players);

	return (
		<TeamLineupContainer className={className}>
			<ColumnLayout>
				{ renderTeamPositions(
					lineup, 
					team_breakdown_players, 
					true,
					showModalForPlayerId,
					actual_round_id
				) }
			</ColumnLayout>

			<PositionHeading>Interchange</PositionHeading>
			<ColumnLayout>
				{is_classic 
					? renderTeamPositions(
						lineup.bench, 
						team_breakdown_players, 
						false, 
						showModalForPlayerId,
						actual_round_id
					) : renderBenchNoPosition(bench, showModalForPlayerId, actual_round_id)}
			</ColumnLayout>
		</TeamLineupContainer>
	);
};

TeamLineup.displayName = "TeamLineup";

export default TeamLineup;