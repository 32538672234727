// @flow
export class SagaError extends Error {
	constructor(message: any, cause?: any) {
		super(message);
		this.name = "SagaError";
		this.cause = cause;
		if ("captureStackTrace" in Error)  {
			Error.captureStackTrace(this, SagaError);
		}
	}

	cause: any;
}