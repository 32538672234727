// @flow
import * as React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import * as selectors from "../modules/selectors";

const GameSelectionFavouriteRedirect = ({ game_selection_favourite }) => {
	if (game_selection_favourite) {
		const { game, type, id } = game_selection_favourite;
		const redirect_url = type === "team" ?
			`/${game}/${type}` :
			`/${game}/${type}/${id}`
		;
		return <Redirect to={redirect_url} />;
	}

	return <Redirect to="/game-selection" />;
};

const mapStateToProps = (state, props) => ({
	game_selection_favourite: selectors.uiGameSelection.getFavourite(state)
});

export default connect(mapStateToProps, null)(GameSelectionFavouriteRedirect);