import TagManager from "react-gtm-module";
import isMobile from "./isMobile";

TagManager.initialize({
	gtmId: process.env.REACT_APP_GTM_SECOND_CONTAINER_ID
});

export const dataLayer = params => {
	TagManager.dataLayer({
		dataLayer: {
			"platformType": isMobile.any() ? "mobile": "desktop",
			"environment" : process.env.REACT_APP_GTM_ENVIRONMENT, // dev, prod
			"siteDelivery" : "responsive",
			"digitalHierLevel1":"Digital Rights Network",
			"digitalHierLevel2":"Elite",
			"digitalHierLevel3":"AFL Other",
			"loginSubType": "Fantasy",
			"event":"pageview",

			// Possible dynamic params:
			// 'pageName':'Unknown', // for example: /my-team/player-1
			// 'loginType': 'Fantasy', // Facebook, email
			// 'siteSection': 'Unknown', // for example: my-team
			// 'contentType': 'Unknown', // classic draft
			// 'matchStatus': 'Unknown', //live, pre, post
			// 'loginStatus': 'Unknown', // logged in or logged out
			...params,
		}
	});
};

export default dataLayer;