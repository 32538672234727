// @flow
import type { TRound } from "../../modules/types";
import type { TStat } from "../PlayerProfile/printStat";
import * as format from "../../helpers/numeralFormats";

export type TBarStat = TStat & {
	max: number,
	min: number,
	/* Whether or not the stats bar should be flipped so the least value wins */
	order_desc?: boolean,
	/* Whether or not the stat is 'winnable' by a player - i.e. bye round */
	not_winnable?: boolean
};

export const compareStats = (round: TRound, 
	statsBasis: string, 
	isDraft: boolean): Array<TBarStat> => ([
	{
		name: "Games Played",
		id: `${statsBasis}.games_played`,
		min: 0,
		max: round.id
	},
	{
		name: "Last Round Score",
		id: `${statsBasis}.points_last_round`,
		min: 0,
		max: 200
	},
	{
		name: "Average Score",
		id: `${statsBasis}.avg_points`,
		min: 0,
		max: 200
	},
	{
		name: "Price",
		id: "cost",
		format: format.SHORT_PRICE,
		min: 0,
		max: 1e6,
		not_draft: true,
	},
	{
		name: "Total Points",
		id: `${statsBasis}.total_points`,
		min: 0,
		max: 200 * (round.id - 1)
	},
	{
		name: "Last 3 Average",
		id: `${statsBasis}.last_3_avg`,
		min: 0,
		max: 200
	},
	{
		name: "Last 5 Average",
		id: `${statsBasis}.last_5_avg`,
		min: 0,
		max: 200
	},
	{
		name: "Last Round Price Change",
		id: "stats.cost_round_diff",
		format: format.SHORT_PRICE,
		min: 0,
		max: 3e5,
		not_draft: true
	},

	{
		name: "Time on Ground Percentage",
		id: "stats.tog",
		format: format.PERCENTAGE,
		min: 0,
		max: 1
	},
	{
		name: "Highest Season Score",
		id: `${statsBasis}.high_score`,
		min: 0,
		max: 200
	},
	{
		name: "Lowest Season Score",
		id: `${statsBasis}.low_score`,
		min: -20,
		max: 200,
	},
	{
		name: "Season Price Change",
		id: "stats.cost_season_diff",
		format: format.SHORT_PRICE,
		min: -1e6,
		max: 1e6,
		not_draft: true
	},

	{
		name: "Last Round Rank",
		id: `${statsBasis}.ranks.${round.id - 1}`,
		min: 0,
		max: 900,
		order_desc: true
	},
	{
		name: "Season Rank",
		id: `${statsBasis}.season_rank`,
		min: 0,
		max: 900,
		order_desc: true
	},
	{
		name: "Ownership Percentage",
		id: isDraft ? "stats.leagues_rostered" : "stats.owned_by",
		format: format.PERCENTAGE,
		min: 0,
		max: 1
	},
	{
		name: "Price per Point",
		id: "stats.cost_divided_by_points",
		format: format.DEC_PRICE,
		min: 0,
		max: 50000,
		order_desc: true,
		not_draft: true
	},

	{
		name: "Next Projected Score",
		id: statsBasis === "custom_stats" 
			? "custom_stats.proj_score_next_round" : "stats.proj_score",
		is_coach: true,
		min: 0,
		max: 200,
	},
	isDraft ? {
		name: "Average Against Next Opponent",
		id: `${statsBasis}.opp_avg_one_round_after_current`,
		is_coach: true,
		min: 0,
		max: 200
	} :
		{
			name: "Projected Price Change",
			id: "stats.proj_price_change_next_round",
			is_coach: true,
			format: format.SHORT_PRICE,
			min: -5e5,
			max: 5e5,
		},
	isDraft ? {
		name: "Average at upcoming venue",
		id: `${statsBasis}.venue_avg_one_round_after_current`,
		max: 200,
		min: 0,
		is_coach: true,
	} :
		{
			name: "Break Even",
			id: "stats.break_even",
			is_coach: true,
			min: -100,
			max: 300,
		},
	{
		name: "Consistency",
		id: `${statsBasis}.consistency`,
		is_coach: true,
		min: 0,
		max: 100,
	},
	{
		name: "Bye Round",
		id: "stats.bye_round_id",
		is_coach: true,
		max: 0,
		min: 0,
		not_winnable: true
	},
]);