// @flow
import styled from "styled-components";

export const TradeSectionWrapper = styled.div`
  @media (max-width: 780px) {
    ${props => props.is_active === true && `
			display: block
		`};
    ${props => props.is_active === false && `
			display: none
		`};
		margin: 0;
		width: 100%;
	}
`;

export const TabWrapper = styled.div`
	margin-bottom: 40px;
	width: fit-content;
	border-bottom: 1px solid #333333;
	@media (max-width: 780px) {
		width: 100%;
		padding: 0;
	}
`;
export const MobileContentTabs = styled(TabWrapper)`
	display: none;
	@media (max-width: 780px) {
		display: block;
		margin-bottom: 1px;
	}
`;



export const Tabs = styled.div`
	display: flex;
`;
export const Tab = styled.div`
	font-family: SourceSansPro;
  font-weight: 600;
	font-size: 14px;
	padding-right: 25px;
	padding-bottom: 12px;
  text-align: center;
	cursor: pointer;
  ${props => props.is_active === true && `
    color: #1D4073;
    border-bottom: 3px solid #1D4073;
  `}
	@media (max-width: 780px) {
		flex: 1;
	}
`;

export const TradeWrapper = styled.div`
  @media(max-width: 780px){
    width: 100%
  }
`;