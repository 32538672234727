// @flow
import React, {useState} from "react";
import { NavLink, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SEASON_YEAR } from "../../../modules/constants";
import { Landing } from "../../../components/Landing";
import { Exist, InputItem, Preloader } from "../../../components";
import { forgotPassword } from "../../../modules/actions";
import { isForgotPasswordPending, isForgotPasswordSuccess } from "../../../modules/selectors";
import {
	AFLiDButonWrap,
	AFLIDButton,
	AFLIDButtonDark,
	AFLiDModalContentWrapper,
	InputWrap,
	ModalContent,
	ModalContentNoPadMob,
	ModalInputWrap,
	ModalSpan,
	ModalWrap,
	PageTitleStyled,
	SuccessContent
} from "./../afliDModalStyles";


function AfliDForgotPassword () {
	const dispatch = useDispatch();
	const history = useHistory();
	const forgotPasswordSuccess = useSelector(isForgotPasswordSuccess);	
	const forgotPasswordPending = useSelector(isForgotPasswordPending);	
	const [resetPassword,setResetPassword] = useState("");
	
	const handleCancel = event => {
		event.preventDefault();
		history.goBack();
	};
	const handleResetPassword = event => {
		setResetPassword(event.target.value);
	};

	const sendForgetPasswordReq = event => {
		event.preventDefault();
		dispatch(
			forgotPassword({email:resetPassword})	
		);
	};
	
	return <Landing>
		<AFLiDModalContentWrapper>
			<PageTitleStyled>Password Reset</PageTitleStyled>
			<ModalWrap>
				<ModalContent>
			To reset the password of your old AFL Fantasy account, 
			enter the email address you used to use to play AFL 
			Fantasy. We will email you a link to reset your password.
				</ModalContent>
				<form onSubmit={sendForgetPasswordReq}>
					<ModalInputWrap>
						<InputWrap>
							<InputItem
								name="resetPassword"
								placeholder={`Enter ${SEASON_YEAR - 1} Registered Email Address`}
								type="email"
								required
								onChange={handleResetPassword}
								value={resetPassword}
							/>
						</InputWrap>
					</ModalInputWrap>
					<ModalContentNoPadMob>
						<ModalSpan>
					PLEASE NOTE: This is to reset your old AFL Fantasy password so 
					you can link that account to your AFL iD. This will not impact 
					your AFL iD password.
						</ModalSpan>
					</ModalContentNoPadMob>
					<Exist when={!forgotPasswordSuccess}>
						<AFLiDButonWrap>
							<AFLIDButton onClick={handleCancel}>Cancel</AFLIDButton>
							<AFLIDButtonDark>Next</AFLIDButtonDark>
						</AFLiDButonWrap>
					</Exist>
					<Exist when={forgotPasswordSuccess}>
						<SuccessContent>
							Email sent,{" "}
							please check your inbox.{" "}
							<NavLink to="/login/link-old-account">
								Back to Link Screen.
							</NavLink>
						</SuccessContent>
					</Exist>
					<Exist when={forgotPasswordPending}>
						<Preloader />
					</Exist>
				</form>
			</ModalWrap>
		</AFLiDModalContentWrapper>
	</Landing>;
}

export default AfliDForgotPassword;