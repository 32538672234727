// @flow
import React, { Component } from "react";
import styled from "styled-components";

import type { TRound } from "../../modules/types";
import TeamUserAvatar from "../Team/TeamUserAvatar";

import { ButtonSecondaryLink } from "../../components/ButtonSecondary";

import colors from "../../assets/css/colors";
import { above, below } from "../../assets/css/media";
import { getShortName } from "../../helpers/league";

const TeamUserAvatarStyled = styled(TeamUserAvatar)`
	position: relative;
	width: ${({ compact }) => compact ? "40px" : "60px" };
	height: ${({ compact }) => compact ? "40px" : "60px" };
	
	${below.phone`
		margin-bottom: 10px;
	`}
`;

const FixtureWrapper = styled.div`
	background: ${colors.form.advanced_color};
	padding: ${({ compact }) => compact ? "5px 10px" : "10px" };
	height: ${({ compact }) => compact ? "60px" : "120px" };

	display: flex;
	justify-content: space-between;
	align-items: center;

	${({ shadow }) => shadow && `box-shadow: ${colors.secondary.paleGrey}`}
`;

const Score = styled.div`
	font-size: 22px;
	line-height: 1.27;
	font-weight: bold;
	letter-spacing: -0.5;
	color: ${({ live }) => live ? colors.success : colors.primary.primary};
	${({ right }) => right && "text-align: right;"}

	${above.phone`
		font-size: ${({ compact }) => compact ? "22px" : "26px" };
		line-height: 1.2;
	`}
`;

const ProjectedScore = styled.div`
	font-size: 12px;
	line-height: 1;
	font-weight: bold;
	color: ${colors.coach};
`;

const ProjectedScoreContainer = styled.div`
	display: inline-block;
	border: 1px solid ${colors.coach};
	border-radius: 10px;
	padding: 3px 10px;
	${({ right }) => right && "float: right;"}
`;

const ScoreWrapper = styled.div`
	flex: 0 0 50%;
	margin-bottom: 5px;

	display: flex;
    flex-direction: column;
    justify-content: center;
	align-items: ${({ right }) => right ? "flex-end" : "flex-start" };

	${above.phone`
		flex: 1 2 20%;
	`}

	${above.tablet`
		margin: 0;
	`}
`;

const MatchUpButton = styled(ButtonSecondaryLink)`
	width: 100%;
	background: ${colors.secondary.paleGrey};
	color: ${colors.primary.primary};
	border-radius: 0;
	border: none;

	${above.tablet`
		width: auto;
	`}
`;

const AboveTabletCenterWrapper = styled.div`
	display: flex;
	flex: 0 1 42%;
	align-items: center;

	${below.tablet`
		display: none;
	`};
`;

const BelowTabletCenterWrapper = styled.div`
	display: flex;
    flex-wrap: wrap;

	${above.tablet`
		display: none;
	`};
`;

const TeamName = styled.div`
	font-size: 16px;
	line-height: 1.2;
	font-weight: bold;
	color: ${colors.primary.primary};
	margin-bottom: 0.43em;

	text-overflow: ellipsis;
    overflow: hidden;
	max-width: 60px;

	${above.phone`
		max-width: 110px;
	`}

	${above.tablet`
		max-width: 130px;
	`}

	${above.desktop`
		max-width: 150px;
	`}

	${below.phone`
		text-align: center;
		font-size: 12px;
	`}
`;

const UserName = styled.div`
	font-size: 14px;
	line-height: 1.2;
	color: ${colors.secondary.accentGrey};

	${below.phone`
		display: none;
	`}
`;

const LadderLabel = styled.div`
	font-size: 14px;
	line-height: 1.28;
	color: ${colors.primary.darkGrey};

	strong {
		font-weight: bold;
	}

	${below.phone`
		display: none;
	`}
`;

const TeamDetailsWrapper = styled.div`
	display: flex;
	justify-content: ${({ right }) => right ? "flex-end" : "flex-start"}
	flex-direction: column;

	${below.phone`
		justify-content: center;
	`}
`;

const SideWrapperStyles = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	flex: 0 1 25%;

	${below.phone`
		align-self: flex-start;
	`}

	${above.phone`
		flex-direction: row;
		flex: 0 1 40%;
	`}

	${above.tablet`
		flex: 0 1 25%;
	`}
`;

const HomeSideWrapper = styled(SideWrapperStyles)`
    margin-right: 4%;

	${TeamUserAvatarStyled} {
		margin-right: 10px;
	}
`;

const AwaySideWrapper = styled(SideWrapperStyles)`
	flex-direction: column-reverse;
	justify-content: flex-end;
	margin-left: 4%;

	${above.phone`
		flex-direction: row;
	`}

	${TeamUserAvatarStyled} {
		margin-left: 10px;
	}

	${TeamDetailsWrapper} {
		align-items: flex-end;
	}
`;

type TLeague = {
	id: number,
	bye_rounds: number,
	status: string
}

type TTeam = {
	id: number,
	name: string,
	firstname: string,
	lastname: string,
	points: number,
	projected_points: number,
	rank: number,
	lineup: {
		"1": Array<number>,
		"2": Array<number>,
		"3": Array<number>,
		"4": Array<number>,
		"5": Array<number>,
		"6": Array<number>,
		bench?: Array<number>,
		captain?: number,
		vice_captain?: number,
		emergency?: Object
	},
	avatar_version: number,
	user_id: number
}

type TFixture = {
	home: TTeam,
	away: TTeam
}

type Props = {
	league: TLeague,
	fixture: TFixture,
	round: TRound,
	shadow?: boolean,
	compact?: boolean,
	className?: string,
	has_assistant_coach: any
}

class Fixture extends Component<Props> {
	get is_scheduled() {
		const { round } = this.props;
		return round.status === "scheduled";
	}

	get is_live() {
		const { round } = this.props;
		return round.status === "active";
	}

	get show_projected_score() {
		const { has_assistant_coach } = this.props;
		return (this.is_scheduled || this.is_live) && has_assistant_coach;
	}

	renderHomeScoreSection = () => {
		const { fixture: { home }, compact } = this.props;

		return (
			<ScoreWrapper>
				<Score live={this.is_live} compact={compact}>{home.points || "-"}</Score>
				{this.show_projected_score && (
					<ProjectedScoreContainer>
						<ProjectedScore>{home.projected_points}</ProjectedScore>
					</ProjectedScoreContainer>
				)}
			</ScoreWrapper>
		);
	};

	renderAwayScoreSection = () => {
		const { fixture: { away }, compact } = this.props;

		return (
			<ScoreWrapper right>
				<Score right compact={compact} live={this.is_live}>{away.points || "-"}</Score>
				{this.show_projected_score && (
					<ProjectedScoreContainer right>
						<ProjectedScore>{away.projected_points}</ProjectedScore>
					</ProjectedScoreContainer>
				)}
			</ScoreWrapper>
		);
	};

	renderMatchupButton = () => {
		const { league, round, fixture: { away, home } } = this.props;

		return (
			<MatchUpButton
				to={`/league/${league.id}/matchup/${round.id}/${home.id}/${away.id}`}
			>
				Go to match up
			</MatchUpButton>
		);
	};

	renderTeamUserAvatar = (team: TTeam) => {
		const { compact } = this.props;
		return (
			<TeamUserAvatarStyled
				compact={compact}
				user_id={team.user_id}
				avatar_version={team.avatar_version}
				className='user-avatar'
			/>
		);
	};

	renderTeamDetails = (team: TTeam, align_right?: boolean) => {
		const { compact } = this.props;

		return (
			<TeamDetailsWrapper right={align_right}>
				<TeamName>{team.name}</TeamName>
				<UserName>{getShortName(team)}</UserName>
				{!compact && (
					<LadderLabel>
						Ladder Position <strong>{team.rank}</strong>
					</LadderLabel>
				)}
			</TeamDetailsWrapper>
		);
	};

	render() {
		const { fixture: { home, away }, shadow, compact, className } = this.props;

		return (
			<FixtureWrapper className={className} shadow={shadow} compact={compact}>
				<HomeSideWrapper>
					{this.renderTeamUserAvatar(home)}
					{this.renderTeamDetails(home)}
				</HomeSideWrapper>

				<BelowTabletCenterWrapper>
					{this.renderHomeScoreSection()}
					{this.renderAwayScoreSection()}
					{this.renderMatchupButton()}
				</BelowTabletCenterWrapper>

				<AboveTabletCenterWrapper>
					{this.renderHomeScoreSection()}
					{this.renderMatchupButton()}
					{this.renderAwayScoreSection()}
				</AboveTabletCenterWrapper>

				<AwaySideWrapper>
					{this.renderTeamDetails(away, true)}
					{this.renderTeamUserAvatar(away)}
				</AwaySideWrapper>
			</FixtureWrapper>
		);
	}
}

export default Fixture;