// @flow
import styled from "styled-components";
import colors from "../../assets/css/colors";
import { below } from "../../assets/css/media";

export const ButtonFacebook = styled.a`
	width: 200px;
	height: 40px;
	margin-top: 1em;
	background: ${colors.facebook};
	display: flex;
	justify-content: center;
	align-items: center;
	line-height: .9;
	outline: none;
	border: none;
	border: 1px solid ${colors.facebook};
	color: #ffffff;
	font-size: 14px;
	letter-spacing: 0.5px;
	cursor: pointer;
	border-radius: 3px;
	text-decoration: none;
	margin: 1em 0;
	opacity: ${({ is_disabled }) => is_disabled ? ".5" : "1"};
  ${below.phone`
    width: 320px;
  `}
`;

export default ButtonFacebook;