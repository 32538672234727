// @flow
import React from "react";
import numeral from "numeral";
import _ from "lodash";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { coach_player_stats } from "../../helpers/stats";
import type { TCoachPlayerStatsById } from "../../modules/types/player";
import { TBody, Td, Tr } from "../TableX";
import Lock from "../Icons/Lock";
import * as selectors from "../../modules/selectors";
import { score_settings } from "../../helpers";
import {TPLeague, TRoundsReducer} from "../../modules/types";
import {isDraftComplete} from '../../helpers/league/index';

import {
	ButtonLoadMore,
	DraftFilters,
	PlayersTableHeader,
	PlayerInfo,
	TdDraftOwner,
	TdNext,
	FantasyCoachModal,
} from "../";

import type {
	TCoachStatsListReducer,
	TPlayer,
	TPlayerStatusesReducer,
	TPosition,
	TRootStore,
	TSquad,
	TRound
} from "../../modules/types";
import * as actions from "../../modules/actions";
import colors from "../../assets/css/colors";
import { renderTableHeaderCell } from "../../helpers/tables";
import EmptyMessage from "./emptyMessage";

import {
	Spacer,
	FcLink,
	Table,
	TableWrapper,
	TdYourTeam,
	StyledTr,
	ThStat,
	Th,
	TdStat,
	TableHead
} from "./PlayersTableStyles";
import { PlayerRowButtons } from "./PlayerRowButtons";




numeral.nullFormat("- -");

const getCompressedViewStatus = () => {
	if(localStorage.getItem("compressed_view")){
		return localStorage.getItem("compressed_view") === "true" ? true : false;
	}
	localStorage.setItem("compressed_view", "false");
	return false;
};

type TableColumn = { key: string, prop: string, tooltip?: string, is_fc?: boolean };

type Props = {
	match: {
		params: {action: string, type: string}
	},
	all_stats: Object,
	league: TPLeague,
	players: Array<TPlayer>,
	fetchWaiverList: typeof actions.leagueDraft.fetchWaiverList,
	fetchWaiverFree: typeof actions.leagueDraft.fetchWaiverFree,
	fetchAllStats: typeof actions.fetchAllStats,
	is_pending_players: boolean,
	positions: Array<TPosition>,
	squads: Array<TSquad>,
	rounds: TRoundsReducer,
	playerStatuses: TPlayerStatusesReducer,
	actual_round: TRound,
	last_round: Object,
	table: {
		basic: TableColumn[],
		coach: Object,
	},
	coach_stats_list: TCoachStatsListReducer,
	has_assistant_coach: boolean,
	coach_players_by_id: TCoachPlayerStatsById,
	waiver_requests: string,
	addOutTrade: Function,
	addInTrade: Function,
	cancelTradeRequest: Function,
	removeOutPlayer: Function,
	removeInPlayer: Function,
	only_fa: boolean,
	only_teams: boolean,
	is_league_started: boolean,
	addOwnerFilter?:boolean,
	show_next?: boolean,
	current_user_id: number,
	data_loading_complete: boolean,
	is_classic: boolean,
	fa:Object
}

type TFilters = {
	search: string,
	by_squad: string,
	by_position: string,
	by_status: string,
	by_dpp: boolean,
	by_favourites: boolean,
	by_drafted: boolean,
	by_fa: boolean,
	by_owner: string
}

type State = {
	order_by_desc: boolean,
	filters: TFilters,
	sort_by?: string,
	order_by_desc?: boolean,
	players_show_limit: number,
	players_show_limit_step: number,
	compressed_view: boolean,
	player_row_menu_ids: Array<number>,
	active_stats_type: string,
	buttons_open: Array<number>,
	fa_trade: {
		new_player_id: number,
		old_player_id: number
	},
	team_trade: {
		new_player_id: Array<number>,
		old_player_id: Array<number>
	},
	is_fa_trade: boolean,
	is_team_trade: boolean,
	show_team: boolean,
	show_fantasy_coach_modal: boolean,
	
};

class PlayersComponent extends React.Component<Props, State> {
	static defaultProps = {
		players: [],
		// actual_round: {},
		table: {
			basic: [
				{ key: "AVG", prop: "stats.avg_points", tooltip: "Average Points" },
				{ key: "TP", prop: "stats.total_points", tooltip: "Total Points" },
				{
					key: "RD X Score",
					prop: "stats.points_last_round",
					tooltip: "Points Last Round"
				},
				{ key: "L3 AVG", prop: "stats.last_3_avg", tooltip: "Last 3 Average" },
			],
			coach: { ...coach_player_stats("stats", 0, false) },
			pre_draft_list_size: 0,
		},
		waiver_requests: ""
	};

	constructor(props, state) {
		super(props, state);

		_.bindAll(this, [
			"getNextSquadMatchup",
			"onFiltersChange",
			"onSearchSubmit",
			"onLoadMoreClick",
			"setSelectedStatsType",
			"getPlayerRowButtons",
			"filterChecker",
			"setPlayerShowLimit",
			"setCompressedView",
			"isActiveTab",
			"orderByComparator",
			"toggleFantasyCoachModal",
			"tableColumnToCellInfo",
			"onSortColumnChange",
			"resetFilters",
			"handleButtons",
			"handleRemoveButton",
			"handleSwitchFilterChange"
		]);
	}

	state = {
		compressed_view: getCompressedViewStatus(),
		players_show_limit: 50,
		players_show_limit_step: 20,
		sort_by: "stats.avg_points",
		buttons_open: [],
		order_by_desc: true,
		player_row_menu_ids: [],
		filters: {
			search: "",
			by_squad: "",
			by_position: "",
			by_status: "",
			by_dpp: false,
			by_owner: "",
			by_favourites: false,
			by_drafted: false,
			by_fa: false
		},
		active_stats_type: "general-stats",
		fa_trade: {
			new_player_id: 0,
			old_player_id: 0
		},
		team_trade: {
			new_player_id: [],
			old_player_id: []
		},
		is_fa_trade: false,
		is_team_trade: false,
		show_team: false,
		show_fantasy_coach_modal: false
	};

	componentDidMount() {
		const { match, fetchAllStats } = this.props;
		if(match.params.action === "free-agent" || match.params.type === "delist") {
			this.setState({
				filters: {
					...this.state.filters,
					by_owner: "fa_rfa"
				}
			});
		}
		else if (match.params.action === "rfa") {
			this.setState({
				filters: {
					...this.state.filters,
					by_owner: "rfa"
				}
			});
		}
		fetchAllStats();
	}

	shouldComponentUpdate(next_props) {
		return next_props.data_loading_complete;
	}

	filterChecker(is_what, what) {
		return is_what ? what : _.identity;
	}

	toggleFantasyCoachModal() {
		const { show_fantasy_coach_modal } = this.state;
		this.setState({ show_fantasy_coach_modal: !show_fantasy_coach_modal });
	}

	handleButtons(playerID) {
		const { buttons_open } = this.state;

		this.setState({ buttons_open: [...buttons_open, playerID] });
	}

	handleRemoveButton(playerID) {
		const { buttons_open } = this.state;
		const newButtonsArr = buttons_open.filter((id => playerID !== id));
		this.setState({ buttons_open: newButtonsArr });
	}

	getNextSquadMatchup(squad_id) {
		const { actual_round, rounds, squads } = this.props;
		const next_round_id = parseInt(actual_round.id, 10) + 1;

		// return nothing after last round;
		if(!(next_round_id in rounds.by_id)) {
			return "---";
		}

		const next_round = rounds.by_id[next_round_id];
		// find match with squad_id
		if(!next_round) {
			return "---";
		}

		const matches = next_round.matches.map(match_id => rounds.matches_by_id[match_id]);
		const match = matches.find(match => match.home_squad_id === squad_id ||
			match.away_squad_id === squad_id);

		const away_squad = squads.find(squad => squad.id === match.away_squad_id) || {};
		const home_squad = squads.find(squad => squad.id === match.home_squad_id) || {};

		if(match.away_squad_id === squad_id) {
			return `@ ${home_squad.short_name}`;
		}
 		else {
			return `V ${away_squad.short_name}`;
		}

	}

	get table_keys() {
		const { active_stats_type } = this.state;
		const { league, actual_round, is_classic } = this.props;


		const statsBasis = league.custom_scoring_enabled ? "custom_stats" : "stats";

		if(active_stats_type === "custom-stats"){
			const customStats = [{
				key: "GP",
				prop: "stats.games_played",
				tooltip: "Games Played",
				is_fc: false
			}];

			if(league.custom_scoring && Object.keys(league.custom_scoring).length > 0){
				Object.keys(league.custom_scoring).forEach(i => {
					customStats.push({ 
						key: i,
						prop: i,
						tooltip: _.get(score_settings[i],"title", "" ), 
						is_fc: false
					});
				});
			}
			else{
				for(let i = 0; i < 9; i++){
					customStats.push({ 
						key: Object.keys(score_settings)[i],
						prop:  Object.keys(score_settings)[i],
						tooltip: _.get(
							score_settings[ Object.keys(score_settings)[i]],"title", "" 
						), 
						is_fc: false
					});
				}
				
			}
			return customStats;
		}
		return active_stats_type === "general-stats" ?
			this.props.table.basic :
			coach_player_stats(statsBasis, actual_round, is_classic)[active_stats_type];
	}

	setSelectedStatsType(event) {
		const { value } = event.target;
		this.setState({ active_stats_type: value });
	}

	setPlayerShowLimit(players_show_limit) {
		this.setState({
			players_show_limit,
			players_show_limit_step: players_show_limit
		});
	}

	setCompressedView(compressed_view) {
		this.setState({ compressed_view });
		localStorage.setItem("compressed_view", compressed_view);
	}

	isActiveTab(sort_prop){
		const { sort_by } = this.state;

		return _.eq(sort_by, sort_prop) ? "active" : "";
	}

	handleSwitchFilterChange(name, value){
		this.setState({
			filters: {
				...this.state.filters,
				[name]: value
			}
		});
	}


	/**
	 * Handle search and filters
	 */
	onFiltersChange(event) {
		const { filters } = this.state;
		const new_filters = this.formElementToKeyValueObject(event.currentTarget);

		this.setState({
			filters: {
				...filters,
				...new_filters
			}
		});
	}

	resetFilters() {
		this.setState({
			filters: {
				search: "",
				by_squad: "",
				by_position: "",
				by_status: "",
				by_dpp: false,
				by_owner: "",
				by_favourites: false,
				by_drafted: false,
				by_fa: false
			},
			active_stats_type: "general-stats"
		});
	}

	onSearchSubmit(event) {
		event.preventDefault();
	}

	formElementToKeyValueObject(form) {
		return Array.from(form.elements).reduce(this.accumulateFormInputsValueByName, {});
	}

	accumulateFormInputsValueByName(accumulator: Object, formElement: Object) {
		const { type, name, checked, value } = formElement;

		if(_.eq(type, "submit")) {
			return accumulator;
		}

		if (!name) {
			throw new Error("All form inputs should be have \"name\" attribute!");
		}

		accumulator[name] = _.eq(type, "checkbox") ? checked : value;

		return accumulator;
	}

	onSortColumnChange(column_value) {
		const { sort_by, order_by_desc } = this.state;
		const { has_assistant_coach } = this.props;

		const keys = this.table_keys;
		const stat = _.find(keys, { prop: column_value }, {});
		const can_sort = has_assistant_coach || !_.get(stat, "is_fc", false);
		const getColumnValue = column_value.includes("stat") ? 
			column_value:`custom_stats.${column_value}`;

		if (!can_sort) {
			// The user cannot sort by this column
			return;
		}
		const default_order = !_.eq(getColumnValue, "stats.adp");
		this.setState({
			sort_by: getColumnValue,
			order_by_desc: _.eq(sort_by, getColumnValue) ? !order_by_desc : default_order,
		});
	}

	tableColumnToCellInfo(column: TableColumn) {
		return {
			table: {
				order_by_desc: this.state.order_by_desc,
				sort_key: this.state.sort_by
			},
			cell: {
				sort_key: column.prop,
				short_name: column.key,
				rel: column.prop,
				sortable: true,
				onClick: this.onSortColumnChange,
				name: column.key,
				tooltip: column.tooltip || "",
				is_fc: column.is_fc || false
			}
		};
	}

	get table_header() {
		const { show_next } = this.props;

		const cells = _(this.table_keys)
			.map(this.tableColumnToCellInfo)
			.map(cell => renderTableHeaderCell(cell, ThStat))
			.map((cell,cellIndex) => ({
				...cell,
				key: cell.props.rel || cellIndex
			}))
			.value();
		return (
			<TableHead>
				<Tr className="table-header-row">
					<Th textAlign="left" rel="player" className="absolute-group-left">Player</Th>
				
					<Th textAlign="left" rel="owner" className="th-owner">Owner</Th>
			
					{show_next && (<Th className="th-next">Next</Th>)}
					{ cells }
					<Th width="100px" rel="action" className="absolute-group-right">Action</Th>
				</Tr>
			</TableHead>
		);
	}

	get has_filters() {
		const { only_fa } = this.props;
		const filter_keys = Object.keys(this.state.filters);
		const active_filters = filter_keys.filter(key => this.state.filters[key]);

		return only_fa || active_filters.length !== 0;
		// Old logic below the owner_by is always return true.
		// return only_fa || _.size(_.values(this.state.filters).filter(_.identity));
	}

	get players_filter() {
		const { only_fa } = this.props;
		const { filters } = this.state;
		const {
			by_status,
			by_squad,
			by_favourites,
			by_position,
			by_dpp,
			by_owner,
			by_fa,
			search
		} = filters;
		const is_by_fa = only_fa || by_fa;

		const by_owned = by_owner === "owned";
		const by_rfa = by_owner === "rfa";
		const by_fa_rfa = by_owner === "fa_rfa";

		const byPlayerStatus = _.matches({ status: by_status });

		const bySquadId = _.matches({ squad_id: parseInt(by_squad, 10) });

		const byFavourites = _.property("is_favourite");

		const byPosition = _.flow([
			_.property("positions"),
			_.partial(_.includes, _, parseInt(by_position, 10))
		]);

		const byDPP = ({ positions }) => _.eq(_.size(positions), 2);

		const byName = (({ first_name, last_name }) => (
			`${first_name} ${last_name}`.toLowerCase().includes(search.toLowerCase())
		));

		const byFA = ({ owner, expiry }) => !owner && !expiry;
		const byRFA = _.property("expiry");
		const byFA_RFA = byFA;
		const byOWNER = _.property("owner");

		return _.overEvery([
			this.filterChecker(by_status, byPlayerStatus),
			this.filterChecker(by_squad, bySquadId),
			this.filterChecker(by_favourites, byFavourites),
			this.filterChecker(by_position, byPosition),
			this.filterChecker(by_dpp, byDPP),
			this.filterChecker(by_rfa, byRFA),
			this.filterChecker(by_fa_rfa, byFA_RFA),
			this.filterChecker(by_owned, byOWNER),
			this.filterChecker(is_by_fa, byFA),
			this.filterChecker(search, byName),
		]);
	}

	get players_unsliced() {
		const { only_fa, players } = this.props;

		if(this.has_filters || only_fa) {
			const filtered_players = players.filter(this.players_filter);
			return _.orderBy(filtered_players, this.orderByComparator);
		}

		return _.orderBy(players, this.orderByComparator);
	}

	get players() {
		const { players_show_limit } = this.state;
		return this.players_unsliced.slice(0, players_show_limit);
	}

	orderByComparator(player) {
		const { sort_by, order_by_desc } = this.state;
		let value = _.get(player, sort_by);
		const order = order_by_desc ? -1 : 1;

		if (_.isString(value)) {
			value = parseFloat(value);
		}

		if (
			_.isNaN(value) ||
			value === undefined ||
			(sort_by === "stats.adp" && value === 0)
		) {
			value = (Number.MAX_SAFE_INTEGER - 1) * order;
		}

		return value * order;
	}

	getTableMessage(message) {
		return (
			<TBody>
				<Tr>
					<Td align="center" colSpan={_.size(this.table_keys) + 4}>
						<EmptyMessage>{message}</EmptyMessage>
					</Td>
				</Tr>
			</TBody>
		);
	}

	get is_free_agent_page() {
		return window.location.pathname.includes("rfa") 
			|| window.location.pathname.includes("free-agent");
	}

	get table_body() {
		// eslint-disable-next-line max-len
		const {
			data_loading_complete,
			show_next,
			has_assistant_coach,
			all_stats,
			league
		} = this.props;
		const { compressed_view,active_stats_type } = this.state;
		const isLeagueFullSubscription = league.subscription ==="full";
		if(!data_loading_complete) {
			return this.getTableMessage("Loading, please wait...");
		}

		const players_list = this.players;
		
		if(data_loading_complete && _.isEmpty(players_list) && this.has_filters) {
			return this.getTableMessage(
				"Sorry, there are no players who match this search query. Please "
				+ "adjust your filters above and try again."
			);
		}

		const cells = players_list.map((player, index) => {
			const { draft_order, owner } = player;
			const is_ignored = !draft_order;
			const playerAllStats = _.get(all_stats, `${player.id}`, {});
			const is_user_team = Boolean(owner && owner.user_id === this.props.current_user_id);
			const isWhite = (index+1)%2 !== 0;
			return (
				<StyledTr
					className={
						`${is_ignored ? "is-ignored" : ""} 
						${is_user_team ? "is_owned_by_user" : ""}`
					}
					key={player.id}
					owned_by_user={is_user_team}
					isWhite={isWhite}
				>
					<TdYourTeam textAlign="left" className="absolute-group">
						<PlayerInfo
							className="player-info"
							player={player}
							is_compressed={compressed_view}
							is_classic={false}
						/>
					</TdYourTeam>
				
					<TdDraftOwner 
						is_compressed={compressed_view} 
						player={player} 
						className="td-draft-owner"/>
			
					
					{show_next &&
						(<TdNext is_compressed={compressed_view}
							squad_id={_.get(player.competition, "opponent.id")} 
							className="td-next"/>)
					}
					{this.table_keys.map(({ key, prop, is_fc }) => {
						return (
							<TdStat
								className={`player-stats ${this.isActiveTab(prop)}`}
								key={key}
								is_fc={is_fc}
								rel={prop}
							>
								{active_stats_type === "custom-stats" 
									? this.getPlayerSeasonStat(player, playerAllStats, prop)
									: this.renderStatContent(player, prop, is_fc, has_assistant_coach, isLeagueFullSubscription)
								}
							</TdStat>
						);
					})}
					<TdStat rel="action" className="absolute-group-right">
						{this.getPlayerRowButtons(player)}
					</TdStat>
				</StyledTr>
			);
		});

		return <TBody>{ cells }</TBody>;
	}

	renderStatContent = (player, prop, is_fc, has_assistant_coach, isLeagueFullSubscription) => {
		if (is_fc && !has_assistant_coach) {
		  return this.renderFantasyCoachStat(player, prop, isLeagueFullSubscription);
		}
		else {
		  return this.renderPlayerStat(player, prop, is_fc, has_assistant_coach, isLeagueFullSubscription);
		}
	  }
	  
	  renderFantasyCoachStat = (player, prop, isLeagueFullSubscription) => {
	  	if (isLeagueFullSubscription && (prop === "stats.proj_avg" || prop === "custom_stats.proj_score_next_round")) {
		  return this.getPlayerStat(player, prop);
	  	}
	  	else {
		  return (
	  			<FcLink onClick={this.toggleFantasyCoachModal}>
			  <Lock color={colors.coach} />
	  			</FcLink>
		  );
	  	}
	  }
	  
	  renderPlayerStat = (player, prop, is_fc, has_assistant_coach, isLeagueFullSubscription) => {
	  	if (!isLeagueFullSubscription && (prop === "stats.proj_avg")) {
		  return (
	  			<FcLink onClick={this.toggleFantasyCoachModal}>
			  <Lock color={colors.coach} />
	  			</FcLink>
		  );
	  	}
	  	else {
		  return this.getPlayerStat(player, prop);
	  	}
	  }


	  getPlayerStat(player: TPlayer, prop: string) {
	  	if (_.includes(prop, "owned_by")) {
	  		return numeral(_.get(player, prop, null)).format("0");
	  	}
	  	if (_.includes(prop, "adp") && _.get(player, prop) === 0) {
	  		return "- -";
	  	}
	  	return _.get(player, prop, "- -");
	  }

	  getPlayerSeasonStat(player: TPlayer, playerAllStats: Object, prop: string) {
	  	if (_.includes(prop, "games_played")) {
	  		return _.get(player, prop, "- -");
	  	}

	  	return _.get(playerAllStats, prop, "- -");
	  }

	  getPlayerRowButtons(player) {
	  	const {
	  		addInTrade,
	  		addOutTrade,
	  		current_user_id,
	  		league,
	  		fa,
	  		removeInPlayer
	  	} = this.props;
	  	const {
	  		compressed_view,
	  		buttons_open,
			
	  	} = this.state;
		

	  	return <PlayerRowButtons 
	  		player={player}
	  		fa={fa}
	  		addInTrade={addInTrade}
	  		addOutTrade={addOutTrade}
	  		buttons_open={buttons_open}
	  		compressed_view={compressed_view}
	  		current_user_id={current_user_id}
	  		handleButtons={this.handleButtons}
	  		handleRemoveButton={this.handleRemoveButton}
	  		isDisabled={!isDraftComplete(league)}
	  		removeInPlayer={removeInPlayer}
	  	/>;
	  }

	  checkIsManagementAvailable(player_id, checkIsPlayerLocked=false) {
	  	const { actual_round, players, league } = this.props,
	  		{ status } = actual_round,
	  		{ status: league_status, lockout } = league,
	  		player = _.find(players, { id: player_id });
	  	if (league.draft_status !== "complete") {
	  		return false;
	  	}

	  	if (league_status === "scheduled") {
	  		return true;
	  	}

	  	if (!lockout && status !== "scheduled") {
	  		return false;
	  	}

	  	if(checkIsPlayerLocked) {
	  		return player && !player.locked;
	  	}
	  	else {
	  		return true;
	  	}
	  }

	  onLoadMoreClick() {
	  	const { players_show_limit, players_show_limit_step } = this.state;

	  	this.setState({
	  		players_show_limit: players_show_limit + players_show_limit_step,
	  	});
	  }

	  get load_more_btn(){
	  	const { players_show_limit } = this.state;
	  	if(this.players_unsliced.length < players_show_limit) {
	  		return null;
	  	}
	  	return (
	  		<ButtonLoadMore onClick={this.onLoadMoreClick}>
				Load more players
	  		</ButtonLoadMore>
	  	);
	  }

	  render() {
	  	const {
	  		squads,
	  		positions,
	  		playerStatuses,
	  		coach_stats_list,
	  		league: { trade_waivers },
	  		league,
	  		addOwnerFilter,
	  		data_loading_complete,
	  	} = this.props;

	  	const {
	  		active_stats_type,
	  		players_show_limit,
	  		compressed_view,
	  		show_fantasy_coach_modal,
	  		filters
	  	} = this.state;
	  	const hide_rfa = !trade_waivers;
		
	  	return (
	  		<React.Fragment>
	  			{
	  				show_fantasy_coach_modal ?
	  					<FantasyCoachModal
	  						closeClick={this.toggleFantasyCoachModal}
	  					/>
	  					: null
	  			}
	  			{data_loading_complete ? <React.Fragment>
	  				<DraftFilters
	  					handleChange={this.onFiltersChange}
	  					handleSwitchFilterChange={this.handleSwitchFilterChange}
	  					handleSubmit={this.onSearchSubmit}
	  					squads={squads}
	  					positions={positions}
	  					statuses={playerStatuses}
	  					hide_rfa={hide_rfa}
	  					is_hide_sort
	  					is_show_owner={addOwnerFilter}
	  					selectOptions={[
	  						{ key: "general-stats",value: "General Stats" },
	  						...coach_stats_list
	  					]}
	  					onSelectChange={this.setSelectedStatsType}
	  					active_stats_type={active_stats_type}
	  					league={league}
	  					resetFilters={this.resetFilters}
	  					filters={filters}
	  				/>
	  				<PlayersTableHeader
	  					title="All Players"
	  					players_show_limit={players_show_limit}
	  					onPlayerShowLimitClick={this.setPlayerShowLimit}
	  					is_compressed={compressed_view}
	  					onCompressedViewClick={this.setCompressedView}
	  					show_fantasy_ad
	  				/>
	  				<TableWrapper>
	  					<Table is_compressed={compressed_view}
	  						show_border_bottom={
	  							this.players_unsliced.length < this.state.players_show_limit}
	  					>
	  						{ this.table_header }
	  						{ this.table_body }
	  					</Table>

	  				</TableWrapper>
					

	  				{ this.load_more_btn }
	  				<Spacer />
	  			</React.Fragment>:null}
	  		</React.Fragment>
	  	);
	  }
}

const mapStateToProps = (state: TRootStore, props) => {
	const all_stats = state.players.all_stats;
	const is_classic = _.get(window.location, "pathname", "").includes("classic");
	return {
		players: selectors.players.getExtendedPlayersArrayWithOwners(state, props)
			.map(player => ({
				...player,
				custom_stats: {
					...player.custom_stats,
					..._.get(all_stats, `${player.id}`, {})
				}
			})),
		all_stats,
		is_pending_players: state.players.is_pending,
		squads: selectors.squads.getSquads(state),
		positions: selectors.positions.getPositionsArray(state),
		playerStatuses: state.playerStatuses,
		rounds: state.rounds,
		actual_round: selectors.rounds.getActualRound(state),
		last_round: selectors.rounds.getLastRound(state),
		has_assistant_coach: Boolean(selectors.getUser(state).assistant_coach),
		coach_stats_list: state.coachStatsList,
		coach_players_by_id: selectors.players.getCoachPlayersById(state),
		is_league_started: selectors.leagueDraft.isLeagueStarted(state, props),
		current_user_id: state.user.data.id,
		is_classic,
		data_loading_complete: _.every([true,
			!state.players.is_pending,
			!state.rounds.is_pending,
			!state.squads.is_pending,
			!state.venues.is_pending,
			!state.leagues.waiver_free.is_pending,
			!state.leagues.show_my.is_pending,
	
		])
	};
};

const mapDispatchToProps = {
	fetchWaiverList: actions.leagueDraft.fetchWaiverList,
	fetchWaiverFree: actions.leagueDraft.fetchWaiverFree,
	fetchAllStats: actions.fetchAllStats,
	
};

export const PlayersTable = withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	)(PlayersComponent)
);
export default PlayersTable;