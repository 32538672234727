// @flow
import * as React from "react";
import styled from "styled-components";
import { CancelButton } from "../../../../components";

const Button = styled(CancelButton)`
	background: #496CA0;
	white-space: nowrap;
	opacity: 0.5;
	
	&:hover {
		opacity: .7;
	}
`;

type Props = {};

export const ResetButton = (props: Props) => <Button {...props}>Reset queue</Button>;

export default ResetButton;