// @flow

import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../../modules/actions";
import * as selectors from "../../../modules/selectors";
import JsonFetcherCore from "../JsonFetcherCore";
import type { TResources } from "../JsonFetcherCore";

type TResourceKeys = "players" | "coach_players" | "rounds" | "squads" | "venues";

type Props = {
	// fetch: Array of resource key strings
	fetch: Array<TResourceKeys>,
	// interval: milliseconds
	interval?: number,

	// Below are passed in via redux connet
	fetchPlayers: typeof actions.fetchPlayers,
	fetchCoachPlayers: typeof actions.fetchCoachPlayers,
	fetchRounds: typeof actions.fetchRounds,
	fetchSquads: typeof actions.fetchSquads,
	fetchVenues: typeof actions.fetchVenues,
	fetchChecksums: typeof actions.fetchChecksums,

	checksums: Object,
	players_exist: boolean,
	coach_players_exist: boolean,
	rounds_exist: boolean,
	squads_exist: boolean,
	venues_exist: boolean,
	has_assistant_coach: boolean
};

export class JsonFetcherContainer extends Component<Props> {
	render() {
		const {
			fetch,
			fetchPlayers,
			fetchCoachPlayers,
			fetchRounds,
			fetchSquads,
			fetchVenues,
			fetchChecksums,
			checksums,
			players_exist,
			coach_players_exist,
			rounds_exist,
			squads_exist,
			venues_exist,
			interval,
			has_assistant_coach
		} = this.props;

		// map resourceKeys to fetch actions
		const resources_available: TResources = {
			players: { fetch: fetchPlayers, exists: players_exist },
			rounds: { fetch: fetchRounds, exists: rounds_exist },
			squads: { fetch: fetchSquads, exists: squads_exist },
			venues: { fetch: fetchVenues, exists: venues_exist }
		};

		if (has_assistant_coach) {
			resources_available.coach_players =
				{ fetch: fetchCoachPlayers, exists: coach_players_exist };
		}

		// Create resources object
		const resources: TResources = fetch.reduce((obj, resource_key_string) => {
			if (resources_available[resource_key_string]) {
				obj[resource_key_string] = resources_available[resource_key_string];
			}
			return obj;
		}, {});

		return (
			<JsonFetcherCore
				resources={resources}
				fetchChecksums={fetchChecksums}
				checksums={checksums}
				interval={interval}
			/>
		);
	}
}

const mapStateToProps = state => ({
	checksums: selectors.checksums.getChecksums(state),
	players_exist: selectors.players.hasPlayers(state),
	coach_players_exist: selectors.players.hasCoachPlayers(state),
	rounds_exist: selectors.rounds.hasRounds(state),
	squads_exist: selectors.squads.hasSquads(state),
	venues_exist: selectors.venues.hasVenues(state),
	has_assistant_coach: Boolean(selectors.getUser(state).assistant_coach),
});

const mapDispatchToProps = {
	fetchPlayers: actions.fetchPlayers,
	fetchCoachPlayers: actions.fetchCoachPlayers,
	fetchRounds: actions.fetchRounds,
	fetchSquads: actions.fetchSquads,
	fetchVenues: actions.fetchVenues,
	fetchChecksums: actions.fetchChecksums,
};

export const JsonFetcher = connect(
	mapStateToProps,
	mapDispatchToProps
)(JsonFetcherContainer);

export default JsonFetcher;