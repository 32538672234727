// @flow
import * as React from "react";
// import styled from 'styled-components';

import Container from "./Container";

type Props = {}

class NotInAnyLeagues extends React.Component<Props> {
	render() {
		return (
			<Container text={"Loading..."} />
		);
	}
}

export default NotInAnyLeagues;