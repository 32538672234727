// @flow
import * as React from "react";
import styled from "styled-components";
import colors from "../../../../assets/css/colors";
import { Tooltip } from "../../../../components";

const LeagueItemStyled = styled.div`
	display: flex;
	color: ${colors.primary.primary};
	margin: 1em 0;
	justify-content: space-between;
	align-items: center;
`;

const LeagueItemData = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	border-bottom: 1px solid white;
	padding: 12px 0 13px;

	span {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
	}

	h4 {
		text-transform: capitalize;
		font-weight: normal;
		font-size: 14px;
		margin: 0 0.92em 0 0;
	}

	> p {
		text-align: right;
		color: ${colors.secondary.accentGrey};
		font-size: 13px;
		text-transform: none;
	}
`;

type Props = {
	name: string,
	content: any,
	tooltip: {
		title: string,
		description: string,
	}
};

export const LeagueItem = ({ name, content, tooltip }: Props) => (
	<LeagueItemStyled>
		<LeagueItemData>
			<span>
				<h4>{name}</h4>
				<Tooltip {...tooltip} />
			</span>
			<p>{content}</p>
		</LeagueItemData>
	</LeagueItemStyled>
);

export default LeagueItem;