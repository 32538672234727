// @flow
import * as React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import _ from "lodash";
import { Redirect } from "react-router-dom";
import * as selectors from "../../../modules/selectors";
import * as actions from "../../../modules/actions";
import { isMobile } from "../../../utils";
import {
	AdsContainer,
	PageTitle,
	Footer,
	PageContentWrapper,
	StandardSidebar,
	TwoColumnLayout,
	LeagueGameBar,
	Exist,
} from "../../../components";
import SelectArrowDownSmall from "../../../assets/images/ArrowDownSmall.svg";
import Ad from "../../../components/Ad";
import type { 
	TPLeague, 
	TPlayersById, 
	TDraftTeamsById, 
	TDraftUserTeam 
} from "../../../modules/types";

import colors from "../../../assets/css/colors";
import JsonFetcher from "../../../components/utils/JsonFetcher";
import AddPlayerModal from "./leagueEditTeams/AddPlayerModal/AddPlayerModal";
import { LeagueEditTeamList } from "./leagueEditTeams/ListViewTeamEdit";

const POSITION_KEYS = ["1", "2", "3", "4", "bench"];

const findCurrentPositionInTeam = (player_id, lineup) => {
	let player_position = 0;
	POSITION_KEYS.forEach(position => {
		if(lineup[position] && lineup[position].includes(player_id)){
			player_position = position;
		}
	});
	return player_position;
};

type Props = {
	selected_league_id: number,
	fetchLeague: typeof actions.leagueDraft.showDraftLeague,
	showTeamsDraftLeague: typeof actions.leagueDraft.showTeamsDraftLeague,
	draftLeagueTeamUpdate: typeof actions.leagueDraft.draftLeagueTeamUpdate,
	user_id: number,
	league: TPLeague,
	teams: Array<TDraftUserTeam>,
	teams_by_id: TDraftTeamsById,
	error?: string,
	players_by_id: TPlayersById,
	players_arr: Array<Object>,
	fetchWaiverFree: Function,
	fetchDraftLadder: Function,
	match: {
		params: {
			league_id: string
		}
	},

}

const NoTeamSelectedWrap = styled.div`
	display: flex;
	align-self: center;
	flex-direction: column;
	justify-content: space-around;
	opacity: 0.7;
	font-family: TitilliumUpright;
	font-weight: bold;
	font-size: 24px;
	text-align: center;
	padding: 20px;
	height: 150px;

`;

const TeamNameHeader = styled.div`
	height: 60px;
	font-family: TitilliumUpright;
	font-weight: bold;
	font-size: 16px;
	text-align: left;
	padding-left: 20px;
	background-color: #1D4073;
	color: #fff;
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const PageTitleSelectWrap = styled(PageTitle)`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin-top: 10px;
`;
const Controls = styled.div`
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid #fff;
	width: ${isMobile() ? "100%;" : ""};
	select {
		width: ${isMobile() ? "100%;" : "190px"};
		height: 40px;
		border: 1px solid #CAD2D8;
		padding-left: 10px;
		color: #7F8A90;
		font-family: SourceSansPro;
		font-size: 12px;
		font-weight: 600;
		-webkit-appearance: none;
		-moz-appearance: none;
		-o-appearance: none;
		appearance: none;
		background-image: url(${SelectArrowDownSmall});
		background-position: 98% center;
		background-repeat: no-repeat;
		background-size: 15px;
		outline: none;
		option {
			width: 190px;
			height: 40px;
			color: #7F8A90;
			font-family: SourceSansPro;
			font-size: 12px;
		}
	}
`;
const ControlTeamItem = ({ team_name, current_team, ...rest }) => (
	<option className={current_team === team_name ? "active" : ""} {...rest}>
		{ team_name }
	</option>
);


const HeadingDiv = styled.div `
	text-transform: inherit;
	color: ${colors.primary.primary};
	font-family: "SourceSansPro";
	font-size: 12px;
	font-weight: 600;
	letter-spacing: 0;
	line-height: 12px;
	margin-bottom: 10px;
`;


type State = {
	current_edit_team_id: number,
	drop_player_modal: {
		open: boolean,
		id: number,
		position: number
	},
};

class EditTeamsComponent extends React.Component<Props, State> {

	constructor(props, state) {
		super(props, state);

		_.bindAll(this, [
			"onSelectChangeTeam",
			"openAddPlayerModal",
			"closeAddPlayerModal"
		]);
	}

	state = {
		current_edit_team_id: 0,
		drop_player_modal: {
  		open: false,
  		id: 0, 
			position: 0,
  	},
	};

	componentDidMount() {
		this.fetchLeague();
	}
	onSelectChangeTeam(e){
		this.setState({ current_edit_team_id: e.target.value });
	}
	openAddPlayerModal(player_id: number, position?: number) {
		const { teams_by_id } = this.props;
		const { current_edit_team_id } = this.state;
		if(player_id === 0 && position){
			this.setState({
				drop_player_modal: {open: true, id: 0, position: position}
			});
			return;
		}
		const player_position = 
				findCurrentPositionInTeam(player_id, teams_by_id[current_edit_team_id].lineup);
		this.setState({
			drop_player_modal: { open: true,  id: player_id, position: player_position }
		});
	}
	closeAddPlayerModal(){
  	this.setState({
  		drop_player_modal: { open: false, id: 0, position: 0 }
  	});
	}


	get status_and_time_until_draft() {
		const
			{
				league,
			} = this.props;
		return (league.status === "scheduled"
			&& (Date.parse(league.draft_start) - Date.parse(Date())) > 1800000);
	}

	fetchLeague() {
		const { fetchLeague, selected_league_id, fetchWaiverFree, fetchDraftLadder } = this.props;
		fetchLeague({
			id: selected_league_id
		});
		fetchDraftLadder({ league_id: selected_league_id });
		fetchWaiverFree({
			league_id: selected_league_id
		});

	}


	get table_header() {
		return (
			<HeadingDiv>Team / Owner</HeadingDiv>
		);
	}


	get team_selector() {
		const { current_edit_team_id } = this.state;
		const { teams } = this.props;
		const default_option = {
			id: 0,
			name: "Select a Team"
		};

		const select_teams_array = [default_option, ...teams];
		return(
			<Controls>
				
				<select
					onChange={this.onSelectChangeTeam} 
					value={current_edit_team_id} >
					{select_teams_array.map(team => (
						<ControlTeamItem
							key={team.id}
							team_id={team.id}
							current_team={current_edit_team_id}
							team_name={team.name}
							value={team.id}
						> {team.name}</ControlTeamItem>)
					)}
				</select>
			</Controls>
		
		);
			
	}
	get team_edit_section() {
		const { current_edit_team_id } = this.state;
		const { teams_by_id } = this.props;
		const team_on_show = teams_by_id[current_edit_team_id];
		if(current_edit_team_id === 0 || !team_on_show){
			return <NoTeamSelectedWrap>Select a team to edit</NoTeamSelectedWrap>;
		}
		return (
			<LeagueEditTeamList 
				team={team_on_show} 
				openAddPlayerModal={this.openAddPlayerModal} 
				closeAddPlayerModal={this.closeAddPlayerModal}
			/>
		);
	}


	get show_team_table() {
		const { current_edit_team_id } = this.state;
		const { teams_by_id } = this.props;
		const currentTeam = teams_by_id[current_edit_team_id];
		return (
			<div>
				
				<PageTitleSelectWrap>
					{isMobile() ? null : "Edit Teams"}
					{this.team_selector}
				</PageTitleSelectWrap>
				{!isMobile() &&
				<TeamNameHeader>
					{current_edit_team_id === 0 || !currentTeam ?  
						"Select a Team" : currentTeam.name }
				</TeamNameHeader>
				}
				{this.team_edit_section}
				
			</div>
		);
	
	}

	render() {
		const {
			league,
			user_id,
			teams_by_id
		} = this.props;

		const { drop_player_modal, current_edit_team_id } = this.state;
		
		if(user_id !== league.commissioner 
			|| !["full", "lite"].includes(league.subscription)  
			|| league.draft_status !== "complete"){
			return <Redirect to={`/draft/league/${league.id}/about`} />;
		}
		
		return (
			<React.Fragment>
				<JsonFetcher fetch={["rounds", "players", "squads"]} />
				<LeagueGameBar no_fetch={true} />
				<AdsContainer>
					<Ad />
				</AdsContainer>

				
				<PageContentWrapper>
					
					<TwoColumnLayout>
						
						{this.show_team_table}
						<Exist when={this.state.drop_player_modal.open}>
							<AddPlayerModal 
								openAddPlayerModal={this.openAddPlayerModal}
								closeAddPlayerModal={this.closeAddPlayerModal} 
								player_id={drop_player_modal.id} 
								active_position={drop_player_modal.position}
								team={teams_by_id[current_edit_team_id]} 
								league={league}
								players_by_id={this.props.players_by_id}
								players={this.props.players_arr}
							/>
						</Exist>
						<StandardSidebar />
					</TwoColumnLayout>
				</PageContentWrapper>
				<Footer />
			</React.Fragment>
		);
	}
}
const mapStateToProps = (state, props) => {
	const { leagues: { show_order: { error }, teams_by_id } } = state;
	const players_by_id = selectors.players.getExtendedPlayersById(state);
	const players_arr = selectors.players.getExtendedPlayersArrayWithOwners(state, props);
	const selected_league_id = props.match.params.league_id;
	const teams = Object.keys(state.leagues.teams_by_id)
		.map(team_id => state.leagues.teams_by_id[team_id]);
	return {
		selected_league_id,
		league: selectors.leagues.getLeague(state, props),
		user_id: selectors.getUser(state).id,
		error,
		teams_by_id,
		teams,
		players_by_id,
		players_arr
	};
};

const mapDispatchToProps = {
	fetchLeague: actions.leagueDraft.showDraftLeague,
	showTeamsDraftLeague: actions.leagueDraft.showTeamsDraftLeague,
	draftLeagueTeamUpdate: actions.leagueDraft.draftLeagueTeamUpdate,
	fetchWaiverFree: actions.leagueDraft.fetchWaiverFree,
	fetchDraftLadder: actions.leagueDraft.ladderDraftLeague
};

export const EditTeams = connect(
	mapStateToProps,
	mapDispatchToProps,
)(EditTeamsComponent);

export default EditTeams;