// @flow
import * as React from "react";
import styled, { css } from "styled-components";
import type { TPlayer } from "../../../../modules/types";
import {
	Tooltip,
	Widget,
	WidgetBody,
	WidgetHeader,
	WidgetHeaderTitle,
} from "../../../../components";
import { below } from "../../../../assets/css/media";
import AutoPickToggle from "./autoPickToggle";

const WidgetWrapper = styled(Widget)`
	margin-bottom: 10px;
	${below.phone`
		margin-top: 10px;
		width: 100%;
	`}
`;

const WidgetAutoFakeHeaderContainer = styled(WidgetHeader)`
	background: none;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	z-index: 3;
`;

const WidgetAutoPickHeader = styled(WidgetHeader)`
	border-radius: 0px;
	${({ open }) => open && css`
		background: #198DE1;
	`};
`;

const WidgetAutoPickContent = styled.div`
	min-height: 250px;
	
	img {
		position: relative;
		z-index: 2;
		margin: 0 auto;
		display: block;
		width: 100%;
	}
`;

type Props = {
	widget_open: boolean,
	tooltip_msg: string,
	player?: TPlayer | null,
	onChange: Function,
}

export class WidgetAutoPick extends React.Component<Props> {
	static defaultProps = {
		tooltip_msg: "Turn this on to automatically pick your next selection."
	};

	get content() {
		const { widget_open, player } = this.props;

		if(!(widget_open && player)) {
			return null;
		}

		const name = `${player.first_name} ${player.last_name}`,
			img_url = `${process.env.REACT_APP_PLAYERS_PATH + player.id}.png`;


		return (
			<WidgetAutoPickContent>
				<img src={img_url} alt={name} title={name} />
			</WidgetAutoPickContent>
		);
	}

	render() {
		const { widget_open, tooltip_msg, onChange } = this.props;

		return (
			<WidgetWrapper>
				<WidgetAutoFakeHeaderContainer>
					<WidgetHeaderTitle>
						<span>Auto pick</span>
						<AutoPickToggle
							isAutoPickEnabled={widget_open}
							onChange={onChange}
						/>
					</WidgetHeaderTitle>
					
					<Tooltip show_on_left description={tooltip_msg} />
				</WidgetAutoFakeHeaderContainer>
				<WidgetAutoPickHeader open={widget_open} />
				<WidgetBody open={widget_open}>
					{ this.content }
				</WidgetBody>
			</WidgetWrapper>
		);
	}
}

export default WidgetAutoPick;