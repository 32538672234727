// @flow
import { createReducer } from 'redux-act';
import * as actions from '../../actions';
import type { TPositionsReducer } from '../../types';
import { arrayToObjectById } from "./../../../helpers";

export const initial_state: TPositionsReducer = {
	by_id: {},
	ordered_ids: [],
};

export const positions = createReducer({}, initial_state);

positions.on(actions.setPositions, (state, payload: any) => ({
	...state,
	ordered_ids: payload.map(item => item.id),
	by_id: arrayToObjectById(payload),
}));

export default positions;