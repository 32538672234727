// @flow
import styled from "styled-components";

export const TableGrid = styled.div.attrs({
	role: "table",
	"aria-label": props => (props.label ? props.label : undefined)
})`
	width: 100%;
`;

export default TableGrid;