// @flow
import * as React from "react";
import bindAll from "lodash/bindAll";
import {
	Field,
	reduxForm,
	getFormValues
} from "redux-form";
import { connect } from "react-redux";

import {
	CardNumberElement,
	CardCVCElement,
	CardExpiryElement,
	injectStripe,
} from "react-stripe-elements";

import styled from "styled-components";

import colors from "../../assets/css/colors";
import { below } from "../../assets/css/media";
import {
	ButtonPrimary, FormCheckbox,
	FormField,
	Label,
	Tooltip,
	ErrorField,
	TextModal,
	Preloader,
	FlexDiv
} from "../../components";
import { CoachSubscriptionValidate } from "../../utils/FormValidations";

const FormWrapper = styled.div`
	${below.phone`
		${({ isAccountPage }) => isAccountPage && `
			width: 100%;
		`}
	`}
`;

const ProSubscriptionFormStyled = styled.form`
	background: #fff;
	padding: 25px;
	box-sizing: border-box;

	${below.tablet`
		padding: 1em 0;
	`}

	.have-the-permission {
		margin-bottom: 0;
	}

	button {
		margin-bottom: 1em;
	}
	
	label{
		color: #000;
	}
	.tooltip-text {
		width: 110px;
	}
`;

export const ProButton = styled(ButtonPrimary)`
	background: #000;
	border-color: #000;
	color: #fff;
	width: 210px;
	margin: 0 10px;
	:hover:not(:disabled) {
		background-color: #333;
		color: #fff;
		border-color: #333;
	}
	${below.tablet`
		margin: 0;
		width: 100%;
	`}
`;

const UpdateButton = styled(ButtonPrimary)`
	width: 200px;
	margin-right: 20px;
`;

const CancelButton = styled(ButtonPrimary)`
	background-color: #CF2223;
	border-color: #CF2223;
	width: 200px;

	:hover:not(:disabled) {
		background-color: #df3a3a;
	}
`;
const ButtonWrapper = styled.div``;

export const TitlePage = styled.h3`
	color: ${colors.coach};
	font-size: 18px;
	margin: 1em 0 .5em 0;
`;

const FormSubtitle = styled.h3`
	color: ${colors.primary.primary};
	font-size: 18px;
	margin-bottom: 20px;
	margin-top: 16px;
`;

export const Description = styled.p`
	font-family: "SourceSansPro";
	font-size: 14px;
	color: ${colors.primary.darkGrey};
	line-height: 20px;
`;

const CheckboxLabelSpan = styled.span`
	font-size: 14px;
	color: ${colors.primary.darkGrey};
	font-family: "SourceSansPro";
	${below.tablet`
		font-size: 12px;
	`}
`;

const PrivacyPolicyBlock = styled.div`
	margin: 0em 0 1.5em 0;
	input:checked + .checkmark {
    background: none;
		fill: #000;
  }
`;

const CardNumberElementStyled = styled.div`
	padding-left: 1%;
	border: 1px solid ${colors.form.borderColor};
	background-color: ${colors.white};
	width: 98%;
	font-family: "SourceSansPro";
	margin-top: .5em;
`;

const CardDetails = styled.div`
	display: flex;
	margin: 1em 0;

    justify-content: space-between;
	${Label}{
		color: #000;
	}
`;

const NameWrapper = styled.div`
	display: flex;
    justify-content: space-between;
`;

const CardExpiryStyled = styled.div`
	min-width: 200px;
	padding-left: 1em;
	border: 1px solid ${colors.form.borderColor};
	background-color: ${colors.white};
	margin: 0 0 .5em 0;
	${below.tablet`
		min-width: 100px;
	`}
`;

const CardCVCEStyled = styled.div`
	min-width: 200px;
	padding-left: 1em;
	border: 1px solid ${colors.form.borderColor};
	background-color: ${colors.white};
	margin-left: 15px;
	margin: 0 0 .5em 0;
	${below.tablet`
		min-width: 100px;
	`}
`;

const public_url = process.env.REACT_APP_PUBLIC_URL || "";

const styledElement = {
	base: {
		color: "#000",
		lineHeight: "40px",
		fontWeight: 400,
		fontSize: "13px",
		"::placeholder": {
			color: colors.secondary.accentGrey,
		}
	},
	invalid: {
		color: colors.warning
	},
	fonts:[
		{
			src: `${public_url}/assets/fonts/
			sourcesans/SourceSansPro-Regular.woff2`,
			family: "SourceSansPro",
			style: "normal",
		}
	]
};



type Props = {
	submitData: Function,
	handleSubmit: Function,
	reset?: Function,
	onCancelSubscription?: Function,
	onSubmit?: Function,
	stripe: {
		createToken: Function,
	},
	is_active?: boolean,
	cur_plan?: string,
  	change: Function,
	handleClose: Function,
	subscribe: Object,
	leagueForm: Object,
	isAccountPage?: boolean,
}

type State = {
	start_validation: boolean,
	card_expiry: {
		complete: boolean,
		error: {
			message: string,
		},
	},
	card_cvc: {
		complete: boolean,
		error: {
			message: string,
		},
	},
	card_number: {
		complete: boolean,
		error: {
			message: string,
		},
	},
	card_error: {
		message: string,
	},
	validation_pending: boolean,
};

const fullName = value =>
	value && !/^([^0-9]*)$/.test(value) ?
		"Name can not contain numbers" : undefined;

class ProSubscriptionFormComponent extends React.Component<Props, State> {
	constructor(props, state) {
		super(props, state);

		bindAll(this, [
			"handleErrorModalClose",
		]);
	}

	state = {
		start_validation: false,
		card_error: { message: "" },
		card_number: { complete: false,
			error: { message: "Please enter a valid card number" } },
		card_cvc: { complete: false,
			error: { message: "Please enter a valid CVV number" } },
		card_expiry: { complete: false,
			error: { message: "Please enter a valid expiry date using MM/YYYY" } },
		validation_pending: false,
	};

	componentDidMount() {
		// if (this.props.subscription && this.props.subscription.plan) {
		// 	// this.props.change('plan', cur_plan);
		// }
		const { change, cur_plan } = this.props;
		if (cur_plan) {
			change("plan", cur_plan);
		}
	}

	componentDidUpdate(old_props: Props) {
		const { cur_plan, change } = this.props;
		const { cur_plan: old_plan } = old_props;

		if (cur_plan && cur_plan !== old_plan) {
			change("plan", cur_plan);
		}
	}

	createToken(values) {
		const { stripe, submitData } = this.props;
		const { plan, fullname, league_id } = values;
		this.setState({
			validation_pending: true,
		});
		stripe.createToken({ name: fullname }).then(response => {
			if(response.error) {
				if(response.error.type !== "validation_error") {
					this.setState({ card_error: response.error });

				}
			}
			else {
				const { id } = response.token;
				submitData({ league_id: league_id, plan, token: id });
			}
		}).then(() => this.setState({ validation_pending: false }));
	}

	handleErrorModalClose() {
		this.setState({ card_error: { message: "" } });
	}

	errorModal(message) {
		return (
			<TextModal
				onClick={this.handleErrorModalClose}
				header_text='Error'
				text={message}/>
		);
	}

	showError(error_text) {
		return (<ErrorField>{error_text}</ErrorField>);
	}

	stripeElementChange(element, name) {
		// $FlowFixMe
		this.setState({ [name]: element });
	}

	stripeValidation(element) {
		const { start_validation } = this.state;
		return start_validation && element && element.error && element.error.message
			? this.showError(element.error.message) : null;
	}

	get card_details() {
		const { card_cvc, card_expiry, start_validation } = this.state;
		const { card_error } = this.state;

		return (
			<CardDetails>
				{ card_error && card_error.message ? this.errorModal(card_error.message) : null}
				<div>
					<NameWrapper>
						<Label
							is_error={start_validation && card_expiry && !card_expiry.complete}
						>
							Card expiry date
						</Label>
						<Tooltip
							description='The expiry date stated on the front of your card'
						/>
					</NameWrapper>
					<CardExpiryStyled>
						<CardExpiryElement
							style={styledElement}
							name="card_expiry"
							placeholder='MM / YY'
							onChange={element => this.stripeElementChange(element, "card_expiry")}
						/>
					</CardExpiryStyled>
					{this.stripeValidation(card_expiry)}
				</div>
				<div className='card_cvc'>
					<NameWrapper>
						<Label is_error={start_validation && card_cvc && !card_cvc.complete}>
							CVV
						</Label>
						<Tooltip
							description='The CVV is the 3 digit
							number located on the back of your card.'
							show_on_left
						/>
					</NameWrapper>
					<FlexDiv>
						<CardCVCEStyled>
							<CardCVCElement
								style={styledElement}
								name="card_cvc"
								placeholder='XXX'
								onChange={element => this.stripeElementChange(element, "card_cvc")}
							/>
						</CardCVCEStyled>
						
					</FlexDiv>
					{this.stripeValidation(card_cvc)}
				</div>
			</CardDetails>
		);
	}

	get stripe_form() {
		const { card_number, start_validation } = this.state;

		return (
			<div className="checkout">
				<NameWrapper>
					<Label is_error={start_validation && card_number && !card_number.complete}>
						Card number
					</Label>
					<Tooltip description='VISA or Mastercard only' show_on_left/>
				</NameWrapper>
				{this.stripeValidation(card_number)}
				<CardNumberElementStyled>
					<CardNumberElement
						style={styledElement}
						name="card_number"
						placeholder='XXXX-XXXX-XXXX-XXXX'
						onChange={element => this.stripeElementChange(element, "card_number")}
					/>
				</CardNumberElementStyled>
				{this.card_details}
			</div>
		);
	}

	updateButtons() {
		const cancelSub = this.props.onCancelSubscription
			? this.props.onCancelSubscription
			: () => null;
		return (<React.Fragment>
			<UpdateButton onClick={() => {
				this.setState({ start_validation: true });
			}}>
				Update Details
			</UpdateButton>
			<CancelButton type="button" onClick={cancelSub}>
				Cancel Fantasy Coach
			</CancelButton>
		</React.Fragment>);
	}

	creditCardForm() {
		const { is_active } = this.props;
		return (
			<React.Fragment>
				{ is_active
					&& <FormSubtitle>Update Your Credit or Debit Card Details</FormSubtitle>
				}
				<Field
					label='Cardholder’s name'
					type='text'
					name='fullname'
					placeholder='Fullname'
					component={FormField}
					validate={fullName}
					tooltip={{
						name: "",
						description: "Use the same name as displayed on your credit card",
						show_on_left: true,
					}}
				/>

				{this.stripe_form}

				<PrivacyPolicyBlock>
					<Field
						noBasis={true}
						label={
							<CheckboxLabelSpan htmlFor="have_the_permission">
								I am 18+/have the permisson of my guardian*
							</CheckboxLabelSpan>
						}
						name='have_the_permission'
						type='checkbox'
						component={FormCheckbox}
						parse={value => value ? 1 : 0}
					/>
				</PrivacyPolicyBlock>
				
				<PrivacyPolicyBlock>
					<Field
						noBasis={true}
						label={
							<CheckboxLabelSpan htmlFor="privacy_policy">
								I have read
								and accept the&nbsp;
								<a href="/account/fantasy-coach-terms" target='_blank'>
								Terms & Conditions*</a>
							</CheckboxLabelSpan>
						}
						name='privacy_policy'
						type='checkbox'
						component={FormCheckbox}
						parse={value => value ? 1 : 0}
					/>
				</PrivacyPolicyBlock>

				<ButtonWrapper>
					<ProButton
						disabled={this.state.validation_pending}
						onClick={() => {
							this.setState({ start_validation: true });
						}}
						type="submit">
						Confirm subscription to Pro {" "}
						{this.props.cur_plan === "full" ? "Deluxe" : "Lite"}
					</ProButton>
					<ProButton onClick={this.props.handleClose}>
            			Skip this for now
					</ProButton>

				</ButtonWrapper>
			</React.Fragment>
		);
	}

	render() {
		const { handleSubmit, subscribe, isAccountPage } = this.props;
		if(subscribe.is_pending){
			return <Preloader />;
		}
		return (
			<FormWrapper isAccountPage={isAccountPage}>
				<ProSubscriptionFormStyled				
					onSubmit={handleSubmit(data => {
						this.createToken(data);
					})}
					disabled={this.state.validation_pending || subscribe.is_pending}
				>
					{
						this.creditCardForm()
					}
				</ProSubscriptionFormStyled>
			</FormWrapper>
		);
	}
}

export const ProSubscriptionForm = reduxForm(
	{
		form:  "pro_subscription_form",
		enableReinitialize: true,
		validate: CoachSubscriptionValidate,
	}
)(ProSubscriptionFormComponent);

const mapStateToProps = (state, ownProps) => {
	return {
		leagueForm: getFormValues("league_manager")(state)
	};
};

export default injectStripe(connect(mapStateToProps, {})(ProSubscriptionForm));