/* eslint max-len: 0 */
// @flow
import React from "react";
import SvgIconContainer from "../SvgIconContainer";

export default (
	{
		unit = "em",
		...rest
	}: Object = {}) => (
	<SvgIconContainer viewBox="0 0 24 24" {...rest} unit={unit}>
		<title>Available</title>
		<path d="M5,10.292044 C5,10.1552699 5.09118279,10.0640871 5.22795696,10.0640871
        L9.08042963,10.0640871 C9.19440811,10.0640871 9.30838659,10.1552699 9.30838659,10.292044
        L9.30838659,17.9741937 C9.30838659,18.0881721 9.21720381,18.2021506 9.08042963,18.2021506
        L5.22795696,18.2021506 C5.11397848,18.2021506 5,18.1109678 5,17.9741937 L5,10.292044 Z
        M20.2275251,12.6400007 C20.2275251,12.6400007 20.2275251,12.617205 20.2275251,12.6400007
        L20.1363423,14.6232263 C20.1363423,14.646022 19.634837,16.674839 19.634837,16.674839
        L19.3156972,18.0653765 C19.2017188,18.6124732 18.7002134,19 18.1531167,19 L13.4116119,19
        C13.3204291,19 13.2292463,18.9772043 13.1608593,18.9088172 L11.3144079,17.495484
        C11.2688165,17.4726884 11.2460208,17.4498927 11.2004294,17.4498927 L10.1974187,17.4498927
        C10.0834403,17.4498927 9.99225748,17.3587099 9.99225748,17.2447314 L9.99225748,11.2266676
        C9.99225748,11.1126891 10.0834403,11.0215063 10.1974187,11.0215063 C10.2886015,11.0215063
        10.4253757,11.0215063 10.5165585,11.0215063 C10.6077413,11.0215063 10.6761284,10.9531192
        10.6989241,10.8847321 C11.1776337,9.22064631 12.0666658,8.6279582 13.0240851,8.0352701
        C13.0468808,8.0124744 13.0696765,7.98967871 13.0924722,7.96688301 C14.1866656,6.32559288
        14.6197838,5.27699085 14.6197838,5.25419516 C14.7109666,5.07182959 14.8705365,4.9806468
        15.0756977,5.0034425 C15.6911815,5.14021668 16.1242998,5.43656073 16.329461,5.89247465
        C16.785375,6.8270982 16.261074,8.17204428 15.5999988,9.63096884 C15.5316117,9.74494732
        15.6227945,9.90451719 15.7595686,9.92731289 L16.1470955,9.97290428 C16.1698912,9.97290428
        16.1242998,9.97290428 16.1470955,9.97290428 L18.7002134,9.76774302 C19.40688,9.74494732
        19.9767724,10.2692483 20.0679552,10.9531192 L20.2275251,12.6400007 Z" />
	</SvgIconContainer >
);