// @flow
import { call, put, takeLatest } from "redux-saga/effects";
import * as actions from "../../actions";

export const createSagas = (API: Object): Object => {
	function* fetchSquads() {
		try {
			const result = yield call(API.squads.fetch);
			yield put(actions.fetchSquadsSuccess(result));
		}
		catch (e) {
			if (process.env.NODE_ENV !== "test") {
				console.error(e);
			}
			yield put(actions.fetchSquadsFailed(e.message));
		}
	}

	function* watch() {
		yield takeLatest(actions.fetchSquads, fetchSquads);
	}

	return {
		fetchSquads,
		watch,
	};
};


export default createSagas;