// @flow
import React from "react";
import ordinal from "ordinal";
import * as _ from "lodash";
import styled from "styled-components";
import { FixtureTeam } from "..";
import { rounds_types } from "../../modules/types";
import { below } from "../../assets/css/media";

import type { TTeam } from "./types";
import { Score, ActualPoints, Rank, Wrapper } from "./FixtureWrapper";
import renderCup from "./cup";
import { Team } from "./FixtureTeam";

type Props = {
	team: TTeam,
	round: rounds_types.TRound,
	shadow?: boolean,
	compact?: boolean,
	className?: string,
	has_assistant_coach: boolean,
	is_game_bar?: boolean,
	show_rank: boolean,
	season_finished: boolean,
	game_type: string,
	league_id: number,
	in_league_page?: boolean,
	overview_page?: boolean,
	final_bye_team?: boolean,
}

const EliminatedWrapper = styled(Wrapper)`
	margin-bottom: ${({ compact }) => compact ? "5px" : "20px"};
	justify-content: flex-start;
	height: 80px;
	${({ inLeaguePage, compact }) => (inLeaguePage && compact) && `
		height: 60px;
		padding: 0px;
	`}
	.align-div{
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-evenly;
		height: 100%;
	}
	${({ isOverview }) => isOverview && `
		height: 60px;
	`}


	${below.tablet`
		margin-bottom: 5px;
	`}

	${Score} {
		flex-grow: 0;
		${below.tablet`
			flex-grow: 1;
		`}
	}
	${Rank}{
		${({ compact, inLeaguePage }) => (compact && inLeaguePage) && `
			background: white;
			border-right: none;
			justify-content: center;
			color: #1D4073;
			font-size: 14px;
			font-family: SourceSansPro;
			font-weight: 600;
		`}
	}

	${Team} {
		${below.tablet`
			flex:  0 1 150px;
			h5, h6 {
				max-width: 145px;
			}
		`}
	}
`;

export class EliminatedTeam extends React.Component<Props> {
	static defaultProps = {
		show_rank: false,
		season_finished: false,
	};

	get is_scheduled() {
		const { round } = this.props;
		return round.status === "scheduled";
	}

	get is_live() {
		const { round } = this.props;
		return round.status === "active";
	}

	get show_projected_score() {
		const { has_assistant_coach } = this.props;
		return (this.is_scheduled || this.is_live) && has_assistant_coach;
	}

	renderScore(team: TTeam) {
		const { is_game_bar, final_bye_team } = this.props;
		const is_display = team.points !== undefined && !final_bye_team;
		return <Score is_game_bar={is_game_bar}>
			{is_display &&
				<ActualPoints>
					{team.points}
				</ActualPoints>
			}
		</Score>;
	}

	render () {
		const {
			team,
			compact,
			is_game_bar,
			className,
			show_rank,
			game_type,
			league_id,
			season_finished,
			in_league_page,
			overview_page
		} = this.props;

		const show_rank_element = show_rank && compact;

		return (
			<EliminatedWrapper 
				className={className} 
				compact={compact} 
				inLeaguePage={in_league_page}
				isOverview={overview_page}
				is_game_bar={is_game_bar}>
				<div className="align-div">
					<Rank show={show_rank_element}>
						{ season_finished ? ordinal(_.get(team, "rank", 0)) : 
							ordinal(_.get(team, "selected_round_rank", 0)) }
					</Rank>
					<FixtureTeam
						compact={compact}
						is_game_bar={is_game_bar}
						team={team}
						hide_cup
						game_type={game_type}
						league_id={league_id}
						firstname={team.firstname}
						lastname={team.lastname}
						name={team.name}
						rank={team.rank}
						record_last_five={team.record_last_five}
						is_overview={in_league_page}
					/>
				</div>
				
				<div className="align-div">
					{ this.renderScore(team) }
					{ renderCup(team, false, true) }
				</div>
				
			</EliminatedWrapper>
		);
	}
}

export default EliminatedTeam;