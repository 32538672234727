// @flow
import styled, { css } from "styled-components";
import colors from "../../assets/css/colors";
import { TableFlexCell } from "./TableFlexCell";

export const PlayerTableCell = styled(TableFlexCell)`
	height: ${({ height }) => height || "60px"};

	${({ compressed }) => compressed && css`
		height: auto;
	`}

	flex: 1 0 auto;

	.live {
		display: flex;
		align-items: center;
		justify-content: center;
		color: #fff;
		background: ${colors.success};
		width: 30px;
		height: 20px;
		border-radius: 15px;
		font-weight: bold;
		font-size: 12px;
	}
`;

export default PlayerTableCell;