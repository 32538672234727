// @flow
import styled from "styled-components";
import { below } from "../../assets/css/media";

export const DraftTimerWrapper = styled.div`
	display: flex;
	align-items: center;
	flex-wrap: nowrap;
	text-align: center;
	font-size: 16px;
	padding: 30px 20px 30px 34px;
	color: ${({ is_your_pick }) => is_your_pick ? "#FFF" : "#1D4073"};
	height: 100%;
	box-sizing: border-box;
	
	.timer-unit {
		color: ${({ is_your_pick }) => is_your_pick ? "#FFF" : "#1D4073"};
	}
	
	.timer-label {
		color: ${({ is_your_pick }) => is_your_pick ? "#FFF" : "#7F8A90"};
	}
	
	.draft-timer {
		position: relative;
		
		&:before {
			display: ${({ is_your_pick }) => is_your_pick ? "block" : "none"};
			position: absolute;
			left: 50%;
			top: 50%;
			content: '';
			width: 0;
			height: 0;
			box-shadow: 0 0 1000px 120px rgba(29, 64, 115, 0.4);
			z-index: -1;
		}

		& > div {
			border-color: ${({ is_your_pick }) => is_your_pick ? "#198DE1" : "#cad2d9"};
		}
	}
	
	p {
		margin: 5px 5px 0 5px;
		white-space: nowrap;
		font-size: 14px

	}
	${below.phone`
		padding: 0px;
		height: 30%;
	`}
`;

export default DraftTimerWrapper;