// @flow
import React from "react";
import * as _ from "lodash";
import { isEmpty } from "lodash";
import {
	Exist,
	TrRow,
	PlayerInfo,
	TdStat,
	TdYourTeamExtended,
	DragIcon,
	DraftOrder,
	IgnoreCheckbox,
	Expander,
	DeluxOnlyLabel,
} from "../../../../components";
import { getShortName } from "../../../../helpers/league";
import { TBody, Td, Tr } from "../../../../components/TableX";
import {TDraftTeamsById, TPLeague, TPlayer, TDraftUserTeam} from "../../../../modules/types";
import { isBelowTablet, isMobile } from "../../../../utils";
import NoDrop from "../../../../components/Icons/NoDrop";
import ArrowUpSmall from "../../../../components/Icons/ArrowUpSmall";
import ArrowDownSmall from "../../../../components/Icons/ArrowDownSmall";
import Lock from "../../../../components/Icons/Lock";
import colors from "../../../../assets/css/colors";
import { isBothTrue, isEitherTrue } from "../../../classic/myTeam/helpers";
import {
	AbsoluteDiv,
	TdDraftedPlayerInfo,
	DraftedPlayerPickNum,
	DraftedPlayerPickUserName,
	TdShowAvatar,
	TdButtons,
	TdKeptBy,
	KeptByContent,
	LiveDraftSwitchButtonsWrap,
	PreDraftSwitchButton,
	TrRowDrafted,
	TdPlayerInfo,
	FcLink
} from "./LiveDraftStyles";
import DraftButton from "./draftButton";
import { MobilePlayerActions } from "./MobilePlayerActions";




const maybeGet = (condition, onTrue, onFalse) => condition ? onTrue : onFalse;

const indexHandler = (index, by_drafted) => {
	return by_drafted ? index + 1 : index;
};

const isEven = index => {
	return (index + 1) % 2 === 0;
};

type Props = {
    onChangePlayerOrder: Function,
    updatePlayerPreDraftListOrder: Function,
    table_keys: Object,
    is_compressed: boolean,
    is_dnd_allowed: boolean,
    onDrop: Function,
    onDragEnd: Function,
    onDragLeave: Function,
    onDragOver: Function,
    onDragEnter: Function,
    onDragStart: Function,
    onEnableDrag: Function,
    onDisableDrag: Function,
    filters: Object,
    coachSelectValue: string,
    all_stats: Object,
    show_kept_players: boolean,
    teams_by_id: TDraftTeamsById,
    kept_players_ids: number[],
    league: TPLeague,
    picks_by_player_id: Object,
    is_pending_players: boolean,
    pre_draft_list_size: number,
    isActiveTab: Function,
    player_list_use: TPlayer[],
    is_my_turn: boolean,
    getTableMessage: Function,
    isDrafted: Function,
    pickAPlayer: Function,
    drafted_players: Array<Object>,
    is_commissioner_type: boolean,
    filters: Object,
    onClickIgnore: Function,
    teams: TDraftUserTeam[],
    selected_team_id: number | string,
    has_assistant_coach: boolean,
    toggleFantasyCoachModal: Function,
    getAvailablePositions: Function,
    actions_player_id: number,
    closeActionsPlayerId: Function,
    openActionsPlayerId: Function,
	setPlayerForPick: Function,
	orderByComparator: Function
}




export const LiveDraftTableBody = (props: Props) => {
	function getPlayerSeasonStat(player: TPlayer, playerAllStats: Object, prop: string) {
		if (_.includes(prop, "games_played")) {
			return _.get(player, prop, "- -");
		}

		return _.get(playerAllStats, prop, "- -");
	}
	function findKeptPlayersOwner(playerID) {
		const { teams_by_id } = props;
		const teamsArr = Object.keys(teams_by_id).map(teamID => teams_by_id[teamID]);
		const ownerTeam = teamsArr.find(team => {
			const team_keepers = _.get(team, "keepers");
			const team_keep =	!_.isEmpty(team_keepers) 
				? team_keepers.map(keeper => keeper.id) : [];
			return team_keep.includes(playerID);
		});
		return _.get(ownerTeam, "name", "");
	}

	const formatValue = num => {
		if(isNaN(num)) {
			return num;
		}
		const maxTwoDecimal = num;
		if(maxTwoDecimal.toString().includes("0.5")){
			return maxTwoDecimal.toFixed(1);
		}
		return maxTwoDecimal.toFixed();
	};
	function statCell({ is_fc, player, playerAllStats, prop, is_delux, is_pro }) {
		const { has_assistant_coach, coachSelectValue } = props;

		if (is_pro && !is_delux) {
			return (
				<React.Fragment>
					<DeluxOnlyLabel>Deluxe only</DeluxOnlyLabel>
				</React.Fragment>
			);
		}
		
		return (
			is_fc && !has_assistant_coach ?
				(<FcLink onClick={props.toggleFantasyCoachModal}>
					<Lock color={colors.coach} />
				</FcLink>):	coachSelectValue === "custom-stats" ?
					getPlayerSeasonStat(player, playerAllStats, prop) :
					formatValue(_.get(player, prop, "--"))
		);
	}

	function columns_size() {
		const size_of_predefined_columns = 4;
		return _.size(props.table_keys) + size_of_predefined_columns;
	}

	function getTableMessage(message) {
		return (
			<Tr>
				<Td align="center" colSpan={columns_size()}>
					<Expander>{message}</Expander>
				</Td>
			</Tr>
		);
	}

	function getMobilePlayerActions(player: TPlayer, draft_button) {
		const { 
			is_compressed,
			updatePlayerPreDraftListOrder,
			pre_draft_list_size,
			show_kept_players,
			actions_player_id,
			openActionsPlayerId,
			closeActionsPlayerId
		 } = props;
		const { id, draft_order } = player;
		const is_ignored = !draft_order;
		const isOpen = id === actions_player_id;

		const mobilePassedProps = {
			is_compressed,
			updatePlayerPreDraftListOrder,
			pre_draft_list_size,
			show_kept_players,
			actions_player_id,
			id,
			draft_order,
			is_ignored,
			isOpen,
			player,
			openActionsPlayerId,
			onClickIgnore,
			closeActionsPlayerId,
			is_my_turn,
			is_commissioner_type,
			draft_button,
			filters,
		};
		return <MobilePlayerActions {...mobilePassedProps}/>;
	}

	function setPlayerToDraft(player) {
		if(is_my_turn || is_commissioner_type){
			setPlayerForPick(player);
		}
	}

    
	const {
		onChangePlayerOrder,
		updatePlayerPreDraftListOrder,
		table_keys,
		is_compressed,
		is_dnd_allowed,
		onDrop,
		onDragEnd,
		onDragLeave,
		onDragOver,
		onDragEnter,
		onDragStart,
		onEnableDrag,
		onDisableDrag,
		filters: { by_drafted },
		coachSelectValue,
		all_stats,
		show_kept_players,
		teams_by_id,
		kept_players_ids,
		league,
		is_pending_players, 
		pre_draft_list_size, 
		isActiveTab,
		picks_by_player_id,
		player_list_use,
		is_my_turn,
		isDrafted,
		is_commissioner_type,
		filters,
		drafted_players,
		onClickIgnore,
		getAvailablePositions,
		teams,
		orderByComparator,
		selected_team_id,
		setPlayerForPick
	} = props;

	const show_drafted_mobile = isBothTrue(isMobile(), by_drafted);

	if(isEitherTrue(isEmpty(props), isEmpty(table_keys))){
		return null;
	}

	if (is_pending_players) {
		return (
			<TBody>
				{ getTableMessage("Loading, please wait...") }
			</TBody>
		);
	}
	// Active tab will be Drafted Players only on mobile otherwise it will be PDL
	const
		players_list = player_list_use;

		
	const is_delux = league.subscription === "full";
	const cells = players_list.map((player, index) => {
			
		const index_handler = indexHandler(index, by_drafted);
		const is_even = isEven(index_handler);
		const { draft_order } = player,
			is_ignored = !draft_order,
			is_drafted = isDrafted(player),
			is_not_able_to_change_order = _.some(
				[is_ignored, is_drafted],
				_.identity,
			),
			is_dnd_enabled = !is_not_able_to_change_order && is_dnd_allowed,
			is_draft_disabled = (
				_.some([
					!is_my_turn,
					is_drafted,
					_.isEmpty(getAvailablePositions(player, teams, selected_team_id)),
				], _.identity)
			);

		const draft_button = maybeGet(
			is_draft_disabled,
			<NoDrop size={1.5}/>,
			<DraftButton onClick={_.partial(setPlayerToDraft, player)}/>
		);
		const playerAllStats = _.get(all_stats, `${player.id}`, {});
		const keeper_players = kept_players_ids;
		const is_keeper = keeper_players.includes(player.id);
		const keeperOwner = findKeptPlayersOwner(player.id);
			
		const
			pick_object = _.get(picks_by_player_id, player.id, {}),
			user = _.get(teams_by_id, pick_object.picked_team_id, {});
		const is_mobile = isMobile();

		if(show_drafted_mobile){
			return (
				<TrRow
					index={index_handler}
					draggable="false"
					data-drag-and-drop-enabled={is_dnd_enabled}
					data-player-id={player.id}
					onDrop={onDrop}
					onDragEnd={onDragEnd}
					onDragEnter={onDragEnter}
					onDragLeave={onDragLeave}
					onDragOver={onDragOver}
					onDragStart={onDragStart}
					key={player.id}
					is_compressed={is_compressed}
					scrollable={coachSelectValue === "custom-stats"}
				>
					<AbsoluteDiv is_even={is_even}>
						<TdDraftedPlayerInfo
							is_compressed={is_compressed}
						>
							<DraftedPlayerPickNum>
								<Exist when={is_keeper}>
										Keeper
								</Exist>
								<Exist when={!is_keeper}>
									{pick_object.pick - 1}
								</Exist>
							</DraftedPlayerPickNum>
							<DraftedPlayerPickUserName>
								<Exist when={is_keeper}>
									{keeperOwner}
								</Exist>
								<Exist when={!is_keeper}>
									{getShortName(user)}
								</Exist>
							</DraftedPlayerPickUserName>
						</TdDraftedPlayerInfo>
					
						<TdShowAvatar is_compressed={is_compressed} player_info={true} 
							className="player-info-stat">
							<PlayerInfo
								className="player-info"
								player={player}
								is_compressed={is_compressed}
								is_classic={false}
							/>
						</TdShowAvatar>

					</AbsoluteDiv>
						
					
					{table_keys.map(({ key, prop, is_fc, is_pro }) => (
						<TdStat
							className={`player-stats ${isActiveTab(prop)}`}
							is_fc={is_fc} key={key}
							is_compressed={is_compressed}
							rel={prop}
						>
							{statCell({
								is_fc, player, playerAllStats, prop, is_delux, is_pro
							})}
							
						</TdStat>
					))}
					<TdButtons
						is_compressed={is_compressed}>
						<NoDrop size={1.5}/>
					</TdButtons>
			
				
				</TrRow>
			);
		};

		return (
			<TrRow
				index={index_handler}
				draggable="false"
				data-drag-and-drop-enabled={is_dnd_enabled}
				data-player-id={player.id}
				onDrop={onDrop}
				onDragEnd={onDragEnd}
				onDragEnter={onDragEnter}
				onDragLeave={onDragLeave}
				onDragOver={onDragOver}
				onDragStart={onDragStart}
				key={player.id}
				is_compressed={is_compressed}
				scrollable={coachSelectValue === "custom-stats"}
			>
				<AbsoluteDiv is_even={is_even}>
					<Exist when={show_kept_players}>
						<TdKeptBy>
							<KeptByContent>
									Kept by: <span>{findKeptPlayersOwner(player.id)}</span>
							</KeptByContent>
						</TdKeptBy>
					</Exist>
					<Exist when={!show_kept_players}>
						<React.Fragment>
							<Exist when={!is_mobile}>
								<Td is_compressed={is_compressed} className="dnd">
									<DragIcon
										onMouseEnter={onEnableDrag}
										onMouseLeave={onDisableDrag}
										disabled={!is_dnd_enabled}
									/>
								</Td>
							</Exist>
			
								
							<TdYourTeamExtended is_compressed={is_compressed}>
								<DraftOrder
									disabled={is_not_able_to_change_order}
									onChange={onChangePlayerOrder}
									name="draft_order"
									data-id={player.id}
									maxValue={pre_draft_list_size}
									value={draft_order || "-"}
									is_compressed={is_compressed}
								/>
								<Exist when={!is_mobile}>
									<LiveDraftSwitchButtonsWrap is_compressed={is_compressed}>
										<PreDraftSwitchButton
											up={true}
											disabled={
												is_not_able_to_change_order 
													|| draft_order === 1
											}
											data-order={parseInt(draft_order, 10) - 1}
											data-id={player.id}
											onClick={updatePlayerPreDraftListOrder}
											is_compressed={is_compressed}
										>
											<ArrowUpSmall />
										</PreDraftSwitchButton>
										<PreDraftSwitchButton
											disabled={(
												is_not_able_to_change_order
													|| pre_draft_list_size === draft_order
											)}
											data-order={parseInt(draft_order, 10) + 1}
											data-id={player.id}
											onClick={updatePlayerPreDraftListOrder}
											is_compressed={is_compressed}
										>
											<ArrowDownSmall />
										</PreDraftSwitchButton>
									</LiveDraftSwitchButtonsWrap>
								</Exist>
						
							</TdYourTeamExtended>
						</React.Fragment>
					</Exist>
						
	
					
					<TdShowAvatar is_compressed={is_compressed} player_info={true} 
						className="player-info-stat">
						<PlayerInfo
							className="player-info"
							player={player}
							is_compressed={is_compressed}
							is_classic={false}
						/>
					</TdShowAvatar>

				</AbsoluteDiv>
					
					
				{table_keys.map(({ key, prop, is_fc, is_pro }) => (
					<TdStat
						className={`player-stats ${isActiveTab(prop)}`}
						is_fc={is_fc} key={key}
						is_compressed={is_compressed}
						rel={prop}
					>
							
						{statCell({
							is_fc, player, playerAllStats, prop, is_delux, is_pro
						})}
							
					</TdStat>
				))}
				<Exist when={isBelowTablet()}>
					{getMobilePlayerActions(player, draft_button)}
				</Exist>
				<Exist when={!isBelowTablet()}>
					<TdButtons is_compressed={is_compressed}>
						{maybeGet(
							is_commissioner_type,
							draft_button,
							maybeGet(
								(is_my_turn && !filters.by_ignored),
								draft_button,
								<IgnoreCheckbox
									onChange={onClickIgnore}
									checked={is_ignored}
									name={`ignore-${player.id}`}
									pre_draft={true}
								/>
							)
						)}
					</TdButtons>
				</Exist>
			</TrRow>
		);
	});

	const keeper_players = kept_players_ids;
	const drafted_cells = drafted_players.sort(orderByComparator).map(player => {
		// only use pick_object if not keeper player 
		const
			pick_object = _.get(picks_by_player_id, player.id, {}),
			user = _.get(teams_by_id, pick_object.picked_team_id, {});
		const is_keeper = keeper_players.includes(player.id);
		const keeperOwner = findKeptPlayersOwner(player.id);
		const playerAllStats = _.get(all_stats, `${player.id}`, {});
		if(isMobile()){
			return <TrRowDrafted key={player.id} is_compressed={is_compressed}>
				<AbsoluteDiv is_even={false}>
					<TdDraftedPlayerInfo
						is_compressed={is_compressed}
						colSpan={1}
					>
						<DraftedPlayerPickNum>
							{is_keeper ? 
								"Keeper"
								: pick_object.pick - 1
							}
						</DraftedPlayerPickNum>
						<DraftedPlayerPickUserName>
							{is_keeper ?
								keeperOwner
								: getShortName(user)
							}
						</DraftedPlayerPickUserName>
					</TdDraftedPlayerInfo>
					<TdPlayerInfo is_compressed={is_compressed} drafted>
						<PlayerInfo
							className="player-info"
							player={player}
							is_compressed={is_compressed}
						/>
					</TdPlayerInfo>

				</AbsoluteDiv> 
				{table_keys.map(({ key, prop, is_fc, is_pro }) => (
					<TdStat
						className={`player-stats ${isActiveTab(prop)}`}
						key={key}
						is_fc={is_fc}
						is_compressed={is_compressed}
					>
						{statCell({
							is_fc, player, playerAllStats, prop, is_delux, is_pro
						})}
					</TdStat>
				))}
				<TdButtons
					is_compressed={is_compressed}>
					<NoDrop size={1.5}/>
				</TdButtons>
						
			</TrRowDrafted>;
					
		}

		return (
			<TrRowDrafted key={player.id} is_compressed={is_compressed}>
				
				<TdDraftedPlayerInfo
					is_compressed={is_compressed}
					colSpan={1}
				>
					<DraftedPlayerPickNum>
						{is_keeper ? 
							"Keeper"
							: pick_object.pick - 1
						}
					</DraftedPlayerPickNum>
					<DraftedPlayerPickUserName>
						{is_keeper ?
							keeperOwner
							: getShortName(user)
						}
					</DraftedPlayerPickUserName>
				</TdDraftedPlayerInfo>
				<TdPlayerInfo 
					is_compressed={is_compressed} 
					drafted 
					coachSelectValue={coachSelectValue}> 
					<PlayerInfo
						className="player-info"
						player={player}
						is_compressed={is_compressed}
					/>
				</TdPlayerInfo>
				<TdStat is_compressed={is_compressed}></TdStat>
		
				{table_keys.map(({ key, prop, is_fc, is_pro }) => (
					<TdStat
						className={`player-stats ${isActiveTab(prop)}`}
						key={key}
						is_fc={is_fc}
						is_compressed={is_compressed}
					>
						{statCell({
							is_fc, player, playerAllStats, prop, is_delux, is_pro
						})}
					</TdStat>
				))}
				<TdButtons
					is_compressed={is_compressed}>
					<NoDrop size={1.5}/>
				</TdButtons>
			</TrRowDrafted>
		);
	});

	return (
		<TBody>
			{/* {by_drafted && !_.isEmpty(drafted_cells) ? (
					<TrRow>
						<Td style={{ padding: 0 }} colSpan={this.columns_size}>
							<DraftedPlayersList>
								<TableFixed>
									<TBody>
										{ drafted_cells }
									</TBody>
								</TableFixed>
							</DraftedPlayersList>
						</Td>
					</TrRow>
				) : null} */}

			{ !is_pending_players && _.isEmpty(players_list) ? (
				getTableMessage(
					"Sorry, there are no players who match this search query. " +
						"Please adjust your filters above and try again."
				)
			) : by_drafted ? drafted_cells : cells }
		</TBody>
	);
	
};