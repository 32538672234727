// @flow
import styled, { css } from "styled-components";
import TdYourTeam from "./TdYourTeam";

export const TdYourTeamExtended = styled(TdYourTeam)`
	white-space: nowrap;
	display: table-cell;
	
	input,
	div {
		display: inline-block;
		vertical-align: middle;
	}
	
	div button:first-child {
		margin-bottom: 1px;
	}
	
	${({ is_compressed }) => is_compressed ? css`
		padding: 3px 0;
		height: 30px;
		margin-bottom: 0px;
		input {
			height: 18px;
			border-radius: 0px;
		}
		
		div {
			white-space: nowrap;
			
			button {
				width: 26px;
				height: 20px;
				border-radius: 0px;
				display: inline-block;
				padding: 0;
				
				&:first-child {
					margin: 0 2px 0 0;
				}
			}
		}
	` : ""}
`;

export default TdYourTeamExtended;