import * as React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { bindAll } from "lodash";
import * as actions from "../../../../modules/actions";
import { InputItem, ButtonPrimary, Exist } from "../../../../components";
import CloseCircle from "../../../../components/Icons/CloseCircle";
import TickCircle from "../../../../components/Icons/TickCircle";
import type { TPLeague, TDraftUserTeam } from "../../../../modules/types";
import { below } from "../../../../assets/css/media";
import ReplaceCoachModalComponent from "./ReplaceCoachErrorModal";


const ConfirmedButton = styled.p`
	background: none;
	border: none;
	color: #4EA431;
	font-family: SourceSansPro;
	font-size: 12px;
	font-weight: 400;
	text-align: center;
	min-width: 103.5px;
`;

const FormWrapper = styled.form`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding-right: 10px;
  input{
    font-family: SourceSansPro;
    font-weight: 400;
    border-radius: 0;
    font-size: 12px;
    border: 1px solid #CAD2D8;
    height: 30px;
    min-width: 220px;
    ::placeholder{
      font-family: SourceSansPro;
      font-weight: 400;
      font-size: 12px;
    }
  }
  button {
    height: 30px;
    padding: 5px 30px;
  }
  ${below.phone`
 	input{
		min-width: 100px;
		padding-right: 1em;
	 } 
  `}
`;

const InputWrapper = styled.div`
  width: 100%;
  margin-right: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  svg{
		display: block;
    align-self: center;
    margin-left: -25px;
    height: 16px;
    width: 16px;
    padding-top: 5px
  }
  div{
		display: block;
    align-self: center;
    margin-left: -25px;
    height: 16px;
    width: 16px;
    padding-top: 5px
  }
`;
type Props = {
	team: TDraftUserTeam,
	league: TPLeague,
	checkEmail: Function,
	addCoach: Function,
	currentReplaceIndex: Array<number>,
	changeTeamCoach: Function,
	changeTeamCoachClear: Function,
	new_coach_id: number,
	new_coach_firstname: string,
	new_coach_lastname: string,
	index: number,
	is_pending: boolean,
	error: any,
	success: boolean,
}
const fieldLevelRequired = value => (value && value.length > 1) 
	? true 
	: false;
const fieldLevelEmail = value =>
	((value && value.length > 1) && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value))
		? true
		: false;

class ReplaceCoachComponent extends React.Component<Props, State> {
  
	constructor(props, state) {
		super(props, state);

		bindAll(this, [
			"handleSubmit",
			"handleChange",
			"closeClick"
		]);
	}
	state = {
  	firstname: "",
  	email: "",
  	errors: {
  		firstname: null,
  		email: null,
  	},
  	loading: false,
  	local_exists: false,
  	modal_open: false,
	};

	async handleSubmit(e: any){
  	this.setState({ loading: true });
  	e.preventDefault();
  	const { 
  		team, 
  		league, 
  		addCoach, 
  		changeTeamCoach, 
  		changeTeamCoachClear, 
  	} = this.props;
  	changeTeamCoachClear();
  	const payload = { 
  		firstname: this.state.firstname, 
  		email: this.state.email, 
  		team_id: team.id, 
  		league_id: league.id 
  	};
  	await changeTeamCoach(payload);
  	setTimeout(() => {
  		if(this.props.success === true){
  		this.setState({ local_exists: true });
  		addCoach();
  	}
  	if(this.props.success === false){
  		this.setState({ modal_open: true });
  		this.setState({ loading: false });
  	}
  	}, 2000);
  	
  	
	}
	handleChange(e){
  	const { name, value } = e.target;
  	
  	let errors = this.state.errors;  

  	switch (name) {
  		case "firstname":
  			errors.firstname = fieldLevelRequired(value);
  			break;
  		case "email":
  			errors.email = fieldLevelEmail(value);
  			break;
  		default:
  			break;
  	}
  	this.setState({ [e.target.name]: e.target.value, errors: errors });
	}
	closeClick(){
  	const { changeTeamCoachClear } = this.props;
  	this.setState({ modal_open: false });
  	changeTeamCoachClear();
	}
	get is_disabled(){
  	const { errors, loading } = this.state;
  	if(errors.firstname === false || errors.email === false){
  		return true;
  	}
  	if(errors.firstname === null || errors.email === null){
  		return true;
  	}
  	if(loading === true){
  		return true;
  	}
  	return false;
	}
  
	render() {
  	const { errors } = this.state;
  	const { error, is_pending } = this.props;
  	return(
			
  		<FormWrapper onSubmit={this.handleSubmit}>
  			<InputWrapper>
  				<InputItem
  					label="First name"
  					type='text'
  					name={"firstname"}
  					tooltip={false}
  					placeholder={"First name"}
  					onChange={this.handleChange}
  				/>
  				<Exist when={errors.firstname === null}>
  					<div></div>
  				</Exist>
  				<Exist when={errors.firstname === true}>
  					<TickCircle /> 
  				</Exist>
  				<Exist when={errors.firstname === false}>
				  <CloseCircle />
  				</Exist>
  			

  			</InputWrapper>
  			<InputWrapper>
  				<InputItem
  					label="Email Address"
  					type='text'
  					name={"email"}
  					tooltip={false}
  					placeholder={"Email address"}
  					onChange={this.handleChange}
  				/>
  				<Exist when={errors.email === null}>
  					<div></div>
  				</Exist>
  				<Exist when={errors.email === true}>
  					<TickCircle /> 
  				</Exist>
  				<Exist when={errors.email === false}>
				  <CloseCircle />
  				</Exist>
  			</InputWrapper>
  			{this.state.local_exists ? 
  				<ConfirmedButton>Replaced</ConfirmedButton> 
  				: 
  				<ButtonPrimary 
  					type='submit' 
  					disabled={is_pending || this.is_disabled}
  				>Replace
  				</ButtonPrimary>}
  			{(this.state.modal_open && error !== null) &&
					<ReplaceCoachModalComponent 
						closeClick={this.closeClick} 
						message={error.message}
					/>}
  		</FormWrapper>
  	);
	}
}


const mapStateToProps = (state, props) => ({
	is_pending: state.leagues.change_team_coach.is_pending,
	error: state.leagues.change_team_coach.error,
	new_coach_id: state.leagues.change_team_coach.user_id,
	new_coach_firstname: state.leagues.change_team_coach.first_name,
	new_coach_lastname: state.leagues.change_team_coach.last_name,

});
const mapDispatchToProps = {
	checkEmail: actions.checkEmail,
	clearCheckEmailData: actions.clearCheckEmailData,
	changeTeamCoach: actions.leagueDraft.changeTeamCoach,
	changeTeamCoachClear: actions.leagueDraft.changeTeamCoachClear,
};



export const ReplaceCoach = connect(
	mapStateToProps, mapDispatchToProps
)(ReplaceCoachComponent);
export default ReplaceCoach;