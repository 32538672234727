import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import _ from "lodash";
import * as actions from "../../modules/actions";
import {TUser} from "../../modules/types";
import {
	TextModal,
	ConfirmationModal,
	Exist,
	Preloader,
} from "../../components";

import { isAllTrue } from "../../helpers";
import AccountForm from "./accountForm";
import { LinkNewAccount } from "./LinkNewAccount";



type Props = {
	user: TUser,
	fetchCountries: Function,
	updateUser: typeof actions.updateUser,
	fetchUser: typeof actions.fetchUser,
	deactivateUser: typeof actions.deactivateUser,
	clearUpdateUserData: typeof actions.clearUpdateUserData,
	emailCheck: typeof actions.emailCheck,
	fetchSquads: Function,
	squads: Array<Object>,
	// countries: Array<Object>,
	user_update: {
		error: {
			message: string,
		},
		success: boolean,
	},
	deactivate: Object
};

type State = {
	file: Object,
	previewImg: string,
	deactivate_modal_open: boolean,
	birthday: string,
}

class AccountContentComponent extends React.Component<Props, State> {
	constructor() {
		super();

		_.bindAll(this, [
			"handleSubmit",
			"handleErrorModalClose",
			"onChangeFile",
			"onChangeBirthday",
		]);
	}

	state = {
		previewImg: "",
		file: {},
		deactivate_modal_open: false,
		birthday: "",
	};

	componentDidMount() {
		const { fetchSquads, fetchUser, emailCheck} = this.props;
		fetchSquads();
		// fetchCountries();
		fetchUser();
		emailCheck();
	}

	componentWillUnmount(): void {
		this.props.fetchUser();
	}

	get squadsOptions() {
		const { squads } = this.props;
		const squads_options = _.map(squads, squad => {
			return {
				value: squad.id,
				text: squad.full_name,
			};
		});

		return _.concat(squads_options, {
			value: "",
			text: "Select a team",
		});
	}

	// get countriesOptions() {
	// 	const { countries } = this.props;
	// 	return _.map(countries, country => {
	// 		return {
	// 			value: country.code,
	// 			text: country.name,
	// 		};
	// 	});
	// }

	onChangeFile(e) {
		const
			reader = new FileReader(),
			file = e.target.files[0];

		reader.onload = e => {
			this.setState({ previewImg: e.target.result });
		};
		if(file){
			reader.readAsDataURL(file);
			this.setState({ file });
		}	
	}

	onChangeBirthday(birthday) {
		this.setState({ birthday });
	}

	handleSubmit(values) {
		const { updateUser } = this.props;
		const { file } = this.state;
		const { birthday } = this.state;

		let data_to_send = _.omit({ ...values }, ["birthday"]);

		// if (typeof (values.birthday) !== "string") {
		// 	data_to_send = {
		// 		birthday: birthday.format("YYYY-MM-DD[T]HH:mm:ss"),
		// 		...data_to_send,
		// 	};
		// }
		data_to_send = {
			birthday,
			...data_to_send,
		};

		updateUser({
			...data_to_send,
			avatar: file,
		});
	}

	handleErrorModalClose() {
		const { clearUpdateUserData } = this.props;
		clearUpdateUserData();
	}

	get error_modal() {
		const { user_update } = this.props;
		const text = _.get(user_update, "error.message");

		return (
			<TextModal
				onClick={this.handleErrorModalClose}
				header_text='Error'
				text={ text } />
		);
	}

	get success_modal() {
		return (
			<TextModal
				onClick={this.handleErrorModalClose}
				header_text='Account settings'
				text='Your account settings have now been updated!' />
		);
	}

	get deactivate_modal() {
		return (
			<ConfirmationModal
				cancelClick={() => this.setState({ deactivate_modal_open: false })}
				closeClick={() => this.setState({ deactivate_modal_open: false })}
				confirmClick={this.props.deactivateUser}
				header_text='Deactivate Account'
				body_text='This will deactivate your account. You will no longer be able to log in to your account. Are you sure?' // eslint-disable-line
			/>
		);
	}

	render() {
		if(_.isEmpty(this.props)){
			return null;
		}
		const {
			user,
			user_update,
			userIsPending,
			deactivate
		} = this.props;
		const { previewImg, deactivate_modal_open } = this.state;
		const hasUserData = Boolean(Object.keys(user).length);
		if(!deactivate.is_pending && deactivate.success) {
			return <Redirect to="/logout" />;
		}

		if(!user || userIsPending){
			return <Preloader />;
		}

		return (
			<div>
				<Exist when={Boolean(user_update.error)}>
					{this.error_modal}
				</Exist>
				<Exist when={ isAllTrue([ !user_update.error, user_update.success])}>
					{this.success_modal}
				</Exist>
				<Exist when={deactivate_modal_open}>
					{this.deactivate_modal}
				</Exist>
				<Exist when={!hasUserData}>
					<Preloader />
				</Exist>
				<Exist when={hasUserData}>
					<AccountForm
						onSubmit={this.handleSubmit}
						user={user}
						squadsOptions={this.squadsOptions}
						// countriesOptions={this.countriesOptions}
						previewImg={previewImg}
						onChangeFile={this.onChangeFile}
						onChangeBirthday={this.onChangeBirthday}
						onDeactiveClick={e => {
							e.preventDefault();
							this.setState({ deactivate_modal_open: true });
						}}
					/>
					{/* Removed https://geniussports.atlassian.net/browse/F2P1-38902  */}
					<LinkNewAccount />
				</Exist>					
			</div>
		);
	};
}

const mapStateToProps = state => ({
	user: state.user.data,
	userIsPending: state.user.user.is_pending,
	// countries: state.countries.data,
	user_update: state.user.update,
	deactivate: state.user.deactivate
});

const mapDispatchToProps = {
	// fetchCountries: actions.fetchCountries,
	fetchSquads: actions.fetchSquads,
	fetchUser: actions.fetchUser,
	updateUser: actions.updateUser,
	clearUpdateUserData: actions.clearUpdateUserData,
	deactivateUser: actions.deactivateUser,
	emailCheck: actions.emailCheck,
};

const AccountContent = connect(
	mapStateToProps,
	mapDispatchToProps,
)(AccountContentComponent);

export default AccountContent;