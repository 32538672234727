// @flow
import type { Saga } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import * as actions from "../../actions/index";

type Action = {
	payload: Object
};

export const createSagas = (API: Object): Object => {

	function* sendMessageSaga(action: Action): Saga<void> {
		try {
			const { result, errors } = yield call(API.coachesBox.create_message, action.payload);

			if (errors.length) {
				yield put(actions.sendCoachesBoxMessageFailed(errors[0].text));
			}
			else {
				yield put(actions.sendCoachesBoxMessageSuccess(result));
			}
		}
		catch (event) {
			console.log(event);
		}
	}

	function* getMessagesSaga(action: Action): Saga<void> {
		try {
			const { result, errors } = yield call(API.coachesBox.get_messages, action.payload);

			if (errors.length) {
				yield put(actions.getCoachesBoxMessagesFailed(errors[0].text));
			}
			else {
				yield put(actions.getCoachesBoxMessagesSuccess({
					comments: result,
					...action.payload
				}));
			}
		}
		catch (event) {
			console.log(event);
		}
	}

	function* updateSettingsSaga(action: Action): Saga<void> {
		try {
			const { coaches_box, type, league_id, ...rest } = action.payload;

			if (type !== "draft" && type !== "classic") {
				throw new TypeError(`Type ${type} is not one of 'classic' or 'draft'`);
			}

			const endpoint = coaches_box ? API.coachesBox.add_box : API.coachesBox.remove_box;
			const { errors } = yield call(endpoint, { ...rest, league_id, type });

			if (errors.length) {
				yield put(actions.setCoachesBoxSettingsFailed(errors[0].text));
			}
			else {
				yield put(actions.setCoachesBoxSettingsSuccess({
					league_id,
					coaches_box,
					type
				}));
			}
		}
		catch (event) {
			console.log(event);
		}
	}

	function* getSettingsSaga(action: Action): Saga<void> {
		try {
			const { result, errors } =
				yield call(API.coachesBox.get_settings, { ...action.payload });

			if (errors.length) {
				yield put(actions.getCoachesBoxSettingsFailed(errors[0].text));
			}
			else {
				yield put(actions.getCoachesBoxSettingsSuccess(result));
			}
		}
		catch (event) {
			console.log(event);
		}
	}

	function* watch() {
		yield takeLatest(actions.sendCoachesBoxMessage, sendMessageSaga);
		yield takeLatest(actions.getCoachesBoxMessages, getMessagesSaga);
		yield takeLatest(actions.getCoachesBoxSettings, getSettingsSaga);
		yield takeLatest(actions.setCoachesBoxSettings, updateSettingsSaga);
	}

	return {
		getSettingsSaga,
		watch
	};
};

export default createSagas;