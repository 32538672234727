// @flow
import * as React from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import _, { get } from "lodash";

import type { 
	TRootStore, 
	TRound, 
	TPLeague, 
	TPlayersById, 
	TDraftTeamsById 
} from "../../../../modules/types";
import * as selectors from "../../../../modules/selectors";
import * as actions from "../../../../modules/actions";
import colors from "../../../../assets/css/colors";

import { withDraftPlayerModal } from "../../../../components";
import withRoundsSelector from "../../../../components/utils/withRoundsSelector";

import TransactionHistoryFilters from "./transactionHistory/transactionHistoryFilters";
import TransactionHistoryRFARow from "./transactionHistory/transactionHistoryRFARow";

const EmptyState = styled.div`
	padding: 20px 20px 22px;
	background-color: ${colors.secondary.lightGrey};
	border-radius: 3px;
	font-family: SourceSansPro;
    font-size: 13px;
`;

type Props = {
	league_rounds: Array<TRound>,
	actual_round: TRound,
	transactionHistory: typeof actions.leagueDraft.fetchTransactionHistory,
	showTeamsDraftLeague: typeof actions.leagueDraft.showTeamsDraftLeague,
	league_id: number,
	transaction_history: Array<Object>,
	teams_by_id: TDraftTeamsById,
	players_by_id: TPlayersById,
	transaction_history_with_player_names: Array<Object>,
	favourite_players: Array<number>,
	league: TPLeague,
}

type State = {
	selected_round_id: number,
	selected_owner: number,
	selected_transaction_type: string,
	selected_player: string,
	set_favourites: boolean,
}
export class TransactionHistoryPage extends React.Component<Props, State> {
	static defaultProps = {
		selected_round_id: 0 //'All'
	};
	constructor(props: Props, state: State) {
		super(props, state);

		_.bindAll(this, [
			"getTransactionRow",
			"setSelectedRoundId",
			"setSelectedOwner",
			"setSelectedTransactionType",
			"setPlayerSearch",
			"onSearchSubmit",
			"setFavourites"
		]);
	}

	state = {
		selected_round_id: 0,
		selected_owner: 0,
		selected_transaction_type: "",
		selected_player: "",
		set_favourites: false
	};

	componentDidMount() {
		const {
			transactionHistory,
			showTeamsDraftLeague,
			league_id
		} = this.props;

		transactionHistory({
			league_id
		});
		showTeamsDraftLeague({
			league_id: league_id
		});

	}
	componentDidUpdate(prev_props: Props, prev_state: State) {
		const {
			transactionHistory,
			league_id,
		} = this.props;
		const { selected_round_id, selected_owner } = this.state;

		let data = { league_id };
		if (prev_state !== this.state) {

			if(selected_round_id === 0) {
				transactionHistory({
					...data,
					team_id: selected_owner,

				});
			}
			else {
				transactionHistory({
					...data,
					round_id: selected_round_id,
					team_id: selected_owner,
				});
			}
		}
	}

	get get_transaction_list() {
		const {
			transaction_history_with_player_names,
		} = this.props;

		const {
			selected_player,
		} = this.state;

		const filteredTransactions = transaction_history_with_player_names
			? transaction_history_with_player_names
				.filter(transaction => transaction.player_name
					.includes(selected_player.toLowerCase())) : [];

		return this.getFilteredByType(filteredTransactions);
	}

	getFilteredByType(FilteredTransactions: Array<Object>) {
		const {
			selected_transaction_type
		} = this.state;

		if (selected_transaction_type === "free_agent") {
			return this.getFilteredByFavourites(FilteredTransactions
				.filter(trans => trans.type !== "Trade"));
		}
		else if (selected_transaction_type === "trade") {
			return this.getFilteredByFavourites(FilteredTransactions
				.filter(trans => trans.type === "Trade"));
		}
		else {
			return this.getFilteredByFavourites(FilteredTransactions);
		}
	}

	getFilteredByFavourites(FilteredFurtherTransactions: Array<Object>) {

		return this.getTransactionRow(FilteredFurtherTransactions);

	}

	get history_empty_state() {
		const { actual_round = {} } = this.props;
		const { selected_round_id } = this.state;
		if (selected_round_id === 0) {
			return <EmptyState>No transactions have been made.</EmptyState>;
		}
		if (selected_round_id >= actual_round.id) {
			return <EmptyState>No transactions have been made this round.</EmptyState>;
		}
		return <EmptyState>No transactions match this criteria.</EmptyState>;
	}

	getTransactionRow(trade: Array<Object>) {
		const {
			set_favourites
		} = this.state;
		const {
			players_by_id,
			favourite_players
		} = this.props;

		// Order should be preserved for RFAs made at the same exact time
		const tradesWithOrder = _.map(trade, (value, i) => ({ ...value, order: i }));

		const transactionsRFAs = _.filter(
			tradesWithOrder,
			trans => trans.external_id !== 0
				&& trans.from !== "Trade" && trans.to !== "Trade"
		);

		const transactionsTradesAndFAs = _.filter(
			tradesWithOrder,
			trans => trans.external_id === 0
				|| trans.from === "Trade" || trans.to === "Trade"
		);

		const accessorOne = "value[0].player_id";
		const accessorTwo = "value[1].player_id";

		const pairedRFAs = _(transactionsRFAs)
			.groupBy(x => x.external_id)
			.map((value, key) => ({ value }))
			.value();

		const pairedTradeAndFAs = _(transactionsTradesAndFAs)
			.groupBy(x => x.date_iso)
			.map((value, key) => ({ value }))
			.value();

		const filteredPairedRFAs = pairedRFAs.filter(trade => {
			if(set_favourites){
				const firstPlayer = get(trade, accessorOne);
				const secondPlayer = get(trade, accessorTwo);
				return favourite_players.includes(firstPlayer) 
					|| favourite_players.includes(secondPlayer); 
			};
			return true;
		});

		const filteredFavouritesTradeFAs = pairedTradeAndFAs.filter(trade => {
			if(set_favourites){
				const firstPlayer = get(trade, accessorOne);
				const secondPlayer = get(trade, accessorTwo);

				return favourite_players.includes(firstPlayer) 
					|| favourite_players.includes(secondPlayer); 
			};
			return true;
		});

		const transactionsJoinedTogether = filteredPairedRFAs.concat(filteredFavouritesTradeFAs);


		// Sort first by date
		// If date matches, refer to the original order
		const transactionsSortedByDate = _.orderBy(
			transactionsJoinedTogether,
			[
				"value.0.date_iso",
				"value.0.order"
			],
			[ "desc", "asc" ]
		);

		return (
			<TransactionHistoryRFARow
				players_by_id={players_by_id}
				trade={transactionsSortedByDate}
				key={1}
				empty_state={this.history_empty_state}
			/>
		);
	}

	setSelectedRoundId(selected_round_id: number) {
		this.setState({ selected_round_id });
	}

	setSelectedOwner({ currentTarget }: Object) {
		const { value } = currentTarget;
		this.setState({ selected_owner: parseInt(value, 10) });
	}

	setSelectedTransactionType({ currentTarget }: Object) {
		const { value } = currentTarget;
		this.setState({ selected_transaction_type: value });
	}

	setPlayerSearch(event: any) {
		const { value } = event.target;
		this.setState({ selected_player: value });
	}

	setFavourites() {
		const { set_favourites } = this.state;
		this.setState({ set_favourites: !set_favourites });
	}

	onSearchSubmit(event: any) {
		event.preventDefault();
	}

	render() {
		const { teams_by_id } = this.props;
		const { selected_round_id } = this.state;
		const {
			league_rounds,
			league
		} = this.props;


		return (
			<React.Fragment>
				<TransactionHistoryFilters
					selected={selected_round_id}
					teams={teams_by_id}
					handleRoundChange={this.setSelectedRoundId}
					handleOwnerChange={this.setSelectedOwner}
					handleTransactionChange={this.setSelectedTransactionType}
					handlePlayerChange={this.setPlayerSearch}
					handleSubmit={this.onSearchSubmit}
					handleFavourites={this.setFavourites}
					league={league}
					league_rounds={league_rounds}
				/>

				{this.get_transaction_list}

			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: TRootStore, props) => {
	return {
		league: selectors.leagueDraft.getLeague(state, props),
		actual_round: selectors.rounds.getActualRound(state),
		league_rounds: selectors.rounds.futureRoundsDisabled(state, props),
		transaction_history: selectors.leagueDraft.getTransactionsHistoryById(state),
		players_by_id: selectors.players.getExtendedPlayersById(state),
		teams_by_id: state.leagues.teams_by_id,
		transaction_history_with_player_names: selectors.getTransactionPlayerNames(state),
		favourite_players: state.players.favourites
	};
};
const mapDispatchToProps = {
	transactionHistory: actions.leagueDraft.fetchTransactionHistory,
	showTeamsDraftLeague: actions.leagueDraft.showTeamsDraftLeague,
};


const TransactionHistory = compose(
	withRouter,
	withRoundsSelector,
	connect(
		mapStateToProps,
		mapDispatchToProps
	),
	withDraftPlayerModal,
)(TransactionHistoryPage);

export default TransactionHistory;