// @flow
import * as React from "react";
import type { ComponentType } from "react";

import styled from "styled-components";
import colors from "../../../assets/css/colors";

import ArrowDownSmall from "../../../components/Icons/ArrowDownSmall";

import { default_transition } from "../../../assets/css/vars";

const SelectWrapper = styled.div`
	display: flex;
	position: relative;
	width: 100%;
	height: 40px;
	margin-top: ${({half_margin}) => !half_margin && "5px"};
	.arrow {
		height: 18px;
		width: 18px;
		position: absolute;
		top: 12px;
		right: 10px;
		pointer-events: none;
		color: ${({ color }) => color || colors.form.arrow};
		opacity: ${({ disabled }) => disabled ? ".5" : "1"};
		${default_transition}
	}
`;

type SelectItemProps = {
	color?:string;
	isComplete?: boolean;
}

const SelectItem: ComponentType<SelectItemProps> = styled.select`	
	color: ${({ color, isComplete }) => isComplete ? colors.primary.primary
		: color || colors.form.textColor};
	background-color: ${({ isComplete }) => isComplete ? 
		colors.form.completedField : colors.white
};
	font-size: 13px;
	height: 40px;
	border: 1px solid ${colors.form.borderColor};
	border-radius: 2px;
	width: 100%;
	padding-left: 0.76em;
	padding-right: 30px;
	-webkit-appearance: none;
	-moz-appearance: none;
	outline: none;
	border-radius: 0;
	font-family: SourceSansPro;
	font-weight: 600;
	border-radius: 2px;

	&:disabled {
		opacity: .5;
	}

	&:hover:not(:disabled) {
		background-color: ${colors.form.base};
	}

	&::-ms-expand {
	    display: none;
	}
`;

type Props = {
	is_disabled?: boolean,
	color?: string,
	className?: string,
	value?: any,
	half_margin?: boolean
};

export const Select = ({ is_disabled, color, half_margin, className, ...rest }: Props) => {
	return (
		<SelectWrapper className={className} disabled={is_disabled} half_margin={half_margin}>
			<SelectItem 
				{...rest}
				disabled={is_disabled}
				color={color} 
			/>
			<ArrowDownSmall className="arrow" color={color} size="1" unit="px" />
		</SelectWrapper>
	);
};

Select.defaultProps = {
	is_disabled: false
};

export default Select;