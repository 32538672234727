import React from "react";
import { connect } from "react-redux";
import bindAll from "lodash/bindAll";

import styled from "styled-components";
import colors from "../../assets/css/colors";
import CautionReset from "../../components/Icons/CautionReset";
import isAuthenticated from "../../utils/auth/isAuthenticated";
import * as actions from "../../modules/actions";
import {
	changePassword,
	changePasswordFailed,
	changePasswordSuccess,
	clearUpdateUserData,
} from "../../modules/actions";
import { OneColumnLayout, PageTitle, TextModal } from "../../components";

import UpdatePasswordForm from "../updatePassword/updatePasswordForm";



const PageTitleStyled = styled(PageTitle)`
	font-size: 32px;
	display: flex;
	align-items: flex-end;

	svg{
		margin: 0 10px 8px 0;
	}
`;

class ChangePasswordSection extends React.Component {
	constructor() {
		super();

		bindAll(this, [
			"handleSubmit",
			"handleModalErrorClose",
			"handleModalSuccessClose",
		]);
	}

	componentDidMount() {
		// if(this.props.is_authorized) {
		// 	window.location = '/';
		// }

		// const { search } = this.props.location;
		// const { token } = queryString.parse(search);

		// if(!token) {
		// 	window.location = '/';
		// }
	}

	handleModalErrorClose() {
		const { clearUpdateUserData } = this.props;
		clearUpdateUserData();
	}

	get error_modal() {
		const { user } = this.props;

		return (
			<TextModal
				onClick={this.handleModalErrorClose}
				header_text='Error'
				text={ user.update.error.message } />
		);
	}

	handleModalSuccessClose() {
		window.location = "/game-selection";
	}

	get success_modal() {
		return (
			<TextModal
				onClick={this.handleModalSuccessClose}
				header_text='Success'
				text='Your password updated' />
		);
	}

	handleSubmit({ password }) {
		const { updateUser } = this.props;
		updateUser({
			password,
			confirmPassword: password,
		});
	}

	render() {
		const { user } = this.props;

		return (
			<React.Fragment>
				{ user.update.error ?
					this.error_modal : null}

				{user.update.success && !user.update.error ?
					this.success_modal : null}
				<OneColumnLayout>
					<PageTitleStyled>
						<CautionReset color={colors.title} size='1.3' />
							Reset your password
					</PageTitleStyled>
					<UpdatePasswordForm
					 	onSubmit={this.handleSubmit} 
						 isDisabled={false} 
						 from_account={true}
					/>
				</OneColumnLayout >
			</React.Fragment>
		);
	}
}

const mapStateToProps = state => ({
	user: state.user,
	is_authorized: isAuthenticated(),
});

const mapDispatchToProps = {
	changePassword,
	changePasswordFailed,
	changePasswordSuccess,
	clearUpdateUserData,
	updateUser: actions.updateUser,
};

export const ChangePassword = connect(
	mapStateToProps,
	mapDispatchToProps
)(ChangePasswordSection);

export default ChangePassword;