import { createReducer } from "redux-act";

import * as actions from "../../actions";

const initial_state = {
	selected: null,
	selected_classic: null
};

export const uiSelectedLeague = createReducer({}, initial_state);

uiSelectedLeague.on(actions.uiSelectedLeague.setSelectedLeague, (state, payload) => ({
	...state,
	selected: payload
}));

uiSelectedLeague.on(actions.uiSelectedLeague.clearSelectedLeague, state => ({
	...state,
	selected: null
}));

uiSelectedLeague.on(actions.uiSelectedLeague.setSelectedClassicLeague, (state, payload) => ({
	...state,
	selected_classic: payload
}));

uiSelectedLeague.on(actions.uiSelectedLeague.clearSelectedClassicLeague, state => ({
	...state,
	selected_classic: null
}));