// @flow
import React from "react";
import SvgIconContainer from "../SvgIconContainer";

export default (
	{
		color = "#1D4073",
		unit = "em",
		...rest
	}: Object = {}) => (
	<SvgIconContainer viewBox="0 0 24 24" {...rest} unit={unit}>
		<g>
			<path
				fill={color}
				fillRule="evenodd"
				d="M6 15l6-6 6 6H6z"
				clipRule="evenodd"
			/>
		</g>
	</SvgIconContainer>
);