// @flow
import * as React from "react";
import styled from "styled-components";

import colors from "../../assets/css/colors";
import { below } from "../../assets/css/media";

import CellsCondensed from "../Icons/CellsCondensed";
import CellsLarge from "../Icons/CellsLarge";

const HeaderWrapper = styled.div`
	width: 100%;
	height: 60px;
	display: flex;
	background: ${colors.primary.primary};
	color: #fff;
	border-radius: 2px;
	justify-content: flex-end;
	align-items: center;
	box-sizing: border-box;
	padding: 0 10px;

	${below.tablet`
		border-radius: 0;
	`}

	@media (max-width: 767px){
		height: 40px;
	}
`;

const Title = styled.h3`
	font-family: TitilliumUpright;
	font-weight: 700;
	color: #fff;
	margin-right: auto;
	font-size: 16px;
	flex-grow: 1;
`;

const ButtonsContainer = styled.div`
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;

	@media (max-width: 767px){
		display: none;
	}
	@media (min-width: 768px){
		display: flex;
	}
`;

const DraftHeaderButton = styled.button`
	background: transparent;
	width: 40px;
	height: 40px;
	padding: 0;
	border: none;
	border-radius: 2px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	margin-left: 5px;
	${({ is_active }) => is_active && `background: ${colors.primary.primary}`};
	color: #fff;
	svg {
		width: 32px;
		height: 32px;
		opacity: ${({ is_active }) => is_active ? 1:0.3};
	}

	&:last-child {
		${below.tablet`
			margin-left: 5px !important;
		`}
	}
`;

type Props = {
	title: string,
	middleArea: React.Node,
	is_compressed?: boolean,
	onCompressedViewClick?: Function,
}

export class PlayersTableHeader extends React.PureComponent<Props> {
	static defaultProps = {
		middleArea: null,
	};

	render() {
		const {
			title,
			is_compressed,
			onCompressedViewClick,
			middleArea
		 } = this.props;

		return (
			<React.Fragment>
				<HeaderWrapper>
					<Title>
						{title}
					</Title>
					{middleArea}
					<ButtonsContainer>
						{onCompressedViewClick && (
							<React.Fragment>
								<DraftHeaderButton
									is_active={is_compressed===true}
									onClick={() => onCompressedViewClick(true)}
									rel="compressed-view"
								>
									<CellsCondensed />
								</DraftHeaderButton>
								<DraftHeaderButton
									is_active={is_compressed === false}
									onClick={() => onCompressedViewClick(false)}
									rel="uncompressed-view"
								>
									<CellsLarge />
								</DraftHeaderButton>
							</React.Fragment>
						)}
					</ButtonsContainer>
				</HeaderWrapper>
			</React.Fragment>
		);
	}
}

export default PlayersTableHeader;