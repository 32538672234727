// @flow
import * as React from "react";
import styled from "styled-components";
import { get } from "lodash";

import colors from "../../assets/css/colors";
import { above } from "../../assets/css/media";

import clubLogos from "../../utils/clubLogos";

const lightGrey = "#8D979C";

const Container = styled.div`
	box-sizing: border-box;
	margin: 5px 1px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	min-width: 120px;
	max-width: 100vw;

	${above.phone`
		min-width: ${({ panels }) => panels ? `${panels * 120}px` : "360px"};
	`}
`;

const Title = styled.div`
	box-sizing: border-box;
	flex: 0 0 20px;
	padding: 5px 10px;
	font-size: 10px;
	font-weight: 700;
	text-transform: uppercase;
	white-space: nowrap;
	color: ${lightGrey};
	width: 100%;
`;

const Squads = styled.div`
	box-sizing: border-box;
	flex: 1 2 auto;
	padding: 5px 10px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-content: flex-start;
	width: 90px;

	${above.phone`
		width: 100%;
	`}
`;

const Squad = styled.div`
	font-size: 16px;
	font-weight: 600;
	color: ${colors.primary.primary};
	width: 16px;
	margin-right: 5px;
	margin-bottom: 2px;

	${above.phone`
		width: 40px;
		margin-right: 2px;
		margin-top: 4px;
		margin-bottom: 0;
	`}
`;

const SquadLogo = styled.img`
	display: block;
	max-width: 100%;
`;

type Props = {
	bye_squads: Array<number>
}

const ByePanel = ({ bye_squads }: Props) =>
	<Container panels={bye_squads.length / 2}>
		<Title>Bye</Title>
		<Squads>
			{bye_squads.map(squad_id => (
				<Squad key={squad_id}><SquadLogo src={get(clubLogos, squad_id)} /></Squad>
			))}
		</Squads>
	</Container>;

export default ByePanel;