// @flow
import _ from "lodash";

export const replaceUrlTo = (url: string): void => {
	window.location.replace(url);
};

export const redirectTo = (url: string) : void => {
	window.location.href = url;
};

export const convertPathToArray = (pathname: string): Array<string> => {
	// Convert path `"/leagues/4324/test"` to `["leagues", "4324", "test"]`.
	// The reduce removes blank string from split method
	return pathname.split("/").reduce((acc, item) => {
		if(item) acc.push(item);
		return acc;
	}, []);
};

export const isMatchingPathnames = (pathname_1: string, pathname_2: string): boolean => {
	return _.isEqual(convertPathToArray(pathname_1), convertPathToArray(pathname_2));
};


export default {
	replaceUrlTo,
	redirectTo,
	convertPathToArray,
	isMatchingPathnames
};