// @flow
import _ from 'lodash';
import { createSelector } from 'reselect';

import getLeague from './getLeague';

const getLeagueUserTeamId = createSelector(
	getLeague,
	(league: Object) => _.get(league, 'team_id', 0)
);

export default getLeagueUserTeamId;