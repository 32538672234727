/* eslint max-len: 0 */
// @flow
import React from "react";
import SvgIconContainer from "../SvgIconContainer";

export default (
	{
		unit = "em",
		...rest
	}: Object = {}) => (
	<SvgIconContainer viewBox="0 0 24 24" {...rest} unit={unit}>
		<title>Bell</title>
		<path d="M18.75,17.25 L5.25,17.25 C5.05226738,17.2794638 4.85189783,17.2170834 4.7058334,17.0805861 C4.55976897,16.9440889 4.48397831,16.7483977 4.5,16.549125 C4.49533625,16.2476897 4.69351921,15.980627 4.983375,15.89775 C8.163,14.881875 5.062125,9.07275 8.67225,5.96025 C9.20529981,5.50963751 9.83967982,5.19502105 10.521,5.043375 C10.6212286,4.30325002 11.2531193,3.75116292 12,3.75116292 C12.7468807,3.75116292 13.3787714,4.30325002 13.479,5.043375 C14.1603202,5.19502105 14.7947002,5.50963751 15.32775,5.96025 C18.937875,9.07275 15.837,14.88525 19.016625,15.89775 C19.3064808,15.980627 19.5046638,16.2476897 19.5,16.549125 C19.5160217,16.7483977 19.440231,16.9440889 19.2941666,17.0805861 C19.1481022,17.2170834 18.9477326,17.2794638 18.75,17.25 L18.75,17.25 Z M15.840375,11.925 C15.75,8.45325 14.625,6.375 12,6.375 C9.375,6.375 8.25,8.34375 8.159625,11.925 C8.113125,13.1835 8.059875,14.631375 7.4295,15.75 L16.5705,15.75 C15.940125,14.632875 15.886875,13.185 15.840375,11.925 Z M9.950625,18 L14.049375,18 C14.1020012,17.9997135 14.1524106,18.0211678 14.1886891,18.0592921 C14.2249676,18.0974165 14.2438958,18.1488278 14.241,18.201375 C14.1369274,19.3614479 13.1647318,20.2503302 12,20.2503302 C10.8352682,20.2503302 9.86307255,19.3614479 9.759,18.201375 C9.75610422,18.1488278 9.77503241,18.0974165 9.8113109,18.0592921 C9.84758939,18.0211678 9.89799883,17.9997135 9.950625,18 Z" />
	</SvgIconContainer>
);