// @flow
import styled from "styled-components";
import { default_transition } from "../../../assets/css/vars";

export const FieldViewControlWrapper = styled.div`
	color: #fff;
	display: flex;
	padding: 5px;
	border-radius: 2px;
	cursor: pointer;
	opacity: 0.3;

	${default_transition};
	
	&.active {
		opacity: 1;
	}

	:hover {
		background-color: #a0a8ac;
		&.active {
			background-color: #244f8f;
		}
	}
`;

export default FieldViewControlWrapper;