// @flow
import styled from "styled-components";
import colors from "../../assets/css/colors";
import { below } from "../../assets/css/media";

export const TransactionPlayers = styled.div`
	flex-basis: 38%;
	> div {
		margin-bottom: 10px;
		border-radius: 0px;
	}
	> p {
		color: ${colors.primary.darkGrey};
		font-size: 13px;
		line-height: 1.3;
		margin-bottom: 5px;
		text-align: right;
		font-family: SourceSansPro, sans-serif;
		font-weight: normal;
	}
	
	&:first-of-type > p {
		text-align: left;
	}

	${below.tablet`
		flex-basis: 48%;
	`};

	${below.phone`
		flex: 1 1 0;
		display: flex;
		flex-direction: column;
		min-height: 98px;
		justify-content: inherit;
		> p {
			text-align: left;
		}
		&:first-of-type > p {
			text-align: left;
		}
		width: 100%;
	`};
`;

export default TransactionPlayers;