// @flow
import styled from "styled-components";
import { default_transition } from "../../assets/css/vars";

export const ButtonDisabled = styled.button`
	outline: none;
	border: 1px solid #CAD2D8;
	background-color: #CAD2D8;
	color: #8D979C;
	font-size: 14px;
	font-weight: 400;
	line-height: 1;
	padding: 14px 12px 12px;
	cursor: default;
	width: ${({ width }) => width || "auto"};
	text-align: center;
	border-radius: 2px;
	letter-spacing: -0.3px;
	${default_transition}
`;

export default ButtonDisabled;