import getLeague from "./getLeague";
import getLeagueRounds from "./getLeagueRounds";
import getById from "./getById";
import getOrderedLadderCalculated from "./getOrderedLadderCalculated";
import getRostersById from "./getRostersById";
// export const testSelector = (state: Object, props: Object) => props.test;


// grouped selectors
import * as ladder from "./ladder";
import * as show from "./show";
import * as show_my from "./show_my";


export default {
	getLeague,
	getById,
	getOrderedLadderCalculated,
	getLeagueRounds,
	getRostersById,
	// grouped selectors
	ladder,
	show,
	show_my
};