// @flow
import * as React from "react";
import { connect } from "react-redux";
import * as _ from "lodash";
import { compose } from "redux";

import { withRouter } from "react-router-dom";


import * as selectors from "../../../modules/selectors";
import type { TPLeague } from "../../../modules/types";
import { loginToken } from "../../../modules/actions";

import {
	PageContentWrapper,
	TwoColumnLayout,
	PageTitle,
	AdsContainer,
	Ad,
	Footer,
	StandardSidebar,
	LeagueGameBar,
	withDraftCore,
	Preloader
} from "../../../components";
import RedirectIfNotCommish from "../../../components/RedirectIfNotCommish";
import { LeagueManagerComponent } from "../../../components/utils/LeagueManager/index";
import LeagueSettingsForm from "./leagueSettings/LeaguesSettingsForm";

type Props = {
	rounds_list: Array<Object>,
	handleSubmit: Function,
	league: TPLeague,
	league_show:  {
		error: string,
		league_id: number,
	},
	match: {
		params: {
			league_id: number,
		}
	},
	user: {
		id: number,
	},
}

class DraftLeagueSettingsComponent extends React.Component<Props> {

	componentDidMount() {
		window.scrollTo(0, 0);
	}

	get check_is_from_app() {
		const params = new URLSearchParams(window.location.search);
		const from_app = params.get("from_app");
		return Boolean(from_app);

	}

	get app_league_id() {
		const { match } = this.props;
		return match.params.league_id;
	}
	
	render() {
		const {
			match,
			league,
			league_show,
			user
		} = this.props;
		const leagueID = _.get(league, 'id');

		if(this.check_is_from_app && _.isEmpty(user)){
			return <Preloader />;
		}

		if (_.isEmpty(league) || !Boolean(leagueID) ) {
			return null;
		}
		

		return (
			<div>
				<RedirectIfNotCommish
					league={league}
					userID={user.id}
					leagueID={league_show.league_id} />
				<LeagueGameBar no_fetch={true} />
				<AdsContainer>
					<Ad/>
				</AdsContainer>
				<LeagueManagerComponent 
					from="update" 
					league_id={match.params.league_id} 
					isFromApp={this.check_is_from_app}
				>
					{({ rounds_list, handleSubmit, ...props }: Props) => (
						<PageContentWrapper>
							<PageTitle>
								League settings
							</PageTitle>
							<TwoColumnLayout>
								<LeagueSettingsForm
									{...props}
									rounds={rounds_list}
									onSubmit={handleSubmit}
								/>
								<StandardSidebar />
							</TwoColumnLayout>
						</PageContentWrapper>
					)}
				</LeagueManagerComponent>
				<Footer />
			</div>
		);
	}
}

const mapStateToProps = (state, props) => ({
	league_show: state.leagues.show,
	user: state.user.data,
	league: selectors.leagueDraft.getLeague(state, props),
});

const mapDispatchToProps = {
	loginToken
};

export const DraftLeagueSettings = compose(
	withRouter,
	withDraftCore,
	connect(mapStateToProps, mapDispatchToProps),
)(DraftLeagueSettingsComponent);

export default DraftLeagueSettings;