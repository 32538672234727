// @flow
import { createAction } from "redux-act";

export const createClassicLeague = createAction("Create Classic League");
export const createClassicLeagueSuccess = createAction("Create Classic Success");
export const createClassicLeagueFailed = createAction("Create Classic Failed");

export const clearClassicLeagueErrors = createAction("Clear Classic League Errors");

export const showClassicLeague = createAction("Show Classic League");
export const showClassicLeagueSuccess = createAction("Show Classic Success");
export const showClassicLeagueFailed = createAction("Show Classic Failed");

export const updateClassicLeague = createAction("Update Classic League");
export const updateClassicLeagueSuccess = createAction("Update Classic Success");
export const updateClassicLeagueFailed = createAction("Update Classic Failed");

export const removeClassicLeague = createAction("Remove Classic League");
export const removeClassicLeagueSuccess = createAction("Remove Classic Success");
export const removeClassicLeagueFailed = createAction("Remove Classic Failed");

export const myListClassicLeagues = createAction("My List Classic Leagues");
export const myListClassicLeaguesSuccess = createAction("My List Classic Leagues Success");
export const myListClassicLeaguesFailed = createAction("My List Classic Leagues Failed");

export const howJoJoinClassicLeagues = createAction("How Jo Join Classic Leagues");
export const howJoJoinClassicLeaguesSuccess = createAction("How Jo Join Classic Leagues");
export const howJoJoinClassicLeaguesFailed = createAction("How Jo Join Classic Leagues");

export const showToJoinLoadMoreClassicLeagues =
	createAction("Show To Join Load More Classic Leagues");
export const showToJoinLoadMoreClassicLeaguesSuccess =
	createAction("Show To Join Load More Classic Leagues Success");
export const showToJoinLoadMoreClassicLeaguesFailed =
	createAction("Show To Join Load More Classic Leagues Failed");

export const inviteToClassicLeague = createAction("Invite To Classic League");
export const inviteToClassicLeagueSuccess = createAction("Invite To Classic League Success");
export const inviteToClassicLeagueFailed = createAction("Invite To Classic League Failed");

export const joinToClassicLeague = createAction("Join To Classic League");
export const joinToClassicLeagueSuccess = createAction("Join To Classic League Success");
export const joinToClassicLeagueFailed = createAction("Join To Classic League Failed");

export const leaveClassicLeague = createAction("Leave Classic League");
export const leaveClassicLeagueSuccess = createAction("Leave Classic LeagueSuccess");
export const leaveClassicLeagueFailed = createAction("Leave Classic LeagueFailed");

export const ladderClassicLeague = createAction("Ladder Classic League");
export const ladderClassicLeagueSuccess = createAction("Ladder Classic League Success");
export const ladderClassicLeagueFailed = createAction("Ladder Classic League Failed");

export const ladderLoadMoreClassicLeague = createAction("Ladder Load More Classic League");
export const ladderLoadMoreClassicLeagueSuccess =
	createAction("Ladder Load More Classic League Success");
export const ladderLoadMoreClassicLeagueFailed =
	createAction("Ladder Load More Classic League Failed");

export const showMyClassicLeagues = createAction("Show My Classic Leagues");
export const showMyClassicLeaguesSuccess = createAction("Show My Classic Leagues Success");
export const showMyClassicLeaguesFailed = createAction("Show My Classic Leagues Failed");


export const regenerateListClassicLeagues = createAction("Regenerate List Classic Leagues");
export const regenerateListClassicLeaguesSuccess =
	createAction("Regenerate List Classic Leagues Success");
export const regenerateListClassicLeaguesFailed =
	createAction("Regenerate List Classic Leagues Failed");


export const regenerateShowClassicLeague = createAction("Regenerate Show Classic League");
export const regenerateShowClassicLeagueSuccess =
	createAction("Regenerate Show Classic League Success");
export const regenerateShowClassicLeagueFailed =
	createAction("Regenerate Show Classic League Failed");

export const getCelebrities = createAction("Get Classic Celebrity Teams");
export const getCelebritiesFailed = createAction("Get Classic Celebrity Teams Failed");
export const getCelebritiesSuccess = createAction("Get Classic Celebrity Teams Success");

export const addCelebritiesToLeague = createAction("Add Classic Celebrity Teams To League");
export const addCelebritiesToLeagueFailed = 
	createAction("Add Classic Celebrity Teams To League Failed");
export const addCelebritiesToLeagueSuccess = 
	createAction("Add Classic Celebrity Teams To League Success");

export const removeTeamFromLeague = createAction("Remove Team From Classic League");
export const removeTeamFromLeagueFailed = createAction("Remove Team From Classic League Failed");
export const removeTeamFromLeagueSuccess = createAction("Remove Team From Classic League Success");

export const getClassicLeagueRosters = createAction("Get Classic League Rosters");
export const getClassicLeagueRostersFailed = createAction("Get Classic League Rosters Failed");
export const getClassicLeagueRostersSuccess = createAction("Get Classic League Rosters Success");

export const showTeamsClassic = createAction("Show Teams Classic");
export const clearTeamsClassic = createAction("Clear Teams Classic");
export const showTeamsClassicFailed = createAction("Show Teams Classic Failed");
export const showTeamsClassicSuccess = createAction("Show Teams Classic Success");