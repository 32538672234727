// @flow
import React from "react";
import styled from "styled-components";
import * as _ from "lodash";
import type { CoachesBoxComment, TPLeague, TUser } from "../../modules/types";
import { below } from "../../assets/css/media";
import CoachesBoxTop from "./CoachesBoxTop";
import ChatBox from "./ChatBox";

const WidgetPosition = styled.div`
	position: sticky;
	top: ${({ is_live_draft }) => is_live_draft ? "200px;" : "50px;"}
	${below.tablet`
		top: 100px;
		width: 100%;
	`};
	right: 0px;
	z-index: 99;
	float: right;
	${below.phone`
		display: none;
	`}
`;

type Props = {
	toggleOpen: Function,
	opened: boolean,
	comments: CoachesBoxComment[],
	user: TUser,
	league: TPLeague,
	coaches_box_used: boolean,
	setBoxUsed: Function,
	sendMessage: Function,
	clearErrors: Function,
	send_error: string,
	messages_available: boolean,
	loadMoreMessages: Function,
	unread_messages: boolean,
	clearUnread: Function,
	teams_by_id: { [number]: { id: number, name: string } },
	location: Object,
};

export class CoachesBox extends React.PureComponent<Props> {
	constructor() {
		super();

		_.bindAll(this, [
			"toggleCoachesBox"
		]);
	}
	toggleCoachesBox({ currentTarget }: Object) {
		const { /*name,*/ checked } = currentTarget;
		const { setBoxUsed } = this.props;
		setBoxUsed(checked);
	}
	render() {
		const {
			toggleOpen,
			opened,
			comments,
			user,
			league,
			coaches_box_used,
			sendMessage,
			clearErrors,
			send_error,
			messages_available,
			loadMoreMessages,
			unread_messages,
			clearUnread,
			teams_by_id,
			location
		} = this.props;

		const is_live_draft = location.pathname.includes("live-draft");
		return <WidgetPosition opened={opened} is_live_draft={is_live_draft}>
			<CoachesBoxTop
				onClick={toggleOpen}
				opened={opened}
				handleToggle={this.toggleCoachesBox}
				coaches_box_used={coaches_box_used}
				unread_messages={unread_messages}
				is_live_draft={is_live_draft}
			/>
			<ChatBox
				opened={opened && coaches_box_used}
				comments={comments}
				user={user}
				league={league}
				sendMessage={sendMessage}
				clearErrors={clearErrors}
				send_error={send_error}
				messages_available={messages_available}
				loadMoreMessages={loadMoreMessages}
				clearUnread={clearUnread}
				teams_by_id={teams_by_id}
				is_live_draft={is_live_draft}
			/>
		</WidgetPosition>;
	}
}

export default CoachesBox;