// @flow
import * as React from "react";
import styled from "styled-components";
import { Fields } from "redux-form";
import { range } from "lodash";
import CheckedIcon from "../../../components/Icons/CheckedIcon";
import UnCheckedIcon from "../../../components/Icons/UnCheckedIcon";

import { Tooltip } from "../../Form";

import Locked from "../../../components/Icons/Locked";
import { FlexDiv, FlexDivColCenter } from "../../../components";

import {
	FormFieldCheckboxWrapDiv,
	FieldWrapDiv,
	FormInputCheckbox,
	FormCheckboxLabel,
	FormCheckboxLabelSpan,
	PointsSelect,
	LockedIconWrap
} from "./FormScoreSettingStyle";
// import { below } from "../../../assets/css/media";

const StyledFlexColumn = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	${({ showCursor }) => showCursor && `
		cursor: pointer;
	`}
`;

const renderField = fields => {
	const {
		is_disabled,
		label,
		names,
		minValue,
		maxValue,
		statType,
		showCheckBox,
		showBlankOption,
		handleProOpen
	} = fields;
	const SVGDimensions = {
		height: 20,
		width: 20,
	};
	const fieldShow = fields[`${statType}_show`];
	const fieldValue = fields[`${statType}_value`];
	const selectOptions = range(minValue, maxValue);
	const handleSelectChange = event => {
		if(Number(event.target.value) !== 0) {
			fieldShow.input.onChange(true);
		}
		else {
			fieldShow.input.onChange(false);
		}
		fieldValue.input.onChange(event.target.value);
	};
	const handleCheckboxChange = () => {
		if(fieldShow.input.value) {
			fieldValue.input.onChange(0);
		}
		else {
			fieldValue.input.onChange(3);
		}
	};
	return (
		<FieldWrapDiv>
			<FormInputCheckbox
				onClick={handleCheckboxChange}
				className="visuallyhidden"
				name={names[0]}
				id={names[0]}
				type="checkbox"
				disabled={is_disabled}
				{...fieldShow.input}
			/>
			{showCheckBox ? (
				<FlexDiv>
					{is_disabled ? (
						<FlexDiv>
							<StyledFlexColumn onClick={handleProOpen} showCursor={is_disabled}>
								<LockedIconWrap >
									<Locked color="#ffffff" />
								</LockedIconWrap>
							</StyledFlexColumn>
							<FormCheckboxLabelSpan>{label}</FormCheckboxLabelSpan>
						</FlexDiv>
					) : (
						<FormCheckboxLabel htmlFor={names[0]}>
							<FlexDivColCenter>
								{fieldShow.input.value === true ? (
									<CheckedIcon {...SVGDimensions} />
								) : (
									<UnCheckedIcon {...SVGDimensions} />
								)}
							</FlexDivColCenter>
							<FormCheckboxLabelSpan>{label}</FormCheckboxLabelSpan>
						</FormCheckboxLabel>
					)}
				</FlexDiv>
			) : (
				<FlexDiv>
					<FormCheckboxLabelSpan>{label}</FormCheckboxLabelSpan>
				</FlexDiv>
			)}
			<FlexDiv>
				<FlexDivColCenter>
					<PointsSelect
						{...fieldValue.input}
						onChange={value => handleSelectChange(value)}
						name={names[1]}
						value={fieldValue.input.value}
						disabled={is_disabled}
					>
						{showBlankOption ? <option /> : null}
						{selectOptions.map(option => (
							<option key={option} value={option}>
								{option}
							</option>
						))}
					</PointsSelect>
				</FlexDivColCenter>
				{fields.tooltip ? <Tooltip {...fields.tooltip} /> : null}
			</FlexDiv>
		</FieldWrapDiv>
	);
};

export type FormScoreSettingProps = {
	label: string,
	names: string[],
	is_disabled?: boolean,
	tooltip?: {
		title: string,
		description: string,
	},
	statType: string,
	minValue?: number,
	maxValue?: number,
	showCheckBox: boolean,
	showBlankOption: boolean,
	handleProOpen?: Function
};

export const FormScoreSetting = ({
	label,
	tooltip,
	names,
	is_disabled = false,
	showBlankOption = true,
	statType,
	minValue = 0,
	maxValue = 10,
	showCheckBox,
	handleProOpen
}: FormScoreSettingProps) => {
	return (
		<FormFieldCheckboxWrapDiv isDisabled={is_disabled}>
			<Fields
				showBlankOption={showBlankOption}
				showCheckBox={showCheckBox}
				tooltip={tooltip}
				is_disabled={is_disabled}
				statType={statType}
				handleProOpen={handleProOpen}
				names={names}
				component={renderField}
				label={label}
				minValue={minValue}
				maxValue={maxValue}
			/>
		</FormFieldCheckboxWrapDiv>
	);
};

export default FormScoreSetting;