// @flow
import styled from "styled-components";
import { below } from "../../assets/css/media";

/**
 * 
	overflow: scroll; removed in AFD21-310
 */
export const ModalBody = styled.div`
	text-align: center;
	${below.desktop`
		overflow: scroll;
		overflow-x: hidden;
	`}

	${below.tablet`
	`}
`;

export default ModalBody;