// @flow
import * as React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import styled from "styled-components";
import {
	Footer,
	PageTitle,
	ButtonLoadMore,
	SearchForm,
	PageContentWrapper,
	TwoColumnLayout,
	Ad,
	StandardSidebar,
	AdsContainer,
	TextModal,
	Exist
} from "../../../components";

import colors from "../../../assets/css/colors";
import { below, above } from "../../../assets/css/media";
import More from "../../../components/Icons/More";
import * as actions from "../../../modules/actions";
import { redirectTo } from "../../../utils/url";
import { isMobile } from "../../../utils";
import { ROUNDS_IN_AFL_SEASON } from "../../../helpers";
import JoinLeagueLayout from "./leaguesJoin/JoinLeagueLayout";
import JoinLeagueList from "./leaguesJoin/JoinLeagueList";
import JoinLeaguePopup from "./leaguesJoin/JoinLeaguePopup";
import JoinLeagueSettingsPopup from "./leaguesJoin/JoinLeagueSettingsPopup";


const RoundSelect = styled.select`
	font-family: "SourceSansPro";
	font-size: 12px;
	font-weight: 600;
	letter-spacing: 0;
	line-height: 12px;
	color: ${colors.secondary.accentGrey};
	height: 40px;
	width: 180px;
	background: #fff;
	border: 1px solid ${colors.form.borderColor};
	${below.tablet`
			width: 100%;
	`}
`;

const OFF_GROWTH = 10;
const PageTitleStyled = styled(PageTitle)`
	font-size: 32px;
	display: flex;
	align-items: flex-end;

`;
const SearchWrap = styled.div`
	display: flex;
	margin: 0 0 1em;
	${below.phone`
		flex-direction: column;
	`}
`;

const SearchFormFlex = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	form{
		min-width: 350px;
	}
	${below.phone`
		form{
			min-width: 340px;
		}
	`}
	min-width: 350px;
`;

const RoundSelectMargin = styled(RoundSelect)`
	margin: 0 10px;
	margin-right: ${({ right_zero }) => right_zero ? "0px" : ""};
	${below.phone`
		margin: 5px 0;
		margin-bottom: ${({ right_zero }) => right_zero ? "0px" : ""}
	`};
`;
const NotFound = styled.div`
	color: ${colors.title};
`;
const MoreFiltersButton = styled.button`
	appearance: none;
	border: none;
	background: none;
	height: 20px;
	width: 20px;
	padding: 0;
	svg{
		height: 20px;
		width: 20px;
	}
	${above.phone`
		display: none;
	`}
`;

type Props = {
	showToJoinClassicLeagues: Function,
	showToJoinLoadMoreClassicLeagues: Function,
	joinToClassicLeague: typeof actions.joinToClassicLeague,
	fetchClassicLeagues: typeof actions.myListClassicLeagues,
	leagues_to_join: Array<Object>,
	is_load_more: boolean,
	join_to: Object,
	offset: number,
	search: string,
	history: {
		push: Function
	},
	match: {
		params: {
			code: string
		}
	}
}

type State = {
	search_value: string,
	is_show_join_popup: boolean,
	is_show_settings_popup: boolean,
	league_to_join: Object,
	filter_size: string,
	filter_num_teams: string,
	filter_start_round: string,
	show_filters: boolean
}

class ClassicLeaguesJoinPage extends React.Component<Props, State> {
	state = {
		search_value: "",
		is_show_join_popup: false,
		is_show_settings_popup: false,
		league_to_join: {},
		filter_size: "",
		filter_num_teams: "",
		filter_start_round: "",
		show_filters: !isMobile(),
	};

	componentDidMount() {
		this.joinOnLink();
		this.triggerSearch();
		this.props.fetchClassicLeagues();
	}

	componentDidUpdate(prev_props, prev_state) {
		const { join_to } = this.props;

		if (join_to.league_id && prev_props.join_to.league_id !== join_to.league_id) {
			redirectTo(`/classic/league/${join_to.league_id}/about`);
		}

		if (this.props.search !== prev_props.search ||
			this.state.filter_size !== prev_state.filter_size ||
			this.state.filter_num_teams !== prev_state.filter_num_teams ||
			this.state.filter_start_round !== prev_state.filter_start_round
		) {
			this.triggerSearch();
		}
	}

	joinOnLink() {
		const {
			match: {
				params
			}
		} = this.props;

		if(params && params.code) {
			this.props.joinToClassicLeague({ code: params.code });
		}
	}

	join = code => {
		this.props.joinToClassicLeague({ code });
	};

	handleSearchSubmit(api_params) {
		// history.push decodes any encoded characters (i.e. '10%25' -> '10%')
		// Raw '%' chars break router on reloads/back navigation
		// Need to double encode everything as a work around
		const search = encodeURIComponent(encodeURIComponent(api_params.q)) || "";
		
		if(search && search !== "undefined") {
			this.props.history.push(`/classic/leagues/join/search/${search}`);
		}
		else {
			this.props.history.push("/classic/leagues/join");
		}
	};

	triggerSearch() {
		const { search, showToJoinClassicLeagues } = this.props;

		this.setState({
			search_value: search
		});
		showToJoinClassicLeagues({ 
			offset: 0, 
			q: search, 
			limit: 20,
			size: this.state.filter_size,
			order_by: this.state.filter_num_teams,
			start_round: this.state.filter_start_round,
		});
	}

	handleLeagueJoinPopup({ id }: Object) {
		const { leagues_to_join } = this.props,
			league_to_join = _.find(leagues_to_join, { id });

		this.setState({
			is_show_join_popup: true,
			league_to_join
		});
	}

	handleLeagueSettingsPopup({ id }: Object) {
		const { leagues_to_join } = this.props,
			league_to_join = _.find(leagues_to_join, { id });

		this.setState({
			is_show_settings_popup: true,
			league_to_join
		});
	}

	handleLeagueJoinPopupHide() {
		this.setState({
			is_show_join_popup: false,
			league_to_join: {}
		});
	}

	handleLeagueSettingsPopupHide() {
		this.setState({
			is_show_settings_popup: false,
			league_to_join: {}
		});
	}

	loadMore() {
		const { offset } = this.props;

		this.props.showToJoinLoadMoreClassicLeagues({
			offset: offset + OFF_GROWTH,
			q: this.state.search_value
		});
	}
	handleUpdateOrderBy(e) {
		this.setState({
			filter_num_teams: e.target.value
		});
	}
	handleUpdateSize(e) {
		this.setState({
			filter_size: e.target.value
		});
	}
	handleUpdateStartRound(e) {
		this.setState({
			filter_start_round: e.target.value
		});
	}
	handleFiltersShow(e) {
		this.setState({
			show_filters: true
		});
	}

	get error_modal() {
		const { error } = this.props.join_to;
		return (
			<TextModal
				onClick={()=>redirectTo("/game-selection")}
				header_text='Error'
				text={ error }
			/>
		);
	}

	render() {
		
		const { leagues_to_join, is_load_more, 
			search, join_to } = this.props;
		const { is_show_join_popup, league_to_join, 
			is_show_settings_popup, show_filters } = this.state;
		const {error} = join_to;	
		return (
			<React.Fragment>
				<AdsContainer>
					<Ad />
				</AdsContainer>
				<PageContentWrapper>
					
					<TwoColumnLayout>
						<JoinLeagueLayout>
							<PageTitleStyled>
						
						Join a Classic league
							</PageTitleStyled>
							<SearchWrap>
								<SearchFormFlex>
									<SearchForm
										placeholder=
											"Search for a Public League or enter your Private PIN"
										field_name="q"
										initialValues={{ q: search }}
										onSubmit={this.handleSearchSubmit.bind(this)}
										onChange={this.handleSearchSubmit.bind(this)}
									/>
									{(!show_filters) && 
										<MoreFiltersButton 
											onClick={this.handleFiltersShow.bind(this)}
											value={true}
										>
											<More />
										</MoreFiltersButton> 

									}
									
								</SearchFormFlex>
								{
								
									show_filters && <> <RoundSelectMargin
										onChange={this.handleUpdateOrderBy.bind(this)}
										value={this.state.filter_num_teams}
										right_zero
									>
										<option value="">Teams Entered: High-Low</option>
										<option value="num_teams_desc">Highest</option>
										<option value="num_teams">Lowest</option>
									</RoundSelectMargin>
									<RoundSelectMargin
										onChange={this.handleUpdateSize.bind(this)}
										value={this.state.filter_size}
									
									>
										<option value="">All Sizes</option>
										{[4,6,8,10,12,14,16,18,20].map(num => <option 
											key={num}
											value={num}>
											{num}
										</option>)}
									</RoundSelectMargin>
									<RoundSelect
										onChange={this.handleUpdateStartRound.bind(this)}
										value={this.state.filter_start_round}
									>
										<option value="">All starting rounds</option>
										{_.range(1, ROUNDS_IN_AFL_SEASON).map(num => <option 
											key={num}
											value={num}>
											{num}
										</option>)}
									</RoundSelect> </> }
								
							</SearchWrap>

							<JoinLeagueList
								handleLeagueJoinPopup={this.handleLeagueJoinPopup.bind(this)}
								handleLeagueSettingsPopup=
									{this.handleLeagueSettingsPopup.bind(this)}
								leagues_list={leagues_to_join}
								join={this.join}
							/>
							
							{error && this.error_modal }

							<Exist when={!leagues_to_join.length}>
								<NotFound>
									Sorry, we couldn't find any Classic leagues
									that match that invite code or league name.
									This is the Classic Leagues page.
									Please check the code and the game type and try again.
								</NotFound>
							</Exist>
							<Exist when={is_show_join_popup}>
								<JoinLeaguePopup
									onClick={this.handleLeagueJoinPopupHide.bind(this)}
									join={this.join}
									league={league_to_join}
								/>		
							</Exist>				
							
							<Exist when={is_show_settings_popup}>
								<JoinLeagueSettingsPopup
									join={this.join}
									onClick={this.handleLeagueSettingsPopupHide.bind(this)}
									league={league_to_join}
								/>	
							</Exist>
							<Exist when={is_load_more}>
								<ButtonLoadMore onClick={this.loadMore.bind(this)}>
									View more leagues
								</ButtonLoadMore>
							</Exist>

							
						</JoinLeagueLayout>
						<StandardSidebar />
					</TwoColumnLayout>
				</PageContentWrapper>
				<Footer />
			</React.Fragment>
		);
		
	}
}

const mapStateToProps = (state, props) => {
	const leagues_size = state.leaguesClassic.show_to_join.ordered_ids.length;
	const offset = state.leaguesClassic.show_to_join.offset;
	let search = props.match.params.search;
	
	if (search) {
		try {
			search = decodeURIComponent(search);
		} 
		catch (error) { /* Don't worry, I guess */}
	}
	return {
		leagues_to_join: state.leaguesClassic.show_to_join.ordered_ids.map(
			league_id => state.leaguesClassic.by_id[league_id]
		),
		is_load_more: leagues_size && leagues_size >= offset + 20,
		join_to: state.leaguesClassic.join_to,
		offset,
		search,
	};
};

const mapDispatchToProps = {
	showToJoinClassicLeagues: actions.howJoJoinClassicLeagues,
	showToJoinLoadMoreClassicLeagues: actions.showToJoinLoadMoreClassicLeagues,
	joinToClassicLeague: actions.joinToClassicLeague,
	fetchClassicLeagues: actions.myListClassicLeagues
};

export const ClassicLeaguesJoin = connect(
	mapStateToProps,
	mapDispatchToProps
)(ClassicLeaguesJoinPage);

export default ClassicLeaguesJoin;