// @flow
import * as React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import * as _ from "lodash";
import styled from "styled-components";
import type { TRootStore, TSquad, TRound, TRoundsById, TUser } from "../modules/types";
import {
	AdsContainer,
	Ad,
	Footer,
	PageContentWrapper,
	PageTitle,
	OneColumnLayout,
	Preloader,
} from "../components";


import * as selectors from "../modules/selectors";
import withRoundsSelector from "../components/utils/withRoundsSelector";

import { fetchOffSeasonRankings } from "../modules/actions/offSeason";
import OffSeasonRankingsTable from "./offSeason/offSeasonRankingsTable";

const ORDER_BY_ASC = ["rank"];

const TAB_COLS = [
	"rank", "rank_change", "avatar", "total_points",
	"avg_points", "round_points", "value"
];
const MOB_COLS = [
	"rank", "avatar", "total_points",
	"avg_points", "round_points", "value"
];



const OneColumnLayoutStyled = styled(OneColumnLayout)`
	margin-top: 5px;
`;

type Props = {
	is_preseason: boolean,
	rounds_ordered: Array<TRound>,
	rounds_by_id: TRoundsById,
	selected_round_id: number,
	setSelectedRoundId: Function,
	squads: Array<TSquad>,
	user: TUser,
	offSeasonRankingsQuery: Function,
	off_season_rankings: Array<Object>,
}

type TFilters = {
	team_state: number,
	squad_id: number
}

type TSortOrder = {
	by: string,
	direction: 'ASC' | 'DESC'
}

type State = {
	filters: TFilters,
	order: TSortOrder,
	showing_teams: number,
	offset: number
}

export class OffSeasonRankingsPage extends React.Component<Props, State> {
	constructor() {
		super();

		_.bindAll(this, [
			"updateTeamState",
			"updateSquadId",
			"updateOrdering",
		]);
	}

	state = {
		filters: {
			team_state: 0,
			squad_id: 0,
		},
		order: {
			by: "rank",
			direction: "ASC",
		},
		showing_teams: 100,
		offset: 0
	};

	componentDidMount() {
		this.props.offSeasonRankingsQuery();
	}

	refreshRankings() {}

	updateTeamState({ currentTarget }: any) {
		let team_state = parseInt(currentTarget.value, 10);
		if (_.isNaN(team_state) || !_.isNumber(team_state)) {
			team_state = 0;
		}
		this.setState({
			filters: {
				squad_id: 0, /* Reset Club being shown */
				team_state,
			}
		}, () => this.refreshRankings());
	}
	updateSquadId({ currentTarget }: any) {
		let squad_id = parseInt(currentTarget.value, 10);
		if (_.isNaN(squad_id) || !_.isNumber(squad_id)) {
			squad_id = 0;
		}

		this.setState({
			filters: {
				team_state: 0, /* Reset state being shown */
				squad_id,
			}
		}, () => this.refreshRankings());
	}
	updateOrdering(new_by: string) {
		const { order: current_order } = this.state;

		if (current_order.by === new_by) {
			const new_dir = current_order.direction === "ASC" ? "DESC" : "ASC";
			this.setState({
				order: {
					...current_order,
					direction: new_dir
				}
			}, () => this.refreshRankings());
		}
		else {
			const new_dir = _.includes(ORDER_BY_ASC, new_by) ? "ASC" : "DESC";

			this.setState({
				order: {
					direction: new_dir,
					by: new_by,
				}
			}, () => this.refreshRankings());
		}
	}

	get display_teams() {
		const { off_season_rankings } = this.props;
		const { showing_teams } = this.state;

		return _.take(off_season_rankings, showing_teams);
	}
	get ladder_stats() {
		return [
			{
				key: "Total Points",
				prop: "overall_points",
				short_key: "",
			},
			{
				key: "Average Points",
				prop: "avg_points",
			},
			{
				key: "Points last round",
				prop: "round_points",
			},
			{
				key: "Team value",
				prop: "value",
				format: "$0,0",
			}
		];
	}

	renderRankingsTable() {
		const {
			selected_round_id,
			rounds_by_id,
			user
		} = this.props;
		const {
			order
		} = this.state;

		return <OffSeasonRankingsTable
			ladder_teams_by_id={{}}
			ladder_teams_ordered={this.display_teams}
			rounds_by_id={rounds_by_id}
			selected_round_id={selected_round_id}
			user_id={user.id}
			ladder_stats={this.ladder_stats}
			updateSortBy={this.updateOrdering}
			order={order}
			mobile_columns={MOB_COLS}
			tablet_columns={TAB_COLS}
		/>;
	}

	render() {
		const { off_season_rankings } = this.props;

		return (
			<React.Fragment>
				<AdsContainer>
					<Ad />
				</AdsContainer>

				<PageContentWrapper>
					<PageTitle>
						Rankings
					</PageTitle>
					<OneColumnLayoutStyled>
						{ off_season_rankings.length ? this.renderRankingsTable() : <Preloader /> }
					</OneColumnLayoutStyled>
				</PageContentWrapper>

				<AdsContainer>
					<Ad />
				</AdsContainer>

				<Footer />
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: TRootStore, props) => ({
	is_preseason: selectors.rounds.isPreseason(state),
	user: selectors.getUser(state),
	squads: selectors.squads.getSquads(state),
	off_season_rankings: _.get(state.offSeasonRankings, "rankings", []),
});

const mapDispatchToProps = {
	offSeasonRankingsQuery: fetchOffSeasonRankings,
};


const OffSeasonRankings = compose(
	withRoundsSelector,
	connect(
		mapStateToProps,
		mapDispatchToProps
	)
)(OffSeasonRankingsPage);

export default OffSeasonRankings;