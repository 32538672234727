// @flow
import styled from "styled-components";

export const TeamViewControls = styled.div`
	display: flex;
	justify-content: space-around;
	align-items: center;

	margin: 0 4px 0 0;

	> div {
		margin: 0 4px;

		&:first-child {
			margin-left: 0;
		}
	}
`;

export default TeamViewControls;