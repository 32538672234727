// @flow
import React from "react";
import { getLoginLink } from "../helpers";

function InitiatePage () {
	React.useEffect(() => {
		window.location.href = getLoginLink();
	});
	
	return <></>;
}

export default InitiatePage;