export const breakpoints = {
	two: {
		max: "767px"
	},
	three: {
		max: "980px",
		min: "767px"
	}
};

export default breakpoints;