// @flow
import { createAction } from "redux-act";

export const getNotificationBar = createAction("Get Notification Bar");
export const getNotificationBarFailed = createAction("Get Notification Bar Failed");
export const getNotificationBarSuccess = createAction("Get Notification Bar Success");

export const getNotificationDraftBar = createAction();
export const getNotificationDraftBarFailed = createAction();
export const getNotificationDraftBarSuccess = createAction();


export const getWinners = createAction("Get Winners");
export const getWinnersFailed = createAction("Get Winners Failed");
export const getWinnersSuccess = createAction("Get Winners Success");