import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import bindAll from "lodash/bindAll";
import * as actions from "../modules/actions";
import isAuthenticated from "../utils/auth/isAuthenticated";
import {
	TextModal,
} from "../components";
import {TUser} from "../modules/types";
import CommunicationsForm from "./communications/communicationsForm";




type Props = {
	is_authorized: boolean,
	updateUser: typeof actions.updateUser,
	clearUpdateUserData: typeof actions.clearUpdateUserData,
	user: TUser,
};

type State = {}

class CommunicationsComp extends React.Component<State, Props> {
	constructor() {
		super();

		bindAll(this, [
			"handleSubmit",
			"handleModalClose",
		]);
	}

	state = {};

	handleSubmit(values) {
		const { updateUser } = this.props;
		updateUser(values);
	}

	get error_modal() {
		const { user } = this.props;

		return (
			<TextModal
				onClick={this.handleModalClose}
				header_text='Error'
				text={ user.update.error.message } />
		);
	}

	get success_modal() {
		return (
			<TextModal
				onClick={this.handleModalClose}
				header_text='Success'
				text='Communication settings have been successfully changed!' />
		);
	}

	handleModalClose() {
		const { clearUpdateUserData } = this.props;
		clearUpdateUserData();
	}

	get check_is_authorized() {
		const { is_authorized } = this.props;
		return !is_authorized ? (<Redirect to="/" />) : null;
	}

	get check_user_errors() {
		const { user } = this.props;
		return user.update.error ? this.error_modal : null;
	}

	get check_is_update_user() {
		const { user } = this.props;
		return !user.update.error && user.update.success ?
			this.success_modal : null;

	}
	render() {
		return (
			<React.Fragment>
				{ this.check_is_authorized }
				{ this.check_user_errors }
				{ this.check_is_update_user }
				<CommunicationsForm onSubmit={this.handleSubmit}/>
				
			</React.Fragment>
		);
	}
}

const mapStateToProps = state => ({
	is_authorized: isAuthenticated(),
	user: state.user,
});

const mapDispatchToProps = {
	updateUser: actions.updateUser,
	clearUpdateUserData: actions.clearUpdateUserData,
};

export const Communications = connect(
	mapStateToProps,
	mapDispatchToProps,
)(CommunicationsComp);

export default Communications;