// @flow
import * as React from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import styled from "styled-components";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import bindAll from "lodash/bindAll";
import { below } from "../../assets/css/media";
import colors from "../../assets/css/colors";
import { RegistrationValidate } from "../../utils/FormValidations";
import { ButtonPrimary, FormCheckbox } from "../../components";
import {TUser} from "../../modules/types";
const TermsFormStyled = styled.form`
	background: transparent;
	margin-bottom: 50px;
`;
const TermsConditionsCopy = styled.label`
  color: #1D4073;
  font-family: SourceSansPro;
  font-size: 14px;
  text-align: left;
  font-weight: 400;
  a{
    font-weight: 700;
    text-decoration: underline;
    color: #1D4073;
  }
  margin-bottom: 20px;
  ${below.phone`
    font-size: 12px
  `}

`;

const InputLabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  input{
    margin-right: 18px;
  }
  label:first-child{
	  width: 16px;
  }
`;
const ButtonPrimaryStyled = styled(ButtonPrimary)`
	height: 40px;
	width: 200px;
	font-size: 12px;
	margin: 20px 0;
  :hover:not(:disabled){
    background-color: ${colors.primary.accent}
  }
`;
type Props = {
	handleSubmit: Function,
	user: TUser,
  toyota: number,
  is_pending: boolean,
  success: boolean,
  tc: number,
}
class TermsConditionsForm extends React.Component<Props> {
	constructor(props) {
		super(props);
		bindAll(this, [
			"handleSubitTerms",
		]);
	}
	handleSubitTerms(e) {
		const {tc } = this.props;
		e.preventDefault();
		this.props.handleSubmit({ tc });
	}
	render() {
		const { tc } = this.props;
		return <TermsFormStyled onSubmit={this.handleSubitTerms}>
			<InputLabelWrapper>
				<Field
					type="checkbox"
					name='tc'
					component={FormCheckbox}
					parse={value => value ? 1 : 0}
					tnc={true}
				/> 

				<TermsConditionsCopy for="tc">
					I have read and accepted {" "} <a
						href="https://www.afl.com.au/privacy"
						target='_blank'
						rel="noopener noreferrer">
						AFL's Privacy Policy*
					</a>, {" "}<a href={
						"http://www.telstra.com.au/"
						+ "privacy/privacy-statement/?ref=Net-Footer-Corp-Privacy"
					}
					target='_blank' rel="noopener noreferrer">
					Telstra's Privacy Policy</a> and the {" "} 
					<a href="/terms-conditions" target='_blank'>
					Terms & Conditions</a>. {" "}
					I also agree to receiving Tipping/Fantasy 
					emails about the competition.
				</TermsConditionsCopy>
        
				
			</InputLabelWrapper>
			{/* <InputLabelWrapper>
				<Field
					label={<TermsConditionsCopy>
            I'm interested in Toyota products, news and events, 
            and I agree to Toyota Motor Corporation Australia Pty 
            Limited using my personal information to send me marketing 
            material in accordance with its Privacy Policy available at: <a href={
							"https://www.toyota.com.au/privacy-policy"
						}
						target='_blank' rel="noopener noreferrer">
            www.toyota.com.au/privacy-policy</a> 
					</TermsConditionsCopy>}
					type="checkbox"
					name='toyota'
					component={FormCheckbox}
					parse={value => value ? 1 : 0}
					tnc={true}
				 />

				
			</InputLabelWrapper> */}
			
			<ButtonPrimaryStyled disabled={this.props.is_pending || tc !== 1}>
					Continue
			</ButtonPrimaryStyled>
		
			
		</TermsFormStyled>;
	}
}
TermsConditionsForm = reduxForm({
	form: "terms_conditions_form",
	validate: RegistrationValidate
})(TermsConditionsForm);
const selector = formValueSelector("terms_conditions_form");
const mapStateToProps = state => {
	return {
		initialValues: {
			tc: 0,
			// toyota: 0,
		},
		// toyota:selector(state, "toyota"),
		tc:selector(state, "tc"),
		is_pending: state.user.update.is_pending,
		success: state.user.update.success
	};
};;
TermsConditionsForm = withRouter(connect(mapStateToProps, {})(TermsConditionsForm));
export default TermsConditionsForm;