import React from "react";
import { connect } from "react-redux";
import bindAll from "lodash/bindAll";
import get from "lodash/get";
import styled from "styled-components";

import { SEASON_YEAR } from "../modules/constants";
import isAuthenticated from "../utils/auth/isAuthenticated";
import { clearLoginErrors, preregistration } from "../modules/actions";
import {TUser} from "../modules/types";
import { Exist, Footer, OneColumnLayout, TextModal } from "../components";
import logo from "../assets/images/home-logo.webp";
import HomeBackground from "../assets/images/home-bg-2023.webp";
import HomeBackgroundMobile from "../assets/images/home-bg-mobile.webp";
import { below } from "../assets/css/media";

import { isMobile } from "../utils";
import OffSeasonForm from "./offSeason/offSeasonForm";
import LinkStyled from "./offSeason/linkStyled";

const OffseasonLoginWrapper = styled.div`
background-color: #001431;
background-image: url(${HomeBackground});
background-size: cover;
background-repeat: no-repeat;
background-position: top center;
padding: 3em 0;
@media (min-width: 1450px){
background-position: top -45px center;
}
@media (min-width: 1550px){
background-position: top -100px center;
}
${below.desktop`
	background-image: url(${HomeBackgroundMobile});
background-size: cover;
background-position: top;
min-height: 630px;
`};
`;

const LogoContainer = styled.div`
	margin: 6px 16px 0;
	width: 200px;
	position: relative;
	z-index: 99;
	img {
		display: block;
		margin: 0 auto;
    width: 200px;
	}
  .hilux-absolute{
    width: 310px;
    
    position: absolute;
    top: 44px;
    left: -190px;
    ${below.desktop`
      width: 200px;
      top: 12px;
      left: -80px;
    `}

  }

	${below.desktop`
    img{
      width: 99px;
    }
	`};
`;

const OneColumnLayoutStyled = styled(OneColumnLayout)`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
`;

const LoginTitle = styled.h4`
	color: #FFFFFF;
	font-size: 32px;
	max-width: 370px;
	margin: 1em .5em .5em .5em;
	${below.phone`
		font-size: 22px;
	`}
`;

const DescriptionBlock = styled.p`
	max-width: 370px;
	font-family: "SourceSansPro";
	color: #FFFFFF;
	font-size: 20px;
	line-height: 28px;
	text-align: center;
	${below.desktop`
		max-width: 600px;
		font-size: 16px;
	`};
`;

const SeePrizeBlock = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 0 0 1em 0;
	color: #FFFFFF;
	a{
		font-size: 12px;
		font-family: SourceSansPro;
		font-weight: 600;
	}

	p{
		font-size: 16px;
		font-weight: bold;
	}
`;

const ButtonContainer = styled.button`
	padding: 0;
	border: none;
	background: none;
`;

type Props = {
	is_authorized: boolean,
	user: TUser,
	preregistration: typeof preregistration,
	clearLoginErrors: typeof clearLoginErrors,
	form_sending: boolean,
	form_error_text: string,
};

type State = {
	show_sign_up: boolean,
	click_num_mob: number
}

// gavinwanganeen
const SECRET = atob("Z2F2aW53YW5nYW5lZW4=");

class OffSeasonComponent extends React.Component<Props, State> {
	constructor() {
		super();

		bindAll(this, [
			"handleSubmit",
			"handleErrorModalClose",
			"handleClickIncrement",
			"egm",
		]);

		window.addEventListener("keypress", this.egm);
	}

	state = {
		show_sign_up: false,
		click_num_mob: 0
	};

	keys = [];

	// enableGodMode = egm
	egm(event: React.SyntheticEvent<KeyboardEvent>) {
		this.keys.push(String.fromCharCode(event.charCode));
		const result = this.keys.join("");

		if(!SECRET.includes(result)) {
			this.keys = [];
			return;
		}

		if(SECRET === result) {
			localStorage.setItem("a18c25c64-24db-44aa-a479-77915d02abbb", "true");
			window.location.reload();
		}
	}

	get error_modal() {
		const { form_error_text } = this.props;

		return (
			<TextModal
				onClick={this.handleErrorModalClose}
				header_text='Error'
				text={ form_error_text }
			/>
		);
	}

	handleClickIncrement() {
		if(this.state.click_num_mob > 8){
			const result = window.prompt();
			if(!SECRET.includes(result)) {
				return;
			}
	
			if(SECRET === result) {
				localStorage.setItem("a18c25c64-24db-44aa-a479-77915d02abbb", "true");
				window.location.reload();
			}
		}
		this.setState({
			click_num_mob: this.state.click_num_mob + 1
		});
		
	}

	handleErrorModalClose() {
		const { clearLoginErrors } = this.props;
		clearLoginErrors();
	}

	handleSubmit(values) {
		const { preregistration } = this.props;
		preregistration(values);
	}

	render() {
		const { form_sending, form_error_text } = this.props;
		const is_mobile = isMobile();
		

		return (
			<React.Fragment>
				<OffseasonLoginWrapper className="offseason-login-wrapper">
					{ form_error_text !== "" ? this.error_modal : null }
					<OneColumnLayoutStyled>
						<Exist when={!is_mobile}>
							<LogoContainer>
								<img src={logo} alt="AFL Logo" />
							</LogoContainer>
						</Exist>
						<Exist when={is_mobile}>
							<ButtonContainer onClick={this.handleClickIncrement}>
								<LogoContainer>
									<img src={logo} alt="AFL Logo" />
								</LogoContainer>
							</ButtonContainer>
							
						</Exist>
						<LoginTitle>Thank you for playing AFL Fantasy in {SEASON_YEAR}!</LoginTitle>
						<DescriptionBlock>
							Congratulations to our major prize winner 
							Christopher B (Noeyedeer5)!
						</DescriptionBlock>
						<SeePrizeBlock>
							<LinkStyled to='/classic/help/prizes'>
								See all of the {SEASON_YEAR} Prize Winners
							</LinkStyled>
						</SeePrizeBlock>
						{/* <DescriptionBlock>
							Want to check out all the 2020 stats?
						</DescriptionBlock> */}
						{/* <LinkStyled to="off-season/stats-centre">
							View the 2020 Stats Centre
						</LinkStyled> */}

						<OffSeasonForm {...{
							onSubmit: this.handleSubmit,
							form_sending: form_sending,
						}} />
					</OneColumnLayoutStyled >
				</OffseasonLoginWrapper>
				<Footer />
			</React.Fragment>
		);
	}
}

const mapStateToProps = state => ({
	is_authorized: isAuthenticated(),
	user: state.user,
	form_error_text: get(state, "user.preregistration.error", ""),
	form_sending: get(state, "user.preregistration.is_sending", false),
});

const mapDispatchToProps = {
	preregistration,
	clearLoginErrors,
};

export const OffSeason = connect(
	mapStateToProps,
	mapDispatchToProps
)(OffSeasonComponent);

export default OffSeason;