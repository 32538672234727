// @flow
import _ from "lodash";
import { createSelector } from "reselect";
import { get } from "lodash";
import * as roundsSelector from "../rounds";
import {
	createWinLossForm,
	createWinLossRecord,
	gamesPlayed,
	leagueStatsSelected,
} from "../../../helpers/team";
import { getLadderRoundFinals, BYE_ROUNDS } from "../../../helpers";
import * as ladder from "./ladder";
import getById from "./getById";
import getLeague from "./getLeague";

const getSelectedRoundId = (state: Object, props: Object) => props.selected_round_id;

const getLeagueIdUrlParam = (state, props) => get(props, "match.params.league_id");

export const getLadderRound = createSelector(
	roundsSelector.getLastCompleteRound,
	getSelectedRoundId,
	getLeague,
	(last_complete_round, selected_round_id, league) => {
		const last_complete_round_id = _.get(last_complete_round, "id", 0);
		const ladder_round_id = selected_round_id < last_complete_round_id
			? selected_round_id : last_complete_round_id;

		const finals = _.get(league, "finals", false);
		if (finals) {
			return getLadderRoundFinals(league, ladder_round_id);
		}

		return ladder_round_id;
	}
);

const getOrderedLadderCalculated = createSelector(
	ladder.getOrdered,
	getLeagueIdUrlParam,
	getById,
	getLadderRound,
	(
		ladder,
		league_id: number | typeof undefined, 
		leagues_by_id: Object,
		ladder_round_id
	) => {
		const league = get(leagues_by_id, league_id, {});
		// const last_complete_round_id = _.get(last_complete_round, 'id', 0);
		// const ladder_round_id = selected_round_id < last_complete_round_id
		// 	? selected_round_id : last_complete_round_id;
		const previous_ladder_round_id = ladder_round_id - 1;
		const { start_round, end_round, type: league_type, bye_rounds } = league;

		const getFilteredScoreflow = (team, last_rd) => {
			let filtered = [];
			if (league_type === "open") {
				filtered = _.filter(team.scoreflow,(stats, key) => {

					if (!bye_rounds && last_rd.includes(BYE_ROUNDS)) {
						return false;
					}

					const is_in_range = _.inRange(last_rd, start_round, end_round + 1);
					return is_in_range && last_rd >= parseInt(key, 10);
				});
			}
			else {
				filtered = _.filter(team.scoreflow,(stats, key) => (
					_.has(team.league_against, key) && last_rd >= parseInt(key, 10)
				));
			}

			return _.sum(filtered);
		};

		return ladder.map(team => {
			const points_for = getFilteredScoreflow(team, ladder_round_id);
			const points_against = leagueStatsSelected(team.league_against, ladder_round_id);
			return {
				...team,
				games_played: gamesPlayed(team, ladder_round_id),
				...createWinLossRecord(team, ladder_round_id),
				form: createWinLossForm(team, ladder_round_id),
				league_points_selected_round:
					leagueStatsSelected(team.league_scoreflow, ladder_round_id),
				points_for_selected_round: points_for,
				points_against_selected_round: points_against,
				points_percent_selected_round:
					team.league_against ?
						(((points_for / points_against) || 0) * 100).toFixed(2) : "-",
				points_average_selected_round:
					gamesPlayed(team, ladder_round_id)
						? ((points_for
						/ gamesPlayed(team, ladder_round_id))).toFixed(2) : 0,
				points_last_round_selected_round: leagueStatsSelected(
					team.scoreflow, previous_ladder_round_id, league.start_round
				),
				rank_selected_round: _.get(team, ["rank_history", ladder_round_id])
			};
		});
	});

export default getOrderedLadderCalculated;