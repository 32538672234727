// @flow
import * as React from "react";
import styled from "styled-components";
import colors from "../../../../assets/css/colors";
import {
	LeagueAvatar,
	LeagueName,
	ModalContainer,
} from "../../../../components";
import { below } from "../../../../assets/css/media";
import type { TPLeague } from "../../../../modules/types";

const LeagueSettingsPopupHeading = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	background: #fff;
	height: 100px;
	padding: 75px;
	box-sizing: border-box;
	
	${below.tablet`
		height: auto;
		display: block;
		margin: 1em 0;
		padding: 0 0 0 10px;
	`}

	> div {
		width: 70%;
		display: flex;
		align-items: center;
		box-sizing: border-box;
		
		${below.tablet`
			display: block;
			width: 100%;
		`}
	}

	img {
		margin-right: 1em;
	}
`;

const LeagueSettingsPopupOption = styled.div`
	padding: 0 75px;
	box-sizing: border-box;
	margin: 10px 0;
	
	${below.tablet`
		padding: 0 10px;
	`}

	h3 {
		color: ${colors.primary.primary};
		font-size: 15px;
		font-weight: bold;
		line-height: 1.46;
	}

	> div {
		margin: .5em auto;
		box-sizing: border-box;

		> div {
			background: #fff;
			display: flex;
			align-items: center;
			box-sizing: border-box;

			p:first-child {
				color: ${colors.primary.primary};
				font-size: 12px;
				line-height: 1.66;
			}
			p:last-child {
				color: ${colors.secondary.accentGrey};
				font-size: 12px;
				line-height: 1.66;
				margin-left: 1em;
			}
		}
	}
`;

const LeagueNameStyled = styled(LeagueName)`
	margin-left: 1em;
`;

const onOff = param => param ? "ON" : "OFF";

type PropsOption = {
	label: string,
	value: string | number,
	is_uppercase?: boolean
};
const PopupOption = ({ label, value, is_uppercase }: PropsOption) => (
	<div>
		<p>{label}</p>
		<p className={is_uppercase ? "uppercase" : ""}>{value}</p>
	</div>
);

type Props = {
	league: TPLeague,
	onClick: Function,
};

export const LeagueSettingsPopup = ({ league, onClick }: Props) => {
	if (!league) {
		return null;
	}

	return (
		<ModalContainer is_sponsored={false} onClick={onClick}>
			<LeagueSettingsPopupHeading>
				<div className="hidden-mobile">
					<LeagueAvatar is_classic league={league} size="60px" />
					<LeagueNameStyled width="300px"
						name={league.name}
						lastname={league.lastname}
						firstname={league.firstname}
					/>
				</div>
				<div className="hidden-desktop">
					<LeagueNameStyled width="300px"
					            name={league.name}
					            lastname={league.lastname}
					            firstname={league.firstname}
					/>
				</div>
			</LeagueSettingsPopupHeading>

			<LeagueSettingsPopupOption>
				<h3>Basic Settings</h3>
				<div>
					<PopupOption label="League Size" value={league.size} />
					<PopupOption label="Starting Round" value={league.start_round} />
					<PopupOption label="Privacy Settings"
						value={league.privacy ? "PUBLIC" : "PRIVATE"}
					/>
					<PopupOption label="League type" value={
						league.type === "head_to_head" ? "Head to head" : "Open"
					} />
					<PopupOption label="Coaches Box"
					             value={onOff(league.coaches_box)}
					/>

				</div>
			</LeagueSettingsPopupOption>

			<LeagueSettingsPopupOption>
				<h3>Advanced Settings</h3>
				<div>
					<PopupOption label="League Finals Series"
						value={league.finals ? league.finals_format : "OFF"}
					/>
					<PopupOption label="Play Opponents"
						value={league.play_times}
						is_uppercase={true}
					/>
					<PopupOption label="Bye Rounds"
						value={onOff(league.bye_rounds)}
					/>
				</div>
			</LeagueSettingsPopupOption>
		</ModalContainer>
	);
};

export default LeagueSettingsPopup;