// @flow
import React from "react";
import SvgIconContainer from "../SvgIconContainer";

export default (
	{
		unit = "em",
		...rest
	}: Object = {}) => (
	<SvgIconContainer viewBox="0 0 16 16" {...rest} unit={unit}>
		<g stroke="none" strokeWidth="1" fillRule="evenodd">
			<path
				d="M3.18620646,12.63479 C2.56974296,13.2512535 2.58047168,
					14.2174334 3.18301058,14.8199723 C3.78975027,15.426712 4.7638889,
					15.4210803 5.36819283,14.8167764 L10.816767,9.36820224 C11.1227983,
					9.06217091 11.2730798,8.66780903 11.2725107,8.27358879 C11.2740189,
					7.87511397 11.1207117,7.48376882 10.8199629,7.18301999 L5.36819283,
					1.73124994 C4.75172934,1.11478645 3.78554948,1.12551516 3.18301058,
					1.72805406 C2.57627089,2.33479375 2.58190253,3.30893238 3.18620646,
					3.91323631 L7.54698332,8.27401318 L3.18620646,12.63479 Z"
				transform="
					translate(7.000000, 8.272522)
					scale(-1, 1)
					translate(-7.000000, -8.272522)"
			/>
		</g>
	</SvgIconContainer>
);