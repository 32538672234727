// @flow
import styled from "styled-components";
import { below } from "../../../../assets/css/media";

export const JoinLeagueLayout = styled.div`

	.search-form-wrapper {
		margin: 0 0 1em;

		form {
			width: 100%;
		}

		${below.desktop`
			margin: 1em 10px;

			a {
				width: 45%;
				font-size: 10px;
			}
		`}
	}
`;

export default JoinLeagueLayout;