import { isMobile } from "../../../../utils";

const is_mobile = isMobile();
export const draft_order_column = {
	prop: "draft_order",
	key: "Draft Position",
	is_fc: false,
	tooltip: undefined,
	width: is_mobile ? "210px" : "115px"
};
export const player_info_column = {
	prop: "player-info",
	key: "",
	is_fc: false,
	tooltip: undefined,
	width: "181px",
	sortable: false,
};
export const dnd_column = {
	prop: "dnd",
	key: "",
	is_fc: false,
	tooltip: undefined,
	width: "55px",
	sortable: false,
};
export const kept_by_column = {
	prop: "kept_by",
	key: "Kept By",
	is_fc: false,
	tooltip: undefined,
	width: is_mobile ? "208px" : "110px",
	sortable: false,
};