// @flow
import {get} from "lodash";
import logo20 from "../assets/images/logos/20.svg";
import logo10 from "../assets/images/logos/10.svg";
import logo30 from "../assets/images/logos/30.svg";
import logo40 from "../assets/images/logos/40.svg";
import logo50 from "../assets/images/logos/50.svg";
import logo60 from "../assets/images/logos/60.svg";
import logo70 from "../assets/images/logos/70.svg";
import logo80 from "../assets/images/logos/80.svg";
import logo90 from "../assets/images/logos/90a.svg";
import logo100 from "../assets/images/logos/100.svg";
import logo110 from "../assets/images/logos/110a.svg";
import logo120 from "../assets/images/logos/120.svg";
import logo130 from "../assets/images/logos/130.svg";
import logo140 from "../assets/images/logos/140.svg";
import logo150 from "../assets/images/logos/150.svg";
import logo160 from "../assets/images/logos/160a.svg";
import logo1000 from "../assets/images/logos/1000.svg";
import logo1010 from "../assets/images/logos/1010.svg";
import bye_logo from "../assets/images/bye.svg";

import png_logo20 from "../assets/images/logos/pngs/20.png";
import png_logo10 from "../assets/images/logos/pngs/10.png";
import png_logo30 from "../assets/images/logos/pngs/30_1.png";
import png_logo40 from "../assets/images/logos/pngs/40.png";
import png_logo50 from "../assets/images/logos/pngs/50.png";
import png_logo60 from "../assets/images/logos/pngs/60.png";
import png_logo70 from "../assets/images/logos/pngs/70.png";
import png_logo80 from "../assets/images/logos/pngs/80.png";
import png_logo90 from "../assets/images/logos/pngs/90.png";
import png_logo100 from "../assets/images/logos/pngs/100.png";
import png_logo110 from "../assets/images/logos/pngs/110.png";
import png_logo120 from "../assets/images/logos/pngs/120.png";
import png_logo130 from "../assets/images/logos/pngs/130.png";
import png_logo140 from "../assets/images/logos/pngs/140.png";
import png_logo150 from "../assets/images/logos/pngs/150.png";
import png_logo160 from "../assets/images/logos/pngs/160.png";
import png_logo1000 from "../assets/images/logos/pngs/1000.png";
import png_logo1010 from "../assets/images/logos/pngs/1010.png";

import rlogo20 from "../assets/images/logos/reverse/20.svg";
import rlogo10 from "../assets/images/logos/reverse/10.svg";
import rlogo30 from "../assets/images/logos/reverse/30.svg";
import rlogo40 from "../assets/images/logos/reverse/40.svg";
import rlogo50 from "../assets/images/logos/reverse/50.svg";
import rlogo60 from "../assets/images/logos/reverse/60.svg";
import rlogo70 from "../assets/images/logos/reverse/70.svg";
import rlogo80 from "../assets/images/logos/reverse/80.svg";
import rlogo90 from "../assets/images/logos/reverse/90.svg";
import rlogo100 from "../assets/images/logos/reverse/100.svg";
import rlogo110 from "../assets/images/logos/reverse/110a.svg";
import rlogo120 from "../assets/images/logos/reverse/120.svg";
import rlogo130 from "../assets/images/logos/reverse/130.svg";
import rlogo140 from "../assets/images/logos/reverse/140.svg";
import rlogo150 from "../assets/images/logos/reverse/150.svg";
import rlogo160 from "../assets/images/logos/reverse/160a.svg";
import rlogo1000 from "../assets/images/logos/reverse/1000.svg";
import rlogo1010 from "../assets/images/logos/reverse/1010.svg";

import png_rlogo20 from "../assets/images/logos/reverse/pngs/20.png";
import png_rlogo10 from "../assets/images/logos/reverse/pngs/10.png";
import png_rlogo30 from "../assets/images/logos/reverse/pngs/30.png";
import png_rlogo40 from "../assets/images/logos/reverse/pngs/40.png";
import png_rlogo50 from "../assets/images/logos/reverse/pngs/50.png";
import png_rlogo60 from "../assets/images/logos/reverse/pngs/60.png";
import png_rlogo70 from "../assets/images/logos/reverse/pngs/70.png";
import png_rlogo80 from "../assets/images/logos/reverse/pngs/80.png";
import png_rlogo90 from "../assets/images/logos/reverse/pngs/90.png";
import png_rlogo100 from "../assets/images/logos/reverse/pngs/100.png";
import png_rlogo110 from "../assets/images/logos/reverse/pngs/110.png";
import png_rlogo120 from "../assets/images/logos/reverse/pngs/120.png";
import png_rlogo130 from "../assets/images/logos/reverse/pngs/130.png";
import png_rlogo140 from "../assets/images/logos/reverse/pngs/140.png";
import png_rlogo150 from "../assets/images/logos/reverse/pngs/150.png";
import png_rlogo160 from "../assets/images/logos/reverse/pngs/160.png";
import png_rlogo1000 from "../assets/images/logos/reverse/pngs/1000.png";
import png_rlogo1010 from "../assets/images/logos/reverse/pngs/1010.png";

import indigenous_60 from "../assets/images/logos/indigenous/60.svg";
import indigenous_110 from "../assets/images/logos/indigenous/110.svg";
import indigenous_90 from "../assets/images/logos/indigenous/90.svg"; 
import indigenous_10 from "../assets/images/logos/indigenous/10.svg";
import { IS_IE } from "../helpers";

const isIndigenousRound = (round_num: number) => {
	// return round_num === 18;
	const indigenousRounds =  process.env.REACT_APP_INDIGENOUS_ROUNDS ?  JSON.parse(process.env.REACT_APP_INDIGENOUS_ROUNDS)  : [] ;
	const numberedRounds = indigenousRounds.map(num => Number(num));
	return numberedRounds.includes(round_num) ;
};


export const shouldUseIndigenousLogos = (round_num: number) => {
	// CHECKTIME IS AT LEAST 5pm on Monday May 13th 1715583600000
	// NO LATER THAN Monday May 27th (approx. 4.30/5pm). 1716793200000
	// const START_DATE = 1715583600000;
	const START_DATE = 1715583600000;
	const END_DATE = 1716793200000;
	const isTimeBetween = START_DATE <= Date.now() && Date.now() <= END_DATE;
	const SITE_ENV = process.env.REACT_APP_ENV || "uat";
	const isUAT = SITE_ENV === "uat";
	return isIndigenousRound(round_num) || (isTimeBetween && !isUAT);
};

export const getClubLogoIndigenous = (round_num: number, squad_id: number) => {
		
	const shouldSwitchLogo = shouldUseIndigenousLogos(round_num);
	const indigenousObj = {
		"10": indigenous_10,
		"60":indigenous_60 ,
		"90": indigenous_90,
		"110": indigenous_110
	};

	const obj = IS_IE ? {
		"20": png_logo20, //BRIS
		"10": png_logo10, // ADEL
		"30": png_logo30, // CARL
		"40": png_logo40, // COLL
		"50": png_logo50, // ESS
		"60": png_logo60, // FREO
		"70": png_logo70, // GEEL
		"80": png_logo80, //  HAW
		"90": png_logo90, // MEL
		"100": png_logo100, // NORF
		"110": png_logo110, // PORT
		"120": png_logo120, // RICH
		"130": png_logo130, // STK
		"140": png_logo140, // WBD
		"150": png_logo150,  // WCE
		"160": png_logo160, // SYD
		"1000": png_logo1000, // GCS
		"1010": png_logo1010 // GWS
	} : {
		"0" : bye_logo,
		"20": logo20,
		"10": logo10,
		"30": logo30,
		"40": logo40,
		"50": logo50,
		"60": logo60,
		"70": logo70,
		"80": logo80,
		"90": logo90,
		"100": logo100,
		"110": logo110,
		"120": logo120,
		"130": logo130,
		"140": logo140,
		"150": logo150,
		"160": logo160,
		"1000": logo1000,
		"1010": logo1010
	};
	const indigenousLogo = get(indigenousObj, squad_id);
	const isExist = shouldSwitchLogo && Boolean(indigenousLogo);
	if(isExist){
		return indigenousLogo;
	}
	return obj[squad_id];
};

export const clubLogos =  IS_IE ? {
	"20": png_logo20,
	"10": png_logo10,
	"30": png_logo30,
	"40": png_logo40,
	"50": png_logo50,
	"60": png_logo60,
	"70": png_logo70,
	"80": png_logo80,
	"90": png_logo90,
	"100": png_logo100,
	"110": png_logo110,
	"120": png_logo120,
	"130": png_logo130,
	"140": png_logo140,
	"150": png_logo150,
	"160": png_logo160,
	"1000": png_logo1000,
	"1010": png_logo1010
} : {
	"0" : bye_logo,
	"20": logo20,
	"10": logo10,
	"30": logo30,
	"40": logo40,
	"50": logo50,
	"60": logo60,
	"70": logo70,
	"80": logo80,
	"90": logo90,
	"100": logo100,
	"110": logo110,
	"120": logo120,
	"130": logo130,
	"140": logo140,
	"150": logo150,
	"160": logo160,
	"1000": logo1000,
	"1010": logo1010
};

export const reverse_logos = IS_IE ? {
	"20": png_rlogo20,
	"10": png_rlogo10,
	"30": png_rlogo30,
	"40": png_rlogo40,
	"50": png_rlogo50,
	"60": png_rlogo60,
	"70": png_rlogo70,
	"80": png_rlogo80,
	"90": png_rlogo90,
	"100": png_rlogo100,
	"110": png_rlogo110,
	"120": png_rlogo120,
	"130": png_rlogo130,
	"140": png_rlogo140,
	"150": png_rlogo150,
	"160": png_rlogo160,
	"1000": png_rlogo1000,
	"1010": png_rlogo1010
} : {
	"20": rlogo20,
	"10": rlogo10,
	"30": rlogo30,
	"40": rlogo40,
	"50": rlogo50,
	"60": rlogo60,
	"70": rlogo70,
	"80": rlogo80,
	"90": rlogo90,
	"100": rlogo100,
	"110": rlogo110,
	"120": rlogo120,
	"130": rlogo130,
	"140": rlogo140,
	"150": rlogo150,
	"160": rlogo160,
	"1000": rlogo1000,
	"1010": rlogo1010
};

export default clubLogos;