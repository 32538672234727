// @flow
import * as React from "react";
import { range, get } from "lodash";
import { Field } from "redux-form";
// import styled from "styled-components";
import {
	FormRadio, 
	FormScoreSetting, 
	FormSelect
} from "../../../../../components";
import type { 
	TPLeague, 
	TRoundsById, 
	TRound, 
	TRoundsWithMatches 
} from "../../../../../modules/types";
import { 
	league_tooltips, 
	orderedSquadSizeKeys, 
	everyXNumberBetween, 
	isAnyTrue,
	isAllTrue
} from "../../../../../helpers";
import type { ScoreSettingsProps } from "../../../../../helpers/index";
import { ProFormSelect, ProFormRadio } from "../../../../proSubscription";
import {
	FormScoreWrap,
	FormScoreTitleWrap,
	FormScoreTitle,
	FormScoreTitleRightWrap
} from "./../../../../../components/Form/FormScoreSetting/FormScoreSettingStyle";

// const StyledSpan = styled.span`
// 	color: #1D4073;
// 	font-family: "SourceSansPro";
// 	font-size: 14px;
// 	-webkit-letter-spacing: 0;
// 	-moz-letter-spacing: 0;
// 	-ms-letter-spacing: 0;
// 	letter-spacing: 0;
// 	line-height: 20px;
// 	margin: 0 0 20px 0;
// `;

type Props = {
	league: TPLeague,
	emergency: number,
	handleProOpen: Function,
	is_pro_sub: boolean,
  from: string,
  actual_round: TRound,
  rounds_by_id: TRoundsById,
  rounds_with_matches: TRoundsWithMatches,
};


export const GameplaySettings = (props: Props) => {
	const leagueSubscription = props.league.subscription ? props.league.subscription:"none";
  
	const disableEmergencies = isAnyTrue([
		leagueSubscription === "none",
		!Boolean(props.emergency)
	]);
	const emergencyRangeMin = !Boolean(props.emergency) ? 0:1;
	const emergencyRangeMax = !Boolean(props.emergency) ? 1:5;
  
	const is_create = !Boolean(props.league.id);
	const is_dpp_locked = get(props.rounds_with_matches, "[4].matches[8].status") !== "scheduled";
	// const is_league_scoring =  is_create ? false : props.league.status !== 'scheduled';
	const is_draft_starting = (isAllTrue([
		props.league.status === "scheduled",
		(Date.parse(props.league.draft_start) - new Date().getTime()) < 1800000
	]) ) || props.league.draft_status === "complete";
	const is_actual_round_active = is_create ? false : props.actual_round.status === "playing";
	const disableEmergFields = isAllTrue([
		is_actual_round_active,
		is_draft_starting
	]);
	const isKeepersFinalised = isAllTrue([
		props.league.keeper,
		props.league.regenerated_from !== 0,
		props.league.keepers_finalised
	]);
	const isLeagueSubscribed = ["full", "lite"].includes(leagueSubscription);
	return (
		<div>
			<FormRadio
				label="Enable captains"
				name="captains"
				elements={[
					{
						value: 1,
						text: "Yes",
					},
					{
						value: 0,
						text: "No",
					},
				]}
				tooltip={league_tooltips.captains}
				is_disabled={is_actual_round_active}
			/>
			<FormScoreWrap>
				<FormScoreTitleWrap>
					<FormScoreTitle>Position</FormScoreTitle>
					<FormScoreTitleRightWrap>Number of players</FormScoreTitleRightWrap>
				</FormScoreTitleWrap>
				{orderedSquadSizeKeys.map((stat: ScoreSettingsProps) => (
					<FormScoreSetting
						showBlankOption={false}
						showCheckBox={false}
						key={stat.key}
						label={`${stat.title} (${stat.stat})`}
						names={[`${stat.key}_show`, `${stat.key}_value`]}
						statType={stat.key}
						tooltip={{
							title: stat.title,
							description: stat.description,
						}}
						minValue={stat.minValue ? stat.minValue : -10}
						maxValue={stat.maxValue ? stat.maxValue : 21}
						is_disabled={stat.isPremium || is_draft_starting || isKeepersFinalised}
					/>
				))}
			</FormScoreWrap>
			
			<Field
				label='Enable auto updating of Dual Position Players (DPP)'
				name='original_positions'
				component={ProFormRadio}
				tooltip={league_tooltips.original_positions}
				elements={[
					{
						value: 0,
						text: "Yes",
					},
					{
						value: 1,
						text: "No",
					},
				]}
				is_disabled={is_dpp_locked}
				is_pro_sub={isLeagueSubscribed}
				handleProOpen={props.handleProOpen}
			/>

			<FormRadio
				label="Enable Emergencies"
				name="emergency"
				elements={[
					{
						value: 1,
						text: "Yes",
					},
					{
						value: 0,
						text: "No",
					},
				]}
				tooltip={league_tooltips.emergency}
				is_disabled={disableEmergFields}
			/>

			<Field
				label='Number of Emergencies'
				name='emergency_limit'
				options={
					range(emergencyRangeMin, emergencyRangeMax)
						.map(num => ({ value: num, text: num }))
				}
				component={FormSelect}
				tooltip={league_tooltips.num_emergencies}
				is_disabled={!Boolean(props.emergency) || disableEmergFields}
			/>
			<Field
				label='Emergency TOG threshold'
				name='tog_threshold'
				options={everyXNumberBetween(0,90,5).map(item => ({
					value: item, 
					text: `${item}%`
				}))}
				component={ProFormSelect}
				tooltip={league_tooltips.tog_threshold_emergencies}
				is_disabled={disableEmergencies}
				is_pro_sub={isLeagueSubscribed}
				handleProOpen={props.handleProOpen}
			/>
		</div>
	);
};

export default GameplaySettings;