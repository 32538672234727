/* eslint max-len: 0 */
// @flow
import React from "react";
import SvgIconContainer from "../SvgIconContainer";

export default (
	{
		unit = "em",
		...rest
	}: Object = {}) => (
	<SvgIconContainer viewBox="0 0 24 24" {...rest} unit={unit}>
		<title>Plus</title>
		<path d="m11 5v6h-6v2h6v6h2v-6h6v-2h-6v-6z" fillRule="evenodd" />
	</SvgIconContainer>
);