// @flow
import styled, { css } from "styled-components";
import colors from "../../assets/css/colors";
import { below } from "../../assets/css/media";
import PlayerInfo, { PlayerName, PlayerStatus, PlayerDetails } from "../PlayerInfo";

export const NextSquadOpponentWrapper = styled.div`
	flex: 0 0 100px;
	display: flex;

	${below.tablet`
		display: none;
	`};
`;

export const PlayerComparisonRow = styled.div`
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid ${colors.secondary.paleGrey};
`;

export const PositionHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 60px;
  ${({ is_compressed }) => is_compressed && `
    height: 30px;
  `}
	border-bottom: 2px solid #CAD2D8;
	${below.phone`
		height: 40px;
	`}
`;

export const PositionTitle = styled.div`
	font-size: 12px;
	font-weight: 500;
	color: ${colors.primary.primary};
  font-family: SourceSansPro;
`;

export const PlayerCount = styled.div`
	font-size: 12px;
	font-weight: 500;
	font-family: SourceSansPro;
	color: ${colors.primary.primary};
`;

export const ScoreWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	flex: 0 0 70px;
	${below.tablet`
		flex: none;
	`};
	${below.phone`
		${({ reverse }) => reverse ? "margin-left: 15px" : "margin-right: 15px"};
	`}
`;

const getPlayerScoreColor = ({
	is_playing,
	is_projected,
	is_average
}) => {
	if (is_playing) {
		return colors.player.live;
	}
	if (is_projected) {
		return colors.coach;
	}
	if (is_average) {
		return colors.primary.primary;
	}
	return colors.primary.primary;
};

export const Score = styled.div`
	font-size: 26px;
	font-weight: 700;
	font-family: SourceSansPro;
	color: ${getPlayerScoreColor};
  ${({ is_compressed }) => is_compressed && `
    font-size: 12px;
  `}

	${below.tablet`
		font-size: 14px;
		width: 20px;
		text-align: center;
		font-weight: 600;
    ${({ is_compressed }) => is_compressed && `
    font-size: 12px;
    `}
	`};
`;

export const ScoreLabel = styled(Score)`
	font-size: 9px;
	font-weight: 400;
	text-transform: uppercase;

	display: inline-flex;
	align-items: center;

	svg {
		padding-right: 3px;
		padding-bottom: 2px;
		fill: ${getPlayerScoreColor};
	}

	${({ is_compressed }) => is_compressed && `
		display: none;
	`}
`;

export const PlayerInfoStyled = styled(PlayerInfo)`
	flex: 1 0 auto;
	${PlayerName} {
		${below.desktop`
			font-size: 12px;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			max-width: 70px;
		`};

		${below.phone`
			max-width: 45px;
		`};

		@media (max-width: 330px) {
			max-width: 40px;
		}
	}

	${below.desktop`
		margin: 0;
	`};

	${PlayerStatus} {
		${({ is_compressed }) => is_compressed && css`
			button {
				margin: 0 3px;
				&:last-of-type {
					${({ reverse }) => reverse ? "margin-left": "margin-right" }: 0;
				}
				&:first-of-type {
					${({ reverse }) => reverse ? "margin-right": "margin-left" }: 0;
				}
			}
		`}
		${below.desktop`
			margin-right: 2px;
			margin-left: 2px
			button {
				margin: 0 2px;
			}
		`};

		${below.phone`
			width: 11px;
      ${({ reverse }) => !reverse ? "margin-right": "margin-left" }: 9px;
			button {
				
				transform: translateX(${({ reverse }) => reverse ? "-2px" : "2px"});
				> svg {
					display: none;
				}
			}
		`}
	}
	${PlayerDetails}{
		${below.phone`
			text-transform: uppercase;
		`};
	}
`;

export const Player = styled.div`
	box-sizing: border-box;
	display: flex;
	justify-content: space-between;
	flex: 0 0 50%;
	flex-direction: ${({ reverse }) => reverse ? "row-reverse" : "row"};
	align-items: center;
	padding-${({ reverse }) => reverse ? "left" : "right"}: 3%;

	${below.phone`
		padding-${({ reverse }) => reverse ? "left" : "right"}: 5px;
	`}

	${({ is_scoring_player }) => !is_scoring_player && `
		opacity: 0.5;
	`}

	

	${NextSquadOpponentWrapper} {
		justify-content: ${({ reverse }) => reverse ? "flex-end" : "flex-start"};
	};

	${({ make_pink }) => make_pink && `
		background: #ff00ff;
	`}
`;