// @flow
import React from "react";
import styled from "styled-components";
import colors from "../../assets/css/colors";

export const StyledPreloader = styled.div`
	position: fixed;
	z-index: 999999;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(4, 4, 4, 0.72);

	.spin {
		display: block;
		position: relative;
		margin: 0 auto;
		top: 40%;
		border: 16px solid #f3f3f3;
		border-radius: 50%;
		border-top: 16px solid ${colors.primary.primary};
		width: 120px;
		height: 120px;
		-webkit-animation: spin 2s linear infinite;
		animation: spin 2s linear infinite;

		@-webkit-keyframes spin {
			0% {
				-webkit-transform: rotate(0deg);
			}
			100% {
				-webkit-transform: rotate(360deg);
			}
		}

		@keyframes spin {
			0% {
				transform: rotate(0deg);
			}

			100% {
				transform: rotate(360deg);
			}
		}
	}
`;

export const Preloader = () => (
	<StyledPreloader>
		<div className="spin" />
	</StyledPreloader>
);

export default Preloader;