// @flow
import styled from "styled-components";

export const PlayersWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	margin: 20px 0;

	> div {
		margin: 0;

		&:first-of-type {
			margin-left: 0;
		}

		&:last-of-type {
			margin-right: 0;
		}
		
	}
`;