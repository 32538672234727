/* eslint max-len: 0 */
// @flow
import React from "react";
import SvgIconContainer from "../SvgIconContainer";

export default (
	{
		unit = "em",
		...rest
	}: Object = {}) => (
	<SvgIconContainer viewBox="0 0 24 24" {...rest} unit={unit}>
		<title>Bye</title>
		<path d="M20.060241,16.0481928 L21,17.4216867 L18.9036145,17.4216867 L17.9638554,16.0481928
        L11.0963855,16.0481928 L10.0843373,17.4216867 L7.91566265,17.4216867 L8.92771084,16.0481928
        L8.71084337,16.0481928 L3.61445783,10.9518072 C3.27510214,10.6124515 3.27510214,10.0622473
        3.61445783,9.72289157 L3.61445783,9.72289157 C3.95381352,9.38353588 4.5040178,9.38353588
        4.84337349,9.72289157 L9.43373494,14.313253 L20.1325301,14.313253 C20.6116205,14.313253
        21,14.7016325 21,15.1807229 L21,15.1807229 C21,15.6598133 20.6116205,16.0481928
        20.1325301,16.0481928 L20.060241,16.0481928 Z M10.3012048,11.6385542 L12.4985952,9.00168572
        C12.8017829,8.63786057 13.3425036,8.58870414 13.7063287,8.89189177 C13.757453,8.93449536
        13.8034441,8.98290543 13.8433735,9.03614458 L16.0120482,11.7831325 L18.686747,11.7831325
        C19.1927711,11.7831325 19.5542169,12.1445783 19.5542169,12.6506024 C19.5542169,13.1566265
        19.1927711,13.5180723 18.686747,13.5180723 L10.0843373,13.5180723 L9.43373494,13.2289157
        L5.96385542,9.8313253 C5.6746988,9.46987952 5.6746988,8.89156627 5.96385542,8.53012048
        C6.3253012,8.24096386 6.90361446,8.24096386 7.19277108,8.53012048 L10.3012048,11.6385542 Z
        M13.7710843,11.7831325 L13.1204819,10.9879518 L12.4698795,11.7831325 L13.7710843,11.7831325
        Z M4.95180723,8.45783133 C4.27309585,8.45783133 3.72289157,7.90762704 3.72289157,7.22891566
        C3.72289157,6.55020428 4.27309585,6 4.95180723,6 C5.63051861,6 6.18072289,6.55020428
        6.18072289,7.22891566 C6.18072289,7.90762704 5.63051861,8.45783133 4.95180723,8.45783133 Z"
		/>
	</SvgIconContainer>
);