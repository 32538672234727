// @flow
import Cookies from "js-cookie";

export const logout = () => {
	Cookies.remove("session");

	// Reload URL to let app decide what should happen next when cookie doesn't exist
	// window.location.reload();
};

export default logout;