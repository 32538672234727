// @flow
import styled from "styled-components";
import FieldRowLabel from "./fieldRowLabel";

export const BenchLabel = styled(FieldRowLabel)`
	position: absolute;
	top: 50%;
	right: 0;
	z-index: 1;
	transform: translateY(-50%);
	@media screen and (max-width: 1240px) {
		right: 18px;
	}
	> div {
		transform: rotate(-90deg);
	}
`;

export default BenchLabel;