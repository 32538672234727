// @flow
import * as React from "react";
import styled from "styled-components";
import _ from "lodash";
// import { below, above } from '../../assets/css/media';
// import colors from '../../assets/css/colors';
import ButtonLoadMore from "../ButtonLoadMore";
import ClassicRankings from "../ClassicRankings";
import type {TPLeague, TUser, TRound} from "../../modules/types";

const TAB_COLS = [
	"rank", "rank_change", "avatar", "name", "points_for_selected_round",  
	"points_average_selected_round", "points_last_round_selected_round"
];
const MOB_COLS = [
	"rank", "avatar", "name", 
	"points_for_selected_round", "points_average_selected_round", 
	"points_last_round_selected_round"
];

const LadderWrapper = styled.div``;

const OFFSET_STEP = 100;

type Props = {
	compact?: boolean,
	user: TUser,
	ladder_teams_ordered: Array<Object>,
	rounds_by_id: {
		[round: number | string]: TRound
	},
	selected_round_id: number,
	league: TPLeague,
	league_current_round_fixtures: Array<Array<number>>,
	start_with: number,
	show_load_more: boolean,
	loadMore: Function,
	user_at_bottom: boolean,
}

type TSortOrder = {
	by: string,
	direction: 'ASC' | 'DESC'
}

type State = {
	order: TSortOrder,
	showing_teams: number,
	offset: number
}

export class ClassicLadderOpen extends React.Component<Props, State> {
	static defaultProps = {
		start_with: 20,
		show_load_more: true,
		loadMore: () => null,
		user_at_bottom: false
	};
	constructor(props: Props) {
		super(props);

		_.bindAll(this, [
			"updateOrdering",
			"loadMore"
		]);
	}

	state = {
		filters: {
			team_state: 0,
			squad_id: 0,
		},
		order: {
			by: "rank",
			direction: "ASC",
		},
		showing_teams: this.props.start_with,
		offset: 0
	};

	loadMore() {
		const { loadMore, ladder_teams_ordered } = this.props;
		const { showing_teams } = this.state;
		let { offset } = this.state;

		if (showing_teams + 20 > _.size(ladder_teams_ordered)) {
			offset += OFFSET_STEP;
			loadMore();
		}

		this.setState({
			showing_teams: showing_teams + 20,
			offset
		});
	}
	updateOrdering(new_by: string) {
		const { order: current_order } = this.state;

		if (current_order.by === new_by) {
			const new_dir = current_order.direction === "ASC" ? "DESC" : "ASC";
			this.setState({
				order: {
					...current_order,
					direction: new_dir
				}
			});
		}
		else {
			const new_dir = new_by === "rank" ? "ASC" : "DESC";

			this.setState({
				order: {
					direction: new_dir,
					by: new_by,
				}
			});
		}
	}

	get show_load_more() {
		const { ladder_teams_ordered, show_load_more } = this.props;
		const { showing_teams } = this.state;
		return show_load_more && showing_teams <= _.size(ladder_teams_ordered);
	}
	get display_teams() {
		const { ladder_teams_ordered, user, start_with, user_at_bottom } = this.props;
		const { showing_teams } = this.state;

		const user_team_index = _.findIndex(ladder_teams_ordered, { user_id: user.id });

		let teams = _.take(ladder_teams_ordered, showing_teams);

		if (user_team_index !== -1 && user_team_index > start_with) {
			const user_team = ladder_teams_ordered[user_team_index];
			teams = _.reject(teams, { user_id: user.id });

			if (user_at_bottom) {
				teams = [
					...teams,
					user_team
				];
			}
			else {
				teams = [
					user_team,
					...teams,
				];
			}
		}

		return teams;
	}
	get ladder_stats() {
		const { selected_round_id, rounds_by_id } = this.props;

		const round_over = _.get(rounds_by_id, [selected_round_id, "status"]) === "complete";

		const round_id = round_over 
			? selected_round_id 
			: selected_round_id - 1;

		const last_round_key = round_over ? "Round Points" : "Points Last Round";

		return [
			{ 
				key: "Total Points", 
				short_key: "Total",
				prop: "points_for_selected_round",
				sort_key: "" 
			 },
			{ 
				key: "Average Points", 
				short_key: "Avg",
				prop: "points_average_selected_round", 
				sort_key: ""
			},
			{ 
				key: last_round_key,
				short_key: "Last",
				prop: `league_scoreflow.${round_id}`,
				rel: "points_last_round_selected_round",
				sort_key: "" 
			},
			{ 
				key: "Overall Ranking",
				short_key: "Ovr Rank",
				prop: `overall_rank_history.${round_id}`,
				rel: "overall_rank",
				sort_key: "" 
			},
			// { 
			// 	key: 'Value',
			// 	short_key: 'Value',
			// 	prop: 'value', 
			// 	format: '$0,0',
			// 	sort_key: 'team_value',
			// }
		];
	}
	renderRankingsTable() {
		const {
			selected_round_id,
			rounds_by_id,
			user
		} = this.props;

		const {
			order
		} = this.state;

		return <ClassicRankings 
			ladder_teams_ordered={this.display_teams}
			rounds_by_id={rounds_by_id}
			selected_round_id={selected_round_id}
			user_id={user.id}
			ladder_stats={this.ladder_stats}
			updateSortBy={this.updateOrdering}
			order={order}
			mobile_columns={MOB_COLS}
			tablet_columns={TAB_COLS}
			is_ladder
		/>;
	}
	renderLoadMore() {
		return this.show_load_more 
			&& <ButtonLoadMore onClick={this.loadMore}>Load More</ButtonLoadMore>;
	}

	render() {
		return <LadderWrapper>
			{ this.renderRankingsTable() }
			{ this.renderLoadMore() }
		</LadderWrapper>;
	}
}

export default ClassicLadderOpen;