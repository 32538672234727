// @flow
import { get, isEmpty } from "lodash";
import * as React from "react";

import {
	TableGrid,
	TableFlexBody
} from "../../../../components";
import JoinLeagueRow from "./JoinLeagueRow";

type Props = {
	leagues_list: Array<Object>,
	handleLeagueJoinPopup: Function,
	handleLeagueSettingsPopup: Function,
	join: Function
}

export const JoinLeagueList = ({
	leagues_list, handleLeagueJoinPopup, handleLeagueSettingsPopup, join
}: Props) => {
	if(isEmpty(leagues_list) || isEmpty(leagues_list[0])){
		return null;
	}
	return(
		<TableGrid>
			<TableFlexBody>
				{leagues_list.map(league => {
					const leagueID = get(league, 'id', 0);
					return(
						<JoinLeagueRow key={leagueID}
							league={league}
							join={join}
							handleLeagueSettingsPopup={handleLeagueSettingsPopup}
							handleLeagueJoinPopup={handleLeagueJoinPopup}
						/>
					);
				} )}
			</TableFlexBody>
		</TableGrid>
	
	);
};

export default JoinLeagueList;