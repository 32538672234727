// @flow
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';

export const getWaiverRequestsByTeamId = (state: Object) => {
	const { by_id = {}, ordered_ids = [] } = get(state, 'leagues.waiver_requests', {});

	return groupBy(ordered_ids.map(id => by_id[id]), 'team_id');
};

export default getWaiverRequestsByTeamId;