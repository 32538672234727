// need to upgrade flow to support enum
export const AFLIdModalStatus = {
	EmailFound: "emailFound",
	EmailNotFound: "emailNotFound",
	HasPlayedBeforeRego: "hasPlayedBeforeRego",
	IncorrectEmailDetails: "incorrectEmailDetails",
	Info: "info",
	LinkOldEmail: "linkOldEmail",
	NotPlayedBeforeRego: "notPlayedBeforeRego",
	PasswordReset: "passwordReset",
	Off: "off",
};

export type TAFLIdModalTypes = AFLIdModalStatus.EmailFound |
    AFLIdModalStatus.EmailNotFound |
    AFLIdModalStatus.HasPlayedBeforeRego |
    AFLIdModalStatus.IncorrectEmailDetails |
    AFLIdModalStatus.Info |
    AFLIdModalStatus.LinkOldEmail |
    AFLIdModalStatus.NotPlayedBeforeRego |
    AFLIdModalStatus.PasswordReset |
    AFLIdModalStatus.Off;

export type TAFLIdModalStatus = {
	currentAFLiDModal: TAFLIdModalTypes
}