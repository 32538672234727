// @flow
import _, { get } from "lodash";
import type { Saga } from "redux-saga";
import Cookies from "js-cookie";

import { call, put, takeLatest, takeEvery, select } from "redux-saga/effects";
import * as actions from "../../actions/index";
import { Api } from "../../../utils/index";
import { TAFLiDAuthCode } from "../../types";
import { AFLIdModalStatus } from "../../types/store/aflIdModalReducer";
import { getAfliDIdentityToken } from "../../selectors";
import { getLogoutParams } from "../../../helpers";

const checkErrorsIn = errors => {
	if (_.size(errors)) {
		throw new Error(_.get(_.first(errors), "text", "Unknown error"));
	}
};
const addSessionCookie = session_id => {
	Cookies.set("session", session_id, { expires: 90 });
};

const removeSessionCookie = () => {
	Cookies.remove("session");
};

type PostActionType = {
	type: string,
	payload: {
		login: string,
		password: string,
		redirect_url: string
	}
};

type TokenLogin = {
	type: string;
	payload: {
		token: string
	}
}

type LinkAflIDAccount = {
	type: string;
	payload: {
		type: string,
		identity_token: string,
		recovery_token: string
	}
}

type AflIDCheckCredentials = {
	type: string;
	payload: {
		login: string,
		password: string,
		noFollowThrough?: boolean
	}
}

type AuthCodeAfliD = {
	type: string;
	payload: TAFLiDAuthCode
}

type AppleLogin = {
	type: String,
	payload: {
		code: string,
		id_token: string,
		state: string
	}
}

export const createSagas = (API: Object): Object => {
	const loginSaga = function* loginSaga(
		action: PostActionType
	): Saga<void> {
		try {
			const { login, password } = action.payload;
			const {
				result: { user, session_id },
				errors,
				success
			} = yield call(Api.Auth.login, { login, password });
	
			if (!success) {
				throw new Error(errors[0].text);
			}
	
			addSessionCookie(session_id);
			yield put(actions.loginSuccess({ user, session_id }));
		}
		catch (error) {
			console.log(error);
			removeSessionCookie();
			yield put(actions.loginFailed(error.message));
		}
	};
	
	const authCodeAfliDSaga = function* authCodeAfliDSaga(
		action: AuthCodeAfliD
	): Saga<void> {
		try {
			const {
				code,
				code_verifier,
				nonce,
				redirect_url
			} = action.payload;
			const auth_code = yield call(Api.Auth.auth_code_aflid, {
				code,
				code_verifier,
				nonce,
				redirect_url
			});
			if (!auth_code.success) {
				throw new Error(auth_code.errors[0].text);
			}
			yield put(actions.authCodeSuccess(auth_code.result));
			localStorage.setItem("idt", auth_code.result.identity_token);
			yield put(actions.emailCheck());
			const email_check = yield call(Api.Auth.auth_email_check, { 
				identity_token:auth_code.result.identity_token,
			});
			if (!email_check.success) {
				yield put(actions.emailCheckFailed(email_check.errors[0].text));
				throw new Error(email_check.errors[0].text);
			}
			yield put(actions.emailCheckSuccess(email_check.result));
			if(email_check.result.session_id){
				addSessionCookie(email_check.result.session_id);
			}
			const afl_account_id = get(email_check, 'result.user.afl_account_id', 0);
			if(afl_account_id !== 0){
				yield put(actions.aflAccountIdPresent(afl_account_id));
			}
		}
		catch (error) {
			console.log(error);
			removeSessionCookie();
			yield put(actions.loginFailed(error.message));
		}
	};

	const linkAccountLoginSaga = function* linkAccountLoginSaga(
		action: {payload: {email: string, password: string}}
	): Saga<void> {
		try {
			const response = yield call(Api.Auth.link_account_login, action.payload);
			if(!response.success){
				throw new Error(response.errors[0].text); 
			}
			yield put(actions.linkAccountLoginSuccess(response.result));
		} 
		catch (err) {
			yield put(actions.linkAccountLoginFailed(err));
		}
	};

	const linkNewAccountSaga = function* linkNewAccountSaga(
		action: {payload: {email: string, password: string}}
	): Saga<void> {
		try {
			const response = yield call(Api.Auth.auth_link_new_account, action.payload);
			if(!response.success){
				throw new Error(response.errors[0].text);
			}
			yield put(actions.linkNewAccountSuccess(response.result));
		} 
		catch (err) {
			yield put(actions.linkAccountLoginFailed(err));
		}
	};

	const ultimateFootyEmailCheckSaga = function* ultimateFootyEmailCheckSaga(
		action
	): Saga<void> {
		try {	
			const response = yield call(Api.User.uf_email_check, action.payload);
			if(!response.success){
				throw new Error(response.errors[0].text);
			}
			yield put(actions.ultimateFootyEmailCheckSuccess(response.result));
		} 
		catch (err) {
			console.log(err, "ERR");
			yield put(actions.ultimateFootyEmailCheckFailed(err));
		}
	};

	const ultimateFootyEmailPasswordCheckSaga = function* ultimateFootyEmailPasswordCheckSaga(
		action: {payload: {email: string, password: string}}
	): Saga<void> {
		try {
			const response = yield call(Api.User.uf_email_pw_check, action.payload);
			if(!response.success){
				throw new Error(response.errors[0].text);
			}
			yield put(actions.ultimateFootyEmailPasswordCheckSuccess(response.result));
		} 
		catch (err) {
			yield put(actions.ultimateFootyEmailPasswordCheckFailed(err));
		}
	};

	const ultimateFootyLinkAccountSaga = function* ultimateFootyLinkAccountSaga(
		action: {payload: {email: string, password: string}}
	): Saga<void> {
		try {
			const response = yield call(Api.User.uf_link_account, action.payload);
			if(!response.success){
				throw new Error(response.errors[0].text);
			}
			yield put(actions.ultimateFootyLinkAccountSuccess(response.result));
		} 
		catch (err) {
			yield put(actions.ultimateFootyLinkAccountFailed(err));
		}
	};

	const loginTokenSaga = function* loginTokenSaga(
		action: TokenLogin
	): Saga<void> {
		try {
			const { token } = action.payload;
			const {
				result: { user, sid },
				errors,
				success
			} = yield call(Api.Auth.login_token, { token });
	
			if (!success) {
				throw new Error(errors[0].text);
			}
	
			addSessionCookie(sid);
			yield put(actions.loginSuccess({ user, session_id: sid }));
		}
		catch (error) {
			console.log(error);
			removeSessionCookie();
			yield put(actions.loginFailed(error.message));
		}
	};

	const authCheckCredentialsSaga = function* authCheckCredentialsSaga(
		action: AflIDCheckCredentials
	): Saga<void> {
		try {
			const { login, password } = action.payload;
			const check_credentials = yield call(Api.Auth.check_credentials, { login, password });
			const identity_token = yield select(getAfliDIdentityToken);
			if (!check_credentials.success) {
				throw new Error(check_credentials.errors[0].text);
			}
	 		// add to same reduceer effect as email check
			yield put(actions.authCheckCredentialsSuccess(check_credentials.result));
			if(action.payload.noFollowThrough){
				return;
			}
			yield put(actions.authLinkAccount());
			const auth_link_account = yield call(Api.Auth.auth_link_account, { 
				type:"recovery_token",
				identity_token,
				recovery_token:check_credentials.result.recovery_token
			});
			if (!auth_link_account.success) {
				yield put(actions.authLinkAccountFailed(auth_link_account.errors[0].text));
				throw new Error(auth_link_account.errors[0].text);
			}
			yield put(actions.authLinkAccountSuccess(auth_link_account.result));
		}
		catch (error) {
			yield put(actions.setAflIdModalStatus(AFLIdModalStatus.IncorrectEmailDetails));
			yield put(actions.authCheckCredentialsFailed(error.message));
		}
	};

	const authLinkAccountSaga = function* authLinkAccountSaga(
		action: LinkAflIDAccount
	): Saga<void> {
		try {
			const { type, identity_token, recovery_token } = action.payload;
			const {
				result,
				errors,
				success
			} = yield call(Api.Auth.auth_link_account, { 
				type,
				identity_token,
				recovery_token
			});
	
			if (!success) {
				throw new Error(errors[0].text);
			}
	
			yield put(actions.authLinkAccountSuccess(result));
		}
		catch (error) {
			yield put(actions.authLinkAccountFailed(error.message));
		}
	};

	const loginAppleIdSaga = function* loginAppleIdSaga(
		action: AppleLogin
	): Saga<void> {
		try {
			const payload = {
				identity_token: action.payload.id_token,
			};

			const {
				result: { user, session_id },
				errors,
				success
			} = yield call(Api.Auth.login, { ...payload });
			if (!success) {
				throw new Error(errors[0].text);
			}
			addSessionCookie(session_id);
			yield put(actions.loginSuccess({ user, session_id }));
		} 
		catch (err) {
			console.log(err);
			removeSessionCookie();
			yield put(actions.loginFailed(err.message));
		}
	};
	
	const preregistrationSaga = function* preregistrationSaga(
		action: PostActionType
	): Saga<void> {
		try {
			const {
				// result,
				errors,
				success,
			} = yield call(Api.Auth.preregistration, action.payload);
	
	
			if (!success) {
				throw new Error(errors[0].text);
			}
	
			yield put(actions.preregistrationSuccess());
		}
		catch (error) {
			console.log(error);
			yield put(actions.preregistrationFailed(error.message));
		}
	};
	
	type CheckEmailType = {
		type: string,
		payload: {
			email: string
		},
	};
	
	const checkEmailSaga = function* checkEmailSaga(action: CheckEmailType): Saga<void> {
		try {
			const email = action.payload ;
			const result = yield call(Api.User.check_email, { email });
			const { success, errors } = result;
			// reversed logic here because 510 means its a valid user as they exist
			if(success){
				yield put(actions.checkEmailFailed("User does not exist"));
				throw new Error("User does not exist");
			}
			if(errors[0].code === 510){
				yield put(actions.checkEmailSuccess());
			}
			
	
		} 
		catch (err) {
			console.log(err);
		}
	};
	
	const logoutSaga = function* logoutSaga(): Saga<void> {
		try {
			const {
				errors,
				success
			} = yield call(Api.Auth.logout);
			const id_token =  yield select(getAfliDIdentityToken) || localStorage.getItem("idt");
			
			
			if (!success) {
				throw new Error(errors[0].text);
			}
			
			removeSessionCookie();
			yield put(actions.logoutSuccess());
			if(id_token){
				const logoutURL = 
					`${process.env.REACT_APP_SSO_URL || ""}/logout${getLogoutParams(id_token)}`;
				window.location.assign(logoutURL);
			}
		}
		catch (error) {
			console.log(error);
			removeSessionCookie();
			yield put(actions.loginFailed(error.message));
		}
	};
	
	type LoginFBType = {
		type: string,
		payload: {
			token: string,
		}
	};
	
	const loginFBSaga = function* loginFBSaga(
		action: LoginFBType
	): Saga<void> {
		try {
			const { token } = action.payload;
			const {
				result: { user, session_id },
				errors,
				success
			} = yield call(Api.Auth.login, { token });
	
			if (!success) {
				throw new Error(errors[0].text);
			}
	
			addSessionCookie(session_id);
			yield put(actions.loginSuccess({ user, session_id, is_fb_login: true }));
		}
		catch (error) {
			console.log(error);
			yield put(actions.loginFailed(error.message));
		}
	};
	
	const facebookConnectSaga = function* loginFBSaga(
		action: LoginFBType
	): Saga<void> {
		try {
			const { token } = action.payload;
			const {
				errors,
				result
			} = yield call(Api.Auth.facebook_connect, { token });
	
			if (errors.length) {
				yield put(actions.facebookConnectFailed({ message: errors[0].text }));
			}
			else {
				yield put(actions.facebookConnectSuccess(result));
			}
		}
		catch (error) {
			console.log(error);
		}
	};
	
	type ForgotPasswordActionType = {
		type: string,
		payload: {
			email: string,
		}
	};
	
	const forgotPasswordSaga = function* forgotPasswordSaga(
		action: ForgotPasswordActionType
	): Saga<void> {
		try {
			const { email } = action.payload;
			const { result, errors } = yield call(Api.Auth.forgot_password, { email });
	
			if (errors.length) {
				yield put(actions.forgotPasswordFailed({ message: errors[0].text }));
			}
			else {
				yield put(actions.forgotPasswordSuccess(result));
			}
		}
		catch (event) {
			console.log(event);
		}
	};
	
	type ChangePasswordActionType = {
		type: string,
		payload: {
			password: string,
			token: string,
		}
	};
	
	const changePasswordSaga = function* changePasswordSaga(
		action: ChangePasswordActionType
	): Saga<void> {
		try {
			const { password, token } = action.payload;
			const changePasswordRes =  yield call(
				Api.Auth.change_password, { password, token }
			);
			const id_token = localStorage.getItem("idt");
			if (changePasswordRes.errors.length) {
				yield put(actions.changePasswordFailed({
					message: changePasswordRes.errors[0].text
				}));
			}
			else {
				yield put(actions.changePasswordSuccess(changePasswordRes.result));
				if(id_token) {
					yield put(actions.addLocalIdToken(id_token));
					yield put(actions.authCheckCredentials({
						login:changePasswordRes.result.user.email,
						password
					}));
				}
			}
		}
		catch (event) {
			console.log(event);
		}
	};
	
	type createUser = {
		payload: Object
	};
	
	const createUserSaga = function* createUserSaga(
		action: createUser
	): Saga<void> {
		try {
			const {
				result,
				errors
			} = yield call(Api.User.create_user, action.payload);
	
			if (errors.length) {
				yield put(actions.loginFailed({ message: errors[0].text }));
			}
			else {
				addSessionCookie(result.session_id);
				yield put(actions.loginSuccess(result));
			}
		}
		catch (event) {
			console.log(event);
		}
	};

	type aflIDCreateUser = {
		payload: Object
	};
	const aflIDCreateUserSaga = function* aflIDCreateUserSaga(
		action: aflIDCreateUser
	): Saga<void> {
		try {
			const {
				result,
				errors
			} = yield call(Api.User.aflid_create_user, action.payload);
	
			if (errors.length) {
				yield put(actions.loginFailed({ message: errors[0].text }));
			}
			else {
				addSessionCookie(result.session_id);
				yield put(actions.loginSuccess(result));
				
			}
		}
		catch (event) {
			console.log(event);
		}
	};
	
	const updateUserSaga = function* updateUserSaga(
		action: createUser
	): Saga<void> {
		try {
			const { result, errors } = yield call(Api.User.update_user, action.payload);
	
			if (errors.length) {
				yield put(actions.updateUserFailed({ message: errors[0].text }));
			}
			else {
				yield put(actions.updateUserSuccess(result));
			}
		}
		catch (event) {
			console.log(event);
		}
	};
	
	type activateUser = {
		payload: Object
	};
	
	const activateUserSaga = function* activateUserSaga(
		action: activateUser
	): Saga<void> {
		try {
			const { result, errors, success } = yield call(Api.User.activate_user, action.payload);
	
			if (!success) {
				throw new Error(errors[0].text);
			}
	
			yield put(actions.activateUserSuccess(result));
			
		}
		catch (error) {
			console.log(error);
			yield put(actions.activateUserFailed({ message: error.message }));
		}
	};
	
	type FetchUserAction = {
		payload: Object
	};
	
	function* fetchUserSaga(action: FetchUserAction): Saga<void> {
		try {
	
			const {
				result: user,
				errors
			} = yield call(Api.User.get);
	
			if (errors.length) {
				throw errors[0];
			}
	
			yield put(actions.fetchUserSuccess(user));
		}
		catch (error) {
			console.log(error);
			yield put(actions.fetchUserFailed({ ...error }));
		}
	}
	
	const subscribeUserSaga = function* subscriptionUserSaga(
		action: { payload: Object }
	): Saga<void> {
		try {
			const { result, errors } = yield call(Api.User.subscribe_user, action.payload);
	
			if (errors.length) {
				yield put(actions.subscribeUserFailed({ message: errors[0].text }));
			}
			else {
				yield put(actions.subscribeUserSuccess(result));
				const {
					token,
					plan
				} = action.payload;
				const costObj = {
					"trial": 0,
					"month": 4.99,
					"season": 19.99
				};
				window.gtag("event", "purchase", {
					transaction_id: token,
					affiliation: "Fantasy Web | Coach Subscription",
					value: _.get(costObj, `[${plan}]`, 0),
					currency: "AUD",
					items: [
						{
							id: plan,
							name: `AFL Fantasy Coach - ${plan}`,
							category: "user"
						}
					]
				});
			}
		}
		catch (event) {
			console.log(event);
		}
	};
	
	const unsubscribeUserSaga = function* unsubscribeUserSaga(action: Object): Saga<void> {
		try {
			const { result, errors } = yield call(Api.User.unsubscribe_user);
	
			if (errors.length) {
				yield put(actions.unsubscribeUserFailed({ message: errors[0].text }));
			}
			else {
				yield put(actions.unsubscribeUserSuccess(result));
			}
		}
		catch (event) {
			console.log(event);
		}
	};
	
	const fetchUserSubscriptionSaga = function* fetchUserSubscriptionSaga(
		action: { payload: Object }
	): Saga<void> {
		try {
			const { result, errors } = yield call(Api.User.subscription, action.payload);
	
			if (errors.length) {
				yield put(actions.fetchUserSubscriptionFailed({ message: errors[0].text }));
			}
			else {
				yield put(actions.fetchUserSubscriptionSuccess(result));
			}
		}
		catch (event) {
			console.log(event);
		}
	};
	
	const deactivateUserSaga = function* deactivateUserSaga(
		action: { payload: Object }
	): Saga<void> {
		try {
			const { result, errors } = yield call(Api.User.deactivate);
	
			if (errors.length) {
				yield put(actions.deactivateUserFailed({ message: errors[0].text }));
			}
			else {
				yield put(actions.deactivateUserSuccess(result));
			}
		}
		catch (event) {
			console.log(event);
		}
	};
	function* fetchUserHistorySaga(action: { payload: Object }): Saga<void> {
		try {
			const { result, errors } = yield call(Api.User.all_seasons_history);
			checkErrorsIn(errors);
	
			yield put(actions.fetchUserHistorySuccess(result));
		}
		catch (event) {
			yield put(actions.fetchUserHistoryFailed(event.message));
	
			console.log(event);
		}
	}
	function* watch() {
		yield takeLatest(actions.fetchUser, fetchUserSaga);
		yield takeLatest(actions.login, loginSaga);
		yield takeLatest(actions.authCodeAfliD, authCodeAfliDSaga);
		yield takeLatest(actions.authLinkAccount, authLinkAccountSaga);
		yield takeLatest(actions.authCheckCredentials, authCheckCredentialsSaga);
		yield takeEvery(actions.loginToken, loginTokenSaga);
		yield takeLatest(actions.linkAccountLogin, linkAccountLoginSaga);
		yield takeLatest(actions.linkNewAccount, linkNewAccountSaga);
		yield takeLatest(actions.appleIdLogin, loginAppleIdSaga);
		yield takeLatest(actions.logout, logoutSaga);
		yield takeLatest(actions.forgotPassword, forgotPasswordSaga);
		yield takeLatest(actions.changePassword, changePasswordSaga);
		yield takeLatest(actions.loginFB, loginFBSaga);
		yield takeLatest(actions.createUser, createUserSaga);
		yield takeLatest(actions.aflIDCreateUser, aflIDCreateUserSaga);
		yield takeLatest(actions.updateUser, updateUserSaga);
		yield takeLatest(actions.activateUser, activateUserSaga);
		yield takeLatest(actions.subscribeUser, subscribeUserSaga);
		yield takeLatest(actions.facebookConnect, facebookConnectSaga);
		yield takeLatest(actions.fetchUserSubscription, fetchUserSubscriptionSaga);
		yield takeLatest(actions.unsubscribeUser, unsubscribeUserSaga);
		yield takeLatest(actions.deactivateUser, deactivateUserSaga);
		yield takeLatest(actions.fetchUserHistory, fetchUserHistorySaga);
		yield takeLatest(actions.preregistration, preregistrationSaga);
		yield takeLatest(actions.checkEmail, checkEmailSaga);
		yield takeLatest(actions.ultimateFootyEmailCheck, ultimateFootyEmailCheckSaga);
		yield takeLatest(actions.ultimateFootyEmailPasswordCheck, 
			ultimateFootyEmailPasswordCheckSaga);
		yield takeLatest(actions.ultimateFootyLinkAccount, ultimateFootyLinkAccountSaga);
	}
	return {
		fetchUserSaga,
		loginSaga,
		logoutSaga,
		forgotPasswordSaga,
		changePasswordSaga,
		loginFBSaga,
		createUserSaga,
		updateUserSaga,
		activateUserSaga,
		subscribeUserSaga,
		facebookConnectSaga,
		fetchUserSubscriptionSaga,
		unsubscribeUserSaga,
		deactivateUserSaga,
		fetchUserHistorySaga,
		preregistrationSaga,
		checkEmailSaga,
		watch,
	};
};


export function* userRootSaga(): Saga<void> {
	
}