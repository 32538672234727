// @flow
import React from "react";
import styled from "styled-components";
import _, { get } from "lodash";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";

import { coach_player_stats } from "../../../helpers/stats";

import {
	Ad,
	AdsContainer,
	Footer,
	MatchCentreBar,
	OneColumnLayout,
	PageTitle,
	PlayersOnTrade,
	PlayersTable,
	TeamForTrade,
	TeamToTeamTrade,
	withDraftPlayerModal,
	LeagueGameBar,
	Preloader,
} from "../../../components";


import JsonFetcher from "../../../components/utils/JsonFetcher";

import type {
	TCoachPlayerStatsById,
	TCoachStatsListReducer,
	TPlayer,
	TPlayerStatusesReducer,
	TPosition,
	TRootStore,
	TRound,
	TSquad,
	TTeamsDraftTradesReducer,
	TPLeague,
	TDraftTeamsById,
	TDraftUserTeam
} from "../../../modules/types";

import * as selectors from "../../../modules/selectors";
import * as actions from "../../../modules/actions";
import withCoachesBox from "../../../components/utils/withCoachesBox";
import { below } from "../../../assets/css/media";
import { isAllTrue } from "../../../helpers";


const basic_stats = (round_id, statsBasis) => ([
	{ key: "GP", prop: `${statsBasis}.games_played`, tooltip: "Games Played" },
	{ key: "AVG", prop: `${statsBasis}.avg_points`, tooltip: "Average Points" },
	{ key: "TP", prop: `${statsBasis}.total_points`, tooltip: "Total Points" },
	{ key: "Last RD", prop: `${statsBasis}.scores[${round_id}]`, tooltip: "Score Last Round" },
	{ key: "L3", prop: `${statsBasis}.last_3_avg`, tooltip: "Average over last three games" },
	{ key: "L5", prop: `${statsBasis}.last_5_avg`, tooltip: "Average over last five games" },
	{ key: "ADP", prop: "stats.adp",tooltip: "Average Draft Position" },
	{ key: "PROJ", prop: statsBasis === "custom_stats" ? "custom_stats.proj_score_next_round" 
		: "stats.proj_score", tooltip: "Projected Score", is_fc: true },
	{
		key: "TS%",
		prop: "stats.leagues_rostered",
		tooltip: "Percentage of teams who own player",
		is_fc: false
	},
]);

type TableColumns = Array<{ key: string, prop: string, tooltip?: string }>;

type Props = {
	match: {
		params: {
			league_id: string,
			action: string,
			type: string,
			player_id: string
		}
	},
	league: TPLeague,
	teams_by_id: TDraftTeamsById,
	players: Array<TPlayer>,
	actual_round: Object,
	showDraftLeague: typeof actions.leagueDraft.showDraftLeague,
	fetchTeams: typeof actions.leagueDraft.showTeamsDraftLeague,
	fetchCoachPlayers: typeof actions.fetchCoachPlayers,
	fetchWaiverList: typeof actions.leagueDraft.fetchWaiverList,
	fetchWaiverFree: typeof actions.leagueDraft.fetchWaiverFree,
	fetchFavourites: typeof actions.fetchFavourites,
	fetchTeam: typeof actions.teamsDraft.fetchTeam,
	fetchAllCustomStats: typeof actions.fetchAllCustomStats,
	fetchAllCoachCustomStats: typeof actions.fetchAllCoachCustomStats,
	is_pending_players: boolean,
	positions: Array<TPosition>,
	squads: Array<TSquad>,
	playerStatuses: TPlayerStatusesReducer,
	table: {
		basic: TableColumns,
		coach: Object,
	},
	coach_stats_list: TCoachStatsListReducer,
	has_assistant_coach: boolean,
	coach_players_by_id: TCoachPlayerStatsById,
	waiver_requests: string,
	current_user_id: number,
	last_round: TRound,
	data_loading_complete: boolean,
	user_team: TDraftUserTeam,
	trade_id?: number,
	history: {
		push: Function
	},
	playerIDsInLineup: Array<number>
}

type TFilters = {
	search: string,
	by_squad: string,
	by_position: string,
	by_status: string,
	by_dpp: boolean,
	by_favourites: boolean,
	by_drafted: boolean,
	by_fa: boolean
}

type State = {
	filters: TFilters,
	sort_by?: string,
	order_by_desc?: boolean,
	players_show_limit?: number,
	players_show_limit_step?: number,
	player_row_menu_ids: Array<number>,
	is_coach_active: boolean,
	coach_type: string,
	fa_trade: {
		new_player_id: number,
		old_player_id: number
	},
	team_trade: TTeamsDraftTradesReducer,
	is_fa_trade: boolean,
	is_team_trade: boolean,
	show_team: boolean,
	data_load_initial: boolean,
};

const StyledOneColumnLayout = styled(OneColumnLayout)`
	${below.tablet`
		margin-top: 0;
	`}
`;

class PlayersComponent extends React.Component<Props, State> {

	static defaultProps = {
		players: [],
		last_round: {},
		table: {
			basic: basic_stats(1, "stats"),
			coach: coach_player_stats("stats", 0, false),
			pre_draft_list_size: 0,
		},
		waiver_requests: ""
	};

	constructor(props, state) {
		super(props, state);

		this.unmounted = false;
		this.should_make_trade = false;

		_.bindAll(this, [
			"addOutTrade",
			"addInTrade",
			"cancelTradeRequest",
			"removeOutPlayer",
			"removeInPlayer",
			"reFetchData",
		]);
	}

	state = {
		players_show_limit: 50,
		players_show_limit_step: 20,
		sort_by: "stats.proj_avg",
		order_by_desc: true,
		player_row_menu_ids: [],
		filters: {
			search: "",
			by_squad: "",
			by_position: "",
			by_status: "",
			by_dpp: false,
			by_favourites: false,
			by_drafted: false,
			by_fa: false
		},
		is_coach_active: false,
		coach_type: "coaches-choice",
		fa_trade: {
			new_player_id: 0,
			old_player_id: 0
		},
		team_trade: {
			new_player_id: [],
			old_player_id: [],
			owner_id: 0
		},
		is_fa_trade: false,
		is_team_trade: false,
		show_team: false,
		data_load_initial: false,
	};

	componentDidMount() {
		const {
			showDraftLeague,
			fetchTeams,
			fetchCoachPlayers,
			fetchWaiverList,
			fetchWaiverFree,
			fetchFavourites,
			fetchTeam,
			match: { params: { league_id, action, type, player_id } },
			has_assistant_coach,
		} = this.props;

		fetchTeam({ league_id });

		showDraftLeague({ id: league_id });
		this.getPlayerStats();
		fetchTeams({
			league_id: league_id,
			details: 1
		});

		fetchWaiverList({
			league_id
		});
		fetchWaiverFree({
			league_id
		});
		fetchFavourites();

		if(has_assistant_coach) {
			fetchCoachPlayers();
		}

		if(action === "team-to-team-trade") {
			this.setState({ is_team_trade: true });
		}

		this.should_make_trade = Boolean(type && player_id);
	}

	shouldComponentUpdate(next_props) {
		return next_props.data_loading_complete;
	}


	componentDidUpdate(prev_props: Props) {
		const { 
			match, 
			league, 
		} = this.props;

		if(match.params.action !== prev_props.match.params.action) {
			window.location.reload();
		}

		if(get(prev_props, 'league.id')!== get(league, 'id')){
			this.getPlayerStats();
			this.shouldFetchCoachPlayers();
		}

		

		if(isAllTrue([
			this.hasDeferredTrade(prev_props),
			this.state.data_load_initial
		])) {
			this.makeDeferredTrade();
		}

		// T2T success redirect
		if(_.get(prev_props, "trade_id") !== _.get(this.props, "trade_id")) {
			this.props.history.push(`/draft/league/${match.params.league_id}/transactions`);
		}

		this.shouldRedirectToTeam();

		// Free agaent success redirect
		if(
			isAllTrue([
				_.get(prev_props, "waiver_requests") !== _.get(this.props, "waiver_requests"),
				_.get(this.props, "waiver_requests") === "substituted"
			])
		) {
			this.props.history.push(`/draft/league/${match.params.league_id}/team`);
		}
		// RFA redirect
		else if (_.get(prev_props, "waiver_requests") !== _.get(this.props, "waiver_requests")) {
			this.props.history.push(`/draft/league/${match.params.league_id}/transactions`);
		}
		this.isDataFinishedLoading(prev_props);
	}

	componentWillUnmount() {
		this.unmounted = true;
		this.should_make_trade = false;
	}

	ref = React.createRef();
	unmounted = false;
	should_make_trade = false;

	shouldFetchCoachPlayers() {

		const {has_assistant_coach, coach_players_by_id,fetchCoachPlayers} = this.props;
		if(isAllTrue([
			has_assistant_coach,
			_.isEmpty(coach_players_by_id)
		]) ) {
			fetchCoachPlayers();
		}
	}

	shouldRedirectToTeam(){
		const { 
			match, 
			playerIDsInLineup,  
		} = this.props;
		const type = match.params.type;
		const playerID = match.params.player_id;
		if(isAllTrue([
			type === "delist",
			!playerIDsInLineup.includes(Number(playerID))
		])) {
			this.props.history.push(`/draft/league/${match.params.league_id}/team`);
		}
	}

	isDataFinishedLoading(prev_props){
		if(isAllTrue([
			this.props.data_loading_complete,
			!prev_props.data_loading_complete,
			!this.state.data_load_initial
		]) 
		){
			this.setState({
				data_load_initial: true,
			});
		}
	}
	get component_to_show() {
		const { league, last_round, match: { params: { action } }, actual_round } = this.props;
		const { show_team, fa_trade, team_trade, is_fa_trade, is_team_trade } = this.state;
		if (is_team_trade) {
			return (
				<TeamToTeamTrade
					league={league}
					cancelTradeRequest={this.cancelTradeRequest}
					team_trade={team_trade}
				/>
			);
		}
		if (isAllTrue([show_team, is_fa_trade])) {
			return (
				<TeamForTrade
					fa={fa_trade}
					removeOutPlayer={this.removeOutPlayer}
					addOutTrade={this.addOutTrade}
				/>
			);
		}
		if(isAllTrue([
			!is_team_trade,
			!show_team,
			!is_fa_trade,
			["team-to-team-trade"].includes(action),
		])) {
			return (
				<Preloader />
			);
		}

		const statsBasis = league.custom_scoring_enabled ? "custom_stats" : "stats";
		
		return (
			<PlayersTable
				league={league}
				addOutTrade={this.addOutTrade}
				addInTrade={this.addInTrade}
				actual_round={actual_round}
				cancelTradeRequest={this.cancelTradeRequest}
				removeOutPlayer={this.removeOutPlayer}
				removeInPlayer={this.removeInPlayer}
				table={{
					basic: basic_stats(last_round.id, statsBasis)
				}}
				addOwnerFilter
				show_next
				fa={fa_trade}
			/>
		);
	}
	get show_match_bar() {
		const { match: { params: { action } } } = this.props;
		if (action === "team-to-team-trade" || action === "free-agent") {
			return null;
		}
		return <MatchCentreBar />;
	}


	getPlayerStats() {
		const {
			fetchAllCustomStats,
			fetchAllCoachCustomStats,
			league,
			match: { params: { league_id } },
			has_assistant_coach,
		} = this.props;
		if(league && Boolean(league.custom_scoring_enabled)){
			fetchAllCustomStats(league_id);
			if(has_assistant_coach){
				fetchAllCoachCustomStats(league_id);
			}
		}
	}
	hasDeferredTrade({ players: prevPlayers, match: prevMatch }) {
		const { match: { params: { type } } } = this.props;

		if(!type) {
			return false;
		}

		const
			{ players, match: { params: { player_id } } } = this.props,
			{ params: { player_id: prevPlayerId, type: prevType } } = prevMatch,
			has_players = !!_.size(players),
			trade_changed = !_.eq(prevPlayerId, player_id) || !_.eq(prevType, type);

		return (this.should_make_trade && has_players) || trade_changed;
	}

	makeDeferredTrade() {
		this.should_make_trade = false;

		const
			{ players, match: { params: { player_id, type } }, current_user_id } = this.props,
			player = _.find(players, { id: parseInt(player_id, 10) }) || {},
			{ id, owner, waiver } = player,
			is_user_team = _.eq(_.get(owner, "user_id"), current_user_id),
			trade_params = { id, is_user_team, owner, waiver, is_fa: _.eq(type, "delist") };

		this.cancelTradeRequest().then(() => (
			!this.unmounted && this.makeDeferredTradeByType(trade_params, type)
		));
	}

	makeDeferredTradeByType(trade_params, type) {
		const actionByType = {
			"delist": this.addOutTrade,
			"add": this.addInTrade,
			"trade": trade_params.is_user_team ? this.addOutTrade : this.addInTrade,
		};

		const makeTradeByType = _.get(actionByType, type, _.identity);

		makeTradeByType(trade_params);
	}

	reFetchData() {
		this.cancelTradeRequest();

		const {
			fetchTeams,
			fetchWaiverList,
			fetchWaiverFree,
			match: { params: { league_id } },
		} = this.props;

		fetchTeams({
			league_id: league_id,
			details: 1
		});

		fetchWaiverList({ league_id	});
		fetchWaiverFree({ league_id	});
	}

	addOutTrade({ id, owner, waiver, is_fa, is_user_team }) {
		const { fa_trade, is_fa_trade, filters } = this.state;

		if (is_fa_trade) {
			this.setState({
				fa_trade: {
					...fa_trade,
					old_player_id: id
				},
				is_fa_trade: true

			});
			if(fa_trade.new_player_id === 0){
				this.setState({
					show_team: false
				});
			}
		}

		if (!owner) {
			this.setState({
				fa_trade: {
					...fa_trade,
					old_player_id: id
				},
				is_fa_trade: true
			});
		}
		else if (isAllTrue([is_user_team, !is_fa])) {
			const { team_trade } = this.state;
			const { old_player_id } = team_trade;
			this.setState({
				team_trade: {
					...team_trade,
					old_player_id: _.xor(old_player_id, [id]),
					owner_id: 0
				},
				is_team_trade: true
			});
		}

		if (isAllTrue([
			is_user_team,
			is_fa
		])) {
			this.setState({
				fa_trade: {
					...fa_trade,
					old_player_id: id
				},
				filters: {
					...filters,
					by_fa: true,
				},
				is_fa_trade: true
			});
		}
	}

	addInTrade({ id, is_user_team, owner }) {
		const { fa_trade } = this.state;

		if (!owner) {
			this.setState({
				fa_trade: {
					...fa_trade,
					new_player_id: id
				},
				is_fa_trade: true,
				
			});
			if(fa_trade.old_player_id === 0){
				this.setState({
					show_team: true
				});
			}
		}

		if (owner) {
			const { team_trade } = this.state;
			const { new_player_id } = team_trade;
			this.setState({
				team_trade: {
					...team_trade,
					new_player_id: _.xor(new_player_id, [id]),
					owner_id: owner.id
				},
				is_team_trade: true
			});
		}
	}

	cancelTradeRequest() {
		return new Promise(resolve => {
			const { filters } = this.state;

			this.setState({
				fa_trade: {
					new_player_id: 0,
					old_player_id: 0
				},
				team_trade: {
					new_player_id: [],
					old_player_id: []
				},
				is_fa_trade: false,
				is_team_trade: false,
				show_team: false,
				filters: {
					...filters,
					by_fa: false
				}
			}, resolve);
		});
	}

	removeOutPlayer({ id }) {
		const { is_fa_trade, is_team_trade, fa_trade, team_trade } = this.state;
		if (is_fa_trade) {
			this.setState({
				fa_trade: {
					...fa_trade,
					old_player_id: 0
				},
				show_team: true
			});
		}
		if (is_team_trade) {
			const { old_player_id } = team_trade;
			this.setState({
				team_trade: {
					...team_trade,
					old_player_id: _.xor(old_player_id, [id])
				}
			});
		}
	}

	removeInPlayer({ id }) {
		const { is_fa_trade, is_team_trade, fa_trade, team_trade } = this.state;
		if (is_fa_trade) {
			this.setState({
				fa_trade: {
					...fa_trade,
					new_player_id: 0
				},
				show_team: false
			});
		}
		if (is_team_trade) {
			const { new_player_id } = team_trade;
			this.setState({
				team_trade: {
					...team_trade,
					new_player_id: _.xor(new_player_id, [id])
				}
			});
		}
	}

	get page_title() {
		const { league } = this.props;
		const { is_fa_trade, is_team_trade } = this.state;

		if(is_fa_trade) {
			return "Free agent request";
		}
		if(is_team_trade) {
			return "Team to team trade";
		}

		return `Players list for ${league && league.name ? league.name : ""}`;
	}

	render() {
		const { league, user_team, data_loading_complete } = this.props;
		const{
			fa_trade,
			is_fa_trade,
			data_load_initial
		} = this.state;
		const playerID = this.props.match.params.player_id;
		return (
			<React.Fragment>
				{/* Game bar has JSON fetcher so will remove duplicates */}
				{/* <JsonFetcher fetch={["players", "squads", "venues", "coach_players", "rounds"]} /> */}
				<JsonFetcher fetch={["squads", "venues", "rounds", "players", "coach_players"]} />

				<LeagueGameBar no_fetch={true} />

				<AdsContainer>
					<Ad />
				</AdsContainer>

				<StyledOneColumnLayout ref={this.ref}>
					<PageTitle>
						{this.page_title}
					</PageTitle>
					{data_load_initial ? <div>
						{is_fa_trade && !_.isEmpty(user_team) ? (
							<PlayersOnTrade
								user_team={user_team}
								league={league}
								fa={fa_trade}
								type="fa"
								reFetchData={this.reFetchData}
								cancelRequest={this.cancelTradeRequest}
								removeOutPlayer={this.removeOutPlayer}
								removeInPlayer={this.removeInPlayer}
								playerID={playerID}
							/>
						) : null} 
						{/* clarify logic in here */}
						{this.component_to_show} 
					</div> : <Preloader />
					}
				</StyledOneColumnLayout>
				{data_loading_complete ? this.show_match_bar:null}
				<Footer show_key />
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: TRootStore, props) => ({
	playerIDsInLineup: selectors.getPlayerIdsInLineup(state),
	actual_round: selectors.rounds.getActualRound(state),
	players: selectors.players.getExtendedPlayersArrayWithOwners(state, props),
	is_pending_players: state.players.is_pending,
	squads: selectors.squads.getSquads(state),
	positions: selectors.positions.getPositionsArray(state),
	playerStatuses: state.playerStatuses,
	league: selectors.leagueDraft.getLeague(state, props),
	teams_by_id: state.leagues.teams_by_id,
	has_assistant_coach: Boolean(selectors.getUser(state).assistant_coach),
	coach_stats_list: state.coachStatsList,
	coach_players_by_id: state.players.coach_by_id,
	current_user_id: state.user.data.id,
	last_round: selectors.rounds.getLastRound(state),
	user_team: state.teamsDraft.user_team,
	trade_id: state.teamsDraft.trade_id,
	waiver_requests: state.teamsDraft.waiver_requests,
	data_loading_complete: _.every([true,
		!state.players.is_pending,
		!state.rounds.is_pending,
		!state.squads.is_pending,
		!state.venues.is_pending,
		!state.leagues.show.is_pending,
		!state.leagues.waiver_free.is_pending,
		!state.leagues.waiver_list.is_pending
	])
});

const mapDispatchToProps = {
	showDraftLeague: actions.leagueDraft.showDraftLeague,
	fetchTeams: actions.leagueDraft.showDraftOrder,
	fetchTeam: actions.teamsDraft.fetchTeam,
	fetchCoachPlayers: actions.fetchCoachPlayers,
	fetchAllCustomStats: actions.fetchAllCustomStats,
	fetchAllCoachCustomStats: actions.fetchAllCoachCustomStats,
	fetchWaiverList: actions.leagueDraft.fetchWaiverList,
	fetchWaiverFree: actions.leagueDraft.fetchWaiverFree,
	fetchFavourites: actions.fetchFavourites
};
export const Players = compose(
	withRouter,
	connect(
		mapStateToProps,
		mapDispatchToProps,
	),
	withDraftPlayerModal,
	withCoachesBox
)(PlayersComponent);

export default Players;