/* eslint max-len: 0 */
// @flow
import React from "react";
import SvgIconContainer from "../SvgIconContainer";

export default (
	{
		unit = "em",
		...rest
	}: Object = {}) => (
	<SvgIconContainer viewBox="0 0 16 17" {...rest} unit={unit}>
		<title>Checked</title>
		<rect width="15"height="15"x="0.5"y="0.833"fill="#1D4073"stroke="#1D4073"rx="7.5"/>
		<path fill="#fff" fillRule="evenodd" d="M6.333 12.5L3 9.167l1.25-1.25L6.333 10l5.417-5.417L13 5.833 6.333 12.5z" clipRule="evenodd" />
	</SvgIconContainer>
);