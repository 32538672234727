// @flow
import * as React from "react";
import styled from "styled-components";

import { below } from "../../assets/css/media";
import EllipsisRow from "../../components/EllipsisRow";
import { getShortName } from "../../helpers/league";

const LeagueNameStyled = styled.div`
	width: 100%;
`;
const EllipsisRowStyledWrapper = styled.div`
	width: 100%;
	max-width: 100%;
	margin-bottom: 0.3em;
`;

const EllipsisRowStyled = styled(EllipsisRow)`
	color: #1D4073;
	font-size: 16px;
	font-weight: bold;
	line-height: 1;
	display: block;
	font-family: TitilliumUpright;
	${below.tablet`
		font-size: 12px;
	`}
`;

const CommissionerBlock = styled.p`
	font-size: 14px;
	line-height: 1;
	font-family: SourceSansPro;
	font-weight: 400;
	color: #1D4073;
	${below.tablet`
		font-size: 12px;
	`}
`;

type Props = {
	name: string,
	lastname: string,
	firstname: string,
	width: string,
	className?: string,
	theme?: {
		leagueName?: {
			color?: string,
			children?: {
				commissioner?: {
					color?: string
				}
			}
		}
	}
};

export const LeagueName = ({ name, lastname, firstname, width, className }: Props) => (
	<LeagueNameStyled className={className}>
		<EllipsisRowStyledWrapper>
			<EllipsisRowStyled width={width}>
				{name}
			</EllipsisRowStyled>
		</EllipsisRowStyledWrapper>
		<CommissionerBlock className="commissioner">
			{ getShortName({ lastname, firstname }) }
		</CommissionerBlock>
	</LeagueNameStyled>
);

export default LeagueName;