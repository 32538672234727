// @flow
import React from "react";
import SvgIconContainer from "../SvgIconContainer";

export default (
	{
		unit = "em",
		...rest
	}: Object = {}) => (
	<SvgIconContainer viewBox="0 0 24 24" {...rest} unit={unit}>
		<title>Transactions</title>
		<path d="M22.1957263,20.3678709 C22.1546413,20.6045241 21.9854855,20.7987585
        21.7567198,20.8719635 C21.5279541,20.9451686 21.2774604,20.885222 21.1066186,20.7163853
        L19.4146578,19.030831 C12.3284104,27.3733961 2.01968555,23.52308 0.0874803336,19.6714826
        C6.52987281,22.8798658 12.9722653,18.3901794 14.9051112,14.5379413 L13.2131503,12.852387
        C13.0409276,12.6826528 12.9789449,12.4304109 13.0528873,12.2001873 C13.1268297,11.9699638
        13.3240978,11.8009871 13.5629461,11.7632792 L23.0349799,10.1904796 C23.2399705,10.1548653
        23.4494894,10.2210165 23.5968655,10.3678831 C23.7442416,10.5147496 23.811118,10.7240382
        23.7762138,10.9291509 L22.1957263,20.3678709 Z M8.2405854,8.28916667 L9.81056429,9.76572276
        C9.97037062,9.91446747 10.0278846,10.135517 9.95927308,10.3372709 C9.89066158,10.5390249
        9.70761551,10.6871058 9.48598706,10.7201506 L0.696839778,12.0984568 C0.506535728,12.1298758
        0.311933867,12.0719982 0.175032668,11.9432631 C0.0381314697,11.8145281
        -0.0239933286,11.6309928 0.00845069784,11.4511301 L1.47380396,3.18297748
        C1.51210376,2.97568532 1.66913412,2.80563367 1.88137632,2.7416084 C2.09361851,2.67758313
        2.32594988,2.73017938 2.48439242,2.87812199 L4.05377686,4.35467807 C10.6297354,-2.95623954
        20.1946579,0.417943755 21.9881552,3.7932499 C16.0120107,0.978249067 10.0340827,4.91498338
        8.2405854,8.28916667 Z" />
	</SvgIconContainer>
);