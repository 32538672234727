// @flow
import React from "react";
import SvgIconContainer from "../SvgIconContainer";

export default (
	{
		unit = "em",
		...rest
	}: Object = {}) => (
	<SvgIconContainer viewBox="0 0 16 16" {...rest} unit={unit}>
		<g stroke="none" strokeWidth="1" fillRule="evenodd">
			<path
				d="M4.18620646,11.63479 C3.56974296,12.2512535 3.58047168,13.2174334 4.18301058,
					13.8199723 C4.78975027,14.426712 5.7638889,14.4210803 6.36819283,13.8167764
					L11.816767,8.36820224 C12.1227983,8.06217091 12.2730798,7.66780903 12.2725107,
					7.27358879 C12.2740189,6.87511397 12.1207117,6.48376882 11.8199629,6.18301999
					L6.36819283,0.731249942 C5.75172934,0.114786447 4.78554948,0.125515165
					4.18301058,0.728054063 C3.57627089,1.33479375 3.58190253,2.30893238 4.18620646,
					2.91323631 L8.54698332,7.27401318 L4.18620646,11.63479 Z"
				transform="translate(8.000000, 7.272522) scale(1, -1)
					rotate(-270.000000) translate(-8.000000, -7.272522) "
			/>
		</g>
	</SvgIconContainer>
);