// @flow
import * as React from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import type { TPlayersById } from "../../modules/types";
import * as actions from "../../modules/actions";
import { below } from "../../assets/css/media";

import {
	TransactionPlayersWrapper,
	PlayerInfo,
	ButtonPrimaryLink,
	ButtonAlert,
} from "../";
import { TransactionPlayers } from "./TransactionPlayers";

const Wrapper = styled.div`
	margin-top: 15px;
`;

const OfferWrapper = styled.div`
	background-color: none;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-evenly;
	button{
		width: 50%;
	}
`;

const OfferOutCell = styled.div`
	width: 80%;
	background: #F0F3F7;
	border-radius: 0;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	box-sizing: border-box;
	padding-right: 15px;
	border: 1px solid #CAD2D8;
	${below.tablet`
		width: 60%;
	`};
`;

const ActionContainer = styled.div`
	margin-left: auto;
	a, button {
		width: 160px;
		box-sizing: border-box;
	}
	${below.desktop}{
		a, button {
			width: 130px;
		}
	}
		
	
`;

const StyledTransactionPlayers = styled(TransactionPlayers)`
	width: 100%;
	flex-basis: 100%;
`;

type Props = {
	players_by_id: TPlayersById,
	offer: Object,
	users_offer?: boolean,
	match: {
		params: {
			league_id: string
		}
	},
	cancelOffer: typeof actions.teamsDraft.fetchCancelPlayerOnOffer
}

const OfferToLeagueComp = ({
	players_by_id,
	offer,
	users_offer,
	match: {
		params: {
			league_id
		}
	},
	cancelOffer
}: Props) => (
	<Wrapper>
		<TransactionPlayersWrapper>
			<StyledTransactionPlayers>
				<p>
					{users_offer? "You have offered to the league:"
						: `${offer.old_team_name} has offered to the league:`}
				</p>
				{offer.old_player_id.map(
					id => (
						<OfferWrapper>
							<OfferOutCell key={id} users_offer={users_offer}>
								<PlayerInfo player={players_by_id[id]} is_classic={false} />
							</OfferOutCell>
							<ActionContainer>
								{users_offer? (
									<ButtonAlert inverted
										onClick={() =>
											cancelOffer({ trade_id: offer.id, league_id })}
									>
										Cancel offer
									</ButtonAlert>
								) : (
									<ButtonPrimaryLink
										to={`/draft/league/${league_id}/players/${id}/trade`}>
										Propose trade
									</ButtonPrimaryLink>
								)}
							</ActionContainer>
						</OfferWrapper>
						
					)
				)}
			</StyledTransactionPlayers>
		</TransactionPlayersWrapper>
	</Wrapper>
);

const mapDispatchToProps = {
	cancelOffer: actions.teamsDraft.fetchCancelPlayerOnOffer
};

export const OfferToLeague = withRouter(connect(null, mapDispatchToProps)(OfferToLeagueComp));
export default OfferToLeague;