// @flow
import React from "react";
import styled from "styled-components";
import * as _ from "lodash";

import type { TMatch } from "../../modules/types";

import colors from "../../assets/css/colors";
import { below, above } from "../../assets/css/media";
import { default_transition } from "../../assets/css/vars";

import type { 
	TDraftUserTeam, 
	TRound, 
	TPlayersById, 
	TCoachPlayerStatsById,
	TTeam 
} from "../../modules/types";
import { 
	getTeamProjectedScoreByMatch, 
	getTeamScoreByMatch, 
	getDraftTeamScoreByMatch } 
from "../../helpers/team";

import IconCircled from "../../components/IconCircled";
import Arrow from "../../components/Icons/Arrow";
import { scoreflowToForm } from "../../helpers/league";
import { TPLeague } from "../../modules/types";
import { isMatchComplete } from "../../helpers/rounds";
import TeamInfo from "./teamInfo";
import PlayersInfo from "./playersInfo";
import Graph from "./graph";



const TeamInfoStyled = styled(TeamInfo)`
`;

const GraphIndicatorBar = styled.div`
  width: 3px;
  height: 42px;
  background-color: ${({ right }) => right ? "#198DE1" : "#001F4A"};
  align-self: center;
  display: ${({ graph_open }) => graph_open ? "" : "none"};
  ${above.phone`
    display: none;
  `}
`;

const SeperatorDiv = styled.div`
	width: inherit;
	margin: 0 20px;
	border-bottom: 1px solid #CAD2D8;
`;

const SummaryPanelWrapper = styled.div`
	display: flex;
	overflow: hidden;
	padding: 5px;
	margin: -5px 0 20px;
`;

const NavSection = styled.div`
	position: relative;
	border: 0 none;
	background: #ffffff;
	box-shadow: 0 0 10px 0 rgba(127,138,144,0.25);
	border-radius: 5px;
	flex: 0 0 30px;
`;

const PrevFixture = styled(NavSection)`
	margin-right: 10px;
	margin-left: -20px;
`;

const NextFixture = styled(NavSection)`
	margin-left: 10px;
	margin-right: -20px;
`;

export const PanelButton = styled.button`
	border: 0 none;
	background: transparent;
	margin: 0;
	padding: 0;

	position: absolute;
	top: 50%;
	transform: translateY(-50%);

	${({ right_align }) => right_align ? "left: -50%;" : "right: -50%;"}
`;

export const ChangePanelIcon = styled(IconCircled)`
	font-size: 24px;
	color: ${colors.primary.primary};
	width: 30px;
	height: 30px;
	background: transparent;

	${default_transition};

	svg {
		${default_transition};
	}

	:hover {
		background: ${colors.primary.accent};

		svg {
			fill: white;
		}
	}
`;

const FixturePanel= styled.div`
	flex: 1 2 auto;
	background: #F0F3F7;
	border-radius: 0px;
	border: 1px solid #CAD2D8;
`;

const TeamsInfo = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	min-height: 75px;
	${TeamInfoStyled}:first-child {
		${below.desktop`
			padding-right: 0px;
		`};
	}
	${below.phone`
			max-height: 66px;
			min-height: 66px;
	`};
`;

const PlayersInfoWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 0 2em;
	padding: .3em 0;

	${below.tablet`
		margin: 0px;
		padding: 0px;
		justify-content: space-around;

	`};
`;

const Versus = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 10%;
	color: ${colors.secondary.paleGrey};
	font-size: 20px;

	${below.tablet`
		font-size: 12px;
		color: #1D4073;
		font-family TitilliumUpright;
		font-weight: 400;
	`};
`;

const OpenGraph = styled.button`
	border: 0 none;
	background: transparent;
	margin: 0;
	padding: 0;
    display: flex;
	font-size: 24px;
	align-items: center;
    justify-content: center;
	flex: 0 0 10%;
`;

const GraphContainer = styled.div`
	position: relative;
	overflow: hidden;
	padding: 0;
	height: 0px;
	background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.05) 100%);
	transition: height 0.5s ease;
	display: flex;
	justify-content: center;
	align-items: center;

	${({ is_open }) => is_open && `
		height: 340px;
		padding: 20px 16px 5px;
	`};
`;

const GraphMessage = styled.div`
	position: absolute;
    background: white;
    padding: 10px;
    border: 2px solid lightgrey;
    border-radius: 10px;
    color: darkgrey;
	text-align: center;

	${below.phone`
		max-width: 220px;
	`}
`;

const accumulateValues = (acc, item) => {
	const last_value = _.get(_.last(acc), "value");
	const updated_item_value = _.isNumber(last_value) && _.isNumber(item.value) ?
		item.value + last_value :
		item.value;

	const updated_item = { ...item, value: updated_item_value };
	return [ ...acc, updated_item];
};

type State = {
	graph_open: boolean
};

type Props = {
	home_team: TDraftUserTeam,
	home_team_proj_score: number,
	away_team: TDraftUserTeam,
	away_team_proj_score: number,
	round_id: number,
	round: TRound,
	actual_round: TRound,
	has_assistant_coach: boolean,
	onClickNext: ?Function,
	onClickPrev: ?Function,
	players_by_id: TPlayersById,
	coach_players_by_id: TCoachPlayerStatsById,
	is_classic: boolean,
  league: TPLeague,
  user_id: number,
};

export class SummaryPanel extends React.Component<Props, State> {
	static defaultProps = {
		is_classic: false,
	};

	constructor(props: Props) {
		super(props);

		_.bindAll(this, ["toggleGraph"]);
	}

	state = {
		graph_open: false
	};

	toggleGraph() {
		this.setState(state => ({
			graph_open: !state.graph_open
		}));
	}

	getMatchTeamScore(match: TMatch, team: TTeam) {
		const { players_by_id, round, is_classic, league } = this.props;
		const player_scoring_path = 
      league.custom_scoring_enabled === 1 ? 
      	`custom_stats.scores[${round.id}]` : `stats.scores[${round.id}]`;
		const scoring_players = 
      _.get(team, `scoring_players[${round.id}]`, { players: [], captain: 0 });
		const lineup = _.get(team, "lineup");
		let team_score_by_match;
		if(!is_classic){
			team_score_by_match = getDraftTeamScoreByMatch({
				player_scoring_path,
				players_by_id,
				round,
				scoring_players
			});
		}
		else{
			team_score_by_match = getTeamScoreByMatch({
				lineup, players_by_id, round, is_classic
			});
		}
		

		return _.get(team_score_by_match, match.id);
	}

	getMatchTeamProjectedScore(match: TMatch, team: TTeam) {
		const { players_by_id, coach_players_by_id, round } = this.props;

		const lineup = _.get(team, "lineup");
		const team_projected_score_by_match = getTeamProjectedScoreByMatch(
			lineup, players_by_id, coach_players_by_id, round
		);

		return _.get(team_projected_score_by_match, match.id);
	}

	get graph_data(): Array<Object> {
		const {
			home_team,
			away_team,
			round,
		} = this.props;

		const round_matches = _.get(round, "matches", []);

		const home_team_match_scores = round_matches.map(match => ({
			match: match.id,
			value: isMatchComplete(match) || round.status === "complete" ?
				this.getMatchTeamScore(match, home_team) :
				this.getMatchTeamProjectedScore(match, home_team),
			is_projected: !isMatchComplete(match) && round.status !== "complete"
		})).reduce(accumulateValues, []);

		const away_team_match_scores = round_matches.map(match => ({
			match: match.id,
			value: isMatchComplete(match) || round.status === "complete" ?
				this.getMatchTeamScore(match, away_team) :
				this.getMatchTeamProjectedScore(match, away_team),
			is_projected: !isMatchComplete(match) && round.status !== "complete"
		})).reduce(accumulateValues, []);

		const home_team_graph_data = {
			id: _.get(home_team, "id"),
			color: colors.primary.primary,
			scores: home_team_match_scores,
		};

		const away_team_graph_data = {
			id: _.get(away_team, "id"),
			color: colors.primary.accent,
			scores: away_team_match_scores,
		};

		return [home_team_graph_data, away_team_graph_data];
	}

	get show_projected_not_available_message() {
		const { round_id, actual_round } = this.props;

		return round_id > _.get(actual_round, "id", 0) + 3;
	}

	get show_subscribe_fantasy_coach_message() {
		const { has_assistant_coach, round } = this.props;
		const is_round_scheduled = _.get(round, "status") === "scheduled";
		return !has_assistant_coach && is_round_scheduled;
	}

	get show_first_match_not_complete_with_no_projected() {
		const { has_assistant_coach, round } = this.props;
		const is_round_scheduled = _.get(round, "status") === "scheduled";
		const is_first_match_complete = isMatchComplete(_.get(round, "matches[0]"));
		return !has_assistant_coach && !is_round_scheduled && !is_first_match_complete;
	}

	get show_graph_data() {
		return this.show_subscribe_fantasy_coach_message === false &&
			this.show_projected_not_available_message === false;
	}

	renderFixturesPanel() {
		const { graph_open } = this.state;
		const {
			home_team,
			home_team_proj_score,
			away_team,
			away_team_proj_score,
			round,
			round_id,
			has_assistant_coach,
			players_by_id,
			actual_round,
			is_classic,
			league,
			user_id
		} = this.props;

		const is_active_match = round_id === actual_round.id && actual_round.status === "active";

		// TODO - use ladder teams w/ league_scoreflow and rank properties
		const home_form = scoreflowToForm(home_team, round_id, actual_round).join("");
		const away_form = scoreflowToForm(away_team, round_id, actual_round).join("");

		return (
			<FixturePanel>
				<TeamsInfo>
					<GraphIndicatorBar graph_open={graph_open}/>
					<TeamInfoStyled
						opponent={home_team}
						team_proj_score={home_team_proj_score}
						round={round}
						has_assistant_coach={has_assistant_coach}
						is_active_match={is_active_match}
						players_by_id={players_by_id}
						form={home_form}
						graph_open={graph_open}
						is_classic={is_classic}
						league={league}
						user_id={user_id}
					/>
					<Versus>VS</Versus>
					<TeamInfoStyled
						opponent={away_team}
						team_proj_score={away_team_proj_score}
						round={round}
						has_assistant_coach={has_assistant_coach}
						is_active_match={is_active_match}
						players_by_id={players_by_id}
						right_align
						form={away_form}
						graph_open={graph_open}
						is_classic={is_classic}
						league={league}
						user_id={user_id}
					/>
					<GraphIndicatorBar graph_open={graph_open} right={true} />
				</TeamsInfo>
				<SeperatorDiv />
				<PlayersInfoWrapper>
					<PlayersInfo
						opponent={home_team}
						players_by_id={players_by_id}
						round={round}
						is_classic={is_classic}
					/>
					<OpenGraph onClick={this.toggleGraph}>
						<Arrow direction={ graph_open ? "up" : "down" } />
					</OpenGraph>
					<PlayersInfo
						opponent={away_team}
						players_by_id={players_by_id}
						round={round}
						right_align
						is_classic={is_classic}
					/>
				</PlayersInfoWrapper>

				<GraphContainer is_open={graph_open}>
					{graph_open && (
						<React.Fragment>
							{this.show_subscribe_fantasy_coach_message ? (
								<GraphMessage>
									{"To see projected scores subscribe to fantasy coach"}
								</GraphMessage>
							) : null}
							{this.show_projected_not_available_message ? (
								<GraphMessage>
									{`Projected scores will be available a few rounds prior
									to round start`}
								</GraphMessage>
							) : null}
							{this.show_first_match_not_complete_with_no_projected ? (
								<GraphMessage>
									{`You will start seeing scores on completion of the first
									match of the round`}
								</GraphMessage>
							) : null}
							<Graph
								data={this.graph_data}
								matches={_.get(round, "matches")}
								show_data={this.show_graph_data}
								actual_round={actual_round}
							/>
						</React.Fragment>
					)}
				</GraphContainer>
			</FixturePanel>
		);
	}

	render() {
		const {
			onClickNext,
			onClickPrev,
		} = this.props;

		return (
			<SummaryPanelWrapper>
				{onClickPrev && (
					<PrevFixture>
						<PanelButton onClick={onClickPrev}>
							<ChangePanelIcon>
								<Arrow direction="left" />
							</ChangePanelIcon>
						</PanelButton>
					</PrevFixture>
				)}
				{this.renderFixturesPanel()}
				{onClickNext && (
					<NextFixture>
						<PanelButton right_align onClick={onClickNext}>
							<ChangePanelIcon>
								<Arrow direction="right" />
							</ChangePanelIcon>
						</PanelButton>
					</NextFixture>
				)}
			</SummaryPanelWrapper>
		);
	}
}

export default SummaryPanel;