// @flow
import * as React from "react";
import _ from "lodash";

import styled from "styled-components";
import { Field } from "redux-form";
import colors from "../../assets/css/colors";

import { FormGroup, Label, LabelWrapper, Tooltip } from "../../components/Form";

import Locked from "../../components/Icons/Locked";
import { BorderedIcon, Exist, FlexDiv } from "../../components";
import { below } from "../../assets/css/media";
import { isAnyTrue } from "../../helpers";
export const BorderIconWrap = styled.div`
    height: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
		cursor: pointer;
`;

const RadioSwitch = styled.div`
	display: flex;
	min-height: 30px;
	padding: .3em 0;
	border: 1px solid ${colors.form.borderColor};
	background-color: #ffffff;
	align-items: center;
	border-radius: 3px;
	margin-top: 5px;
	opacity: ${({ is_disabled }) => is_disabled ? ".5" : "1"};
	width: ${({ width }) => width ? width : "100%"};

	input {
		display: none;

		&:checked + label {
			background: ${colors.primary.accent};
			color: #ffffff;
		}

		&:disabled + label {
			opacity: .5;
		}
	}

	label {
		display: flex;
		justify-content: center;
		color: ${colors.form.textColor};
		line-height: 1.7;
		padding: 9px 10px;
		margin: 0px 5px;
		font-size: 14px;
		cursor: pointer;
		opacity: ${({ is_disabled }) => is_disabled ? ".5" : "1"};
		font-weight: bold;
		border-radius: 3px;
		flex-basis: 50%;
		width: 150px;

		font-family: "SourceSansPro";
		font-size: 12px;
		font-weight: 600;
		letter-spacing: 0;
		line-height: 12px;
		text-align: center;		

		${below.desktop`
			padding: 0;
			height: 30px;
			line-height: 30px;
		`}
	}
`;

type Props = {
	label: string,
	name: string,
	is_disabled?: boolean,
	tooltip?: {
		title: string,
		description: string,
	},
	elements: Array<Object>,
	is_string_value?: boolean,
  width?: string,
	handleProOpen: Function,
	is_pro_sub: boolean,
	input: Object,
};


export const ProFormRadio = ({
	label,
	input,
	elements,
	tooltip,
	is_disabled = false,
	is_string_value,
	width,
	handleProOpen,
	is_pro_sub,
}: Props) => { 
	const { name } = input;
	return (
		<div>
			<LabelWrapper>
				<FlexDiv>
					
					{(isAnyTrue([
 						!is_pro_sub,
						is_disabled
					])) ? (
							<BorderIconWrap onClick={!is_pro_sub ? handleProOpen : null}>
								<BorderedIcon
									padding='0'
									hide_border={true}
									background={ !is_pro_sub ? "#000" : colors.primary.primary}
									size={1}
									className="disable-icon"
								>
									<Locked size={1.1} color='#ffffff' />
								</BorderedIcon>
							</BorderIconWrap>
						) : ""}
					<Label htmlFor={name} is_disabled={isAnyTrue([
						!is_pro_sub,
						is_disabled
					])}>
						{label}
					</Label>
				</FlexDiv>
				<Exist when={Boolean(tooltip)}>
					<Tooltip {...tooltip} />
				</Exist>
			</LabelWrapper>
			<FormGroup>
				<RadioSwitch is_disabled={isAnyTrue([
					!is_pro_sub,
					is_disabled
				])} width={width}>
					{
						_.map(elements, ({ value, text }, index) => {
							return (
								<React.Fragment key={`${name}_${value}`}>
									<Field
										parse={value => 
											(is_string_value ? value : _.toInteger(value))}
										name={name}
										id={`${name}_${value}`}
										value={value}
										component='input'
										type='radio'
										key={index}
										disabled={isAnyTrue([
											!is_pro_sub,
											is_disabled
										])}
									/>
									<label
										htmlFor={`${name}_${value}`}
										key={index + 1}
									>
										{text}
									</label>
								</React.Fragment>
							);
						})
					}
				</RadioSwitch>
			</FormGroup>
		</div>
	);
};

export default ProFormRadio;