// @flow
import React from "react";
import styled from "styled-components";
import * as _ from "lodash";
import { get } from "lodash";
import * as numeral from "numeral";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import MATCH_STATS from "../../utils/matchStats";
import { score_settings } from "../../helpers";
import * as format from "../../helpers/numeralFormats";

import * as actions from "../../modules/actions";
import * as selectors from "../../modules/selectors";

import Preloader from "../Preloader";
import {getClubLogoIndigenous} from "../../utils/clubLogos";
import byeLogo from "../../assets/images/bye-light.svg";
import type { TPLeague, TPlayer, TRound } from "../../modules/types";
import {
	SectionHeader,
	StatHeadItem,
	StatHeadRow,
	StatItem,
	StatItemRow,
} from "./PlayerPopupStyles";





const FlexCol = styled.div`
    display: flex;
    flex-direction: column;
`;



type MatchStatProps = {
    is_classic: boolean,
    league: TPLeague,
    rounds: TRound[],
    player: TPlayer,
    has_coach: boolean,
    actual_round: number,
    fullLink: string,
    player_matches: Object,
    fetchPlayerMatches: Function,
    fetchCoachStats: Function,
	first_scheduled_round:TRound
}


const DEFAULT_VALUE = "— —";


const getOpponentFromRoundID = (player:TPlayer, round:TRound) => {
	const match = 
		round.matches.find(match => 
			[match.home_squad_id, match.away_squad_id]
				.includes(player.squad_id));
	const isPlayerHome = match && _.get(match, "home_squad_id", 0) === player.squad_id;
	
	return isPlayerHome ? _.get(match, "away_squad_id", 0) 
		: _.get(match, "home_squad_id", 0);
};



class MatchStatsComponent extends React.PureComponent<MatchStatProps> {
	constructor(props) {
		super(props);
		
		_.bindAll(this, [
			// 'openSubModal',
			// 'closeSubModal'
		]);
	}

	componentDidMount(){
		this.props.fetchPlayerMatches(this.props.player.id);
		this.props.fetchCoachStats();
	}
	get roundsToUse() {
		return this.props.rounds;
		// const isFirstScheduled = _.get(this.props.rounds, '[0].status') === 'scheduled'; 
		// if(isFirstScheduled){
		
		// }
		// return this.props.rounds.filter(round => round.status !== "scheduled");
	}
	
	get isLeagueCustomScoring() {
		return Boolean(this.props.league) && this.props.league.custom_scoring_enabled;
	}

	getRoundScore(round_num, fallback_score, round_stats) {
		const {league, rounds} = this.props;

		if(rounds[0].status === "scheduled"){
			return fallback_score;
		}
		const custom_scoring_enabled = Boolean(get(league, 'custom_scoring_enabled'));
		const leagueCustomScoring = get(league, 'custom_scoring', {});
		let score = 0;
		if(custom_scoring_enabled && leagueCustomScoring){
			Object.keys(leagueCustomScoring).forEach(statKey => {
				score += round_stats[statKey] * leagueCustomScoring[statKey];
			});
			return score;
		}
		MATCH_STATS.played.forEach(stat => {
			if(["TOG", "stats.score"].includes(stat.id)){
				return;
			}
			score += round_stats[stat.id] * stat.score_per;
		});
		return score;
		
	}

	displayStatNotEmpty (stat: Object, round_id: number | string, index: number) {		
		const {
			first_scheduled_round,
			player,
			player_matches,
		} = this.props;
		const firstScheduledRoundID = get(first_scheduled_round,"id",1);
		const round_stats =  player_matches[player.id][round_id];
		const value = _.get(round_stats, stat.id, DEFAULT_VALUE);
		
		if(firstScheduledRoundID === 1) {
			return (
				<StatItem rel={stat.id} key={`${stat.id}-key-${index}`}>
					{DEFAULT_VALUE}
				</StatItem>
			);
		}

		if (stat.id === "TOG") {
			return (
				<StatItem rel={stat.id} key={`${stat.id}-key-${index}`}>
					{ numeral(value / 100).format(format.PERCENTAGE)}
				</StatItem>
			);
		}
		else if (stat.id === "stats.score") {
			const { scores } = player.stats;
			const scoreValue = _.get(scores, round_id, DEFAULT_VALUE);
			return (
				<StatItem rel={stat.id} key={`${stat.id}-key-${index}`}>
					{
				        this.getRoundScore(round_id, scoreValue, round_stats) 
					}
				</StatItem>
			);
		}
		else if (stat.id === "custom_stats.score") {
			const { scores } = this.props.player.custom_stats;
			return (
				<StatItem rel={stat.id} key={`${stat.id}-key-${index}`}>
					{

						_.get(scores, round_id, DEFAULT_VALUE)
					}
				</StatItem>
			);
		}
		return (
			<StatItem rel={stat.id} key={`${stat.id}-key-${index}`}>
				{value}
			</StatItem>
		);
	};

	displayMatchStat (stat: Object, round_id: number | string, row_id: any) {
		const { player, player_matches } = this.props;
		if (player_matches 
            && player_matches[player.id].hasOwnProperty(round_id) 
            && this.props.player) {
			return this.displayStatNotEmpty(stat, round_id, row_id);
		}
		return <StatItem rel={stat.id} key={`${stat.id}-key-${row_id}`}>{DEFAULT_VALUE}</StatItem>;
	};

	getOpponentFromRound(round) {
		const {player, rounds, actual_round} = this.props;
		const opponentID = getOpponentFromRoundID(player,round);
		const logo = getClubLogoIndigenous(actual_round, opponentID);
		const isRoundOneStarted = rounds[0].status !== "scheduled";
		if(round.status !== "scheduled" && !isRoundOneStarted){
			return <img src={byeLogo} alt="Bye"/>;
		}
		return <img src={logo} alt="Opponent Logo"/>;
	}

	render() {
		const {league, player_matches, player} = this.props;
		const stats = [];
		if(!player_matches[player.id]){
			return <Preloader />;
		}
		if(this.isLeagueCustomScoring){
			stats.push({
				name: "% TOG", 
				id: "TOG", 
				tooltip: "Percentage of time spent on ground"
			});
			Object.keys(league.custom_scoring).forEach(i => {
				stats.push({ 
					id: i, 
					name: i, 
					tooltip: score_settings[i].title + ` (${league.custom_scoring[i]} points)`, 
					score_per: league.custom_scoring[i]
				});
			});
			stats.push({
				name: "Score", 
				id: "stats.score", 
				tooltip: "Total Score for Game"
			});
		}
		else{
			MATCH_STATS.played.forEach(i => { 
				stats.push(i);
			});
		}
        
		return(
			<FlexCol>
				<SectionHeader>
					<p>Match Stats</p>
				</SectionHeader>
				<StatHeadRow>
					<StatHeadItem>
                        Rnd
					</StatHeadItem>
					<StatHeadItem>
                        Opp
					</StatHeadItem>
					{stats.map((stat, index) => {
						return(
							<StatHeadItem key={`head-stat-item-match-${index}`} is_fc={false}>
								{stat.name}
							</StatHeadItem>
						);
					})}
				</StatHeadRow>
				{this.roundsToUse.map((round, index) => {
					return(
						<StatItemRow key={`${index}-round-item`}>
							<StatItem>
								{round.id}
							</StatItem>
							<StatItem >
								{this.getOpponentFromRound(round)}
							</StatItem>
							{stats.map(stat => {
								return(
									
									this.displayMatchStat(stat, round.id, index)
							
								);
							})}
						</StatItemRow>
					);
				})}
			</FlexCol>
		);
		
	}
}

const mapStateToProps = state => ({
	menu_open: state.uiMenu.open,
	league_id: state.leagues.show.league_id,
	player_matches: state.players.matches_by_id,
	first_scheduled_round: 
		selectors.rounds.getFirstScheduledRound(state)
});

const mapDispatchToProps = {
	fetchPlayerMatches: actions.fetchPlayerMatches,
	fetchCoachStats: actions.fetchCoachPlayers
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MatchStatsComponent));