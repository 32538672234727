// @flow
import * as React from "react";
import styled from "styled-components";
import { ModalContainer, ButtonPrimary } from "../../components";
import Caution from "../Icons/Caution";
import colors from "../../assets/css/colors";
import { below } from "../../assets/css/media";
import ModalBody from "./ModalBody";


type Props = {
	onClick: Function,
	text: any,
	header_text: string
};

const CautionStyled = styled(Caution)`
	color: ${colors.buttons.cancel};
`;

const BodyStyled = styled(ModalBody)`
	padding: 20px;
    color: ${colors.primary.primary};
    font-family: SourceSansPro;
    font-weight: 400;
	${below.phone`
		padding-top: 15px;
	`}
`;

const HeaderStyled = styled.h1`
    text-align: center;
    font-size: 26px;
    font-family: SourceSansPro;
    color: ${colors.primary.primary};
    ${below.phone`
		margin-top: 25px;
	`}
`;

const StyledButton = styled(ButtonPrimary)`
    width: 200px;
    margin-bottom: 20px;
`;

const error_icon = <CautionStyled />;

export const InformationAcceptModal = ({ text, onClick, header_text }: Props) => (
	<ModalContainer
		top_icon={header_text === "Error" ? error_icon : undefined}
		onClick={onClick}
	>
		<HeaderStyled>
			{header_text}
		</HeaderStyled>
		<BodyStyled>
			{text}
		</BodyStyled>
		<StyledButton onClick={onClick}>
            Got it!
		</StyledButton>
	</ModalContainer>
);

export default InformationAcceptModal;