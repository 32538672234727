/* eslint max-len: 0 */
// @flow
import React from "react";
import SvgIconContainer from "../SvgIconContainer";

export default (
	{
		unit = "em",
		...rest
	}: Object = {}) => (
	<SvgIconContainer viewBox="0 0 24 24" {...rest} unit={unit}>
		<title>Emergency Defender</title>
		<path d="M7,6 L10.5446154,6 C11.4553892,6 12.289227,6.11076812 13.0461538,6.33230769 C13.8030807,6.55384726 14.452305,6.90461298 14.9938462,7.38461538 C15.5353873,7.86461778 15.9569216,8.47999625 16.2584615,9.23076923 C16.5600015,9.98154222 16.7107692,10.8861486 16.7107692,11.9446154 C16.7107692,13.0030822 16.5630784,13.9138423 16.2676923,14.6769231 C15.9723062,15.4400038 15.5569258,16.0676898 15.0215385,16.56 C14.4861512,17.0523102 13.8523114,17.4153834 13.12,17.6492308 C12.3876886,17.8830781 11.5907735,18 10.7292308,18 L7,18 L7,6 Z M10.36,15.4523077 C10.7907714,15.4523077 11.1969212,15.4000005 11.5784615,15.2953846 C11.9600019,15.1907687 12.2892294,15.0092321 12.5661538,14.7507692 C12.8430783,14.4923064 13.0615377,14.1353869 13.2215385,13.68 C13.3815393,13.2246131 13.4615385,12.6461574 13.4615385,11.9446154 C13.4615385,11.2430734 13.3815393,10.6707714 13.2215385,10.2276923 C13.0615377,9.78461317 12.8430783,9.44000123 12.5661538,9.19384615 C12.2892294,8.94769108 11.9600019,8.778462 11.5784615,8.68615385 C11.1969212,8.59384569 10.7907714,8.54769231 10.36,8.54769231 L10.1753846,8.54769231 L10.1753846,15.4523077 L10.36,15.4523077 Z" id="ED"></path>
	</SvgIconContainer>
);