// @flow
import React from "react";
import { Redirect } from "react-router-dom";
import styled from "styled-components";
import { connect } from "react-redux";
import _ from "lodash";
import { compose } from "redux";

import withRoundSelector from "../../../components/utils/withRoundsSelector";
import * as actions from "../../../modules/actions";
import * as selectors from "../../../modules/selectors";
// import type { TPLeague } from "../../../modules/types";
import { below } from "../../../assets/css/media";
import {
	Footer,
	PageContentWrapper,
	PageTitle,
	RoundSelector,
	TwoColumnLayout,
	AdsContainer,
	Ad,
	StandardSidebar,
	MatchCentreBar,
	AdFantasyCoach,
	LeagueGameBar,
	Fixtures
} from "../../../components";
import type { 
	TPLeague, 
	TRoundsById, 
	TRound, 
	TPlayersById, 
	TCoachPlayerStatsById,
	TClassicTeamsByID
} from "../../../modules/types";
import JsonFetcher from "../../../components/utils/JsonFetcher";

import { getLeagueRoundAliases, getLeagueFinalFullNames, getLeagueInitialFetchRound } from "../../../helpers/league";
import withCoachesBox from "../../../components/utils/withCoachesBox";

const FixturesWrapper = styled.div`
	margin-bottom: 50px;
`;

const PageHeaderWrapper = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	align-items: center;
	justify-content: space-between;
	${below.phone`
		.round-selector{
			width: 100%;
			select{
				width: 100%;
			}
		}
	`}
`;

type Props = {
	selected_league_id: number,
	ladder_teams_by_id: TClassicTeamsByID,
	ladder_teams_ordered: Object,
	fetchLeague: typeof actions.showClassicLeague,
	fetchLadder: typeof actions.ladderClassicLeague,
	fetchRounds: typeof actions.fetchRounds,
	is_pending_show: boolean,
	is_pending_ladder: boolean,
	user_id: number,
	rounds_ordered: Array<Object>,
	rounds_by_id: TRoundsById,
	selected_round_id: number,
	setSelectedRoundId: Function,
	league: TPLeague,
	has_assistant_coach: boolean,
	show_league_success: boolean,
	coach_players_by_id: TCoachPlayerStatsById,
	actual_round: TRound,
	match: {
		params: {
			league_id: number
		}
	},
	players_by_id: TPlayersById,
	rounds_with_matches: Array<Object>,
	fetchLeagueRosters: typeof actions.getClassicLeagueRosters,
};

class LeagueFixtures extends React.Component<Props> {

	componentDidMount() {
		const { league_id } = this.props.match.params;
		const { fetchLeague, fetchLadder, selected_round_id, actual_round } = this.props;
		const roundId = getLeagueInitialFetchRound(selected_round_id, actual_round.id);
		fetchLeague({ id: league_id });
		fetchLadder({ league_id });		
		this.fetchLeagueRosters(roundId);
	}

	componentDidUpdate(prev_props: Props) {
		const { selected_league_id, selected_round_id } = this.props;

		if (
			selected_league_id !== prev_props.selected_league_id
		) {
			this.fetchLeague();
			this.fetchLadder();
		}
		else if (
			selected_round_id !== prev_props.selected_round_id 
				&& Boolean(prev_props.selected_round_id)
		) {
			this.fetchLadder();
		}

		if (!this.props.is_pending_ladder && prev_props.is_pending_ladder) {
			this.fetchLeagueRosters(selected_round_id);
		}

		this.checkShouldSetRound(prev_props);
	}

	checkShouldSetRound(prev_props: Props) {
		const { selected_league_id, league, setSelectedRoundId, actual_round } = this.props;
		if (
			selected_league_id !== prev_props.selected_league_id 
			|| league !== prev_props.league
			|| (actual_round && !prev_props.actual_round)
		) {
			setSelectedRoundId(this.league_selected_round);
		}
	}

	fetchLeagueRosters(round_id) {
		const { fetchLeagueRosters, selected_league_id, league } = this.props;
		const league_type = _.get(league, "type");
		if(league_type !== "open"){
			fetchLeagueRosters({
				league_id: selected_league_id,
			});
		}
	}

	fetchLadder() {
		const { fetchLadder, selected_league_id, selected_round_id } = this.props;
		fetchLadder({
			league_id: selected_league_id,
			round: selected_round_id
		});
	}

	fetchLeague() {
		const { fetchLeague, selected_league_id } = this.props;
		fetchLeague({
			id: selected_league_id
		});
	}

	get is_league_bye_round() {
		const {
			rounds_by_id,
			league,
			selected_round_id
		} = this.props;

		if (rounds_by_id && selected_round_id && league) {
			const round = rounds_by_id[selected_round_id];
			return Boolean(league.bye_rounds) ? false : round.is_bye;
		}

		return false;
	}

	get league_current_round_fixtures() {
		const { league, selected_round_id } = this.props;
		return selected_round_id ?
			_.get(league, `fixture.${selected_round_id}`, []): [];
	}

	get league_rounds() {
		const {
			rounds_ordered,
			league
		} = this.props;

		if (rounds_ordered && league && league.start_round && league.end_round) {
			return rounds_ordered.filter(round =>
				round.id >= league.start_round && round.id <= league.end_round
			);
		}

		return rounds_ordered;
	}

	get league_selected_round() {
		const { selected_round_id, league } = this.props;

		if (selected_round_id && league) {
			const start_round = _.first(this.league_rounds).id;
			const end_round = _.last(this.league_rounds).id;

			if ( selected_round_id < start_round) {
				return start_round;
			}
			else if (selected_round_id > end_round) {
				return end_round;
			}

			return selected_round_id;
		}

		return  null;
	}

	get shouldRedirectToOverview() {
		const {
			show_league_success,
			league,
		} = this.props;

		return (show_league_success && league.type !== "head_to_head");
	}

	get shouldRenderNoFixturesMessage() {
		const {
			show_league_success,
			rounds_by_id,
		} = this.props;

		const empty_rounds = _.isEmpty(rounds_by_id);

		return (show_league_success && !empty_rounds && !this.league_current_round_fixtures.length);
	}

	get finals_rounds() {
		const {
			rounds_ordered,
			league
		} = this.props;

		return getLeagueRoundAliases(league, rounds_ordered);
	}

	get finals_names() {
		const {
			rounds_ordered,
			league
		} = this.props;

		return getLeagueFinalFullNames(league,  rounds_ordered);
	}

	render() {
		const {
			setSelectedRoundId,
			ladder_teams_by_id,
			league,
			rounds_with_matches,
			has_assistant_coach,
			coach_players_by_id,
			selected_round_id,
			actual_round,
			players_by_id,
			user_id,
			ladder_teams_ordered
		} = this.props;

		if (this.shouldRedirectToOverview) {
			return <Redirect to={`/classic/league/${league.id}/`}/>;
		}
		else {
			return (
				<React.Fragment>
					<JsonFetcher fetch={["rounds", "coach_players", "players"]} />
					<LeagueGameBar no_fetch={true} />
					<AdsContainer>
						<Ad />
					</AdsContainer>


					<PageContentWrapper>
						
						<TwoColumnLayout>
							
							<FixturesWrapper>
								<PageHeaderWrapper>
									<PageTitle>
									Fixtures for {league && league.name}
									</PageTitle>
									<RoundSelector
										selected={selected_round_id}
										rounds={this.league_rounds}
										handleClick={setSelectedRoundId}
										round_aliases={this.finals_rounds}
									/>
								</PageHeaderWrapper>
								
								<Fixtures
									league={league}
									ladder_teams_by_id={ladder_teams_by_id}
									ladder_teams_ordered={ladder_teams_ordered}
									rounds_with_matches={rounds_with_matches}
									has_assistant_coach={has_assistant_coach}
									coach_players_by_id={coach_players_by_id}
									selected_round_id={selected_round_id}
									is_league_bye_round={this.is_league_bye_round}
									is_league_no_fixtures={this.shouldRenderNoFixturesMessage}
									league_current_round_fixtures=
										{this.league_current_round_fixtures}
									actual_round={actual_round}
									players_by_id={players_by_id}
									finals={this.finals_names}
									league_fixture={true}
									user_id={user_id}
									is_classic
								/>
								<AdFantasyCoach type="leaderboard" small_screen_only />
							</FixturesWrapper>
							<StandardSidebar />
						</TwoColumnLayout>
					</PageContentWrapper>
					<MatchCentreBar />
					<Footer />
				</React.Fragment>
			);
		}
	}
}

const mapStateToProps = (state, props) => {
	return {
		selected_league_id: props.match.params.league_id,
		league: selectors.leaguesClassic.show.getLeague(state, props),
		is_pending_show: selectors.leaguesClassic.show.isPending(state),
		is_pending_ladder: selectors.leaguesClassic.ladder.isPending(state),
		ladder_teams_by_id: selectors.leaguesClassic.ladder.getById(state),
		ladder_teams_ordered: selectors.orderedLadderCalculated(state, props),
		show_league_success: state.leaguesClassic.show.success,
		user_id: selectors.getUser(state).id,
		has_assistant_coach: Boolean(selectors.getUser(state).assistant_coach),
		coach_players_by_id: state.players.coach_by_id,
		actual_round:  selectors.rounds.getActualRound(state),
		players_by_id: selectors.players.getExtendedPlayersById(state),
		rounds_with_matches: selectors.rounds.getRoundsWithMatches(state),
	};
};

const mapDispatchToProps = {
	fetchLeague: actions.showClassicLeague,
	fetchLadder: actions.ladderClassicLeague,
	fetchLeagueRosters: actions.getClassicLeagueRosters,
};

export const ClassicLeagueFixtures = compose(
	withRoundSelector,
	withCoachesBox,
	connect(mapStateToProps, mapDispatchToProps)
)(LeagueFixtures);


export default ClassicLeagueFixtures;