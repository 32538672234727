// @flow

import { Component } from "react";
import _ from "lodash";

export type TResources = {
	[key: string]: {
		fetch: Function,
		exists: boolean
	}
};

type Props = {
	resources: TResources, // resources to be used
	fetchChecksums: Function, // fetchChecksums to be used
	checksums: Object, // Checksums object from state
	interval?: number, // milliseconds
};

type State = {
	fetched: Array<string>
};

export class JsonFetcherCore extends Component<Props, State> {
	state = {
		fetched: []
	};

	componentDidMount() {
		const { fetchChecksums, checksums, interval } = this.props;
		fetchChecksums();
		this.fetchResourcesIfRequired(checksums);

		if (interval) {
			this.interval_id = window.setInterval(this.props.fetchChecksums, interval);
		}
	}

	componentDidUpdate(prev_props: Props) {
		const { interval } = this.props;

		this.fetchResourcesIfRequired(prev_props.checksums);

		// Clearing setInterval when an interval exists but it has changed or been removed
		if (this.interval_id && prev_props.interval && interval !== prev_props.interval) {
			window.clearInterval(this.interval_id);
		}

		// Create setInterval when non exists but interval value has been passed in.
		if (!this.interval_id && interval) {
			this.interval_id = window.setInterval(this.props.fetchChecksums, interval);
		}
	}

	componentWillUnmount() {
		if (this.interval_id) {
			window.clearInterval(this.interval_id);
		}
	}

	interval_id: null;

	fetchResourcesIfRequired(prev_checksums: Object) {
		const { checksums, resources } = this.props;
		const { fetched } = this.state;
		// if no current checksums then don't fetch
		if (_.isEmpty(checksums)) return false;

		// Compare prev_checksums to current checksums,
		// only add to resource_keys_to_update if they are different
		const resource_keys_to_update = Object.keys(resources).filter(resource_key =>
			!_.isEqual(prev_checksums[resource_key], checksums[resource_key])  ||
			(resources[resource_key].exists === false &&
				fetched.includes(resource_key) === false)
		);

		// Trigger resource fetch for those resources that have changed in checksum
		resource_keys_to_update.forEach(resource_key => {
			if (fetched.includes(resource_key)) {
				resources[resource_key].fetch();
			}
			else {
				this.setState(state => ({
					fetched: state.fetched.concat(resource_key)
				}), resources[resource_key].fetch);
			}

		});
	}

	render() {
		return null;
	}
}

export default JsonFetcherCore;