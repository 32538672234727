// @flow
import * as React from "react";
import styled from "styled-components";

import { ButtonSecondaryLink } from "../ButtonSecondary";

import colors from "../../assets/css/colors";

const MessageText = styled.h4`
	font-size: 15px;
	line-height: 1.5;
	font-weight: bold;
	text-align: center;
	color: ${colors.primary.darkGrey};
	margin-bottom: 20px;
`;

const Wrapper = styled.div`
	background: white;
	padding: 30px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

type Props = {
	league_id: number,
	is_classic: boolean,
}

const ByeRoundMessage = ({ league_id, is_classic }: Props) => (
	<Wrapper>
		<MessageText>Your league has chosen not to play during AFL Bye rounds</MessageText>
		<ButtonSecondaryLink to={`/${is_classic ? "classic" : "draft"}/league/${league_id}/`}>
			Go to your League Overview
		</ButtonSecondaryLink>
	</Wrapper>
);

export default ByeRoundMessage;