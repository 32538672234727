import * as format from "../../helpers/numeralFormats";
export const CLASSIC_STATS_OVERVIEW = (round: number) => [
	{
		title: "Total Points",
		short_title: "Total",
		prop: "stats.total_points"
	},
	{
		title: "Last 3 Average",
		short_title: "L3 Avg",
		prop: "stats.last_3_avg"
	},
	{
		title: "Last 5 Average",
		short_title: "L5 Avg",
		prop: "stats.last_5_avg"
	},
	{
		title: "Round Price Change",
		short_title: "Rnd $ Change",
		prop: "stats.cost_round_diff"
	},
	{
		title: "Season Price Change",
		short_title: "$ Change",
		prop: "stats.cost_season_diff"
	},
	{
		title: "$ Per Point",
		short_title: "$ Per Point",
		prop: "stats.cost_divided_by_points"
	},
	{
		title: "% Owned",
		short_title: "% Own",
		prop: "stats.owned_by"
	},
	{
		title: "Highest Score",
		short_title: "High Score",
		prop: "stats.high_score"
	},
	{
		title: "Lowest Score",
		short_title: "Low Score",
		prop: "stats.low_score"
	},
	{
		title: "Round Rank",
		short_title: "Rnd Rnk",
		prop:  `stats.ranks[${round}]`
	},
	{
		title: "Season Rank",
		short_title: "Rank",
		prop: "stats.season_rank"
	},
	{
		title: "Bye Round",
		short_title: "Bye Rnd",
		prop: "",
		is_fc: true
	}
];

export const DRAFT_STATS_OVERVIEW = (statsBasis: String) => {
	return(
		[
			{
				title: "Total Points",
				short_title: "Total",
				prop: `${statsBasis}.total_points`
			},
			{
				title: "Last 3 Average",
				short_title: "L3 Avg",
				prop: `${statsBasis}.last_3_avg`
			},
			{
				title: "Last 5 Average",
				short_title: "L5 Avg",
				prop: `${statsBasis}.last_5_avg`
			},
			{
				title: "Highest Score",
				short_title: "Highest",
				prop: `${statsBasis}.high_score`
			},
			{
				title: "Lowest",
				short_title: "Lowest",
				prop: `${statsBasis}.low_score`
			},
			{
				title: "Round Rank",
				short_title: "Rnd Rnk",
				prop: `${statsBasis}.round_rank`
			},
			{
				title: "Season Rank",
				short_title: "Rank",
				prop: `${statsBasis}.season_rank`
			},
			{
				title: "Bye Round",
				short_title: "Bye",
				prop: "",
				is_fc: true
			}
		]
	);
}; 


export const CLASSIC_FIXTURE_STATS = [
	{
		name: "Projected",
		id: "stats.proj_scores",
		is_coach: true,
		tooltip: "Projected Score"
	},
	{
		name: "Proj $ Change",
		id: "stats.proj_price_change",
		format: format.SHORT_PRICE,
		is_coach: true,
		not_draft: true,
		tooltip: "Projected Round Price Change"
	},
	{
		name: "BE",
		id: "stats.break_evens",
		is_coach: true,
		not_draft: true,
		tooltip: "Break Even"
	},
	{
		name: "BE %",
		id: "stats.be_pct",
		format: format.PERCENTAGE,
		is_coach: true,
		not_draft: true,
		tooltip: "Percentage Chance of Reaching Break Even"
	},
	{
		name: "Avg vs Opp",
		id: "stats.opponents",
		is_coach: true,
		tooltip: "Average Score Against Opponent"
	},
	{
		name: "Venue Avg",
		id: "stats.avg_at_venue",
		is_coach: true,
		tooltip: "Average Score At Venue"
	},
];

export const DRAFT_FIXTURE_STATS = (statsBasis: string) => [
	{
		name: "Projected",
		id: "proj_scores",
		is_coach: true,
		tooltip: "Projected Score",
	},
	{
		name: "Avg vs Opp",
		id: "opponents",
		is_coach: true,
		tooltip: "Average Score Against Opponent"
	},
	{
		name: "Venue Avg",
		id: "venues",
		is_coach: true,
		tooltip: "Average Score At Venue"
	},
];