// @flow
import styled from "styled-components";
import * as React from "react";
import { below } from "../../assets/css/media";
import {
	PlayerTableCell,
	PlayerInfo,
	UndoButton, TradePlayerCost, WrappedTradeIcon
} from "../";
import { isEditTradePage } from "../../helpers";
import {TPlayer} from "../../modules/types";
import { currencyFormat } from "../../utils/currencyFormat/index";
import Undo from "../Icons/Undo";
import Minus from "../Icons/Minus";
import Plus from "../Icons/Plus";
import colors from "../../assets/css/colors";
import { TradeCell } from "./TradeCell";




const InCellStyled = styled(TradeCell)`
	background-color: #F0F3F7;
	${({isLocked}) => isLocked && `
		opacity: 0.7;
	`}
	${({isEditPage}) => isEditPage && `
		.undo-button{
			svg{
				background: ${colors.primary.primary};
			}
		}
	`}
	
	/* padding-left: 20px; */
	margin-bottom: 10px;
	border: 1px solid #CAD2D8;
	.player-info{
		margin-left: 10px;
		${below.desktop}{
			margin: 0;
		}
		
	}
	.player-status-show{
		display: none;
	}
	.filled-icon {
		margin-left: auto;
		margin-right: 4em;
		${below.desktop`
			margin-right: 1em;
		`}
	}
	${below.desktop`
		margin: ${({ is_right }) => is_right ? "2px 0 2px 2px" : "2px 2px 2px 0"};
	`}
	${below.phone`
		.player-details-status{
			display: none;
		};
		.player-status-show{
			display: block;
			align-self: center;
			height: 12px;
			width: 12px;
			margin-right: 5px;
			svg{
				height: 12px;
				width: 12px;
			}
		}
		.player-avatar{
			display: block;
		}
	`}
`;

type TCell = {
	removePlayer?: Function,
	player: TPlayer,
	with_cost?: boolean,
	is_compressed?: boolean,
	is_classic: boolean,
	modal?: string,
  right?: boolean,
	status_show?: boolean,
	updateTradeEdit?: Function,
	isPlayerChangeAvailable?: boolean
};
export const TradeInCell = ({ 
	player, 
	removePlayer, 
	with_cost = false, 
	is_compressed, 
	is_classic,
	modal,
	status_show,
	right,
	updateTradeEdit,
	isPlayerChangeAvailable
}: TCell) => {
	const getModalIcon = () => {
		if (modal === "plus"){
			return(
				<WrappedTradeIcon bg="#7ED321">
					<Plus />
				</WrappedTradeIcon>
			);
		}
		if(modal === "minus"){
			return(
				<WrappedTradeIcon bg="#D92425">
					<Minus />
				</WrappedTradeIcon>
			);
		}
		return null;
	};

	const isLocked = isEditTradePage() && !isPlayerChangeAvailable;
	const isEditPage = isEditTradePage();
	return (
		<InCellStyled is_right={right} isLocked={isLocked} isEditPage={isEditPage}>
			<PlayerTableCell
				className="player-table-cell"
				width="auto"
				height="60px"
				justify="space-between"
			>
				<PlayerInfo player={player} 
					is_compressed={is_compressed} 
					is_classic={is_classic} 
					status_show={status_show}/>
				{(removePlayer && !isLocked) ? (
					<UndoButton
						className="undo-button"
						onClick={() => {
							if(updateTradeEdit){
								updateTradeEdit();
							}
							removePlayer({ id: player.id });
						} }
					>
						<Undo />
					</UndoButton>
				) : null}
			</PlayerTableCell>
			{with_cost && (
				<TradePlayerCost margin="right" is_double>
					{currencyFormat({ input: player.cost })}
				</TradePlayerCost>
			)}
			{getModalIcon()}
		</InCellStyled>
	);
};

export default TradeInCell;