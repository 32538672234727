// @flow
import type { Saga } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import * as actions from "../../actions/index";

export const createSagas = (API: Object): Object => {

	function* fetchNewsSaga(): Saga<void> {
		const result = yield call(API.News.get);
		yield put(actions.fetchNewsSuccess(result));
	}

	function* watch() {
		yield takeLatest(actions.fetchNews, fetchNewsSaga);
	}

	return {
		fetchNewsSaga,
		watch
	};
};

export default createSagas;