/* eslint max-len: 0 */
// @flow
import React from "react";
import SvgIconContainer from "../SvgIconContainer";

export default (
	{
		unit = "em",
		title,
		...rest
	}: Object = {}) => (
	<SvgIconContainer viewBox="0 0 16 16" {...rest} unit={unit} strokeWidth="0" stroke="#B55DAE" fillRule="evenodd" fill="#FFFFFF">
		<title>{title ? title : "Star"}</title>
		<path d="M12.282905,15.1490094 C12.4024081,15.1487732 12.5188079,15.1108871 12.6182856,15.0387692 C12.7912273,14.9151338 12.8778453,14.7032037 12.8406279,14.4916407 L12.0727208,10.0104593 L12.2608589,9.82701394 L15.3319289,6.83257167 C15.4843399,6.68646039 15.5387765,6.46553272 15.4700748,6.26041838 C15.4043906,6.05743744 15.2285556,5.90979292 15.0146966,5.87984356 L10.5177215,5.22634225 L10.4014027,4.99056139 L8.50948026,1.15559136 C8.41371421,0.962294516 8.21664453,0.84 8.00092526,0.84 C7.78520598,0.84 7.5881363,0.962294516 7.49259886,1.15512933 L5.48044822,5.22705766 L5.22048951,5.26484459 L0.983357892,5.88072819 C0.771534665,5.90985374 0.594827908,6.05714765 0.528034109,6.26026329 C0.46124031,6.46337894 0.516038142,6.68680224 0.669285573,6.83601393 L3.92934328,10.0111551 L3.88467995,10.2705443 L3.15829475,14.4890575 C3.12332688,14.7015951 3.21176213,14.915599 3.38656626,15.0414501 C3.56137039,15.1673012 3.79237498,15.1832798 3.98366035,15.0822629 L8.00092526,12.970245 L8.23359727,13.0925689 L12.0201071,15.0832761 C12.1010433,15.1262761 12.1912606,15.1488408 12.282905,15.1490094 Z M12.282905,15.1490094 C12.2830738,15.1490097 12.2832426,15.1490099 12.2834114,15.1490101 L12.2823985,15.1490101 C12.2825674,15.14901 12.2827362,15.1490097 12.282905,15.1490094 Z" id="Shape" />
	</SvgIconContainer>
);