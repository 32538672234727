// @flow
import _ from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import { ScrollTo } from "react-scroll-to";
import styled from "styled-components";
import type { TRoundWithMatches } from "../../modules/types";
import { getActualMatch } from "../../helpers/rounds";

// import colors from '../../assets/css/colors';

import * as selectors from "../../modules/selectors";
import Arrow from "../Icons/Arrow";

import RoundList from "./RoundList";

const Container = styled.div`
	background: #F0F3F7;
  
`;

const InnerContainer = styled.div`
	max-width: 1280px;
	margin: 0 auto;

	display: flex;
	justify-content: space-between;
  position: relative;
`;

const ArrowButton = styled.button.attrs({
	type: "button"
})`
	background: transparent;
	border: 0 none;
	margin: 0;
	padding: 0;

	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 20px;
	width: 30px;
	flex: 0 0 30px;
	z-index: 1;

	/* ${({ hide_shadow, right }) => !hide_shadow && `
		// box-shadow: ${right && "-22px 0px 30px -13px rgba(240,243,247,0.75)"};
	`} */
  ${({ right }) => right && `
    position: absolute;
    height: 100%;
    width: 90px;
    justify-content: flex-end;
    right: 0;
    background: linear-gradient(90deg, rgba(249,250,252,0), rgba(240,243,247,1));
    svg{
      margin-right: 5px;
    }
  `}
`;

const List = styled.div`
    position: relative;
	flex: 1 10 auto;
	display: flex;
	overflow-x: auto;
`;

type Props = {
	actual_round: TRoundWithMatches,
	rounds_with_matches: Array<TRoundWithMatches>
}

const getScrollDistance = container_width => container_width / 4 * 3;

class MatchCentreBarComponent extends React.PureComponent<Props> {
	componentDidMount() {
		this.positionListScroll();
	}

	componentDidUpdate() {
		this.positionListScroll();
	}

	ListRef = React.createRef();

	get ListRefElement() {
		return this.ListRef.current;
	}

	get actual_match() {
		const { actual_round } = this.props;

		if (actual_round) {
			return getActualMatch(actual_round.matches);
		}

		return null;
	}

	get actual_round_panel() {
		const { actual_round } = this.props;

		if (actual_round && actual_round.id) {
			return window.document.getElementById(`R-${actual_round.id}`);
		}

		return null;
	}

	get actual_match_panel() {
		if (this.actual_match) {
			return window.document.getElementById(`M-${this.actual_match.id}`) || null;
		}

		return null;
	}

	get last_match() {
		const { actual_round } = this.props;
		if (actual_round && actual_round.matches) {
			return _.last(actual_round.matches);
		}

		return null;
	}

	get last_match_panel() {
		if (this.last_match) {
			return window.document.getElementById(`M-${this.last_match.id}`);
		}

		return null;
	}

	get is_ready_to_set_scroll_position() {
		const { actual_round } = this.props;

		return actual_round &&
			this.actual_match &&
			this.actual_round_panel &&
			this.actual_match_panel &&
			this.actual_match_panel.offsetLeft &&
			this.actual_match_panel.offsetWidth &&
			this.ListRefElement;
	}

	get container_width() {
		if (this.ListRefElement) {
			return this.ListRefElement.offsetWidth;
		}

		return null;
	}

	get actual_round_end_offset_position() {
		if (this.last_match_panel && this.container_width) {
			return (this.last_match_panel.offsetLeft + this.last_match_panel.offsetWidth) -
				this.container_width;
		}

		return 0;
	}

	positionListScroll() {
		if (this.is_ready_to_set_scroll_position) {
			const ideal_scroll_position = // $FlowFixMe
				this.actual_match_panel.offsetLeft - this.actual_match_panel.offsetWidth;

			const is_ideal_past_end_of_round =
				ideal_scroll_position > this.actual_round_end_offset_position;

			const scroll_position = is_ideal_past_end_of_round ?
				this.actual_round_end_offset_position : ideal_scroll_position;

			// $FlowFixMe
			this.ListRefElement.scrollLeft = scroll_position;
		}
	}

	render() {
		const { rounds_with_matches = [], actual_round } = this.props;
		return (
			<Container className="playwright-mask-hidden">
				<InnerContainer>

					<ScrollTo>
						{({ scroll }) => (
							<ArrowButton
								onClick={() => scroll({
									id: "match-centre-bar",
									ref: this.ListRef,
									x: this.ListRefElement ? this.ListRefElement.scrollLeft -
										getScrollDistance(this.ListRefElement.offsetWidth): 0,
									smooth: true
								})}
							>
								<Arrow direction="left" />
							</ArrowButton>
						)}
					</ScrollTo>

					<List ref={this.ListRef} id={"match-centre-bar"}>
						{rounds_with_matches.map(round => (
							<RoundList key={round.id} round={round} actual_round={actual_round} />
						))}
						
					</List>
					<ScrollTo>
						{({ scroll }) => (
							<ArrowButton
								right
								onClick={() => scroll({
									id: "match-centre-bar",
									ref: this.ListRef,
									x: this.ListRefElement ? this.ListRefElement.scrollLeft +
										getScrollDistance(this.ListRefElement.offsetWidth): 0,
									smooth: true
								})}
							>
								<Arrow direction="right" />
							</ArrowButton>
						)}
					</ScrollTo>

					
				</InnerContainer>
			</Container>
		);
	}
}

const mapStateToProps = state => ({
	actual_round: selectors.rounds.getActualRound(state),
	rounds_with_matches: selectors.rounds.getRoundsWithMatches(state)
});

export const MatchCentreBar = connect(mapStateToProps)(MatchCentreBarComponent);

export default MatchCentreBar;