// @flow
import * as React from "react";

import { Field } from "redux-form";
import { FormField, FormSelect, FormRadio } from "../../../../../components";
import type { TRound } from "../../../../../modules/types";

import { LEAGUE_SIZE } from "../../../../../helpers/index";

import { league_tooltips } from "../../../../../helpers";

type Props = {
	rounds: TRound[],
	league_type: string,
}

const leagueTooltipsName = {...league_tooltips.name, show_on_left:true};
const leagueTooltipsType = {...league_tooltips.type, show_on_left:true};

export const LeagueSettings = ({ rounds, league_type }: Props) => (
	<div>
		<Field
			label='League name'
			type='text'
			name='name'
			placeholder='League name'
			component={FormField}
			tooltip={leagueTooltipsName}
		/>

		<FormRadio
			label='Privacy settings'
			name='privacy'
			elements={[
				{
					value: 0,
					text: "Private",
				},
				{
					value: 1,
					text: "Public",
				}
			]}
			tooltip={league_tooltips.privacy}
			is_disabled={false}
		/>

		<FormRadio
			label='League type'
			name='type'
			elements={[
				{
					value: "head_to_head",
					text: "Head to Head",
				},
				{
					value: "open",
					text: "Open",
				}
			]}
			is_string_value={true}
			width='50%'
			tooltip={leagueTooltipsType}
			is_disabled={false}
		/>

		{league_type === "head_to_head" ? (
			<Field
				label='League size'
				name='size'
				options={LEAGUE_SIZE}
				component={FormSelect}
				tooltip={league_tooltips.size}
			/>
		) : null}

		<Field
			label='League start round'
			name='start_round'
			options={rounds}
			component={FormSelect}
			tooltip={league_tooltips.start_round}
		/>
		<FormRadio
			label='Coaches box'
			name='coaches_box'
			elements={[
				{
					value: 1,
					text: "On",
				},
				{
					value: 0,
					text: "Off",
				}
			]}
			tooltip={league_tooltips.coaches_box}
			is_disabled={false}
		/>
	</div>
);

export default LeagueSettings;