// @flow
import React from "react";
import * as _ from "lodash";
import { 
	TPosition, 
	TRound,
	TRoundWithMatches, 
	TPlayersArrayWithFixtureById, 
	TDraftUserTeam,
	TPLeague,
	TMatch
} from "../../../../modules/types";
import { 
	FieldRowLabel, 
	FieldPlayers, 
	FieldRow,
	Field,
	BenchOverlay,
	FieldBackground
} from "../../../../components/Field";
import OverflowUtils from "./overflowUtils";
import DraftFieldPlayer from "./fieldPlayer";

type Props = {
	team: TDraftUserTeam,
	league: TPLeague,
	positions: { [number]: TPosition },
	players_by_id: TPlayersArrayWithFixtureById,
	has_assistant_coach: boolean,
	actual_round: TRound,
	player_ids: Array<number>,
	selected_round: TRoundWithMatches,
	is_mobile: boolean,
	field_stat: string,
	showModalForPlayerId : Function,
	removePlayer: Function,
	setCaptain: Function,
	setViceCaptain: Function,
	setEmergency: Function,
	openPlayerPopup: Function,
	areSubstitutesActive: Function,
	substitute_is_bench: boolean,
	substitute_id: number,
	isOutSubstitute: Function,
	isInSubstitute: Function,
	substitutePlayer: Function,
	cancelSubstitute: Function,
	matches: Array<TMatch>,
	is_static: boolean,
	is_current: boolean,
	bye_detector_selected: number[],
	bye_detector_active: boolean,
	rounds: TRound[],
	mobile_stat: Object,
	score_stat: Object,
	flip: boolean,
	toggleFantasyCoachModal: Function,
	setPlayerAction: Function,
	is_classic: boolean,
	is_team_full?: boolean,
  is_overflow?: boolean,
};

type State = {

};

export class FieldViewTeam extends React.Component<Props, State> {
	constructor() {
		super();

		_.bindAll(this, [
			"renderPositionGroup",
			"renderPlayer"
		]);
	}

	/*
	 * Returns a list of the different personnel who are locked
	 * i.e. [ 'captain', 'emergency.1', 'emergency.2' ]
	 */
	get locked_personnel() {
		const { positions, team: { lineup }, players_by_id } = this.props;

		// Checks if the player at the given path is locked
		const getPersonnelLocked = path => (
			Boolean(_.get(players_by_id, [_.get(lineup, path), "locked"], false))
		);

		const personnel_paths = [
			"captain", "vice_captain", 
			..._.map(positions, p => `emergency.${p.id}`)
		];

		return _(personnel_paths)
			.map(path => {
				const locked = getPersonnelLocked(path);

				return { path, locked };
			})
			.filter(({ locked }) => locked)
			.map(({ path }) => path)
			.value();
	}

	get max_squad_size() {
		return OverflowUtils.maxSquadSize(this.props.league);
	}

	get actual_squad_size() {
		const { team: { lineup }, positions } = this.props;
		return OverflowUtils.actualSquadSize(lineup, positions);
	}

	get lineup() {
		const { team: { lineup }, positions, league } = this.props;
		return OverflowUtils.normaliseLineup(league, lineup, positions);
	}
	
	renderPositionGroup(position: TPosition, index: number) {
		return <FieldRow is_draft key={position.id}>
			<FieldRowLabel is_draft>
				<div>{position.full_name}</div>
			</FieldRowLabel>
			<FieldPlayers is_draft>
				{_.map(
					this.lineup[position.id],
					_.partial(this.renderPlayer, _, _, false, position)
				)}
			</FieldPlayers>
		</FieldRow>;
	}

	renderBench() {
		const overflow_size = this.actual_squad_size - this.max_squad_size;
		const { bench = [] } = this.lineup;
		const bench_players = _.slice(bench, 0, bench.length - overflow_size);
		if(this.props.substitute_id !== 0 && !this.props.substitute_is_bench){
			bench_players.push(0);
		}
		const overflow_players = _.slice(bench, bench.length - overflow_size);
		return <React.Fragment>
			{ this.renderBenchSection(bench_players, false) }
			{ !_.isEmpty(overflow_players) && this.renderBenchSection(overflow_players, true) }
		</React.Fragment>;
	}

	renderBenchSection(players: Object[], is_overflow: boolean) {
		return <FieldRow is_draft key={is_overflow ? "overflow" : "bench"}>
			<BenchOverlay is_overflow={is_overflow} />
			<FieldRowLabel is_draft>
				<div>{ is_overflow ? "Overflow" : "Interchange"}</div>
			</FieldRowLabel>
			<FieldPlayers is_draft>
				{_.map(
					players, 
					_.partial(this.renderPlayer, _, _, true, {})
				)}		
			</FieldPlayers>
		</FieldRow>;
	}

	renderPlayer(player_id: number, index: number, is_bench: boolean, position: TPosition) {
		const {
			team,
			players_by_id,
			league,
			removePlayer,
			setCaptain,
			setViceCaptain,
			setEmergency,
			substitutePlayer,
			cancelSubstitute,
			openPlayerPopup,
			is_mobile,
			has_assistant_coach,
			field_stat,
			areSubstitutesActive,
			isOutSubstitute,
			isInSubstitute,
			selected_round,
			matches,
			is_static,
			is_current,
			bye_detector_selected,
			bye_detector_active,
			mobile_stat,
			toggleFantasyCoachModal,
			flip,
			setPlayerAction,
			is_team_full,
			rounds,
			actual_round,
			is_overflow
		} = this.props;

		return <DraftFieldPlayer
			key={`${position ? position.id : "bench"}-${index}`}
			player_id={player_id}
			players_by_id={players_by_id}
			position={position}
			team={team}
			league={league}
			is_bench={is_bench}
			removePlayer={removePlayer}
			setCaptain={setCaptain}
			setViceCaptain={setViceCaptain}
			setEmergency={setEmergency}
			substitutePlayer={substitutePlayer}
			cancelSubstitute={cancelSubstitute}
			openPlayerPopup={openPlayerPopup}
			rounds={rounds}
			is_mobile={is_mobile}
			has_assistant_coach={has_assistant_coach}
			field_stat={field_stat}
			areSubstitutesActive={areSubstitutesActive}
			isOutSubstitute={isOutSubstitute}
			isInSubstitute={isInSubstitute}
			position_index={index}
			selected_round={selected_round}
			matches={matches}
			locked_personnel={this.locked_personnel}
			is_static={is_static}
			is_current={is_current}
			bye_detector_selected={bye_detector_selected}
			bye_detector_active={bye_detector_active}
			mobile_stat={mobile_stat}
			toggleFantasyCoachModal={toggleFantasyCoachModal}
			flip={flip}
			setPlayerAction={setPlayerAction}
			is_team_full={is_team_full}
			actual_round={actual_round}
			is_overflow={is_overflow}
		/>;
	}

	render() {
		const { 
			positions, 
			is_static,
			bye_detector_active, 
			cancelSubstitute,
			areSubstitutesActive,
			is_classic
		} = this.props;

		return <Field 
			is_draft 
			is_locked={is_static || (bye_detector_active && is_classic)}
			is_substitute={areSubstitutesActive()}
			onClick={cancelSubstitute} 
		>
			<FieldBackground is_locked={is_static || (bye_detector_active)} />
			{ _.map(positions, this.renderPositionGroup) }
			{ this.renderBench() }
		</Field>;
	}
};

export default FieldViewTeam;