// @flow
import * as React from "react";
import styled from "styled-components";
import { ButtonSecondary } from "../../../../components";
import Tick from "../../../../components/Icons/Tick";

const Button = styled(ButtonSecondary)`
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 120px;
	flex-direction: row;
	min-width: 7em;
	background-color: #198DE1;
	&:hover {
		opacity: .7;
	}

	svg {
		position: relative;
		left: 10px;
		top: -1px;
	}
`;

type Props = {
	is_saved?: boolean,
	is_keeper?: boolean
}

export const SaveButton = ({ is_saved, is_keeper, ...rest }: Props) => (
	<Button {...rest}>
		{is_saved ? (
			<React.Fragment>
				<span>Saved</span>
				<Tick/>
			</React.Fragment>
		) : (
			<span>Save {is_keeper ? "keepers" : "queue"}</span>
		)}
	</Button>
);

SaveButton.defaultProps = {
	is_saved: false,
};

export default SaveButton;