// @flow
import * as React from "react";
import _ from "lodash";
import styled from "styled-components";
import moment from "moment";
import colors from "../../../../assets/css/colors";
import isTimeOut from "../../../../utils/isTimeOut";
import {
	ButtonPrimary,
	CountdownTimer,
	LeagueAvatar,
	LeagueName,
	ModalContainer,
} from "../../../../components";
import { below } from "../../../../assets/css/media";
import { lockoutContent } from "../../../../helpers";
import type { TPLeague } from "../../../../modules/types";

const LeagueAvatarStyled = styled(LeagueAvatar)``;

const JoinLeagueSettingsPopupHeading = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	background: #fff;
	height: 100px;
	padding: 75px;
	box-sizing: border-box;
	
	${below.tablet`
		height: auto;
		display: block;
		margin: 45px 0 0 0;
		padding: 0 0 0 10px;

		${LeagueAvatarStyled} {
			display: none;
		}
	`}

	> div {
		display: flex;
		align-items: center;
		box-sizing: border-box;
		
		${below.tablet`
			display: block;
			width: 100%;
		`}
	}

	img {
		margin-right: 1em;
	}
`;

const JoinLeagueSettingsPopupOption = styled.div`
	padding: 0 25px;
	box-sizing: border-box;
	margin: 10px 0;
	
	${below.tablet`
		padding: 0 10px;
	`}

	h3 {
		color: ${colors.primary.primary};
		font-size: 15px;
		font-weight: bold;
		line-height: 1.46;
	}

	> div {
		margin: .5em auto;
		box-sizing: border-box;

		> div {
			background: #fff;
			display: flex;
			align-items: center;
			box-sizing: border-box;
			justify-content: space-between;

			p:first-child {
				color: ${colors.primary.primary};
				font-size: 12px;
				line-height: 1.66;
			}
			p:last-child {
				color: ${colors.secondary.accentGrey};
				font-size: 12px;
				line-height: 1.66;
				margin-left: 1em;
			}
		}
	}
`;
const JoinLeagueSettingsOptionWrapper = styled.div`
	height: 350px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`;


const JoinLeagueSettingsPopupFooter = styled.div`
	margin: 20px 0 2.5em;
	
	${below.tablet`
		padding-left: 10px;
	`}

	button {
		width: 200px;
	}
`;

const CountdownWrapper = styled.div`
	justify-content: flex-end;

	.countdown-title {
		font-size: 10px;
		letter-spacing: 0.5px;
		line-height: 1.4;
		color: ${colors.primary.primary}
		margin-right: 1em
	}
`;

const LeagueNameStyled = styled(LeagueName)`
	margin-left: 1em;
	text-align: left;
	${below.tablet`
		margin: 1em 0 0 0;
	`};
`;

const onOff = param => param ? "ON" : "OFF";
const waiverPeriod = period => period === 1 ? "1 day" : "2 days";
const waiverOrder = order => order ? "Last pick to bottom" : "Reverse ladder order";

type PropsOption = {
	label: string,
	value: string | number,
	is_uppercase?: boolean
};
const PopupOption = ({ label, value, is_uppercase }: PropsOption) => (
	<div>
		<p>{label}</p>
		<p className={is_uppercase ? "uppercase" : ""}>{value}</p>
	</div>
);

type Props = {
	league: TPLeague,
	onClick: Function,
	join: Function
};

export const JoinLeagueSettingsPopup = ({ league, onClick, join }: Props) => {
	if (!league) {
		return null;
	}
	const custom_scoring_enabled = _.get(league, "custom_scoring_enabled", false);
	return (
		<ModalContainer is_sponsored={false} onClick={onClick}>
			<JoinLeagueSettingsPopupHeading>
				<div>
					<LeagueAvatarStyled league={league} size="60px" />
					<LeagueNameStyled width="300px"
						name={league.name}
						lastname={league.lastname}
						firstname={league.firstname}
					/>
				</div>
				{!isTimeOut(league.draft_start) ? (
					<CountdownWrapper>
						<CountdownTimer date={league.draft_start} is_uppercase={true} />
					</CountdownWrapper>
				) : null}
			</JoinLeagueSettingsPopupHeading>
			<JoinLeagueSettingsOptionWrapper>
				<JoinLeagueSettingsPopupOption>
					<h3>Basic Settings</h3>
					<div>
						<PopupOption label="League Size" value={league.size} />
						<PopupOption label="Starting Round" value={league.start_round} />
						<PopupOption label="Privacy Settings"
							value={league.privacy ? "PUBLIC" : "PRIVATE"}
						/>

					</div>
				</JoinLeagueSettingsPopupOption>

				<JoinLeagueSettingsPopupOption>
					<h3>Advanced Settings</h3>
					<div>
						<PopupOption label="League Finals Series"
							value={league.finals ? league.finals_format : "OFF"}
						/>
						<PopupOption label="Play Opponents"
							value={league.play_times}
							is_uppercase={true}
						/>
						<PopupOption label="Bye Rounds"
							value={onOff(league.bye_rounds)}
						/>
						<PopupOption label="Coaches Box"
							value={onOff(league.coaches_box)}
						/>
						<PopupOption label="Auto updating of DPP"
							value={onOff(league.original_positions)}
						/>
					</div>
				</JoinLeagueSettingsPopupOption>

				<JoinLeagueSettingsPopupOption>
					<h3>Draft Options</h3>
					<div>
						<PopupOption label="Draft Type"
							value={league.draft_type}
							is_uppercase={true}
						/>
						<PopupOption label="Draft Order"
							value={league.draft_order}
							is_uppercase={true}
						/>
						<PopupOption label="Draft Turn Time"
							value={league.draft_turn}
						/>
						<PopupOption label="Draft Date & Time"
							value={moment(league.draft_start).format("DD/MM/YY HH:mm")}
						/>
					</div>
				</JoinLeagueSettingsPopupOption>

				<JoinLeagueSettingsPopupOption>
					<h3>Game Play</h3>
					<div>
						<PopupOption label="Squad and Size Layout"
							value={league.squad_size}
						/>
						<PopupOption label="Captains" value={onOff(league.captains)} />
						<PopupOption label="Emergency" value={onOff(league.emergency)} />
						<PopupOption label="Lockout Type" value={lockoutContent(league)} />
						<PopupOption label="Custom Scoring" value={onOff(custom_scoring_enabled)} />
						
					</div>
				</JoinLeagueSettingsPopupOption>

				<JoinLeagueSettingsPopupOption>
					<h3>Trades & Restricted Free Agents</h3>
					<div>
						<PopupOption label="Restricted Free Agents"
							value={onOff(league.trade_waivers)}
						/>

						{league.trade_waivers ? (
							<PopupOption label="RFA Time Period"
								value={waiverPeriod(league.waiver_period)} />
						) : null}
						{league.trade_waivers ? (
							<PopupOption label="RFA Order Policy"
								value={waiverOrder(league.waiver_order)} />
						) : null}
						<PopupOption label="RFA privacy settings"
							value={league.rfa_privacy}
							is_uppercase={true}
						/>
						<PopupOption label="Trade Approval Options"
							value={league.approve_trade}
							is_uppercase={true}
						/>
					</div>
				</JoinLeagueSettingsPopupOption>
			</JoinLeagueSettingsOptionWrapper>
			

			<JoinLeagueSettingsPopupFooter>
				<ButtonPrimary onClick={join.bind(this, league.id)} width="200px">
					Join this league
				</ButtonPrimary>
			</JoinLeagueSettingsPopupFooter>
		</ModalContainer>
	);
};

export default JoinLeagueSettingsPopup;