// @flow
import React from "react";
import SvgIconContainer from "../SvgIconContainer";

export default (
	{
		unit = "em",
		...rest
	}: Object = {}) => (
	<SvgIconContainer viewBox="0 0 16 16" {...rest} unit={unit}>
		<g stroke="none" strokeWidth="1" fillRule="evenodd">
			<path d="M5.18620646,12.63479 C4.56974296,13.2512535 4.58047168,14.2174334
				5.18301058,14.8199723 C5.78975027,15.426712 6.7638889,15.4210803 7.36819283,
				14.8167764 L12.816767,9.36820224 C13.1227983,9.06217091 13.2730798,8.66780903
				13.2725107,8.27358879 C13.2740189,7.87511397 13.1207117,7.48376882 12.8199629,
				7.18301999 L7.36819283,1.73124994 C6.75172934,1.11478645 5.78554948,1.12551516
				5.18301058,1.72805406 C4.57627089,2.33479375 4.58190253,3.30893238 5.18620646,
				3.91323631 L9.54698332,8.27401318 L5.18620646,12.63479 Z" />
		</g>
	</SvgIconContainer>
);