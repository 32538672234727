// @flow
import * as React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

import {
	OneColumnLayout,
	AdsContainer,
	Ad,
	Footer,
} from "../components";

import Whistle from "../components/Icons/Whistle";

import colors from "../assets/css/colors";
import { below } from "../assets/css/media";

const OneColumnLayoutStyled = styled(OneColumnLayout)`
	border: 1px solid #ecf1f5;
	margin: 1em auto;
	padding: 2em;
	text-align: center;
	background: ${colors.form.base};
    max-width: 920px;
	${below.desktop`
		padding: 2em 0;
	`}
`;

const Title = styled.h2`
	margin-top: 20px;
	font-size: 42px;
	color: ${colors.primary.primary};
`;

const Description = styled.p`
	color: #000000;
	font-size: 14px;
	max-width: 390px;
	line-height: 20px;
	margin: 1em auto;
	font-family: SourceSansPro;
`;

const NavLinkStyled = styled(NavLink)`
	width: 200px;
	height: 40px;
	background: ${colors.primary.primary};
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid ${colors.primary.primary};
	color: #ffffff;
	font-size: 14px;
	line-height: 14px;
	cursor: pointer;
	border-radius: 2px;
	text-decoration: none;
	margin: 20px auto;
`;

export const NoMatch = () => {

	return (
		<React.Fragment>
			<AdsContainer>
				<Ad/>
			</AdsContainer>
			<OneColumnLayoutStyled>
				<div>
					<Whistle size={6} />
					<Title>404 : Page missing</Title>
					<Description>
						Unfortunately we couldn’t find the page you were looking for.
						Please try to refresh this page or
					</Description>
					<NavLinkStyled to="/">
						Return to the homepage
					</NavLinkStyled>
				</div>
			</OneColumnLayoutStyled>
			<Footer />
		</React.Fragment>
	);
};

export default NoMatch;