// @flow

import type { TNews } from "../news";
import type { TUserReducer } from "./userReducer";
import type { TSquadsReducer } from "./squadsReducer";
import type { TPositionsReducer } from "./positionsReducer";
import type { TPlayersReducer } from "./playersReducer";
import type { TPlayerStatusesReducer } from "./playerStatusesReducer";
import type { TTeamsDraftReducer } from "./teamsDraftReducer";
import type { TRoundsReducer } from "./roundsReducer";
import type { TCoachStatsListReducer } from "./coachStatsListReducer";
import type { TUiMenuReducer } from "./uiMenuReducer";
import type { TUiSelectedLeagueReducer } from "./uiSelectedLeagueReducer";
import type { TUiGameSelectionReducer } from "./uiGameSelectionReducer";
import type { TAFLIdModalStatus } from "./aflIdModalReducer";

export type TRootStore = {
	aflIdModalStatus: TAFLIdModalStatus,
	positions: TPositionsReducer,
	user: TUserReducer,
	squads: TSquadsReducer,
	players: TPlayersReducer,
	playerStatuses: TPlayerStatusesReducer,
	teamsDraft: TTeamsDraftReducer,
	rounds: TRoundsReducer,
	help: Object,
	leagues: Object,
	coachStatsList: TCoachStatsListReducer,
	uiMenu: TUiMenuReducer,
	uiSelectedLeague: TUiSelectedLeagueReducer,
	uiGameSelection: TUiGameSelectionReducer,
	is_pending: boolean,
	playerModal: { player_id: number },
	news: TNews,
	leaguesClassic: Object,
	offSeasonRankings: Object,
	playerNews: []
};

export * from "./userReducer";
export * from "./squadsReducer";
export * from "./positionsReducer";
export * from "./playerStatusesReducer";
export * from "./teamsDraftReducer";
export * from "./coachStatsListReducer";
export * from "./uiMenuReducer";
export * from "./teamsDraftTradesReducer";