// @flow
import styled from "styled-components";
import { ButtonPrimary } from "../ButtonPrimary";


export const OpenTeamFieldButton = styled(ButtonPrimary)`
	background:#8D979C;
	border: none;
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: #FFF;
	cursor: pointer;
	outline: none;
	padding: ${({ inverted }) => inverted ? "5px 0 5px 12px" : "5px 12px 5px 0"};
	font-size: 13px;

	svg {
		min-width: 24px;
		min-height: 24px;
		margin-right: 3px;
	}
`;

export default OpenTeamFieldButton;