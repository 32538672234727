// @flow

export const leagueValidate = (values: Object) => {
	const errors = {};

	if (!values.name) {
		errors.name = "Required";
	}
	else if (values.name.length > 40) {
		errors.name = "Must be 40 characters or less";
	}

	return errors;
};