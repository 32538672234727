// @flow
import * as _ from "lodash";
import type { TMatch, TRoundTypes, TArrayOfRoundTypes, TLockoutInfo } from "../../modules/types";

type TMatches = Array<TMatch>;

type TRoundWithMatches = {
	matches: TMatches,
	id?: number
};

export const matchStarted = (round: TRoundWithMatches, squad_id: number): boolean => {
	const match = round ? findMatch(round.matches, squad_id) : undefined;
	return match !== undefined && !_.isEmpty(match) && match.status !== "scheduled"; 
};

export const lastRoundFinished = (
	round: TRoundWithMatches, 
	squad_id: number, 
	rounds: Array<TRoundWithMatches>
) => {
	const last = _.find(rounds, { id: _.get(round, "id", 0) - 1 });

	return last && last.status !== "scheduled" && matchStarted(last, squad_id);
};

export const findMatch = (matches: Array<TMatch>, squad_id: number) => {
	return _.find(matches, m => m.home_squad_id === squad_id || m.away_squad_id === squad_id);
};


// Match helpers
export const isMatchComplete = (match: TMatch): boolean => match.status === "complete";
export const isMatchPlaying = (match: TMatch): boolean => match.status === "playing";
export const isMatchScheduled = (match: TMatch): boolean => match.status === "scheduled";

export const isMatchLockoutPartial = (match: TMatch): boolean => match.lockout === "partial";
export const isMatchLockoutFull = (match: TMatch): boolean => match.lockout === "full";

export const getMatchUsingSquadId = (squad_id: number, matches: Array<TMatch>) => _.find(
	matches, ({ home_squad_id, away_squad_id }: TMatch) => (
		[home_squad_id, away_squad_id].includes(squad_id)
	)
);

export const getActualMatch =  (matches: Array<TMatch>) =>
	_.find(matches, ({ status }) => ["playing", "scheduled"].includes(status));

export const getNextScheduledMatch = (matches: Array<TMatch>) => {
	return _.find(matches, ({ status }) => status === "scheduled");
};

// Round helpers
export const isRoundComplete = (round: TRoundTypes): boolean =>
	_.get(round, "status") === "complete";
export const isRoundActive = (round: TRoundTypes): boolean =>
	_.get(round, "status") === "active";
export const isRoundScheduled = (round: TRoundTypes): boolean =>
	_.get(round, "status") === "scheduled";

export const getRoundNextScheduledMatch = (round: TRoundTypes) => {
	const round_matches = _.get(round, "matches");
	return getNextScheduledMatch(round_matches);
};

export const getFirstScheduledRound = (rounds: TArrayOfRoundTypes) =>
	_.find(rounds, { status: "scheduled" });

export const getActualRound = (rounds: TArrayOfRoundTypes) =>
	_.find(rounds, ({ status }) => ["active", "scheduled"].includes(status)) || _.last(rounds);

export const getActiveRound = (rounds: TArrayOfRoundTypes) => _.find(rounds, { status: "active" });

export const getLastCompleteRound = (rounds: TArrayOfRoundTypes) =>
	_.findLast(rounds, { status: "complete" });

export const getActiveOrLastCompleteOrNextScheduledRound = (rounds: TArrayOfRoundTypes) =>
	getActiveRound(rounds) || getLastCompleteRound(rounds) || getFirstScheduledRound(rounds);

export const getLockoutInfo = (
	actual_round: TRoundWithMatches,
	is_rolling: boolean
): TLockoutInfo => {
	const matches = _.get(actual_round, "matches", []);

	if (
		_.some([
			actual_round,
			matches,
		], _.isEmpty)
	) {
		return {
			next_match_date: null,
			lockout_type: "full",
			is_rolling: false,
			is_first: false,
			is_locked: false,
			is_loading: true,
		};
	}

	if (_.get(actual_round, "lockout") === "full" && !is_rolling) {
		return {
			next_match_date: null,
			lockout_type: "full",
			is_rolling: false,
			is_first: false,
			is_locked: true,
			is_loading: false,
		};
	}

	const next_match = _(matches)
		.filter({ status: "scheduled" })
		.first();

	const lockout_type = _.get(next_match, "lockout", "full");
	const is_locked = is_rolling ? next_match === undefined : false;

	return {
		next_match_date: _.get(next_match, "date", null),
		lockout_type,
		is_rolling,
		is_first: Boolean(_.get(next_match, "is_first", false)),
		is_locked,
		is_loading: false,
	};
};