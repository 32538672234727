// @flow
import _ from 'lodash';
import { createSelector } from 'reselect';

import getTeamsById from './getTeamsById';

const getTeamsByIdWithPureLineups = createSelector(
	getTeamsById,
	teams_by_id => _.reduce(teams_by_id, (result, team) => {
		return {
			...result,
			[team.id]: {
				...team,
				lineup_ids: _.chain(team.lineup)
					.omit(['captain', 'vice_captain', 'emergency'])
					.values()
					.flatten()
					.value()
			}
		};
	}, {})
);

export default getTeamsByIdWithPureLineups;