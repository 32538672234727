// @flow
import styled from "styled-components";
import { below } from "../../../assets/css/media";

export const TeamName = styled.div`
	color: #FFFFFF;
	font-size: 16px;
	line-height: 1.25;
	margin: 0 10px 0 18px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	${below.desktop`
		font-size: 14px;
		line-height: 1;
		margin-left: 10px;
		flex: 1;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	`};
`;

export default TeamName;