// @flow
import * as React from "react";
import { reduxForm, Field } from "redux-form";
import styled from "styled-components";

import colors from "../../assets/css/colors";
import { ChangePassword } from "../../utils/FormValidations";

import {
	ButtonPrimary, FormField,
} from "../../components";

const ChangePasswordFormStyled = styled.form`
	background: ${colors.form.base};
	padding: 3em;
	border: 1px solid ${colors.secondary.lightGrey};
	box-sizing: border-box;
	margin-bottom: 50px;
	${({from_account}) => from_account && `
		background: none;
		padding: 0;
		border: none;
	`}
`;

const FormItem = styled.div`
	margin: 10px 0;

	input{
		height: 40px;
	}
`;

const TitleForm = styled.h5`
	font-size: 18px;
	color: ${colors.primary.primary};
	margin-bottom: 10px;
`;

const Description = styled.div`
	max-width: 579px;
	line-height: 20px;
	color: ${colors.primary.darkGrey};
	font-family: "SourceSansPro";
	font-size: 14px;
	margin-bottom: 30px;
`;

type Props = {
	handleSubmit: Function,
	reset: Function,
	onSubmit: Function,
	from_account?: boolean,
}

const UpdatePasswordFormComponent = ({ handleSubmit, onSubmit, reset, from_account }: Props) => (
	<ChangePasswordFormStyled
		from_account={from_account}
		onSubmit={handleSubmit(data => {
			reset();
			return  onSubmit(data);
		})}
	>
		<div>
			<TitleForm>Change your password</TitleForm>
			<Description>
				Please enter a new password for your AFL Fantasy account.
				This password should include at least eight characters,
				a mixture of upper and lower case letters, at least one
				special character and at least one number.
			</Description>
			<FormItem>
				<Field
					label='Password*'
					type='password'
					name='password'
					placeholder='Password'
					component={FormField}
					tooltip={null}
				/>
			</FormItem>
			<FormItem>
				<Field
					label='Confirm password*'
					type='password'
					name='confirm_password'
					placeholder='Confirm password'
					component={FormField}
					tooltip={null}
				/>
			</FormItem>
		</div>

		<ButtonPrimary>
			Confirm new password
		</ButtonPrimary>
	</ChangePasswordFormStyled>
);
export const UpdatePasswordForm = reduxForm({
	form: "change_password_form",
	validate: ChangePassword,
})(UpdatePasswordFormComponent);

export default UpdatePasswordForm;