
// @flow

import _ from "lodash";
import { delay } from "redux-saga";
import {
	call,
	put,
	takeLatest,
	takeEvery,
	select,
	cancelled,
} from "redux-saga/effects";
import * as actions from "../../actions";
import * as selectors from "../../selectors";
import type { TDraftTeamNamePayload } from "../../types/index";
import { getPreDraftList } from "../../selectors/teamsDraft";

const checkErrorsIn = errors => {
	if (_.size(errors)) {
		throw new Error(_.get(_.first(errors), "text", "Unknown error"));
	}
};

export const createSagas = (API: Object): Object => {

	function* updateUserTeamNameSaga(action: { payload: TDraftTeamNamePayload }) {
		try {
			const response = yield call(
				API.teamsDraftInternal.update_team_name, { ...action.payload }
			);

			if (response.result.team) {
				yield put(actions.updateUserTeamNameSuccess());
				yield delay(15000);
				yield put(actions.updateUserTeamNameReset());
			}
			else {
				yield put(actions.updateUserTeamNameError(response.errors[0].text));
			}
		}
		catch (event) {
			console.log(event);
		}
	}
	function* updatePreDraftList({ payload }) {
		yield put(actions.teamsDraft.clearUpdatePreDraftListSuccessFlag());

		const old_list = yield select(getPreDraftList);

		try {
			yield put(actions.teamsDraft.forceUpdatePreDraftList(payload.list));

			const { errors } = yield call(API.teamsDraft.update_list, payload);
			checkErrorsIn(errors);
			yield put(actions.teamsDraft.postPreDraftListSuccess(payload.list));
			yield call(delay, 5000);
		}
		catch (error) {
			yield put(actions.teamsDraft.postPreDraftListFailed(error.message));
		}
		finally {
			if (yield cancelled()) {
				yield put(actions.teamsDraft.forceUpdatePreDraftList(old_list));
			}
		}

		yield put(actions.teamsDraft.clearUpdatePreDraftListSuccessFlag());
	}

	function* resetPreDraftList({ payload }) {
		try {
			const { errors } = yield call(API.teamsDraft.reset_list, payload);
			checkErrorsIn(errors);
			yield call(showPreDraftList, {
				payload: { league_id: payload.league_id },
			});
		}
		catch (error) {
			yield put(actions.teamsDraft.postResetPreDraftListFailed(error.message));
		}
	}

	function* updatePlayerPreDraftListOrder({
		payload: { player_id, team_id, new_order },
	}) {
		const position = parseInt(new_order, 10) - 1;
		const int_player_id = parseInt(player_id, 10);

		if (!_.isFinite(position) || !_.isFinite(int_player_id)) {
			throw new Error(
				"player_id and new_order fields should be a number"
			);
		}

		const old_list = yield select(getPreDraftList);
		const list = [...old_list];
		const max_pos = list.length;
		const min_pos = 0;
		const position_in_range = Math.min(
			Math.max(position, min_pos),
			max_pos
		);

		list.splice(old_list.indexOf(int_player_id), 1);
		list.splice(position_in_range, 0, int_player_id);

		yield put(actions.teamsDraft.postPreDraftList({ team_id, list }));
	}

	function* addPlayerToPreDraftList({ payload: { player_id, team_id } }) {
		const list = yield select(getPreDraftList);

		yield put(
			actions.teamsDraft.postPreDraftList({
				team_id,
				list: [...list, parseInt(player_id, 10)],
			})
		);
	}

	function* removePlayerFromPreDraftList({
		payload: { player_id, team_id, silent = false },
	}) {
		const old_list = yield select(getPreDraftList);
		const new_list = _.without(old_list, parseInt(player_id, 10));

		if (silent) {
			yield put(actions.teamsDraft.forceUpdatePreDraftList(new_list));
		}
		else {
			yield put(actions.teamsDraft.postPreDraftList({ team_id, list: new_list }));
		}
	}

	function* showPreDraftList({ payload }) {
		try {
			const { result, errors } = yield call(
				API.teamsDraft.show_list,
				payload
			);
			checkErrorsIn(errors);
			yield put(actions.teamsDraft.fetchPreDraftSuccess(result));
		}
		catch (error) {
			yield put(actions.teamsDraft.fetchPreDraftFailed(error.message));
		}
	}

	function* pickPlayer({ payload }) {
		try {
			const { result, errors } = yield call(
				API.teamsDraft.pick_player,
				payload
			);
			checkErrorsIn(errors);
			yield put(actions.teamsDraft.postPickedPlayerSuccess(result));
		}
		catch (error) {
			yield put(actions.teamsDraft.postPickedPlayerFailed(error.message));
		}
	}

	function* toggleAutoPick({ payload }) {
		try {
			const { result, errors } = yield call(
				API.teamsDraft.toggle_auto_pick,
				payload
			);
			checkErrorsIn(errors);
			yield put(actions.teamsDraft.postAutoPickFlagSuccess(result));
		}
		catch (error) {
			yield put(actions.teamsDraft.postAutoPickFlagFailed(error.message));
		}
	}

	function* commissionerTrade({ payload }) {
		try {
			const { result, errors } = yield call(
				API.teamsDraft.commissioner_rade,
				payload
			);
			checkErrorsIn(errors);
			yield put(actions.teamsDraft.makeCommissionerTradeSuccess(result));
		}
		catch (error) {
			yield put(actions.teamsDraft.makeCommissionerTradeFailed(error.message));
		}
	}	

	function* update({ payload }) {
		const { id, lineup, old_lineup } = payload;
		try {
			yield put(
				actions.leagueDraft.forceTeamUpdate({
					lineup,
					id,
				})
			);
			const { result, errors } = yield call(API.teamsDraft.update, {
				lineup,
				id,
			});

			checkErrorsIn(errors);

			if (result) {
				yield put(
					actions.leagueDraft.userTeamUpdated({
						...result,
						lineup,
						id,
					})
				);
				yield put(actions.teamsDraft.postTeamUpdateSuccess({ lineup, id }));
			}
		}
		catch (error) {
			yield put(
				actions.leagueDraft.forceTeamUpdate({
					lineup: old_lineup,
					id,
				})
			);
			yield put(actions.teamsDraft.postTeamUpdateFailed(error.message));
		}
		finally {
			if (yield cancelled()) {
				yield put(
					actions.leagueDraft.forceTeamUpdate({
						lineup: old_lineup,
						id,
					})
				);
			}
		}
	}

	function* showSnapshot({ payload }) {
		try {
			const { result, errors } = yield call(
				API.teamsDraft.snapshot,
				payload
			);
			checkErrorsIn(errors);
			yield put(actions.teamsDraft.fetchSnapshotSuccess(result));
		}
		catch (error) {
			yield put(actions.teamsDraft.fetchSnapshotFailed(error.message));
		}
	}

	function* getLineups({ payload }) {
		try {
			const { result, errors } = yield call(
				API.teamsDraft.lineups,
				payload
			);
			checkErrorsIn(errors);
			yield put(actions.teamsDraft.fetchLineupsSuccess(result));
		}
		catch (error) {
			yield put(actions.teamsDraft.fetchLineupsFailed(error.message));
		}
	}

	function* getTeam({ payload }) {
		try {
			const { result, errors } = yield call(API.teamsDraft.show, payload);
			checkErrorsIn(errors);
			yield put(actions.teamsDraft.fetchTeamSuccess(result));
		}
		catch (error) {
			yield put(actions.teamsDraft.fetchTeamFailed(error.message));
		}
	}

	function* getMyTeams({ payload }) {
		try {
			const { result, errors } = yield call(
				API.teamsDraft.show_my,
				payload
			);
			checkErrorsIn(errors);
			yield put(actions.teamsDraft.fetchMyTeamsSuccess(result));
		}
		catch (error) {
			yield put(actions.teamsDraft.fetchMyTeamsFailed(error.message));
		}
	}

	function* postRequestWaiver({ payload }) {
		try {
			const { result, errors } = yield call(
				API.teamsDraft.request_waiver_trade,
				payload
			);
			checkErrorsIn(errors);
			yield put(actions.teamsDraft.postRequestWaiverSuccess(result));
		}
		catch (error) {
		    yield put(actions.teamsDraft.postRequestWaiverFailed(error.message));
		}
	}



	function* fetchMakeTrade({ payload }) {
		try {
			const { result, errors } = yield call(
				API.teamsDraft.make_trade,
				payload
			);
			checkErrorsIn(errors);

			yield put(actions.teamsDraft.fetchMakeTradeSuccess(result));
		}
		catch (error) {
			yield put(actions.teamsDraft.fetchMakeTradeFailed(error.message));
		}
	}

	function* fetchTrades({ payload }) {
		try {
			const { result, errors } = yield call(
				API.teamsDraft.trades,
				payload
			);
			checkErrorsIn(errors);

			yield put(actions.teamsDraft.fetchTradesSuccess(result));
		}
		catch (error) {
			yield put(actions.teamsDraft.fetchTradesFailed(error.message));
		}
	}

	function* fetchRejectTrade({ payload: { trade_id, league_id, trades } }) {
		const { my } = trades;
		try {
			yield put(
				actions.teamsDraft.forceTradesSuccess({
					...trades,
					my: my.filter(({ id }) => id !== trade_id),
				})
			);

			const { result, errors } = yield call(API.teamsDraft.reject_trade, {
				trade_id,
			});
			checkErrorsIn(errors);

			if (!result) {
				yield put(
					actions.teamsDraft.forceTradesSuccess({
						...trades,
					})
				);
			}
		}
		catch (error) {
			yield put(
				actions.teamsDraft.forceTradesSuccess({
					...trades,
				})
			);
			yield put(actions.teamsDraft.fetchRejectTradeFailed(error.message));
		}
	}

	function* fetchAcceptTrade({ payload: { trade_id, league_id, trades } }) {
		const { my } = trades;
		try {
			yield put(
				actions.teamsDraft.forceTradesSuccess({
					...trades,
					my: my.filter(({ id }) => id !== trade_id),
				})
			);

			const { result, errors } = yield call(API.teamsDraft.accept_trade, {
				trade_id,
			});
			checkErrorsIn(errors);

			if (result) {
				yield put(actions.teamsDraft.fetchTrades({ league_id }));
			}
			else {
				yield put(
					actions.teamsDraft.forceTradesSuccess({
						...trades,
					})
				);
			}
		}
		catch (error) {
			yield put(
				actions.teamsDraft.forceTradesSuccess({
					...trades,
				})
			);
			yield put(actions.teamsDraft.fetchRejectTradeFailed(error.message));
		}
	}

	function* fetchRemovePlayer({ payload: { team_id, player_id } }) {
		try {
			const { result, errors } = yield call(
				API.teamsDraft.remove_player,
				{ team_id, player_id }
			);
			checkErrorsIn(errors);

			yield put(actions.teamsDraft.fetchRemovePlayerSuccess(result));

			yield put(
				actions.leagueDraft.userTeamUpdatedRemovePlayer({
					lineup: result.lineup,
					id: result.id,
				})
			);
		}
		catch (error) {
			yield put(actions.teamsDraft.fetchRemovePlayerFailed(error.message));
		}
	}

	function* fetchBlockTrade({ payload: { trade_id, trades } }) {
		const { proposed } = trades;
		try {
			yield put(
				actions.teamsDraft.forceTradesSuccess({
					...trades,
					proposed: proposed.filter(({ id }) => id !== trade_id),
				})
			);
			const { result, errors } = yield call(API.teamsDraft.block_trade, {
				trade_id,
			});
			checkErrorsIn(errors);

			if (!result) {
				yield put(
					actions.teamsDraft.forceTradesSuccess({
						...trades,
					})
				);
			}
		}
		catch (error) {
			yield put(
				actions.teamsDraft.forceTradesSuccess({
					...trades,
				})
			);
			yield put(actions.teamsDraft.fetchBlockTradeFailed(error.message));
		}
	}

	function* fetchApproveTrade({ payload: { trade_id, trades } }) {
		const { proposed } = trades;
		try {
			yield put(
				actions.teamsDraft.forceTradesSuccess({
					...trades,
					proposed: proposed.filter(({ id }) => id !== trade_id),
				})
			);
			const { result, errors } = yield call(
				API.teamsDraft.approve_trade,
				{ trade_id }
			);
			checkErrorsIn(errors);

			if (!result) {
				yield put(
					actions.teamsDraft.forceTradesSuccess({
						...trades,
					})
				);
			}
		}
		catch (error) {
			yield put(
				actions.teamsDraft.forceTradesSuccess({
					...trades,
				})
			);
			yield put(actions.teamsDraft.fetchApproveTradeFailed(error.message));
		}
	}
	function* fetchRemoveWaiverTrade({ payload: { id } }) {
		const waivers = yield select(
			selectors.leagueDraft.getWaiverTrades
		);

		try {
			yield put(
				actions.leagueDraft.updatedWaiverRequests(
					waivers.filter(({ id: trade_id }) => trade_id !== id)
				)
			);

			const { result, errors } = yield call(
				API.teamsDraft.remove_waiver_trade,
				{ id }
			);
			checkErrorsIn(errors);

			if (!result) {
				yield put(
					actions.leagueDraft.updatedWaiverRequests(waivers)
				);
			}
		}
		catch (error) {
			yield put(actions.leagueDraft.updatedWaiverRequests(waivers));
			yield put(actions.teamsDraft.fetchRemoveWaiverTradeFailed(error.message));
		}
	}

	function* postOrderWaiverTrade({ payload }) {
		const { id, position } = payload;
		const int_trade_id = parseInt(id, 10);

		if (!_.isFinite(position) || !_.isFinite(int_trade_id)) {
			throw new Error("id and new_order fields should be a number");
		}

		const waivers = yield select(
			selectors.leagueDraft.getWaiverTrades
		);
		const list = [...waivers];
		const max_pos = list.length;
		const min_pos = 0;
		const position_in_range = Math.min(
			Math.max(position, min_pos),
			max_pos
		);
		const trade = _.find(waivers, { id: int_trade_id });

		list.splice(_.findIndex(waivers, { id: int_trade_id }), 1);
		list.splice(position_in_range, 0, trade);
		const new_list = list.map((item, i) => {
			item.user_position = i;
			return item;
		});

		try {
			yield put(actions.leagueDraft.updatedWaiverRequests(list));
			const trades = new_list.map(item => {
				return {
					id: item.id,
					position: item.user_position,
				};
			});
			const { result, errors } = yield call(
				API.teamsDraft.manage_order_waiver_trade,
				{ trades }
			);
			checkErrorsIn(errors);
			if (result) {
				yield put(actions.teamsDraft.postOrderWaiverTradeSuccess());
			}
			else {
				yield put(actions.teamsDraft.postOrderWaiverTradeFailed());
			}
		}
		catch (err) {
			yield put(actions.leagueDraft.updatedWaiverRequests(waivers));
			yield put(actions.teamsDraft.postOrderWaiverTradeFailed());
		}
		finally {
			if (yield cancelled()) {
				yield put(
					actions.leagueDraft.updatedWaiverRequests(waivers)
				);
				yield put(actions.teamsDraft.postOrderWaiverTradeFailed());
			}
		}
	}

	function* fetchPlayersOnOfferList(action) {
		try {
			const { success, result, errors } = yield call(
				API.teamsDraft.offer_trades,
				{ ...action.payload }
			);
			if (success === 1) {
				yield put(actions.teamsDraft.fetchPlayersOnOfferListSuccess(result));
			}
			else {
				yield put(
					actions.teamsDraft.fetchPlayersOnOfferListFailed(errors[0].text)
				);
			}
		}
		catch (e) {
			if (process.env.NODE_ENV !== "test") {
				console.error(e);
			}
			yield put(actions.teamsDraft.fetchPlayersOnOfferListFailed(e.message));
		}
	}

	function* fetchCancelPlayerOnOffer({ payload: { trade_id, league_id } }) {
		try {
			const { success, result, errors } = yield call(
				API.teamsDraft.reject_trade,
				{ trade_id }
			);
			checkErrorsIn(errors);

			if (success === 1) {
				yield put(actions.teamsDraft.fetchCancelPlayerOnOfferSuccess(result));
				yield put(actions.teamsDraft.fetchPlayersOnOfferList({ league_id }));
			}
		}
		catch (e) {
			if (process.env.NODE_ENV !== "test") {
				console.error(e);
			}
			yield put(actions.teamsDraft.fetchCancelPlayerOnOfferFailed(e.message));
		}
	}

	function* selectKeeper({ payload }) {
		try {
			const { result, errors } = yield call(
				API.teamsDraft.select_keeper,
				payload
			);
			checkErrorsIn(errors);
			yield put(actions.teamsDraft.postSelectKeeperSuccess(result));
		}
		catch (error) {
			yield put(actions.teamsDraft.postSelectKeeperFailed(error.message));
		}
	}

	function* removeKeeper({ payload }) {
		try {
			const { result, errors } = yield call(
				API.teamsDraft.remove_keeper,
				payload
			);
			checkErrorsIn(errors);
			yield put(actions.teamsDraft.removeKeeperSuccess(result));
		}
		catch (error) {
			yield put(actions.teamsDraft.removeKeeperFailed(error.message));
		}
	}

	function* watch() {
		yield takeLatest(
			actions.updateUserTeamName,
			updateUserTeamNameSaga
		);
		yield takeLatest(
			actions.teamsDraft.updatePlayerPreDraftListOrder,
			updatePlayerPreDraftListOrder
		);
		yield takeLatest(
			actions.teamsDraft.removePlayerFromPreDraftList,
			removePlayerFromPreDraftList
		);
		yield takeLatest(
			actions.teamsDraft.addPlayerToPreDraftList,
			addPlayerToPreDraftList
		);
		yield takeLatest(actions.teamsDraft.postPreDraftList, updatePreDraftList);

		yield takeLatest(actions.teamsDraft.fetchPreDraftList, showPreDraftList);
		yield takeLatest(actions.teamsDraft.postPickedPlayer, pickPlayer);
		yield takeLatest(actions.teamsDraft.makeCommissionerTrade, commissionerTrade);
		yield takeEvery(actions.teamsDraft.postSelectKeeper, selectKeeper);
		yield takeEvery(actions.teamsDraft.removeKeeper, removeKeeper);
		yield takeLatest(actions.teamsDraft.postAutoPickFlag, toggleAutoPick);

		yield takeLatest(actions.teamsDraft.postTeamUpdate, update);

		yield takeLatest(actions.teamsDraft.fetchSnapshot, showSnapshot);
		yield takeLatest(actions.teamsDraft.fetchLineups, getLineups);
		yield takeEvery(actions.teamsDraft.fetchTeam, getTeam);
		yield takeLatest(actions.teamsDraft.fetchMyTeams, getMyTeams);
		yield takeLatest(actions.teamsDraft.postRequestWaiver, postRequestWaiver);
		yield takeLatest(actions.teamsDraft.fetchMakeTrade, fetchMakeTrade);
		yield takeLatest(actions.teamsDraft.fetchTrades, fetchTrades);
		yield takeLatest(actions.teamsDraft.fetchRejectTrade, fetchRejectTrade);
		yield takeLatest(actions.teamsDraft.fetchAcceptTrade, fetchAcceptTrade);
		yield takeLatest(actions.teamsDraft.fetchRemovePlayer, fetchRemovePlayer);
		yield takeLatest(actions.teamsDraft.fetchBlockTrade, fetchBlockTrade);
		yield takeLatest(actions.teamsDraft.fetchApproveTrade, fetchApproveTrade);
		yield takeLatest(
			actions.teamsDraft.fetchRemoveWaiverTrade,
			fetchRemoveWaiverTrade
		);
		yield takeLatest(actions.teamsDraft.postOrderWaiverTrade, postOrderWaiverTrade);
		yield takeLatest(actions.teamsDraft.postResetPreDraftList, resetPreDraftList);
		yield takeLatest(
			actions.teamsDraft.fetchPlayersOnOfferList,
			fetchPlayersOnOfferList
		);
		yield takeLatest(
			actions.teamsDraft.fetchCancelPlayerOnOffer,
			fetchCancelPlayerOnOffer
		);
	}

	return {
		updateUserTeamNameSaga,
		updatePlayerPreDraftListOrder,
		addPlayerToPreDraftList,
		removePlayerFromPreDraftList,
		updatePreDraftList,
		resetPreDraftList,
		showPreDraftList,
		pickPlayer,
		selectKeeper,
		removeKeeper,
		toggleAutoPick,
		commissionerTrade,
		update,
		showSnapshot,
		getLineups,
		getTeam,
		postRequestWaiver,
		fetchRejectTrade,
		fetchAcceptTrade,
		fetchRemovePlayer,
		fetchBlockTrade,
		fetchApproveTrade,
		fetchRemoveWaiverTrade,
		postOrderWaiverTrade,
		fetchPlayersOnOfferList,
		fetchCancelPlayerOnOffer,
		watch,
	};
};

export default createSagas;