// @flow
import styled, { css } from "styled-components";
import type { StyledComponentClass } from "styled-components";
import { Link } from "react-router-dom";

import colors from "../../assets/css/colors";
import { default_transition } from "../../assets/css/vars";

export const ButtonPrimary = styled.button`
	border: 1px solid ${colors.buttons.primary_button.main.border};
	background-color: ${colors.buttons.primary_button.main.background};
	color: ${colors.buttons.primary_button.main.color};
	font-family: "SourceSansPro";
	font-size: 12px;
	font-weight: 600;
	line-height: 1;
	padding: 14px 12px 12px;
	cursor: pointer;
	width: ${({ width }) => width || "auto"};
	text-align: center;
	border-radius: 2px;
	${default_transition}

	:hover:not(:disabled) {
		background-color: ${colors.buttons.primary_button.hover.background};
		color: ${colors.buttons.primary_button.hover.color};
		border-color: ${colors.buttons.primary_button.hover.border};
	}

	&:disabled {
		background: ${colors.secondary.paleGrey};
		color: #ffffff;
		cursor: inherit;
		border-color: ${colors.secondary.paleGrey};
	}
`;

export const PrimaryButtonStyles = css`
	border: 1px solid ${colors.buttons.primary_button.main.border};
	background-color: ${colors.buttons.primary_button.main.background};
	color: ${colors.buttons.primary_button.main.color};
	font-family: "SourceSansPro";
	font-size: 12px;
	font-weight: 600;
	line-height: 1;
	padding: 14px 12px 12px;
	cursor: pointer;
	width: ${({ width }) => width || "auto"};
	text-align: center;
	border-radius: 2px;
	${default_transition}

	:hover:not(:disabled) {
		background-color: ${colors.buttons.primary_button.hover.background};
		color: ${colors.buttons.primary_button.hover.color};
		border-color: ${colors.buttons.primary_button.hover.border};
	}

	&:disabled {
		background: ${colors.secondary.paleGrey};
		color: #ffffff;
		cursor: inherit;
		border-color: ${colors.secondary.paleGrey};
	}
`;

ButtonPrimary.displayName = "ButtonPrimary";

ButtonPrimary.defaultProps = {
	...colors.buttons.primary_button
};

export const withLinkStyled = (Component: StyledComponentClass) => {
	const ComponentWithLink = styled(Component.withComponent(Link))`
		display: flex;
		align-items: center;
		justify-content: center;
		text-decoration: none;
	`;

	ComponentWithLink.displayName = `${Component.displayName || "Button"}Link`;
	
	return ComponentWithLink;
};

export const ButtonPrimaryLink = withLinkStyled(ButtonPrimary);

export default ButtonPrimary;