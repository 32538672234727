// @flow
import * as React from "react";
import _ from "lodash";
import { ConfirmationModal } from "../../Modal";


type State = {
	show_modal: boolean,
	modal_header: any,
	modal_body: any,
	warning: boolean,
	onConfirm: Function
}

const withConfirmModal = (WrappedComponent: any) => {
	class WithConfirmModal extends React.Component<any, State> {
		constructor(props: any) {
			super(props);

			_.bindAll(this, ["showConfirmModal", "hideConfirmModal"]);
		}

		state = {
			show_modal: false,
			modal_header: null,
			modal_body: null,
			onConfirm: () => null,
			warning: false,
		};

		showConfirmModal({ modal_header, modal_body, onConfirm, warning = false }: Object) {
			this.setState({
				show_modal: true,
				modal_header,
				modal_body,
				onConfirm,
				warning
			});
		}

		hideConfirmModal() {
			this.setState({
				show_modal: false,
				modal_header: null,
				modal_body: null,
				onConfirm: () => null,
				warning: false
			});
		}

		getModalElement() {
			const { show_modal, modal_header, modal_body, warning } = this.state;
			return show_modal && <ConfirmationModal 
				header_text={modal_header}
				body_text={modal_body}
				confirmClick={this.state.onConfirm}
				cancelClick={this.hideConfirmModal}
				closeClick={this.hideConfirmModal}
				warning={warning}
			/>;
		}

		render() {
			return <React.Fragment>
				{ this.getModalElement() }
				<WrappedComponent
					{...this.props}
					hideConfirmModal={this.hideConfirmModal}
					showConfirmModal={this.showConfirmModal}
				/>
			</React.Fragment>;
		}
	}

	return WithConfirmModal;
};

export default withConfirmModal;