/* eslint max-len: 0 */
// @flow
import React from "react";
import SvgIconContainer from "../SvgIconContainer";

export default (
	{
		unit = "em",
		...rest
	}: Object = {}) => (
	<SvgIconContainer viewBox="0 0 24 24" {...rest} unit={unit}>
		<title>Extended Bench</title>
		<path d="m10.3789474 14.4947368h2.7157894c-.2526315-2.5052631 2.9052632-3.2842105 2.9052632-5.8947368 0-2.48421053-1.7894737-3.6-4.1684211-3.6-1.5578947 0-2.88421048.77894737-3.8315789 1.91578947l1.70526316 1.55789474c.56842104-.56842105 1.13684214-.96842105 1.91578944-.96842105.8421053 0 1.4105263.46315789 1.4105263 1.32631579 0 1.57894735-3.07368416 2.77894735-2.6526315 5.66315785zm-.50526319 3.1789474c0 1.0947369.77894739 1.9368421 1.85263159 1.9368421s1.8736842-.8421052 1.8736842-1.9368421c0-1.1157895-.8-1.9578947-1.8736842-1.9578947s-1.85263159.8421052-1.85263159 1.9578947z" fillRule="evenodd" />
	</SvgIconContainer>
);