// @flow
import * as React from "react";
import styled from "styled-components";
import default_image from "../../assets/images/LeagueLogo.svg";
const DRAFT_LEAGUE_AVATARS_PATH = process.env.REACT_APP_DRAFT_LEAGUE_AVATARS_PATH || "";
const CLASSIC_LEAGUE_AVATARS_PATH = process.env.REACT_APP_CLASSIC_LEAGUE_AVATARS_PATH || "";


const LeagueAvatarWrapper = styled.div`
	border-radius: 50%;
	overflow: hidden;
`;

const LeagueAvatarPicture = styled.picture`
	display: block;
	img {
		display: block;
		height: ${({ size }) => size || "100%"};
		width: ${({ size }) => size || "100%"};
		min-height: ${({ size }) => size || "100%"};
		min-width: ${({ size }) => size || "100%"};
		object-fit: cover;
		border-radius: 50%;
		@media(max-width: 640px){
			min-height: unset;
			min-width: unset;
		}
	}
`;

type Props = {
	is_classic?: boolean,
	league: {
		id: number,
		avatar_version: number,
		is_club?: boolean,
		name: string
	},
	size?: string,
	className?: string
};

export const LeagueAvatar = ({ league, size, is_classic, className }: Props) => {
	const { id, avatar_version, is_club, name } = league;
	const avatar_prefix = is_classic ? CLASSIC_LEAGUE_AVATARS_PATH : DRAFT_LEAGUE_AVATARS_PATH;
	const avatar = `${avatar_prefix}${id}.png?v=${avatar_version}`;
	const is_avatar = is_club || avatar_version;

	return (
		<LeagueAvatarWrapper className={className}>
			<LeagueAvatarPicture size={size}>
				{is_avatar ? <source srcSet={avatar} type="image/png" /> : null}
				<img src={default_image} alt={`Avatar for ${name}`} />
			</LeagueAvatarPicture>
		</LeagueAvatarWrapper>
	);
};

export default LeagueAvatar;