// @flow
import * as React from "react";
import styled from "styled-components";
import {
	ModalContainer, PlayerImgContainer, ModalInnerTitle, ButtonAlert, ButtonAccent
} from "../";
import {TPlayer} from "../../modules/types";
import { below } from "../../assets/css/media";

const ButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	margin: 0 1em;
	padding: 2.5em 0;

	button {
		min-width: 160px;
		margin: 0 5px;

		${below.desktop`
			min-width: 85px;
		`}
	}
`;
const ModalText = styled(ModalInnerTitle)`
	padding-top: 175px;
`;
type Props = {
	id: number,
	player: TPlayer,
	positions: Array<number>,
	addPlayer: Function,
	closeModal: Function
};

export const ClassicTeamSelectPositionModal = (
	{ id, player, positions, addPlayer, closeModal }: Props
) => {
	const player_name = `${player.first_name} ${player.last_name}`,
		avatar_src = `${process.env.REACT_APP_PLAYERS_PATH + player.id}.webp`;
	// console.log(player);

	return (
		<ModalContainer
			onClick={closeModal}
			is_sponsored={true}
		>
			<PlayerImgContainer>
				<img src={avatar_src} alt={player_name}/>
			</PlayerImgContainer>
			<ModalText>
				Please select a position you would like to recruit {player_name} to.
			</ModalText>
			<ButtonWrapper>
				<ButtonAlert inverted onClick={closeModal}>
					No, Cancel
				</ButtonAlert>
				{positions.map((pos_id, index) => (
					<ButtonAccent
						key={pos_id}
						onClick={() => addPlayer({ id: id, positions: [pos_id] })}
					>
						Recruit as a {player.positions_full_name[index]}
					</ButtonAccent>
				))}
			</ButtonWrapper>
		</ModalContainer>
	);
};
export default ClassicTeamSelectPositionModal;