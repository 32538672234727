// @flow
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import {FlexDiv} from "../../components";
import PageTitle from "../../components/PageTitle";
import ModalContainer from "../../components/Modal/ModalContainer";
import {below} from "../../assets/css/media";

export const AFLiDModalContainer = styled(ModalContainer)`
	> div {
		${below.tablet`
		padding: 0;
	`};
	}
	${below.tablet`
	padding: 0
`};
`;

export const PureButton = styled.button`
	border: 0;
	padding: 0;
	margin: 0;
	background: none;
	cursor: pointer;
	appearance: none;
`;

export const ButtonAsLink = styled(PureButton)`
	font-weight: 700;
	text-decoration: underline;
`;

export const ForgotPasswordLink = styled.a`
	border: 0;
	padding: 0;
	margin: 0;
	background: none;
	cursor: pointer;
	appearance: none;
	font-weight: 700;
	text-decoration: underline;
	color: black;
`;

export const NavLinkAsLink = styled(NavLink)`
	color: #1C4072;
	font-weight: 700;
	text-decoration: underline;
	font-size:16px;
`;

export const AFLiDModalContentWrapper = styled.div`
	max-width: 520px;
	padding: 32px 40px;
	width: 100%;
	background: #ffffff;
	border-radius: 1px;
	> div {
		${below.tablet`
		padding: 0 5px;
	`};
	}
	${below.tablet`
	padding: 20px 0;
	max-width: 320px;
`};
`;

export const ModalContent = styled.div`
	text-align: center;
	color: #1c4072;
	font-size: 16px;
	padding: 0 2em;
	line-height: 20px;
	font-family: "SourceSansPro";
	${below.tablet`
	    font-size: 14px;
	`};
	p {
		margin: 0 0 20px 0;
	}
`;

export const ErrorContent = styled(ModalContent)`
	color:red;
`;

export const SuccessContent = styled(ModalContent)`
	color:green;
	padding: 20px 0 0 0;
	a {
		font-weight: 700;
		color:green;
	}
`;

export const DOBErrorText = styled(ErrorContent)`
	padding: 0;
	text-align: left;
	margin: 10px 0 0;
	font-size: 12px;
	height: 20px;
`;


export const ModalContentNoPadMob = styled(ModalContent)`
	margin-bottom: 20px;
	${below.tablet`
		padding: 0;
	`};
`;

export const ModalWrap = styled.div`
	padding: 0;
	max-width: 450px;
	margin: 0 auto 20px;
	${below.tablet`
    	margin: 0 20px 0;
		padding: 0 !important;
	`}
`;

export const ModalSubTitle = styled.div`
	font-family: "SourceSansPro";
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	line-height: 22px;
	display: flex;
	text-align: center;
	color: #1c4072;
`;

export const PageTitleStyled = styled(PageTitle)`
	font-size: 32px;
	display: flex;
	flex-direction: column;
	align-items: center;
	line-height: 40px;
	${below.tablet`
		font-size:22px;
        line-height: 26px;
	`};
`;

export const ModalInputWrap = styled.div`
	margin: 20px auto;
	width: 350px;
	${below.tablet`
    	width: 100%;
	`}
`;

export const ModalSpan = styled.span`
	display: block;
	font-family: "SourceSansPro";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 120%;
	width: 100%;

	text-align: center;
	color: #0c2b59;
	margin-bottom: 5px;
	strong {
		font-weight: 700;
		margin: 0 3px;
	}
`;

export const AFLiDButonWrap = styled(FlexDiv)`
	margin: 20px 0 0;
	justify-content: center;
	${below.tablet`
    	width: 100%;
		flex-direction: column;
	`}
`;

export const AFLIDButton = styled.button`
	box-sizing: border-box;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 12px;

	width: 172px;
	height: 44px;
	border-radius: 2px;

	font-family: SourceSansPro;
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 120%;
	margin: 0 6px;
	text-align: center;
	color: #198de2;
	border: 1px solid #198de2;
	background: #fff;

	${below.tablet`
    	width: 100%;
		margin: 6px 0;
	`}
`;

export const AFLiDLinkButton = styled(NavLink)`
	box-sizing: border-box;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 12px;

	width: 172px;
	height: 44px;
	border-radius: 2px;

	font-family: SourceSansPro;
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 120%;
	margin: 0 6px;
	text-align: center;
	color: #198de2;
	border: 1px solid #198de2;
	background: #fff;
	text-decoration

	${below.tablet`
		width: 100%;
		margin: 6px 0;
	`}
`;

export const AFLIDButtonDark = styled(AFLIDButton)`
	background: #198de2;
	color: #ffffff;
`;

export const AFLIDLogInButton = styled(AFLIDButtonDark)`
	background: #1D4073;
`;

export const InputWrap = styled(FlexDiv)`
	margin: 12px 0 0;
`;

export const SelectWrap = styled(FlexDiv)`
	margin: 20px 0px;
`;

export const ModalCheckbox = styled.input`
	flex-shrink: 0;
`;
export const CheckBoxWrap = styled(FlexDiv)`
	width: 100%;
	[type="checkbox"] {
		width: 18px;
		height: 18px;
		margin: auto 0;
	}
`;
export const CheckBoxLabel = styled.label`
	font-family: SourceSansPro;
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
    line-height: 16px;
	margin: 0 0 0 10px;
	text-align:left;
	a {
		font-weight: 600;
		color:rgb(29, 64, 115);
	}
`;

export const DatePickerWrap = styled.div`
	display: flex;
	flex-direction: column;
	margin: 10px 0 0;
	.rsd__react-select-datepicker {
		justify-content: space-between;
		margin: 5px 0 0;
		> div {
			min-width: 30%;
		}
	}
	.rsd__react-select-datepicker select {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		height: 56px;
		padding: 16px 12px;
		color: #808990;
		border:1px solid #CAD2D8;
		font-family: SourceSansPro;
	}
`;

export const DOBLabel = styled.label`
	font-family: SourceSansPro;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	color: #808990;
	text-align: left;
`;