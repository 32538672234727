// @flow
import * as React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import { isNumber } from "lodash";

import type { TMatch } from "../../modules/types";
import type { TSquadsById } from "../../modules/types";

import colors from "../../assets/css/colors";
import { below, above } from "../../assets/css/media";

import * as selectors from "../../modules/selectors";
import { getClubLogoIndigenous } from "../../utils/clubLogos";
import { isAllTrue } from "../../helpers";
import { isMatchScheduled } from "../../helpers/rounds";



const darkGrey = colors.primary.darkGrey;
const lightGrey = "#8D979C";

const AbovePhone = styled.div`
  font-family: SourceSansPro;
  font-weight: 600;
  color: #8D979C;
  font-size: 10px;
	${below.phone`
		display: none;
	`};
`;

const BelowPhone = styled.div`
	${above.phone`
		display: none;
	`};
`;

const ContainerLink = styled(Link)`
	box-sizing: border-box;
	margin: 5px 1px;
	min-width: 70px;
	text-decoration: none;

	${above.phone`
		min-width: 120px;
	`}
`;

const TopContainer = styled.div`
	background-color: #FFFFFF;
	border-top-left-radius: 2px;
	border-top-right-radius: 2px;
	padding: 5px;
	margin-bottom: 1px;
`;

const DateTimeConstainer = styled.div`
	display: flex;
	justify-content: space-between;
	color: ${lightGrey};
`;
const Date = styled.div`
	margin-right: 10px;
	font-size: 10px;
	font-weight: 600;
	text-transform: capitalize;
	white-space: nowrap;
  font-family: SourceSansPro;
  color: #8D979C;
`;
const Time = styled.div`
	font-size: 10px;
  color: ${colors.secondary.accentGrey};
  font-family: SourceSansPro;
  font-weight: 600;
  display: ${({ is_desktop }) => is_desktop ? "block" : "none"};
  ${below.phone`
		display: ${({ is_desktop }) => is_desktop ? "none" : "block"}
	`};
`;

const BottomContainer = styled.div`
	background-color: #FFFFFF;
	border-bottom-left-radius: 2px;
	border-bottom-right-radius: 2px;
	padding: 5px;
`;

const SquadLine = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 10px;
	font-weight: 500;
	color: ${darkGrey};
	text-transform: uppercase;
	margin-bottom: 2px;
	height: 16px;
`;
const SquadLogoWrapper = styled.div`
	margin-right: 3px;
	flex: 0 0 16px;
`;
const SquadLogo = styled.img`
	display: block;
	width: 15px;
	height: 15px;
`;
const SquadName = styled.div`
	margin-right: 10px;
	flex: 1 2 100%;
  color: ${({ is_winning }) => is_winning ? colors.primary.primary : colors.secondary.accentGrey};
	${below.phone`
		display: none;
	`}
`;
const SquadScore = styled.div`
  color: ${({ is_winning }) => is_winning ? colors.primary.primary : colors.secondary.accentGrey};
`;

const button_background = {
	scheduled: colors.primary.primary,
	playing: "#4EA431",
	complete: "#D92425",
};

const hover_button_background = {
	scheduled: "#d3dade",
	playing: "#54b135",
	complete: "#df3a3a",
};

// const button_color = {
// 	scheduled: colors.primary.primary,
// 	playing: '#FFFFFF',
// 	complete: '#FFFFFF',
// };

const button_text =  {
	scheduled: "Match Up",
	playing: "Live",
	complete: "Full time",
};

const Button = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
  font-family: SourceSansPro;
	font-size: 10px;
	line-height: 1;
	font-weight: 500;
	white-space: nowrap;
	text-decoration: none;
	padding: 5px;
	margin-top: 5px;
	border-radius: 2px;

	transition: background-color 0.2s linear;

	color: white;
	background-color: ${({ status }) =>
		button_background[status] ? button_background[status] : button_background.scheduled};

	:hover {
		background-color: ${({ status }) => hover_button_background[status]
		? hover_button_background[status]
		: hover_button_background.scheduled};
	}

	${below.phone`
		display: none;
	`}
`;

type SquadInfo = {
	id: number,
	short_name: string,
	score: number,
	goals: number,
	behinds: number,
  is_home: boolean,
}

const renderSquad = (squad_info: SquadInfo, match: TMatch, matchScoreStatus: string, actual_round_id: number) => {
	const is_match_scheduled = isMatchScheduled(match);
	const fallback_score = !is_match_scheduled ? 0 : "";
	const goals_behinds =
    isAllTrue([isNumber(squad_info.goals), isNumber(squad_info.behinds)])  ?
    	`${squad_info.goals}.${squad_info.behinds}.` :
    	"";
	const score = squad_info.score || fallback_score;
	const is_winning = (squad_info.is_home && matchScoreStatus === "home") 
    || (!squad_info.is_home && matchScoreStatus === "away");

	const logo = getClubLogoIndigenous(actual_round_id, squad_info.id);
	return (
		<SquadLine>
			<SquadLogoWrapper>
				<SquadLogo src={logo} alt="AFL club logo"/>
			</SquadLogoWrapper>
			<SquadName is_winning={is_winning}>{squad_info.short_name}</SquadName>
			<SquadScore is_winning={is_winning}>
				{`${goals_behinds}${score}`}
			</SquadScore>
		</SquadLine>
	);
};


type Props = {
	match: TMatch,
	squads_by_id: TSquadsById,
	pathname: string,
	selected_draft_league_id: number,
	actual_round: Object
}

const MatchPanel = ({ match, squads_by_id, pathname, selected_draft_league_id, actual_round }: Props) => {
	const date_time = moment(match.date);
	const date_formatted = date_time.format("dddd MMMM DD");
	const date_mobile_formatted = date_time.format("DD/MM");
	const time_formatted = date_time.format("HH:mm");
	const matchStatusGetter = () => {
		if(match.status === "playing"){
			return "LIVE";
		}
		if(match.status === "complete"){
			return "FT";
		}
		return "";
	};
	const matchStatusMobileValue = 
    match.status === "scheduled" ? time_formatted : matchStatusGetter();
  
  

	if (squads_by_id) {
		const home_squad = squads_by_id[match.home_squad_id];
		const away_squad = squads_by_id[match.away_squad_id];

		if (home_squad && away_squad) {
			const home = {
				id: home_squad.id,
				short_name: home_squad.short_name,
				score: match.home_score,
				goals: match.home_goals,
				behinds: match.home_behinds,
				is_home: true
			};

			const away = {
				id: away_squad.id,
				short_name: away_squad.short_name,
				score: match.away_score,
				goals: match.away_goals,
				behinds: match.away_behinds,
				is_home: false
			};
      
			let url = `/classic/afl-matchups/${match.id}`;
			if(pathname.substr(0, 6) === "/draft") {
				url = `/draft/league/${selected_draft_league_id}/afl-matchups/${match.id}`;
			}

			const matchResultStatus = () => {
				if(match.home_score > match.away_score){
					return "home";
				}
				if(match.home_score < match.away_score){
					return "away";
				}
				return "draw";
			};

			return (
				<ContainerLink id={`M-${match.id}`} to={url}>
					<TopContainer>
						<DateTimeConstainer>
							<Date>
								<AbovePhone>{date_formatted}</AbovePhone>
								<BelowPhone>{date_mobile_formatted}</BelowPhone>
							</Date>
							<Time is_desktop={true}>
								{time_formatted}
							</Time>
							<Time>
								{matchStatusMobileValue}
							</Time>
						</DateTimeConstainer>
					</TopContainer>
					<BottomContainer>
						{renderSquad(home, match, matchResultStatus(), actual_round.id)}
						{renderSquad(away, match, matchResultStatus(), actual_round.id)}
						<Button status={match.status}>
							{button_text[match.status] || button_text.scheduled}
						</Button>
					</BottomContainer>
				</ContainerLink>
			);
		}
	}

	return null;
};

const mapStateToProps = state => ({
	squads_by_id: selectors.squads.getSquadsById(state),
	pathname: state.uiMenu.pathname,
	selected_draft_league_id: state.uiSelectedLeague.selected
});

export default connect(mapStateToProps)(MatchPanel);