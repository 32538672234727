// @flow
import * as _ from "lodash";
import type { TRound } from "../../modules/types";
const lastFiveHelper = (currentRound: TRound, statsBasis: string = "stats") => {
	const currentRoundStatus = _.get(currentRound, "matches[0].status", "scheduled");
	const isFirstMatchComplete = currentRoundStatus === "complete";
	const lastCompleteRound = isFirstMatchComplete ? currentRound.id : currentRound.id - 1;
	const roundLowerBound = lastCompleteRound - 5 > 0 ? lastCompleteRound - 5 : 0;
	let holdingArr = [];
	for(let i = lastCompleteRound; i > roundLowerBound; i--){
		holdingArr.unshift({
			key: `R${i}`,
			prop: `${statsBasis}.scores[${i}]`,
			tooltip: `Points scored in round ${i}`,
			name: `Round ${i} score`
		});
	}
	return holdingArr;
};


export const coach_player_stats = (
	statsBasis: string = "stats", 
	currentRound: TRound, 
	is_classic: boolean
) => ({
	"coaches-choice": [
		{
			key: "GP",
			prop: `${statsBasis}.games_played`,
			tooltip: "Games Played",
		},
		{
			key: "AVG",
			prop: `${statsBasis}.avg_points`,
			tooltip: "Average Points",
		},
		{
			key: "TP",
			prop: `${statsBasis}.total_points`,
			tooltip: "Total Points",
		},
		{
			key: "TS%",
			prop: is_classic ? "stats.owned_by" : "stats.leagues_rostered",
			name: "Selection Percentage",
			tooltip: "Percentage of teams who own player",
			is_fc: false
		},
		{
			key: "%Starts",
			prop: "stats.selections_info.start",
			name: "Start Percentage",
			tooltip: "Percentage of teams starting the player on field",
			is_fc: true,
			format: "0.[0]",
		},
		{
			key: "% as C",
			prop: "stats.selections_info.c",
			name: "Captain Percentage",
			tooltip: "Percentage of teams with the player as Captain",
			is_fc: true,
			format: "0.[0]",
		},
		{
			key: "% as VC",
			prop: "stats.selections_info.vc",
			name: "Vice-Captain Percentage",
			tooltip: "Percentage of teams with the player as Vice-Captain",
			is_fc: true,
			format: "0.[0]",
		},
		{
			key: "IN",
			prop: "stats.traded_in_week",
			name: "Trade Ins",
			tooltip: "Number of teams who have traded the player in this week",
			is_fc: true,
			format: "0.[0]a",
		},
		{
			key: "OUT",
			prop: "stats.traded_out_week",
			name: "Trade Outs",
			tooltip: "Number of teams who have traded the player out this week",
			is_fc: true,
			format: "0.[0]a",
		},
		{
			key: "PROJ AVG",
			prop: "stats.proj_avg",
			name: "Projected Average",
			tooltip: "Player's Projected Average this season",
			is_fc: true,
		},
	],
	consistency: [
		{
			key: "GP",
			prop: `${statsBasis}.games_played`,
			tooltip: "Games Played",
		},
		{
			key: "AVG",
			prop: `${statsBasis}.avg_points`,
			tooltip: "Average Points",
		},
		{
			key: "TP",
			prop: `${statsBasis}.total_points`,
			tooltip: "Total Points",
		},
		{
			key: "L3 AVG",
			prop: `${statsBasis}.last_3_avg`,
			tooltip: "Average Points over last three games",
			name: "Last 3 Average",
		},
		{
			key: "L5 AVG",
			prop: `${statsBasis}.last_5_avg`,
			tooltip: "Average Points over last five games",
			name: "Last 5 Average",
		},
		{
			key: "Consistency",
			prop: `${statsBasis}.consistency`,
			tooltip: "Consistency Rating",
			is_fc: true,
		},
		{
			key: "20 < AVG",
			prop: `${statsBasis}.out_20_avg`,
			name: "Scores 20 < Average",
			tooltip: "Number of Scores that are 20 points below player's average",
			is_fc: true,
		},
		{
			key: "20 > AVG",
			prop: `${statsBasis}.in_20_avg`,
			name: "Scores 20 > Average",
			tooltip: "Number of Scores that are 20 points above player's average",
			is_fc: true,
		},
	],
	opposition: [
		{
			key: "GP",
			prop: `${statsBasis}.games_played`,
			tooltip: "Games Played",
		},
		{
			key: "AVG",
			prop: `${statsBasis}.avg_points`,
			tooltip: "Average Points",
		},
		{
			key: "TP",
			prop: `${statsBasis}.total_points`,
			tooltip: "Total Points",
		},
		{
			key: "OPP AVG",
			prop: `${statsBasis}.opp_avg_one_round_after_current`,
			tooltip: "Average against upcoming opponent",
			name: "Average vs Next Opponent",
			is_fc: true,
		},
		{
			key: "OPP 2W",
			prop: `${statsBasis}.opp_avg_two_rounds_after_current`,
			tooltip: "Average against upcoming opponent in two weeks",
			is_fc: true,
			name: "Average vs Opponent in 2 weeks",
		},
		{
			key: "OPP 3W",
			prop: `${statsBasis}.opp_avg_three_rounds_after_current`,
			tooltip: "Average against upcoming opponent in three weeks",
			is_fc: true,
			name: "Average vs Opponent in 3 weeks",
		},
		{
			key: "N3 OPP AVG",
			prop: `${statsBasis}.opp_avg_3_rounds`,
			tooltip: "Average against next three upcoming opponents",
			name: "Average vs Next 3 Opponents",
			is_fc: true,
		},
	],
	venues: [
		{
			key: "GP",
			prop: `${statsBasis}.games_played`,
			tooltip: "Games Played",
		},
		{
			key: "AVG",
			prop: `${statsBasis}.avg_points`,
			tooltip: "Average Points",
		},
		{
			key: "TP",
			prop: `${statsBasis}.total_points`,
			tooltip: "Total Points",
		},
		{
			key: "VEN AVG",
			prop: `${statsBasis}.venue_avg_one_round_after_current`,
			tooltip: "Average at upcoming game venue",
			is_fc: true,
			name: "Average at Next Venue",
		},
		{
			key: "VEN 2W",
			prop: `${statsBasis}.venue_avg_two_rounds_after_current`,
			tooltip: "Average at upcoming game venue in two weeks",
			is_fc: true,
			name: "Average at Venue in 2 weeks",
		},
		{
			key: "VEN 3W",
			prop: `${statsBasis}.venue_avg_three_rounds_after_current`,
			tooltip: "Average at upcoming game venue in three weeks",
			is_fc: true,
			name: "Average at Venue in 3 weeks",
		},
		{
			key: "N3 VEN AVG",
			prop: `${statsBasis}.venue_avg_3_rounds`,
			tooltip: "Average at next three upcoming game venues",
			is_fc: true,
			name: "Average at Next 3 Venues",
		},
	],
	form: [
		{
			key: "GP",
			prop: `${statsBasis}.games_played`,
			tooltip: "Games Played",
		},
		{
			key: "AVG",
			prop: `${statsBasis}.avg_points`,
			tooltip: "Average Points",
		},
		{
			key: "TP",
			prop: `${statsBasis}.total_points`,
			tooltip: "Total Points",
		},
		{
			key: "L3 AVG",
			prop: `${statsBasis}.last_3_avg`,
			tooltip: "Average Points over last three games",
			name: "Last 3 Average",

		},
		{
			key: "L5 AVG",
			prop: `${statsBasis}.last_5_avg`,
			tooltip: "Average Points over last five games",
			name: "Last 5 Average",
		},
		{
			key: "LAST RD",
			prop: `${statsBasis}.points_last_round`,
			tooltip: "Score Last Round",
		},
		{
			key: "2 RD Ago",
			prop: `${statsBasis}.points_2_rounds_prior`,
			tooltip: "Score from two rounds ago",
			is_fc: true,
			name: "Score 2 Rounds Ago",
		},
		{
			key: "3 RD Ago",
			prop: `${statsBasis}.points_3_rounds_prior`,
			tooltip: "Score from three rounds ago",
			is_fc: true,
			name: "Score 3 Rounds Ago",
		},
		{
			key: "L3 Proj AVG",
			prop: `${statsBasis}.last_3_proj_avg`,
			tooltip: "Projected average over last three rounds",
			is_fc: true,
			name: "Projected Average of Last 3 Rounds",
		},
		{
			key: "L3 VS Proj",
			prop: `${statsBasis}.last_3_avg_vs_proj`,
			tooltip: "Difference between projected and actual average over last three rounds",
			is_fc: true,
			name: "Last 3 Average vs Projected",
		},
	],
	"history": [
		{
			key: "GP",
			prop: `${statsBasis}.games_played`,
			tooltip: "Games Played",
		},
		...lastFiveHelper(currentRound, statsBasis),
		{
			key: "L5 AVG",
			prop: `${statsBasis}.last_5_avg`,
			tooltip: "Average Points over last five games",
			name: "Last 5 Average",
		},
	]
});
export const team_trade_coach_player_stats = {
	"coaches-choice": [
		{
			key: "AVG",
			prop: "stats.avg_points",
			tooltip: "Average Points",
		},
		{
			key: "% as C",
			prop: "stats.selections_info.c",
			tooltip: "% as captain",
			is_fc: true,
		},
		{
			key: "% as VC",
			prop: "stats.selections_info.vc",
			tooltip: "% as VC",
			is_fc: true,
		},
		{
			key: "% Starting",
			prop: "stats.selections_info.start",
			tooltip: "% starting on field",
			is_fc: true,
		},
	],
	consistency: [
		{
			key: "AVG",
			prop: "stats.avg_points",
			tooltip: "Average Points",
		},
		{
			key: "L3 AVG",
			prop: "stats.last_3_avg",
			tooltip: "Average Points over last three games",
			is_fc: true,

		},
		{
			key: "L5 AVG",
			prop: "stats.last_5_avg",
			tooltip: "Average Points over last five games",
			is_fc: true,
		},
		{
			key: "Cons",
			prop: "stats.consistency",
			tooltip: "Consistency Rating",
			is_fc: true,
		},
	],
	opposition: [
		{
			key: "GP",
			prop: "stats.games_played",
			tooltip: "Games Played",
		},
		{
			key: "AVG",
			prop: "stats.avg_points",
			tooltip: "Average Points",
		},
		{
			key: "TP",
			prop: "stats.total_points",
			tooltip: "Total Points",
		},
		{
			key: "N3 OPP AVG",
			prop: "stats.opp_avg_3_rounds",
			tooltip: "Average against upcoming three opponents",
			is_fc: true,
		},
	],
	venues: [
		{
			key: "GP",
			prop: "stats.games_played",
			tooltip: "Games Played",
		},
		{
			key: "AVG",
			prop: "stats.avg_points",
			tooltip: "Average Points",
		},
		{
			key: "TP",
			prop: "stats.total_points",
			tooltip: "Total Points",
		},
		{
			key: "N3 VEN AVG",
			prop: "stats.venue_avg_3_rounds",
			tooltip: "Average at upcoming three game venues",
			is_fc: true,
		},
	],
	form: [
		{
			key: "AVG",
			prop: "stats.avg_points",
			tooltip: "Average Points",
		},
		{
			key: "Last RD Score",
			prop: "stats.points_last_round",
			tooltip: "Score Last Round",
		},
		{
			key: "L3 Proj AVG",
			prop: "stats.last_3_proj_avg",
			tooltip: "Projected average over last three rounds",
			is_fc: true,
		},
		{
			key: "L3 V Proj",
			prop: "stats.last_3_avg_vs_proj",
			tooltip: "Difference between projected and actual average over last three rounds",
			is_fc: true,
		},
	],
};