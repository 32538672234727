/* eslint max-len: 0 */
// @flow
import React from "react";
import SvgIconContainer from "../SvgIconContainer";

export default (
	{
		unit = "em",
		...rest
	}: Object = {}) => (
	<SvgIconContainer viewBox="0 0 24 24" {...rest} unit={unit}>
		<title>Undo</title>
		<path d="m10.1129466 5.53743285-2.96885342 2.77064655h7.01061582c2.0883213-.00000003 4.0180098 1.11410612 5.0621705 2.9226454 1.0441607 1.8085394 1.0441607 4.0367517 0 5.845291s-2.9738492 2.9226455-5.0621705 2.9226455h-2.592575c-.2357808.0000533-.4619199-.0935874-.6286412-.2603107-.2542467-.2542319-.3302982-.6365922-.1926873-.9687646s.4617799-.5487311.8213285-.5486827h2.592575c2.2413547-.0071727 4.054537-1.8261667 4.054537-4.067533 0-2.2413662-1.8131823-4.0603602-4.054537-4.067533h-7.01061582l2.96885342 2.7715424c.1723201.1609053.2736797.3836626.2817897.6192872.0123333.3593395-.1930158.6907233-.52028793.8396189-.21459218.0976561-.4591926.1060495-.67997423.0233332-.10933367-.0409384-.20952691-.1030238-.29485203-.182707l-4.6168415-4.30928127c-.18036133-.16814171-.28278061-.40364415-.28278061-.65022445s.10241928-.48208273.28278061-.65022444l4.6168415-4.30840624c.35891409-.33465321.9211134-.31516348 1.25599089.04354135.3349413.35778019.3158419.92001738-.0426664 1.2551159z"/>
	</SvgIconContainer>
);