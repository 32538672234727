// @flow
import * as React from "react";
import { connect } from "react-redux";
import _, { get, isNumber } from "lodash";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import numeral from "numeral";
import * as actions from "../../../modules/actions";
import { default_transition } from "../../../assets/css/vars";
import { below, above } from "../../../assets/css/media";
import type { TPlayer, TRound, TMatch } from "../../../modules/types";
import {
	FantasyCoachModal,
	PlayerCost,
	PlayerInfoShadow,
	PlayerStatusIcon,
	StatusButton,
	FavButton,
} from "../../../components";
import PlayerInfo from "../../../components/PlayerInfo";
import { ByeButton } from "../../ByeDetector";
import {
	displayRoundLabel,
	displayRoundScore,
	roundHasStarted,
} from "../../../helpers/stats/displayRound";
import * as selectors from "../../../modules/selectors";

import { isMobile } from "../../../utils";
import { shouldHideTrash } from "../../../pages/classic/myTeam/hideTrash";
import Captain from "../../../components/Icons/Captain";
import NoDrop from "../../../components/Icons/NoDrop";
import positionEmergencyButton from "../../utils/positionEmergency";
import ViceCaptain from "../../../components/Icons/ViceCaptain";
import Emergency from "../../../components/Icons/Emergency";
import Swap from "../../../components/Icons/Swap";
import SwapDisabled from "../../../components/Icons/SwapDisabled";
import Trash from "../../../components/Icons/Trash";
import Trade from "../../../components/Icons/Trade";
import TradeDisabled from "../../../components/Icons/TradeDisabled";
import Lock from "../../../components/Icons/Lock";
import Close from "../../../components/Icons/Close";
import More from "../../../components/Icons/More";
import Undo from "../../../components/Icons/Undo";
import colors, { lightenColor } from "../../../assets/css/colors";
import { onDragOver } from "../../../utils/drag_and_drop";
import {getClubLogoIndigenous} from "../../../utils/clubLogos";
import Star from "../../Icons/Star";
import byeLogo from "../../../assets/images/bye-light.svg";
import { isAllTrue, isAnyTrue, isEditTradePage } from "../../../helpers";
import { Exist } from "../../Exist";
import CommandButton, { getCommandButtonColors } from "./commandButton";

const getPlayerColor = ({ is_captain, is_vice, is_emergency, odd }) => {
	const { captain, vice_captain, emergency, odd_row } = colors.player_background;

	if (is_captain) {
		return captain;
	}
	if (is_vice) {
		return vice_captain;
	}
	if (is_emergency) {
		return emergency;
	}
	return odd ? odd_row : "white";
};

const highlightSwap = ({ is_on_trade, is_trade_view, in_substitute }) => {
	return is_on_trade || (is_trade_view && (in_substitute)) ? css`
		box-shadow: 0 0 10px 0 rgba(38,36,36,0.5);
		border-radius: 5px;
		width: 100%;
		z-index: 1;
		${below.desktop`
			width: 100%;
		`};
	` : "";
};


const ListViewPlayerWrapper = styled.div`
	height: 60px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	background-color: ${getPlayerColor};
	opacity: ${({ locked_by_swap }) => locked_by_swap ? "0.6" : "1"};
	box-shadow: ${({ swap_highlighted }) => (
		swap_highlighted ? "0 0 10px 0 rgba(38,36,36,0.5)" : "0 0 0 0 #fff"
	)};
	z-index: 0;
	position: relative;
	padding: 0;
	${below.desktop`
		padding: 0 0 0 ${({ no_padding }) => no_padding ? 0 : 5}px;
	`};
	${({ swap_highlighted }) => swap_highlighted && "border-radius: 5px;"}
	${highlightSwap}
	${({ out_substitute }) => out_substitute ? css`
		border: 3px solid #198DE1;
		position: relative;
	` : "" }

	${default_transition}

	.popped-up + &, .popped-up {
		border-top-color: transparent;
	}

	${({ fade_out }) => fade_out && "opacity: 0.3;"};
	cursor: ${({ draggable }) => draggable ? "grab" : "default"};
	
	&:active {
        cursor: grabbing;
    }
  
  .absolute-empty{
    ${above.phone`
      display: none;
    `}
    ${below.phone`
      min-width: 290px;
      background: inherit;
    `}
  }
  
  
`;
const EditListViewPlayerWrapper = styled(ListViewPlayerWrapper)`
	height: ${isMobile() ? "60px;" : "30px;"};
	${above.tablet`
		border-top: none;
	`};
	${below.phone`
		justify-content: space-between;
	`}
`;

const PlayerInfoDiv = styled.div`
	display: flex;
	flex: 0 1 ${({ is_trade_view }) => is_trade_view ? "220px" : "280px"};
	${below.desktop`
		flex-basis: 65%;
	`};
	${below.tablet`
		flex-basis: 250px;
	`};
	${below.phone`
    flex-basis: ${({ is_draft }) => !is_draft ? "230px" : "180px"};
    box-shadow: ${({ is_draft }) => is_draft && "5px 0px 10px -6px #333"};
    z-index: ${({ is_draft }) => is_draft && "20"};
	`}
  
`;

const PlayerStatus = styled.div`
	position: relative;
	display: flex;
	width: 30px;
	margin-left: 5px;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;

	${below.phone`
		width: ${({ is_draft }) => is_draft ? "22px" : "15px"};
		margin-left: ${({ is_draft }) => is_draft ? "3px" : "0px"};
	`}

	button {
		position: relative;
		z-index: 2;
	}
`;

const Shadow = styled(PlayerInfoShadow)`
	left: 9px;
	height: 50px;

	${below.phone`
		left: 7px;
	`}
`;

const StatusButtonStyled = styled(StatusButton)`
	height: 16px;
	width: 16px;
	margin: 2.5px 0;
	svg {
		height: 14px;
		width: 14px;
	}

	${below.phone`
		height: 8px;
		width: 8px;

		svg {
			display: none;
		}
	`}
`;

const FavButtonStyled = styled(FavButton)`
	height: 16px;
	width: 16px;
  ${below.phone`
    display: none;
  `}
`;

const PlayerAvatar = styled.div`
	height: 60px;
	object-fit: contain;
	cursor: pointer;

	img {
		max-width: 100%;
		height: 100%;
	}
`;

const PlayerDetails = styled.div`
	display: flex;
	flex-direction: column;
	flex:1;
	justify-content: space-around;
	padding: 5px 0 7px 0;
  ${below.phone`
    ${({ is_draft }) => !is_draft && `
      padding: 15px 0 15px 0;
    `}
  `}
`;

const PlayerDetailsTop = styled.div`
	display: flex;
	align-items: baseline;
	margin-top: ${ ({ is_draft }) => is_draft ? "6px" : "0"};
`;

const Positions = styled.span`
	color: ${colors.primary.primary};
	font-family: SourceSansPro;
  ${({ trade_view }) => trade_view && `
    text-transform: uppercase;
    font-size: 10px;
    margin-left: 5px;
  `}
	span.short {
		display: none;
	}

	${below.phone`
		span.long {
			display: none;
		}
		span.short {
			display: inline;
		}
	`}
`;

const PlayerDetailsBottom = styled.div`
	display: flex;
	align-items: baseline;
	${below.phone`
		> p {
			font-size: 10px;
			height: 14px;
		}
	`}
`;

const PlayerName = styled.p`
	color: ${colors.primary.primary};
	font-weight: 700;
	font-size: 16px;
	cursor: pointer;
	text-align: left;
	line-height: 1;
	z-index: 10;
	:hover {
		text-decoration: underline;
	}


	max-width: 150px;
	white-space: nowrap;
	height: 20px;
	text-overflow: ellipsis;
	overflow: hidden;

	& > span.initials {
		display: none;
	}

	${below.phone`
		max-width: 65px;
		font-size: 11px;

		& > span.initials {
			display: inline;
		}
		& > span.full {
			display: none;
		}
	`}
`;

const PlayerStatsWrapper = styled.div`
	display: flex;
	flex: 1 1 0px;
	justify-content: space-around;
	align-items: center;
	-ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;
	&::-webkit-scrollbar {
  	display: none;
	}
  ${below.phone`
    ${({ is_draft }) => is_draft && `
      overflow-x: scroll;
      height: 100%;
    `}
  `}
  
`;
const AbsoluteButtonUndo = styled.button`
	position: absolute;
	top: -2px;
	right: -2px;
	border: none;
	color: #fff;
	background: #198DE1;
	border-radius: 0 0 0 5px;
	width: 29px;
	height: 29px;
	padding: 0;
	cursor: pointer;

	:hover {
		background-color: ${lightenColor(colors.warning, 0.2)};
	}
`;

const getStatsColor = ({ is_coach, is_single, is_live, is_average, is_dnp }) => {
	if(is_coach) {
		return "#B89344";
	}
	if (is_live) {
		return "#7ED321";
	}
	if(is_single) {
		if (is_average) {
			return colors.primary.primary;
		}

		return colors.primary.darkGrey;
	}

	return "#1D4073";
};

const StatsItemStack = styled.div`
	font-size: 20px;

	:last-child {
		font-size: 8px;
		font-weight: 400;
		text-transform: uppercase;
		display: inline-flex;
		align-items: center;

		svg {
			padding-right: 3px;
			padding-bottom: 2px;
		}
	}
`;

const PlayerStatsItem = styled.div`
	color: ${getStatsColor};
	flex: 1;
	
	display: flex;
    justify-content: center;
    align-items: center;

	font-family: SourceSansPro;
	font-weight: 600;
	font-size: ${({ is_single }) => is_single ? "16px" : "12px"};

	${({ is_single }) => is_single && css`
		flex-direction: column;
	`}}

	button {
		margin-top: 5px;
		${({ is_single }) => !is_single && "font-size: 12px"};
	}

	${below.phone`
		align-items: center;
		padding: 0 5px;
    min-width: 40px;
	`};

	${StatsItemStack} {
		color: ${getStatsColor};

		&:last-child > svg {
			fill: ${getStatsColor};
		}
	}

	> img{
		height: 30px;
		width: 30px;
	}  
`;

const PlayerFixtureWrapper = styled.div`
	color: #1D4073;
	font-family: SourceSansPro;
	font-weight: 600;
	font-size: 12px;
	display: flex;
	flex-direction: row;
	align-items: center;
	.players-team{
		font-weight: bold;
	}
	img{
		width: 30px;
		height: 30px;
		${below.phone`
			margin-right: 50px;
		`}
	}
	.vs{
		margin: 0 4px;
	}
`;
const EditTeamsOppositionButtonsWrap = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	min-width: 186px;
	justify-content: ${({ active }) => active ? "flex-start" : "flex-end"};
	box-shadow: ${({ active }) => active ? "0 0 10px 0 rgba(0,0,0,0.2)" : ""};
	background: inherit;
	height: 100%;
	${PlayerFixtureWrapper}{
		img{
			${below.phone`
				margin-right: 23px;
			`}
		}
	}
	button{
		height: 24px;
		width: 24px;
		svg{
			height: 24px;
			width: 24px;
		}
	}
`;


const MobileActions = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	margin-left: auto;

	background: white;
	position: absolute;
  box-shadow: 1px 4px 20px -6px #333;
	top: 0px;
	right: 0px;
	height: 100%;
	z-index: 21;
  width: 230px;

	padding: 0 5px;

	${({ menu_closed }) => menu_closed && css`
		display: none;
	`}
`;

const Actions = styled.div`
	display: flex;
	align-items: center;
	justify-content: ${({ is_draft, is_locked }) => is_draft && is_locked ? 
		"flex-end" : "space-between"};
	margin-left: ${({ edit_team_view }) => edit_team_view ? "" : "auto"};
	${({ edit_teams_view }) => edit_teams_view && `
		justify-content: space-around;
	`}
	margin-right: 10px;
	flex: 0 0 auto;
	width: ${({ is_draft }) => is_draft ? "135px" : "90px"};
	${below.desktop`
		margin: 0;
		position: relative;
		height: 100%;
		padding: ${({ edit_teams_more }) => edit_teams_more ? "0 10px 0 0" : "0 5px"};
		width: 40px;
		justify-content: center;
		${CommandButton} {
			margin: 0 5px;
		}
	`};
	& button {
		margin: ${({ is_draft, is_locked }) => is_draft && is_locked ? 
		"0 2px" : "0 2px"};
	}
  ${below.phone`
    box-shadow: ${({ is_draft }) => is_draft && "-8px 0px 15px -10px #333"};
  `}
`;

const StyledCommandButton = styled(CommandButton)`
	margin: 0;
  width: ${({ is_draft }) => is_draft ? "24px" : "20px"};
  height: ${({ is_draft }) => is_draft ? "24px" : "20px"};
  > svg {
		width: ${({ is_draft }) => is_draft ? "24px" : "20px"};
		height: ${({ is_draft }) => is_draft ? "24px" : "20px"};
	}
`;

const PlayerPointsWrap = styled.div`
	color: ${({ status }) => status === "live" ? "#1D4073":
		status === "projected" ? "#B89344":"#7F8A90"};
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	width: 60px;
`;

const PlayerScore = styled.div`
  font-family: "SourceSansPro";
  font-size: 26px;
  font-size: ${({ points }) => points === "DNP" ? "22px": "26px"};
  font-weight: bold;
  letter-spacing: 0;
  line-height: 26px;
  text-align: center;
  ${below.phone`
    font-size: 16px;
  `}
`;
const PlayerLabel = styled.div`

font-family: "SourceSansPro";
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 10px;
  text-align: center;
  ${below.phone`
    display: none;
  `}
`;

const stats_names = [
	"stats.games_played",
	"stats.owned_by",
	"stats.avg_points",
	"stats.last_3_avg",
	"stats.last_5_avg",
	"stats.points_last_round",
	"stats.cost_divided_by_points",
	"stats.total_points",
];

const stats_names_draft = [
	"stats.games_played",
	"stats.leagues_rostered",
	"stats.avg_points",
	"stats.last_3_avg",
	"stats.last_5_avg",
	"stats.total_points",
];

const custom_stats_draft = [
	"stats.games_played",
	"stats.owned_by",
	"custom_stats.avg_points",
	"custom_stats.last_3_avg",
	"custom_stats.last_5_avg",
	"custom_stats.total_points",
];

const stats_names_trades = [
	"stats.points_last_round",
	"stats.games_played",
	"stats.avg_points",
	"stats.last_3_avg"
];

const coach_stats_names = [
	"stats.proj_score",
	"stats.break_even",
	"stats.bye_round_id",
	"partial_bye_round"
];

const coach_stats_names_draft = [
	"stats.proj_score",
	"stats.bye_round_id",
	"partial_bye_round"
];

const custom_coach_stats_names_draft = upcoming_round => [
	`custom_stats.proj_scores.${upcoming_round}`,
	"stats.bye_round_id",
	"partial_bye_round"
];

const coach_stats_names_trades = [
	"stats.proj_score",
	"stats.break_even",
	"stats.bye_round_id",
	"partial_bye_round"
];

export const SwapCommandButtonCommand = styled(CommandButton)`
	cursor: pointer;
	transition: box-shadow linear 0.5s;
	box-shadow: 0 0 0 ${colors.form.base}00;
	background-color: unset;

	width: ${({ is_draft }) => is_draft ? "24px" : "20px"};
	height: ${({ is_draft }) => is_draft ? "24px" : "20px"};
	margin: 0;

	:hover {
		box-shadow: 0 0 10px ${colors.form.base}aa;
		background-color: ${colors.form.base}aa;
	}
`;

export const SwapCommandButton = styled(CommandButton)`
	cursor: pointer;
	transition: box-shadow linear 0.5s;
	box-shadow: 0 0 0 ${colors.form.base}00;
	background-color: unset;

	width: ${({ is_draft }) => is_draft ? "24px" : "20px"};
	height: ${({ is_draft }) => is_draft ? "24px" : "20px"};
	margin: 0;

	:hover {
		box-shadow: 0 0 10px ${colors.form.base}aa;
		background-color: ${colors.form.base}aa;
	}
`;

export const AddPlayerCommandButton = styled(CommandButton)`
	cursor: pointer;
	transition: box-shadow linear 0.5s;
	box-shadow: 0 0 0 ${colors.form.base}00;
	background-color: unset;

	width: ${({ is_draft }) => is_draft ? "24px" : "20px"};;
	height: ${({ is_draft }) => is_draft ? "24px" : "20px"};;
	margin: 0;

	:hover {
		box-shadow: 0 0 10px ${colors.form.base}aa;
		background-color: ${colors.form.base}aa;
	}
`;

const LockedCommandButton = styled(CommandButton)`
	width: ${({ is_draft }) => is_draft ? "24px" : "20px"};;
	height: ${({ is_draft }) => is_draft ? "24px" : "20px"};;
	background-color: unset;
	cursor: default;
`;

const CommandButtonLast = styled(CommandButton)`
	margin: 0;
	background: rgba(0,0,0,0);
	display: ${({ is_hidden }) => is_hidden ? "none" : "flex"};
	align-items: center;
	justify-content: center;

	height: ${({ is_draft }) => is_draft ? "24px" : "20px"};
  width: ${({ is_draft }) => is_draft ? "24px" : "20px"};
  > svg {
		width: ${({ is_draft }) => is_draft ? "24px" : "20px"};
		height: ${({ is_draft }) => is_draft ? "24px" : "20px"};
	}
	padding: 0;

	${default_transition};

	> svg {
		${default_transition};
	}

	${({ trade }) => trade && css`
		&:hover {
			> svg {
				fill: #1b63a4;
			}
		}
	`};
`;

const CommandButtonTrade = styled(CommandButton)`
	width: ${({ is_draft }) => is_draft ? "24px" : "20px"};
	height: ${({ is_draft }) => is_draft ? "24px" : "20px"};
	margin: 0;
	> svg {
		width: ${({ is_draft }) => is_draft ? "24px" : "20px"};
		height: ${({ is_draft }) => is_draft ? "24px" : "20px"};
	}
`;

const CommandButtonSwapTrash = styled(CommandButton)`;
	margin: 0;
  width: ${({ is_draft }) => is_draft ? "24px" : "20px"};
  height: ${({ is_draft }) => is_draft ? "24px" : "20px"};
  > svg {
		width: ${({ is_draft }) => is_draft ? "24px" : "20px"};
		height: ${({ is_draft }) => is_draft ? "24px" : "20px"};
	}
`;

const CommandButtonTradeTeam = styled(CommandButton)`
	width: ${({ is_draft }) => is_draft ? "24px" : "20px"};
	height: ${({ is_draft }) => is_draft ? "24px" : "20px"};
	> svg {
		width: ${({ is_draft }) => is_draft ? "24px" : "20px"};
		height: ${({ is_draft }) => is_draft ? "24px" : "20px"};
	}
	margin: auto;
	background: none;
`;
const CommandButtonUndo = styled(CommandButtonTrade)`
	background: #D92425;
	color: #fff;
	width: ${({ is_draft }) => is_draft ? "24px" : "20px"};
	height: ${({ is_draft }) => is_draft ? "24px" : "20px"};
	> svg {
		width: ${({ is_draft }) => is_draft ? "24px" : "20px"};
		height: ${({ is_draft }) => is_draft ? "24px" : "20px"};
	}
	border-radius: 2px;
	margin-left: auto;
	margin-right: ${({ small_margin }) => small_margin ? "5px" : "auto"};

	&:hover {
		background-color: ${lightenColor(colors.warning, 0.2)};
	}
`;

const FcLink = styled.a`
	color: ${colors.coach};
	cursor: pointer;
`;
const TradeButtonWrapper = styled.div`
	flex: 0 0 70px;
	display: flex;
	align-items: center;
	justify-content: center;
`;
const getColor = (is_true, color_true, color_false) => {
	return is_true ? color_true : color_false;
};

const getHiddenCommand = (is_substitute, is_locked, locked_by_swap) => {
	return !is_substitute || is_locked || locked_by_swap;
};

const getHiddenSwapCommand = (is_substitute, is_locked, locked_by_swap) => {
	return is_substitute || is_locked || locked_by_swap;
};

const isLockedBySwap = (in_substitute, is_substitute, out_substitute) => {
	return !in_substitute && is_substitute && !out_substitute;
};

const isLockedByTradeSwap = (in_substitute, is_substitute, out_substitute, is_empty_cell) => {
	return isLockedBySwap(in_substitute, is_substitute, out_substitute) ||
		(!is_empty_cell && !in_substitute);
};

const formatCost = (cost): any => {
	if (_.isNumber(cost)) {
		return `$${numeral(cost / 1000).format("0.[0]")}k`;
	}
	return cost;
};

const getIsTradePage = () => {
	return _.get(window.location, "pathname", "")
		.includes("advanced-trades") ? true:false;
};

const handleTradePageStat = (isTradesPage, player_stat_field) => {
	return isTradesPage ? 
		player_stat_field : isMobile() ? _.get(player_stat_field, "key") : player_stat_field;
};

const handleStatValue = stat_value => {
	return _.isNumber(stat_value) ? Math.round(stat_value) : stat_value;
};

const getClassnamePopped = (popped: boolean) => {
	return popped ? " popped-up" : "";
};


type Props = {
	player: TPlayer,
	position_id?: number,
	is_bench?: boolean,
	is_mobile?: boolean,
	is_captain: boolean,
	is_vice: boolean,
	is_emergency?: boolean,
	emergency_position?: number,
	is_team_started: boolean,
	is_substitute: boolean,
	in_substitute: boolean,
	out_substitute: boolean,
	is_locked: boolean,
	player_stat_field: string | typeof undefined,
	is_draft?: boolean,
	has_captain?: boolean,
	has_emergencies?: boolean,
	has_assistant_coach: boolean,
	selected_round?: TRound,
	removePlayer: Function,
	setCaptain?: Function,
	setViceCaptain?: Function,
	setEmergency?: Function,
	substitutePlayer: Function,
	pureSubstitutePlayer: Function,
	cancelSubstitute: Function,
	openPlayerPopUp: Function,
	delist_link?: string,
	trade_link?: string,
	is_trade_view?: boolean,
	is_trade_view_team?: boolean,
	match_not_started?: boolean,
	is_on_trade?: boolean,
	removePlayerInTrade?: Function,
	removePlayerOutTrade?: Function,
	position_index?: number,
	is_in_swap?: boolean,
	trade_id?: number,
	is_empty_cell?: boolean,
	players_margin?: number,
	double_score?: boolean,
	trade?: Object,
	locked_personnel: any[],
	is_first_round_with_partial_lockout: boolean,
	is_static: boolean,
	is_current: boolean,
	bye_detector_selected: number[],
	bye_detector_active: boolean,
	player_bye: number,
	mobile_stat?: string,
	no_padding: boolean,
	setPlayerAction: Function,
	is_utility: boolean,
	utility_position: string,
	odd?: boolean,
	postRemoveFromFavourites: typeof actions.postRemoveFromFavourites,
	postAddToFavourites: typeof actions.postAddToFavourites,
	player_is_favourite: boolean,
	no_trade_buttons?: boolean,
	edit_teams_view?: boolean,
	player_next_match: TMatch,
	pointsLastRound: number | string,
	pointsLabel: string,
	openAddPlayerModal: Function,
	closeAddPlayerModal: Function,
  custom_scoring_enabled?: boolean,
  actual_round: TRound,
  is_overflow?: boolean,
  is_my_team?: boolean,
  active_trade_id?: number,
  league: Object,
  player_partial_bye?: number,
  is_partial_or_standard?: string
};

type State = {
	is_actions_open: boolean,
	show_fantasy_coach_modal: boolean,
	edit_teams_buttons: boolean
}

class ListViewPlayerComponent extends React.Component<Props, State> {
	static defaultProps = {
		is_mobile: isMobile(),
		is_draft: false,
		has_captain: true,
		has_emergencies: true,
		locked_personnel: [],
		is_first_round_with_partial_lockout: false,
		is_static: false,
		is_current: true,
		bye_detector_selected:[],
		bye_detector_active: false,
		player_bye: 0,
		no_padding: false,
		is_utility: false,
		utility_position: "",
		setPlayerAction: (a: any, b: any) => null,
		pureSubstitutePlayer: _.noop,
		odd: false
	};

	constructor(props: Props, state: State) {
		super(props, state);

		_.bindAll(this, [
			"onToggleActions",
			"toggleFantasyCoachModal",
			"openPopup",
			"trashPlayer",
			"onDragStart",
			"onDrop",
			"onDragEnd",
			"handleEditTeamsButtons"
		]);

		this.onShowActions = this.onToggleActions.bind(this, true);
		this.onHideActions = this.onToggleActions.bind(this, false);
	}

	state = {
		is_actions_open: false,
		show_fantasy_coach_modal: false,
		edit_teams_buttons: false
	};

	toggleFantasyCoachModal() {
		const { show_fantasy_coach_modal } = this.state;
		this.setState({ show_fantasy_coach_modal: !show_fantasy_coach_modal });
	}

	onShowActions: Function;
	onHideActions: Function;

	onToggleActions(value: boolean | typeof undefined) {
		this.setState({
			is_actions_open: _.isBoolean(value) ? value : !this.state.is_actions_open
		});
	}
	handleEditTeamsButtons() {
		this.setState({
			edit_teams_buttons: !this.state.edit_teams_buttons
		});
	}

	get round_started() {
		const { selected_round } = this.props;
		return roundHasStarted(selected_round);
	}

	showByeButton() {
		const { player_bye, bye_detector_selected, bye_detector_active } = this.props;
	
		return bye_detector_active && _.includes(bye_detector_selected, player_bye);
	}

	showPartialByeButton() {
		const { bye_detector_selected, bye_detector_active, player_partial_bye } = this.props;
	
		return bye_detector_active && _.includes(bye_detector_selected, player_partial_bye);
		
	}

	shouldFadeOut() {
		const { 
			is_draft, 
			player_bye, 
			bye_detector_selected, 
			bye_detector_active, 
			player_partial_bye, 
			is_partial_or_standard 
		} = this.props;

		if(is_draft){
			return false;
		}
		if(is_partial_or_standard === "partial"){
			return bye_detector_active && !_.includes(bye_detector_selected, player_partial_bye);
		}
		return bye_detector_active && !_.includes(bye_detector_selected, player_bye);
	}

	getPlayerStatField(player_stat_field){
		const {is_draft} = this.props;
		return is_draft ? player_stat_field : _.get(player_stat_field, "key");
	}


	playerStatFieldUse(is_draft, player_stat_field, tradePageStats) {
		return is_draft ? player_stat_field : tradePageStats;
	}

	handledPartialBye() {
		return this.props.player_partial_bye || 0;
	}


	getStatValue(
		player: TPlayer,
		player_stat_field: string,
		is_draft: boolean,
		default_value: string = "- -"
	) {
		const partialBye = this.handledPartialBye();
		if (isAllTrue([ "partial_bye_round" === player_stat_field, this.showPartialByeButton()])) {
			return <ByeButton round={partialBye} selected={true} />;
		}
		if(isAnyTrue([
			get(player_stat_field, 'key') === "partial_bye_round",
			player_stat_field === "partial_bye_round"
		])){
			return partialBye;
		}
		if (isAllTrue(["stats.bye_round_id"=== player_stat_field, this.showByeButton()])) {
			const { player_bye } = this.props;
			return <ByeButton round={player_bye} selected={true} />;
		}
	
		if (isAllTrue([player_stat_field === "stats.leagues_rostered", Boolean(is_draft)])){
			let score = 
        _.get(player, "stats.leagues_rostered", default_value);
			score = Math.round(score);
			return score;
		}
		
		const score = this.getScoreStat({ player_stat_field, player, default_value });
		if (score !== undefined) {
			return score;
		}
		const isTradesPage = getIsTradePage();
		const tradePageStats = handleTradePageStat(isTradesPage, player_stat_field);

		const playerStatFieldUse = 
			this.playerStatFieldUse(is_draft, player_stat_field, tradePageStats);
		
		// If score is undefined, try just getting it straight from the player object
		let stat_value = _.get(player, playerStatFieldUse, default_value);
		stat_value = handleStatValue(stat_value);
		if (_.includes(["cost"], playerStatFieldUse)) {
			stat_value = formatCost(stat_value);
		}
		return stat_value;
	}

	getScoreStat({ player_stat_field, player, default_value }: Object) {
		const { selected_round = {}, double_score } = this.props;

		let score;
		switch (player_stat_field) {
			case "stats.proj_score":
				score = _.get(
					player,
					`stats.proj_scores.${selected_round.id}`,
					default_value
				);
				break;
			case "stats.points_last_round":
				const round_id = _.get(selected_round, "id", 0) - 1;
				const score_stat = `stats.scores.${round_id}`;
				// Return straight away so score isn't doubled
				return _.get(player, score_stat, round_id >= 1 ? "DNP" : "- -");
			case "stats.round_score":
				score = displayRoundScore(player, selected_round);
				break;
			default:
				return undefined;
		}

		// Check if you should double the score (i.e. for Captain)
		if (double_score && _.isNumber(score)) {
			score  = score * 2;
		}
		return score;
	}

	get stats_names() {
		const { is_draft, is_trade_view, custom_scoring_enabled } = this.props;
		if (is_draft) {
			return custom_scoring_enabled ? custom_stats_draft : stats_names_draft;
		}

		if (is_trade_view) {
			return stats_names_trades;
		}

		return stats_names;
	}

	get coach_stats_names() {
		const { is_draft, is_trade_view, custom_scoring_enabled, selected_round } = this.props;
		const upcoming_round = _.get(selected_round, "id");

		if (is_draft) {
			return custom_scoring_enabled ? custom_coach_stats_names_draft(upcoming_round) 
				: coach_stats_names_draft;
		}
		if (is_trade_view) {
			return coach_stats_names_trades;
		}

		return coach_stats_names;
	}

	renderMobileStats() {
		const {
			player, player_stat_field = "", is_trade_view, mobile_stat, is_draft
		} = this.props;
		const field_is_coach = coach_stats_names.includes(player_stat_field);
		/*
		 * Main stat should be calculated with the draft
		 * stat value function for live scoring
		 */
		const field_stat_value = this.getStatValue(
			player, player_stat_field, true
		);

		if (mobile_stat !== undefined) {
			/*
			const field_stat = this.getStatsItem(
				field_is_coach,
				field_stat_value,
				true,
				'field',
				false,
				player_stat_field
			);
			*/
			
			const mobile_is_coach = coach_stats_names.includes(mobile_stat);
			
			const mobile_stat_value = this.getStatValue(player, mobile_stat, Boolean(is_draft));
			const mobile_stat_element = this.getStatsItem(
				mobile_is_coach,
				mobile_stat_value,
				true,
				"mobile",
				false,
				mobile_stat
			);

			return (
				<PlayerStatsWrapper 
					className="player-stats" is_trade_view={is_trade_view} is_draft={is_draft}>
					{ !is_trade_view && this.getNextLogoStatItem()}
					{  mobile_stat_element }
				</PlayerStatsWrapper>
			);
  
		}

		return this.getStatsItem(
			field_is_coach, field_stat_value, true, "field", false, player_stat_field
		);
	}

	get stats_blocks() {
		const {
			player, odd, is_draft = false, is_trade_view, is_mobile
		} = this.props;

		if(is_mobile && !is_draft) {
			return this.renderMobileStats();
		}
		const
			common_stats = _.map(
				this.stats_names,
				(stats_name, key) => {
					const player_stat = this.getStatValue(player, stats_name, is_draft);
					return this.getStatsItem(false, player_stat, false, key, false, stats_name);
				}
			),
			coach_stats = _.map(
				this.coach_stats_names,
				(coach_stats_name, key) => {
					const player_stat = this.getStatValue(player, coach_stats_name, is_draft);
					
					return this.getStatsItem(
						true, player_stat, false, key, false, coach_stats_name
					);
				}
			);

		return (
			<PlayerStatsWrapper is_trade_view={is_trade_view} odd={odd} 
				className="scrollable-group"
				is_draft={is_draft}
			>
				{is_draft && <PlayerStatsItem className="absolute-empty" /> }
				{ !is_trade_view && this.getNextLogoStatItem()}
				{ common_stats }
				{ coach_stats }
			</PlayerStatsWrapper>
		);
	}

	getTradeLink() {
		const { is_draft, player, trade_link = "" } = this.props;
		const player_id = _.get(player, "id", "");
		return is_draft
			?  trade_link
			: `/classic/advanced-trades/${player_id}`;
	}
	isScoreField(stat_field: string) {
		const { is_draft } = this.props;
		return stat_field === "stats.round_score"
			|| (!is_draft && stat_field === "stats.points_last_round");
	}
	getStatsItem(
		is_coach_stat: boolean,
		player_stat: any,
		is_single: boolean,
		key: any,
		is_field?: boolean,
		stat_field: string
	) {
		const { has_assistant_coach, player } = this.props;
		const is_dnp = player_stat === "DNP";
		const stat = is_field
			? <React.Fragment>
				<StatsItemStack>
					{ player_stat }
				</StatsItemStack>
				<StatsItemStack>
					{ this.getFieldStatBottom(player_stat) }
				</StatsItemStack>
			</React.Fragment>
			: player_stat;
		return <PlayerStatsItem
			is_single={is_single}
			is_live={
				this.isScoreField(stat_field)
				&& player.is_playing_now
				&& player_stat !== "DNP"
				&& is_single
			}
			is_average={stat_field === "stats.avg_points"}
			is_coach={is_coach_stat}
			key={key}
			is_dnp={is_dnp}
		>
			{!is_coach_stat || has_assistant_coach
				? stat
				: <FcLink onClick={this.toggleFantasyCoachModal}>
					<Lock size={1.70}/>
				</FcLink>
			}
		</PlayerStatsItem>;
	}

	getNextLogoStatItem(){
		const { player, actual_round } = this.props;
		const squad_id = _.get(player.competition, "opponent.id");
		const imgSrc = squad_id ? getClubLogoIndigenous(actual_round.id, squad_id) : byeLogo;
		const stat = <img src={imgSrc} alt="Team logo" />;

		return <PlayerStatsItem>{stat}</PlayerStatsItem>;
	}


	getFieldStatBottom(player_stat: any) {
		const { player_stat_field, player, selected_round } = this.props;
		
		const statObject = {
			"stats.avg_points": "Average",
			"stats.last_3_avg": "AVG L3",
			"stats.last_5_avg": "AVG L5",
			"stats.points_last_round": "Last Round",
			"stats.round_score": displayRoundLabel(
				player,
				selected_round,
				player_stat
			),
			"stats.proj_score": "Projected",
			"stats.bye_round_id": "Bye Round"
		};
		return _.get(statObject, player_stat_field);

	}

	/**
	 * Once a player is locked, their captain buttons (i.e. C, VC)
	 * only display if they are selected
	 */

	isCaptainButtonHidden(type: 'C' | 'VC') {
		const {
			is_bench,
			is_captain,
			is_vice,
			is_substitute,
			is_locked,
			has_captain,
			locked_personnel
		} = this.props;

		/**
		 * In general, captain buttons are hidden:
		 *   - when substituing,
		 *   - when the player is on the bench
		 *   - when the league doesn't use captains
		 *   - when the captain/vc is already locked
		 */
		const selected = (type === "C" && is_captain) || (type === "VC" && is_vice);
		const cap_locked = (
			!selected
			&& locked_personnel.includes(type === "C" ? "captain" : "vice_captain")
		);
		const base_hidden = isAnyTrue([
			is_substitute, Boolean(is_bench), !has_captain, cap_locked 
		]);

		if (!is_locked) {
			return base_hidden;
		}

		return isAnyTrue([base_hidden, !selected]);
	}

	/**
	 * Once a player is locked, their emergency buttons
	 * only display if they are selected
	 */

	isEmergencyButtonHidden(emg_pos: number) {
		const {
			player,
			is_bench,
			is_emergency,
			is_substitute,
			is_locked,
			has_emergencies,
			emergency_position,
			locked_personnel
		} = this.props;

		/**
		 * In general, emergency buttons are hidden:
		 *   - when substituing,
		 *   - when the player is not on the bench
		 *   - when the league doesn't use emergencies
		 *   - when the current emergency at this position is locked
		 */
		const selected = is_emergency && (emergency_position === emg_pos || emg_pos === 0);
		const player_pos = _.get(player, ["positions", 0], 0);
		const emergency_locked = (
			!selected &&
			locked_personnel.includes(`emergency.${emg_pos || player_pos}`)
		);
		const base_hidden = isAnyTrue([
			is_substitute, !is_bench, !has_emergencies, emergency_locked
		]);

		if (!is_locked) {
			return base_hidden;
		}

		return base_hidden || !selected;
	}

	get action_buttons() {
		const {
			player,
			is_captain,
			is_vice,
			is_emergency,
			setCaptain,
			setViceCaptain,
			substitutePlayer,
			in_substitute,
			out_substitute,
			is_substitute,
			is_locked,
			position_id,
			is_mobile,
			cancelSubstitute,
			is_bench,
			is_static,
			is_draft,
			is_utility,
			edit_teams_view,
			openAddPlayerModal,
			is_overflow,
			is_my_team
			
		} = this.props;
		const
			{ is_actions_open, edit_teams_buttons } = this.state,
			locked_by_swap = isLockedBySwap(in_substitute, is_substitute, out_substitute),
			is_hidden_swap_command = getHiddenCommand(
				is_substitute,
				is_locked,
				locked_by_swap
			),
			is_hidden_command = getHiddenSwapCommand(
				is_substitute,
				is_locked,
				locked_by_swap
			),
			btn_colors = getCommandButtonColors(
				is_emergency,
				is_captain,
				is_vice,
			);

		const static_or_locked = isAnyTrue([is_static, is_locked]);

		const actions = <React.Fragment>
			{ this.renderEmergencyButton(btn_colors) }
			<StyledCommandButton
				{...btn_colors.captain}
				is_draft={is_draft}
				onClick={setCaptain}
				data-id={player.id}
				is_hidden={isAnyTrue([this.isCaptainButtonHidden("C"), Boolean(is_overflow)])}
			>
				<Captain />
			</StyledCommandButton>
			<StyledCommandButton
				{...btn_colors.vice}
				onClick={setViceCaptain}
				is_draft={is_draft}
				data-id={player.id}
				is_hidden={isAnyTrue([this.isCaptainButtonHidden("VC"), Boolean(is_overflow)])}
			>
				<ViceCaptain />
			</StyledCommandButton>
			<StyledCommandButton
				background="none"
				is_hidden={isAnyTrue([is_hidden_command, Boolean(is_overflow)])}
				onClick={event => {
					substitutePlayer(event);
					this.onHideActions();
				}}
				data-is_bench={is_bench}
				data-id={player.id}
				is_draft={is_draft}
				data-position={position_id}
				data-is_utility={is_utility}
				{...btn_colors.base}
			>
				<Swap size={1.25}/>
			</StyledCommandButton>
			<SwapCommandButton
				is_hidden={isAnyTrue([is_hidden_swap_command, Boolean(is_overflow) ])}
				onClick={event => {
					substitutePlayer(event);
					this.onHideActions();
				}}
				data-id={player.id}
				is_draft={is_draft}
				data-is_bench={is_bench}
				data-position={position_id}
				data-is_utility={is_utility}
				{...btn_colors.base}
			>
				<Swap size={2.5}/>
			</SwapCommandButton>
			<LockedCommandButton is_hidden={!locked_by_swap} is_draft={is_draft}>
				<NoDrop size={2.5}/>
			</LockedCommandButton>
			{ this.renderTradeButton(btn_colors) }
			{ this.renderTrashButton(btn_colors) }
			<Exist when={isAllTrue([
				Boolean(edit_teams_view),
				!static_or_locked
			])}>
				<AddPlayerCommandButton
					is_hidden={!edit_teams_view}
					onClick={() => {
						openAddPlayerModal(player.id);
					}}
					data-id={player.id}
					data-is_bench={is_bench}
					is_draft={is_draft}
					data-position={position_id}
					data-is_utility={is_utility}
					{...btn_colors.base}>
					<Trash />
				</AddPlayerCommandButton>
			</Exist>
	
			
			<CommandButtonLast
				is_hidden={isAnyTrue([
					!is_mobile, 
					isAllTrue([static_or_locked,!is_mobile ]), 
					Boolean(edit_teams_view)
				])}
				background="none"
				is_draft={is_draft}
				onClick={() => {
					this.onHideActions();
					cancelSubstitute();
				}}
				{...btn_colors.base}
			>
				<Close color="#CAD2D8" />
			</CommandButtonLast>
		</React.Fragment>;

		if(isAllTrue([Boolean(is_mobile), !edit_teams_view, !is_my_team])) {

			return(
				<Actions is_draft={is_draft}>
					<NoDrop />
				</Actions>
			);
			
		}

		if(isAllTrue([Boolean(is_mobile), !edit_teams_view])) {
			const is_show_closed_menu = _.some([
				_.every([is_actions_open, is_substitute], _.negate(_.identity)),
				_.every([is_substitute, locked_by_swap]),
			]);

			return(
				<Actions is_draft={is_draft}>
					<CommandButton
						background="none"
						onClick={() => !locked_by_swap && this.onShowActions()}
					>
						<More />
					</CommandButton>
					<MobileActions menu_closed={is_show_closed_menu}>
						{ actions }
					</MobileActions>
				</Actions>
			);
			
		}
		if(edit_teams_view){
			return(
				isMobile() ? 
					<Actions edit_teams_more={!edit_teams_buttons}> 
						{!edit_teams_buttons ? 
							<More 
								size={2} 
								onClick={this.handleEditTeamsButtons}
							/> : 
							<>
								{actions}
								<CommandButton 
									background="none" 
									onClick={this.handleEditTeamsButtons}
								>
									<Close />
								</CommandButton>
							</>} 
					</Actions> : 
					<Actions 
						is_static={static_or_locked} 
						is_draft={is_draft} 
						is_locked={is_locked}
						edit_teams_view={edit_teams_view}
					>
						{actions}
					</Actions>
			);
			
		}
		if(isAllTrue([!is_my_team, !edit_teams_view])){
			return(
				<Actions
					is_static={static_or_locked} 
					is_draft={is_draft} 
					is_locked={is_locked}
					edit_teams_view={false}
				>
					{ this.renderTradeButton(btn_colors) }
				</Actions>
			);
     
		}

		return <Actions 
			is_static={static_or_locked} 
			is_draft={is_draft} 
			is_locked={is_locked}
			edit_teams_view={edit_teams_view}
		>
			{actions}
		</Actions>;
	}

	/**
	 * Trash Button should always be hidden when substituting
	 * On classic, should be hidden after team is started (i.e. replaced by
	 *   trade button) or player is locked
	 * On draft, should be hidden once player is locked
	 * remove is_locked JIRA: AFD21-78
	 */
  
	isTrashHidden() {
    
		// const is_players_team_locked = player.is_playing_now 
		
		const {
			is_team_started,
			is_substitute,
			is_draft,
			
		} = this.props;
    
		
    
		return (!is_draft && is_team_started) || is_substitute;
	}

	/**
	 * Trade Button should always be hidden when substituting
	 * On classic, should be hidden before team is started (i.e. shows
	 *   trade button) or when player is locked
	 * On draft, should be hidden once player is locked
	 * remove is_locked JIRA: AFD21-78
	 */
	isTradeHidden() {
		const {
			is_team_started,
			is_substitute,
			is_draft,
		} = this.props;
		return (!is_draft && !is_team_started) || is_substitute;
	}


	isClassicStarted() {
		const {
			is_team_started,
			is_first_round_with_partial_lockout,
			is_locked
		} = this.props;

		if (!is_team_started) {
			if (is_first_round_with_partial_lockout) {
				return is_locked;
			}
			return false;
		}

		return !is_first_round_with_partial_lockout;
	}

	isNeedToLock() {
		const {
			is_locked,
			is_team_started,
			is_substitute,
		} = this.props;

		return is_team_started && (is_locked || is_substitute);
	}

	getPositions() {
		const { player } = this.props;

		const positions = _.get(player, "positions_full_name", []);

		return <React.Fragment>
			<span className="long">{ positions.join(" / ") }</span>
			<span className="short">{
				_.map(positions,
					pos => _.size(positions) > 1 ? pos.charAt(0) : pos
				).join("/")
			}</span>
		</React.Fragment>;
	}
	getShortPositions() {
		const { player } = this.props;
		const positions = _.get(player, "positions_name", []);

		return <React.Fragment>
			<span className="short">{
				_.map(positions,
					pos => pos
				).join("/")
			}</span>
		</React.Fragment>;
	}

	getPlayerNames() {
		const { player } = this.props;

		const first_init = _.get(player, "first_name", "").charAt(0);

		const player_name = `${player.first_name} ${player.last_name}`;
		const player_initials = `${first_init}. ${player.last_name}`;

		return <React.Fragment>
			<span className="full">{ player_name }</span>
			<span className="initials">{ player_initials }</span>
		</React.Fragment>;
	}

	/**
	 * In classic, trashing player performs the removePlayer action
	 * In draft, it links to the delist page
	 */
	renderTrashButton(btn_colors: Object) {
		const {
			is_draft,
			is_substitute,
			is_locked,
			is_team_started,
			is_first_round_with_partial_lockout,
			setPlayerAction,
			player,
			no_trade_buttons,
			actual_round
		} = this.props;
		const hasTeamPlayedInRound = () => {
			let match = {};
			if(!_.isEmpty(actual_round)){
				actual_round.matches.forEach(fixture => {
					if(player.squad_id === fixture.home_squad_id || 
            player.squad_id === fixture.away_squad_id){
						match = fixture;
					}
				});
			}
			
			return (match.status === "complete" && actual_round.status === "active");
		};
		const is_team_live = player.is_playing_now || hasTeamPlayedInRound();

		const hide_trash = is_team_live ? true : shouldHideTrash(
			is_substitute, is_locked, is_team_started, is_first_round_with_partial_lockout, is_draft
		);

		const draft_button = <CommandButtonSwapTrash
			is_hidden={this.isTrashHidden()}
			onClick={() => setPlayerAction(player.id, "delist")}
			is_draft={is_draft}
			trash
			{...btn_colors.base}
		>
			<Trash size={1.5} color="#D92425"/>
		</CommandButtonSwapTrash>;

		const button = <CommandButtonLast
			is_hidden={hide_trash}
			onClick={this.trashPlayer}
			is_draft={is_draft}
			trash
			{...btn_colors.base}
		>
			<Trash size={1.5} color="#D92425"/>
		</CommandButtonLast>;
		if(no_trade_buttons === true){
			return null;
		}
		return is_draft ? draft_button : button;
	}

	/**
	 * In classic, trading player links to advanced trade page
	 * In draft, it opens a modal which links to the delist page
	 */
	renderTradeButton(btn_colors: Object) {
		const { is_draft, setPlayerAction, player } = this.props;

		const draft_button = <CommandButtonLast
			is_hidden={this.isTradeHidden()}
			onClick={() => setPlayerAction(player.id, "trade")}
			is_draft={is_draft}
			trade
			{...btn_colors.base}
		>
			<Trade size={1.5}/>
		</CommandButtonLast>;

		const button =
		<Link to={this.getTradeLink()}>
			<CommandButtonLast
				is_hidden={!this.isClassicStarted() || this.isNeedToLock()}
				trade
				is_draft={is_draft}
				{...btn_colors.base}
			>
				<Trade size={1.5}/>
			</CommandButtonLast>
		</Link>;
		if(this.props.no_trade_buttons === true){
			return null;
		}
		return is_draft ? draft_button : button;
	}

	trashPlayer() {
		const {
			player,
			is_bench,
			is_captain,
			is_vice,
			is_emergency,
			removePlayer,
			position_id,
			is_draft,
		} = this.props;

		if (is_draft) {
			return;
		}

		removePlayer({
			id: player.id,
			is_bench,
			is_captain,
			is_vice,
			is_emergency,
			position: position_id,
		});
	}

	renderEmergencyButton(btn_colors: Object) {
		const {
			player,
			setEmergency,
			is_draft,
			is_emergency,
			emergency_position,
			is_utility,
			utility_position,
			is_overflow
		} = this.props;

		const base_color = "#CAD2D8",
			  base_bg = "#F8F8FA",
			  active_color = "#fff";

		const positions = _.get(player, "positions", []);
		const is_utility_case = isAllTrue([is_utility, positions, positions.length > 1]);

		/**
		 * If a draft player is DPP, should show a button for each position
		 */
		if(is_draft && is_overflow){
			return null;
		}
		if ((is_draft && positions.length > 1)) {
			return _.map(positions, pos_id => {
				const selected = is_emergency && pos_id === emergency_position;
				const emg_colors = {
					background: getColor(selected, "#B55DAE", base_bg),
					color: getColor(selected, active_color, base_color),
					hover_bg: getColor(selected, base_bg, "#B55DAE"),
					hover_color: getColor(selected, base_color, active_color),
				};

				return <CommandButton
					key={pos_id}
					{...emg_colors}
					onClick={setEmergency}
					data-id={player.id}
					is_draft={is_draft}
					data-position={pos_id}
					is_hidden={this.isEmergencyButtonHidden(pos_id)}
					data-is_utility={false}
				>
					{ positionEmergencyButton(pos_id) }
				</CommandButton>;
			});
		}

		if (is_utility_case) {
			return _.map(positions, position_id => {

				const is_this_is_position =
					parseInt(utility_position, 0) === parseInt(position_id, 0) && is_emergency;

				return (
					<CommandButton
						background={is_this_is_position ? "#B55DAE" : "#CAD2D8"}
						color="#F8F8FA"
						hover_bg="#B55DAE"
						hover_color="#FFFFFF"
						onClick={setEmergency}
						data-id={player.id}
						data-position_id={position_id}
						key={position_id}
						data-is_emergency={is_emergency}
						data-is_utility={true}
					>
						{positionEmergencyButton(position_id)}
					</CommandButton>
				);
			});
		}
		else {
			return <CommandButton
				{...btn_colors.emergency}
				onClick={setEmergency}
				data-id={player.id}
				is_hidden={this.isEmergencyButtonHidden(0)}
				data-is_utility={false}
			>
				<Emergency />
			</CommandButton>;
		}
	}

	get trade_buttons() {
		const { is_trade_view_team } = this.props;

		if (!is_trade_view_team) {
			return (
				<TradeButtonWrapper>{this.trade_button}</TradeButtonWrapper>
			);
		}
		const {
			player,
			position_id,
			is_bench,
			removePlayer,
			// removePlayerOutTrade,
			is_locked,
			position_index,
			in_substitute,
			out_substitute,
			is_substitute,
			substitutePlayer,
			// is_in_swap,
			// trade_id,
			is_empty_cell,
			trade,
			is_draft,
			is_utility
		} = this.props;
		const btn_colors = getCommandButtonColors();
		const isEdit = window.location.pathname.includes('edit-advanced');
		if (is_locked) {
			return (
				<TradeButtonWrapper>
					<CommandButtonTradeTeam {...btn_colors.base} is_draft={is_draft}>
						<TradeDisabled size={1.75} color="#CAD2D8" />
					</CommandButtonTradeTeam>
				</TradeButtonWrapper>
			);
		}

		const is_locked_by_trade_swap = isLockedByTradeSwap(
			in_substitute, is_substitute, out_substitute, is_empty_cell
		);

		if (trade) {
			return this.trade_in_buttons;
		}

		const swapButton = () => {
			if (in_substitute) {
				return (
					<CommandButtonTradeTeam
						data-id={player.id}
						is_draft={is_draft}
						data-position={position_id}
						data-is_bench={is_bench}
						data-position_index={position_index}
						data-is_trade_out={false}
						data-is_utility={is_utility}
						onClick={substitutePlayer}
						{...btn_colors.base}
						trade
					>
						<Swap size={1.75} />
					</CommandButtonTradeTeam>
				);
			}
			return (
				<CommandButtonTradeTeam {...btn_colors.base} is_draft={is_draft}>
					<SwapDisabled size={1.75} color="#CAD2D8" />
				</CommandButtonTradeTeam>
			);
		};
		const tradeButton = () => {
			if ((is_locked_by_trade_swap || !is_empty_cell) && !isEdit) {
				return (
					<CommandButtonTradeTeam {...btn_colors.base} is_draft={is_draft}>
						<TradeDisabled size={1.75} color="#CAD2D8" />
					</CommandButtonTradeTeam>
				);
			}
			return (
				<CommandButtonTradeTeam
					data-id={player.id}
					data-position={position_id}
					data-is_bench={is_bench}
					data-position_index={position_index}
					is_draft={is_draft}
					onClick={removePlayer}
					{...btn_colors.base}
					trade
				>
					<Trade size={1.75} />
				</CommandButtonTradeTeam>
			);
		};

		return (
			<TradeButtonWrapper>
				{swapButton()}
				{tradeButton()}
			</TradeButtonWrapper>
		);
	}


	get trade_in_buttons() {
		const {
			player,
			position_id,
			is_bench,
			removePlayer,
			position_index,
			substitutePlayer,
			trade_id,
			out_substitute,
			is_utility,
			is_draft
		} = this.props;

		const swapButton = () => {
			return (
				<CommandButtonTradeTeam
					onClick={substitutePlayer}
					data-position={position_id}
					is_draft={is_draft}
					data-position_index={position_index}
					data-is_bench={is_bench}
					data-is_trade_out={true}
					data-id={trade_id}
					data-is_utility={is_utility}
				>
					<Swap size={1.75}/>
				</CommandButtonTradeTeam>
			);
		};
		const undoButton = () => {
			if (out_substitute) {
				return (
					<AbsoluteButtonUndo
						onClick={substitutePlayer}
						data-position={position_id}
						data-position_index={position_index}
						data-is_bench={is_bench}
						data-is_trade_out={true}
						is_draft={is_draft}
						data-id={trade_id}
						data-is_utility={is_utility}
					>
						<Close size={2} />
					</AbsoluteButtonUndo>
				);
			}

			return (
				<CommandButtonUndo
					onClick={removePlayer}
					data-id={player.id}
					is_draft={is_draft}
					small_margin
				>
					<Undo size={2}/>
				</CommandButtonUndo>
			);
		};


		return (
			<TradeButtonWrapper>
				{swapButton()}
				{undoButton()}
			</TradeButtonWrapper>
		);
	}

	get trade_button() {
		const {
			player,
			position_id,
			is_bench,
			removePlayer,
			is_on_trade,
			removePlayerInTrade,
			is_locked,
			position_index,
			in_substitute,
			out_substitute,
			is_substitute,
			substitutePlayer,
			is_empty_cell,
			is_utility,
			is_draft,
			active_trade_id
		} = this.props;

		const btn_colors = getCommandButtonColors();
		
		if(isAllTrue([Boolean(is_on_trade), isEditTradePage()])){
			const currentTrade = get(is_on_trade, '[0]');
		
			const isTradeActive = currentTrade.id === Number(active_trade_id);
			if(!isTradeActive){
				return <CommandButtonTrade {...btn_colors.base} is_draft={is_draft}>
					<TradeDisabled size={1.75} color="#CAD2D8" />
				</CommandButtonTrade>;
			}
		}
		if (is_on_trade) {
			return (
				<CommandButtonUndo
					data-id={player.id}
					data-position={position_id}
					onClick={removePlayerInTrade}
					is_draft={is_draft}
					{...btn_colors.base}
				>
					<Undo size={2} />
				</CommandButtonUndo>
			);
		}
		const isEdit = window.location.pathname.includes('edit-advanced');
		const locked_by_swap = isLockedBySwap(in_substitute, is_substitute, out_substitute);
		const is_not_in_swap = isAllTrue([!is_empty_cell, !in_substitute, !isEdit]);
		const isLocked = (is_locked, locked_by_swap, is_not_in_swap) => {
			return is_locked || locked_by_swap || is_not_in_swap;
		};
		if (isLocked(is_locked, locked_by_swap, is_not_in_swap)) {
			return (
				<CommandButtonTrade {...btn_colors.base} is_draft={is_draft}>
					<TradeDisabled size={1.75} color="#CAD2D8" />
				</CommandButtonTrade>
			);
		}

		// const is_locked_by_trade_swap = isLockedByTradeSwap(
		// 	in_substitute, is_substitute, out_substitute, is_empty_cell
		// );
		// if (is_locked) {
		// 	return (
		// 		<CommandButtonTrade {...btn_colors.base}>
		// 			<TradeDisabled size={1.25}/>
		// 		</CommandButtonTrade>
		// 	);
		// }
		//
		// if (is_locked_by_trade_swap) {
		// 	return (
		// 		<CommandButtonTrade {...btn_colors.base}>
		// 			<SwapDisabled size={1.25}/>
		// 		</CommandButtonTrade>
		// 	);
		// }

		if (in_substitute) {
			return (
				<CommandButtonTrade
					data-id={player.id}
					is_draft={is_draft}
					data-position={position_id}
					data-is_bench={is_bench}
					data-position_index={position_index}
					data-is_trade_out={false}
					onClick={substitutePlayer}
					data-is_utility={is_utility}
					{...btn_colors.base}
					trade
				>
					<Swap size={2} />
				</CommandButtonTrade>
			);
		}


		if (isAllTrue([!is_empty_cell, !isEdit])) {
			return (
				<CommandButtonTradeTeam {...btn_colors.base} is_draft={is_draft}>
					<TradeDisabled size={1.75} color="#CAD2D8" />
				</CommandButtonTradeTeam>
			);
		}

		return (
			<CommandButtonTrade
				data-id={player.id}
				data-position={position_id}
				data-is_bench={is_bench}
				data-position_index={position_index}
				onClick={removePlayer}
				is_draft={is_draft}
				{...btn_colors.base}
				trade
			>
				<Trade size={2} />
			</CommandButtonTrade>
		);
	}

	openPopup() {
		const { player, openPlayerPopUp } = this.props;
		if (player && player.id) {
			openPlayerPopUp(player.id);
		}
	}

	renderCosts() {
		const { player } = this.props;
		let round_diff = _.get(player, "stats.cost_round_diff", 0);
		let season_diff = _.get(player, "stats.cost_season_diff", 0);

		const has_one = (round_diff || season_diff)
			&& (round_diff !== "-" || season_diff !== "-");

		return <PlayerDetailsBottom>
			<PlayerCost cost={player.cost}/>
      
			{has_one && <React.Fragment>
				<PlayerCost
					cost={round_diff}
					is_diff
				/>
				<PlayerCost
					cost={season_diff}
					is_diff
				/>
			</React.Fragment>}
		</PlayerDetailsBottom>;
	}

	is_dnd = false;

	swapPlayers() {
		const { player, position_id, is_bench, is_utility } = this.props;

		this.props.pureSubstitutePlayer({
			id: player.id,
			position: position_id,
			is_bench,
			is_utility,
		});
	}

	onDragStart() {
		this.is_dnd = true;
		this.swapPlayers();
	}

	onDrop() {
		if(this.not_allowed_for_swap) {
			return;
		}

		this.swapPlayers();
	}

	onDragEnd() {
		this.is_dnd = false;
		const active = document.activeElement;

		if (active && active.blur) {
			active.blur();
		}

		this.props.cancelSubstitute();
	}

	get not_allowed_for_swap() {
		const {
			in_substitute,
			out_substitute,
			is_substitute,
		} = this.props;

		return !in_substitute && is_substitute && !out_substitute;
	}

	
	render() {
		const {
			player,
			is_captain,
			is_vice,
			double_score,
			is_emergency,
			in_substitute,
			out_substitute,
			is_substitute,
			position_id,
			is_draft,
			is_trade_view,
			is_on_trade,
			is_static,
			is_current,
			no_padding,
			is_locked,
			odd,
			postRemoveFromFavourites,
			postAddToFavourites,
			player_is_favourite,
			has_assistant_coach,
			player_next_match,
			pointsLastRound,
			pointsLabel,
			edit_teams_view,
		} = this.props;
		const { show_fantasy_coach_modal, edit_teams_buttons } = this.state;

		const
			swap_highlighted = isAllTrue([is_substitute, out_substitute]),
			locked_by_swap = this.not_allowed_for_swap,
			player_name = `${player.first_name} ${player.last_name}`;

		const popped = isAnyTrue([
			Boolean(is_on_trade), isAllTrue([Boolean(is_trade_view), in_substitute])
		]);

		const player_avatar = `${process.env.REACT_APP_PLAYERS_PATH + player.id}_450.png`;
		const player_avatar_webp = `${process.env.REACT_APP_PLAYERS_PATH + player.id}_450.webp`;
		const lowercasePointLabel = pointsLabel && pointsLabel.toLowerCase();
		
		if(this.props.edit_teams_view){
			return(
				<EditListViewPlayerWrapper
					onDragStart={this.onDragStart}
					onDragEnd={this.onDragEnd}
					onDrop={this.onDrop}
					onDragOver={onDragOver}
					draggable={!is_locked}
					key={position_id}
					is_captain={is_captain}
					is_vice={is_vice}
					is_substitute={is_substitute}
					in_substitute={in_substitute}
					out_substitute={out_substitute}
					is_emergency={is_emergency}
					swap_highlighted={swap_highlighted}
					locked_by_swap={locked_by_swap}
					is_on_trade={is_on_trade}
					is_trade_view={is_trade_view}
					fade_out={this.shouldFadeOut()}
					no_padding={no_padding}
					odd={odd}
				>
					
					<PlayerInfo
						className="player-info"
						player={player}
						is_compressed={!isMobile()}
						is_classic={false}
						edit_teams_view={this.props.edit_teams_view}
					/>
					<Exist when={isAllTrue([Boolean(edit_teams_view), isMobile()])}>
						<EditTeamsOppositionButtonsWrap active={edit_teams_buttons}>
							<Exist when={isAllTrue([player_next_match, !edit_teams_buttons])}>
								<PlayerFixtureWrapper>	
									<img 
										src={_.get(player_next_match,'opposition_logo')} 
										alt="Opposition Logo"
									/>
								</PlayerFixtureWrapper>
							</Exist>
						
							{ this.action_buttons }
						</EditTeamsOppositionButtonsWrap>
					</Exist>
					<Exist when={!isAllTrue([Boolean(edit_teams_view), isMobile()])}>
						<Exist when={isAllTrue([player_next_match, isMobile()])}>
							<PlayerFixtureWrapper>	
								<img 
									src={_.get(player_next_match,'opposition_logo')} 
									alt="Opposition Logo"/>
							</PlayerFixtureWrapper> 
						</Exist>
						<Exist when={isAllTrue([player_next_match, !isMobile()])}>
							<PlayerFixtureWrapper 
								is_home={_.get(player_next_match,'is_home')}> 
								<p className={_.get(player_next_match,'is_home') 
									? "players-team" : ""}>
									{_.get(player_next_match,'home_team')}</p> 
								<p className="vs">v</p> 
								<p className={_.get(player_next_match,'is_home')
									? "" : "players-team"}>
									{_.get(player_next_match,'away_team')}
								</p> 
							</PlayerFixtureWrapper>
						</Exist>
						
							
						<Exist when={Boolean(is_trade_view)}>
							{this.trade_buttons}
						</Exist>
						<Exist when={!is_trade_view}>
							{ this.action_buttons }
						</Exist>
						
						
					</Exist>
					
				</EditListViewPlayerWrapper>
			);
		}

		const pointDisplay = pointsLastRound;

		//const shouldDouble = is_draft && double_score && Number(pointsLastRound);
		const shouldDouble = isAllTrue([
			Boolean(is_draft),
			Boolean(double_score),
			Boolean(Number(pointsLastRound)),
			isNumber(pointsLastRound)
		])  ;
		return (
			<React.Fragment>
				<Exist when={show_fantasy_coach_modal}>
					<FantasyCoachModal
						closeClick={this.toggleFantasyCoachModal}
					/>
				</Exist>
				<ListViewPlayerWrapper
					onDragStart={this.onDragStart}
					onDragEnd={this.onDragEnd}
					onDrop={this.onDrop}
					onDragOver={onDragOver}
					draggable={!is_locked}
					key={position_id}
					is_captain={is_captain}
					is_vice={is_vice}
					is_substitute={is_substitute}
					in_substitute={in_substitute}
					out_substitute={out_substitute}
					is_emergency={is_emergency}
					swap_highlighted={swap_highlighted}
					locked_by_swap={locked_by_swap}
					is_on_trade={is_on_trade}
					is_trade_view={is_trade_view}
					fade_out={this.shouldFadeOut()}
					no_padding={no_padding}
					odd={odd}
					is_draft={is_draft}
					className={`list-view-player${getClassnamePopped(popped)}`}
				>
					<PlayerInfoDiv
						is_trade_view={is_trade_view}
						is_draft={is_draft}
						className="list-view-player-info"
					>
						<PlayerAvatar onClick={this.openPopup}>
							<picture>
								<source srcSet={player_avatar_webp} type="image/webp" />
								<source srcSet={player_avatar} type="image/png" />
								<img
									src={player_avatar_webp}
									alt={player_name}
								/>
							</picture>
						</PlayerAvatar>
						<PlayerStatus is_draft={is_draft}>
							<Shadow compressed />
							<Exist when={isAnyTrue([!is_static, is_current])}>
								<StatusButtonStyled status={player.status} is_bye={player.is_bye}>
									<PlayerStatusIcon
										status={player.status}
										is_bye={player.is_bye}
									/>
								</StatusButtonStyled>
							</Exist>
							
							<FavButtonStyled
								active={player_is_favourite}
								className="favourite-button"
								onClick={() => {
									player_is_favourite?
										postRemoveFromFavourites(player.id):
										postAddToFavourites(player.id);
								}}>
								<Star />
							</FavButtonStyled>
						</PlayerStatus>
						<PlayerDetails is_draft={is_draft}>
							<PlayerDetailsTop is_draft={is_draft}>
								<PlayerName
									status={player.status}
									onClick={this.openPopup}
									className="player-name"
								>
									{ this.getPlayerNames() }
								</PlayerName>
								<Exist when={Boolean(is_trade_view)}>
									<Positions className='player-positions' trade_view={true}>
										{ this.getShortPositions() }
									</Positions>
								</Exist>
							
							</PlayerDetailsTop>
							<Exist when={!is_draft}>
								{this.renderCosts()}
							</Exist>
							<Exist when={!is_trade_view}>
								<PlayerDetailsBottom>
									<Positions className='player-positions'>
										{ this.getPositions() }
									</Positions>
								</PlayerDetailsBottom>
							</Exist>
							
							
						</PlayerDetails>
						<Exist when={!is_trade_view}>
							<PlayerPointsWrap status={lowercasePointLabel}>
								<Exist when={isAllTrue([pointsLabel === 'projected',
								 !has_assistant_coach])}>
									<FcLink onClick={this.toggleFantasyCoachModal}>
										<Lock size={1.70}/>
									</FcLink>
								</Exist>
								<Exist when={isAnyTrue([pointsLabel !== 'projected',
								 has_assistant_coach])}>
									<PlayerScore points={pointsLastRound}>
										{shouldDouble 
											? 2 * Number(pointDisplay) 
											: pointDisplay}
									</PlayerScore>
								</Exist>
								
								<PlayerLabel>{pointsLabel}</PlayerLabel>
							</PlayerPointsWrap>
						</Exist>				
						
					</PlayerInfoDiv>
					{ this.stats_blocks }
					{ is_trade_view ? this.trade_buttons : this.action_buttons }
				</ListViewPlayerWrapper>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state, props) => ({
	player_is_favourite: state.players.favourites.includes(props.player && props.player.id),
	user: state.user.data,
	actual_round: selectors.rounds.getActualRound(state)
});

const mapDispatchToProps = {
	postRemoveFromFavourites: actions.postRemoveFromFavourites,
	postAddToFavourites: actions.postAddToFavourites,
};

export const ListViewPlayer = connect(mapStateToProps, mapDispatchToProps)(ListViewPlayerComponent);

export default ListViewPlayer;