// @flow

import _ from "lodash";
import { createSelector } from "reselect";
import type { TRootStore, TRound, TStateWithSquads, TSquad } from "../../types";
import { getActualRound } from "../rounds";



export const getByeSquadIdsArray = createSelector(
	getActualRound,
	(round: TRound) => _.get(round, "bye_squads", [])
);

export const getSquadsById = ({ squads }: TRootStore) => squads.by_id;

// @flow

export const getSquads = (state: TStateWithSquads): Array<TSquad> =>
	state.squads.ordered_ids.map(item => state.squads.by_id[item]);


export const hasSquads = (state: TStateWithSquads): boolean => !_.isEmpty(state.squads.ordered_ids);