// @flow
import * as _ from "lodash";

type Props = {
	input?: string | number,
	currency?: string,
	show_sign?: boolean
}


export const currencyFormat = (
	{ input, currency = "$", show_sign = false }: Props
) => {
	if(!_.isNumber(input)){
		return input || "";
	}



	const decimals = (Number(input) > 1000000 || Number(input) < -1000000) ? 3 : 0;

	const val = Intl.NumberFormat("en-AU", {
		notation: "compact",
		maximumFractionDigits: decimals,
		minimumFractionDigits: decimals,
		style: "currency",
		currency: "AUD",
	}).format(Number(input));

	return val;
};

export default currencyFormat;