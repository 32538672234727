// @flow
import * as React from "react";
import styled from "styled-components";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

import {
	Footer,
	PageTitle,
	AdsContainer,
	Ad,
	PageContentWrapper,
	TwoColumnLayout,
	StandardSidebar,
	Exist,
	Preloader
} from "../../../components";
import { LeagueManagerComponent } from "../../../components/utils/LeagueManager/index";
import LeagueCreateForm from "./leaguesCreate/LeaguesCreateForm";
import RegenerateLeagues from "./leaguesCreate/regenerateLeagues";

type Props = {
	handleSubmit: Function,
	rounds_list: Array<Object>,
	league_create: {
		league_id: number,
		is_pending:"idle"| "loading" | "success" | "fail"
	},
	subscriptionPlan: string
}

const PageTitleStyled = styled(PageTitle)`
	font-size: 32px;
	display: flex;
	align-items: flex-end;

	svg{
		margin-right: .2em;
	}
`;

export const DraftLeagueCreateUFCom = ({ league_create, subscriptionPlan }: Props) => (
	<div>
		{
			league_create.league_id && subscriptionPlan === "" ?
				<Redirect to={`/draft/league/${league_create.league_id}/team-name/create`}/> :
				league_create.league_id && subscriptionPlan !== "" ?
					<Redirect to={`/draft/league/${league_create.league_id}/settings`} />
					:null
		}
		<AdsContainer>
			<Ad/>
		</AdsContainer>
		<PageContentWrapper>
			<PageTitleStyled>Create a Draft league</PageTitleStyled>
			<TwoColumnLayout>
				<div>
					<RegenerateLeagues />
					<LeagueManagerComponent is_draft={true} from="create_uf">
						{({ rounds_list, handleSubmit, ...props }: Props) => {
							return (
								<React.Fragment>
									<LeagueCreateForm
										{...props}
										onSubmit={handleSubmit}
										rounds={rounds_list}
									/>
								</React.Fragment>
							);
						}}
					</LeagueManagerComponent>
					<Exist when={league_create.is_pending === "loading"}>
						<Preloader />
					</Exist>
				</div>
				<StandardSidebar />
			</TwoColumnLayout>
		</PageContentWrapper>
		<Footer />
	</div>
);

const mapStateToProps = state => {
	// eslint-disable-next-line max-len
	const subscriptionPlan = state.form.pro_subscription_form && state.form.pro_subscription_form.values
		? state.form.pro_subscription_form.values.plan:"";
	return ({
		league_create: state.leagues.create_uf,
		subscriptionPlan,
		league_regen_by_id: state.leagues.regenerate_show_uf.by_id
	});
};

export const DraftLeagueCreateUF = connect(
	mapStateToProps,
	null,
)(DraftLeagueCreateUFCom);

export default DraftLeagueCreateUF;