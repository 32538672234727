// @flow
import React from "react";
import styled from "styled-components";


import apple_app_store from "../../assets/images/App-Store-Button@2x.png";
import google_play_store from "../../assets/images/Google-Play-Button@2x.png";
import colors from "../../assets/css/colors";
import AppIcon from "../Icons/GoToApp";

import { ButtonPrimary } from "../";

const Wrapper = styled.div`
	width: calc(100% - 20px);
	margin: 10px auto;
	position: relative;
	background-color: ${colors.form.base};
	box-sizing: border-box;
	padding: 20px;
	text-align: center;

	a img {
		width: 130px;
	}

	p {
		font-family: SourceSansPro;
		font-size: 14px;
		line-height: 1.4;
		color: black;
	}

	h4 {
		color: ${colors.primary.primary};
		font-size: 20px;
		line-height: 1.2;
		margin: 15px 0 1em;
	}
`;

const Links = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 24px 0;
`;

const StyledButtonPrimary = styled(ButtonPrimary)`
	width: 200px;
	position: relative;
	margin: 0 auto;
`;

const StyledP = styled.p`
	margin-top: 20px;
`;

type Props = {
	page_name?: string,
	draft_message?: boolean,
	draft_start?: string,
};

const basicMessage = "This page is only available on the AFL Fantasy app, "+
	"you can download it via their respective stores here:";
const draftMessage = "Live drafting on the mobile website will be "+
	"available soon. In the meantime, please download the official " +
	"AFL Fantasy app to take part in your live draft, or visit the"+
	" website from a computer.";
export const GoToApp = ({ page_name = "", draft_message, draft_start }: Props) => (
	<Wrapper>
		<AppIcon size="3" color={colors.primary.primary} />

		<h4>
			{page_name}
		</h4>

		<p>
			{
				draft_message ? draftMessage : basicMessage
			}

			
			
		</p>
		{draft_start &&
			<StyledP>
				Your draft will commence at {draft_start}
			</StyledP>
		}
		<Links>
			<a
				href="https://itunes.apple.com/au/app/afl-fantasy/id796588195?mt=8"
				target="_blank"
				rel="noopener noreferrer"
				title="Apple App Store"
			>
				<img src={apple_app_store} alt="Apple App Store"/>
			</a>
			<a
				href="https://play.google.com/store/apps/details?id=com.fanhub.AFLFantasy&hl=en"
				target="_blank"
				rel="noopener noreferrer"
				title="Google Play Store"
			>
				<img src={google_play_store} alt="Google Play Store"/>
			</a>
		</Links>

		<StyledButtonPrimary
			onClick={() => window.history.back()}
		>
			Go back
		</StyledButtonPrimary>
	</Wrapper>
);

export default GoToApp;