// @flow
import styled from "styled-components";
import { Link } from "react-router-dom";
import colors from "../../assets/css/colors";
import ButtonPrimary from "../ButtonPrimary";

export const CancelButton = styled(ButtonPrimary)``;

CancelButton.displayName = "CancelButton";
CancelButton.defaultProps = {
	...colors.buttons.cancel_button
};

export const CancelButtonLink = styled(ButtonPrimary.withComponent(Link))`
	display: flex;
	align-items: center;
	justify-content: center;
	text-decoration: none;
`;

CancelButtonLink.displayName = `${CancelButtonLink.displayName || "Button"}Link`;

CancelButtonLink.defaultProps = {
	...colors.buttons.cancel_button
};

export default CancelButton;