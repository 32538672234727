// @flow
import * as React from "react";

import { ButtonPrimaryLink } from "../../ButtonPrimary";

import Container from "./Container";
import ButtonsWrapper from "./ButtonsWrapper";

type Props = {
	game_type: 'classic' | 'draft'
}

class NotInAnyLeagues extends React.Component<Props> {
	render() {
		const { game_type } = this.props;

		return (
			<Container
				show_icon
				text={"You are not in any leagues"}
				dashed_border
				renderRightSide={() => (
					<ButtonsWrapper>
						<ButtonPrimaryLink to={`/${game_type}/leagues/join`}>
							Join a league
						</ButtonPrimaryLink>
						<ButtonPrimaryLink to={`/${game_type}/leagues/create`}>
							Create a league
						</ButtonPrimaryLink>
					</ButtonsWrapper>
				)}
			/>
		);
	}
}

export default NotInAnyLeagues;