// @flow
import * as React from "react";
import styled from "styled-components";
import Locked from "../../../../components/Icons/Locked";
import {
	BorderedIcon,
	Label,
	Input,
	FormGroup,
	Tooltip,
	ErrorField
} from "../../../../components";
import colors from "../../../../assets/css/colors";

type Props = {
	label: string,
	name: string,
	input: Object,
	type: string,
	placeholder: string,
	className?: string,
	tooltip?: {
		title: string,
		description: string,
	},
	meta: {
		touched: any,
		error: string,
		warning: string,
	},
	is_disabled: boolean,
};

const FormGroupStyled = styled(FormGroup)`
	margin-bottom: .5em;
`;

const is_error = meta => {
	return !!(meta.touched && meta.error);
};

export const InviteFormField = ({
	label,
	name,
	type,
	placeholder,
	input,
	tooltip,
	is_disabled,
	meta: { touched, error, warning },
	className,
}: Props ) => (
	<div className={className}>
		<Label htmlFor={name} is_error={is_error({ touched, error })}>
			{is_disabled ? (
				<BorderedIcon padding='0' hide_border={true} background={colors.buttons.baseColor}>
					<Locked size="1.8" color='#ffffff' />
				</BorderedIcon>
			) : ""}
			{label}

			{tooltip ? (
				<Tooltip {...tooltip} />
			): null}
		</Label>
		<FormGroupStyled>
			<Input
				input={input}
				name={name}
				type={type}
				placeholder={placeholder}
				is_disabled={is_disabled}
				is_error={is_error({ touched, error })}
			/>
		</FormGroupStyled>
		{ touched && ((
			error && <ErrorField>{error}</ErrorField>) ||
			(warning && <ErrorField>{warning}</ErrorField>
			))
		}
	</div>
);

export default InviteFormField;