// @flow
import { get } from "lodash";
import { createReducer } from "redux-act";
import * as actions from "../../actions/index";
import type { TUserReducer } from "../../types/store/userReducer";

const initial_state: TUserReducer = {
	activate: {
		is_pending: false,
		error: null,
		success: false,
	},
	afl_id:{
		identity_token:"",
		loading_status: "idle",
		user:null,
		afl_account:null,
		is_linked: false,
		afl_account_id: 0,
		leagues_history:null,

	},
	uf_account: {
		is_match: false,
		is_pending: "idle",
		leagues_history: null,
		is_linked: false,
		error: null,
		uf_user:null
	},
	deactivate: {
		is_pending: false,
		error: null,
		success: false,
	},
	login: {
		is_pending: false,
		error: null,
		is_fb_login: false,
	},
	logout: {
		is_pending: false,
		error: null,
	},
	user: {
		is_pending: false,
		error: null,
	},
	forgot_password: {
		is_pending: false,
		error: null,
		success: false
	},
	change_password: {
		is_pending: false,
		error: null,
		success: false
	},
	update: {
		is_pending: false,
		error: null,
		success: false
	},
	data: {},
	subscribe: {
		is_pending: false,
		error: null,
		success: false
	},
	unsubscribe: {
		is_pending: false,
		error: null,
		success: false
	},
	facebookConnect: {
		is_pending: false,
		error: null,
		success: false
	},
	checkEmail: {
		is_pending: false,
		error: null,
		exists: false,
	},
	subscription: {
		is_pending: false,
		error: null,
		plan: "",
		start: "",
		end: "",
		status: ""
	},
	history: {

	},
	preregistration: {
		is_sending: false,
		error: "",
	},
	currentDraftTab:0
};

export const user = createReducer({}, initial_state);

// Login
user
	.on(actions.login, state => ({
		...state,
		login: {
			...state.login,
			is_pending: true,
			error: null,
		}
	}))
	.on(actions.loginToken, state => ({
		...state,
		login: {
			...state.login,
			is_pending: true,
			error: null
		}
	}))
	.on(actions.aflIDCreateUser, (state, payload) => ({
		...state,
		login: {
			...state.user,
			is_pending: true,
		}
	}))
	.on(actions.loginSuccess, (state, payload) => {
		return {
			...state,
			login: {
				...state.user,
				is_pending: false,
				is_fb_login: payload.is_fb_login,
			},
			data: {
				...state.data,
				...payload.user,
			}
		};
	})
	.on(actions.loginFailed, (state, payload) => {
		return {
			...state,
			login: {
				error: payload,
				is_pending: false,
			},
		};
	})
	.on(actions.clearLoginErrors, state => {
		return {
			...state,
			login: {
				...state.login,
				error: null,
			},
			preregistration: {
				...state.preregistration,
				error: "",
			}
		};
	})

	// Logout
	.on(actions.logout, state => ({
		...state,
		logout: {
			...state.logout,
			is_pending: true,
			error: null,
		}
	}))
	.on(actions.logoutSuccess, (state, payload) => {
		return {
			...state,
			logout: {
				...state.logout,
				is_pending: false,
			},
			data: {}
		};
	})
	.on(actions.authCodeSuccess, (state, { identity_token, user }) => {
		return {
			...state,
			data: {
				...state.afl_id.user,
				supported_club: user.supported_club,
				country: user.country,
				firstname: user.firstname,
			},
			afl_id: {
				...state.afl_id,
				identity_token,
			}
		};
	})
	.on(actions.authLinkAccount, (state, payload) => ({
		...state,
		afl_id: {
			...state.afl_id,
			is_linked:false
		}
	}))
	.on(actions.authLinkAccountSuccess, (state, payload) => ({
		...state,
		afl_id: {
			...state.afl_id,
			is_linked:true,
		}
	}))
	.on(actions.authLinkAccountFailed, (state, payload) => {
		const alreadyLinkedText = "AFL Account is already linked";
		const newState = payload === alreadyLinkedText ? {
			...state.afl_id,
			is_linked:true
		}:{
			...state.afl_id,
		};
		return {
			...state,
			afl_id: newState
		};
	})
	.on(actions.authCheckCredentialsFailed, (state, payload) => {
		const isAlreadyLinked = payload === "User is already linked to an AFL Account";

		return {
			...state,
			checkEmail: {
				...state.checkEmail,
				error: isAlreadyLinked ? payload : null,
			}
		};
	})
	.on(actions.loginAfliD, (state, props) => {
		return {
			...state,
			afl_id: {
				...state.afl_id,
				loading_status:"loading",
				user:null
			}
		};
	})
	
	.on(actions.emailCheck, (state, props) => ({
		...state,
		afl_id: {
			...state.afl_id,
			loading_status:"loading",
		}
	}))
	.on(actions.emailCheckFailed, (state, props) => ({
		...state,
		afl_id: {
			...state.afl_id,
			loading_status:"fail",
		}
	}))
	.on(actions.addLocalIdToken,(state,props) => ({
		...state,
		afl_id:{
			...state.afl_id,
			identity_token:props
		}
	}))
	.on(actions.emailCheckSuccess, (state, props) => ({
		...state,
		data: {
			...state.data,
			...props.user,
		},
		afl_id: {
			...state.afl_id,
			loading_status:"success",
			afl_account: {
				...props.afl_account,
				...state.afl_id.afl_account,
			},
			leagues_history: {
				...props.leagues_history,
				...state.afl_id.leagues_history,
			},
			user: {
				...props.user
			}

		}
	}))
	.on(actions.authCheckCredentialsSuccess, (state, props) => ({
		...state,
		afl_id: {
			...state.afl_id,
			leagues_history: {
				...props.leagues_history,
				...state.afl_id.leagues_history,
			},
			user: {
				...props.user
			}
		}
	}))
	.on(actions.aflAccountIdPresent,  (state, props) => ({
		...state,
		afl_id: {
			...state.afl_id,
			afl_account_id: props
		}
	}))
	.on(actions.loginAfliDSuccess, (state, props) => {
		return {
			...state,
			afl_id: {
				...state.afl_id,
				loading_status:"success",
				user:props
			},
			data: {
				...state.data,
				...props
			}
		};
	})
	.on(actions.loginAfliDFailed, (state, props) => {
		return {
			...state,
			afl_id: {
				...state.afl_id,
				loading_status:"fail"
			}
		};
	})	
	.on(actions.linkAccountLoginSuccess, (state, props) => {
		return {
			...state,
			afl_id: {
				...state.afl_id,
				leagues_history: {
					...state.afl_id.leagues_history,
					...props.leagues_history,
				},
				user: {
					...state.afl_id.user,
					...props.user
				}
			}
		};
	})
	.on(actions.linkAccountLoginFailed, (state, props) => {
		return{
			...state,
			user: {
				...state.user,
				error: props.message
			}
		};
	})
	.on(actions.linkNewAccountSuccess, (state, props) => {
		return{
			...state,
			afl_id: {
				...state.afl_id,
				user: {
					...state.afl_id.user,
					isSuccessfullyLinked: true
				}
			}
		};
	})
	.on(actions.wipeFetchedAccountData, state => {
		return {
			...state,
			afl_id: {
				...state.afl_id,
				user: null,
				leagues_history: null
			},
			user: {
				...state.user,
				error: null
			}
		};
	})
	.on(actions.ultimateFootyEmailCheck, state => {
		return {
			...state,
			uf_account: {
				...state.uf_account,
				is_pending: "loading",
				uf_user:null
			}
		};
	})
	.on(actions.ultimateFootyRemoveLoadedLeagues, state => {
		return {
			...state,
			uf_account: {
				...state.uf_account,
				leagues_history: null,
				uf_user:null,
				is_match:false,
				error:null,
			}
		};
	})
	.on(actions.ultimateFootyEmailCheckSuccess, (state, payload) => {
		const ufUser = payload.uf_user;
		const ufUserID = Array.isArray(ufUser) ? 0:get(ufUser,"id",0);
		return {
			...state,
			uf_account: {
				...state.uf_account,
				is_pending: "success",
				is_match: Boolean(ufUserID) ? true:false,
				leagues_history: payload.leagues_history,
				uf_user: payload.uf_user
			}
		};
	})
	.on(actions.ultimateFootyEmailCheckFailed, (state, payload) => {
		// do not set is_match false here.
		// fail occurs if wrong password entered.
		return {
			...state,
			uf_account: {
				...state.uf_account,
				is_pending: "fail",
				error: payload.message
			}
		};
	})
	.on(actions.ultimateFootyToggleIsMatched, (state, payload) => ({
		...state,
		uf_account: {
			...state.uf_account,
			is_match:payload
		}
	}))
	.on(actions.ultimateFootyEmailPasswordCheck, state => {
		return {
			...state,
			uf_account: {
				...state.uf_account,
				is_pending: "loading",
				leagues_history:null,
				uf_user:null
			}
		};
	})
	.on(actions.ultimateFootyEmailPasswordCheckSuccess, (state, payload) => {
		return {
			...state,
			uf_account: {
				...state.uf_account,
				is_pending: "success",
				is_match: true,
				leagues_history: payload.leagues_history,
				error:null,
				uf_user: payload.uf_user
			}
		};
	})
	.on(actions.ultimateFootyEmailPasswordCheckFailed, (state, payload) => {
		// do not set is_match false here.
		// fail occurs if wrong password entered.
		return {
			...state,
			uf_account: {
				...state.uf_account,
				is_pending: "fail",
				error: payload.message
			}
		};
	})
	.on(actions.ultimateFootyLinkAccount, state => {
		return {
			...state,
			uf_account: {
				...state.uf_account,
				is_pending: "loading",
			}
		};
	})
	.on(actions.ultimateFootyLinkAccountSuccess, state => {
		return {
			...state,
			uf_account: {
				...state.uf_account,
				is_linked: true,
				is_pending: "success",
				error:null,
			}
		};
	}) 
	.on(actions.ultimateFootyLinkAccountFailed, (state, payload) => {
		return {
			...state,
			uf_account: {
				...state.uf_account,
				is_pending: "fail",
				is_linked: false,
				error: payload.message
			}
		};
	})

	// Forgot password
	.on(actions.forgotPassword, state => {
		return {
			...state,
			forgot_password: {
				...state.forgot_password,
				is_pending: true,
				error: null,
				success: false,
			}
		};
	})
	.on(actions.forgotPasswordFailed, (state, { message }) => {
		return {
			...state,
			forgot_password: {
				...state.forgot_password,
				is_pending: false,
				error: message,
			}
		};
	})
	.on(actions.forgotPasswordSuccess, state => {
		return {
			...state,
			forgot_password: {
				...state.forgot_password,
				is_pending: false,
				success: true
			},
		};
	})

	// Change password
	.on(actions.changePassword, state => {
		return {
			...state,
			change_password: {
				...state.change_password,
				error: null,
				is_pending: true,
				success: false,
			}
		};
	})
	.on(actions.changePasswordFailed, (state, { message }) => {
		return {
			...state,
			change_password: {
				...state.change_password,
				error: message,
				is_pending: false
			}
		};
	})
	.on(actions.changePasswordSuccess, state => {
		return {
			...state,
			change_password: {
				...state.change_password,
				is_pending: false,
				success: true
			}
		};
	})

	// Fetch user
	.on(actions.fetchUser, (state, payload) => {
		return {
			...state,
			user: {
				...state.user,
				is_pending: true,
				error: null
			},
		};
	})
	.on(actions.fetchUserSuccess, (state, payload) => {
		return {
			...state,
			user: {
				...state.user,
				is_pending: false,
				error: null,
			},
			data: payload,
		};
	})
	.on(actions.fetchUserFailed, (state, payload: { text: string, code: number }) => {
		return {
			...state,
			user: {
				...state.user,
				is_pending: false,
				error: payload,
			},
		};
	})
	.on(actions.clearUserError, state => {
		return {
			...state,
			user: {
				...state.login,
				error: null,
			}
		};
	})

	// Activate user - recover user for game
	.on(actions.activateUser, (state, payload) => {
		return {
			...state,
			activate: {
				...state.activate,
				is_pending: true,
				error: null,
				success: false
			}
		};
	})
	.on(actions.activateUserSuccess, (state, payload) => {
		return {
			...state,
			data: {
				...state.data,
				...payload.user,
			},
			activate: {
				...state.activate,
				is_pending: false,
				success: true
			}
		};
	})
	.on(actions.activateUserFailed, (state, payload) => {
		return {
			...state,
			activate: {
				...state.activate,
				is_pending: false,
				error: payload,
			},
		};
	})
	.on(actions.clearUserActivateError, (state, payload) => {
		return {
			...state,
			activate: {
				...state.activate,
				error: null,
			},
		};
	})
	.on(actions.updateUser, state => {
		return {
			...state,
			update: {
				is_pending: true,
				error: null,
				success: false
			},
		};
	})
	.on(actions.updateUserSuccess, state => {
		return {
			...state,
			update: {
				is_pending: false,
				error: null,
				success: true
			},
		};
	})
	.on(actions.updateUserFailed, (state, payload) => {
		return {
			...state,
			update: {
				is_pending: false,
				error: payload,
				success: true
			},
		};
	})
	.on(actions.clearUpdateUserData, state => {
		return {
			...state,
			update: {
				is_pending: false,
				error: null,
				success: false
			},
		};
	})
	.on(actions.subscribeUser, state => {
		return {
			...state,
			subscribe: {
				is_pending: true,
				error: null,
				success: false
			},
		};
	})
	.on(actions.subscribeUserSuccess, (state, payload) => {
		return {
			...state,
			data: {
				...state.data,
				assistant_coach: 1
			},
			subscribe: {
				...payload,
				is_pending: false,
				error: null,
				success: true,
			},
		};
	})
	.on(actions.subscribeUserFailed, (state, payload) => {
		return {
			...state,
			subscribe: {
				is_pending: false,
				error: payload,
				success: false,
			},
		};
	})
	.on(actions.clearSubscribeUserErrors, state => {
		return {
			...state,
			subscribe: {
				is_pending: false,
				error: null,
				success: false,
			},
		};
	})
	.on(actions.facebookConnect, state => {
		return {
			...state,
			facebookConnect: {
				is_pending: true,
				error: null,
				success: false,
			},
		};
	})
	.on(actions.facebookConnectSuccess, state => {
		return {
			...state,
			facebookConnect: {
				is_pending: false,
				error: null,
				success: true,
			},
		};
	})
	.on(actions.facebookConnectFailed, (state, payload) => {
		return {
			...state,
			facebookConnect: {
				is_pending: false,
				error: payload,
				success: false,
			},
		};
	})
	.on(actions.clearFacebookConnectErrors, state => {
		return {
			...state,
			facebookConnect: {
				is_pending: false,
				error: null,
				success: false,
			},
		};
	})
	.on(actions.fetchUserSubscription, state => ({
		...state,
		subscription: {
			...state.subscription,
			is_pending: true,
			error: null
		}
	}))
	.on(actions.fetchUserSubscriptionSuccess, (state, payload) => ({
		...state,
		subscription: {
			...state.subscription,
			...payload,
			is_pending: false,
			error: null
		}
	}))
	.on(actions.fetchUserSubscriptionFailed, (state, payload) => ({
		...state,
		subscription: {
			...state.subscription,
			is_pending: false,
			error: payload
		}
	}))
	.on(actions.unsubscribeUser, state => ({
		...state,
		unsubscribe: {
			success: false,
			is_pending: true,
			error: null
		}
	}))
	.on(actions.unsubscribeUserSuccess, (state, payload) => ({
		...state,
		unsubscribe: {
			success: true,
			is_pending: false,
			error: null
		}
	}))
	.on(actions.unsubscribeUserFailed, (state, payload) => ({
		...state,
		unsubscribe: {
			success: false,
			is_pending: false,
			error: payload
		}
	}))
	.on(actions.deactivateUser, state => ({
		...state,
		deactivate: {
			...state.deactivate,
			is_pending: true,
			error: null,
			success: false
		}
	}))
	.on(actions.deactivateUserFailed, (state, payload) => ({
		...state,
		deactivate: {
			...state.deactivate,
			is_pending: false,
			error: payload,
			success: false
		}
	}))
	.on(actions.deactivateUserSuccess, state => ({
		...state,
		deactivate: {
			...state.deactivate,
			is_pending: false,
			error: null,
			success: true
		}
	}))
	.on(actions.fetchUserHistorySuccess, (state, payload) => ({
		...state,
		history: payload,
	}))
	.on(actions.preregistrationSuccess, state => ({
		...state,
		preregistration: {
			...state.preregistration,
			is_sending: true,
		}
	}))
	.on(actions.preregistrationFailed, (state, payload) => ({
		...state,
		preregistration: {
			...state.preregistration,
			error: payload,
		}
	}))
	.on(actions.checkEmail, state => ({
		...state,
		checkEmail: {
			...state.checkEmail,
			is_pending: true,
		}
	}))
	.on(actions.checkEmailSuccess, state => ({
		...state,
		checkEmail: {
			error: null,
			is_pending: false,
			exists: true,
		}
	}))
	.on(actions.checkEmailFailed, (state, payload) => ({
		...state,
		checkEmail: {
			exists: false,
			is_pending: false,
			errors: true,
		}
	}))
	.on(actions.clearCheckEmailData, state =>({
		...state,
		checkEmail: {
			is_pending: false,
			error: null,
			exists: false,
		}
	}))
	.on(actions.setDraftTab, (state, payload) => ({
		...state,
		currentDraftTab: payload
	}))
;

export default user;