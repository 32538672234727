
// @flow

import * as React from "react";
import { compose } from "redux";
import styled from "styled-components";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import _ from "lodash";
import numeral from "numeral";
import type { TPlayer, TPLeague, TRootStore, TRound, TSquad, ById } from "../../modules/types";
import * as selectors from "../../modules/selectors";
import * as actions from "../../modules/actions";
import { below } from "../../assets/css/media";
import OneColumnLayout from "../OneColumnLayout";
import JsonFetcher from "../utils/JsonFetcher";
import AdsContainer from "../AdsContainer";
import Ad from "../Ad";
import LeagueGameBar from "../LeagueGameBar";
import { PageTitle } from "../PageTitle";
import SearchInputWrapper from "../SearchInputWrapper";
import SearchField from "../SearchField";
import SearchSubmitButton from "../SearchSubmitButton";
import Search from "../Icons/Search";
import { SearchWrapper, StyledLabel } from "../ClassicFilters";
import { Tooltip, Select } from "../Form";
import Footer from "../Footer";
import colors from "../../assets/css/colors";
import { default_transition } from "../../assets/css/vars";
import * as format from "../../helpers/numeralFormats";
import PlayerCard from "../PlayerProfile/playerCard";
import isMobile from "../../utils/isMobile/index";
import withCoachesBox from "../utils/withCoachesBox";
import withFantasyCoachModal from "../utils/withFantasyCoachModal";
import emptyPlayer from "../../assets/images/EmptyPlayer.png";

import Close from "../Icons/Close";
import More from "../Icons/More";
import { Exist } from "../Exist";
import { ROUNDS_IN_AFL_SEASON } from "../../helpers";
import { PlayersWrapper } from "./PlayersWrapper";
import CompareAddPlayer from "./CompareAddPlayer";
import EmptyComparePlayer from "./EmptyComparePlayer";
import CompareDiagram from "./CompareDiagram";
import StatsWrapper from "./StatsWrapper";
import StatsBlock from "./StatsBlock";
import type { TBarStat } from "./playerStats";
import { compareStats } from "./playerStats";




const DEFAULT_VALUE = "- -";
numeral.nullFormat(DEFAULT_VALUE);

const SEASON_YEAR: string = process.env.REACT_APP_SEASON_YEAR || "";

const StyledSearchWrapper = styled(SearchWrapper)`
	margin-top: 0;
	${below.phone`
		width: 95%;
		justify-content: space-around;
	`}
`;

const MoreButtonWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	svg{
		height: 20px;
		width: 20px;
	}
`;

// const PlayerFilterWrapper = styled.div`
// 	display: flex;
// 	flex-direction: row;
// 	align-items: center;
// 	${below.phone`
// 		flex-direction: column;
// 	`}
// `;

// const StatRowWrapper = styled.div`
//   width: 100%:
//   display: flex;
//   flex-direction: row;
// `;

const SelectWrapper = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: baseline;

	> div {
		min-width: 140px;
		margin-left: 10px;

		:first-child {
			margin-left: 0;
		}
	}
	${below.phone`
		> div {
			min-width: 100px;
			margin-left: 0;
			width: 95%;
			height: 80px;
		}
		flex-direction: column;
		align-items: center;
		width: 95%;
	`}
`;

const ProfileForm = styled.div`
	${SelectWrapper} {
		> div:last-of-type {
			margin: 0 0 0 auto;
		}
	}
	${below.phone`
		display: flex;
		flex-direction: column;
		align-items: center;
		${SelectWrapper} {
			> div:last-of-type {
				margin: 0;
			}
		}
	`}
`;

const StyledSearchInputWrapper = styled(SearchInputWrapper)`
	${below.phone`
		max-width: 300px;
	`}
`;

const SearchResultsWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: left;
	flex-wrap: wrap;
	margin-top: 20px;
	${below.phone`
		margin-left: 20px;
	`}
`;
const PlayerResult = styled.div`
	background-color: ${colors.secondary.lightGrey};
	color: ${colors.buttons.loadMoreText};
	padding: 10px;
	padding-top: 12px;
	margin-right: 5px;
	margin-bottom: 5px;
	font-size: 12px;
	border-radius: 2px;
	cursor: pointer;

	${default_transition};

	:hover {
		background-color: ${colors.secondary.paleGrey};
	}
`;

const PlayerImgTileRow = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	margin: 10px 0;
`;

const PlayerImgTile = styled.div`
	height: 90px;
	width: 25%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
	background: ${({ is_filled }) => is_filled ? "#CAD2D8" : "#F8F8FA"};
	${({ isSelected }) => isSelected && `
		background-color: #CAD2D8;
	`}
	img{
		height: 75px;
		width: auto;
	}
`;

const SearchResult = props => {
	const { player, addPlayer } = props;
	if (!player) {
		return null;
	}
	return (<PlayerResult onClick={() => addPlayer(player.id)}>
		{player.first_name} {player.last_name}
	</PlayerResult>);
};

const searchByName = (({ first_name, last_name }, search) => (
	`${first_name} ${last_name}`.toLowerCase().includes(search.toLowerCase())
));

type Props = {
	players: ById<TPlayer>,
	league: TPLeague,
	actual_round: TRound,
	is_classic: boolean,
	match: {
		params: {
			league_id: string | number,
		}
	},
	removeFromFavourites: Function,
	addToFavourites: Function,
	squads: Array<TSquad>,
	fetchPastYearPlayers: typeof actions.fetchPastYearPlayers,
	past_years: Array<Object>,
	fetchTeams: typeof actions.leagueDraft.showTeamsDraftLeague,
	fetchWaiverList: typeof actions.leagueDraft.fetchWaiverList,
	fetchWaiverFree: typeof actions.leagueDraft.fetchWaiverFree,
	fetchAllCustomStats: typeof actions.fetchAllCustomStats,
	fetchAllCoachCustomStats: typeof actions.fetchAllCoachCustomStats,
	has_assistant_coach: boolean,
	initial_player: number,
	is_data_loaded: boolean,
	rounds: TRound[],
	toggleFantasyCoachModal: Function,
};

type State = {
	selected_squad: number,
	selected_player: string,
	redirect?: string,
	by_year: string,
	player_search: string,
	search_results: string[],
	compare_players: number[],
	show_cards: number,
	displayed_selected_player: string,
	select_options_open: boolean,
};

class ComparePlayersComponent extends React.Component<Props, State> {
	static defaultProps = {
		is_classic: false
	};

	constructor(props: Props) {
		super(props);

		_.bindAll(this, [
			"handlePlayerChange",
			"handleTeamChange",
			"handleChangeYear",
			"closePlayer",
			"closeMobPlayer",
			"addPlayer",
			"resize",
			"handleDisplayedSelectedPlayer",
			"handleSelectOptions"
		]);
	}

	state = {
		selected_squad: -1,
		selected_player: "",
		by_year: SEASON_YEAR,
		player_search: "",
		search_results: [],
		compare_players: [],
		redirect: undefined,
		show_cards: 4,
		displayed_selected_player: "",
		select_options_open: false,
	};

	componentDidMount() {
		const {
			fetchTeams,
			fetchWaiverList,
			fetchWaiverFree,
			is_classic,
			is_data_loaded,
			fetchAllCustomStats,
			fetchAllCoachCustomStats,
			league
		} = this.props;
		const { league_id } = this.props.match.params;

		if (!is_classic) {
			fetchTeams({
				league_id: league_id,
				details: 1
			});

			fetchWaiverList({
				league_id
			});
			fetchWaiverFree({
				league_id
			});
			if(league && Boolean(league.custom_scoring_enabled)){
				fetchAllCustomStats(league_id);
				fetchAllCoachCustomStats(league_id);
			}
		}

		window.addEventListener("resize", this.resize);
		this.resize();
		this.resetComparePlayers();

		if (is_data_loaded) {
			this.manageSelectForm();
		}
	}

	shouldComponentUpdate(props) {
		return props.is_data_loaded;
	}

	componentDidUpdate(old_props: Props, old_state: State) {
		const { 
			initial_player, 
			is_data_loaded, 
			fetchAllCustomStats, 
			fetchAllCoachCustomStats
		} = this.props;
		const old_player = old_props.initial_player;

		const { compare_players } = this.state;
		const { compare_players: old_players } = old_state;

		if (old_player !== initial_player) {
			this.resetSelectedPlayer();
			this.resetSelectedSquad();
			this.resetComparePlayers();
		}

		if (is_data_loaded && !old_props.is_data_loaded) {
			this.manageSelectForm();
		}

		if (_.isEqual(compare_players, old_players)) {

		}
		if(old_props.league !== this.props.league 
			&& Boolean(this.props.league.custom_scoring_enabled)
		){
			const league_id = this.props.league.id;
			fetchAllCustomStats(league_id);
			fetchAllCoachCustomStats(league_id);
		}
	}

	resize() {
		const { compare_players } = this.state;
		let new_players = [...compare_players];

		if (typeof window.matchMedia !== "undefined") {
			// const not_full_size = window.matchMedia(
			// 	`(max-width: ${breakpoints.three.max})`
			// ).matches;
			// const show_two = window.matchMedia(
			// 	`(max-width: ${breakpoints.two.max})`
			// ).matches;

			let show_cards = 4;
			// if (not_full_size) {
			// 	show_cards = show_two ? 2 : 3;
			// }

			if (_.size(compare_players) > show_cards) {
				new_players = _.take(compare_players, show_cards);
			}

			this.setState({
				show_cards,
				compare_players: new_players
			});
		}
	}

	handleSelectOptions() {
		const { select_options_open } = this.state;

		this.setState({
			select_options_open: !select_options_open
		});
	}

	resetComparePlayers() {
		const { initial_player } = this.props;
		this.setState({
			compare_players: initial_player ? [ initial_player ] : []
		});
	}

	clearSearch() {
		this.setState({
			player_search: "",
			search_results: []
		});
	}

	resetSelectedPlayer() {
		const { initial_player, players } = this.props;
		const player = _.get(players, initial_player);
		if (initial_player && player) {
			const new_selected = player.id;
			this.setState({
				selected_player: new_selected
			});
		}
	}

	resetSelectedSquad() {
		const { initial_player, squads, players } = this.props;
		const player = _.get(players, initial_player);
		if (initial_player && player) {
			this.setState({
				selected_squad: player.squad_id
			});
		}
		else {
			this.setState({
				selected_squad: _.get(_.first(squads), "id")
			});
		}
	}

	manageSelectForm() {
		const { selected_squad, selected_player } = this.state;

		if (selected_squad === -1) {
			this.resetSelectedSquad();
		}

		if (selected_player === "") {
			this.resetSelectedPlayer();
		}
	}

	get squad_players() {
		const { players } = this.props;
		const { selected_squad } = this.state;

		if (selected_squad) {
			return _.filter(players, { squad_id: selected_squad });
		}

		return [];
	}

	handleTeamChange(e) {
		this.setState({
			selected_squad: parseInt(e.target.value, 10)
		});
	}

	handlePlayerChange(e) {
		const new_player_id_string = e.target.value;
		if (!new_player_id_string) {
			// They've selected the 'Select a Player' option
			return;
		}
		const player_id = parseInt(new_player_id_string, 10);

		this.addPlayer(player_id);
		this.setState({
			selected_player: new_player_id_string,
		});
	}

	handlePlayerSearchChange(e) {
		const new_search = e.currentTarget.value;
		const { players } = this.props;
		let search_results = [];

		if (new_search.length >= 3) {
			search_results = _(players)
				.filter(p => searchByName(p, new_search))
				.value();
		}

		this.setState({
			player_search: new_search,
			search_results
		});
	}

	handleChangeYear(e) {
		const { past_years, fetchPastYearPlayers } = this.props;

		const by_year = e.currentTarget.value;

		const alreadyFetched = past_years.hasOwnProperty(by_year);

		if(by_year !== `${SEASON_YEAR}` && !alreadyFetched) {
			fetchPastYearPlayers(by_year);
		}

		this.setState({
			by_year
		});
	}

	handleDisplayedSelectedPlayer(player_id: string) {
		this.setState({
			displayed_selected_player: player_id
		});
	}

	updateSearch() {

	}

	closePlayer(player_id: number) {
		const { compare_players } = this.state;

		if (_.includes(compare_players, player_id)) {
			this.setState({
				compare_players: _.without(compare_players, player_id)
			});
		}
	}

	closeMobPlayer(player_id: number) {
		const { compare_players } = this.state;
		if (_.includes(compare_players, player_id)) {
			const new_compare_players = _.without(compare_players, player_id);
			const new_display_player = new_compare_players[0];
			this.setState({
				compare_players: new_compare_players,
				displayed_selected_player: new_display_player
			});
		}
	}

	addPlayer(player_id: number) {
		const { compare_players, show_cards } = this.state;
		const { has_assistant_coach } = this.props;

		const current_players = _.size(compare_players);

		// Can compare maximum of 4 players, or 2 without fantasy coach
		if (current_players >= show_cards || (!has_assistant_coach && current_players >= 2)) {
			return;
		}

		if (!_.includes(compare_players, player_id)) {
			this.setState({
				compare_players: [...compare_players, player_id],
				displayed_selected_player: `${player_id}`,
			});
		}
	}

	renderProfileForm() {
		const { squads, players } = this.props;
		const { squad_players } = this;
		const {
			selected_squad,
			selected_player,
			player_search,
			compare_players,
			by_year,
			select_options_open
		} = this.state;
		const { league_id } = this.props.match.params;

		let search_results = [];
		if (player_search.length >= 3) {
			search_results = _(players)
				.filter(p => searchByName(p, player_search))
				.filter(p => !_.includes(compare_players, _.get(p, "id")))
				.value();
		}

		return (
			<ProfileForm>
				
				<StyledSearchWrapper>
					<StyledSearchInputWrapper>
						<SearchField
							type="text"
							name="search"
							component="input"
							placeholder="Search for a player"
							value={player_search}
							onChange={e => this.handlePlayerSearchChange(e)}
						/>
						<SearchSubmitButton
							type="button"
							onClick={() => this.clearSearch()}
						>
							{player_search
								? <Close />
								: <Search />
							}
						</SearchSubmitButton>
						
					</StyledSearchInputWrapper>
					<Exist when={isMobile()}>
						<MoreButtonWrapper onClick={this.handleSelectOptions}>
							{!select_options_open ? <More /> : <Close />}		
						</MoreButtonWrapper> 
					</Exist>
				</StyledSearchWrapper>
				
				{(select_options_open || !isMobile()) && 
					<SelectWrapper>
						<div>
							<StyledLabel>
							Club
								<Tooltip
									title="Club"
									description="Find a player from a specific AFL Club"
								/>
							</StyledLabel>
							<Select
								name="by_squad" is_disabled={false}
								value={selected_squad || ""}
								onChange={e => this.handleTeamChange(e)}
							>
								{squads.map(({ id, full_name }) => (
									<option key={id} value={id}>
										{full_name}
									</option>
								))}
							</Select>
						</div>
						<div>
							<StyledLabel>
							Player
								<Tooltip
									title="Player"
									description="Find a specific player from the selected AFL Club"
								/>
							</StyledLabel>
							<Select
								name="by_player" is_disabled={false} 
								value={selected_player}
								onChange={e => this.handlePlayerChange(e)}
							>
								<option value="">Select a Player</option>
								{
									_(squad_players)
										.orderBy(p => p.last_name)
										.map(({ id, first_name, last_name }) => (
											<option key={id} value={id}>
												{first_name} {last_name}
											</option>
										))
										.value()
								}
							</Select>
						</div>
						<div>
							<StyledLabel>
							Year
								<Tooltip
									show_on_left={true}
									title="Year"
									description="View stats from a specific year"
								/>
							</StyledLabel>
							<Select
								name="by_year"
								is_disabled={false}
								value={by_year}
								onChange={e => this.handleChangeYear(e)}
							>
								{_.range(SEASON_YEAR, 2014, -1).map(year => (
									<option key={year} value={year}>
										{year}
									</option>
								))}
							</Select>
						</div>
					</SelectWrapper>
					

				}
				<SearchResultsWrapper>
					{
						_.map(search_results, player => (
							<SearchResult
								key={player.id}
								player={player}
								league_id={league_id}
								addPlayer={this.addPlayer}
							/>
						))
					}
				</SearchResultsWrapper>
				
			</ProfileForm>
		);
	}

	getDisplayPlayers() {
		const { compare_players } = this.state;
		const { has_assistant_coach } = this.props;
		const max_num = has_assistant_coach ? 4 : 2;
		const num_empty_players = Math.max(max_num - _.size(compare_players), 0);
		const empty_players = num_empty_players > 0 ? _.fill(new Array(num_empty_players), 0) : [];
		return _.concat(compare_players, empty_players);
	}

	renderPlayers() {
		const {
			has_assistant_coach,
			players,
			league,
			actual_round,
			is_classic,
			toggleFantasyCoachModal,
		} = this.props;
		const { by_year, displayed_selected_player } = this.state;
		const display_players = this.getDisplayPlayers();
		if(isMobile()){
			return(
				<React.Fragment>
					<PlayerImgTileRow>
						{_.map(display_players, (player_id: number, i: number) => {
							const avatar_src = 
							player_id === 0 ? emptyPlayer : 
								`${process.env.REACT_APP_PLAYERS_PATH + player_id}.webp`;
						
							return(
								<PlayerImgTile 
									isSelected={displayed_selected_player === `${player_id}`}
									onClick={_.partial(this.handleDisplayedSelectedPlayer, 
										`${player_id}`)}>
									<img src={avatar_src} alt="Player Avatar" />
								</PlayerImgTile>
							);
						})}
						{!has_assistant_coach && 
							<CompareAddPlayer
								show={display_players.length < 4}
								has_coach={has_assistant_coach}
								toggleFantasyCoachModal={toggleFantasyCoachModal}
							/>
						}
					</PlayerImgTileRow>
					
				</React.Fragment>
			);
		}
		return <PlayersWrapper>
			{
				_.map(display_players, (player_id: number, i: number) => {
					const player = _.get(players, player_id);

					if (_.isEmpty(player)) {
						return <EmptyComparePlayer key={i} 
							has_coach={has_assistant_coach}
							toggleFantasyCoachModal={toggleFantasyCoachModal}
							count={i + 1}
						/>;
					}
					return <PlayerCard
						key={i}
						player={player}
						league={league}
						actual_round={actual_round}
						is_classic={is_classic}
						by_year={by_year}
						compressed
						show_buttons
						onClose={this.closePlayer}
						showing_players={display_players.length}
						has_coach={has_assistant_coach}
						isCompare={true}
					/>;
				})
			}
			{!has_assistant_coach && 
				<CompareAddPlayer
					show={display_players.length < 4}
					has_coach={has_assistant_coach}
					toggleFantasyCoachModal={toggleFantasyCoachModal}
				/>
			} 
			
		</PlayersWrapper>;
	}

	adjustProp(prop: string) {
		const { by_year } = this.state;

		if (by_year !== SEASON_YEAR) {
			return `past_years.${by_year}.${prop}`;
		}
		return prop;
	}

	getStatValue(player_id: number, stat: TBarStat) {
		const { players } = this.props;
		const player = players[player_id];

		const prop = this.adjustProp(stat.id);

		let raw_value = _.get(player, prop, DEFAULT_VALUE);

		if (stat.format === format.PERCENTAGE) {
			raw_value = raw_value / 100;
		}

		const bye_stats = [
			"stats.proj_score",
			"stats.proj_price_change_next_round",
			"stats.break_even",
		];

		if (stat.id === "stats.proj_price_change_next_round"
			|| stat.id === "stats.cost_divided_by_points"
		) {
			raw_value = Math.round(raw_value / 1000) * 1000;
		}

		if (player.competition.is_bye && _.includes(bye_stats, stat.id)) {
			return {
				raw_value: "bye",
				display_value: "Bye"
			};
		}

		const display_value = numeral(raw_value).format(stat.format || format.DEFAULT);

		return {
			raw_value,
			display_value
		};
	}

	renderPlayerStats() {
		const {
			actual_round,
			is_classic,
			has_assistant_coach,
			toggleFantasyCoachModal,
			league,
		} = this.props;
		const { displayed_selected_player } = this.state;
		// const { by_year } = this.state;
		const display_players = this.getDisplayPlayers();
		const key = display_players.indexOf(Number(displayed_selected_player));
		const statsBasis = !_.isEmpty(league) && league.custom_scoring_enabled 
			? "custom_stats" : "stats";

		/*
		 * Filter out stats that aren't meant for draft,
		 * if it's the draft version of page
		 */
		const stats = _.filter(
			compareStats(actual_round, statsBasis, !is_classic),
			stat => !stat.not_draft || is_classic
		);
		// const stats_with_leader = stats.map(stat => {
		// 	let statLeader = 0;
		// 	display_players.map((playerID, index) => {
		// 		const currentLeadingValue = Number(this.getStatValue(statLeader, stat).raw_value);
		// 		const playerStatValue = Number(this.getStatValue(playerID, stat).raw_value);
		// 		if(playerStatValue > currentLeadingValue){
		// 			statLeader = playerID;
		// 		}
		// 	});
		// 	stat.leader = statLeader;
		// 	return stat;
		// });

    

		return <StatsWrapper>
			{_.map(stats, stat => {
				const mobPlayerIndexToShow = 
					display_players.indexOf(Number(displayed_selected_player));
		
				const values = _.map(
					_.filter(display_players, p => p !== 0),
					player => {
						return this.getStatValue(player, stat);
					});
				return <StatsBlock
					values={values}
					stat={stat}
					columns={4}
					key={`${stat.id}-${displayed_selected_player}-${key}`}
					has_coach={has_assistant_coach}
					toggleFantasyCoachModal={toggleFantasyCoachModal}
					mobIndexToShow={mobPlayerIndexToShow}
				/>;
			})}
			{/* {
        
				display_players.map((playerID, index) => {
					const player = players[playerID];
					const is_first = index === 0;
					const statsColumnProps = {
						stats: stats_with_leader,
						has_coach: has_assistant_coach,
						toggleFantasyCoachModal,
						is_first,
						player,
						by_year
					};
          

				})
			} */}
		</StatsWrapper>;
	}

	renderDiagram() {
		const { compare_players, by_year } = this.state;
		const { players, rounds, actual_round, past_years } = this.props;
		const statsBasis = _.get(this.props.league, "custom_scoring_enabled", 0) 
			? "custom_stats" : "stats";
		const playersToUse = 
			by_year === SEASON_YEAR ? players : _.get(past_years, `${by_year}`, players);

		const oldRound = {
			id: ROUNDS_IN_AFL_SEASON,
			status: "complete",
			is_bye: 0,
			is_final: 0,
		};
		const roundToUse = by_year === SEASON_YEAR ? actual_round : oldRound;
		return <CompareDiagram
			players={compare_players}
			players_by_id={playersToUse}
			rounds={rounds}
			statsBasis={statsBasis}
			actual_round={roundToUse}
		/>;
	}

	render(){
		const {  displayed_selected_player, redirect, by_year } = this.state;
		const {  actual_round, league, is_classic, has_assistant_coach, players } = this.props;
		const playerToShow = _.get(players, displayed_selected_player);

		if (redirect) {
			return <Redirect to={redirect} />;
		}

		return (
			<React.Fragment>
				<JsonFetcher fetch={["rounds", "players", "venues", "squads", "coach_players"]} />
				<LeagueGameBar />
				<AdsContainer>
					<Ad />
				</AdsContainer>
				<OneColumnLayout>
					<PageTitle>
						Compare Players
					</PageTitle>
					<div>
						{this.renderProfileForm()}
						{this.renderPlayers()}
						{isMobile() &&
						 <PlayerCard
						 	player={playerToShow}
						 	league={league}
						 	actual_round={actual_round}
						 	is_classic={is_classic}
						 	by_year={by_year}
						 	show_buttons
						 	onClose={this.closeMobPlayer}
						 	showing_players={1}
						 	has_coach={has_assistant_coach}
						 	isCompare={true}
						 />
						}
						{this.renderPlayerStats()}
						{this.renderDiagram()}
					</div>
				</OneColumnLayout>
				<Footer show_key />
			</React.Fragment>
		);
	}
}

const getInitialPlayer = (props: Props, players: ById<TPlayer>) => {
	const player_id_string = _.get(props, "match.params.player_id");
	if (player_id_string) {
		try {
			return parseInt(player_id_string, 10);
		}
		catch (e) {
			/* Couldn't parse the id -> just ignore - treat as if no player given */
		}
	}

	return 0;
};

const mapStateToProps = (state: TRootStore, props: Props) => {
	const { is_classic } = props;

	if(!is_classic && !_.get(props, "match.params.league_id")) {
		return {};
	}

	const players = is_classic
		? selectors.getExtendedPlayersWithPastYearsClassic(state, props)
		: selectors.getExtendedPlayersWithPastYearsDraft(state, props);

	const initial_player = getInitialPlayer(props, players);

	const league = is_classic ? null : selectors.leagueDraft.getLeague(state, props);
	const actual_round = selectors.rounds.getActualRound(state);
	const squads = selectors.squads.getSquads(state);
	const past_years = state.players.past_years;
	const has_assistant_coach = Boolean(selectors.getUser(state).assistant_coach);
	const rounds = selectors.rounds.getRounds(state);

	const data_to_load = [
		!_.isEmpty(players),
		!_.isEmpty(actual_round),
		is_classic || !_.isEmpty(league),
		!_.isEmpty(squads),
		!_.isEmpty(state.players.by_id),
		!has_assistant_coach || !_.isEmpty(state.players.coach_by_id),
	];

	return {
		league,
		actual_round,
		squads,
		rounds,
		players,
		past_years,
		has_assistant_coach,
		initial_player,
		is_data_loaded: _.every(data_to_load)
	};
};

const mapDispatchToProps = {
	addToFavourites: actions.postAddToFavourites,
	removeFromFavourites: actions.postRemoveFromFavourites,
	fetchPastYearPlayers: actions.fetchPastYearPlayers,
	fetchTeams: actions.leagueDraft.showDraftOrder,
	fetchWaiverList: actions.leagueDraft.fetchWaiverList,
	fetchWaiverFree: actions.leagueDraft.fetchWaiverFree,
	fetchAllCustomStats: actions.fetchAllCustomStats,
	fetchAllCoachCustomStats: actions.fetchAllCoachCustomStats,
};

export const ComparePlayers = compose(
	withRouter,
	withCoachesBox,
	withFantasyCoachModal,
	connect(
		mapStateToProps,
		mapDispatchToProps,
	)
)(ComparePlayersComponent);

export default ComparePlayers;