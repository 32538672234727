import React from "react";
import styled from "styled-components";
import { ModalContainer, ButtonPrimary, ModalBody } from "../../components";
import { below } from "../../assets/css/media";

const StyledModal = styled(ModalContainer)`
	${below.desktop`
		margin: auto auto;
	`};

	${ModalBody} {
		padding: 20px 40px 50px 40px;
		text-align: center;
		> div {
			font-family: SourceSansPro;
			font-size: 14px;
			line-height: 1.4;
		}

		${below.desktop`
			height: auto;
		`};

		button {
			margin-top: 30px;
			text-align: center;
			min-width: 200px;
		}
	}
`;

const CenterButton = styled.div`
	display: flex;
	justify-content: center;
`;

const success_message = ("Congratulations on upgrading to Fantasy Coach! "
	+ "The website & apps will now feature additional stats while your "
	+ "subscription is active.");

const SuccessModal = props => {
	return (
		<StyledModal
			onClick={props.closeModal}
			header_text={props.header_text ? props.header_text : "Subscription Confirmed"}
		>
			<ModalBody>{props.body_text ? props.body_text : success_message}</ModalBody>
			<CenterButton>
				<ButtonPrimary onClick={props.closeModal}>Continue</ButtonPrimary>
			</CenterButton>
		</StyledModal>);
};

export default SuccessModal;