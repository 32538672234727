// @flow
import { createReducer } from "redux-act";
import * as actions from "../../actions";

const initialState = {
	player_id: null
};

export const playerModal = createReducer({}, initialState);

playerModal.on(actions.showModalForPlayerId, (state, player_id) => ({ ...state, player_id }));
playerModal.on(actions.hideModalForPlayer, state => ({ ...state, player_id: null }));

export default playerModal;