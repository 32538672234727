// @flow
import * as React from "react";
// import * as _ from 'lodash';
import { Exist, ThStat, ThToolTip } from "../components";
import ArrowSolid from "../components/Icons/ArrowSolid";

export type TCellInfo = {
	table: {
		sort_key: string,
		order_by_desc: boolean
	},
	cell: {
		prop: string,
		sort_key: string,
		name: string,
		short_name: string,
		rel: string,
		sortable: boolean,
		onClick: Function,
		is_fc: boolean,
		tooltip?: any
	}
}

/**
 * Checks whether a table header cell sort direction arrow should be shown
 *
 * @param {boolean} is_active Whether the column is the active sort position
 * @param {boolean} same_direction Whether the active sort direction matches the arrow direction
 */
export const shouldShowArrow = (is_active: boolean, same_direction: boolean) => {
	return is_active && same_direction ? "show" : "";
};

export const isActiveSortColumn = (cell_info: TCellInfo) => {
	return cell_info.table.sort_key === cell_info.cell.sort_key;
};

const getHeaderClassName = (cell_info: TCellInfo, className: string | Function): string => {
	const is_active = isActiveSortColumn(cell_info);
	const base = `stat${
		is_active ? " active" : "" 
	}${
		cell_info.cell.sortable ? " sortable" : ""
	} th-stat-cell`;

	if (typeof className === "function") {
		return `${base} ${className(cell_info)}`;
	}

	return `${base}${className && " "}${className}`;
};

/**
 * Renders a header cell for a table
 *
 * @param {TCellInfo} cell_info An object containing information about the cell and table
 * @param {React Component} ThComponent The component to render the header cell as
 * @param {string |  Function} className A string class name or a function to use to generate
 *			a string to be added to the class names given to the rendered component
 */
export const renderTableHeaderCell = (
	cell_info: any,
	ThComponent: Function = ThStat,
	className: string | Function = "",
	passed_props: Object = {}
)  => {
	const { cell, table } =  cell_info;
	const is_active = isActiveSortColumn(cell_info);
	const top_class = `top ${shouldShowArrow(is_active, !table.order_by_desc)}`;
	const down_class = `down ${shouldShowArrow(is_active, table.order_by_desc)}`;

	return <ThComponent
		className={getHeaderClassName(cell_info, className)}
		onClick={() => cell.onClick(cell.sort_key)}
		key={`${cell.prop}-key-${cell.rel}`}
		rel={cell.rel}
		is_fc={cell.is_fc}
		width={cell.width ? cell.width : "auto"}
		player_info={cell.prop === "player-info"}
		dnd={cell.prop === "dnd"}
		draft_order={cell.prop === "draft_order"}
		{...passed_props}
	>	
		<Exist when={cell.sortable}>
			<div className="highlighter" />
		</Exist>
	
		<span className="column-name">
			<span>{cell.name}</span>
			<span>{cell.short_name}</span>
		</span>
		{ cell.sortable && <React.Fragment>
			<ArrowSolid direction="up" className={top_class} />
			<ArrowSolid direction="down" className={down_class} />
		</React.Fragment> }
		{cell.tooltip && <ThToolTip text={cell.tooltip} />}
	</ThComponent>;
};