// @flow
import React from "react";
import styled from "styled-components";

import colors from "../../assets/css/colors";

/** Use this transition if customising what element hover triggers the tooltip */
export const TH_TOOLTIP_HOVER_TRANSITION = (
	"transition: opacity 0.2s linear 0.4s, "
	+ "transform 0.2s linear 0.4s, "
	+ "padding 0s linear 0.4s, "
	+ "font-size 0s linear 0.4s;"
);
const Wrapper = styled.div`
	text-align: center;
	border-radius: 5px;
	color: ${colors.title};
	box-shadow: 0 0 20px 5px rgba(0,0,0,0.15);
	background: #fff;
	box-sizing: border-box;
	font-family: SourceSansPro;

	z-index: 5;
	position: absolute;
	top: calc(100% + 6px);
	left: 50%;
	min-width: 140px;
	text-transform: none;
	
	opacity: 0;
	transform: translate3d(-50%, 10px, 0);
	padding: 0;
	font-size: 0;

	transition: opacity 0.2s linear 0.2s,
		transform 0.2s linear 0.2s,
		padding 0s linear 0.4s,
		font-size 0s linear 0.4s;

	/* Tooltip becomes visible upon hoving over its direct parent */
	*:hover > & {
		opacity: 1;
		transform: translate3d(-50%, 0, 0);
		padding: 1.2em;
		font-size: 12px;

		${TH_TOOLTIP_HOVER_TRANSITION}
	}

	&:after {
		content: '';
		display: block;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0px 5px 8px 5px;
		border-color: #ffffff transparent transparent transparent;
		position: absolute;
		bottom: 100%;
		left: 50%;
		transform: translateX(-50%);
	}
`;

type Props = {
	text: string
}

export const ThToolTip = ({ text }: Props) => (
	<Wrapper className='tooltip-wrapper'>
		{text}
	</Wrapper>
);

export default ThToolTip;