// @flow
import React from "react";
import styled from "styled-components";
import * as _ from "lodash";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import MATCH_STATS from "../../utils/matchStats";
import { score_settings } from "../../helpers";
import * as selectors from "../../modules/selectors";

import * as actions from "../../modules/actions";
import colors from "../../assets/css/colors";
import LockCircle from "../Icons/LockCircle";

import Preloader from "../Preloader";
import { below } from "../../assets/css/media";
import { getClubLogoIndigenous } from "../../utils/clubLogos";
import type { TPLeague, TPlayer, TRound } from "../../modules/types";
import {
	SectionHeader,
	StatHeadItem,
	StatHeadRow,
	StatItem,
	StatItemRow,
} from "./PlayerPopupStyles";


const FcLink = styled.a`
	color: ${colors.coach};
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: flex-start;
`;


const FlexCol = styled.div`
    display: flex;
    flex-direction: column;
    .venue-section{
        min-width: 80px;
        ${below.phone`
            min-width: 70px;
        `}
    }
`;



type MatchStatProps = {
    is_classic: boolean,
    league: TPLeague,
    rounds: TRound[],
    player: TPlayer,
    has_coach: boolean,
    actual_round: number,
    fullLink: string,
    player_matches: Object,
    fetchPlayerMatches: Function,
    fetchCoachStats: Function,
    venues: Object,
    classic_venue_stats: [],
    draft_venue_stats: [],
    fetchPlayerVenueClassic: typeof actions.fetchPlayerVenueStats,
	fetchPlayerVenueDraft: typeof actions.fetchPlayerVenueStatsDraft,
}



class VenueStatsComponent extends React.PureComponent<MatchStatProps> {
	constructor(props) {
		super(props);
		
		_.bindAll(this, [
			// 'openSubModal',
			// 'closeSubModal'
		]);
	}

	componentDidMount(){
		this.props.fetchPlayerMatches(this.props.player.id);
		this.props.fetchCoachStats();
		this.getPlayerOpponentVenueData();
	}

	getPlayerOpponentVenueData() {
		const { league } = this.props;
		const league_id = _.get(league, "id");
		const isCustomScoring = _.get(league, "custom_scoring_enabled", 0);
		if(!Boolean(isCustomScoring)){
			this.props.fetchPlayerVenueClassic();
			return;
		}
		this.props.fetchPlayerVenueDraft(league_id);
	}
	get venuesToUse() {

		const venuesByID = this.props.venues;
		return Object.keys(venuesByID).map((squadID =>venuesByID[squadID]));
	}
	
	get isLeagueCustomScoring() {
		return Boolean(this.props.league) && this.props.league.custom_scoring_enabled;
	}

	get stats() {
		const { league } = this.props;
		const stats = [];
		const gamesPlayed = {
			name: "GP",
			id: "GP",
			tooltip: "Games Played against opponent",
		};

		if(this.isLeagueCustomScoring){
			stats.push({
				name: "% TOG", 
				id: "TOG", 
				tooltip: "Percentage of time spent on ground"
			});
			Object.keys(league.custom_scoring).forEach(i => {
				stats.push({ 
					id: i, 
					name: i, 
					tooltip: score_settings[i].title + ` (${league.custom_scoring[i]} points)`, 
					score_per: league.custom_scoring[i]
				});
			});
			stats.push({
				name: "Score", 
				id: "stats.score", 
				tooltip: "Total Score for Game"
			});
		}
		else{
			MATCH_STATS.played.forEach(i => { 
				stats.push(i);
			});
		}
		return [gamesPlayed, ...stats];
	}

	get table_body() {
		const {
			league, 
			player, 
			draft_venue_stats, 
			classic_venue_stats,
			has_coach
		} = this.props;
		if(has_coach){
			return this.venuesToUse.map((venue, index) => {
				return(
					<StatItemRow key={`${index}-venue-item-value`}>
						<StatItem className="venue-section">
							{venue.name}
						</StatItem>
						{this.stats.map(stat => {
							const isCustomScoring = 
								Boolean(_.get(league, "custom_scoring_enabled", 0));
							const selectorData = 
								isCustomScoring 
									? draft_venue_stats[player.id] 
									: classic_venue_stats[player.id];
							const oppositionData = _.get(selectorData, venue.id, {});
							return(
								
								this.displayVenueStat(stat, oppositionData)
						
							);
						})}
					</StatItemRow>
				);
			});
		}
		return <StatItemRow>
			<FcLink href={"/coach-subscription"} target="_blank">
				<LockCircle size={2}/>
			</FcLink>
		</StatItemRow>;
		
	}

	displayVenueStat (stat: Object, venueData) {
		const {player} = this.props;
		
		const isMatchPlayed = Boolean(venueData["score"]) && venueData["score"] !==0;
		const playedDefault = isMatchPlayed ? 0 : "--";
		if(venueData[stat.id]){
			return  <StatItem rel={stat.id} key={`${stat.id}-${player.id}`}>
				{venueData[stat.id]}
			</StatItem>;
		}
		if(stat.id.includes("score")){
			const displayScore = venueData["score"] ? venueData["score"] : playedDefault;
			return  <StatItem rel={stat.id} key={`${stat.id}-${player.id}`}>
				{displayScore}
			</StatItem>;
		}
		return <StatItem rel={stat.id} key={`${stat.id}-${player.id}`}>{playedDefault}</StatItem>;
	};

	getOpponentImage(opponent) {
		const opponentID = opponent.id;
		const logo = getClubLogoIndigenous(this.props.actual_round, opponentID);
		return <img src={logo} alt="Opponent Logo"/>;
	}


	render() {
		const {
			player_matches, 
			player, 
		} = this.props;
		if(!player_matches[player.id]){
			return <Preloader />;
		}
	
		return(
			<FlexCol>
				<SectionHeader>
					<p>Venue Stats</p>
				</SectionHeader>
				<StatHeadRow>
					<StatHeadItem className="venue-section">
                        Venue
					</StatHeadItem>
					{this.stats.map((stat, index) => {
						return(
							<StatHeadItem key={`-${index}-venue-item`} is_fc={false}>
								{stat.name}
							</StatHeadItem>
						);
					})}
				</StatHeadRow>
				{this.table_body}
			</FlexCol>
		);
		
	}
}

const mapStateToProps = state => ({
	menu_open: state.uiMenu.open,
	league_id: state.leagues.show.league_id,
	player_matches: state.players.matches_by_id,
	venues: state.venues.by_id,
	classic_venue_stats: selectors.players.getPlayerVenueStatsClassic(state),
	draft_venue_stats: selectors.players.getPlayerVenueStatsDraft(state),
});

const mapDispatchToProps = {
	fetchPlayerMatches: actions.fetchPlayerMatches,
	fetchCoachStats: actions.fetchCoachPlayers,
	fetchPlayerVenueClassic: actions.fetchPlayerVenueStats,
	fetchPlayerVenueDraft: actions.fetchPlayerVenueStatsDraft,
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VenueStatsComponent));