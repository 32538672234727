/* eslint max-len: 0 */
// @flow
import React from "react";
import SvgIconContainer from "../SvgIconContainer";

export default (
	{
		unit = "em",
		...rest
	}: Object = {}) => (
	<SvgIconContainer viewBox="0 0 24 24" {...rest} unit={unit} color="#D92425">
		<title>Close Circle Logo</title>
		<g fill="#D92425"></g>
		<path d="M8,0 C12.418278,0 16,3.581722 16,8 C16,12.418278 12.418278,16 8,16 C3.581722,16 0,12.418278 0,8 C0,3.581722 3.581722,0 8,0 Z M11.5777778,3.33333333 L8.03833333,6.90133333 L8,6.8627451 L7.96033333,6.90133333 L4.42222222,3.33333333 L3.33333333,4.43137255 L6.87233333,7.99933333 L3.33333333,11.5686275 L4.42222222,12.6666667 L7.96033333,9.09733333 L8,9.1372549 L8.03833333,9.09733333 L11.5777778,12.6666667 L12.6666667,11.5686275 L9.12733333,7.99933333 L12.6666667,4.43137255 L11.5777778,3.33333333 Z"></path>
	</SvgIconContainer >
);