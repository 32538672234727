// @flow
import styled from "styled-components";
import colors from "../../assets/css/colors";

export const SearchSubmitButton = styled.button`
	position: absolute;
	cursor: pointer;
	top: 50%;
	transform: translateY(-50%);
	right: 5px;
	outline: none;
	border: none;
	background: transparent;
	color: ${colors.title};
	height: 40px;
	width: 40px;
	font-size: 14px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 0 2px 2px 0;
	> svg {
		height: 24px;
		width: 24px;
	}
`;

export default SearchSubmitButton;