// @flow
import * as React from "react";
import { connect } from "react-redux";
import styled from "styled-components";

import {
	StripeProvider,
	Elements,
} from "react-stripe-elements";
import { bindAll, get } from "lodash";
import { below } from "../../assets/css/media";
import colors from "../../assets/css/colors";
import * as actions from "../../modules/actions";
import * as selectors from "../../modules/selectors";
import { isMobile } from "../../utils";
import isAuthenticated from "../../utils/auth/isAuthenticated";
import Tick from "../../components/Icons/Tick";
// $FlowFixMe
import SubscriptionForm from "../proSubscription/SubscriptionForm";
import { subscriptionFeatures } from "../proSubscription/PackageArr";
import proSubBanner from "../../assets/images/pro-sub-header.png";

const stripe_pk = process.env.REACT_APP_STRIPE_PK || "";
const TabSection = styled.div`
  display: flex;
  flex-direction: column;
	margin-bottom: 10px;
`;

const FlexColumnCenter = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	form{
		padding: 25px 0;
	}
`;

const TabWrapper = styled.ul`
  width: 360px;
  height: 40px;
  outline: solid 1px ${colors.secondary.paleGrey};
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  padding: 0 5px;
  ${below.phone`
  	width: 275px;
  `}
`;
const PackageTabs = styled.li`
  colors: ${colors.secondary.accentGrey};
  cursor: pointer;
  font-family: SourceSansPro;
  font-weight: 600;
  font-size: 12px;
  border-radius: 2px;
  width: 170px;
  padding: 9px 10px;
  ${props => props.is_active === true && `
    color: ${colors.white};
    background-color: ${colors.black};
  `}
  & span {
	  margin-right: 2px
  }
  
  ${below.phone`
  	display: flex;
	justify-content: center;
	width: 120px;
  `}
`;


const ModalTitle = styled.h2`
	color: ${colors.black};
	font-size: 32px;
	text-align: center;
	margin-bottom: 10px;
	padding-top: 20px;  
	${below.tablet`
		font-size: 24px;
	`}

	${below.phone`
		font-size: 22px;
	`}
`;

const ModalText = styled.p`
  font-size: 14px;
  font-family: SourceSansPro;
  line-height: 20px;
  color: ${colors.black};
  width: 600px;
  height: 60px;
  margin-top: 20px;
  ${below.phone`
	  width: 100%;
	  height: auto;
	  padding: 0 0 20px 0;
  `}
`;

const FeaturesSection = styled.div`
  padding: 0px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  min-height: 115px;
  
  ${below.phone`
	  display: flex;
	  flex-direction: column;
	  margin-top: 20px;
	  padding: 0;
		width: 100%;
  `}
`;

const FeatureItem = styled.div`
  color: ${colors.black};
  border: 1px solid ${colors.black};
  font-family: SourceSansPro;
  font-weight: 600;
  font-size: 12px;
  width: 260px;
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
	margin-top: 10px;
	${props => props.pointer && `
		cursor: pointer;
	`}
  svg{
    width: 14px;
    height: 14px;
    margin-right: 13px;
    margin-left: 8px;
  }
  ${below.phone`
  	margin-top: 5px;
 	width: 99%;
  `}
`;

const FeatureItemTextWrap = styled.div`
	display: flex;
	justify-content: space-between;
	width: calc(100% - 45px);
`;

const FeatureItemText = styled.div`
	line-height: 20px;
	height: 20px;
`;

const FeatureItemDeluxe = styled.div`
	border-radius: 2px;
	background-color: ${colors.black};
	color: ${colors.white};
	font-family: "SourceSansPro";
	font-size: 12px;
	font-weight: 600;
	letter-spacing: 0;
	line-height: 20px;
	text-align: center;
    height: 20px;
    padding: 0 5px;
`;


const AFLProTxtWrap = styled.div`
	display: flex;
	width: 100%;
	justify-content: center;
	${below.phone`
		display: flex;
		margin: 0;
		flex-direction: column;
		height: 100px;
		justify-content: center;
		text-align: center;
	`}
`;

const AFLProTxtWrapImg = styled.img`
	max-width: 920px;
	${below.phone`
		max-width: 100%;
		margin: 0 auto;
	`}
`;


const LeagueSelect = styled.select`
	font-family: "SourceSansPro";
	font-size: 12px;
	font-weight: 600;
	letter-spacing: 0;
	line-height: 12px;
	color: ${colors.secondary.accentGrey};
	height: 40px;
	width: 200px;
	border: 1px solid ${colors.form.borderColor};
	${below.tablet`
    	width: 100%;
	`}
`;

type Props = {
	closeClick: Function,
	subscribeLeague: typeof actions.leagueDraft.subscribeLeague,
	clearSubscribeLeagueErrors: typeof actions.leagueDraft.clearSubscribeLeagueErrors,
	showDraftLeague: typeof actions.leagueDraft.showDraftLeague,
	subscribe: {
		error: string,
		is_pending: boolean,
	},
	league: {
		subscription: any,
		id: number,
	},
	onSubmit: Function,
	create?: boolean,
	leagueCreateSubmit: Function,
	createdID: number,
	changeFieldValue: Function,
	fetchMyDraftLeagues: Function,
	my_draft_leagues: Object,
	user_id: number,
	leagues_by_id: Object,
};

type State = {
	active_tab: string,
	is_subscribed: boolean,
	selected_league: number,
}
// eslint-disable-next-line max-len
const modalText = "With a Pro Subscripton you have access to even more customisation options that will let you create an advanced and unique Draft league.";
class ProSubscriptionModalComponent extends React.Component<Props, State> {
	constructor(props: Props, state: State){
		super(props, state);
		bindAll(this, [
			"setActiveTab", "handleSubmit", "setCurrentLeague"
		]);
	}
	state = {
  	active_tab: "full",
  	is_subscribed: false,
  	selected_league: 0,
	};

	componentDidMount() {
  	const { fetchMyDraftLeagues } = this.props;
  	fetchMyDraftLeagues();
	}

	componentDidUpdate(prevProps, prevState) {
  	const { selected_league } = this.state;
  	const { showDraftLeague } = this.props;
  	if(selected_league !== prevState.selected_league){
  		showDraftLeague({ id: selected_league });
  	}
	}

	setActiveTab(tab: string){
  	this.setState({
  		active_tab: tab,
  	});
	}
	isActive(tab: string){
  	if(this.state.active_tab === tab){
  		return "selected-tab";
  	}
	}
	handleSubmit(values) {
	  const { subscribeLeague, create, leagueCreateSubmit, changeFieldValue } = this.props;

  	if(create){
  		changeFieldValue("plan", values.plan);
  		changeFieldValue("token", values.token);
  		leagueCreateSubmit();
  	}
  	else{
  		subscribeLeague(values);
  	}
  	
	}
	get message(){
  	return "Your league has upgraded to Pro!";
	}

	get modalImgContent(){
	  return <AFLProTxtWrap>
  		<AFLProTxtWrapImg src={proSubBanner} alt="Rise above the pack with a pro Subscription" />
  	</AFLProTxtWrap>;
	}

	get commissioner_leagues() {
  	const { my_draft_leagues, user_id } = this.props;
  	return my_draft_leagues.filter(league => league.commissioner === user_id);
	}

	setCurrentLeague(e) {
  	this.setState({
  		selected_league: Number(e.target.value)
  	});
	}

	render() {
  	const { closeClick, subscribe, leagues_by_id } = this.props;
  	const { active_tab, selected_league } = this.state;
  	const selected_league_sub = get(leagues_by_id, `[${selected_league}].subscription`);
  	const featureItems = subscriptionFeatures(active_tab);
	  
  	const deluxeLabel = isMobile() ? "Deluxe":"Pro Deluxe";
  	const liteLabel = isMobile() ? "Lite":"Pro Lite";

  	return(
  		<FlexColumnCenter>

				
  			{this.modalImgContent}
  			<ModalTitle>Subscribe to Pro</ModalTitle>
  			<LeagueSelect onChange={e => this.setCurrentLeague(e)} value={selected_league}>
  				<option 
  					value={0} 
  					key={"league-0"}>Select a League
  				</option>
  				{this.commissioner_leagues.map((league, index )=> (
  					<option key={`league-${index}`} value={league.id}>
  						{league.name}
  					</option>
  				))}


  			</LeagueSelect>
  			<ModalText>{modalText}</ModalText>

  			{["full", "lite"].includes(selected_league_sub) ? 
  				<p>This league has already subscribed</p>
  				: <React.Fragment>
  					<TabSection>
  				<TabWrapper>
  					<PackageTabs 
  						is_active={active_tab === "full"} 
  						onClick={() => this.setActiveTab("full")}>
  						<span>{deluxeLabel}</span>
  						<span>$49.99</span>
  					</PackageTabs>
  					<PackageTabs 
  						is_active={active_tab === "lite"}  
  						onClick={() => this.setActiveTab("lite")}>
  						{liteLabel} ($29.99)
  					</PackageTabs>
  				</TabWrapper>
  			</TabSection>
  			<FeaturesSection>
  				{featureItems.map((feature, index) => {
  					const deluxe = index < 2 && active_tab === "full" ? 
  						<FeatureItemDeluxe>Deluxe only</FeatureItemDeluxe>:null;
  					return (
  						<FeatureItem key={index}>
  							<div><Tick /></div>
  							<FeatureItemTextWrap>
  								<FeatureItemText>{feature}</FeatureItemText>
  								{deluxe}
  							</FeatureItemTextWrap>
  						</FeatureItem>
  					);
  				})}
  			</FeaturesSection>
					
  			<StripeProvider apiKey={stripe_pk}>
  				<Elements>
  					<SubscriptionForm 
  						handleClose={closeClick} 
  						cur_plan={active_tab} 
  						is_active={false} 
  						submitData={this.handleSubmit}
  						subscribe={subscribe}
  						initialValues={{ league_id: selected_league, plan: active_tab }}
  						create={this.props.create}
  								isAccountPage={true}
  					/>
  				</Elements>
  			</StripeProvider>
						
  				</React.Fragment>
  			}
  		
  		</FlexColumnCenter>
  	);
	}
	
};

const mapStateToProps = (state, props) => ({
	is_authorised: isAuthenticated(), 
	subscribe: state.leagues.subscribe,
	createdID: state.leagues.create.league_id,
	my_draft_leagues: selectors.leagues.show_my.getLeagues(state),
	user_id: selectors.getUser(state).id,
	leagues_by_id: state.leagues.by_id,
});

const mapDispatchToProps = {
	subscribeLeague: actions.leagueDraft.subscribeLeague,
	clearSubscribeLeague: actions.leagueDraft.clearSubscribeLeagueErrors,
	showDraftLeague: actions.leagueDraft.showDraftLeague,
	fetchMyDraftLeagues: actions.leagueDraft.showMyDraftLeagues,
};

const ProSubscriptionContent = connect(
	mapStateToProps,
	mapDispatchToProps)
(ProSubscriptionModalComponent);

export default ProSubscriptionContent;