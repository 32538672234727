// @flow
import * as React from "react";

import { ButtonPrimaryLink } from "../../ButtonPrimary";

import Container from "./Container";

type Props = {
	game_type: 'classic' | 'draft',
	league: {
		id: number
	}
}

class YourSeasonFinished extends React.Component<Props> {
	render() {
		const { game_type, league } = this.props;
		return (
			<Container
				show_icon text={"Your league's season has finished"}
				renderRightSide={() => (
					<ButtonPrimaryLink to={`/${game_type}/league/${league.id}`}>
						League Overview
					</ButtonPrimaryLink>
				)}
			/>
		);
	}
}

export default YourSeasonFinished;