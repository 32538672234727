// @flow
import * as _ from "lodash";
import type { TRound, TPlayer, TDraftLineup } from "../../modules/types";
import { matchStarted, findMatch } from "../rounds";

export const roundHasStarted = (round: TRound) => {
	return round && round.status !== "scheduled";
};

export const getDisplayRoundId = (round: TRound) => {
	return _.get(round, "id", 0);
};

export const getPlayerMatchStatus = (player: TPlayer, round: TRound) => {
	const match = findMatch(_.get(round, "matches", []), _.get(player, "squad_id", 0));
	return _.get(match, "status");
};

export const showDNP = (round: TRound, match_started: boolean) => {
	const round_id = getDisplayRoundId(round);

	return round_id >= 1 && match_started;
};


export const displayRoundLabel = (
	player: TPlayer,
	round: TRound, 
	stat_value: any,
	has_assistant_coach?: boolean,
) => {
	const status = getPlayerMatchStatus(player, round);
	const is_playing_now = status === "playing";
	const match_scheduled = status === "scheduled";
	const is_dnp = stat_value === "DNP";

	if (is_playing_now) {
		// Match is active => Live score
		return is_dnp ? "DNP" : "LIVE";
	}

	if (match_scheduled) {
		return has_assistant_coach ? "Projected":"";
	}

	// Match is complete => Final score from current round
	return "FINAL";
};

export const displayRoundScore = (
	player: TPlayer, 
	round: TRound, 
	custom_scoring?: boolean,
	has_assistant_coach?: boolean,
	
) => {
	const match_started = matchStarted(round, _.get(player, "squad_id", 0));
	const round_id = getDisplayRoundId(round);
	const matches = _.get(round, "matches", []);
	const is_team_dnp = findMatch(matches, _.get(player, "squad_id", 0)) === undefined;
	const is_dnp_eligible = match_started || is_team_dnp;
	let player_stat_field = `stats.scores.${round_id}`;

	if(custom_scoring) {
		player_stat_field = _.get(player, `custom_stats.scores.${round_id}`) ? 
			`custom_stats.scores.${round_id}`:`custom_stats.proj_score_actual_round`;
	}
	
	const projectedScore = has_assistant_coach ? 
		_.get(player, "stats.proj_score", "- -"):"- -";

	const default_value = showDNP(round, is_dnp_eligible)
		? "DNP" : projectedScore;

	return _.get(player, player_stat_field, default_value);
};

export const shouldDoubleScore = (
	player: TPlayer, 
	lineup: TDraftLineup, 
	round: TRound, 
	captain: TPlayer
) => {
	const player_id = _.get(player, "id");
	const captain_id = _.get(lineup, "captain");
	const vice_id = _.get(lineup, "vice_captain");

	if (player_id === captain_id) {
		return displayRoundScore(player, round) !== "DNP";
	}

	if (player_id === vice_id && captain) {
		const is_cap_bye = _.get(round, "bye_squads", []).includes(_.get(captain, "squad_id"));
		// const captain_status = getPlayerMatchStatus(captain, round);
		const captain_score = displayRoundScore(captain, round);
		const is_cap_dnp = captain_score === "DNP" || is_cap_bye;

		return displayRoundScore(player, round) !== "DNP"
			&& is_cap_dnp;
	}
	return false;
};