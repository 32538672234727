// @flow
import * as React from "react";
import styled from "styled-components";
import colors from "../../assets/css/colors";
import CautionReset from "../../components/Icons/CautionReset";

import {
	ModalContainer, PageTitle,
} from "../../components";

import ResetPasswordForm from "./resetPasswordForm";

type Props = {
	closeClick: Function,
	confirmClick: Function,
	sent_to_reset_password?: boolean,
};

const ModalContent = styled.div`
	text-align: center;
	color: ${colors.primary.darkGrey};
	font-size: 14px;
	padding: 0 2em;
	line-height: 20px;
	font-family: "SourceSansPro";
`;

const PageTitleStyled = styled(PageTitle)`
	font-size: 32px;
	display: flex;
	flex-direction: column;
    align-items: center;

	svg{
		margin: 0 10px 30px 0;
	}
`;

const ModalContentStyled = styled(ModalContent)`
	margin: 1em 0;
`;

export const ResetPasswordModal = ({
	closeClick,
	confirmClick,
	sent_to_reset_password,
}: Props) => (
	<ModalContainer onClick={closeClick}>
		<PageTitleStyled>
			<CautionReset color={colors.title} size='2' />
			Reset your password
		</PageTitleStyled>

		{sent_to_reset_password ? (
			<ModalContentStyled>
				Password recovery link successfully sent to your email
			</ModalContentStyled>
		) : (
			<div>
				<ModalContent>
					A link to reset your password will be
					emailed to this address. Click on the link in
					the email to change your password. If you need assistance, please contact us.
				</ModalContent>
				<ResetPasswordForm closeClick={closeClick} onSubmit={confirmClick} />
			</div>
		)}


	</ModalContainer>
);

export default ResetPasswordModal;