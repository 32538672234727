// @flow

import { css } from "styled-components";

import "./fonts.css";
import colors from "./colors";
import { above, below } from "./media";

export default css`
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}

body {
	line-height: 1;
}

ol, ul {
	list-style: none;
}

blockquote, q {
	quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

body {
	font-family: TitilliumUpright;
	width: 100%;
	font-size: 16px;
	color: ${colors.primary.darkGrey};
}


h1, h2, h3, h4 ,h5 ,h6, strong {
	font-family: TitilliumUpright;
	font-weight: 700;
}
div, input, select, label, button{
	font-family: TitilliumUpright;
}
.uppercase {
	text-transform: uppercase;
}
.hidden{
	display: none !important;
}
.hidden-mobile {
	${below.desktop`
		display: none !important;
	`}
}
.hidden-desktop {
	${above.desktop`
		display: none !important;
	`}
}

/**
 * Fixed an issue with telstra header leaving 5px gap between itself 
 * and rest of page
 */
${below.tablet`
	#bphf-top > #tmx-header > #tmx-network .tmx-tmedia.section > a {
		height: 45px;
		width: 90px;
		overflow: hidden;
		display: block;
	}
`};

#bphf-top, #bphf-bottom {
	overflow: hidden;
}
`;