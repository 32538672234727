// @flow
import * as React from "react";
import styled from "styled-components";
import * as _ from "lodash";
import { getAvatar } from "../../modules/selectors";
import colors from "../../assets/css/colors";
import { ErrorBoundary } from "../../components";
import { isAllTrue } from "../../helpers";

const clubColors = {
	"10": "#002B5C",
	"20": "#69003d",
	"30": "#051829",
	"40": "#000000",
	"50": "#CC2031",
	"60": "#250553",
	"70": "#002B5C",
	"80": "#352015",
	"90": "#CD1A2E",
	"100": "#13388F",
	"110": "#008aab",
	"120": "#FFD300",
	"130": "#ED1B2F",
	"140": "#1C2E71",
	"150": "#003087",
	"160": "#d52b1e",
	"1000": "#FFE010",
	"1010": "#ff7900"
};

const getClubColor = (id: string) => {
	if(id in clubColors) {
		return clubColors[id];
	}
	return "#E00034";
};

const AvatarImg = styled.img`
	display: block;
	background: ${colors.secondary.lightGrey};
	color: #fff;
	line-height: 70px;
	font-family: TitilliumUpright;
	font-weight: 200;
	text-align: center;
	font-size: 30px;
`;

const NoId = styled.div`
	display: block;
	background: ${colors.secondary.lightGrey};
`;

const AvatarFallback = styled.div`
	font-size: 26px;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 400;
	color: #fff;
	font-family: TitilliumUpright;
	background: ${({ supported_club_id }) => supported_club_id ? getClubColor(supported_club_id) :
		"#E00034"};
	line-height: 1;
	vertical-align: middle;
	span {
		margin-top: 0.2em;
	}
`;

const NameTooltip = styled.div`
	/* 
	 * Only opacity and transform should be animated
	 *
	 * On the fade out animation, height, padding and 
	 * font-size should be delayed and only change after 
	 * opacity and transform animations have completeted
	 */
	transition: opacity 0.2s ease-out, 
		transform 0.2s ease-out, 
		height 0s linear 0.2s,
		padding 0s linear 0.2s,
		font-size 0s linear 0.2s;

	font-family: TitilliumUpright;
	font-weight: 300;
	background-color: ${colors.primary.darkGrey};
	color: white;
	border-radius: 5px;
	box-sizing: border-box;
	z-index: 10;

	/* Initial values - make tooltip hidden */
	opacity: 0;
	height: 0;
	font-size: 0;
	padding: 0px;

	/* Truncate team name if >150px long */
	width: auto;
	> span {
		display: inline-block;
		max-width: 150px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	position: absolute;
	/* Starts with top-right corner 8px left of centre of avatar */
	top: 50%;
	right: calc(100% + 8px);
	/* 
	 * Translate so that centre of tooltip starts fade ins and 
	 * ends fade outs 4px above centre of avatar
	 */
	transform: translate(0, -15px);

	/* Arrow on right of tooltip */
	:after {
		content: " ";
		height: 0;
		width: 0;
		top: calc(50% - 4px);
		right: -8px;
		position: absolute;
		pointer-events: none;
		border: 4px solid rgba(0, 0, 0, 0);
		border-left: 4px solid black;
	}
`;

const HoverWrapper = styled.div`
	
	position: relative;
	width: ${({ no_hover }) => no_hover ? "" : "100%"};
	:hover {
		${NameTooltip} {
			/* 
			* Only opacity and transform should be animated
			*
			* On the fade in animation, height, padding and 
			* font-size should change immediately
			*/
			transition: opacity 0.2s ease-out, 
				transform 0.2s ease-out, 
				height 0s linear,
				padding 0s linear,
				font-size 0s linear;

			/* 
			* Translate so that centre of tooltip ends fade ins and 
			* starts fade outs in line with centre of avatar
			*/
			transform: translate(0, -11px);

			/* Make the tooltip visible */
			opacity: 1;
			height: 22px;
			font-size: 12px;
			padding: 5px 10px;
		}
	}
`;

const AvatarWrapper = styled.div`
	border-radius: 50%;
	overflow: hidden;
	position: relative;
	height: 100%;
	width: 100%;

	background-color: white;
	box-shadow: 0 0 5px 0 rgba(127,138,144,0.2);

	display: flex;
	justify-content: center;
	align-items: center;

	> img, > div {
		height: calc(100% - 2px);
		width: calc(100% - 2px);
		border-radius: 50%;
	}
	> img {
		pointer-events: none;
	}
`;

type Props = {
	user_id?: number,
	avatar_version?: number,
	firstname?: string,
	lastname?: string,
	className?: string,
	supported_club_id?: number,
	hover_name: string,
	no_hover?: boolean,
};

type State = {
	has_error: boolean
}

export class TeamUserAvatar extends React.Component<Props, State> {
	static defaultProps = {
		hover_name: "",
	};
	state = {
		has_error: false
	};
	render() {
		const {
			user_id = 0,
			avatar_version = 1,
			className = "",
			firstname = "",
			lastname = "",
			supported_club_id,
			hover_name
		} = this.props;
		if (user_id === 0) {
			return <AvatarWrapper className={className}>
				<NoId />
			</AvatarWrapper>;
		}
		
		const src = getAvatar({ user_id, avatar_version });
		
		const fallback_avatar = <AvatarFallback supported_club_id={supported_club_id}>
			<span>
				{(isAllTrue([Boolean(firstname), firstname.length > 0])) 
					&& firstname.charAt(0).toUpperCase()}
				{(lastname && lastname.length > 0) && lastname.charAt(0).toUpperCase()}
			</span>
		</AvatarFallback>;

		return <ErrorBoundary fallback={fallback_avatar}>
			<HoverWrapper no_hover={this.props.no_hover}>
				{ !_.isEmpty(hover_name) && <NameTooltip><span>{hover_name}</span></NameTooltip> }
				<AvatarWrapper className={className}>
					{this.state.has_error
						? fallback_avatar
						: <AvatarImg
							src={src}
							alt=""
							onError={() => this.setState({ has_error: true })}
						/>
					}
				</AvatarWrapper>
			</HoverWrapper>
		</ErrorBoundary>;
	}
}

TeamUserAvatar.displayName = "TeamUserAvatar";

export default TeamUserAvatar;