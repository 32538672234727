// @flow
import React from "react";
import { connect } from "react-redux";
import _, { isEmpty } from "lodash";
import { compose } from "redux";


import * as selectors from "../../../modules/selectors";
import * as actions from "../../../modules/actions";
import TwoColumnLayout
from "../../../components/TwoColumnLayout";
import PageContentWrapper from "../../../components/PageContentWrapper";
import withRoundsSelector from "../../../components/utils/withRoundsSelector";
import RoundSelector from "../../../components/RoundSelector";
import AdsContainer from "../../../components/AdsContainer";
import MatchCentreBar from "../../../components/MatchCentreBar";
import Ad from "../../../components/Ad";
import LeagueGameBar from "../../../components/LeagueGameBar";
import AdFantasyCoach from "../../../components/AdFantasyCoach";
import PageTitle from "../../../components/PageTitle";
import H2HLadder from "../../../components/H2HLadder";
import Footer from "../../../components/Footer";
import withCoachesBox from "../../../components/utils/withCoachesBox";
import {Exist} from "../../../components/Exist";
import {isMobile} from "../../../utils/isMobile";
import { getLeagueRoundAliases, getWinners } from "../../../helpers/league";
import type { TPLeague, TRoundsById, TRound, TDraftTeamsById } from "../../../modules/types";
import { WidgetSpacer, WidgetRfaOrder, WidgetNews, withDraftCore } from "../../../components";
import { isRoundActive, isRoundComplete } from "../../../helpers/rounds";
import YourTeamDetails from "../../../components/LeagueGameBar/YourTeamDetails";


const isRoundDisabled = (round: TRound, league: TPLeague) => (
	round.id !== league.start_round
	&& !isRoundActive(round)
	&& !isRoundComplete(round)
);


type Props = {
	selected_league_id: number,
	ladder_teams_ordered: Array<Object>,
	ladder_teams_by_id: TDraftTeamsById,
	fetchLeague: typeof actions.leagueDraft.showDraftLeague,
	fetchLadder: typeof actions.leagueDraft.ladderDraftLeague,
	is_pending_show: boolean,
	is_pending_ladder: boolean,
	user_id: number,
	rounds_ordered: Array<Object>,
	rounds_by_id: TRoundsById,
	selected_round_id: number,
	setSelectedRoundId: Function,
	league: TPLeague,
	actual_round_id: number,
	actual_round: TRound,
}

type State = {
	rfa_order_widget_open: boolean,
	selected_round_id: number,
}

class LeagueLadderPage extends React.Component<Props, State> {
	constructor(props) {
		super(props);

		_.bindAll(this, [
			"onToggleWaiverOrderWidget",
			"setSelectedRound"
		]);
	}

	state = {
		rfa_order_widget_open: false,
		selected_round_id: 1,
	};

	componentDidMount() {

		this.fetchLeague();
		this.setSelectedRound(this.initial_selected_round);
	}

	componentDidUpdate(prev_props, prevState) {
		const { selected_league_id } = this.props;
		
		if (
			selected_league_id &&
			selected_league_id !== prev_props.selected_league_id
		) {
			this.fetchLeague();
		}
		if(Boolean(this.props.actual_round_id) && !Boolean(prev_props.actual_round_id)){
			this.setSelectedRound(this.props.actual_round_id);
		}

		if (
			selected_league_id !== prev_props.selected_league_id) {
			this.setSelectedRound(Number(this.league_selected_round));
		}
	}

	get league_rounds() {
		const {
			rounds_ordered,
			league
		} = this.props;

		if (rounds_ordered && league && league.start_round && league.end_round) {
			return _(rounds_ordered)
				.map(round => ({
					...round,
					disabled: isRoundDisabled(round, league)
				}))
				.filter(round =>
					round.id >= league.start_round
					&& round.id <= league.end_round
				)
				.value();
		}

		return rounds_ordered;
	}

	get finals_rounds() {
		const {
			rounds_ordered,
			league
		} = this.props;

		return getLeagueRoundAliases(league, rounds_ordered);
	}

	get initial_selected_round() {
		return this.props.actual_round_id;
	}

	get league_selected_round() {
		const { selected_round_id, league } = this.props;

		if (selected_round_id && league && !isEmpty(this.league_rounds)) {
			
			const start_round = _.first(this.league_rounds).id;
			const end_round = _.last(this.league_rounds).id;

			if ( selected_round_id < start_round) {
				return start_round;
			}
			else if (selected_round_id > end_round) {
				return end_round;
			}

			return selected_round_id;
		}

		return  null;
	}

	get league_current_round_fixtures() {
		const { league, selected_round_id } = this.props;
		return selected_round_id ?
			_.get(league, `fixture.${selected_round_id}`, []): [];
	}

	fetchLadder() {
		const { fetchLadder, selected_league_id } = this.props;
		fetchLadder({
			league_id: selected_league_id,
			round: this.props.selected_round_id
		});
	}

	fetchLeague() {
		const { fetchLeague, selected_league_id } = this.props;
		fetchLeague({
			id: selected_league_id
		});
	}

	setSelectedRound(id: number){
		this.props.setSelectedRoundId(id);
	}

	onToggleWaiverOrderWidget() {
		this.setState(state => ({
			rfa_order_widget_open: !state.rfa_order_widget_open
		}));
	}

	get winners() {
		const { league, ladder_teams_ordered, rounds_by_id, selected_round_id } = this.props;
		const round = _.get(rounds_by_id, selected_round_id);
		return getWinners(league, round, ladder_teams_ordered);
	}

	renderMainSection() {
		const {
			league,
			ladder_teams_ordered,
			ladder_teams_by_id,
			rounds_by_id,
			user_id,
			actual_round
		} = this.props;
		ladder_teams_ordered
			.sort((a,b) => a.rank_selected_round > b.rank_selected_round ? 1 : -1);
		const is_mobile = isMobile();
		 return (
			<div>
				<RoundSelector
					selected={this.props.selected_round_id}
					rounds={this.league_rounds}
					handleClick={this.setSelectedRound}
					round_aliases={this.finals_rounds}
				/>
				<Exist when={is_mobile}>
					<YourTeamDetails
						league={league}
						league_is_pending={false}
						display_mobile={true}
						actual_round={actual_round}
					/>
				</Exist>
				{/* $FlowFixMe */}
				<H2HLadder
					user_id={user_id}
					ladder_teams_ordered={ladder_teams_ordered}
					ladder_teams_by_id={ladder_teams_by_id}
					rounds_by_id={rounds_by_id}
					selected_round_id={this.props.selected_round_id}
					league={league}
					league_current_round_fixtures={this.league_current_round_fixtures}
					show_tabs
					{...this.winners}
				/>
				<AdFantasyCoach type="leaderboard" small_screen_only />
			</div>
		 );
	}

	renderAside() {
		return <aside>
			<Ad type="mrec" />
			<WidgetSpacer />
			<WidgetRfaOrder />
			<WidgetSpacer />
			<WidgetNews />
			<AdFantasyCoach type="mrec" large_screen_only />
		</aside>;
	}

	render() {
		const { league } = this.props;
		const title_league_name = league ? league.name : "-";
		return (
			<React.Fragment>

				<LeagueGameBar />

				<AdsContainer>
					<Ad />
				</AdsContainer>
				<PageContentWrapper className="playwright-mask-hidden">
					<PageTitle>
						Ladder for {title_league_name}
					</PageTitle>
					<TwoColumnLayout>
						{this.renderMainSection()}
						{this.renderAside()}
					</TwoColumnLayout>
				</PageContentWrapper>
				<MatchCentreBar />
				<Footer />
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state, props) => {
	const selected_league_id = props.match.params.league_id;
	const actual_round = selectors.rounds.getActualRound(state);
	return {
		selected_league_id,
		league: selectors.leagues.getLeague(state, props),
		is_pending_show: selectors.leagues.show.isPending(state),
		is_pending_ladder: selectors.leagues.ladder.isPending(state),
		ladder_teams_by_id: selectors.leagues.ladder.getById(state),
		ladder_teams_ordered: selectors.orderedLadderCalculated(state, props),
		user_id: selectors.getUser(state).id,
		actual_round_id: actual_round.id,
		actual_round
	};
};

const mapDispatchToProps = {
	fetchLeague: actions.leagueDraft.showDraftLeague,
	fetchLadder: actions.leagueDraft.ladderDraftLeague
};

export const LeagueLadder = compose(
	withRoundsSelector,
	withCoachesBox,
	withDraftCore,
	connect(
		mapStateToProps,
		mapDispatchToProps
	),
)(LeagueLadderPage);

export default LeagueLadder;