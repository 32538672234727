// @flow
import * as React from "react";
import styled from "styled-components";
import _ from "lodash";
import { Exist, TeamUserAvatar } from "../../../../components";
import { below } from "../../../../assets/css/media";
import Keeper from "../../../../components/Icons/Keeper";
import colors from "../../../../assets/css/colors";
import { isAllTrue, isAnyTrue } from "../../../../helpers";
// const getBackgroundColor = ({ is_active, is_empty }) => {
// 	if(is_empty) {
// 		return '#FFF';
// 	}
//
// 	if(is_active) {
// 		return '#5EAFEA';
// 	}
//
// 	return '#F0F3F7';
// };


const DraftTeamListOrderItemWrapper = styled.div`
	z-index: 1;
	width: 178px;
	min-width: 178px;
	height: 60px;
	background: ${({ is_your_team }) => is_your_team ? "#E9F3FA" : "#F0F3F7"};
	${props => props.is_your_pick === true && `
		background-color: #5EAFEA;
		span, p, div {
			color: #fff;
		}
	`};
	transform: scale(${({ is_active }) => is_active ? 1.05 : 1});
	opacity: ${({ is_past_pick }) => is_past_pick ? 0.5 : 1};
	border: ${({ is_your_team }) => is_your_team ? "1px #198DE1 solid" : "1px #CAD2D8 solid"};
	${props => props.is_empty && `
		border: none;
	`}
	box-sizing: border-box;
	.turn-number {
		color: ${({ is_empty, is_your_pick }) => is_empty || is_your_pick ? "#fff": "#1D4073"};
		padding: 4px 5px 3px;
		min-width: 20px;
		text-align: center;
		position: absolute;
		left: 0;
		top: 20px;
		font-size: ${({ is_active }) => is_active ? "18px" : "18px"};
	}
	${below.phone`
		width: 127px;
		height: 52px;
	`}

`;
const DraftOrderItemContent = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	align-items: center;
	position: relative;
	top: 10px;
	left: 35px;
`;
const DraftNameItemsWrap = styled.div`
	text-align: left;
`;

const TeamName = styled.div`
	color: ${({ is_active, is_your_pick }) =>  is_your_pick ?  "#fff" : "#1D4073"};
	font-size: 12px;
	font-weight: 700;
	white-space: nowrap;
	margin: 0 4px 2px;
	overflow: hidden;
	text-overflow: ellipsis;
	font-weight: bold;
	line-height: 14px;
	text-overflow: ellipsis;
	max-width: 120px;
`;

const FirstName = styled.div`
	color: ${({ is_active }) => is_active ?  "#1D4073" : "#1D4073"};
	font-size: 12px;
	white-space: nowrap;
	margin: 0 4px;
	overflow: hidden;
	text-overflow: ellipsis;
	font-family: SourceSansPro;
	font-weight: 400px;
`;

const KeeperName = styled.div`
	color: ${colors.primary.darkGrey};
	font-size: 10px;
	margin: 0 4px;
	p	{
		white-space: nowrap;
		
		overflow: hidden;
		text-overflow: ellipsis;
		font-family: SourceSansPro;
		font-weight: 400px;
	}
	
	display: flex;
	align-items: center;
	svg{
		margin-left: 3px;
	}
`;


const TeamUserAvatarStyled = styled(TeamUserAvatar)`
	height: 40px;
	width: 40px;
	border-radius: 100%;
	object-fit: cover;
	margin: 0 auto 0;
	overflow: hidden;
	padding-bottom: 0;
	
`;

const TeamNamePlaceholder = styled.div`
	background: #FFF;
	height: 12px;
	width: 67px;
	margin: 0 auto 5px;
`;

const UserNamePlaceholder = styled.div`
	background: #FFF;
	height: 12px;
	width: 59px;
`;

const UserAvatarPlaceholder = styled.div`
	background: #FFF;
	height: 40px;
	width: 40px;
	border-radius: 100%;
	margin: 0 5px;
`;

type Props = {
	pick_number:number,
	is_your_team: boolean,
	team_name?: string,
	first_name?: string,
	is_your_pick?: boolean,
	user_id?: number,
	this_is_you?:boolean,
	is_past_pick?:boolean,
	current_pick_number?:number,
	keeperPlayer?: Object,
	draftedPlayer?: Object,
	league: Object,
};

const renderTeamName = name => {
	if(name.length > 16){
		return name.substring(0, 14);
	}
	return name;
};


const DraftTeamListOrderItem = ({
	team_name = "",
	first_name = "",
	is_your_pick,
	user_id = 0,
	pick_number = 0,
	current_pick_number = 0,
	this_is_you,
	is_past_pick,
	is_your_team,
	keeperPlayer,
	draftedPlayer,
	league
}: Props) => {
	const is_active = _.eq(pick_number, current_pick_number);
	const isCommissionerAndPicked = isAllTrue([
		league.draft_type === "commissioner",
		pick_number < current_pick_number
	]);

	const isEmptyBool = !(isAllTrue([
		Boolean(user_id), 
		Boolean(team_name), 
		Boolean(first_name)
	]));
	let is_empty = isEmptyBool;
	if(league.draft_type === "commissioner"){
		is_empty = isAnyTrue([isEmptyBool, !isCommissionerAndPicked]);
	} 

	const user_avatar_component = isAllTrue([
		Boolean(user_id),
		!is_empty
	])  ? (
			<TeamUserAvatarStyled user_id={user_id} no_hover/>
		) : <UserAvatarPlaceholder />;

	const team_name_component = isAllTrue([
		Boolean(team_name),
		!is_empty
	])  ? (
			<TeamName is_active={is_active}>
				{renderTeamName(team_name)}
			</TeamName>
		) : <TeamNamePlaceholder />;

	const user_name_component = isAllTrue([
		Boolean(first_name),
		!is_empty
	])  ? (
			<FirstName is_active={is_active} >{first_name}</FirstName>
		) : <UserNamePlaceholder />;

	const keeper_player_component = keeperPlayer ? 
		(
			<KeeperName is_active={is_active}>
				<p>{keeperPlayer.first_name} {keeperPlayer.last_name}</p>
				<Keeper /> 
			</KeeperName>
		)
		: null;
	const DraftedPlayerComponent = () => {
		const firstname = _.get(draftedPlayer, 'first_name');
		const lastname = _.get(draftedPlayer, 'last_name');
		return <Exist when={Boolean(draftedPlayer)}>
			<KeeperName is_active={is_active}>
				<p>{firstname} {lastname}</p>
			</KeeperName>
		</Exist>;
	};

	return (
		<DraftTeamListOrderItemWrapper
			is_empty={is_empty}
			is_active={is_active}
			is_past_pick={is_past_pick}
			is_your_team={is_your_team}
			is_your_pick={is_your_pick}
		>
			<span className="turn-number">{ pick_number }.</span>
			<DraftOrderItemContent>
				{ user_avatar_component }
				<DraftNameItemsWrap>
					{ team_name_component }
					{ user_name_component }
					{ keeper_player_component !== null ?
						keeper_player_component
						: <DraftedPlayerComponent />
					}
				</DraftNameItemsWrap>
			</DraftOrderItemContent>
		
			
			
		
			
		</DraftTeamListOrderItemWrapper>
	);
};

export default DraftTeamListOrderItem;