// @flow
import styled from "styled-components";
import above from "../../assets/css/media";

export const OneColumnLayout = styled.div`
	width: 100%;
	max-width: ${({ width }) => width ? width : "1280px"};
	margin: 50px auto 50px;
	box-sizing: border-box;
	${above.tablet`
		padding: 0 20px;
	`}
`;

OneColumnLayout.displayName = "OneColumnLayout";

export default OneColumnLayout;