import styled, {css} from "styled-components";
import {
	BarItem,
	Tabs,
	TabItem,
	TdStat,
	TdYourTeamExtended,
	TrRow,
	SwitchButton
} from "../../../../components";
import Table, {  Td } from "../../../../components/TableX";
import { below } from "../../../../assets/css/media";
import colors from "../../../../assets/css/colors";



export const MobileWrapper = styled.div`
	display: flex;
	width: 100%;
	.hidden{
		display: none;
	}
`;

export const SelectedContentWrap = styled.div`
	width: 100%;
`;


export const StyledBarItem = styled(BarItem)`
	${below.phone`
		flex-direction: column;
	`}
`;

export const StyledTabs = styled(Tabs)`

	justify-content: space-evenly;
	
`;
export const StyledTab = styled(TabItem)`
	justify-content: center;
	
	${below.phone`
		width: 25%;
	`}

`;

export const DraftedPlayerPickNum = styled.p`
	color: #1D4073;
	font-size: 12px;
	margin-bottom: 5px;
	font-family: SourceSansPro;
	font-weight: 600;

`;

export const TdShowAvatar = styled(TdStat)`
	${below.phone`
		.player-avatar {
			display: inline;
		}
		&.player-info-stat{
			padding-left: 0;
		}
	`};
`;


// const ThIgnoreStyled = styled(ThIgnore)`
// 	text-align: center !important;
// 	min-width: 190px;
// 	width: 160px;
// `;
export const PlayerTableContainer = styled.div`
	overflow-x: auto;
    width: 100%;
    overflow-y: hidden;
	.align-center .column-name{
		text-align: center;
	}
`;

export const FcLink = styled.a`
	color: ${colors.coach};
	cursor: pointer;
`;

export const DraftedPlayerPickUserName = styled.p`
	color: #1D4073;
	font-size: 10px;
	font-family: SourceSansPro;
	font-weight: 400;
	width: 75px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	${below.phone`
		width: 45px;
	`}
`;

export const TdButtons = styled(Td)`
	width: 4%;
`;

export const TdDraftedPlayerInfo = styled(TdYourTeamExtended)`
	width: 8%;

	${({ is_compressed }) => is_compressed && css`
		white-space: nowrap;
		text-align: left;
		width: 19%;

		${DraftedPlayerPickNum},
		${DraftedPlayerPickUserName} {
			display: inline-block;
			vertical-align: middle;
			width: 40%;
			text-align: center;
			font-size: 14px;
			margin: 0;
		}
	`}
	${below.phone`
		width: 40px;
	`}
`;

export const TdKeptBy = styled(Td)`
	min-width: 115px;
`;

export const KeptByContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
	font-family: SourceSansPro;
	font-size: 14px;
	color: ${colors.primary.primary};
	font-weight: 700;
	height: 40px;
	span{
		font-size: 12px;
		font-weight: 400;
	}
`;

export const TdPlayerInfo = styled(TdStat)`
	width: 25%;
	${props => props.drafted && `
		width: 35%;
	`}
	${({ coachSelectValue }) => {
		
		switch (coachSelectValue) {
			case "basic":
				return "width: 45%";
			case "custom-stats":
				return "width: 21%";
			case "coaches-choice":
				return "width: 30%";
			case "consistency":
				return "width: 27%";
			case ["opposition", "venues"].includes(coachSelectValue):
				return "width: 26%";
			case "history":
				return "width: 25%";
			default:
				return "width: 35%";
		}
	}}
    ${({drafted}) => {
		if(!drafted){
			return "width: 25%";
		}
	}}
`;

export const LiveDraftTable = styled(Table)`
	.align-center{
		.column-name{
			text-align: center;
		}
	}
	${below.tablet`
		& td,
		& th {
			position: relative;
			display: table-cell;
		}
	
		.last-right-th{
			position: sticky;
			right: -2px;
			min-width: 50px;
			background: white;
			box-shadow: -3px 2px 30px -17px #333;
			z-index: 2;
			text-transform: capitalize;
			font-family: SourceSansPro;
			font-weight: 600;
			padding-bottom: 2px;
		}
		.last-right-td {
			position: sticky;
			right: -2px;
			min-width: 50px;
			background: white;
			box-shadow: -3px 2px 30px -17px #333;
			z-index: 10;
		}
		.absolute-group th{
			height: 59px;

		}
		.align-center th{
			height: 56px;
		}
		.absolute-group{
			.column-name{
				span{
					text-transform: capitalize;
					font-family: SourceSansPro;
					font-weight: 600;
					${below.phone`
						min-width: 200px;
						text-align: center;
					`}
				}
			}
		}
	`};
`;

// const TableFixed = styled(Table)`
// 	table-layout: fixed;
	
// `;

export const TrRowDrafted = styled(TrRow)`
	background: transparent !important;

	${Td} {
		background: transparent !important;
	}

	${TdPlayerInfo} {
		.player-avatar,
		.player-details-info {
			opacity: .5;
		}
	}
	${below.phone`
		width: calc(100vw - 10px)
	`}
`;

// const DraftedPlayersList = styled.div`
// 	background: #F0F3F7;
// 	overflow: auto;
// 	max-height: 400px;
// 	padding: 0;
// 	border-bottom: 2px solid #CAD2D8;

// 	${TrRowDrafted}:last-child {
// 		border-bottom: none;

// 		td {
// 			border-bottom: none;
// 		}
// 	}

// 	${TableHead} {
// 		tr {
// 			border: none;
// 		}

// 		th {
// 			padding: 0;
// 			height: 0;
// 			overflow:hidden;
// 			border: none;

// 			& > * {
// 				overflow: hidden;
// 				height: 0;
// 				position: absolute;
// 				top: -9999999px;
// 			}
// 		}
// 	}
// `;

export const AbsoluteDiv = styled.div`
	position: sticky;
	left: 0;
	background-color: ${({ is_even }) => is_even ? "#f8f8f8" : "#fff"};
	z-index: 2;
	${below.phone`
		max-width: 210px;
		box-shadow: 10px 0 14px -14px;
		.player-names{
			max-width: 70px;
		}
		input{
			height: 40px;
		}
	`}
`;

export const DraftButtonWrapper = styled.div`
	width: 40px;
	height: 40px;

	font-size: 10px;
	font-family: SourceSansPro;
	font-weight: 600;
	color: ${colors.primary.primary};
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
	border: none;
	background: none;
	button{
		display: flex;
    padding: 0;
    width: 30px;
    margin: 0;
    align-items: center;
    justify-content: center;
		svg{
			height: 30px;
			width: 30px;
		}
	}
`;

export const PreDraftSwitchButton = styled(SwitchButton)`
	height: 20px;
	width: ${({ is_compressed }) => is_compressed ? "26px;" : "20px;"};
	padding: 0;
	border-radius: 0;
	margin-top: ${({ is_compressed }) => is_compressed ? "2px;" : "" };
	svg{
		margin-top: ${({ is_compressed }) => is_compressed ? "0px;" : "2px;"};
		height: 20px;
		width: 20px;
		g {
			path{
				fill: #fff
			}
		}
	}
`;
export const LiveDraftSwitchButtonsWrap = styled.div`
	display:flex;
	margin-top: ${({ is_compressed }) => is_compressed ? "1px;" : ""};
	button{
		border-radius: 0px;
	}
`;

export const LiveStatusWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	svg{
		margin-left:10px;
		margin-bottom: 3px;
	}
`;

export const MoreButtonWrapper = styled.button`
	height: 100%;
	width: 100%;
	outline: none;
	border: none;
	padding: 0px;
	background: none;
	box-shadow: -8px 0px 35px -14px #333;
	svg{
		height: 20px;
		width: 20px;
	}
`;

export const CloseButtonWrapper = styled(MoreButtonWrapper)`
	height: 30px;
	box-shadow: 0;
	width: 30px;
	svg{
		height: 30px;
		width: 30px;
	}
`;
export const MobileActionsWrapper = styled.div`
	width: 190px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-evenly;
	position: absolute;
	top: 0px;
	height: 95%;
	right: 0px;
	background: white;
	box-shadow: -5px 5px 7px -7px #333;
	${PreDraftSwitchButton}{
		height: 30px;
		width: 30px;
	}
`;

export const DraftedDivAbs = styled.div`
	width: calc(100vw - 10px);
	${below.phone`
		width: unset;
	`}
`;