// @flow
import styled from "styled-components";
import { below } from "../../assets/css/media";
import { TradeInCell } from "../../components";
export const TradeContent = styled.div`
	background: #F8F8FA;
	margin-bottom: ${({ no_margin, compressed }) => no_margin || compressed ? 0 : "20px"};
	padding: ${({ compressed, empty }) => compressed 
		? "0"
		: empty
			? "20px 20px 22px"
			: "12px 20px 30px"};
	${below.tablet`
		padding: 5px 10px 15px;
		margin-bottom: 0;
		border-radius: 0;

		&:last-of-type {
			padding-bottom: 25px;
		}
	`};
	${TradeInCell}{
		.player-info{
			
		}
	}
`;

export default TradeContent;