// @flow
import * as React from "react";
import styled from "styled-components";
import _ from "lodash";
import colors from "../../assets/css/colors";
import type { TRound } from "../../modules/types";
import { below } from "../../assets/css/media";
import ArrowSolid from "../Icons/ArrowSolid";
import { isOffSeason } from "../../utils";
import RoundSelectorItem from "./RoundSelectorItem";

const Wrapper = styled.div`
	width: 100%;
	padding: 12px 0;
	margin: 0 0 20px 0;
	border-bottom: 2px solid ${colors.secondary.paleGrey};
`;
const Inner = styled.div`
	height: 36px;
	width: 100%;
	box-sizing: border-box;
	padding: 0 0;
	position: relative;

	display: flex;
	align-items: center;
`;
const Mask = styled.div`
	width: 100%;
	height: 36px;
	overflow-x: scroll;
	overflow-y: hidden;
	overflow: -moz-scrollbars-none;
	-ms-overflow-style: none;
	::-webkit-scrollbar {
		height: 0!important;
		width: 0!important;
		display: none!important;
	}
	flex: 1 0 0;
`;
const Track = styled.div`
	min-width: ${({ length })=> length * 36}px;
	height: 36px;
	white-space: nowrap;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	::-webkit-scrollbar {
		height: 0!important;
		width: 0!important;
		display: none!important;
	}
`;

const Arrow = styled(ArrowSolid)`
	height: 30px;
	width: 30px;
	color: ${colors.primary.primary};

	flex: 0 0 auto;

	cursor: pointer;
	${({ disabled })=> disabled && `
		cursor: default;
		color: ${colors.secondary.paleGrey};
	`};
`;
const Controls = styled.div`
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid #fff;
	margin-bottom: 20px;
	background-color: #fff;
	select {
		width: 160px;
		height: 40px;
		border-color: #CAD2D8;
		padding-left: 10px;
		color: #7F8A90;
		font-family: SourceSansPro;
		font-size: 12px;
		font-weight: 600;
		background-color: #fff;
		option {
			width: 190px;
			height: 40px;

		}
	}
	${below.phone`
		width: 100%;
		select{
			width: 100%;
		}
	`}
`;
const ControlRoundItem = ({ round_id, label, current_round, ...rest }) => (
	<option className={current_round === round_id ? "active" : ""} {...rest}>
		{label}
	</option>
);

type TScrollContainer = {
	querySelector: Function,
	clientWidth: number,
	scrollWidth: number,
	scrollLeft: number,
	addEventListener: Function,
	removeEventListener: Function
}

const getActiveChildHalfWayScrollPosition = (scrollContainer: TScrollContainer):number => {
	const child = scrollContainer.querySelector(".selected");

	if (child) {
		return child.offsetLeft
			- (scrollContainer.clientWidth / 2)
			+ (child.clientWidth / 2)
			- 30; /* account for the left arrow */
	}

	return 0;
};

type Props = {
	selected?: number,
	rounds: Array<TRound>,
	handleClick: Function,
	show_all_button?: boolean,
	round_aliases?: Object
}


export class RoundSelector extends React.Component<Props> {
	static defaultProps = {
		show_all_button: false,
		round_aliases: {}
	};
	constructor() {
		super();
		_.bindAll(this, [
			"setPositionToActiveRoundCentre",
			"setNextScrollPosition",
			"setPreviousScrollPosition",
			"onResize",
			"setRound",
			"onSelectChangeRound"
		]);
	}

	componentDidMount() {
		if (!this.onResizeDebounce) {
			this.onResizeDebounce = _.debounce(this.onResize, 300);
		}
		window.addEventListener("resize", this.onResizeDebounce);

	}

	componentDidUpdate(prev_props: Props) {
		if (prev_props.selected !== this.props.selected) {
			this.setPositionToActiveRoundCentre();
		}
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.onResizeDebounce);
	}

	roundsOverflowContainer: TScrollContainer;
	onResizeDebounce = null;

	onResize() {
		this.setPositionToActiveRoundCentre();
	}

	get next_round() {
		const { selected, show_all_button } = this.props;

		if (selected !== undefined) {
			if (show_all_button && selected === 0) {
				return this.first_round_id;
			}
			return selected + 1;
		}

		return undefined;
	}

	get prev_round() {
		const { selected, show_all_button } = this.props;

		if (selected !== undefined) {
			if (show_all_button && selected === this.first_round_id) {
				return 0;
			}
			return selected - 1;
		}

		return undefined;
	}

	get first_round_id() {
		const { rounds } = this.props;

		return _.get(_.first(rounds), "id", 0);
	}

	get last_round_id() {
		const { rounds } = this.props;

		return _.get(_.last(rounds), "id", 0);
	}

	inRange(value?: number) {
		const { rounds, show_all_button } = this.props;
		let is_all = value === 0 && show_all_button;

		const enabled_round = _.find(
			rounds,
			({ disabled = false, id }) => !disabled && id === value
		);


		return value !== undefined && (enabled_round !== undefined || is_all);
	}

	setNextScrollPosition() {
		const next = this.next_round;

		if (next !== undefined && this.inRange(next)) {
			this.setRound(next);
		}
	}

	setPreviousScrollPosition() {
		const prev = this.prev_round;

		if (prev !== undefined && this.inRange(prev)) {
			this.setRound(prev);
		}
	}

	setPositionToActiveRoundCentre() {
		if (this.roundsOverflowContainer) {
			const scroll_offset = getActiveChildHalfWayScrollPosition(
				this.roundsOverflowContainer
			);
			this.roundsOverflowContainer.scrollLeft = scroll_offset;
		}
	}

	setRound(id: number) {
		const { handleClick } = this.props;

		handleClick(id, this.setPositionToActiveRoundCentre);
	}
	onSelectChangeRound(e: any) {
		const { handleClick } = this.props;
		const id = e.target.value;
		handleClick(Number(id));
	}

	render() {
		const { rounds, selected, round_aliases = {}, show_all_button } = this.props;
		const first_round_id = show_all_button ? 0 : _.get(rounds, [0,  "id"], 0);
		const selected_index = _.findIndex(rounds, function(r) {
			return r.id === selected;
		});
		if (_.isEmpty(rounds)) {
			return null;
		}

		const length = rounds.length + (show_all_button ? 1 : 0);
		if(length){
			return(
				<Controls>
					<select 
						onChange={this.onSelectChangeRound} 
						value={selected} >
						{isOffSeason() && <ControlRoundItem
							key={0}
							round_id={0}
							id={`pick-item-control-${0}`}
							current_round={selected}
							value={0}
							label={"Overall"}
						> Overall</ControlRoundItem>}
						{rounds.map(round => {
							if(round.label){
								return (
									<ControlRoundItem
										key={round.id}
										round_id={round.id}
										id={`pick-item-control-${round.id}`}
										current_round={selected}
										value={round.id}
										label={"All Rounds"}
									> {round.label}</ControlRoundItem>);
							}
							return <ControlRoundItem
								key={round.id}
								round_id={round.id}
								id={`pick-item-control-${round.id}`}
								current_round={selected}
								value={round.id}
								label={"Round " + round.id}
							> {round.id}</ControlRoundItem>;
						})}
					</select>
				</Controls>
			);
		}
		return <Wrapper>
			<Inner>
				<Arrow
					direction="left"
					onClick={this.setPreviousScrollPosition}
					disabled={!this.inRange(this.prev_round)}
				/>
				<Mask
					innerRef={x => this.roundsOverflowContainer = x}
				>
					<Track length={length}>
						{show_all_button && (
							<RoundSelectorItem
								round_status={"active"}
								key={0}
								is_first={true}
								selected_round_index={0}
								round_number={0}
								selected_round={selected}
								handleClick={this.setRound}
								round_alias={"All"}
								disabled={false}
							/>
						)}
						{rounds.map(round => (
							<RoundSelectorItem
								round_status={round.status}
								key={round.id}
								is_first={first_round_id === round.id}
								selected_round_index={selected_index + (show_all_button ? 1 : 0)}
								round_number={round.id}
								selected_round={selected}
								handleClick={this.setRound}
								round_alias={round_aliases[round.id]}
								disabled={round.disabled || false}
							/>
						))}
					</Track>
				</Mask>
				<Arrow
					direction="right"
					onClick={this.setNextScrollPosition}
					disabled={!this.inRange(this.next_round)}
				/>
			</Inner>
		</Wrapper>;
	}
}


export default RoundSelector;