// @flow
import * as React from "react";
import styled from "styled-components";
import { below } from "../../assets/css/media";

import Caution from "../Icons/Caution";

import { ModalContainer, ButtonPrimary } from "../../components";
import ButtonAlert from "../ButtonAlert";
import colors from "../../assets/css/colors";

const ButtonCancel = styled(ButtonPrimary)`
	background: transparent;
	color: ${colors.buttons.cancel};
	border-color: ${colors.buttons.cancel};
`;

const ButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	margin: 0 1em;
	padding: 40px 0;

	button {
		min-width: 200px;
		margin: 0 10px;
		${below.desktop`
			min-width: 85px;
		`}
	}
`;

const Text = styled.div`
	font-family: 'SourceSansPro';
	font-size: 14px;
	line-height: 1.43;
	margin: 0 100px;
	${below.tablet`
		margin: 0 20px;
	`};
`;

type Props = {
	closeClick: Function,
	confirmClick: Function,
	cancelClick: Function,
	header_text: React.Node,
	body_text?: React.Node,
	confirm_button_text?: string,
	cancel_button_text?: string,
	warning?: boolean,
};

export const ConfirmationModal = ({
	closeClick,
	confirmClick,
	cancelClick,
	header_text,
	body_text,
	confirm_button_text,
	cancel_button_text,
	warning = false,
}: Props) => {
	const MainButton = warning ? ButtonAlert : ButtonPrimary;
	

	return <ModalContainer 
		warning={warning} 
		header_text={header_text} 
		onClick={closeClick} 
		top_icon={<Caution />}
	>
		<Text>{body_text}</Text>
		<ButtonWrapper>
			<ButtonCancel onClick={cancelClick}>
				{cancel_button_text}
			</ButtonCancel>
			<MainButton onClick={confirmClick}>
				{confirm_button_text}
			</MainButton>
		</ButtonWrapper>
	</ModalContainer>;
};

ConfirmationModal.defaultProps = {
	confirm_button_text: "Confirm",
	cancel_button_text: "Cancel"
};

export default ConfirmationModal;