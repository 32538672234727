// @flow
import moment from "moment/moment";
import React from "react";
import styled from "styled-components";
import { InputItem, Select } from "../Form";

const DobSelectWrap = styled.div`
    display:flex;
    justify-content: space-between;
    > input, div {
        margin:0;
        width: 30%
    }
`;

type IDobSelect = {
    handleChange:Function,
    dob_day: string,
    dob_month: string,
    dob_year: string
}

export const DobSelect = ({
	handleChange,
	dob_day,
	dob_month,
	dob_year
}:IDobSelect) => {
	return <React.Fragment>
		<DobSelectWrap>
			<InputItem
				name="dob_day"
				placeholder="Day"
				inputMode="numeric"
				pattern="[0-9]*"
				type="text" 
				required={true}
				onChange={handleChange}
				value={dob_day}
			/>
			<Select 
				name="dob_month"
				onChange={handleChange}
				value={dob_month}
				required={true}>
				<option value="">Month</option>
				{moment.monthsShort().map(
					(month,index) => 
						<option key={month} value={index+1}>{month}</option>
				)
				}
			</Select>
			<InputItem
				name="dob_year"
				placeholder="Year"
				inputMode="numeric"
				pattern="[0-9]{4}"
				type="text" 
				required={true}
				onChange={handleChange}
				value={dob_year}
			/>
		</DobSelectWrap>
	</React.Fragment>;
};