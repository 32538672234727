// @flow
import styled from "styled-components";

import colors from "../../assets/css/colors";
import { above } from "../../assets/css/media";

export const PageSubTitle = styled.h2`
	color: ${colors.primary.primary};
	font-size: 16px;
	font-weight: 400;
	line-height: 1.3;

	${above.phone`
		font-size: 20px;
	`}

	${above.tablet`
		font-size: 24px;
	`}
`;

export default PageSubTitle;