// @flow
import * as React from "react";
import styled, { css } from "styled-components";
import * as _ from "lodash";
import colors from "../../assets/css/colors";
import { currencyFormat } from "../../utils";

const isZero = cost => {
	if (_.isNumber(cost)) {
		// $FlowFixMe - checked if is a number
		return Math.abs(cost) < 500;
	}
	return cost === "-";
};

const PlayerCostStyled = styled.p`
	font-size: 12px;
	color: #7F8A90;
	font-weight: 400;
	margin-bottom: -3px;
`;
const PlayerCostDiffStyled = styled.p`
	background: ${({ is_positive, is_even }) => 
		is_even ? colors.secondary.accentGrey
			: is_positive 
				? colors.success 
				: colors.warning};

	color: #fff;
	font-size: 10px;
	height: 14px;
	font-weight: 700;
	min-width: 30px;
	padding: 2px 2px 0 2px;
	box-sizing: border-box;
	border-radius: 2px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 2px;
	white-space: nowrap;

	${({ is_bracketed }) => is_bracketed && css`
		background: unset;
		color: ${({ is_positive, is_even }) => 
		is_even ? colors.secondary.accentGrey
			: is_positive 
				? colors.success 
				: colors.warning};
		&:before {
			content: '(';
		}
		&:after {
			content: ')';
		}
	`}
`;
type Props = {
	cost: number | string,
	is_diff?: boolean,
	is_bracketed?: boolean,
}
export const PlayerCost = ({ cost, is_diff, is_bracketed = false }: Props) => {
	if (isZero(cost)) {
		return <PlayerCostDiffStyled is_even>
			HOLD
		</PlayerCostDiffStyled>;
	}
	return is_diff ? (
		<PlayerCostDiffStyled 
			is_bracketed={is_bracketed} 
			// $FlowFixMe - already checking if number
			is_positive={_.isNumber(cost) && cost >= 0}
		>
			{currencyFormat({ input: cost, currency: "", show_sign: true })}
		</PlayerCostDiffStyled>
	) : (
		<PlayerCostStyled>
			{currencyFormat({ input: cost })}
		</PlayerCostStyled>
	);
};

export default PlayerCost;