// @flow
import styled, { css } from "styled-components";

export const FieldRowLabel = styled.div`
	width: ${({ is_draft }) => is_draft ?  "30px" : "18px"};
	${({ is_draft }) => is_draft ?  css`
		min-height: 150px;
		margin: 0 10px;
	` : css`
		height: 250px
	`};
	box-sizing: border-box;
	margin-left: 5px;
	@media screen and (max-width: 1240px) {
		width: 0px;
	}
	> div {
		text-transform: uppercase;
		color: white;
		//opacity: 0.6;
		//text-shadow: 0px 0px 3px #00000088; /* Make the text visible above white lines on field*/
		letter-spacing: .2px;
		transform: rotate(-90deg);
		box-sizing: border-box;
		${({ is_draft }) => is_draft ?  "min-height: 150px;" : "height: 250px"};
		width: ${({ is_draft }) => is_draft ?  "30px" : "18px"};
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
	}
`;

export default FieldRowLabel;