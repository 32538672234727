// @flow
import * as React from "react";
import styled from "styled-components";
import colors from "../../assets/css/colors";

const MessageText = styled.h4`
	font-size: 15px;
	line-height: 1.5;
	font-weight: bold;
	text-align: center;
	color: ${colors.primary.darkGrey};
	margin-bottom: 20px;
`;

const Wrapper = styled.div`
	background: white;
	padding: 30px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

type Props = {
	league_id: number
}

const NoFixtureRoundMessage = ({ league_id }: Props) => (
	<Wrapper>
		<MessageText>Fixtures are not yet determined</MessageText>
	</Wrapper>
);

export default NoFixtureRoundMessage;