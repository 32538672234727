// @flow
import { call, put, takeLatest, select } from 'redux-saga/effects';
import * as actions from '../../actions';

import { getFavouritesLastFetched } from '../../selectors';
import { getRounds } from '../../selectors/rounds';


const size = arr => arr.length;
const first = arr => arr[0];

/**
 *
 * @param {*} last_fetch_date_string Date converted into a string at the time of successful fetch
 * @param {*} seconds Seconds until data should be invalidated
 */
function isInvalid(last_fetch_date_string: ?string, seconds: number) {
	const milliseconds = seconds * 1000;
	const last_fetch_date = last_fetch_date_string && new Date(last_fetch_date_string);
	const now = new Date();

	return last_fetch_date ? now - last_fetch_date > milliseconds : true;
}

export const createSagas = (API: Object): Object => {
	function* fetchPlayers() {
		try {
			let result = yield call(API.players.fetch);
			const rounds =yield select(getRounds);
			const actualRound = rounds.find(round => round.status === "playing" 
				|| round.status === "scheduled");
			if(actualRound && actualRound.id === 0){
				result = result.map(player => ({
					...player,
					is_bye: 0
				}));
			}
			yield put(actions.fetchPlayersSuccess(result));
		}
		catch (e) {
			if (process.env.NODE_ENV !== 'test') {
				console.error(e);
			}
			yield put(actions.fetchPlayersFailed(e.message));
		}
	}

	function* fetchCoachPlayers() {
		try {
			const result = yield call(API.players.coach);
			yield put(actions.fetchCoachPlayersSuccess(result));
		}
		catch (error) {
			yield put(actions.fetchCoachPlayersFailed(error.message));
		}
	}

	function* fetchPastYearPlayers({ payload: year }) {
		try {
			const result = yield call(API.players.fetch_past_year, { year });
			yield put(actions.fetchPastYearPlayersSuccess({ year, result }));
		}
		catch (e) {
			if (process.env.NODE_ENV !== 'test') {
				console.error(e);
			}
			yield put(actions.fetchPastYearPlayersFailed(e.message));
		}
	}
	function* fetchPastYearPlayerMatches({ payload }) {
		try {
			const { year, player_id } = payload;
			const result = yield call(API.players.fetch_past_player_matches, { year, player_id });
			yield put(actions.fetchPastYearPlayerMatchesSuccess({ year, result, player_id }));
		}
		catch (err) {
			console.log(err);
			yield put(actions.fetchPastYearPlayerMatchesFailed(err.message));
		}
	}

	function* fetchPlayerMatches({ payload: player_id }) {
		try {
			const result = yield call(API.players.fetch_matches, { player_id });
			yield put(actions.fetchPlayerMatchesSuccess({ player_id, result }));
		}
		catch (e) {
			if (process.env.NODE_ENV !== 'test') {
				console.error(e);
			}
			yield put(actions.fetchPlayerMatchesFailed(e.message));
		}
	}

	function* fetchPlayersMatches({ payload: round_id }) {
		try {
			const matches = yield call(API.players.fetch_all_matches, { round_id });
			yield put(actions.fetchPlayersMatchesSuccess({ round_id, matches }));
		}
		catch (e) {
			if (process.env.NODE_ENV !== 'test') {
				console.error(e);
			}
			yield put(actions.fetchPlayersMatchesFailed(e.message));
		}
	}

	function* fetchFavourites() {
		try {
			const last_fetched = yield select(getFavouritesLastFetched);

			// is invalid after (60 * 30 =) 30 minutes and then will allow refetch when called
			if (isInvalid(last_fetched, 60 * 30)) {
				const { result, errors } = yield call(API.players.favourites);

				if (size(errors)) {
					throw new Error(first(errors));
				}

				yield put(actions.fetchFavouritesSuccess({
					favourites: result,
					current_date_string: new Date().toString()
				}));
			}
		}
		catch (error) {
			yield put(actions.fetchFavouritesFailed(error.message));
		}
	}

	function* postAddToFavourites({ payload: player_id }) {
		try {
			const { errors } = yield call(API.players.add_to_favourites, { player_id });

			if (size(errors)) {
				throw new Error(first(errors));
			}

			yield put(actions.postAddToFavouritesSuccess(player_id));
		}
		catch (error) {
			yield put(actions.postAddToFavouritesFailed(error.message));
		}
	}

	function* postRemoveFromFavourites({ payload: player_id }) {
		try {
			const { errors } = yield call(API.players.remove_from_favourites, { player_id });

			if (size(errors)) {
				throw new Error(first(errors));
			}

			yield put(actions.postRemoveFromFavouritesSuccess(player_id));
		}
		catch (error) {
			yield put(actions.postRemoveFromFavouritesFailed(error.message));
		}
	}
	
	function* fetchAllStats() {
		try {
			const result = yield call(API.players.fetch_all_stats);
			yield put(actions.fetchAllStatsSuccess(result));
		}
		catch (e) {
			if (process.env.NODE_ENV !== 'test') {
				console.error(e);
			}
			yield put(actions.fetchAllStatsFailed(e.message));
		}
	}

	function* fetchAllCustomStats({ payload: league_id }) {
		try {
			yield put(actions.fetchAllCustomStatsLoad());
			const result = yield call(API.players.fetch_all_custom_stats, { league_id });
			yield put(actions.fetchAllCustomStatsSuccess(result));
		}
		catch (e) {
			if (process.env.NODE_ENV !== 'test') {
				console.error(e);
			}
			yield put(actions.fetchAllCustomStatsFailed(e.message));
		}
	}

	function* fetchAllCoachCustomStats({ payload: league_id }) {
		try {
			const result = yield call(API.players.fetch_all_custom_coach_stats, { league_id });
			yield put(actions.fetchAllCoachCustomStatsSuccess(result));
		}
		catch (e) {
			if (process.env.NODE_ENV !== 'test') {
				console.error(e);
			}
			yield put(actions.fetchAllCoachCustomStatsFailed(e.message));
		}
	}

	function* fetchPlayerVenueStats() {
		try{
			const result = yield call(API.players.fetch_players_venue_stats);
			yield put(actions.fetchPlayerVenueStatsSuccess(result));
		}
		catch(e){
			if (process.env.NODE_ENV !== 'test') {
				console.error(e);
			}
			yield put(actions.fetchPlayerVenueStatsFailed(e.message));
		}
	}

	function* fetchPlayerVenueStatsDraft({ payload: league_id }) {
		try {
			const result = yield call(API.players.fetch_player_venue_stats_draft, { league_id });
			yield put(actions.fetchPlayerVenueStatsDraftSuccess(result));	
		} 
		catch (e) {
			if (process.env.NODE_ENV !== 'test') {
				console.error(e);
			}
			yield put(actions.fetchPlayerVenueStatsDraftFailed(e.message));
		}

	}

	function* fetchPlayerOppositionStats() {
		try {
			const result = yield call(API.players.fetch_players_opposition_stats);
			yield put(actions.fetchPlayerOppositionStatsSuccess(result));
		} 
		catch (e) {
			if (process.env.NODE_ENV !== 'test') {
				console.error(e);
			}
			yield put(actions.fetchPlayerOppositionStatsFailed(e.message));
		}
	}

	function* fetchPlayerOppositionStatsDraft({ payload: league_id }) {
		try {
			const result = 
				yield call(API.players.fetch_players_opposition_stats_draft, { league_id });
			yield put(actions.fetchPlayerOppositionStatsDraftSuccess(result));
		} 
		catch (e) {
			if (process.env.NODE_ENV !== 'test') {
				console.error(e);
			}
			yield put(actions.fetchPlayerOppositionStatsDraftFailed(e.message));
		}
	}

	function* watch() {
		yield takeLatest(actions.fetchPlayers, fetchPlayers);
		yield takeLatest(actions.fetchCoachPlayers, fetchCoachPlayers);
		yield takeLatest(actions.fetchPastYearPlayers, fetchPastYearPlayers);
		yield takeLatest(actions.fetchFavourites, fetchFavourites);
		yield takeLatest(actions.postAddToFavourites, postAddToFavourites);
		yield takeLatest(actions.postRemoveFromFavourites, postRemoveFromFavourites);
		yield takeLatest(actions.fetchPlayerMatches, fetchPlayerMatches);
		yield takeLatest(actions.fetchPlayersMatches, fetchPlayersMatches);
		yield takeLatest(actions.fetchAllStats, fetchAllStats);
		yield takeLatest(actions.fetchAllCustomStats, fetchAllCustomStats);
		yield takeLatest(actions.fetchAllCoachCustomStats, fetchAllCoachCustomStats);
		yield takeLatest(actions.fetchPastYearPlayerMatches, fetchPastYearPlayerMatches);
		yield takeLatest(actions.fetchPlayerVenueStats, fetchPlayerVenueStats);
		yield takeLatest(actions.fetchPlayerVenueStatsDraft, fetchPlayerVenueStatsDraft);
		yield takeLatest(actions.fetchPlayerOppositionStats, fetchPlayerOppositionStats);
		yield takeLatest(actions.fetchPlayerOppositionStatsDraft, fetchPlayerOppositionStatsDraft);

	}

	return {
		fetchPlayers,
		fetchFavourites,
		fetchPastYearPlayers,
		postAddToFavourites,
		postRemoveFromFavourites,
		fetchAllStats,
		fetchAllCustomStats,
		fetchAllCoachCustomStats,
		watch,
	};
};


export default createSagas;