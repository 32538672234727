// @flow
import React from "react";
import styled from "styled-components";
import { below } from "../../assets/css/media";
import { socialShare } from "../../utils/social_share/index";
import share_bg from "../../assets/images/share_bg.jpg";
import Close from "../Icons/Close";
import FacebookIcon from "../Icons/FacebookIcon";
import TwitterIcon from "../Icons/TwitterIcon";

const ShareBarContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	background: url(${share_bg}) 0 0 no-repeat;
	width: 100%;
	height: 60px;
	margin: 20px auto;
	position:relative;
	padding: 0 30px 0 20px;
	box-sizing: border-box;
	p {
		font-size: 14px;
		color: #ffffff;
		font-family: "SourceSansPro";
		strong {
			font-weight: bold;
		}
	}
	button {
		font-size: 1em;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 2px;
		border: 1px solid #CAD2D8;
		background: rgba(0,0,0,0);
		width: 41px;
		height: 41px;
		margin-left: 10px;
		cursor: pointer;
		${below.desktop`
			width: 30px;
			height: 30px;
		`}
	}
	
	> svg {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 10px;
		font-size: 1.25em;
		cursor:pointer;
	}
	svg:not(:root){
		overflow: visible !important;
	}
`;
const Facebook = styled.button`

`;
const Twitter = styled.button`
	svg{
		path{
			fill: white;
		}
	}
`;


type Props = {
	round_id: number,
	team_id: number,
	is_scored_complete: boolean
}

export const ShareBar = ({
	round_id,
	team_id,
	is_scored_complete
}: Props) => {
	const [is_hidden, handleHide] = React.useState(
		localStorage.getItem(`round_${round_id}_banner_hidden`)
	);
	if (is_hidden === "true") {
		return null;
	}

	if(!is_scored_complete){
		return null;
	}

	const share_data = {
		is_classic: true,
		team_id,
		round_id,
		share_type: "team"
	};

	const shareFb = () => {
		socialShare({
			...share_data,
			sc_type: "fb"
		});
	};


	const shareTw = () => {
		socialShare({
			...share_data,
			sc_type: "tw"
		});
	};

	const hideBanner = () => {
		localStorage.setItem(`round_${round_id}_banner_hidden`, "true");
		handleHide("true");
	};
	return (
		<ShareBarContainer>
			<p>Share your <strong>Round {round_id} team results</strong> with your friends</p>
			<Facebook onClick={shareFb}><FacebookIcon /></Facebook>
			<Twitter onClick={shareTw}><TwitterIcon /></Twitter>
			<Close color={"#fff"} onClick={hideBanner} />
		</ShareBarContainer>
	);
};

export default ShareBar;