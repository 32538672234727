// @flow
import _ from 'lodash';
import { createSelector } from 'reselect';

import { getSquadSizeArray } from '../../../helpers';

import getLeague from './getLeague';
import getLeagueDraftOrder from './getLeagueDraftOrder';
import getTeamsById from './getTeamsById';

const getDraftOrder = createSelector(
	getLeague,
	getLeagueDraftOrder,
	getTeamsById,
	(league: Object, ordered_teams: Array<number>, teams_by_id: Object) => {
		if(_.isEmpty(league) || _.isEmpty(ordered_teams) || _.isEmpty(teams_by_id)) {
			return [];
		}

		let pick_counter = 0;
		const { draft_order } = league,
			teams_reversed = ordered_teams.slice().reverse(),
			total_positions = _.sum(getSquadSizeArray(league)),
			is_snake = _.eq(draft_order, 'snake'),
			rounds_range = _.range(1, total_positions + 1),
			getTeamsArray = (is_snake, round_id) => {
				if(!is_snake) {
					return ordered_teams;
				}

				return round_id % 2 ? ordered_teams : teams_reversed;
			},
			createOrder = round_id => ([
				{ round_id, is_label: true },
				...(getTeamsArray(is_snake, round_id).map(team_id => ({
					...teams_by_id[team_id],
					pick: ++pick_counter,
				}))),
			]);

		return _.flatten(rounds_range.map(createOrder));
	}
);

export default getDraftOrder;