// @flow
import { createAction } from "redux-act";


import {
	TAFLiDAuthCode,
	TAFLiDLogin,
	TUFLogin,
	TAFLiDAuthCodeResponse,
	TAFLiDLinkAccount,
	TAFLiDCheckCredentials,
	TAFLiDCheckCredentialsResponse
} from "../types";
import * as leagueDraftUtil from "./leaguesDraft";
import * as uiMenuActions from "./uiMenu";
import * as teamsDraftUtil from "./teamsDraft";
import * as uiSelectedLeagueActions from "./uiSelectedLeague";
import * as uiGameSelectionActions from "./uiGameSelection";

export const teamsDraft = teamsDraftUtil;
export const leagueDraft = leagueDraftUtil;
export const fetchUser = createAction("Fetch User");
export const fetchUserSuccess = createAction("Fetch User Success");
export const fetchUserFailed = createAction("Fetch User Failed");
export const clearUserError = createAction("Clear User Error");

export const activateUser = createAction("Activate User");
export const activateUserSuccess = createAction("Activate User Success");
export const activateUserFailed = createAction("Activate User Failed");
export const clearUserActivateError = createAction("Clear User Activate Errors");

export const authCodeAfliD = createAction<TAFLiDAuthCode>("authCodeAfliDSaga Start");
export const authCodeSuccess = createAction<TAFLiDAuthCodeResponse>("authCodeAfliDSaga Start");

export const authLinkAccount = createAction<TAFLiDLinkAccount>("authLinkAccount Start");
export const authLinkAccountSuccess = createAction<TAFLiDAuthCodeResponse>(
	"authLinkAccountSuccess"
);
export const authLinkAccountFailed = createAction(
	"authLinkAccountFailed"
);

export const authCheckCredentials = createAction<TAFLiDCheckCredentials>(
	"authCheckCredentials Start"
);
export const authCheckCredentialsSuccess = createAction<TAFLiDCheckCredentialsResponse>(
	"authCheckCredentialsSuccess"
);
export const authCheckCredentialsFailed = createAction(
	"authCheckCredentialsFailed"
);

export const linkAccountLogin = createAction("Link Account Login");
export const linkAccountLoginSuccess = createAction("Link Account Login Success");
export const linkAccountLoginFailed = createAction("Link Account Login Failed");

export const linkNewAccount = createAction("Link New Account");
export const linkNewAccountSuccess = createAction("Link New Account Success");
export const linkNewAccountFailed = createAction("Link New Account Failed");

export const ultimateFootyRemoveLoadedLeagues = createAction("ultimateFootyRemoveLoadedLeagues");
export const ultimateFootyEmailCheck = createAction("Ultimate Footy Email Check");
export const ultimateFootyEmailCheckSuccess = createAction("UF Email Check Success");
export const ultimateFootyEmailCheckFailed = createAction("UF Email Check Failed");
export const ultimateFootyToggleIsMatched = createAction("ultimateFootyToggleIsMatched");


export const ultimateFootyEmailPasswordCheck = createAction("UF Email and PW Check");
export const ultimateFootyEmailPasswordCheckSuccess = createAction("UF Email and PW Check Success");
export const ultimateFootyEmailPasswordCheckFailed = createAction("UF Email and PW Check Failed");

export const ultimateFootyLinkAccount = createAction<TUFLogin>("UF Link Account");
export const ultimateFootyLinkAccountSuccess = createAction("UF Link Account Success");
export const ultimateFootyLinkAccountFailed = createAction("UF Link Account Failed");

export const wipeFetchedAccountData = createAction("Wipe Fetched Account Data");

export const loginAfliD = createAction<TAFLiDLogin>("loginAfliD Start");
export const loginAfliDSuccess = createAction("loginAfliD Success");
export const loginAfliDFailed = createAction("loginAfliD Failed");
export const login = createAction("Login Start");
export const loginToken = createAction("Login Token");
export const loginSuccess = createAction("Login Success");
export const loginFailed = createAction("Login Failed");
export const addLocalIdToken = createAction("addLocalIdToken");
export const emailCheck = createAction("Email Check");
export const emailCheckSuccess = createAction("Email Check Success");
export const emailCheckFailed = createAction("Email Check Failed");
export const aflAccountIdPresent = createAction("AFL Account ID Present");

export const clearLoginErrors = createAction("Clear Login Errors");

export const preregistration = createAction("Preregistration Start");
export const preregistrationSuccess = createAction("Preregistration Success");
export const preregistrationFailed = createAction("Preregistration  Failed");

export const logout = createAction("Logout Start");
export const logoutSuccess = createAction("Logout Success");
export const logoutFailed = createAction("Logout  Failed");
export const clearLogoutErrors = createAction("Clear Logout Errors");

export const loginFB = createAction("Login FB Start");
export const facebookConnect = createAction("Facebook Connect");
export const facebookConnectSuccess = createAction("Facebook Connect Success");
export const facebookConnectFailed = createAction("Facebook Connect Failed");
export const clearFacebookConnectErrors = createAction("Clear Facebook Connect Errors");

export const createUser = createAction("Create User");
export const aflIDCreateUser = createAction("Create User");

export const updateUser = createAction("Update User");
export const updateUserSuccess = createAction("Update User Success");
export const updateUserFailed = createAction("Update User Failed");
export const clearUpdateUserData = createAction("Clear Update User Data");

export const checkEmail = createAction("Check Email");
export const checkEmailSuccess = createAction("Check Email Success");
export const checkEmailFailed = createAction("Check Email Failed");
export const clearCheckEmailData = createAction("Clear Check Email Data");

export const subscribeUser = createAction("Subscribe User");
export const subscribeUserSuccess = createAction("Subscribe User Success");
export const subscribeUserFailed = createAction("Subscribe User Failed");
export const clearSubscribeUserErrors = createAction("Clear Subscribe User Errors");

export const fetchUserSubscription = createAction("Fetch User Subscription");
export const fetchUserSubscriptionSuccess = createAction("Fetch User Subscription Success");
export const fetchUserSubscriptionFailed = createAction("Fetch User Subscription Failed");

export const unsubscribeUser = createAction("Unsubscribe User");
export const unsubscribeUserSuccess = createAction("Unsubscribe User Success");
export const unsubscribeUserFailed = createAction("Unsubscribe User Failed");

export const deactivateUser = createAction("Deactivate User");
export const deactivateUserSuccess = createAction("Deactivate User Success");
export const deactivateUserFailed = createAction("Deactivate User Failed");

export const changePassword = createAction("Change Password");
export const changePasswordSuccess = createAction("Change Password Success");
export const changePasswordFailed = createAction("Change Password Failed");

export const forgotPassword = createAction("Close Hamburger Menu");
export const forgotPasswordSuccess = createAction("Forgot Password Success");
export const forgotPasswordFailed = createAction("Forgot Password Failed");

export const fetchCountries = createAction("Get Countries");
export const fetchCountriesSuccess = createAction("Get Countries Success");

export const contactUs = createAction("Contact Us");
export const contactUsSuccess = createAction("Contact Us");
export const contactUsFailed = createAction("Contact Us");
export const contactUsClear = createAction("Contact Us Clear");

export const uiMenu = uiMenuActions;
export const uiSelectedLeague = uiSelectedLeagueActions;
export const uiGameSelection = uiGameSelectionActions;

export const showModalForPlayerId = createAction("Show Modal With Player Info");
export const hideModalForPlayer = createAction("Hide Modal With Player Info");

export const fetchNews = createAction("Fetch News");
export const fetchNewsSuccess = createAction("Fetch News Success");

export const fetchFAQs = createAction("Fetch FAQs");
export const fetchFAQsSuccess = createAction("Fetch FAQs Success");

export const fetchUserHistory = createAction("fetchUserHistory");
export const fetchUserHistorySuccess = createAction("fetchUserHistorySuccess");
export const fetchUserHistoryFailed = createAction("fetchUserHistoryFailed");

export const globalRedirect = createAction("Global Redirect");
export const setDraftTab = createAction("Set Draft Tab");


export const updateUserTeamName = createAction();
export const updateUserTeamNameSuccess = createAction();
export const updateUserTeamNameError = createAction();
export const updateUserTeamNameReset = createAction();

export const appleIdLogin = createAction();

export const fetchPlayerNews = createAction("Fetch Player News");
export const fetchPlayerNewsSuccess = createAction("Fetch Player News Success");
export const fetchPLayerNewsFailed = createAction("Fetch Player News Failed");

export const setAflIdModalStatus = createAction();

export * from "./teamsClassic";
export * from "./leaguesClassic";
export * from "./coachesBox";
export * from "./cms";
export * from "./checksums";
export * from "./offSeason";
export * from "./rounds";
export * from "./squads";
export * from "./venues";
export * from "./positions";
export * from "./players";