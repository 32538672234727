// @flow
import React from "react";
import styled, { css } from "styled-components";
import * as _ from "lodash";

import type { 
	ById, 
	TCelebTeam, 
	TClassicTeam
} from "../../../../../modules/types";
import { TeamUserAvatar } from "../../../../../components";
import Plus from "../../../../../components/Icons/Plus";
import Tick from "../../../../../components/Icons/Tick";
import colors from "../../../../../assets/css/colors";
import { default_transition } from "../../../../../assets/css/vars";
import { getCelebName } from "../../../../../helpers/league";

const CelebIcon = styled.div`
	position: absolute;
	top: -6px;
	right: -5px;
	height: 24px;
	width: 24px;
	svg {
		height: 24px;
		width: 24px;
	}
	border-radius: 12px;
	background-color: ${({ is_active }) => is_active ? "#198DE1" : colors.secondary.paleGrey};
	${default_transition};
`;

const AvatarWrapper = styled.div`
	width: 60px;
	height: 60px;
	border-radius: 30px;
	border: 3px solid ${({ is_active }) => is_active ? "#4A90E2" : "white"};
	position: relative;
	box-sizing: border-box;
	${default_transition};
`;

const CelebrityWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	> p {
		width: 50px;
		text-align: center;
		color: ${colors.primary.primary};
		font-size: 10px;
		line-height: 13px;
		font-family: TitilliumUpright;
	}

	${({ disabled }) => !disabled && css`
		cursor: pointer;

		:hover {
			${AvatarWrapper} {
				border-color: ${({ is_active }) => is_active ? "#65a1e6" : "#e2e6e9"};
			}
			${CelebIcon} {
				background-color: ${({ is_active }) => is_active ? "#309ce8" : "#b6c1c9"};
			}
		}
	`};
`;

type Props = {
	celebrity: TCelebTeam,
	league_teams: ById<TClassicTeam>,
	addCelebrity: Function,
	removeFromLeague: Function,
	can_add: boolean,
	can_remove: boolean,
}

export class Celebrity extends React.PureComponent<Props> {
	constructor() {
		super();

		_.bindAll(this, [
			"handleClick"
		]);
	}
	get is_active() {
		const { league_teams, celebrity } = this.props;
		return _.has(league_teams, celebrity.id);
	}
	get team_details() {
		const { celebrity, league_teams } = this.props;
		return {
			...celebrity,
			...league_teams[celebrity.id]
		};
	}
	get is_enabled() {
		const { can_add, can_remove } = this.props;
		const { is_active } = this;
		return is_active ? can_remove : can_add;
	}

	handleClick() {
		const { 
			addCelebrity, 
			removeFromLeague, 
			celebrity,
		} = this.props;

		const id = _.get(celebrity, "id");
		if (id && this.is_enabled) {
			this.is_active 
				? removeFromLeague(id) 
				: addCelebrity(id);
		}
	}

	render() {
		const celebrity = this.team_details;
		return <CelebrityWrapper
			onClick={this.handleClick}
			is_active={this.is_active}
			disabled={!this.is_enabled}
		>
			<AvatarWrapper is_active={this.is_active}>
				<TeamUserAvatar 
					user_id={_.get(celebrity, "user_id")} 
					avatar_version={_.get(celebrity, "avatar_version")} 
				/>
				<CelebIcon is_active={this.is_active}>
					{this.is_active ? <Tick color="white" /> : <Plus color="white" />}
				</CelebIcon>
			</AvatarWrapper>
			<p>{getCelebName(celebrity)}</p>
		</CelebrityWrapper>;
	}
}

export default Celebrity;