// @flow
import styled from "styled-components";

export const PlayerWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
  width: 310px;
  height: 310px;

	border-radius: 5px;
	box-sizing: border-box;

`;