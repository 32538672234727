// @flow
import styled from "styled-components";

export const TradePlayerCost = styled.div`
	color: #7F8A90;
	font-size: 22px;
	font-weight: 900;
	line-height: 1.23;
	
	${({ margin, is_double }) => `margin-${margin}: ${is_double ? "3" : "1"}0px`};
`;

export default TradePlayerCost;