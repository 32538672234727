/* eslint max-len: 0 */
// @flow
import React from "react";
import SvgIconContainer from "../SvgIconContainer";

import colors from "../../assets/css/colors";

export default (
	{
		unit = "em",
		...rest
	}: Object = {}) => (
	<SvgIconContainer viewBox="0 0 24 24" color={colors.primary.accent} {...rest} unit={unit}>
		<path fill="#198DE1" d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0Zm.021 4.502-.182.002c-1.654.046-2.085.732-2.191 1.822l-.018.24-.01.251-.005.399c0 .336.033.67.1 1a.693.693 0 0 0-.095-.007c-.12 0-.277.119-.277.558 0 .44.265 1.05.51 1.05v.254c0 .42.167.82.464 1.116a.27.27 0 0 1 .083.225l-.056.727a.75.75 0 0 1-.473.617c-.974.367-2.343.922-3.278 1.618a2.11 2.11 0 0 0-.792 1.589.406.406 0 0 0 .406.436h6.25a3.889 3.889 0 1 0 1.188-4.949l-.003-.04a.27.27 0 0 1 .083-.224c.298-.295.466-.696.467-1.116v-.253c.245 0 .51-.61.51-1.049 0-.439-.157-.558-.277-.558a.693.693 0 0 0-.095.007c.067-.334.101-.675.1-1.016l-.003-.336a10.75 10.75 0 0 0-.004-.156l-.016-.287c-.067-.903-.295-1.276-.892-1.276a1.868 1.868 0 0 0-1.494-.644Zm3.89 6.927a3.182 3.182 0 1 1 0 6.364 3.182 3.182 0 0 1 0-6.364Zm.173 1.17a.342.342 0 0 0-.515.302v1.368h-1.368a.342.342 0 1 0 0 .684h1.368v1.368a.342.342 0 1 0 .684 0v-1.368h1.368a.342.342 0 1 0 0-.684h-1.368v-1.368a.342.342 0 0 0-.17-.301Z"/>
	</SvgIconContainer >
);