/* eslint max-len: 0 */
// @flow
import React from "react";
import SvgIconContainer from "../SvgIconContainer";

import colors from "../../assets/css/colors";

export default (
	{
		unit = "em",
		...rest
	}: Object = {}) => (
	<SvgIconContainer viewBox="0 0 24 24" color={colors.primary.accent} {...rest} unit={unit}>
		<title>Bracket</title>
		<g fillRule="nonzero">
			<path d="M10.6666667,4.08333333 L10.6666667,6.75 L14.6666667,6.75 L14.666,13.4163333 L17.333,13.4163333 L17.3333333,10.0833333 L28,10.0833333 L28,18.0833333 L17.3333333,18.0833333 L17.333,14.7493333 L14.666,14.7493333 L14.6666667,21.4166667 L10.6666667,21.4166667 L10.6666667,24.0833333 L0,24.0833333 L0,16.0833333 L10.6666667,16.0833333 L10.6666667,20.0833333 L13.333,20.0833333 L13.333,8.08333333 L10.6666667,8.08333333 L10.6666667,12.0833333 L0,12.0833333 L0,4.08333333 L10.6666667,4.08333333 Z"></path>
		</g>
	</SvgIconContainer >
);