// @flow
import React from "react";
import styled from "styled-components";

import { below } from "../../assets/css/media";
import { header_height } from "../../assets/css/vars";
import NavItem from "./NavItem";

const Container = styled.nav`
	height: ${header_height}px;
	${below.tablet`
		width: 100%;
		height: auto;
	`};
`;

const List = styled.ul`
	font-family: TitilliumUpright;
	height: ${header_height}px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;

	${below.tablet`
		width: 100%;
		flex-direction: column;
		height: auto;
	`};
`;


type Props = {
	closeSubModal?: Function,
	openSubModal?: Function,
	items: Array<{
		to: string,
		title: string,
		non_link?: boolean,
		show_league_switcher?: boolean,
		sub_align?: 'right' | 'left',
		sub_title?: string,
		sub_items?: Array<{
			to: string,
			title: string,
			external?: boolean,
			show_new?: boolean
		}>
	}>
}

const Nav = (props: Props) => (
	<Container>
		<List>
			{props.items.map(item => (
				<NavItem
					to={item.to}
					key={`${item.title}${item.to}`}
					title={item.title}
					non_link={item.non_link}
					show_league_switcher={item.show_league_switcher}
					sub_align={item.sub_align}
					sub_title={item.sub_title}
					sub_items={item.sub_items}
					closeSubModal={props.closeSubModal}
					openSubModal={props.openSubModal}
				>
					{item.title}
				</NavItem>
			))}

		</List>
	</Container>
);

Nav.defaultProps = {
	non_link: false
};

export default Nav;