// @flow
import styled from "styled-components";

import { below } from "../../assets/css/media";

export const PageContentWrapper = styled.div`
	width: 100%;
	max-width: ${({ width }) => width ? width : "1280px"};
	margin: 50px auto 50px;
	box-sizing: border-box;
	padding: 0 20px;

	${below.tablet`
		padding: 0 10px;
	`}

	${below.phone`
		padding: 0 5px;
		margin: 20px auto 20px;
	`};
`;

PageContentWrapper.displayName = "PageContentWrapper";

export default PageContentWrapper;