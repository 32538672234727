/* eslint max-len: 0 */
// @flow
import React from "react";
import SvgIconContainer from "../SvgIconContainer";

export default (
	{
		unit = "em",
		...rest
	}: Object = {}) => (
	<SvgIconContainer viewBox="0 0 24 24" {...rest} unit={unit}>
		<title>Search</title>
		<path d="M19.942 17.807l-3.78-3.4c1.996-2.905 1.45-6.851-1.26-9.105-2.71-2.253-6.69-2.071-9.182.421s-2.675 6.472-.421 9.182c2.253 2.71 6.2 3.256 9.105 1.26l3.4 3.781.113.113c.624.54 1.565.481 2.117-.132s.511-1.556-.091-2.119zm-14.683-7.25c0-2.924 2.37-5.294 5.294-5.294 2.924 0 5.294 2.37 5.294 5.294 0 2.924-2.37 5.294-5.294 5.294-2.923-.003-5.291-2.372-5.294-5.294z" />
	</SvgIconContainer>
);