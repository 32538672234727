// @flow
import * as React from "react";
import styled from "styled-components";
import { SwitchToggle } from "..";
import colors from "../../assets/css/colors";

const FlipTogglerWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
  	-webkit-user-select: none; /* Chrome/Safari */        
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* IE10+ */
	margin-left: 30px;
	p {
		padding-top: 5px;
		margin-left: 8px;
	}
  	.toggle-state-text {
  		color: white;
  		font-size: 15px;
  		line-height: 1.2;
  		text-align: right;
  		text-transform: uppercase;
  		padding-top: 5px;
		cursor: pointer;
  	}
  	
  	.flip-cards-toggle input + label {
		background: ${({ checked }) => checked 
		? colors.primary.accent 
		: colors.secondary.paleGrey};
		&:before {
			background-color: white;
			transition: transform 0.2s linear;
		}
  	}
	
	div {
		text-align: center;
		margin-right: 0;
	}
`;

type Props = {
	onChange: Function,
	checked: boolean
}

export const FlipToggle = ({ onChange, checked }: Props) => (
	<FlipTogglerWrapper checked={checked}
		className="flip-cards-toggle-wrapper"
	>
		<SwitchToggle
			onChange={onChange}
			id="flip_cards"
			name="flip_cards"
			className="flip-cards-toggle"
		/>
		<p>
			<label className="toggle-state-text" htmlFor="flip_cards">
				Flip all cards
			</label>
		</p>
	</FlipTogglerWrapper>
);

export default FlipToggle;