// @flow
import * as React from "react";
import styled from "styled-components";
import _ from "lodash";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
// import moment from "moment";
import * as selectors from "../../../modules/selectors";

import { isMobile } from "../../../utils";
import {
	AdsContainer,
	Aside,
	ConfirmationModal,
	DraftFilters,
	DraftTableHeader,
	DraftTitleContainer,
	Footer,
	IgnoreCheckbox,
	ManageKeepers,
	PageContentWrapper,
	PageTitle,
	Preloader,
	FantasyCoachModal,
	TableHead,
	ThIgnore,
	ThStat,
	TwoColumnLayout,
	WidgetNews,
	withDraftCore,
	withDraftPlayerModal,
	Exist,
	TextModal,
	FinaliseOrUnlockLeague
	// GoToApp,
} from "../../../components";

import type { TCellInfo } from "../../../helpers/tables";
import { renderTableHeaderCell } from "../../../helpers/tables";
import { teamsDraft as actions, fetchUser } from "../../../modules/actions";
import { leagueDraft as actionsLeagueDraft } from "../../../modules/actions";
import ArrowUpSmall from "../../../components/Icons/ArrowUpSmall";
import ArrowDownSmall from "../../../components/Icons/ArrowDownSmall";

import { Td, Tr } from "../../../components/TableX";
import Ad from "../../../components/Ad";
import DraftOrderWidget from "../../../components/DraftOrderWidget";
import type { TDraftPassedProps } from "../../../components/Draft/withDraftCore";
import type { TRootStore } from "../../../modules/types/store";
import type {TPlayer, TDraftUserTeam} from "../../../modules/types";
import withCoachesBox from "../../../components/utils/withCoachesBox";
import withConfirmModal from "../../../components/utils/withConfirmModal";
import More from "../../../components/Icons/More";
import Close from "../../../components/Icons/Close";
import Warning from "../../../components/Icons/Warning";
import { getIsRegenerated, isAllTrue } from "../../../helpers";
import { LoadingStatus } from "../../../modules/constants";
import ErrorModal from "../../coachSubscription/errorModal";
import TopBar from "./preDraft/topBar";
// import ThStatIgnored from './preDraft/thStatIgnored';
import SaveButton from "./preDraft/saveButton";
import ResetButton from "./preDraft/resetButton";
import ControlButtonsContainer from "./preDraft/controlButtonsContainer";
import TeamViewWidget from "./liveDraft/teamViewWidget";
import {
	StyledTabs, 
	StyledTab, 
	StyledButtonLoadMore, 
	MoreButtonWrapper, 
	PreDraftSwitchButton,
	MobileActionsWrapper,
	IgnoreButtonWrapper,
	CloseButtonWrapper,
	PlayerTableContainer,
	PreDraftTable
} from "./preDraft/PreDraftStyles";
import PreDraftTableBodyContainer from "./preDraft/PreDraftTable";





type Props = TDraftPassedProps & {
	updateDraftLeague: typeof actionsLeagueDraft.updateDraftLeague,
	clearDraftLeagueUpdateErrors: typeof actionsLeagueDraft.clearDraftLeagueUpdateErrors,
	is_pre_draft_list_successfully_saved?: boolean,
	resetQueue: Function,
	saveQueue: Function,
	showConfirmModal: Function,
	fetchUser: Function,
	hideConfirmModal: Function,
	onResetAllFilters: Function,
	all_stats: Object,
	userTeam: TDraftUserTeam,
	addKeeper: Function,
	removeKeeper: Function,
	league_update: {
		error: string,
		is_pending: boolean,
		status: "idle" | "loading" | "success" | "error"
	},
	history: Object
}

type State = {
	ignore_modal: boolean,
	ignore_player_id?: number,
	show_fantasy_coach_modal: boolean,
	actions_player_id: number,
	active_tab: string,
	team_keepers: Object[],
	is_keeper_saving: boolean,
	is_mobile : boolean,
	show_too_many_keeper_modal: boolean,
}

const StyledPageContentWrapper = styled(PageContentWrapper)`
	margin-top: 0;
`;

const DraftTitleContainerFlex = styled(DraftTitleContainer)`
	display:flex;
    width: 100%;
    justify-content: space-between;
	> div {
		padding: 0;
	}
`;


class PreDraftContainer extends React.Component<Props, State> {

	constructor(props, state) {
		super(props, state);

		this.state = {
			ignore_modal: false,
			ignore_player_id: 0,
			show_fantasy_coach_modal: false,
			actions_player_id: 0,
			active_tab: "queue",
			team_keepers: !_.isEmpty(props.userTeam.keepers) ? props.userTeam.keepers : [],
			is_keeper_saving: false,
			is_mobile:isMobile(),
			show_too_many_keeper_modal: false
		};

		_.bindAll(this, [
			"onClickIgnore",
			"onCloseIgnoreModal",
			"handleToggleTooManyKeepers",
			"onConfirmIgnoreModal",
			"toggleFantasyCoachModal",
			"columnToCellInfo",
			"openActionsPlayerId",
			"closeActionsPlayerId",
			"setActiveTab",
			"toggleKeeper",
			"toggleActiveTabQueue",
			"resize",
			"finaliseInviteEmails",
			"handleErrorModalClose",
			"getMobilePlayerActions"
		]);
	}

	componentDidMount(){
		window.addEventListener('resize', this.resize);
		this.resize();
		this.props.fetchUser();
	}
	componentDidUpdate(prevProps){
		
		
		const prevKeeper = _.get(prevProps, "userTeam.keepers", []);
		const prevKeeperLength = _.size(prevKeeper);
		const currentKeepers = _.get(this.props.userTeam, "keepers", []);
		if(prevKeeperLength !== _.size(currentKeepers)){
			this.setState({ team_keepers: currentKeepers });
		}
		
		
	}

	resize() {
		this.setState({is_mobile: isMobile()});
	}
	
	toggleKeeper(e) {
		this.setState({ is_keeper_saving: true });
		const { currentTarget: { name } } = e;
		const { 
			addKeeper, 
			removeKeeper, 
			league: { team_id, keeper_num }, 
			players_by_id 
		} = this.props;
		const { team_keepers } = this.state;
		const player_id = Number(_.last(name.split("-")));
		const player = players_by_id[player_id];
		const isSelected = team_keepers.find(playerA => 
			Number(playerA.id) === Number(player_id));
		if(isSelected) {
			this.setState({ team_keepers: team_keepers.filter(player => 
				Number(player.id) !== player_id), is_keeper_saving: false});
			removeKeeper({ player_id, team_id });
		}

		if(team_keepers.length === keeper_num && !isSelected){
			this.handleToggleTooManyKeepers();
			return;
		}
		if(!isSelected) {
			this.setState({ team_keepers: [...team_keepers, player], is_keeper_saving: false });
			addKeeper({ player_id, team_id });
		}
	
	}
	

	toggleFantasyCoachModal() {
		const { show_fantasy_coach_modal } = this.state;
		this.setState({ show_fantasy_coach_modal: !show_fantasy_coach_modal });
	}

	openActionsPlayerId(player_id: number){
		this.setState({
			actions_player_id: player_id
		});
	}

	closeActionsPlayerId() {
		this.setState({
			actions_player_id: 0
		});
	}

	setActiveTab({ currentTarget }: Object) {
		const { tab } = currentTarget.dataset;
		this.setState({
			active_tab: tab
		});
	}

	toggleActiveTabQueue() {
		this.setState({
			active_tab: "queue"
		});
	}

	getIsActiveTab(tabString){
		const {active_tab} = this.state;
		return active_tab === tabString ? "active-tab" : "";
	}

	renderTabs() {
		const { league } = this.props;
		const { active_tab, team_keepers } = this.state;
		
		const curr_keeper_num = team_keepers.length;
		
		const num_keepers = _.get(league, "keeper_num", 1);
		const is_warning = curr_keeper_num === 0;
		
		const is_keeper = this.is_keeper;
		const { is_mobile }  = this.state;
		const showKeeperTab =  isAllTrue([league.regenerated,league.keeper]);
		return <StyledTabs>
			<StyledTab
				onClick={this.setActiveTab}
				data-tab="queue"
				is_keeper={is_keeper}
				className={this.getIsActiveTab("queue")}>
				Queue
			</StyledTab>
			{showKeeperTab &&
				<React.Fragment>
					<StyledTab
						onClick={this.setActiveTab}
						data-tab="keepers"
						is_keeper={is_keeper}
						is_warning={is_warning}
						className={active_tab === "keepers" ? "active-tab" : ""}>
						Keepers 
						<span>
							{curr_keeper_num} / {num_keepers} 
							{is_warning && <Warning />}
						</span>
					</StyledTab>
					{is_mobile &&
						<StyledTab
							onClick={this.setActiveTab}
							data-tab="team"
							is_keeper={is_keeper}
							className={this.getIsActiveTab("team")}>
							Team
						</StyledTab>
					}
					
				</React.Fragment>
			}
			{is_mobile &&
				<StyledTab
					onClick={this.setActiveTab}
					data-tab="draft-order"
					is_keeper={is_keeper}
					className={this.getIsActiveTab("draft-order")}>
					Draft Order
				</StyledTab>
			}
		</StyledTabs>;
	}


	get players() {
		const { 
			players, 
			orderByComparator, 
			players_filter, 
			has_filters, 
			show_kept_players,
			kept_players
		} = this.props;
		const { team_keepers } = this.state;
		
		const kept_players_combined = [...team_keepers, ...kept_players];
		const arrayUniqueByKey = [...new Map(kept_players_combined.map(item =>
			[item["id"], item])).values()];

		const playersToUse = 
			show_kept_players 
				? arrayUniqueByKey 
				: players.slice()
					.filter(player => !this.props.kept_players_ids.includes(player.id));
		const { players_show_limit } = this.props;

		if(has_filters) {
			return playersToUse
				.filter(players_filter)
				.sort(orderByComparator)
				.slice(0, players_show_limit);
		}

		return playersToUse.sort(orderByComparator).slice(0, players_show_limit);
	}

	get load_more_btn(){
		if(this.props.load_more_is_hidden) {
			return null;
		}

		return <StyledButtonLoadMore onClick={this.props.onLoadMoreClick}>
			Load more
		</StyledButtonLoadMore>;
	}
	
	columnToCellInfo(column: Object, noHighlight?: boolean): TCellInfo {
		return {
			table: {
				order_by_desc: this.props.order_by_desc,
				sort_key: this.props.sort_by
			},
			cell: {
				sort_key: column.prop,
				name: column.key,
				short_name: column.key,
				rel: column.prop,
				sortable: !noHighlight,
				onClick: this.props.onSortColumnChange,
				...column,
			}
		};
	}

	get table_header_end_column() {

		const {show_kept_players} = this.props;
		const { is_mobile }  = this.state;
		return !show_kept_players ?  
			<ThIgnore className="last-right-th"> 
				{is_mobile ? "Action" : "Ignore"}
			</ThIgnore> : 
			<ThIgnore />;
		
	}

	get table_header() {
		const { table_keys, show_kept_players } = this.props;
		const classNameFirstBlock = show_kept_players ? " align-center" : "";
		const { is_mobile }  = this.state;
		const cells = _(table_keys)
			.map(key => this.columnToCellInfo(key, false))
			.map(cell => renderTableHeaderCell(cell, ThStat, "player-stats"))
			.value();
		const { is_coach_active } = this.props;
		const draft_order_column = {
			prop: "draft_order",
			key: "Draft Position",
			is_fc: false,
			tooltip: undefined,
			width: is_mobile ? "210px" : "115px"
		};
		const player_info_column = {
			prop: "player-info",
			key: "",
			is_fc: false,
			tooltip: undefined,
			width: "181px",
			sortable: false,
		};
		const dnd_column = {
			prop: "dnd",
			key: "",
			is_fc: false,
			tooltip: undefined,
			width: "55px",
			sortable: false,
		};
		const kept_by_column = {
			prop: "kept_by",
			key: "Kept By",
			is_fc: false,
			tooltip: undefined,
			width: is_mobile ? "208px" : "110px",
			sortable: false,
		};

		return (
			<TableHead className={is_coach_active ? "coach-stats" : "basic-stats"}>
				<Tr className="table-header-row">
					
					<th className={"absolute-group" + classNameFirstBlock}>
						{show_kept_players ? 
							renderTableHeaderCell(this.columnToCellInfo(kept_by_column))
							:	<React.Fragment>
								<Exist when={!is_mobile}>
									{renderTableHeaderCell(this.columnToCellInfo(dnd_column, true))}
								</Exist>
								

								{renderTableHeaderCell(this.columnToCellInfo(draft_order_column)) 
								}
								<Exist when={!is_mobile}>
									{renderTableHeaderCell(
										this.columnToCellInfo(player_info_column, true)
									)}
								</Exist>
					
							</React.Fragment>
						}
						
					</th>

					{ cells }
					{this.table_header_end_column
					}
				</Tr>
			</TableHead>
		);
	}
	getPlayerSeasonStat(player: TPlayer, playerAllStats: Object, prop: string) {
		if (_.includes(prop, "games_played")) {
			return _.get(player, prop, "- -");
		}

		return _.get(playerAllStats, prop, "- -");
	}


	getMobilePlayerActions(player: TPlayer) {
	
		const { 
			is_compressed,
			updatePlayerPreDraftListOrder,
			pre_draft_list_size,
			show_kept_players
		 } = this.props;
		const { actions_player_id } = this.state;
		const { id, draft_order } = player;
		const is_ignored = !draft_order;
		const isOpen = id === actions_player_id;
		if(show_kept_players){
			return null;
		}
		if(!isOpen){
			return <Td is_compressed={is_compressed} className="last-right-td">
				<MoreButtonWrapper onClick={_.partial(this.openActionsPlayerId, id)}>
					<More />
				</MoreButtonWrapper>
			</Td>
			;
		}
		return(
			<Td is_compressed={is_compressed} className="last-right-td">
				<MobileActionsWrapper>
					<PreDraftSwitchButton
						up={true}
						disabled={is_ignored || draft_order === 1}
						data-order={parseInt(draft_order, 10) - 1}
						data-id={player.id}
						onClick={updatePlayerPreDraftListOrder}
						is_compressed={is_compressed}
					>
						<ArrowUpSmall />
					</PreDraftSwitchButton>
					<PreDraftSwitchButton
						disabled={is_ignored || pre_draft_list_size === draft_order}
						data-order={parseInt(draft_order, 10) + 1}
						data-id={player.id}
						onClick={updatePlayerPreDraftListOrder}
						is_compressed={is_compressed}
					>
						<ArrowDownSmall />
					</PreDraftSwitchButton>

					<IgnoreButtonWrapper>
						Ignore
						<IgnoreCheckbox
							onChange={this.onClickIgnore}
							checked={is_ignored}
							name={`ignore-${player.id}`}
							pre_draft={true}
						/>
					</IgnoreButtonWrapper>
					<CloseButtonWrapper onClick={this.closeActionsPlayerId}>
						<Close />
					</CloseButtonWrapper>

				</MobileActionsWrapper>
			</Td>
			
		);
	}

	get table_body() {
		return <PreDraftTableBodyContainer
			{...this.props}
			addKeeper={this.props.addKeeper}
			getMobilePlayerActions={this.getMobilePlayerActions}
			hideConfirmModal={this.props.hideConfirmModal}
			onClickIgnore={this.onClickIgnore}
			onResetAllFilters={this.props.onResetAllFilters}
			resetQueue={this.props.resetQueue}
			saveQueue={this.props.saveQueue}
			showConfirmModal={this.props.showConfirmModal}
			userTeam={this.props.userTeam}
			players={this.players}
			toggleFantasyCoachModal={this.toggleFantasyCoachModal}
			getPlayerSeasonStat={this.getPlayerSeasonStat}
		 />;
	}
	


	onClickIgnore({ currentTarget: { name, checked } }) {
		const { league: { team_id } } = this.props;
		const player_id = parseInt(_.last(name.split("-")), 10);

		if(checked) {
			this.setState({ ignore_player_id: player_id, ignore_modal: true });
		}
		else {
			this.props.addPlayerToPreDraftList({ player_id,	team_id });
		}
	}

	onCloseIgnoreModal() {
		this.setState({
			ignore_player_id: 0,
			ignore_modal: false
		});
	}

	handleToggleTooManyKeepers() {
		this.setState({
			show_too_many_keeper_modal:!this.state.show_too_many_keeper_modal
		});
	}

	onConfirmIgnoreModal() {
		const
			{ league: { team_id }, removePlayerFromPreDraftList } = this.props,
			{ ignore_player_id: player_id } = this.state;

		removePlayerFromPreDraftList({ player_id, team_id });
		this.onCloseIgnoreModal();
	}

	get too_many_keepers_modal() {
		const { show_too_many_keeper_modal } = this.state;
		const {
			league: { keeper_num }
		} = this.props;
		const keepersText = keeper_num > 1 ? "s":"";
		if(show_too_many_keeper_modal) {
			return (
				<TextModal
					onClick={this.handleToggleTooManyKeepers}
					header_text='Error'
					text={ `Only ${keeper_num} keeper${keepersText} allowed in this league.` } />
			);	
		}
		return null;
	}

	get updated_modal() {
		const {
			clearDraftLeagueUpdateErrors,
			league_update,
		} = this.props;
		const leagueUpdateStatus = _.get(league_update,"status",LoadingStatus.IDLE);
		if(leagueUpdateStatus === LoadingStatus.SUCCESS){
			return (
				<TextModal
					onClick={clearDraftLeagueUpdateErrors}
					header_text="League Settings"
					text="Your League settings have now been updated!"
				/>
			);
		}
		return null;
	}

	get ignore_modal() {
		if(this.state.ignore_modal) {
			return (
				<ConfirmationModal
					header_text="Ignore player"
					body_text="This action will ignore the player in your draft
						 order. It will send the player to the bottom of your draft order list."
					closeClick={this.onCloseIgnoreModal}
					cancelClick={this.onCloseIgnoreModal}
					confirmClick={this.onConfirmIgnoreModal}
				/>
			);
		}

		return null;
	}

	get is_commissioner_type() {
		const
			{ user, league } = this.props,
			is_commissioner_draft = league.draft_type === "commissioner",
			you_is_commissioner = league.commissioner === _.get(user, "id");

		// Dirty hack to hide elements until league received
		if(_.isEmpty(league)) {
			return true;
		}

		return is_commissioner_draft && you_is_commissioner;
	}

	mainContent() {
		const {
			squads,
			positions,
			playerStatuses,
			league: { name },
			stats_options_list,
			order_by_desc,
			onToggleOrder,
			onFiltersChange,
			onCompressedViewClick,
			onPlayerShowLimitClick,
			onSearchSubmit,
			onSelectChange,
			resetQueue,
			saveQueue,
			is_coach_active,
			is_compressed,
			is_pre_draft_list_successfully_saved,
			onResetAllFilters,
			filters,
			toggleKeptPlayers,
			show_kept_players,
			league,
			coachSelectValue,
			userTeam
		} = this.props;
		const { active_tab, team_keepers, is_keeper_saving } = this.state;

		const leagueName = show_kept_players ? `Kept players for ${name}` : name;
		const is_tab_keepers = active_tab === "keepers";

		if(is_tab_keepers){
			return <ManageKeepers 
				coachSelectValue={coachSelectValue} 
				team_keepers={team_keepers}
				is_keeper_saving={is_keeper_saving}
				toggleKeeperModal={this.toggleActiveTabQueue}
				toggleKeeper={this.toggleKeeper}
				userTeam={userTeam}
			/>;
		}
		return (
			<React.Fragment>
				<DraftFilters
					handleChange={onFiltersChange}
					handleSubmit={onSearchSubmit}
					onToggleOrder={onToggleOrder}
					league={league}
					order_by_desc={order_by_desc}
					squads={squads}
					positions={positions}
					statuses={playerStatuses}
					is_show_drafted={false}
					onSelectChange={onSelectChange}
					selectOptions={stats_options_list}
					onResetAllFilters={onResetAllFilters}
					filters={filters}
					show_kept_players={show_kept_players}
					toggleKeptPlayers={toggleKeptPlayers}
					show_ignored
					is_show_keeper_toggle
				/>
				<DraftTableHeader
					title={leagueName}show
					onSelectChange={onSelectChange}
					isCoachActive={is_coach_active}
					selectOptions={stats_options_list}
					is_compressed={is_compressed}
					onCompressedViewClick={onCompressedViewClick}
					onPlayerShowLimitClick={onPlayerShowLimitClick}
					middleArea={(
						<ControlButtonsContainer>
							<ResetButton onClick={resetQueue}/>
							<SaveButton
								onClick={saveQueue}
								is_saved={is_pre_draft_list_successfully_saved}
							/>
						</ControlButtonsContainer>
					)}
					show_fantasy_ad
				/>
				<PlayerTableContainer>
					<PreDraftTable is_compressed={is_compressed}>
						{ this.table_header }
						{ this.table_body }
					</PreDraftTable>
								
				</PlayerTableContainer>
				{ this.load_more_btn }
			</React.Fragment>
		);

	}

	get is_keeper() {
		const {league: {
			keeper,
			regenerated_from,
			uf_regenerated_from
		}} = this.props;
		const isRegenerated = getIsRegenerated(regenerated_from,uf_regenerated_from);
		return keeper === 1 && isRegenerated;
	}
	mobileMainContent() {
		const {
			coachSelectValue,
			userTeam,
			league
		} = this.props;

		const { 
			active_tab,
			team_keepers,
			is_keeper_saving
		 } = this.state;
		switch (active_tab) {
			case "queue":
				return this.mainContent();
			case "keepers":
				return <ManageKeepers 
					coachSelectValue={coachSelectValue} 
					team_keepers={team_keepers}
					is_keeper_saving={is_keeper_saving}
					toggleKeeperModal={this.toggleActiveTabQueue}
					toggleKeeper={this.toggleKeeper}
					userTeam={userTeam}
				/>;
			case "draft-order":
				return 	<DraftOrderWidget />;
			case "team":
				return <TeamViewWidget
					picks={[]}
					commissioner_type={this.is_commissioner_type}
					title={userTeam.name}
					onToggle={null}
					onFilterByPosition={key => {
						this.props.onFilterByPosition(key);
						this.toggleActiveTabQueue();
					}}
					widget_open={true}
					league={league}
					onChangeTeam={null}
					drafted_players={[]}
					keeper_players={this.props.kept_players}
				/>;
			default:
				return this.mainContent();
		}
	}

	finaliseInviteEmails(e){
		e.preventDefault();
		// just updating team_finalised status for now
		const { updateDraftLeague,league } = this.props;
		const scoreValues = league.custom_scoring_enabled ? league.custom_scoring : {};
		const skipCustomScoring = league && league.draft_status !== "scheduled";
		
		updateDraftLeague({
			...league,
			id: league.id,
			teams_finalised: 1,
			scoreValues,
			skipCustomScoring
		});
	}

	handleErrorModalClose() {
		const { clearDraftLeagueUpdateErrors } = this.props;
		clearDraftLeagueUpdateErrors();
		window.location.reload();
	}

	get error_modal() {
		const { league_update } = this.props;
		const league_error = league_update.error;
		if(!league_error){
			return null;
		}
		return (
			<ErrorModal
				onClick={this.handleErrorModalClose}
				header_text='Error'
				text={league_error} />
		);
	}

	

	getDraftStatusPath(status){
		return status === "active" ? "live-draft" : "team";
	}

	render() {
		
		const { show_fantasy_coach_modal } = this.state;
		const {
			league_update,
			league: { 
				name, 
				// draft_start 
			},
			missed_draft_start,
			showConfirmModal,
			hideConfirmModal,
		} = this.props;
		const { is_mobile }  = this.state;
		const leagueUpdateStatus = _.get(league_update,"status",LoadingStatus.IDLE);
		const leagueUpdateLoading = leagueUpdateStatus === LoadingStatus.LOADING;
		// const draft_start_moment = moment(draft_start);
		// const moment_now = moment();
		// const diff = moment(draft_start_moment).diff(moment_now, 'days', true);
		// const isWithinDay = diff < 1;
		// if(is_mobile && isWithinDay) {
		// 	return (
		// 		<React.Fragment>
		// 			<GoToApp 
		// 				page_name="Pre Draft" 
		// 				draft_message
		// 				draft_start={moment(draft_start).format('DD/MM/YY HH:mm')}
		// 			/>
		// 			<Footer />
		// 		</React.Fragment>
		// 	);
		// }
		if(this.props.preloader || leagueUpdateLoading ) {
			return <Preloader />;
		}
		const {
			league: { draft_status },
			match: { params: { league_id } },
		} = this.props;

		if(draft_status !== "scheduled") {
			const path = this.getDraftStatusPath(draft_status);
			const full_path = `/draft/league/${league_id}/${path}`;
			this.props.history.push(full_path);
		}

		if(is_mobile){
			return (
				<React.Fragment>
					<TopBar 
						missed_draft_start={missed_draft_start} 
						showConfirmModal={showConfirmModal} 
						hideConfirmModal={hideConfirmModal}
						predraft_mob={true}
					/>
					<StyledPageContentWrapper>
				
						<AdsContainer>
							<Ad />
						</AdsContainer>
						{ this.ignore_modal }
						<Exist when={show_fantasy_coach_modal}>
							<FantasyCoachModal
								closeClick={this.toggleFantasyCoachModal}
							/>
						</Exist>
						{this.renderTabs()}
						{this.mobileMainContent()}
					</StyledPageContentWrapper>
				</React.Fragment>
			);
		}

		

		
		return (
			<div className="playwright-mask-hidden">
				{ this.ignore_modal }
				{ this.updated_modal }
				{ this.too_many_keepers_modal }
				{
					show_fantasy_coach_modal ?
						<FantasyCoachModal
							closeClick={this.toggleFantasyCoachModal}
						/>
						: null
				}
				<TopBar 
					missed_draft_start={missed_draft_start} 
					showConfirmModal={showConfirmModal} 
					hideConfirmModal={hideConfirmModal}
				/>
				<AdsContainer>
					<Ad />
				</AdsContainer>
				{this.error_modal}
				<StyledPageContentWrapper>
					<DraftTitleContainerFlex>
						<PageTitle>
							Pre Draft for {name}
						</PageTitle>
						<FinaliseOrUnlockLeague
							finaliseInviteEmails={this.finaliseInviteEmails}
						/>
					</DraftTitleContainerFlex>
					<TwoColumnLayout>
					
						<div>
							{this.is_keeper && this.renderTabs()}	
							{this.mainContent()}
						</div>
						<Aside>
							<DraftOrderWidget />
							<WidgetNews />
						</Aside>
					</TwoColumnLayout>
				</StyledPageContentWrapper>
				<Footer show_key />
			</div>
		);
	}
}

const mapStateToProps = (state: TRootStore, ownProps: Props) => {
	const { is_pre_draft_list_successfully_saved } = state.teamsDraft;
	const user_id = selectors.getUser(state).id;
	const teams_by_id = selectors.getTeamsById(state);
	const teamIDsArr = Object.keys(teams_by_id);
	const userTeamArr = teamIDsArr.filter(teamID =>{
		return teams_by_id[teamID].user_id === user_id;
	});
	const userTeamID =  _.get(userTeamArr, "[0]", 0);
	const userTeam = teams_by_id && teams_by_id[userTeamID] 
		? teams_by_id[userTeamID] : {};
	

	return{
		userTeam,
		is_pre_draft_list_successfully_saved,
		teams_by_id,
		league_update: state.leagues.update
	};
};

const on_clear_msg = "This action will reset your pre-draft list to " +
	"its original state before ANY of your changes. Are you sure you " +
	"want to continue? This action cannot be undone!!!";

const mapDispatchToProps = (dispatch, props) => {
	const { league: { team_id, id }, pre_draft_list, is_pending_players } = props;

	return {
		resetQueue: () => (
			team_id
			&& window.confirm(on_clear_msg)
			&& dispatch(actions.postResetPreDraftList({ team_id, league_id: id }))
			&& props.onResetFilterClick()
		),
		saveQueue: () => (
			!is_pending_players
			&& team_id
			&& dispatch(actions.postPreDraftList({ list: pre_draft_list, team_id }))
		),
		addKeeper: ({ player_id, team_id }) => {
			return (
				dispatch(actions.postSelectKeeper({ player_id, team_id }))
			);
		},
		removeKeeper: ({ player_id, team_id }) => {
			return (
				dispatch(actions.removeKeeper({ player_id, team_id }))
			);
		},
		updateDraftLeague: leagueData => (
			dispatch(actionsLeagueDraft.updateDraftLeague(leagueData))
		),
		clearDraftLeagueUpdateErrors: () => (
			dispatch(actionsLeagueDraft.clearDraftLeagueUpdateErrors())
		),
		fetchUser: () => {
			return (
				dispatch(fetchUser())
			);
		}
	};
};

export const PreDraft = compose(
	withRouter,
	withConfirmModal,
	withDraftPlayerModal,
	withDraftCore,
	withCoachesBox,
	connect(
		mapStateToProps,
		mapDispatchToProps,
	)
)(PreDraftContainer);

export default PreDraft;