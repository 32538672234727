// @flow
import * as React from "react";
import styled from "styled-components";
import {
	ButtonPrimary,
	ButtonSecondary,
	CountdownTimer,
	LeagueAvatar,
	ModalContainer,
	ModalInnerTitle,
} from "../../../../components";
import type { TPLeague } from "../../../../modules/types";

import isTimeOut from "../../../../utils/isTimeOut";
import colors from "../../../../assets/css/colors";
import { below } from "../../../../assets/css/media";

const LeagueAvatarWrapper = styled.div`
	text-align: center;
	margin: 1em 0;
	img {
		margin: 0 auto;
	}
`;

const PopupSubtitle = styled.h3`
	text-align: center;
	font-size: 20px;
	font-weight: bold;
	line-height: 1;
	color: ${colors.primary.primary};
	margin-bottom: 5px;

	${below.tablet`
		font-size: 12px;
		font-weight: normal;
		text-transform: uppercase;
	`}
`;

const JoinLeaguePopupFooter = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 1em 0 1.5em;

	${below.tablet`
		flex-direction: column-reverse;
	`}

	button {
		margin: 0 .5em;
		width: 200px;

		${below.tablet`
			margin: .5em;
			width: 265px;
			font-size: 10px;
		`}
		
		&.cancel{
			color: red;
			border-color: red;
			background: white;
			:hover{
				background-color: red;
				color: #fffff;
				border-color: red;

			}
		}
	}
`;

const CountdownWrapper = styled.div`
	> div {
		justify-content: center;

		> div {
			div:first-child {
				background: #fff;
				height: 60px;
				width: 60px;
				font-size: 30px;
				font-weight: bold;
				letter-spacing: 0.1px;
				line-height: 1;
				display: flex;
				align-items: center;
				justify-content: center;

				${below.tablet`
					height: 30px;
					width: 45px;
					font-size: 19px;
					padding-top: 5px;
				`}
			}

			div:last-child {
				font-size: 12px;
				line-height: 1.33;
			}
		}
	}
`;

type Props = {
	league: TPLeague,
	join: Function,
	onClick: Function
};

export const JoinLeaguePopup = ({ league, join, onClick }: Props) => {

	if (!league) {
		return null;
	}

	return (
		<ModalContainer is_sponsored={true} onClick={onClick}>

			<LeagueAvatarWrapper>
				<LeagueAvatar league={league} size="100px" />
			</LeagueAvatarWrapper>

			<ModalInnerTitle>
				Are you sure you want to join {league.name}?
			</ModalInnerTitle>

			{!isTimeOut(league.draft_start) ? (
				<CountdownWrapper>
					<PopupSubtitle>
						Draft Starts in:
					</PopupSubtitle>
					<CountdownTimer date={league.draft_start} label_type="full" popup/>
				</CountdownWrapper>
			) : null}

			<JoinLeaguePopupFooter>
				<ButtonSecondary onClick={onClick} className='cancel'>
					No, cancel
				</ButtonSecondary>
				<ButtonPrimary onClick={join.bind(this, league.id)}>
					Yes, join this league
				</ButtonPrimary>
			</JoinLeaguePopupFooter>

		</ModalContainer>
	);
};

export default JoinLeaguePopup;