// @flow
import styled from "styled-components";
import { Link } from "react-router-dom";

export const PlayerActionButton = styled.button`
	border: 0;
	background: transparent;
	height: ${({ compressed_view }) => compressed_view ? "20px" : "30px"};
	width: ${({ compressed_view }) => compressed_view ? "20px" : "30px"};
	padding: 0;
	cursor: pointer;
	&:disabled {
		cursor: not-allowed;
	}
	& + & {
		margin-left: 10px;
	}
	svg {
		height: 100%;
		width: 100%;
	}
`;

export const PlayerActionLink = styled(Link)`
	height: 30px;
	width: 30px;
	padding: 0;
	cursor: pointer;
	&:disabled {
		cursor: not-allowed;
	}
	& + & {
		margin-left: 10px;
	}
	> svg {
		height: 30px;
		width: 30px;
	}
`;

export const UndoPlayerActionButton = styled(PlayerActionButton)`
	border-radius:50%;
	background-color: #7F8A90;
	color:#fff;
`;
export default PlayerActionButton;