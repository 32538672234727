// @flow
import * as React from "react";
import styled from "styled-components";
import _ from "lodash";
import { Link } from "react-router-dom";
import ordinal from "ordinal";

import colors from "../../assets/css/colors";
import { below } from "../../assets/css/media";
import Lock from "../../components/Icons/Lock";
import TeamForm from "../../components/TeamForm";
import type { TRound, TPlayersById } from "../../modules/types";
import { getTeamScoreFromLadder, getLiveDraftTeamScore } from "../../helpers/team";
import { getShortName } from "../../helpers/league";

import { Exist } from "../Exist";
import TeamAvatar from "./teamAvatar";

const TeamAvatarWrapper = styled.div`
	border-radius: 50%;
	border: 4px solid ${({ border_color }) => border_color ? border_color : "transparent"};
	height: 100%;
	${below.phone`
		display: none;
	`}

	object {
		border: 1px solid #FFFFFF;
		${below.desktop`
			height: 40px;
			width: 40px;
		`};

		${below.tablet`
			height: 30px;
			width: 30px;
		`};
	}
`;
const TeamDetailsWrapper = styled.div`
	width: 175px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	${below.phone`
		width: 75px;
		${({ right_align }) => right_align ? "margin-right: 10px" : "margin-left: 10px"};
	`}
`;


const rightAlignRow = ({ right_align }) => right_align ? "row-reverse" :  "row";

const TeamRank = styled.div`
	font-size: 12px;
	padding-top: 2px;
	font-family: TitilliumUpright;
	font-weight: bold;
	color: #1D4073;
	margin-right: ${({ right_side }) => right_side ? "16px" : ""};
	margin-left: ${({ right_side }) => right_side ? "" : "15px"};
	${below.phone`
		margin-right: 0px;
		margin-left: 0px;
	`}
`;


const Wrapper = styled.div`
	display: flex;
	flex: 40%;
	width: 100%;
	flex-direction: ${rightAlignRow};
	align-items: center;
`;

const TeamDetails = styled.div`
	display: flex;
	flex: 80%;
	flex-direction: ${rightAlignRow};
	text-align: ${({ right_align }) => right_align ? "right" : "left"};
	padding-top: 10px;
	${({ right_align }) => right_align ? "margin-right: 40px" : "margin-left: 40px"};
	justify-content: flex-start;
	padding-bottom: 0px;
	width: 100%;
	${below.desktop`
		flex-direction: column;
		align-items: ${({ right_align }) => right_align ? "flex-end" :  "flex-start"};
		padding: 5px;
		margin-left: 0px;
		margin-right: 0px;
	`};
`;
const TeamDetailsForm = styled(TeamDetails)`
	padding-top: 0px;
	align-items: center;
	${below.phone`
		align-items: ${({ right_align }) => right_align ? "flex-end" : "flex-start"};
		padding-bottom: 0px;
	`}
`;

const TeamName = styled.p`
	color: ${colors.primary.primary};
	font-size: 12px;
	font-family: TitilliumUpright;
	font-weight: bold;
	max-width: 150px;

	word-break: break-all; /* Fallback */
	word-break: break-word;
	hyphens: auto;

	${below.desktop`
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		max-width: 200px;
		font-size: 14px;
	`};

	${below.desktop`
		max-width: 100px;
		font-size: 12px;
	`};
`;

const UserName = styled.p`
	color: ${colors.primary.primary};
	font-size: 12px;
	font-family: SourceSansPro;
	font-weight: 400;
	margin-top: .5em;
	${below.desktop`
		font-size: 12px;
	`};
	${below.tablet`
		font-size: 10px;
	`};
`;

const TeamNames = styled.div`
	margin-top: .5em;
	${below.desktop`
		margin: 0.5em 0 0 0;
	`};
`;

const TeamPoints = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: ${({ right_side }) => right_side ? "flex-start" : "flex-end"};
	flex: 20%;
	align-items: center;

`;

const Points = styled.div`
	font-size: 26px;
	font-weight: 700;
  font-family: SourceSansPro;
	color: ${colors.primary.primary};

	${below.desktop`
		font-size: 24px;
	`};

	${below.tablet`
		font-size: 14px;
	`};
`;

const ProjectedScoreWrapper = styled.div`
	font-size: 10px;
  font-family: SourceSansPro;
  font-weight: 600;
	color: ${colors.coach};
	white-space: nowrap;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
`;

const ProjectedScore = styled.span`

  ${below.phone`
    margin-top: 4px;
    font-size: 10px;
  `}
`;

const ProjectedLabel = styled.span`
  margin-right: 2px;
  ${below.phone`
    display: none;
  `}
`;

const PointsWrapper = styled.div`
	text-align: center;
`;

type Props = {
	opponent: Object,
	team_proj_score: number,
	round: TRound,
	has_assistant_coach: boolean,
	is_active_match: boolean,
	players_by_id: TPlayersById,
	right_align?: boolean,
	form: string,
	graph_open: boolean,
	className: string,
	is_classic: boolean,
  league?: Object,
  user_id: number
};

const getBorderColor = (graph_open, right_align) => {
	if (!graph_open) return null;

	return right_align ?
		colors.primary.accent :
		colors.primary.primary;
};

// const getDraftTeamScore = (team, players, round, is_classic) => {
// 	return complexUtils.helpers.team.getTeamScore({
// 		lineup: team.lineup,
// 		players_by_id: players,
// 		round,
// 		is_classic
// 	});
// };


export const TeamInfo = ({
	opponent,
	team_proj_score,
	round,
	has_assistant_coach,
	right_align,
	is_active_match,
	players_by_id,
	form,
	graph_open,
	className,
	league,
	user_id,
	is_classic = false
}: Props) => (
	<Wrapper className={className} right_align={right_align}>
		<Exist when={opponent}>
			<TeamDetailsWrapper right_align={right_align}>
				<TeamDetails right_align={right_align}>
					<TeamAvatarWrapper
						border_color={getBorderColor(graph_open, right_align)}
					>
						<TeamAvatar team={opponent} size="40px" />
					</TeamAvatarWrapper>
					<TeamNames right_align={right_align}>
						<TeamName>{opponent.name}</TeamName>
						<UserName>{getShortName(opponent)}</UserName>
					</TeamNames>
				</TeamDetails>
				<TeamDetailsForm right_align={right_align}>
						
					<TeamRank right_side={right_align}>{
						(opponent.rank !== 0 && opponent.rank !== undefined)
								&& ordinal(opponent.rank)
					}</TeamRank>
					<TeamForm
						summary_bar={true}
						right_side={right_align}
						form={form}
						condensed
					/>
						
				</TeamDetailsForm>
			</TeamDetailsWrapper>
				
			<TeamPoints right_side={right_align}>
				<PointsWrapper>
					<Points>{is_classic ? getTeamScoreFromLadder({
						round,
						team: opponent,
						players_by_id,
						is_active_match,
						is_classic
					}) : getLiveDraftTeamScore({
						team: opponent,
						is_active_match,
							  round,
	              players_by_id,
	              scoring_players: _.get(opponent.scoring_players, `[${round.id}]`, []),
	              custom_scoring_enabled: _.get(league, "custom_scoring_enabled", 0)
					})
              
					} </Points>
					<ProjectedScoreWrapper>
						<ProjectedLabel>Projected: </ProjectedLabel>
						<ProjectedScore>
							{has_assistant_coach && team_proj_score}
							<Exist when={!has_assistant_coach}>
								<Link to="/coach-subscription" title="Coach Subscription">
									<Lock color={colors.black} />
								</Link>
							</Exist>
						</ProjectedScore>
					</ProjectedScoreWrapper>
				</PointsWrapper>
			</TeamPoints>

		</Exist>
	</Wrapper>
);

export default TeamInfo;