// @flow
import * as React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import styled from "styled-components";

import colors from "../../../assets/css/colors";
import { above } from "../../../assets/css/media";

import type { 
	TClassicTeam, 
	TRoundsWithMatches, 
	TRound, 
	TPlayersById 
} from "../../../modules/types";

import * as actions from "../../../modules/actions";
import * as selectors from "../../../modules/selectors";
import type { TPLeague, TUser } from "../../../modules/types";

import { getTeamProjectedScore, getTeamScoreFromLadder } from "../../../helpers/team";

import Lock from "../../Icons/Lock";
import withFantasyCoachModal from "../../utils/withFantasyCoachModal";
import TeamUserAvatar from "../../Team/TeamUserAvatar";
import { Exist } from "../../Exist";

import { Container } from "./Container";

const getGameBarColor = ({ is_coach, is_negative, is_live }) => {
	if (is_coach) {
		return colors.coach;
	}
	if (is_live) {
		return colors.success;
	}
	return is_negative ? colors.warning : "#1D4073";
};

const OpenLeagueContainer = styled(Container)`
	padding: 10px;
`;

const GameBarSection = styled.div`
	position: relative;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	align-content: flex-start;
	flex-direction: column;
	height: 50px;
`;

const GameBarLabel = styled.h3`
	color: ${({ is_coach }) => is_coach ? colors.coach : "#8D979C"};
	font-size: 10px;
	text-transform: uppercase;
	line-height: 1.1;
	margin-bottom: 8px;
	text-align: center;
	font-weight: 400;
`;

const GameBarValue = styled.p`
	color: ${getGameBarColor};
	font-size: 18px;
	line-height: 1.05;
	margin-bottom: 5px;
`;

const GameBarSubLabel = styled(GameBarLabel)`
	margin-bottom: 0;
`;

const FcLink = styled.a`
	color: ${colors.coach};
	text-decoration: none;
	cursor: pointer;
`;

const StatsWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-around;
    flex: 0 0 64%;
	${({display_mobile}) => display_mobile && `
		flex: 0 0 100%;
	`}
`;

const TeamDetails = styled.div`
	display: flex;
    align-items: center;
	margin-left: 10px;
`;

const TeamUserAvatarWrapper = styled.div`
	flex: 0 0 40px;
	height: 40px;
	width: 40px;
`;

const TeamName = styled.div`
	font-size: 14px;
	font-weight: 700;
	color: #1D4073;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 120px;

	${above.phone`
		max-width: 350px;
	`}
`;

const UserName = styled.div`
	font-size: 12px;
	font-weight: 500;
	color: #7F8A90;
	max-width: 120px;

	${above.phone`
		max-width: 350px;
	`}
`;

const Names = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1 2 auto;
	margin-left: 10px;
`;

type Props = {
	user: TUser,
	league: TPLeague,
	fetchMyTeam: typeof actions.fetchMyClassicTeam,
	fetchClassicTeamSnapshot: typeof actions.fetchClassicTeamSnapshot,
	team: TClassicTeam,
	snapshot: Object,
	actual_round: TRound,
	players_by_id: TPlayersById,
	rounds_with_matches: TRoundsWithMatches,
	has_assistant_coach: boolean,
	toggleFantasyCoachModal: Function,
	display_mobile?: boolean
}

class OpenLeague extends React.Component<Props> {
	componentDidMount() {
		const { fetchMyTeam, fetchClassicTeamSnapshot, team } = this.props;

		if (!team.id) {
			fetchMyTeam();
		}
		const isOnMyTeamPage = window.location.pathname.includes('classic/team') 
			|| window.location.pathname.includes("advanced-trades");
		if(!isOnMyTeamPage){
			fetchClassicTeamSnapshot();
		}
		
	}

	get score_round_id() {
		const { actual_round } = this.props;
		const round_id = _.get(actual_round, "id", 1);
		const round_status = _.get(actual_round, "status", "scheduled");

		// Before the round starts, show score of previous round
		return round_status === "scheduled"
			? Math.max(round_id - 1, 1) /* Make sure no round 0 */
			: round_id;
	}

	get render_round_score() {
		const {	rounds_with_matches, players_by_id, team } = this.props;

		const round_id = this.score_round_id;
		const score_round = _.find(rounds_with_matches, { id: round_id });

		const is_active_match = _.get(score_round, "status") === "active";
		const team_score = getTeamScoreFromLadder({
			team,
			is_active_match,
			round: score_round,
			players_by_id,
			is_classic: true
		});

		return (
			<GameBarSection>
				<GameBarLabel>
					ROUND {round_id} SCORE
				</GameBarLabel>
				<GameBarValue is_live={is_active_match}>
					{team_score}
				</GameBarValue>
			</GameBarSection>
		);
	}

	get projected_score() {
		const { team, actual_round, players_by_id } = this.props;
		const lineup = _.get(team, "lineup", {});
		return getTeamProjectedScore(
			lineup,
			players_by_id,
			{ captains: true, emergency: true },
			actual_round,
			true
		);
	}

	get render_proj_score() {
		const { has_assistant_coach, toggleFantasyCoachModal, actual_round } = this.props;
		const { projected_score } = this;
		const min_proj = Math.round(projected_score * .95);
		const max_proj = Math.round(projected_score * 1.05);

		return (
			<GameBarSection>
				<GameBarLabel is_coach>
					ROUND {_.get(actual_round, "id", "-")} PROJECTED
				</GameBarLabel>
				<GameBarValue is_coach>
					{ has_assistant_coach ? projected_score	: (
						<FcLink
							onClick={toggleFantasyCoachModal}
						>
							<Lock />
						</FcLink>
					)}
				</GameBarValue>
				{has_assistant_coach ? (
					<GameBarSubLabel is_coach>
						{min_proj} - {max_proj}
					</GameBarSubLabel>
				) : null}
			</GameBarSection>
		);
	}

	get render_rank() {
		const {	league, snapshot, display_mobile } = this.props;

		const is_league_selected = !_.isEmpty(league);

		const rank = is_league_selected
			? _.get(league, "team_rank", "-")
			: _.get(snapshot, "rank", "--");
		const num_teams = is_league_selected
			? _.get(league, "num_teams", "-")
			: _.get(snapshot, "num_teams", "--");;

		return (
			<GameBarSection>
				<GameBarLabel>
					{is_league_selected ? "League" : "Overall"} Ranking
				</GameBarLabel>
				<GameBarValue>
					{rank}
				</GameBarValue>
				<Exist when={!display_mobile}>
					{is_league_selected ?
						(<GameBarSubLabel>
							out of {num_teams}
						</GameBarSubLabel>) : null
					}
				</Exist>
				
			</GameBarSection>
		);
	}

	render() {
		const { snapshot, user, display_mobile } = this.props;
		const { firstname, lastname, name } = snapshot;
		const { id: user_id, avatar_version } = user;

		return (
			<OpenLeagueContainer display_mobile={display_mobile}>
				<Exist when={!display_mobile}>
					<TeamDetails>
						<TeamUserAvatarWrapper>
							<TeamUserAvatar
								user_id={user_id}
								avatar_version={avatar_version}
								firstname={firstname}
								lastname={lastname}
							/>
						</TeamUserAvatarWrapper>
						<Names>
							<TeamName>{name || ""}</TeamName>
							<UserName>{`${firstname || ""} ${lastname || ""}`}</UserName>
						</Names>
					</TeamDetails>
				</Exist>
				
				<StatsWrapper display_mobile={display_mobile}>
					{this.render_round_score}
					{this.render_proj_score}
					{this.render_rank}
				</StatsWrapper>
			</OpenLeagueContainer>
		);
	}
}

const mapStateToProps = state => {
	return {
		user: selectors.getUser(state),
		team: selectors.getMyClassicTeam(state),
		snapshot: state.teamsClassic.snapshot,
		rounds_with_matches: selectors.rounds.getRoundsWithMatches(state),
		players_by_id: selectors.players.getExtendedPlayersById(state)
	};
};

const mapDispatchToProps = {
	fetchMyTeam: actions.fetchMyClassicTeam,
	fetchClassicTeamSnapshot: actions.fetchClassicTeamSnapshot,
};

export default withFantasyCoachModal(
	connect(mapStateToProps, mapDispatchToProps)(OpenLeague)
);