// @flow
import React from "react";
import { Redirect } from "react-router-dom";
import {TPLeague} from "../../modules/types";

type Props = {
	leagueID: string | number,
	userID: string | number,
	league: TPLeague,
}

export default ({ leagueID, userID, league }:Props) => {
	if(league.commissioner && league.commissioner !== userID) {
		return <Redirect to={`/draft/league/${leagueID}/about`} />;
	}
	else {
		return null;
	}

};