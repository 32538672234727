// @flow
import * as React from "react";
import EmgDef from "../Icons/EmgDef";
import EmgMid from "../Icons/EmgMid";
import EmgRuck from "../Icons/EmgRuck";
import EmgFwd from "../Icons/EmgFwd";

export const positionEmergencyButton = (pos_id: number) => (
	pos_id === 1
		? <EmgDef />
		: pos_id === 2
			? <EmgMid />
			: pos_id === 3
				? <EmgRuck />
				: <EmgFwd />
);

export default positionEmergencyButton;