// @flow
import styled, { css } from "styled-components";
import { default_transition } from "../../../assets/css/vars";

const getColors = ({ background, color, hover_bg, hover_color }) => {
	return css`
		color: ${color};
		background: ${background};
		&:hover {
			color: ${hover_color};
			background: ${hover_bg};
		}
	`;
};

const getColor = (is_true, color_true, color_false) => {
	return is_true ? color_true : color_false;
};

export const getCommandButtonColors = (
	emg?: boolean = false, 
	cap?: boolean = false,
	vc?: boolean = false, 
	base_color?: string = "#CAD2D8", 
	base_bg?: string = "#F8F8FA", 
	active_color?: string = "#fff"
) => {
	return ({
		emergency: {
			background: getColor(emg, "#B55DAE", base_bg),
			color: getColor(emg, active_color, base_color),
			hover_bg: getColor(emg, "#c785c1", "#B55DAE"),
			hover_color: getColor(emg, active_color, active_color),
		},
		captain: {
			background: getColor(cap, "#F5A623", "#CAD2D8"),
			color: getColor(cap, "#FFF", "#FFF"),
			hover_bg: getColor(cap, "#f8c36d", "#F5A623"),
			hover_color: getColor(cap, active_color, active_color),
		},
		vice: {
			background: getColor(vc, "#198DE1", "#CAD2D8"),
			color: getColor(vc, "#fff", "#fff"),
			hover_bg: getColor(vc, "#5eb2ed", "#198DE1"),
			hover_color: getColor(vc, active_color, active_color),
		},
		base: {
			background: base_bg,
			hover_bg: "#fdfdfd"
		}
	});
};

export const CommandButton = styled.button`
	border: none;
	outline: 0;
	border-radius: 50%;
	width: 20px;
	height: 20px;
	margin-${({ reverse }) => reverse ? "left" : "right"}: 3px;
	${({ noMargin }) => noMargin && "margin: 0 !important;"}
	align-items: center;
	justify-content: center;
	padding: 0;
	cursor: pointer;
	display: ${({ is_hidden }) => is_hidden ? "none" : "flex"};
	font-size: 16px;
	${getColors}
	${default_transition}

	> svg {
		${default_transition};
		width: 20px;
		height: 20px;
	}

	&:hover {
		> svg {
			${({ trade }) => trade && css`
				fill: #1b63a4;
			`}
			${({ trash }) => trash && css`
				fill: #df3a3a;
			`}
		}
	}
`;

export default CommandButton;