// @flow
import { createReducer } from "redux-act";
import * as actions from "../../actions";

const initialState = {
	faqs: {
		sections: [],
		articles: [],
	}
};

export const help = createReducer({}, initialState);

help.on(actions.fetchFAQsSuccess, (state, payload) => ({
	...state,
	faqs: {
		sections: payload.sections,
		articles: payload.articles
	}
}));

export default help;