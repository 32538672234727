// @flow
import styled from "styled-components";
import { Tr } from "../../components/TableX";

export const TrRow = styled(Tr)`
	&.is-ignored {
		.player-info{
			opacity: .3;
		}
		
		.player-stats{
			color: rgba(11, 27, 23, 0.29);
		}
	}
	td{
		border-bottom: none;
	}
	.dnd {
		min-width: 55px;
	}
	th {
		position: absolute;
		background: ${({ index }) => index % 2 ? "#f8f8fa" : "#fff"};
		width: 375px;
		box-shadow: ${({ scrollable }) => scrollable ? "5px 0 5px -5px #cad2d8" : "" } ;
		/* border-right: 0.5px solid #cad2d8; */
		.player-info-stat{
			width: 100%;
			.player-details-status{
				margin-left: 5px;
			}
		}
		td{
			padding: 1px 0px;
		}
	}
`;

export default TrRow;