// @flow
import React from "react";
import styled, { css } from "styled-components";
import Close from "../Icons/Close";
import Chat from "../Icons/Chat";
import SvgIconContainer from "../SvgIconContainer";
import colors from "../../assets/css/colors";
import { default_transition } from "../../assets/css/vars";
import { below } from "../../assets/css/media";
import ChatToggle from "./ChatToggle";

const open_transition = "transition: all 0.2s ease-in-out;";

const UnreadNotification = styled.div`
	height: ${({ show_notification }) => show_notification ? "16px" : "2px"};
	width: ${({ show_notification }) => show_notification ? "16px" : "2px"};
	opacity: ${({ show_notification }) => show_notification ? "1" : "0"};
	margin: ${({ show_notification }) => show_notification ? "1px" : "8px"};
	border-radius: 8px;
	background-color: ${colors.buttons.cancel};

	position: absolute;
	top: -3px;
    left: -7px;

    box-sizing: border-box;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;

	${default_transition};

	svg {
		${default_transition};
		font-size: ${({ show_notification }) => show_notification ? "6px" : "1px"};
	}
`;

const OpenBoxTitle = styled.div`
	color: white;
	font-size: ${({ opened }) => opened ? "24px" : "0px"};
	${default_transition}
	width: ${({ opened }) => opened ? "150px"  : "0px"};
	opacity: ${({ opened }) => opened ? 1 : 0};
	padding-top: ${({ opened }) => opened ? "3px"  : "0px"};
`;

const BoxTitle = styled.div`
	color: white;
	font-size: ${({ opened }) => opened ? "0px" : "16px"};
	opacity: ${({ opened }) => !opened ? 1 : 0};
	${default_transition}
	width: 0;

	height: 60px;
	width: 40px;
	display: flex;
	justify-content: center;
	align-items: center;

	svg {
		font-size: 30px;
		${below.tablet`
			font-size: 20px;
		`};
	}

	${({ opened }) => opened && "display: none"};
`;

const BoxSliderWrapper = styled.div`
	${({ opened }) => !opened && "display: none"};
	margin-left: auto;
`;

const CloseWrapper = styled.div`
	height: 100%;
	width: 60px;
	${below.tablet`
		width: 45px;
	`};
	display: flex;
	justify-content: center;
	align-items: center;

	svg {
		font-size: 30px;
		${below.tablet`
			font-size: 20px;
		`};
	}

	${({ opened }) => !opened && "display: none"};
`;

const CoachesBoxTopWrapper = styled.div`
	background-color: #00529C;
	:hover {
		background: #006dcc;
	}
	border-radius: 2px 0 0 2px;
	margin-top: 5px;
	cursor: pointer;

	position: absolute;
	top: ${({ opened, is_live_draft }) => opened ? "0 ": "150px"};

	right: 0;

	z-index: 1;

	${open_transition};

	height: 60px;
	${({ opened }) => opened
		? css`
			width: 550px;
			${below.tablet`
				width: calc(100% - 25px);
			`};
			display: flex;
			justify-content: flex-start;
			align-items: center;
			border-bottom-right-radius: 0;
		`
		: css`
			width: 40px;
		`}

	${below.tablet`
		top: ${({ opened }) => opened ? "0 ": "70px"};
	`}
`;

type Props = {
	onClick: Function,
	opened: boolean,
	handleToggle: Function,
	coaches_box_used: boolean,
	unread_messages: boolean,
};

export const CoachesBoxTop = ({
	onClick,
	handleToggle,
	opened,
	coaches_box_used,
	unread_messages,
}: Props) => {
	const show_notification = unread_messages && coaches_box_used;

	return <CoachesBoxTopWrapper onClick={onClick} opened={opened}>
		<UnreadNotification show_notification={show_notification}>
			<SvgIconContainer viewBox="0 0 8 8">
				<circle cx="4" cy="4" r="4" fill="white" />
			</SvgIconContainer>
		</UnreadNotification>
		<CloseWrapper opened={opened}>
			<Close color="white" />
		</CloseWrapper>
		<OpenBoxTitle opened={opened}>Coaches Box</OpenBoxTitle>
		<BoxSliderWrapper opened={opened}>
			<ChatToggle
				onChange={handleToggle}
				is_checked={coaches_box_used}
			/>
		</BoxSliderWrapper>
		<BoxTitle opened={opened}><Chat /></BoxTitle>
	</CoachesBoxTopWrapper>;
};

export default CoachesBoxTop;