// @flow
import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { compose } from "redux";
import * as selectors from "../../../modules/selectors";
import * as leagueClassicActions from "../../../modules/actions/leaguesClassic";
import * as actions from "../../../modules/actions";
import { TwoColumnLayout, PageContentWrapper, H2HLadder, LeagueGameBar } from "../../../components";
import JsonFetcher from "../../../components/utils/JsonFetcher";
import withRoundsSelector from "../../../components/utils/withRoundsSelector";
import RoundSelector from "../../../components/RoundSelector";
import AdsContainer from "../../../components/AdsContainer";
import AdFantasyCoach from "../../../components/AdFantasyCoach";
import Ad from "../../../components/Ad";
import {Exist} from "../../../components/Exist";
import PageTitle from "../../../components/PageTitle";
import ClassicLadderOpen from "../../../components/ClassicLadderOpen";
import Footer from "../../../components/Footer";
import { withClassicPlayerModal, StandardSidebar, MatchCentreBar } from "../../../components";
import withCoachesBox from "../../../components/utils/withCoachesBox";
import isMobile from "../../../utils/isMobile/index";
import { getLeagueRoundAliases, getWinners } from "../../../helpers/league";
import type { 
	TPLeague, 
	TRoundsById, 
	TRound, 
	TClassicTeamsByID,
	TClassicTeam,
	TUser
} from "../../../modules/types";
import { isRoundActive, isRoundComplete } from "../../../helpers/rounds";
import YourTeamDetails from "../../../components/LeagueGameBar/YourTeamDetails";


const isRoundDisabled = (round: TRound, league: TPLeague) => (
	round.id !== league.start_round
	&& !isRoundActive(round)
	&& !isRoundComplete(round)
);

type Props = {
	selected_league_id: number,
	ladder_teams_ordered: Array<TClassicTeam>,
	ladder_teams_by_id: TClassicTeamsByID,
	fetchLeague: typeof actions.showClassicLeague,
	fetchLadder: typeof leagueClassicActions.ladderClassicLeague,
	fetchLadderLoadMore: typeof leagueClassicActions.ladderLoadMoreClassicLeague,
	is_pending_show: boolean,
	is_pending_ladder: boolean,
	user: TUser,
	rounds_ordered: Array<TRound>,
	rounds_by_id: TRoundsById,
	selected_round_id: number,
	setSelectedRoundId: Function,
	league: TPLeague,
	offset: number,
	is_load_more: boolean,
	actual_round: TRound,
}

type State = {
}

class LeagueLadderPage extends React.Component<Props, State> {
	constructor(props) {
		super(props);

		_.bindAll(this, ["loadMore"]);
	}

	componentDidMount() {
		const { selected_round_id } = this.props;

		this.fetchLeague();

		if (selected_round_id) {
			this.fetchLadder();
		}
	}

	componentDidUpdate(prev_props) {
		const { selected_league_id, selected_round_id, setSelectedRoundId, league } = this.props;

		if (
			selected_league_id &&
			selected_league_id !== prev_props.selected_league_id
		) {
			this.fetchLeague();
			this.fetchLadder();
		}
		else if (
			selected_round_id !== prev_props.selected_round_id
		) {
			this.fetchLadder();
		}

		if (
			selected_league_id !== prev_props.selected_league_id ||
			league !== prev_props.league
		) {
			setSelectedRoundId(this.league_selected_round);
		}
	}

	get league_rounds() {
		const {
			rounds_ordered,
			league
		} = this.props;

		if (rounds_ordered && league && league.start_round && league.end_round) {
			return _(rounds_ordered)
				.map(round => ({
					...round,
					disabled: isRoundDisabled(round, league)
				}))
				.filter(round =>
					round.id >= league.start_round
					&& round.id <= league.end_round
				)
				.value();
		}

		return rounds_ordered;
	}

	get league_selected_round() {
		const { selected_round_id, league } = this.props;

		if (selected_round_id && league) {
			const start_round = _.first(this.league_rounds).id;
			const end_round = _.last(this.league_rounds).id;

			if ( selected_round_id < start_round) {
				return start_round;
			}
			else if (selected_round_id > end_round) {
				return end_round;
			}

			return selected_round_id;
		}

		return  null;
	}

	get league_current_round_fixtures() {
		const { league, selected_round_id } = this.props;
		return selected_round_id ?
			_.get(league, `fixture.${selected_round_id}`, []): [];
	}

	get finals_rounds() {
		const {
			rounds_ordered,
			league
		} = this.props;

		return getLeagueRoundAliases(league, rounds_ordered);
	}

	get winners() {
		const { league, ladder_teams_ordered, rounds_by_id, selected_round_id } = this.props;
		const round = _.get(rounds_by_id, selected_round_id);
		return getWinners(league, round, ladder_teams_ordered);
	}

	get ladder_stats() {
		const { selected_round_id, rounds_by_id } = this.props;

		const round_over = _.get(rounds_by_id, [selected_round_id, "status"]) === "complete";

		const round_id = round_over
			? selected_round_id
			: selected_round_id - 1;

		const last_round_key = round_over ? "Round Points" : "Points Last Round";

		return [
			{
				key: "Total Points",
				short_key: "Total",
				prop: "points_for_selected_round",
				sort_key: ""
			 },
			{
				key: "Average Points",
				short_key: "Avg",
				prop: "points_average_selected_round",
				sort_key: ""
			},
			{
				key: last_round_key,
				short_key: "Points",
				prop: `league_scoreflow.${round_id}`,
				rel: "points_last_round_selected_round",
				sort_key: ""
			},
			{
				key: "Overall Ranking",
				short_key: "Ovr Rank",
				prop: `overall_rank_history.${round_id}`,
				rel: "overall_rank",
				sort_key: ""
			},
			// {
			// 	key: 'Value',
			// 	short_key: 'Value',
			// 	prop: 'value',
			// 	format: '$0,0',
			// 	sort_key: 'team_value',
			// }
		];
	}

	fetchLadder() {
		const { fetchLadder, selected_league_id, selected_round_id } = this.props;
		fetchLadder({
			league_id: selected_league_id,
			round: selected_round_id
		});
	}

	fetchLeague() {
		const { fetchLeague, selected_league_id } = this.props;
		fetchLeague({
			id: selected_league_id
		});
	}
	loadMore() {
		const { offset, selected_league_id } = this.props;

		this.props.fetchLadderLoadMore({
			league_id: selected_league_id,
			offset: offset + 100
		});
	}

	renderMainSection() {
		const {
			league,
			ladder_teams_ordered,
			ladder_teams_by_id,
			rounds_by_id,
			selected_round_id,
			setSelectedRoundId,
			actual_round,
			user,
		} = this.props;
		const sorted_ladder_teams_ordered = _.sortBy(ladder_teams_ordered, "rank_selected_round");
		const league_type = league ? league.type : null;
		const is_mobile = isMobile();

		 return (
			<div >
				<RoundSelector
					selected={selected_round_id}
					rounds={this.league_rounds}
					handleClick={setSelectedRoundId}
					round_aliases={this.finals_rounds}
				/>
				<Exist when={is_mobile}>
					<YourTeamDetails
						league={league}
						league_is_pending={false}
						display_mobile={true}
						actual_round={actual_round}
					/>
				</Exist>
				
				{ league_type === "head_to_head" ?
					// $FlowFixMe
					<H2HLadder
						user_id={user.id}
						ladder_teams_ordered={sorted_ladder_teams_ordered}
						ladder_teams_by_id={ladder_teams_by_id}
						rounds_by_id={rounds_by_id}
						selected_round_id={selected_round_id}
						league={league}
						league_current_round_fixtures={this.league_current_round_fixtures}
						is_classic
						show_tabs
						{...this.winners}
					/> :
					// $FlowFixMe
					<ClassicLadderOpen
						user={user}
						ladder_teams_ordered={ladder_teams_ordered}
						ladder_teams_by_id={ladder_teams_by_id}
						rounds_by_id={rounds_by_id}
						selected_round_id={selected_round_id}
						league={league}
						league_current_round_fixtures={this.league_current_round_fixtures}
						loadMore={this.loadMore}
						{...this.winners}
					/>}
				<AdFantasyCoach type="leaderboard" small_screen_only />
			</div>
		 );
	}

	render() {
		const { league } = this.props;
		const title_league_name = league ? league.name : "-";
		return (
			<React.Fragment>
				<JsonFetcher fetch={["rounds", "venues", "squads"]} />
				<LeagueGameBar no_fetch={true} />
				<AdsContainer>
					<Ad />
				</AdsContainer>
				<PageContentWrapper className="playwright-mask-hidden">
					<PageTitle>
						Ladder for {title_league_name}
					</PageTitle>
					<TwoColumnLayout>
						{this.renderMainSection()}
						<StandardSidebar />
					</TwoColumnLayout>
				</PageContentWrapper>
				<MatchCentreBar />
				<Footer />
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state, props) => {
	const selected_league_id = props.match.params.league_id;
	const ladder_teams_ordered = selectors.leaguesClassic.getOrderedLadderCalculated(state, props);
	const user = selectors.getUser(state);
	const actual_round = selectors.rounds.getActualRound(state);
	return {
		selected_league_id,
		league: selectors.leaguesClassic.show.getLeague(state, props),
		is_pending_show: selectors.leaguesClassic.show.isPending(state),
		is_pending_ladder: selectors.leaguesClassic.ladder.isPending(state),
		ladder_teams_by_id: selectors.leaguesClassic.ladder.getById(state),
		ladder_teams_ordered,
		user,
		offset: selectors.leaguesClassic.ladder.getOffset(state),
		is_load_more: selectors.leaguesClassic.ladder.isLoadMore(state),
		actual_round
	};
};

const mapDispatchToProps = {
	fetchLeague: leagueClassicActions.showClassicLeague,
	fetchLadder: leagueClassicActions.ladderClassicLeague,
	fetchLadderLoadMore: leagueClassicActions.ladderLoadMoreClassicLeague
};

export const LeagueLadder = compose(
	withClassicPlayerModal,
	withCoachesBox,
	withRoundsSelector,
	connect(
		mapStateToProps,
		mapDispatchToProps
	),
)(LeagueLadderPage);

export default LeagueLadder;