// @flow
import styled from "styled-components";
import { ButtonPrimary } from "../../components";
import colors from "../../assets/css/colors";
import { below } from "../../assets/css/media";

export const ButtonPrimaryStyled = styled(ButtonPrimary)`
	min-width: 200px;
	height: 40px;
	line-height: .9;
	background: #1D4073;
	margin-top: 15px;

	:hover:not(:disabled) {
		background-color: ${colors.buttons.accentHover};
	}
	${below.phone`
		width: 335px;
	`}
`;

export default ButtonPrimaryStyled;