// @flow
import React from "react";
import _ from "lodash";
import styled from "styled-components";

import { Group } from "@vx/group";
import { GlyphDot } from "@vx/glyph";
import { Line } from "@vx/shape";
import { Point } from "@vx/point";
import { scaleLinear, scaleBand } from "@vx/scale";
import { AxisLeft, AxisBottom } from "@vx/axis";
import { GridColumns } from "@vx/grid";
import { withParentSize } from "@vx/responsive";

import type { TMatch } from "../../modules/types";

import colors from "../../assets/css/colors";
import {getClubLogoIndigenous} from "../../utils/clubLogos";

const GraphSVG = styled.svg`
	.vx-axis.vx-axis-left {
		.vx-axis-tick {
			text {
				font-family: inherit;
				font-weight: 500;
				fill: #89969F;
			}
		}
	}
`;

const VsText = styled.text`
	font-size: 10px;
	font-weight: 500;
	fill: ${colors.secondary.paleGrey};
`;

// accessors
const id = d => d.id;
const match = d => d.match;
const value = d => d.value;

type Props = {
	parentWidth: number,
	parentHeight: number,
	matches: Array<TMatch>,
	data: Array<Object>,
	show_data: boolean,
	actual_round: Object,
}

const Graph = ({
	parentWidth, parentHeight, matches, data, show_data,
	actual_round
}: Props) => {
	const joint_teams_data = data.reduce((acc, team) => {
		return _.filter([ ...acc, ...team.scores], "value");
	}, []);

	// scales
	const xScale = scaleBand({
		domain: matches.map(id)
	});

	const yScale = scaleLinear({
		domain: [0, Math.max(...joint_teams_data.map(value))]
	});

	// positions
	const x = d => xScale(match(d));
	const y = d => yScale(value(d));

	const is_small = parentWidth < 700;

	const margin = {
		top: 20,
		right: is_small ? 40 : 60,
		bottom: is_small ? 80 : 50,
		left: is_small ? 40 : 80
	};
	const width = parentWidth - margin.left - margin.right;
	const height = parentHeight - margin.top - margin.bottom;

	// bounds
	const xMax = width;
	const yMax = height;

	// update scale range to match bounds
	xScale.range([20, xMax + 40]);
	yScale.range([yMax, 0]);

	return (
		<GraphSVG
			width="100%"
			height={height + margin.bottom}
		>
			<Group left={margin.left} top={margin.top}>
				<AxisLeft
					scale={yScale}
					hideTicks
					hideZero
					hideAxisLine
					numTicks={5}
					left={-(margin.left - 40)}
				/>
				<AxisBottom scale={xScale} top={10}>
					{axixData => (
						<React.Fragment>
							{axixData.ticks.map(tick => {
								const match_id = tick.value;
								const match = matches.find(match => match.id === match_id);
								const home_squad_id = _.get(match, "home_squad_id");
								const home_squad_name = _.get(match, "home_squad_name");
								const away_squad_id = _.get(match, "away_squad_id");
								const away_squad_name = _.get(match, "away_squad_name");
								const homeSquadLogo = getClubLogoIndigenous(
									actual_round.id, home_squad_id
								);
								const awaySquadLogo = getClubLogoIndigenous(
									actual_round.id, away_squad_id
								);

								return (
									<React.Fragment key={match_id}>
										<image
											y={yMax}
											x={xScale(match_id)}
											style={{
												transform: `translateX(${
													is_small ? "-8px" : "-24px"
												})`
											}}
											xlinkHref={homeSquadLogo}
											height="16"
											width="16"
										>
											<title>{home_squad_name}</title>
										</image>
										<VsText
											y={yMax + 10}
											x={xScale(match_id)}
											style={{
												transform: `translateX(-5px)
													translateY(${is_small ? "16px" : "0px"})`
											}}
										>
											VS
										</VsText>
										<image
											y={yMax}
											x={xScale(match_id)}
											style={{
												transform: `translateX(${is_small ? "-8px" : "8px"})
													translateY(${is_small ? "28px" : "0px"})`
											}}
											xlinkHref={awaySquadLogo}
											height="16"
											width="16"
										>
											<title>{away_squad_name}</title>
										</image>
									</React.Fragment>
								);
							})}
						</React.Fragment>
					)}
				</AxisBottom>
				<GridColumns
					className="column-grid"
					style={{ opacity: 0.2 }}
					scale={xScale}
					width={xMax}
					height={yMax}
					stroke="#D8D8D8"
					strokeWidth={2}
				/>

				{/* Render Lines */}
				{show_data && data.map(team => team.scores.map((d, i) => {
					if (_.isNumber(d.value) === false) return null;

					const current_x = x(d);
					const current_y = y(d);
					const current_is_projected = d.is_projected;

					const previous = team.scores[i-1];

					if (!previous) {
						return null;
					}

					const previous_x = x(previous);
					const previous_y = y(previous);

					const current_point = new Point({ x: current_x, y: current_y });
					const previous_point = new Point({ x: previous_x, y: previous_y });

					return (
						<g key={`line-${i}`}>
							<Line
								stroke={team.color}
								strokeWidth={1}
								strokeDasharray={current_is_projected ? "4,2" : "" }
								opacity={current_is_projected ? 0.5 : 1}
								from={previous_point}
								to={current_point}
							/>
						</g>
					);
				}))}

				{/* Render Points */}
				{show_data &&  data.map(team => team.scores.map((d, i) => {
					if (_.isNumber(d.value) === false) return null;

					const cx = x(d);
					const cy = y(d);

					// const current_is_projected = d.is_projected;
					return (
						<g key={`line-point-actual-${i}`}>
							<GlyphDot
								cx={cx}
								cy={cy}
								r={4}
								fill={team.color}
								stroke={team.color}
								strokeWidth={2}
								opacity={d.is_projected ? 0.8 : 1}
							/>
						</g>
					);
				}))}

			</Group>
		</GraphSVG>
	);
};

export default withParentSize(Graph);