/* eslint max-len: 0 */
// @flow
import React from "react";
import SvgIconContainer from "../SvgIconContainer";

const transform = {
	up: "matrix(-1 0 0 -1 24 24.599952)",
	right: "matrix(0 -1 1 0 -.299976 24.299976)",
	down: "",
	left: "matrix(0 1 -1 0 24.299976 .299976)"
};

export default (
	{
		unit = "em",
		direction = "down",
		...rest
	}: Object = {}) => (
	<SvgIconContainer viewBox="0 0 24 24" {...rest} unit={unit}>
		<title>Arrow {direction}</title>
		<path d="M16.6 8.6l-4.6 4.6-4.6-4.6-1.4 1.4 6 6 6-6z" transform={transform[direction]} />
	</SvgIconContainer>
);