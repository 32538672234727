// @flow

export const CoachSubscriptionValidate = (values: Object) => {
	const errors = {};

	if (!values.fullname) {
		errors.fullname = "Cardholder's name is required";
	}

	errors.have_the_permission = !values.have_the_permission ?
		"Please confirm permission to subscribe" : null;

	errors.privacy_policy = !values.privacy_policy ?
		"Please accept the Terms & Conditions" : null;

	return errors;
};