// @flow
import * as React from "react";
import styled from "styled-components";
import _ from "lodash";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

import * as selectors from "../../modules/selectors";
import colors from "../../assets/css/colors";
import Delist from "../Icons/Delist";
import Lock from "../Icons/Lock";
import {
	TeamUserAvatar,
	PlayerInfo,
	DraftTableHeader,
	TdDraftOwner,
	TdNext,
	PlayerActionButton,
	UndoButton,
	BenchLabel
} from "../";
import {TPLeague} from "../../modules/types";

import type {
	TCoachStatsListReducer,
	TTeam,
	TRound
} from "../../modules/types";

import TableUnstyled, { Th as ThUnstyled, THead, Tr, TBody, Td } from "../TableX";
import Stop from "../Icons/Stop";
import Undo from "../Icons/Undo";
import { getSquadSize } from "../../helpers/league";
import { coach_player_stats } from "../../helpers/stats";
import { renderTableHeaderCell } from "../../helpers/tables";
import ThStatUnstyled from "../ThStat";
import sticky_headers from "../../assets/css/sticky";
import { getLineupPlayersCount } from "../../helpers/complex/team";

const TrStyled = styled(Tr)`
	height: 60px;
	${({ is_on_trade }) => {
		return is_on_trade ? `
			background: ${colors.secondary.lightGrey};
			> div {
				opacity: .5;
			}
			> div:first-child {
				opacity: 1;

				> div:first-child {
					opacity: 1;
				}
				> div {
					opacity: .5;
				}
			}
			> div:last-child {
				opacity: 1;
			}
		` : "";
	}}
`;

const ThStat = styled(ThStatUnstyled)`
	${sticky_headers};
`;
const Th = styled(ThUnstyled)`
	${sticky_headers};
`;
const Table = styled(TableUnstyled)`
	border-collapse: separate;
`;

type TableColumn = { key: string, prop: string, tooltip: string, is_fc?:boolean };

type Props = {
	team: TTeam,
	league: TPLeague,
	table: {
		basic: TableColumn[],
		coach: TableColumn[],
	},
	actual_round: TRound,
	lineup_players: Object,
	emergency_arr: Array<number>,
	addOutTrade: Function,
	removeOutPlayer: Function,
	fa: Object,
	is_league_started: boolean,
	coach_stats_list: TCoachStatsListReducer,
	has_assistant_coach: boolean,
	is_classic: boolean,
};

type State = {
	compressed_view: boolean,
	order_by_desc: boolean,
	active_stats_type: string,
	sort_by: string
};

export class TeamForTradePage extends React.Component<Props, State> {
	static defaultProps = {
		players: [],
		table: {
			basic: [
				{ key: "AVG", prop: "avg_points", tooltip: "" },
				{ key: "PLD", prop: "games_played", tooltip: "" },
				{ key: "TOT", prop: "total_points", tooltip: "" },
				{ key: "L5 AVG", prop: "last_5_avg", tooltip: "" },
				{ key: "AVG DP", prop: "adp", tooltip: "" },
			],
			coach: [],
		},
		team: {},
		league: {}
	};

	constructor(props: Props, state: State) {
		super(props, state);

		_.bindAll(this, [
			"getTableSection",
			"getTableRow",
			"tableColumnToCellInfo"
		]);
	}

	state = {
		compressed_view: false,
		active_stats_type: "general-stats",
		order_by_desc: true,
		sort_by: "stats.proj_avg"
	};

	get team_data() {
		const { team } = this.props;

		if (_.isEmpty(team)) {
			return null;
		}
		return (
			<React.Fragment>
				<TeamUserAvatar user_id={team.user_id} avatar_version={team.avatar_version} />
				<h3>
					{team.name}
				</h3>
			</React.Fragment>
		);
	}
	get team_size() {
		const { team, league } = this.props;

		if (_.isEmpty(team) || _.isEmpty(league)) {
			return null;
		}
		const { lineup } = team,
			current = getLineupPlayersCount({ lineup }),
			total = getSquadSize(league);

		return (<p>{current}/{total}</p>);
	}

	get table_keys() {
		const { active_stats_type } = this.state;
		const { league, actual_round, is_classic } = this.props;
		const isLeagueCustomScoring = _.get(league, "custom_scoring_enabled", 0);
		const statsBasis = isLeagueCustomScoring ? "custom_stats" : "stats";
		return active_stats_type === "general-stats" ?
			this.props.table.basic :
			coach_player_stats(statsBasis, actual_round, is_classic)[active_stats_type];
	}

	get table_header() {
		return (<DraftTableHeader
			title="Select a player from your team to delist"
			is_compressed={this.state.compressed_view}
			onCompressedViewClick={bool => this.setState({ compressed_view : bool })}
			selectOptions={[
				{ key: "general-stats",value: "General Stats" },
				...this.props.coach_stats_list
			]}
			onSelectChange={e => this.setState({ active_stats_type: e.target.value })}
			active_stats_type={this.state.active_stats_type}
		/>);
	}

	tableColumnToCellInfo(column: TableColumn) {
		return {
			table: {
				order_by_desc: false,
				sort_key: ""
			},
			cell: {
				sort_key: column.prop,
				name: column.key,
				short_name: column.key,
				rel: column.prop,
				sortable: false,
				onClick: () => null,
				tooltip: column.tooltip || "",
				is_fc: column.is_fc || false
			}
		};
	}

	get table_head() {
		const cells = _(this.table_keys)
			.map(this.tableColumnToCellInfo)
			.map(cell => renderTableHeaderCell(cell, ThStat))
			.value();

		return (
			<THead>
				<Tr>
					<Th textAlign="left">Player</Th>
					<Th textAlign="left">Owner</Th>
					<Th>Next</Th>
					{ cells }
					<Th width="100px">Action</Th>
				</Tr>
			</THead>
		);
	}

	get table_body() {
		const { team, lineup_players } = this.props;
		const { lineup } = team;
		
		if (!lineup || _.isEmpty(lineup_players)) {
			return <TBody><Tr><Td>Loading</Td></Tr></TBody>;
		}
		const lineup_pure = _.omit(lineup, ["captain", "vice_captain", "bench", "emergency"]);
		const bench = _.get(lineup, "bench");

		return (
			<TBody>
				{_.map(lineup_pure, this.getTableSection)}
				<Tr>
					<Td colSpan={`${this.table_keys.length + 4}`}>
						<BenchLabel>
							Interchange
						</BenchLabel>
					</Td>
				</Tr>

				{_.map(bench, id => this.getTableRow(
					{ id, is_bench: true, is_agent: false }
				))}
			</TBody>
		);
	}
	getTableSection(line: Array<number>) {
		return _.map(line, id => this.getTableRow(
			{
				id,
				is_bench: false
			}
		));
	}
	getTableRow({ id, is_bench }: Object) {
		const {
				lineup_players,
				fa,
				has_assistant_coach,
				league
			} = this.props,
			is_on_trade = fa.old_player_id === id,
			player = lineup_players[id];
		const isLeagueCustomScoring = _.get(league, "custom_scoring_enabled", 0);
		const statsBasis = isLeagueCustomScoring ? "custom_stats" : "stats";
		
		if(!player) {
			return (
				<Tr key={id}>
					<Td colspan="9">Loading</Td>
				</Tr>
			);
		}
		const { compressed_view } = this.state;
		return (
			<TrStyled key={id} is_on_trade={is_on_trade}>
				<Td>
					<PlayerInfo
						player={player}
						is_compressed={compressed_view}
						is_classic={false}
					/>
				</Td>
				<TdDraftOwner team={this.props.team} is_compressed={compressed_view} />
				<TdNext squad_id={_.get(player.competition.opponent,'id')} is_compressed={compressed_view} />
				{this.table_keys.map(({ key, prop, is_fc }) =>{
				
				 return(
						<Td key={key} rel={prop}>
							{ is_fc && !has_assistant_coach ? (
								<Link to="/coach-subscription" title="Coach Subscription">
									<Lock color={colors.coach} />
								</Link>
							) : _.get(player, statsBasis +"."+ prop, "--")}
						</Td>
					);
				}
				)}
				{this.getControls({ id })}
			</TrStyled>
		);
	}

	getControls({ id }: Object) {
		const {
				removeOutPlayer,
				addOutTrade,
				fa,
			} = this.props,
			is_on_trade = fa.old_player_id === id;

		if (!this.isCanTrade({ id })) {
			return (
				<Td>
					<PlayerActionButton disabled="disabled">
						<Stop color={colors.secondary.paleGrey} />
					</PlayerActionButton>
				</Td>
			);
		}

		return is_on_trade ? (
			<Td>
				<UndoButton
					onClick={() => removeOutPlayer({ id, is_user_team: true })}
				>
					<Undo color="#fff" />
				</UndoButton>
			</Td>
		) : (
			<Td>
				<PlayerActionButton
					onClick={() => addOutTrade({ id, is_user_team: true })}
				>
					<Delist color={colors.buttons.cancel} />
				</PlayerActionButton>
			</Td>
		);
	}

	isActiveTab(sort_prop: string){
		const { sort_by } = this.state;
		return _.eq(sort_by, sort_prop) ? "active" : "";
	}

	isCanTrade({ id }: Object) {
		const {
			league: { lockout },
			is_league_started,
			actual_round: { status }
		} = this.props;
		const is_not_scheduled_round = status !== "scheduled";
		/*
			AFLB21-70 FA players locked issue
			const player = lineup_players[id];
			const is_captain = captain === id;
			const is_vice_captain = vice_captain === id;
			lineup_players,
			team: { lineup: { captain, vice_captain } },
			const is_locked = player.locked || is_captain || is_vice_captain;
		*/
		const is_locked = false;
		const is_lockout = lockout ? (is_not_scheduled_round && is_locked) : is_not_scheduled_round;

		if (!is_league_started) {
			return true;
		}

		return !Boolean(is_lockout);
	}

	onSortColumnChange(column_value : string) {
		const { sort_by, order_by_desc } = this.state;

		this.setState({
			sort_by: column_value,
			order_by_desc: _.eq(sort_by, column_value) ? !order_by_desc : order_by_desc,
		});
	}

	render() {
		return <div>
			{this.table_header}
			<Table
				is_compressed={this.state.compressed_view}
				tablet_columns={["stats.avg_points"]}
				show_border_bottom
			>
				{this.table_head}
				{this.table_body}
			</Table>
		</div>;
	}
}

const mapStateToProps = (state, props) => {
	const is_classic = _.get(window.location, "pathname", "").includes("classic");
	return {
		league: selectors.leagueDraft.getLeague(state, props),
		isPending: state.leagues.show.isPending,
		team: selectors.complex.team.getUserTeamFromTeamsById(state, props),
		user_id: state.user.data.id,
		has_assistant_coach: Boolean(selectors.getUser(state).assistant_coach),
		actual_round: selectors.rounds.getSelectedRound(state),
		lineup_players: selectors.complex.team.getUserPlayersFromLineup(state, props),
		emergency_arr: selectors.complex.team.getEmergencyArray(state),
		is_league_started: selectors.leagueDraft.isLeagueStarted(state, props),
		coach_stats_list: state.coachStatsList,
		is_classic
	};
};
export const TeamForTrade = withRouter(
	connect(mapStateToProps)(TeamForTradePage)
);
export default TeamForTrade;