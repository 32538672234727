// @flow
import { createReducer } from "redux-act";
import * as actions from "../../actions";

const initialState = {
	notification_bar: {
		show: false,
		content: "",
		is_pending: false,
		error: null,
	},
	notification_bar_draft: {
		show: false,
		content: "",
		is_pending: false,
		error: null,
	},
	winners: {
		is_pending: false,
		error: null,
		winners: [],
	},
};

export const cms = createReducer({}, initialState);

cms.on(actions.getNotificationBar, (state, payload) => ({
	...state,
	notification_bar: {
		...state.notification_bar,
		error: null,
		is_pending: true
	}
}));
cms.on(actions.getNotificationBarFailed, (state, payload) => ({
	...state,
	notification_bar: {
		...state.notification_bar,
		error: payload,
		is_pending: false
	}
}));
cms.on(actions.getNotificationBarSuccess, (state, { show, content }) => ({
	...state,
	notification_bar: {
		...state.notification_bar,
		show: show !== 0,
		content,
		error: null,
		is_pending: false
	}
}));

cms.on(actions.getNotificationDraftBar, state => ({
	...state,
	notification_bar_draft: {
		...state.notification_bar_draft,
		error: null,
		is_pending: true
	}
}));
cms.on(actions.getNotificationDraftBarFailed, (state, payload) => ({
	...state,
	notification_bar_draft: {
		...state.notification_bar_draft,
		error: payload,
		is_pending: false
	}
}));
cms.on(actions.getNotificationDraftBarSuccess, (state, { show, content }) => ({
	...state,
	notification_bar_draft: {
		...state.notification_bar_draft,
		show: show !== 0,
		content,
		error: null,
		is_pending: false
	}
}));

cms.on(actions.getWinners, (state, payload) => ({
	...state,
	winners: {
		...state.winners,
		error: null,
		is_pending: true
	}
}));
cms.on(actions.getWinnersFailed, (state, payload) => ({
	...state,
	winners: {
		...state.winners,
		error: payload,
		is_pending: false
	}
}));
cms.on(actions.getWinnersSuccess, (state, winners) => ({
	...state,
	winners: {
		...state.winners,
		winners,
		error: null,
		is_pending: false
	}
}));

export default cms;