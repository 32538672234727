//@flow
import * as React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import * as _ from "lodash";
import * as selectors from "../../../../modules/selectors";
import * as actions from "../../../../modules/actions";
import { LineupSetter } from "../../../../components/MyTeam/lineupSetter";
import { TeamInfoBlock } from "../../../../components";

import { isMobile } from "../../../../utils";
import {
	TPosition,
	TRound,
	TPlayersArrayWithFixtureById,
	TDraftUserTeam,
	TPLeague,
	TPlayer,
} from "../../../../modules/types";
import ListViewTeam from "../leagueTeam/listView";
import OverflowUtils from "../leagueTeam/overflowUtils";
import { isAllTrue } from "../../../../helpers";


type Props = {
  match: {
	  params: {
		  league_id: string
	  }
  },
  team: TDraftUserTeam,
  positions: { [number]: TPosition },
  players_by_id: TPlayersArrayWithFixtureById,
  actual_round: TRound,
  league: TPLeague,
  updateMyTeam: Function,
  is_current: boolean,
  player_ids: Array<number>,
  showModalForPlayerId: typeof actions.showModalForPlayerId,
  is_static: boolean,
  selected_round: TRound,
  bye_detector_selected: number[],
  empty_lineup: Object,
  bye_detector_active: boolean,
  rounds: TRound[],
	removePlayer: typeof actions.teamsDraft.fetchRemovePlayer,
	fetchPlayerMatches: Function,
	squads_by_id: Object,
	fetchSquads: Function,
	openAddPlayerModal: Function,
	closeAddPlayerModal: Function,
}
function removeEmptyBenchPlayers(arr, val) {
	let i = arr.length;
	while (i--) {
		if (arr[i] === val) {
			arr.splice(i, 1);
		}
	}
	return arr;
}
const emgPosition = (emg, p_id) => _.findKey(emg, id => id === p_id);
type TPlayerAction = null | 'delist' | 'trade';

type State = {
	preloader: boolean,
	is_mobile: boolean,
	field_stat: string,
	substitute_id: number,
	substitute_position: number,
	substitute_player_positions: Array<number>,
	substitute_is_bench: boolean,
	modal_action_type: TPlayerAction,
	modal_action_player: null | number
};

class LeagueEditTeamListComponent extends React.Component<Props, State> {
	constructor(props) {
		super(props);
		_.bindAll(this, [
			"setCaptain",
			"setViceCaptain",
			"setEmergency",
			"substitutePlayer",
			"cancelSubstitute",
			"checkIsPlayerChangeAvailable",
			"areSubstitutesActive",
			"isOutSubstitute",
			"isInSubstitute",
			"removePlayer",
			"openPlayerPopup",
			"setPlayerAction",
			
		]);
	}
	state = {
  	preloader: true,
  	is_mobile: isMobile(),
  	substitute_id: 0,
  	substitute_position: 0,
  	substitute_player_positions: [],
  	substitute_is_bench: false,
  	modal_action_player: null,
  	modal_action_type: null,
  	field_stat: "stats.points_last_round",
  	
	};
	componentDidMount() {
  	this.props.fetchSquads();
	}
	checkIsPlayerChangeAvailable(player_id) {
  	const { actual_round, players_by_id, league: { lockout: league_lockout } } = this.props;
  	const player = players_by_id[player_id];
  	const { status, lockout } = actual_round;
  	const is_active = status === "active";

  	if (league_lockout) {
  		return !player || !player.locked;
  	}

  	if (is_active) {
  		if (lockout === "full") {
  			return false;
  		}
  		else {
  			return !player || !player.locked;
  		}
  	}

  	return true;
	}
  
	setCaptain(event) {
  	const { team, updateMyTeam } = this.props;
  	const { checkIsPlayerChangeAvailable } = this;
  	LineupSetter.setCaptain({
  		event,
  		team,
  		updateMyTeam,
  		checkIsPlayerChangeAvailable,
  		is_draft: true
  	});
	}
	setViceCaptain(event) {
  	const { team, updateMyTeam } = this.props;
  	const { checkIsPlayerChangeAvailable } = this;
  	LineupSetter.setViceCaptain({
  		event,
  		team,
  		updateMyTeam,
  		checkIsPlayerChangeAvailable,
  		is_draft: true
  	});
	}
	setEmergency(event: Object) {
  	const { team, updateMyTeam, players_by_id } = this.props;
  	const { currentTarget: { dataset: { id: id_string, position: pos_string } } } = event;


  	const player_id = parseInt(id_string, 10);
  	const player = players_by_id[player_id];

  	if (_.isEmpty(player)) {
  		return;
  	}

  	const position = pos_string ? parseInt(pos_string, 10) : player.positions[0];

  	LineupSetter.setEmergencyDraft({
  		player_id,
  		position,
  		team,
  		lineup: this.lineup,
  		updateMyTeam,
  		checkIsPlayerChangeAvailable: this.checkIsPlayerChangeAvailable
  	});
	}
	findLeader(id) {
  	const { team: { lineup: { captain, vice_captain } } } = this.props;
  	return _.findKey({ captain, vice_captain }, player_id => player_id === id);
	}
	swapPlayersOnField({ player_in, player_out }) {
  	const { lineup } = this;
  	const players_id = [player_in.id, player_out.id];
  	const swapPlayersOnFound = player_id => {
  		let found = players_id.includes(player_id),
  			inverted_index = players_id.indexOf(player_id) ^ 1;

  		return found ? players_id[inverted_index] : player_id;
  	};

  	const new_lineup = _.chain(lineup)
  		.omit(["captain", "vice_captain", "emergency"])
  		.mapValues(value => value.map(swapPlayersOnFound))
  		.value();
  	return { ...new_lineup };
	}

	swapEmergency(emergency, player_out, player_in) {
  	const { lineup: { bench } } = this;
  	const new_emg = { ...emergency };
  	const out_pos = emgPosition(emergency, player_out.id);
  	const in_pos = emgPosition(emergency, player_in.id);
  	const out_on_bench = bench.indexOf(player_out.id) !== -1;
  	const in_on_bench = bench.indexOf(player_in.id) !== -1;

  	// Swapping inside of bench? Don't change either, I guess
  	if (out_on_bench && in_on_bench) {
  		return new_emg;
  	}

  	if (out_pos) {
  		new_emg[out_pos] = 0;
  		if (player_in.positions.includes(parseInt(out_pos, 10))) {
  			new_emg[out_pos] = player_in.id;
  		}
  	}
  	if (in_pos) {
  		new_emg[in_pos] = 0;
  		if (player_out.positions.includes(parseInt(in_pos, 10))) {
  			new_emg[in_pos] = player_out.id;
  		}
  	}
  	return { ...new_emg };
	}

	tryToSubstitute({ player_in, player_out }) {
  	const { team, updateMyTeam, players_by_id } = this.props;
  	const { lineup } = this;
  	const { emergency } = lineup;
  	const what_leader_out = this.findLeader(player_out.id);
  	const what_leader_in = this.findLeader(player_in.id);
  	const full_player_out = players_by_id[player_out.id];
  	const full_player_in = players_by_id[player_in.id];

  	if (_.isEmpty(full_player_in) || _.isEmpty(full_player_out)) {
  		return;
  	}

  	const swaps = this.swapPlayersOnField({ player_in, player_out });

  	let new_lineup = {
  		...lineup,
  		...swaps,
  		emergency: this.swapEmergency(
  			emergency,
  			full_player_out,
  			full_player_in
  		)
  	};

  	if (what_leader_out && player_in.is_bench) {
  		new_lineup = {
  			...new_lineup,
  			[what_leader_out]: player_in.id
  		};
  	}
  	if (what_leader_in && player_out.is_bench) {
  		new_lineup = {
  			...new_lineup,
  			[what_leader_in]: player_out.id
  		};
  	}
  	updateMyTeam({
  		old_lineup: lineup,
  		lineup: new_lineup,
  		id: team.id
  	});

  	this.cancelSubstitute();
	}
	substitutePlayer({ currentTarget }: Object) {
  	const {
  		id: string_id,
  		position: string_pos,
  		position_index: string_pos_index,
  		is_bench: string_is_bench
  	} = currentTarget.dataset;

  	const { players_by_id } = this.props;
  	const id = parseInt(string_id, 10);
  	const position = parseInt(string_pos, 10);
  	const is_bench = string_is_bench? JSON.parse(string_is_bench): false;
  	const { substitute_id, substitute_position, substitute_is_bench } = this.state;

  	if (id === 0) {
  		const position_index = parseInt(string_pos_index, 10);
  		this.substituteEmptyPlayer({
  			empty_in: { position, position_index },
  			player_out: { id: substitute_id, position: substitute_position }
  		});
  	}

  	if (substitute_id === id) {
  		return this.cancelSubstitute();
  	}
  	if (substitute_id) {
  		return this.tryToSubstitute({
  			player_in: { id, position, is_bench },
  			player_out: {
  				id: substitute_id, position: substitute_position, is_bench: substitute_is_bench
  			}
  		});
  	}

  	const player = players_by_id[id];

  	this.setState({
  		substitute_id: id,
  		substitute_position: position,
  		substitute_player_positions: player.positions,
  		substitute_is_bench: is_bench
  	});
	}
	cancelSubstitute() {
  	const { substitute_id } = this.state;
  	if (substitute_id) {
  		this.setState({
  			substitute_id: 0,
  			substitute_position: 0,
  			substitute_player_positions: [],
  			substitute_is_bench: false
  		});
  	}
	}
	areSubstitutesActive() {
  	return this.state.substitute_id !== 0;
	}
	isOutSubstitute(player: TPlayer, position_id: number) {
  	const { substitute_id } = this.state;
  	const is_subs = this.areSubstitutesActive();

  	return is_subs && !_.isEmpty(player) && substitute_id === player.id;

	}
	isInSubstitute(player: TPlayer, position_id: number, position_index?: number) {
  	const { substitute_position, substitute_player_positions } = this.state;

  	const is_subs = this.areSubstitutesActive();
  	const in_is_bench = position_id === undefined;
  	const out_is_bench = isNaN(substitute_position);

  	let in_position;
  	let is_locked;

  	const out_position = in_is_bench || substitute_player_positions.includes(position_id);
  	if (_.isEmpty(player)) {
  		// this empty player can become the in substitute if:
  		//  - the player being subbed has a position that matches the empty spot
  		//  - the empty spot is on the bench
  		in_position = _.includes(substitute_player_positions, position_id)
				|| in_is_bench;
  		is_locked = false;
  	}
  	else {
  		in_position = out_is_bench || player.positions.includes(substitute_position);
  		is_locked = !this.checkIsPlayerChangeAvailable(player.id);
  	}
  	return this.inSubstitute(is_subs, is_locked, in_position, out_position);
	}
	inSubstitute(is_subs, is_locked, in_position, out_position) {
  	return is_subs && !is_locked && in_position && out_position;
	}

	substituteEmptyPlayer({ empty_in, player_out }) {
  	const { team, updateMyTeam, players_by_id } = this.props;
  	const { lineup } = this;
  	const { emergency } = lineup;
  	const player_leader = this.findLeader(player_out.id);
  	const player_emg_pos = emgPosition(emergency, player_out.id);
  	const player = players_by_id[player_out.id];
  	const out_position = player_out.position || "bench";
  	const in_position = empty_in.position || "bench";

  	if (_.isEmpty(player)) {
  		return;
  	}

  	// Move 0 into old player position
  	const pos_row = lineup[out_position];
  	const new_row = pos_row.map(id => id === player_out.id ? 0 : id);

  	let new_lineup = {
  		...lineup,
  		[out_position]: [...new_row]
  	};

  	// Move player into old 0 position
  	new_lineup[in_position][empty_in.position_index] = player_out.id;

  	// Player is emergency and moving off bench
  	if (isAllTrue([player_emg_pos, !empty_in.position]) ) {
  		new_lineup = {
  			...new_lineup,
  			emergency: {
  				...emergency,
  				[player_emg_pos]: 0
  			}
  		};
  	}

  	// Player is captain/vc and moving to bench
  	if (isAllTrue([player_leader, Boolean(empty_in.position)])) {
  		new_lineup = {
  			...new_lineup,
  			[player_leader]: 0
  		};
  	}

  	delete new_lineup.NaN;
  	removeEmptyBenchPlayers(new_lineup.bench, 0);
  	updateMyTeam({
  		old_lineup: lineup,
  		lineup: new_lineup,
  		id: team.id
  	});

  	this.cancelSubstitute();
	}
	setPlayerAction(player_id: number | null, action: TPlayerAction) {
  	this.setState({
  		modal_action_type: action,
  		modal_action_player: player_id,
  	});
	}
  
	openPlayerPopup(player_id) {
  	const { showModalForPlayerId } = this.props;
  	showModalForPlayerId(player_id);
	}
	removePlayer({ id }: Object) {
  	const { removePlayer, team } = this.props;
  	removePlayer({
  		player_id: id,
  		team_id: team.id
  	});
	}
	get lineup() {
  	const { team: { lineup }, positions, league } = this.props;
  	return OverflowUtils.normaliseLineup(league, lineup, positions);
	}
	renderList(){
  	const {
  		positions,
  		players_by_id,
  		actual_round,
  		player_ids,
  		showModalForPlayerId,
  		team,
  		league,
  		is_static,
  		selected_round,
  		is_current,
  		bye_detector_selected,
  		bye_detector_active,
			rounds,
      
  	} = this.props;
  	const matches = selected_round ? selected_round.matches : [];
    
  	const listViewProps = {
  		positions,
  		players_by_id,
  		actual_round,
  		player_ids,
  		selected_round: this.props.actual_round,
  		showModalForPlayerId,
			rounds,
  		team,
  		league,
  		setCaptain: this.setCaptain,
  		setViceCaptain: this.setViceCaptain,
  		setEmergency: this.setEmergency,
  		substitutePlayer: this.substitutePlayer,
  		cancelSubstitute: this.cancelSubstitute,
  		is_mobile: this.state.is_mobile,
  		areSubstitutesActive: this.areSubstitutesActive,
  		substitute_is_bench: this.state.substitute_is_bench,
  		substitute_id: this.state.substitute_id,
  		isOutSubstitute: this.isOutSubstitute,
  		isInSubstitute: this.isInSubstitute,
  		matches,
  		is_static,
  		is_current,
  		bye_detector_selected,
  		bye_detector_active,
  		score_stat: this.state.field_stat,
  		setPlayerAction: this.setPlayerAction,
  		is_classic: false,
  		field_stat: this.state.field_stat,
  		has_assistant_coach: false,
  		mobile_stat: this.state.field_stat,
  		openPlayerPopup: this.openPlayerPopup,
  		removePlayer: this.removePlayer,
  		squads_by_id: this.props.squads_by_id,
  		openAddPlayerModal: this.props.openAddPlayerModal,
  		closeAddPlayerModal: this.props.closeAddPlayerModal
  	};
		if(!listViewProps.team.lineup){
			listViewProps.team.lineup = this.props.empty_lineup;
		}
  	return (
  		<>
  			<ListViewTeam 
  				{...listViewProps} 
  				no_trade_buttons 
  				is_compressed={true} edit_teams_view={true} />
  			<TeamInfoBlock
  				lineup={this.lineup}
  				positions={positions}
  				is_draft={true}
  				has_captain={_.get(league, "captains")}
  				has_emergencies={_.get(league, "emergency")}
  			/>
  		</>
			
			
  	);
	}
	render(){
  	return(
  		<React.Fragment>
  			{this.renderList()}	
  		</React.Fragment>
  	);
	}
}

const mapStateToProps = (state, props) => {
	const actual_round = selectors.rounds.getActualRound(state);

	const { league_id } = props.match.params;

	const players_by_id = selectors.players.getExtendedPlayersById(state);

	const has_assistant_coach = Boolean(selectors.getUser(state).assistant_coach);

	const league = selectors.leagueDraft.getLeague(state, props);

	const actual_round_id = _.get(actual_round, "id", 0);
	const rounds_with_matches = selectors.rounds.getRoundsWithMatches(state);
	const selected_round = _.find(rounds_with_matches, { id: props.selected_round_id });
	const url_round_id = _.get(props, "match.params.round_id");
	const empty_lineup = selectors.complex.team.getEmptyLineup(state, props);

	return {
		league_id,
		league,
		has_assistant_coach,
		data_loading_complete: _.every([true,
			!state.players.is_pending,
			!state.rounds.is_pending,
			!state.squads.is_pending,
			!state.venues.is_pending,
			!state.leagues.show.is_pending,
			!state.leagues.ladder.is_pending,

		]),
		positions: state.positions.by_id,
		actual_round,
		rounds: rounds_with_matches,
		players_by_id,
		last_round: selectors.rounds.getLastCompleteRound(state),
		ladder_teams: selectors.orderedLadderCalculated(state, props),
		ladder_by_id: selectors.leagues.ladder.getById(state),
		is_team_saving: state.teamsDraft.is_team_saving,
		error_text: state.teamsDraft.error,
		is_static: actual_round_id > props.selected_round_id,
		is_current: actual_round_id <= props.selected_round_id,
		url_round_id,
		user: selectors.getUser(state),
		transaction_history: selectors.getGroupedTransactions(state),
		selected_round,
		empty_lineup,
		squads_by_id: selectors.squads.getSquadsById(state),
	};
};
const mapDispatchToProps = {
	showModalForPlayerId: actions.showModalForPlayerId,
	fetchTeam: actions.teamsDraft.fetchTeam,
	showDraftLeague: actions.leagueDraft.showDraftLeague,
	updateMyTeam: actions.teamsDraft.postTeamUpdate,
	fetchLadder: actions.leagueDraft.ladderDraftLeague,
	removePlayer: actions.teamsDraft.fetchRemovePlayer,
	clearErrors: actions.teamsDraft.clearTeamsDraftErrors,
	fetchTransactionHistory: actions.leagueDraft.fetchTransactionHistory,
	fetchFavourites: actions.fetchFavourites,
	fetchSquads: actions.fetchSquads,
};

export const LeagueEditTeamList = compose(
	withRouter,
	connect(
		mapStateToProps,
		mapDispatchToProps
	)
)(LeagueEditTeamListComponent);