// @flow
import * as React from "react";
import styled from "styled-components";

import { Input, Label, FormGroup } from "../../Form";
import ErrorField from "../ErrorField";

type Props = {
	label: string,
	name: string,
	input: Object,
	type: string,
	placeholder: string,
	tooltip: {
		title: string,
		description: string,
	},
	meta: {
		touched: any,
		error: string,
		warning: string,
	},
	is_disabled: boolean,
	is_first: boolean,
	max_length: number,
	autocomplete?: any,
	autoFocus?: boolean
};

const InputWrapper = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	margin-bottom: .5em;
`;

const ErrorFieldStyled = styled(ErrorField)`
	font-size: 14px;
	margin-top: 10px;
`;

const FormGroupStyled = styled(FormGroup)`
	margin-bottom: 0;
`;

const is_error = meta => {
	return !!(meta.touched && meta.error);
};

export const SimpleFormField = ({
	label,
	name,
	type,
	placeholder,
	input,
	tooltip,
	is_disabled,
	is_first,
	meta: { touched, error, warning },
	max_length,
	autocomplete,
	autoFocus
}: Props ) => (
	<InputWrapper>
		{is_first ? (
			<Label htmlFor={name} is_error={is_error({ touched, error })}>
				{label}
			</Label>
		) : null}

		<FormGroupStyled>
			<Input
				input={input}
				name={name}
				type={type}
				max_length={max_length}
				placeholder={placeholder}
				is_disabled={is_disabled}
				is_error={is_error({ touched, error })}
				autocomplete={autocomplete}
				autoFocus={autoFocus}
			/>
		</FormGroupStyled>
		{ touched && ((
			error && <ErrorFieldStyled>{error}</ErrorFieldStyled>) ||
			(warning && <ErrorFieldStyled>{warning}</ErrorFieldStyled>
			))
		}
	</InputWrapper>
);

export default SimpleFormField;