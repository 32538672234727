import React from "react";
import HelpContent from "./help/helpContent";
import { sections } from "./help/sections";

const FantasyCoachTerms = props => (
	<React.Fragment>
		<HelpContent section={sections["fantasy-coach-terms-and-conditions"]} />
	</React.Fragment>
);

export default FantasyCoachTerms;