// @flow
import React from "react";
import _ from "lodash";
import styled from "styled-components";

import { Group } from "@vx/group";
import { GlyphDot } from "@vx/glyph";
import { Line } from "@vx/shape";
import { Point } from "@vx/point";
import { Text } from "@vx/text";
import { scaleLinear, scaleBand } from "@vx/scale";
import { AxisLeft, AxisBottom } from "@vx/axis";
import { GridRows } from "@vx/grid";
import { withTooltip, Tooltip } from "@vx/tooltip";
import { withParentSize } from "@vx/responsive";

import type { TRound } from "../../modules/types";

import colors from "../../assets/css/colors";
import getPlayerUrl from "../../utils/playerUrl";
import { default_transition } from "../../assets/css/vars";

const GraphSVG = styled.svg`
	.vx-axis.vx-axis-left {
		.vx-axis-tick {
			text {
				font-family: inherit;
				font-weight: 500;
				fill: #8D979C;
			}
		}
	}
`;

const GraphWrapper = styled.div`
	position: relative;

	div.vx-tooltip-portal:before{
		content:'';
		display: block;
		width: 0;
		height: 0;
		position: absolute;

		border-top: 4px solid transparent;
		border-bottom: 4px solid transparent;
		border-${({ right_align }) => right_align ? "right" : "left"}: 4px solid black;
		${({ right_align }) => right_align ? "left" : "right"}: -4px;
		top: 8px;
	}
`;

const TooltipText = styled.div`
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 100px;
`;

const ImageWrapper = styled.g`
	> image {
		height: ${({ is_small }) => is_small ? 16 : 24}px;
		width: ${({ is_small }) => is_small ? 16 : 24}px;
		transform: ${({ is_small }) => 
		`translate(-${is_small ? 8 : 12}px, -${is_small ? 9 : 14}px);`};
	}
`;

const LineGroup = styled.g`
	${default_transition}
	&:hover {
		opacity: 1!important;
	}
`;

const LineGroups = styled.g`
	&:hover > ${LineGroup} {
		opacity: 0.1;
	}
`;

const AxisText = styled.text`
	font-size: 10px;
	font-weight: 500;
	fill: #8D979C;
	transform: translateX(-6px) translateY(4px);
`;

const getAvatarRound = player => (
	_.last(_.filter(player.scores, score => !score.is_projected))
	|| _.first(_.filter(player.scores, score => score.is_projected))
);

const renderPlayerAvatar = (player, x, y, is_small) => {
	const data_point = getAvatarRound(player);

	if (!data_point || _.isNumber(data_point.value) === false) return null;

	const cx = x(data_point);
	const cy = y(data_point);

	const color = player.color;
	const player_url = getPlayerUrl(player.id, true);

	return (
		<ImageWrapper is_small={is_small}>
			<GlyphDot
				cx={cx}
				cy={cy}
				r={is_small ? 8 : 12}
				fill={"white"}
				stroke={color}
				strokeWidth={1}
			/>
			<image
				y={cy}
				x={cx}
				xlinkHref={player_url}
			>
				<title>{player.name}</title>
			</image>
			<GlyphDot
				cx={cx}
				cy={cy}
				r={is_small ? 7 : 11}
				fill={"#00000000"}
				stroke={"white"}
				strokeWidth={1}
			/>
		</ImageWrapper>
	);
};

const renderScorePoints = (player, x, y) => (data_point, i) => {
	if (!data_point || _.isNumber(data_point.value) === false) return null;

	const cx = x(data_point);
	const cy = y(data_point);

	const color = data_point.is_projected ? colors.coach : player.color;

	return (
		<g key={`line-point-actual-${i}`}>
			<GlyphDot
				cx={cx}
				cy={cy}
				r={5}
				fill={color}
				stroke={color}
				strokeWidth={data_point}
				opacity={data_point.is_projected ? 0.8 : 1}
			/>
		</g>
	);
};

const renderScoreLines = (player, x, y) => (data_point, i) => {
	if (!data_point || _.isNumber(data_point.value) === false) return null;

	const current_x = x(data_point);
	const current_y = y(data_point);
	const current_is_projected = data_point.is_projected;

	const previous = player.scores[i-1];

	if (!previous) {
		return null;
	}

	const previous_x = x(previous);
	const previous_y = y(previous);

	const current_point = new Point({ x: current_x, y: current_y });
	const previous_point = new Point({ x: previous_x, y: previous_y });

	const color = data_point.is_projected ? colors.coach : player.color;

	return (
		<g key={`line-${i}`}>
			<Line
				stroke={color}
				strokeWidth={3}
				strokeDasharray={current_is_projected ? "4,2" : "" }
				opacity={current_is_projected ? 0.5 : 1}
				from={previous_point}
				to={current_point}
			/>
		</g>
	);
};

// accessors
const round = d => d.round;
const value = d => d.value;

let tooltip_timeout;

type Props = {
	parentWidth: number,
	parentHeight: number,
	rounds: Array<TRound>,
	data: Array<Object>,
	tooltipOpen: boolean,
    tooltipLeft: number,
    tooltipTop: number,
    tooltipData: any,
    hideTooltip: Function,
    showTooltip: Function
}

const Graph = ({
	parentWidth, parentHeight, data,
	tooltipOpen, tooltipLeft, tooltipTop, tooltipData = {}, hideTooltip, showTooltip
}: Props) => {
	const joint_player_data = data.reduce((acc, player) => {
		return [ ...acc, ...player.scores];
	}, []);

	// scales
	const xScale = scaleBand({
		domain: _.range(1, 24)
	});

	const yScale = scaleLinear({
		domain: [
			Math.min(...joint_player_data.map(value), 0),
			Math.max(...joint_player_data.map(value), 200)
		]
	});

	// positions
	const x = d => xScale(round(d));
	const y = d => yScale(value(d));

	const is_small = parentWidth < 700;

	const margin = {
		top: 20,
		right: is_small ? 40 : 60,
		bottom: is_small ? 60 : 90,
		left: is_small ? 40 : 80
	};
	const width = parentWidth - margin.left - margin.right;
	const height = parentHeight - margin.top - margin.bottom;

	// bounds
	const xMax = width;
	const yMax = height;

	// update scale range to match bounds
	xScale.range([20, xMax + 40]);
	yScale.range([yMax, 0]);

	const player_scores = data.map((player, i) => {
		const scores = _.get(player, "scores", []);

		return <LineGroup
			key={i}
			onMouseEnter={event => {
				if (tooltip_timeout) clearTimeout(tooltip_timeout);
				const data_point = getAvatarRound(player);
				const right_align = data_point.round <= 6;
				const left = Math.round(x(data_point) - (
					right_align
						? (is_small ? -56 : -98)
						: (is_small ? 88 : 55)
				));
				const top = Math.round(y(data_point) + 7);
				showTooltip({
				  tooltipData: {
					  text: _.get(player, "name", ""),
					  right_align
				  },
				  tooltipTop: top,
				  tooltipLeft: left
				});
			  }}
			onMouseLeave={event => {
				tooltip_timeout = setTimeout(() => {
					hideTooltip();
			  }, 300);
			}}
		>
			{
				_.map(scores, renderScoreLines(player, x, y))
			}
			{
				_.map(scores, renderScorePoints(player, x, y))
			}
			{
				renderPlayerAvatar(player, x, y, is_small)
			}
		</LineGroup>;
	});

	return <GraphWrapper right_align={tooltipData.right_align}>
		<GraphSVG
			width="100%"
			height={height + margin.bottom}
		>
			<Group left={margin.left} top={margin.top}>
				<AxisLeft
					scale={yScale}
					hideTicks
					hideAxisLine
					numTicks={9}
				/>
				<AxisBottom
					scale={xScale}
					top={10}
					tickValues={is_small ? _.range(1, 24, 2) : undefined}
				>
					{axisData => (
						<React.Fragment>
							{axisData.ticks.map(tick => {
								const round_id = tick.value;
								return <AxisText
									key={round_id}
									y={yMax + 10}
									x={xScale(round_id)}
								>
									{round_id}
								</AxisText>;
							})}
						</React.Fragment>
					)}
				</AxisBottom>
				<GridRows
					className="row-grid"
					scale={yScale}
					width={xMax}
					height={yMax}
					stroke="#fff"
					strokeWidth={2}
				/>

				<LineGroups>
					{player_scores}
				</LineGroups>

				{ !is_small && <React.Fragment>
					<g>

						<rect
							x={-65}
							y={yScale(140)}
							width={30}
							height={yScale(60) - yScale(140)}
							rx={15} ry={15}
							fill='white'
							stroke="#CAD2D8"
							strokeWidth={1}
						/>
						<Text
							x={-45}
							y={yScale(90)}
							angle={-90}
							style={{
								fill: "#CAD2D8",
								fontSize: "14px"
							}}
						>
							Score
						</Text>
					</g>

					<g>
						<rect
							x={xScale(10)}
							y={yMax + 35}
							width={xScale(14) - xScale(10)}
							height={30}
							rx={15} ry={15}
							fill='white'
							stroke="#CAD2D8"
							strokeWidth={1}
						/>
						<Text
							x={xScale(11) + 16}
							y={yMax + 55}
							style={{
								fill: "#CAD2D8",
								fontSize: "14px"
							}}
						>
							Round
						</Text>
					</g>
				</React.Fragment>}
			</Group>
		</GraphSVG>
		{tooltipOpen && <Tooltip
			top={tooltipTop}
			left={tooltipLeft}
			style={{
				backgroundColor: "rgba(0,0,0,0.9)",
				color: "white",
				maxWidth: "100px"
			}}
		>
			<TooltipText>
				{tooltipData.text}
			</TooltipText>
		</Tooltip>}

	</GraphWrapper>;
};

export default withParentSize(withTooltip(Graph));