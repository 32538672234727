// @flow
import { get, isEmpty } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import {
	Ad,
	AdsContainer,
	ButtonPrimary,
	Footer,
	Label,
	LeagueGameBar,
	PageContentWrapper,
	PageTitle,
	Preloader,
	StandardSidebar,
	Tooltip,
	TwoColumnLayout,
} from "../../../components";
import FormGroup from "../../../components/Form/FormGroup/index";
import Input from "../../../components/Form/Input/index";
import * as actions from "../../../modules/actions";
import {
	changeDraftTeamNameError,
	isChangeDraftTeamNameLoading,
	isChangeDraftTeamNameSuccess,
} from "../../../modules/selectors/teamsDraft";

const SubTitle = styled.h3`
	color: #1D4073;
	font-family: TitilliumUpright;
	font-size: 24px;
	font-weight: 700;
	letter-spacing: 0;
	line-height: 24px;
	margin-bottom: 45px;
`;

const Form = styled.form`
	display: block;
	max-width: 440px;
	width: 100%;
	
	button {
		margin-top: 45px;
	}
	
	${FormGroup} {
		width: 100%;
		justify-content: space-between;
	}
`;

const Error = styled.p`
	color: red;
	font-family: TitilliumUpright;
	font-size: 18px;
	font-weight: 700;
	letter-spacing: 0;
	line-height: 24px;
	margin-bottom: 45px;
`;

const text = {
	base: {
		title: "Update your team name",
		subtitle: "You can change your name at any point",
		button_title: "Edit Team Name"
	},
	create: {
		title: "Give your team a name",
		subtitle: "You’ve created your league, now give your team a name!",
		button_title: "Set up your pre-draft list"
	},
	join: {
		title: "Give your team a name",
		subtitle: "You’ve joined this league, now give your team a name!",
		button_title: "Set up your pre-draft list"
	}
};

const getButtonTitle = ({isSaving, isSaved, btn_copy}) => {

	if (isSaving) {
		return "Saving...";
	}

	if (isSaved) {
		return "Team Name Saved";
	}

	return btn_copy;
};

type Props = { team_id: number, league_team_name: string, btn_copy: string }

const LeagueNameForm = (
	{ team_id, league_team_name, btn_copy}: Props
) => {
	
	const dispatch = useDispatch();
	const [team_name, setTeamName] = useState(league_team_name);
	const [isDisabled, setDisabled] = useState(isEmpty(team_name));
	const error = useSelector(changeDraftTeamNameError);
	const isSaving = useSelector(isChangeDraftTeamNameLoading);
	const isSaved = useSelector(isChangeDraftTeamNameSuccess);
	const button_title = getButtonTitle({isSaving, isSaved, btn_copy});
	
	const onSubmit = useCallback(event => {
		event.preventDefault();
		event.preventDefault();
		dispatch(actions.updateUserTeamName({
			id: team_id,
			name: team_name,
		}));
	}, [dispatch, team_name, team_id]);
	
		

	const handleChange = event => {
		const name = event.target.value;
		setTeamName(name);
		setDisabled(name.length < 2 || name.length > 40);

		if (error || isSaved) {
			dispatch(actions.updateUserTeamNameReset());
		}

	};
	return (
		<Form onSubmit={onSubmit}>
			<FormGroup>
				<Label htmlFor="team_name">Display Name</Label>
				<Tooltip
					description={`The name you enter here will only be used in this Draft league. 
					It can be different to your Classic team name, and if you’re in multiple 
					Draft leagues you can have a different name in each one! The maximum 
					length is 40 characters, the minimum length is 2.`}
				/>
			</FormGroup>

			<Input
				input={{ onChange: handleChange, defaultValue: league_team_name }}
				type="text" id="team-name" name="team_name"
				placeholder="Enter a team name" />
			<ButtonPrimary disabled={isDisabled || isSaving} type="submit" onClick={onSubmit}>
				{button_title}
			</ButtonPrimary>
		</Form>
	);
};

export const LeagueNameChange = () => {
	const dispatch = useDispatch();
	const { league_id, from } = useParams();
	const league = useSelector(state => get(state.leagues.by_id, league_id, {}));
	const isSaved = useSelector(isChangeDraftTeamNameSuccess);
	const error = useSelector(changeDraftTeamNameError);
	const history = useHistory();
	const labels = text[from || "base"];

	useEffect(() => {
		dispatch(actions.leagueDraft.showDraftLeague({ id: league_id }));
	}, [dispatch, league_id]);

	useEffect(() => {
		if (isSaved && from) {
			history.push(`/draft/league/${league_id}`);
		}
	}, [from, isSaved, history, league_id, dispatch]);

	if (!league.team_id || !league.team_name) {
		return <Preloader />;
	}
	

	return (
		<div>
			<LeagueGameBar />
			<AdsContainer>
				<Ad />
			</AdsContainer>
			<PageContentWrapper>
				<PageTitle>
					{labels.title}
				</PageTitle>
				<SubTitle>{labels.subtitle}</SubTitle>
				{error && <Error>{error}</Error>}
				<TwoColumnLayout>
					<LeagueNameForm
						team_id={league.team_id}
						btn_copy={labels.button_title}
						league_team_name={league.team_name}
					/>
					<StandardSidebar />
				</TwoColumnLayout>
			</PageContentWrapper>
			<Footer />
		</div>
	);
};

export default LeagueNameChange;