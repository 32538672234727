// @flow
import { isEmpty } from "lodash";

import type { TStateWithVenues } from "../../types";

export const getVenues = (state: TStateWithVenues): Array<Object> =>
	state.venues.ordered_ids.map(item => state.venues.by_id[item]);

export const getVenuesById = (state: TStateWithVenues): Object => state.venues.by_id;

export const hasVenues = (state: TStateWithVenues): boolean =>
	isEmpty(state.venues.ordered_ids) === false;