// @flow
import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import * as actions from "../modules/actions";
import type { TRootStore, TUser } from "../modules/types";
import { Preloader } from "../components";
import { getLogoutParams } from "../helpers";
import { REACT_APP_SSO_URL } from "../modules/constants";

type Props = {
	logout: Function,
	user: TUser
}
class LogoutPage extends React.Component<Props> {
	componentDidMount() {
		this.props.logout();
	}

	render() {
		const { user } = this.props;
		const id_token = localStorage.getItem("idt");

		if (!_.isEmpty(user)) {
			return <Preloader />;
		}

		if(id_token) {
			const logoutURL = 
			`${REACT_APP_SSO_URL}/logout${getLogoutParams(id_token)}`;
			window.location.assign(logoutURL);
		}
		else {
			// Hard redirect so that the store is cleared
			window.location.replace("/");
		}
		return null;
	}
}
const mapStateToProps = (state: TRootStore) => ({
	user: state.user.data
});
const mapDispatchToProps = {
	logout: actions.logout,
};

export const Logout = connect(
	mapStateToProps,
	mapDispatchToProps,
)(LogoutPage);
export default Logout;