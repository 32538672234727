// @flow
import * as _ from "lodash";
import { call, put, takeLatest } from "redux-saga/effects";
import * as actions from "../../actions/index";

export const createSagas = (API: Object): Object => {

	function* getNotificationBar({ payload }: Object) {
		try {
			const response = yield call(API.notification_bar);
			const show = _.get(response, "show");
			const content = _.get(response, "text");

			if (_.isNil(show) || _.isNil(content)) {
				throw new Error(
					`Unable to fetch notification bar - response: ${JSON.stringify(response)}`
				);
			}

			yield put(actions.getNotificationBarSuccess({ show, content }));
		}
		catch (error) {
			yield put(actions.getNotificationBarFailed(error.message));
		}
	}

	function* getNotificationBarDraftSaga() {
		try {
			const response = yield call(API.notification_bar_draft);
			const show = _.get(response, "show");
			const content = _.get(response, "text");

			if (show) {
				yield put(actions.getNotificationDraftBarSuccess({ show, content }));
			}

		}
		catch (error) {
			yield put(actions.getNotificationDraftBarFailed(error.message));
		}
	}

	function* getWinners({ payload }: Object) {
		try {
			const response = yield call(API.winners);

			if (_.isNil(response)) {
				throw new Error(
					`Unable to fetch notification bar - response: ${JSON.stringify(response)}`
				);
			}

			yield put(actions.getWinnersSuccess(response));
		}
		catch (error) {
			yield put(actions.getWinnersFailed(error.message));
		}
	}

	function* watch() {
		yield takeLatest(actions.getNotificationBar, getNotificationBar);
		yield takeLatest(actions.getWinners, getWinners);
		yield takeLatest(actions.getNotificationDraftBar, getNotificationBarDraftSaga);
	}

	return {
		getNotificationBar,
		getWinners,
		watch
	};
};

export default createSagas;