// @flow
import styled, { css } from "styled-components";
import * as React from "react";
import connect from "react-redux/es/connect/connect";
import _ from "lodash";
import colors from "../../assets/css/colors";
import Caution from "../Icons/Caution";
import Tick from "../Icons/Tick";
import type { TPosition, TRootStore } from "../../modules/types";
import * as selectors from "../../modules/selectors";
import IconCircled from "../IconCircled";
import { below } from "../../assets/css/media";
import { SaveTeamButton } from "../../components/MyTeam/TopControls";
import Warning from "../Icons/Warning";
import TickCircle from "../Icons/TickCircle";
import { isAllTrue } from "../../helpers";
import { Exist } from "../Exist";

const InfoBlock = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	box-sizing: border-box;
	/* padding: ${({ is_draft }) => is_draft ? "0" :  "0 20px 20px"} ; */

	${({ is_draft }) => is_draft && "align-items: center"};
	background: ${({ is_draft }) => is_draft ? "white" : "#F8F8FA" };

	${below.desktop`
		display: none;
	`};
`;

const InfoItemsWrapper = styled.div`
	width: 100%;
	height: 60px;
	display: flex;
	justify-content:  ${({ is_draft }) => is_draft ? "space-between" : "space-around" };
	width: ${({ is_draft }) => is_draft ? "779px" : "auto" };
	align-items: center;
	border-radius: 2px;
`;
const TeamStatus = styled.div`
	width: 85px;
	font-size: 12px;
	line-height: 1.16;
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: ${colors.secondary.accentGrey};
`;


const InfoItem = styled.div`
	font-size: 12px;
	line-height: 1.16;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: ${({ is_short_name, is_draft }) => is_draft ? "0" : is_short_name ? "5px" : "10px"};
	color:  ${({ is_full, is_draft }) => 
		is_draft && is_full ? colors.primary.primary : colors.secondary.accentGrey};
	${({ is_draft }) => is_draft && "font-family: SourceSansPro;"}
	${({ is_draft }) => !is_draft && "text-transform: uppercase;"}
	
	p {
		margin-right: ${({ is_draft }) => is_draft ? "0" : "5px"};
		margin-left: ${({ is_draft }) => is_draft ? "5px" : "0"};
		padding-top: 3px;
		font-weight: ${({ is_draft }) => is_draft ? "600" : "400"};
		> span {
			font-weight: ${({ is_draft }) => is_draft ? "600" : "300"};
		}
		${({ is_short_name }) => is_short_name && css`
			text-transform: uppercase;
		`}
	}

`;

const StyledNote = styled.p`
font-size: 12px;
color:  ${({ is_full, is_draft }) => 
		is_draft && is_full ? colors.primary.primary : colors.secondary.accentGrey};
${({ is_draft }) => is_draft && "font-family: SourceSansPro;"}
${({ is_draft }) => !is_draft && "text-transform: uppercase;"}
margin-right: ${({ is_draft }) => is_draft ? "0" : "5px"};
		margin-left: ${({ is_draft }) => is_draft ? "5px" : "0"};
		padding-top: 3px;
		font-weight: ${({ is_draft }) => is_draft ? "600" : "400"};
		> span {
			font-weight: ${({ is_draft }) => is_draft ? "600" : "300"};
		}
		${({ is_short_name }) => is_short_name && css`
			text-transform: uppercase;
		`}
`;
const StyledSaveTeamButton = styled(SaveTeamButton)`
	height: 40px;
	padding: 0;
`;


type Props = {
	lineup: Object,
	positions: Array<TPosition>,
	is_team_saving: boolean,
	is_team_saved: boolean,
	is_draft?: boolean,
	has_captain?: boolean,
	has_emergencies?: boolean,
	short_name?: boolean,
	extra_small?: boolean,
	emergency_limit?: number,
}

const WarningClassic = ({ param }) => param ? (
	<IconCircled background="#7ed322" size="14px">
		<Tick color="#fff" />
	</IconCircled>
) : (
	<Caution color={"red"} size={1}/>
);

const WarningDraft = ({ param }) => param ? (
	<TickCircle size={1.3} viewbox={16} color={colors.primary.primary} />
) : (
	<Warning size={1.3}/>
);

export class TeamInfoBlockComponent extends React.Component<Props> {
	static defaultProps = {
		is_draft: false,
		has_captain: true,
		has_emergencies: true,
		extra_small: false,
	};

	constructor(props: Props) {
		super(props);

		_.bindAll(this, [
			"getItemContent",
		]);
	}

	getPositionFullFill(line: Array<number>) {
		const full_amount = _.size(line);
		const full_filled_amount = _.size(_.filter(line, i => i !== 0));

		return {
			full_amount,
			full_filled_amount,
			is_full: full_amount === full_filled_amount,
		};

	}

	getItemContent({ id, full_name, short_name }: TPosition) {
		const { lineup = {}, short_name: is_short_name, is_draft } = this.props;

		if (_.isEmpty(lineup)) {
			return null;
		}

		const {
			full_amount,
			full_filled_amount,
			is_full,
		} = this.getPositionFullFill(lineup[id]);
		return <InfoItem key={id} is_short_name={is_short_name} 
			is_full={is_full} is_draft={is_draft}>
			{is_draft && <WarningDraft param={is_full} />}
			<p>
				{is_short_name ? short_name : full_name}
				{" "}
				<span>{full_filled_amount}/{full_amount}</span>
			</p>
			{!is_draft && <WarningClassic param={is_full} />}
		</InfoItem>;
	}


	get emergencies() {
		const { lineup = {}, is_draft, short_name, emergency_limit } = this.props;

		const emgs = is_draft ? lineup.emergency : lineup.bench.emergency;

		if (_.isEmpty(lineup)) {
			return null;
		}

		let {
			full_amount,
			full_filled_amount,
			is_full,
		} = this.getPositionFullFill(emgs);
		if(isAllTrue([Boolean(is_draft), Boolean(emergency_limit)])){
			full_amount = emergency_limit;
		}


		const is_emergency_full = full_amount === full_filled_amount;

		return <InfoItem is_short_name={short_name} is_full={is_full} is_draft={is_draft}>
			<Exist when={Boolean(is_draft)}>
				<WarningDraft param={is_emergency_full} />
			</Exist>
			<p>
				{short_name ? "Emg" : "Emergencies"} <span>{full_filled_amount}/{full_amount}</span>
			</p>
			<Exist when={!is_draft}>
				<WarningClassic param={is_full} />
			</Exist>
		</InfoItem>;
	}

	get save_button_text() {
		const { is_team_saving, is_team_saved } = this.props;
		if (is_team_saving) {
			return <TeamStatus>Saving...</TeamStatus>;
		}

		return is_team_saved ? (
			<TeamStatus>Saved <Tick size={1.5}/></TeamStatus>
		) : null;
	}

	get utility_block() {
		const { lineup = {} } = this.props;
		const { bench: { utility } } = lineup;
		const is_full = utility.id !== 0;

		return (
			<InfoItem>
				<p>
					Utl <span>{is_full ? 1 : 0}/1</span>
				</p>
				<WarningClassic param={is_full} />
			</InfoItem>
		);
	}

	
	renderLeaders() {
		const {
			lineup,
			has_captain,
			has_emergencies,
			short_name,
			is_draft
		} = this.props;
		return <React.Fragment>
			<Exist when={Boolean(has_emergencies)}>
				{this.emergencies}
			</Exist>
			<Exist when={Boolean(has_captain)}>
				<InfoItem is_short_name={short_name} 
					is_full={lineup.captain} is_draft={is_draft}>
					{is_draft && <WarningDraft param={lineup.captain} />}
					<p>{short_name ? "Cap" : "Captain"}</p>
					{!is_draft && <WarningClassic param={lineup.captain} />}
				</InfoItem>
				<InfoItem is_short_name={short_name} 
					is_full={lineup.vice_captain} is_draft={is_draft}>
					{is_draft && <WarningDraft param={lineup.vice_captain} />}
					<p>{short_name ? "VC" : "Vice Captain"}</p>
					{!is_draft && <WarningClassic param={lineup.vice_captain} />}
				</InfoItem>
			</Exist>
		</React.Fragment>;
	}

	render() {
		const {
			positions,
			lineup,
			is_draft,
			extra_small,
			is_team_saving
		} = this.props;
		if (_.isEmpty(lineup)) {
			return null;
		}

		return (
			<>
				<InfoBlock is_draft={is_draft}>
					<InfoItemsWrapper is_draft={is_draft}>
						{_.map(positions, this.getItemContent)}
						{ !is_draft ? this.utility_block : null }
						{ !extra_small && this.renderLeaders() }
					</InfoItemsWrapper>
					{is_draft && <StyledSaveTeamButton 
						onClick={() => null}
						is_saved={true}
						is_saving={is_team_saving}
					/>}
				
				</InfoBlock>
				{is_draft ? <StyledNote>In AFL Fantasy Draft you can only select 1 emergency per position</StyledNote>: null}
			
			</>
		);
	}
}

const mapStateToProps = (state: TRootStore) => ({
	is_team_saving: selectors.isMyTeamSaving(state),
	is_team_saved: selectors.isMyTeamSaved(state)
});

export const TeamInfoBlock = connect(
	mapStateToProps
)(TeamInfoBlockComponent);
export default TeamInfoBlock;