// @flow
import { call, put, takeLatest } from "redux-saga/effects";
import * as actions from "../../actions";

export const createSagas = (API: Object): Object => {
	function* fetchRounds() {
		try {
			const result = yield call(API.rounds.fetch);
			yield put(actions.fetchRoundsSuccess(result));
		}
		catch (e) {
			yield put(actions.fetchRoundsFailed(e.message));
		}
	}

	function* fetchPastRounds({ payload: year }) {
		try {
			const result = yield call(API.rounds.fetch_past_year, { year });
			yield put(actions.fetchPastRoundsSuccess({ year, result }));
		}
		catch (e) {
			yield put(actions.fetchPastRoundsFailed(e.message));
		}
	}


	function* watch() {
		yield takeLatest(actions.fetchRounds, fetchRounds);
		yield takeLatest(actions.fetchPastRounds, fetchPastRounds);
	}

	return {
		fetchRounds,
		fetchPastRounds,
		watch,
	};
};


export default createSagas;