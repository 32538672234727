// @flow
import * as React from "react";
import styled from "styled-components";
import Arrow from "../Icons/Arrow";

const WidgetHeaderCloseBtnWrapper = styled.button`
	background:#8D979C;
	width: 40px;
	height: 40px;
	border: none;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #FFF;
	cursor: pointer;
	outline: none;
	
	svg {
		min-width: 32px;
		min-height: 32px;
	}
`;

const defaultProps = {
	open: true,
	onClick: () => {}
};

export const WidgetHeaderCloseBtn = ({ open, onClick }: typeof defaultProps) => (
	<WidgetHeaderCloseBtnWrapper onClick={onClick}>
		<Arrow direction={open ? "up" : "down"}/>
	</WidgetHeaderCloseBtnWrapper>
);

WidgetHeaderCloseBtn.defaultProps = defaultProps;