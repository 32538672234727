// @flow
import styled from "styled-components";

export const BarItem = styled.div`
	display: flex;
	align-items: center;
	height: 100%;
	width: 100%;
`;

export default BarItem;