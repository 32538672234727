// @flow
import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import get from "lodash/get";

import * as actions from "../../../modules/actions";

type Props = {
	fetchMyDraftLeagues: typeof actions.leagueDraft.showMyDraftLeagues,
	my_draft_leagues: Array<number>,
	fetch_pending: boolean,
	favourite: Object
};

type State = {
	redirect_url: string
}

export class LeaguePage extends React.Component<Props, State> {

	state = {
		redirect_url: ""
	};

	componentDidMount() {
		const { fetchMyDraftLeagues, my_draft_leagues, favourite } = this.props;
		// if Fav exisit go to that league directly.
		if(
			get(favourite, "game") === "draft" &&
			get(favourite, "type") === "league" &&
			get(favourite, "id")) {
			this.setState({ redirect_url: `/draft/league/${favourite.id}` });
		}
		// if no draft leagues in store to redirect to in fallback
		// we need to fetch in order to find default league [0] of list
	
		else if(my_draft_leagues.length < 1) {
			fetchMyDraftLeagues();
		}
		else {
			this.setState({ redirect_url: `/draft/league/${my_draft_leagues[0]}` });
		}
	}

	componentDidUpdate(prev_props: Object) {
		const { my_draft_leagues, fetch_pending } = this.props;

		// we are here if we need to fetch draft leagues
		if(prev_props.fetch_pending === true && fetch_pending === false) {
			// fetch complete
			if(my_draft_leagues.length === 0) {
				// still no draft league after the fetch => go to leagues join
				this.setState({ redirect_url: "/draft/leagues/join" });
			}
			else {
				this.setState({ redirect_url: `/draft/league/${my_draft_leagues[0]}` });
			}
		}
	}

	render() {
		if(this.state.redirect_url) {
			return (
				<Redirect to={this.state.redirect_url} />
			);
		}
		return (
			<div>loading</div>
		);
	}
}


const mapStateToProps = (state, props) => ({
	my_draft_leagues: state.leagues.show_my.ordered_ids,
	fetch_pending: state.leagues.show_my.is_pending,
	favourite: get(state,`uiGameSelection.favourite[${get(state, "user.data.id")}]`)
});

const mapDispatchToProps = {
	fetchMyDraftLeagues: actions.leagueDraft.showMyDraftLeagues,
};


export default connect(mapStateToProps, mapDispatchToProps)(LeaguePage);