// @flow
import * as React from 'react';
import * as _ from "lodash";
import type { TPlayersById, TPLeague } from "../../modules/types";
import {
	TradeInCell,
	TransactionPlayers,
	TransactionPlayersWrapper,
	ButtonPrimary,
	ButtonAlert,
} from "../";
import { TransactionButtonsWrapper } from "./TransactionButtonsWrapper";

type Props = {
	players_by_id: TPlayersById,
	trade: Object,
	blockTrade: Function,
	approveTrade: Function,
	league: TPLeague,
	user: Object,
	vote: 'yes' | 'no' | typeof undefined
}
export const TradeProposed = ({ 
	trade, 
	players_by_id, 
	blockTrade, 
	approveTrade, 
	vote, 
	league, 
	user 
}: Props) => {
	const {
		id: 
			trade_id, new_player_id, old_player_id, new_team_name, old_team_name, status
	} = trade;
	const dontShowControls = league.commissioner && 
		league.approve_trade && 
		league.commissioner !== _.get(user, 'id') && 
		league.approve_trade === "commissioner";

	const highestNumberInTrade = Math.max(old_player_id.length, new_player_id.length);
	if(dontShowControls === undefined){
		return null;
	}
	const trade_controls = (status === "accepted" || dontShowControls) ? null : (
		<React.Fragment>
			<ButtonPrimary 
				onClick={() => approveTrade({ trade_id })}
				disabled={vote !== undefined}
			>
				Approve trade
			</ButtonPrimary>
			<ButtonAlert 
				inverted 
				onClick={() => blockTrade({ trade_id })}
				disabled={vote !== undefined}
			>
				Reject trade
			</ButtonAlert>
		</React.Fragment>);
	
	return (
		<React.Fragment>
			<TransactionPlayersWrapper>
				<TransactionPlayers>
					<p>
						{new_team_name} has offered:
					</p>
					{new_player_id.map(
						id => (<TradeInCell 
							key={id} 
							player={players_by_id[id]}
							is_classic={false}
						/>)
					)}
				</TransactionPlayers>
				<TransactionButtonsWrapper numTrades={highestNumberInTrade}>
					{trade_controls}
				</TransactionButtonsWrapper>
				<TransactionPlayers>
					<p>
						To {old_team_name}, for:
					</p>
					{old_player_id.map(id => (
						<TradeInCell 
							key={id} 
							player={players_by_id[id]} 
							is_classic={false}
						/>
					))}
				</TransactionPlayers>
			</TransactionPlayersWrapper>
			<TransactionButtonsWrapper show_mobile>
				{trade_controls}
			</TransactionButtonsWrapper>
		</React.Fragment>
	);
};

export default TradeProposed;