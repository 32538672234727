// @flow
import styled from "styled-components";
import React from "react";
import colors from "../../../../assets/css/colors";

const TeamWarningWrapper = styled.p`
	color: white;
	text-align: center;
	background-color: ${colors.buttons.cancel};
	border-radius: 2px;
	font-weight: 200;
	padding: 21px 10px 20px;
	font-size: 14px;
	margin: 10px 0;
	font-family: SourceSansPro;

	.requiredAction {
		font-weight: 600;
	}
`;

export const OverflowBanner = (props: Object) => (
	<TeamWarningWrapper {...props}>
		Your team has more players than the squad size
		allows in this league, resulting in your team being
		in an invalid state!
		{" "}
		<span className="requiredAction">
		You have until lockout to delist enough players to be
		at the correct squad size!
		</span>
		{" "}
		Your team will NOT score unless you can resolve
		your squad size.
	</TeamWarningWrapper>
);

export const EmptyPositionBanner = (props: Object) => (
	<TeamWarningWrapper {...props}>
		Your team has an empty position on the field.
		{" "}
		<span className="requiredAction">
		Please fill the empty position with a
		Free Agent or Restricted Free Agent.
		</span>
		{" "}
		Your team will still score, but will
		not have a score in the empty position.
	</TeamWarningWrapper>
);

export const EmptyEmergencyBanner = (props: Object) => (
	<TeamWarningWrapper {...props}>
		Your team does not have enough players.
		{" "}
		<span className="requiredAction">
		Please fill the empty position with a
		Free Agent or Restricted Free Agent.
		</span>
		{" "}
		Your team will still score, but will
		not have a score in the empty position.
	</TeamWarningWrapper>
);