// @flow
import * as React from "react";
import styled from "styled-components";

import colors from "../../assets/css/colors";
import { below } from "../../assets/css/media";

import AFLLogoImg from "../../assets/images/afl-logo.svg";

const Container = styled.div`
	box-sizing: border-box;
	margin: 5px 1px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 10px;
	min-width: 117px;

	${below.phone`
		padding: 2px;
		min-width: auto;
		width: 30px;
	`}
`;

const Round = styled.div`
	font-size: 16px;
	font-weight: 600;
	color: ${colors.primary.primary};
	white-space: nowrap;

	${below.phone`
		font-size: 10px;
		transform: rotate(-90deg);
	`}
`;

const AFLLogo = styled.img`
	display: block;
	margin-bottom: 5px;
	width: 50px;

	${below.phone`
		display: none;
	`}
`;

type Props = {
	round_id: number
}

const RoundPanel = ({ round_id }: Props) =>
	<Container id={`R-${round_id}`}>
		<AFLLogo src={AFLLogoImg} alt="AFL logo" />
		<Round>Round {round_id}</Round>
	</Container>;

export default RoundPanel;