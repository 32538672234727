import * as React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { bindAll } from "lodash";
import * as actions from "../../../../modules/actions";
import { InputItem, ButtonPrimary, Exist } from "../../../../components";
import CloseCircle from "../../../../components/Icons/CloseCircle";
import TickCircle from "../../../../components/Icons/TickCircle";
import { below } from "../../../../assets/css/media";



const ConfirmedButton = styled.p`
	background: none;
	border: none;
	color: #4EA431;
	font-family: SourceSansPro;
	font-size: 12px;
	font-weight: 400;
	text-align: center;
	min-width: 91.5px;
`;


const FormWrapper = styled.form`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding-right: 10px;
  input{
    font-family: SourceSansPro;
    font-weight: 400;
    border-radius: 0;
    font-size: 12px;
    border: 1px solid #CAD2D8;
    height: 30px;
    min-width: 220px;
    ::placeholder{
      font-family: SourceSansPro;
      font-weight: 400;
      font-size: 12px;
    }
	${below.phone`
		min-width: 100px;
		padding-right: 1em;
	`}
  }
  button {
    height: 30px;
    padding: 5px 30px;
  }
`;
const InputWrapper = styled.div`
  width: 100%;
  margin-right: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  svg{
		display: block;
    align-self: center;
    margin-left: -25px;
    height: 16px;
    width: 16px;
    padding-top: 5px
  }
  div{
		display: block;
    align-self: center;
    margin-left: -25px;
    height: 16px;
    width: 16px;
    padding-top: 5px
  }
`;

const fieldLevelRequired = value => (value && value.length > 1) 
	? true 
	: false;
const fieldLevelEmail = value =>
	((value && value.length > 1) && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value))
		? true
		: false;


type Props = {
  closeClick: Function,
  leagueID: number,
  invite_to: {
		is_pending: boolean,
		result: {
			result: boolean
		}
  },
  inviteToDraftLeague: typeof actions.leagueDraft.inviteToDraftLeague,
};

type State = {
	firstname: string,
	email: string,
	errors: {firstname: any, email: any}
}
class LeagueTeamInviteComponent extends React.Component<Props, State> {

	constructor(props: Props) {
		super(props);

		bindAll(this, [
			"handleSubmitEmails", "handleChange"
		]);
	}
	state = {
  	firstname: "",
  	email: "",
  	errors: {
  		firstname: null,
  		email: null,
		},
		call_made: false
	};

	handleSubmitEmails(e: any) {
		e.preventDefault();
  	const invites = [{
			email: this.state.email,
			firstname: this.state.firstname
		}];

  	const payload = {
  		league_id: this.props.leagueID,
  		invites,
  	};

		this.props.inviteToDraftLeague(payload);
		this.setState({ call_made: true });
	};
	handleChange(e){
  	const { name, value } = e.target;
  	
  	let errors = this.state.errors;  

  	switch (name) {
  		case "firstname":
  			errors.firstname = fieldLevelRequired(value);
  			break;
  		case "email":
  			errors.email = fieldLevelEmail(value);
  			break;
  		default:
  			break;
  	}
  	this.setState({ [e.target.name]: e.target.value, errors: errors });
	}
	get is_disabled(){
  	const { errors } = this.state;
  	if(errors.firstname === false || errors.email === false){
  		return true;
  	}
  	if(errors.firstname === null || errors.email === null){
  		return true;
  	}
  	return false;
	}
	render() {
		const { errors } = this.state;
  	return(
			<FormWrapper onSubmit={this.handleSubmitEmails}>
				<InputWrapper>
  				<InputItem
  					label="First name"
  					type='text'
  					name={"firstname"}
  					tooltip={false}
  					placeholder={"First name"}
  					onChange={this.handleChange}
  				/>
					<Exist when={errors.firstname === null}>
						<div></div>
					</Exist>
					<Exist when={errors.firstname === true}>
						<TickCircle /> 
					</Exist>
					<Exist when={errors.firstname === false}>
						<CloseCircle />
					</Exist>
				</InputWrapper>
				<InputWrapper>
  				<InputItem
  					label="Email Address"
  					type='text'
  					name={"email"}
  					tooltip={false}
  					placeholder={"Email address"}
  					onChange={this.handleChange}
  				/>
  				{errors.email === null && <div></div>}
  				{errors.email === true && <TickCircle />}
  				{errors.email === false && <CloseCircle />}
  			</InputWrapper>
				{this.state.call_made ? 
  				<ConfirmedButton>Invited</ConfirmedButton> 
  				: 
  				<ButtonPrimary 
  					type='submit' 
  					disabled={this.props.is_pending || this.is_disabled}
  				>Invite
  				</ButtonPrimary>}
			</FormWrapper>
		);
	}
	
};


const mapStateToProps = (state, props) => ({
	is_pending: state.leagues.invite_to.is_pending,
});
const mapDispatchToProps = {
	inviteToDraftLeague: actions.leagueDraft.inviteToDraftLeague,
};

export const 
	LeagueTeamInvite = connect(mapStateToProps, mapDispatchToProps)(LeagueTeamInviteComponent);

export default LeagueTeamInvite;