// @flow
import styled from "styled-components";

export const EllipsisRow = styled.p`
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	width: ${({ width }) => width || "90%"};
`;

export default EllipsisRow;