// @flow
import _ from "lodash";
import * as React from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../modules/actions";
import {
	Tooltip,
	Widget,
	WidgetBody,
	WidgetHeader,
	WidgetHeaderCloseBtn,
	WidgetHeaderTitle,
} from "../../components";

import Arrow from "../../components/Icons/Arrow";

import type { TNews, TRootStore } from "../../modules/types";

const NewsArticle = styled.article`
	display: flex;
	align-items: flex-start;
	padding: 20px 0;
	border-bottom:1px solid #CAD2D8;
`;

const NewsTitleLink = styled.a`
	font-family: TitilliumUpright, Arial, sans-serif;
	display: inline-block;
	color: #1D4073;
	font-size: 18px;
	line-height: 22px;
	font-weight: 700;
	margin-bottom: 10px;
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
`;

const NewsArticleContainer = styled.div`
	font-family: SourceSansPro, Arial, sans-serif;
	font-size: 14px;
	color: #262424;
	line-height: 20px;
`;

const NewsArticleContent = styled.div`
	margin-bottom: 10px;

	img {
		display: none;
	}
`;

const NewsThumbnail = styled.img`
	width: 60px;
	height: auto;
	margin-right: 20px;
`;

const ReadMoreLink = styled.a`
	font-family: SourceSansPro, Arial, sans-serif;
	color: #198DE1;
	font-size: 12px;
	text-decoration: none;
	display: inline-flex;
	align-items: center;

	&:hover {
		text-decoration: underline;
	}
`;

const WidgetBodyStyled = styled(WidgetBody)`
	background: transparent;
`;


type Props = {
	news: TNews,
	fetchNews: typeof actions.fetchNews,
}

type State = {
	widget_open: boolean,
}

class WidgetNewsContainer extends React.PureComponent<Props, State> {
	constructor(props, state) {
		super(props, state);

		_.bindAll(this, [
			"onToggle",
		]);
	}

	state = {
		widget_open: true,
	};

	componentDidMount() {
		this.props.fetchNews();
	}

	onToggle() {
		this.setState({ widget_open: !this.state.widget_open });
	}

	get content() {
		return this.props.news.map(({ title, description, link, thumbnail }) => (
			<NewsArticle key={title}>
				<NewsThumbnail src={thumbnail} alt={title} />
				<NewsArticleContainer>
					<NewsTitleLink href={link} target="_blank">{title}</NewsTitleLink>
					<NewsArticleContent
						dangerouslySetInnerHTML={{
							__html: description.split("http:").join("https:") }}
					/>
					<ReadMoreLink href={link} target="_blank">
						<Arrow size={1.5} direction="right" />
						<span>Read more</span>
					</ReadMoreLink>
				</NewsArticleContainer>
			</NewsArticle>
		));
	}

	render() {
		const msg = "All your latest AFL Fantasy news";
		const { widget_open } = this.state;

		return (
			<Widget>
				<WidgetHeader>
					<WidgetHeaderTitle>
						<span>Latest fantasy news</span>
						<Tooltip show_on_left description={msg} />
					</WidgetHeaderTitle>
					<WidgetHeaderCloseBtn  open={widget_open} onClick={this.onToggle} />
				</WidgetHeader>
				<WidgetBodyStyled open={widget_open}>
					{ this.content }
				</WidgetBodyStyled>
			</Widget>
		);
	}
}

const mapDispatchToProps = ({
	fetchNews: actions.fetchNews,
});

const mapStateToProps = ({ news }: TRootStore) => ({ news });

export const WidgetNews = withRouter(connect(
	mapStateToProps,
	mapDispatchToProps,
)(WidgetNewsContainer));

export default WidgetNews;