/* eslint max-len: 0 */
// @flow
import React from "react";
import SvgIconContainer from "../SvgIconContainer";

export default (
	{
		unit = "em",
		...rest
	}: Object = {}) => (
	<SvgIconContainer viewBox="0 0 18 18" {...rest} unit={unit}>
		<g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
			<g transform="translate(-976 -1133)">
				<path fill="#FFF" d="M0 0H1280V2385H0z"></path>
				<g transform="translate(960 950)">
					<path fill="#F8F8FA" fillRule="nonzero" d="M0 60H300V935H0z"></path>
					<g transform="translate(10 177)">
						<path
							fill="#FFF"
							stroke="#E8E9EA"
							d="M-0.5 -0.5H280.5V30.5H-0.5z"
						></path>
						<g>
							<path
								fill="#F8F8FA"
								fillRule="nonzero"
								d="M0 0L0 30 28 30 40.0489197 0z"
							></path>
							<g fill="#9EA5AD" transform="translate(6 6)">
								<path d="M13.5 2.16c1.16 0 2.1.967 2.1 2.16v11.52c0 .573-.221 1.122-.615 1.527A2.07 2.07 0 0113.5 18H5.1C3.94 18 3 17.033 3 15.84V4.32c0-1.193.94-2.16 2.1-2.16v2.52c0 .597.47 1.08 1.05 1.08h6.3c.58 0 1.05-.483 1.05-1.08V2.16zM8.424 8.038H6.51v7.234h1.914V13.47l.69-.98 1.603 2.783h2.093l-2.582-4.296 2.159-2.938h-2.093L8.47 10.73h-.045V8.038zM9.3 0c.888 0 1.678.578 1.97 1.44h.48c.193 0 .35.161.35.36v.36h.7v2.16a.71.71 0 01-.7.72H6.5a.71.71 0 01-.7-.72V2.16h.7V1.8c0-.199.157-.36.35-.36h.48A2.092 2.092 0 019.3 0zm0 1.08c-.58 0-1.05.484-1.05 1.08 0 .597.47 1.08 1.05 1.08.58 0 1.05-.483 1.05-1.08 0-.596-.47-1.08-1.05-1.08z"></path>
							</g>
						</g>
					</g>
				</g>
			</g>
		</g>
	</SvgIconContainer>
);