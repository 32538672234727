// @flow
import * as React from "react";
import { connect } from "react-redux";
import styled from "styled-components";

import {
	StripeProvider,
	Elements,
} from "react-stripe-elements";
import { bindAll } from "lodash";
import { below } from "../../assets/css/media";
import colors from "../../assets/css/colors";
import * as actions from "../../modules/actions";
import { isMobile } from "../../utils";
import isAuthenticated from "../../utils/auth/isAuthenticated";
import { ModalContainer, 
	ModalContent, ModalImgWrap,
	ModalBody, ModalOverlay, TextModal
} from "../../components/Modal";
import Preloader from "../../components/Preloader";

import Tick from "../../components/Icons/Tick";
// $FlowFixMe
import afl_pro from "../../assets/images/afl-pro.webp";
import AFLProTxt from "../../assets/images/afl-pro-txt.svg";
import SubscriptionForm from "./SubscriptionForm";
import { subscriptionFeatures } from "./PackageArr";

const stripe_pk = process.env.REACT_APP_STRIPE_PK || "";
const TabSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const TabWrapper = styled.ul`
  width: 360px;
  height: 40px;
  outline: solid 1px ${colors.secondary.paleGrey};
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  padding: 0 5px;
  ${below.phone`
  	width: 275px;
  `}
`;
const PackageTabs = styled.li`
  colors: ${colors.secondary.accentGrey};
  cursor: pointer;
  font-family: SourceSansPro;
  font-weight: 600;
  font-size: 12px;
  border-radius: 2px;
  width: 170px;
  padding: 9px 10px;
  ${props => props.is_active === true && `
    color: ${colors.white};
    background-color: ${colors.black};
  `}
  & span {
	  margin-right: 2px
  }
  
  ${below.phone`
  	display: flex;
	justify-content: center;
	width: 120px;
  `}
`;


const ModalTitle = styled.h2`
	color: ${colors.black};
	font-size: 32px;
	text-align: center;
	margin-bottom: 10px;
	padding-top: 20px;  
	${below.tablet`
		font-size: 24px;
	`}

	${below.phone`
		font-size: 22px;
	`}
`;

const ModalText = styled.p`
  font-size: 14px;
  font-family: SourceSansPro;
  line-height: 20px;
  color: ${colors.black};
  width: 600px;
  height: 60px;
  margin-top: 20px;
  ${below.phone`
	  width: 100%;
	  height: auto;
	  padding: 0 0 20px 0;
  `}
`;

const WarningText = styled.p`
  font-size: 14px;
  font-family: SourceSansPro;
  line-height: 20px;
  color: ${colors.coach};
  width: 600px;
  margin-top: 20px;
  ${below.phone`
	  width: 100%;
	  height: auto;
	  padding: 0 0 20px 0;
  `}
`;

const FeaturesSection = styled.div`
  padding: 0 25px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  min-height: 115px;
  
  ${below.phone`
	  display: flex;
	  flex-direction: column;
	  margin-top: 20px;
	  padding: 0;
  `}
`;

const FeatureItem = styled.div`
  color: ${colors.black};
  border: 1px solid ${colors.black};
  font-family: SourceSansPro;
  font-weight: 600;
  font-size: 12px;
  width: 260px;
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
	margin-top: 10px;
	${props => props.pointer && `
		cursor: pointer;
	`}
  svg{
    width: 14px;
    height: 14px;
    margin-right: 13px;
    margin-left: 8px;
  }
  ${below.phone`
  	margin-top: 5px;
 	width: 99%;
  `}
`;

const FeatureItemTextWrap = styled.div`
	display: flex;
	justify-content: space-between;
	width: calc(100% - 45px);
`;

const FeatureItemText = styled.div`
	line-height: 20px;
	height: 20px;
`;

const FeatureItemDeluxe = styled.div`
	border-radius: 2px;
	background-color: ${colors.black};
	color: ${colors.white};
	font-family: "SourceSansPro";
	font-size: 12px;
	font-weight: 600;
	letter-spacing: 0;
	line-height: 20px;
	text-align: center;
    height: 20px;
    padding: 0 5px;
`;

const ProModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: grey;
	z-index: 2000;
	overflow: hidden;
	${ModalContainer} {
		${below.phone`
    		overflow-y: scroll;
		`}
	}
	${ModalImgWrap} {
		background: url(${afl_pro});
		background-size: cover;
		background-position-y: -105px;
		height: 100px;
		width: 100%;
		${below.phone`
			background-position-y: 0;
		`}
	}
	${ModalContent} {
		box-sizing: border-box;
		box-shadow: 1px 0px 12px 0 rgb(0,0,0,0.2);
		padding-top: 0;
		${below.tablet`
			max-width: unset;
		`}

		overflow: scroll;
		height: calc(100vh - 50px);
		${below.phone`
			height: 100vh;
			max-width: 320px;
			padding-top: 0;
			padding-left: 0;
			padding-right: 0;
		`}

		@media screen and (max-width: 330px) {
			padding-top: 0;
		}

		${ModalBody} {
			padding: 0 100px 0px;
			overflow: hidden;
			position: static;
			${below.desktop}{
				overflow: hidden;
			}
			${below.phone`
				padding: 0;
				max-width: 300px;
				margin: 0 auto;
			`}
		}
	}
  ${ModalOverlay}{
    background: rgba(0, 0, 0, 0.5);
	box-shadow: 1px 0px 12px 0 rgb(0,0,0,0.2);
	height: auto;
  }
  

	.close-button {
		z-index: 1;
		> svg {
			fill: ${colors.white};
		}
		:hover {
			> svg {
				fill: #333;
			}
		}
	}     
`;
const ProTextModal = styled(TextModal)`
	${ModalBody}{
		overflow: hidden;
		${below.desktop}{
				overflow: hidden;
			}
	}
`;

const AFLProTxtWrap = styled.div`
	display: inline-block;
	margin: 30px 0px 0 160px;
	${below.phone`
		display: flex;
		margin: 0;
		flex-direction: column;
		height: 100px;
		justify-content: center;
		text-align: center;
	`}
`;

const AFLProTxtWrapImg = styled.img`
	max-width: 310px;
	${below.phone`
		max-width: 250px;
		margin: 0 auto;
	`}
`;


type Props = {
	closeClick: Function,
	subscribeLeague: typeof actions.leagueDraft.subscribeLeague,
	clearSubscribeLeagueErrors: typeof actions.leagueDraft.clearSubscribeLeagueErrors,
	showDraftLeague: typeof actions.leagueDraft.showDraftLeague,
	subscribe: {
		error: string,
		is_pending: boolean,
	},
	league: {
		subscription: any,
		id: number,
	},
	onSubmit: Function,
	create?: boolean,
	leagueCreateSubmit: Function,
	createdID: number,
	changeFieldValue: Function,
};

type State = {
	active_tab: string,
	is_subscribed: boolean,
}
// eslint-disable-next-line max-len
const modalText = "With a Pro Subscripton you have access to even more customisation options that will let you create an advanced and unique Draft league.";
class ProSubscriptionModalComponent extends React.Component<Props, State> {
	constructor(props: Props, state: State){
		super(props, state);
		bindAll(this, [
			"setActiveTab", "handleSubmit"
		]);
	}
	state = {
  	active_tab: "full",
  	is_subscribed: this.props.league.subscription,
	};

	setActiveTab(tab: string){
  	this.setState({
  		active_tab: tab,
  	});
	}
	isActive(tab: string){
  	if(this.state.active_tab === tab){
  		return "selected-tab";
  	}
	}
	handleSubmit(values) {
	  const { subscribeLeague, create, leagueCreateSubmit, changeFieldValue } = this.props;

  	if(create){
  		changeFieldValue("plan", values.plan);
  		changeFieldValue("token", values.token);
  		leagueCreateSubmit();
  	}
  	else{
  		subscribeLeague(values);
  	}
  	
	}
	get message(){
  	const { league: subscription } = this.props;
  	const subtype = subscription === "full" ? "Full" : "Lite";
  	return `Your league has upgraded to Pro ${subtype}!`;
	}

	get modalImgContent(){
	  return <AFLProTxtWrap>
  		<AFLProTxtWrapImg src={AFLProTxt} alt="Rise above the pack with a pro Subscription" />
  	</AFLProTxtWrap>;
	}

	render() {
  	const { closeClick, subscribe, league } = this.props;
  	const { active_tab } = this.state;
  	const featureItems = subscriptionFeatures(active_tab);
  	if(["full", "lite"].includes(league.subscription)){
  		return(
  			<ProTextModal
  				onClick={closeClick}
  				header_text='Error'
  				text={this.message} />
  		);
	  }
	  
  	const deluxeLabel = isMobile() ? "Deluxe":"Pro Deluxe";
  	const liteLabel = isMobile() ? "Lite":"Pro Lite";
		const warningMSG = "* Not all features are available once your league's draft is complete.";
  	return(
  		<ProModalWrapper>
  				{ subscribe && subscribe.is_pending ? (<Preloader />) : null}
  				<ModalContainer
  					onClick={closeClick}
  				container_background={colors.white}
  				modalImgContent={this.modalImgContent}
  				>
  					<ModalTitle>Subscribe to Pro</ModalTitle>
  					<ModalText>{modalText}</ModalText>
  					<TabSection>
  						<TabWrapper>
  							<PackageTabs 
  								is_active={active_tab === "full"} 
  								onClick={() => this.setActiveTab("full")}>
  							<span>{deluxeLabel}</span>
  							<span>$49.99</span>
  							</PackageTabs>
  							<PackageTabs 
  								is_active={active_tab === "lite"}  
  								onClick={() => this.setActiveTab("lite")}>
  							{liteLabel} ($29.99)
  							</PackageTabs>
  						</TabWrapper>
  					</TabSection>
  					<FeaturesSection>
  						{featureItems.map((feature, index) => {
							  const deluxe = index < 2 && active_tab === "full" ? 
							  	<FeatureItemDeluxe>Deluxe only</FeatureItemDeluxe>:null;
							  return (
  							<FeatureItem key={index}>
  								<div><Tick /></div>
  								<FeatureItemTextWrap>
  									<FeatureItemText>{feature}</FeatureItemText>
  									{deluxe}
  								</FeatureItemTextWrap>
  							</FeatureItem>
							  );
						  })}
  					</FeaturesSection>
					<WarningText>{warningMSG}</WarningText>
  					<StripeProvider apiKey={stripe_pk}>
  						<Elements>
  							<SubscriptionForm 
  								handleClose={closeClick} 
  								cur_plan={active_tab} 
  								is_active={false} 
  								submitData={this.handleSubmit}
  								subscribe={subscribe}
  								initialValues={{ league_id: league.id, plan: active_tab }}
  								create={this.props.create}
  							/>
  						</Elements>
  					</StripeProvider>
						
  				</ModalContainer>
  			</ProModalWrapper>
  	);
	}
	
};

const mapStateToProps = state => ({
	is_authorised: isAuthenticated(), 
	subscribe: state.leagues.subscribe,
	createdID: state.leagues.create.league_id
});

const mapDispatchToProps = {
	subscribeLeague: actions.leagueDraft.subscribeLeague,
	clearSubscribeLeague: actions.leagueDraft.clearSubscribeLeagueErrors,
	showDraftLeague: actions.leagueDraft.showDraftLeague,
};

export const ProSubscriptionModal = connect(
	mapStateToProps,
	mapDispatchToProps)
(ProSubscriptionModalComponent);

export default ProSubscriptionModal;