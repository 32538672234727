// @flow
import styled from "styled-components";
import colors from "../../assets/css/colors";

export const FavButton = styled.button`
	border: 0;
	background: ${colors.secondary.paleGrey};
	height: 16px;
	width: 16px;
	border-radius: 50%;
	padding: 0;
	margin: 2.5px 0;
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	transition: all 0.2s linear;
	font-size: 16px;
	&:hover {
		background: ${colors.secondary.accentGrey};
	}
	${({ active }) => active && `
		background: ${colors.favourite_background};
	
	`}
	svg{
		height: 10px;
		width: 10px;
	}
`;

export default FavButton;