// @flow
import React from "react";
import SvgIconContainer from "../SvgIconContainer";

export default (
	{
		unit = "em",
		...rest
	}: Object = {}) => (
	<SvgIconContainer viewBox="0 0 20 6" {...rest} unit={unit}>
		<title>More</title>
		<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g transform="translate(-340.000000, -22.000000)" fill="#CAD2D8" fillRule="nonzero">
				<g transform="translate(340.000000, 15.000000)">
					<circle cx="2.5" cy="10" r="2.5" />
					<circle cx="10" cy="10" r="2.5" />
					<circle cx="17.5" cy="10" r="2.5" />
				</g>
			</g>
		</g>
	</SvgIconContainer>
);