// @flow
import React from "react";
import { connect } from "react-redux";
import { withRouter, Link, Redirect } from "react-router-dom";
import styled from "styled-components";
import * as _ from "lodash";
import {
	AdsContainer,
	Ad,
	Tabs,
	TabItem,
	Footer,
	TextModal,
	PageTitle,
	PageContentWrapper,
	TwoColumnLayout,
	StandardSidebar,
	HOCComingSoon,
	ButtonPrimary
} from "../components";
import * as selectors from "../modules/selectors";
import { contactUs, contactUsClear, fetchFAQs, getWinners } from "../modules/actions";
import colors from "../assets/css/colors";
import { IS_PRIZES_CLOSED } from "../modules/constants/index";
import type { TWinner } from "../modules/types/cms";
import { below } from "../assets/css/media";
import toyota_car_img from "../assets/images/prize.png";
import HelpContent from "./help/helpContent";
import type { TSection } from "./help/sections";
import { draft_sections, classic_sections } from "./help/sections";
import Faq from "./help/faq";
// import ContactUsForm from "./contactUs/contactUsForm";
import RoundWinners from "./help/winners";


const TabLink = styled(Link)`
	text-decoration: none;
  margin-right: 20px;
  width: 70px;
  display: flex;
  ${below.phone`
    justify-content: space-around;
		margin-right: 0px;
		width: 100%;
    a{
      width: fit-content;
      margin: 0px;

    }
  `}
`;

const StyledTabs = styled(Tabs)`
  border-bottom: 1px solid #F0F3F7;
	margin-bottom: 20px;
	${below.phone`
		width: 100%;
		justify-content: center;
	`}
`;

const StyledTabItem = styled(TabItem)`
  width: 90px;
  display: inline;
  height: 25px;
  border-top: 2px solid transparent;
	border-bottom: 2px solid transparent;
	${below.tablet`
		width: auto;
	`}
`;

const ContactUsMessage = styled.p`
	font-size: 14px;
	font-family: SourceSansPro;
	color: ${colors.primary.primary};
	margin-bottom: 20px;
	font-weight: 600;
	margin-top: 10px;
`;


const ContentContainer = styled.div`
	${below.tablet`
		padding: 0 5px;
	`}
`;
const MainPrizeContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  img{
    width: 750px;
    ${below.phone`
      width: 355px;
    `}
  }
`;

const ButtonContact = styled(ButtonPrimary)`
	width: 330px;
`;


type Props = {
	match: { params: { section: string }},
	is_classic: boolean,
	contactUs: typeof contactUs,
	contactUsClear: typeof contactUsClear,
	fetchFAQs: typeof fetchFAQs,
	getWinners: Function,
	faqs: Object,
	categories: Object,
	contact: {
		error: string,
		success: boolean,
	},
	location: Object,
	winners: TWinner[]
}

class HelpComp extends React.Component<Props> {
	constructor(props: Props) {
		super(props);

		_.bindAll(this, [
			"sectionTab",
			"handleSubmit",
			"handleModalClose",
		]);
	}
	componentDidMount(): void {
		this.props.fetchFAQs();
		this.props.getWinners();
	}

	sectionTab(tab_section: TSection) {
		const { is_classic, match } = this.props;
		const { section } = match.params;

		return (
			<TabLink
				key={tab_section.id}
				to={`/${is_classic ? "classic" : "draft"}/help/${tab_section.id}`}
			>
				<StyledTabItem className={section === tab_section.id ? "active-tab" : ""}>
					{tab_section.name}
				</StyledTabItem>
			</TabLink>
		);
	}

	getCurrentSection(section: string) {
		return _.find(this.sections, { id: section });
	}

	get sections() {
		return this.props.is_classic ? classic_sections : draft_sections;
	}

	get error_modal() {
		const { contact } = this.props;

		return (
			<TextModal
				onClick={this.handleModalClose}
				header_text='Error'
				text={ contact.error } />
		);
	}

	get success_modal() {
		return (
			<TextModal
				onClick={this.handleModalClose}
				header_text='Success'
				text='Thank you. Your email has been sent.' />
		);
	}

	handleModalClose() {
		const { contactUsClear } = this.props;
		contactUsClear();
	}

	handleSubmit(values) {
		const { contactUs } = this.props;
		contactUs(values);
	}

	checkError() {
		const { contact } = this.props;
		return (
			<React.Fragment>
				{contact.error ? this.error_modal : null}
				{contact.success ? this.success_modal : null}
			</React.Fragment>
		);
	}

	handleOpenContactZd() {
		window.zE.activate();
	}

	render() {
		const { is_classic, match, faqs, winners, categories } = this.props;
		const { section } = match.params;
		const section_obj = this.getCurrentSection(section);
		if (!section_obj) {
			return <Redirect to="/classic/help/game-guidelines" />;
		}
		if (section === "prizes" && IS_PRIZES_CLOSED) {
			return <HOCComingSoon prizes={true} />;
		}

		return (
			<React.Fragment>
				{this.checkError()}
				<AdsContainer>
					<Ad />
				</AdsContainer>
				<PageContentWrapper>
					<PageTitle>
							Help
					</PageTitle>
					<TwoColumnLayout>
						<ContentContainer>
							<StyledTabs>
								{_.map(this.sections, this.sectionTab)}
							</StyledTabs>
							{section_obj.id === "prizes" && <MainPrizeContainer>
								<img src={toyota_car_img} alt="Toyota Hilux"/>
							</MainPrizeContainer>}
							{ section_obj.id === "faqs"
								? <Faq is_classic={is_classic} 
									faqs={faqs} categories={categories} />
								: <HelpContent section={section_obj} />
							}
							{section_obj.id === "prizes" && <RoundWinners winners={winners} />}
							<ContactUsMessage>
								Can’t find what you're looking for? 
								If so you can submit a question using the link below. 
							</ContactUsMessage>
							<ButtonContact onClick={this.handleOpenContactZd}>
							Contact Us
							</ButtonContact>

							{/* <ContactUsForm onSubmit={this.handleSubmit} /> */}
						</ContentContainer>
						<StandardSidebar />
					</TwoColumnLayout>
				</PageContentWrapper>
				<Footer />
			</React.Fragment>
		);
	}
};

const mapStateToProps = state => {
	
	return{
		contact: state.contact,
		faqs: selectors.getFaqs(state),
		categories: selectors.getFaqCats(state),
		winners: state.cms.winners.winners,
	};
};

const mapDispatchToProps = {
	contactUs,
	contactUsClear,
	fetchFAQs,
	getWinners
};

export const Help = connect(
	mapStateToProps,
	mapDispatchToProps
)(HelpComp);

export default withRouter(Help);