/* eslint max-len: 0 */
// @flow
import React from "react";
import SvgIconContainer from "../SvgIconContainer";

export default (
	{
		unit = "em",
		...rest
	}: Object = {}) => (
	<SvgIconContainer viewBox="0 0 24 24" {...rest} unit={unit}>
		<title>Oval</title>
		<path d="M17.6189707,15.909361 C16.3626932,15.2571359 15.5,13.7410732 15.5,12
        C15.5,10.2589268 16.3626932,8.74286409 17.6189707,8.09063896 C16.1318311,7.26278705
        14.1698744,6.75 12,6.75 C9.71169546,6.75 7.6546239,7.32028968 6.14181499,8.22897315
        C7.52649183,8.80487698 8.5,10.4004817 8.5,12.25 C8.5,13.8848772 7.73933643,15.3213557
        6.60640831,16.0305182 C8.06383876,16.7869514 9.93804419,17.25 12,17.25 C14.1698744,17.25
        16.1318311,16.737213 17.6189707,15.909361 Z M18.657917,15.2191944 C19.7905453,14.3195989
        20.4615385,13.1948359 20.4615385,12 C20.4615385,10.8051641 19.7905453,9.68040107
        18.657917,8.78080564 C17.4590955,8.998038 16.5,10.3389764 16.5,12 C16.5,13.6610236
        17.4590955,15.001962 18.657917,15.2191944 Z M5.59017175,15.4072986 C6.67053303,15.0625785
        7.5,13.7947009 7.5,12.25 C7.5,10.468843 6.39715454,9.0557458 5.07847921,9.00160969
        C4.10610073,9.8613416 3.53846154,10.9010311 3.53846154,12 C3.53846154,13.2796468
        4.30809116,14.47892 5.59017175,15.4072986 Z M12,18.75 C6.52734525,18.75 2,15.8072256 2,12
        C2,8.19277441 6.52734525,5.25 12,5.25 C17.4726548,5.25 22,8.19277441 22,12 C22,15.8072256
        17.4726548,18.75 12,18.75 Z M12,14 C10.8954305,14 10,13.1045695 10,12 C10,10.8954305
        10.8954305,10 12,10 C13.1045695,10 14,10.8954305 14,12 C14,13.1045695 13.1045695,14 12,14 Z
        M12,13 C12.5522847,13 13,12.5522847 13,12 C13,11.4477153 12.5522847,11 12,11 C11.4477153,11
        11,11.4477153 11,12 C11,12.5522847 11.4477153,13 12,13 Z" />
	</SvgIconContainer>
);