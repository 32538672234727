// @flow
import * as React from "react";
import * as _ from "lodash";
import styled from "styled-components";
import { below } from "../../../../assets/css/media";
import colors from "../../../../assets/css/colors";

import {
	TableFlexCell,
	LeagueAvatar,
	LeagueName,
	ButtonSecondary,
	ButtonPrimary,
} from "../../../../components";
import type { TPLeague } from "../../../../modules/types";

import OverflowHidden from "../../../../components/OverflowHidden";

type Props = {
	league: TPLeague,
	handleLeagueJoinPopup: Function,
	handleLeagueSettingsPopup: Function,
	join: Function
};

const LeagueAvatarStyled = styled(LeagueAvatar)`
	display: flex;
	justify-content: center;
	flex-direction: column;
`;

const FlexDiv = styled.div`
	display: flex;
	justify-content: space-between;
`;

const FlexButtonsDiv = styled(FlexDiv)`
	margin: 0 20px 0 0;
	width: 250px;
	${below.desktop`
		margin: 0;
		width: 110px;
	`}
`;

const LeagueRowWrap = styled(FlexDiv)`
	border: 1px solid ${colors.secondary.paleGrey};
	height: 80px;
	margin: 0 0 10px 0;
	${below.desktop`
		height: 50px;
	`}
`;

export const LeagueNameWrapper = styled(OverflowHidden)`
	display: flex;
	width: 300px;
	img {
		margin: 0px 30px 0 10px;
	}
	${below.tablet`
		display: flex;
		align-items: center;
		justify-content: center;

		.league-name {
			text-align: left;
		}

		p {
			margin-left: 10px;
		}

		${LeagueAvatarStyled} {
			width: 40px;
			height: 40px;
			margin: 0 0 0 10px;
			img {
				height: 100%;
				margin: 0;
				width: 100%;
			}
		}
	`}

	${below.small_phone`
		.league-name p {
			width: 100px;
		}
	`}
`;

const ButtonPrimaryStyled = styled(ButtonPrimary)`
	font-weight: normal;
    font-family: SourceSansPro;
	min-width: 0;
	${below.desktop`
    	padding: 7px 13px;
	`}
`;

const ButtonSecondaryStyled = styled(ButtonSecondary)`
	font-weight: normal;
	font-family: SourceSansPro;
	min-width: 0;
`;

const DesktopTableFlexCell = styled(TableFlexCell)`
	color: ${colors.primary.primary};
	margin: 0;
	text-align: center;
	width: 60px;
	${below.desktop`
		display: none;
	`}
`;

const DesktopSpan = styled.span`
	${below.desktop`
		display: none;
	`}
`;

const LeagueNameFlex = styled(LeagueName)`
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 0 0 0 10px;
	width:200px;
	${below.desktop`
		margin: 0;
	`}
`;

const TableFlexCellFlex = styled(TableFlexCell)`
	margin: auto 0;
`;

export const JoinLeagueRow = ({
	league, handleLeagueJoinPopup, handleLeagueSettingsPopup, join
}: Props) => (
	<LeagueRowWrap key={_.get(league, 'id')} className="playwright-mask-hidden">
		<LeagueNameWrapper>
			<LeagueAvatarStyled league={league} size="60px" />
			<LeagueNameFlex
				name={league.name}
				width="90%"
				lastname={league.lastname}
				firstname={league.firstname}
			/>
		</LeagueNameWrapper>
		<FlexDiv>
			<DesktopTableFlexCell role="cell">
				Teams <br /><br />
				{league.num_teams}
			</DesktopTableFlexCell>
			<DesktopTableFlexCell role="cell">
				Size <br /><br />
				{league.size}
			</DesktopTableFlexCell>
			<DesktopTableFlexCell role="cell">
				Start <br /><br />
				Round {league.start_round}
			</DesktopTableFlexCell>
		</FlexDiv>
		<FlexButtonsDiv>
			<DesktopSpan>
				<TableFlexCellFlex role="cell">
					<ButtonSecondaryStyled
						onClick={handleLeagueSettingsPopup.bind(this, { id: league.id })}>
						View <DesktopSpan>league details</DesktopSpan>
					</ButtonSecondaryStyled>
				</TableFlexCellFlex>
			</DesktopSpan>
			<TableFlexCellFlex role="cell">
				<ButtonPrimaryStyled
					onClick={handleLeagueJoinPopup.bind(this, { id: league.id })}>
					Join league
				</ButtonPrimaryStyled>
			</TableFlexCellFlex>
		</FlexButtonsDiv>
	</LeagueRowWrap>
);

export default JoinLeagueRow;