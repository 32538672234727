// @flow
import React from "react";
import styled from "styled-components";
import * as moment from "moment";
import * as _ from "lodash";
import type { TCoachesBoxComment } from "../../modules/types";
import { TeamUserAvatar } from "../Team";
import { getShortName } from "../../helpers/league";
import colors from "../../assets/css/colors";

const UserName = styled.div`
	color: ${colors.secondary.accentGrey};
	font-size: 12px;
	line-height: 14px;
	font-weight: 400;
`;
const TeamName = styled.div`
	color: ${colors.primary.primary};
	font-size: 14px;
	line-height: 18px;
`;
const UserDetails = styled.div``;
const CommentHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
`;
const CommentedAt = styled.div`
	color: ${colors.secondary.accentGrey};
	font-size: 14px;
	font-weight: 400;
`;

const MessageContent = styled.div`
	color: ${colors.primary.darkGrey};
	font-family: "SourceSansPro";
	font-size: 14px;
	line-height: 20px;
	width: 100%;
	margin-top: 10px;
`;

const AvatarWrapper = styled.div`
	display: flex;
	align-items: flex-start;
	margin-right: 10px;
	float: left;

	> div {
		width: 40px;
		height: 40px;
	}
`;

const MessageWrapper = styled.div`
	width: calc(100% - 70px);
	max-width: 430px;
	float: left;
`;

const CommentWrapper = styled.div`
	width: 100%;
	padding-bottom: 12px;
`;

type Props = {
	comment: TCoachesBoxComment,
	teams_by_id: { [number]: { id: number, name: string } }
};

export class Comment extends React.PureComponent<Props> {
	render() {
		const { comment, teams_by_id } = this.props;
		return <CommentWrapper>
			<AvatarWrapper>
				<TeamUserAvatar 
					user_id={comment.user_id} 
					firstname={comment.firstname} 
					lastname={comment.lastname} 
				/>
			</AvatarWrapper>
			<MessageWrapper>
				<CommentHeader>
					<UserDetails>
						<TeamName>
							{_.get(_.find(teams_by_id, { user_id: comment.user_id }),  "name", "")}
						</TeamName>
						<UserName>{getShortName(comment)}</UserName>
					</UserDetails>
					<CommentedAt>
						{moment(comment.created_at).format("MMMM D, h:mm A")}
					</CommentedAt>
				</CommentHeader>
				<MessageContent>
					{comment.text}
				</MessageContent>
			</MessageWrapper>
		</CommentWrapper>;
	}
}

export default Comment;