// @flow
import * as React from "react";
import styled from "styled-components";
import { ModalContainer } from "../../components";
import Caution from "../Icons/Caution";
import colors from "../../assets/css/colors";
import { below } from "../../assets/css/media";
import ModalBody from "./ModalBody";


type Props = {
	onClick: Function,
	text: any,
	header_text: string
};

const CautionStyled = styled(Caution)`
	color: ${colors.buttons.cancel};
`;

const BodyStyled = styled(ModalBody)`
	padding-bottom: 50px;
	${below.phone`
		padding-top: 50px;
	`}
`;

const error_icon = <CautionStyled />;

export const TextModal = ({ text, onClick, header_text }: Props) => (
	<ModalContainer
		top_icon={header_text === "Error" ? error_icon : undefined}
		onClick={onClick}
	>
		<BodyStyled>
			{text}
		</BodyStyled>
	</ModalContainer>
);

export default TextModal;