// @flow
import { createReducer } from "redux-act";
import * as _ from "lodash";

import * as actions from "../../actions";

const initial_state = {
	settings: {
		types: {
			classic: {},
			draft: {}
		},
		is_pending: false,
		update_pending: false,
		error: null,
	},
	messages: {
		types: {
			classic: {},
			draft: {},
		},
		is_pending: false,
		error: null,
	},
	send: {
		is_pending: false,
		error: null,
	}
};

export const coachesBox = createReducer({}, initial_state);

coachesBox.on(actions.getCoachesBoxSettings, state => ({
	...state,
	settings: {
		...state.settings,
		is_pending: true,
		error: null,
	}
}));

coachesBox.on(actions.getCoachesBoxSettingsSuccess, (state, payload) => {
	const classic = _.get(payload, "classic", []);
	const draft = _.get(payload, "draft", []);
	return {
		...state,
		settings: {
			...state.settings,
			types: {
				classic: {
					..._.keyBy(classic, "id")
				},
				draft: {
					..._.keyBy(draft, "id")
				}
			},
			is_pending: false,
			error: null,
		}
	};
});

coachesBox.on(actions.getCoachesBoxSettingsFailed, (state, payload) => ({
	...state,
	settings: {
		...state.settings,
		is_pending: false,
		error: payload,
	}
}));

coachesBox.on(actions.setCoachesBoxSettings, state => ({
	...state,
	settings: {
		...state.settings,
		update_pending: true,
		error: null,
	}
}));

coachesBox.on(actions.setCoachesBoxSettingsSuccess, (state, payload) => {
	const type = _.get(payload, "type");
	const coaches_box = _.get(payload, "coaches_box");
	const league_id = _.get(payload, "league_id");

	if (type === undefined || coaches_box === undefined || league_id === undefined) return state;

	const state_type = _.get(state, ["settings", "type", type], {});
	const state_league = _.get(state, ["settings", "type", type, league_id], {});

	return {
		...state,
		settings: {
			...state.settings,
			types: {
				...state.settings.types,
				[type]: {
					...state_type,
					[league_id]: {
						...state_league,
						coaches_box
					}
				}
			},
			update_pending: false,
			error: null,
		}
	};
});

coachesBox.on(actions.setCoachesBoxSettingsFailed, (state, payload) => ({
	...state,
	settings: {
		...state.settings,
		update_pending: false,
		error: payload,
	}
}));

coachesBox.on(actions.getCoachesBoxMessages, state => ({
	...state,
	messages: {
		...state.messages,
		is_pending: true,
		error: null
	}
}));

coachesBox.on(actions.getCoachesBoxMessagesSuccess, (state, payload) => {
	const { type, league_id } = payload;
	const comments = [
		..._.get(state, ["messages",  "types", type,  league_id],  []),
		...payload.comments
	];
	const state_type = _.get(state, ["messages",  "types", type], {});

	return {
		...state,
		messages: {
			...state.messages,
			types: {
				...state.types,
				[type]: {
					...state_type,
					[league_id]: _(comments)
						.orderBy("id")
						.uniqBy("id")
						.value()
				}
			},
			is_pending: false,
			error: null
		}
	};
});

coachesBox.on(actions.getCoachesBoxMessagesFailed, (state, payload) => ({
	...state,
	messages: {
		...state.messages,
		is_pending: false,
		error: payload
	}
}));

coachesBox.on(actions.sendCoachesBoxMessage, state => ({
	...state,
	send: {
		is_pending: true,
		error: null,
	}
}));

coachesBox.on(actions.sendCoachesBoxMessageSuccess, (state, payload) => ({
	...state,
	send: {
		is_pending: false,
		error: null,
	}
}));

coachesBox.on(actions.sendCoachesBoxMessageFailed, (state, payload) => ({
	...state,
	send: {
		is_pending: false,
		error: payload,
	}
}));

coachesBox.on(actions.clearCoachesBoxErrors, state => ({
	...state,
	send: {
		...state.send,
		error: null,
	},
	messages: {
		...state.messages,
		error: null
	},
	settings: {
		...state.settings,
		error: null
	}
}));