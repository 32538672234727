// @flow
import * as React from "react";
import * as _ from "lodash";
import styled from "styled-components";

import TeamBreakdown from "./TeamBreakdown";

const TeamBreakdownsContainer = styled.div`
margin-bottom: 50px;
`;

type Props = {
	rosters: Array<Object>,
	team_breakdown_players: Object,
	league_id: number,
	round_id: number,
	is_classic?: boolean,
	user_id: number,
	showModalForPlayerId: Function,
	actual_round_id: number;
}

const TeamBreakdowns = ({ 
	rosters, 
	team_breakdown_players, 
	league_id, 
	round_id,
	is_classic = false,
	user_id,
	showModalForPlayerId,
	actual_round_id
}: Props) => (
	<TeamBreakdownsContainer>
		{_.map(rosters, team => (
			<TeamBreakdown
				key={team.user_id}
				team={team}
				team_breakdown_players={team_breakdown_players}
				league_id={league_id}
				round_id={round_id}
				is_classic={is_classic}
				is_user={team.user_id === user_id}
				showModalForPlayerId={showModalForPlayerId}
				actual_round_id={actual_round_id}
			/>
		))}
	</TeamBreakdownsContainer>
);

TeamBreakdowns.displayName = "TeamBreakdowns";

export default TeamBreakdowns;