// @flow
import styled, { css } from "styled-components";
import { below } from "../../assets/css/media";
import PlayerInfoShadow from "../PlayerInfoShadow";
import { PlayerTableCell } from "../";
import { PlayerAvatarWrapper } from "../PlayerInfo/playerAvatar";
import WrappedTradeIcon from "./WrappedTradeIcon";

const reverseRight = ({ reverse }) => reverse ? "right" : "left";
const reverseLeft = ({ reverse }) => reverse ? "left" : "right";

export const TradeCell = styled.div`
	${({ compressed }) => compressed && css`
		background-color: #F0F3F7;
		padding: 5px;
		box-sizing: border-box;
		height: 40px;
		margin-top: 20px;
		position: relative;
		border: 1px solid #CAD2D8;
		${below.tablet`
			margin-top: 10px;
		`}

		${PlayerInfoShadow} {
			height: 40px;
    		${reverseRight}: 90px;
			${below.desktop`
				${reverseRight}: 80px;
			`}
			${below.tablet`
				${reverseRight}: 90px;
			`}
			${below.phone`
				${reverseRight}: 46px;
			`};
		}

		> ${PlayerAvatarWrapper} {
			height: 60px;
			width: 44px;
			position: absolute;
			bottom: 0;
			${reverseRight}: 40px;

			${below.desktop`
				${reverseRight}: 30px;
			`}
		}

		> ${WrappedTradeIcon} {
			margin-${reverseLeft}: 44px;
			${below.phone`
				margin-${reverseLeft}: 0px;
			`};
		}

		.player-details-info > p {
			margin-top: 2px;

			flex-direction: column;
			align-items: flex-start;
		}

		${below.phone`
			.player-details-info {
				flex-direction: column;
				align-items: ${({ reverse }) => reverse ? "flex-end" : "flex-start"};

				p {
					margin-top: 0;
					margin-right: 0;
				}

				p:first-of-type {
					font-size: 12px;
				}
			}
		`};


		.player-details-status {
			align-items: center;
			margin-${reverseRight}: 0;
		}
	`};

	background: #fff;
	display: flex;
	align-items: center;
	margin-bottom: 10px;
	&:last-child {
		margin-bottom: 0;
	}


	${below.tablet`
		${css`
			${PlayerTableCell} {
				min-width: 100px;
				flex-basis: 75%;
        width: 170px;
			}
			.player-details-info > p:first-of-type {
				width: 80px;
				text-overflow: ellipsis;
			}
		`}
	`}
	${below.phone`
		.player-avatar {
			display: none
		}
	`};

	@media (max-width: 450px) {
		.player-details-info > p:first-of-type {
			width: 70px;
		}
	};

	@media (max-width: 400px) {
		.player-details-info > p:first-of-type {
			width: 60px;
		}
	};

	@media (max-width: 374px) {
		.player-details-info > p:first-of-type {
			width: 50px;
		}
	};
`;

export default TradeCell;