// @flow
import React from "react";
import styled from "styled-components";

import desktopBg from "../../assets/images/FantasyCoach_BG.jpg";
import mobileBg from "../../assets/images/FantasyCoach_BG_mob.jpg";
import { below } from "../../assets/css/media";

const FCBanner = styled.div`
	${below.tablet`
		display: ${({ is_mobile }) => !is_mobile ? "none" : "block"};
	`}
	display: ${({ is_mobile }) => is_mobile ? "none" : "block"};
	img {
		max-width: 1280px;
		width: 100%;
		vertical-align: bottom;
	}
	background-color: #b79242;
	text-align: center;
`;

export const FantasyCoachBanner = () => {
	return <React.Fragment>
		<FCBanner>
			<img src={desktopBg} alt="Fantasy Coach Subscription" />
		</FCBanner>
		<FCBanner is_mobile>
			<img src={mobileBg} alt="Fantasy Coach Subscription" />
		</FCBanner>
	</React.Fragment>;
};

export default FantasyCoachBanner;