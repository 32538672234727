// @flow
import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";

import { below } from "../../assets/css/media";
import colors from "../../assets/css/colors";
import { uiMenu } from "../../modules/actions";

const Container = styled.button`
	width: 19px;
	height: 14px;
	z-index: 1002;
	cursor: pointer;
	color: white;
	position: relative;
	display: none;
	background: transparent;
	border: 0;
	${below.tablet`
		display: block;
	`};
	${props => props.menu_open && `color: ${colors.primary.dark}`};
`;

const Line = styled.div`
	height: 2px;
	width: 100%;
	background-color: currentColor;
	position: absolute;
	top: 0;
	left: 0;
	border-radius: 1px;

	&:nth-child(1) {
	top: 0;
	transition: top 0.2s linear 0.2s, transform 0.2s linear 0s;
	${props =>
		props.menu_open && `
		top: 50%;
		margin-top: -1px;
		transform: rotate(45deg);
		transition: top 0.2s linear, transform 0.2s linear 0.2s;
	`};
	}

  &:nth-child(2) {
    top: 50%;
    transition: transform 0.2s linear 0s;
    margin-top: -1px;
    ${props =>
		props.menu_open &&
      `
      transform: rotate(-45deg);
      transition: transform 0.2s linear 0.2s;
    `};
  }

  &:nth-child(3) {
    top: 100%;
    transition: top 0.2s linear 0.2s, transform 0.2s linear 0s;
    margin-top: -2px;
    ${props =>
		props.menu_open &&
      `
      top: 50%;
      margin-top: -1px;
      transform: rotate(-45deg);
      transition: top 0.2s linear, transform 0.2s linear 0.2s;
    `};
  }
`;

type Props = {
	closeHamburgerMenu: Function,
	openHamburgerMenu: Function,
	menu_open: boolean
}


const Hamburger = ({ openHamburgerMenu, closeHamburgerMenu, menu_open }: Props) => (
	<Container
		onClick={() => {
			if (menu_open) {
				closeHamburgerMenu();
			}
			else {
				openHamburgerMenu();
			}
		}}
		menu_open={menu_open}
	>
		<Line menu_open={menu_open} />
		<Line menu_open={menu_open} />
		<Line menu_open={menu_open} />
	</Container>
);


const mapStateToProps = state => ({
	menu_open: state.uiMenu.open
});

const mapDispatchToProps = {
	openHamburgerMenu: uiMenu.openHamburgerMenu,
	closeHamburgerMenu: uiMenu.closeHamburgerMenu,
};

export default connect(mapStateToProps, mapDispatchToProps)(Hamburger);