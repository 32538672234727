// @flow
import type { Saga } from "redux-saga";

import { call, put, takeLatest } from "redux-saga/effects";
import * as actions from "../../actions/index";
import { Api } from "../../../utils/index";

function* countriesSaga(): Saga<void> {
	try {
		const result = yield call(Api.countries);
		yield put(actions.fetchCountriesSuccess(result));
	}
	catch (event) {
		console.log(event);
	}
}

export default function* countriesRootSaga(): Saga<void> {
	yield takeLatest(actions.fetchCountries, countriesSaga);
}