// @flow
import styled from "styled-components";

export const ListRow = styled.div`
	//height: 250px;
	display: flex;
	font-size: 12px;
	flex-direction: column;
	color: #7F8A90;
`;

export default ListRow;