// @flow
import * as React from "react";
import styled from "styled-components";
import _ from "lodash";
import { connect } from "react-redux";
import {
	ButtonPrimary, 
	ModalContainer, 
	ModalInnerTitle, 
	PlayerImgContainer,
	ModalContent
} from "../../../../components";
import { below } from "../../../../assets/css/media";
import colors from "../../../../assets/css/colors";
import type { TPlayer } from "../../../../modules/types/player";
import CountdownTimer from "../../../../components/CountdownTimer";
import type { TRootStore } from "../../../../modules/types";
import * as selectors from "../../../../modules/selectors";
import type { TPositionsById } from "../../../../modules/types/position";

const StyledPlayerImgContainer = styled(PlayerImgContainer)`
	${below.phone`
		width: 200px;
		height: 200px;
		transform: translate(-50%,25%);
	`}
`;

const ModalContainerGrey = styled(ModalContainer)`
	${ModalContent} {
		background: #F8F8FA;
	}
`;
const ModalText = styled(ModalInnerTitle)`
	padding-top: 150px;
	font-size: 32px;
	${below.desktop`
		padding-top: 175px;
	`}
`;

const BtnCancel = styled(ButtonPrimary)`
	background: transparent;
	color: #D92425;
	margin-bottom: 20px;
	border: none;
`;

const BtnPosition = styled(ButtonPrimary)`
	background: ${props => props.pos === "bench" ? colors.white:colors.primary.primary};
	color: ${props => props.pos === "bench" ? colors.primary.primary:colors.white};
`;

const CountdownTimerStyled = styled(CountdownTimer)`
	justify-content: center;
	margin-top: 20px;

	& > div {
		background: transparent;
		padding: 5px 10px;

	}

	.timer-unit{
		font-size: 20px;
	}

	.timer-label{
		font-size: 8px;
		line-height: 1.33;
	}

	& > div {
		font-size: 20px;
	}
`;

const ButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	margin: 0 1em;
	padding: 20px 0 10px;

	button {
		min-width: 160px;
		margin: 0 5px;

		${below.desktop`
			min-width: 85px;
		`}
	}
`;

type Props = {
	player: TPlayer,
	pickMsg: Object,
	closeClick: Function,
	confirmClick?: Function,
	player: TPlayer,
	availablePositions: Array<string>,
	positions: TPositionsById,
	nextPickAt: string,
	commissioner_type?: boolean,
	team_name?: string,
};

const getPositionBtnText = (positions, key) => {
	const position = _.get(positions, `${key}.name`);
	return position ? `Draft as ${position.toLowerCase()}` : "Draft to interchange";
};

const MakePickPopupContainer = ({
	closeClick,
	confirmClick,
	player,
	pickMsg,
	availablePositions,
	positions,
	nextPickAt,
	commissioner_type = false,
	team_name = "",
}: Props) => {
	if(!player) {
		return null;
	}

	const
		current_pick = _.get(pickMsg, "pick", 1),
		player_name = `${player.first_name} ${player.last_name}`,
		avatar_src = `${process.env.REACT_APP_PLAYERS_PATH + player.id}.webp`,
		timer = commissioner_type ? null : (
			<div>
				<CountdownTimerStyled
					showOnComplete={true}
					hideHoursIfZero={true}
					date={nextPickAt}
				/>
			</div>
		);

	return (
		<ModalContainerGrey
			onClick={closeClick}
			is_sponsored={true}
		>
			<StyledPlayerImgContainer>
				<img src={avatar_src} alt={player_name}/>
			</StyledPlayerImgContainer>
			<ModalText>
				Are you sure you want to draft
				<br />
				{player_name}
				{" "}
				{commissioner_type ? `to ${team_name}` : `at pick ${current_pick}`}
				{"?"}
			</ModalText>
			{ timer }
			<ButtonWrapper>
				{ availablePositions.map(position_key => {
					return (
						<BtnPosition
							key={position_key}
							data-pos={position_key}
							pos={position_key}
							data-id={player.id}
							onClick={confirmClick}
						>
							{ getPositionBtnText(positions, position_key) }
						</BtnPosition>
					);
				}) }
			</ButtonWrapper>
			<BtnCancel onClick={closeClick}>Cancel</BtnCancel>
		</ModalContainerGrey>
	);
};

const mapStateToProps = (state: TRootStore) => ({
	positions: selectors.positions.getPositionsById(state),
});

export const MakePickPopup = connect(
	mapStateToProps,
)(MakePickPopupContainer);

export default MakePickPopup;