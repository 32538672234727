// @flow
import styled from "styled-components";
import * as _ from "lodash";
import colors from "../../assets/css/colors";
import { below } from "../../assets/css/media";

export const StatusButton = styled.button`
	border: 0;
	background: ${({ status, is_bye }) => {
		if (is_bye) {
			return "#45BFA3";
		}
		const statusObject = {
			"is-bye": "#45BFA3",
			"injured": "#8F8F8F",
			"not-playing": "#D92425",
			"medical_sub": colors.player.medical_sub,
			"emergency":colors.player.emergency,
			"uncertain": "#EF791D"
		};
		return _.get(statusObject, `[${status}]`, "#7ED321");
	}};
	height: ${({ edit_teams_view, is_compressed }) => 
		edit_teams_view || is_compressed ? "8px;" : "16px;"};
	width: ${({ edit_teams_view, is_compressed }) => 
		edit_teams_view || is_compressed ? "8px;" : "16px;"};
	border-radius: 50%;
	padding: 0;
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	svg {
		height: ${({ is_compressed, edit_teams_view }) => 
		is_compressed || edit_teams_view ? "8px" : "12px;"};
		width: ${({ is_compressed, edit_teams_view }) => 
		is_compressed || edit_teams_view ? "8px" : "12px;"};
		display: ${({ is_compressed, edit_teams_view }) =>  
		is_compressed || edit_teams_view ? "none" : "" }
	};

	${({ is_compressed, edit_teams_view }) => (is_compressed && edit_teams_view) && `
		height: 8px;
		width: 8px;
		svg {
			display: none;
		}
	`};
	${below.phone`
		${({ league_matchup }) => league_matchup && `
			height: 8px;
			width: 8px;
			svg{
				display: none;
			}
		`}
	`}
`;

export default StatusButton;