// @flow
const prefix = "teams_classic/";
export const createAPIEndpoints = ({ Private }: Object): Object => ({
	show: params => Private.get(`${prefix}show`, params),
	show_my: params => Private.get(`${prefix}show_my`, params),
	update: params => Private.post(`${prefix}update`, params),
	snapshot: params => Private.get(`${prefix}snapshot`, params),
	auto_fill: params => Private.post(`${prefix}auto_fill`, params),
	show_trades: params => Private.get(`${prefix}show_trades`, params),
	show_trades_history: params => Private.get(`${prefix}show_trades_history`, params),
	reverse_trade: params => Private.get(`${prefix}reverse_trade`, params),
	rollback: params => Private.get(`${prefix}rollback`, params),
	make_trade: params => Private.post(`${prefix}make_trade`, params),
	make_double_trade: params => Private.post(`${prefix}make_double_trade`, params),
	get_celebrities: params => Private.get(`${prefix}celebrities`, params),
	rankings: params => Private.get(`${prefix}rankings`, params),
	season_history: params => Private.get(`${prefix}season_history`, params),
	history_statistic: params => Private.get(`${prefix}history_statistic`, params),
	edit_trade: params => Private.post(`${prefix}update_trade`, params)
});

export default createAPIEndpoints;