// @flow
import _ from 'lodash';
import type { 
	TPlayer, 
	TPositionsById, 
	TPlayersById,
	TTeamClassicLineup,
	TRoundWithMatches
} from '../../../modules/types';

type TTeamScore = {
	lineup: TTeamClassicLineup,
	players_by_id: TPlayersById,
	round: TRoundWithMatches,
	team?: Object,
	is_classic: boolean
}

type TLineup = TTeamClassicLineup



export const getEmergencyIds = (lineup: TLineup) => {
	const { emergency = {} } = lineup;

	return _.map(emergency, value => value);
};

export const getLineupPlayersCount = ({ lineup }: TLineup) => {
	const ids = _.chain(lineup)
		.omit(['captain', 'vice_captain', 'emergency'])
		.values().flatten().value();
	return _.size(_.filter(ids, id => id !== 0));
};

export const getLineupPlayersHelper = (
	players: Array<TPlayer>, selected_team: Object, positions_by_id: TPositionsById
) => {
	const { lineup } = selected_team;

	if (!lineup || _.isEmpty(players) || _.isEmpty(positions_by_id)) {
		return {};
	}
	const playerWithPositionNames = (id, key, inverted_emergency) => {
		const emergency_pos = inverted_emergency[id];
		let player = _.find(players, player => player.id === id) || {},
			positions_name = [],
			positions_full_name = [],
			position_in_lineup = key === 'bench' ? 'EMG' : positions_by_id[key].short_name;

		if (emergency_pos) {
			position_in_lineup = positions_by_id[emergency_pos].short_name;
		}

		if (player.id) {
			positions_name = player.positions.map(
				id => positions_by_id[id].short_name
			);
		}

		if (player.id) {
			positions_full_name = player.positions.map(
				id => positions_by_id[id].name
			);
		}

		return {
			...player,
			positions_name,
			positions_full_name,
			position_in_lineup
		};
	};
	const { emergency = {} } = lineup;
	const inverted_emergency = _.invert(emergency);

	return _.chain(lineup)
		.omit(['captain', 'vice_captain', 'emergency'])
		.mapValues((line, key) => (
			_.map(line, id => playerWithPositionNames(id, key, inverted_emergency))
		))
		.values()
		.flatten()
		.keyBy('id')
		.value();
};

const getEmergencyScore = (
	emergency: Object,
	position_id?: number | string,
	score_key: string,
	players_by_id: Object,
	is_classic: boolean
) => {
	let score;

	if(!position_id) {
		return null;
	}

	if (is_classic) {
		const position_bench = _.get(emergency, position_id, []);
		const emgs = _.get(emergency, 'emergency', []);

		const emergency_player = _(emgs)
			// Remove anyone not in correct position
			.filter(p_id => _.includes(position_bench, p_id))
			.map(p_id => players_by_id[p_id])
			// map to a list of scores with ids
			.map(player => {
				const id = _.get(player, 'id');
				return {
					score: _.get(player, score_key, 0),
					id
				};
			})
			// Make sure that you're taking the best emergency,
			// if there's more than one
			.orderBy('score', 'desc')
			// Take the highest score
			.first();

		if (!_.isEmpty(emergency_player)) {
			score = _.get(emergency_player, 'score', 0);
			// Remove this player from the list of emergencies so they're not used again
			emergency.emergency = _.without(emgs, _.get(emergency_player, 'id'));
		}
	}
	else {
		// check and get player_id in emerge.posKey
		const emergency_player_id = _.get(emergency, position_id, null);

		if(emergency_player_id) {
			score = _.get(players_by_id[emergency_player_id], score_key, 0);
			emergency[position_id] = 0;
		}
		else {
			score = 0;
		}
	}

	return score;
};

export const getTeamScore = ({ lineup, players_by_id, round, is_classic }: TTeamScore) => {
	if(
		_.isEmpty(lineup) ||
		_.isEmpty(players_by_id) ||
		_.isEmpty(round)
	) {
		return '--';
	}

	const pure_lineup = _.chain(lineup)
		.omit(['captain', 'vice_captain', 'bench', 'emergency'])
		.value();
	const team_players_ids = _.chain(pure_lineup)
		.values()
		.flatten()
		.filter(_.identity)
		.value();

	const score_key = `stats.scores.${round.id}`;

	let emergency = { ..._.get(lineup, is_classic ? 'bench': 'emergency', {}) };

	let score = getLeaderScore(lineup, players_by_id, round);

	score += team_players_ids.map(player_id => {
		const player = _.get(players_by_id, player_id, null);

		if(player === null) {
			return 0;
		}

		const players_match = round.matches
			.find(m => m.home_squad_id === player.squad_id || m.away_squad_id === player.squad_id);

		const match_status = _.get(players_match, `status`, 'scheduled');


		let score = _.get(players_by_id[player_id], score_key, null);

		// Add emergency score if exsits
		if (match_status !== 'scheduled' && score === null && !_.isEmpty(emergency)) {
			// get player position key
			const position_id = Object.keys(pure_lineup)
				.find(player_ids => pure_lineup[Number(player_ids)].includes(player_id));

			score = getEmergencyScore(
				emergency,
				position_id,
				score_key,
				players_by_id,
				is_classic
			);
		}
		score = _.isNil(score) ? 0 : score;

		return score;
	}).reduce((value, score) => {
		return value + score;
	}, 0);

	return score;
};

const getLeaderScore = (
	lineup: TLineup,
	players_by_id: TPlayersById,
	round: Object
):number => {
	if(!lineup.captain) {
		return 0;
	}

	const captain = players_by_id[lineup.captain];
	const captains_match = round.matches.find(m =>
		m.home_squad_id === captain.squad_id ||
			m.away_squad_id === captain.squad_id
	);

	if(!captains_match) {
		return 0;
	}

	const captains_score = _.get(captain, `stats.scores.${round.id}`, null);
	if(_.get(captains_match, `status`) !== 'scheduled' && captains_score !== null) {
		return captains_score;
	}
	else if(_.get(captains_match, `status`) === 'scheduled') {
		return 0;
	}

	if(!lineup.vice_captain) {
		return 0;
	}

	const vice_captain = players_by_id[lineup.vice_captain];
	const vice_captains_score = _.get(vice_captain, `stats.scores.${round.id}`, 0);
	return vice_captains_score;
};

export const getLeaderId = (
	lineup: TLineup,
	players_by_id: TPlayersById,
	proj_score_key: string,
	round: Object
) => {
	const { captain, vice_captain } = lineup;
	let leader_id = captain || vice_captain;

	if (leader_id) {
		const leader = players_by_id[leader_id],
			leader_score = _.result(leader, proj_score_key, 0);

		if (!leader_score) {
			leader_id = vice_captain;
		}
	}

	return leader_id;
};