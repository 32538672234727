// @flow
import styled from "styled-components";
import { Link } from "react-router-dom";
import colors from "../../assets/css/colors";
import ButtonPrimary from "../ButtonPrimary";

export const ButtonAlert = styled(ButtonPrimary)`
	background: ${({ inverted }) => inverted ? "#fff" : colors.buttons.cancel};
	border-color: ${colors.buttons.cancel};
	color: ${({ inverted }) => inverted ? colors.buttons.cancel : "#ffffff"};

	:hover:not(:disabled) {
		background: ${({ inverted }) => !inverted ? "#fff" : colors.buttons.cancel};
		border-color: ${colors.buttons.cancel};
		color: ${({ inverted }) => !inverted ? colors.buttons.cancel : "#ffffff"};
	}
`;

ButtonAlert.displayName = "ButtonAlert";

export const ButtonAlertLink = styled(ButtonPrimary.withComponent(Link))`
	display: flex;
	align-items: center;
	justify-content: center;
	text-decoration: none;
	box-sizing: border-box;
	${({reschedule}) => reschedule && `
		background: #198DE1;
		border: none;
	`}
`;

ButtonAlertLink.displayName = "ButtonAlertLink";
ButtonAlertLink.defaultProps = {
	...colors.buttons.alert_button
};

export default ButtonAlert;