/* eslint max-len: 0 */
// @flow
import React from "react";
import SvgIconContainer from "../SvgIconContainer";

export default (
	{
		unit = "em",
		...rest
	}: Object = {}) => (
	<SvgIconContainer viewBox="0 0 24 24" {...rest} unit={unit}>
		<title>Emergency</title>
		<path d="m8 6h7.7538462v2.65846154h-4.5784616v1.88307696h3.9138462v2.6584615h-3.9138462v2.1415385h4.7630769v2.6584615h-7.9384615z" fillRule="evenodd"/>
	</SvgIconContainer>
);