// @flow
import "babel-polyfill";
import round10 from "round10";

round10.polyfill();

// Element.closest()
(function (ELEMENT: any) {
	ELEMENT.matches = (
		ELEMENT.matches
		|| ELEMENT.mozMatchesSelector
		|| ELEMENT.msMatchesSelector
		|| ELEMENT.oMatchesSelector
		|| ELEMENT.webkitMatchesSelector
	);

	ELEMENT.closest = ELEMENT.closest || function closest(selector) {
		if (!this) {
			return null;
		}

		if (this.matches(selector)) {
			return this;
		}

		if (!this.parentElement) {
			return null;
		}
		else {
			return this.parentElement.closest(selector);
		}
	};
}(Element.prototype));