// @flow
import * as React from "react";
import styled from "styled-components";
import { get } from "lodash";
import { below } from "../../assets/css/media";
import colors from "../../assets/css/colors";

import { ModalContainer, 
	ModalContent,
	ModalBody, ModalOverlay,
} from "../../components/Modal";
import Bracket from "../../components/Icons/Bracket";
import { TPLeague } from "../../modules/types";




const ModalTitle = styled.h2`
	color: ${colors.primary.primary};
	font-size: 32px;
	text-align: center;
	margin-bottom: 10px;
	padding-top: 20px;  
	${below.tablet`
		font-size: 24px;
	`}

	${below.phone`
		font-size: 22px;
	`}
`;

const ModalText = styled.p`
	color: ${colors.primary.primary};
	font-family: SourceSansPro;
	font-size: 14px;
	line-height: 20px;
	text-align: center;
	margin-bottom: 10px;
	padding-top: 20px;  
	${below.tablet`
		font-size: 12px;
	`}

	${below.phone`
		font-size: 12px;
	`}
`;


const GreatButton = styled.button`
	width: 200px;
	height: 40px;
	background: #1D4073;
	border-radius: 2px;
	color: white;
	outline: none;
	font-family: SourceSansPro;
	font-size: 12px;
	font-weight: 500;
	text-align: center;
	border: none;
	margin-top: 20px;
`;





const ProModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: grey;
	z-index: 2000;
	overflow: hidden;
	
	${ModalContent} {
		box-sizing: border-box;
		box-shadow: 1px 0px 12px 0 rgb(0,0,0,0.2);
		padding-top: 0;
		${below.tablet`
			max-width: unset;
		`}

		height: fit-content;
		padding-bottom: 30px;
		${below.phone`
			height: 502px;
			max-width: 320px;
			padding-top: 0;
			padding-left: 0;
			padding-right: 0;
		`}

		@media screen and (max-width: 330px) {
			padding-top: 0;
		}

		${ModalBody} {
			padding: 0 100px 0px;
			overflow: hidden;
			position: static;
			${below.desktop}{
				overflow: hidden;
			}
			${below.phone`
				padding: 0;
				max-width: 300px;
				margin: 0 auto;
			`}
		}
	}
  ${ModalOverlay}{
    background: rgba(0, 0, 0, 0.5);
    box-shadow: 1px 0px 12px 0 rgb(0,0,0,0.2);
    height: 100%;
  }
  

	.close-button {
		z-index: 1;
		> svg {
			fill: ${colors.primary.primary};
		}
		:hover {
			> svg {
				fill: #333;
			}
		}
	}     
`;


const LogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  width: 235px;
  margin-left: auto;
  margin-right: auto;
  ${below.phone`
    width: 130px;
  `}
  svg{
    height: 60px;
		width: 90px;
		g{
			path{
				fill: ${colors.primary.primary};
			}
		}
    ${below.phone`
      height: 60px;
    `}
  }
`;

const getModalText = (format: number, length: number) => {
	switch (format) {
		case 2:
			return "In a two team bracket, at the end of the season, " +
				"the top 2 teams on the ladder will face off in the Grand " + 
				"Final to determine the League Champion.";
		case 4: 
			return length !== 3 
				? "In a four team finals bracket, the first round (Preliminary Finals) " + 
				"consists of two games " + 
				"(1st vs 4th & 2nd vs 3rd).<br /> <br /> In the second round, the two teams " + 
				"that won in the first round will " + 
				"play off in the Grand Final to determine the League Champion."
				: "In a four team finals bracket over three weeks, the first round"+
				" (Semi Finals) consists of one elimination game (3rd vs 4th), "+
				"as well as one qualifying game (1st vs 2nd). <br/> <br />" +
				"In the second round (Preliminary Finals), the winner of"+
				" the game between the low ranked seeds will face the loser of the"+
				" 1st & 2nd matchup from round one. While the winner of the 1st & 2nd "+
				"matchup in round one will have a bye and progress"+
				" through to the Grand Final.<br/> <br />" +
				"The last round will see the matchup winner from round two vs the"+
				" winner of the 1st & 2nd matchup in the round face off in the "+
				"Grand Final to determine the League Champion";
		case 6:
			return length !== 4
				? "In a six team finals bracket over three weeks, the first round " + 
			"(Semi Finals) consists of two elimination games (3rd vs 6th & 4th vs 5th). " + 
			"Teams ranked 1st & 2nd receive a bye in the first round. <br /> <br />" +
			"In the second round (Preliminary Finals), the 1st ranked team will play " +
			"the lowest-ranked seed to progress through to round two, while the 2nd ranked "+
			"team will play the higher-ranked team to progress. <br /> <br />" +
			"The two teams to win in round two will play off in the Grand Final " +
			"to determine the League Champion."
				: "In a six team finals bracket over four weeks, the first round (Finals Week 1)" +
				" consists of two elimination games (3rd vs 6th & 4th vs 5th). Teams ranked 1st &" +
				" 2nd receive a bye in the first round. <br /> <br />" +
				"In the second round (Semi Finals), the winners of the first round games play " +
				"against each other, while the 1st & 2nd teams also play against each other."+
				"<br /><br />"+
				"In the third round (Preliminary Finals), the winner of the "+
				"game between the low ranked seeds will face the loser of the 1st & 2nd matchup" +
				" from round two. While the winner of " +
				"the 1st & 2nd matchup in round two will have a bye" +
				" and progress through to the Grand Final. <br /> <br />" +
				"The last round will see the matchup winner from round three vs the winner"+
				" of the 1st & 2nd matchup in the round face off " +
				"in the Grand Final to determine the League Champion.";
		case 8:
			return "In an eight team finals bracket over four weeks, the first round " +
			"(Finals Week 1) consists of two elimination games (5th vs 8th & 6th vs 7th), "+
			"as well as two qualifying games (1st vs 4th & 2nd vs 3rd). <br /> <br />" +
			"In the second round (Semi Finals), the loser of 1st vs 4th will play" +
			" the winner of 5th vs 8th, and the loser of 2nd vs 4th will play the "+
			"winner of 6th vs 7th. The winners of the two qualifying games will "+
			"receive a bye in round two and progress straight to the Preliminary Finals. <br />"+
			"<br />" +
			"In the third round (Preliminary Finals), the winners of the two semi finals"+
			" games will face off against the two teams who won their games"+
			" in the Qualifying finals.<br /> <br />" +
			"The last round will see the winners of the matchups in the Preliminary"+
			" finals face off in the Grand Final to determine the League Champion.";
		default:
			return "";
	}
};


type Props = {
	league: TPLeague,
	toggleModal: () => void;
};


// eslint-disable-next-line max-len
const FinalsModal = ({ league, toggleModal }: Props) => {
	const format = get(league, "finals_format");
	const length = get(league, "finals_length");
	const innerText = getModalText(format, length);

	return(
		<ProModalWrapper>
			<ModalContainer
				onClick={toggleModal}
				container_background={colors.white}
			>
				<LogoWrapper>
					<Bracket />
				</LogoWrapper>
				<ModalTitle>Finals Scoring</ModalTitle>
			
				<ModalText dangerouslySetInnerHTML={{ __html: innerText }} />
				<GreatButton onClick={toggleModal}>
					Great
				</GreatButton>
			</ModalContainer>
		</ProModalWrapper>
	);
	
};




export default FinalsModal;