// @flow
import * as React from "react";
import styled from "styled-components";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import type { TPlayerStatusesReducer, TPosition, TSquad } from "../../modules/types";

import colors from "../../assets/css/colors";
import Search from "../../components/Icons/Search";
import SortDown from "../../components/Icons/SortDown";
import SortUp from "../../components/Icons/SortUp";
import { Select } from "../Form";
import {
	FormCircleCheckbox,
	Label,
	SearchField,
	SearchInputWrapper,
	SearchSubmitButton,
	Tooltip,
} from "../../components";
import { below } from "../../assets/css/media";

const prices = [
	{ key: "200000-299000", value: "$200K - $299K" },
	{ key: "300000-399000", value: "$300K - $399K" },
	{ key: "400000-499000", value: "$400K - $499K" },
	{ key: "500000-599000", value: "$500K - $599K" },
	{ key: "600000-699000", value: "$600K - $699K" },
	{ key: "700000-799000", value: "$700K - $799K" },
	{ key: "800000-899000", value: "$800K - $899K" },
	{ key: "900000-9999999", value: "$900K+"}
];

const ClassicFiltersForm  = styled.form`
	margin: 1em 0 20px;

	${below.desktop`
		padding: 0 15px;
	`};
`;

const StyledFormCircleCheckbox = styled(FormCircleCheckbox)`
	margin-top: 20px;
	min-width: auto !important;
	margin-left: 5px;
`;

export const SearchWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 20px 0;
`;

export const CheckboxWrapper = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;

	> div {
		min-width: 140px;
		margin-right: 10px;
		${below.tablet`
			margin-right: 0;
		`};
	}

	${below.desktop`
		flex-wrap: wrap;
	`};

	${below.tablet`
		flex-wrap: wrap;
		> div {
			flex-basis: 100%;
			margin: 0 0 10px 0;
		}
	`};
`;

export const SelectWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: center;

	margin-top: -10px;
    margin-left: -10px;
	${below.tablet`
		margin-top: 0px;
   		margin-left: 0px;
	`};

	> div {
		min-width: 140px;
		margin-left: 10px;
		margin-top: 10px;
		flex: 1 0 0px;
		${below.tablet`
			margin-left: 0px;
		`};
	}

	> ${CheckboxWrapper} {
		flex-basis: auto;
		min-width: auto;
	}

	${below.tablet`
		flex-wrap: wrap;
		> div {
			flex: 0 0 calc(50% - 5px);
			margin: 0 0 10px 0;
		}
		> ${CheckboxWrapper} {
			flex: 0 0 100%;
			margin-left: 0;
			&:nth-child(even) {
				margin-left: 0px;
			}
		}
	`};
`;

const CheckboxLabel = styled.label`
	cursor: pointer;
	height: 36px;
	width: 36px;
	border: 1px solid ${colors.primary.primary};
	background-color: #FFFFFF;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-left: 1em;
	svg {
		margin: 0 auto;
	}
`;

const HiddenCheckbox = styled.input`
	display: none;
`;

export const StyledLabel = styled(Label)`
	margin-bottom: 4px;
	vertical-align: middle;
`;

type Props = {
	handleChange: Function,
	handleSubmit: Function,
	onToggleOrder?: Function,
	order_by_desc?: boolean,
	positions: Array<TPosition>,
	squads: Array<TSquad>,
	statuses: TPlayerStatusesReducer,
	is_hide_sort?: boolean,
	is_show_owner?: boolean,
	selected_by_position?: string,
	selected_by_price?: string,
	years: number[]
};

type State = {
	by_dpp: boolean,
	by_favourites: boolean,
	by_hide_your_players: boolean,
};

class ClassicFiltersComponent extends React.PureComponent<Props, State> {
	static defaultProps = {
		order_by: true,
		is_hide_sort: true,
		is_show_owner:false,
	};

	constructor(props: Props, state: State) {
		super(props, state);

		_.bindAll(this, [
			"handleSwitchChange",
		]);
	}

	state = {
		by_dpp: false,
		by_favourites: false,
		by_hide_your_players: false,
	};

	handleSwitchChange({ currentTarget }: Object) {
		const { name, checked } = currentTarget;
		this.setState({ [name]: checked });
	}

	render() {
		const {
			handleChange,
			handleSubmit,
			order_by_desc,
			onToggleOrder,
			positions,
			squads,
			statuses,
			is_hide_sort,
			selected_by_position,
			selected_by_price,
			years
		} = this.props;

		const {
			by_dpp,
			by_favourites,
			by_hide_your_players,
		} = this.state;

		return (
			<ClassicFiltersForm onChange={handleChange} onSubmit={handleSubmit}>
				<SearchWrapper>
					<SearchInputWrapper>
						<SearchField
							type="text"
							name="search"
							component="input"
							placeholder="Search for a player"
						/>
						<SearchSubmitButton type="submit">
							<Search />
						</SearchSubmitButton>
					</SearchInputWrapper>
					{!is_hide_sort ? (
						<div>
							<CheckboxLabel htmlFor="order_by_desc" onClick={onToggleOrder}>
								{order_by_desc ? <SortDown /> : <SortUp />}
							</CheckboxLabel>
							<HiddenCheckbox
								type="checkbox"
								defaultChecked={order_by_desc}
								value={order_by_desc ? "desc" : "asc"}
								id="order_by_desc"
								name="order_by_desc" />
						</div>
					) : null}
				</SearchWrapper>
				<div>
					<SelectWrapper>
						<div>
							<StyledLabel>
								Club
								<Tooltip
									title="Club"
									description="Filter the player list by AFL club"
								/>
							</StyledLabel>
							<Select name="by_squad" is_disabled={false}>
								<option value="">
										All Clubs
								</option>
								{squads.map(({ id, full_name }) => (
									<option key={id} value={id}>{full_name}</option>
								))}
							</Select>
						</div>
						<div>
							<StyledLabel>
								Position
								<Tooltip
									title="Position"
									description="Filter the player list by positions"
								/>
							</StyledLabel>
							<Select
								readOnly
								value={selected_by_position}
								is_disabled={false} name="by_position"
							>
								<option value="">
										All Positions
								</option>
								{positions.map(({ id, full_name }) => (
									<option key={id} value={id}>{full_name}</option>
								))}
							</Select>
						</div>
						<div>
							<StyledLabel>
								Price
								<Tooltip
									title="Price"
									description="Filter the player list by price range"
								/>
							</StyledLabel>
							<Select
								readOnly
								value={selected_by_price}
								is_disabled={false} name="by_price"
							>
								<option value="">
									All Prices
								</option>
								{prices.map(({ key, value }) => (
									<option key={key} value={key}>{value}</option>
								))}
							</Select>
						</div>
						<div>
							<StyledLabel>
								Status
								<Tooltip
									title="Status"
									description="Filter the player list by current playing status"
								/>
							</StyledLabel>
							<Select is_disabled={false} name="by_status">
								<option value="">
										All Statuses
								</option>
								{statuses.map(({ key, value }) => (
									<option key={key} value={key}>{value}</option>
								))}
							</Select>
						</div>
						<div>
							<StyledLabel>
								Year
								<Tooltip
									title="Year"
									description={"Update the player list to "
									+ "show stats from specific years"}
								/>
							</StyledLabel>
							<Select name="by_year" is_disabled={false}>
								{years.map(year => (
									<option key={year} value={year}>{year}</option>
								))}
							</Select>
						</div>
						<CheckboxWrapper>
							<StyledFormCircleCheckbox
								id="dpp"
								name="by_dpp"
								checked={by_dpp}
								onChange={this.handleSwitchChange}
								label="Show DPP"
							/>
							<StyledFormCircleCheckbox
								id="favourites"
								name="by_favourites"
								checked={by_favourites}
								onChange={this.handleSwitchChange}
								label="Show your favourites"
							/>
							<StyledFormCircleCheckbox
								id="by_hide_your_players"
								name="by_hide_your_players"
								checked={by_hide_your_players}
								onChange={this.handleSwitchChange}
								label="Hide your players"
							/>
						</CheckboxWrapper>
					</SelectWrapper>
				</div>
			</ClassicFiltersForm>
		);
	}
}

export const ClassicFilters = withRouter(ClassicFiltersComponent);

export default withRouter(ClassicFilters);

export * from "./StatsCentreFilters";