import React from "react";
import { Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import bindAll from "lodash/bindAll";
import styled from "styled-components";

import { get } from "lodash";
import { below } from "../assets/css/media";
import isAuthenticated from "../utils/auth/isAuthenticated";
import { clearLoginErrors, forgotPassword, login, setAflIdModalStatus } from "../modules/actions";
import {TUser} from "../modules/types";
import { TextModal } from "../components";
import { Landing } from "../components/Landing";
import { isChangePasswordSuccess } from "../modules/selectors";
import { getLoginLink, isAllTrue } from "../helpers";
import { getAflIdModalStatus } from "../modules/selectors/aflIdModal";
import { AFLIdModalStatus, TAFLIdModalTypes } from "../modules/types/store/aflIdModalReducer";
import { dataLayer } from "../utils";
import ResetPasswordModal from "./login/resetPasswordModal";
import { AFLiDButonWrap, AFLIDButton, AFLIDLogInButton } from "./login/afliDModalStyles";




const LoginTitle = styled.h2`
	color: #FFFFFF;
	font-size: 52px;
	max-width: 480px;
	margin: 10px .5em 26px .5em;
  ${below.phone`
    font-size: 20px;
  `}
`;

const SignUpBlock = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 0 0 20px 0;
	color: #FFFFFF;
	p {
		font-size: 22px;
		font-family: SourceSansPro;
		font-weight: 700;
	}
  ${below.phone`
    a {
      width: 320px;
      
    }
	p {
		font-size: 18px;
	}
  `}
`;

const ImportantAccountInfo = styled.button`
	background:none;
	border: none;
	color: #fff;
	font-size: 16px;
	text-decoration: underline;
`;

type Props = {
	is_authorized: boolean,
	user: TUser,
	login: typeof login,
	clearLoginErrors: typeof clearLoginErrors,
	forgotPassword: typeof forgotPassword,
	show_login?: boolean,
	match: {params: {code: string}},
	setAflIdModalStatus: TAFLIdModalTypes,
	changePasswordSuccess: boolean
};

type State = {
	show_sign_up: boolean,
	click_count: number
}

class LoginPage extends React.Component<Props, State> {
	constructor() {
		super();

		bindAll(this, [
			"handleSubmit",
			"handleErrorModalClose",
			"showResetPassword",
			"hideResetPassword",
			"handleSubmitResetPassword",
			"handleClick",
			"infoAFLidModal"
		]);
	}

	state = {
		show_sign_up: false,
		click_count: 0
	};

	componentDidMount() {
		document.getElementById("menu-wrapper").click();
	}

	get error_modal() {
		const { user } = this.props;

		return (
			<TextModal
				onClick={this.handleErrorModalClose}
				header_text='Error'
				text={ user.login.error || user.forgot_password.error }
			/>
		);
	}

	showResetPassword(event) {
		event.preventDefault();
		this.setState({ show_sign_up: true });
	}

	hideResetPassword() {
		this.setState({
			show_sign_up: false,
		});

		const { clearLoginErrors } = this.props;
		clearLoginErrors();
	}

	get sign_up_module() {
		const { user } = this.props;

		return (
			<ResetPasswordModal
				onSubmit={this.handleSubmitResetPassword}
				closeClick={this.hideResetPassword}
				confirmClick={this.handleSubmitResetPassword}
				sent_to_reset_password={user.forgot_password.success}
			/>
		);
	}

	handleErrorModalClose() {
		const { clearLoginErrors } = this.props;
		clearLoginErrors();
	}

	handleSubmit(values) {
		const { login } = this.props;
		login(values);
	}

	handleSubmitResetPassword(values) {
		this.props.forgotPassword(values);
		this.setState({ sent_to_reset_password: true });
	}

	onSelectGame() {
		dataLayer({
			'event': 'login-click'
		});
		localStorage.setItem('original-click', 'login');
		window.location.href = getLoginLink();
	};

	onSignUp() {
		dataLayer({
			'event': 'sign-up-click'
		});
		localStorage.setItem('original-click', 'sign-up');
		window.location.href = getLoginLink();
	}
	

	handleClick() {
		this.setState({
			click_count: this.state.click_count + 1
		});
	}

	infoAFLidModal() {
		this.props.setAflIdModalStatus(AFLIdModalStatus.Info);
	}

	render() {
		const { user, is_authorized, changePasswordSuccess } = this.props;
		const { show_sign_up } = this.state;
		const userRecovered = get(user.data, 'recovered', 0);
		const isChangePasswordFlow = localStorage.getItem('change_password_flow') === "true";
		if(isAllTrue([is_authorized, changePasswordSuccess, isChangePasswordFlow])){
			window.location.href = getLoginLink();
			localStorage.setItem('change_password_flow', 'false');
		};
		if(is_authorized && userRecovered === 1) {
			return <Redirect to="/game-selection" />;
		}
		return (
			<React.Fragment>
				<Landing title='Login to Play AFL Fantasy'>
					<LoginTitle onClick={this.handleClick}>
						2024 HiLux 4x4 SR5 Double-Cab Automatic – Valued at up to $70,000
					</LoginTitle>
					<SignUpBlock>
						<p>New to AFL Fantasy? Sign up here:</p>
						<AFLiDButonWrap>
							<AFLIDButton
								onClick={this.onSignUp}
							>Sign Up</AFLIDButton>
							<AFLIDLogInButton
								onClick={this.onSelectGame}
							>Log In</AFLIDLogInButton>
						</AFLiDButonWrap>
					</SignUpBlock>	
					<ImportantAccountInfo
						onClick={this.infoAFLidModal}
					>Important Account Info
					</ImportantAccountInfo>
				</Landing>
				{ show_sign_up ? this.sign_up_module : null }
				{ user.login.error || user.forgot_password.error ? this.error_modal : null }
			</React.Fragment>
		);
	}
}

const mapStateToProps = state => {
	const aflIdModalStatus = getAflIdModalStatus(state);
	return {
		is_authorized: isAuthenticated(),
		user: state.user,
		aflIdModalStatus,
		changePasswordSuccess: isChangePasswordSuccess(state)
	};
};

const mapDispatchToProps = {
	login,
	clearLoginErrors,
	forgotPassword,
	setAflIdModalStatus: setAflIdModalStatus,
	
};

export const Login = withRouter(connect(
	mapStateToProps,
	mapDispatchToProps
)(LoginPage));

export default Login;