// @flow
import * as React from "react";
import styled, { css } from "styled-components";
import _, { get } from "lodash";
import { Link } from "react-router-dom";
import type { TPlayer, TRound } from "../../../../modules/types";
import colors from "../../../../assets/css/colors";
import { below } from "../../../../assets/css/media";
import { ByeButton } from "../../../../components";
import Lock from "../../../../components/Icons/Lock";
import AddPlayer from "../../../../components/Icons/AddPlayer";
import NoDrop from "../../../../components/Icons/NoDrop";
import { currencyFormat } from "../../../../utils/currencyFormat";
import { getPartialByeRound, isAllTrue } from "../../../../helpers";
import { PoolPlayerInfo } from "./poolPlayerInfo";
import { PoolPlayerStatus } from "./poolPlayerStatus";

type Props = {
	player: TPlayer,
	sort_by: string,
	is_coach: boolean,
	has_assistant_coach: boolean,
	addPlayer: Function,
	is_locked: boolean,
	is_mobile?: boolean,
	no_positions: boolean,
	rounds: TRound[],
	bye_detector_active: boolean,
	bye_detector_selected: number[],
	player_bye: number,
	score_stat?: Object,
	week_trades_left: number,
	is_team_started: number,
	round: TRound,
	is_team_full_complete_started: boolean
}

const Statistic = styled.div`
	font-family: 'SourceSansPro';
	color: ${({ is_coach }) => is_coach ? colors.coach : colors.primary.primary};
	font-size: ${({ is_mobile }) => is_mobile ? "16px" : "12px"};
	width: 36px;
	text-align: center;

	flex: 1 1 0px;
	display: flex;
	justify-content: center;

	${({ is_score }) => is_score && "display: none;"}

	${below.desktop`
		${({ is_score }) => is_score && "display: flex;"}
	`}
`;

const AddPlayerLink = styled(Link)`
	text-decoration: none;
	margin-right: 10px;
`;

const AddPlayerWrapper = styled.button`
	color: #198DE1;
	cursor: pointer;
	margin-right: 10px;
	border: none;
	width: 24px;
	height: 24px;
	border-radius: 50%;
	padding: 0;
`;

const IconWrapper = styled.div`
	margin-left: auto;
	margin-right: 10px;
`;

const PoolPlayerCellStyled = styled.div`
	height: 70px;
	border-bottom: 1px solid #CAD2D8;
	display: flex;
	align-items: center;
	opacity: ${({ is_locked }) => is_locked ? .5 : 1};
	
	&:nth-child(odd) {
		background: #f8f8fa;

		${PoolPlayerStatus} {
			&:after {
				background: #f8f8fa;
			}
		}
	}
	
	${({ is_mobile }) => is_mobile && css`
		height: 60px;
		.player-info {
			width: auto;
			margin: 0;
			padding-left: 10px;
			box-sizing: border-box;

			flex: 0 0 70%;
			${below.desktop`
				flex-basis: 65%;
			`};
			${below.tablet`
				flex-basis: 250px;
			`};
			${below.phone`
				flex-basis: 180px;
			`};

			.player-details span.long {
				display: none;
			}
			.player-details span.short {
				display: inline;
			}

			.player-name {
				width: 55px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
		
		${AddPlayerWrapper} {
			margin-left: auto;
			margin-right: 10px;
		}
	`}
`;

const shouldBeFaded = (
	is_locked: boolean, 
	no_positions: boolean, 
	showing_bye: boolean,  
	bye_detector_active: boolean,  
	bye_detector_selected: number[], 
	player_bye: number
) => {
	if (is_locked && !no_positions) {
		return true;
	}
	return showing_bye && bye_detector_active && !_.includes(bye_detector_selected, player_bye);
};

export class PoolPlayerCell extends React.PureComponent<Props> {
	static defaultProps = {
		is_mobile: false
	};

	get showing_bye() {
		return this.props.sort_by === "stats.bye_round_id";
	}

	get showing_partial_bye() {
		return this.props.sort_by === "partial_bye_round";
	}

	get faded() {
		const {
			is_locked,
			no_positions,
			bye_detector_active,
			bye_detector_selected,
			player_bye,
		} = this.props;

		return shouldBeFaded(
			is_locked,
			no_positions,
			this.showing_bye,
			bye_detector_active,
			bye_detector_selected,
			player_bye
		);
	}

	get isPartialFaded() {
		const {
			is_locked,
			no_positions,
			bye_detector_active,
			bye_detector_selected,
			player,
			rounds
		} = this.props;

		const squadId = get(player, 'squad_id',0);
		const player_partial_bye = getPartialByeRound(squadId, rounds);

		return shouldBeFaded(
			is_locked,
			no_positions,
			this.showing_partial_bye,
			bye_detector_active,
			bye_detector_selected,
			player_partial_bye
		);
	}

	get value() {
		const {
			player,
			sort_by,
			bye_detector_active,
			player_bye,
			rounds,
			bye_detector_selected
		} = this.props;


		let value = _.eq(sort_by, "cost") ? (
			currencyFormat({ input: player.cost })
		) : (
			_.get(player, sort_by, "— —")
		);

		if (this.showing_bye && bye_detector_active && !this.faded) {
			const selected = isAllTrue([
				bye_detector_selected.includes(player_bye),
				bye_detector_active
			]);
			value = <ByeButton round={player_bye} selected={selected} />;
		}

		const squadId = get(player, 'squad_id',0);
		const player_partial_bye = getPartialByeRound(squadId, rounds);
	
		if(isAllTrue([
			this.showing_partial_bye,
			// bye_detector_active,
			player_partial_bye !== 0,
		])){
			const selected =  bye_detector_selected.includes(player_partial_bye) 
				&& bye_detector_active;
			value = <ByeButton round={player_partial_bye} selected={selected} />;
		}

		return value;
	}
	get trade_link() {
		const { player } = this.props;

		return `/classic/advanced-trades/${player.id}/in`;
	}

	get player_locked() {
		const { round, player, is_team_full_complete_started } = this.props;
		const locked_out = _.get(round, "lockout") === "full";

		return player.locked || locked_out || is_team_full_complete_started;
	}

	renderButton() {
		const {
			player,
			addPlayer,
			is_locked,
			is_team_started,
			week_trades_left,
		} = this.props;

		if (is_locked) {
			if (
				!this.player_locked
				&& is_team_started 
				&& week_trades_left
			) {
				return (
					<AddPlayerLink to={this.trade_link}>
						<AddPlayer size={1.5} />
					</AddPlayerLink>
				);
			}

			return (
				<IconWrapper>
					<NoDrop size={1.5} />
				</IconWrapper>
			);
		}

		return (
			<AddPlayerWrapper
				className="add-button-wrapper"
				title="Add Player"
				data-id={player.id}
				data-positions={player.positions}
				onClick={() => addPlayer({ id: player.id, positions: player.positions })}
			>
				<AddPlayer size={1.5} />
			</AddPlayerWrapper>
		);
	}

	renderStatistic() {
		const {
			is_coach,
			has_assistant_coach,
			is_mobile,
		} = this.props;

		return <Statistic is_mobile={is_mobile} is_coach={is_coach}>
			{is_coach && !has_assistant_coach ? (
				<Lock size={1.5} title="No assistant coach" />
			) : this.value}
		</Statistic>;
	}

	renderScore() {
		const {
			player,
			has_assistant_coach,
			is_mobile,
			score_stat,
		} = this.props;

		if (!score_stat) {
			return null;
		} 

		const stat_is_coach = score_stat.is_coach;
		const value = _.get(player, score_stat.key, "— —");

		return <Statistic is_mobile={is_mobile} is_coach={stat_is_coach} is_score>
			{stat_is_coach && !has_assistant_coach ? (
				<Lock size={1.5} title="No assistant coach" />
			) : value}
		</Statistic>;
	}

	render() {
		const {
			player,
			is_mobile,
		} = this.props;

		return (
			<PoolPlayerCellStyled 
				is_mobile={is_mobile} 
				is_locked={this.faded && !this.isPartialFaded} 
				className="playwright-mask-hidden"
			>
				<PoolPlayerInfo is_mobile={is_mobile} className="player-info" player={player} />
				{ this.renderScore() }
				{ this.renderStatistic() }
				{ this.renderButton() }
			</PoolPlayerCellStyled>
		);
	}
};

export default PoolPlayerCell;