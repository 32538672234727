// @flow
import _ from 'lodash';
import type { TDraftLeaguesReducer } from '../../types';

export const getWaiverTrades = (state: TDraftLeaguesReducer) => {
	const waiver_requests = _.get(state, 'leagues.waiver_requests', {});
	const { ordered_ids = [], by_id = {} } = waiver_requests;
	return _.reduce(ordered_ids, (accumulator, trade_id) => {
		accumulator.push(by_id[trade_id]);
		return accumulator;
	}, []);
};

export default getWaiverTrades;