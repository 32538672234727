// @flow
import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import * as _ from "lodash";

import * as actions from "../modules/actions";
import * as selectors from "../modules/selectors";

import {
	Footer,
	AdsContainer,
	Ad,
	PageContentWrapper,
	TwoColumnLayout,
	StandardSidebar,
	PageTitle,
	FantasyCoachBanner
} from "../components";

import CheckoutForm from "./coachSubscription/CheckoutForm";
import SuccessModal from "./coachSubscription/successModal";
import CancelModal from "./coachSubscription/cancelModal";
import ErrorModal from "./coachSubscription/errorModal";


const unsub_msg = "You have successfully cancelled your Fantasy Coach subscription. "
	+ "You'll still be able to use Fantasy Coach until your subscription period ends.";


type Props = {
	fetchUserSubscription: typeof actions.fetchUserSubscription,
	subscription: Object,
	subscribe: Object,
	unsubscribe: Object,
	has_coach_subscription: boolean,
	subscribeUser: Function,
	clearSubscribeUserErrors: Function,
	cancelSubscription: Function,
	from_account: boolean
}

type State = {
	success: {
		show: boolean,
		header_text?: string,
		body_text?: string
	},
	show_cancel_modal: boolean
}

const trigger_on_success = (props, old_props, key, onSuccess: Function) => {
	const is_pending = _.get(props, `${key}.is_pending`, false);
	const success = _.get(props, `${key}.success`, false);
	const old_ip = _.get(old_props, `${key}.is_pending`, false);

	if (!is_pending && old_ip && success) {
		onSuccess();
	}
};

class CoachSubscriptionSettingsComp extends React.Component<Props, State> {
	constructor() {
		super();

		_.bindAll(this, [
			"handleSubmit",
			"handleCancelButton",
			"handleCancelModalClose",
			"handleCancelModalConfirm",
			"handleSuccessModalClose",
			"handleErrorModalClose"
		]);
	}

	state = {
		success: {
			show: false,
			body_text: undefined,
			header_text: undefined
		},
		show_cancel_modal: false,
	};

	componentDidMount() {
		const { fetchUserSubscription } = this.props;
		fetchUserSubscription();
	}

	componentDidUpdate(old_props: Props) {
		const { fetchUserSubscription } = this.props;

		trigger_on_success(this.props, old_props, "subscribe",
			() => {
				this.setState({ success: { show: true } });
				fetchUserSubscription();
			}
		);

		trigger_on_success(this.props, old_props, "unsubscribe",
			() => {
				this.setState({
					success: {
						show: true,
						body_text: unsub_msg,
						header_text: "Unsubscription Successful"
					}
				});
				fetchUserSubscription();
			}
		);
	}

	checkCancelSuccess(old_props: Props) {
		const { subscription: { is_pending, success }, fetchUserSubscription } = this.props;
		const { subscription: { is_pending: old_ip } } = old_props;

		if (!is_pending && old_ip && success) {
			fetchUserSubscription();
		}
	}

	handleSubmit(values) {
		const { subscribeUser } = this.props;
		subscribeUser(values);
	}

	handleCancelButton() {
		this.setState({ show_cancel_modal: true });
	}

	get error_modal() {
		const { subscribe } = this.props;

		return (
			<ErrorModal
				onClick={this.handleErrorModalClose}
				header_text='Error'
				text={subscribe.error.message} />
		);
	}

	get success_modal() {
		const { body_text, header_text } = this.state.success;
		return (
			<SuccessModal
				closeModal={this.handleSuccessModalClose}
				body_text={body_text}
				header_text={header_text}
			/>
		);
	}

	get cancel_modal() {
		return (
			<CancelModal
				cancelSubscription={this.handleCancelModalConfirm}
				closeModal={this.handleCancelModalClose}
			/>
		);
	}

	handleSuccessModalClose() {
		this.setState({
			success: { show: false }
		});
		this.props.fetchUserSubscription();
	}

	handleCancelModalClose() {
		this.setState({
			show_cancel_modal: false
		});
	}

	handleCancelModalConfirm() {
		this.setState({
			show_cancel_modal: false
		});
		this.props.cancelSubscription();
	}

	handleErrorModalClose() {
		const { clearSubscribeUserErrors } = this.props;
		clearSubscribeUserErrors();
	}

	showModals() {
		const { subscribe } = this.props;
		const { success, show_cancel_modal } = this.state;

		return (<React.Fragment>
			{ subscribe && subscribe.error ? this.error_modal : null }
			{ success.show ? this.success_modal : null}
			{ show_cancel_modal ? this.cancel_modal : null}
		</React.Fragment>);
	}

	get has_subscription() {
		const { subscription } = this.props;
		return subscription && subscription.status !== "unsubscribed";
	}

	render() {
		const { plan } = this.props.subscription;

		if(!this.has_subscription && !this.state.success.show) {
			return (<Redirect to="/coach-subscription" />);
		}

		if(this.props.from_account){
			return(
				<React.Fragment>
					{ this.showModals() }
					<CheckoutForm
						is_active={true}
						plan={plan}
						handleSubmit={this.handleSubmit}
						onCancelSubscription={this.handleCancelButton}
					/>

				</React.Fragment>
			);
		}

	
		return (
			<React.Fragment>
				{ this.showModals() }
				<AdsContainer>
					<Ad/>
				</AdsContainer>
				<FantasyCoachBanner />
				<PageContentWrapper>
					<PageTitle>
						Subscription settings
					</PageTitle>
					<TwoColumnLayout>
						<CheckoutForm
							is_active={true}
							plan={plan}
							handleSubmit={this.handleSubmit}
							onCancelSubscription={this.handleCancelButton}
						/>
						<StandardSidebar />
					</TwoColumnLayout>
				</PageContentWrapper>
				<Footer />
			</React.Fragment>
		);
	}
}


const mapStateToProps = state => ({
	subscription: state.user.subscription,
	subscribe: state.user.subscribe,
	unsubscribe: state.user.unsubscribe,
	has_coach_subscription: Boolean(selectors.getUser(state).assistant_coach),

});

const mapDispatchToProps = {
	subscribeUser: actions.subscribeUser,
	fetchUserSubscription: actions.fetchUserSubscription,
	clearSubscribeUserErrors: actions.clearSubscribeUserErrors,
	cancelSubscription: actions.unsubscribeUser
};


export const CoachSubscriptionSettings = connect(
	mapStateToProps,
	mapDispatchToProps,
)(CoachSubscriptionSettingsComp);

export default CoachSubscriptionSettings;