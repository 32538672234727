// @flow
import styled from "styled-components";
import colors from "../../../../assets/css/colors";

export const ButtonSocial = styled.a`
	display: flex;
	align-items: center;
	cursor: pointer;
	width: 92%;
	height: 40px;
	border: 1px solid #d3d7db;
	border-radius: 2px;
	color: ${colors.primary.primary};
	font-size: 12px;
	margin: 1em 0;
	width:100%;
	background: white;
	border-right: none;
	
	> div {
		flex-basis: 15%;
	}
		.text{
			justify-content: flex-start;
			padding-left:0.5em ;
			color: ${colors.secondary.accentGrey};
		}
	
	div{
		display: flex;
		justify-content: center;
		border-right: 1px solid #d3d7db;
		align-items: center;
		flex-basis: 5%;
		height: 100%;
		
		&.text{
			flex-basis: 95%;
		}
	}
`;

export default ButtonSocial;