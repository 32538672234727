// @flow

export const LoginValidate = (values: Object) => {
	const errors = {};

	if (!values.login) {
		errors.login = "Required";
	}
	else if(values.login && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.login)) {
		errors.login = "Invalid email address";
	}

	if (!values.password) {
		errors.password = "Required";
	}

	return errors;
};

export const PreregistrationValidate = (values: Object) => {
	const errors = {};

	if (!values.email) {
		errors.email = "Required";
	}
	else if(values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
		errors.email = "Invalid email address";
	}

	if (!values.first_name) {
		errors.first_name = "Required";
	}

	return errors;
};