// @flow
import * as React from "react";
import { connect } from "react-redux";
import _, { isEmpty } from "lodash";
import { compose } from "redux";
import { Redirect, withRouter } from "react-router-dom";
import styled from "styled-components";
import * as selectors from "../../../modules/selectors";
import * as actions from "../../../modules/actions";
import ErrorModal from "../../coachSubscription/errorModal";
import {
	AdsContainer,
	ConfirmationModal,
	PageTitle,
	Footer,
	PageContentWrapper,
	StandardSidebar,
	TwoColumnLayout,
	LeagueGameBar,
	Preloader,
	FinaliseOrUnlockLeague
} from "../../../components";
import type { TPLeague, TDraftTeamsById, TDraftUserTeam } from "../../../modules/types";

import Ad from "../../../components/Ad";
import TeamUserAvatar from "../../../components/Team/TeamUserAvatar";
import Close from "../../../components/Icons/Close";
import colors from "../../../assets/css/colors";
import { getShortName } from "../../../helpers/league";

import ReplaceCoach from "./leagueInvite/ReplaceCoach";
import LeagueTeamInvite from "./leagueInvite/LeagueTeamInvite";
import {
	HeadingDiv,
	CloseButton,
	Commish,
	RemoveButton,
	LeaveButton,
	InviteTextButton,
	PostDraftButtonsWrap,
	ReplaceButton,
	TeamCellWrapper,
	TeamInfoWrapper,
	AvatarWrapper,
	NamesWrapper,
	TeamName,
	InviteOpenWrapper,
	UserNameIndicatorWrapper,
	UserName,
	JoinedWrapper,
	JoinedIndicator,
	ActionsWrapper,
	DraftBody,
} from "./leagueTeams/LeagueTeamsStyles";
import { AssignTeamButtons } from "./leagueTeams/AssignTeamButtons";


const ManageInviteOpenWrapper = styled(InviteOpenWrapper)`
	left:unset;
`;

const StyledHeadingDiv = styled(HeadingDiv)`
	display: flex;
	justify-content: space-between;
`;
type Props = {
	selected_league_id: number,
	showDraftOrder: typeof actions.leagueDraft.showDraftOrder,
	changeDraftOrder: actions.leagueDraft.changeDraftOrder,
	leaveDraftLeague: typeof actions.leagueDraft.leaveDraftLeague,
	removeTeamDraftLeague: typeof actions.leagueDraft.removeTeamDraftLeague,
	fetchLeague: typeof actions.leagueDraft.showDraftLeague,
	showTeamsDraftLeague: typeof actions.leagueDraft.showTeamsDraftLeague,
	inviteToDraftLeague: typeof actions.leagueDraft.inviteToDraftLeague,
	draftLeagueTeamUpdate: typeof actions.leagueDraft.draftLeagueTeamUpdate,
	updateDraftLeague: typeof actions.leagueDraft.updateDraftLeague,
	clearDraftLeagueUpdateErrors: typeof actions.leagueDraft.clearDraftLeagueUpdateErrors,
	is_pending_show: boolean,
	teamsAssignedUsers: Object,
	history: {
		push: Function,
	},
	leagueUpdate: Boolean,
	user_id: number,
	league: TPLeague,
	teams: Array<TDraftUserTeam>,
	draft_order: Array<number>,
	error?: string,
	match: {
		params: {
			league_id: string
		}
	},
	leave_league_success: boolean,
	remove_team_success: boolean,
	newCoach: Object,
	teams_by_id: TDraftTeamsById,
	league_error: string
}



type NewCoach = {team_id: number, value:{firstname: string, user_id: number, lastname: string}}

type State = {
	show_leave_modal: boolean,
	show_remove_modal: boolean,
	left_the_league: boolean,
	remove_the_team: boolean,
	remove_id: number,
	show_invite_inline: boolean,
	show_replace_form: boolean,
	currentReplaceIndex: Array<number>,
	new_coaches: Array<NewCoach>,
	opened_assign_teams: Array<number>,
	teamsAssigned: Object,
	inviteIndex: number,
};

class LeagueTeamsComponent extends React.Component<Props, State> {

	constructor(props, state) {
		super(props, state);

		_.bindAll(this, [
			"leaveThisLeague",
			"removeFromLeague",
			"confirmLeaveleague",
			"confirmRemoveTeamleague",
			"closeLeaveModal",
			"closeRemoveModal",
			"openInviteInline",
			"closeInviteInline",
			"openReplaceForm",
			"addCoach",
			"handleEditTeamsClick",
			"finaliseInviteEmails",
			"handleOpenedAssignedTeams",
			"onTeamChange",
			"closeAssignedTeams",
			"handleErrorModalClose",
			"closeTeamRemoveModal",
			"openRemoveModal",
			"openSecondConfirmRemove"
		]);
	}

	state = {
		show_remove_modal: false,
		show_remove_confirm_modal: false,
		show_leave_modal: false,
		left_the_league: false,
		remove_the_team: false,
		remove_id: 0,
		show_invite_inline: false,
		show_replace_form: false,
		currentReplaceIndex: [],
		new_coaches: [],
		opened_assign_teams: [],
		teamsAssigned: this.props.teamsAssignedUsers,
		inviteIndex: 0,
	};

	componentDidMount() {
		this.fetchLeague();
		const { league_id } = this.props.match.params;
		this.props.showTeamsDraftLeague({ league_id });
	}

	componentDidUpdate(prev_props: Props) {
		const { remove_team_success: success } = this.props;
		const { remove_team_success: old_success } = prev_props;
		if (!old_success && success) {
			window.location.reload();
		}
		if(_.isEmpty(prev_props.teamsAssignedUsers)
			 && !_.isEmpty(this.props.teamsAssignedUsers)){
			this.setState({
				teamsAssigned: this.props.teamsAssignedUsers
			});
		}
	}

	confirmLeaveleague() {
		const { leaveDraftLeague, league: { id } } = this.props;
		leaveDraftLeague({ league_id: id });
		this.setState({ left_the_league: true });

	}
	finaliseInviteEmails(e) {
		const { draftLeagueTeamUpdate, updateDraftLeague, league } = this.props;
		const { new_coaches } = this.state;
		e.preventDefault();
		new_coaches.map(new_coach => (
			draftLeagueTeamUpdate(new_coach)
		));
		this.setState({ new_coaches: [] });
		const scoreValues = league.custom_scoring_enabled ? league.custom_scoring : {};
		const skipCustomScoring = (league && league.draft_status !== "scheduled") 
			|| isEmpty(scoreValues);
		updateDraftLeague({
			...league,
			id: league.id,
			teams_finalised: 1,
			scoreValues,
			skipCustomScoring
		});
	}

	confirmRemoveTeamleague() {
		const { removeTeamDraftLeague, league: { id } } = this.props;
		const team_id = this.state.remove_id;
		removeTeamDraftLeague({ league_id: id,  team_id: team_id });
		this.removeFromLeague();
	}

	cancelRemoveTeamLeague() {
		this.setState({ remove_the_team: false });
	}

	leaveThisLeague() {
		this.setState({ show_leave_modal: true });
	}

	removeFromLeague() {
		this.setState({ remove_the_team: false });
	}

	openSecondConfirmRemove() {
		this.setState({
			show_remove_modal: false,
			remove_the_team: true
		});
	}

	openRemoveModal({ currentTarget }) {
		const { id } = currentTarget.dataset;
		this.setState({show_remove_modal: true, remove_id: parseInt(id, 10)});
	}

	closeLeaveModal() {
		this.setState({ show_leave_modal: false });
	}

	closeRemoveModal() {
		this.setState({ show_remove_modal: false });
	}

	closeTeamRemoveModal() {
		this.setState({remove_the_team: false});
	}
	openInviteInline(index: number){
		this.setState({ show_invite_inline: true, inviteIndex: index });
	}
	closeInviteInline(){
		this.setState({ show_invite_inline: false, inviteIndex: 0 });
	}
	openReplaceForm(index){
		this.setState({
			currentReplaceIndex: [...this.state.currentReplaceIndex, index]
		});
	}

	handleOpenedAssignedTeams(index){
		this.setState({
			opened_assign_teams: [...this.state.opened_assign_teams, index]
		});
	}

	closeAssignedTeams(index){
		const { opened_assign_teams } = this.state;
		const removedArr = opened_assign_teams.filter(team => team !== index);
		this.setState({
			opened_assign_teams: removedArr
		});
	}

	addCoach(){
		const { newCoach } = this.props;
		const{ user_id, first_name, last_name, team_id, team_name } = newCoach;
		const payload = {
			team_id: team_id,
			value: {
				user_id,
				firstname: first_name,
				lastname: last_name,
				avatar_version: 1,
				name: team_name,
			}
		};
		this.setState({
			new_coaches: [...this.state.new_coaches, payload]
		});
	}



	get leave_modal() {
		return (
			<ConfirmationModal
				header_text='Leave League?'
				body_text={<div>
					If you leave this league,{" "}
					any keepers associated with your team will be lost,{" "}
					even if you re-join the league<br />
					Consider asking your commissioner to use the{" "}
					<strong>Set New Coach For Keeper Team</strong>{" "}
					option if you just want to change which account{" "}
					is in charge of this team.</div>}
				confirmClick={this.confirmLeaveleague}
				cancelClick={this.closeLeaveModal}
				closeClick={this.closeLeaveModal}
			/>
		);
	}

	get remove_modal() {
		return (
			<ConfirmationModal
				header_text='Remove Team?'
				body_text={<div>
					If you remove a team from your league, you cannot add it back. 
					Any keepers associated with this team will be removed.<br />
					Consider using the <strong>Set New Coach For Keeper Team</strong>
					{" "}option if you are looking to replace the coach without{" "}
					losing the link to the past team and keepers.</div>}
				confirmClick={this.openSecondConfirmRemove}
				cancelClick={this.closeRemoveModal}
				closeClick={this.closeRemoveModal}
			/>
		);
	}

	get remove_team_modal() {
		return (
			<ConfirmationModal
				header_text='Remove Team?'
				body_text={<div>
					Are you sure you want to remove this user from the league</div>}
				confirmClick={this.confirmRemoveTeamleague}
				cancelClick={this.closeTeamRemoveModal}
				closeClick={this.closeTeamRemoveModal}
			/>
		);
	}

	get status_and_time_until_draft() {
		const
			{
				league,
			} = this.props;
		return (league.status === "scheduled"
			&& (Date.parse(league.draft_start) - Date.parse(Date())) > 1800000);
	}

	fetchLeague() {
		const { fetchLeague, selected_league_id } = this.props;
		fetchLeague({
			id: selected_league_id
		});
	}

	handleEditTeamsClick() {
		const { selected_league_id, history } = this.props;
		const url = `draft/league/${selected_league_id}/edit-teams`;
		history.push(url);
	}


	get table_header() {
		const {teams, league} = this.props;
		const teamsJoined = teams.reduce((numJoined, currTeam) => {
			if(currTeam.active){
				return numJoined += 1;
			}
			return numJoined;
		}, 0);
		const leagueSize = league.size;
		return (
			<StyledHeadingDiv>
				Team / Owner
				<p>
					{teamsJoined} / {leagueSize} teams joined
				</p>
			</StyledHeadingDiv>
		);
	}

	onTeamChange(e) {
		const { teams_by_id } = this.props;
		const { value, dataset } = e.target;
		const newValueTeam = teams_by_id[value];
		const { user } = dataset;
		this.setState({
			teamsAssigned: {
				...this.state.teamsAssigned,
				[newValueTeam.id]: Number(user)
			}
		});
	}

	assignTeamButtons(index, currTeam) {
		const {
			league,
			newCoach,
			user_id
		} = this.props;
		const {new_coaches, opened_assign_teams} = this.state;
		return <AssignTeamButtons 
			addCoach={this.addCoach}
			closeAssignedTeams={this.closeAssignedTeams}
			currTeam={currTeam}
			handleOpenedAssignedTeams={this.handleOpenedAssignedTeams}
			index={index}
			league={league}
			new_coaches={new_coaches}
			success={newCoach.success}
			opened_assign_teams={opened_assign_teams}
			user_id={user_id}
		/>;


	}

	commissionerButton(team) {
		const {
			user_id,
			league
		} = this.props;
		if (user_id !== league.commissioner && team.user_id === league.commissioner) {
			return (
				<div>
					<Commish>Commisioner</Commish>
				</div>
			);
		}
		return null;
	};

	 removeOrLeaveButtons(team) {
		const {
			user_id,
			league
		} = this.props;
		
		const status = this.status_and_time_until_draft;
		if (user_id !== team.user_id
			&& user_id === league.commissioner && status) {
			return (
				<div>
					<RemoveButton
						disabled={league.teams_finalised}
						data-id={team.id}
						onClick={this.openRemoveModal}>
						Remove from the league
					</RemoveButton>
				</div>
			);
		}
		else if (user_id === team.user_id && status) {
			return (
				<div>
					<LeaveButton
						disabled={league.teams_finalised}
						onClick={this.leaveThisLeague}>
						Leave league
					</LeaveButton>
				</div>
			);
		}
		return (
			this.commissionerButton(team)
		);
	};
	inviteButton(index: number){
		const { user_id, league } = this.props;
		const status = this.status_and_time_until_draft;
		if((user_id === league.commissioner || league.privacy === 1) && status){
			return <InviteTextButton 
				onClick={()=>this.openInviteInline(index)}
			>Invite</InviteTextButton>;
		};
		return null;
	}
	postDraftButtons(team, index){
		const { user_id, league } = this.props;
		const { currentReplaceIndex } = this.state;
		const status = this.status_and_time_until_draft;
		// const edit_teams_disabled = league.draft_status !== 'complete';
		const handleReplace = () => this.openReplaceForm(index);
		if(user_id === league.commissioner && !status && team.user_id !== user_id){
			return(
				<PostDraftButtonsWrap>
					{/* <EditTeamButton 
						onClick={this.handleEditTeamsClick}
						no_border={currentReplaceIndex.includes(index)} 
						disabled={edit_teams_disabled}
					>
						Edit Team
					</EditTeamButton> */}
					{currentReplaceIndex.includes(index) 
						? 
						<ReplaceCoach 
							team={team} 
							league={league} 
							addCoach={this.addCoach} 
							new_coaches={this.state.new_coaches}
							success={this.props.newCoach.success}
						/> 
						: <ReplaceButton 
							onClick={handleReplace}>
							Replace coach from the league
						</ReplaceButton>}
				</PostDraftButtonsWrap>
			);
		}
	}
	empty_team_row(index: number) {
		const { league: { id } } = this.props;
		const { show_invite_inline } = this.state;
		return(
			<TeamCellWrapper	
				key={`${index}-empty`}
			>
				<div>
					<TeamInfoWrapper>
						<AvatarWrapper>
							<TeamUserAvatar
								
							/>
						</AvatarWrapper>
						<NamesWrapper>
							<TeamName>Invite another team</TeamName>
						</NamesWrapper>
					</TeamInfoWrapper>
				</div>
				{ show_invite_inline && this.state.inviteIndex===index ?
					<ManageInviteOpenWrapper isOpen={show_invite_inline}>
						<CloseButton onClick={this.closeInviteInline}>
							<Close />
						</CloseButton>
						<LeagueTeamInvite 
							leagueID={id} 
							closeClick={this.closeInviteInline}
						/>
					</ManageInviteOpenWrapper>
					 :
					this.inviteButton(index)
				}
			</TeamCellWrapper>
		);
	}

	get error_modal() {
		const { league_error } = this.props;
		if(!league_error){
			return null;
		}
		return (
			<ErrorModal
				onClick={this.handleErrorModalClose}
				header_text='Error'
				text={league_error} />
		);
	}

	handleErrorModalClose() {
		const { clearDraftLeagueUpdateErrors } = this.props;
		clearDraftLeagueUpdateErrors();
		window.location.reload();
	}


	get table_body() {
		const {
			league, teams
		} = this.props;
		let mappingArray = teams;
		if (mappingArray.length < league.size ){
			const difference = league.size - mappingArray.length;
			for(let i=0; i<difference; i++){
				mappingArray.push("empty");
			}
		}
		// if(!Boolean(league.teams_finalised) && league.size !== 20){
		// 	const emptyRowsAdd = league.size % 2 === 0 
		// 		? ["empty", "empty"]
		// 		: ["empty"];
		// 	mappingArray = _.concat(mappingArray, emptyRowsAdd);
		// }
		const cells = mappingArray.map((team, index) => {
			const draft_order = index;
			const is_ignored = !(draft_order + 1);
			const joinedData = this.isTeamJoined(team);
			if(team === "empty"){
				return this.empty_team_row(index);
			}
			return (
				<TeamCellWrapper
					className={is_ignored ? "is-ignored" : null}
					key={team.id}
				>
					<div>
						<TeamInfoWrapper>
							<AvatarWrapper>
								<TeamUserAvatar
									user_id={team.user_id}
									avatar_version={team.avatar_version}
									firstname={team.firstname}
									lastname={team.lastname}
								/>
							</AvatarWrapper>
							<NamesWrapper>
								<TeamName>{ team.name }</TeamName>
								<UserNameIndicatorWrapper>
									<UserName>
										{ getShortName(team) }
										
									</UserName>
									<JoinedWrapper color={joinedData.color}>
										<JoinedIndicator color={joinedData.color}/>
										{joinedData.text}
									</JoinedWrapper>
								</UserNameIndicatorWrapper>
									
							</NamesWrapper>
						</TeamInfoWrapper>
					</div>
					<ActionsWrapper>
						{this.assignTeamButtons(index, team)}
						{this.removeOrLeaveButtons(team)}
						{this.postDraftButtons(team, index)}
					</ActionsWrapper>
					
				</TeamCellWrapper>
			);
		});

		return <DraftBody>{ cells }</DraftBody>;
	}

	get show_manage_teams_table() {
		
		return (
			<div>
				{ this.table_header }
				{ this.table_body }
				<FinaliseOrUnlockLeague
					finaliseInviteEmails={this.finaliseInviteEmails}
				 />
			</div>
		);
	
	}

	isLeagueFullJoined(){
		const {
			league, teams
		} = this.props;
		const { size } = league;
		const mappingArray = [...teams];
		if (mappingArray.length < size ){
			const difference = size - mappingArray.length;
			for(let i=0; i<difference; i++){
				mappingArray.push("empty");
			}
		}
		const number_ready = mappingArray.reduce((totalReady, team) => {
			const isActive = _.get(team, "active", 0);
			return totalReady = totalReady + isActive;
		}, 0);

		return number_ready === size;
	}

	isTeamJoined(team) {
		if(_.get(team, "active")){
			return {
				text: "Joined",
				color: colors.primary.accentGreen
			};
		}
		return {
			text: "Yet to join",
			color: colors.secondary.accentGrey
		};
	}

	getHeading(){
		const {
			league,
			user_id
		} = this.props;
		if(user_id === league.commissioner){
			return "Manage Teams";
		}
		else{
			return "League Teams";
		}
	}
	render() {
		const {
			leave_league_success,
			leagueUpdate,
		} = this.props;
		const {
			show_leave_modal,
			show_remove_modal,
			left_the_league,
			remove_the_team
		} = this.state;
		return (
			<React.Fragment>
				{this.error_modal}
				<LeagueGameBar no_fetch={true} />
				<AdsContainer>
					<Ad />
				</AdsContainer>
				{leagueUpdate ? <Preloader />:null}
				{left_the_league && leave_league_success
					? <Redirect to='/draft/leagues' /> : null}
				{show_leave_modal ? this.leave_modal : null}
				{show_remove_modal ? this.remove_modal : null}
				{remove_the_team ? this.remove_team_modal: null}
		
				<PageContentWrapper>
					<PageTitle>
						{this.getHeading()}
					</PageTitle>
					<TwoColumnLayout>
						{this.show_manage_teams_table}
						<StandardSidebar />
					</TwoColumnLayout>
				</PageContentWrapper>
				<Footer />
			</React.Fragment>
		);
	}
}
const mapStateToProps = (state, props) => {
	const { leagues: { show_order: { error }, teams_by_id } } = state;
	const selected_league_id = props.match.params.league_id;
	const teams = Object.keys(state.leagues.teams_by_id)
		.map(team_id => state.leagues.teams_by_id[team_id]);

	const teamsAssignedUsers = Object.keys(teams_by_id).reduce((obj, team_id) =>{
		return{
			...obj,
			[team_id]: teams_by_id[team_id].user_id,
		};
	}, {});

	const leagues_loading_status = state.leagues.show_teams.status;
	
	return {
		selected_league_id,
		leagues_loading_status,
		league: selectors.leagues.getLeague(state, props),
		is_pending_show: selectors.leagues.show.isPending(state),
		user_id: selectors.getUser(state).id,
		leave_league_success: state.leagues.leave.success,
		remove_team_success: state.leagues.remove_team.success,
		draft_order: state.leagues.show_order.ordered_ids,
		error,
		teams_by_id,
		teams,
		newCoach: state.leagues.change_team_coach,
		leagueUpdate: state.leagues.update.is_pending,
		teamsAssignedUsers,
		league_error: state.leagues.update.error
	};
};
const mapDispatchToProps = {
	fetchLeague: actions.leagueDraft.showDraftLeague,
	leaveDraftLeague: actions.leagueDraft.leaveDraftLeague,
	removeTeamDraftLeague: actions.leagueDraft.removeTeamDraftLeague,
	showDraftOrder: actions.leagueDraft.showDraftOrder,
	changeDraftOrder: actions.leagueDraft.changeDraftOrder,
	showTeamsDraftLeague: actions.leagueDraft.showTeamsDraftLeague,
	inviteToDraftLeague: actions.leagueDraft.inviteToDraftLeague,
	draftLeagueTeamUpdate: actions.leagueDraft.draftLeagueTeamUpdate,
	updateDraftLeague: actions.leagueDraft.updateDraftLeague,
	clearDraftLeagueUpdateErrors: actions.leagueDraft.clearDraftLeagueUpdateErrors
};

export const LeagueTeams = compose(
	withRouter,
	connect(
		mapStateToProps,
		mapDispatchToProps,
	))(LeagueTeamsComponent);

export default LeagueTeams;