// @flow
import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import bindAll from "lodash/bindAll";
import { withRouter } from "react-router-dom";

import * as actions from "../../../../modules/actions";
import colors from "../../../../assets/css/colors";
import { below } from "../../../../assets/css/media";

import { ButtonPrimary, LeagueAvatar, LeagueName, ConfirmationModal } from "../../../../components";
const SEASON_YEAR = process.env.REACT_APP_SEASON_YEAR || "";;

const Wrapper = styled.section`
	width: 100%;
`;

const Title = styled.h2`
	font-size: 24px;
	line-height: 1.666;
	color: ${colors.primary.primary};
	margin: 1.25em 0;
`;

const League = styled.div`
	width: 100%;
	box-sizing: border-box;
	padding: 20px;
	border: 1px dashed #CAD2D8;
	min-height: 100px;
	margin-bottom: 20px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;

const LeagueNameWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	> :first-child {
		width: 60px;
		height: 60px;
		margin-right: 10px;
	}
`;

const Details = styled.div`
	display: flex;
	flex-direction: row;
	font-family: TitilliumUpright;
	font-weight: 700;
	text-transform: uppercase;
	color: ${colors.secondary.accentGrey};
	> div {
		margin: 0 5px;
		text-align: center;
		font-size: 12px;
		line-height: 2.5;
		width: 5em;
	}
	span {
		color: ${colors.primary.darkGrey};
	}
	${below.tablet`
		display: none;
	`}
`;


type Props = {
	leagues: [{
		id: number,
		name: string,
		firstname: string,
		lastname: string,
		avatar_version: number,
		avatar_prefix: string,
		is_club?: boolean,
		users: Array<Object>,
		size: number,
		privacy: 1 | 0,
		start_round: number
	}],
	fetchRegenerateLeaguesList: typeof actions.regenerateListClassicLeagues,
	fetchRegenerateLeague: typeof actions.regenerateShowClassicLeague,
	regenerate_list: Object,
	history: {
		push: Function
	},
	match: {
		params: {
			regenerate_league_id?: string
		}
	}
}

type State = {
	confirm_modal: boolean,
	selected_regenerated_league_id: number,
}

class RegenerateLeaguesComp extends React.Component<Props, State> {
	constructor(props) {
		super(props);
		bindAll(this, ["handleRegnerateLeague"]);
	}

	state = {
		confirm_modal: false,
		selected_regenerated_league_id: 0,
	};

	componentDidMount() {
		this.props.fetchRegenerateLeaguesList();
	}

	componentDidUpdate(prev_props:Props) {
		// fetch each regen league
		if(prev_props.regenerate_list.length === 0 && this.props.regenerate_list.length > 0) {
			this.props.regenerate_list.forEach(v => {
				this.props.fetchRegenerateLeague({ id: v.id });
			});
		}


	}

	handleRegnerateLeague() {
		this.props.history
			 .push(`/classic/leagues/create/${this.state.selected_regenerated_league_id}`);
		this.setState({ confirm_modal: false });
	}

	render () {
		const { leagues } = this.props;

		if(leagues.length === 0) {
			return null;
		}
		return (
			<Wrapper>
				{this.state.confirm_modal && <ConfirmationModal
					body_text="This action will pre-fill the create league form with the same settings from last season. You can still make changes to any setting that you please before confirming the league and inviting all your mates! Do you want to continue?" // eslint-disable-line max-len
					header_text="Regenerate League"
					cancelClick={() => this.setState({ confirm_modal: false })}
					closeClick={() => this.setState({ confirm_modal: false })}
					confirmClick={this.handleRegnerateLeague}
					confirm_button_text="Continue"
				/>}
				<Title>
					Regenerate your {parseInt(SEASON_YEAR, 10) - 1} leagues
				</Title>
				{leagues.map(league => {
					if(
						this.props.match.params.regenerate_league_id &&
						this.props.match.params.regenerate_league_id === league.id.toString()
					) {
						return null;
					}
					return (
						<League key={league.id}>
							<LeagueNameWrapper>
								<div>
									<LeagueAvatar league={league} size="60px" is_classic={true}/>
								</div>
								<LeagueName
									name={league.name}
									width="11.4em"
									lastname=""
									firstname=""
								/>
							</LeagueNameWrapper>
							<Details>
								<div>
									<p>Type</p>
									<span>
										{league.privacy === 0 ? "PRIVATE": "PUBLIC"}
									</span>
								</div>
								<div>
									<p>Size</p>
									<span>
										{league.size}
									</span>
								</div>
								<div>
									<p>Start</p>
									<span>
									Round {league.start_round}
									</span>
								</div>
							</Details>
							<div>
								<ButtonPrimary onClick={() => {
									this.setState({
										confirm_modal: true,
										selected_regenerated_league_id: league.id
									});
								}}>
									Regenerate league
								</ButtonPrimary>
							</div>
						</League>
					);
				})}
			</Wrapper>
		);
	}
}

const mapStateToProps = state => ({
	regenerate_list: state.leaguesClassic.regenerate_list.result,
	leagues: state.leaguesClassic.regenerate_list.result
		.filter(l => l.id in state.leaguesClassic.regenerate_show.by_id)
		.map(l => state.leaguesClassic.regenerate_show.by_id[l.id]),
});

const mapDispatchToProps = {
	fetchRegenerateLeaguesList: actions.regenerateListClassicLeagues,
	fetchRegenerateLeague: actions.regenerateShowClassicLeague,
};


export const RegenerateLeagues =
	withRouter(connect(mapStateToProps, mapDispatchToProps)(RegenerateLeaguesComp));
export default RegenerateLeagues;