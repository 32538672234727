// @flow
import styled, { css } from "styled-components";
import * as React from "react";
import _ from "lodash";
import connect from "react-redux/es/connect/connect";
import colors from "../../../assets/css/colors";
import { below } from "../../../assets/css/media";
import {
	ButtonAlert,
	ModalContainer,
	ModalInnerTitle,
	TradeInCellShow,
	TradeOutCellShow,
} from "../../../components";
import * as actions from "../../../modules/actions";
import * as selectors from "../../../modules/selectors";
import Oval from "../../../components/Icons/Oval";
import CellsCondensed from "../../../components/Icons/CellsCondensed";
import { ButtonSecondary, CancelButton, ButtonPrimary } from "../../../components";
import type { 
	TClassicTeam, TRootStore, TPlayersArrayWithFixtureById } from "../../../modules/types";
import Arrow from "../../../components/Icons/Arrow";
import Transactions from "../../../components/Icons/Transactions";
import {
	TopControlsWrapper,
	SaveTeamButton,
	TeamViewControls,
	FieldViewControlWrapper,
	TeamName
} from "../../../components/MyTeam/TopControls";
import Caution from "../../../components/Icons/Caution";
import FlipToggle from "../../../components/Field/flipToggle";
import { convertLineupForBE } from "../../../modules/sagas/teamsClassic";

const AutoFillButton = styled(ButtonSecondary)`
	min-width: 8.5em;
	margin-left: 8px;
	background-color: white;
	color: ${colors.primary.primary};
	border-width: 1px;
	${below.desktop`
		min-width: 80px
	`}
	${below.phone`
		margin: 0 4px
	`}
`;

const ClearAllButton = styled(CancelButton)`
	min-width: 8.5em;
	margin-left: 8px;
	background-color: white;
	color: red;
	border-color: red;
	border-width: 1px;
	${below.desktop`
		min-width: 80px
	`}
	${below.phone`
		margin: 0 4px
	`}
`;

const StyledSaveButton = styled(SaveTeamButton)`
	min-width: 8.5em;
	margin-left: 8px;
	background-color: white;
	color: #37B5EA;
	border-color: #37B5EA;
	border-width: 1px;
	${below.desktop`
		min-width: 80px
	`}
	${below.phone`
		display: flex !important;
		margin: 0 4px
	`}
`;

const MobileTeamName = styled(TeamName)`
	${below.desktop`
		flex: unset;
	`}
	${below.phone`
		display: none;
	`}
`;

const OpenPlayerPoolButton = styled(ButtonPrimary)`
	background: #8D979C;
	border: none;
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: #FFF;
	padding: 5px 0 5px 12px;
	font-size: 13px;
	margin-right: 10px;
	
	svg {
		min-width: 24px;
		min-height: 24px;
		margin-left: 3px;
	}
`;

const ButtonGroup = styled.div`
	display: flex;
	align-items: center;
	button {
		height: 40px;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		justify-content: center;
		${below.desktop`
			height: 35px;
			margin-right: 10px;
		`}
		svg {
			margin-top: -3px;
		}
	}
`;

const ModalInnerSubTitle = styled.p`
	color: #262424;
	font-family: "SourceSansPro";
	text-align: center;
	max-width: 60%;
	margin: 0 auto 1em;
	font-size: 14px;
	line-height: 1.42;
`;
const TradeColumn = styled.div`
	width: 440px;
`;
const ConfirmModalContent = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 1em 2em 40px;
	${below.desktop`
		${css`
			margin: 1em 0;
		`}
	`}
	
	> div > div {
		margin-bottom: 1em;
	}
	
	> svg {
		margin: 0 50px;
	}

`;

const ConfirmModalContentStyled = styled(ConfirmModalContent)`
	${below.desktop`
		${css`
			> svg {
				margin: 0 20px;
			}
		`}
	`}
`;

const ConfirmButtonsWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 20px;
	
	button {
		width: 220px;
		height: 40px;
		margin: 0 20px;
		${below.desktop`
			height: 50px;
		`}
	}
`;

const NameWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: baseline;
	justify-content: flex-start;
`;

type Props = {
	team: TClassicTeam,
	handleFlip: Function,
	clear: Function,
	saveTeam: Function,
	autoFill: Function,
	handleViewType: Function,
	is_type_field_list: boolean,
	is_team_saved: boolean,
	is_team_saving: boolean,
	is_team_started: boolean,
	is_mobile: boolean,
	onSwitchPlayerPool?: Function,
	trades: Array<Object>,
	is_lockout: boolean,
	rollbackTeam: typeof actions.rollbackTeam,
	players_by_id: TPlayersArrayWithFixtureById,
	is_static: boolean,
	is_users_team: boolean,
	is_classic?: boolean
}

type State = {
	flip_cards: boolean,
	show_rollback_modal: boolean
}

export class TeamTopControlsComponent extends React.Component<Props, State> {
	static defaultProps = {
		onSwitchPlayerPool: () => {},
		is_static: false,
		is_users_team: true,
		is_classic: true
	};

	constructor(props: Props) {
		super(props);
		_.bindAll(this, [
			"handleSwitchChange",
			"clear",
			"saveTeam",
			"autoFill",
			"handleRollbackPopup",
			"rollbackTeam"
		]);
	}

	state = {
		flip_cards: false,
		show_rollback_modal: false,
	};

	clear() {
		const { clear, is_team_saving } = this.props;

		if (!is_team_saving) {
			clear();
		}
	}
	saveTeam() {
		const { saveTeam, team: { lineup: curent_lineup, formation } } = this.props;
		const { lineup } = convertLineupForBE(curent_lineup);
		saveTeam({ lineup, old_lineup: curent_lineup, formation });
	}
	autoFill() {
		const { autoFill, team: { lineup, formation } } = this.props;
		autoFill({ lineup, formation });
	}

	handleSwitchChange({ currentTarget }: Object) {
		const { name, checked } = currentTarget;
		const { handleFlip } = this.props;
		this.setState({ [name]: checked });
		handleFlip(checked);
	}

	get shouldNotShowFlipToggle() {
		const { is_type_field_list, is_mobile, is_static } = this.props;
		return is_mobile || !is_type_field_list || is_static;
	}

	get rollback_button() {
		const { trades, is_lockout } = this.props;
		const tradesLength = trades && trades.length ? trades.length: 0;

		if (is_lockout || !tradesLength) {
			return null;
		}

		return (
			<ButtonGroup>
				<ButtonAlert inverted onClick={this.handleRollbackPopup}>
					Rollback team
				</ButtonAlert>
			</ButtonGroup>

		);
	}
	get rollback_modal() {
		const { trades, players_by_id } = this.props;

		return (
			<ModalContainer onClick={this.handleRollbackPopup} container_background="#F8F8FA">
				<Caution size={3} color={colors.warning} />
				<ModalInnerTitle>
					Are you sure you want to rollback your team?
				</ModalInnerTitle>
				<ModalInnerSubTitle>
					Rolling back your team will reset it to the state it was after the last round's
					lockout lift. This includes all trades, substitutions, changes to Captain, Vice
					Captain and Emergencies.
				</ModalInnerSubTitle>
				<ConfirmModalContentStyled>
					<TradeColumn>
						{trades.map(({ new_player_id: id }) => (
							<TradeInCellShow
								key={id}
								player={players_by_id[id]}
								is_classic
							/>
						))}
					</TradeColumn>
					<Transactions color={colors.primary.primary} size={3.5} />
					<TradeColumn>
						{trades.map(({ old_player_id: id }) => (
							<TradeOutCellShow
								key={id}
								player={players_by_id[id]}
								is_classic
							/>
						))}
					</TradeColumn>
				</ConfirmModalContentStyled>
				<ConfirmButtonsWrapper>
					<ButtonSecondary inverted onClick={this.handleRollbackPopup}>
						No, I've changed my mind
					</ButtonSecondary>
					<ButtonAlert
						onClick={this.rollbackTeam}
					>
						Yes, I'd like to rollback my team
					</ButtonAlert>
				</ConfirmButtonsWrapper>

			</ModalContainer>
		);
	}

	handleRollbackPopup() {
		const { show_rollback_modal } = this.state;
		this.setState({
			show_rollback_modal: !show_rollback_modal
		});
	}

	rollbackTeam() {
		this.handleRollbackPopup();
		this.props.rollbackTeam();
	}

	get buttons() {
		const {
			is_team_started,
			is_team_saving,
			is_team_saved,
			is_static
		} = this.props;

		if (is_static) {
			return null;
		}

		return is_team_started ? this.rollback_button : (
			<ButtonGroup>
				<AutoFillButton onClick={this.autoFill} disabled={is_team_saving}>
					Auto Fill
				</AutoFillButton>
				<ClearAllButton onClick={this.clear} disabled={is_team_saving}>
					Clear All
				</ClearAllButton>
				<StyledSaveButton
					onClick={this.saveTeam}
					is_saved={is_team_saved}
					is_saving={is_team_saving}
				/>
			</ButtonGroup>
		);
	}

	renderMobile() {
		const {
			onSwitchPlayerPool,
			is_static,
			is_team_started,
			is_classic
		} = this.props;
		const { show_rollback_modal } = this.state;

		return (
			<TopControlsWrapper is_classic={is_classic}>
				<MobileTeamName>
					{this.getTeamName()}
					{is_team_started ? this.rollback_button : null}
				</MobileTeamName>
				{!is_static && this.buttons}
				{!is_static && <OpenPlayerPoolButton onClick={onSwitchPlayerPool}>
					<span>Player pool</span>
					<Arrow direction="right"/>
				</OpenPlayerPoolButton>}
				{!is_static && show_rollback_modal && this.rollback_modal}
			</TopControlsWrapper>
		);
	}

	getTeamName() {
		const { team, is_users_team } = this.props;
		if (is_users_team) {
			return "Your Team";
		}
		else {
			const fname = _.get(team, "firstname", "");
			const lname = _.get(team, "lastname", "");
			const name = _.isEmpty(lname) || lname === "."
				? fname
				: `${fname} ${lname.charAt(0)}`;

			return `${name}'s Team`;
		}
	}

	render() {
		const {
			is_mobile,
		} = this.props;
		const { show_rollback_modal } = this.state;

		if(is_mobile) {
			return this.renderMobile();
		}

		const {
			handleViewType,
			is_type_field_list,
			is_static,
			is_classic
		} = this.props;

		return (
			<TopControlsWrapper is_classic={is_classic}>
				<NameWrapper>
					<TeamName>{this.getTeamName()}</TeamName>
					{!this.shouldNotShowFlipToggle && (
						<FlipToggle
							checked={this.state.flip_cards}
							onChange={this.handleSwitchChange}
						/>
					)}
				</NameWrapper>

				{ <TeamViewControls>

					{!is_static && this.buttons}
					<FieldViewControlWrapper
						className={is_type_field_list ? "active" : ""}
						onClick={handleViewType}>
						<Oval size={2.5}/>
					</FieldViewControlWrapper>
					<FieldViewControlWrapper
						className={is_type_field_list ? "" : "active"}
						onClick={handleViewType}>
						<CellsCondensed size={2.5} color={"white"}/>
					</FieldViewControlWrapper>
				</TeamViewControls>}

				{show_rollback_modal && this.rollback_modal}
			</TopControlsWrapper>
		);
	}
}

const mapStateToProps = (state: TRootStore) => ({
	is_team_saving: selectors.isMyTeamSaving(state),
	is_team_saved: selectors.isMyTeamSaved(state),
	is_lockout: selectors.isLockout(state),
	players_by_id: selectors.players.getExtendedPlayersById(state),
});

const mapDispatchToProps = {
	clear: actions.clearMyClassicTeam,
	saveTeam: actions.saveMyClassicTeam,
	autoFill: actions.autoFillMyClassicTeam,
	rollbackTeam: actions.rollbackTeam
};

export const TeamTopControls = connect(
	mapStateToProps,
	mapDispatchToProps
)(TeamTopControlsComponent);

export default TeamTopControls;