// @flow
import React from "react";
import styled from "styled-components";
import * as _ from "lodash";

import type { 
	ById, 
	TCelebTeam, 
	TClassicTeam, 
	TPLeague 
} from "../../../../../modules/types";
import { PageSubTitle } from "../../../../../components";
import Celebrity from "./celebrity";

const CelebritiesWrapper = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin: 20px 0 30px;
	> div {
		margin: 0 10px;
		&:first-of-type {
			margin-left: 0;
		}
		&:last-of-type {
			margin-right: 0;
		}
	}
`;

const CelebrityInviteWrapper = styled.div`
	> p {
		font-family: SourceSansPro, sans-serif;
		font-weight: 200;
		font-size: 14px;
	}

	${PageSubTitle} {
		font-size: 18px;
    	font-weight: 400;
	}
`;

type Props = {
	league: TPLeague,
	celebrities: ById<TCelebTeam>,
	league_teams: ById<TClassicTeam>,
	addCelebrity: Function,
	removeFromLeague: Function,
}

export class CelebrityInvites extends React.PureComponent<Props> {
	constructor() {
		super();

		_.bindAll(this, [
			"renderCeleb"
		]);
	}
	get remaining_spots() {
		const { league, league_teams } = this.props;
		return Math.max(0, _.get(league, "size", 0) - _.size(league_teams));
	}

	get is_open_league() {
		const { league } = this.props;
		const type = _.get(league, "type", "");
		return type === "open";
	}

	get season_started() {
		const { league } = this.props;
		const league_status = _.get(league, "status", "");
		return league_status !== "scheduled";
	}

	renderCeleb(celebrity: TCelebTeam) {
		const { league_teams, addCelebrity, removeFromLeague } = this.props;
		const { season_started, remaining_spots, is_open_league } = this;
		const id = _.get(celebrity, "id");
		if (id) {
			return <Celebrity
				key={id}
				celebrity={celebrity}
				league_teams={league_teams}
				addCelebrity={addCelebrity}
				removeFromLeague={removeFromLeague}
				can_add={
					!season_started 
					&& (is_open_league || remaining_spots > 0)
				}
				can_remove={!season_started}
			/>;
		}
	}

	render() {
		
		return <CelebrityInviteWrapper>
			<PageSubTitle>Add a celebrity to your league:</PageSubTitle>
			{
				this.is_open_league 
					? <p>You may add as many celebrities to your Open league as you like.</p>
					: <p>Your league has {this.remaining_spots} places remaining</p>
			}
			
			<CelebritiesWrapper>
				{
					_(this.props.celebrities)
						.orderBy(celeb => celeb.id)
						.map(this.renderCeleb)
						.value()
				}
			</CelebritiesWrapper>
		</CelebrityInviteWrapper>;
	}
}

export default CelebrityInvites;