// @flow
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Exist} from "../../components";
import {setAflIdModalStatus} from "../../modules/actions";
import { isAlreadyLinkedError } from "../../modules/selectors";
import {getAflIdModalStatus} from "../../modules/selectors/aflIdModal";
import {AFLIdModalStatus} from "../../modules/types/store/aflIdModalReducer";

import { AFLiDModalContainer, ModalContent, PageTitleStyled } from "./afliDModalStyles";

type Props = {
	closeClick: Function,
	isLinked?: boolean,
}

const AFLiDInfoModal = ({
	closeClick,
}: Props) => {
	localStorage.setItem("aflid_info", "1");
	return (
		<AFLiDModalContainer onClick={closeClick} maxWidth={660}>
			<PageTitleStyled>
				Important Account Info - 
				<br />
				AFL iD
			</PageTitleStyled>
			<ModalContent>
				<p>
					AFL Fantasy requires an AFL iD account to play.
				</p>
				<p>
					If you played AFL Fantasy last season, simply login using the same AFL iD as last season.
				</p>
				<p>
					If you’re new to AFL Fantasy, login to your AFL iD (or create one if you haven’t already) and you’ll automatically be taken back to AFL Fantasy to complete your registration.
				</p>
				<p>
					If you’ve played AFL Fantasy before, but you skipped the 2023 season, you’ll have to register a new account for AFL Fantasy. Once you’ve logged in, you can go to “My Account” and enter your old account details in order to link your old Fantasy account to your AFL iD, and gain access to your account history.
				</p>
			</ModalContent>
		</AFLiDModalContainer>
	);
};

const IncorrectEmailDetailsModal = ({
	closeClick,
	isLinked
}: Props) => {
	if(isLinked){
		return <AFLiDModalContainer onClick={closeClick} maxWidth={660}>
			<PageTitleStyled>Account Is Linked</PageTitleStyled>
			<ModalContent>
				<p>
				The email address you entered has either already been 
				linked to a different AFL iD, or it has been used to 
				create an AFL iD account and that AFL iD account has already logged in to Fantasy.
				</p>
				<p>
				Please double check the email address you’ve entered 
				here, and make sure you haven’t already linked your 
				AFL Fantasy account to a different AFL iD.
				</p>
			</ModalContent>
		</AFLiDModalContainer>;
	}
	return(
		<AFLiDModalContainer onClick={closeClick} maxWidth={660}>
			<PageTitleStyled>Incorrect Details</PageTitleStyled>
			<ModalContent>
				<p>
				The email address and password you entered{" "}
					do not match an AFL Fantasy/Tipping account.
				</p>
				<p>
					Please try again, and reset your password if necessary.
					{" "}Remember, we're asking for your old AFL Fantasy login,
					{" "}and not your new AFL iD details.
				</p>
			</ModalContent>
		</AFLiDModalContainer>
	);
};

export const AFLiDModal = () => {
	const dispatch = useDispatch();
	const aflIdModal = useSelector(getAflIdModalStatus);
	const isAlreadyLinked = useSelector(isAlreadyLinkedError);
	if (aflIdModal === AFLIdModalStatus.Off) {
		return null;
	}
	const handleCloseModal = () => dispatch(setAflIdModalStatus(AFLIdModalStatus.Off));

	return (
		<React.Fragment>
			<Exist when={aflIdModal === AFLIdModalStatus.Info}>
				<AFLiDInfoModal closeClick={handleCloseModal}/>
			</Exist>
			<Exist when={aflIdModal === AFLIdModalStatus.IncorrectEmailDetails}>
				<IncorrectEmailDetailsModal 
					closeClick={handleCloseModal} isLinked={isAlreadyLinked} />
			</Exist>

			
		</React.Fragment>
	);
};
export default AFLiDModal;