// @flow
import styled from "styled-components";
import * as React from "react";
import colors from "./../../../../assets/css/colors";
import { below } from "./../../../../assets/css/media";

type Props = {
    title: string, 
    subTitle: string, 
    children: React.Node,
    handleProOpen: Function,
    showProTxt: boolean
};

const LeagueTitleDiv = styled.h3`
    font-family: TitilliumUpright;
    font-size: 24px;
    letter-spacing: 0;
    line-height: 24px;
    padding: 0 0 15px 0;
    color: ${colors.primary.primary};
    ${below.tablet`
        font-size: 16px;
    `};
`;

const LeagueSubTitle = styled.div `
    color: ${colors.primary.primary};
    font-family: "SourceSansPro";
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
    margin: 0 0 20px 0;
`;

const LeagueFormWrapDiv = styled.div`
    display: flex;
    justify-content: space-between;
    ${below.tablet`
        flex-direction: column;
    `};
`;

const LeagueFormColDiv = styled.div`
    width: 45%;
    ${below.tablet`
        margin: 0 1%;
        width: 98%;
    `};
`;

const SubscribeTitleWrap = styled.div`
    display: flex;
`;

const SubscribeTitle = styled(LeagueTitleDiv)`
    color: #000;
`;

const SubscribeSubTitle = styled(LeagueSubTitle)`
    color: #000;
`;

const SubscribeModalSpan = styled(SubscribeSubTitle)`
    cursor: pointer;
    text-decoration: underline;
`;

export class LeagueFormWrap extends React.PureComponent<Props> {
	render() {
		const { title, subTitle, children, showProTxt } = this.props;
		const subscribeTxt =  "The Pro subscription for AFL Fantasy"+
                            " Draft gives you access to loads of customisation"+
                            " features. With them, you can tailor your Draft "+
                            "league to exactly how you want it. With the ability"+
                            " to customise your point scoring, league settings, "+
                            "draft order and more, your Pro subscription will "+
                            "ensure that every component of your Fantasy Draft "+
                            "league is uniquely yours.";
		return (
			<LeagueFormWrapDiv>
				<LeagueFormColDiv>
					<LeagueTitleDiv>{title}</LeagueTitleDiv>
					<LeagueSubTitle>{subTitle}</LeagueSubTitle>
					<div>{children}</div>
				</LeagueFormColDiv>
				{showProTxt ?
					<LeagueFormColDiv>
						<SubscribeTitleWrap>
							<SubscribeTitle>Subscribe to Pro</SubscribeTitle>
						</SubscribeTitleWrap>
						<SubscribeSubTitle>{subscribeTxt}</SubscribeSubTitle>
						<SubscribeModalSpan onClick={() => this.props.handleProOpen()}>
						    Find out more
						</SubscribeModalSpan>
					</LeagueFormColDiv>:null}
			</LeagueFormWrapDiv>
		);
	}
}

export default LeagueFormWrap;