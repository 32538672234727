// @flow
import * as React from "react";
import styled from "styled-components";

import { below } from "../../../../assets/css/media";
import colors from "../../../../assets/css/colors";
import { ModalContainer, 
	ModalContent, 
	ModalBody, ModalOverlay } from "../../../../components/Modal";




const ModalTitle = styled.h2`
	color: ${colors.primary.primary};
	font-size: 32px;
	text-align: center;
  margin-bottom: 10px;
	${below.tablet`
		font-size: 24px;
	`}

	${below.phone`
		font-size: 16px;
	`}
`;

const ModalText = styled.p`
  color: ${colors.primary.primary};
  font-size: 20px;
  font-family: SourceSansPro;
`;


const ReplaceCoachModalWrapper = styled.div`
  background: grey;
	${ModalContent} {
		box-sizing: border-box;
    box-shadow: 1px 0px 12px 0 rgb(0,0,0,0.2);
		${below.tablet`
			max-width: unset;
		`}

		${below.phone`
			max-width: 300px;
			padding-top: 190px;
		`}

		@media screen and (max-width: 330px) {
			padding-top: 180px;
		}

		${ModalBody} {
			padding: 0 100px 50px;

			${below.phone`
				padding: 0 20px 30px;
			`}
		}
	}
  ${ModalOverlay}{
    background: rgba(0, 0, 0, 0.5);
    box-shadow: 1px 0px 12px 0 rgb(0,0,0,0.2)
  }
  form{
    div{
      margin-bottom: 10px;
    }
  }
  

	.close-button {
		z-index: 1;
		> svg {
			fill: ${colors.primary.primary};
		}
		:hover {
			> svg {
				fill: ${colors.primary.accent};
			}
		}
	}     
`;

type Props = {
	closeClick: Function,
	message: any,
};

class ReplaceCoachModalComponent extends React.Component<Props> {
	render() {
		const { message } = this.props;
		return <ReplaceCoachModalWrapper>
			<ModalContainer
				onClick={this.props.closeClick}
				container_background={"#fff"}
			>
				<ModalTitle>Invitation Error</ModalTitle>
				<ModalText>
					{message}
				</ModalText>
			</ModalContainer>
		</ReplaceCoachModalWrapper>;
	}
	
};
	



export default ReplaceCoachModalComponent;