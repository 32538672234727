// @flow
import * as React from "react";
import { formValueSelector, reduxForm } from "redux-form";
import { connect } from "react-redux";
import moment from "moment";
import bindAll from "lodash/bindAll";
import isEmpty from "lodash/isEmpty";
import { leagueValidate } from "../../../../utils/FormValidations/index";
import { Preloader, TabWrap } from "../../../../components";
import * as selectors from "../../../../modules/selectors";
import ProSubscriptionModal  from "../../../proSubscription/ProSubscriptionModal";
import * as actions from "../../../../modules/actions";
import { score_settings, FINALS_LENGTHS } from "./../../../../helpers";
import type { LeagueFormProps } from "./../../leagues/leaguesCreate/LeaguesCreateForm";
import {
	CreateForm,
	LeagueSubmitButton,
} from "./../../leagues/leaguesCreate/LeaguesCreateFormStyles";

import { LeaguesTabArr } from "./../../leagues/LeaguesTabArr";

const getFinalStartRound = (league, lastFixtureCheck) => {
	return league.finals === 0 ? 0 : league.finals_start_round === 0 ? 
		lastFixtureCheck : league.finals_start_round;
};

type State = {
	open_pro_modal: boolean,
};

class LeagueSettingsForm extends React.Component<LeagueFormProps, State> {
	constructor(props) {
		super(props);
		this.state = {
			open_pro_modal: false,
		};
		bindAll(this, ["setActiveTab", "openProModal", "closeProModal"]);
	}
	setActiveTab(tabIndex) {
		this.props.setActiveDraftTab(tabIndex);
	}
	openProModal() {
		this.setState({
			open_pro_modal: true
		});
	}
	closeProModal(){
		this.setState({
			open_pro_modal: false
		});
	}
	render() {
		const { props } = this;

		const activeTab = props.currentDraftTab;
		const leagueTabs = LeaguesTabArr(props, this.openProModal);
		const disableSubmitButton = props.league_create.is_pending || !props.league_name;
		const getProSub = () => {
			if(props.is_pro_sub === undefined){
				return false;
			}
			return props.is_pro_sub;
		};
		return (
			<div>
				{props.league_create.is_pending ? <Preloader />:null}
				{this.state.open_pro_modal && 
					<ProSubscriptionModal 
						closeClick={this.closeProModal} 
						league={this.props.league}
						is_pro_sub={getProSub()}
						onSubmit={props.handleSubmit}
					/>
				}
				<CreateForm onSubmit={props.handleSubmit}>
					<TabWrap
						activeTab={activeTab}
						setActiveTab={this.setActiveTab}
						tabData={leagueTabs.map(tab => ({
							alertText: tab.alertText,
							key: tab.key,
							title: tab.title,
							content: <div>{tab.content}</div>,
						}))}
					/>
					<LeagueSubmitButton disabled={disableSubmitButton}>
						Update your league
					</LeagueSubmitButton>
				</CreateForm>
			</div>
		);
		/*
				<UploadAvatarWrapper>
					<FormUploadImage
						name='league_avatar'
						size="75px"
						onChange={props.onChangeFile}
						preview_img={
							props.previewImg ?
								props.previewImg :
								props.league.avatar_version ?
									get_league_avatar({
										league_id: props.league.id,
										avatar_version: props.league.avatar_version,
									}) : ''
						}
						add_text=''
					/>
					<div>
						{!props.league_name ? (
							<LeagueName>props.league.name</LeagueName>
						) : (
							<LeagueName>{props.league_name}</LeagueName>
						)}
						<UserName>{getShortName(props.league)}</UserName>
					</div>
				</UploadAvatarWrapper>
		*/
	}
}

LeagueSettingsForm = reduxForm({
	form: "league_manager",
	validate: leagueValidate,
})(LeagueSettingsForm);

const selector = formValueSelector("league_manager");

const mapStateToProps = (state, ownProps) => {
	const league = selectors.leagueDraft.getLeague(state, ownProps);
	let positionArr = [];
	let posInt = "";
	if(league.squad_size){
		positionArr = league.squad_size.split("-").map(item => item.split("/")[0]);
		posInt = league.squad_size.split("/")[1];
	}
	const getCustomScoring = stat => {
		return (league.custom_scoring 
			&& Number.isInteger(league.custom_scoring[stat])) 
			? league.custom_scoring[stat] : score_settings[stat].defaultValue;
			
	};
	const showScoringField = stat => Boolean(getCustomScoring(stat));

	const last_fixture_round = !isEmpty(league.fixture) ? 
		Object.keys(league.fixture)[Object.keys(league.fixture).length - 1]:0;

	const lastFixtureCheck = !isEmpty(league.fixture) ? Number(last_fixture_round) + 1 : 20;

	const finals_start_value = getFinalStartRound(league, lastFixtureCheck);

	const teamNumTotal = [
		selector(state, "DEF_value"),
		selector(state, "MID_value"),
		selector(state, "FWD_value"),
		selector(state, "RUC_value"),
		selector(state, "INT_value"),
	].reduce((total, currVal) => {
		return total += Number(currVal);
	}, 0);

	const finalsLength = 
		league.finals_length === 0 
			? FINALS_LENGTHS[Number(league.finals_format)] : league.finals_length;
	return {
		actual_round:  selectors.rounds.getActualRound(state),
		is_finals: selector(state, "finals") === 1,
		is_traders: selector(state, "trade_waivers") === 1,
		is_pro_sub: ["full", "lite"].includes(league.subscription) ,
		is_draft_auto: selector(state, "draft_type") === "auto",
		league_size: selector(state, "size"),
		is_draft_commissioner: selector(state, "draft_type") === "commissioner",
		league_name: selector(state, "name"),
		maxDate: ownProps.selected_round.start,
		emergency: selector(state, "emergency"),
		emergency_limit: selector(state, "emergency_limit"),
		finals_length: selector(state, "finals_length"),
		finals_start_round: selector(state, "finals_start_round"),
		currentDraftTab:state.user.currentDraftTab,
		keeper: selector(state, "keeper"),
		keeper_deadline: selector(state, "keeper_deadline"),
		draft_status: selector(state, "draft_status"),
		league_draft_start: selector(state, "draft_start"),
		squadSize: teamNumTotal,
		finalsFormat: selector(state, "finals_format"),
		leagueSizeLive: selector(state, "size"),
		leaguePlayByes: selector(state, "bye_rounds"),
		currentPlayTimes: selector(state, "play_times"),
		currentStartRound: selector(state, "start_round"),
		currentPlayFinals: selector(state, "finals"),
		initialValues: {
			id: league.id,
			name: league.name || "",
			start_round: league.start_round,
			privacy: league.privacy,
			play_times: league.play_times,
			finals: league.finals,
			captains: league.captains,
			emergency: league.emergency,
			lockout: league.lockout,
			trade_waivers: league.trade_waivers,
			waiver_period: league.waiver_period,
			waiver_order: league.waiver_order,
			coaches_box: league.coaches_box,
			bye_rounds: league.bye_rounds,
			rfa_privacy: league.rfa_privacy,
			approve_trade: league.approve_trade,
			size: league.size,
			draft_type: league.draft_type,
			finals_format: league.finals_format,
			draft_start: moment(league.draft_start),
			squad_size: league.squad_size,
			draft_order: league.draft_order,
			draft_turn: league.draft_turn,
			keeper_deadline: moment(league.keeper_deadline),
			rank_by: league.rank_by,
			emergency_limit: league.emergency_limit,
			original_positions: league.original_positions,
			finals_length: finalsLength,
			finals_start_round: finals_start_value,
			subscription: league.subscription,
			keeper: league.keeper,
			keeper_num: league.keeper_num,
			tog_threshold: league.tog_threshold,
			B_value: getCustomScoring("B"),
			B_show: showScoringField("B"),
			CL_value: getCustomScoring("CL"),
			CL_show: showScoringField("CL"),
			CM_value: getCustomScoring("CM"),
			CM_show: showScoringField("CM"),
			CP_value: getCustomScoring("CP"),
			CP_show: showScoringField("CP"),
			CS_value: getCustomScoring("CS"),
			CS_show: showScoringField("CS"),
			D_value: getCustomScoring("D"),
			D_show: showScoringField("D"),
			ED_value: getCustomScoring("ED"),
			ED_show: showScoringField("ED"),
			FA_value: getCustomScoring("FA"),
			FA_show: showScoringField("FA"),
			FD_value: getCustomScoring("FD"),
			FD_show: showScoringField("FD"),
			FF_value: getCustomScoring("FF"),
			FF_show: showScoringField("FF"),
			G_value: getCustomScoring("G"),
			G_show: showScoringField("G"),
			GA_value: getCustomScoring("GA"),
			GA_show: showScoringField("GA"),
			H_value: getCustomScoring("H"),
			H_show: showScoringField("H"),
			HO_value: getCustomScoring("HO"),
			HO_show: showScoringField("HO"),
			I50_value: getCustomScoring("I50"),
			I50_show: showScoringField("I50"),
			IED_value: getCustomScoring("IED"),
			IED_show: showScoringField("IED"),
			K_value: getCustomScoring("K"),
			K_show: showScoringField("K"),
			M_value: getCustomScoring("M"),
			M_show: showScoringField("M"),
			R50_value: getCustomScoring("R50"),
			R50_show: showScoringField("R50"),
			SP_value: getCustomScoring("SP"),
			SP_show: showScoringField("SP"),
			T_value: getCustomScoring("T"),
			T_show: showScoringField("T"),
			UCP_value: getCustomScoring("UCP"),
			UCP_show: showScoringField("UCP"),

			DEF_show: true,
			DEF_value: positionArr[0],
			MID_show: true,
			MID_value: positionArr[1],
			RUC_show: true,
			RUC_value: positionArr[2],
			FWD_show: true,
			FWD_value: positionArr[3],
			INT_show: true,
			INT_value: posInt ? posInt:4,
		},
		enableReinitialize: true,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		setActiveDraftTab: (tabIndex:number) => dispatch(actions.setDraftTab(tabIndex))
	};
};

LeagueSettingsForm = connect(mapStateToProps,mapDispatchToProps)(LeagueSettingsForm);

export default LeagueSettingsForm;