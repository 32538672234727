// @flow
import React from "react";
import ordinal from "ordinal";
import * as _ from "lodash";
import { FixtureTeam, ButtonFixtureMatchUp } from "../";
import type { TRound } from "../../modules/types";
import { isMobile } from "../../utils";
import type { TFixture, TLeague, TTeam } from "./types";
import { Score, ActualPoints, Rank, Wrapper } from "./FixtureWrapper";
import renderCup from "./cup";

type TGameType = 'draft' | 'classic';

type Props = {
	league: TLeague,
	fixture: TFixture,
	round: TRound,
	shadow?: boolean,
	compact?: boolean,
	className?: string,
	has_assistant_coach: boolean,
	is_game_bar?: boolean,
	game_type: TGameType,
	show_rank: boolean,
	season_finished: boolean,
	league_fixture?: boolean,
	is_overview?: boolean,
}

const getMatchupUrl = (league: TLeague, round: TRound, game_type: TGameType, home, away) => {
	if (!home || !away) {
		return "#";
	}
	const classic_url =
			`/classic/league/${league.id}/matchup/${round.id}/${home.id}/${away.id}`;
	const draft_url =
		`/draft/league/${league.id}/fantasy-matchups/${round.id}/${home.id}/${away.id}`;
	return game_type === "classic" ? classic_url : draft_url;
};


class Fixture extends React.Component<Props> {
	static defaultProps = {
		show_rank: false,
		season_finished: false,
	};
	get is_scheduled() {
		const { round } = this.props;
		return round.status === "scheduled";
	}

	get is_live() {
		const { round } = this.props;
		return round.status === "active";
	}

	get is_full_time() {
		const { round } = this.props;
		return round.status !== "active" && round.status !== "scheduled";
	}

	get show_projected_score() {
		const { has_assistant_coach } = this.props;
		return (this.is_scheduled || this.is_live) && has_assistant_coach;
	}

	get loser() {
		const { fixture } = this.props;
		if (this.is_full_time && fixture.home && fixture.away) {
			const { home, away } = fixture;
			if (home.points === away.points) {
				return 0;
			}
			return home.points > away.points ? away.id : home.id;
		}
		return 0;
	}

	get button_text() {
		const {
			is_game_bar,
		} = this.props;
		const is_short_button = is_game_bar;

		const matchup_button = {
			type: "",
			text: isMobile() ? "VS" : "Match up"
		};
		if(is_short_button){
			matchup_button.text = "Match up";
		}

		if(this.is_full_time) {
			matchup_button.type = "full_time";
			matchup_button.text = isMobile() ? " FT" : "Full time";
		}
		else if(this.is_live) {
			matchup_button.type = "live";
			matchup_button.text = isMobile() ? "VS" : "Live";
		}

		return matchup_button;
	}

	renderScore(team?: TTeam, loser: boolean = false) {
		const { is_game_bar } = this.props;
		if (!team) {
			return <Score />;
		}

		return <Score is_game_bar={is_game_bar}>
			{team.points !== undefined &&
				<ActualPoints loser={loser} is_game_bar={is_game_bar}>
					{team.points}
				</ActualPoints>
			}
			{this.show_projected_score && (
				<span>{isMobile() ? null : "Projected: "} {team.projected_points}</span>
			)}
		</Score>;
	}

	renderRank(team?: TTeam, reverse: boolean = false) {
		const { show_rank, compact, league } = this.props;
		const show_rank_element = show_rank && compact;
		const rank = _.get(team, "rank", 0);
		const selected_rank = _.get(team, "selected_round_rank", 0);
		if(!league.finals){
			return(
				<Rank show={show_rank_element} reverse={reverse} compact={compact}>
					{ selected_rank === 0 ? "TBA" : ordinal(selected_rank)}
				</Rank>
			);
			
		}
		return <Rank show={show_rank_element} reverse={reverse} compact={compact}>
			{ rank === 0 ? "TBA" : ordinal(rank) }
		</Rank>;
	}

	render () {
		const {
			fixture: { home, away },
			league,
			round,
			compact,
			is_game_bar,
			game_type,
			className,
			season_finished,
			is_overview
		} = this.props;
		const url = getMatchupUrl(league, round, game_type, home, away);
		const matchup_button = this.button_text;

		const league_id = _.get(league, "id");

		const loser = this.loser;
		return ( (home || away) ? 
			<Wrapper className={className} compact={compact} 
				is_game_bar={is_game_bar} league_fixture={this.props.league_fixture}>
				{ this.renderRank(home) }
				<FixtureTeam
					compact={compact}
					is_game_bar={is_game_bar}
					team={home}
					game_type={game_type}
					league_id={league_id}
					firstname={_.get(home, "firstname", "")}
					lastname={_.get(home,"lastname", "")}
					name={_.get(home,"name", "Opponent yet to be determined")}
					rank={_.get(home, "rank", 0)}
					record_last_five={_.get(home, "record_last_five", [])}
					league_fixture={this.props.league_fixture}
					is_overview={is_overview}
				/>
				{ this.renderScore(home, _.get(home, "id") === loser) }
				{ (!is_game_bar && season_finished === true) && renderCup(home) }
				<ButtonFixtureMatchUp is_fixture={true}
					to={url}
					type={matchup_button.type}
					is_game_bar={is_game_bar}
				>
					{matchup_button.text}
				</ButtonFixtureMatchUp>
				{ (!is_game_bar && season_finished === true) && renderCup(away, true) }
				{ this.renderScore(away, _.get(away, "id") === loser) }
				<FixtureTeam
					compact={compact}
					is_game_bar={is_game_bar}
					right_side
					team={away}
					game_type={game_type}
					league_id={league_id}
					firstname={_.get(away, "firstname", "Opponent yet to be determined")}
					lastname={_.get(away,"lastname", "")}
					name={_.get(away,"name", "")}
					rank={_.get(away, "rank", 0)}
					record_last_five={_.get(away, "record_last_five", [])}
				/>
				{ this.renderRank(away, true) }
			</Wrapper> : <React.Fragment/>
		);
	}
}

export default Fixture;