// @flow
import * as React from "react";
import styled from "styled-components";

import CautionIcon from "../../Icons/Caution";

import colors from "../../../assets/css/colors";
import { below } from "../../../assets/css/media";
import Label from "./Label";
import IconWrapper from "./IconWrapper";

const LeftSide = styled.div`
	display: flex;
	align-items: center;
`;
const RightSide = styled.div`
	display: flex;
	align-items: center;
`;

export const Container = styled.div`
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: ${colors.secondary.lightGrey};
	height: 60px;
	width: 100%;
	border-radius: 0px;
	padding: 15px 20px;
	border: 1px ${({ dashed_border }) =>
		dashed_border ? "dashed" : "solid"} ${colors.secondary.paleGrey};

	${below.tablet`
		${({ display_mobile }) => display_mobile ? "display: flex;" : "display: none"}; 
	`}
`;

type Props = {
	show_icon?: boolean,
	text?: ?string,
	renderRightSide?: Function,
	small_label?: boolean,
	dashed?: boolean,
	display_mobile?: boolean,
}

export const MessageContainer = ({ 
	show_icon, 
	text, 
	renderRightSide, 
	small_label = false,
	dashed = false,
	display_mobile
}: Props) => (
	<Container dashed_border={dashed} display_mobile={display_mobile}>
		<LeftSide>
			{show_icon ? <IconWrapper><CautionIcon /></IconWrapper> : null}
			<Label small_label={small_label}>{text ? text : null}</Label>
		</LeftSide>
		<RightSide>
			{renderRightSide ? renderRightSide() : null}
		</RightSide>
	</Container>
);

export default MessageContainer;