// @flow
import styled from "styled-components";
import { below } from "../../../../assets/css/media";
import { OneColumnLayout } from "../../../../components";

export const JoinLeagueLayout = styled(OneColumnLayout)`
	margin: 30px auto;

	.search-form-wrapper {
		margin: 1em 0;

		form {
			width: 100%;
		}
		
		${below.desktop`
			margin: 1em 10px;
			
			a {
				width: 45%;
				font-size: 10px;
			}
		`}
	}
`;

export default JoinLeagueLayout;