// @flow
import styled from "styled-components";

import colors from "../../assets/css/colors";
import { below } from "../../assets/css/media";

export const AdsContainer = styled.div`
	box-sizing: border-box;
	background: ${colors.form.advanced_color};
	padding: 20px;
	width: 100%;
	min-height: 130px;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	${below.tablet`
		min-height: 50px;
	`};
`;

export default AdsContainer;