// @flow
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import * as _ from "lodash";
import ReactMarkdown from "react-markdown/";
import colors from "../../assets/css/colors";
import { below } from "../../assets/css/media";
import "whatwg-fetch";

import type { TSection } from "./sections";

const RouterLink = props => {
	return (
		props.href.match(/^(https?:)?\/\//)
			? (<a href={props.href}>{props.children}</a>)
			: (<Link to={props.href}>{props.children}</Link>)
	);
};

const mdUrl = sec => `/help/${sec.source ? sec.source : sec.id}.md`;
const fetchSource = (sec, receive, fail?) => window.fetch(mdUrl(sec)).then(receive).catch(fail);

const MarkdownContainer = styled.div`
	position: relative;
	font-family: SourceSansPro, sans-serif;
	line-height: 1.4;
	color: ${colors.primary.darkGrey};
	padding-bottom: 50px;
  .scrollable-table{
    ${below.phone`
      overflow: auto;
    `}
    
  }
  .prize-image{
	  ${below.phone`
	 	min-width:  140px;
	  `}
  }

	h1, h2, h3, h4, h5 {
		padding-bottom: 8px;
		padding-top: 8px;
		font-family: TitilliumUpright, Arial, sans-serif;
		color: ${colors.primary.primary};
	}
	h1 {
		padding: 0;
		font-size: 30px;
    ${below.phone`
      font-size: 18px;
    `}
	}
	h2 {
		padding-top: 16px;
		font-size: 24px;
    ${below.phone`
      font-size: 16px;
    `}
	}
	h3, h4 {
		font-size: 20px;
    ${below.phone`
      font-size: 14px;
    `}
	}
	p {
		font-family: SourceSansPro, sans-serif;
		padding-bottom: 8px;
		color: ${colors.primary.primary}
    ${below.phone`
      font-size: 12px;
    `}

	}
	ul, ol {
		font-family: SourceSansPro, sans-serif;
		margin-left: 32px;
		padding-bottom: 16px;
		list-style-position: outside;
		color: ${colors.primary.primary};
		li{
			a{
				color: ${colors.primary.primary}
			}
		}
	}
	ul {
		list-style-type: disc;
		ul {
			list-style-type: square;
			${/* eslint-disable-next-line max-len */""}
			list-style-image: url('data:image/gif;base64,R0lGODlhBQAKAIABAAAAAP///yH5BAEAAAEALAAAAAAFAAoAAAIIjI+ZwKwPUQEAOw==');
		}
	}
	ol {
		list-style-type: decimal;
	}
	li {
		line-height: 1.4;
	}
	.doHeadingCounter {
		h2:before {
			counter-increment: h2;
    		content: counter(h2) ".";
		}
	}
	em {
		font-style: italic;
	}
	strong {
		font-weight: bold;
		color: ${colors.primary.primary};
	}
	pre {
		padding-top: 16px;
		padding-bottom: 16px;
		font-family: SourceSansPro, sans-serif;
	}

	table, tr, td, th {
		border: 1px solid ${colors.secondary.paleGrey} ;
	}

	table {
		font-family: SourceSansPro, sans-serif;
		width: 100%;
		margin: 8px 0 8px 0;
    /* ${below.phone`
      display: none;
    `} */
	}

	td, th {
		padding: 4px 8px;
		text-align: left;
	}

	th {
		font-weight: bold;

		:empty {
			display: none;
		}
	}

	td {
		padding: 4px 8px;
		text-align: left;
		line-height: 1.4;
		vertical-align: middle;
		color: ${colors.primary.primary};
	}
	p{
		color: ${colors.primary.primary}
	}
`;

type Props = {
	section: TSection
}

type State = {
	source?: string
}

class HelpContent extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {};

		_.bindAll(this, [
			"acceptSource"
		]);
	}

	componentDidMount() {
		fetchSource(this.props.section, this.acceptSource);
	}

	componentDidUpdate(oldProps: Props) {
		if (!oldProps || mdUrl(oldProps.section) !== mdUrl(this.props.section))  {
			fetchSource(this.props.section, this.acceptSource);
		}
	}

	acceptSource(resp: Object) {
		resp.text().then(text => this.setState({ source: text }));
	}

	render() {
		const { source } = this.state;

		if (!source) {
			return <p>Loading...</p>;
		}

		return (
			<MarkdownContainer>
				{source
					? <ReactMarkdown
						escapeHtml={false}
						source={source}
						renderers={{ Link: RouterLink }}
					/>
					: <p>This page has not been added yet!</p>
				}
			</MarkdownContainer>
		);
	}
};

export default HelpContent;