/* eslint max-len: 0 */
// @flow
import React from "react";
import SvgIconContainer from "../SvgIconContainer";

export default (
	{
		unit = "em",
		...rest
	}: Object = {}) => (
	<SvgIconContainer viewBox="0 0 24 24" {...rest} unit={unit}>
		<title>Locked</title>
		<path d="m15.7825009 10.3307616-.3591498.0001456-.0024749-1.79225498c-.001019-1.95224913-1.5900421-3.5396708-3.5422912-3.53865173-1.95224918.00101907-3.53967085 1.58989655-3.53865178 3.54229127l.00247489 1.79225494h-.12709273c-.67156788.0002912-1.21575186.5450575-1.21531512 1.2166254l.00101907 5.3358564c.00029117.6715679.54505748 1.2157519 1.21662536 1.2153152l7.56703991-.0037852c.6715679-.0002911 1.2157519-.5450574 1.2153151-1.2166253l-.001019-5.3358565c-.0002912-.6715679-.5449119-1.2156063-1.2164798-1.2153151zm-3.449847 4.0956469.0007279 1.5112825-.5508807.0002912-.0007279-1.5112825c-.3028097-.1122434-.5198719-.4010773-.5200174-.7429029-.0002912-.4390741.3556558-.7953122.7947299-.7954578.439074-.0002912.7953122.3556558.7954578.7947299.0001456.34168-.2166254.630805-.5192896.7433396zm1.7858494-4.0947734-4.47124739.0023293-.00247489-1.79225497c-.00058232-1.23263933 1.00189248-2.23613323 2.23453188-2.23671556 1.2326393-.00058233 2.2361332 1.00189251 2.2367155 2.23453184z" />
	</SvgIconContainer>
);