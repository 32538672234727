/* eslint max-len: 0 */
// @flow
import React from "react";
import SvgIconContainer from "../SvgIconContainer";

export default (
	{
		unit = "em",
		...rest
	}: Object = {}) => (
	<SvgIconContainer viewBox="0 0 24 24" {...rest} unit={unit}>
		<title>Tick</title>
		<polygon points="9.50069894 17.93584 4.50209682 12.9372379
        6.37657262 11.0627621 9.50069894 14.1868884 17.6234274 6.06415998 19.4979032
        7.93863578"></polygon>
	</SvgIconContainer>
);