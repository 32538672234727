// @flow
import * as React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import * as _ from "lodash";

import ButtonAlert, { ButtonAlertLink } from "../../ButtonAlert";
import LeagueLateLabel from "../../../utils/leagueLate";
import * as actions from "../../../modules/actions";
import { IS_DISABLE_LIVE_DRAFT } from "../../../modules/constants";
import Container from "./Container";


type Props = {
	is_draft: boolean,
	is_commissioner: boolean,
	league_id: any,
	dashed?: boolean,
	showConfirmModal: Function,
	hideConfirmModal: Function,
	leaveClassicLeague: Function,
	leaveDraftLeague: Function,
	leave: {
		is_pending: boolean,
		success: boolean,
		error: any,
	},
}

type State = {
	leave_success: boolean
}

class LeagueLateStartComponent extends React.Component<Props,State> {
	constructor(props: Props) {
		super(props);

		_.bindAll(this, [
			"showLeaveLeagueModal",
			"confirmLeave",
			"renderLeaveButton",
			"renderCommissionerButton"
		]);
	}
	state = {
		leave_success: false,
	};
	componentDidUpdate(prev_props: Props) {
		if (prev_props.leave.is_pending && !this.props.leave.is_pending) {
			this.updateLeaveSuccess();
		}
	}
	updateLeaveSuccess() {
		const { is_draft, leave } = this.props;
		const leave_success = is_draft ? leave.success : Boolean(!leave.error);
		this.setState({
			leave_success
		});
	}
	confirmLeave() {
		const {
			is_draft,
			league_id,
			hideConfirmModal,
			leaveClassicLeague,
			leaveDraftLeague,
		} = this.props;

		if (is_draft) {
			leaveDraftLeague({ league_id });
		}
		else {
			leaveClassicLeague({ league_id });
		}
		hideConfirmModal();
	}
	showLeaveLeagueModal() {
		const {
			is_draft,
			showConfirmModal,
		} = this.props;

		return showConfirmModal({
			modal_header: `Leave ${is_draft ? "Draft" : "Classic"} League?`,
			modal_body: "Are you sure you wish to leave this league?",
			onConfirm: this.confirmLeave,
			warning: true,
		});
	}
	renderCommissionerButton() {
		const {
			is_draft,
			league_id,
		} = this.props;
		return <ButtonAlertLink 
			to={`/${
				is_draft ? "draft" : "classic"
			}/league/${
				league_id
			}/settings`}
		>
			{IS_DISABLE_LIVE_DRAFT && is_draft ? "League Settings" : "Reschedule league"}
		</ButtonAlertLink>;
	}

	renderLeaveButton() {
		return <ButtonAlert
			onClick={this.showLeaveLeagueModal}
		>
			Leave league
		</ButtonAlert>;
	}
	render() {
		const {
			is_commissioner,
			is_draft,
			dashed = false,
		} = this.props;
		const { leave_success } = this.state;

		if (leave_success) {
			return <Redirect to={`/${is_draft ? "draft" : "classic"}/leagues/join`} />;
		}

		return <Container 
			show_icon 
			small_label
			text={is_draft ? LeagueLateLabel.DRAFT : LeagueLateLabel.CLASSIC}
			renderRightSide={is_commissioner 
				? this.renderCommissionerButton 
				: this.renderLeaveButton}
			dashed={dashed}
		/>;
	}
};

const mapStateToProps = (state, props: Props) => {
	return {
		leave: props.is_draft
			? state.leagues.leave
			: state.leaguesClassic.leave,
	};
};

const mapDispatchToProps = {
	leaveClassicLeague: actions.leaveClassicLeague,
	leaveDraftLeague: actions.leagueDraft.leaveDraftLeague,
};

export const LeagueLateStart = connect(
	mapStateToProps, mapDispatchToProps
)(LeagueLateStartComponent);

export default LeagueLateStart;