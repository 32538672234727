/* eslint max-len: 0 */
// @flow
import React from "react";
import SvgIconContainer from "../SvgIconContainer";

export default (
	{
		unit = "em",
		...rest
	}: Object = {}) => (
	<SvgIconContainer viewBox="0 0 24 24" {...rest} unit={unit}>
		<title>Captain</title>
		<path d="M7,12.0890208 C7,11.1157221 7.15430113,10.2522293 7.46290801,9.49851632
			C7.7715149,8.74480335 8.18694101,8.10979487 8.70919881,7.59347181 C9.23145662,7.07714875
			9.83382448,6.68249395 10.5163205,6.40949555 C11.1988165,6.13649715 11.9139428,6 12.6617211,6
			C13.3976298,6 14.0652789,6.1513338 14.6646884,6.45400593 C15.264098,6.75667807
			15.7715113,7.11572493 16.1869436,7.53115727 L14.5133531,9.41839763 C14.2284852,9.16913822
			13.9495563,8.97626181 13.6765579,8.83976261 C13.4035595,8.70326341 13.0771532,8.63501484
			12.6973294,8.63501484 C12.353114,8.63501484 12.0237404,8.7121654 11.7091988,8.86646884
			C11.3946572,9.02077228 11.1216629,9.24035466 10.8902077,9.52522255 C10.6587526,9.81009045
			10.4747781,10.1602353 10.3382789,10.5756677 C10.2017797,10.9911 10.1335312,11.459938
			10.1335312,11.9821958 C10.1335312,13.0741894 10.3620155,13.9109763 10.8189911,14.4925816
			C11.2759667,15.0741869 11.8783345,15.3649852 12.6261128,15.3649852 C13.0771536,15.3649852
			13.4658737,15.2789326 13.7922849,15.1068249 C14.118696,14.9347172 14.4065269,14.7121675
			14.6557864,14.4391691 L16.3293769,16.2908012 C15.830858,16.860537 15.2611308,17.2878324
			14.620178,17.5727003 C13.9792253,17.8575682 13.3026742,18 12.5905045,18 C11.8308567,18
			11.112763,17.8783395 10.4362018,17.6350148 C9.75964053,17.3916902 9.16617466,17.0237413
			8.65578635,16.5311573 C8.14539804,16.0385732 7.74184125,15.4213687 7.44510386,14.6795252
			C7.14836647,13.9376818 7,13.0741889 7,12.0890208 Z" />

	</SvgIconContainer >
);