// @flow
import React from "react";

type Props = {
    when: boolean,
	children: any,
}

export const Exist = ({children, when}: Props) => {
	if(when){
		return <React.Fragment>
			{children}
		</React.Fragment>;
	}
	return null;
};