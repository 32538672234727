// @flow
/* eslint max-len: 0 */
import React from "react";
import SvgIconContainer from "../SvgIconContainer";

export default (
	{
		unit = "em",
		color = "#B89344",
		...rest
	}: Object = {}) => (
	<SvgIconContainer viewBox="0 0 24 24" {...rest} unit={unit}>
		<g stroke="none" strokeWidth="1" fillRule="evenodd">
        	<path d="M12,0 C18.627417,0 24,5.372583 24,12 C24,18.627417 18.627417,24 12,24 C5.372583,24 0,18.627417 0,12 C0,5.372583 5.372583,0 12,0 Z M12.2697559,4.5 L11.8211521,4.5 C9.61009682,4.5 7.83611061,6.28181818 7.83611061,8.44545455 L7.83611061,8.44545455 L7.83611061,10.6090909 L7.37028164,10.6090909 C6.88963836,10.6090909 6.5,10.9948586 6.5,11.4707273 L6.5,11.4707273 L6.5,17.6383636 C6.5,18.1142323 6.88963836,18.5 7.37028164,18.5 L7.37028164,18.5 L16.6297184,18.5 C17.1103616,18.5 17.5,18.1142323 17.5,17.6383636 L17.5,17.6383636 L17.5,11.4707273 C17.5,10.9948586 17.1103616,10.6090909 16.6297184,10.6090909 L16.6297184,10.6090909 L16.2547973,10.6090909 L16.2547973,8.44545455 L16.2511361,8.27545991 C16.161127,6.18927222 14.4226255,4.5 12.2697559,4.5 L12.2697559,4.5 Z M11.8211521,6.28181818 L12.4030288,6.28576122 C13.5491519,6.35378227 14.4551012,7.29340067 14.4551012,8.44545455 L14.4551012,8.44545455 L14.4551012,10.6090909 L9.63580676,10.6090909 L9.63580676,8.44545455 C9.63580676,7.24909091 10.6127847,6.28181818 11.8211521,6.28181818 L11.8211521,6.28181818 Z" fill={color}></path>
		</g>
	</SvgIconContainer>
);