// @flow
import React from "react";
import styled from "styled-components";
import * as _ from "lodash";
import colors from "../../assets/css/colors";
import ArrowDown from "../../components/Icons/ArrowDown";

const CategoryWrapper = styled.div`
	margin-bottom: 2px;
`;
const CategoryHeader = styled.div`
	cursor: pointer;
	background-color: transparent;
	margin-bottom: 2px;
	font-size: 16px;
	color: ${props => props.is_fc ? colors.coach : colors.primary.primary};
	padding: 25px 10px;
	font-family: SourceSansPro, sans-serif;
	border-radius: 2px;
	${({ isOpen }) => !isOpen && `
		border-bottom: 1px solid #F0F3F7;
	`}

	display: flex;
	justify-content: space-between;
	div{
		font-family: SourceSansPro, sans-serif;
		font-weight: bold;
		font-size: 16px;
	}
	svg{
		transform: ${({ isOpen }) => isOpen ? "rotate(180deg)" : "rotate(0deg)"};
		transition: all 0.4s;
	}
`;
const CategoryQAWrapper = styled.div`
	display: ${ props => props.expanded ? "block" : "none" };
	font-family: SourceSansPro, sans-serif;
	padding: 0px 12px;
	margin-bottom: 10px;
`;
const QAWrapper = styled.div`
	margin-bottom: 10px;
	:last-of-type {
		border-bottom:  none;
		margin-bottom: 0;
	}
`;
const Question = styled.h3`
	font-family: SourceSansPro, sans-serif;
	font-size: 14px;
	color: ${colors.primary.primary};
	padding-bottom: 8px;
	font-weight: bold;
`;
const Answer = styled.div`
	font-family: SourceSansPro, sans-serif;
	font-size: 14px;
	padding-bottom: 20px;
	color: ${colors.primary.primary};
	p {
		font-family: SourceSansPro, sans-serif;
		color: ${colors.primary.primary};
		font-size: 14px;
		padding-bottom: 10px;
		:last-of-type {
			padding-bottom: 0!important;
			margin-bottom: 0!important;
		}
	}
`;


type Props = {
	items: Object[],
	category: Object,
}

type State = {
	expanded: boolean
}

class FaqCategory extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			expanded: false
		};

		_.bindAll(this, [
			"toggleExpanded"
		]);
	}

	toggleExpanded() {
		this.setState({
			expanded: !this.state.expanded
		});
	}

	render() {
		const { category, items } = this.props;
		const { expanded } = this.state;
		if (items.length === 0) {
			return null;
		}
		return (
			<CategoryWrapper>
				<CategoryHeader 
					onClick={this.toggleExpanded}
					is_fc={category.zendesk_section_id === 8416141715741}
					isOpen={expanded}
				> 
					<div dangerouslySetInnerHTML={{ __html: category.name }} ></div>
					<ArrowDown />

				</CategoryHeader>
				<CategoryQAWrapper expanded={expanded}>
					{_.map(items, item => {
						return (
							<QAWrapper key={item.id}>
								<Question dangerouslySetInnerHTML={{ __html: item.title }} />
								<Answer dangerouslySetInnerHTML={{ __html: item.body }} />
							</QAWrapper>
						);
					})}
				</CategoryQAWrapper>
			</CategoryWrapper>
		);
	}
};

export default FaqCategory;