// @flow
import React from "react";
import styled from "styled-components";

import colors from "../../assets/css/colors";

const WrapperWLD = styled.div`
	margin: 1px;
	width: 20px;
	height: 20px;
	border-radius: 3px;
	> svg {
		display: block;
		height: 100%;
		width: 100%;
	}
	fill: white;
	background: ${({ result }) => {
		if(result === "w") {
			return colors.primary.accentGreen;
		}
		else if(result === "l") {
			return "#D0021B";
		}
		else {
			return colors.secondary.accentGrey;
		}
	}};
`;

type Props = {
	result: 'w' | 'l' | 'd'
}
export const WLD = ({ result }: Props) => (
	<WrapperWLD result={result}>
		<svg width="20px" height="20px" viewBox="0 0 20 20">
			{result === "w"?
				(<path d="M5.051 5.873h1.547l.741 4.29.208 1.365.208
			1.352h.052c.096-.45.189-.903.28-1.359.09-.455.184-.907.28-1.358l1.04-4.29h1.312l1.04
			4.29c.096.442.191.89.286 1.345l.286
			1.372h.052c.07-.46.137-.914.202-1.365.065-.45.132-.901.201-1.352l.741-4.29h1.443l-1.625
			8.502h-1.872L10.42 9.903c-.069-.33-.136-.656-.201-.982a24.441 24.441 0 0
			1-.176-.981h-.052c-.06.33-.123.656-.188.981-.065.326-.132.653-.202.982l-1.027
			4.472H6.741l-1.69-8.502z" />) :
				result === "l" ?
					(<path d="m7.83333333
						5.86176953h1.508v7.22799997h3.53599997v1.274h-5.04399997z" />) :
					(<path d="M7.034 5.998h2.275c1.3 0 2.314.351 3.042 1.053.728.702 1.092 1.755
				1.092 3.159 0 .702-.093 1.322-.28 1.859a3.566 3.566 0 0 1-.806
				1.345c-.35.36-.775.631-1.274.813-.498.182-1.063.273-1.696.273H7.034V5.998zm2.17
				7.28c.868 0 1.533-.25 1.996-.748.464-.498.696-1.271.696-2.32
				0-1.04-.234-1.798-.702-2.275-.468-.477-1.131-.715-1.99-.715h-.662v6.058h.663z"
					/>)}
		</svg>
	</WrapperWLD>
);