// @flow
import * as React from "react";
import styled, { css } from "styled-components";
import { Redirect } from "react-router-dom";
import _ from "lodash";
import { connect } from "react-redux";
import { compose } from "redux";
import type { 
	TClassicTeam, 
	TRound, 
	TPosition, 
	TPlayersArrayWithFixtureById 
} from "../../modules/types";
import * as selectors from "../../modules/selectors";
import * as actions from "../../modules/actions";
import { below } from "../../assets/css/media";
import { ieMediaQuery } from "../../assets/css/browser";
import JsonFetcher from "../../components/utils/JsonFetcher";
import {
	withClassicPlayerModal,
	Preloader,
	AdsContainer,
	Ad,
	MatchCentreBar,
	Footer, PageTitle, OneColumnLayout,
	ClassicUserTrades,
	LeagueGameBar,
	ByeDetectorControls,
	PoolSelect,
} from "../../components";
import withByeDetector from "../../components/utils/withByeDetector";
import withCoachesBox from "../../components/utils/withCoachesBox";
import withFantasyCoachModal from "../../components/utils/withFantasyCoachModal";
import { isMobile } from "../../utils/isMobile";
import UserTeam from "./advancedTrades/userTeam";
import TradePlayersPool from "./advancedTrades/tradePlayersPool";
import DraftEndSeason from "./advancedTrades/draftEndSeason";

const TwoColumns = styled.section`
	display: flex;
	justify-content: space-between;

	> div {
		flex: 1 0 0px;
		max-width: 610px;
		width: 0; /* Allow both cols to shrink evenly */

		${ieMediaQuery(css`
			max-width: 620px;
			.list-view-player > div:last-child > button, 
				.list-view-player > button:last-child {
					margin: 0;
			}
		`)}

		&:first-child {
			margin-right: 5px;

			${below.desktop`
				margin: 0;
			`}
		}
	}

	/* Show  short player positions for dpp */
	.player-positions > span {
		&.long {
			display: none;
		}
		&.short {
			display: inline;
		}
	}

	p.player-name {
		max-width: 90px;
		${below.tablet`
			max-width: 65px;
		`}
		& > span.initials {
			display: inline;
		}
		& > span.full {
			display: none;
		}
	}

	${below.desktop`
		width: 100%;
		> div {
			flex-direction: column;
			width: 100%;
			max-width: 100%;
		}
		
		${({ show_player_pool }) => show_player_pool ? css`
			> div:first-child {
				display: none;
			}
			> div:last-child {
				display: flex;
			}
		` : css`
		> div:first-child {
				display: flex;
			}
			> div:last-child {
				display: none;
			}
		` }
	`}

	${below.phone`
		.list-view-player .player-stats > div {
			font-size: 12px;
		}
	`}
`;

const PoolSelectWrapper = styled.div`
	width: ${({ is_mobile }) => is_mobile ? "100%" : "230px"};
	box-sizing: border-box;
	margin: 0 20px 20px;

	${below.desktop`
		width: 100%;
	`};
`;

const SelectsWrapper = styled.div`
	justify-content: flex-start;
	align-items: center;

	display: none;

	${below.desktop`
		display: flex;
	`}

	> div {
		min-width: 120px;
		flex: 1 1 0px;
		margin: 0;

		:first-child {
			margin-right: 20px;
		}
	}

	@media screen and (max-width: 600px) {
		flex-wrap: wrap;
	}
`;
type Props = {
	team: TClassicTeam,
	fetchMyTeam: typeof actions.fetchMyClassicTeam,
	updateMyTeam: typeof actions.updateMyClassicTeam,
	fetchFavourites: typeof actions.fetchFavourites,
	clearErrorMyTeam: Function,
	positions: Array<TPosition>,
	players_by_id: TPlayersArrayWithFixtureById,
	has_assistant_coach: boolean,
	removePlayer: Function,
	errors: string | null,
	closeErrorModal: Function,
	actual_round: TRound,
	is_team_started: boolean,
	player_ids: Array<number>,
	selected_round: TRound,
	is_old_scores: boolean,
	selected_round_id: number,
	showModalForPlayerId : Function,
	data_loading_complete: boolean,
	is_pending_players: boolean,
	remaining_salary: number,
	bye_rounds: number[],
	bye_detector_selected: number[],
	bye_detector_active: boolean,
	setByeDetectorActive: Function,
	toggleByeDetectorRound: Function,
	toggleFantasyCoachModal: Function,
	player_stats: Object[],
	score_stats: Object[],
	is_season_complete: boolean
}

type State = {
	is_mobile: boolean,
	preloader: boolean,
	show_player_pool: boolean,
	mobile_stat: string,
	score_stat: string,
}
class AdvancedTradesPage extends React.Component<Props, State> {
	constructor(props, state) {
		super(props, state);

		_.bindAll(this, [
			"resize",
			"openPopup",
			"onTogglePlayerPool",
			"onHidePlayerPool",
			"onShowPlayerPool",
			"onMobileStatSelectChange",
			"onScoreStatSelectChange",
		]);
	}
	state = {
		preloader: true,
		is_mobile: false,
		show_player_pool: false,
		mobile_stat: _.get(_.first(this.player_stats), "key"),
		score_stat: "stats.points_last_round"
	};

	componentDidMount(): void {
		const {
			fetchMyTeam,
			fetchFavourites,
		} = this.props;
		fetchMyTeam();
		fetchFavourites();
		window.addEventListener("resize", this.resize);
		this.resize();
	}
	shouldComponentUpdate(next_props) {
		return next_props.data_loading_complete;
	}
	componentDidUpdate(prev_props: Props) {
		if(!prev_props.data_loading_complete && this.props.data_loading_complete) {
			this.setState({ preloader: false });
		}
		if (!prev_props.bye_detector_active && this.props.bye_detector_active) {
			this.setState({
				mobile_stat: "stats.bye_round_id"
			});
		}
	}
	componentWillUnmount() {
		window.removeEventListener("resize", this.resize);
	}

	onShowPlayerPool: Function;

	get score_stat() {
		const { score_stats } = this.props;
		const { score_stat } = this.state;
		return _.find(score_stats, { key: score_stat });
	}

	get mobile_stat() {
		const { player_stats } = this.props;
		const { mobile_stat } = this.state;
		return _.find(player_stats, { key: mobile_stat });
	}

	get player_stats() {
		const { score_stats, player_stats } = this.props;
		const game_bar_keys = _.map(score_stats, "key");

		return _.filter(player_stats, stat => {
			return !_.includes(game_bar_keys, stat.key);
		});
	}

	resize() {
		this.setState({
			is_mobile: isMobile()
		});
	}
	openPopup(player_id) {
		const { showModalForPlayerId } = this.props;
		showModalForPlayerId(player_id);
	}

	onTogglePlayerPool(state) {
		this.setState({
			show_player_pool: _.isUndefined(state) ? !this.state.show_player_pool : state
		});
	}
	onShowPlayerPool() {
		this.onTogglePlayerPool(true);
	}
	onHidePlayerPool() {
		this.onTogglePlayerPool(false);
	}
	onMobileStatSelectChange({ currentTarget }: Object) {
		const mobile_stat = currentTarget.value;
		if (mobile_stat) {
			this.setState({
				mobile_stat
			});
		}
	}
	onScoreStatSelectChange(event: Object) {
		const score_stat = event.currentTarget.value;
		if (score_stat) {
			this.setState({
				score_stat
			});
		}
	}

	get json_list() {
		if(this.props.has_assistant_coach) {
			return ["rounds", "players", "venues", "squads", "coach_players"];
		}
		return ["rounds", "players", "venues", "squads"];
	}

	renderByeDetector() {
		const {
			has_assistant_coach,
			bye_detector_active,
			bye_detector_selected,
			bye_rounds,
			setByeDetectorActive,
			toggleByeDetectorRound,
			toggleFantasyCoachModal,
		} = this.props;
		return <ByeDetectorControls
			bye_rounds={bye_rounds}
			selected={bye_detector_selected}
			setActive={setByeDetectorActive}
			active={bye_detector_active}
			has_coach={has_assistant_coach}
			toggleRound={toggleByeDetectorRound}
			showFantasyCoachModal={toggleFantasyCoachModal}
		/>;
	}

	renderScoreSelect() {
		const {
			score_stats,
		} = this.props;

		const { is_mobile, score_stat } = this.state;

		return (
			<PoolSelectWrapper is_mobile={is_mobile}>
				<PoolSelect
					name="field_statistic"
					label="Scoring"
					description={"Scoring stat that will be shown"}
					onChange={this.onScoreStatSelectChange}
					value={score_stat}
					half_margin
				>
					{score_stats.map(({ key, value }) => (
						<option value={key} key={key}>{value}</option>
					))}
				</PoolSelect>
			</PoolSelectWrapper>
		);
	}

	renderStatisticSelect() {
		const { is_mobile, mobile_stat } = this.state;

		return (
			<PoolSelectWrapper is_mobile={is_mobile}>
				<PoolSelect
					name="mobile_statistic"
					label="Statistic"
					description={"Stat that will be shown"}
					onChange={this.onMobileStatSelectChange}
					value={mobile_stat}
					half_margin
				>
					{_.map(this.player_stats, ({ key, value }) => (
						<option value={key} key={key}>{value}</option>
					))}
				</PoolSelect>
			</PoolSelectWrapper>
		);
	}

	renderSelects() {
		return <SelectsWrapper>
			{ this.renderScoreSelect() }
			{ this.renderStatisticSelect() }
		</SelectsWrapper>;
	}

	get initial_player() {
		const player_id = _.get(this.props, "match.params.player_id" , 0);
		const side = _.get(this.props, "match.params.side", "out");
		return {
			trade_in: side === "in",
			player_id
		};
	}

	get main_content() {
		const { bye_detector_active, bye_detector_selected } = this.props;
		const { is_mobile, show_player_pool } = this.state;
		const initial_player = this.initial_player;

		const bye_detector_controls = this.renderByeDetector();

		const user_team = <UserTeam
			player_id={initial_player.trade_in ? 0 : initial_player.player_id}
			is_mobile={is_mobile}
			openPopup={this.openPopup}
			onSwitchPlayerPool={this.onShowPlayerPool}
			bye_detector_controls={bye_detector_controls}
			bye_detector_active={bye_detector_active}
			bye_detector_selected={bye_detector_selected}
			controls={this.renderSelects()}
			score_stat={this.score_stat}
			mobile_stat={this.mobile_stat}
		/>;

		const players_pool = <TradePlayersPool
			is_mobile={is_mobile}
			player_id={initial_player.trade_in ? initial_player.player_id : 0}
			openPopup={this.openPopup}
			onSwitchPlayerPool={this.onHidePlayerPool}
			bye_detector_controls={bye_detector_controls}
			bye_detector_active={bye_detector_active}
			bye_detector_selected={bye_detector_selected}
			stat_controls={this.renderSelects()}
			score_stat={this.score_stat}
			mobile_stat={this.mobile_stat}
		/>;

		return (
			<TwoColumns show_player_pool={show_player_pool}>
				{ user_team }
				{ players_pool }
			</TwoColumns>
		);
	}

	get complete_season_message() {
		return (<DraftEndSeason />);
	}

	render() {
		const { team, is_season_complete } = this.props;
		if (!_.isEmpty(team) && !team.complete) {
			return <Redirect to={"/classic/team"} />;
		}

		return (
			<React.Fragment>
				<JsonFetcher fetch={this.json_list} />
				{this.state.preloader ? (<Preloader />) : (
					<React.Fragment>
						<LeagueGameBar />
						<AdsContainer>
							<Ad />
						</AdsContainer>
						
						<OneColumnLayout>
							<PageTitle>
								Trade centre
							</PageTitle>
							{is_season_complete ? (
								this.complete_season_message
							) : (
								<React.Fragment>
									<ClassicUserTrades />
									{this.main_content}
								</React.Fragment>
							)}

						</OneColumnLayout>
						<MatchCentreBar />
						<Footer show_key />
					</React.Fragment>
				)}
			</React.Fragment>
		);
	}
}

const mapStateToProps = state => {
	const actual_round = selectors.rounds.getActualRound(state);
	const players_by_id = selectors.players.getExtendedPlayersById(state);

	return {
		actual_round,
		players_by_id,
		team: selectors.getMyClassicTeam(state),
		positions: selectors.positions.getPositionsArray(state),
		has_assistant_coach: Boolean(selectors.getUser(state).assistant_coach),
		is_pending_players: state.players.is_pending,
		errors: state.teamsClassic.show_my.error,
		last_round: selectors.rounds.getLastCompleteRound(state),
		is_team_started: selectors.isMyTeamStarted(state),
		player_ids: selectors.getMyClassicTeamPlayersIds(state),
		remaining_salary: selectors.getMyClassicTeamRemainingSalary(state),
		player_stats: state.teamsClassic.player_pool_statistic,
		score_stats: state.teamsClassic.game_bar_statistic,
		data_loading_complete: !_.includes([
			state.players.is_pending,
			state.rounds.is_pending,
			state.squads.is_pending,
			state.venues.is_pending,
			state.teamsClassic.show_my.is_pending,
		], true),
		is_season_complete: selectors.rounds.isSeasonComplete(state)
	};
};
const mapDispatchToProps = {
	fetchMyTeam: actions.fetchMyClassicTeam,
	clearErrorMyTeam: actions.clearErrorsMyTeam,
	fetchFavourites: actions.fetchFavourites,
	showModalForPlayerId: actions.showModalForPlayerId,
};

export const AdvancedTrades = compose(
	withClassicPlayerModal,
	withCoachesBox,
	withByeDetector,
	withFantasyCoachModal,
	connect(
		mapStateToProps,
		mapDispatchToProps,
	)
)(AdvancedTradesPage);


export default AdvancedTrades;