// @flow
import styled from "styled-components";
import * as React from "react";
import { below } from "../../assets/css/media";
//import colors from '../../assets/css/colors';
import {
	PlayerTableCell,
	PlayerInfo,
	UndoButton, TradePlayerCost,
} from "../";
import {TPlayer} from "../../modules/types";
import { currencyFormat } from "../../utils/currencyFormat/index";
import Undo from "../Icons/Undo";
import { TradeCell } from "./TradeCell";

const InCellStyled = styled(TradeCell)`
	text-align: right;
	flex-direction: row;
	padding-right: 20px;
	${PlayerTableCell} {
		justify-content: start;
	}
  .trade-in-undo{
    margin-left: 20px;
    margin-right: 0;
  }
	.player-info {
		flex-direction: row;
		text-align: left;
		.player-avatar {
			margin-left: 5px;
		}
    .player-details-info {
      > p div{
        text-align: left;
      }
      .player-cost {
        display: none;
      }
    }
    .player-details-status{
      margin-left: 0;
      margin-right: 10px;
    }
		> div {
			align-items: flex-start;
		}
	}
	.filled-icon {
		margin-left: 4em;
		margin-right: auto;
		${below.desktop`
			margin-left: 1em;
		`}
	}
`;
type TCell = {
	removePlayer?: Function,
	player: TPlayer,
	with_cost?: boolean,
	is_compressed?: boolean,
	is_classic: boolean,
};
export const TradeInCellClassic = ({ 
	player, 
	removePlayer, 
	with_cost = false, 
	is_compressed,
	is_classic,
}: TCell) => {
	return (
		<InCellStyled>
			{removePlayer && (
				<UndoButton
					onClick={() => removePlayer({ id: player.id })}
					className="trade-in-undo"
				>
					<Undo color="#fff" />
				</UndoButton>
			)}
			<PlayerTableCell
				className="player-table-cell"
				width="auto"
				height="80px"
				justify="flex-start"
			>
				<PlayerInfo
					player={player}
					className="player-info"
					is_compressed={is_compressed}
					is_classic={is_classic}
				/>
				
			</PlayerTableCell>
			{with_cost && (
				<TradePlayerCost margin="left" is_double>
					{currencyFormat({ input: player.cost })}
				</TradePlayerCost>
			)}
			
		</InCellStyled>
	);
};

export default TradeInCellClassic;