// @flow
import * as React from "react";
import styled from "styled-components";
import colors from "../../../../assets/css/colors";
import {
	ButtonPrimary,
	LeagueAvatar,
	LeagueName,
	ModalContainer,
} from "../../../../components";
import { below } from "../../../../assets/css/media";
import type { TPLeague } from "../../../../modules/types";

const LeagueAvatarStyled = styled(LeagueAvatar)``;

const JoinLeagueSettingsPopupHeading = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	background: #fff;
	height: 100px;
	padding: 75px;
	box-sizing: border-box;
	
	${below.tablet`
		justify-content: left;
		height: auto;
		display: block;
		margin: 45px 0 0 0;
		padding: 0 0 0 10px;

		${LeagueAvatarStyled} {
			display: none;
		}
	`}

	> div {
		width: 70%;
		display: flex;
		align-items: center;
		box-sizing: border-box;
		
		${below.tablet`
			display: block;
			width: 100%;
		`}
	}

	img {
		margin-right: 1em;
	}
`;

const JoinLeagueSettingsPopupOption = styled.div`
	padding: 0 75px;
	box-sizing: border-box;
	margin: 10px 0;
	
	${below.tablet`
		padding: 0 10px;
	`}

	h3 {
		color: ${colors.primary.primary};
		font-size: 15px;
		font-weight: bold;
		line-height: 1.46;
	}

	> div {
		margin: .5em auto;
		box-sizing: border-box;

		> div {
			background: #fff;
			display: flex;
			align-items: center;
			box-sizing: border-box;

			p:first-child {
				color: ${colors.primary.primary};
				font-size: 12px;
				line-height: 1.66;
			}
			p:last-child {
				color: ${colors.secondary.accentGrey};
				font-size: 12px;
				line-height: 1.66;
				margin-left: 1em;
			}
		}
	}
`;

const JoinLeagueSettingsPopupFooter = styled.div`
	margin: 20px 0 2.5em;
	padding-left: 75px;
	
	${below.tablet`
		padding-left: 10px;
	`}

	button {
		width: 200px;
	}
`;

const LeagueNameStyled = styled(LeagueName)`
	margin-left: 1em;
	${below.tablet`
		margin-left: 0;
	`};
`;

const onOff = param => param ? "ON" : "OFF";

type PropsOption = {
	label: string,
	value: string | number,
	is_uppercase?: boolean
};
const PopupOption = ({ label, value, is_uppercase }: PropsOption) => (
	<div>
		<p>{label}</p>
		<p className={is_uppercase ? "uppercase" : ""}>{value}</p>
	</div>
);

type Props = {
	league: TPLeague,
	onClick: Function,
	join: Function
};

export const JoinLeagueSettingsPopup = ({ league, onClick, join }: Props) => {
	if (!league) {
		return null;
	}

	return (
		<ModalContainer is_sponsored={false} onClick={onClick}>
			<JoinLeagueSettingsPopupHeading>
				<div>
					<LeagueAvatarStyled is_classic league={league} size="60px" />
					<LeagueNameStyled
						width="300px"
						name={league.name}
						lastname={league.lastname}
						firstname={league.firstname}
					/>
				</div>
			</JoinLeagueSettingsPopupHeading>

			<JoinLeagueSettingsPopupOption>
				<h3>Basic Settings</h3>
				<div>
					{league.type === "head_to_head" && (
						<PopupOption label="League Size" value={league.size} />
					)}
					<PopupOption label="Starting Round" value={league.start_round} />
					<PopupOption label="Privacy Settings"
						value={league.privacy ? "PUBLIC" : "PRIVATE"}
					/>
					<PopupOption
						label="League type"
						value={league.type === "head_to_head" ? "Head to head" : "Open"}
					/>
					<PopupOption
						label="Coaches Box"
						value={onOff(league.coaches_box)}
					/>

				</div>
			</JoinLeagueSettingsPopupOption>
			{league.type === "head_to_head" && (
				<JoinLeagueSettingsPopupOption>
					<h3>Advanced Settings</h3>
					<div>
						<PopupOption label="League Finals Series"
							value={league.finals ? league.finals_format : "OFF"}
						/>
						<PopupOption label="Play Opponents"
							value={league.play_times}
							is_uppercase={true}
						/>
						<PopupOption label="Bye Rounds"
							value={onOff(league.bye_rounds)}
						/>
					</div>
				</JoinLeagueSettingsPopupOption>
			)}


			<JoinLeagueSettingsPopupFooter>
				<ButtonPrimary onClick={join.bind(this, league.code)} width="200px">
					Join this league
				</ButtonPrimary>
			</JoinLeagueSettingsPopupFooter>
		</ModalContainer>
	);
};

export default JoinLeagueSettingsPopup;