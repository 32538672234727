// @flow
import { css } from "styled-components";
import { below } from "./media";

export const sticky_headers = css`
	@supports(position: sticky) {
		position: sticky;
		top: 130px;
		left: 0;
		background: white;
		z-index: 95;

		:hover {
			z-index: 96;
		}
	}

	${below.tablet`
		@supports(position: sticky) {
			top: 115px;
		}
	`}
`;

export default sticky_headers;