// @flow
import * as React from "react";
import styled from "styled-components";
import { below } from "../../assets/css/media";
import colors from "../../assets/css/colors";

import { ModalContainer, 
	ModalContent,
	ModalBody, ModalOverlay,
} from "../../components/Modal";
import Caution from "../../components/Icons/Caution";




const ModalTitle = styled.h2`
	color: ${colors.primary.primary};
	font-size: 32px;
	text-align: center;
	padding-top: 20px;  
	${below.tablet`
		font-size: 24px;
	`}

	${below.phone`
		font-size: 22px;
	`}
`;

const ModalText = styled.p`
	color: ${colors.primary.primary};
	font-family: SourceSansPro;
	font-size: 14px;
	line-height: 20px;
	text-align: center;
	margin-bottom: 10px;
	padding-top: 20px;  
	${below.tablet`
		font-size: 12px;
	`}

	${below.phone`
		font-size: 12px;
	`}
`;


const GreatButton = styled.button`
	width: 200px;
	height: 40px;
	background: #1D4073;
	border-radius: 2px;
	color: white;
	outline: none;
	font-family: SourceSansPro;
	font-size: 12px;
	font-weight: 500;
	text-align: center;
	border: none;
	margin-top: 20px;
`;





const ProModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: grey;
	z-index: 2000;
	overflow: hidden;
	
	${ModalContent} {
		box-sizing: border-box;
		box-shadow: 1px 0px 12px 0 rgb(0,0,0,0.2);
		padding-top: 0;
		${below.tablet`
			max-width: unset;
		`}

		height: fit-content;
		padding-bottom: 30px;
		${below.phone`
			height: 502px;
			max-width: 320px;
			padding-top: 0;
			padding-left: 0;
			padding-right: 0;
		`}

		@media screen and (max-width: 330px) {
			padding-top: 0;
		}

		${ModalBody} {
			padding: 0 100px 0px;
			overflow: hidden;
			position: static;
			${below.desktop}{
				overflow: hidden;
			}
			${below.phone`
				padding: 0;
				max-width: 300px;
				margin: 0 auto;
			`}
		}
	}
  ${ModalOverlay}{
    background: rgba(0, 0, 0, 0.5);
    box-shadow: 1px 0px 12px 0 rgb(0,0,0,0.2);
    height: 100%;
  }
  

	.close-button {
		z-index: 1;
		> svg {
			fill: ${colors.primary.primary};
		}
		:hover {
			> svg {
				fill: #333;
			}
		}
	}     
`;


const LogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  width: 235px;
  margin-left: auto;
  margin-right: auto;
  ${below.phone`
    width: 130px;
  `}
  svg{
    height: 60px;
		width: 90px;
		
		path{
			fill: ${colors.warning};
		}
		
    ${below.phone`
      height: 60px;
    `}
  }
`;

const getModalText = () => {
	return " It looks like you currently don’t have a team linked to your "+
	"account from last years season. Either you are a brand new user to this "+
	"league who will be starting a fresh, meaning you will have all your draft"+
	" picks and don’t need to select keepers, or you have joined the league with"+
	" a new email, in that case, your commissioner will need to link" +
	" your new email to your team from last year!";
};


type Props = {
	toggleModal: () => void;
};


// eslint-disable-next-line max-len
export const NoKeepersModal = ({ toggleModal }: Props) => {
	const innerText = getModalText();

	return(
		<ProModalWrapper>
			<ModalContainer
				onClick={toggleModal}
				container_background={colors.white}
			>
				<LogoWrapper>
					<Caution />
				</LogoWrapper>
				<ModalTitle>No Linked Team!</ModalTitle>
			
				<ModalText dangerouslySetInnerHTML={{ __html: innerText }} />
				<GreatButton onClick={toggleModal}>
					Ok
				</GreatButton>
			</ModalContainer>
		</ProModalWrapper>
	);
	
};




export default NoKeepersModal;