// @flow
import * as React from "react";
import { Field } from "redux-form";
import styled, { css } from "styled-components";
import moment from "moment/moment";

import {
	getStartDraftDate,
	isAllTrue,
	isAnyTrue,
	league_tooltips,
} from "../../../../../helpers";

import {
	FormDateField,
	FormSelect,
	FormRadio,
	Exist,
} from "../../../../../components";
import type { TPLeague } from "../../../../../modules/types";
import colors from "../../../../../assets/css/colors";
import { IS_DISABLE_LIVE_DRAFT } from "../../../../../modules/constants";

const CommissionerNote = styled.p`
	font-family: SourceSansPro;
	font-weight: 400;
	font-size: 14px;
	color: ${colors.secondary.accentGrey};
	margin-bottom: 30px;
`;

const DraftSettingsWrapper = styled.div`
	${({ no_margin }) => no_margin && css`
		& > div:first-of-type > div:last-of-type {
			margin-bottom: 10px;
		}
	`};
`;


const StyledNotice = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 5px;
	font-size: 14px;
	strong{	
		font-weight: 600;
		font-family: SourceSansPro;
	}
	p{
		font-weight: 400;
		font-family: SourceSansPro;
	}
	color: ${colors.primary.primary};
	margin-bottom: 20px;
	

`;

type Props = {
	is_draft_commissioner: boolean,
	maxDate: string,
	is_draft_auto: boolean,
  	league: TPLeague,
	regenerated_from: number;
	keeper: number;
	changeFieldValue: Function;
}

export const DraftSettings = ({ 
	maxDate, 
	is_draft_commissioner, 
	is_draft_auto, 
	league, 
	regenerated_from, 
	keeper }: Props) => {
	
	const isKeeperAndRegen = Boolean(regenerated_from) && Boolean(keeper);
	const date_difference = Date.parse(league.draft_start) - new Date().getTime();
	const is_draft_starting = (league.status === "scheduled" && 
    date_difference < 0 ? false : isAllTrue([
			league.status === "scheduled",
			(date_difference) < 1800000 
		]));
	const isDisableDraftSettings =isAllTrue([
		IS_DISABLE_LIVE_DRAFT,
		league.draft_status === "scheduled"
	]);

	const getValidDatesLeague = (currentData, maxDate) => {
		const
			start_date = getStartDraftDate(),
			yesterday = start_date.subtract(1, "day");
	
		maxDate = moment(maxDate).subtract(1, "day");

		if(isKeeperAndRegen){
			const allowedStartDate = getStartDraftDate().add(3, "day");
			return isAllTrue([
				currentData.isAfter(allowedStartDate),
				currentData.isBefore(maxDate)
			]);
		}
	
		return isAllTrue([
			currentData.isAfter(yesterday),
			currentData.isBefore(maxDate)
		]);
	};

	const getDefaultStartDraftDate = () => {
		if(isKeeperAndRegen){
			const newStart = getStartDraftDate().add(3, "day");
			return newStart;
		}
		return getStartDraftDate();
	};


	return(
		<DraftSettingsWrapper no_margin={is_draft_commissioner}>
			<Exist when={is_draft_commissioner}>
				<CommissionerNote>
					Please note that the commissioner's draft 
					can only be completed by the commissioner 
					and only on the desktop website.
				</CommissionerNote>
			</Exist>
		

			<Field
				label='Draft turn time'
				name='draft_turn'
				options={[
					{
						value: 60,
						text: "60 seconds",
					},
					{
						value: 120,
						text: "2 minutes",
					},
					{
						value: 300,
						text: "5 minutes",
					},
					{
						value: 900,
						text: "15 minutes",
					},
					{
						value: 3600,
						text: "60 minutes",
					},
					{
						value: 43200,
						text: "12 hours",
					},
					{
						value: 86400,
						text: "24 hours",
					},
				]}
				component={FormSelect}
				tooltip={league_tooltips.draft_turn}
				is_disabled={isAnyTrue([
					isDisableDraftSettings,
					is_draft_commissioner,
					is_draft_auto,
					is_draft_starting,
					league.draft_status === "complete" 
				])}
			/>

			<Field
				label='Draft date and time'
				type='text'
				name='draft_start'
				placeholder='Draft Date and Time'
				isValidDate={getValidDatesLeague}
				component={FormDateField}
				defaultValue={getDefaultStartDraftDate()}
				maxDate={maxDate}
				readOnly={true}
				timeFormat='h:mmA'
				tooltip={league_tooltips.draft_start}
				is_disabled={ isAnyTrue([
					is_draft_starting,
					league.draft_status === "complete",
					isDisableDraftSettings
				])}
			/>

			<FormRadio
				label='Draft turn type'
				name='draft_order'
				elements={[
					{
						value: "snake",
						text: "Snake",
					},
					{
						value: "linear",
						text: "Linear",
					}
				]}
				tooltip={league_tooltips.draft_order}
				is_string_value={true}
				is_disabled={isAnyTrue([
					is_draft_commissioner,
					is_draft_starting,
					league.draft_status === "complete",
					isDisableDraftSettings
				])}
			/>
			<Exist when={isDisableDraftSettings}>
				<StyledNotice>
					<strong>Please note: </strong>
					<p>Live drafts are now disabled for season 2024 and can no longer be scheduled.
						Please come back next season to create your next draft league
					</p>
				</StyledNotice>
				
			</Exist>
		</DraftSettingsWrapper>
	);
};
	


export default DraftSettings;