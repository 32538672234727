// @flow
import styled, { css } from "styled-components";
import { below } from "../../assets/css/media";

export const TransactionButtonsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: ${props => props.numTrades ? `${props.numTrades * 60}px`:"60px"};
	margin-top: 20px;

	button {
		display: block;
		min-width: 150px;
	}

	${({ show_mobile }) => show_mobile 
		? css`
			display: none;
			${below.desktop`
				margin-top: 20px;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
			`};
		`
		: css`
			${below.desktop`
				display: none;
			`};
	`};
`;

export default TransactionButtonsWrapper;