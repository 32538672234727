// @flow
import moment from "moment";
import _, { isEmpty } from "lodash";
import type { TPLeague, TRound, TPlayer, TTeam, ById } from "../../modules/types";
import { isAllTrue } from "..";
import { getActualRound } from "../rounds";
import { IS_DISABLE_LIVE_DRAFT } from "../../modules/constants";

export const FINALS_NAMES = [ "GF", "PF", "SF", "W1" ];
export const FINALS_FULL_NAMES = [ 
	"Grand Final", "Preliminary Finals", "Semi Finals", "Finals Week 1" 
];
export const FINALS_LENGTHS = {
	"8": 4,
	"6": 3,
	"5": 4,
	"4": 2,
	"2": 1,
	"1": 1
};

export const ROUNDS_IN_AFL_SEASON = 24;
export const DEFAULT_FINALS_FORMAT = 8;

type TLeague = Object;
export type TLeagueDraftState = 'complete' | 'active' | 'future' | 'unscheduled';

export const isDraftComplete = (league: TLeague) => league.draft_status === "complete";

export const isDraftActive = (league: TLeague) => league.draft_status === "active";

export const isDraftFuture = (league: TLeague) =>
	league.draft_status === "scheduled" &&
	moment().isBefore(league.draft_start);

export const isDraftUnscheduled = (league: TLeague) =>
	league.draft_status === "scheduled" &&
	moment().isBefore(league.draft_start) === false;

export const getDraftState = (league: TLeague): ?TLeagueDraftState => {
	if (isDraftComplete(league)) {
		return "complete";
	}
	else if (isDraftActive(league)) {
		return "active";
	}
	else if (isDraftFuture(league)) {
		return "future";
	}
	else if (isDraftUnscheduled(league)) {
		return "unscheduled";
	}

	return null;
};

export const getDefaultDraftLeagueRoute = (league: TLeague) => {
	const league_draft_state = getDraftState(league);


	if (!(league && league.id)) return null;



	const states = {
		active: IS_DISABLE_LIVE_DRAFT 
			? `/draft/league/${league.id}/pre-draft/` : `/draft/league/${league.id}/live-draft/`,
		complete: `/draft/league/${league.id}/`,
		future: `/draft/league/${league.id}/pre-draft/`,
		unscheduled: `/draft/league/${league.id}/pre-draft/`
	};

 	return league_draft_state ? states[league_draft_state] : null;
};

export const getDefaultDraftLeagueRouteButtonText = (league: TLeague) => {
	const league_draft_state = getDraftState(league);

	if (!(league && league.id)) return null;

	const states = {
		active: IS_DISABLE_LIVE_DRAFT ? "Go to your Pre Draft List" : "Go to your Live Draft",
		complete: "Go to League Overview",
		future: "Go to your Pre Draft List",
		unscheduled: "Go to your Pre Draft List"
	};

 	return league_draft_state ? states[league_draft_state] : null;
};

export const getSquadSizeArray = (league: TLeague = {}) => {
	const { squad_size } = league;

	if (!squad_size) {
		return [];
	}

	return _.map(squad_size.split(/[-/]/), _.unary(parseInt));
};

export const getSquadSize = (league: TLeague) => {
	const squad_size = getSquadSizeArray(league);

	if (_.isEmpty(squad_size)) {
		return 0;
	}

	return squad_size.reduce((sum, num) => sum + num, 0);
};

/**
 * Gets the rounds between the given league's start and end rounds (inclusive)
 * Also sets any future rounds to be disabled.
 * If league is not given, returns all rounds, with future rounds disabled
 * 
 * @param {TRound[]} rounds_ordered List of all rounds
 * @param {TPLeague} league The league to get rounds for (optional)
 */
export const getLeagueRounds = (rounds_ordered: TRound[], league?: TPLeague ) => {
	const start_round = _.get(league, "start_round", 1);
	const final_round = _.get(_.last(rounds_ordered), "id", 1);
	const end_round = _.get(league, "end_round", final_round);

	if (rounds_ordered && start_round && end_round) {
		const actual_round = getActualRound(rounds_ordered);
		return _(rounds_ordered)
			.filter(({ id }) => _.inRange(id, start_round, end_round + 1))
			.map(round => ({ ...round, disabled: round.id > actual_round.id }))
			.value();
	}

	return rounds_ordered;
};

export const getRankingsRounds = (rounds_ordered: TRound, league?: TPLeague ) => {
	const returnRounds = rounds_ordered.filter((round: TRound) => round.status !== "scheduled");
	returnRounds.unshift({id: 0, label: 'All Rounds'});
	return returnRounds;
};

export const getLeagueRoundAliases =  (league: TPLeague, rounds_ordered: TRound[]) => {
	const { finals, finals_format } = league;
	const league_rounds = getLeagueRounds(rounds_ordered, league);
	const finals_rounds = {};
	if (finals) {
		_(league_rounds)
			.reverse()
			.slice(0, FINALS_LENGTHS[finals_format])
			.forEach((round, i) => finals_rounds[round.id] = FINALS_NAMES[i]);
	}
	return finals_rounds;
};

const finalsFormatHelper = (finals_format: number, finals_length: number) => {
	switch (finals_format) {
		case 2: 
			return 1;
		case 4:
			return finals_length === 3 ? "6" : "4";
		case 6:
			return finals_length === 4 ? "8" : "6";
		case 8:
			return "8";
		default:
			return "8";
	}
};

export const getLeagueFinalFullNames =  (league: TPLeague, rounds_ordered: TRound[]) => {
	const { finals, finals_format, finals_length } = league;
	const league_rounds = getLeagueRounds(rounds_ordered, league);

	const finalsFormatted = finalsFormatHelper(finals_format, finals_length);

	const finals_rounds = {};
	if (finals) {
		_(league_rounds)
			.reverse()
			.slice(0, FINALS_LENGTHS[finalsFormatted])
			.forEach((round, i) => finals_rounds[round.id] = FINALS_FULL_NAMES[i]);
	}

	return finals_rounds;
};



export const getFirstRoundFinalTemplate = (league: TPLeague)  =>{
	//check the league format and finals format for the type(:number) of finals to show
	//then return the corresponding type of fixture 
	const { finals_format, finals_length } = league;
	
	switch (finals_format) {
		case 4:
			return finals_length === 3 ? [{
				round: "1",
				fixtures: [{ home: "1", away: "2" }, { home: "3", away: "4" }]
			},{
				round: "2",
				fixtures: [{ home: "", away: "" }]
			},{
				round: "3",
				fixtures: [{ home: "", away: "" }]
			}] : [{
				round: "1",
				fixtures: [{ home: "1", away: "4" }, { home: "2", away: "3" }]
			},{
				round: "2",
				fixtures: [{ home: "", away: "" }]
			}];
		case 5:
			return [{
				round: "1",
				fixtures: [{ home: "2", away: "3" }, { home: "4", away: "5" }],
			},{
				round: "2",
				fixtures: [{ home: "1", away: "" }, { home: "", away: "" }]
			},{
				round: "3",
				fixtures: [{ home: "", away: "" }]
			},{
				round: "4",
				fixtures: [{ home: "", away: "" }]
			}];
		case 6:
			return finals_length === 4 ? [{
				round: "1",
				fixtures: [{ home: "4", away: "5" }, { home: "3", away: "6" }]
			},{
				round: "2",
				fixtures: [{ home: "1", away: "2" }, { home: "", away: "" }]
			},{
				round: "3",
				fixtures: [{ home: "", away: "" }]
			},{
				round: "4",
				fixtures: [{ home: "", away: "" }]
			}]:[{
				round: "1",
				fixtures: [{ home: "4", away: "5" }, { home: "3", away: "6" }]
			},{
				round: "2",
				fixtures: [{ home: "1", away: "" }, { home: "2", away: "" }]
			},{
				round: "3",
				fixtures: [{ home: "", away: "" }]
			}];
		case 8: 
			return [{
				round: "1",
				fixtures: [{ home: "1", away: "4" }, { home: "5", away: "8" }, 
					{ home: "6", away: "7" }, { home: "2", away: "3" }]
			},{
				round: "2",
				fixtures: [{ home: "", away: "" }, { home: "", away: "" }]
			},{
				round: "3",
				fixtures: [{ home: "", away: "" }, { home: "", away: "" }]
			},{
				round: "4",
				fixtures: [{ home: "", away: "" }]
			}];
		default:
			return [];
	}
	

};

export const getLeagueLadderRound = (
	league: TPLeague,
	selected_round_id: number,
	last_complete_round: TRound
) =>  {
	if(isEmpty(last_complete_round) ){
		return league.start_round;
	}
	const round = selected_round_id <= last_complete_round.id + 1
		? selected_round_id
		: Math.max(last_complete_round.id, league.start_round);
	return round || 1;
};

export const getLadderRoundFinals = (league: TPLeague, ladder_round_id: number) => {
	const format = _.get(league, "finals_format", 0);
	const end_round = _.get(league, "end_round", 0);
	const finals_length = _.get(FINALS_LENGTHS, format, 0);
	const last_regular_round = end_round - finals_length;

	return Math.min(last_regular_round, ladder_round_id);
};

type TWinners = {
	premier?: number,
	consolation_premier?: number,
	wooden_spoon?: number,
};

export const hasLeagueFinished = (league: TPLeague, round: TRound) => {
	const end_round = _.get(league, "end_round", 0);
	const round_id = _.get(round, "id", 0);

	if (end_round === round_id) {
		return _.get(round, "status") === "complete";
	}
	
	return round_id > end_round;
};

const hasFinalsStarted = (league: TPLeague, round: TRound) => {
	const finals = _.get(league, "finals");
	const format = _.get(league, "format", 0);
	const end_round = _.get(league, "end_round", 0);
	const round_id = _.get(round, "id", 0);

	return finals && end_round - format < round_id;
};

const getWoodenSpooner = (
	ladder_teams_ordered: Object[]
) => _.get(_.last(ladder_teams_ordered), "id");

const getRegularWinners = (
	league: TPLeague, 
	round: TRound, 
	ladder_teams_ordered: Object[]
): TWinners => {
	if (hasLeagueFinished(league, round)) {
		return {
			premier: _.get(_.first(ladder_teams_ordered), "id"),
			wooden_spoon: getWoodenSpooner(ladder_teams_ordered),
		};
	}
	return {};
};

const getTeamValue = (
	ladder_teams_ordered: Object[], 
	id: number, 
	value: any,
	default_value?: any
) => {
	const team = _.find(ladder_teams_ordered, { id });

	return _.get(team, value, default_value);
};

const getGrandFinalMatchup = (
	league: TPLeague,  
	ladder_teams_ordered: Object[], 
	consolation: boolean = false
) => {
	const end_round = _.get(league, "end_round", 0);
	const format = _.get(league, "finals_format", 0);
	const final_round_fixture = _.get(league, ["fixture", end_round], []);

	// The GF is the first matchup in the final round where one of the
	// teams is in the top 8 (or whatever format is being used)
	return _.find(
		final_round_fixture,
		teams => {
			const team_id = _.get(teams, 0);
			const team_rank = getTeamValue(ladder_teams_ordered, team_id, "rank", 0);
			return consolation ?  team_rank > format : team_rank <= format;
		});
};

const getPremier = (
	league: TPLeague,  
	ladder_teams_ordered: Object[],
	consolation: boolean = false,
): number | void => {
	const end_round = _.get(league, "end_round", 0);
	return _(getGrandFinalMatchup(league, ladder_teams_ordered, consolation))
		.map(id => ({
			score: getTeamValue(ladder_teams_ordered, id, ["scoreflow", end_round]),
			rank: getTeamValue(ladder_teams_ordered, id, "rank"),
			id,
		}))
		.orderBy(["score", "rank"], ["desc", "asc"])
		.map("id")
		.first();
};

const getFinalsWinners = (
	league: TPLeague, 
	round: TRound, 
	ladder_teams_ordered: Object[]
): TWinners => {
	if (hasLeagueFinished(league, round)) {
		return {
			premier: getPremier(league, ladder_teams_ordered),
			consolation_premier: getPremier(league, ladder_teams_ordered, true),
			wooden_spoon: getWoodenSpooner(ladder_teams_ordered)
		};
	}
	return {
		wooden_spoon: hasFinalsStarted(league, round) 
			? getWoodenSpooner(ladder_teams_ordered)
			: undefined
	};
};

export const getWinners = (
	league: TPLeague, 
	round: TRound, 
	ladder_teams_ordered: Object[]
): TWinners => {
	const type = _.get(league, "type");
	const finals = _.get(league, "finals");
	if (type === "open" || !finals) {
		return getRegularWinners(league, round, ladder_teams_ordered);
	}
	return getFinalsWinners(league, round, ladder_teams_ordered);
};

/* Show the last 5 games from the selected round unless:
 *  - There have been less than 5 games played or,
 *  - The selected round is in the future - then, show the last 5 games that have been played
*/
export const scoreflowToForm = (
	team: TTeam,
	selected_round_id: number,
	actual_round: TRound
) => {
	const scoreflow = _.get(team, "league_scoreflow");
	if(scoreflow && selected_round_id && actual_round) {
		let start_id = (selected_round_id - 5 < 1) ? 1 : selected_round_id - 5;
		let end_id = selected_round_id;
		if (selected_round_id > actual_round.id) {
			start_id = (actual_round.id - 5 < 1) ? 1 : actual_round.id - 5;
			end_id = actual_round.id;
		}
		return _(scoreflow)
			.filter((s, r) => _.inRange(r, start_id, Math.min(start_id + 5, end_id)))
			.map(score => {
				return score === 4 ? "w" : score === 0 ? "l" : "d";
			})
			.value();
	}
	return [];
};

interface TNamed {
	firstname?: string,
	first_name?: string,
	lastname?: string,
	last_name?: string,
	name?: string
};

export const getShortName = (entity?: TNamed) => {
	if (!entity) {
		return "";
	}

	const fname = entity.firstname || entity.first_name;
	const lname = entity.lastname || entity.last_name;
	const lastNameHandled = _.get(lname, [0]) + ".";
	
	if (isAllTrue([Boolean(fname), lname === "."])) {
		return fname;
	}
	
	else if (isAllTrue([Boolean(fname), Boolean(lname)])) {
		return `${String(fname)} ${lastNameHandled}`;
	}
	else if (fname) {
		return fname;
	}
	return "";
};

export const transactionPlayerName = (transaction: Object, player: TPlayer) => {
	return (
		`${player[transaction.player_id].first_name} ${player[transaction.player_id].last_name}`
	).toLowerCase();
};

export const getCelebName = (celeb?: TNamed) => {
	if (celeb && !_.isEmpty(celeb)) {
		return celeb.name;
	}

	return "";
};

export const mergeArrays = (arrays: any) => [].concat.apply([], arrays);

export const getPicksByTeamID = (arr: number[]) => arr.reduce((obj, item) => ({
	...obj,
	[item]: obj[item] ? 1 + obj[item] : 1
}), {});

export const picksByDraftRound = (
	picks: number[], noTeams: number, draftRound:number
): number[] => picks.slice((draftRound - 1) * noTeams, draftRound * noTeams);

export const picksByTeamAndRound = (
	draftOrder: number[], 
	numTeams: number, 
	team: number, 
	round: number) => {
	const pickLowerBound = (round - 1) * numTeams;
	const pickUpperBound =  round * numTeams;
	const by_team = 
			draftOrder.reduce((pickObj, teamID, index) => {
				if((index >= pickLowerBound && index < pickUpperBound)  || pickUpperBound === 0){
					if(teamID === team){
						return {
							...pickObj,
							[index+1]: teamID
						};
					}
					return pickObj;
				}
				return pickObj;
			}, {});
	return by_team;
};

export const totalSquadSize = (squadSizeStr: string) => {
	const getNosFromSquadSize = squadSizeStr.split("-").map(item => item.split("/"));
	const squadSizeNumsArray = mergeArrays(getNosFromSquadSize).map(num => Number(num));
	return squadSizeNumsArray.reduce((a, b) => a + b, 0);
}; 

export const getUserShortName = (team: TTeam) => team && team.lastname ? 
	`${team.firstname} ${team.lastname.charAt(0)}`:"";
export const getTeamNameStr = (teamsByID: ById<TTeam>, teamID: number) =>
	teamsByID && teamsByID[teamID]
		? `${teamsByID[teamID].name} / ${getUserShortName(teamsByID[teamID])}`
		: "";

export const replaceAt = (array:Array<number>, index:number, value:number):Array<number> => {
	const ret = array.slice(0);
	ret[index] = value;
	return ret;
};

const indexBetween = (item:number, min:number, max:number) => item >= min && item < max;

export const replaceRound = (
	array: Array<number>,
	subArray:Array<number>,
	startIndex:number
):Array<number> => 
	array.map((item, index) => {
  		const subArrayLength = subArray.length;
		  return indexBetween(index, startIndex, startIndex + subArrayLength) ? 
		  subArray[index - startIndex] : item;
	});

export const isOdd = (num:number) => Boolean(num % 2);

export const getSortedDraftOrder = (
	currentDraftRound:number, 
	draftOrder:Array<number>,
	draftType: string
):Array<number> => {
	if(draftType === "snake") {
		return isOdd(currentDraftRound) ? draftOrder:[ ...draftOrder ].reverse();
	}
	else {
		return draftOrder;
	}
};

export const getDidPicksChange = (
	currentList: Array<number>, 
	draftOrder:Array<number>, 
	currentDraftRound:number,
	draftType: string ): boolean => {
	return JSON.stringify(currentList) !== JSON.stringify(getSortedDraftOrder(
		currentDraftRound, draftOrder, draftType
	));
};
export const getRoundSelectStr = (label:string, didPicksChange:boolean) => 
	didPicksChange ? `${label} (Edited)`:label;

export const everyXNumberBetween = (
	initial: number, 
	max: number, 
	x: number
):Array<number> => {
	let count = initial;
	let arr = [];
	while (count <= max) {
	  if (count % x === 0) {
			arr.push(count);
	  }
	  count++;
	}
	return arr;
};

export const restoreFullDraftOrder = (
	draftOrder:Array<number>,
	draftRounds: number,
	draftType: string
):Array<number> => {
	const reverseOrder = draftType === "snake" ? [...draftOrder].reverse():draftOrder;
	const rounds = [...Array(draftRounds).keys()];
	
	return mergeArrays(rounds.map((round, index) => 
		isOdd(index) ? reverseOrder : draftOrder)
	);
};

export const chunkArray = (array:Array<number>, size: number):Array<Array<number>>  => {
	if (!array.length) {
	  return [];
	}
	const head = array.slice(0, size);
	const tail = array.slice(size);
  
	return [head, ...chunkArray(tail, size)];
};

export const getLeagueScoringBasis = (league: TLeague) => {
	return Boolean(_.get(league, "custom_scoring_enabled", 0)) ? "custom_stats" : "stats";
};

export const threeDaysPriorKeeperDeadline = (draft_start: string) => {
	const date = new Date(draft_start);
	date.setDate(date.getDate()-3);
	return  new Date(date.toUTCString());
};

export const getKeeperDeadlinePassed = (
	draft_start:string,
	keeper_deadline:string
) => {
	const threeDaysPriorDate = threeDaysPriorKeeperDeadline(draft_start);
	const countdownTime = new Date(keeper_deadline) || threeDaysPriorDate;
	return countdownTime < new Date();
};



export const getStatKeyName = (key: string) => {
	const keyStatObject = {
		"K": "Kick",
		"H": "Handball",
		"M": "Mark",
		"T": "Tackle",
		"FF": "Free For",
		"FA": "Free Against",
		"HO": "Hit Out",
		"G": "Goal",
		"B": "Behind",
		"CL": "Clearance",
		"CM": "Contested Mark",
		"CP": "Contested Possession",
		"CS": "Clanger",
		"ED":"Effective Disposal",
		"IED": "Ineffective Disposal",
		"R50": "Rebound 50",
		"I50": "Inside 50",
		"SP": "Spoil",
		"UCP": "Uncontested Possession",
		"FD": "Free Kick Differential",
		"GA": "Goal Assist",
	};
	return keyStatObject[key] || "";
};


export const getLeagueInitialFetchRound = (selected_round_id: number, actual_round_id: number) => {
	return selected_round_id > 0 ? selected_round_id : actual_round_id;
};