/* eslint-disable max-len */
// @flow
import * as React from "react";
import styled, { css } from "styled-components";
import { ThToolTip } from "../../../components";
import { default_transition } from "../../../assets/css/vars";
import { below } from "../../../assets/css/media";
import { isMobile } from "../../../utils";
import sticky_headers from "../../../assets/css/sticky";
import { isAllTrue, isAnyTrue } from "../../../helpers";
import { Exist } from "../../Exist";

const ListViewHeaderWrapper = styled.div`
	display: flex;
	background-color: white;
	justify-content: space-around;
	height: 60px;
	align-items: center;
	border-bottom: 2px solid #CAD2D8;
  .absolute-empty{
    display: none;
  }
	& > :first-child{
		flex: 0 1 280px;
		box-sizing: border-box;
		${({ is_trades }) => is_trades && "flex: 0 0 220px;"}
		text-align: left;

		${below.desktop`
			flex-basis: 65%;
		`};

		${below.tablet`
			flex-basis: 250px;
			padding: 0 0 0 10px;
		`};
		${below.phone`
			flex-basis: 180px;
      ${({ is_draft }) => !is_draft && `
        flex-basis: 230px;
      `}
		`};
	}
	.last {
		flex: 0 0 ${({ is_trades, is_draft }) => is_trades 
		? "70px" 
		: (is_draft ? "145px" : "100px")};
		text-align: center;

		> span:last-child {
			display: none;
		}

		${below.desktop`
			flex-basis: ${({ is_trades }) => is_trades ? "70px" : "40px"};
			> span {
				display: none;
				&:last-child {
					display: inline;
				}
			}
		`}
	}

	${sticky_headers}
  ${below.tablet`
		@supports(position: sticky) {
			top: 85px;
		}
	`}

	${below.desktop`
		display: ${({ show_mobile }) => show_mobile ? "flex" : "none"};
		padding: 0 20px 0 25px;
    	margin: 0 -20px;
	`};

	${below.tablet`
		padding: 0;
		margin: 0;
    ${({ is_draft }) => !is_draft && `
      margin-right : 4px;
    `}
    ${({ is_draft }) => is_draft && `
      .absolute-right{
        position: absolute;
        right: 0;
        background: white;
        width: 50px;
        z-index: 20;
        box-shadow: -8px 0px 15px -10px #333;
      }
      .absolute{
        width: 184px;
        z-index: 20;
        position: absolute;
        left: 0;
        background: white;
        box-shadow: 5px 0px 10px -6px #333;
      }
      .absolute-empty{
        display: block;
        min-width: 440px;
        background: white;
      }
    `}
    
	`}
`;

const SHTooltip = styled(ThToolTip)`
`;

const HeaderItem = styled.div`
	color: #1D4073;
	font-family: SourceSansPro;
	font-weight: 600;
	font-size: 12px;
	flex: 1;
	${({ is_coach }) => is_coach && "color: #B89344"};
	height: 100%;

	display: flex;
    align-items: center;
    justify-content: center;
	&:first-child {
		justify-content: flex-start;
	}
	text-align: center;
	&:not(.lowerCase){
		text-transform: uppercase;
	}

	cursor: default;
	user-select: none;

	position: relative;

	p {
		padding-left: 30px;
	}
	> div.highlighter {
		opacity: 0;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(180deg, #FFFFFF 0%, #F0F3F7 100%);
		z-index: 1;

		${default_transition}

		&:before,
		&:after {
			position: absolute;
			left: 50%;
		}
	}

	span {
		position: relative;
		z-index: 2;
	}

	:hover {
		> div.highlighter {
			opacity: 1;
		}
	}
	
	${({ only_show_mobile }) => only_show_mobile && "display: none;"};

	${below.desktop`
		display: none;
    span{
      text-transform: capitalize;
    }
    
		${({ show_mobile, only_show_mobile }) => (show_mobile || only_show_mobile) && css`
			display: flex;
      ${({ is_draft }) => is_draft && "min-width: 50px;" }
			&:not(:first-child) {
				justify-content: center;
			}
		`};
	`}
`;

const StatsHeaderItems = styled.div`
	display: flex;
	justify-content: space-around;
	flex: 1 1 0px;
	height: 100%;
	-ms-overflow-style: none;
  scrollbar-width: none;
	&::-webkit-scrollbar {
  	display: none;
	}	

	> div:first-child {
		justify-content: center;
	}
  ${below.tablet`
    width: 130px;
    ${({ is_draft }) => is_draft && `
      width: 100%;
      overflow-x: auto;
      overflow-y: hidden;
      background-color: white;
    `}
   
  `}
`;

type THeader = {
	tooltip: string,
	key: string, 
	is_coach: boolean,
  short_name?: string,
	not_draft?: boolean,
}

const header_titles: THeader[] = [
	{ tooltip: "Games Played", key: "GP", is_coach: false },
	{ tooltip: "Selected %", key: "TS%", is_coach: false },
	{ tooltip: "Average Points", key: "AVG", is_coach: false },
	{ tooltip: "Last 3 Average", key: "L3", is_coach: false },
	{ tooltip: "Last 5 Average", key: "L5", is_coach: false },
	{ tooltip: "Points Last Round", key: "LAST", is_coach: false, not_draft: true },
	{ tooltip: "$ per Point", key: "$/P", is_coach: false, not_draft: true },
	{ tooltip: "Total Points", key: "TP", is_coach: false },
	{ tooltip: "Project Score", key: "PROJ", is_coach: true },
	{ tooltip: "Break Even", key: "B.E.", is_coach: true, not_draft: true },
	{ tooltip: "Bye Round", key: "BYE", is_coach: true },
	{ tooltip: "Early Bye Round", key: "EBR", is_coach: true}
];

const trade_header_titles: THeader[] = [
	{ tooltip: "Points Last Round", key: "LAST", is_coach: false },
	{ tooltip: "Games Played", key: "GP", is_coach: false },
	{ tooltip: "Average Points", key: "AVG", is_coach: false },
	{ tooltip: "Last 3 Average", key: "L3", is_coach: false },
	{ tooltip: "Project Score", key: "PROJ.", is_coach: true },
	{ tooltip: "Break Even", key: "B.E.", is_coach: true },
	{ tooltip: "Bye Round", key: "BYE", is_coach: true },
	{ tooltip: "Early Bye Round", key: "EBR", is_coach: true}
];


type Props = {
	player_ids?: Array<number>,
	is_draft?: boolean,
	is_trades?: boolean,
	is_trades_pool?: boolean,
	score_stat?: Object,
	mobile_stat?: Object,
	edit_teams_view?: boolean,
	playerRowRef?: Object,
}

const isTradeView = (is_trades, is_trades_pool) => is_trades || is_trades_pool;

const renderMobileHeaders = (score_stat = {}, mobile_stat = {}, show_mobile, is_draft, index: number) => {
	if(!show_mobile){
		return null;
	}
	return <HeaderItem key={`${index}-header-item`} className="scrollable" only_show_mobile is_coach={mobile_stat.is_coach} is_draft={is_draft}>
		{mobile_stat.tooltip && <SHTooltip text={mobile_stat.tooltip} />}
		<div className="highlighter" />
		<span>{is_draft ? mobile_stat.key : mobile_stat.short_name}</span>
	</HeaderItem>;
};

export const ListViewHeader = ({ 
	player_ids, 
	is_draft, 
	is_trades, 
	is_trades_pool, 
	score_stat = {}, 
	mobile_stat = {},
	edit_teams_view,
	playerRowRef
}: Props) => {
	let last_column_label = "";
	let last_column_short = is_draft ? "Action" : "TRD";
	let titles = header_titles.filter(({ not_draft }) => isAnyTrue([!is_draft,!not_draft ]));

	if (is_trades) {
		last_column_label = "SWAP/TRADE";
		titles = trade_header_titles;
	}

	if (is_trades_pool) {
		last_column_label = "ADD";
		last_column_short = "ADD";
		titles = trade_header_titles;
	}
	if(edit_teams_view) {
		titles = [];
	}
	const is_trade_view = isTradeView(is_trades, is_trades_pool);
	const show_mobile = mobile_stat !== undefined;

	return (
		<ListViewHeaderWrapper 
			is_draft={is_draft} 
			is_trades={is_trade_view} 
			show_mobile={show_mobile}
		>
			<HeaderItem 
				show_mobile 
				className="lowerCase absolute">{ isAllTrue([isMobile(), Boolean(is_draft)]) ? "Defenders" : "Player"}</HeaderItem>
      

			<StatsHeaderItems is_trades={is_trade_view} className="scrollable-group" is_draft={is_draft} ref={playerRowRef}>
				<Exist when={Boolean(is_draft)}>
					<HeaderItem 
					  show_mobile 
					  className="lowerCase absolute-empty" 
          			/>
				</Exist>
				<Exist when={!is_trade_view }>
					<HeaderItem className='lowerCase scrollable' show_mobile is_draft={is_draft}>Next</HeaderItem> 
				</Exist>
				{titles
					.map(({ key, is_coach, tooltip }) => (
						<HeaderItem key={key} is_coach={is_coach}>
							<SHTooltip text={tooltip} />
							<div className="highlighter" />
							<span>{key}</span>
						</HeaderItem>
					))}

				{is_draft ? 
					titles.map((stat, index) => {
						return renderMobileHeaders(stat, stat, show_mobile, is_draft, index);
					}) :
					renderMobileHeaders(score_stat, mobile_stat, show_mobile, is_draft, 0)
				}
				
			</StatsHeaderItems>

			<HeaderItem className="last absolute-right" show_mobile>
				<span>{last_column_label}</span>
				<span>{last_column_short}</span>
			</HeaderItem>

		</ListViewHeaderWrapper>
	);
};

export default ListViewHeader;