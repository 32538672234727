// @flow
import * as React from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
// import _ from 'lodash';
import { connect } from "react-redux";
import { below } from "../../assets/css/media";
import * as actions from "../../modules/actions";
import colors from "../../assets/css/colors";
import type { TRootStore, TPlayer } from "../../modules/types";
import { ButtonAccent } from "../ButtonAccent";
import { CancelButton } from "../CancelButton";
import { ButtonPrimaryLink } from "../ButtonPrimary";
import ButtonAlert, { ButtonAlertLink } from "../ButtonAlert";
import { Exist } from "../Exist";
import { isAllTrue } from "../../helpers";
import { PlayerImgContainer } from "./PlayerImgContainer";
import { ModalInnerTitle } from "./ModalInnerTitle";
import { ModalContainer } from "./ModalContainer";

const ModalHeading = styled(ModalInnerTitle)`
	padding-top: 175px;
`;


const ButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	margin: 0 1em;
	padding: 2.5em 0;

	button,
	a {
		min-width: 160px;
		margin: 0 5px;

		${below.desktop`
			min-width: 85px;
		`}
	}
`;

const ModalCopy = styled.p`
	text-align: center;
	max-width: 600px;
	position: relative;
	font-family: 'SourceSansPro';
	color: ${colors.primary.darkGrey};
	font-size: 14px;
	line-height: 1.45;
	margin: 0 auto 20px;
`;

type Props = {
	closeClick: Function,
	player: TPlayer,
	action: 'delist' | 'trade',
	children: React.Node,
  is_my_team: boolean,
	fetchMakeTrade: typeof actions.teamsDraft.fetchMakeTrade,
	fetchPlayersOnOfferList: typeof actions.teamsDraft.fetchPlayersOnOfferList,
	removePlayer: Function,
	players_margin: number,
	match: {
		params: {
			league_id: string
		}
	},
	delist_link?: string,
	trade_link?: string,
	history: {
		push: Function
	}
};

const PlayerActionModalContainer = ({
	closeClick,
	player,
	children,
	action,
	match: { params: { league_id } },
	fetchMakeTrade,
	removePlayer,
	players_margin,
	delist_link = "",
	trade_link,
	history,
	fetchPlayersOnOfferList,
	is_my_team
}: Props) => {
	if(!player) {
		return null;
	}

	const player_name = `${player.first_name} ${player.last_name}`;
	const avatar_src = `${process.env.REACT_APP_PLAYERS_PATH + player.id}.webp`;
	const getTradeCopy = () => {
		if(!is_my_team){
			return(
				"By selecting trade this player you will be taken to the team to team trade page."
			); 
		}
	
		return (
			`By selecting trade this player you will be taken to the team to team trade page. 
        		Alternatively you can offer this player to your league.`
		); 
		
	};

	return (
		<ModalContainer onClick={closeClick}>
			<PlayerImgContainer>
				<img src={avatar_src} alt={player_name}/>
			</PlayerImgContainer>
			<ModalHeading>
				Are you sure you want to {action}{" "} {!is_my_team ? "for " : ""}{player_name}?
			</ModalHeading>
			<ModalCopy>
				{isAllTrue([action === "delist", players_margin === 0]) && ""}
				{isAllTrue([action === "delist", players_margin > 0]) &&
				`Because your team is in invalid state you can delist this player without selecting
					another. Alternatively you can offer this player to your league.`}
				{action === "trade" && getTradeCopy()}
			</ModalCopy>

			<ButtonWrapper>
				<CancelButton
					onClick={closeClick}
				>
					No, cancel
				</CancelButton>
				<Exist when={is_my_team}>
					<ButtonAccent
						onClick={() => {
							fetchMakeTrade({ league_id, old_player_id: [player.id] });
							fetchPlayersOnOfferList({ league_id });
							history.push(`/draft/league/${league_id}/transactions`);
						}}
					>
						Offer player to the league
					</ButtonAccent>
				</Exist>
				<Exist when={isAllTrue([action === "delist", players_margin > 0])}>
					<ButtonAlert onClick={() => {
						removePlayer({ id: player.id });
						closeClick();
					}}>
						Delist player
					</ButtonAlert>
				</Exist>
				
				<Exist when={isAllTrue([action === "delist", players_margin <= 0])}>
					<ButtonAlertLink to={delist_link}>
						Delist player
					</ButtonAlertLink>
				</Exist>
				<Exist when={action === "trade"}>
					<ButtonPrimaryLink to={trade_link}>
						Trade {!is_my_team && "for"}  player
					</ButtonPrimaryLink>
				</Exist>
			</ButtonWrapper>
		</ModalContainer>
	);
};

const mapStateToProps = (state: TRootStore) => ({});

const mapDispatchToProps = {
	fetchMakeTrade: actions.teamsDraft.fetchMakeTrade,
	fetchPlayersOnOfferList: actions.teamsDraft.fetchPlayersOnOfferList
};

export const PlayerActionModal = withRouter(connect(
	mapStateToProps, mapDispatchToProps
)(PlayerActionModalContainer));

export default PlayerActionModal;