// @flow
import styled, { css } from "styled-components";

export const FieldRow = styled.div`
	${({ is_draft }) => is_draft ?  css`
		min-height: 150px;
		margin-bottom: 10px;
		padding: 10px 50px 0 0;

		&:last-child {
			margin-bottom: 0;
		}
	` : css`height: ${({ height }) => height || "250px"}`};
	display: flex;
	position: relative;
	align-items: center;
`;

export default FieldRow;