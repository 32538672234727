// @flow
import * as React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { compose } from "redux";
import _ from "lodash";
import {
	Ad,
	AdsContainer,
	Footer,
	PageContentWrapper,
	PageTitle,
	TwoColumnLayout,
	WidgetNews,
	withDraftPlayerModal,
	LeagueGameBar,
	Tabs,
	TabItem
} from "../../../components";

import JsonFetcher from "../../../components/utils/JsonFetcher";

import type { TPLeague, TRootStore } from "../../../modules/types";
import * as selectors from "../../../modules/selectors";
import * as actions from "../../../modules/actions";
import withCoachesBox from "../../../components/utils/withCoachesBox";
import { below } from "../../../assets/css/media";
import DraftHistory from "./leagueHistory/DraftHistory";


const StyledTabs = styled(Tabs)`
	margin-top:10px;
	border-bottom: 2px solid #F0F3F7;
	justify-content: flex-start;
	${below.phone`
		justify-content: space-evenly;
	`}
`;
const StyledTab = styled(TabItem)`
	justify-content: center;
	width: 100px;
	${below.phone`
		width: 50%;
	`}

`;

type Props = {
	match: {
		params: {
			league_id: string,
		},
	},
	league: TPLeague,
	showDraftOrder: typeof actions.leagueDraft.showDraftOrder,
	showDraftLeague: typeof actions.leagueDraft.showDraftLeague,
	fetchTeams: typeof actions.leagueDraft.showDraftOrder,
	fetchCoachPlayers: typeof actions.fetchCoachPlayers,
	fetchWaiverList: typeof actions.leagueDraft.fetchWaiverList,
	fetchWaiverFree: typeof actions.leagueDraft.fetchWaiverFree,
	fetchFavourites: typeof actions.fetchFavourites,
	has_assistant_coach: boolean,
}

type State = {
	active_tab: string
}

class LeagueHistoryPage extends React.Component<Props, State> {

	constructor(props, state) {
		super(props, state);

		_.bindAll(this, [
			"setActiveTab"
		]);
	}

	state = {
		active_tab: "draft"
	};

	componentDidMount() {
		const {
			showDraftLeague,
			showDraftOrder,
			fetchTeams,
			fetchCoachPlayers,
			fetchWaiverList,
			fetchWaiverFree,
			fetchFavourites,
			match: { params: { league_id } },
			has_assistant_coach,
		} = this.props;

		showDraftLeague({ id: league_id });
		showDraftOrder({ league_id });
		fetchTeams({ league_id,	details: 1 });
		fetchWaiverList({ league_id });
		fetchWaiverFree({ league_id });
		fetchFavourites();

		if(has_assistant_coach) {
			fetchCoachPlayers();
		}
	}

	setActiveTab({ currentTarget }: Object) {
		const { tab } = currentTarget.dataset;
		this.setState({
			active_tab: tab
		});
	}
	
	renderTabs() {
		const { league } = this.props;
		const { active_tab } = this.state;
		const is_keeper = _.get(league, "keeper", 0) === 1;
		const is_regen = _.get(league, "regenerated_from", 0) !== 0;

		if(!is_keeper && !is_regen){
			return null;
		}
		return <StyledTabs>
			<StyledTab
				onClick={this.setActiveTab}
				data-tab="draft"
				is_keeper={is_keeper}
				className={active_tab === "draft" ? "active-tab" : ""}>
				Draft
			</StyledTab>
			{is_keeper &&
					<StyledTab
						onClick={this.setActiveTab}
						data-tab="keepers"
						is_keeper={is_keeper}
						className={active_tab === "keepers" ? "active-tab" : ""}>
						Keepers
					</StyledTab>
			}
			
		</StyledTabs>;
	}

	render() {
		const { league } = this.props;
		const { active_tab } = this.state;
		const show_keepers = active_tab === "keepers";

		return (
			<React.Fragment>
				<JsonFetcher fetch={["players", "rounds", "squads", "venues"]} />
				<LeagueGameBar no_fetch={true} />
				<AdsContainer>
					<Ad />
				</AdsContainer>
				<PageContentWrapper>
					<PageTitle>
						Draft History for {_.get(league, "name", "")}
					</PageTitle>
					{this.renderTabs()}
					<TwoColumnLayout>
						<DraftHistory show_keepers={show_keepers} />
						<aside>
							<WidgetNews />
						</aside>
					</TwoColumnLayout>
				</PageContentWrapper>
				<Footer show_key />
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: TRootStore, props: Props) => ({
	league: selectors.leagueDraft.getLeague(state, props),
	has_assistant_coach: Boolean(selectors.getUser(state).assistant_coach),
});

const mapDispatchToProps = {
	showDraftOrder: actions.leagueDraft.showDraftOrder,
	showDraftLeague: actions.leagueDraft.showDraftLeague,
	fetchTeams: actions.leagueDraft.showDraftOrder,
	fetchCoachPlayers: actions.fetchCoachPlayers,
	fetchWaiverList: actions.leagueDraft.fetchWaiverList,
	fetchWaiverFree: actions.leagueDraft.fetchWaiverFree,
	fetchFavourites: actions.fetchFavourites,
};

const LeagueHistory = compose(
	connect(
		mapStateToProps,
		mapDispatchToProps
	),
	withDraftPlayerModal,
	withCoachesBox
)(LeagueHistoryPage);

export default LeagueHistory;