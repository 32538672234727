// @flow
import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import Arrow from "../Icons/Arrow";
import colors from "../../assets/css/colors";
import { below } from "../../assets/css/media";

export const Button = styled(Link)`
	width: auto;
	background: ${colors.primary.primary};
	height: 20px;
	display: flex;
	text-align: center;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	font-size: 10px;
	line-height: 1;
	color: #FFFFFF;
	outline: none;
	border: none;
	cursor: pointer;
	border-radius: 1px;
	text-decoration: none;
	padding: 2px 12px 0;
	min-width: 10em;
	white-space: nowrap;
	font-family: SourceSansPro;
	font-weight: 600;
	transition: background-color 0.2s linear;

	:hover {
		background-color: #d3dade;
	}

	svg {
		transform: translateY(-0.1em);
		font-size: 1.2em;
		margin-left: .25em;
	}

	${props => props.type === "live" && `
		background: ${colors.primary.accentGreen};
		color: #fff;

		:hover {
			background-color: #54b135;
		}
	`}
	${props => props.type === "full_time" && `
		background: ${colors.buttons.cancel};
		color: #fff;

		:hover {
			background-color: #df3a3a;
		}
	`};

	&.is-game-bar {
		min-width: auto;
		padding: 0px 6px 0 6px;

		${below.tablet`
			height: 20px;
			font-size: 10px;
		`}
	}
`;

type Props = {
	children: Object,
	type?: 'full_time' | 'live' | '',
	no_arrow?: boolean,
	is_game_bar?: boolean,
	to?: string,
	no_arrow?: boolean,
	is_fixture?: boolean
}

export const ButtonMatchUp = ({
	children,
	no_arrow = true,
	is_game_bar = false,
	to = "#"
}: Props) => (
	<Button className={is_game_bar ? "is-game-bar" : ""} to={to}>
		{children}
		{!no_arrow && <Arrow direction="right" />}
	</Button>
);


export default ButtonMatchUp;