// @flow
import { get } from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { below } from "../../../assets/css/media";
import { TeamUserAvatarStyled,Exist } from "../../../components";
import {
	getAFliDUserClassicLeagueHistory,
	getAFliDUserDraftLeagueHistory,
	getNewAccountInfo,
} from "./../../../modules/selectors";

const DesktopOnly = styled.div`
	${below.tablet`
		display:none;
	`};
`;

const MobileOnly = styled.div`
	display:none;
	${below.tablet`
		display:block;
	`};
`;

const Wrap = styled.div`
    color: #1C4072;
    margin: 20px 0;
	font-family: SourceSansPro;
    font-weight: 400;
	width: 100%;
    div {
    	font-family: SourceSansPro;
    }
`;



const MainLabel = styled.div`
    font-size: 22px;
    font-weight: 700;
    margin: 0 0 20px 0;
`;

const ContentWrap = styled.div`
    background-color: #F3F3F3;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
`;

const TopWrap = styled.div`
    display:flex;
    margin: 0 0 20px 0;

	${below.tablet`
		margin: 0;
	`};
`;

const LogoWrap = styled.div`
	height: 50px;
	width: 50px;
    border-radius: 50%;
    background-color: #fff;
	> div {
		padding: 5px;
	}
`;

const NameDetailsWrap = styled.div`
    font-size: 18px;
    display:flex;
    flex-direction: column;
    text-align: left;
    margin-left: 10px;
`;
const TeamNameWrap = styled.div`
    margin: 5px 0;
`;
const TeamNameLabel = styled.div`
	font-size: 14px;
`;
const TeamName = styled.div`
    font-size: 18px;
    font-weight: 700;
	margin: 5px 0
`;

const TeamEmail = styled.div`
	font-size: 14px;
	font-weight: 700;
`;
const TeamEmailMobile = styled(TeamEmail)`
	margin-bottom: 10px;
	text-overflow: ellipsis;
	overflow: hidden;
	width: 100%;
	white-space: nowrap;
`;

const TableWrap = styled.div`
    margin: 0 0 20px 0;
`;

const Table = styled.table`
    text-align: left;
    width: 100%;
    th, td {
        font-size: 14px;
        height: 30px;
        line-height: 30px;
        padding: 0 10px;
        width: 50%;
    }
    th {
        background: linear-gradient(180deg, #001437 0%, #010C2A 100%);
        color: #fff;
    }
    td {
        background-color: #fff;
        border: 1px solid #eee;
        font-weight: 700;
    }
`;

type TProps = {
	isUFWidget?: boolean
}


export const AccountHistoryWidget = ({isUFWidget}: TProps) => {
	const {user} = useSelector(getNewAccountInfo);
	const userID = get(user,"id",0);
	const classicHistory = useSelector(getAFliDUserClassicLeagueHistory);
	const draftHistory = useSelector(getAFliDUserDraftLeagueHistory);
	
	const overall_ranks = get(classicHistory, 'overall_ranks', {});
	const previous_classic_leagues = get(classicHistory, 'previous_classic_leagues');

	const previous_draft_leagues = get(draftHistory, 'previous_draft_leagues');

	const hasRanks = Boolean(Object.keys(overall_ranks).length);
	const historyRowsToShow = 5;
	const last5Leagues = Array.from(Array(historyRowsToShow).keys());
	
	
	return (
		<Wrap>
			<MainLabel>Is This Your Account?</MainLabel>
			<ContentWrap>
				<TopWrap>
					<LogoWrap>
						<TeamUserAvatarStyled
							user_id={userID}
							firstname={user.firstname} 
							lastname={user.lastname} 
							team
							avatar_version={user.avatar_version}
						/>
					</LogoWrap>
					<NameDetailsWrap>
						<TeamNameWrap>
							<TeamNameLabel>Team Name:</TeamNameLabel>
							<TeamName>{user.team_name || user.teamname}</TeamName>
						</TeamNameWrap>
						<DesktopOnly>
							<TeamEmail>{user.email}</TeamEmail>
						</DesktopOnly>
					</NameDetailsWrap>
				</TopWrap>
				<MobileOnly>
					<TeamEmailMobile>{user.email}</TeamEmailMobile>
				</MobileOnly>
				<Exist when={hasRanks}>
					<TableWrap>
						<Table>
							<thead>
								<tr>
									<th>Year</th>
									<th>Overall Rank</th>
								</tr>
							</thead>
							<tbody>
								{Boolean(overall_ranks) && Object.keys(overall_ranks)
									.map(year => <tr key={year}>
										<td>{year}</td>
										<td>{overall_ranks[year].toLocaleString()}</td>
									</tr>
									)}
							</tbody>
						</Table>
					</TableWrap>
					<TableWrap>
						<Table>
							<thead>
								<tr>
									<th>Classic</th>
									<th>Draft</th>
								</tr>
							</thead>
							<tbody>
								{last5Leagues.map(item => <tr key={item}>
									<td>{get(previous_classic_leagues, item, "")}</td>
									<td>{get(previous_draft_leagues, item, "")}</td>
								</tr>)}
							</tbody>
						</Table>
					</TableWrap>
				</Exist>
			</ContentWrap>
		</Wrap>
	);
	
};