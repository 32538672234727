// @flow
import * as React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import styled from "styled-components";
import moment from "moment";
import { Redirect } from "react-router-dom";

import * as selectors from "../../../modules/selectors";
import * as actions from "../../../modules/actions";

import {
	AdsContainer,
	Ad,
} from "../../../components";

import OneColumnLayout from "../../../components/OneColumnLayout";
import PageTitle from "../../../components/PageTitle";
import JsonFetcher from "../../../components/utils/JsonFetcher";
import type { TPLeague, TRound, TPlayersById} from "../../../modules/types";

import { above } from "../../../assets/css/media";
import ShowLeagueRow from "./myLeagues/ShowLeagueRow";
import LeagueSettingsPopup from "./myLeagues/LeagueSettingsPopup";
import RegenerateLeagues from "./leaguesCreate/RegenerateLeagues";

const StyledPageTitle = styled(PageTitle)`
	margin-bottom: 16px;
`;

const InnerSpacing = styled.div`
	margin-top: 10px;
	margin-bottom: 50px;

	${above.phone`
		margin-top: 30px;
	`}
`;

type League = Object;

type Props = {
	myList: typeof actions.myListClassicLeagues,
	my_leagues: Array<League>,
	live_leagues: Array<League>,
	has_live_leagues: boolean,
	future_leagues: Array<League>,
	has_future_leagues: boolean,
	unscheduled_leagues: Array<League>,
	has_unscheduled_leagues: boolean,
	user: {
		id: number,
		avatar_version: number,
		firstname: string,
		lastname: string
	},
	actual_round: TRound,
	rounds_with_matches: Array<Object>,
	players_by_id: TPlayersById,
	has_assistant_coach: boolean,
	single_league: TPLeague
};

type State = {
	is_show_settings_popup: boolean,
	league_settings: Object,
};

class LeaguesPage extends React.Component<Props, State> {
	state = {
		is_show_settings_popup: false,
		league_settings: {},
	};

	componentDidMount() {
		const { myList } = this.props;
		myList();
	}

	renderLiveLeagues = () => {
		const {
			live_leagues,
			has_live_leagues,
			user,
			actual_round,
			rounds_with_matches,
			players_by_id,
			has_assistant_coach
		} = this.props;

		return (
			<React.Fragment>
				<StyledPageTitle>
					League hub
				</StyledPageTitle>
				{has_live_leagues && <JsonFetcher fetch={["rounds", "coach_players"]} />}
				{live_leagues.map(league => {
					const is_commissioner = league.commissioner === user.id;

					const start_round = league.start_round;
					const round = start_round > actual_round.id ?
						_.find(rounds_with_matches, { id: start_round }, actual_round) :
						actual_round
					;

					return (
						<ShowLeagueRow
							key={league.id}
							league={league}
							is_commissioner={is_commissioner}
							league_type='live'
							round={round}
							players_by_id={players_by_id}
							has_assistant_coach={has_assistant_coach}
							user={user}
							handleLeagueSettingsPopup={this.handleLeagueSettingsPopup.bind(this)}
						/>
					);
				})}
			</React.Fragment>
		);
	};

	handleLeagueSettingsPopup({ id }: Object) {
		const { my_leagues } = this.props,
			league_settings = _.find(my_leagues, { id });

		this.setState({
			is_show_settings_popup: true,
			league_settings
		});
	}

	handleLeagueSettingsPopupHide() {
		this.setState({
			is_show_settings_popup: false,
			league_settings: {}
		});
	}

	renderFutureLeagues = () => {
		const {
			future_leagues,
			user,
			actual_round,
			rounds_with_matches,
			players_by_id,
			has_assistant_coach
		} = this.props;

		return (
			<React.Fragment>
				<StyledPageTitle>
					Yet to start
				</StyledPageTitle>
				{future_leagues.map(league => {
					const is_commissioner = league.commissioner === user.id;
					const start_round = league.start_round;
					const round = start_round > actual_round.id ?
						_.find(rounds_with_matches, { id: start_round }, actual_round) :
						actual_round
					;

					return (
						<ShowLeagueRow
							key={league.id}
							league={league}
							is_commissioner={is_commissioner}
							league_type='future'
							round={round}
							players_by_id={players_by_id}
							has_assistant_coach={has_assistant_coach}
							user={user}
							handleLeagueSettingsPopup={this.handleLeagueSettingsPopup.bind(this)}
						/>
					);
				})}
			</React.Fragment>
		);
	};

	renderUnscheduledLeagues = () => {
		const {
			unscheduled_leagues,
			user,
			actual_round,
			rounds_with_matches,
			players_by_id,
			has_assistant_coach
		} = this.props;

		return (
			<React.Fragment>
				<StyledPageTitle>
					Your Unscheduled Leagues
				</StyledPageTitle>
				{unscheduled_leagues.map(league => {
					const is_commissioner = league.commissioner === user.id;
					const start_round = league.start_round;
					const round = start_round > actual_round.id ?
						_.find(rounds_with_matches, { id: start_round }, actual_round) :
						actual_round
					;

					return (
						<ShowLeagueRow
							key={league.id}
							league={league}
							is_commissioner={is_commissioner}
							league_type='unscheduled'
							round={round}
							players_by_id={players_by_id}
							has_assistant_coach={has_assistant_coach}
							user={user}
							handleLeagueSettingsPopup={this.handleLeagueSettingsPopup.bind(this)}
						/>
					);
				})}
			</React.Fragment>
		);
	};

	render() {
		const {
			has_live_leagues,
			has_future_leagues,
			has_unscheduled_leagues,
			single_league,
		} = this.props;

		const {
			is_show_settings_popup,
			league_settings
		} = this.state;

		if (single_league) {
			return <Redirect to={`/classic/league/${single_league.id}`} />;
		}

		return (
			<React.Fragment>
				{is_show_settings_popup ? (
					<LeagueSettingsPopup
						onClick={this.handleLeagueSettingsPopupHide.bind(this)}
						league={league_settings}
					/>
				) : null}
				<AdsContainer>
					<Ad/>
				</AdsContainer>
				<OneColumnLayout>
					<InnerSpacing>
						{has_live_leagues ? this.renderLiveLeagues() : null}
						{has_future_leagues ? this.renderFutureLeagues() : null}
						{has_unscheduled_leagues ? this.renderUnscheduledLeagues() : null}
						<RegenerateLeagues />
					</InnerSpacing>
				</OneColumnLayout>
			</React.Fragment>
		);
	}
}

type TLeague = Object;

const mapStateToProps = state => {
	const my_leagues = state.leaguesClassic.my_list.ordered_ids.map(
		league_id => state.leaguesClassic.my_list.by_id[league_id]
	);

	const live_leagues = my_leagues.filter((league: TLeague) => league.status === "playing");
	const future_leagues = my_leagues.filter((league: TLeague) =>
		league.status === "scheduled" && moment().isBefore(league.start_at));
	const unscheduled_leagues = my_leagues.filter((league: TLeague) =>
		league.status === "scheduled" && moment().isBefore(league.start_at) === false);

	const single_league = my_leagues.length === 1 ? my_leagues[0] : null;

	return {
		user: state.user.data,
		user_id: state.user.data.id,
		user_avatar_version: state.user.data.avatar_version,
		my_leagues,
		live_leagues,
		has_live_leagues: _.isEmpty(live_leagues) === false,
		future_leagues,
		has_future_leagues: _.isEmpty(future_leagues) === false,
		unscheduled_leagues,
		has_unscheduled_leagues: _.isEmpty(unscheduled_leagues) === false,
		actual_round: selectors.rounds.getActualRound(state),
		rounds_with_matches: selectors.rounds.getRoundsWithMatches(state),
		players_by_id: selectors.players.getExtendedPlayersById(state),
		single_league,
		has_assistant_coach: Boolean(selectors.getUser(state).assistant_coach),
	};
};

const mapDispatchToProps = {
	myList: actions.myListClassicLeagues,
};

export const MyClassicLeagues = connect(
	mapStateToProps,
	mapDispatchToProps
)(LeaguesPage);

export default MyClassicLeagues;