// @flow
import * as React from "react";
import styled from "styled-components";
import _ from "lodash";
import { Link } from "react-router-dom";
import AddPlayer from "../../../components/Icons/AddPlayer";
import Swap from "../../Icons/Swap";
import { below } from "../../../assets/css/media";
import { onDragEnter, onDragLeave, onDragOver } from "../../../utils/drag_and_drop";
import { SwapCommandButton } from "./listViewPlayer";

const ListViewPlayerWrapper = styled.div`
	height: ${({ edit_teams_view }) => edit_teams_view ? "30px;" : "60px;"}
	box-sizing: border-box;
	border: 1px dashed #CAD2D8;
	display: flex;
	align-items: center;
	justify-content: space-around;
	background-color: #F8F8FA;
	cursor: pointer;
	padding: 0 5px;
	${below.desktop`
		padding: 0 10px;
	`};
`;

const AddPlayerArea = styled.div`
	display: ${({ is_hidden }) => is_hidden ? "none" : "flex"};
	align-items: center;
	justify-content: center;
	color: #CAD2D8;
`;

const AddPlayerAreaButtonWrap = styled.div`
	display: flex;
    justify-content: flex-end;
    width: 100%;
`;
const AddPlayerAreaButtonLink = styled(Link)`
	color: #CAD2D8;
`;

const SwapCommandButtonStyled = styled(SwapCommandButton)`
	width: 24px;
	height: 24px;
	border-radius: 50%;
	background: #fff;
	svg{
		width: 24px;
		height: 24px;
	}
`;


type Props = {
	position_id: number,
	position_full_name: string,
	onClick?: Function,
	is_bench?: boolean,
	position_index?: number,
	is_substitute?: boolean,
	out_substitute?: boolean,
	in_substitute?: boolean,
	leagueID?: number,
	substitutePlayer?: Function,
	pureSubstitutePlayer?: Function,
	index?: number,
	is_utility?: boolean,
	is_team_full?: boolean,
	is_classic?: boolean,
	edit_teams_view?: boolean,
	openAddPlayerModal?: Function,
	closeAddPlayerModal?: Function
};

export const ListViewPlayerEmpty = ({
	leagueID,
	position_id,
	position_index,
	position_full_name,
	is_bench,
	onClick,
	is_substitute,
	in_substitute,
	substitutePlayer,
	pureSubstitutePlayer = _.noop,
	index,
	is_utility,
	is_team_full,
	is_classic,
	edit_teams_view,
	openAddPlayerModal
}: Props) => {
	const onDropPlayer = () => pureSubstitutePlayer({
		position: position_id,
		index,
		is_bench,
		is_utility,
	});
	const renderAddPlayer = () => {
		if(is_classic){
			return(
				<AddPlayerArea is_hidden={is_substitute && in_substitute }>
					<AddPlayerAreaButtonWrap onClick={onClick}>
						<AddPlayer size={2} />
					</AddPlayerAreaButtonWrap>
				</AddPlayerArea>
			);
		}
		if(edit_teams_view){
			return(
				<AddPlayerArea is_hidden={is_substitute && in_substitute }>
					<AddPlayerAreaButtonWrap onClick={() => {
						if(openAddPlayerModal){
							openAddPlayerModal(0, Number(position_id));
						}
					}}>
						<AddPlayer size={2} />
					</AddPlayerAreaButtonWrap>
				</AddPlayerArea>
			);
		}
		return (
			(leagueID &&
			<AddPlayerArea is_hidden={is_substitute && in_substitute }>
				<AddPlayerAreaButtonWrap>
					<AddPlayerAreaButtonLink to={`/draft/league/${leagueID}/players`}>
						<AddPlayer size={2} />
					</AddPlayerAreaButtonLink>
				</AddPlayerAreaButtonWrap>
			</AddPlayerArea>)
		);
	};
	if(in_substitute || is_team_full){
		return (
			<ListViewPlayerWrapper
				onClick={onClick}
				onDrop={onDropPlayer}
				onDragOver={onDragOver}
				onDragEnter={onDragEnter}
				onDragLeave={onDragLeave}
				edit_teams_view={edit_teams_view}
			>
				<SwapCommandButtonStyled
					background="none"
					onClick={event => {
						substitutePlayer && substitutePlayer(event);
					}}
					data-id={0}
					data-position={position_id}
					data-position_index={position_index}
					data-index={index}
					data-is_bench={is_bench}
					data-is_utility={Boolean(is_utility)}
				>
					<Swap size={3}/>
				</SwapCommandButtonStyled>
				
				
			</ListViewPlayerWrapper>
		);
	}
	return(
		<ListViewPlayerWrapper edit_teams_view={edit_teams_view}>
			{renderAddPlayer()}
		</ListViewPlayerWrapper>
	);
	
};

ListViewPlayerEmpty.defaultProps = {
	is_mobile: false
};

export default ListViewPlayerEmpty;