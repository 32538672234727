// @flow
import styled from "styled-components";

export const WidgetHeaderTitle = styled.div`
	display: flex;
	align-items: center;
	
	.tooltip-icon {
		background: none;
	}
`;

export default WidgetHeaderTitle;