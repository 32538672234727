// @flow
import type { Match, RouterHistory } from "react-router-dom";
import { withRouter } from "react-router-dom";

import * as React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import styled from "styled-components";
import {
	BarItem,
	CountdownTimer,
	DraftTeamListOrderIndicator,
	DraftTimerWrapper,
	Exist,
	TopBarWrapper,
} from "../../../../components";
import type { TPLeague, TUser } from "../../../../modules/types";
import * as actions from "../../../../modules/actions";
import * as selectors from "../../../../modules/selectors";
import Clock from "../../../../components/Icons/Clock";
import LeagueLateStart from "../../../../components/LeagueGameBar/yourTeamDetails/LeagueLateStart";
import Close from "../../../../components/Icons/Close";
import { below } from "../../../../assets/css/media";
import {  isAllTrue } from "../../../../helpers";
import { IS_DISABLE_LIVE_DRAFT } from "../../../../modules/constants";

const KeeperLeagueBanner = styled.div`
	height: 40px;
	width: 100%;
	position: relative;
	top: 0;
	background: #D92425;
	font-family: SourceSansPro;
	font-weight: 400;
	font-size: 14px;
	color: white;
	text-align: center;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	${below.phone`
		height: fit-content;
		padding: 10px 5px 0 5px;
		line-height: 20px;
		flex-direction: column;
		z-index: 100;
		position: sticky;
		top: 50px;
		justify-content: flex-start;
	`}
`;

const CloseButton = styled.button`
	position: absolute;
	right: 10px;
	height: 32px;
	width: 32px;
	svg{
		height: 30px;
		width: 30px;
		fill: white;
	}
	padding: 0;
	background: none;
	border: none;
	${below.phone`
		right: unset;
		position: relative;
	`}
`;

const StyledDraftTimerWrapper = styled(DraftTimerWrapper)`
	height: 40px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const StyledTopBarWrapper = styled(TopBarWrapper)`
	${below.phone`
		height: 40px;
	`}
`;

type Props = {
	missed_draft_start: boolean,
	user: TUser,
	league: TPLeague,
	match: {
		...Match,
		params: {
			league_id: number
		}
	},
	history: RouterHistory,
	league_loading: boolean,
	fetchLeague: Function,
	showConfirmModal: Function,
	hideConfirmModal: Function,
	predraft_mob: boolean,
};

type State = {
	completed: boolean,
	cool_off: boolean,
	keeper_local_show: boolean,
}

class TopBarComponent extends React.Component<Props, State> {
	constructor(props) {
		super(props);

		_.bindAll(this, "onTimerComplete", "fetchLeague", "handleCloseBanner");
	}

	state = {
		completed: false,
		cool_off: false,
		keeper_local_show: true,
	};

	componentDidUpdate() {
		const { league_loading } = this.props;
		const { completed, cool_off } = this.state;

		/* Only try to fetch league once: 
		 *     timer finished, 
		 *     the league isn't already being fetched, and
		 *     the timer isn't cooling off
		 */
		if (!league_loading && !cool_off && completed) {
			this.fetchLeague();
		}
	}

	componentWillUnmount() {
		if (this.scheduled) {
			clearTimeout(this.scheduled);
		}
	}

	scheduled: TimeoutID;

	onTimerComplete() {
		if(!IS_DISABLE_LIVE_DRAFT){
			this.setState({ completed: true });
		}
		
		/**
		 * Added window.location instead of history.push because sometimes
		 * page doesn't want reload, probably due to some memory leak in timer component.
		 * So this is brut force solution.
		 */
		// window.location = `/draft/league/${league_id}/live-draft`;
	}

	fetchLeague() {
		const { match: { params: { league_id } }, fetchLeague } = this.props;
		fetchLeague({ id: league_id });

		/* Cool-off for 5s after fetching league */
		this.setState({ cool_off: true }, () => this.scheduled = setTimeout(() => {
			this.setState({ cool_off: false });
		}, 5000));
		
	}

	handleCloseBanner(){
		localStorage.setItem("keeper_banner", "false");
		this.setState({
			keeper_local_show: false
		});
	}

	renderMissedStart() {
		const { 
			league, 
			user,
			showConfirmModal,
			hideConfirmModal,
		} = this.props;
		const league_id = _.get(league, "id");
		const user_id = _.get(user, "id");
		const commish = _.get(league, "commissioner");
		const is_commissioner = commish && commish === user_id;

		return <LeagueLateStart 
			league_id={league_id}
			is_draft
			is_commissioner={is_commissioner}
			dashed
			showConfirmModal={showConfirmModal}
			hideConfirmModal={hideConfirmModal}
		/>;
	}

	render() {
		const { league, missed_draft_start, predraft_mob } = this.props;
		const { completed, keeper_local_show } = this.state;
		const shift = 5 * 1000;
		const draft_start_milliseconds = +(new Date(league.draft_start));
		const draft_start_date = new Date(draft_start_milliseconds + shift);
		/* commented this out for reference.
		* note league.uf_regenerated_from return null
		* const isRegenerated = getIsRegenerated(league.regenerated,league.uf_regenerated_from);
		*
		*/
		const isRegenerated = league.regenerated  || league.uf_regenerated_from;
		const is_keeper = isAllTrue([
			league.keeper,
			isRegenerated
		]);
		
		const keeper_num = _.get(league, "keeper_num");
		const is_keeper_show =	isAllTrue([
			localStorage.getItem("keeper_banner") !== "false",
			is_keeper,
			keeper_local_show
		]);
		if(isAllTrue([
			IS_DISABLE_LIVE_DRAFT,
			league.draft_status === "scheduled"
		])){
			return null;
		}
		
		if(predraft_mob){
			return is_keeper_show ?	<KeeperLeagueBanner>
			If you select no keepers before your deadline,
			you will automatically keep the {keeper_num} {" "} 
			highest average score players of last season
				<CloseButton onClick={this.handleCloseBanner}>
					<Close />
				</CloseButton>
			</KeeperLeagueBanner> : <StyledTopBarWrapper is_your_pick={false}>
				<StyledDraftTimerWrapper is_your_pick={false}>
					
					<Clock size={1.35} onClick={this.fetchLeague} />
					<p>Live Draft starts in:</p>
					<CountdownTimer
						className="draft-timer"
						date={draft_start_date.toString()}
						onComplete={this.onTimerComplete}
						showOnComplete={true}
						small
					/>
				</StyledDraftTimerWrapper>

			</StyledTopBarWrapper>;
		}

		return (
			<React.Fragment>
				<TopBarWrapper is_your_pick={false}>
					<BarItem>
						
						<DraftTimerWrapper is_your_pick={false}>
							{completed 
								? <p>Live Draft Loading...</p>
								: <React.Fragment><Clock size={1.35} onClick={this.fetchLeague} />
									<p>Live Draft starts in:</p>
									<CountdownTimer
										className="draft-timer"
										date={draft_start_date.toString()}
										onComplete={this.onTimerComplete}
										showOnComplete={true}
										small
									/>
								</React.Fragment>
							}
			
								
						</DraftTimerWrapper>
						
						
						{missed_draft_start
							? this.renderMissedStart()
							: <DraftTeamListOrderIndicator league={league} pre_draft={true}/>
						}
					</BarItem>
				</TopBarWrapper>
				<Exist when={is_keeper_show}>
					<KeeperLeagueBanner>
						If you select no keepers before your deadline,
						you will automatically keep the {keeper_num} {" "} 
						highest average score players of last season
						<CloseButton onClick={this.handleCloseBanner}>
							<Close />
						</CloseButton>
					</KeeperLeagueBanner>

				</Exist>
			</React.Fragment>
		
		);
	}
}

const mapStateToProps = (state, props) => ({
	user: selectors.getUser(state),
	league: selectors.leagueDraft.getLeague(state, props),
	league_loading: state.leagues.show.is_pending
});

const mapStateToDispatch = {
	fetchLeague: actions.leagueDraft.showDraftLeague,
};

export const TopBar = withRouter(
	connect(mapStateToProps, mapStateToDispatch)(TopBarComponent)
);

export default TopBar;