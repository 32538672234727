// @flow
import Color from "color";

const primary = "#1D4073";
const white = "#ffffff"; // In case it's changed site-wide to an off-white
const black = "#000000"; // In case it's changed site-wide to an off-black
const cancel = "#D92425";
const baseHover = "#001f4a";
const accent = "#198DE1";
const coach = "#B89344";
const coach_accent = "#D7C08E";

const getButtonColors = (
	primary: string, 
	secondary: string, 
	hover_border: string = primary, 
	border: string = hover_border
) => ({
	main: {
		background: secondary,
		color: primary,
		border
	},
	hover: {
		background: primary,
		color: secondary,
		border: hover_border
	}
});

export const lightenColor = (color: string, amount: number = 0.95) => Color(color)
	.mix(Color("white"), amount)
	.hex();

export default {
	coach,
	fc: {
		primary: coach,
		accent: coach_accent,
		light: "#F7F2E8"
	},
	title: "#1d4072",
	black,
	white,
	primary: {
		accent,
		primary,
		dark: "#01285E",
		darkGrey: "#262424",
		accentGreen: "#4EA431",
		light: "#F9FAFC"
	},
	secondary: {
		accentGrey: "#7F8A90",
		ownerGrey: "#faebd7",
		paleGrey: "#CAD2D8",
		lightGrey: "#F0F3F7",
	},
	form: {
		base: "#f8f8fa",
		borderColor: "#CAD2D8",
		textColor: "#7f8a8f",
		textDarkColor: "#4a4a4a",
		arrow: "#224375",
		advanced_color: "#f0f4f8",
		disabled: "#778288",
		coachBase: "#F7F2E8",
		completedField:"#f0f3f7"
	},
	buttons: {
		baseColor: "#1d4072",
		baseHover,
		loadMore: "#cad2d9",
		loadMoreText: "#848f95",
		cancel,
		accentHover: "#1474b8",
		primary_button: {
			main: {
				background: primary,
				color: white,
				border: primary
			},
			hover: {
				background: baseHover,
				color: white,
				border: baseHover
			}
		},
		secondary_button: getButtonColors(primary, white),
		cancel_button: getButtonColors(cancel, white),
		alert_button: getButtonColors(white, cancel, cancel),
		save_button: getButtonColors(accent, white),
		accent: getButtonColors(white, accent, accent),
		coach: {
			main: {
				background: coach,
				color: white,
				border: coach
			},
			hover: {
				background: "#c4a562",
				color: white,
				border: "#c4a562"
			}
		}
	},
	table: {
		base: "#f8f8fa",
	},
	player: {
		captain: "#F5A623",
		vice_captain: accent,
		emergency: "#B55DAE",
		available: "#7ED321",
		live: "#7ED321",
		not_selected: cancel,
		injured: "#8F8F8F",
		medical_sub: "#FF7DC7",
		uncertain: "#EF791D",
		bye: "#45BFA3"
	},
	player_background: {
		captain: "#FCF9F3",
		vice_captain: "#EAF3F9",
		emergency: "#FCF7FB",
		odd_row: "#F8F8FA",
		// available: '#7ED321',
		// not_selected: '#D92425',
		// injured: '#8F8F8F',
		// uncertain: '#EF791D',
		// bye: '#45BFA3'
	},
	demo: "#FF00EB",
	warning: cancel,
	success: "#4EA431",
	facebook: "#3C5998",
	errorColor: "red",
	favourite_background: "#5B028E",
	favourite_forground: "#F8E71C",
	game_bar_score: "#B89344"
};