
// @flow
import * as React from "react";
import styled from "styled-components";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import type { TPlayerStatusesReducer, TPosition, TSquad, TPLeague } from "../../modules/types";

import colors from "../../assets/css/colors";
import Search from "../../components/Icons/Search";
import SortDown from "../../components/Icons/SortDown";
import SortUp from "../../components/Icons/SortUp";
import More from "../../components/Icons/More";
import Close from "../../components/Icons/Close";
import Clock from "../../components/Icons/Clock";
import { Select } from "../Form";
import {
	SearchField,
	SearchInputWrapper,
	SearchSubmitButton,
	CountdownTimer
} from "../../components";
import { FormCircleCheckbox } from "../Form/FormCircleCheckbox";
import { above, below } from "../../assets/css/media";
import CommandButton from "../../../src/components/MyTeam/ListView/commandButton.js";
import { isMobile } from "../../utils";
import { Exist } from "../Exist";
import { 
	getIsRegenerated, 
	getKeeperDeadlinePassed, 
	threeDaysPriorKeeperDeadline 
} from "../../helpers";

const DraftFiltersForm = styled.form`
	margin: 1em 0 20px;

	${below.desktop`
		padding: 0 5px;
	`};
`;

const SearchCountdownWrap = styled.div`
	display: flex;
	align-items: center;
	${below.phone`
		input{
			width: 170px;
		}
	`};
`;

const CountdownWrapper = styled.div`
	height: 38px;
	min-width: 120px;
	max-width: unset !important;
	display: flex;
	align-items: center;
	color: ${colors.primary.primary};
	background: #f0f3f7;
	padding: 1px 12px;
	margin-top: 4px;
	svg{
		margin-right: 5px;
		height: 20px;
		width: 20px;
		margin-top: 5px;
	}
	p{
		margin-top: 5px;
		font-family: SourceSansPro;
		font-weight: 600;
		font-size: 14px;
	}
	.timer-unit{
		font-size: 18px;
	}
	.timer-label{
		font-family: SourceSansPro;
		font-weight: 600;
		text-transform: uppercase;
	}
	${below.phone`
		height: 40px;
		max-width: 120px !important;
		justify-content: center;
		margin-top: 0px;
		padding: 0px;
		margin-right: 5px;
		.timer-unit{
			font-size: 10px;
		}
		p{
			margin-top 0px;
			margin-right: 5px;
			font-size: 10px;
		}
		.draft-timer{
			margin-top: 2px;
		}
		svg{
			margin-right: 5px;
			height: 14px;
			width: 14px;
			margin-top: 0px;
		}
	`}
`;

const StyledFormCircleCheckbox = styled(FormCircleCheckbox)`
	min-width: auto !important;
	${props => props.margin && `
		margin-left: 5px;
	`}

	${below.tablet`
		margin: 20px 0 0 0;
		width: 100%;
	`}
`;

const StyledCommandButton = styled(CommandButton)`
	> svg{
		height: 100%;
		width: 100%;

		&.filter{
			filter: invert(96%) sepia(8%) saturate(89%) hue-rotate(155deg) brightness(88%) 
				contrast(96%);
		}
	}

	${above.tablet`
		display: none;
	`}

	height: auto;
	width: 25px;
`;

const SelectFiltersWrapper = styled.div`
	${below.tablet`
		display: flex;
		flex-flow: column;

		> div{
			margin-right: 0;
			min-width: 100%;
			max-width: 100%;
			max-width: 100%;
		}
	`}

	${above.tablet`
		display: flex;
		flex-flow: row;
		align-items: center;

		> div {
			min-width: 120px;
			max-width: 120px;
			margin-right: 10px;
		}
		.stats_type {
			max-width: 225px;
		}
	`}

`;
const RadioButtonWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	justify-content: flex-start;
	margin-top: 24px;
	span.resetFilters{
		max-width: 122px;
		color: #D92425;
		font-size: 12px;
		cursor: pointer;
		font-weight: bold;

	}
	${below.tablet`
		flex-direction: column;
		justify-content: space-evenly;
		margin-top: 0px;
	`}
`;
const FieldContainer = styled.div`
	max-width: 300px;
	min-width: 200px;
	flex-basis: calc(50% - 10px);
	margin-right: 20px;

	${below.phone`
		margin-right: 0px;
		margin-bottom: 10px;
	`}
`;

const FlexRow = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
`;

const SearchWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 20px 0;
`;

const StyledSearchInputWrapper = styled(SearchInputWrapper)`
	margin-right: 10px;

	${above.tablet`
		min-width: 200px;
		width: 200px;
		margin-top: 5px;
	`};	

	${below.tablet`
		min-width: auto;
		max-width: calc(100% - 38px);
	`};	

	${below.desktop`
		${({show_tablet_column}) => show_tablet_column && `

			min-width: 100%;
			width: 100%;
			margin-left: 10px;
		`}
	`}
	${below.phone`
		${({show_tablet_column}) => show_tablet_column && `

		min-width: auto;
		max-width: calc(100% - 38px);
			
		`}
	`}
`;

const SelectWrapper = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-top: 20px;

	.changed{
		background-color: #F0F3F7;
		color: #1D4073;
	}

	${below.tablet`
		flex-wrap: wrap;
		${({ show_keeper_countdown }) => show_keeper_countdown && `
			justify-content: space-around;
		`}
		> div:not(:first-child) {
			flex-basis: 100%;
			margin-bottom: 10px;
		}
	`};
	
	span.resetFilters{
		max-width: 122px;
		color: #D92425;
		font-size: 12px;
		cursor: pointer;

		${below.tablet`
			margin-top: 32px;
		`}

		${above.desktop`
			line-height: 45px;
		`}
	}
	${below.desktop`
		${({show_tablet_column}) => show_tablet_column && `

			flex-direction: column;
			
		`}
	`}
	${below.phone`
		${({show_tablet_column}) => show_tablet_column && `

			flex-direction: row;
			
		`}
	`}
	
`;

const CheckboxLabel = styled.label`
	cursor: pointer;
	height: 36px;
	width: 36px;
	border: 1px solid ${colors.primary.primary};
	background-color: #FFFFFF;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-left: 1em;
	svg {
		margin: 0 auto;
	}
`;

const HiddenCheckbox = styled.input`
	display: none;
`;

const KeptPlayersToggleWrapper = styled.div`
	min-width: 300px !important;
	height: 38px;
	border: 1px solid #CAD2D8;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-evenly;
	padding: 0 5px;
	margin-top: 4px;
`;
const KeptPlayersButton = styled.button`
	width: 144px;
	height: 30px;
	color: #7F8A90;
	font-family: SourceSansPro;
	outline: none;
	padding: 0;
	font-size: 12px;
	text-align: center;
	border: none; 
	background-color: transparent;
	${({ isActive }) => isActive && `
		background-color: #198DE1;
		border-radius: 2px;
		color: white;
		br
	`}
`;

type TFilters = {
	search: string,
	by_squad: string,
	by_position: string,
	by_status: string,
	by_dpp: boolean,
	by_favourites: boolean,
	by_drafted: boolean,
	by_fa: boolean,
	by_owner: string,
	active_stats_type: string
}

type Props = {
	handleChange: Function,
	handleSubmit: Function,
	resetFilters: Function,
	onToggleOrder?: Function,
	order_by_desc?: boolean,
	is_show_drafted?: boolean,
	positions: Array<TPosition>,
	squads: Array<TSquad>,
	statuses: TPlayerStatusesReducer,
	is_hide_sort?: boolean,
	is_show_owner?: boolean,
	show_keeper_countdown?: boolean,
	show_ignored?: boolean,
	match: {
		params: {
			action: string,
			type: string
		}
	},
	onSelectChange?: Function,
	onResetAllFilters: Function,
	active_stats_type?: string,
	toggleKeptPlayers: Function,
	is_by_drafted?: boolean,
	show_kept_players: boolean,
	selectOptions: Array<{
		key: string,
		value: string
	}>,
	select_info?: {
		label?: string,
		title?: string,
		description?: string,
	},
	hide_stats_list?: boolean,
	league: TPLeague,
	children?: React.Node,
	filters: TFilters,
	show_tablet_column: boolean,
  is_trade_page?: boolean,
	is_show_keeper_toggle?: boolean,
	toggleDraftedPlayers: Function,
	handleSwitchFilterChange?: Function
};


type State = {
	by_dpp: boolean,
	by_favourites: boolean,
	by_drafted: boolean,
	hideFilters: boolean,
	by_ignored: boolean,
};

class DraftFiltersComponent extends React.PureComponent<Props, State> {
	static defaultProps = {
		order_by: true,
		is_show_drafted: false,
		is_hide_sort: true,
		is_show_owner:false,
		filters: {},
	};

	constructor(props: Props, state: State) {
		super(props, state);

		_.bindAll(this, [
			"handleSwitchChange",
			"renderIgnored",
			"renderOwnerFilter",
			"resetFilters",
			"hideFilters",
			"buildFilters"
		]);
	}

	state = {
		by_dpp: false,
		by_favourites: false,
		by_drafted: false,
		by_ignored: false,
		hideFilters: window.innerWidth < 768,
	};

	handleSwitchChange({ currentTarget }: Object) {
		const {handleSwitchFilterChange} = this.props;
		const { name } = currentTarget;
		this.setState({ ...this.state, [name]: !this.state[name] });
		if(handleSwitchFilterChange){
			handleSwitchFilterChange(name, !this.state[name]);
		}
	}
	
	resetFilters() {
		const { onResetAllFilters } = this.props;
		this.setState({
			by_dpp: false,
			by_favourites: false,
			by_drafted: false,
			by_ignored: false
		});
		onResetAllFilters();
	}

	renderIgnored() {
		const { show_ignored } = this.props;
		const { by_ignored } = this.state;
		if(!show_ignored) {
			return null;
		}

		return (
			<StyledFormCircleCheckbox
				id="ignored"
				name="by_ignored"
				checked={by_ignored}
				isActive={by_ignored}
				onChange={this.handleSwitchChange}
				label="Show your ignored players"
			/>
		);
	}

	get owner_default_value() {
		const { match: { params: { action, type } } } = this.props;
		if (action === "free-agent" || type === "delist") {
			return "fa_rfa";
		}
		if (action === "rfa") {
			return "rfa";
		}
		return false;
	}

	renderOwnerFilter(filtersChanged: Object) {
		const { is_show_owner, filters } = this.props;

		if (!is_show_owner) {
			return null;
		}
		return (
			<div>
				<Select
					is_disabled={false}
					name="by_owner"
					defaultValue={this.owner_default_value}
					value={filters.by_owner}
					className={filtersChanged.by_owner}
				>
					<option value="false">
						All Players
					</option>
					<option value="fa_rfa">
						Free Agents
					</option>
					<option value="rfa">
						Restricted (RFA)
					</option>
					<option value="owned">
						Owned Players
					</option>
				</Select>
			</div>
		);
	}

	renderStatsSelectFilter() {
		const {
			onSelectChange,
			active_stats_type,
			selectOptions,
			hide_stats_list
		 } = this.props;

		if (hide_stats_list) {
			return null;
		}
		return <React.Fragment>
			<FieldContainer className="default-container">
				<Select
					name="stats_type"
					is_disabled={false}
					onChange={onSelectChange}
					value={active_stats_type}
				>
					{selectOptions.map(({ key, value }) => (
						<option key={key} value={key}>
							{value}
						</option>
					))}
				</Select>
			</FieldContainer>
		</React.Fragment>;
	}
	renderKeptPlayersToggle(){
		const { 
			toggleKeptPlayers, 
			show_kept_players, 
			league, 
			is_show_keeper_toggle 
		} = this.props;
		const { keeper, regenerated_from, uf_regenerated_from } = league;
		const isRegenerated = getIsRegenerated(regenerated_from,uf_regenerated_from);
		const isKeeperAndRegen = 
			Boolean(keeper) 
			&& isRegenerated 
			&& is_show_keeper_toggle;

		if(!isKeeperAndRegen){
			return null;
		}
		return(
			<KeptPlayersToggleWrapper>
				<KeptPlayersButton onClick={toggleKeptPlayers} isActive={!show_kept_players}>
					Show available players
				</KeptPlayersButton>
				<KeptPlayersButton onClick={toggleKeptPlayers} isActive={show_kept_players}>
					Show kept players
				</KeptPlayersButton>
			</KeptPlayersToggleWrapper>
		);
	}

	renderDraftedPlayersToggle(){
		const {  
			is_show_drafted,
			toggleDraftedPlayers,
			is_by_drafted
		} = this.props;

		if(!is_show_drafted){
			return null;
		}

		return(
			<KeptPlayersToggleWrapper>
				<KeptPlayersButton onClick={toggleDraftedPlayers} isActive={!is_by_drafted}>
					Show available players
				</KeptPlayersButton>
				<KeptPlayersButton onClick={toggleDraftedPlayers} isActive={is_by_drafted}>
					Show Drafted players
				</KeptPlayersButton>
			</KeptPlayersToggleWrapper>
		);
	}

	renderKeeperCountdown() {
		const { league, show_keeper_countdown } = this.props;
		const { 
			draft_start, 
			keeper_deadline 
		} = league;
			
		const threeDaysPriorDate = threeDaysPriorKeeperDeadline(draft_start);
		const countdownTime = new Date(keeper_deadline) || threeDaysPriorDate;
		const isPassedDate = getKeeperDeadlinePassed(draft_start, keeper_deadline);
		
		if(!show_keeper_countdown){
			return null;
		}

		if(isPassedDate){
			return <CountdownWrapper>
				<Clock size={1.35} />
				<p> Deadline passed</p>
			</CountdownWrapper>;
		}

		return(
			<CountdownWrapper>
				<Clock size={1.35} />
				<p>Deadline:</p>
				<CountdownTimer
					className="draft-timer"
					showOnComplete={true}
					hideHoursIfZero={true}
					date={countdownTime}
					small
					isKeeperCounter={true}
				/>
			</CountdownWrapper>
		);
	}

	renderStatsPresetFilter(filtersChanged: Object) {
		const {
			onSelectChange,
			active_stats_type,
			selectOptions,
			hide_stats_list,
		 } = this.props;

		if (hide_stats_list) {
			return null;
		}
		const custom_stats_object = { key: "custom-stats", value: "Scoring Stats" };
		if(!selectOptions.some(stat => stat.key === "custom-stats")){
			selectOptions.splice(1, 0, custom_stats_object);
		}
		

		return <div className='stats_type'>
			<Select
				name="stats_type"
				is_disabled={false}
				onChange={onSelectChange}
				value={active_stats_type}
				className={filtersChanged.active_stats_type}
			>
				{selectOptions ? selectOptions.map(({ key, value }) => (
					<option key={key} value={key}>
						{value}
					</option>
				)):null}
			</Select>
		</div>;
	}

	hideFilters(){
		this.setState({ hideFilters: !this.state.hideFilters });
	}

	buildFilters(){
		const {			
			positions,
			squads,
			selectOptions,
			filters,
			active_stats_type,
			onResetAllFilters,
			is_trade_page,
			show_keeper_countdown,
			show_tablet_column
		} = this.props;

		const {
			by_dpp,
			by_favourites,
			hideFilters,

		} = this.state;
		const is_mobile = isMobile();

		const filtersChanged = _.cloneDeep(filters);
		filtersChanged.active_stats_type = active_stats_type;
		Object.keys(filtersChanged).forEach(i => {
			if(i === "active_stats_type"){
				filtersChanged[i] = filtersChanged[i] !== "general-stats" ? "changed" : "";
			}
			else{
				filtersChanged[i] = !["","false"].includes(filtersChanged[i]) ? "changed" : "";
			}
		});
		if(is_trade_page && selectOptions){
			return (
				<div>
					{this.renderStatsPresetFilter(filtersChanged)}
				</div>
			);
		}

		const searchSection = () => {
			if(show_keeper_countdown){
				return <SearchCountdownWrap>
					<StyledSearchInputWrapper>
						<SearchField
							type="text"
							name="search"
							component="input"
							placeholder="Search for a player"
							value={filters.search}
							className={filtersChanged.search}
						/>
						<SearchSubmitButton type="submit">
							<Search />
						</SearchSubmitButton>
					</StyledSearchInputWrapper>
					<Exist when={is_mobile}>
						{this.renderKeeperCountdown()}
					</Exist>
				</SearchCountdownWrap>;
			}
			return <React.Fragment>
				<StyledSearchInputWrapper show_tablet_column={show_tablet_column}>
					<SearchField
						type="text"
						name="search"
						component="input"
						placeholder="Search for a player"
						value={filters.search}
						className={filtersChanged.search}
					/>
					<SearchSubmitButton type="submit">
						<Search />
					</SearchSubmitButton>
				</StyledSearchInputWrapper>
				<Exist when={is_mobile}>
					{this.renderKeeperCountdown()}
				</Exist>
			</React.Fragment>;
				
		};

		return <div>
			<SelectWrapper 
				show_keeper_countdown={show_keeper_countdown} 
				show_tablet_column={show_tablet_column}>
				<FlexRow>
					{searchSection()}
					<StyledCommandButton background="none" onClick={() => this.hideFilters()}>
						<Exist when={hideFilters}>
							<More /> 
						</Exist>
						<Exist when={!hideFilters}>
							<Close className="filter" />
						</Exist>
					</StyledCommandButton>
				</FlexRow>
				<Exist when={!hideFilters}>
					<SelectFiltersWrapper show_tablet_column={show_tablet_column}>
						<div>
							<Select 
								name="by_squad" 
								is_disabled={false} 
								value={filters.by_squad}
								className={filtersChanged.by_squad}
							>
								<option value="">
									All Clubs
								</option>
								{squads.map(({ id, full_name }) => (
									<option key={id} value={id}>{full_name}</option>
								))}
							</Select>
						</div>
						<div>
							<Select
								readOnly
								is_disabled={false} name="by_position" 
								value={filters.by_position}
								className={filtersChanged.by_position}
							>
								<option value="">
									All Positions
								</option>
								{positions.map(({ id, full_name }) => (
									<option key={id} value={id}>{full_name}</option>
								))}
							</Select>
						</div>
						{this.renderOwnerFilter(filtersChanged)}
						{/* {!isKeeper && <div>
						<Select 
							is_disabled={false} 
							name="by_status" 
							value={filters.by_status}
							className={filtersChanged.by_status}
						>
							<option value="">
									All Statuses
							</option>
							{statuses ? statuses.map(({ key, value }) => (
								<option key={key} value={key}>{value}</option>
							)) : null}
						</Select>
					</div>} */}
						{ selectOptions && this.renderStatsPresetFilter(filtersChanged) }

						{!is_mobile && this.renderKeeperCountdown()}
						{this.renderDraftedPlayersToggle()}
						{this.renderKeptPlayersToggle()}
			
					</SelectFiltersWrapper>

				</Exist>
			</SelectWrapper>
			<Exist when={!hideFilters}>
				<RadioButtonWrapper>
					<StyledFormCircleCheckbox
						id="dpp"
						name="by_dpp"
						checked={by_dpp}
						isActive={by_dpp}
						onChange={this.handleSwitchChange}
						label="Only show dual position players"
						value={by_dpp}
					/>
					<StyledFormCircleCheckbox
						id="favourites"
						name="by_favourites"
						isActive={by_favourites}
						checked={by_favourites}
						onChange={this.handleSwitchChange}
						label="Only show your favourites"
						value={by_favourites}
					/>
					{this.renderIgnored()}
					{/* {is_show_drafted && (
						<StyledFormCircleCheckbox
							id="drafted"
							name="by_drafted"
							checked={by_drafted}
							onChange={this.handleSwitchChange}
							label="Show drafted players"
							margin
						/>
					)} */}
					
					{onResetAllFilters && 
					<div>
						<span 
							role='button' 
							onClick={this.resetFilters} 
							onKeyDown={this.resetFilters}
							className='resetFilters'
						>
							Restore filters to default
						</span>
					</div>
					}
				</RadioButtonWrapper>
			</Exist>
			
		</div>;
	}

	render() {
		const {			
			handleChange,
			handleSubmit,
			order_by_desc,
			onToggleOrder,
			is_hide_sort,
		} = this.props;

		return (
			<DraftFiltersForm onChange={handleChange} onSubmit={handleSubmit}>
				{!is_hide_sort &&<SearchWrapper>
					<div>
						<CheckboxLabel htmlFor="order_by_desc" onClick={onToggleOrder}>
							{order_by_desc ? <SortDown /> : <SortUp />}
						</CheckboxLabel>
						<HiddenCheckbox
							type="checkbox"
							defaultChecked={order_by_desc}
							value={order_by_desc ? "desc" : "asc"}
							id="order_by_desc"
							name="order_by_desc" />
					</div>
				</SearchWrapper>}
				{this.buildFilters()}
			</DraftFiltersForm>
		);
	}
}

export const DraftFilters = withRouter(DraftFiltersComponent);

export default DraftFilters;