// @flow

import * as React from "react";
import styled, { css } from "styled-components";
import { connect } from "react-redux";
import _ from "lodash";
import numeral from "numeral";
import { Link } from "react-router-dom";
import above, { below } from "../../assets/css/media";
import type {
	TPlayer,
	TPLeague,
	TRootStore,
	TRound,
	TUser
} from "../../modules/types";
import FavButton from "../FavButton";
import PlayerActionButton from "../PlayerActionButton";
import IconCircled from "../IconCircled";
import AddPlayer from "../Icons/AddPlayer";
import Delist from "../Icons/Delist";
import Trade from "../Icons/Trade";
import StatusButton from "../StatusButton";
import Injured from "../Icons/Injured";
import Star from "../Icons/Star";
import Compare from "../Icons/Compare";
import Available from "../Icons/Available";
import Bye from "../Icons/Bye";
import Uncertain from "../Icons/Uncertain";
import NotSelected from "../Icons/NotSelected";
import InjurySub from "../Icons/InjurySub";
import { reverse_logos, getClubLogoIndigenous, shouldUseIndigenousLogos } from "../../utils/clubLogos";
import club_colors from "../../utils/clubColors";
import * as actions from "../../modules/actions";
import * as selectors from "../../modules/selectors";
import colors from "../../assets/css/colors";
import * as format from "../../helpers/numeralFormats";
import { getShortName } from "../../helpers/league";
import { default_transition } from "../../assets/css/vars";
import Close from "../Icons/Close";
import { ButtonPrimaryLink } from "../ButtonPrimary";
import breakpoints from "../ComparePlayers/breakpoints";
import SelectedEmergency from "../Icons/SelectedEmergency";
import isMobile from "../../utils/isMobile/index";
import { Exist } from "../Exist";
import { isAllTrue } from "../../helpers";
import { isOffSeason } from "../../utils";

const SEASON_YEAR: string = process.env.REACT_APP_SEASON_YEAR || "";

const CloseCardBlock = styled.div`
	width: 100%;

	display: flex;
	justify-content: flex-end;
	position: absolute;
	z-index: 10;

	${default_transition};

	svg {
		height: 32px;
		width: 32px;
		margin: 14px;

		cursor: pointer;

		${default_transition};

		border-radius: 16px;
		box-shadow: 0 0 0 ${colors.secondary.paleGrey}33;
    fill: #fff;
		&:hover {
			fill: ${colors.buttons.baseHover};
			background-color: ${colors.secondary.paleGrey}33;
			box-shadow: 0 0 10px ${colors.secondary.paleGrey}33;
		}
	}
`;

const getEndIndex = (isMobile: boolean) => isMobile ? 4 : undefined;

export const BackgroundLogoContainer = styled.div`
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	z-index: 1;
	@media (max-width: 767px){
		height: fit-content;
		top: -65px;
		height: fit-content;
		overflow: visible
	}
`;

export const CardContainer = styled.div`
  position: relative;
	margin-top: 45px;
	${({ isCompare }) => isCompare && `
		margin-top: 0px;
	`}
	${({ compressed }) => compressed && css`
		border-radius: 5px;
		/* box-sizing: border-box; */

		@media (min-width: ${breakpoints.three.min}) {
			max-width: calc(50% - 60px);
			flex: 1 0 auto;
		}

		margin-top: 0;
		height: 310px;
		background-color: ${colors.form.base};
		border-radius: 5px;

		${default_transition}
		:hover {
			box-shadow: 0 2px 10px 0 #89969F;
		}

		@media (max-width: ${breakpoints.two.max}) {
			height: auto;
			min-height: 430px;
			width: 50%;
		}

		@media (max-width: 550px) {
			${PlayerAvatar} {
				display: none;
			}
		}
	`};
`;

const TopBlock = styled.div`
	background: ${({ squad_id }) => club_colors[squad_id] || "#DFDFDF"};
	width: 100%;
	color: #FFF;
	position: relative;
	overflow: visible;
	height: 300px;

	&:after {
		content: '';
		display: block;
		clear: both;
	}
	border-radius: 5px;

	${({ compressed }) => compressed && css`
		border-radius: 0;
		height: 260px;
		width: unset;
	`};
	
	${below.tablet`
		overflow: hidden;
		border-radius: 0;
	`}
`;

export const CardLogo = styled.img`
	position: absolute;
	top: -350px;
	right: -430px;
	min-width: 1200px;
	opacity: 0.2;

	@media (max-width: 767px) {
		top: -100%;
		right: -50%;
		min-width: 500px;
		width: 120%;
		position: absolute;
		${({ popup }) => popup && css`
		top: 20px;
		right: -20px;
		min-width: 300px;;
		width: 90%;
		position: absolute;
	`};
	}

	
`;

export const PlayerAvatar = styled.div`
	position: absolute;
	left: 49%;
	top: -82px;
	height: 382px;
	transform: translate(-50%, 0%);
	z-index: 1;

	@media (max-width: 767px) {
		height: 100%;
		left: auto;
		right: -18px;
		bottom: 0;
		top: 0;
		transform: translate(0, 0);
	}

	img {
		height: 100%;
	}

	${({ compressed }) => compressed && css`
		height: 100%;
		transform: translate(-50%, 0);
		left: 50%;
		top: 0;

		> img {
			height: 110%;
			margin-top: -10%;
		}

		right: 0;
		${({ showing_players }) => showing_players === 3 && css`
			right: -10px;
		`}
		${({ showing_players }) => showing_players >= 4 && css`
			right: -20px;
			min-width: 68%;

		`}
	`};
	${above.phone`
		${({isCompare}) => isCompare && `
			width: 200px;
		`}
	`}
	
`;

export const PlayerInfoInner = styled.div`
	padding: 20px;
	font-size: 20px;
	font-weight: 600;

	@media (max-width: 767px) {
		padding: 0 10px;
	}
	${({ compressed }) => compressed && css`
		padding: 0 10px;
	`};
`;

export const PlayerInfo = styled.div`
	float: left;
	width: ${({ compressed }) => compressed ? "100%" : "61.25%"};
	position: relative;
	z-index: 2;

	img {
		height: 80px;
		margin-top: 30px;
		margin-left: 20px;
		@media (max-width: 767px) {
			height: 50px;
		}
		${({ compressed }) => compressed && css`
			height: 50px;
		`};

	}

	${PlayerInfoInner} {
		padding-top: 0px;
		margin-left: 10px;
	}

	@media (max-width: 767px) {
		float: none;
		width: 100%;

		img {
			width: 50px;
			margin-top: 10px;
			margin-left: 10px;
		}

		${PlayerInfoInner} {
			margin-left: 10px;
			position: relative;
			top: 45px;
			${({ popup }) => popup && `
				top: 0px;
			`}
		}
	}
	${({ compressed }) => compressed && css`
		float: none;

		img {
			width: 50px;
			margin-top: 10px;
			margin-left: 10px;
		}

		${PlayerInfoInner} {
			margin-left: 10px;
		}
	`};
`;

export const PlayerFirstName = styled.p`
	text-transform: none;
	font-size: 20px;
	font-family: TitilliumUpright;
	font-weight: bold;
	margin-bottom: 10px;

	@media (max-width: 767px) {
		margin-bottom: 3px;
		font-size: 14px;
	}
	${({ compressed }) => compressed && css`
		margin-bottom: 3px;
		font-size: 14px;
	`};
	${({ popup }) => popup && `
		margin-bottom: 0px;
	`}

	${({ margin_top }) => margin_top && css`
		margin-top: 10px;
	`}
	${({price, mob}) => price && `
		width: 85px;
		background: white;
		color: ${colors.primary.primary};
		border-radius: 5px;
		height 33px;
		display: flex;
		justify-content: center;
		align-items: center;
		padding-top: 2px;
		${mob && `
			width: 60px;
			height: 23px;
			font-size: 14px;
			margin-top: 5px !important;
		`}
	`}
`;

export const PlayerLastName = styled.p`
	display: inline-block;
	vertical-align: middle;
	font-weight: bold;
	font-size: 60px;
	margin-right: 5px;
	margin-left: -1px;
	margin-bottom: 10px;

	@media (max-width: 767px) {
		font-size: 30px;
	}
	${({ compressed }) => compressed && css`
		font-size: ${({ showing_players }) => showing_players === 4 ? "20px" : "26px"};
		max-width: ${({ showing_players }) => showing_players === 4 ? "130px" : "150px"};;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
    	width: auto;
	`};
	${({ popup }) => popup && `
		font-size: 40px;
	`}
`;

export const StatusIcon = styled(StatusButton)`
	display: inline-block;
	vertical-align: middle;
	width: 30px;
	height: 30px;
	border: 3px solid white;
	margin-bottom: 10px;
	svg {
		display: block;
		margin: 0 auto;
	}

	@media (max-width: 767px) {
		width: 20px;
		height: 20px;
		border: 1px solid white;
		margin-bottom: 0px;
	}
	${({ compressed }) => compressed && css`
		width: 20px;
		height: 20px;
		border: 2px solid white;
	`};
`;

export const Position = styled.p`
	display: block;
	padding-top: 10px;
	font-family: SourceSansPro;
	font-weight: 400;
	font-size: 14px;
	@media (max-width: 767px) {
		padding-top: 3px;
		font-size: 14px;
	}
	${({ compressed }) => compressed && css`
		padding-top: 3px;
		font-size: 14px;
	`};
	${({ popup }) => popup && `
		padding-top: 0px;
	
	`}
	${({age_section}) => age_section && `
		margin-left: 10px;
	`}
`;

export const StatBlock = styled.div`
	background: #FFF;
	border-radius: 45px;
	width: 125px;
	/* padding: 10px 0 10px 0; */
	height: 120px;
	position: relative;
	margin: 5px 0;
	overflow: hidden;
	${({ popup }) => popup && css`
		width: 110px;
		height: 110px;
		border-radius: 45px;
		margin: 0;
		${below.phone`
		
			margin-right: 4px;
		`}
	`};
	${({ isCompare }) => isCompare && `
				margin-top: 40px;
	`}
	div {
		position: absolute;
		left: 0;
		top: 0;
		display: flex;
		align-items: center;
		justify-content: space-around;
		flex-flow: column;
		text-transform: none;
		width: 100%;
		height: 100%;
		padding: 20% 5px 22%;
		box-sizing: border-box;
		

		p {
			font-size: 12px;
			color: ${({coach_stat}) => coach_stat ? colors.coach : colors.primary.primary};
			text-align: center;
			margin-bottom: 5px;
			font-family: SourceSansPro;
			font-weight: 600;
			@media (max-width: 767px) {
				font-size: 8px;
				margin-bottom: 5px;
			}
			${({ compressed }) => compressed && css`
				font-size: 8px;
				margin-bottom: 5px;
			`};
			${({ popup }) => popup && css`
				font-size: 10px;
			`}
		}

		span {
			font-weight: bold;
			color: ${({coach_stat}) => coach_stat ? colors.coach : colors.primary.primary};
			font-size: 40px;
			text-transform: none;
			font-family: SourceSansPro;
			@media (max-width: 767px) {
				font-size: 14px;
			}
			${({ compressed }) => compressed && css`
				font-size: 12px;
			`};
			${({ popup }) => popup && css`
				font-size: 30px;
			`}
			${({ isCompare, is_classic }) => {
		if(isCompare && is_classic){
			return "font-size: 20px";
		}
		if(isCompare){
			return "font-size: 26px";
		}
	}}
		}
	}
`;

export const PlayerStats = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 0;
	padding-left: 125px;
	flex-wrap: wrap;
	width: 45%;
	right: 35px;
	box-sizing: border-box;
	position: absolute;
	z-index: 2;
	height: 300px;
	padding-top: 15px;
	${({ popup }) => popup && css`
		padding: 20px 40px 25px 10px;
	`}
	@media screen and (max-width: 767px) {
		float: none;
		padding: 5px 10px 30px 13px;
		display: flex;
		align-items: flex-end;
		width: 76%;
		height: 165px;
		${({ popup }) => popup && `
			left: -20px;
			top: 20px;
			width: 100%;
			justify-content: flex-start;
			padding-left: 20px;
			padding-right: 0px;
			margin-left: 0px;
		`}

		${StatBlock} {
			display: flex;
			width: 60px;
			height: 60px;
			padding: 0;
			margin: 0;
			${({ popup }) => popup && `
				margin-right: 4px;
			`}
			border-radius: 24px;
		}
	}
	${below.phone`
		left: 0;
		${({ popup }) => popup && `
			float: none;
			left: unset;
			top: unset;
		`}
	`}

	${({ compressed, isCompare }) => (compressed || isCompare) && css`
		float: none;
		padding: 5px 10px 10px;
		padding-left: 5%;
		display: block;
		width: 100px;
		height: 100px;

		${StatBlock} {
			width: 60px;
			height: 60px;
			padding: 0;
			margin: 0;
			display: none;
			border-radius: 24px;
			${({ isCompare }) => isCompare && `
				margin-top: 40px;
			`}
		}

		${StatBlock}:nth-last-child(-n+2) {
			display: block;
		}
	`};
	${({isCompare}) => (isCompare) && `
		left: 0;
	`}
	${({popup}) => !popup && `
		${below.phone`
			left: 0;
		`}
	`}
`;

export const FavIcon = styled(FavButton)`
	width: 30px;
	height: 30px;

	svg {
		height: 18px;
		width: 18px;
	}
`;

export const FAIcon = styled.div`
	background: #198DE1;
	color: #FFF;
	border-radius: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 30px;
	height: 30px;
	margin-right: 10px;
	font-size: 16px;
	font-weight: 200;
	padding: 4px 2px 0;
`;

export const FAIconContainer = styled.div`
	color: #1D4073;

	display: flex;
    align-items: baseline;

	div,
	span {
		display: inline-flex;
		vertical-align: middle;
	}

	${({ compressed }) => compressed && css`
		span {
			font-size: 14px;
		}
		${FAIcon} {
			width: 21px;
			height: 21px;
			font-size: 14px;
			margin-right: 5px;
		}
	`};
`;

export const BottomButtonsBlock = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;

	button {
		margin-left: 5px;
	}
`;

export const BLink = styled(Link)`
	text-decoration: none;
	color: initial;
`;

export const PlayerControlButton = styled(PlayerActionButton)`
	width: auto;
	height: auto;
	display: flex;
	align-items: center;
	color: ${({ color }) => color || "#000"};

	text-decoration: none;

	border-radius: 2px;

	${default_transition};

	span {
		font-size: 14px;
		line-height: 20px;
		padding-top: 2px;
		font-weight: 500;
	}

	svg {
		width: 30px;
		height: 30px;
		margin-left: 5px;
		${({ compare }) => compare && `
			width: 20px;
			height: 20px;
			margin-left: 0px;
			g {
				fill: #FFF;
			}
		`}
	}

	:hover {
		background-color: ${colors.form.base};
	}

	@media (max-width: 767px) {
		padding: 0;
		width: 30px;
		height: 30px;

		span {
			display: none;
		}

		svg {
			margin: 0 auto;
		}
	}

	${({ compressed }) => compressed && css`
		padding: 0;

		span {
			display: none;
		}

		svg {
			margin: 0 auto;
		}
	`};
`;

export const CompareButton = (props: Object) => (
	<BLink to={props.to || ""}>
		<PlayerControlButton color="#7F8A90" {...props} compare>
			<IconCircled size={"30px"} background={"#7F8A90"}>
				<Compare />
			</IconCircled>
			
		</PlayerControlButton>
	</BLink>
);

export const TradeButton = (props: Object) => {
	return <BLink to={props.to || ""}>
		<PlayerControlButton color="#1D4073" {...props}>
			<Trade />
		</PlayerControlButton>
	</BLink>;
};

export const AddButton = (props: Object) => {
	return <BLink to={props.to || ""}>
		<PlayerControlButton color="#198DE1" {...props}>
			<AddPlayer />
		</PlayerControlButton>
	</BLink>;
};

export const DelistButton = (props: Object) => {
	return <BLink to={props.to || ""}>
		<PlayerControlButton color="#D92425" {...props}>
			<Delist />
		</PlayerControlButton>
	</BLink>;
};

const getPlayerUrl = (pid, lid?, compare?) => {
	if (compare) {
		return lid
			? `/draft/league/${lid}/players/compare/${pid}`
			: `/classic/stats-centre/compare/${pid}`;
	}
	return lid
		? `/draft/league/${lid}/player/${pid}`
		: `/classic/player/${pid}`;
};


export const BottomBlock = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding: 0px 20px 10px;
	margin: 20px 0px 10px;
	> div:first-of-type {
		margin: 0 auto 0 0;
	}

	@media (max-width: ${breakpoints.two.max}) {
		padding: 0;
		margin: 10px;
		flex-direction: row;
		align-items: center;
		> div:first-of-type {
			margin: 0;
		}

		${BottomButtonsBlock} {
			justify-content: flex-start;
			a:first-child button, a:first-child {
				margin-left: 0;
			}
		}
	}
	${({ compressed }) => compressed && css`
		margin: 8px;
		padding: 0;
		border: none;

		${FavIcon}, ${PlayerControlButton} {
			height: 30px;
			width: 30px;
			svg {
				font-size: 25px;
			}
		}

		${BottomButtonsBlock} {
			width: 150px;
		}

		${({ showing_players }) => showing_players >= 3 && css`
			${FavIcon}, ${PlayerControlButton} {
				height: 30px;
				width: 30px;
				svg {
					font-size: 20px;
				}

				margin-left: 5px;
			}

			${BottomButtonsBlock} {
				width: 100px;
			}
		`};
		${({ showing_players }) => showing_players >= 4 && css`
			width: 200px;
		`};
	`};
	${({ isCompare }) => isCompare && `
		margin: 0;
		padding: 0;
		height: 48px;
		display: flex;
		flex-direction: row;
		width: 100%;
		justify-content: space-between;
		
	`}
	${below.phone}{
		${({ isCompare }) => isCompare && `
			justify-content: space-around;
			width: 95%;
		`}
	}
`;

export const OwnerBlock = styled.div`
	white-space: nowrap;
	font-family: TitilliumUpright, Arial, sans-serif;
	font-weight: 700;
	flex-grow: 1;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;


	.team-name {
		color: #1D4073;
		font-size: 12px;

		${({ isCompare }) => isCompare === true && `
			align-self: flex-start;
			margin-left: 10px;
			margin-bottom: 3px;
		`}
	}

	.user-name {
		font-family: SourceSansPro;
		color: #1D4073;
		font-size: 12px;
		font-weight: 400;
		margin-left: 10px;
		margin-bottom: 2px;
		${({ isCompare }) => isCompare && `
			align-self: flex-start;
			
		`}
	}
	img{
		width: 30px;
		height: 30px;
	}
	${({ compressed }) => compressed && css`
		width: 130px;
    flex-direction: column;
    
		.team-name, .user-name {
			overflow: hidden;
			text-overflow: ellipsis;
		}

		${({ showing_players }) => showing_players === 3 && css`
			width: 100px;
		`};

		${({ showing_players }) => showing_players >= 4 && css`
			width: 90px;
		`};
	`};
`;

const FullProfileButton = styled(ButtonPrimaryLink)`
	margin: 10px;
`;

export const statusIconComponent = {
	"injured": Injured,
	"is-bye": Bye,
	"not-playing": NotSelected,
	"medical_sub": InjurySub,
	"uncertain": Uncertain,
	"playing": Available,
	"emergency": SelectedEmergency
};

export const basicStats = (statsBasis: string = "stats") => {
	return	[
		{
			title: "Games Played",
			id: `${statsBasis}.games_played`,
		},
		{
			title: "Last Round score",
			id: `${statsBasis}.points_last_round`,
			short_title: "Last Round"
		},
		{
			title: "Average score",
			id: `${statsBasis}.avg_points`,
		},
	];
};

export const draftStats = (statsBasis: string = "stats") => {
	return [
		...basicStats(statsBasis),
		{
			title: "Average Draft position",
			id: "stats.adp",
			short_title: "ADP"
		},
		{
			title: "Leagues Rostered %",
			is_coach: false,
			id: "stats.leagues_rostered"

		},
		{
			title: "Projected Score",
			is_coach: true,
			id: `${statsBasis}.proj_score`
		},
		
	];
};

export const classicStats = isOffSeason() ? [
	...basicStats(),
	{
		title: "Average Draft position",
		id: "stats.adp",
		short_title: "ADP"
	},
	{
		title: "High Score",
		id: "stats.high_score",
		short_title: "High Score"
	},
	{
		title: "Low Score",
		id: "stats.low_score",
		short_title: "Low Score"
	},

] : [
	...basicStats(),
	{
		title: "Breakeven",
		is_coach: true,
		id: "stats.break_even"
	},
	{
		title: "Projected Score",
		is_coach: true,
		id: "stats.proj_score"
	},
	{
		title: "Proj. Price Change",
		is_coach: true,
		id: "stats.proj_price_change_next_round"
	}
];




type Props = {
	player: TPlayer,
	user: TUser,
	league: TPLeague,
	actual_round: TRound,
	is_classic?: boolean,
	stats: typeof draftStats | typeof classicStats,
	removeFromFavourites: Function,
	addToFavourites: Function,
	by_year: string,
	past_years: {[string]: TPlayer},
	my_players: number[],
	fetchMyClassicTeam: typeof actions.fetchMyClassicTeam,
	compressed: boolean,
	showing_players: number,
	show_buttons: boolean, /* If compressed, the number of cards that are being displayed */
	onClose: Function,
	isCompare: boolean,
};

class PlayerCardComponent extends React.Component<Props> {
	static defaultProps = {
		is_classic: false,
		compressed: false,
		show_buttons: false,
		onClose: () => 0,
		showing_players: 0
	};

	constructor(props: Props) {
		super(props);

		_.bindAll(this, [
			"onFavourite",
			"closePlayer"
		]);
	}

	

	get classic_owner_block() {
		const { my_players, player, user } = this.props;

		if (player) {
			const owns_player = _.includes(my_players, player.id);
			if (owns_player) {
				return (
					<React.Fragment>
						<div className="team-name">{_.get(user, "team_name")}</div>
						<div className="user-name">{getShortName(user)}</div>
					</React.Fragment>
				);
			}
		}

		return null;
	}

	get draft_owner_block() {
		const { player, compressed, isCompare } = this.props;
		const { owner, waiver } = player;

		if (owner) {
			return (
				<React.Fragment>
					<div className="team-name">{_.get(owner, "name")}</div>
					<div className="user-name">{getShortName(owner)}</div>
				</React.Fragment>
			);
		}

		return (
			<FAIconContainer compressed={compressed} isCompare={isCompare}>
				<FAIcon>FA</FAIcon>
				<span>{waiver}</span>
			</FAIconContainer>
		);

	}

	get trade_buttons() {
		const
			{ user, player, league, compressed } = this.props,
			{ owner } = player,
			is_user_team = _.get(owner, "user_id") === user.id,
			is_trade_locked = !this.checkIsManagementAvailable(player),
			league_id = league ? league.id : "",
			player_id = player ? player.id : "",
			button_props = { league_id, player_id, compressed };

		if (is_trade_locked) {
			return null;
		}

		if (is_user_team) {
			return (
				<React.Fragment>
					<TradeButton
						{...button_props}
						to={`/draft/league/${league_id}/players/${player_id}/trade/`}
					/>
					<DelistButton
						{...button_props}
						to={`/draft/league/${league_id}/players/${player_id}/delist/`}
					/>
				</React.Fragment>
			);
		}

		if (owner) {
			return <TradeButton
				{...button_props}
				to={`/draft/league/${league_id}/players/${player_id}/trade/`}
			/>;
		}

		return <AddButton
			{...button_props}
			to={`/draft/league/${league_id}/players/${player_id}/add/`}
		/>;
	}

	checkIsManagementAvailable(player) {
		const { actual_round, league } = this.props;
		if (league !== null) {
			const { lockout } = league;
			if (league.status === "scheduled") {
				return true;
			}

			if (!lockout && actual_round.status !== "scheduled") {
				return false;
			}

			return !_.get(player, "locked");
		}
	}

	onFavourite() {
		const { player, removeFromFavourites, addToFavourites } = this.props;
		const { id, is_favourite } = player || {};

		is_favourite ? removeFromFavourites(id) : addToFavourites(id);
	}

	getStat(stat_id: string) {
		const { player, by_year, past_years, league } = this.props;
		const player_id = player ? player.id : "";
		const stat_substring = stat_id.split(".")[1];
		const custom_scoring_enabled = _.get(league, "custom_scoring_enabled", false);
		let value: string | number;
		if (by_year === SEASON_YEAR) {
			value =  _.get(player, stat_id, "--");
			if(custom_scoring_enabled && stat_id !== "stats.adp"){
				value = _.get(player, `custom_stats.${stat_substring}`, "--");
			}
		}
		else {
			value = _.get(past_years, by_year + "." + player_id + "." + stat_id, "--");
		}

		if (stat_id === "cost") {
			value = numeral(value).format(format.TWO_DEC_PRICE);
		}
		return value;
	}

	closePlayer() {
		const { onClose, player } = this.props;
		if (player && onClose) {
			onClose(player.id);
		}
	}

	get compareComponent() {
		const {compressed, is_classic} = this.props;
		const stat = is_classic ? {
			title: "Price",
			is_coach: false,
			id: "cost"
		} : {
			title: "ADP",
			is_coach: false,
			id: "stats.adp"
		};
		const value = this.getStat(stat.id);
		const comp_title = stat.title;
		const id = stat.id;

		return <React.Fragment key={"compare-price"}>
			<StatBlock
				key={`${id}-desktop`}
				className="hidden-mobile"
				compressed={compressed}
				isCompare={true}
				is_classic={true}
			>
				<div>
					<p>{ comp_title }</p>
					<span>{ value === "" ? "N/A" : value }</span>
				</div>
			</StatBlock>
			<StatBlock
				key={`${id}-mob`}
				className="hidden-desktop"
				compressed={compressed}
				isCompare={true}
			>
				<div>
					<p>{ comp_title}</p>
					<span>{ value }</span>
				</div>
			</StatBlock>
		</React.Fragment>;
	};

	render() {
		const { 
			player, 
			compressed, 
			show_buttons, 
			league, 
			showing_players, 
			actual_round 
		} = this.props;

		if (!player) {
			return "";
		}

		const
			getFromPlayer = _.partial(_.get, player, _),
			PLAYERS_AVATAR_PATH = (process.env.REACT_APP_PLAYERS_PATH),
			squad_id = getFromPlayer("squad_id"),
			avatar_url = `${PLAYERS_AVATAR_PATH + getFromPlayer("id")}_450.png`,
			avatar_url_webp = `${PLAYERS_AVATAR_PATH + getFromPlayer("id")}_450.webp`,
			reverse_url = reverse_logos[squad_id],
			status = getFromPlayer("is_bye") ? "is-bye" : _.get(player, "status", "uncertain"),
			StatusIconComponent = _.get(statusIconComponent, status),
			squad_name = getFromPlayer("squad.name"),
			first_name = getFromPlayer("first_name"),
			last_name = getFromPlayer("last_name"),
			full_name = `${first_name} ${last_name}`,
			positions_str = getFromPlayer("positions_full_name", []).join(" / "),
			{ stats, is_classic, isCompare } = this.props,
			{ is_favourite } = player,
			trade_buttons = this.trade_buttons,
			league_id = _.get(league, "id"),
			player_id = _.get(player, "id");
		const endIndex = getEndIndex(isMobile());
		const playerTeamLogo = shouldUseIndigenousLogos(actual_round) ? getClubLogoIndigenous(actual_round.id, squad_id) : reverse_url;
		const playerIndigenousHandledLogo = getClubLogoIndigenous(actual_round.id, squad_id);

		const stats_components = _.map(stats, stat => {
			const { title, id, short_title } = stat;
			const value = this.getStat(id);
			const comp_title = short_title ? short_title : title;
			return (
				<React.Fragment key={id}>
					<StatBlock
						key={`${id}-desktop`}
						className="hidden-mobile"
						compressed={compressed}
						isCompare={isCompare}
						is_classic={is_classic}
					>
						<div>
							<p>{ compressed ? comp_title : title }</p>
							<span>{ value === "" ? "N/A" : value }</span>
						</div>
					</StatBlock>
					<StatBlock
						key={`${id}-mob`}
						className="hidden-desktop"
						compressed={compressed}
						isCompare={isCompare}
					>
						<div>
							<p>{ short_title ? short_title : title }</p>
							<span>{ value }</span>
						</div>
					</StatBlock>
				</React.Fragment>
			);
		}).slice(0, endIndex);

		if(isCompare){
			stats_components.push(this.compareComponent);
		}
		return (
			<CardContainer 
				compressed={compressed} 
				showing_players={showing_players} 
				isCompare={isCompare}>
				<Exist when={show_buttons}>
					<CloseCardBlock>
						<Close color={colors.primary.primary} onClick={this.closePlayer} />
					</CloseCardBlock>
				</Exist>
				<TopBlock squad_id={squad_id} compressed={compressed}>
					<BackgroundLogoContainer>
						<CardLogo
							src={playerTeamLogo}
							alt={squad_name}
							title={squad_name}
							compressed={compressed}
						/>
						
					</BackgroundLogoContainer>
					<PlayerInfo compressed={compressed}>
						<img 
							src={playerIndigenousHandledLogo} 
							alt={squad_name} 
							title={squad_name} 
						/>
					
						<PlayerInfoInner compressed={compressed}>
							<PlayerFirstName compressed={compressed}>{first_name}</PlayerFirstName>
							<PlayerLastName
								showing_players={showing_players}
								compressed={compressed}
							>
								{last_name}
							</PlayerLastName>
							<StatusIcon
								status={player.status}
								is_bye={player.is_bye}
								compressed={compressed}
							>
								<Exist when={StatusIconComponent !== undefined}>
									<StatusIconComponent />
								</Exist>
					
							</StatusIcon>
							<Position compressed={compressed}>{ positions_str }</Position>
						</PlayerInfoInner>
					</PlayerInfo>
					<PlayerStats compressed={compressed} isCompare={isCompare}>
						{ stats_components }
					</PlayerStats>
					<PlayerAvatar 
						compressed={compressed} 
						showing_players={showing_players} 
						isCompare={isCompare}
					>
						<picture>
							<source srcSet={avatar_url_webp} type="image/webp" />
							<source srcSet={avatar_url} type="image/png" />
							<img src={avatar_url_webp} 
								alt={`${full_name} - ${squad_name}`} 
								title={full_name} />
						</picture>
					</PlayerAvatar>
				</TopBlock>
				<BottomBlock 
					compressed={compressed} 
					showing_players={showing_players} 
					isCompare={isCompare}>
					<OwnerBlock 
						compressed={compressed} 
						showing_players={showing_players} 
						isCompare={isCompare}>
						{ is_classic ? this.classic_owner_block : this.draft_owner_block }
					</OwnerBlock>
					<BottomButtonsBlock>
						<Exist when={!(is_classic && trade_buttons !== null)}>
							{trade_buttons}
						</Exist>
					
						<Exist when={!compressed}>
							<CompareButton
								compressed={compressed}
								to={getPlayerUrl(player_id, league_id, true)}
							/>
						</Exist>
						<Exist when={!isOffSeason()}>
							<FavIcon active={is_favourite} onClick={this.onFavourite}>
								<Star title="Select As Favourite" />
							</FavIcon>
						</Exist>
					</BottomButtonsBlock>
				</BottomBlock>
				<Exist when={isAllTrue([show_buttons, !compressed])}>
					<div><FullProfileButton
						to={getPlayerUrl(player_id, league_id)}
					>
						Full Profile
					</FullProfileButton></div>
				</Exist>
			</CardContainer>
		);
	}
}

const mapStateToProps = (state: TRootStore, props: Props) => ({
	stats: props.is_classic ? classicStats : draftStats(),
	past_years: state.players.past_years,
	user: selectors.getUser(state),
	my_players: props.is_classic ? selectors.getMyClassicTeamPlayersIds(state) : []
});

const mapDispatchToProps = {
	addToFavourites: actions.postAddToFavourites,
	removeFromFavourites: actions.postRemoveFromFavourites,
	fetchMyClassicTeam: actions.fetchMyClassicTeam
};

export const PlayerCard = connect(
	mapStateToProps,
	mapDispatchToProps,
)(PlayerCardComponent);

export default PlayerCard;