// @flow
import axios, { CancelToken } from 'axios';
import qs from 'qs';
import size from 'lodash/size';
import first from 'lodash/first';
import partial from 'lodash/partial';
import flow from 'lodash/flow';
import get from 'lodash/get';
import property from 'lodash/property';
import { CANCEL } from 'redux-saga';
import mapValues from 'lodash/mapValues';
import isBoolean from 'lodash/isBoolean';
import each from 'lodash/each';
import root from 'window-or-global';

/* eslint-disable no-underscore-dangle */
/* eslint-disable complexity */

const createHttpClient = ({
	baseApiUrl,
	baseJSONUrl,
	getSessionId,
	logout,
}: Object) => {
	const HttpClient = axios.create({
		baseURL: baseApiUrl,
	});

	const checkForAuthError = response => {
		const { errors } = response.data;
		const error = size(errors) ? first(errors) : null;

		if (error) {
			const errorCode = parseInt(error.code, 10);

			if (errorCode === 401) {
				logout();
			}
		}

		return response;
	};

	HttpClient.interceptors.response.use(
		flow([
			checkForAuthError,
			property('data'),
		]),
		error => Promise.reject(error),
	);

	function prepareFormData(data) {
		const prep_data = mapValues(data, value => {
			if (isBoolean(value)) {
				return +value;
			}
			return value;
		});

		const formData = new FormData();
		each(prep_data, (value, key) => {
			if (value instanceof File) {
				formData.append(key, value);
				return;
			}

			formData.append(key, value);
		});

		return formData;
	}

	const _makeRequest = (isPrivate, requestConfig) => {
		const config = { ...requestConfig };

		if (!config.params) {
			config.params = {};
		}

		if (config.baseURL !== baseJSONUrl) {
			config.withCredentials = true;
		}

		var IS_API_ON_THE_SAME_DOMAIN = (baseApiUrl || '').includes(get(root, 'location.host', ''));

		if (isPrivate && !IS_API_ON_THE_SAME_DOMAIN) {
			config.withCredentials = false;
			var sid = getSessionId();

			if (config.method === 'get') {
				config.params.sid = sid;
			}

			if (config.method === 'post') {
				config.data.sid = sid;
			}
		}

		if (config.method === 'post') {
			const content_type = config.is_multipart ?
				'multipart/form-data;' :
				'application/x-www-form-urlencoded';
			config.headers = {
				'content-type': content_type,
				...requestConfig.headers,
			};

			config.data = config.is_multipart ?
				prepareFormData(config.data) : qs.stringify(config.data);
		}
		// if (config.method === 'get' && config.baseURL !== baseJSONUrl) {
		// 	config.params._ = new Date().getTime();
		// }

		const source = CancelToken.source();
		const request = HttpClient.request({
			...config,
			cancelToken: source.token,
		});

		request[CANCEL] = () => source.cancel();

		return request;
	};

	const _makePublicRequest = partial(_makeRequest, false);
	const _makePrivateRequest = partial(_makeRequest, true);

	const Public = {
		get: (url: string, params?: Object, config?: Object) =>
			_makePublicRequest({
				url,
				params,
				...config,
				method: 'get',
			}),
		delete: (url: string, params: Object, config?: Object) =>
			_makePublicRequest({
				url,
				params,
				...config,
				method: 'delete',
			}),
		post: (url: string, data?: Object, config?: Object) =>
			_makePublicRequest({
				url,
				data,
				...config,
				method: 'post',
			}),
		put: (url: string, data: Object, config?: Object) =>
			_makePublicRequest({
				url,
				data,
				...config,
				method: 'put',
			}),
	};

	const Private = {
		get: (url: string, params?: Object, config?: Object) =>
			_makePrivateRequest({
				url,
				params,
				...config,
				method: 'get',
			}),
		delete: (url: string, params: Object, config?: Object) =>
			_makePrivateRequest({
				url,
				params,
				...config,
				method: 'delete',
			}),
		post: (url: string, data?: Object, config?: Object) =>
			_makePrivateRequest({
				url,
				data,
				...config,
				method: 'post',
			}),
		post_multipart: (url: string, data?: Object, config?: Object) =>
			_makePrivateRequest({
				url,
				data,
				...config,
				method: 'post',
				is_multipart: true,
			}),
		put: (url: string, data: Object, config?: Object) =>
			_makePrivateRequest({
				url,
				data,
				...config,
				method: 'put',
			}),
	};

	const JSON = {
		get: (url: string, config?: Object) =>
			Public.get(url, {}, { baseURL: baseJSONUrl, ...config }),
	};

	return { Public, Private, JSON };
};

export default createHttpClient;