// @flow
import * as React from "react";
import styled from "styled-components";
import { Field } from "redux-form";
import * as _ from "lodash";

import colors from "../../../../assets/css/colors";
import { default_transition } from "../../../../assets/css/vars";

import { SimpleFormField } from "../../../../components";
import Plus from "../../../../components/Icons/Plus";
import Close from "../../../../components/Icons/Close";
import { FieldGroup } from "./LeagueInviteEmail";

const fieldLevelRequired = value => value || typeof value === "number" 
	? undefined 
	: "Required";
const fieldLevelEmail = value =>
	value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
		? "Invalid email address"
		: undefined;

const EmailButton = styled.button`
	width: 16px;
	height: 16px;

	background-color: #fff;
	border-radius: 50%;

	border: none;
	padding: 0;
	margin: 0;

	display: flex;
	justify-content: center;
	align-items: center;

	${default_transition};

	&:hover {
		background-color: ${({ is_trash }) => is_trash 
		? colors.buttons.cancel 
		: colors.primary.accent};

		& > svg {
			fill: white;
		}
	}

	> svg {
		height: 20px;
		width: 20px;
		fill: ${({ is_trash }) => is_trash ? colors.buttons.cancel : colors.primary.primary};
		${default_transition};
	}
`;

type Props = {
	fields: any
}

export class EmailFields extends React.Component<Props> {
	constructor(props: Props) {
		super(props);

		_.bindAll(this, [
			"addInvite",
			"removeInvite"
		]);
	}

	addInvite(e: any) {
		const { fields } = this.props;

		e.preventDefault();

		fields.push({});
	}

	removeInvite(e: any, index: number) {
		const { fields } = this.props;

		e.preventDefault();

		fields.remove(index);
	}

	renderButtons(index: number) {
		const { fields } = this.props;

		if (index === fields.length - 1) {
			return <EmailButton onClick={this.addInvite}><Plus /></EmailButton>;
		}
		return <EmailButton 
			is_trash 
			onClick={e => this.removeInvite(e, index)}
		>
			<Close />
		</EmailButton>;
	}

	render() {
		const { fields } = this.props;

		return <React.Fragment>
			{fields.map((invite, index) => {
				const is_last = index === fields.length - 1;
				
				return <FieldGroup key={index} first_child={index === 0}>
					<Field
						label="First name"
						type='text'
						name={`${invite}.firstname`}
						component={SimpleFormField}
						tooltip={false}
						validate={is_last ? undefined : fieldLevelRequired}
						placeholder={"First name"}
					/>
					<Field
						label="Email Address"
						type='text'
						name={`${invite}.email`}
						component={SimpleFormField}
						tooltip={false}
						validate={is_last ? fieldLevelEmail : [fieldLevelRequired, fieldLevelEmail]}
						placeholder={"Email address"}
					/>
					{this.renderButtons(index)}
				</FieldGroup>;
			})}
		</React.Fragment>;
	}
}

export default EmailFields;