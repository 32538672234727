// @flow
import { createReducer } from "redux-act";

import * as actions from "../../actions";
import type {
	TUiGameSelectionReducer,
	TUiGameSelectionFavourite
} from "../../types/store/uiGameSelectionReducer";

const initial_state: TUiGameSelectionReducer = {
	favourite: {}
};

const uiGameSelection = createReducer({}, initial_state);

uiGameSelection.on(
	actions.uiGameSelection.setFavourite,
	(state, { user_id, id, game, type }: TUiGameSelectionFavourite ) => ({
		...state,
		favourite: {
			...state.favourite,
			[user_id]: {
				id, game, type
			}
		}
	})
);

uiGameSelection.on(
	actions.uiGameSelection.clearFavourite,
	(state, { user_id }) => ({
		...state,
		favourite: {
			...state.favourite,
			[user_id]: null
		}
	})
);

export default uiGameSelection;