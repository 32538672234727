// @flow
import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import styled from "styled-components";
import _, { isEmpty } from "lodash";
import { compose } from "redux";

import * as actions from "../../../modules/actions";


import * as selectors from "../../../modules/selectors";
import type { 
	TRound, 
	TPLeague, 
	TRoundsById, 
	TPlayersById, 
	TCoachPlayerStatsById,
	TDraftTeamsById
} from "../../../modules/types";
import { below, above } from "../../../assets/css/media";
import colors from "../../../assets/css/colors";

import TwoColumnLayout from "../../../components/TwoColumnLayout";
import JsonFetcher from "../../../components/utils/JsonFetcher";
import withRoundsSelector from "../../../components/utils/withRoundsSelector";
// import RoundSelector from '../../../components/RoundSelector';
import PageContentWrapperUnstyled from "../../../components/PageContentWrapper";
import { PageTitle } from "../../../components/PageTitle";
import Footer from "../../../components/Footer";
import Ad from "../../../components/Ad";
import CellsLarge from "../../../components/Icons/CellsLarge";
import Bracket from "../../../components/Icons/Bracket";
import {
	WidgetNews,
	WidgetRfaOrder,
	WidgetSpacer,
	TradeContent,
	AdsContainer,
	AdFantasyCoach,
	Preloader,
	withDraftCore,
} from "../../../components";
import PageSubTitle from "../../../components/PageSubTitle";
import Fixtures from "../../../components/Fixtures/Fixtures";
import H2HLadder from "../../../components/H2HLadder";
import { ButtonPrimaryLink } from "../../../components/ButtonPrimary";
import MatchCentreBar from "../../../components/MatchCentreBar";
import withCoachesBox from "../../../components/utils/withCoachesBox";
import LeagueGameBar from "../../../components/LeagueGameBar";
import Premiers from "../../../components/Premiers";
import FinalsModal from "../../../components/Fixtures/FinalsModal";

import {
	getDefaultDraftLeagueRoute,
	getLeagueRounds,
	getLeagueRoundAliases,
	getLeagueLadderRound,
	getLeagueFinalFullNames,
	hasLeagueFinished,
	getWinners
} from "../../../helpers/league";
import { isMatchingPathnames } from "../../../utils/url";
import { isAllTrue } from "../../../helpers";
import TransactionHistoryRFARow from "./transactions/transactionHistory/transactionHistoryRFARow";

export const PageSubTitleWithButton = styled(PageSubTitle)`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;


const FinalsInfoButton = styled.button`
	outline: none;
	color: #1D4073;
	font-family: SourceSansPro;
	font-size: 12px;
	font-weight: 400;
	line-height: 1;
	cursor: pointer;
	width: auto;
	text-align: left;
	text-decoration: underline;
	border: none;
	padding: 0;
	background-color: transparent;
	margin: 5px 0;
`;


// const HIDE_FINALS_BRACKET = false;
const ListBracketButton = styled.button`
	width: 40px;
	height: 40px;
	border: none;
	padding: 0;
	cursor: pointer;
	background-color: transparent;
	svg{
		path{
			fill: #CAD2D8 
		}
	}
	svg.bracket{
		width: 28px;
		height: 22px;
		
	}
	svg.list{
		width: 28px;
		height: 28px;
		
	}
	${({ is_active }) => is_active && `
		background-color: #EDF0F4;
		svg{
			path{
				fill: ${colors.primary.primary};
			}
		}
	`}
	
`;
const PageContentWrapper = styled(PageContentWrapperUnstyled)`
	${TradeContent} {
		${below.tablet`
			padding: 0 0 10px;
		`}
	}
`;

// const BelowTabletWrapper = styled.div`
// 	${above.tablet`
// 		display: none;
// 	`}
// `;
// const AboveTabletWrapper = styled.div`
// 	${below.tablet`
// 		display: none;
// 	`}
// `;



const FavouriteButton = styled.button`
	padding: 1px 10px;
	width: fit-content;
	height: 20px;
	border-radius: 2px;
	background-color: ${({ is_favourite }) => is_favourite ? "#5410A2" : "#fff"};
	color: ${({ is_favourite }) => is_favourite ?  "#fff" : "#5410A2"};
	border: ${({ is_favourite }) => is_favourite ?  "none" : "1px solid #5410A2"};
	font-family: SourceSansPro;
	font-weight: 400;
	text-align: center;
	margin-top: 3px;
	margin-left: 10px;
	cursor: pointer;
	outline: none;
	${below.tablet`
		display: none;
	`}
`;
const TitleFavouriteWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-around;

`;

const StyledButtonPrimaryLink = styled(ButtonPrimaryLink)`
	border: none;
	background-color: transparent;
	color: #1D4073;
	font-weight: 400;
	text-decoration: underline;
	:hover:not(:disabled) {
		background-color: transparent;
    color: #1D4073;

  }
	${below.tablet`
			display: none;
	`}
`;

const StyledLinkFullWidth = styled.a`
	${above.tablet`
		display: none;
	`}
	width: 100%;
	height: 40px;
	color: #fff;
	background-color: #1D4073;
	font-family: SourceSansPro;
	font-weight: 600;
	font-size: 12px;
	text-decoration: none;
	display: flex;
	align-items: center;
	justify-content: space-around;
	margin-bottom: 5px;
`;

const PageHeaderWrapper = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	align-items: center;
	justify-content: space-between;

`;

const ListBracketWrapper = styled.div`
	align-self: flex-start;
	display: flex;
	flex-direction: row;

`;

type Props = {
	selected_league_id: number,
	ladder_teams_by_id: TDraftTeamsById,
	ladder_teams_ordered: Array<Object>,
	fetchLeague: typeof actions.leagueDraft.showDraftLeague,
	fetchLadder: typeof actions.leagueDraft.ladderDraftLeague,
	fetchAllCustomStats: Function,
	is_pending_show: boolean,
	is_pending_ladder: boolean,
	user_id: number,
	rounds_ordered: Array<Object>,
	rounds_by_id: TRoundsById,
	selected_round_id: number,
	setSelectedRoundId: Function,
	league: TPLeague,
	has_assistant_coach: boolean,
	coach_players_by_id: TCoachPlayerStatsById,
	match: {
		params: {league_id: number}
	},
	location: {
		pathname: string
	},
	last_complete_round: TRound,
	actual_round: TRound,
	players_by_id: TPlayersById,
	fetchTransactionHistory: Function,
	transaction_history: Array<Object>,
	rounds_with_matches: Array<Object>,
	setFavourite: Function,
	clearFavourite: Function,
	favourite: Object,
  is_finals: boolean,
}

type State = {
	active_view: string,
	is_finals_modal_open: boolean,
}

class LeagueOverviewPage extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);

		_.bindAll(this, [
			"handleActiveViewToggle",
			"setActiveViewToBracket",
			"handleFinalsModal"
		]);
	}
	state = {
		active_view: this.props.is_finals ? "bracket" : "list",
		is_finals_modal_open: false,
	};
	componentDidMount() {
		this.fetchLeague();
		this.fetchTransactions();


	}

	componentDidUpdate(prev_props) {
		const { selected_league_id, selected_round_id, 
			fetchAllCustomStats, is_finals, league } = this.props;
		const isLeagueIdReady = isAllTrue([
			Boolean(selected_league_id), 
			selected_league_id !== prev_props.selected_league_id,
			Boolean(prev_props.selected_league_id)
		]);
		const isSelectedRoundChangedAndNot0 = isAllTrue([
			selected_round_id !== prev_props.selected_round_id,
			Boolean(prev_props.selected_round_id)
		]);
		const isLeagueCustomScoring =  isAllTrue([league, Boolean(league.custom_scoring_enabled)]);
		if (isLeagueIdReady) {
			this.fetchLeague();
			if(isAllTrue([league, Boolean(league.custom_scoring_enabled)])){
				fetchAllCustomStats(selected_league_id);
			}
		}
		else if (isSelectedRoundChangedAndNot0 
			&& isLeagueCustomScoring
		) {
			fetchAllCustomStats(selected_league_id);
			
		}
		if(prev_props.is_finals !== is_finals){
			return is_finals === true ? this.setState({ active_view: "bracket" }) : null;
		}

		this.setSelectedRoundIdIfOutsideLeagueRounds(prev_props);
	}

	setSelectedRoundIdIfOutsideLeagueRounds(prev_props) {
		const { selected_league_id, league, rounds_by_id, setSelectedRoundId } = this.props;
		if (
			selected_league_id !== prev_props.selected_league_id
			|| league !== prev_props.league
			|| (!_.isEmpty(rounds_by_id) && _.isEmpty(prev_props.rounds_by_id))
		) {
			setSelectedRoundId(this.league_selected_round);
		}
	}
	handleActiveViewToggle() {
		const newValue = this.state.active_view === "list" ? "bracket" : "list";
		this.setState({ active_view: newValue });
	}
	setActiveViewToBracket() {
		this.setState({ active_view: "bracket" });
	}

	handleFinalsModal() {
		this.setState({ is_finals_modal_open: !this.state.is_finals_modal_open });
	}

	/**
	 * Sometimes the active round which is the selected one by deafult will fall outside
	 * the leagues allowed rounds, in this case we must set the selected round to one the
	 * league is playing
	 */
	get league_selected_round() {
		const { selected_round_id, league, rounds_by_id } = this.props;

		if (selected_round_id && league && !isEmpty(rounds_by_id)) {
			const start_round = _.first(this.league_rounds).id;
			const end_round = _.last(this.league_rounds).id;

			if ( selected_round_id < start_round) {
				return start_round;
			}
			else if (selected_round_id > end_round) {
				return end_round;
			}

			return selected_round_id;
		}

		return  null;
	}

	get league_current_round_fixtures() {
		const { league, selected_round_id } = this.props;
		return selected_round_id ?
			_.get(league, `fixture.${selected_round_id}`, []): [];
	}

	get is_league_bye_round() {
		const {
			rounds_by_id,
			league,
			selected_round_id
		} = this.props;

		if (!isEmpty(rounds_by_id) && selected_round_id && league) {
			const round = rounds_by_id[selected_round_id];
			return Boolean(league.bye_rounds) ? false : round.is_bye;
		}

		return false;
	}

	get league_rounds() {
		const {
			rounds_ordered,
			league
		} = this.props;

		return getLeagueRounds(rounds_ordered, league);
	}

	get finals_rounds() {
		const {
			rounds_ordered,
			league
		} = this.props;

		return getLeagueRoundAliases(league, rounds_ordered);
	}

	get finals_names() {
		const {
			rounds_ordered,
			league
		} = this.props;

		return getLeagueFinalFullNames(league,  rounds_ordered);
	}

	get league_finished() {
		const { league, selected_round_id, rounds_by_id } = this.props;
		const round = _.get(rounds_by_id, selected_round_id);

		return hasLeagueFinished(league, round);
	}
	get is_finals() {
		const { selected_round_id } = this.props;
		return !_.isEmpty(_.get(this.finals_names, selected_round_id));
	}

	getFinalsName(round: number) {
		return _.get(this.finals_names, round, "");
	}

	fetchLadder() {
		const { fetchLadder, selected_league_id, selected_round_id } = this.props;
		fetchLadder({
			league_id: selected_league_id,
			round: selected_round_id
		});
	}

	fetchLeague() {
		const { fetchLeague, selected_league_id } = this.props;
		fetchLeague({
			id: selected_league_id
		});
	}

	fetchTransactions() {
		const { fetchTransactionHistory, selected_league_id, selected_round_id } = this.props;
		fetchTransactionHistory({
			league_id: selected_league_id,
			round_id: selected_round_id,
		});
	}

	renderFixturesHeading() {
		const { league, selected_round_id } = this.props;
		return (
			<PageSubTitleWithButton>
				{
					this.getFinalsName(selected_round_id)
					|| `Round ${selected_round_id || 1} Fixtures`
				}
				{league && (
					<StyledButtonPrimaryLink no_hover to={`/draft/league/${league.id}/fixtures`}>
						View full Fixtures
					</StyledButtonPrimaryLink>
				)}
					
				
			</PageSubTitleWithButton>
		);
	}

	renderLadderHeading() {
		const { league, selected_round_id, last_complete_round = {} } = this.props;

		const ladder_round_id =
			getLeagueLadderRound(league, selected_round_id, last_complete_round);

		const is_finals = !_.isEmpty(this.getFinalsName(selected_round_id));

		return (
			<PageSubTitleWithButton>
				{is_finals ? "Final" : `Round ${ladder_round_id}` } Ladder
				{league && (
					<StyledButtonPrimaryLink to={`/draft/league/${league.id}/ladder`}>
						View full Ladder
					</StyledButtonPrimaryLink>
				)}
			</PageSubTitleWithButton>
		);
	}

	renderTransactionsHeading() {
		const { selected_league_id } = this.props;
		return (
			<PageSubTitleWithButton>
				Transactions
				{selected_league_id && (
					<StyledButtonPrimaryLink 
						to={`/draft/league/${selected_league_id}/transactions`}>
						View all Transactions
					</StyledButtonPrimaryLink>
				)}
			</PageSubTitleWithButton>
		);
	}

	get winners() {
		const { league, ladder_teams_ordered, rounds_by_id, selected_round_id } = this.props;
		const round = _.get(rounds_by_id, selected_round_id);
		return getWinners(league, round, ladder_teams_ordered);
	}
	get is_fixture_data_loaded() {
		const { ladder_teams_by_id,
			league,
			actual_round,
			players_by_id,
			ladder_teams_ordered } = this.props;
		if(!ladder_teams_by_id || 
      !league || 
      !actual_round || 
      !players_by_id || 
      !ladder_teams_ordered){
			return false;
		}
		return true;
	}

	getPremier(consolation: boolean = false) {
		const { ladder_teams_ordered } = this.props;
		const winners = this.winners;

		return _.find(
			ladder_teams_ordered,
			{ id: consolation ? winners.consolation_premier : winners.premier }
		);
	}

	renderPremiers() {
		const premier = this.getPremier();
		return premier && <Premiers team={premier} />;
	}

	renderFixturesSection() {
		const {
			ladder_teams_by_id,
			league,
			rounds_with_matches,
			has_assistant_coach,
			coach_players_by_id,
			selected_round_id,
			actual_round,
			players_by_id,
			ladder_teams_ordered,
			user_id,
			is_finals
		} = this.props;
		const { is_finals_modal_open } = this.state;
		return (
			<React.Fragment>
				{ this.renderPremiers() }
				{ this.renderFixturesHeading() }
				{is_finals && 
					<FinalsInfoButton onClick={this.handleFinalsModal}>
						How do finals work?
					</FinalsInfoButton>
				}
				{is_finals_modal_open &&
					<FinalsModal league={league} toggleModal={this.handleFinalsModal} />
				}
				
				<Fixtures
					compact
					league={league}
					active_view={this.state.active_view}
					ladder_teams_by_id={ladder_teams_by_id}
					ladder_teams_ordered={ladder_teams_ordered}
					rounds_with_matches={rounds_with_matches}
					has_assistant_coach={has_assistant_coach}
					coach_players_by_id={coach_players_by_id}
					selected_round_id={selected_round_id}
					is_league_bye_round={this.is_league_bye_round}
					league_current_round_fixtures={this.league_current_round_fixtures}
					actual_round={actual_round}
					players_by_id={players_by_id}
					finals={this.finals_names}
					user_id={user_id}
					overview_page={true}
					exclude_top_heading
					setActiveViewToBracket={this.setActiveViewToBracket}
				/>
				<StyledLinkFullWidth 
					href={`/draft/league/${league.id}/fixtures`}> 
					<p>View full fixture</p> </StyledLinkFullWidth>
			</React.Fragment>
		);
	}

	renderLadderSection() {
		const {
			ladder_teams_ordered,
			ladder_teams_by_id,
			rounds_by_id,
			selected_round_id,
			user_id,
			league
		} = this.props;
		ladder_teams_ordered
			.sort((a,b) => a.rank_selected_round - b.rank_selected_round);
		return (
			<React.Fragment>
				{this.renderLadderHeading()}
				{/* $FlowFixMe */}
				<H2HLadder
					compact
					user_id={user_id}
					league={league}
					ladder_teams_ordered={ladder_teams_ordered}
					ladder_teams_by_id={ladder_teams_by_id}
					rounds_by_id={rounds_by_id}
					selected_round_id={selected_round_id}
					league_current_round_fixtures={this.league_current_round_fixtures}
					only_show_finals
					{...this.winners}
				/>
				<StyledLinkFullWidth 
					href={`/draft/league/${league.id}/ladder`}> 
					<p>View ladder history</p> </StyledLinkFullWidth>
			</React.Fragment>
		);
	}

	renderTransactions() {
		const {
			players_by_id,
			transaction_history,
			selected_league_id
		} = this.props;

		return !_.isEmpty(transaction_history) && <React.Fragment>
			{this.renderTransactionsHeading()}
			<TransactionHistoryRFARow
				players_by_id={players_by_id}
				trade={transaction_history}
				compressed
			/>
			<StyledLinkFullWidth 
				href={`/draft/league/${selected_league_id}/transactions`}> 
				<p>View full transactions</p> </StyledLinkFullWidth>
		</React.Fragment>;
	}

	renderMainSection() {

		const {
			league,
			setFavourite, 
			clearFavourite,
			selected_league_id,
			user_id,
			favourite
		} = this.props;
		const current_league_object = {
			id: selected_league_id,
			game: "draft",
			type: "league",
			user_id: user_id
		};
		const is_league_favourite = (favourite[user_id] === null || 
			favourite[user_id] === undefined) ? 
			false : favourite[user_id].id === selected_league_id;
		
		return (
			<div>
				<PageHeaderWrapper>
					<TitleFavouriteWrapper>
						<PageTitle>
						League Overview for {league && league.name} 
						</PageTitle>
						{is_league_favourite ? 
							<FavouriteButton 
								is_favourite
								onClick={() => clearFavourite({ user_id: user_id })}>
									Favourite
							</FavouriteButton> : 
							<FavouriteButton 
								onClick={() => setFavourite(current_league_object)}>
								Favourite
							</FavouriteButton> 
						}
					</TitleFavouriteWrapper>
					
					{league.finals_format !== 2 &&
					<ListBracketWrapper>
						<ListBracketButton
							is_active={this.state.active_view === "list"}
							onClick={() => this.handleActiveViewToggle()}
						>
							<CellsLarge className="list" />
						</ListBracketButton>
						<ListBracketButton
							is_active={this.state.active_view === "bracket"}
							onClick={() => this.handleActiveViewToggle()}
						
						>
							<Bracket className="bracket"/>
						</ListBracketButton>
					</ListBracketWrapper>}
					
					
					

				</PageHeaderWrapper>
				{this.renderFixturesSection()}
				{this.renderLadderSection()}
				{this.renderTransactions()}
				<AdFantasyCoach type="leaderboard" small_screen_only />
			</div>
		);
	}

	renderAside() {
		 return (
			<div>
				<Ad type="mrec" />
				<WidgetSpacer />
				<WidgetRfaOrder />
				<WidgetSpacer />
				<WidgetNews />
				<AdFantasyCoach type="mrec" large_screen_only />
			</div>
		 );
	}

	renderAdsContainer() {
		return <React.Fragment>
			<AdsContainer>
				<Ad />
			</AdsContainer>
		</React.Fragment>;
	}

	render() {
		const {
			league,
			location,
			is_pending_show,
		} = this.props;

		const isLoading = is_pending_show || !this.is_fixture_data_loaded;

		if (!_.isEmpty(league)) {
			const default_league_route = getDefaultDraftLeagueRoute(league);

			if (
				default_league_route &&
				isMatchingPathnames(default_league_route, location.pathname) === false
			) {
				return <Redirect to={default_league_route} />;
			}
		}

		return (
			<React.Fragment>
				<JsonFetcher
					fetch={["rounds", "coach_players", "players"]}
					interval={90000} /* Fetch new data once every 90s */
				/>

				<LeagueGameBar no_fetch={true} />

				{this.renderAdsContainer()}


				{isLoading  ? <Preloader />:
					<PageContentWrapper className="playwright-mask-hidden">
						
						

						<TwoColumnLayout>
							{this.renderMainSection()}
							{
								this.renderAside() 
							}
							
						</TwoColumnLayout>
					</PageContentWrapper>}
				<MatchCentreBar />
				<Footer show_key />
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state, props) => {
	const selected_league_id = props.match.params.league_id;
	return {
		selected_league_id,
		league: selectors.leagues.getLeague(state, props),
		is_pending_show: selectors.leagues.show.isPending(state),
		is_pending_ladder: selectors.leagues.ladder.isPending(state),
		ladder_teams_ordered: selectors.orderedLadderCalculated(state, props),
		ladder_teams_by_id: selectors.leagues.ladder.getById(state),
		user_id: selectors.getUser(state).id,
		has_assistant_coach: Boolean(selectors.getUser(state).assistant_coach),
		coach_players_by_id: state.players.coach_by_id,
		players_by_id: selectors.players.getExtendedPlayersById(state),
		last_complete_round: selectors.rounds.getLastCompleteRound(state),
		actual_round:  selectors.rounds.getActualRound(state),
		// transaction_history: selectors.leagueDraft.getTransactionsHistoryById(state),
		transaction_history: selectors.getGroupedTransactions(state),
		user: selectors.getUser(state),
		rounds_with_matches: selectors.rounds.getRoundsWithMatches(state),
		favourite: state.uiGameSelection.favourite
	};
};

const mapDispatchToProps = {
	fetchLeague: actions.leagueDraft.showDraftLeague,
	fetchLadder: actions.leagueDraft.ladderDraftLeague,
	fetchTransactionHistory: actions.leagueDraft.fetchTransactionHistory,
	fetchAllCustomStats: actions.fetchAllCustomStats,
	setFavourite: actions.uiGameSelection.setFavourite,
	clearFavourite: actions.uiGameSelection.clearFavourite
};

export const LeagueOverview = compose(
	withRoundsSelector,
	withCoachesBox,
	withDraftCore,
	connect(
		mapStateToProps,
		mapDispatchToProps
	)
)(LeagueOverviewPage);

export default LeagueOverview;