// @flow
import styled from "styled-components";
import { Link } from "react-router-dom";
import colors from "../../assets/css/colors";
import ButtonPrimary from "../ButtonPrimary";

export const CoachButton = styled(ButtonPrimary)``;

CoachButton.displayName = "CoachButton";
CoachButton.defaultProps = {
	...colors.buttons.coach
};

export const CoachButtonLink = styled(ButtonPrimary.withComponent(Link))`
	display: flex;
	align-items: center;
	justify-content: center;
	text-decoration: none;
`;

CoachButtonLink.displayName = `${CoachButtonLink.displayName || "Button"}Link`;

CoachButtonLink.defaultProps = {
	...colors.buttons.coach
};

export default CoachButton;