// @flow
import styled from "styled-components";
import colors from "../../assets/css/colors";

import { below } from "../../assets/css/media";

export const ButtonLoadMore = styled.button`
	width: 100%;
	background: ${colors.buttons.loadMore};
	height: 60px;
	margin: 10px 0;
	display: flex;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	font-size: 16px;
	line-height: 60px;
	font-family: TitilliumUpright;
	color: ${colors.buttons.loadMoreText};
	outline: none;
	border: none;
	cursor: pointer;
	border-radius: 2px;
	padding: 0;
	font-weight: 400;
	transition: all 0.1s linear;

	text-transform: uppercase;

	box-shadow: 0px 2px ${colors.secondary.accentGrey};

	svg {
		margin-left: .25em;
	}
	&:hover {
		background: ${colors.buttons.loadMoreText};
		color: ${colors.buttons.loadMore};
	}
	&:active {
		box-shadow: none;
		transform: translateY(2px);
	}
	${below.tablet`
		width: calc(100% - 20px);
		position: relative;
		margin: 10px auto;
	`};
`;

export default ButtonLoadMore;