/* eslint max-len: 0 */
// @flow
import React from "react";
import SvgIconContainer from "../SvgIconContainer";

export default (
	{
		unit = "em",
		...rest
	}: Object = {}) => (
	<SvgIconContainer viewBox="0 0 24 24" {...rest} unit={unit}>
		<title>Flip</title>
		<path d="M12,19.0322581 C13.8332903,19.0322581 15.5063226,18.3563871 16.7945806,17.2461935
			L18.4374194,18.8890323 C16.7256774,20.4180645 14.4750968,21.3548387 12,21.3548387
			C6.78580645,21.3548387 2.54632258,17.2291613 2.34193548,12.0645161
			L8.73967565e-13,12.0645161 L3.48387097,8.19354839 L6.96774194,12.0645161
			L4.66451613,12.0645161 C4.86735484,15.9455484 8.06864516,19.0322581 12,19.0322581 Z
			M21.6572903,11.2903226 L24,11.2903226 L20.516129,15.1612903 L17.0322581,11.2903226
			L19.3354839,11.2903226 C19.1326452,7.40929032 15.9305806,4.32258065 12,4.32258065
			C10.1659355,4.32258065 8.49290323,4.99845161 7.20464516,6.10864516 L5.56180645,4.46580645
			C7.27354839,2.93677419 9.52412903,2 11.9992258,2 C17.2134194,2 21.452129,6.12722581
			21.6572903,11.2903226 Z" />

	</SvgIconContainer >
);