// @flow
import styled from "styled-components";
import * as React from "react";
import { below } from "../../assets/css/media";
//import colors from '../../assets/css/colors';
import {TPlayer} from "../../modules/types";
import {
	PlayerTableCell,
	PlayerInfo,
	UndoButton, TradePlayerCost,
} from "../";
import { currencyFormat } from "../../utils/currencyFormat/index";
import Undo from "../Icons/Undo";
import { isAllTrue, isEditTradePage } from "../../helpers";
import colors from "../../assets/css/colors";
import { TradeCell } from "./TradeCell";

const OutCellStyled = styled(TradeCell)`
	
	${({isLocked}) => isLocked && `
		opacity: 0.7;
	`}

	text-align: right;
	flex-direction: row-reverse;
	padding-right: 20px;
	${below.desktop`
		padding-right: 0;
		height: 50px;
	`}
	${PlayerTableCell} {
		justify-content: flex-end;
	}
	.player-info {
		flex-direction: row-reverse;
		text-align: right;
		.player-avatar {
			margin-left: 5px;
		}
		> div {
			align-items: flex-end;
		}
	}
	.filled-icon {
		margin-left: 4em;
		margin-right: auto;
		${below.desktop`
			margin-left: 1em;
		`}
	}
	${({isEditPage}) => isEditPage && `
		background-color: #F0F3F7;
		border: 1px solid #CAD2D8;
		padding-right: 0;
		height: 62px;
		.undo-button{
			svg{
				background: ${colors.primary.primary};
			}
		}
		
	`}
`;
type TCell = {
	removePlayer?: Function,
	player: TPlayer,
	with_cost?: boolean,
	is_compressed?: boolean,
	is_classic: boolean,
	updateTradeEdit?: Function,
	isPlayerChangeAvailable?: boolean
};
export const TradeOutCell = ({ 
	player, 
	removePlayer, 
	with_cost = false, 
	is_compressed,
	is_classic,
	updateTradeEdit,
	isPlayerChangeAvailable,
}: TCell) => {
	const isLocked = isEditTradePage() && !isPlayerChangeAvailable;
	const isEditPage = Boolean(updateTradeEdit);
	return (
		<OutCellStyled isLocked={isLocked} isEditPage={isEditPage}>
			<PlayerTableCell
				className="player-table-cell"
				width="auto"
				height="80px"
				justify="flex-start"
			>
				<PlayerInfo
					player={player}
					className="player-info"
					is_compressed={is_compressed}
					is_classic={isEditPage ? true : is_classic}
				/>
				{isAllTrue([Boolean(removePlayer), !isEditPage])  && (
					<UndoButton
						onClick={() => {
							if(updateTradeEdit){
								updateTradeEdit();
							}
							if(removePlayer){
								removePlayer({ id: player.id });
							}
						} }
					>
						<Undo color="#fff" />
					</UndoButton>
				)}
			</PlayerTableCell>
			{isAllTrue([Boolean(removePlayer), !isLocked, isEditPage])  && (
				<UndoButton
					className="undo-button"
					style={{marginRight: 0, marginLeft: '20px'}}
					onClick={() => {
						if(updateTradeEdit){
							updateTradeEdit();
						}
						if(removePlayer){
							removePlayer({ id: player.id });
						}
					} }
				>
					<Undo color="#fff" />
				</UndoButton>
			)}
			{with_cost && (
				<TradePlayerCost margin="left" is_double>
					{currencyFormat({ input: player.cost })}
				</TradePlayerCost>
			)}
		</OutCellStyled>
	);
};

export default TradeOutCell;