// @flow
import styled from "styled-components";
import colors from "../../assets/css/colors";

export const TableFlexCell = styled.div`
	display: flex;
	font-size: 12px;
	color: ${colors.primary.darkGrey};
	align-items: ${({ align }) => align || "center"};
	justify-content: ${({ justify }) => justify || "center"};
	min-width: ${({ width }) => width || "auto"};
	position: ${({ position }) => position || "static"};
	height: ${({ height }) => height || "100%"};
	flex-basis: ${({ basis, width }) => basis || width || "auto"};
`;

export default TableFlexCell;