// @flow
import _ from "lodash";
import { call, put, select, takeLatest } from "redux-saga/effects";
import * as actions from "../../actions";
import * as selectors from "../../selectors";
import * as helpers from "../../../helpers";
import type { TTeamClassicLineup } from "../../types/teamClassic";
import { isEditTradePage } from "../../../helpers";

type Action = {
	payload: Object
};

const checkErrorsIn = errors => {
	if (_.size(errors)) {
		throw new Error(_.get(_.first(errors), "text", "Unknown error"));
	}
};
const DEFAULT_FORMATION = "6-8-2-6/2-2-2-2";
const MAX_BENCH_PLAYERS = 8;
export const FORMATIOS = {
	"1": "6-8-2-6/3-2-1-2",
	"2": "6-8-2-6/2-3-1-2",
	"3": DEFAULT_FORMATION,
	"4": "6-8-2-6/2-2-1-3",
};

export const convertLineupForBE = (current_lineup: TTeamClassicLineup) => {
	const lineup = _.cloneDeep(current_lineup);
	const utility = _.get(lineup, "bench.utility", null);
	let formation = DEFAULT_FORMATION;

	if (utility && utility.position) {
		lineup.bench[utility.position].push(utility.id);
		delete lineup.bench.utility;
		formation = FORMATIOS[utility.position];
	}

	return {
		lineup,
		formation,
		utility,
	};
};

const handleLeaderChange = (is_leader, lineupLeader) => {
	return is_leader ? 0 : lineupLeader;
};
// This is not working, because  Initial lineup changing

const simulateLineupFormationAfterTrade = (lineup, trade) => {
	const pure_bench = _.omit(lineup.bench, ["emergency", "utility"]);

	let bench_formation = _.map(pure_bench, (line, key) => {
		const pos = parseInt(key, 10);
		const current_size = line.length;
		if (pos === parseInt(trade.in_position, 10) && trade.is_bench) {
			return current_size + 1;
		}
		if (pos === parseInt(trade.position, 10) && current_size > 2 && trade.is_bench) {
			return current_size - 1;
		}
		return current_size;
	});

	if (_.sum(bench_formation) > MAX_BENCH_PLAYERS) {
		bench_formation = _.map(bench_formation, (players_num, index) => (
			index === 2 && players_num === 2 ? players_num - 1 : players_num
		));
	}

	if (_.sum(bench_formation) < MAX_BENCH_PLAYERS) {
		bench_formation = _.map(bench_formation, (players_num, index) => (
			index === 2 && players_num === 1 ? players_num + 1 : players_num
		));
	}

	const utility = _.get(lineup, 'bench.utility');
	let formation = `6-8-2-6/${bench_formation.join("-")}`;

	if(utility){
		const utilityPos = _.get(utility, 'position', 0);
		formation = FORMATIOS[utilityPos] || formation;
	}

	return formation;
};

const addUtilityPlayer = ({
	current_lineup,
	selected_utility_position,
	id,
	player_position,
	position,
	current_formation
}) => {
	const need_added_utility = (selected_utility_position && !current_lineup.bench.utility.id);

	if (need_added_utility || !position) {
		current_lineup.bench.utility = {
			id,
			position: player_position[0]
		};
	}

	const { lineup, formation } = convertLineupForBE(current_lineup);

	return {
		lineup,
		formation: formation || current_formation,
		need_added_utility
	};
};

function *tryToSaveClassicTeam(new_lineup, lineup, team, formation) {
	const { complete_first_time, start_round, complete } = team;
	const actual_round = yield select(selectors.rounds.getActualRound);
	const lockout = _.get(actual_round, "lockout");

	/**
	 * -- Preseason: users can add and remove players whenever, and should be able to save
	 *    afterwards, regardless of whether team is complete
	 * -- In partial lockout of teams 1st round, can only save when team is complete
	 */
	const is_new_team_complete = helpers.isTeamComplete({
		lineup: new_lineup,
		complete_first_time
	});

	if (start_round <= actual_round.id) {
		if (lockout === "partial") {
			/**
			 * In a partial lockout,
			 * only save the team if the new lineup is complete,
			 * or the team wasn't complete in the first place
			 */
			if (is_new_team_complete || !complete) {
				yield put(actions.saveMyClassicTeam({
					lineup: new_lineup, old_lineup: lineup, formation
				}));
			}
			// Don't save if team isn't complete
			return;
		}
	}

	yield put(actions.saveMyClassicTeam({ lineup: new_lineup, old_lineup: lineup, formation }));
}

export const createSagas = (API: Object): Object => {

	function* fetchMyClassicTeam({ payload }: Action) {
		try {
			const { result, errors } = yield call(API.teamsClassic.show_my, payload);
			checkErrorsIn(errors);

			yield put(actions.fetchMyClassicTeamSuccess(result));
		}
		catch (error) {
			yield put(actions.fetchMyClassicTeamFailed(error.message));
		}
	}

	function* fetchClassicTeam({ payload }: Action) {
		try {
			const { result, errors } = yield call(API.teamsClassic.show, payload);
			checkErrorsIn(errors);

			yield put(actions.fetchClassicTeamSuccess(result));
		}
		catch (error) {
			yield put(actions.fetchClassicTeamFailed(error.message));
		}
	}

	function* fetchClassicTeamSnapshot({ payload }: Action) {
		try {
			const { result, errors } = yield call(API.teamsClassic.snapshot, payload);
			checkErrorsIn(errors);

			yield put(actions.fetchClassicTeamSnapshotSuccess(result));
		}
		catch (error) {
			yield put(actions.fetchClassicTeamSnapshotFailed(error.message));
		}
	}

	function* addPlayerToMyClassicTeam({
		payload: {
			id,
			position,
			player_position,
			selected_utility_position,
			no_update
		}
	}: Action) {
		try {
			const team = yield select(selectors.getMyClassicTeam);
			const { lineup: current_lineup, formation: current_formation } = team;
			const actualed_position = position || player_position[0];

			const { lineup, formation, need_added_utility } = addUtilityPlayer({
				current_lineup,
				selected_utility_position,
				id,
				player_position,
				position,
				current_formation
			});

			let new_lineup = _.cloneDeep(lineup);
			const line = new_lineup[actualed_position];
			
		
			const zero_index = line.indexOf(0);
			const bench_line = new_lineup.bench[actualed_position];
			const zero_bench_index = bench_line.indexOf(0);

			if (!need_added_utility) {
				if (helpers.isAllTrue([
					Boolean(~zero_index),
					position
				])) {
					line[zero_index] = id;
					new_lineup = {
						...new_lineup,
						[actualed_position]: line
					};
				}
				else if (~zero_bench_index) {
					bench_line[zero_bench_index] = id;

					new_lineup = {
						...new_lineup,
						bench: {
							...new_lineup.bench,
							[actualed_position]: bench_line
						}
					};
				}
			}
			const isInLine = new_lineup[actualed_position].includes(id);
			if(helpers.isAllTrue([
				no_update,
				!isInLine
			])){
				new_lineup[position].push(id);
				yield put(actions.addPlayerToMyClassicTeamSuccess({ lineup: new_lineup }));
				return;
			}
			yield put(actions.addPlayerToMyClassicTeamSuccess({ lineup: new_lineup }));
	
			yield tryToSaveClassicTeam(new_lineup, lineup, team, formation);
		}
		catch (error) {
			yield put(actions.addPlayerToMyClassicTeamFailed(error.message));
		}
	}
	function* removePlayerFromMyClassicTeam({ payload }: Action) {
		try {
			const {
				id,
				is_bench,
				is_captain,
				is_vice,
				is_emergency,
				no_update,
			} = payload;
			const current_position = payload.position;

			const team = yield select(selectors.getMyClassicTeam);
			const { lineup: current_lineup } = team;
			const { lineup, utility, formation } = convertLineupForBE(current_lineup);
			const position =
				Boolean(current_position) ? current_position : utility.position;

			let new_lineup = {
				...lineup
			};

			if (is_bench) {
				const line = lineup.bench[position].map(
					player_id => player_id === id ? 0 : player_id
				);

				const new_position = {};
				new_position[position] = line;

				new_lineup = {
					...new_lineup,
					bench: {
						...new_lineup.bench,
						...new_position
					}
				};
				if (is_emergency) {
					const { emergency } = lineup.bench;
					const new_emergency = emergency.map(
						player_id => player_id === id ? 0 : player_id
					);
					new_lineup = {
						...new_lineup,
						bench: {
							...new_lineup.bench,
							emergency: [...new_emergency]
						}
					};
				}
			}

			const line = lineup[position].map(player_id => {
				return player_id === id ? 0 : player_id;
			});
			const captain = handleLeaderChange(is_captain, lineup.captain);
			const vice_captain = handleLeaderChange(is_vice, lineup.vice_captain);
			const new_position = {};
			new_position[position] = line;

			new_lineup = {
				...new_lineup,
				...new_position,
				captain,
				vice_captain
			};
			const isInLine = line.includes(Number(id));
			if(helpers.isAllTrue([
				no_update,
				isInLine
			])){
				new_lineup[position] = line.filter(player => Number(player) !== Number(id));
				yield put(actions.removePlayerFromMyClassicTeamSuccess({ lineup: new_lineup }));
				return;
			}
			if(no_update){
				yield put(actions.removePlayerFromMyClassicTeamSuccess({ lineup: new_lineup }));
				return;
			}

			yield put(actions.removePlayerFromMyClassicTeamSuccess({ lineup: new_lineup }));
	
			yield tryToSaveClassicTeam(new_lineup, lineup, team, formation);
		}
		catch (error) {
			yield put(actions.removePlayerFromMyClassicTeam(error.message));
		}
	}

	function* saveMyClassicTeam({ payload: { old_lineup, lineup, formation } }: Action) {

		try {
			const { result, errors } = yield call(API.teamsClassic.update, { lineup, formation });
			checkErrorsIn(errors);

			if(result) {
				yield put(actions.saveMyClassicTeamSuccess(result));
			}
			else {
				yield put(actions.forceUpdateMyClassicTeam(old_lineup));
			}
		}
		catch (error) {
			yield put(actions.forceUpdateMyClassicTeam(old_lineup));
			yield put(actions.saveMyClassicTeamFailed(error.message));
		}
	}

	function* updateMyClassicTeam({ payload: { old_lineup, lineup, formation } }: Action) {

		try {
			yield put(actions.forceUpdateMyClassicTeam(lineup));
			const { result, errors } = yield call(API.teamsClassic.update, { lineup, formation });
			checkErrorsIn(errors);

			if (result) {
				yield put(actions.saveMyClassicTeamSuccess(result));
			}
		}
		catch (error) {
			yield put(actions.saveMyClassicTeamFailed(error.message));
			yield put(actions.forceUpdateMyClassicTeam(old_lineup));
		}
	}

	function* autoFillMyClassicTeam({ payload }: Action) {
		try {
			const { lineup } = convertLineupForBE(payload.lineup);
			payload.lineup = lineup;

			const { result, errors } = yield call(API.teamsClassic.auto_fill,
				{ ...payload, ...lineup }
			);
			checkErrorsIn(errors);

			yield put(actions.autoFillMyClassicTeamSuccess(result));
		}
		catch (error) {
			yield put(actions.autoFillMyClassicTeamFailed(error.message));
		}
	}

	function* clearMyClassicTeam() {
		try {
			const team = yield select(selectors.getMyClassicTeam);
			const { lineup } = team;
			const { bench, ...lineup_without_bench } = lineup;
			const pure_lineup =
				_.omit(lineup_without_bench, ["captain", "vice_captain"]);
			const pure_bench = _.omit(bench, ["utility"]);
			const toZero = obj => _.chain(obj).mapValues(values => values.map(() => 0)).value();

			yield put(actions.clearMyClassicTeamSuccess({
				lineup: {
					...toZero(pure_lineup),
					captain: 0,
					vice_captain: 0,
					bench: {
						...toZero(pure_bench)
					}
				},
				formation: DEFAULT_FORMATION,
			}));
		}
		catch (error) {
			yield put(actions.clearMyClassicTeamFailed(error.message));
		}
	}

	function* fetchMyClassicTrades({ payload }: Action) {
		try {
			const { result, errors } = yield call(API.teamsClassic.show_trades, payload);
			checkErrorsIn(errors);
			
			yield put(actions.fetchMyClassicTradesSuccess(result));
		}
		catch (error) {
			yield put(actions.fetchMyClassicTradesFailed(error.message));
		}
	}

	function* fetchTradeHistory({ payload }: Action) {
		try {
			const { result, errors } = yield call(API.teamsClassic.show_trades_history, payload);
			checkErrorsIn(errors);

			yield put(actions.fetchTradeHistorySuccess(result));
		}
		catch (error) {
			yield put(actions.fetchTradeHistoryFailed(error.message));
		}
	}

	function* addPlayerOutTrade({ payload }: Action) {
		try {
			const {
				id,
				is_bench,
				position,
				position_index,
			} = payload;
			yield put(actions.updatePlayersOnTrade({
				side: "out",
				id,
				position_index,
				position,
				is_bench
			}));
			const team = yield select(selectors.getMyClassicTeam);
			const initial_lineup = yield select(selectors.getInitialLineup);
			const is_initial_lineup = !_.isEmpty(initial_lineup);
			const { lineup: current_lineup } = team;
			const { lineup } = convertLineupForBE(current_lineup);
			if (!is_initial_lineup) {
				yield put(actions.setInitialLineup(_.cloneDeep(lineup)));
			}
			let new_lineup = _.cloneDeep(lineup);

			if (is_bench) {
				const line = new_lineup.bench[position].map(
					player_id => player_id === id ? 0 : player_id
				);
				new_lineup = {
					...new_lineup,
					bench: {
						...new_lineup.bench,
						[position]: line
					}
				};
			}
			let line = new_lineup[position].map(player_id => {
				if(isEditTradePage() && player_id === id){
					return id;
				}
				return player_id === id ? 0 : player_id;
			}).filter(playerID => playerID !== id);
			// const players_on_trade = yield select(selectors.getPlayersOnTrade);
			if(isEditTradePage()){
			 line = line.filter(playerID => playerID !== 0);
			}
			new_lineup = {
				...new_lineup,
				[position]: line,
			};

			yield put(actions.addFillerPositionByTradeUser(position));
			yield put(actions.forceUpdateMyClassicTeam(new_lineup));
		}
		catch (error) {
			yield put(actions.playerOutTradeFailed(error.message));
		}
	}

	function* addPlayerInTrade({ payload }: Action) {
		yield put(actions.updatePlayersOnTrade({
			side: "in",
			id: payload.id,
			position: payload.position
		}));
	}
	function* addPlayerInTradeAndUpdateTeam({payload}: Action) {
		const {position, id, position_index, is_bench} = payload;
		
		const team = yield select(selectors.getMyClassicTeam);
		const { lineup: current_lineup } = team;
		const { lineup } = convertLineupForBE(current_lineup);

		let new_lineup = _.cloneDeep(lineup);
		if(!new_lineup[position].includes(id) && !is_bench){
			new_lineup[position][position_index] = id;
		}
		if (is_bench) {
			const line = [...lineup.bench[position], id].filter((playerID => playerID !== 0));

			const new_position = {};
			new_position[position] = line;

			new_lineup = {
				...new_lineup,
				bench: {
					...new_lineup.bench,
					...new_position
				}
			};
		}
		yield put(actions.forceUpdateMyClassicTeam(new_lineup));
		yield put(actions.updatePlayersOnTrade({
			side: "in",
			id: payload.id,
			position: payload.position
		}));
	}
	// eslint-disable-next-line complexity
	function* removePlayerOutTrade({ payload }: Action) {
		try {
			const {
				id,
				position,
				is_bench
			} = payload;

			
			const players_on_trade = yield select(selectors.getPlayersOnTrade);
			const trade = players_on_trade.find(({ out, swap_ids }) => {
				return  helpers.isAnyTrue([
					id === out,
					swap_ids.includes(id)
				]);
			});
			yield put(actions.updatePlayersOnTrade({
				side: "out",
				id: 0,
				id_to_remove: id
			}));
			const team = yield select(selectors.getMyClassicTeam);
			const current_initial_lineup = yield select(selectors.getInitialLineup);
			const { lineup: initial_lineup } = convertLineupForBE(current_initial_lineup);

			const pure_lineup = _.omit(initial_lineup, ["bench", "captain", "vice_captain"]);
			const pure_bench = _.omit(initial_lineup.bench, ["emergency"]);
			const { lineup: current_lineup } = team;
			const { lineup } = convertLineupForBE(current_lineup);

			let new_lineup = _.cloneDeep(lineup);

			if (trade) {
				_.each(trade.swap_ids, id => {
					_.each(pure_lineup, (line, pos) => {
						const index = line.indexOf(id);
						if (~index) {
							new_lineup[pos][index] = id;
						}
					});
					_.each(pure_bench, (line, pos) => {
						const index = line.indexOf(id);
						if (~index) {
							new_lineup.bench[pos][index] = id;
						}
					});
				});
			}
			// check it is edit trade page because when we add an out player
			// we set the lineup to filter out this player which is different 
			// to normal trade page where we use values of 0 as placeholders
			// when returning them to lineup on edit page we dont use placeholders
			// just put them back into the lineup so dont want to replace a specific index
			if(!isEditTradePage()){
				_.each(pure_bench, (line, pos) => {
					const index = line.indexOf(id);
					if (~index) {
						new_lineup.bench[pos][index] = id;
					}
				});
				_.each(pure_lineup, (line, pos) => {
					const index = line.indexOf(id);
					if (~index ) {
						new_lineup[pos][index] = id;
					}
				});
			}
			
			if(helpers.isAllTrue([
				position !== 'utility',
				!new_lineup[position].includes(id),
				isEditTradePage(),
				!is_bench,
				!new_lineup[position].includes(0)
			]) 
			){
				new_lineup[position].push(id);
			}
			if(helpers.isAllTrue([
				position !== 'utility',
				!new_lineup[position].includes(id),
				isEditTradePage(),
				!is_bench,
				new_lineup[position].includes(0)
			]) 
			){
				const indexOfZero = new_lineup[position].indexOf(0);
				new_lineup[position][indexOfZero] = id;
			}


			const players_by_id = yield select(selectors.getExtendedPlayersById);
			const playerFull = _.get(players_by_id, id);
			const playerDefaultPos = _.get(playerFull, 'positions[0]');
			const isUtilFilled = _.get(current_lineup, 'bench.utility.id') !== 0;
			if(helpers.isAllTrue([
				isEditTradePage(),
				is_bench,
				position === 'utility' ,
				isUtilFilled
			]) ){
				new_lineup[playerDefaultPos] = [...new_lineup[playerDefaultPos], id];
			}
			if(helpers.isAllTrue([
				isEditTradePage(),
				is_bench,
				position !== 'utility'
			])){
				new_lineup.bench[position] = [id, ...new_lineup.bench[position]];
			}
			
			if(helpers.isAllTrue([
				isEditTradePage(),
				is_bench,
				position === 'utility',
				trade.in === 0
			])){
				new_lineup.bench.utility = {position: playerDefaultPos , id};
			}
			yield put(actions.forceUpdateMyClassicTeam(new_lineup));
		}
		catch (error) {
			yield put(actions.playerOutTradeFailed(error.message));
		}
	}

	function* swapPlayersOutTrade({ payload }: Action) {
		try {
			const {
				swap_in_id,
				swap_trade_id,
				swap_position,
				lineup,
			} = payload;
			yield put(actions.forceUpdateMyClassicTeam(lineup));

			const players_on_trade = yield select(selectors.getPlayersOnTrade);
			const pure_bench = _.omit(lineup.bench, ["emergency", "utility"]);
			let bench_formation = _.map(pure_bench, line => {
				return line.length;
			});

			if (_.sum(bench_formation) > MAX_BENCH_PLAYERS) {
				bench_formation = _.map(bench_formation, (players_num, index) => (
					index === 2 && players_num === 2 ? players_num - 1 : players_num
				));
			}

			if (_.sum(bench_formation) < MAX_BENCH_PLAYERS) {
				bench_formation = _.map(bench_formation, (players_num, index) => (
					index === 2 && players_num === 1 ? players_num + 1 : players_num
				));
			}

			const utility = _.get(lineup, 'bench.utility');
			let formation = `6-8-2-6/${bench_formation.join("-")}`;
			if(utility){
				const utilityPos = _.get(utility, 'position', 0);
				formation = FORMATIOS[utilityPos] || formation;
			}

			const trades = players_on_trade.map(trade => {
				if (trade.out === swap_trade_id || trade.swap_ids.includes(swap_trade_id)) {
					trade.swap_ids.push(swap_in_id);
					trade.swap_formations.push(formation);
					if (swap_position) {
						trade.swap_position = swap_position;
					}
				}
				return trade;
			});

			yield put(actions.updatePlayersOnTradeSuccess(trades));
		}
		catch (e) {
			console.log(e.message);
		}
	}

	function* removePlayerInTrade({ payload }: Action) {
		if(isEditTradePage()){
			// Can comment back in to make empty spots disappear when removing players on trade
			// const players_on_trade = yield select(selectors.getPlayersOnTrade);
			// const trade = players_on_trade.find(trade => {
			// 	return  payload.id === trade.in || trade.swap_ids.includes(payload.id);
			// });
			const team = yield select(selectors.getMyClassicTeam);
			const { lineup } = team;

			let new_lineup = _.cloneDeep(lineup);
			const positionInTeam = payload.position;
			const isUtility = positionInTeam === "";
			const utiltyData = isUtility ? {
				position: new_lineup.bench.utility.position,
				id: payload.id
			}:null;
			
			
			new_lineup = utiltyData ? {
				...new_lineup,
				utility: utiltyData
			}:{
				...new_lineup,
				[positionInTeam]: [...new_lineup[positionInTeam]]
			};
			// if(new_lineup[positionInTeam].includes(0) && trade.out === 0){
			// 	new_lineup[positionInTeam] = new_lineup[positionInTeam].filter(playerID => 
			// 		playerID !== 0);
			// }
			yield put(actions.forceUpdateMyClassicTeam(new_lineup));
			
		}
		yield put(actions.updatePlayersOnTrade({
			side: "in",
			id: 0,
			id_to_remove: payload.id
		}));
	}

	function* updatePlayersOnTrade({ payload: {
		side, id, id_to_remove, position_index, is_bench, position
	} }) {
		try {
			const players_on_trade = yield select(selectors.getPlayersOnTrade);
			const players_by_id = yield select(selectors.players.getExtendedPlayersById);
			let updated = false;
			let trade_with_same_position = {};
			const opp_side = side === "out" ? "in" : "out";
			const isRemove = (id, id_to_remove) => Boolean(id_to_remove && id === id_to_remove);
			const isAdd = (id, id_to_remove) => Boolean(id === 0 && !id_to_remove);
			const isPosIndex = position_index => position_index || position_index === 0;

			const tryToUpdate = trade => {
				if (updated) {
					return trade;
				}
				if (isRemove( trade[side], id_to_remove)) {
					trade[side] = 0;
					if (side === "out") {
						trade = {
							...trade,
							position_index: -1,
							is_bench: false,
							position: 0,
							swap_ids: [],
							swap_formations: [],
							swap_position: 0,
						};
					}

					updated = true;
					return trade;
				}
				if (isAdd( trade[side], id_to_remove)) {
					trade[side] = id;
					if (isPosIndex(position_index)) {
						trade = {
							...trade,
							position_index,
							is_bench,
							position
						};
					}
					updated = true;
				}

				if (side === "in") {
					trade = {
						...trade,
						in_position: position
					};
				}

				return trade;
			};

			const tryToAddWithSamePosition = trade => {
				if (trade[opp_side] === trade_with_same_position[opp_side]) {
					trade[side] = id;
					if (isPosIndex(position_index)) {
						trade = {
							...trade,
							position_index,
							is_bench,
							position
						};
					}
				}

				if (side === "in") {
					trade = {
						...trade,
						in_position: position
					};
				}

				return trade;
			};

			// If it's not a 'remove player from trade' action
			if (!id_to_remove) {
				// Filter down to the trades with only the opposite side filled
				const not_empty_trades = _.filter(players_on_trade, trade => {
					return trade[opp_side] !== 0 && trade[side] === 0;
				});

				// Find the first player in the trades that shares a position with the
				// new player, if such a player exists
				const { positions } = players_by_id[id];
				trade_with_same_position = _.find(not_empty_trades, trade => {
					if (side === "in") {
						// Try to match with the trade's swap position, if ther is one
						// If not, try to match on the trade's position
						return _.includes(positions, trade.swap_position || trade.position);
					}
					else {
						const in_id = trade.in;
						const in_positions = _.get(players_by_id, [in_id, "positions"], []);
						// Match on the out position(s) in the team against the positions
						// of the players being traded in
						return _.includes(in_positions, position);
					}
				});
			}

			// If there's a trade with the open position that matches one of the new player's
			// positions, try to add them to that one, otherwise try to update normally
			const trades = players_on_trade.map(
				_.isEmpty(trade_with_same_position) ? tryToUpdate : tryToAddWithSamePosition
			);

			yield put(actions.updatePlayersOnTradeSuccess(trades));

		}
		catch (error) {
			console.log(error.message);
		}
	}

	function* resetTrade({ payload: { clear_reset = false } }) {
		try {
			const initial_lineup = yield select(selectors.getInitialLineup);
			if (!clear_reset) {
				yield put(actions.forceUpdateMyClassicTeam(initial_lineup));
			}
			yield put(actions.resetClassicTradeSuccess());
		}
		catch (e) {
			yield put(actions.resetClassicTradeFailed());

		}
	}

	function* makeTrade() {
		try {
			const players_on_trade = yield select(selectors.getPlayersOnTrade);
			const ready_for_trade = _.chain(players_on_trade)
				.filter(trade => trade.in && trade.out)
				.value();

			const initial_lineup = yield select(selectors.getInitialLineup);

			const options = {
				"1": {
					url: "make_trade",
					getTransformedData: () => {
						const trade = _.first(ready_for_trade);
						const swap_ids = _.result(trade, "swap_ids", []);
						const swap_formations =_.result(trade, "swap_formations", []);
						const formation = simulateLineupFormationAfterTrade(initial_lineup, trade);
						return {
							old_player_id: _.result(trade, "out", 0),
							new_player_id: _.result(trade, "in", 0),
							swap_player_ids: swap_ids,
							swap_formations: swap_formations,
							formation
						};
					}
				},
				"2": {
					url: "make_double_trade",
					getTransformedData: () => {

						return _.extend({}, ...ready_for_trade.map((trade, i) => {
							const index = i + 1,
								pick = _.result.bind(_, trade);

							const swap_formations = pick("swap_formations", []);
							const formation = simulateLineupFormationAfterTrade(
								initial_lineup, trade
							);
							return {
								[`trade${index}_old_player_id`]: pick("out", 0),
								[`trade${index}_new_player_id`]: pick("in", 0),
								[`trade${index}_swap_player_ids`]: pick("swap_ids", []),
								[`trade${index}_swap_formations`]: swap_formations,
								[`trade${index}_new_formation`]: formation,
							};
						}));
					}
				}
			};

			const { url, getTransformedData } = options[_.size(ready_for_trade)];
			const transformed_data = getTransformedData();

			const { result, errors } = yield call(API.teamsClassic[url], {
				...transformed_data,
			});
			checkErrorsIn(errors);

			yield put(actions.fetchMyClassicTeamSuccess(result[0]));
			yield put(actions.fetchMyClassicTrades());
			yield put(actions.resetClassicTrade({
				clear_reset: true
			}));
		}
		catch (e) {
			yield put(actions.makeClassicTradeError(e.message));
		}
	}

	function* reverseTrade({ payload: { trade_ids } }) {
		try {
			const { result, errors } = yield call(API.teamsClassic.reverse_trade, { trade_ids });
			checkErrorsIn(errors);

			yield put(actions.resetClassicTrade({
				clear_reset: true
			}));

			yield put(actions.fetchMyClassicTeamSuccess(result[0]));
			yield put(actions.fetchMyClassicTrades());
		}
		catch (error) {
			yield put(actions.makeClassicTradeError(error.message));
		}
	}

	function* fetchRankings({ payload }: Action) {
		try {
			const { result, errors } = yield call(API.teamsClassic.rankings, { ...payload });
			checkErrorsIn(errors);

			yield put(actions.fetchRankingsSuccess({ result }));
		}
		catch (error) {
			yield put(actions.fetchRankingsFailed(error.message));
		}
	}

	function* loadMoreRankings({ payload }: Action) {
		try {
			const { result, errors } = yield call(API.teamsClassic.rankings, { ...payload });
			checkErrorsIn(errors);

			yield put(actions.loadMoreRankingsSuccess({ result }));
		}
		catch (error) {
			yield put(actions.loadMoreRankingsFailed(error.message));
		}
	}

	function* rollbackTeam() {
		try {
			const { result, errors } = yield call(API.teamsClassic.rollback);
			checkErrorsIn(errors);

			yield put(actions.fetchMyClassicTeamSuccess(result[0]));
			yield put(actions.fetchMyClassicTrades());
		}
		catch (error) {
			yield put(actions.makeClassicTradeError(error.message));
		}
	}

	function* fetchSeasonHistory() {
		try {
			const season_history_response = yield call(API.teamsClassic.season_history);
			const history_statistic_response = yield call(API.teamsClassic.history_statistic);

			const firstError = season_history_response.errors;
			const secondError = history_statistic_response.errors;
			checkErrorsIn(firstError);
			checkErrorsIn(secondError);

			const payload = {
				season_data: season_history_response.result,
				history_statistic: history_statistic_response.result
			};
			yield put(actions.fetchSeasonHistorySuccess(payload));
		} 
		catch (error) {
			yield put(actions.fetchSeasonHistoryFailed(error.message));
		}
	}

	function* editClassicTrade({ payload }: Action) {
		try {
			const response = yield call(API.teamsClassic.edit_trade, {...payload});
			checkErrorsIn(response.errors);
			
			yield put(actions.editClassicTradeSuccess(response.result));
		} 
		catch (err) {
			yield put(actions.editClassicTradeFailed(err));
		}
	}

	function* watch() {
		yield takeLatest(actions.fetchMyClassicTeam, fetchMyClassicTeam);
		yield takeLatest(actions.fetchClassicTeam, fetchClassicTeam);
		yield takeLatest(actions.fetchClassicTeamSnapshot, fetchClassicTeamSnapshot);
		yield takeLatest(actions.addPlayerToMyClassicTeam, addPlayerToMyClassicTeam);
		yield takeLatest(actions.removePlayerFromMyClassicTeam, removePlayerFromMyClassicTeam);
		yield takeLatest(actions.clearMyClassicTeam, clearMyClassicTeam);
		yield takeLatest(actions.saveMyClassicTeam, saveMyClassicTeam);
		yield takeLatest(actions.autoFillMyClassicTeam, autoFillMyClassicTeam);
		yield takeLatest(actions.updateMyClassicTeam, updateMyClassicTeam);
		yield takeLatest(actions.fetchMyClassicTrades, fetchMyClassicTrades);
		yield takeLatest(actions.fetchTradeHistory, fetchTradeHistory);
		yield takeLatest(actions.addPlayerOutTrade, addPlayerOutTrade);
		yield takeLatest(actions.addPlayerInTrade, addPlayerInTrade);
		yield takeLatest(actions.removePlayerInTrade, removePlayerInTrade);
		yield takeLatest(actions.updatePlayersOnTrade, updatePlayersOnTrade);
		yield takeLatest(actions.removePlayerOutTrade, removePlayerOutTrade);
		yield takeLatest(actions.resetClassicTrade, resetTrade);
		yield takeLatest(actions.makeClassicTrade, makeTrade);
		yield takeLatest(actions.reverseClassicTrade, reverseTrade);
		yield takeLatest(actions.swapPlayersOutTrade, swapPlayersOutTrade);
		yield takeLatest(actions.rollbackTeam, rollbackTeam);
		yield takeLatest(actions.fetchRankings, fetchRankings);
		yield takeLatest(actions.loadMoreRankings, loadMoreRankings);
		yield takeLatest(actions.fetchSeasonHistory, fetchSeasonHistory);
		yield takeLatest(actions.editClassicTrade, editClassicTrade);
		yield takeLatest(actions.addPlayerInTradeAndUpdateTeam, addPlayerInTradeAndUpdateTeam);
	}

	return {
		fetchMyClassicTeam,
		fetchClassicTeamSnapshot,
		addPlayerToMyClassicTeam,
		removePlayerFromMyClassicTeam,
		clearMyClassicTeam,
		saveMyClassicTeam,
		autoFillMyClassicTeam,
		updateMyClassicTeam,
		fetchMyClassicTrades,
		fetchTradeHistory,
		addPlayerOutTrade,
		addPlayerInTrade,
		removePlayerInTrade,
		removePlayerOutTrade,
		updatePlayersOnTrade,
		resetTrade,
		makeTrade,
		reverseTrade,
		swapPlayersOutTrade,
		rollbackTeam,
		loadMoreRankings,
		fetchRankings,
		fetchClassicTeam,
		watch
	};
};

export default createSagas;