import styled from "styled-components";

import { below } from "../../assets/css/media";
import colors from "../../assets/css/colors";

export const Td = styled.td`
	text-align: ${props => props.textAlign ? props.textAlign : "center"};
	vertical-align: middle;
	padding: ${({ pre_draft }) => pre_draft ? "0px" : "0px 5px"};
	font-size: 12px;
	color: ${colors.primary.darkGrey};
	font-family: SourceSansPro;
	font-weight: 600;
	border-bottom: 1px solid ${colors.secondary.paleGrey};
	height: ${props => props.is_compressed ? "30px" : "60px"};
	tr:nth-child(even) > & {
		background: ${colors.form.base};
	}

	tr.is_owned_by_user & {
		background: ${colors.secondary.ownerGrey};
	}
	${({ is_fc }) => is_fc && `color: ${colors.coach};`}
`;

export const Th = styled.th`
	position: relative;
	text-align: ${props => props.textAlign ? props.textAlign : "center"};
	text-transform: uppercase;
	vertical-align: middle;
	color: ${colors.primary.primary};
	font-family: TitilliumUpright;
	font-weight: 400;
	height: 60px;
	padding: 0 5px;
	font-size: 12px;
	width: ${({ width }) => width || "auto"};
	border-bottom: 2px solid ${colors.secondary.paleGrey};

	box-sizing: border-box;
	&:first-child {
		text-align: left;
	}
	${({ is_fc }) => is_fc && `color: ${colors.coach}`};
	user-select: none;
	cursor: default;
`;

export const Tr = styled.tr`
	height: ${props => props.is_compressed ? "30px" : "60px"};
`;

export const TBody = styled.tbody``;

export const THead = styled.thead`
	background-color: ${colors.nrlDarkGreen};

	th {
		border-bottom: 2px solid ${colors.secondary.paleGrey};
	}
`;


export const Table = styled.table`
	width: 100%;
	${({ is_compressed }) => is_compressed && `
		tr, td {
			height: 30px;
		}
	`};
	${({ show_border_bottom }) => show_border_bottom && `
		border-bottom: 10px solid ${colors.secondary.paleGrey};
		margin-bottom: 50px;
	`};



	${below.tablet`
		& td,
		& th {
			display: none;
		}
	`};

	${({ tablet_columns }) => tablet_columns && tablet_columns.map(col => below.tablet`
					& th[rel="${col}"],
					& td[rel="${col}"] {
						display: table-cell;
					}
			`)};



	${({ mobile_columns }) => mobile_columns && mobile_columns.map(col => below.phone`
					& th[rel="${col}"],
					& td[rel="${col}"] {
						display: table-cell;
					}
			`)};

	${({ tablet_columns, mobile_columns }) => mobile_columns && tablet_columns &&
		tablet_columns.filter(col => !mobile_columns.includes(col)).map(col => below.phone`
					& th[rel="${col}"],
					& td[rel="${col}"] {
						display: none;
					}
			`)};

	& th:first-child,
	& th:last-child,
	& td:first-child,
	& td:last-child {
		display: table-cell !important;
	}

	.dnd-placeholder {
		background: #DFDFDF;
	}
`;

export default Table;