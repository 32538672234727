// @flow
import * as React from "react";

import {
	StripeProvider,
	Elements,
} from "react-stripe-elements";

import CoachSubscriptionForm from "./coachSubscriptionForm";

const stripe_pk = process.env.REACT_APP_STRIPE_PK || "";

type Props = {
	handleSubmit: Function,
	is_active: boolean,
	plan?: string,
	onCancelSubscription?: Function,
	isAccountPage?: boolean,
}

export const CheckoutForm = ({ 
	handleSubmit, 
	is_active, 
	plan, 
	onCancelSubscription, 
	isAccountPage } : Props) => (
	<StripeProvider apiKey={stripe_pk}>
		<Elements>
			<React.Fragment>
				<CoachSubscriptionForm 
					submitData={handleSubmit} 
					is_active={is_active}
					cur_plan={plan}
					onCancelSubscription={onCancelSubscription}
					isAccountPage={isAccountPage}
				/>
			</React.Fragment>
		</Elements>
	</StripeProvider>
);

export default CheckoutForm;