// @flow
import styled from "styled-components";
import * as React from "react";
// import { isMobile } from '../../utils';

import { below } from "../../assets/css/media";
import Tabs from "../Tabs";
import TabItem from "../TabItem";
import NewIcon from "../NewIcon";

const TabsWrapper = styled.div `
    width: 100%;
`;
const TabsContent = styled.div ``;

const ScrollingTabs = styled(Tabs)`
    ${below.tablet`
        overflow-x: scroll;
        overflow-y: hidden;
    `};
`;

const PaddedTabItem = styled(TabItem)`
    padding: 0 20px;
`;

const PaddedTabTextWrap = styled.div`
	display: flex;
	margin: 0 auto;
`;

const CenteredTitle = styled.div`
	display: flex;
    flex-direction: column;
    justify-content: center;
	font-family: "SourceSansPro";
`;

type Props = {
    activeTab:number;
    setActiveTab: (index:number) => void;
	tabData: {
        key:string;
        title:string;
        alertText:string;
        content:React.Node;
    }[],
};

export class TabWrap extends React.PureComponent<Props> {

	render() {
		const { activeTab, setActiveTab, tabData } = this.props;
		return (
			<TabsWrapper>
				<ScrollingTabs>
					{tabData.map((tab, tabIndex) => (
						<PaddedTabItem
							key={tab.key}
							onClick={() => setActiveTab(tabIndex)}
							data-tab={tab.key}
							className={tabIndex === activeTab ? "active-tab" : ""}>
							<PaddedTabTextWrap>
								<CenteredTitle>{tab.title}</CenteredTitle>
								{tab.alertText === "new" ? <NewIcon isBlue={true} />:null}
							</PaddedTabTextWrap>
						</PaddedTabItem>
					))}
				</ScrollingTabs>
				<TabsContent>{tabData[activeTab].content}</TabsContent>
			</TabsWrapper>
		);
	}
}

export default TabWrap;