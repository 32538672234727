// @flow
import * as React from "react";
import _ from "lodash";
import styled from "styled-components";
import { TextModal, ModalIcon } from "../../Modal";
import colors from "../../../assets/css/colors";


const TextModalStyled = styled(TextModal)`
	${ModalIcon} {
		svg {
			fill ${colors.buttons.cancel};
		}
	}
`;


type State = {
	show_modal: boolean,
	modal_header: any,
	modal_body: any,
}

const withErrorModal = (WrappedComponent: any) => {
	class WithErrorModal extends React.Component<any, State> {
		constructor(props: any) {
			super(props);

			_.bindAll(this, ["showErrorModal", "hideErrorModal"]);
		}

		state = {
			show_modal: false,
			modal_header: null,
			modal_body: null,
		};

		showErrorModal({ modal_header, modal_body }: Object) {
			this.setState({
				show_modal: true,
				modal_header,
				modal_body
			});
		}

		hideErrorModal() {
			this.setState({
				show_modal: false,
				modal_header: null,
				modal_body: null
			});
		}

		getModalElement() {
			const { show_modal, modal_header, modal_body } = this.state;
			return (onClose: Function) => (show_modal && <TextModalStyled
				onClick={onClose}
				header_text={modal_header || "Error"}
				text={modal_body} 
			/>);
		}

		render() {
			const { show_modal, modal_header, modal_body } = this.state;

			return (
				<WrappedComponent
					{...this.props}
					getModalElement={this.getModalElement()}
					show_modal={show_modal}
					modal_header={modal_header}
					modal_body={modal_body}
					hideErrorModal={this.hideErrorModal}
					showErrorModal={this.showErrorModal}
				/>
			);
		}
	}

	return WithErrorModal;
};

export default withErrorModal;