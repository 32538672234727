// @flow
import React from "react";
import styled from "styled-components";
import { below } from "../../assets/css/media";
import emptyPlayer from "../../assets/images/EmptyPlayer.png";


const Card = styled.div`
	border-radius: 0px;
	background-color: ${({ background }) => background};
  ${({ borderLeft }) => borderLeft && `
    border-top: 2px solid black;
    border-left: 2px solid black;
    border-bottom: 2px solid black;
  `}
  ${({ borderRight }) => borderRight && `
    border-top: 2px solid black;
    border-right: 2px solid black;
    border-bottom: 2px solid black;
  `}
	height: 310px;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
  img{
    height: 100%;
  }

	box-sizing: border-box;

	p {
		font-size: 12px;
		line-height: 14px;
		color: ${({ color }) => color};
	}

	${below.tablet`
		height: 90px;
		width: 100%;
		justify-content: flex-end;
		img{
			height: 75px;
			width: auto; 
		}
	`};
`;

const FcBlock = styled.div`
	cursor: pointer;
	width: 100%;
`;

type Props = {
	color: string,
	background: string,
	show_plus: boolean,
	toggleFantasyCoachModal?: Function,
  borderLeft?: boolean,
  borderRight?: boolean
}

export const AddCard = (props: Props) => {
	const card = <Card {...props}>
		<img src={emptyPlayer} alt="Empty Player"/>
	</Card>;

	if (!props.show_plus) {
		return <FcBlock onClick={props.toggleFantasyCoachModal}>
			{card}
		</FcBlock>;
	}

	return card;
};

export default AddCard;