// @flow
import { createSelector } from 'reselect';
import { isUndefined, get } from 'lodash';

import getById from './getById';

const getLeagueIdUrlParam = (state, props) => get(props, 'match.params.league_id');

export default createSelector(
	getLeagueIdUrlParam,
	getById,
	(league_id: number | typeof undefined, leagues_by_id: Object): Object => {
		if(isUndefined(league_id)) {
			throw new Error(
				`"props.match.params.league_id" field is missing in the "getLeague" selector.
				To fix this issue, make sure you are passing "props" to the selector as the
				second parameter, otherwise try to wrap component that uses this selector in
				the "withRouter" function.`
			);
		}

		return get(leagues_by_id, league_id, {});
	},
);