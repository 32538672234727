// @flow
import { createSelector } from "reselect";
import { getRoundsWithMatches } from "../rounds";
import type { TPLeague, TRound } from "../../types";
import { getLeagueRounds } from "../../../helpers/league";
import getLeague from "./getLeague";

export default createSelector(
	getLeague,
	getRoundsWithMatches,
	(league: TPLeague, rounds: TRound[]): TRound[] => {
		return getLeagueRounds(rounds, league);
	},
);