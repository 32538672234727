// @flow
import * as React from "react";

import { Label, Input, FormGroup, Tooltip } from "../../Form";
import ErrorField from "../ErrorField";
import Locked from "../../../components/Icons/Locked";
import { BorderedIcon } from "../../../components";
import colors from "../../../assets/css/colors";

type Props = {
	label: string,
	name: string,
	input: Object,
	type: string,
	placeholder: string,
	className?: string,
	tooltip?: {
		title: string,
		description: string,
	},
	meta: {
		touched: any,
		error: string,
		warning: string,
	},
	is_disabled: boolean,
	max_length: number
};

const is_error = meta => {
	return !!(meta.touched && meta.error);
};

export const FormField = ({
	label,
	name,
	type,
	placeholder,
	input,
	tooltip,
	is_disabled,
	meta: { touched, error, warning },
	className,
	max_length,
}: Props ) => (
	<div className={className}>
		<Label htmlFor={name} is_error={is_error({ touched, error })}>
			{is_disabled ? (
				<BorderedIcon padding='0' hide_border={true} background={colors.buttons.baseColor}>
					<Locked size="1.8" color='#ffffff' />
				</BorderedIcon>
			) : ""}
			{label}

			{tooltip ? (
				<Tooltip {...tooltip} />
			): null}
		</Label>
		{ touched && ((
			error && <ErrorField>{error}</ErrorField>) ||
			(warning && <ErrorField>{warning}</ErrorField>
			))
		}
		<FormGroup>
			<Input
				input={input}
				name={name}
				type={type}
				placeholder={placeholder}
				is_disabled={is_disabled}
				is_error={is_error({ touched, error })}
				max_length={max_length}
			/>
		</FormGroup>
	</div>
);

export default FormField;