// @flow
import styled from "styled-components";

import colors from "../../assets/css/colors";
import { below } from "../../assets/css/media";

export const PageTitle = styled.h1`
	color: ${colors.title};
	font-size: 32px;
	font-weight: bold;
	margin: 0 0 20px;

	${below.tablet`
		display: none;
	`}
`;


export const PageTitleWithIcon = styled(PageTitle)`
	font-size: 32px;
	display: flex;
	align-items: flex-end;

	height: 50px;

	svg {
		margin-right: .32em;
		display: block;
		width: 1.25em;
		height: 1.25em;
		transform: translateY(-0.1em);
		margin-bottom: 2px;
		fill: ${colors.title};
		margin-bottom: -3px;
		max-height: 50px;
	}

	${below.desktop`font-size: 26px`}
	${below.tablet`
		display: none;
	`}
`;


export default PageTitle;