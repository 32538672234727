// @flow
import * as React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import styled from "styled-components";
import _ from "lodash";
import { 
	getPicksByTeamID, 
	picksByDraftRound,
	totalSquadSize, getTeamNameStr, replaceAt,
	replaceRound, getSortedDraftOrder, getDidPicksChange,
	getRoundSelectStr,
	picksByTeamAndRound,
	getKeeperDeadlinePassed
} from "../../../helpers/league";
import * as selectors from "../../../modules/selectors";
import * as actions from "../../../modules/actions";
import { below } from "../../../assets/css/media";
import type { 
	TPLeague, 
	TPlayersById, 
	TDraftTeamsById, 
	TDraftUserTeam 
} from "../../../modules/types";
import {
	AdsContainer,
	TextModal,
	PageTitle,
	Footer,
	PageContentWrapper,
	StandardSidebar,
	TwoColumnLayout,
	LeagueGameBar,
	Exist,
	ButtonPrimaryLink,
} from "../../../components";
import RedirectIfNotCommish from "../../../components/RedirectIfNotCommish";
import Ad from "../../../components/Ad";
import Close from "../../../components/Icons/Close";
import { getIsRegenerated } from "../../../helpers";
import LockCircle from "../../../components/Icons/LockCircle";
import colors from "../../../assets/css/colors";
import { RedTextButton } from "./../leagues/leaguesCreate/LeaguesCreateFormStyles";

import {
	ListItem,
	PickDiv,
	TeamDiv,
	PickSelect,
	KeeperSelect,
	SizeDiv,
	TitleWrap,
	RoundSelect,
	TitleRow,
	PickTitleDiv,
	TeamTitleDiv,
	SizeTitleDiv,
} from "./LeagueEditPicks/LeagueEditPicksStyle";

const StyledRoundSelect = styled(RoundSelect)`
	margin-right: 10px;
`;

const CommissionerPickBanner = styled.div`
	height: 40px;
	width: 100%;
	position: relative;
	top: 0;
	background: #7F8A90;
	font-family: SourceSansPro;
	font-weight: 400;
	font-size: 14px;
	color: white;
	text-align: center;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	${below.phone`
		height: 80px;
		padding: 25px 5px;
		line-height: 20px;
		flex-direction: column;
		z-index: 99;
		position: sticky;
		top: 50px;
		justify-content: flex-start;
	`}

`;

const CloseButton = styled.button`
	position: absolute;
	right: 10px;
	height: 32px;
	width: 32px;
	cursor: pointer;
	svg{
		height: 30px;
		width: 30px;
		fill: white;
	}
	padding: 0;
	background: none;
	border: none;
	${below.phone`
		right: calc(50% - 16px);
		bottom: 0px;
		
	`}
`;

const DisableTableWrapper = styled.div`
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: 88;
	background-color: rgba(240, 243, 247, 0.9);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-size: 20px;
	color: ${colors.primary.primary};
	font-family: SourceSansPro;
	font-weight: 600;
	svg{
		height: 60px;
		width: 60px;
		path{
			fill: ${colors.primary.primary};
		}
	}
	${below.phone`
		padding-top: 100px;
	`}
`;

const DisableKeeperText = styled.div`
	text-align: center;
	color: ${colors.primary.primary};
	font-family: SourceSansPro;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	width: 600px;
	margin-bottom: 20px;
	${below.phone`
		width: 100%;
	`}
`;

const DisableKeeperHeader = styled.h1`
	font-family: TitilliumUpright;
	font-weight: bold;
	font-size: 32px;
	line-height: 32px;
	margin: 20px 0;
	color: ${colors.primary.primary};
	text-align: center;
`;


const BodyDiv = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: fit-content;
	position: relative;
`;

type Props = {
	selected_league_id: number,
	showDraftOrder: typeof actions.leagueDraft.showDraftOrder,
	changeDraftOrder: actions.leagueDraft.changeDraftOrder,
	leaveDraftLeague: typeof actions.leagueDraft.leaveDraftLeague,
	removeTeamDraftLeague: typeof actions.leagueDraft.removeTeamDraftLeague,
	fetchLeague: typeof actions.leagueDraft.showDraftLeague,
	showCustomFullDraftOrder: typeof actions.leagueDraft.showCustomFullDraftOrder,
	saveCustomFullDraftOrder: typeof actions.leagueDraft.showCustomFullDraftOrder,
	resetCustomFullDraftOrderStatus: typeof actions.leagueDraft.resetCustomFullDraftOrderStatus,
	is_pending_show: boolean,
	user_id: number,
	league: TPLeague,
	teams_by_id: TDraftTeamsById,
	teams: Array<TDraftUserTeam>,
	draft_order: Array<number>,
	error?: string,
	match: {
		params: {
			league_id: string,
		},
	},
	leave_league_success: boolean,
	remove_team_success: boolean,
	fullOrder: {
		order: number[],
		error: string | null,
		draft_full_order_status: string
	},
	numTeams: number,
	fetchPlayers: Function,
	players_by_id: TPlayersById,
	saveKeeperDraftPicks: typeof actions.leagueDraft.saveKeeperDraftPicks,

};

type State = {
	currentDraftRound: number,
	currentDraftTeam: number,
	keeper_draft_picks: Object,
	show_commish_banner: boolean
};

class LeagueEditPicksComponent extends React.Component<Props, State> {
	constructor(props, state) {
		super(props, state);

		_.bindAll(this, 
			["setCurrentDraftRound", 
				"resetPicks",
				"setDraftTeam",
				"handleNoTeam",
				"handleCloseBanner"
			]);
	}

	state = {
		currentDraftRound: 1,
		currentDraftTeam: 0,
		keeper_draft_picks: {},
		show_commish_banner: true
	};

	componentDidMount() {
		const { league_id } = this.props.match.params;
		const { fetchLeague, showCustomFullDraftOrder, fetchPlayers } = this.props;
		fetchLeague({ id: league_id });
		fetchPlayers();
		showCustomFullDraftOrder({ id: league_id });
		this.props.showDraftOrder({ league_id });
	
	}

	componentDidUpdate(prev_props: Props) {
		const { remove_team_success: success, league } = this.props;
		const { remove_team_success: old_success } = prev_props;

		const keeper_picks = _.get(league, 'keeper_draft_picks', "[]");

		if(prev_props.league.keeper_draft_picks !== keeper_picks){
			const shouldParse = typeof keeper_picks === "string";
			const parsed =	shouldParse ? JSON.parse(keeper_picks) : keeper_picks;
			this.setState({ keeper_draft_picks: parsed });
		}
		

		if (!old_success && success) {
			window.location.reload();
		}
	}

	get is_keeper_deadline_passed() {
		const { league } = this.props;
		const { draft_start, keeper_deadline } = league;
		return getKeeperDeadlinePassed(
			draft_start,
			keeper_deadline
		);
	}

	handleCloseBanner() {
		localStorage.setItem('show_commish_banner', 'false');
		this.setState({
			show_commish_banner: false
		});
	}

	setDraftTeam(e){
		this.setState({
			currentDraftTeam: Number(e.target.value),
			currentDraftRound: 0,
		});
		if(Number(e.target.value) === 0){
			this.handleNoTeam();
		}
	}

	handleNoTeam(){
		this.setState({
			currentDraftTeam: 0,
			currentDraftRound: 1,
		});
	}

	setCurrentDraftRound(e) {
		this.setState({ currentDraftRound: Number(e.target.value) });
	}

	resetPicks() {
		const { league_id } = this.props.match.params;
		const { draft_order, fullOrder, numTeams, 
			saveCustomFullDraftOrder, league } = this.props;
		const { currentDraftRound } = this.state;
		const startIndex = currentDraftRound === 1 ? 
			currentDraftRound -1: ((currentDraftRound - 1) * numTeams);
		saveCustomFullDraftOrder({
			order:replaceRound(
				fullOrder.order, 
				getSortedDraftOrder( currentDraftRound, draft_order, league.draft_order ),
				startIndex
			),
			id: league_id
		});
	}

	getOpenRemainingPicks(teamID: number) {
		const { fullOrder } = this.props;
		const { keeper_draft_picks } = this.state;
		let currentPicks = [];
		fullOrder.order.forEach((team, pickIndex) => {
			if(team === teamID){
				currentPicks.push(pickIndex + 1);
			}
		});
		const lastOpen = 
			currentPicks.reverse().find(pick => _.get(keeper_draft_picks, pick, 0) === 0);
		return lastOpen || 0;
	}

	setPick(e,pickNumber) {
		const { league_id } = this.props.match.params;
		const { fullOrder, saveCustomFullDraftOrder, saveKeeperDraftPicks,league } = this.props; 
		const { keeper_draft_picks } = this.state;
		// also need to account for player, player goes with pick
		// if there is a person associated with that pick, swap them into 
		// the current team owners last most open pick if different teams

		const currentPickOwner = fullOrder.order[pickNumber - 1];
		const currentAtPick = _.get(keeper_draft_picks, pickNumber, 0);
		const finalEmptyPick: number = this.getOpenRemainingPicks(currentPickOwner) || 0;
		const indexPickNumber = Number(pickNumber);

		const isKeeper = 
			Boolean(_.get(league, 'keeper', 0)) 
			&& getIsRegenerated(league.regenerated_from,league.uf_regenerated_from);
			
		const newKeeperOrder = {
			...keeper_draft_picks,
			[indexPickNumber]: 0,
			[finalEmptyPick]: currentAtPick
		};
		if(currentPickOwner !== Number(e.target.value)){
			this.setState({
				keeper_draft_picks: newKeeperOrder
			});
		}
		const order = fullOrder.order.map((team, index) => {
			if(newKeeperOrder[index + 1]){
				return newKeeperOrder[index + 1];
			}
			return 0;
		});


		if(this.is_keeper_deadline_passed && isKeeper){
			saveKeeperDraftPicks({
				id: league_id,
				order: order
			});
		}

		saveCustomFullDraftOrder({
			order: replaceAt(fullOrder.order, pickNumber-1, Number(e.target.value)),
			id: league_id
		});
	}

	setKeeperPick(e, pickNumber) {
		// on change swap with whatver is currently at that pick. then on redux call 
		// map through them all except for the pick index that exist insert that value
		const { saveKeeperDraftPicks, fullOrder } = this.props;
		const { league_id } = this.props.match.params;
		const current_picks = this.state.keeper_draft_picks;

		const currentPickOwner = fullOrder.order[pickNumber - 1];
		const currentAtPick = _.get(current_picks, pickNumber, 0);
		const playerToAssignToPick = Number(e.target.value);
		const previouslyAssigned: number = 
			Number(Object.keys(current_picks).find(key => 
				current_picks[key] === playerToAssignToPick)) || 0;
		const assignOldToIndex: number = 
			previouslyAssigned === 0 ? 
				this.getOpenRemainingPicks(currentPickOwner) : 
				previouslyAssigned;
		const newOrder = {
			...current_picks,
			[pickNumber]: playerToAssignToPick,
			[assignOldToIndex]: currentAtPick
		};

		this.setState({
			keeper_draft_picks: newOrder
		});

		
		const order = fullOrder.order.map((team, index) => {
			if(newOrder[index + 1]){
				return newOrder[index + 1];
			}
			return 0;
		});



		saveKeeperDraftPicks({
			id: league_id,
			order: order
		});

	}


	get draftOrderList() {
		const { fullOrder, numTeams, teams_by_id, draft_order, league } = this.props;
		const { currentDraftRound, currentDraftTeam, keeper_draft_picks } = this.state;
		const currentList = picksByDraftRound(fullOrder.order, numTeams, currentDraftRound);
		const picksByTeamID = getPicksByTeamID(fullOrder.order);
		const picksByChosenTeam = 
			picksByTeamAndRound(fullOrder.order, numTeams, currentDraftTeam, currentDraftRound);
		const leagueSize = league && league.squad_size ? totalSquadSize(league.squad_size):0;
		const isKeeper = 
			Boolean(_.get(league, 'keeper', 0)) 
			&& getIsRegenerated(league.regenerated_from,league.uf_regenerated_from);

		if(currentDraftTeam !== 0){
			return Object.keys(picksByChosenTeam).map((pickNumber, pickIndex) => {
				const teamID = picksByChosenTeam[Number(pickNumber)];
				const teamKeepers = _.get(teams_by_id[teamID], 'keepers', []);
				const teamKeepersHandled = teamKeepers !== null ? teamKeepers : [];
				const currentValue = _.get(keeper_draft_picks, Number(pickNumber), 0);
				const isAssigned = currentValue !== 0;
			
				const pickChanged = 
				draft_order.length ? picksByChosenTeam[Number(pickNumber)] !== getSortedDraftOrder(
					currentDraftRound,
					draft_order,
					league.draft_order
				)[Number(pickNumber)]:false;
				return (
					<ListItem key={`${currentDraftRound}|${pickIndex}`}>
						<PickDiv>{pickNumber}</PickDiv>
						<TeamDiv isKeeper={isKeeper}>
							{draft_order.length ? (
								<React.Fragment>
									<PickSelect
										hasChanged={pickChanged} 
										onChange={e => this.setPick(e,Number(pickNumber))} 
										value={picksByChosenTeam[pickNumber]}
									>
										{draft_order.map((item, draftIndex) => (
											<option value={item} key={item}>
												{getTeamNameStr(teams_by_id, item)}
											</option>
										))}
									</PickSelect>
									<KeeperSelect
										hasChanged={isAssigned} 
										onChange={e => this.setKeeperPick(e,Number(pickNumber))} 
										value={currentValue}
										disabled={!this.is_keeper_deadline_passed}
									>
										<option value={0} key={0}> 
											Assign a keeper player to this pick
										</option>
										{teamKeepersHandled.map((player, draftIndex) => (
											<option value={player.id} key={player.id}>
												{player.first_name} {player.last_name}
											</option>
										))}
									</KeeperSelect>
								</React.Fragment>
								
							) : getTeamNameStr(teams_by_id, teamID)}
						</TeamDiv>
						
						
										
						<SizeDiv>{picksByTeamID[teamID]} / {leagueSize}</SizeDiv>
					</ListItem>
				);
			});
		}

		return currentList.map((pick, pickIndex) => {
			const teamKeepers = _.get(teams_by_id[pick], 'keepers', []);
			const teamKeepersHandled = _.isEmpty(teamKeepers) ? [] : teamKeepers;
			const pickNumber = currentDraftRound === 1
				? pickIndex + 1
				: pickIndex + 1 + (currentDraftRound - 1) * numTeams;
			const currentValue = _.get(keeper_draft_picks, Number(pickNumber), 0);
			const isAssigned = currentValue !== 0;
			const pickChanged = draft_order.length ? pick !== getSortedDraftOrder(
				currentDraftRound,
				draft_order,
				league.draft_order
			)[pickIndex]:false;
			return (
				<ListItem key={`${currentDraftRound}|${pickIndex}`}>
					<PickDiv>{pickNumber}</PickDiv>
					<TeamDiv isKeeper={isKeeper}>
						{draft_order.length ? (
							<React.Fragment>
								<PickSelect
									hasChanged={pickChanged} 
									onChange={e => this.setPick(e,pickNumber)} 
									value={pick}
								>
									{draft_order.map((item, draftIndex) => (
										<option value={item} key={item}>
											{getTeamNameStr(teams_by_id, item)}
										</option>
									))}
								</PickSelect>
								{(isKeeper) && 
									<KeeperSelect
										hasChanged={isAssigned} 
										onChange={e => this.setKeeperPick(e,Number(pickNumber))} 
										value={currentValue}
										disabled={!this.is_keeper_deadline_passed}
									>
										<option value={0} key={0}> 
											Assign a keeper player to this pick
										</option>
										{teamKeepersHandled.map((player, draftIndex) => (
											<option value={player.id} key={player.id}>
												{player.first_name} {player.last_name}
											</option>
										))}
									</KeeperSelect>
								}
								
							</React.Fragment>
							
						) : getTeamNameStr(teams_by_id, pick)}
					</TeamDiv>		
					<SizeDiv>{picksByTeamID[pick]} / {leagueSize}</SizeDiv>
				</ListItem>
			);
		});
	}

	get error_modal() {
		return (
			<TextModal
				onClick={this.props.resetCustomFullDraftOrderStatus}
				header_text='Error'
				text={ this.props.fullOrder.error } />
		);
	}
	get draft_status () {
		const { league } = this.props;
		return ((league.status === "scheduled"
      && (Date.parse(league.draft_start) - Date.parse(Date())) > 1800000));
	};

	get show_commish_banner() {
		const { league, user_id } = this.props;
		const { show_commish_banner } = this.state;
		const isRegenerated = getIsRegenerated(league.regenerated_from,league.uf_regenerated_from);
		const is_keeper = Boolean(_.get(league, 'keeper', 0)) && isRegenerated;
		const isCommissioner = league.commissioner === user_id;
		const showCommishBanner = 
			localStorage.getItem('show_commish_banner') !== 'false' 
			&& is_keeper
			&& show_commish_banner
			&& isCommissioner;
		return showCommishBanner;
	}

	get draft_rounds_arr() {
		const {fullOrder, numTeams} = this.props;
		const draftRounds = fullOrder.order.length ? fullOrder.order.length / numTeams : 0;
		if(draftRounds % 1 !== 0){
			return [];
		}
		return Array.from(Array(draftRounds).keys()) || [];
	}

	render() {
		const { league_id } = this.props.match.params;
		const { currentDraftRound } = this.state;
		const { draft_order, fullOrder, numTeams, league, user_id, teams_by_id } = this.props;
		
	
	
		const draftFullOrderStatus = fullOrder.draft_full_order_status;
		const draftRounds = fullOrder.order.length ? fullOrder.order.length / numTeams : 0;


		
		if(!this.draft_status){
			return <Redirect to={`/draft/league/${league_id}`} />;
		}
		
    
		return (
			<React.Fragment>
				<RedirectIfNotCommish
					league={league}
					userID={user_id}
					leagueID={league_id} />
				<LeagueGameBar no_fetch={true} />
				{this.show_commish_banner && 
					<CommissionerPickBanner>
						All keepers have been defaulted to the back end of the draft.
						<CloseButton onClick={this.handleCloseBanner}>
							<Close />
						</CloseButton>
					</CommissionerPickBanner>
				}
				<AdsContainer>
					<Ad />
				</AdsContainer>
				{draftFullOrderStatus === "error" ? this.error_modal : null}
				<PageContentWrapper>
					<TwoColumnLayout>
						<div>
							<TitleWrap>
								<PageTitle>Edit draft picks</PageTitle>
								<div>
									<StyledRoundSelect 
										value={this.state.currentDraftTeam} 
										onChange={e => this.setDraftTeam(e)}>
										<option key="0-0"value={0}>
										Select a team
										</option>
										{Object.keys(teams_by_id).map(teamID => {
											const { name } = teams_by_id[teamID];
											return (
												<option value={teamID} key={teamID + 1}>
													{name}
												</option>
											);
										})}
									</StyledRoundSelect>
									{draftRounds > 0 ? (
										<RoundSelect 
											onChange={e => this.setCurrentDraftRound(e)} 
											value={currentDraftRound}>
											<option 
												value={0} 
												key={'round-0'}>Select a Round
											</option>
											{this.draft_rounds_arr.map(round => {
												const didPicksChange = getDidPicksChange(
													picksByDraftRound(
														fullOrder.order, 
														numTeams, 
														round + 1
													), 
													draft_order,
													round + 1,
													league.draft_order
												);
												return (
													<option value={round + 1} key={round + 1}>{
														getRoundSelectStr(
															`Round ${round + 1}`, 
															didPicksChange
														)
													}</option>
												);
											}
											)}
										</RoundSelect>
									) : null}
								</div>
							</TitleWrap>
							<TitleRow>
								<PickTitleDiv>Pick</PickTitleDiv>
								<TeamTitleDiv>Team / Coach</TeamTitleDiv>
								<SizeTitleDiv>Picks assigned / Team size</SizeTitleDiv>
							</TitleRow>
							<BodyDiv>
								<Exist when={!league.teams_finalised}>
									<DisableTableWrapper>
										<LockCircle />
										<DisableKeeperHeader>
											Editing draft picks is locked
										</DisableKeeperHeader>
									
										<DisableKeeperText>
											In order to use this tool, you must first finalise 
											your league participants. To do this, go to 
											Manage Teams in the Settings menu, then scroll down 
											and click "Finalise Participants"
										</DisableKeeperText>
										<ButtonPrimaryLink to={`/draft/league/${league_id}/teams`}>
											Manage Teams
										</ButtonPrimaryLink>
									
									</DisableTableWrapper>
								</Exist>
								{this.draftOrderList}
							</BodyDiv>
							
							{getDidPicksChange(
								picksByDraftRound(
									fullOrder.order, 
									numTeams, 
									currentDraftRound
								), 
								draft_order,
								currentDraftRound,
								league.draft_order
							) ? <RedTextButton onClick={() => this.resetPicks()}>
								Restore picks to default
								</RedTextButton>:null}
						</div>
						<StandardSidebar />
					</TwoColumnLayout>
				</PageContentWrapper>
				<Footer />
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state, props) => {
	const {
		leagues: {
			show_order: { error },
		},
	} = state;
	const teams_by_id = selectors.getTeamsById(state);
	const selected_league_id = props.match.params.league_id;
	const teams = selectors.leagueDraft
		.getLeagueDraftOrder(state)
		.map(team_id => teams_by_id[team_id])
		.filter(_.identity);
	const currentLeague = selectors.leagues.getLeague(state, props);
	const showFullOrder = state.leagues.show_full_order;
	return {
		selected_league_id,
		league: currentLeague,
		is_pending_show: selectors.leagues.show.isPending(state),
		user_id: selectors.getUser(state).id,
		leave_league_success: state.leagues.leave.success,
		remove_team_success: state.leagues.remove_team.success,
		draft_order: state.leagues.show_order.ordered_ids,
		error,
		teams_by_id,
		teams,
		fullOrder: showFullOrder,
		numTeams: currentLeague.num_teams,
		squadSize: currentLeague.squad_size,
		players_by_id: state.players.by_id,
	};
};
const mapDispatchToProps = {
	fetchLeague: actions.leagueDraft.showDraftLeague,
	leaveDraftLeague: actions.leagueDraft.leaveDraftLeague,
	removeTeamDraftLeague: actions.leagueDraft.removeTeamDraftLeague,
	showDraftOrder: actions.leagueDraft.showDraftOrder,
	changeDraftOrder: actions.leagueDraft.changeDraftOrder,
	showCustomFullDraftOrder: actions.leagueDraft.showCustomFullDraftOrder,
	saveCustomFullDraftOrder: actions.leagueDraft.saveCustomFullDraftOrder,
	resetCustomFullDraftOrderStatus: actions.leagueDraft.resetCustomFullDraftOrderStatus,
	fetchPlayers: actions.fetchPlayers,
	saveKeeperDraftPicks: actions.leagueDraft.saveKeeperDraftPicks
};

export const LeagueEditPicks = compose(connect(mapStateToProps, mapDispatchToProps))(
	LeagueEditPicksComponent
);

export default LeagueEditPicks;