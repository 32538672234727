// @flow
import { createAction } from 'redux-act';

export const createDraftLeague = createAction('Create Draft League');
export const createDraftLeagueSuccess = createAction('Create Draft League Success');
export const createDraftLeagueFailed = createAction('Create Draft League Failed');

export const updateDraftLeague = createAction('Update Draft League');
export const updateDraftLeagueSuccess = createAction('Update Draft League Success');
export const updateDraftLeagueFailed = createAction('Update Draft League Failed');

export const createDraftLeagueUF = createAction('Create Draft League');
export const createDraftLeagueUFSuccess = createAction('Create Draft League Success');
export const createDraftLeagueUFFailed = createAction('Create Draft League Failed');

export const updateDraftLeagueUF = createAction('Update Draft League');
export const updateDraftLeagueUFSuccess = createAction('Update Draft League Success');
export const updateDraftLeagueUFFailed = createAction('Update Draft League Failed');

export const uploadDraftLeagueAvatar = createAction('Upload Draft League Avatar');
export const uploadDraftLeagueAvatarSuccess = createAction('Upload Draft League Avatar Success');
export const uploadDraftLeagueAvatarFailed = createAction('Upload Draft League Avatar Failed');

export const showDraftLeague = createAction('Show Draft League');
export const showDraftLeagueSuccess = createAction('Show Draft League Success');
export const showDraftLeagueFailed = createAction('Show Draft League Failed');

export const showMyDraftLeagues = createAction('Show My Draft Leagues');
export const showMyDraftLeaguesSuccess = createAction('Show My Draft Leagues Success');
export const showMyDraftLeaguesFailed = createAction('Show My Draft Leagues Failed');

export const showDraftLeagueTeams = createAction('Show Draft League Teams');
export const showDraftLeagueTeamsSuccess = createAction('Show Draft League Teams Success');
export const showDraftLeagueTeamsFailed = createAction('Show Draft League Teams Failed');

export const showToJoinDraftLeagues = createAction('Show To Join Draft Leagues');
export const showToJoinDraftLeaguesSuccess = createAction('Show To Join Draft Leagues Success');
export const showToJoinDraftLeaguesFailed = createAction('Show To Join Draft Leagues Failed');

export const showToJoinLoadMoreDraftLeagues = createAction('Show To Join Load More Draft Leagues');
export const showToJoinLoadMoreDraftLeaguesSuccess =
	createAction('Show To Join Load More Draft Leagues Success');
export const showToJoinLoadMoreDraftLeaguesFailed =
	createAction('Show To Join Load More Draft Leagues Failed');

export const joinToDraftLeague = createAction('Join To Draft League');
export const joinToDraftLeagueSuccess = createAction('Join To Draft League Success');
export const joinToDraftLeagueFailed = createAction('Join To Draft League Failed');
export const clearJoinToDraftLeagueErrors = createAction('Clear Join To Draft League Errors');

export const removeDraftLeague = createAction('Remove Draft League');
export const removeDraftLeagueSuccess = createAction('Remove Draft League Success');
export const removeDraftLeagueFailed = createAction('Remove Draft League Failed');
export const clearRemoveDraftLeagueErrors = createAction('Clear Remove Draft League Errors');

export const regenerateListDraftLeagues = createAction('Regenerate List Draft Leagues');
export const regenerateListDraftLeaguesSuccess =
	createAction('Regenerate List Draft Leagues Success');
export const regenerateListDraftLeaguesFailed =
	createAction('Regenerate List Draft Leagues Failed');

export const resetRegenerateShow = createAction('Regenerate Show Draft League');
export const regenerateShowDraftLeague = createAction('Regenerate Show Draft League');
export const regenerateShowDraftLeagueSuccess =
	createAction('Regenerate Show Draft League Success');
export const regenerateShowDraftLeagueFailed = createAction('Regenerate Show Draft League Failed');

export const regenerateShowDraftLeagueUF = createAction('Regenerate Show Draft League');
export const regenerateShowDraftLeagueUFSuccess =
	createAction('Regenerate Show Draft League Success');
export const regenerateShowDraftLeagueUFFailed = createAction('');

export const showTeamsDraftLeague = createAction('Show Teams Draft League');
export const showTeamsDraftLeagueSuccess = createAction('Show Teams Draft League Success');
export const showTeamsDraftLeagueFailed = createAction('Show Teams Draft League Failed');

export const inviteToDraftLeague = createAction('Invite To Draft League');
export const inviteToDraftLeagueSuccess = createAction('Invite To Draft League Success');
export const inviteToDraftLeagueFailed = createAction('Invite To Draft League Failed');

export const ladderDraftLeague = createAction('Ladder Draft League');
export const ladderDraftLeagueSuccess = createAction('Ladder Draft League Success');
export const ladderDraftLeagueFailed = createAction('Ladder Draft League Failed');
export const clearDraftLeagueCreateErrors = createAction('Clear Draft League Create errors');
export const clearDraftLeagueUpdateErrors = createAction('Clear Draft League Update errors');

export const clearUFDraftLeagueCreateErrors = createAction('Clear UF Draft League Create errors');

export const leaveDraftLeague = createAction('Leave Draft League');
export const leaveDraftLeagueSuccess = createAction('Leave Draft League Success');
export const leaveDraftLeagueFailed = createAction('Leave Draft League Failed');

export const removeTeamDraftLeague = createAction('Remove Team Draft League');
export const removeTeamDraftLeagueSuccess = createAction('Remove Team Draft League Success');
export const removeTeamDraftLeagueFailed = createAction('Remove Team Draft League Failed');

export const showDraftOrder = createAction('Show Draft Order');
export const showDraftOrderSuccess = createAction('Show Draft Order Success');
export const showDraftOrderFailed = createAction('Show Draft Order Failed');
export const resetShowDraftOrder = createAction('Reset Draft Order State');

export const showCustomFullDraftOrder = createAction('Show Custom Full Draft Order');
export const showCustomFullDraftOrderSuccess = createAction('Show Custom Full Draft Order Success');
export const showCustomFullDraftOrderFailed = createAction('Show Custom Full Draft Order Failed');

export const resetCustomFullDraftOrderStatus = createAction('Reset error status');
export const saveCustomFullDraftOrder = createAction('Save Custom Full Draft Order');
export const saveCustomFullDraftOrderSuccess = createAction('Save Custom Full Draft Order Success');
export const saveCustomFullDraftOrderFailed = createAction('Save Custom Full Draft Order Failed');

export const changeDraftOrder = createAction('Change Draft Order');
export const changeDraftOrderSuccess = createAction('Change Draft Order Success');
export const changeDraftOrderFailed = createAction('Change Draft Order Failed');

export const userTeamUpdated = createAction('USER_TEAM_UPDATED');
export const forceTeamUpdate = createAction('USER_TEAM_FORCE_UPDATED');
export const userTeamUpdatedRemovePlayer = createAction('USER_TEAM_UPDATED_REMOVE_PLAYER');

export const myList = createAction('Get My Draft League Stats');
export const myListSuccess = createAction('Get My Draft League Stats Success');
export const myListFailed = createAction('Get My Draft League Stats Failed');

export const fetchWaiverOrder = createAction('Fetch RFA Order');
export const fetchWaiverOrderSuccess = createAction('Fetch RFA Order Success');
export const fetchWaiverOrderFailed = createAction('Fetch RFA Order Failed');

export const fetchWaiverList = createAction('Fetch RFA List');
export const fetchWaiverListSuccess = createAction('Fetch RFA List Success');
export const fetchWaiverListFailed = createAction('Fetch RFA List Failed');

export const fetchWaiverFree = createAction('Fetch RFA Free');
export const fetchWaiverFreeSuccess = createAction('Fetch RFA Free Success');
export const fetchWaiverFreeFailed = createAction('Fetch RFA Free Failed');

export const fetchWaiverRequests = createAction('Fetch RFA Requests');
export const fetchWaiverRequestsSuccess = createAction('Fetch RFA Requests Success');
export const fetchWaiverRequestsFailed = createAction('Fetch RFA Requests Failed');
export const updatedWaiverRequests = createAction('UPDATE_RFA_REQUESTS');

export const fetchLeagueRosters = createAction('Fetch League Rosters');
export const fetchLeagueRostersSuccess = createAction('Fetch League Rosters Success');
export const fetchLeagueRostersFailed = createAction('Fetch League Rosters Failed');

export const requestServerAndLocalTimeDiff = createAction(
	'Request Server And Local Time Diff'
);
export const requestServerAndLocalTimeDiffSuccess = createAction(
	'Request Server And Local Time Diff Success'
);
export const requestServerAndLocalTimeDiffFailed = createAction(
	'Request Server And Local Time Diff Failed'
);

export const fetchTransactionHistory = createAction('Fetch Transaction History');
export const fetchTransactionHistorySuccess = createAction('Fetch Transaction History Success');
export const fetchTransactionHistoryFailed = createAction('Fetch Transaction History Failed');

export const subscribeLeague = createAction("Subscribe League");
export const subscribeLeagueSuccess = createAction("Subscribe League Success");
export const subscribeLeagueFailed = createAction("Subscribe League Failed");
export const clearSubscribeLeagueErrors = createAction(
	"Clear Subscribe League Errors"
);

export const draftLeagueSettingsUpdate = createAction("Draft League Settings Update");

export const changeTeamCoach = createAction("Change Team Coach");
export const changeTeamCoachSuccess = createAction("Change Team Coach Success");
export const changeTeamCoachFailed = createAction("Change Team Coach Failed");
export const changeTeamCoachClear = createAction("Change Team Coach Clear");

export const draftLeagueTeamUpdate = createAction("Draft League Team Update");

export const fetchTeamOverrideScores = createAction("Fetch Team Override Scores");
export const fetchTeamOverrideScoresSuccess = createAction("Fetch Team Override Scores Success");
export const fetchTeamOverrideScoresFailed = createAction("Fetch Team Override Scores Failed");

export const teamOverrideScoresUpdate = createAction("Team Override Scores Update");
export const teamOverrideScoresUpdateSuccess = createAction("Team Override Scores Update Success");
export const teamOverrideScoresUpdateFailed = createAction("Team Override Scores Update Failed");

export const clearTeamOverrideScores = createAction("Clear Team Override Scores");

export const saveKeeperDraftPicks = createAction("Save Keeper Draft Picks");
export const saveKeeperDraftPicksSuccess = createAction("Save Keeper Draft Pick Success");
export const saveKeeperDraftPicksFailed = createAction("Save Keeper Draft Picks Failed");

export const clearUFDraftLeagueCreate = createAction("Clear UF League State");


export const postWaiverAddDropPlayers = createAction("Post Add Drop Waiver Players");
export const postWaiverAddDropPlayersSuccess = createAction("Post Add Drop Waiver Players Success");
export const postWaiverAddDropPlayersFailed = createAction("Post Add Drop Waiver Players Failed");