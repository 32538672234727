import styled from "styled-components";
import {
	Tabs,
} from "../Tabs";
import {
	TabItem,
} from "../TabItem";
import { CommandButton } from "../MyTeam/ListView/commandButton";
import {
	ModalContainer,
	ModalContent,
	ModalBody,
	ButtonPrimaryLink,
} from "../../components";


import {
	StatBlock,
	PlayerAvatar,
	PlayerStats
} from "../PlayerProfile/playerCard";
import club_colors from "../../utils/clubColors";
import colors from "../../assets/css/colors";
import above, { below } from "../../assets/css/media";

export const SectionHeader = styled.div`
    height: 30px;
    width: 100%;
    padding: 8px 13px;
    color: ${colors.primary.primary};
    font-family: TitilliumUpright;
    font-size: 12px;
    line-height: 14px;
    text-align: left;
    background-color: #F8F8F8;
    display: flex;
    align-items: center;
    justify-content: space-between;
	${({isFullProfile}) => isFullProfile && `
		background-color: transparent;
		font-size: 14px;
	`}

	${({is_coach}) => is_coach && `
		color: ${colors.coach};
	`}
`;

export const StatHeadRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 40px;
    border-bottom: 2px solid #F8F8FA;
    justify-content: space-around;
    position: sticky;
    top: 0px;
    background:#fff;
    ${below.phone`
        &.hidden-mobile{
            display: none;
        }
    `}
`;

export const StatBubbleRow = styled.div`
    background: white;
    width: 100%;
    &.hidden-web{
        display: none;
        ${below.phone`
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
        `}
    }
    
`;

export const StatItemRow = styled(StatHeadRow)`
    border-bottom: 0;
    position: unset;
`;

export const StatHeadItem = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: SourceSansPro;
    font-weight: 600;
    color: ${({is_fc}) => is_fc ? colors.coach : colors.primary.primary};
    font-size: 12px;
    width: 64px;
    
`;  

export const StatItem = styled(StatHeadItem)`
    img{
        width: 50%;
    }
`;


export const StyledMobName = styled.p`
	font-size: 20px;
	font-family: TitilliumUpright;
	font-weight: bold;
	color: #fff;
	margin-right: 5px;
`;


export const StyledTabs = styled(Tabs)`
	height: 53px;
	overflow-x: scroll;
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none;
	
	&:-webkit-scrollbar {
		display: none;
	}
`;

export const StyledTabItem = styled(TabItem)`
	min-width: 101px;
	height: 100%;
	border-top: 0;
	display: inline-block;
	padding: 20px 0;

	${above.tablet`
		&.playerOverview{
			width: 124px;
		}

		&.fixture{
			width: 106px;
		}
	`}

	${below.tablet`
		width: fit-content;
	`}
`;



export const StyledCommandButton = styled(CommandButton)`
	margin: 0;
  width: 30px;
  height: 30px;
  > svg {
		width: 24px;
		height: 24px;
	}
`;

export const PlayerModalContainer = styled(ModalContainer)`
	&::-webkit-scrollbar {
		display: none;
	}
	

	-ms-overflow-style: none;  /* IE and Edge */
	scrollbar-width: none;  /* Firefox */
	
  
	.modal-close-wrap{
		height: 0px;
	}
	${ModalContent} {
		background: white;
		padding: 0px 0 40px;
		max-height: 600px;
		overflow-y: scroll;
		overflow-x: hidden;
		&::-webkit-scrollbar {
			display: none;
		}
		
	
		-ms-overflow-style: none;  /* IE and Edge */
		scrollbar-width: none;  /* Firefox */
		${below.phone`
			max-height: 75%;
		`}
	}
	.modal-close-wrap{
		height: 0px;
	}
	.close-button{
		position: relative;
		top: 10px;
		right: 20px;
		margin: 0;
		z-index: 999999999;
		height: 16px;
		width: 16px;

		margin: 0;
		svg{
			fill: #fff;
			height: 16px;
			width: 16px;
			position: absolute;
		}
	}

	${ModalBody} {
		text-align: left;
		overflow: hidden;
		&::-webkit-scrollbar {
			display: none;
		}
		
	
		-ms-overflow-style: none;  /* IE and Edge */
		scrollbar-width: none;  /* Firefox */
	}
	
	@media (max-width: 780px){
		left: 0;
	}
	${below.phone`
		.hidden-mobile{
			display: none;
		}
	`}
	
`;

export const FcLink = styled.a`
	color: ${colors.coach};
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: flex-start;
`;

export const StyledStatBlock = styled(StatBlock)`
	span{
		color: ${({positive}) => positive ? "#4EA431" : "red"} !important;
	}
`;

export const Exists = styled.div`
	${({when}) => !when && `
		display: none;
	`}
`;


export const AvatarWrapper = styled.div`
	width: 40px;
	height: 40px;
	margin-right: 5px;
	${below.phone`
		display: none;
	`}
`;

export const FlexRow = styled.div`
	display: flex;
	align-items: center;
`;

export const FlexRowStart = styled(FlexRow)`
	align-items: flex-start;
	padding-top: 20px;
	img {
		margin: 0;
	}
`;

export const FlexCol = styled.div`
	display: flex;
	flex-direction: column;
`;

export const MobileDiv = styled.div`
	display: none;
	${below.phone`
		display: block;
	`}
`;

export const WebDiv = styled.div`
	${below.phone`
		display: none;
	`}
`;

export const PlayerOpponentBlock = styled.div`
	font-family: TitilliumUpright;
	font-weight: bold;
	font-size: 14px;
	color: #fff;
	line-height: 18px;
	display: flex;
	align-items: center;
	img{
		height: 20px;
		width: 20px;
		border-radius: 50%;
		margin: 0 10px;
		background: white;
	}
	justify-content: flex-start;
	position: absolute;
	right: 0;
	width: 45%;
	bottom: 10px;
	z-index: 10;
	${below.phone`
		right: unset;
		left: 10px;
		width: 50%;
		font-size: 12px;
		img{
			margin: 0 5px;
		}
	`}
`;

export const FullProfileLink = styled(ButtonPrimaryLink)`
	font-size: 12px;
	font-family: SourceSansPro;
	font-weight: 600;
    height: 100%;
	width: 200px;
	height: 40px;
`;


export const FullProfileContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	background-color: transparent;
	align-items: center;
	opacity: 0.85;
	${({isOverview}) => !isOverview && `
		position: sticky;
		bottom: -10px;
	`}
`;

export const FullPlayerLinkBackground = styled.div`
	background: rgba(0,0,0,0.1);
	width: 205px;
	height: 45px;
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
`;


export const TopPopupBlock = styled.div`
	background-repeat: no-repeat;
	background-position: 0 0;
	background-color: ${({ squad_id }) => club_colors[squad_id] || "#DFDFDF"};
	width: 100%;
	height: 300px;
	color: #FFF;
	position: relative;
	&:after {
		content: '';
		display: block;
		clear: both;
	}
	${below.phone`
		height: 260px
	`}
	${PlayerAvatar}{
		left: 36%;
		height: 340px;
		top: -40px;
		@media(max-width: 767px){
			height: 320px;
			right: 0px;
			top: -17px; 
		}
		${below.phone`
			height: 245px;
			right: 0px;
			left: unset;
			top: unset;
			bottom: 0; 
		
		`}
	}
	${PlayerStats}{
		position: absolute;
		padding: 20px 25px 25px 40px;
		right: 10px;
		width: 52%;
		
		@media (max-width: 767px){
			margin-left: 5px;
			padding-top: 80px;
			
		}
		${below.phone`
			padding: 0;
			left: 10px;
			margin-left: 0;
			width: 60%;
			padding-bottom: 30px;
		`}
	}
`;

export const PlayerPreviewWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
`;

export const PlayerPreviewSection = styled.div`
	width: 100%;
	padding: 8px 13px;
	color: ${colors.primary.primary};
	font-family: SourceSansPro;
	font-size: 12px;
	font-weight: 400;
	line-height: 15px;
	padding-bottom: 5px;
	${below.phone`
		padding-left: 15px;
		padding-right: 1px;

	`}
`;
export const PlayerPreviewLock = styled.div`
	width: 100%;
	height: 55px;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
	padding-top: 5px;
	p{
		color: ${colors.coach};
		font-size: 14px;
		font-family: SourceSansPro;
		font-weight: 400;
		margin-top: 2px;
	}
	${below.phone`
		padding-left: 10px;
		padding-right: 10px;
		p{
			font-size: 12px;
		}
	`}
`;