
// @flow
import * as React from "react";
import styled from "styled-components";
import * as _ from "lodash";
import * as numeral from "numeral";

import type { 
	TRound,
	ById, 
	TPlayer,
	TMatch,
	TSquad,
} from "../../modules/types";
import * as format from "../../helpers/numeralFormats";

import Table, { THead, TBody, Tr, Td, Th } from "../TableX";
import { TdStat } from "../Draft";
import ThStat from "../ThStat";
import type { TStat as TStatStandard } from "../PlayerProfile/printStat";
import MATCH_STATS, { customMatchStats } from "../../utils/matchStats";
import PlayerInfo from "../PlayerInfo";
import {getClubLogoIndigenous} from "../../utils/clubLogos";
import colors from "../../assets/css/colors";
import { 
	MatchStatContainer, 
	MatchValue, 
	MatchScore 
} from "../PlayerProfile/playerProfileStats/playerProfileStatsStyles";
import type { TCellInfo } from "../../helpers/tables";
import { renderTableHeaderCell } from "../../helpers/tables";
import { TeamUserAvatar } from "../Team";
import { below } from "../../assets/css/media";
import type { TPLeague } from "../../modules/types";

type TStat = TStatStandard & {
  auto_width?: boolean
};

const DEFAULT_VALUE = "-";
const getStandardWidth = ({ standard_width }) => standard_width ? "50px" : "auto";
const PTableContainer = styled.div`
  overflow-x: auto;
    width: 100%;
    overflow-y: hidden;
  ${below.desktop`
    overflow-y: auto;
  `}
`;
const PTh = styled(ThStat)`
  width: auto;
  .standard-width {
    width: 50px;
  }
  height: 60px;
  box-sizing: border-box;

  position: sticky;
  top: 0px;
  left: 0;
  background: white;
  z-index: 95;

  :hover {
    z-index: 96;
  }
  

  ${below.tablet` 
    top: 0px; 
  `}
  
`;
const PTHead = styled(THead)``;
const PTd = styled(TdStat)`
  height: 60px;
  width: ${getStandardWidth};
  font-weight: 200;
  font-family: SourceSansPro;
  box-sizing: border-box;
  border-bottom: 1px solid ${colors.secondary.paleGrey}!important;
  min-width: unset;
`;
const TdIgnore = styled(Td)`
  width: 0;
  border: 0;
  padding: 0;
`;
const ThIgnore = styled(Th)`
  width: 0;
  border: 0;
  padding: 0;
`;
const MatchTd = styled(PTd)`
  height: 60px;
`;

const OwnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    height: 40px;
    width: 40px;
  }
`;

const TeamLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > img {
    height: 40px;
    width: 40px;
  }
`;
const PTdPlayer = styled(PTd)`
  ${below.tablet`
    > div > .player-avatar {
      display: none;
    }
  `}

  @media (max-width: 360px) {
    > div > .player-avatar {
      display: none;
    }
  }
`;
const PTr = styled(Tr)`
  border: none;
	position: relative;
`;
const PTBody = styled(TBody)``;
const PTable = styled(Table)`
  /* Border bottom mucks up the alignment/heights of cells */
  box-shadow: 0 10px ${colors.secondary.paleGrey};
  border-collapse: separate;

  ${PTh} {
    :first-child {
      text-align: center;
    }
  }

  > ${PTBody} > ${PTr} {
    border-bottom: 1px solid ${colors.secondary.paleGrey};
    > ${PTd} {
      border: none;
    }
  }

  > ${PTHead} > ${PTr} {
    border-bottom: 2px solid ${colors.secondary.paleGrey};
  }

  width: 100%;
	${below.tablet`
		& td,
		& th {
			position: relative;
			display: table-cell;
		}
	
		th:last-of-type {
			position: sticky;
			right: -2px;
			min-width: 50px;
			background: white;
			box-shadow: -3px 2px 30px -17px #333;
		}
		td:last-of-type {
			position: sticky;
			right: -2px;
			min-width: 50px;
			background: white;
			box-shadow: -3px 2px 30px -17px #333;
		}
	`};
`;

type Props = {
  round: TRound,
  match: TMatch,
  players: ById<TPlayer>,
  owners: ById<number>,
  player_ids: Array<number>,
  match_stats: ById<number>,
  is_classic: boolean,
  squads: ById<TSquad>,
  setOrderBy: Function,
  order_by: string,
  order_desc: boolean,
  league: TPLeague,
  custom_stats: Object,
  actual_round: Object,
}

type State = {

}

type CellRenderer = (TStat) => React.Node;

export class PlayersTable extends React.Component<Props, State> {
	constructor() {
		super();

		_.bindAll(this, [
			"renderMatchStats",
			"renderHeaderCell",
			"renderTableRow",
			"displayStat"
		]);
	}

	componentDidMount() {
		const { match, league, setOrderBy } = this.props;

		const matchStatus = _.get(match, "status", "scheduled");

		const statsBasis = _.get(league, "custom_scoring_enabled") === 1 ? "custom_stats" : "stats";
		const newOrder = `${statsBasis}.avg_points`;
    
		if(matchStatus === "scheduled"){
			setOrderBy(newOrder);
		}
    

	}
  
	renderMatchStats(renderer: CellRenderer) {
		const { is_classic, league } = this.props;
		if(!is_classic && league.custom_scoring_enabled === 0){
			return _(MATCH_STATS.played)
				.filter((stat: TStat) => !stat.not_draft || is_classic)
				.map(renderer)
				.value();
		}
		if(!is_classic && league.custom_scoring_enabled === 1){
			return _(customMatchStats(league.custom_scoring))
				.filter((stat: TStat) => !stat.not_draft || is_classic)
				.map(renderer)
				.value();
		}
		return _(MATCH_STATS.played)
			.filter((stat: TStat) => !stat.not_draft || is_classic)
			.map(renderer)
			.value();

    
	}

	renderHeaderCell(stat: TStat): React.Node {
		return renderTableHeaderCell(
			this.statToCellInfo(stat), 
			PTh, 
			stat.auto_width ? "" : "standard-width"
		);
	}

	statToCellInfo(stat: TStat): TCellInfo {
		return {
			table: {
				order_by_desc: this.props.order_desc,
				sort_key: this.props.order_by
			},
			cell: {
				...stat,
				prop: stat.id,
				sort_key: stat.id,
				short_name: stat.name,
				rel: stat.id,
				sortable: true,
				onClick: this.props.setOrderBy,
				is_fc: stat.is_coach || false,
				tooltip: (stat.id === "stats.score" || stat.id ==="stats.custom_score")
					? undefined 
					: stat.tooltip,
			}
		};
	}

	displayStat(stat: TStat, player_id: number) {
		const { players, match_stats, round, custom_stats, league } = this.props;
		const player = _.get(players, player_id);
		const round_id = _.get(round, "id");
		const value = _.get(match_stats, [player_id, stat.id], DEFAULT_VALUE);

		if (stat.id === "TOG") {
			return (<TdStat rel={stat.id} key={stat.id} standard_width>
				<MatchStatContainer>
					{ numeral(value / 100).format(format.PERCENTAGE) }
				</MatchStatContainer>
			</TdStat>);
		}
		else if (stat.id === "stats.score") {
			if(league.custom_scoring_enabled === 1){
				const customScore =  
					_.get(custom_stats, [player_id, "scores", round_id], DEFAULT_VALUE);
				return <TdStat rel={stat.id} key={stat.id} standard_width>
					<MatchStatContainer>
						{ customScore }
					</MatchStatContainer>
				</TdStat>;
			}
			return <TdStat rel={stat.id} key={stat.id} standard_width>
				<MatchStatContainer>
					{ _.get(player, ["stats", "scores", round_id], DEFAULT_VALUE) }
				</MatchStatContainer>
			</TdStat>;
		}

		const score = _.isNumber(value)
			? _.get(stat, "score_per", 0) * value
			: DEFAULT_VALUE;

		return (
			<MatchTd rel={stat.id} key={stat.id} standard_width>
				<MatchStatContainer>
					<MatchValue>{value}</MatchValue>
					<MatchScore>{score}</MatchScore>
				</MatchStatContainer>
			</MatchTd>
		);
	}

	renderPlayerInfoCell(player_id: number) {
		const { players, is_classic } = this.props;
		const player = _.get(players, player_id);

		return <PlayerInfo 
			player={player}
			is_classic={is_classic}
		/>;
	}

	renderTableHead() {
		const { is_classic } = this.props;
		return <PTHead>
			<PTr>
				<ThIgnore />

				{this.renderHeaderCell({
					id: "squad_id",
					name: "Club"
				})}
				{this.renderHeaderCell({
					id: "player_name",
					name: "Player",
					auto_width: true
				})}
				{this.renderHeaderCell({
					id: "owner",
					name: is_classic ? "Owned" : "Owner"
				})}
				{ this.renderMatchStats(this.renderHeaderCell) }
			</PTr>
		</PTHead>;
	}

	renderOwnerCell(player_id: number) {
		const { owners } = this.props;
		const owner = _.get(owners, player_id);
		if (!_.isEmpty(owner)) {
			return <OwnerWrapper title={owner.name}>
				<TeamUserAvatar 
					user_id={owner.user_id}
					firstname={owner.firstname}
					lastname={owner.lastname}
				/>
			</OwnerWrapper>;
		}

		return <div />;
	}

	renderTableRow(player_id: number): React.Node {
		const { players, squads, actual_round } = this.props;
		const player = _.get(players, player_id);
		const squad_id = _.get(player, "squad_id");
		const squad_name = _.get(squads, [squad_id, "name"]);
		const clubLogo = getClubLogoIndigenous(actual_round.id, squad_id);

		const statCellRenderer = _.partial(this.displayStat, _, player_id);

		return <PTr key={player_id}>
			<TdIgnore />
			<PTd rel='squad_id' standard_width>
				<TeamLogoWrapper>
					{squad_id && <img src={clubLogo} alt={squad_name} /> }
				</TeamLogoWrapper>
			</PTd>
			<PTdPlayer rel='player_name'>{ this.renderPlayerInfoCell(player_id) }</PTdPlayer>
			<PTd rel='owner' standard_width>{this.renderOwnerCell(player_id)}</PTd>
			{ this.renderMatchStats(statCellRenderer) }
		</PTr>;
	}

	renderTableBody() {
		const { player_ids } = this.props;
		return <PTBody>
			{
				_.map(player_ids, this.renderTableRow)
			}
		</PTBody>;
	}

	render() {
		return <PTableContainer>
			<PTable
				
			>
				{ this.renderTableHead() }
				{ this.renderTableBody() }
			</PTable>
		</PTableContainer>;
    
	};
}

export default PlayersTable;