// @flow
import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import _ from "lodash";

import styled from "styled-components";
import * as actions from "../modules/actions";
import * as selectors from "../modules/selectors";
import isAuthenticated from "../utils/auth/isAuthenticated";
import {TUser} from "../modules/types";

import Edit from "../components/Icons/Edit";

import colors from "../assets/css/colors";

import {
	Footer,
	PageTitle,
	TextModal,
	PageContentWrapper,
	TwoColumnLayout,
	Ad,
	AdsContainer,
	StandardSidebar
} from "../components";
import RecoverForm from "./recover/RecoverForm";

const PageTitleStyled = styled(PageTitle)`
	font-size: 32px;
	display: flex;
	align-items: flex-end;

	svg{
		margin: 0 10px 8px 0;
	}
`;

type Props = {
	activateUser: typeof actions.activateUser,
	user: TUser,
	user_activate_error: null | string,
	is_user_activate_success: boolean,
	clearUserActivateError: Function
};

type State = {
}

class RegistrationPage extends React.Component<Props, State > {
	constructor() {
		super();

		_.bindAll(this, [
			"handleSubmit",
			"handleErrorModalClose",
		]);
	}

	handleSubmit(values) {
		const { activateUser } = this.props;
		let data_to_send = values;
		if(data_to_send.news === 1){
			data_to_send = {
				...data_to_send,
				supported_club_offers: 1
			};
		}
		const data = {
			...data_to_send
		};

		activateUser(data);
	}

	get error_modal() {
		const { user_activate_error } = this.props;

		return (
			<TextModal
				onClick={this.handleErrorModalClose}
				header_text='Error'
				text={user_activate_error || "Error"}/>
		);
	}

	handleErrorModalClose() {
		const { clearUserActivateError } = this.props;
		clearUserActivateError();
	}

	render() {
		const { user, user_activate_error, is_user_activate_success } = this.props;

		if (user.recovered) {
			if (is_user_activate_success) {
				// On success of activate user
				return <Redirect to="/coach-subscription" />;
			}

			// If user hits recover page but they are already recovered
			return <Redirect to="/" />;
		}

		return (
			<React.Fragment>
				{ user_activate_error ? this.error_modal : null }
				<AdsContainer><Ad /></AdsContainer>
				<PageContentWrapper>
					<PageTitleStyled>
						<Edit color={colors.title} size='.8' />
							Register for the new season
					</PageTitleStyled>

					<TwoColumnLayout>
						<RecoverForm
							onSubmit={this.handleSubmit}
							user={user}
						/>
						<StandardSidebar />
					</TwoColumnLayout>
				</PageContentWrapper>
				<Footer />
			</React.Fragment>
		);
	}
}

const mapStateToProps = state => ({
	is_authorized: isAuthenticated(),
	user_activate_error: selectors.getActivateUserError(state),
	is_user_activate_success: selectors.isUserActivateSuccess(state),
	user: state.user.data,
});

const mapDispatchToProps = {
	activateUser: actions.activateUser,
	clearUserActivateError: actions.clearUserActivateError,
};

export const Registration = connect(
	mapStateToProps,
	mapDispatchToProps,
)(RegistrationPage);

export default Registration;