// @flow
import { createSelector } from "reselect";
import isEmpty from 'lodash/isEmpty';
import inRange from 'lodash/inRange';
import leagueDraftSelectors from "../../leaguesDraft";
import * as roundsUtil from "../../rounds";
import type { TPLeague } from "../../../types";

export const getLeagueRounds = createSelector(
	leagueDraftSelectors.getLeague,
	roundsUtil.getRounds,
	(league: TPLeague, rounds: Array<Object>) => {
		if (isEmpty(league) || isEmpty(rounds)) {
			return [];
		}
		const { start_round, end_round } = league;
		return rounds.filter(({ id }) => inRange(id, start_round, end_round + 1));
	}
);

export const getLeagueRoundsWithMatches = createSelector(
	leagueDraftSelectors.getLeague,
	roundsUtil.getRoundsWithMatches,
	(league: TPLeague, rounds: Array<Object>) => {
		if (isEmpty(league) || isEmpty(rounds)) {
			return [];
		}
		const { start_round, end_round } = league;
		return rounds.filter(({ id }) => inRange(id, start_round, end_round + 1));
	}
);