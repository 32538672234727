// @flow
import * as React from "react";
import _ from "lodash";
import { Link } from "react-router-dom";

import type {
	TPLeague,
	TDraftUserTeam,
	TPosition,
	TRound,
	TRoundWithMatches,
	TPlayer,
	ById,
	TTeam
} from "../../modules/types";
import NextSquadOpponent from "../../components/NextSquadOpponent";

import { getScoringPlayers } from "../../helpers/teamsClassic/index.js";
import Lock from "../../components/Icons/Lock";

import colors from "../../assets/css/colors";
import {
	displayRoundLabel,
	displayRoundScore,
	getDisplayRoundId,
	shouldDoubleScore,
	showDNP
} from '../../helpers/stats/displayRound';
import { isAllTrue, isAnyTrue } from '../../helpers';
import { 
	getPlayerCustomProjectedScoreByRound, 
	getPlayerProjectedScoreByRound 
} from '../../helpers/players';
import { findMatch, matchStarted } from "../../helpers/rounds";
import {
	Player,
	ScoreLabel,
	Score,
	PlayerInfoStyled,
	NextSquadOpponentWrapper,
	ScoreWrapper,
	PlayerComparisonRow,
} from "./playerRow";
import EmptyPlayerInfo from "./emptyPlayerInfo";

const DEFAULT_VALUE = "-";

const POSITION_KEYS = ["1","2","3","4", "emergency", "utility"];

const getPlayerStatField = (
	roundId: number, 
	isCustomScoring: boolean, 
	player, 
	has_assistant_coach,
	default_value
) => {
	let player_stat_field = `stats.scores.${roundId}`;
	if(isCustomScoring) {
		const isValue = _.get(player, `custom_stats.scores[${roundId}]`) ;
		if(!isValue && !has_assistant_coach){
			return "-";
		}
		player_stat_field = Boolean(isValue) || default_value === "DNP" ? 
			`custom_stats.scores.${roundId}`:`custom_stats.proj_score_actual_round`;
	}
	return player_stat_field;
};

const getRoundDefaultValue = (round, is_dnp_eligible, projectedScore, has_assistant_coach) => {
	const projectedOrBlank = has_assistant_coach ? projectedScore : "-";
	return showDNP(round, is_dnp_eligible)
		? "DNP" : projectedOrBlank;
};
type Props = {
	home_team: TDraftUserTeam,
	away_team: TDraftUserTeam,
	players: ById<TPlayer>,
	positions: Array<TPosition>,
	round: TRound,
	actual_round: TRound,
	has_assistant_coach: boolean,
	league: TPLeague,
	start_compressed: boolean,
	field_stats: Array<Object>,
	active_stats_type: string,
	setActiveStatsType: Function,
	rounds: Array<TRoundWithMatches>,
	openPlayerPopup: Function,
	is_classic: boolean,
	tabs?: React.Node,
	is_compressed: boolean,
	custom_stats: Object,
  user_id: number,
  round_id: number,
}
const getSquadMatch = (matches, squad_id) => {
	// get the match
	return Array.isArray(matches) && matches.find(match =>
		match.away_squad_id === squad_id || match.home_squad_id === squad_id);
};

export class AbstractComparisonTable extends React.Component<Props> {
	static defaultProps = {
		is_classic: false,
		positions: []
	};

	get positions_list() {
		const {
			positions,
			is_classic
		} = this.props;
		let additional_positions = [{ id: "bench", full_name: "Interchange" }];
		if (is_classic) {
			additional_positions.push(
				{ id: "utility", full_name: "Utility" }
			);
		}
		// $FlowFixMe
		return positions ? positions.concat(additional_positions) : null;
	}

	get last_round() {
		const { round, rounds } = this.props;

		const last_round_id = _.get(round, "id", 1) - 1;
		return _.find(rounds, { id: last_round_id });
	}

	is_team_player_captain(team: TTeam, player_id: number) {
		return _.get(team, "lineup.captain") === player_id;
	}

	is_team_player_vice_captain(team: TTeam, player_id: number) {
		return _.get(team, "lineup.vice_captain") === player_id;
	}

	is_player_scoring(team: TTeam, player_id: number) {
		const { is_classic, players, round } = this.props;
		if(is_classic){ 
			const scoring_players = getScoringPlayers(team.lineup, players, round);
		
			const is_in_scoring_array = 
        		scoring_players.some(el => 
        			(isAllTrue([el.player_id === player_id, el.score !== null])));
			let is_on_bench = false;
			POSITION_KEYS.forEach(position_key => {
				
				if(position_key === "utility" && team.lineup.bench[position_key].id === player_id){
					is_on_bench = true;
				}
				if(team.lineup.bench[position_key].length &&  
				team.lineup.bench[position_key].includes(player_id) ){
					is_on_bench = true;
				}
			});
			const is_interchange_active = isAllTrue([round.status === "active",  is_on_bench ]);
			const player_squad_match = getSquadMatch(_.get(round, "matches"), players[player_id].squad_id);
      
		  	const match_status = _.get(player_squad_match, "status", "scheduled");
			const is_starting_active = (isAllTrue([match_status !== "complete", !is_on_bench]));
			
			if(round.status === "complete"){
				return is_in_scoring_array;
			}
			return ((is_in_scoring_array || is_starting_active) && !is_interchange_active);
		}

		const scoring_players = _.get(team, `scoring_players[${round.id}].players`, []);
		const is_on_bench = team.lineup.bench.includes(player_id);
		const player_squad_match = 
        getSquadMatch(_.get(round, "matches"), players[player_id].squad_id);
      
		  const match_status = _.get(player_squad_match, "status", "scheduled");
		const is_starting_active = (match_status !== "complete" && !is_on_bench);
		return (scoring_players.includes(player_id) || is_starting_active);
	}

	is_team_player_emergency(team: TTeam, player_id: number) {
		const { is_classic } = this.props;
		if (is_classic) {
			return _.includes(_.get(team, "lineup.bench.emergency"), player_id);
		}

		const emg = _.get(team, "lineup.emergency");
		return _.isObject(emg) && Object.values(emg).includes(player_id);
	}

	getPlayerSpecialPosition(team: TTeam, player_id: number) {
		if (this.is_team_player_captain(team, player_id)) {
			return "captain";
		}
		else if (this.is_team_player_vice_captain(team, player_id)) {
			return "vice_captain";
		}
		else if (this.is_team_player_emergency(team, player_id)) {
			return "emergency";
		}

		return null;
	}

	renderPlayerScoreLabel(player: TPlayer, match_status: string, score: number | string) {
		const { active_stats_type, round, is_compressed, league , has_assistant_coach} = this.props;
		const projected = active_stats_type === "proj_score";
		const average = active_stats_type === "avg_points";
		let label;
		if (projected) {
			label = "Proj";
		}
		else if (average) {
			label = "Avg";
		}
		else if (active_stats_type === "last_round") {
			const last_round = this.last_round;
			const last_round_status = _.get(last_round, "status");

			if (last_round_status !== "complete") {
				const score = displayRoundScore(player, last_round, league.custom_scoring_enabled);
				label = displayRoundLabel(player, last_round, score);
			}
			else {
				label = "Last Round";
			}
		}
		else {
			const score = displayRoundScore(player, round, league.custom_scoring_enabled);
			label = displayRoundLabel(player, round, score, has_assistant_coach);
		}
		return (
			<ScoreLabel
				is_projected={isAnyTrue([
					projected,
					label==="Projected"
				])}
				is_average={average}
				is_playing={match_status === "playing" && score !== "DNP"}
				is_compressed={is_compressed}
			>
				{label === "FINAL" ? null :  label}
			</ScoreLabel>
		);
	}

	getPlayerForScoreValue(player: TPlayer){
		const {league, custom_stats} = this.props;
		const player_id = _.get(player, "id", 0);
		const customScoringEnabled = _.get(league, "custom_scoring_enabled", false);
		const playerCustomStats = _.get(custom_stats, player_id, {});
		return customScoringEnabled 
			? {...playerCustomStats, 
				squad_id: player.squad_id, 
				id: player.id, 
				custom_stats: playerCustomStats} : player;
	}

	getPlayerProjectedScoreValue(player: TPlayer){
		const {league, round} = this.props;
		const customScoringEnabled = _.get(league, "custom_scoring_enabled", false);
		return customScoringEnabled ?  
			getPlayerCustomProjectedScoreByRound(
				player, round.id, DEFAULT_VALUE
			): getPlayerProjectedScoreByRound(
				player, round.id, DEFAULT_VALUE
			);
	}

	getPlayerScoreValue(player: TPlayer, match_status: string, team: TTeam) {
		const { 
			round, 
			active_stats_type, 
			players, 
			league, 
			custom_stats,
			has_assistant_coach,
			is_classic
		 } = this.props;
		const customScoringEnabled = _.get(league, "custom_scoring_enabled", false);

		let player_to_use = this.getPlayerForScoreValue(player);
		

		let score: any = DEFAULT_VALUE;
		const is_projected = active_stats_type === "proj_score";
		const is_average = active_stats_type === "avg_points";
		const captain_id = _.get(team, ["lineup", "captain"]);
		const player_id = _.get(player, "id", 0);
		const projectedScore = this.getPlayerProjectedScoreValue(player);
		const isCaptainsEnabled = isAnyTrue([
			isAllTrue([
				!is_classic,
				league.captains
			]),
			is_classic
		]) ;
		let should_double = false;
	
		if (active_stats_type === "round_points") {
			should_double = isAllTrue([
				shouldDoubleScore(
					player, _.get(team, "lineup"), round, _.get(players, captain_id)
				),
				isCaptainsEnabled
			]);
			const match_started = matchStarted(round, _.get(player, "squad_id", 0));
			const round_id = getDisplayRoundId(round);
			const matches = _.get(round, "matches", []);
			const is_team_dnp = findMatch(matches, _.get(player, "squad_id", 0)) === undefined;
			const is_dnp_eligible = isAnyTrue([match_started, is_team_dnp]);
			const default_value = getRoundDefaultValue(
				round, is_dnp_eligible, projectedScore, has_assistant_coach
			);
			let player_stat_field =  getPlayerStatField(
				round_id, 
				customScoringEnabled, 
				player_to_use,
				has_assistant_coach,
				default_value
			);
			score = _.get(player, player_stat_field, default_value);
		}
		
		else if (active_stats_type === "last_round") {
			score = displayRoundScore(player_to_use, this.last_round, customScoringEnabled);
		}
		else if (is_projected) {
			score = projectedScore;
			should_double = isAllTrue([
				player_id === captain_id,
				isCaptainsEnabled
			]) ;
		}
		else if (is_average) {
			score = customScoringEnabled ? 
				_.get(custom_stats, `[${player_id}].avg_points`, DEFAULT_VALUE) : 
				_.get(player, ["stats", "avg_points"], DEFAULT_VALUE);
		}

		if (isAllTrue([should_double, _.isNumber(score)])) {
			score = score * 2;
		}

		return score;
	}

	renderPlayerScore(score: any, match_status: string, player: TPlayer) {
		const { has_assistant_coach, active_stats_type, is_compressed, round } = this.props;
		const is_projected = active_stats_type === "proj_score";
		const is_average = active_stats_type === "avg_points";
		const label = displayRoundLabel(player, round, score, has_assistant_coach);

		return (
			<Score
				is_playing={match_status === "playing" && score !== "DNP"}
				is_projected={is_projected || label === "Projected"}
				is_average={is_average}
				is_compressed={is_compressed}
			>
				{is_projected && !has_assistant_coach
					? (
						<Link to="/coach-subscription" title="Coach Subscription">
							<Lock color={colors.coach} />
						</Link>
					)
					: score
				}
			</Score>
		);
	}

	renderPlayer(team: TTeam, player: TPlayer, align: 'left' | 'right' = "left") {
		const {
			round: selected_round,
			openPlayerPopup,
			is_compressed,
			active_stats_type,
			is_classic,
			user_id,
			round_id,
			actual_round
		} = this.props;
		const player_id = _.get(player, "id", 0);
		const reverse = align === "right";
		const lastIndexMatches = _.get(this.props.rounds[round_id - 1], "matches", []).length - 1;
		const last_match_status =_.get(this.props.rounds[round_id - 1], 
			`matches[${lastIndexMatches}].status`, "scheduled") === "complete"
			|| actual_round.id === round_id;
		const is_opponent_team = team.user_id !== user_id;
		const is_show_lineup = 
      (isAllTrue([is_classic, last_match_status, is_opponent_team])) 
      || (!is_opponent_team) || !is_classic;
		
		if (isAnyTrue([!player, !selected_round, !team, !is_show_lineup])) {
			return <EmptyPlayerInfo reverse={reverse} is_compressed={is_compressed} />;
		}

		const round = active_stats_type === "last_round"
			? this.last_round
			: selected_round;

		const player_squad_match = getSquadMatch(_.get(round, "matches"), player.squad_id);
		const match_status = _.get(player_squad_match, "status", "scheduled");
		const special_position = this.getPlayerSpecialPosition(team, player_id);

		const is_captain = this.is_team_player_captain(team, player_id);
		const is_vice_captain = this.is_team_player_vice_captain(team, player_id);
		const is_emergency = this.is_team_player_emergency(team, player_id);
		const score = this.getPlayerScoreValue(player, match_status, team);
		const is_scoring_player = this.is_player_scoring(team, player_id);
		const is_dnp = score === "DNP";
		return (
			<Player 
				special_position={special_position} 
				reverse={reverse} is_dnp={is_dnp} 
				is_scoring_player={is_scoring_player}>
				<PlayerInfoStyled
					player={player}
					reverse={reverse}
					is_compressed={is_compressed}
					hide_favourite
					is_captain={is_captain}
					is_vice_captain={is_vice_captain}
					is_emergency={is_emergency}
					openPlayerPopup={openPlayerPopup}
					is_classic={is_classic}
					league_matchup={true}
				/>
				<NextSquadOpponentWrapper>
					<NextSquadOpponent
						is_compressed={is_compressed}
						squad_id={player.squad_id}
						actual_round={round}
					/>
				</NextSquadOpponentWrapper>
				<ScoreWrapper reverse={reverse} is_compressed={is_compressed}>
					{this.renderPlayerScore(score, match_status, player)}
					{this.renderPlayerScoreLabel(player, match_status, score)}
				</ScoreWrapper>
			</Player>
		);
	}

	renderPlayerRow(home_team_player_id: number, away_team_player_id: number) {
		const {
			players,
			home_team,
			away_team
		} = this.props;
		const home_team_player = _.get(players, home_team_player_id);
		const away_team_player = _.get(players, away_team_player_id);

		return (
			<PlayerComparisonRow key={`${home_team_player_id}-${away_team_player_id}`}>
				{this.renderPlayer(home_team, home_team_player)}
				{this.renderPlayer(away_team, away_team_player, "right")}
			</PlayerComparisonRow>
		);
	}

	render(): any {
		return null;
	}
}

export default AbstractComparisonTable;