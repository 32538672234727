// @flow
import React from "react";
import styled from "styled-components";
import * as _ from "lodash";
import { ButtonFixtureMatchUp } from "../";
import type { TRound } from "../../modules/types";
import type { TFixture, TLeague, TTeam } from "../Fixtures/types";



type TGameType = 'draft' | 'classic';

type Props = {
	league: TLeague,
	fixture: TFixture,
	round: TRound,
	shadow?: boolean,
	compact?: boolean,
	className?: string,
	has_assistant_coach: boolean,
	is_game_bar?: boolean,
	game_type: TGameType,
	show_rank: boolean,
	season_finished: boolean,
	league_fixture?: boolean,
  actual_round: TRound,
}

const Wrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	height: 52px;
	width: 215px;
	background-color: #F0F3F7;
	border: 1px solid #CAD2D8;
	margin-bottom: 20px;
`;

const Rank = styled.div`
	height: 50px;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	width: 20px;
	font-family: SourceSansPro;
	font-size: 14px;
	color: #1D4073;
	font-weight: 600;
	background-color: #fff;
`;

const FixtureTeam = styled.div`
	display: flex;
	flex-direction: ${({ reverse }) => reverse ? "row-reverse" : "reverse"};
	justify-content: flex-start;
	align-items: center;
	height: 50px;
  ${({ loser }) => loser && `
    opacity: 0.4;
  `}
`;
const TeamInfoWrapper = styled.div`
	height: 30px;
	display: flex;
	color: #1D4073;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: ${({ reverse }) => reverse ? "flex-end" : "flex-start"};
	padding: ${({ reverse }) => reverse ? "0 8px 0 0" : "0 0 0 8px"};
`;
const EmptyTeamInfoWrapper = styled.div`
	height: 50px;
	display: flex;
	color: #1D4073;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: ${({ reverse }) => reverse ? "flex-end" : "flex-start"};
	padding: ${({ reverse }) => reverse ? "0 8px 0 0" : "0 0 0 8px"};		
	background-color: #F0F3F7;
`;
const EmptyTeamName = styled.div`
	height: 10px;
	width: 58px;
	background-color: white;


`;
const EmptyUserName = styled.div`
	height: 10px;
	width: 29px;
	background-color: white;
`;


const EmptyRank = styled.div`
	width: 11px;
	height: 10px;
	background-color: #F0F3F7;
`;

const TeamName = styled.p`
	width: 64px;
	line-height: 14px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	font-size: 12px;
	font-family: TitilliumUpright;
	font-weight: 700;
	color: #1D4073;
	text-align: ${({ reverse }) => reverse ? "right" : "left"};
`;

const UserName = styled.p`
	width: 64px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	font-family: SourceSansPro;
	font-weight: 400;
	font-size: 12px;
	color: #1D4073;
	text-align: ${({ reverse }) => reverse ? "right" : "left"};
`;



class BracketFixture extends React.Component<Props> {
	static defaultProps = {
		show_rank: false,
		season_finished: false,
	};
	get is_scheduled() {
		const { round } = this.props;
		return round.status === "scheduled";
	}

	get is_live() {
		const { round } = this.props;
		return round.status === "active";
	}

	get is_full_time() {
		const { round } = this.props;
		return round.status !== "active" && round.status !== "scheduled";
	}

	get show_projected_score() {
		const { has_assistant_coach } = this.props;
		return (this.is_scheduled || this.is_live) && has_assistant_coach;
	}

	get loser() {
		//use this when we have actual finals results from actual fixtures
		const { fixture } = this.props;
		if (this.is_full_time && fixture.home && fixture.away) {
			const { home, away } = fixture;
			if (home.points === away.points) {
				return 0;
			}
			return home.points > away.points ? away.id : home.id;
		}
		return 0;
	}

	get button_text() {
		return {
			type: "",
			text: "vs"
		};
	}
	get loser_string() {
		// if round before actual round check the two teams for a winner in their scoreflow
		const { actual_round, round, fixture: { home, away } } = this.props;
		let loser = "";
    
		if(actual_round.id > Number(round) && (home && away)){
			const home_score = home.scoreflow ? home.scoreflow[round] : 0;
			const away_score = away.scoreflow ? away.scoreflow[round] : 0;
			loser = home_score > away_score ? "away" : "home";
		}
		return loser;
	}


	renderRank(team?: TTeam, reverse: boolean = false) {
		const { show_rank, compact } = this.props;
		const show_rank_element = show_rank && compact;
		const selected_rank = _.get(team, "rank_selected_round", 0);
		
		return(
			<Rank show={show_rank_element} reverse={reverse}>
				{ selected_rank === 0 ? "-" : selected_rank}
			</Rank>
		);
			
		
		
	}

	render () {

		const {
			fixture: { home, away },
			league,
			
			compact,
			is_game_bar,
			game_type,
			className,

		} = this.props;
		const matchup_button = this.button_text;


		const league_id = _.get(league, "id");

		return ( 
		
			<Wrapper className={className} compact={compact} 
				is_game_bar={is_game_bar} league_fixture={this.props.league_fixture}>
				{home === "" ? <FixtureTeam>
					<Rank>
						<EmptyRank />
					</Rank>
					
					<EmptyTeamInfoWrapper>
						
						<EmptyTeamName />
						<EmptyUserName />
						
					</EmptyTeamInfoWrapper>
				</FixtureTeam> :
					
					<FixtureTeam
						compact={compact}
						is_game_bar={is_game_bar}
						team={home}
						game_type={game_type}
						league_id={league_id}
						firstname={_.get(home, "firstname", "")}
						lastname={_.get(home,"lastname", "")}
						name={_.get(home,"name", "")}
						rank={_.get(home, "rank", 0)}
						record_last_five={_.get(home, "record_last_five", [])}
						league_fixture={this.props.league_fixture}
						loser={this.loser_string === "home"}
					
					>
						{ this.renderRank(home) }
						<TeamInfoWrapper>
							<TeamName>
								{_.get(home, "name", "")}
							</TeamName>
							<UserName>
								{_.get(home, "firstname", "")} 
								{_.get(home, "lastname", "").charAt(0)}
							</UserName>
						</TeamInfoWrapper>

					</FixtureTeam>
					
				}
				<ButtonFixtureMatchUp is_fixture={true}
					type={matchup_button.type}
					is_game_bar={false}
				>
					{matchup_button.text}
				</ButtonFixtureMatchUp>
				{away === ""  ? <FixtureTeam reverse={true}>
					<Rank>
					 <EmptyRank />
					</Rank>
					<EmptyTeamInfoWrapper reverse={true}>
						<EmptyTeamName />
						<EmptyUserName />
						
					</EmptyTeamInfoWrapper>
					
					
				</FixtureTeam> :
					<FixtureTeam
						compact={compact}
						is_game_bar={is_game_bar}
						right_side
						team={away}
						game_type={game_type}
						league_id={league_id}
						firstname={_.get(away, "firstname", "")}
						lastname={_.get(away,"lastname", "")}
						name={_.get(away,"name", "")}
						rank={_.get(away, "rank", 0)}
						record_last_five={_.get(away, "record_last_five", [])}
						reverse={true}
						loser={this.loser_string === "away"}
					>
						{ this.renderRank(away, true) }
						<TeamInfoWrapper reverse={true}>
						
							<TeamName reverse={true}>
								{_.get(away, "name", "")}
							</TeamName>
							<UserName reverse={true}>
								{_.get(away, "firstname", "")} 
								{_.get(away,"lastname", "").charAt(0)}
							</UserName>
						</TeamInfoWrapper>
					
					</FixtureTeam>
				
				}
				
				
				
			</Wrapper> 
		);
	}
}

export default BracketFixture;