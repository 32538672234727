// @flow
import * as React from "react";
import { isEmpty } from "lodash";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { below } from "../../../../assets/css/media";
import colors from "../../../../assets/css/colors";

import { ModalContainer, 
	ModalContent,
	ModalBody, ModalOverlay,
} from "../../../../components/Modal";
import type { TPLeague } from "../../../../modules/types";
import LockoutOpen from "../../../../components/Icons/LockoutOpen";
import { updateDraftLeague } from "../../../../modules/actions/leaguesDraft";




const ModalTitle = styled.h2`
	color: ${colors.primary.primary};
	font-size: 32px;
	text-align: center;
	margin-bottom: 10px;
	padding-top: 20px;  
	${below.tablet`
		font-size: 24px;
	`}

	${below.phone`
		font-size: 22px;
	`}
`;

const ModalText = styled.p`
	color: ${colors.primary.primary};
	font-family: SourceSansPro;
	font-size: 14px;
	line-height: 20px;
	text-align: center;
	margin-bottom: 10px;
	padding-top: 20px;  
	${below.tablet`
		font-size: 12px;
	`}

	${below.phone`
		font-size: 12px;
	`}
`;


const ConfirmButton = styled.button`
	width: 200px;
	height: 40px;
	background: #1D4073;
	border-radius: 2px;
	color: white;
	outline: none;
	font-family: SourceSansPro;
	font-size: 12px;
	font-weight: 500;
	text-align: center;
	border: none;
	margin-top: 20px;
`;

const CancelButton = styled(ConfirmButton)`
	background: white;
	border: 1px solid ${colors.buttons.cancel};
	color: ${colors.buttons.cancel};
`;





const ProModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: grey;
	z-index: 2000;
	overflow: hidden;
	
	${ModalContent} {
		box-sizing: border-box;
		box-shadow: 1px 0px 12px 0 rgb(0,0,0,0.2);
		padding-top: 0;
		${below.tablet`
			max-width: unset;
		`}

		height: fit-content;
		padding-bottom: 30px;
		${below.phone`
			height: 502px;
			max-width: 320px;
			padding-top: 0;
			padding-left: 0;
			padding-right: 0;
		`}

		@media screen and (max-width: 330px) {
			padding-top: 0;
		}

		${ModalBody} {
			padding: 0 100px 0px;
			overflow: hidden;
			position: static;
			${below.desktop}{
				overflow: hidden;
			}
			${below.phone`
				padding: 0;
				max-width: 300px;
				margin: 0 auto;
			`}
		}
	}
  ${ModalOverlay}{
    background: rgba(0, 0, 0, 0.5);
    box-shadow: 1px 0px 12px 0 rgb(0,0,0,0.2);
    height: 100%;
  }
  

	.close-button {
		z-index: 1;
		> svg {
			fill: ${colors.primary.primary};
		}
		:hover {
			> svg {
				fill: #333;
			}
		}
	}     
`;


const LogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  width: 235px;
  margin-left: auto;
  margin-right: auto;
  ${below.phone`
    width: 130px;
  `}
  svg{
    height: 60px;
		width: 90px;
		fill: ${colors.primary.primary};
    ${below.phone`
      height: 60px;
    `}
  }
`;

const ButtonWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	button:nth-of-type(1){
		margin-right: 20px;
	}
`;

const getModalText = () => {
	return "By unlocking this league you will be able to manage league teams, "+
	"following any changes you will need to finalise the participants again "+
	"prior to the start of the draft.";
};


type Props = {
	league: TPLeague;
	toggleModal: () => void;
};


// eslint-disable-next-line max-len
const LeagueTeamsUnlockModal = ({ league, toggleModal }: Props) => {
	const innerText = getModalText();
	const dispatch = useDispatch();
	
	const handleUnlockLeague = () => {
		const scoreValues = league.custom_scoring_enabled ? league.custom_scoring : {};
		const skipCustomScoring = (league && league.draft_status !== "scheduled") 
			|| isEmpty(scoreValues);
		dispatch(updateDraftLeague({
			...league,
			id: league.id,
			teams_finalised: 0,
			scoreValues,
			skipCustomScoring
		}));
		toggleModal();
	};


	return(
		<ProModalWrapper>
			<ModalContainer
				onClick={toggleModal}
				container_background={colors.white}
			>
				<LogoWrapper>
					<LockoutOpen />
				</LogoWrapper>
				<ModalTitle>Unlock League</ModalTitle>
			
				<ModalText dangerouslySetInnerHTML={{ __html: innerText }} />
				<ButtonWrapper>
					<ConfirmButton onClick={handleUnlockLeague}>
						Unlock
					</ConfirmButton>
					<CancelButton onClick={toggleModal}>
						Cancel
					</CancelButton>
				</ButtonWrapper>
				
			</ModalContainer>
		</ProModalWrapper>
	);
	
};




export default LeagueTeamsUnlockModal;