// @flow
import styled from "styled-components";
import colors from "../../assets/css/colors";

export const TabItem = styled.li`
	font-size: 12px;
	color: #7F8A90;
	font-family: "SourceSansPro";
	font-weight: 600;
	border-top: 3px solid transparent;
	border-bottom: 3px solid transparent;
	height: 40px;
	cursor: pointer;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	text-align: center;
	flex-shrink: 0;
	&.active-tab {
		color: ${colors.primary.primary};
		cursor: default;
		font-weight: 600;
		border-bottom-color:${colors.primary.primary};
	}

	transition: border-bottom-color 0.2s linear;
	&.coach-tab {
		color: ${colors.coach};
		&.active-tab {
			color: ${colors.coach};
			font-weight: 700;
			border-bottom-color:${colors.coach};
		}
		&:hover {
			color: ${colors.coach};
			background-color:${colors.secondary.lightGrey};
			border-bottom-color:${colors.coach};
		}
	}

	&:hover {
		color: ${colors.primary.primary};
		background-color:${colors.secondary.lightGrey};
		border-bottom-color:${colors.primary.primary};
	}
`;

export default TabItem;