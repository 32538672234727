// @flow
import * as React from "react";
import styled from "styled-components";
import colors from "../../../../assets/css/colors";
import { FlexDiv, Tooltip } from "../../../../components";
import { below } from "../../../../assets/css/media";

const LeagueItemStyled = styled.div`
	display: flex;
	color: ${colors.primary.primary};
	margin: 0em 4em;
	justify-content: space-between;
	align-items: center;
	${below.tablet`
		margin: 0 20px;
	`};
	${below.phone`
		margin: 0 10px;
	`};
`;

const TooltipStyled = styled(Tooltip)`
	flex-basis: 3%;
`;

const LeagueItemData = styled.div`
	width: 100%;
	padding: 0;

	span {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
	}

	h4 {
		text-transform: capitalize;
		font-weight: normal;
		font-size: 14px;
		margin-top: 5px;
	}

	> p {
		color: ${colors.secondary.accentGrey};
		text-transform: capitalize;
		font-size: 13px;
		margin-top: 8px;
		margin-bottom: 20px;
	}
`;

type Props = {
	name: string,
	content: any,
	tooltip: {
		title: string,
		description: string,
	}
};

export const LeagueItem = ({ name, content, tooltip: { title, description } }: Props) => (
	<LeagueItemStyled>
		<LeagueItemData>
			<FlexDiv>
				<h4>{name}</h4>
				<TooltipStyled {...{
					title: title,
					description: description
				}} />
			</FlexDiv>
			<p>{content}</p>
		</LeagueItemData>
	</LeagueItemStyled>
);

export default LeagueItem;