import { createReducer } from "redux-act";
import * as _ from "lodash";
import {
	updateUserTeamName,
	updateUserTeamNameSuccess,
	updateUserTeamNameError, updateUserTeamNameReset,
} from "../../actions";
import { teamsDraft as teamDraftActions } from "../../actions";

export const initial_state = {
	pre_draft_list: [],
	is_pre_draft_list_received: false,
	lineups: {},
	user_team: {},
	is_team_saving: false,
	is_rfa_order_saving: false,
	waiver_requests: "",
	trades: {},
	user_all_teams: [],
	is_pre_draft_list_successfully_saved: undefined,
	by_id: {},
	offer_trades: [],
	error: null,
	team_name_request: "idle",
	commissioner_trade_request: "idle",
};

const onError = (state, payload) => ({
	...state,
	error: payload,
	is_team_saving: false,
	is_pre_draft_list_successfully_saved: undefined,
});

const clearError = state => ({
	...state,
	error: false,
});

const onSuccessUpdatePreDraftList = (state, payload) => ({
	...state,
	pre_draft_list: payload,
});

const replaceUserTeam = (state, lineup, id) => {
	const all_teams = state.user_all_teams;
	const index = _.findIndex(all_teams, { id });
	if (index !== -1) {
		return [
			..._.slice(all_teams, 0, index),
			{
				...all_teams[index],
				lineup,
			},
			..._.slice(all_teams, index + 1),
		];
	}
	return [...all_teams];
};

export const teamsDraft = createReducer({}, initial_state);

teamsDraft.on(teamDraftActions.fetchPreDraftList, state => ({
	...state,
	pre_draft_list: [],
	is_pre_draft_list_received: false,
}));

teamsDraft.on(teamDraftActions.fetchPreDraftSuccess, (state, payload) => ({
	...state,
	pre_draft_list: payload,
	is_pre_draft_list_received: true,
}));

teamsDraft.on(teamDraftActions.fetchPreDraftFailed, (state, payload) => ({
	...state,
	error: payload,
	is_team_saving: false,
	is_pre_draft_list_successfully_saved: undefined,
	is_pre_draft_list_received: false,
}));

teamsDraft.on(teamDraftActions.clearUpdatePreDraftListSuccessFlag, state => ({
	...state,
	is_pre_draft_list_successfully_saved: undefined,
}));

teamsDraft.on(teamDraftActions.postPreDraftListSuccess, (state, payload) => ({
	...state,
	pre_draft_list: payload,
	is_pre_draft_list_successfully_saved: true,
}));

teamsDraft.on(teamDraftActions.forceUpdatePreDraftList, onSuccessUpdatePreDraftList);

teamsDraft.on(teamDraftActions.postPickedPlayerSuccess, state => state);
teamsDraft.on(teamDraftActions.postSelectKeeperSuccess, state => state);
teamsDraft.on(teamDraftActions.removeKeeperSuccess, state => state);

teamsDraft.on(teamDraftActions.postAutoPickFlagSuccess, state => state);

teamsDraft.on(teamDraftActions.postPreDraftListFailed, onError);
teamsDraft.on(teamDraftActions.postSelectKeeperFailed, onError);
teamsDraft.on(teamDraftActions.removeKeeperFailed, onError);

teamsDraft.on(teamDraftActions.postPickedPlayerFailed, onError);

teamsDraft.on(teamDraftActions.postAutoPickFlagFailed, onError);

teamsDraft.on(teamDraftActions.postTeamUpdate, state => ({
	...state,
	is_team_saving: true,
}));
teamsDraft.on(teamDraftActions.postTeamUpdateFailed, onError);
teamsDraft.on(teamDraftActions.postTeamUpdateSuccess, (state, { lineup, id }: any) => ({
	...state,
	user_team: {
		...state.user_team,
		lineup,
	},
	by_id: {
		...state.by_id,
		[id]: {
			...state.by_id[id],
			lineup,
		},
	},
	user_all_teams: replaceUserTeam(state, lineup, id),
	is_team_saving: false,
}));

teamsDraft.on(teamDraftActions.fetchSnapshot, state => state);
teamsDraft.on(teamDraftActions.fetchSnapshotSuccess, (state, payload) => ({
	...state,
	snapshot: payload,
}));
teamsDraft.on(teamDraftActions.fetchSnapshotFailed, onError);

teamsDraft.on(teamDraftActions.fetchLineups, state => state);
teamsDraft.on(teamDraftActions.fetchLineupsSuccess, (state, payload) => ({
	...state,
	lineups: payload,
}));
teamsDraft.on(teamDraftActions.fetchLineupsFailed, onError);

teamsDraft.on(teamDraftActions.fetchTeam, state => state);
teamsDraft.on(teamDraftActions.fetchTeamSuccess, (state, payload: any) => ({
	...state,
	user_team: payload,
	by_id: {
		...state.by_id,
		[payload.id]: payload,
	},
}));
teamsDraft.on(teamDraftActions.fetchTeamFailed, onError);
teamsDraft.on(teamDraftActions.clearTeamErrors, clearError);

teamsDraft.on(teamDraftActions.postRequestWaiver, state => ({
	...state,
	is_team_saving: true,
}));
teamsDraft.on(teamDraftActions.postRequestWaiverSuccess, (state, payload) => ({
	...state,
	waiver_requests: payload,
	is_team_saving: false,
}));
teamsDraft.on(teamDraftActions.postRequestWaiverClear, state => ({
	...state,
	waiver_requests: "",
}));

teamsDraft.on(teamDraftActions.postRequestWaiverFailed, onError);

teamsDraft.on(teamDraftActions.fetchMakeTrade, state => state);
teamsDraft.on(teamDraftActions.fetchMakeTradeSuccess, (state, payload: any) => ({
	...state,
	trade_id: payload.trade_id,
}));
teamsDraft.on(teamDraftActions.fetchMakeTradeFailed, onError);

teamsDraft.on(teamDraftActions.fetchTrades, state => state);
teamsDraft.on(teamDraftActions.fetchTradesSuccess, (state, payload: any) => ({
	...state,
	trades: payload,
}));
teamsDraft.on(teamDraftActions.forceTradesSuccess, (state, payload: any) => ({
	...state,
	trades: payload,
}));
teamsDraft.on(teamDraftActions.fetchTradesFailed, onError);
teamsDraft.on(teamDraftActions.fetchApproveTradeFailed, onError);

teamsDraft.on(teamDraftActions.fetchMyTeams, state => state);
teamsDraft.on(teamDraftActions.fetchMyTeamsSuccess, (state, payload) => ({
	...state,
	user_all_teams: payload,
}));
teamsDraft.on(teamDraftActions.fetchMyTeamsFailed, onError);

teamsDraft.on(teamDraftActions.fetchRejectTradeFailed, onError);

teamsDraft.on(teamDraftActions.fetchRemovePlayer, state => ({
	...state,
	is_team_saving: true,
}));
teamsDraft.on(teamDraftActions.fetchRemovePlayerSuccess, (state, payload: any) => ({
	...state,
	is_team_saving: false,
	user_team: payload,
}));
teamsDraft.on(teamDraftActions.fetchRemovePlayerFailed, onError);
teamsDraft.on(teamDraftActions.postOrderWaiverTrade, state => ({
	...state,
	is_rfa_order_saving: true,
}));
teamsDraft.on(teamDraftActions.postOrderWaiverTradeSuccess, state => ({
	...state,
	is_rfa_order_saving: false,
}));
teamsDraft.on(teamDraftActions.postOrderWaiverTradeFailed, state => ({
	...state,
	is_rfa_order_saving: false,
}));
teamsDraft.on(teamDraftActions.clearTeamsDraftErrors, state => ({
	...state,
	error: null,
}));

teamsDraft.on(teamDraftActions.fetchPlayersOnOfferListSuccess, (state, payload) => ({
	...state,
	offer_trades: payload,
}));
teamsDraft.on(teamDraftActions.fetchPlayersOnOfferListFailed, onError);
teamsDraft.on(updateUserTeamName, state => ({
	...state,
	team_name_request: "loading",
}));
teamsDraft.on(updateUserTeamNameSuccess, state => ({
	...state,
	team_name_request: "success",
	error: null,
}));
teamsDraft.on(updateUserTeamNameError, (state, payload) => ({
	...state,
	team_name_request: "error",
	error: payload,
}));
teamsDraft.on(updateUserTeamNameReset, state => ({
	...state,
	team_name_request: "idle",
	error: null,
}));

teamsDraft.on(teamDraftActions.makeCommissionerTrade, state => ({
	...state,
	commissioner_trade_request: "loading",
	error: null,
}));

teamsDraft.on(teamDraftActions.makeCommissionerTradeSuccess, state => ({
	...state,
	commissioner_trade_request: "success",
	error: null,
}));

teamsDraft.on(teamDraftActions.makeCommissionerTradeFailed, (state, payload) => ({
	...state,
	commissioner_trade_request: "error",
	error: payload,
}));

teamsDraft.on(teamDraftActions.clearCommissionerTradeState, state => ({
	...state,
	commissioner_trade_request: "idle",
	error: null,
}));