// @flow
import React from "react";
import * as _ from "lodash";
import { ListViewPlayer } from "../../../components";
import { getPartialByeRound } from "../../../helpers";
import {
	TPlayersById,
	TRound
} from "../../../modules/types";
import TradePlayerEmpty from "./tradePlayerEmpty";



type Props = {
    players_by_id: TPlayersById,
    has_assistant_coach: boolean,
    is_mobile: boolean,
    openPopup: Function,
    initial_lineup: Object,
    actual_round: TRound,
    bye_detector_active: boolean,
    bye_detector_selected: number[],
    score_stat: string,
    params: Object,
    index: number,
    mobile_stat: string,
    substitute_id: number,
    substitute_position: number | string, 
	rounds: Array<Object>,
    substitute_is_utility: boolean,
    getLine: Function,
    getPrevisionId: Function,
    findTradeByPlayerId: Function,
    showTradeInPlayer: Function,
    removePlayerOnTrade: Function,
    substitutePlayer: Function,
    checkIsPlayerChangeAvailable: Function,
    inSubstitute: Function,
    is_empty_cell: boolean,
    setPlayerOnTrade: Function,
    cancelSubstitute: Function
}


export const ListViewPlayersByPosition = (props: Props) => {
	
	const {
		players_by_id,
		has_assistant_coach,
		is_mobile,
		openPopup,
		initial_lineup,
		actual_round,
		bye_detector_active,
		bye_detector_selected,
		score_stat,
		params,
		index,
		mobile_stat,
		substitute_id, 
		substitute_position, 
		substitute_is_utility,
		getLine,
		getPrevisionId,
		findTradeByPlayerId,
		showTradeInPlayer,
		removePlayerOnTrade,
		substitutePlayer,
		checkIsPlayerChangeAvailable,
		inSubstitute,
		is_empty_cell,
		setPlayerOnTrade,
		rounds,
		cancelSubstitute
	} = props;
	const {
		player_id,
		id,
		name,
		is_bench,
		captain,
		vice_captain,
		emergency,
		is_utility
	} = params;

	const is_subs = substitute_id !== 0;

	if (!player_id) {
		const line = getLine({ initial_lineup, is_bench, is_utility, id });

		const previous_id = getPrevisionId({
			is_utility,
			initial_lineup,
			line,
			index
		});

		const trade = findTradeByPlayerId(previous_id);

		const trade_id = trade ? trade.out : 0;
		if (trade && trade.in) {
			return showTradeInPlayer(
				{
					player_id: trade.in,
					id,
					name,
					is_bench,
					captain,
					vice_captain,
					emergency,
					index,
					trade_id,
					trade
				}
			);
		}

		return (
			<TradePlayerEmpty
				key={`empty-${index}`}
				position_id={id}
				previous_id={previous_id}
				position_full_name={name}
				position_index={index}
				is_bench={is_bench}
				removePlayerOnTrade={removePlayerOnTrade}
				substitutePlayer={substitutePlayer}
				trade_id={trade_id}
				is_substitute={is_subs}
				is_utility={is_utility}
			/>
		);
	}

	const player = players_by_id[player_id];
	const is_emergency = emergency.includes(player_id);
	const is_locked = !checkIsPlayerChangeAvailable(player_id);
	const isOutSubstitute = (is_subs, substitute_id, player_id) => {
		return is_subs && substitute_id === player_id;
	};
	const player_positions = _.get(player, "positions", []);
	const out_substitute = isOutSubstitute(is_subs, substitute_id, player_id);
	const in_position = substitute_is_utility ||
			_.includes(player_positions, substitute_position);
		// const out_position = substitute_player_positions.includes(id);
	const in_substitute = inSubstitute(is_subs, is_locked, in_position, true);
	const trade = findTradeByPlayerId(player_id);
	const is_in_swap = !_.isEmpty(trade);
	const trade_id = trade ? trade.out : 0;

	const player_bye = _.get(player, "stats.bye_round_id");
	const playerSquad = _.get(player, 'squad_id');
	const player_partial_bye = getPartialByeRound(playerSquad, rounds);


	return (
		<ListViewPlayer
			key={player_id}
			player={player}
			position_id={id}
			is_locked={is_locked}
			is_captain={captain === player_id}
			is_team_started={false}
			is_vice={vice_captain === player_id}
			is_bench={is_bench}
			removePlayer={setPlayerOnTrade}
			removePlayerOutTrade={removePlayerOnTrade}
			setCaptain={() => null}
			setViceCaptain={() => null}
			setEmergency={() => null}
			is_substitute={false}
			out_substitute={out_substitute}
			in_substitute={in_substitute}
			substitutePlayer={substitutePlayer}
			cancelSubstitute={cancelSubstitute}
			position_index={index}
			is_emergency={is_emergency}
			player_partial_bye={player_partial_bye}
			has_assistant_coach={has_assistant_coach}
			openPlayerPopUp={openPopup}
			is_mobile={is_mobile}
			is_trade_view
			is_trade_view_team
			is_in_swap={is_in_swap}
			trade_id={trade_id}
			is_empty_cell={is_empty_cell}
			selected_round={actual_round}
			bye_detector_active={bye_detector_active}
			bye_detector_selected={bye_detector_selected}
			player_bye={player_bye}
			no_padding
			mobile_stat={_.get(mobile_stat, "key")}
			player_stat_field={_.get(score_stat, "key")}
			is_utility={is_utility}
		/>
	);
	
};