// @flow
import * as React from "react";
import styled from "styled-components";

import { below } from "../../assets/css/media";

import TeamInfoBar from "./TeamInfoBar";
import TeamLineup from "./TeamLineup";

const TeamBreakdownContainer = styled.div``;

const TeamLineupStyled = styled(TeamLineup)`
	${below.tablet`
		display: none;
	`}
`;

type Props = {
	team: {
		id: number,
		user_id: number,
		avatar_version: number,
		name: string,
		firstname: string,
		lastname: string,
		lineup: Object
	},
	team_breakdown_players: Object,
	league_id: number,
	round_id: number,
	is_classic: boolean,
	is_user: boolean,
	showModalForPlayerId: Function,
	actual_round_id: number
}

const TeamBreakdown = ({ 
	team, 
	team_breakdown_players, 
	league_id, 
	round_id, 
	is_classic,
	is_user,
	showModalForPlayerId,
	actual_round_id
}: Props) => (
	<TeamBreakdownContainer>
		<TeamInfoBar 
			{...team} 
			league_id={league_id} 
			round_id={round_id} 
			is_classic={is_classic} 
			is_user={is_user}
		/>
		<TeamLineupStyled 
			lineup={team.lineup} 
			team_breakdown_players={team_breakdown_players} 
			is_classic={is_classic}
			showModalForPlayerId={showModalForPlayerId}
			actual_round_id={actual_round_id}
		/>
	</TeamBreakdownContainer>
);

TeamBreakdown.displayName = "TeamBreakdown";

export default TeamBreakdown;