// @flow

/* eslint max-len: 0 */
import React, {useState, useReducer, useEffect} from "react";
import {get, isEmpty} from "lodash";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment/moment";
import { useHistory } from "react-router-dom";
import { isAuthenticated } from "../../../utils/auth";
import {DobSelect, Exist, InputItem, Preloader, Select} from "../../../components";
import {Landing} from "../../../components/Landing";
import { IS_TERMS_CLOSED } from "../../../modules/constants";
import { dataLayer } from "../../../utils/GTM";
import {
	getAfliDIdentityToken,
	// getAfliDUser,
	getUser,
	getUserLoginError,
	getUserLoginPending
} from "../../../modules/selectors";
import { getSkillLevel, getStates,getGenderOptions } from "../../../helpers";
import { aflIDCreateUser } from "../../../modules/actions";
import {
	AFLiDModalContentWrapper,
	AFLiDButonWrap,
	AFLIDButtonDark,
	CheckBoxLabel,
	CheckBoxWrap,
	InputWrap,
	SelectWrap,
	ModalCheckbox,
	ModalContentNoPadMob,
	ModalInputWrap,
	ModalSpan,
	ModalWrap,
	PageTitleStyled,
	DatePickerWrap,
	DOBLabel,
	DOBErrorText,
	ErrorContent
} from "./../afliDModalStyles";
import { AFLiDModalContentCheckToken } from "./AFLiDModalContentCheckToken";
import { ToyatoTerms } from "./ToyotaTerms";




const SQUAD_OPTIONS = [
	"Adelaide Crows",
	"Brisbane Lions",
	"Carlton",
	"Collingwood",
	"Essendon",
	"Fremantle",
	"Geelong Cats",
	"Gold Coast Suns",
	"GWS Giants",
	"Hawthorn",
	"Melbourne",
	"North Melbourne",
	"Port Adelaide",
	"Richmond",
	"St Kilda",
	"Sydney Swans",
	"West Coast Eagles",
	"Western Bulldogs"
];

const initialFormState = {
	team_name:"",
	skill_level:"",
	state_of_residence:"",
	gender:"",
	supported_club:"",
	postcode:"",
	termscheckbox:false,
	toyota:false,
	dob_day:"",
	dob_month:"",
	dob_year:"",
};

function reducer(state, action) {
	switch (action.type) {
		case 'UPDATE':
			return {
				...state,
				[action.key]: action.value,
			};
		case 'TOGGLE':
			return {
				...state,
				[action.key]: !state[action.key],
			};
		default:
			return state;
	}
}

export const getDobError = (dobError:boolean,yearGTNow:boolean) => {
	if(dobError){
		return yearGTNow ? 
			"Please enter an earlier year.":
			"Please enter a valid date";
	}
	return "";
};

export const formatDobMonth = (n:string) => (Number(n) < 10) ? ("0" + n) : n;

function AfliDNewUserRego() {
	const [formState, formDispatch] = useReducer(reducer, initialFormState);
	const {
		team_name,
		skill_level,
		state_of_residence,
		gender,
		supported_club,
		termscheckbox,
		toyota,
		dob_day,
		dob_month,
		dob_year
	} = formState;
	const dispatch = useDispatch();
	// const afliDUser = useSelector(getAfliDUser);
	const user = useSelector(getUser);
	const userLoginError = useSelector(getUserLoginError);
	const userLoginPending = useSelector(getUserLoginPending);
	const userLoginErrorMsg = get(userLoginError,"message","");
	const userFirstName = get(user, 'firstname', "");
	const isRecovered = get(user, 'recovered', 0);
	const [dobError, toggleDobError] = useState(false);
	const [dobErrorText, setDobErrorText] = useState("");
	const isCountryAustralia = get(user, 'country', "") === "Australia";
	const userCountry = get(user, "country", "");
	const favourite_team = get(user, 'supported_club');
	const identity_token = useSelector(getAfliDIdentityToken);
	const hasFavTeam = !isEmpty(favourite_team);
	const history = useHistory();
	const isUserNowAuthenticated = !isEmpty(user) && isAuthenticated() && isRecovered;

	useEffect(() => {
		if(isUserNowAuthenticated){
			history.push("/coach-subscription");
		}
	}, [isUserNowAuthenticated, history]);

	const handleFormUpdate = event => {
		formDispatch({
			type: 'UPDATE',
			value: event.target.value,
			key: `${event.target.name}`,
		});								
	};

	const handleFormToggle = event => {
		formDispatch({
			type: 'TOGGLE',
			key: `${event.target.name}`,
		});								
	};
	const handleGoogleEvent = () => {

		const origin = localStorage.getItem('original-click') || "";
		if(origin === ""){
			return;
		}
		dataLayer({
			'event': `${origin}-user-created`
		});
		localStorage.removeItem('original-click');
	};

	const createYourTeam = event => {
		event.preventDefault();
		const yearGTNow = Number(dob_year) >= moment().year();
		const dobyyyymmdd = `${dob_day}-${formatDobMonth(dob_month)}-${dob_year}`;
		const momentDate = moment(dobyyyymmdd, 'DD-MM-YYYY', true);
		const isValidDate = momentDate.isValid();
		
		if(!isValidDate || yearGTNow) {
			toggleDobError(true);
			setDobErrorText(
				getDobError(dobError,yearGTNow)
			);
		}
		else {
			toggleDobError(false);
			setDobErrorText("");
			const payload = {
				team_name,
				terms: termscheckbox ? 1:0,
				toyota: toyota ? 1:0,
				skill_level,
				birthday: isValidDate ? momentDate._d.toISOString():null,
				state: state_of_residence,
				gender,
				supported_club: hasFavTeam ? favourite_team : supported_club,
				country: userCountry,
				afl_identity_token: identity_token,
			};

			dispatch(aflIDCreateUser(payload));
			handleGoogleEvent();
		}
	};
	
	return (
		<React.Fragment>
			<Landing>
				<AFLiDModalContentCheckToken>
					<AFLiDModalContentWrapper>
						<PageTitleStyled>
						Create Your Team Name
							<br />
						To Get Started
						</PageTitleStyled>
						<ModalWrap>
							<form onSubmit={createYourTeam}>
								<ModalContentNoPadMob>
									<ModalSpan>
									Welcome to AFL Fantasy <strong>{userFirstName}</strong>
									</ModalSpan>
									<ModalSpan>
									Please confirm a few final details to get started.
									</ModalSpan>
								</ModalContentNoPadMob>
								<ModalInputWrap>
									<InputWrap>
										<InputItem
											name="team_name"
											placeholder="Enter Your Team Name Here"
											type="text"
											required
											onChange={handleFormUpdate}
											value={team_name}
										/>
									</InputWrap>
									<SelectWrap>
										<Select
											name="skill_level"
											onChange={handleFormUpdate}
											value={skill_level}
											required={true}
										>
											{getSkillLevel.map(item => (
												<option key={item.value} value={item.value}>
													{item.text}
												</option>
											))}
										</Select>
									</SelectWrap>
									<Exist when={isCountryAustralia}>
										<SelectWrap>
											<Select
												name="state_of_residence"
												onChange={handleFormUpdate}
												value={state_of_residence}
												required={true}
											>
												{getStates.map(item => (
													<option key={item.value} value={item.value}>
														{item.text}
													</option>
												))}
											</Select>
										</SelectWrap>
									</Exist>
									<Exist when={!hasFavTeam}>
										<SelectWrap>
											<Select
												label='Which AFL club do you support?'
												name='supported_club'
												onChange={handleFormUpdate}
												required={true}
												value={supported_club}
											>
												<option value="">
													Please select your favourite team
												</option>
												{SQUAD_OPTIONS.map((squad, index) => (
													<option key={`${index}-squad`} value={squad}>
														{squad}
													</option>
												))}
											</Select>
										</SelectWrap>
									</Exist>
									
									<SelectWrap>
										<Select
											name="gender"
											onChange={handleFormUpdate}
											value={gender}
											required={true}
										>
											{getGenderOptions.map(item => (
												<option key={item.value} value={item.value}>
													{item.text}
												</option>
											))}
										</Select>
									</SelectWrap>
									<DatePickerWrap>
										<DOBLabel>D.O.B</DOBLabel>
										<DobSelect 
											handleChange={handleFormUpdate} 
											dob_day={dob_day}
											dob_month={dob_month}
											dob_year={dob_year}
										/>
										<DOBErrorText>{dobErrorText}</DOBErrorText>
									</DatePickerWrap>
									<Exist when={!IS_TERMS_CLOSED}>
										<CheckBoxWrap>
											<ModalCheckbox
												type="checkbox"
												id="termscheckbox"
												name="termscheckbox"
												value={termscheckbox}
												required
												onChange={handleFormToggle}
											/>
											<CheckBoxLabel htmlFor="termscheckbox">
												I have read and accepted {" "} <a
													href="https://www.afl.com.au/privacy"
													target='_blank'
													rel="noopener noreferrer">
												AFL's Privacy Policy*
												</a>, {" "}<a href={
													"http://www.telstra.com.au/"
												+ "privacy/privacy-statement/?ref=Net-Footer-Corp-Privacy"
												}
												target='_blank' rel="noopener noreferrer">
												Telstra's Privacy Policy</a> and the {" "} 
												<a href="/terms-conditions" target='_blank'>
												Terms & Conditions</a>. {" "}
												I also agree to receiving Tipping/Fantasy 
												emails about the competition.
											</CheckBoxLabel>
										</CheckBoxWrap>
									</Exist>
									
									<CheckBoxWrap>
										<ModalCheckbox
											type="checkbox"
											id="toyota"
											name="toyota"
											value={toyota}
											onChange={handleFormToggle}
										/>
										<CheckBoxLabel htmlFor="toyota">
											<ToyatoTerms />
										</CheckBoxLabel>
									</CheckBoxWrap>
									<AFLiDButonWrap>
										<AFLIDButtonDark>Play Now</AFLIDButtonDark>
									</AFLiDButonWrap>
								</ModalInputWrap>
							</form>
							<Exist when={userLoginErrorMsg !== ""}>
								<ErrorContent>{userLoginErrorMsg}</ErrorContent>
							</Exist>
						</ModalWrap>
					</AFLiDModalContentWrapper>
				</AFLiDModalContentCheckToken>
			</Landing>
			<Exist when={userLoginPending}>
				<Preloader />
			</Exist>
		</React.Fragment>
	);
}

export default AfliDNewUserRego;