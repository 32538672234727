// @flow
import * as React from "react";
import styled from "styled-components";

import { above } from "../../assets/css/media";

// import SquadLogo from '../SquadLogo';
import StatusButton from "../StatusButton";

import {getClubLogoIndigenous} from "../../utils/clubLogos";

const SquadLogo = styled.img`
	display: block;
	width: 100%;
`;

const SquadLogoWrapper = styled.div`
	width: 20px;
	flex: 0 0 20px;
	font-size: 10px;
	margin-right: 5px;
	margin-left: 2px;
`;

const StatusButtonWrapper = styled.div`
	flex: 0 0 12px;
	margin-right: 5px;
	margin-bottom: 3px;
`;

const Name = styled.div`
	font-size: 12px;
	font-weight: 500;
	flex: 1 2 auto;
	margin-right: 5px;
	cursor: pointer;

	:hover {
		text-decoration: underline;
	}
`;

const NameEllipsis = styled.div`
	text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
	max-width: 60px;

	${above.desktop`
		max-width: 110px;
	`}
`;

const Position = styled.div`
	font-size: 10px;
	font-weight: 500;
	text-transform: uppercase;
	margin-right: 5px;
`;

const PlayerTileContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: #F8F8FA;
	border-radius: 2px;
	margin: 2px 0;
`;

type Props = {
	squad_id: number,
	status: string,
	first_name: string,
	last_name: string,
	positions_name: Array<string>,
	showModalForPlayerId: Function,
	id: number,
	actual_round_id: number,
}

const PlayerTile = ({ 
	squad_id, 
	status, 
	first_name, 
	last_name, 
	positions_name, 
	showModalForPlayerId,
	id,
	actual_round_id
}: Props) => {
	const name = `${first_name.slice(0, 1)}. ${last_name}`;
	const logo = getClubLogoIndigenous(actual_round_id, squad_id);
	return (
		<PlayerTileContainer>
			<SquadLogoWrapper>
				{/* <SquadLogo squad_id={squad_id} /> */}
				<SquadLogo src={logo} alt="" />
			</SquadLogoWrapper>
			<StatusButtonWrapper>
				<StatusButton is_compressed status={status} />
			</StatusButtonWrapper>
			<Name onClick={() => showModalForPlayerId(id)}>
				<NameEllipsis>{name}</NameEllipsis>
			</Name>
			<Position>{Array.isArray(positions_name) && positions_name.join(" / ")}</Position>
		</PlayerTileContainer>
	);
};

PlayerTile.displayName = "PlayerTile";

export default PlayerTile;