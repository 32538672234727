// @flow
import * as React from "react";
import { Field, reduxForm } from "redux-form";
import styled from "styled-components";
import toyotaLogo from "../../assets/images/toyota-black-logo.png";
import { RegistrationValidate } from "../../utils/FormValidations";
import { below } from "../../assets/css/media";
import { ButtonPrimary, FormCheckbox, FormSelect } from "../../components";
import {TUser} from "../../modules/types";
import { getSkillLevel, registration_tooltips } from "../../helpers";

import colors from "../../assets/css/colors";

const RecoverFormStyled = styled.form`
	background: ${colors.form.base};
	padding: 3em;
	border: 1px solid ${colors.secondary.lightGrey};
	box-sizing: border-box;
	margin-bottom: 50px;
`;
const ToyotaOptInWrapper = styled.div`
  display: flex;
  position: relative;
  margin-bottom: 10px;
  p{
    font-family: SourceSansPro;
    font-size: 14px;
    color: ${colors.primary.primary};
    font-weight: 400;
    a{
      color: ${colors.primary.primary};
    }
  }
  img{
    width: 60px;
    height: 50px;
    position: absolute;
    right: 50px;
    ${below.tablet`
      right: -20px;
    `}
    ${below.phone`
      right: -40px;
    `}
  }
`;
const StyledOptInWrapper = styled(ToyotaOptInWrapper)`
	.tooltip-text{
		z-index: 3;
		p{
			color: white;
			font-size: unset;
		}
	}
`;

const ButtonPrimaryStyled = styled(ButtonPrimary)`
	height: 40px;
	width: 200px;
	font-size: 14px;
`;

const TitleForm = styled.h5`
	font-size: 18px;
	color: ${colors.primary.primary};
	margin-bottom: 10px;
`;

const Description = styled.div`
	max-width: 579px;
	line-height: 20px;
	color: ${colors.primary.darkGrey};
	font-family: "SourceSansPro";
	font-size: 14px;
	margin-bottom: 30px;
`;

type Props = {
	handleSubmit: Function,
	user: TUser
}

const RecoverFormComponent = ({
	handleSubmit,
	user
}: Props) => (
	<RecoverFormStyled onSubmit={handleSubmit}>
		<TitleForm>Welcome back{user.firstname && `, ${user.firstname}`}!</TitleForm>
		<Description>
			Registering for the new AFL Fantasy season is super easy, you're almost there!
			Simply update your registration below and you're good to go!
		</Description>
		<Field
			label='Please select your skill level*'
			name='skill_level'
			component={FormSelect}
			tooltip={registration_tooltips.skill_level}
			options={getSkillLevel}
		/>
		<ToyotaOptInWrapper>
			<Field
				label={
					<p>
            I'm interested in Toyota products, news and events, 
            and I agree to Toyota Motor Corporation Australia Pty 
            Limited using my personal information to send me marketing 
            material in accordance with its Privacy Policy available at: <a href={
							"https://www.toyota.com.au/privacy-policy"
						}
						target='_blank' rel="noopener noreferrer">
            www.toyota.com.au/privacy-policy</a>
					</p>}
				type="checkbox"
				name='toyota'
				component={FormCheckbox}
				parse={value => value ? 1 : 0}
				className="toyota-field"
				 />
			<img src={toyotaLogo} alt="Toyota Logo"/>
		</ToyotaOptInWrapper>
		<StyledOptInWrapper>
			<Field
				label={
					<p>
						I would like to receive communications 
						and special offers from the AFL, its 
						sponsors and/or my selected team of support.
					</p>}
				name='news'
				type="checkbox"
				component={FormCheckbox}
				parse={value => value ? 1 : 0}
			/>
		</StyledOptInWrapper>
		<StyledOptInWrapper>
			<Field
				label={
					<p>
					I have read an accepted {" "} <a
							href="https://www.afl.com.au/privacy"
							target='_blank'
							rel="noopener noreferrer">
              AFL's Privacy Policy*
						</a>,{" "} <a href={
							"http://www.telstra.com.au/"
              + "privacy/privacy-statement/?ref=Net-Footer-Corp-Privacy"
						}
						target='_blank' rel="noopener noreferrer">
            Telstra's Privacy Policy</a> and the {" "}
						<a href="/terms-conditions" target='_blank'>
              Terms & Conditions</a>. I also agree to receiving Tipping/Fantasy
							emails about the competition.
					</p>}
				name='privacy_policy'
				type="checkbox"
				component={FormCheckbox}
				tooltip={registration_tooltips.privacy_policy}
				required={true}
				parse={value => value ? 1 : 0}
			/>
		</StyledOptInWrapper>
		<br/>
		<br/>
		<ButtonPrimaryStyled>
			Register your account
		</ButtonPrimaryStyled>
	</RecoverFormStyled>
);
export const RecoverForm = reduxForm({
	form: "recover_form",
	validate: RegistrationValidate,
	initialValues: {
		privacy_policy: 0,
		skill_level: getSkillLevel[0].value,
	},
})(RecoverFormComponent);

export default RecoverForm;