// @flow
import { createReducer } from "redux-act";
import * as actions from "../../actions";
import { arrayToObjectById } from "./../../../helpers";

export const initial_state = {
	is_pending: false,
	error: null,
	by_id: {},
	ordered_ids: [],
};

export const squads = createReducer({}, initial_state);

squads.on(actions.fetchSquads, state => ({
	...state,
	is_pending: true,
	error: null,
}));
squads.on(actions.fetchSquadsFailed, (state, payload) => ({
	...state,
	is_pending: false,
	error: payload,
}));

squads.on(actions.fetchSquadsSuccess, (state, payload: any) => {
	// build by id
	const by_id = arrayToObjectById(payload, state.by_id);

	return {
		...state,
		is_pending: false,
		error: null,
		by_id,
		ordered_ids: payload.map(item => item.id),
	};
});

export default squads;