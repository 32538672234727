import styled from "styled-components";
import colors from "../../assets/css/colors";
import { below } from "../../assets/css/media";
import TableUnstyled, { Td, Th as ThUnstyled, THead, Tr } from "../TableX";
import {
	ThStat as ThStatUnstyled,
} from "../";

export const TableHead = styled(THead)`
    .th-owner, .th-next, .th-stat-cell{
        background: linear-gradient(180deg,rgba(0,0,0,0.05) 0%,rgba(0,0,0,0) 100%);
    }
    .table-header-row{
    border-bottom: 2px solid #cad2d8;
        th
    .absolute-group{
    position: absolute;
    min-width: 380px;
    display: flex;
    flex-direction: row;
    z-index: 98;
    border-bottom: 0;
    th{
        border-bottom: none;
    }
    }
    .absolute-group-left{
    position: sticky;
    left: 0;
    min-width: 220px;
    background-color: white !important;
    display: flex;
    flex-direction: row;
    z-index: 96;
    border-bottom: 2px solid #cad2d8;;
    th{
        border-bottom: 2px solid #cad2d8;;
    }
    ${below.phone`
        min-width: 100px;
    `}
    }
    .absolute-group-right{
    position: sticky;
    right: 0;
    background-color: white !important;
    display: flex;
    flex-direction: row;
    z-index: 96;
    border-bottom: 2px solid #cad2d8;;
    th{
        border-bottom: 2px solid #cad2d8;
    }
    ${below.phone`
        min-width: 40px;
        width: 40px;
                
    `}
    }
    }
`;
export const TdStat = styled.td`
    text-align: ${props => props.textAlign ? props.textAlign : "center"};
    vertical-align: middle;
    padding: ${({ pre_draft }) => pre_draft ? "0px" : "0px 5px"};
    font-size: 12px;
    color: ${colors.primary.darkGrey};
    font-family: SourceSansPro;
    font-weight: 600;
    border-bottom: 1px solid ${colors.secondary.paleGrey};
    tr.is_owned_by_user & {
        background: ${colors.secondary.ownerGrey} !important;
    }
    ${({ is_fc }) => is_fc && `color: ${colors.coach};`}
    height: 60px;
    position: relative;
    width: 50px;

    ${below.tablet`
        background: linear-gradient(180deg, rgba(0,0,0,0.05) 0%, rgba(0,0,0,0) 100%) !important;
    `}
`;
export const Th = styled(ThUnstyled)`
`;
export const ThStat = styled(ThStatUnstyled)`
    width: 50px;
`;

export const StyledTr = styled(Tr)`
    position: relative;
    z-index: 1;
    box-shadow: 0 0 0px rgba(0, 0, 0, 0);
    &.is_owned_by_user {
        background: ${colors.secondary.ownerGrey} !important;
    }

    transition: transform 0.2s linear,
        box-shadow 0.2s linear;

    > td {
        transition: background-color 0.2s linear,
            border-radius 0.2s linear;
    min-width: 50px;
    }
    td.absolute-group{
    position: sticky;
    left: 0;
    min-width: 230px;
        z-index: 1;
        background-color: inherit;
    ${below.phone`
    min-width: 50px;
    width: 50px
    `}
    }
    th.absolute-group{
    background-color: inherit;
    }
    td.absolute-group-right{
    position: sticky;
    right: 0;
        background: ${({ isWhite }) => isWhite ? "white" : "#f8f8fa"};
        
        

        z-index: 96;
    ${below.phone`
        min-width: 30px;
        width: 30px;
    `}
        .buttons-open-mob{
            ${below.tablet`
                width: calc(100% + 50px);
                position: absolute;
                display: flex;
                align-items: center;
                height: 100%;
                justify-content: space-evenly;
                top: 0;
                right: 0px;
                box-shadow: -5px 3px 25px -10px #333;
                background: inherit;
            `}
        }
        .two-buttons-open-mob{
            ${below.tablet`
                width: calc(100% + 100px);
                position: absolute;
                display: flex;
                align-items: center;
                height: 100%;
                justify-content: space-evenly;
                top: 0;
                right: 0px;
                box-shadow: -5px 3px 25p -10px #333;
                background: inherit;
            `}
        }
    }
    .player-info{
    box-shadow: ${({ is_scrollable }) => 
		is_scrollable ? "rgb(202 210 216) 15px 0px 15px -5px" : 0 }; 
    ${below.desktop`
    
    `}
    }
    .shadow-left {
    box-shadow: ${({ is_scrollable }) => 
		is_scrollable ? "rgb(202 210 216) -10px 0px 20px 0px" : 0}; 
    ${below.desktop`
    box-shadow: rgb(202 210 216) -10px 0px 20px 0px;
    `}
    }
    ${below.tablet`
        .td-draft-owner, .td-next{
            background: linear-gradient(180deg, rgba(0,0,0,0.05) 0%, rgba(0,0,0,0) 100%) !important;
        }
    `}
`;

export const TdYourTeam = styled(Td)`
    padding: 0;
    padding-right: 5px;
    vertical-align: bottom;
    height: 60px;
    &:first-child {
        padding-left: 0;
    }
`;

export const TableWrapper = styled.div`
    width: 100%;
    overflow: auto hidden;
    position: relative;
    tr{
        background-color: white;
    }
    tr:nth-child(even){
        background-color: #f8f8fa;
    }
`;

export const Table = styled(TableUnstyled)`
    border-collapse: separate;
    ${below.tablet`
        & td,
        & th {
            display: table-cell;
        }
    `};
`;
export const Spacer = styled.div`
    height: 40px;
`;

export const FcLink = styled.a`
    color: ${colors.coach};
    cursor: pointer;
`;