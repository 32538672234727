/* eslint max-len: 0 */
// @flow
import React from "react";
import SvgIconContainer from "../SvgIconContainer";

export default (
	{
		unit = "em",
		...rest
	}: Object = {}) => (
	<SvgIconContainer viewBox="0 0 24 24" {...rest} unit={unit}>
		<title>Drag</title>
		<path d="m4.6 14h14.8c.3313708 0 .6.2686292.6.6s-.2686292.6-.6.6h-14.8c-.33137085 0-.6-.2686292-.6-.6s.26862915-.6.6-.6zm0-3h14.8c.3313708 0 .6.2686292.6.6s-.2686292.6-.6.6h-14.8c-.33137085 0-.6-.2686292-.6-.6s.26862915-.6.6-.6zm0-3h14.8c.3313708 0 .6.26862915.6.6s-.2686292.6-.6.6h-14.8c-.33137085 0-.6-.26862915-.6-.6s.26862915-.6.6-.6z"  />
	</SvgIconContainer>
);