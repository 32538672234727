// @flow
import * as React from "react";

import { Field } from "redux-form";
import { FormSelect, FormRadio } from "../../../../../components/index";
import { league_tooltips } from "../../../../../helpers/index";
import type { TRound } from "../../../../../modules/types";


type Props = {
	is_traders: boolean,
	handleProOpen: Function,
	is_pro_sub: boolean,
	initialValues: Object,
  from: string,
  actual_round: TRound,
};

export const TradersSettings = ({ is_traders, 
	handleProOpen, is_pro_sub, initialValues, from, actual_round }: Props) => {
	const is_create = from === "create";
	const is_actual_round_active = is_create ? false : actual_round.status === "playing";
	return (
		<div>
			<FormRadio
				label='Restricted free agents'
				name='trade_waivers'
				elements={[
					{
						value: 1,
						text: "Yes",
					},
					{
						value: 0,
						text: "No",
					}
				]}
				tooltip={league_tooltips.trade_waivers}
				is_disabled={is_actual_round_active}
			/>

			<Field
				label='Restricted free agent time period'
				name='waiver_period'
				component={FormSelect}
				options={[
					{
						value: 1,
						text: "1 day",
					},
					{
						value: 2,
						text: "2 days",
					}
				]}
				tooltip={league_tooltips.waiver_period}
				is_disabled={!is_traders || is_actual_round_active}
			/>

			<FormRadio
				label='Restricted free agent order policy'
				name='waiver_order'
				elements={[
					{
						value: 0,
						text: "Reverse ladder",
					},
					{
						value: 1,
						text: "Last pick to bottom",
					}
				]}
				tooltip={league_tooltips.waiver_order}
				is_disabled={!is_traders || is_actual_round_active}
			/>

			<FormRadio
				label='Restricted free agent privacy'
				name='rfa_privacy'
				elements={[
					{
						value: "public",
						text: "Public",
					},
					{
						value: "private",
						text: "Private",
					}
				]}
				tooltip={league_tooltips.rfa_privacy}
				is_disabled={!is_traders || is_actual_round_active}
				is_string_value={true}
			/>

			<Field
				label='Trade Approval Options'
				name='approve_trade'
				component={FormSelect}
				options={[
					{
						value: "league",
						text: "League approved",
					},
					{
						value: "commissioner",
						text: "Commissioner approved",
					},
					{
						value: "free",
						text: "Free for all ",
					}
				]}
				tooltip={league_tooltips.approve_trade}
			/>

		</div>
	);
};
	

export default TradersSettings;