// @flow
import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import _ from "lodash";
import { compose } from "redux";
import type { 
	TClassicTeam, 
	TPosition, 
	TRound, 
	TUser, 
	TPlayersArrayWithFixtureById 
} from "../../modules/types";
import * as actions from "../../modules/actions";
import * as selectors from "../../modules/selectors";
import { shouldDoubleScore } from "../../helpers/stats/displayRound";
import withRoundsSelector from "../../components/utils/withRoundsSelector";

import {
	Ad,
	AdsContainer,
	Footer,
	MatchCentreBar,
	Preloader,
	TwoColumnLayout,
	withClassicPlayerModal,
	ListViewHeader,
	ListRowLabel,
	ListViewPlayer,
	ListBenchPlayers,
	ListViewPlayerEmpty,
	ListViewField,
	ListRow,
	TeamGameBar,
	StandardSidebar,
	RoundSelector,
	PageContentWrapper
} from "../../components";
import JsonFetcher from "../../components/utils/JsonFetcher";
import withFantasyCoachModal from "../../components/utils/withFantasyCoachModal";
import { isMobile } from "../../utils";
import { getRemainingSalary } from "../../helpers";
import { getLeagueRounds } from "../../helpers/league";
import TeamTitle from "../../components/TeamTitle";
import FieldPlayer from "../../components/FieldPlayer";
import EmptyPlayer from "../../components/FieldPlayer/empty";
import {
	FieldRowLabel,
	FieldPlayers,
	FieldRow,
	Field,
	BenchLabel,
	BenchPlayers,
	FieldBackground
} from "../../components/Field";
import { TeamTopControls } from "./myTeam/teamTopControls";

const MyTeamMobileWrapper = styled.div`
	display: none;
	${({ is_open }) => is_open && `
		display: block;
	`}
`;

type Props = {
	team: TClassicTeam,
	fetchTeam: Function,
	fetchFavourites: typeof actions.fetchFavourites,
	positions: Array<TPosition>,
	players_by_id: TPlayersArrayWithFixtureById,
	has_assistant_coach: boolean,
	actual_round: TRound,
	player_ids: Array<number>,
	selected_round: TRound,
	selected_round_id: number,
	setSelectedRoundId: Function,
	rounds_ordered: Array<TRound>,
	showModalForPlayerId : Function,
	isTeamStarted: boolean,
	data_loading_complete: boolean,
	is_pending_players: boolean,
	remaining_salary: number,
	trades: Array<Object>,
	toggleFantasyCoachModal: Function,
	rounds_with_matches: Array<TRound>,
	user: TUser,
	team_id: number,
	is_current: boolean,
	is_utility: boolean
}

type State = {
	is_mobile: boolean,
	preloader: boolean
}
class UserTeamComponent extends React.Component<Props, State> {

	static defaultProps = {
	};

	constructor(props, state) {
		super(props, state);

		_.bindAll(this, [
			"getFieldRow",
			"getRowPlayers",
			"getListFieldRow",
			"getListViewPlayersByPosition",
			"getFieldPlayer",
			"findLeader",
			"resize",
			"openPopup",
			"selectRound"
		]);
	}

	state = {
		is_mobile: false,
		preloader: true,
	};

	componentDidMount(): void {
		const { fetchFavourites } = this.props;
		fetchFavourites();
		this.fetchTeam();
		window.addEventListener("resize", this.resize);
		this.resize();
		if (this.props.data_loading_complete) {
			this.setState({ preloader: false });
		}
	}

	shouldComponentUpdate(next_props) {
		return next_props.data_loading_complete;
	}

	componentDidUpdate(prev_props) {
		if(!prev_props.data_loading_complete && this.props.data_loading_complete) {
			this.setState({ preloader: false });
		}
		if (prev_props.selected_round_id !== this.props.selected_round_id) {
			this.fetchTeam();
		}
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.resize);
	}

	fetchTeam() {
		const { team_id, selected_round_id, fetchTeam } = this.props;

		fetchTeam({
			id: team_id,
			round: selected_round_id
		});
	}

	resize() {
		this.setState({
			is_mobile: isMobile()
		});
	}
	//Blocks
	get team_field() {
		const { positions } = this.props;
		return positions.map(this.getFieldRow);
	}

	get list_view_field(){
		const { positions } = this.props;
		return positions.map(this.getListFieldRow);

	}

	getListFieldRow({ id, full_name, name }) {
		const
			{ team: { lineup = {} } } = this.props,
			{ bench = {}, captain, vice_captain  } = lineup,
			{ emergency = [] } = bench;

		if (_.isEmpty(lineup)) {
			return null;
		}

		const position_line = _.get(lineup, id, []);
		const position_bench = _.get(bench, id, []);

		return (
			<ListRow key={id}>
				<ListRowLabel>
					{full_name}
				</ListRowLabel>
				{_.map(position_line, (player_id, index) => this.getListViewPlayersByPosition(
					{ player_id, id, name, is_bench: false, captain, vice_captain, emergency },
					index
				))}
				<ListBenchPlayers>
					{_.map(position_bench, (player_id, index) => this.getListViewPlayersByPosition(
						{
							player_id,
							id,
							name,
							is_bench: true,
							captain,
							vice_captain,
							emergency
						},
						index
					))}
				</ListBenchPlayers>
			</ListRow>
		);
	}

	getListViewPlayersByPosition(params, index) {
		const {
			player_id,
			id,
			name,
			is_bench,
			captain,
			vice_captain,
			emergency
		} = params;

		const {
			players_by_id,
			has_assistant_coach,
			team: { lineup = {} },
			selected_round,
			is_current
		} = this.props;

		const { is_mobile } = this.state;

		if (!player_id || _.isEmpty(players_by_id)) {
			return (
				<ListViewPlayerEmpty
					key={`empty-${index}`}
					position_id={id}
					position_full_name={name}
					is_utility={false}
				/>
			);
		}

		const player = players_by_id[player_id];
		const is_emergency = emergency.includes(player_id);
		const double_score = shouldDoubleScore(
			player,
			lineup,
			selected_round,
			players_by_id[captain]
		);
		return (
			<ListViewPlayer
				key={player_id}
				player={player}
				position_id={id}
				is_locked={true}
				is_captain={captain === player_id}
				is_team_started={true}
				is_vice={vice_captain === player_id}
				is_bench={is_bench}
				removePlayer={() => null}
				setCaptain={() => null}
				setViceCaptain={() => null}
				setEmergency={() => null}
				is_substitute={false}
				out_substitute={false}
				in_substitute={false}
				is_utility={false}
				substitutePlayer={() => null}
				cancelSubstitute={() => null}
				is_emergency={is_emergency}
				has_assistant_coach={has_assistant_coach}
				openPlayerPopUp={this.openPopup}
				is_mobile={is_mobile}
				player_stat_field={this.game_bar_stats_value}
				double_score={double_score}
				selected_round={selected_round}
				is_static
				is_current={is_current}
			/>
		);
	}

	openPopup(player_id) {
		const { showModalForPlayerId } = this.props;
		showModalForPlayerId(player_id);
	}

	getFieldRow({ id, full_name, name }) {
		const { team: { lineup = {} } } = this.props;
		const { bench } = lineup;

		if (_.isEmpty(lineup)) {
			return null;
		}

		const position_line = _.get(lineup, id, []);
		const position_bench = _.get(bench, id, []);

		return (
			<FieldRow key={id}>
				<FieldRowLabel>
					<div>
						{full_name}
					</div>
				</FieldRowLabel>
				<FieldPlayers
					widthProp={id === 2 ? 77 : 75}
					paddingProp={(id !== 2) ? 6: 0}
					mids={id === 2}
				>
					{_.map(position_line, (player_id, index) => this.getRowPlayers(
						{ player_id, id, name, is_bench: false, index }
					))}
				</FieldPlayers>
				<BenchPlayers>
					{_.map(position_bench, (player_id, index) => this.getRowPlayers(
						{ player_id, id, name, is_bench: true, index }
					))}
				</BenchPlayers>
			</FieldRow>
		);
	}

	getRowPlayers({ player_id, id, name, is_bench, index }) {
		const { players_by_id } = this.props;

		return player_id && !_.isEmpty(players_by_id) ? (
			this.getFieldPlayer({ player_id, id, is_bench })
		) : (
			<EmptyPlayer
				substitutePlayer={() =>  null}
				key={index}
				pos_name={name}
				pos_id={id}
				is_bench={is_bench}
				index={index}
				onClick={() => null}
				in_substitute={false}
			/>
		);
	}

	getFieldPlayer({ player_id, id, is_bench }) {
		const {
			players_by_id, team: { lineup = {} },
			showModalForPlayerId,
			selected_round,
			has_assistant_coach,
			toggleFantasyCoachModal,
			is_current,
			actual_round
		} = this.props;

		const player = players_by_id[player_id];

		const { captain, vice_captain, bench: { emergency = [] } } = lineup;
		const is_emergency = emergency.includes(player_id);
		const double_score = shouldDoubleScore(
			player,
			lineup,
			selected_round,
			players_by_id[captain]
		);

		return (
			<FieldPlayer
				is_locked={true}
				fantasyCoachModal={toggleFantasyCoachModal}
				key={player_id}
				id={player_id}
				flip={false}
				player={player}
				is_bench={is_bench}
				captain={captain}
				vice_captain={vice_captain}
				is_emergency={is_emergency}
				position={id}
				removePlayer={() => null}
				setCaptain={() => null}
				setViceCaptain={() => null}
				setEmergency={() => null}
				substitutePlayer={() => null}
				out_substitute={false}
				in_substitute={false}
				is_substitute={false}
				player_points={this.game_bar_stats_value}
				is_team_started={true}
				showModalForPlayerId={showModalForPlayerId}
				selected_round={selected_round}
				has_assistant_coach={has_assistant_coach}
				double_score={double_score}
				is_static
				actual_round={actual_round}
				is_current={is_current}
			/>
		);
	}

	get game_bar_stats_value() {
		return "stats.round_score";
	}

	findLeader(id) {
		const { team: { lineup: { captain, vice_captain } } } = this.props;
		return _.findKey({ captain, vice_captain }, player_id => player_id === id);
	}

	get field() {
		const { player_ids } = this.props;
		const { is_mobile } = this.state;

		if(is_mobile) {
			return (
				<ListViewField>
					<ListViewHeader player_ids={player_ids} />
					{ this.list_view_field }
				</ListViewField>
			);
		}

		return (
			<Field
				is_substitute={false}
				is_locked={true}
			>
				<FieldBackground is_locked={true} />
				{this.team_field}
				<BenchLabel>
					<div>INTERCHANGE</div>
				</BenchLabel>
			</Field>
		);
	}

	selectRound(round_id: number) {
		const { setSelectedRoundId } = this.props;
		setSelectedRoundId(round_id);
	}

	get round_selector() {
		const { selected_round_id, rounds_ordered } = this.props;
		return <RoundSelector
			selected={selected_round_id}
			rounds={rounds_ordered}
			handleClick={this.selectRound}
		/>;
	}

	get main_content() {
		const {
			players_by_id, team,
			rounds_with_matches,
			selected_round,
			has_assistant_coach,
			remaining_salary,
			data_loading_complete
		} = this.props;
		const {
			is_mobile,
		 } = this.state;

		const selected_round_id = _.get(selected_round, "id", 0);
		const selected_round_status = _.get(selected_round, "status", "complete");

		const team_top_controls = <TeamTopControls
			onSwitchPlayerPool={() => null}
			handleViewType={() => null}
			handleFlip={() => null}
			is_view_type_list={false}
			is_mobile={is_mobile}
			team={team}
			is_users_team={false}
			is_team_started={true}
			is_static
			is_classic={true}
		/>;

		const team_game_bar = <TeamGameBar
			player_points={this.game_bar_stats_value}
			is_type_field_list={false}
			is_old_scores={true}
			selected_round_id={selected_round_id}
			is_mobile={is_mobile}
			players_by_id={players_by_id}
			remaining_salary={remaining_salary}
			team={team}
			is_team_started={true}
			has_assistant_coach={has_assistant_coach}
			data_loading_complete={data_loading_complete}
			rounds_with_matches={rounds_with_matches}
			show_last_round_score={false}
			is_current_round={selected_round_status !== "complete"}
			is_static
		/>;

		if(is_mobile) {
			return (
				<React.Fragment>
					<MyTeamMobileWrapper is_open>
						{ team_top_controls }
						{ team_game_bar }
						{ this.field }
					</MyTeamMobileWrapper>
				</React.Fragment>
			);
		}

		return (
			<React.Fragment>
				<div>
					{ team_top_controls }
					{ team_game_bar }
					{ this.field }
				</div>
			</React.Fragment>
		);
	}

	render() {
		const { team } = this.props;
		return (
			<React.Fragment>
				<JsonFetcher
					fetch={["rounds", "players", "venues", "squads", "coach_players"]}
					interval={90000} /* Fetch new data once every 90s */
				/>
				{this.state.preloader || !_.size(team) ? (<Preloader />): (
					<React.Fragment>
						<AdsContainer>
							<Ad />
						</AdsContainer>
						<MatchCentreBar />
						<PageContentWrapper>
							<TeamTitle team={team} isTeamStartRoundComplete={false} />
							{ this.round_selector }
							<TwoColumnLayout>
								{ this.main_content }
								<StandardSidebar />
							</TwoColumnLayout>
						</PageContentWrapper>
						<Footer show_key />
					</React.Fragment>
				)}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state, props) => {
	const actual_round = selectors.rounds.getActualRound(state);

	const players_by_id = selectors.players.getExtendedPlayersById(state);
	const team = state.teamsClassic.show.team;
	
	const rounds_with_matches = selectors.rounds.getRoundsWithMatches(state);
	const selected_round = _.find(rounds_with_matches, { id: props.selected_round_id });
	const teamStartRound = _.find(rounds_with_matches,{id: team.start_round} );
	const isTeamStarted = _.get(teamStartRound, 'status') !== "scheduled";
	if(!isTeamStarted){
		team.lineup = {};
	}

	return {
		user: selectors.getUser(state),
		actual_round,
		players_by_id,
		team,
		positions: selectors.positions.getPositionsArray(state),
		has_assistant_coach: Boolean(selectors.getUser(state).assistant_coach),
		is_pending_players: state.players.is_pending,
		last_round: selectors.rounds.getLastRound(state),
		player_ids: selectors.getMyClassicTeamPlayersIds(state),
		rounds_with_matches,
		isTeamStarted,
		remaining_salary: getRemainingSalary(team),
		data_loading_complete: _.every([true,
			!state.players.is_pending,
			!state.rounds.is_pending,
			!state.squads.is_pending,
			!state.venues.is_pending,
			(Boolean(selectors.getUser(state).assistant_coach))?
				!_.isEmpty(state.players.coach_by_id): true
		]),
		trades: selectors.getTrades(state),
		team_id: props.match.params.team_id,
		selected_round,
		// Disable future rounds in round selector
		rounds_ordered: getLeagueRounds(props.rounds_ordered),
		is_current: props.selected_round_id >= _.get(actual_round, "id", 0)
	};
};
const mapDispatchToProps = {
	fetchTeam: actions.fetchClassicTeam,
	fetchFavourites: actions.fetchFavourites,
	showModalForPlayerId: actions.showModalForPlayerId,
};

export const UserTeam = compose(
	withRoundsSelector,
	withClassicPlayerModal,
	withFantasyCoachModal,
	connect(
		mapStateToProps,
		mapDispatchToProps,
	)
)(UserTeamComponent);


export default UserTeam;