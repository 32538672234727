// @flow
import * as React from "react";

import { Field } from "redux-form";
import { FormRadio, FormSelect } from "../../../../../components";

import { getLeagueFinalFormat, league_tooltips } from "../../../../../helpers";

type Props = {
	is_finals: boolean,
	league_size: number,
};

export const AdvancedLeagueSettings = ({ is_finals, league_size }: Props) => (
	<div>
		<FormRadio
			label='League finals series'
			name='finals'
			elements={[
				{
					value: 1,
					text: "Yes",
				},
				{
					value: 0,
					text: "No",
				}
			]}
			tooltip={league_tooltips.finals}
			is_disabled={false}
		/>

		<Field
			label='League finals format'
			name='finals_format'
			options={getLeagueFinalFormat({ league_size }).filter(set => set.value !== 5)}
			component={FormSelect}
			tooltip={league_tooltips.finals_format}
			is_disabled={!is_finals}
		/>

		<Field
			label='Play opponents'
			name='play_times'
			options={[
				{
					value: "flexible",
					text: "Flexible",
				},
				{
					value: "once",
					text: "Once",
				},
				{
					value: "twice",
					text: "Twice",
				},
			]}
			component={FormSelect}
			tooltip={league_tooltips.play_times}
		/>

		<FormRadio
			label='Bye rounds'
			name='bye_rounds'
			elements={[
				{
					value: 1,
					text: "On",
				},
				{
					value: 0,
					text: "Off",
				}
			]}
			tooltip={league_tooltips.bye_rounds}
			is_disabled={false}
		/>

		<FormRadio
			label='Ladder Tiebreaker'
			name='rank_by'
			is_string_value={true}
			elements={[
				{
					value: "percentage",
					text: "Percentage",
				},
				{
					value: "points_for",
					text: "Points For",
				}
			]}
			tooltip={league_tooltips.ladder_tiebreaker}
			is_disabled={false}
		/>
	</div>
);

export default AdvancedLeagueSettings;