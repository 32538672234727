import * as _ from "lodash";

export const basic_stats = round_id => [
	{ key: "GP", prop: "stats.games_played", tooltip: "Games Played" },
	{ key: "AVG", prop: "stats.avg_points", tooltip: "Average Points" },
	{ key: "LAST RD", prop: `stats.scores.${round_id}`, tooltip: "Points Last Round" },
	{ key: "L3 AVG", prop: "stats.last_3_avg", tooltip: "Last 3 Average" },
	{ key: "TP", prop: "stats.total_points", tooltip: "Total Points" },
	{ key: "Price", prop: "cost", tooltip: "Price" },
	{ key: "R$C", prop: "stats.cost_round_diff", tooltip: "Round Price Change" },
	{ key: "S$C", prop: "stats.cost_season_diff", tooltip: "Season Price Change" },
	{ key: "$/PT", prop: "stats.cost_divided_by_points", tooltip: "$ per Point" },
	{ key: "PROJ", prop: "stats.proj_score", tooltip: "Projected Score", is_fc: true },
	{ key: "BE", prop: "stats.break_even", tooltip: "Break Even", is_fc: true },
	{ key: "BYE", prop: "stats.bye_round_id", tooltip: "Bye Round", is_fc: true },
];

export type TableColumn = {
	key: string, name?: string, prop: string, tooltip?: string, is_fc?: boolean, format?: string
};


export const tryConvertNumber = value => {
	try {
		return Number.parseFloat(value);
	}
	catch (e) {
		return value;
	}
};

export const ZERO_ALLOWED_STATS = [
	"stats.break_even",
];
export const isZero = (val, sort_by) => (
	_.eq(val, 0)
	&& _.findIndex(ZERO_ALLOWED_STATS, val => _.startsWith(sort_by, val)) === -1
);
export const isStringOrZero = (val, sort_by) => (
	_.isString(val)
	|| isZero(val, sort_by)
	|| val === undefined
	|| _.isNaN(val)
);
export const getResult = 
    (condition, min, value, sort_by) => condition(value, sort_by) ? min : value;

export const getLastRoundId = (round: TRound)  => {
	if (_.get(round, "status") === "scheduled") {
		return _.get(round, "id", 1) - 1;
	}
	return _.get(round, "id", 0);
};
export const getCompressedViewStatus = () => {
	if(localStorage.getItem("compressed_view")){
		return localStorage.getItem("compressed_view") === "true" ? true : false;
	}
	localStorage.setItem("compressed_view", "false");
	return false;
};

export const getIsWhite = index => {
	return (index+1)%2 !== 0;
};