// @flow
import * as React from "react";
import styled, { css } from "styled-components";
import _ from "lodash";
import * as numeral from "numeral";
import Table, { TBody, THead, Tr, Td } from "../../components/TableX";
import { below, above } from "../../assets/css/media";
import colors from "../../assets/css/colors";
import { getShortName } from "../../helpers/league";
import type { TCellInfo } from "../../helpers/tables";
import type { TRoundsById, TTeam } from "../../modules/types";
import ThStat from "../../components/ThStat";
import sticky_headers from "../../assets/css/sticky";

numeral.nullFormat("- -");

const LadderTable = styled(Table)`
	box-shadow: none;
	margin-top: 0;
	border-collapse: separate;
`;

const LadderTHead = styled(THead)`
	background-color: transparent;
`;

const LadderRow = styled(Tr)`
	${({ is_user_team }) => is_user_team && css`
		background-color: ${colors.secondary.lightGrey};
		> td${TdStat}:nth-child(even), > td${TdStat}:nth-child(odd) {
			background-color: ${colors.secondary.lightGrey};
		}
		:first-child {
			border-bottom: 3px solid ${colors.primary.accent};
		}
		:last-child {
			border-top: 3px solid ${colors.primary.accent};
		}
	`}
`;

const LadderTh = styled(ThStat)`
	color: #1D4073;
	font-size: 12px;
	line-height: 10px;
	text-align: center;
	padding: 0;
	
	svg {
		display: none;
	}

	${sticky_headers}
	
	top: 50px !important;
    height: 75px;

	&.stat {
		width: 140px;
		padding: 0 7px;

		${below.phone`
			width: 45px;
		`}
	}

	&.name {
		text-align: left;
	}
`;

const TdStat = styled(Td)`
	position: relative;
	color: ${colors.primary.darkGrey};
	font-weight: 400;
	box-sizing: border-box;
	padding: 0;
	height: 60px;

	tr:nth-child(even) > & {
		background: white;
	}

	&.stat {
		width: 140px;
		padding: 0 7px;

		${below.phone`
			width: 45px;
			padding: 0 3px;
		`}
	}
	&.rank {
		padding: 0 5px;
		width: 60px;

		${below.phone`
			width: 45px;
			padding: 0;
		`}
	}
`;

const NameWrapper = styled.div`
	flex: 1 0 0px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	box-sizing: border-box;

	${below.phone`
		padding-left: 0px;
	`}
`;

const TeamName = styled.div`
	font-size: 12px;
	line-height: 1.125;
	font-weight: bold;
	text-align: left;
	color: ${colors.primary.primary};
	text-overflow: ellipsis;
	overflow: hidden;
	max-width: 100px;
	white-space: nowrap;

	${above.phone`
		font-size: 14px;
		max-width: 150px;
	`}

	${above.tablet`
		max-width: 200px;
	`}

	${above.desktop`
		max-width: 250px;
	`}
`;

const TeamWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;

	text-decoration: none;

	> div {
		flex: 0;
	}
`;

const UserName = styled.div`
	font-size: 12px;
	line-height: 1.28;
	text-align: left;
	color: #7F8A90;
	text-overflow: ellipsis;
	overflow: hidden;
	max-width: 100px;
	white-space: nowrap;

	${above.phone`
		max-width: 150px;
	`}

	${above.tablet`
		max-width: 200px;
	`}

	${above.desktop`
		max-width: 250px;
	`}
`;

type TableColumn = {
	key: string,
	prop: string,
	hide_small?: boolean,
	format?: string,
	sort_key?: string,
	short_key?: string,
	onClick?: Function,
	/* rel attr of table column. Defaults to value of prop, if not provided */
	rel?: string
};

type Props = {
	compact?: boolean,
	user_id: number,
	ladder_stats: TableColumn[],
	ladder_teams_ordered: Array<Object>,
	rounds_by_id: TRoundsById,
	selected_round_id: number,
	updateSortBy: Function,
	order: { by: string, direction: 'ASC' | 'DESC' },
	mobile_columns: string[],
	tablet_columns: string[],
	is_ladder: boolean,
	is_h2h_overall: boolean,
	is_classic: boolean,
	league_id: number,
};

type State = {
};

export class OffSeasonRankingsTable extends React.Component<Props, State> {
	static defaultProps =  {
		mobile_columns: [],
		tablet_columns: [],
		is_ladder: false,
		is_h2h_overall: false,
		is_classic: true,
		league_id: 0
	};

	constructor() {
		super();

		_.bindAll(this, [
			"tableColumnToCellInfo",
		]);
	}

	getRank(team: TTeam) {
		return _.get(team, "overall_rank", "- -");
	}

	tableColumnToCellInfo(cell: TableColumn): TCellInfo {
		return {
			// $FlowFixMe
			cell: {
				rel: cell.prop,
				...cell,
				name: cell.key,
				short_name: _.get(cell, "short_key", ""),
				sortable: cell.sort_key !== "",
				is_fc: false,
			},
			table: {
				sort_key: this.props.order.by,
				order_by_desc: this.props.order.direction === "DESC"
			}
		};
	}

	renderTableHeader() {
		const { ladder_stats, is_h2h_overall } = this.props;
		const ladder_header_cells = _(ladder_stats)
			.map(this.tableColumnToCellInfo)
			.map(cell_info => (
				<LadderTh key={cell_info.cell.key}>
					<span>{cell_info.cell.key}</span>
				</LadderTh>
			))
			.value();

		return <LadderTHead>
			<Tr>
				<LadderTh>Rank</LadderTh>
				{!is_h2h_overall && <LadderTh width="30px" rel="rank_change" />}
				<LadderTh className="name" rel="avatar">
					<span className="column-name">
						<span>Team</span>
						<span>Team</span>
					</span>
				</LadderTh>
				{ ladder_header_cells }
				<LadderTh />
			</Tr>
		</LadderTHead>;
	}

	renderTableRow(team: TTeam) {
		const { ladder_stats, user_id } = this.props;

		const ladder_stats_body_cells = ladder_stats.map(
			({ key, prop, hide_small, format="0,0", rel=prop }) => {
				const raw_value = _.get(team, prop, null);
				let value = numeral(raw_value).format(format) || "- -";
				if (prop === "value") {
					value = numeral(team[prop] / 1000).format(format) + "k" || "- -";
				}
				return <TdStat
					className='stat'
					key={prop}
					rel={rel}
				>
					{value}
				</TdStat>;
			}
		);

		return (
			<LadderRow
				key={team.team_id || team.id}
				is_user_team={user_id === team.user_id}
			>
				<TdStat
					rel="rank"
					className="rank"
				>
					{this.getRank(team)}
				</TdStat>
				<TdStat />
				<TdStat rel="avatar" className="name">
					<TeamWrapper>
						<NameWrapper>
							<TeamName>{team.team_name || team.name}</TeamName>
							<UserName>{getShortName(team)}</UserName>
						</NameWrapper>
					</TeamWrapper>
				</TdStat>
				{ladder_stats_body_cells}
				<TdStat />
			</LadderRow>
		);
	}

	renderTableBody() {
		const { ladder_teams_ordered } = this.props;

		return (
			<TBody>
				{ladder_teams_ordered.map(team => this.renderTableRow(team))}
			</TBody>
		);
	}

	render() {
		const { mobile_columns, tablet_columns } = this.props;
		return (
			<LadderTable
				tablet_columns={tablet_columns}
				mobile_columns={mobile_columns}
			>
				{this.renderTableHeader()}
				{this.renderTableBody()}
			</LadderTable>
		);
	}
}

export default OffSeasonRankingsTable;