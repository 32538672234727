
// @flow
import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import Arrow from "../Icons/Arrow";
import colors from "../../assets/css/colors";
import { below } from "../../assets/css/media";


export const FixtureButton = styled(Link)`
  width: auto;
	background: ${colors.primary.primary};
	height: 20px;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
	box-sizing: border-box;
	font-size: 10px;
	line-height: 1;
	color: #FFFFFF;
	outline: none;
	border: none;
	cursor: pointer;
	border-radius: 1px;
	text-decoration: none;
	padding: 2px 12px 0;
	min-width: 10em;
	white-space: nowrap;
	font-family: SourceSansPro;
	font-weight: 600;
	transition: background-color 0.2s linear;
	min-width: auto;
	padding: 0px 6px 0 6px;

	${below.tablet`
		height: 30px;
		width: 30px;
		font-size: 12px;
		font-weight: 400;
	`}

	svg {
    height: 16px;
		transform: translateY(-0.1em);
		fill: ${props => props.type === "" ? `${colors.primary.primary};` : "#FFF"}	
	}
	${props => props.type === "" && `
		background: ${colors.primary.primary};
		color: #fff;
	`}
	${props => props.type === "live" && `
		background: ${colors.primary.accentGreen};
		color: #fff;

		:hover {
			background-color: #54b135;
		}
	`}
	${props => props.type === "full_time" && `
		background: ${colors.primary.primary};
		color: #fff;

		:hover {
			background-color: #df3a3a;
		}
	`};

	&.is-game-bar {
    width: auto;
    height: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    box-sizing: border-box;
    font-size: 10px;
    line-height: 1;
    color: #FFFFFF;
    outline: none;
    border: none;
    cursor: pointer;
    border-radius: 1px;
    text-decoration: none;
    padding: 2px 12px 0;
    min-width: 10em;
    white-space: nowrap;
    font-family: SourceSansPro;
    font-weight: 600;
    transition: background-color 0.2s linear;
		min-width: auto;
		padding: 0px 6px 0 6px;
		${({ type }) => type === "live" && `
			padding: 5px 30px;
		`}
		margin: 0px 29px;
		${below.tablet`
			height: 20px;
			font-size: 12px;
			padding: 10px 15px;
			font-weight: 400;
		`}
	}
`;

type Props = {
	children: Object,
	type?: 'full_time' | 'live' | '',
	no_arrow?: boolean,
	is_game_bar?: boolean,
	to?: string,
	no_arrow?: boolean,
	is_fixture?: boolean
}

export const ButtonFixtureMatchUp = ({
	children,
	no_arrow = true,
	is_game_bar,
	to = "#",
	type
}: Props) => (
	<FixtureButton className={is_game_bar ? "is-game-bar" : ""} to={to} type={type}>
		{children}
		{!no_arrow && <Arrow direction="right" />}
	</FixtureButton>
);


export default ButtonFixtureMatchUp;