// @flow

import * as React from "react";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import _, { get, keyBy } from "lodash";
import type { 
	TPlayer, 
	TPLeague, 
	TRootStore, 
	TRound, 
	TUser, 
	TClassicTeam,
	TPlayersById
} from "../../modules/types";
import Star from "../Icons/Star";
import { 
	getClubLogoIndigenous, 
	reverse_logos, 
	shouldUseIndigenousLogos 
} from "../../utils/clubLogos";
import { Exist } from "../../components";
import colors from "../../assets/css/colors";
import * as selectors from "../../modules/selectors";
import * as actions from "../../modules/actions";
import { currencyFormat, isMobile, isOffSeason } from "../../utils";
import { FantasyCoachModal } from "../../components";
import LineupSetter from "../../components/MyTeam/lineupSetter";
import { findMatch } from "../../helpers/rounds";
import {getPlayerIDsFromLineup} from "../../helpers/team";
import {
	AddButton,
	BackgroundLogoContainer,
	BottomBlock,
	BottomButtonsBlock,
	CardLogo,
	classicStats,
	CompareButton,
	DelistButton,
	draftStats,
	FAIcon,
	FAIconContainer,
	FavIcon,
	OwnerBlock,
	PlayerAvatar,
	PlayerFirstName,
	PlayerInfo,
	PlayerInfoInner,
	PlayerLastName,
	PlayerStats,
	Position,
	StatBlock,
	StatusIcon,
	statusIconComponent,
	TradeButton,
} from "../PlayerProfile/playerCard";

import Lock from "../Icons/Lock";
import TeamUserAvatar from "../Team/TeamUserAvatar";
import { getShortName } from "../../helpers/league";
import {getCommandButtonColors} from "../MyTeam/ListView/commandButton";
import Captain from "../Icons/Captain";
import ViceCaptain from "../Icons/ViceCaptain";
import { priceDiff } from "../PlayerProfile/printStat";
import { isAllTrue } from "../../helpers";
import {
	AvatarWrapper,
	StyledCommandButton,
	StyledTabs,
	StyledTabItem,
	FcLink,
	StyledStatBlock,
	PlayerModalContainer,
	TopPopupBlock,
	WebDiv,
	FlexRow,
	MobileDiv,
	FlexRowStart,
	FlexCol,
	StyledMobName,
	PlayerOpponentBlock,
	Exists,
	FullProfileContainer,
	FullPlayerLinkBackground,
	FullProfileLink,
	PlayerPreviewWrapper,
	PlayerPreviewLock,
	SectionHeader,
	PlayerPreviewSection
} from "./PlayerPopupStyles";
import { KeyStatsComponent } from "./KeyStats";
import  MatchStatsComponent from "./MatchStats";
import FixtureStats from "./FixtureStats";
import OppositionStats from "./OppositionStats";
import VenueStats from "./VenueStats";

const SITE_ENV = process.env.REACT_APP_ENV || "uat";

type Props = {
	player: TPlayer,
	user: TUser,
	league: TPLeague,
	actual_round: TRound,
	is_classic?: boolean,
	is_team_started: boolean,
	team_ids: Array<number>,
	stats: Function,
	history: {
		push: Function,
	},
	match: {
		params: {
			league_id: string | number
		}
	},
	onClose: Function,
	removeFromFavourites: Function,
	addToFavourites: Function,
	my_players: number[],
	rounds: TRound[],
	has_coach: boolean,
	fetchPlayerMatches: Function,
	player_matches: Object,
	player_id: number,
	team: TClassicTeam,
	updateMyTeamClassic: typeof actions.updateMyClassicTeam,
	updateMyTeamDraft: typeof actions.teamsDraft.postTeamUpdate,
	players_by_id: TPlayersById,
	fetchPlayerNews: typeof actions.fetchPlayerNews,
	news: [],
	player_news: Object,
};

type State = {
	show_fantasy_coach_modal: boolean,
	active_tab: string,
	news_data: Object
}

class PlayerProfilePopupComponent extends React.Component<Props, State> {
	static defaultProps = {
		is_classic: false
	};

	constructor(props: Props) {
		super(props);

		this.state = {
			show_fantasy_coach_modal: false,
			active_tab: "playerOverview",
			news_data: {}
		};
		
		_.bindAll(this, [
			"onFavourite",
			"goToFullProfilePage",
			"goToPlayerComparePage",
			"goToClassicTradePlayerPage",
			"toggleFantasyCoachModal",
			"setCaptain",
			"setViceCaptain",
			"checkIsPlayerChangeAvailable",
			"shouldComponentUpdate",
			"setActiveTab"

		]);

		this.goToTradeInPlayer = this.goToDraftTradePlayerPage.bind(this, "add");
		this.goToTradeOutPlayer = this.goToDraftTradePlayerPage.bind(this, "trade");
		this.goToTradeDelistPlayer = this.goToDraftTradePlayerPage.bind(this, "delist");
	}
	

	shouldComponentUpdate(nextProps: Props, nextState: State) {
		const should = !_.isEqual(this.props.player, nextProps.player) 
			|| !_.isEqual(this.props.team.lineup, nextProps.team.lineup)
			|| this.state.active_tab !== nextState.active_tab;
		return should;
	}

	componentDidUpdate(prevProps) {
		if(prevProps.team.lineup !== this.props.team.lineup){
			return;
		}
		const modalAnchor = document.querySelector(".anchor-top");
		if(modalAnchor !== null){
			modalAnchor.scrollIntoView();
		}
	}
	
	componentWillUnmount() {
		this.props.onClose();
	}

	goToTradeInPlayer: Function;
	goToTradeOutPlayer: Function;
	goToTradeDelistPlayer: Function;

	

	goToFullProfilePage() {
		const
			{ is_classic, player, match: { params: { league_id } } } = this.props,
			url_prefix = is_classic ? "/classic" : `/draft/league/${league_id}`;
		const player_slug = player.slug;

		return `${url_prefix}/player/${player_slug}`;
	}

	goToPlayerComparePage() {
		const
			{ is_classic, player, match: { params: { league_id } } } = this.props,
			url_prefix = is_classic
				? "/classic/stats-centre"
				: `/draft/league/${league_id}/players`;

		return `${url_prefix}/compare/${player.id}`;
	}

	goToDraftTradePlayerPage(type) {
		const { player, match: { params: { league_id } } } = this.props;
		return `/draft/league/${league_id}/players/${player.id}/${type}`;
	}

	goToClassicTradePlayerPage() {
		const { player } = this.props;
		return `/classic/advanced-trades/${player.id}`;
	}
	toggleFantasyCoachModal() {
		const { show_fantasy_coach_modal } = this.state;
		this.setState({ show_fantasy_coach_modal: !show_fantasy_coach_modal });
	}

	get classic_owner_block() {
		const { my_players, player, user } = this.props;
		if (player) {
			const owns_player = _.includes(my_players, player.id);
			if (owns_player) {
				return (
					<React.Fragment>
						<AvatarWrapper>
							<TeamUserAvatar 
								user_id={user.id} 
								avatar_version={user.avatar_version} 
								firstname={user.firstname} 
								lastname={user.lastname} 
						
							/>
						</AvatarWrapper>
						<div>
							<div className="team-name">{_.get(user, "team_name")}</div>
							<div className="user-name">{getShortName(user)}</div>
						</div>
						
					</React.Fragment>
				);
			}
		}

		return null;
	}

	get draft_owner_block() {
		const { player } = this.props;
		const { owner, waiver } = player;

		if (owner) {
			return (
				<React.Fragment>
					<AvatarWrapper>
						<TeamUserAvatar 
							user_id={owner.user_id} 
							avatar_version={owner.avatar_version} 
							firstname={owner.firstname} 
							lastname={owner.lastname} 
						
						/>
					</AvatarWrapper>
					
					<div>
						<div className="team-name">{_.get(owner, "name")}</div>
						<div className="user-name">{getShortName(owner)}</div>
					</div>
					
				</React.Fragment>
			);
		}

		return (
			<FAIconContainer>
				<FAIcon>FA</FAIcon>
				<span>{waiver}</span>
			</FAIconContainer>
		);

	}

	get draft_trade_buttons() {
		const
			{ user, player, onClose, team: { lineup } } = this.props,
			{ owner } = player,
			is_user_team = _.get(owner, "user_id") === user.id;
		const lineupCaptain = _.get(lineup, "captain", 0);
		const lineupVice = _.get(lineup, "vice_captain");
		const is_emergency = false;
		const is_captain = lineupCaptain === player.id;
		const is_vice = lineupVice === player.id;
		

		const btn_colors = getCommandButtonColors(
			is_emergency,
			is_captain,
			is_vice
		);

		if (!this.isDraftTradeAvailable(player)) {
			return null;
		}

		const EnableCaptionButton = (
			<React.Fragment>
				<StyledCommandButton
					{...btn_colors.captain}
					is_draft={false}
					onClick={this.setCaptain}
					data-id={player.id}
					is_hidden={false}
				>
					<Captain />
				</StyledCommandButton>
				<StyledCommandButton
					{...btn_colors.vice}
					is_draft={false}
					onClick={this.setViceCaptain}
					data-id={player.id}
					is_hidden={false}
				>
					<ViceCaptain />
				</StyledCommandButton>
			</React.Fragment>
		);

		if (is_user_team) {
			return (
				<React.Fragment>
					{!!this.props.league.captains && EnableCaptionButton}
					<TradeButton to={this.goToTradeOutPlayer()} onClick={onClose} />
					<DelistButton to={this.goToTradeDelistPlayer()} onClick={onClose} />
				</React.Fragment>
			);
		}

		if (owner) {
			return(
				<React.Fragment>
					<TradeButton to={this.goToTradeOutPlayer()} onClick={onClose} />
				</React.Fragment>
			) ;
		}

		return <AddButton to={this.goToTradeInPlayer()} onClick={onClose} />;
	}

	get is_captain_started() {
		const {team, actual_round, players_by_id} = this.props;

		const { lineup } = team;

		const captain =  _.get(lineup, "captain", 0);
		if(!captain){
			return false;
		}
		const captainPlayer = get(players_by_id, captain, {});

		const captainSquad = get(captainPlayer, 'squad_id');
		const roundMatches = get(actual_round, 'matches', []);

	
		const match = roundMatches.find(match => 
			[get(match, 'away_squad_id'), get(match, 'home_squad_id')].includes(captainSquad));

		return get(match, 'status') !== "scheduled";
	
	}

	get is_vice_started() {
		const {team, actual_round, players_by_id} = this.props;
		const { lineup } = team;
		const vice_captain =  _.get(lineup, "vice_captain", 0);

		if(!vice_captain){
			return false;
		}
		const viceCaptainPlayer = get(players_by_id, vice_captain, {});
		const viceCaptainSquad = get(viceCaptainPlayer, 'squad_id');
		const roundMatches = get(actual_round, 'matches', []);
	
		const match = roundMatches.find(match => 
			[get(match, 'away_squad_id'), get(match, 'home_squad_id')].includes(viceCaptainSquad));

		return get(match, 'status') !== "scheduled";
	}

	get classic_trade_buttons() {
		const
			{ team_ids, player, actual_round, onClose, team: { lineup } } = this.props,
			is_player_in_my_team = _.includes(team_ids, player.id),
			bench_emergency = _.get(lineup, "bench.emergency", []),
			is_emergency = bench_emergency.includes(player.id),
			is_captain =  _.get(lineup, "captain", 0) === player.id,
			is_vice = _.get(lineup, "vice_captain", 0) === player.id;
		

		const btn_colors = getCommandButtonColors(
			is_emergency,
			is_captain,
			is_vice
		);

		const round_id = _.get(actual_round, "id", 0);
		const status = _.get(actual_round, "status", "scheduled");
		if (round_id < 2 && status === "scheduled") {
			return null;
		}
		if(is_player_in_my_team){
			return (
				<React.Fragment>
					<StyledCommandButton
						{...btn_colors.captain}
						is_draft={false}
						onClick={this.setCaptain}
						data-id={player.id}
						is_hidden={this.is_captain_started}
					>
						<Captain />
					</StyledCommandButton>
					<StyledCommandButton
						{...btn_colors.vice}
						is_draft={false}
						onClick={this.setViceCaptain}
						data-id={player.id}
						is_hidden={this.is_vice_started}
					>
						<ViceCaptain />
					</StyledCommandButton>

				</React.Fragment>
			);
		}

		if(!is_player_in_my_team || !this.isClassicTradeAvailable(player)) {
			return null;
		}

		return <TradeButton to={this.goToClassicTradePlayerPage()} onClick={onClose} />;
	}

	get trade_buttons() {
		const { is_classic } = this.props;
		return is_classic ? this.classic_trade_buttons : this.draft_trade_buttons;
	}
	get mobile_stat_block(){
		const { player } = this.props;
		const getFromPlayer = _.partial(_.get, player, _);
		const player_stat = getFromPlayer("stats.avg_points", "-");
		return(
			<StatBlock key={"stats.avg_points-mob"} className="hidden-desktop">
				<div>
					<p>Average Score</p>
					<span>{ player_stat }</span>
				</div>
			</StatBlock>
		);
	}
	isDraftTradeAvailable(player) {
		const
			{ actual_round, league } = this.props,
			{ lockout } = league;

		if (league.draft_status !== "complete") {
			return false;
		}

		if (league.status === "scheduled") {
			return true;
		}

		if (!lockout && actual_round.status !== "scheduled") {
			return false;
		}

		return !_.get(player, "locked");
	}

	isClassicTradeAvailable(player) {
		const { is_team_started } = this.props;

		if (!is_team_started) {
			return true;
		}

		const
			{ actual_round } = this.props,
			{ status, lockout } = actual_round,
			is_active_round = status === "active";

		if (is_active_round) {
			return lockout === "full" ? false : !player.locked;
		}

		return true;
	}

	goTo(url) {
		const { onClose, history } = this.props;

		onClose();
		history.push(url);
	}

	onFavourite() {
		const
			{ player, removeFromFavourites, addToFavourites } = this.props,
			{ id, is_favourite } = player;

		is_favourite ? removeFromFavourites(id) : addToFavourites(id);
	}

	setCaptain(event: Object) {
		const { 
			team, 
			updateMyTeamClassic, 
			updateMyTeamDraft, 
			is_classic 
		} = this.props;
		const { checkIsPlayerChangeAvailable } = this;
		const updateMyTeam = is_classic ? 
			updateMyTeamClassic : updateMyTeamDraft;
		LineupSetter.setCaptain({
			event,
			team,
			updateMyTeam,
			checkIsPlayerChangeAvailable,
			is_draft: !is_classic
		});
	}
	setViceCaptain(event: Object) {
		const { 
			team, 
			updateMyTeamClassic, 
			updateMyTeamDraft, 
			is_classic 
		} = this.props;

		const updateMyTeam = is_classic ? 
			updateMyTeamClassic : updateMyTeamDraft;
		const { checkIsPlayerChangeAvailable } = this;

		LineupSetter.setViceCaptain({
			event,
			team,
			updateMyTeam,
			checkIsPlayerChangeAvailable,
			is_draft: !is_classic
		});
	}

	checkIsPlayerChangeAvailable(player_id) {
		const { actual_round, is_team_started, players_by_id } = this.props;
		const player = players_by_id[player_id];
		const squad_id = _.get(player, "squad_id");
		const matches = _.get(actual_round, "matches", []);
		const { status, lockout } = actual_round;
		const is_active = status !== "scheduled";

		const match = findMatch(matches, squad_id);
		const match_status = _.get(match, "status");
		const player_available = !match || match_status === "scheduled";

		if (!is_team_started) {
			if (lockout === "partial") {
				return player_available;
			}
			return true;
		}

		/**
		 * If the round has started, players can only be changed
		 * if they aren't locked and lockout is not yet full
		 */
		if (is_active) {
			if (lockout === "full") {
				return false;
			}
			else if (!player) {
				return true;
			}
			else {
				return player_available;
			}
		}

		return true;
	}

	get stats_object() {
		const { stats, league } = this.props;
		const custom_scoring_enabled = _.get(league, "custom_scoring_enabled", false);
		const statsBasis = custom_scoring_enabled ? "custom_stats" : "stats";
		return _.isFunction(stats) ? stats(statsBasis) : stats;
	}

	get playerName() {
		const { player } = this.props;
		if (player === undefined) {
			return "";
		}
		return `${player.first_name} ${player.last_name}`;
	}

	get player_preview_section() {
		const {has_coach, player, player_news} = this.props;
		const playerStatus = _.get(player, "status");
		const text = _.get(player_news, "text");

		const HIDE_PREVIEW = (SITE_ENV === "production") || isOffSeason();
		if(HIDE_PREVIEW){
			return null;
		}
		if(!has_coach){
			return <PlayerPreviewWrapper>
				<SectionHeader is_coach={true}>Player Preview</SectionHeader>
				<PlayerPreviewLock>
					<FcLink href={"/coach-subscription"} target="_blank">
						<Lock size={1.5} color={colors.coach}/>
					</FcLink>
					<p>
						Subscribe to see what Fantasy Coach says about {" "}
						{this.playerName} for this round!
					</p>
				</PlayerPreviewLock>
			</PlayerPreviewWrapper>;
		}
		if(playerStatus === "uncertain"){
			return <PlayerPreviewWrapper>
				<SectionHeader is_coach={true}>Player Preview</SectionHeader>
				<PlayerPreviewSection>
					Player Preview will be available once teams are announced this week.
				</PlayerPreviewSection>
			</PlayerPreviewWrapper>;
		}
		return <PlayerPreviewWrapper>
			<SectionHeader is_coach={true}>Player Preview</SectionHeader>
			<PlayerPreviewSection>
				{text}
			</PlayerPreviewSection>
		</PlayerPreviewWrapper>;
	}

	setActiveTab(tab: string) {
		this.setState({
			active_tab: tab
		});
	}

	get tabs () {
		const {active_tab} = this.state;
		return <StyledTabs>
			<StyledTabItem
				onClick={() => this.setState({active_tab: "playerOverview"})}
				data-tab="playerOverview"
				className={"playerOverview" 
					+ (active_tab === "playerOverview" ? " active-tab" : "")}>
        Player Overview
			</StyledTabItem>
			{!isOffSeason() && 
				<StyledTabItem
					onClick={() => this.setState({active_tab: "fixture"})}
					data-tab="fixture"
					className={"fixture" + (active_tab === "fixture" ? " active-tab" : "")}>
						Fixture Stats
				</StyledTabItem>
			}
			
			<StyledTabItem
				onClick={() => this.setState({active_tab: "match"})}
				data-tab="match"
				className={"match" + (active_tab === "match" ? " active-tab" : "")}>
        Match Stats
			</StyledTabItem>
			<Exist when={!isOffSeason()}>
				<StyledTabItem
					onClick={() => this.setState({active_tab: "opposition"})}
					data-tab="opposition"
					className={"coach-tab opposition" 
								+ (active_tab === "opposition" ? " active-tab" : "")}>
					Opposition Stats
				</StyledTabItem>
				<StyledTabItem
					onClick={() => this.setState({active_tab: "venue"})}
					data-tab="venue"
					className={"coach-tab venue" + (active_tab === "venue" ? " active-tab" : "")}>
					Venue Stats
				</StyledTabItem>

			</Exist>
				
			
		</StyledTabs>;
	}

	isStatPaid(stat){
		const {has_coach} = this.props;
		return stat.is_coach && !has_coach;
	}

	get owner_block() {
		const {is_classic} = this.props;
		return is_classic ? this.classic_owner_block : this.draft_owner_block;
	}

	get fantasy_coach_section() {
		return <Exists when={this.state.show_fantasy_coach_modal}>
			<FantasyCoachModal
				closeClick={this.toggleFantasyCoachModal}
			/>
		</Exists>;

		
	}
	get stats_components() {
		const { player, actual_round, league} = this.props;
		const custom_scoring_enabled = Boolean(_.get(league, "custom_scoring_enabled", 0));
		const
			getFromPlayer = _.partial(_.get, player, _);
		const statsBasis = custom_scoring_enabled ? "custom_stats" : "stats";

		const fieldStatHandler = (customScoringEnabled, stat) => {
			return customScoringEnabled 
				&& stat.includes("proj_score") ? "custom_stats.proj_score_actual_round" : stat;
		};
		
			

		return _.map(this.stats_object, stat => {
			const { title, id } = stat;
			const idHandled = fieldStatHandler(custom_scoring_enabled, id);
			
			const player_stat = getFromPlayer(idHandled, "-");
			let value =  _.eq(idHandled, "cost")
				? currencyFormat({ input: parseInt(player_stat, 10) })
				: player_stat;


			if(this.isStatPaid(stat)){

				return(
					<StatBlock key={`${idHandled}`}  popup coach_stat>
						<div>
							<p>{ title }</p>
							<FcLink href={"/coach-subscription"} target="_blank">
								<Lock size={2} color={colors.coach}/>
							</FcLink>
						</div>
					</StatBlock>
				);
			}
			if(isAllTrue([[ `${statsBasis}.points_last_round`, 
				`${statsBasis}.adp`].includes(idHandled),isMobile() ])){
				return(
					<StatBlock key={`${idHandled}`}  popup coach_stat={stat.is_coach}>
						<div>
							<p>{ stat.short_title }</p>
							<span>{ value || 0 }</span>
						</div>
					</StatBlock>
				);
			}
			if(idHandled.includes("price_change")){
				const { proj_prices, prices } = player.stats;
				const all_prices = { ...proj_prices, ...prices };
				let positive = false;
				value = "--";
				if (_.has(all_prices, `${actual_round.id + 1}`)) {
					positive = all_prices[actual_round.id + 1] > all_prices[actual_round.id];
					value = priceDiff(all_prices[actual_round.id + 1], all_prices[actual_round.id]);
				} 
				
				return(
					<StyledStatBlock 
						key={`${idHandled}`}  
						popup  
						coach_stat={false} 
						positive={positive}>
						<div>
							<p>{ title }</p>
							<span>{value}</span>
						</div>
					</StyledStatBlock>
				);
				
			}
			return (
				
				<StatBlock key={`${idHandled}`}  popup  coach_stat={stat.is_coach}>
					<div>
						<p>{ title }</p>
						<span>{ value }</span>
					</div>
				</StatBlock>
				
			);
		});
	}

	get main_content() {
		const { player, is_classic, actual_round} = this.props;
	
		const commonStatsProps = {
			has_coach: this.props.has_coach,
			rounds: this.props.rounds,
			league: this.props.league,
			player: this.props.player,
			is_classic: this.props.is_classic,
			actual_round: this.props.actual_round.id,
		};
		

		const
			getFromPlayer = _.partial(_.get, player, _),
			PLAYERS_AVATAR_PATH = (process.env.REACT_APP_PLAYERS_PATH || ""),
			squad_id = getFromPlayer("squad_id"),
			avatar_url = `${PLAYERS_AVATAR_PATH + getFromPlayer("id")}_450.png`,
			avatar_url_webp = `${PLAYERS_AVATAR_PATH + getFromPlayer("id")}_450.webp`,
			squad_logo_url = reverse_logos[squad_id],
			status = getFromPlayer("is_bye") ? "is-bye" : _.get(player, "status", "uncertain"),
			StatusIconComponent = _.get(statusIconComponent, status),
			squad_name = getFromPlayer("squad.name"),
			first_name = getFromPlayer("first_name"),
			last_name = getFromPlayer("last_name"),
			full_name = `${first_name} ${last_name}`,
			positions_str = isMobile() 
				? getFromPlayer("positions_name").join(" / ")
				: getFromPlayer("positions_full_name", []).join(" / "),
			{ onClose } = this.props,
			{ is_favourite } = player,
			playerDOB = _.get(player, "dob"),
			playerAge = Math.floor(moment().diff(playerDOB, "years", true)),
			playerOpponent = _.get(player, "competition.opponent.id", 0),
			playerOpponentName = isMobile() ? _.get(player, "competition.opponent.short_name", "")
				: _.get(player, "competition.opponent.full_name", ""),
			playerPrice = currencyFormat({ input: parseInt(player.cost, 10) });
		const playerOpponentLogo = getClubLogoIndigenous(actual_round.id, playerOpponent);
		const playerTeamLogo = shouldUseIndigenousLogos(actual_round.id) 
			? getClubLogoIndigenous(actual_round.id, squad_id) : squad_logo_url;
		return (
			<React.Fragment>
				{this.fantasy_coach_section}
				<PlayerModalContainer onClick={onClose} >
					<TopPopupBlock squad_id={squad_id} className='anchor-top'>
						<BackgroundLogoContainer>
							<CardLogo 
								src={playerTeamLogo} 
								alt={squad_name} 
								title={squad_name} 
								popup
							/>
						</BackgroundLogoContainer>
						<PlayerInfo popup>
							<img src={playerTeamLogo} 
								alt={squad_name} title={squad_name} 
								className='hidden-mobile'/>
							<PlayerInfoInner >
								<WebDiv>
									<PlayerFirstName popup>{first_name}</PlayerFirstName>
									<PlayerLastName popup>{last_name}</PlayerLastName>
									<StatusIcon 
										status={player.status} 
										is_bye={player.is_bye} 
										disabled="disabled"
										compressed
									>	
										<Exist when={StatusIconComponent !== undefined}>
											<StatusIconComponent />
										</Exist>
									</StatusIcon>
									<FlexRow>
										<Position popup>{ positions_str }</Position>
										<Position popup age_section>Age: { playerAge }</Position>
									</FlexRow>
									{
										is_classic && 
									<PlayerFirstName 
										popup 
										margin_top 
										price>
										{playerPrice}
									</PlayerFirstName>
									}

								</WebDiv>
								<MobileDiv>
									<FlexRowStart>
										<img src={playerTeamLogo} 
											alt={squad_name} title={squad_name}/>
										<FlexCol>
											<FlexRow>
												<StyledMobName 
													popup> {first_name} {" "}
													{last_name}</StyledMobName>
												<StatusIcon 
													status={player.status} 
													is_bye={player.is_bye} 
													disabled="disabled"
													compressed
												>
													<Exists when={StatusIconComponent !== 
														undefined}>
														<StatusIconComponent />
													</Exists>
											
												</StatusIcon>
											</FlexRow>
											<FlexRow>
												<Position popup>{ positions_str }</Position>
												<Position popup age_section>
													Age: { playerAge }</Position>
													
											</FlexRow>
											<Exists when={is_classic}>
												<PlayerFirstName 
													popup 
													margin_top 
													mob
													price>
													{playerPrice}
												</PlayerFirstName>
											</Exists>
										

										</FlexCol>
										
										
									</FlexRowStart>
									
									
									

								</MobileDiv>
								
								
								
							</PlayerInfoInner>
						</PlayerInfo>
						<PlayerStats popup>
							{/* { this.mobile_stat_block } */}
							{ this.stats_components }
						</PlayerStats>
						
						<PlayerOpponentBlock>
							Next Opponent: 
							<img src={playerOpponentLogo} alt="Club Logo"/>
							{playerOpponentName}
						</PlayerOpponentBlock>
						<PlayerAvatar>
							<picture>
								<source srcSet={avatar_url_webp} type="image/webp"></source>
								<source srcSet={avatar_url} type="image/png"></source>
								<img src={avatar_url_webp} alt={full_name} title={full_name} />
							</picture>
						</PlayerAvatar>
					</TopPopupBlock>
					<BottomBlock>
						<OwnerBlock>
							{this.owner_block}
						</OwnerBlock>
						<BottomButtonsBlock>
							{ this.trade_buttons }
							<CompareButton to={this.goToPlayerComparePage()} />
							<FavIcon active={is_favourite} onClick={this.onFavourite}>
								<Star />
							</FavIcon>
						</BottomButtonsBlock>
					</BottomBlock>
					{this.player_preview_section}
					{this.tabs}
					<Exists when={["playerOverview", ""].includes(this.state.active_tab)}>
						<KeyStatsComponent 
							{...commonStatsProps}
							fullLink={this.goToFullProfilePage()}
							toggleFantasyCoachModal={this.toggleFantasyCoachModal}
						/>
					</Exists>
					<Exists when={this.state.active_tab === "match"}>
						<MatchStatsComponent 
							{...commonStatsProps}
						/>
					</Exists>
					<Exists when={this.state.active_tab === "fixture"}>
						<FixtureStats 
							{...commonStatsProps}
							toggleFantasyCoachModal={this.toggleFantasyCoachModal}
						/>
					</Exists>
					<Exists when={this.state.active_tab === "opposition"}>
						<OppositionStats 
							{...commonStatsProps}
						/>
					</Exists>
					<Exists when={this.state.active_tab === "venue"}>
						<VenueStats 
							{...commonStatsProps}
						/>
					</Exists>
					
					
					
					
					
					<FullProfileContainer isOverview={this.state.active_tab === "playerOverview"}>
						<FullPlayerLinkBackground>
							<FullProfileLink to={this.goToFullProfilePage()} target="_blank">
							Full player profile
							</FullProfileLink>
						</FullPlayerLinkBackground>
						

					</FullProfileContainer>
					
				</PlayerModalContainer>
			</React.Fragment>
			
		);
	}


	render(){
		const {player} = this.props;
		if(!player){
			return null;
		}
		return this.main_content;
	}
}

const mapStateToProps = (state: TRootStore, props: Props) => {
	const { is_classic } = props;
	const id = _.get(state, "playerModal.player_id");
	const actual_round = selectors.rounds.getActualRound(state);
	const has_coach = Boolean(selectors.getUser(state).assistant_coach);
	const rounds = selectors.rounds.getRoundsWithMatches(state);
	const team = is_classic ? 
		selectors.getMyClassicTeam(state)
		: state.teamsDraft.user_team;

	const default_props = {
		actual_round,
		player: null,
		user: selectors.getUser(state),
		team_ids: [],
		is_team_started: false,
		my_players: [],
		rounds,
		has_coach,
		player_id: id,
		team,
		players_by_id: {}
	};
	const is_team_lineup = _.get(team, "lineup");
	if(!is_classic && is_team_lineup){
		default_props.team_ids = getPlayerIDsFromLineup(_.get(team, "lineup"));
	}
	if (!id) {
		return {
			...default_props,
		};
	}

	if(is_classic) {
		// const isOffseason = isOffSeason();
		const players =  selectors.players.getExtendedPlayersArray(state, props);
		const player = _.find(players, { id });
		const playerTeamID = player.squad_id;
		const players_by_id = keyBy(players, 'id');

		return {
			...default_props,
			player: player,
			team_ids: selectors.getMyClassicTeamPlayersIds(state),
			is_team_started: selectors.isMyTeamStarted(state),
			my_players: selectors.getMyClassicTeamPlayersIds(state),
			news: selectors.playerNews.getPlayerNews(state),
			player_news: selectors.playerNews.getPlayerSpecificNews(state)(id, playerTeamID),
			players_by_id
		};
	}

	const players = selectors.players.getExtendedPlayersArrayWithOwners(state, props);
	const player =  _.find(players, { id });
	default_props.players_by_id = selectors.players.getExtendedPlayersById(state);
	const playerTeamID = _.get(player, 'squad_id', 0);

	return {
		...default_props,
		league: selectors.leagueDraft.getLeague(state, props),
		player,
		news: selectors.playerNews.getPlayerNews(state),
		player_news: selectors.playerNews.getPlayerSpecificNews(state)(id, playerTeamID)
	};
};

const mapDispatchToProps = {
	onClose: actions.hideModalForPlayer,
	addToFavourites: actions.postAddToFavourites,
	removeFromFavourites: actions.postRemoveFromFavourites,
	updateMyTeamClassic: actions.updateMyClassicTeam,
	updateMyTeamDraft: actions.teamsDraft.postTeamUpdate,
	fetchPlayerNews: actions.fetchPlayerNews
};

export const PlayerProfilePopup = withRouter(connect(
	mapStateToProps,
	mapDispatchToProps,
)(PlayerProfilePopupComponent));

export const withDraftPlayerModal = (WrappedComponent: React.ComponentType<Object>) => {
	return (props: Object) => (
		<React.Fragment>
			<WrappedComponent {...props} />
			<PlayerProfilePopup stats={draftStats} {...props} />
		</React.Fragment>
	);
};

export const withClassicPlayerModal = (WrappedComponent: React.ComponentType<Object>) => {
	return (props: Object) => (
		<React.Fragment>
			<WrappedComponent {...props} />
			<PlayerProfilePopup is_classic={true} stats={classicStats} {...props} />
		</React.Fragment>
	);
};

export default PlayerProfilePopup;