/* eslint max-len: 0 */
// @flow
import React from "react";
import SvgIconContainer from "../SvgIconContainer";

export default (
	{
		unit = "em",
		...rest
	}: Object = {}) => (
	<SvgIconContainer viewBox="0 0 24 24" {...rest} unit={unit}>
		<title>Uncertain</title>
		<path d="M16.612771,9.02802334 C16.6799014,10.854949 15.4480927,12.5055718
        13.5847679,13.0855452 C13.5847679,13.0855452 13.4547699,13.1283181 13.4547699,13.1991145
        L13.4547699,14.1651912 C13.4547699,14.5983361 13.2063907,14.9985786 12.8031943,15.2151511
        C12.399998,15.4317236 11.9032396,15.4317236 11.5000433,15.2151511 C11.0968469,14.9985786
        10.8484677,14.5983361 10.8484677,14.1651912 L10.8484677,13.1991145 C10.8611659,12.0992466
        11.6266516,11.1295068 12.750878,10.7890852 C13.5258574,10.5463758 14.0376563,9.859132
        14.0096395,9.0988198 C13.9539184,8.17027756 13.1352839,7.44023229 12.1357654,7.42772846
        C11.4266682,7.43064489 10.7820888,7.81132499 10.4774977,8.40707943 C10.1649227,9.01027533
        9.38593728,9.26351837 8.73758499,8.97271359 C8.0892327,8.68190881 7.81703139,7.95717806
        8.12960635,7.35398215 C8.86283089,5.92168685 10.4131328,5.00676109 12.1183266,5
        L12.1484481,5 C14.5553281,5.01501396 16.5228938,6.79028896 16.612771,9.02802334 Z
        M13.4611112,17.7817101 C13.460691,18.1052117 13.3221569,18.4153082 13.0759847,18.6437817
        C12.8298126,18.8722553 12.4961676,19.0003905 12.1484481,18.9999991 C11.4256507,18.9983709
        10.840962,18.4522054 10.8421281,17.7797483 C10.8432941,17.1072912 11.4298739,16.5628836
        12.1526731,16.5634251 C12.8754724,16.5639665 13.4611091,17.1092521 13.4611112,17.7817101 Z"
		fillRule="nonzero" />
	</SvgIconContainer>
);