// @flow
import styled from "styled-components";

export const TableFlexHeading = styled.div.attrs({
	role: "row"
})`
	display: flex;
	justify-content: space-between;
	height: ${({ height }) => height || "auto"};
	align-items: center;
	color: ${({ color }) => color || "#fff"};
	text-transform: uppercase;
	font-size: 10px;
	font-weight: bold;
	
	> div:first-child {
		padding-left: 20px;
		font-size: 12px;
	}
`;

export default TableFlexHeading;