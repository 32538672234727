// @flow
import * as React from "react";
import _ from "lodash";
import styled from "styled-components";
import { below } from "../../assets/css/media";
import { isMobile } from "../../utils";

const DraftOrderInput = styled.input`
	display: flex;
	font-size: 12px;
	align-items: center;
	justify-content: center;
	height: ${({ is_compressed }) => is_compressed ? "19px;" : "40px;"};
	width: 40px;
	border: 1px solid #C0C0C0;
	font-family: SourceSansPro;
	font-weight: 600;
	color: #1D4073;
	background: #ffffff;
	text-align: center;
	border-radius: 0px;
	padding: 0px;
	${below.phone`
		width: 40px;
		height: 20px;
		font-size: 14px;
	`};
`;

type Props = {
	onChange: Function,
	value: string,
	maxValue: number,
	'data-id': string,
	is_compressed: boolean,
};

type State = {
	value: string
};

export class DraftOrder extends React.Component<Props, State> {
	static defaultProps = {
		maxValue: 1000
	};

	constructor(props: Props) {
		super(props);

		_.bindAll(this, [
			"handleKeyPress",
			"handleOnChange",
			"handleOnBlur",
		]);
	}

	state = {
		value: "-"
	};

	componentDidMount(): void {
		this.setInternalValueToLocal();
	}

	componentDidUpdate(prevProps: Props): void {
		if(prevProps.value !== this.props.value) {
			this.setInternalValueToLocal();
		}
	}

	get is_value_changed(): boolean {
		return String(this.props.value) !== String(this.state.value);
	}

	get is_accepted_value(): boolean {
		return !_.isNaN(parseInt(this.state.value, 10));
	}

	commitChanges(event: KeyboardEvent | SyntheticInputEvent<HTMLInputElement>): void {
		const { onChange } = this.props;
		this.is_accepted_value ? onChange(event) : this.restoreValue();
	}

	setLocalValue(value: string): void {
		const
			digits_only = String(value).replace(/[^\d.]/g, ""),
			int_val = parseInt(digits_only, 10),
			{ maxValue } = this.props;

		value = digits_only ? String(Math.min(Math.max(1, int_val), maxValue)) : "";
		this.setState({ value });
	}

	setInternalValueToLocal(): void {
		const { value } = this.props;
		this.setState({ value });
	}

	restoreValue(): void {
		this.setLocalValue(this.props.value);
	}

	handleKeyPress(event: KeyboardEvent): void {
		if(event.key === "Enter" && this.is_value_changed){
			this.commitChanges(event);
		}
	}

	handleOnBlur(event: SyntheticInputEvent<HTMLInputElement>): void {
		if(this.is_value_changed){
			this.commitChanges(event);
		}
	}

	handleOnChange({ currentTarget }: SyntheticInputEvent<HTMLInputElement>): void {
		this.setLocalValue(currentTarget.value);
	}

	render() {
		const msg = "In order to save draft order you need to click Enter " +
			"button on your keyboard or somewhere out of input box " +
			"with your mouse once you change value of the input box";
		if(isMobile()){
			return (
				<DraftOrderInput 
					title={msg} 
					value={this.state.value} 
					type="tel" 
					autocomplete="off" 
					maxlength="3" 
					onBlur={this.handleOnBlur}
					onChange={this.handleOnChange}
					onKeyPress={this.handleKeyPress}
					maxValue={this.props.maxValue}
					data-id={_.get(this.props, "data-id", "")}
					is_compressed={this.props.is_compressed}
				/>
			);
		}
		return (
			<DraftOrderInput
				title={msg}
				value={this.state.value}
				onBlur={this.handleOnBlur}
				onChange={this.handleOnChange}
				onKeyPress={this.handleKeyPress}
				maxValue={this.props.maxValue}
				data-id={_.get(this.props, "data-id", "")}
				is_compressed={this.props.is_compressed}
			/>
		);
	}
}

export default DraftOrder;