// @flow
import * as React from "react";
import _ from "lodash";

import styled from "styled-components";
import { Field } from "redux-form";
import colors from "../../../assets/css/colors";

import { FormGroup, Label, LabelWrapper, Tooltip } from "../../Form";

import Locked from "../../../components/Icons/Locked";
import { BorderedIcon, FlexDiv } from "../../";
import { below } from "../../../assets/css/media";
import { BorderIconWrap } from "../GenericFormStyles";

const RadioSwitch = styled.div`
	display: flex;
	height: 40px;
	padding: 0;
	outline: 1px solid ${colors.form.borderColor};
	background-color: #ffffff;
	align-items: center;
	border-radius: 3px;
	margin-top: 5px;
	opacity: ${({ is_disabled }) => is_disabled ? ".5" : "1"};
	width: ${({ width }) => width ? width : "100%"};

	label {
		display: flex;
		justify-content: center;
		color: ${colors.form.textColor};
		padding: 9px 10px;
		margin: 0px 5px;
		cursor: pointer;
		opacity: ${({ is_disabled }) => is_disabled ? ".5" : "1"};
		border-radius: 3px;
		flex-basis: 50%;
		width: 150px;
		font-family: "SourceSansPro";
		font-size: 12px;
		font-weight: 600;
		letter-spacing: 0;
		line-height: 12px;
		text-align: center;		

		${below.desktop`
			padding: 0px;
			height: 30px;
			line-height: 30px;
		`}
	}
	
	.field-input {
		display: none;

		&:checked + label {
			background: ${colors.primary.accent};
			color: #ffffff;
		}

		&:disabled + label {
			opacity: .5;
		}
	}
`;

type Props = {
	label: string,
	name: string,
	is_disabled?: boolean,
	tooltip?: {
		title: string,
		description: string,
	},
	elements: Array<Object>,
	is_string_value?: boolean,
	width?: string,
};

export const FormRadio = ({
	label,
	name,
	elements,
	tooltip,
	is_disabled = false,
	is_string_value,
	width,
}: Props) => (
	<div>
		<LabelWrapper>
			<FlexDiv>
				{is_disabled ? (
					<BorderIconWrap>
						<BorderedIcon
							padding='0'
							hide_border={true}
							background={colors.buttons.baseColor}
							size={1}
							className="disable-icon"
						>
							<Locked size={1.1} color='#ffffff' />
						</BorderedIcon>
					</BorderIconWrap>
				) : ""}
				<Label htmlFor={name} is_disabled={is_disabled}>{label}</Label>
			</FlexDiv>
			{tooltip ? (<Tooltip {...tooltip} />) : null}
		</LabelWrapper>
		<FormGroup>
			<RadioSwitch is_disabled={is_disabled} width={width}>
				{
					_.map(elements, ({ value, text }, index) => {
						return (
							<React.Fragment key={`${name}_${value}`}>
								<Field
									parse={value => (is_string_value ? value : _.toInteger(value))}
									name={name}
									id={`${name}_${value}`}
									value={value}
									component='input'
									type='radio'
									key={index}
									disabled={is_disabled}
									className={"field-input"}
								/>
								<label
									htmlFor={`${name}_${value}`}
									key={index + 1}
								>
									{text}
								</label>
							</React.Fragment>
						);
					})
				}
			</RadioSwitch>
		</FormGroup>
	</div>
);

export default FormRadio;