import * as React from "react";
import styled from "styled-components";
import { ButtonPrimaryLink } from "../../../components";
import CalendarCheck from "../../../components/Icons/CalendarCheck";

const DraftEndSeasonStyled = styled.div`
	width: 100%;
	background-color: #F8F8FA;
	border-radius: 2px;
	height: 260px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	
	
	p {
		height: 40px;
		width: 434px;
		color: #1D4073;
		font-family: TitilliumUpright;
		font-weight: 400;
		font-size: 20px;
		line-height: 24px;
		text-align: center;
		margin: 20px 0 30px;
	}
`;

export const DraftEndSeason = () => (
	<DraftEndSeasonStyled>
		<CalendarCheck size={2} />
		<p>
			The AFL Fantasy season is now complete.
			You are unable to make any further adjustments to your team.
		</p>
		<ButtonPrimaryLink to="/classic/team">
			View your team
		</ButtonPrimaryLink>
	</DraftEndSeasonStyled>
);

export default DraftEndSeason;