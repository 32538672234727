// @flow
import styled from "styled-components";
import { Td } from "../../components/TableX";

export const TdYourTeam = styled(Td)`
	display: flex;
	align-items: center;
	margin: 5px 0 5px 5px;
	padding-right: 0;
`;

export default TdYourTeam;