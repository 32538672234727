// @flow
import React from "react";
import { connect } from "react-redux";
import * as _ from "lodash";
import * as numeral from "numeral";
import styled, { css } from "styled-components";
import { compose } from "redux";
import colors from "../../assets/css/colors";
import sticky_headers from "../../assets/css/sticky";
import JsonFetcher from "../../components/utils/JsonFetcher/index";
import * as selectors from "../../modules/selectors";
import * as actions from "../../modules/actions";
import * as format from "../../helpers/numeralFormats";


import {
	ButtonLoadMore,
	ClassicOffSeasonFilters,
	DraftTableHeader,
	Footer,
	OneColumnLayout as OneColumnLayoutUnstyled,
	PageTitle,
	PlayerInfo,
	ThStat as ThStatUnstyled,
	ThToolTip,
	StyledLabel,
	Tooltip,
	Select,
	withClassicPlayerModal,
} from "../../components";

import Table, { TBody, Td, Th as ThUnstyled, THead, Tr } from "../../components/TableX";

import ArrowSolid from "../../components/Icons/ArrowSolid";
import Lock from "../../components/Icons/Lock";

import type {
	TClassicTeam,
	TCoachStatsListReducer,
	TPlayer,
	TPlayerStatusesReducer,
	TPosition,
	TRootStore,
	TSquad,
	TRound
} from "../../modules/types";

import { below } from "../../assets/css/media";
import { renderTableHeaderCell } from "../../helpers/tables";


const SEASON_YEAR = process.env.REACT_APP_SEASON_YEAR || "";

const basic_stats = [
	{ key: "GP", prop: "stats.games_played", tooltip: "Games Played" },
	{ key: "AVG", prop: "stats.avg_points", tooltip: "Average Points" },
	{ key: "L3 AVG", prop: "stats.last_3_avg", tooltip: "Last 3 Average" },
	{ key: "L5 AVG", prop: "stats.last_5_avg", tooltip: "Last 5 Average" },
	{ key: "% OWN", prop: "stats.owned_by", tooltip: "Percentage Owned By"},
	{ key: "ADP", prop: "stats.adp", tooltip: "Average Draft Position"},
	{ key: "LOW", prop: "stats.low_score", tooltop: "Lowest Score"},
	{ key: "HIGH", prop: "stats.high_score", tooltip: "Highest Score"},
	{ key: "TP", prop: "stats.total_points", tooltip: "Total Points" },
	{ key: "Price", prop: "cost", tooltip: "Price" },
	{ key: "R$C", prop: "stats.cost_round_diff", tooltip: "Round Price Change" },
	{ key: "S$C", prop: "stats.cost_season_diff", tooltip: "Season Price Change" },
	{ key: "$/PT", prop: "stats.cost_divided_by_points", tooltip: "$ per Point" },
];

type TableColumn = {
	key: string, name?: string, prop: string, tooltip?: string, is_fc?: boolean, format?: string
};


const CostStatWrapper = styled.span`
	color: ${colors.primary.darkGrey};
	${({ is_positive }) => is_positive && `color: ${colors.success};`}
	${({ is_negative }) => is_negative && `color: ${colors.warning};`}
`;

const ThStat = styled(ThStatUnstyled)`
	width: 40px;
	${sticky_headers};
	@supports(position: sticky) {
		top: 50px;
	}
	
`;

const Th = styled(ThUnstyled)`
	text-align: center;

	${sticky_headers};
	@supports(position: sticky) {
		top: 50px;
	}
`;
const TdStat = styled(Td)`
	width: 40px;
`;

const TdPlayer = styled(TdStat)`
	width: 40%;
`;

const FcLink = styled.a`
	color: ${colors.coach};
	cursor: pointer;
`;
const AllStatsWrapper = styled.div`
	display: none;
	${below.tablet`
		display: block;
		margin-bottom: 0;
	`}
`;

const StatsTable = styled(Table)`
	border-collapse: separate;

	@media screen and (min-width: 768px) and (max-width: 900px) {
		th[rel="owned"], td[rel="owned"] {
			display: none
		}
		td {
			font-size: 11px;
		}

		span.show-desktop {
			display: none;
		}

		span.show-mobile {
			display: inline-block;
		}
	}
	td.mobile-stat, th.mobile-stat {
		display: none !important;
	}
	${below.tablet`
		td.mobile-stat, th.mobile-stat {
			display: table-cell;
		}
		td[rel='${({ mob_stat }) => mob_stat}'] ~ td[rel='${({ mob_stat }) => mob_stat}'],
		th[rel='${({ mob_stat }) => mob_stat}'] ~ th[rel='${({ mob_stat }) => mob_stat}'] {
			display: none;
		}
	`}

	${({ is_compressed }) => is_compressed && css`
		td[rel="action"] {
			> a, > button {
				height: 20px;
				width: 20px;
				> svg {
					height: 20px;
					width: 20px;
				}
			}
		}
	`}
`;

const OneColumnLayout = styled(OneColumnLayoutUnstyled)`
	.slide-fields > div:first-child {
		flex-basis: 0;
		display: none;
	}
	${below.desktop`
		> h1 {
			padding: 0;
			margin-left: 15px;
		}
	`}
	${below.tablet`
		.slide-fields > div:first-child {
			flex-basis: 100%;
			max-width: 100%;
			display: block;
			margin: 0;
		}
		.slide-fields > .default-container {
			display: none;
		}
	`}
`;

const tryConvertNumber = value => {
	try {
		return Number.parseFloat(value);
	}
	catch (e) {
		return value;
	}
};

const ZERO_ALLOWED_STATS = [
	"stats.break_even",
];
const isZero = (val, sort_by) => (
	_.eq(val, 0)
	&& _.findIndex(ZERO_ALLOWED_STATS, val => _.startsWith(sort_by, val)) === -1
);
const isStringOrZero = (val, sort_by) => (
	_.isString(val)
	|| isZero(val, sort_by)
	|| val === undefined
	|| _.isNaN(val)
);
const getResult = (condition, min, value, sort_by) => condition(value, sort_by) ? min : value;

type Props = {
	squads: Array<TSquad>,
	positions: Array<TPosition>,
	players: Array<TPlayer>,
	playerStatuses: TPlayerStatusesReducer,
	coach_stats_list: TCoachStatsListReducer,
	fetchOffSeasonPlayers: typeof actions.fetchOffSeasonPlayers,
	my_players: Array<number>,
	team: TClassicTeam,
	remaining_salary: number,
	actual_round: TRound,
	available_positions: Array<number>,
	is_team_started: number,
	addPlayer: Function,
	has_assistant_coach: boolean,
	data_loading_complete: boolean,
	fetchPlayers: typeof actions.fetchPlayers
};

type State = {
	players_show_limit: number,
	players_show_limit_step: number,
	sort_by: string,
	order_by_desc: boolean,
	filters: {
		search: string,
		by_squad: string,
		by_position: string,
		by_price: string,
		by_status: string,
		by_dpp: boolean,
		by_favourites: boolean,
		by_hide_your_players: boolean,
		by_year: string
	},
	compressed_view: boolean,
	active_stats_type: string,
	show_fantasy_coach_modal: boolean,
	mobile_stat: string,
};
class StatsCentreComponent extends React.Component<Props,State> {

	constructor(props) {
		super(props);
		_.bindAll(this, [
			"handleFilterChange",
			"handleLoadMoreClick",
			"isActiveTab",
			"onSortColumnChange",
			"orderByComparator",
			"getPlayerStat",
			"toggleFantasyCoachModal",
			"setMobileStat",
			"renderStat",
			"renderMobileStat",
			"onPresetChange",
			"tableColumnToCellInfo"
		]);
	}

	state = {
		players_show_limit: 50,
		players_show_limit_step: 20,
		sort_by: "stats.total_points",
		order_by_desc: true,
		filters: {
			search: "",
			by_squad: "",
			by_position: "",
			by_price: "",
			by_status: "",
			by_year: `${SEASON_YEAR}`,
			by_dpp: false,
			by_favourites: false,
			by_hide_your_players: false
		},
		compressed_view: false,
		show_fantasy_coach_modal: false,
		active_stats_type: "general-stats",
		mobile_stat: "stats.total_points",
	};

	componentDidMount() {
		// this.props.fetchOffSeasonPlayers();
		this.props.fetchPlayers();
	}

	shouldComponentUpdate(next_props) {
		return next_props.data_loading_complete;
	}

	toggleFantasyCoachModal() {
		const { show_fantasy_coach_modal } = this.state;
		this.setState({ show_fantasy_coach_modal: !show_fantasy_coach_modal });
	}

	get table_keys(): TableColumn[] {
		return basic_stats;
	}

	get has_filters() {
		const filter_keys = Object.keys(this.state.filters);
		const active_filters = filter_keys.filter(key => this.state.filters[key]);
		return active_filters.length !== 0;
	}

	filterChecker(is_what, what) {
		return is_what ? what : _.identity;
	}

	get players_filter() {
		const {
			search,
			by_squad,
			by_position,
			by_price,
			by_status,
			by_dpp,
			by_favourites,
			by_hide_your_players
		} = this.state.filters;

		const byPlayerStatus = _.matches({ status: by_status });

		const bySquadId = _.matches({ squad_id: parseInt(by_squad, 10) });

		const byFavourites = _.property("is_favourite");

		const byHideYourPlayers = _.property("not_owned_by_user");

		const byPosition = _.flow([
			_.property("positions"),
			_.partial(_.includes, _, parseInt(by_position, 10))
		]);

		const price_string_split = by_price.split("-");
		const byPrice = ({ cost }) => (price_string_split.length > 1) ? _.inRange(
			parseInt(cost, 10),
			parseInt(price_string_split[0], 10),
			parseInt(price_string_split[1], 10)
		) : "";

		const byDPP = ({ positions }) => _.eq(_.size(positions), 2);

		const byName = (({ first_name, last_name }) => (
			`${first_name} ${last_name}`.toLowerCase().includes(search.toLowerCase())
		));

		return _.overEvery([
			this.filterChecker(by_status, byPlayerStatus),
			this.filterChecker(by_squad, bySquadId),
			this.filterChecker(by_favourites, byFavourites),
			this.filterChecker(by_hide_your_players, byHideYourPlayers),
			this.filterChecker(by_position, byPosition),
			this.filterChecker(by_dpp, byDPP),
			this.filterChecker(by_price, byPrice),
			this.filterChecker(search, byName),
		]);
	}

	get players_unsliced() {
		const { players } = this.props;
		if(this.has_filters) {
			return players.filter(this.players_filter)
				.sort(this.orderByComparator);
		}
		return players.sort(this.orderByComparator);
	}

	get players() {
		const { players_show_limit } = this.state;
		const players_show = this.players_unsliced;
		return players_show.slice(0, players_show_limit);
	}

	get mobile_columns() {
		return [ this.state.mobile_stat ];
	}

	get tablet_columns() {
		const default_stats = [
			"stats.games_played",
			"stats.avg_points",
			"stats.last_3_avg"
		];

		return [
			...default_stats,
			_.includes(default_stats, this.state.mobile_stat)
				? "stats.total_points"
				: this.state.mobile_stat
		];
	}

	get current_mobile_stat() {
		const { mobile_stat } = this.state;
		return _.find(this.all_stats, { prop: mobile_stat });
	}

	get all_stats() {
		// const stats_with_dupes = _.reduce(
		// 	coach_player_stats,
		// 	(all, stats) => [...all, ...stats],
		// 	basic_stats
		// );

		return _(basic_stats)
			.uniqBy("prop")
			.uniqBy("key")
			.orderBy("is_fc", "desc")
			.value();
	}

	setMobileStat({ currentTarget }: Object) {
		const stat = currentTarget.value;

		this.setState({
			mobile_stat: stat,
		}, () => this.onSortColumnChange(stat));
	}

	getPlayerStat(player, prop, format_template = "0") {
		let value = _.get(player, prop, "--");

		if(prop === "cost") {
			return numeral(value).format(format.TWO_DEC_PRICE);
		}
		if (prop === "stats.cost_divided_by_points") {
			return numeral(value).format(format.DEC_PRICE);
		}
		if (_.includes(prop, "owned_by")) {
			return numeral(value).format("0");
		}
		if (_.includes(["stats.cost_season_diff", "stats.cost_round_diff"], prop)) {
			if (!value || !_.isNumber(value)) {
				return "--";
			}
			return <CostStatWrapper
				is_positive={value > 0}
				is_negative={value < 0}
			>
				{ numeral(value / 1000).format("$0.[0]") + "k" }
			</CostStatWrapper>;
		}
		return numeral(value).format(format_template);
	}

	orderByComparator(player_one, player_two) {
		const
			{ sort_by, order_by_desc } = this.state,
			order = order_by_desc ? 1 : -1,
			minimal = Number.MAX_SAFE_INTEGER * order,
			getResultOrMin = _.partial(getResult, isStringOrZero, minimal * -1, _, sort_by),
			getValue = player => tryConvertNumber(_.get(player, sort_by)),
			a_result = getResultOrMin(getValue(player_one)),
			b_result = getResultOrMin(getValue(player_two));

		if (a_result < b_result) {
			return order;
		}

		if (a_result > b_result) {
			return -1 * order;
		}

		return 0;
	}

	isActiveTab(sort_prop){
		const { sort_by } = this.state;
		return _.eq(sort_by, sort_prop) ? "active" : "";
	}

	onPresetChange({ currentTarget }: Object) {
		this.setState({
			active_stats_type: currentTarget.value,
			sort_by: "stats.avg_points"
		});
	}

	onSortColumnChange(column_value) {
		const { has_assistant_coach } = this.props;
		const { sort_by, order_by_desc } = this.state;

		const keys = this.table_keys;
		const stat = _.find(keys, { prop: column_value }, {});
		const can_sort = has_assistant_coach || !_.get(stat, "is_fc", false);

		if (!can_sort) {
			// The user cannot sort by this column
			return;
		}

		const default_order = !_.includes(
			["stats.adp", "stats.cost_divided_by_points", "stats.break_even"],
			column_value
		);

		this.setState({
			sort_by: column_value,
			order_by_desc: _.eq(sort_by, column_value) ? !order_by_desc : default_order,
		});
	}

	handleFilterChange(e) {
		const { filters } = this.state;
		const {
			by_year,
			...rest_filters
		} = this.formElementToKeyValueObject(e.currentTarget);

		this.setState({
			filters: {
				...filters,
				by_year,
				...rest_filters
			}
		});
	}

	handleLoadMoreClick() {
		const { players_show_limit, players_show_limit_step } = this.state;

		this.setState({
			players_show_limit: players_show_limit + players_show_limit_step,
		});
	}

	formElementToKeyValueObject(form) {
		return Array.from(form.elements).reduce(this.accumulateFormInputsValueByName, {});
	}

	accumulateFormInputsValueByName(accumulator: Object, formElement: Object) {
		const { type, name, checked, value } = formElement;

		if(_.eq(type, "submit")) {
			return accumulator;
		}

		if (!name) {
			throw new Error("All form inputs should be have \"name\" attribute!");
		}

		accumulator[name] = _.eq(type, "checkbox") ? checked : value;

		return accumulator;
	}

	tableColumnToCellInfo(column: TableColumn) {
		return {
			table: {
				order_by_desc: this.state.order_by_desc,
				sort_key: this.state.sort_by
			},
			cell: {
				...column,
				sort_key: column.prop,
				name: column.key,
				short_name: column.key,
				rel: column.prop,
				sortable: true,
				onClick: this.onSortColumnChange,
			}
		};
	}

	renderStatHeader({ prop, key, tooltip, is_fc }: TableColumn, className = "") {
		const { order_by_desc } = this.state;

		const is_active = this.isActiveTab(prop);
		const is_by_desc = prop === "stats.adp" ? !order_by_desc : order_by_desc;
		const is_top = is_active && !is_by_desc;
		const is_down = is_active && is_by_desc;

		return (
			<ThStat
				key={key}
				onClick={() => this.onSortColumnChange(prop)}
				is_fc={is_fc}
				rel={prop}
				className={`${className} ${this.isActiveTab(prop)}`}
			>
				<div className="highlighter" />
				<span className="column-name">
					{key}
				</span>
				{is_top ? <ArrowSolid direction="up" className="top" /> : null}
				{is_down ? <ArrowSolid direction="down" className='down' /> : null}
				{tooltip && <ThToolTip text={tooltip} />}
			</ThStat>
		);
	}

	renderStat({ is_fc, prop, format }: TableColumn, player: TPlayer, className?: string) {
		const { has_assistant_coach } = this.props;

		return <TdStat
			key={prop}
			rel={prop}
			is_fc={is_fc}
			className={className}
		>
			{is_fc && !has_assistant_coach ? (
				<FcLink onClick={this.toggleFantasyCoachModal}>
					<Lock color={colors.coach} />
				</FcLink>
			) : (
				<React.Fragment>
					{this.getPlayerStat(player, prop, format)}
				</React.Fragment>
			)}
		</TdStat>;
	}

	renderMobileStatHeader() {
		return renderTableHeaderCell(
			this.tableColumnToCellInfo(this.current_mobile_stat),
			ThStat,
			"mobile-stat"
		);
	}
	renderMobileStat(player: TPlayer) {
		return this.renderStat(this.current_mobile_stat, player, "mobile-stat");
	}

	renderAllStatsSelect() {
		const { mobile_stat } = this.state;
		return <AllStatsWrapper>
			<StyledLabel htmlFor="mobile_stat">
				Statistic
				<Tooltip
					title="Statistic"
					description="Change the statistic displayed in the table" />
			</StyledLabel>
			<Select
				name="mobile_stat"
				is_disabled={false}
				onChange={this.setMobileStat}
				value={mobile_stat}
			>
				{_.map(this.all_stats, ({ key, name, tooltip, prop, is_fc = false }) => (
					<option key={key} value={prop}>
						{name || tooltip || key} {is_fc && "(FC)"}
					</option>
				))}
			</Select>
		</AllStatsWrapper>;
	}

	renderTableHead() {
		return (
			<THead>
				<Tr>
					<Th rel="player">
						Player
					</Th>
					{_(this.table_keys)
						.map(this.tableColumnToCellInfo)
						.map(cell => renderTableHeaderCell(cell, ThStat))
						.value()
					}
					{ this.renderMobileStatHeader() }
				</Tr>
			</THead>
		);
	}

	renderTableBody() {
		const { compressed_view } = this.state;
		return (
			<TBody>
				{this.players.map(player => {
					return (
						<Tr
							key={player.id}
						>
							<TdPlayer rel="player">
								<PlayerInfo
									player={player}
									is_compressed={compressed_view}
									no_hover
									is_classic
								/>
							</TdPlayer>
							{ this.table_keys.map(stat => this.renderStat(stat, player)) }
							{ this.renderMobileStat(player) }
						</Tr>
					);
				})}
			</TBody>
		);
	}

	render() {
		const {
			squads,
			positions,
			playerStatuses,
		} = this.props;
		const {
			active_stats_type,
			compressed_view,
			players_show_limit,
			players_show_limit_step,
		} = this.state;

		const availableYears = _.range(SEASON_YEAR, 2014, -1);

		return (
			<React.Fragment>
				<JsonFetcher fetch={["squads", "players", "coach_players", "rounds", "venues"]} />

				<OneColumnLayout>
					<PageTitle>
						Stats Centre
					</PageTitle>
					<ClassicOffSeasonFilters
						handleChange={this.handleFilterChange}
						squads={squads}
						positions={positions}
						statuses={playerStatuses}
						years={availableYears}
					/>
					<DraftTableHeader
						title="All Players"
						selectOptions={[
							{ key: "general-stats",value: "General Stats" },
						]}
						onSelectChange={this.onPresetChange}
						active_stats_type={active_stats_type}
						is_compressed={compressed_view}
						onCompressedViewClick={bool => {
							this.setState({ compressed_view: bool });
						}}
						onPlayerShowLimitClick={num => {
							this.setState({ players_show_limit: num });
						}}
						players_show_limit={players_show_limit_step}
						show_fantasy_ad={false}
						hide_stats_list={true}
						panelArea={this.renderAllStatsSelect()}
					/>
					<StatsTable
						tablet_columns={this.tablet_columns}
						mobile_columns={this.mobile_columns}
						show_border_bottom={
							this.players_unsliced.length < this.state.players_show_limit
						}
						mob_stat={this.state.mobile_stat}
						is_compressed={compressed_view}
					>
						{this.renderTableHead()}
						{this.renderTableBody()}
					</StatsTable>
					{this.players_unsliced.length > players_show_limit && (
						<ButtonLoadMore onClick={this.handleLoadMoreClick}>
						Load more players
						</ButtonLoadMore>
					)}
				</OneColumnLayout>
				<Footer show_key />
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: TRootStore, props) => ({
	squads: selectors.squads.getSquads(state),
	positions: selectors.positions.getPositionsArray(state),
	playerStatuses: state.playerStatuses,
	players: selectors.playersForClassicPlayers(state, props),
	available_positions: selectors.getAvailableTeamPositions(state),
	has_assistant_coach: false,
	data_loading_complete: true
});
const mapDispatchToProps = {
	fetchOffSeasonPlayers: actions.fetchOffSeasonPlayers,
	fetchPlayers: actions.fetchPlayers,
};


const OffSeasonStatsCentre = compose(
	withClassicPlayerModal,
	connect(
		mapStateToProps,
		mapDispatchToProps
	)
)(StatsCentreComponent);

export default OffSeasonStatsCentre;