// @flow
import * as React from "react";
import { connect } from "react-redux";
import _ from "lodash";

import * as selectors from "../../../modules/selectors";
import type {TRound} from "../../../modules/types";
import { BYE_ROUNDS, PARTIAL_BYE_ROUNDS } from "../../../helpers";

type Props = {
	bye_rounds: Array<TRound>,
	is_classic: boolean
}

type State = {
	selected_rounds: number[],
	is_active: boolean,
	is_partial_or_standard: string
}

export const withByeDetector = (WrappedComponent: any) => {
	class WithByeDetector extends React.PureComponent<Props, State> {
		constructor(props) {
			super(props);

			_.bindAll(this, ["setActive", "toggleSelected", "setPartialActive"]);
		}

		state = {
			selected_rounds: [],
			is_active: false,
			is_partial_or_standard: ""
		};

		componentDidUpdate(prev_props: Props) {
			if (_.isEmpty(prev_props.bye_rounds) && !_.isEmpty(this.props.bye_rounds)) {
				this.setState({
					selected_rounds: []
				});
			}
		}

		get bye_round_ids() {
			return _.map(this.props.bye_rounds, "id");
		}

		toggleSelected(id: number) {
			const isPBD = PARTIAL_BYE_ROUNDS.includes(id);
			const filterOutGroup = isPBD ? BYE_ROUNDS: PARTIAL_BYE_ROUNDS;
			const partialOrStandard = isPBD ? "partial" : "standard";

			this.setState(state => {
				const { selected_rounds } = state;
				if(_.includes(selected_rounds, id) && !this.props.is_classic 
					&& selected_rounds.length === 1){
					return{
						is_active: false,
						selected_rounds: [],
						is_partial_or_standard: ""
					};
				}
				if (_.includes(selected_rounds, id)) {
					const selectedRemoved = selected_rounds.filter(round => round !== id);
					return {
						selected_rounds: selectedRemoved,
						is_partial_or_standard:partialOrStandard
					};
				}
				return {
					is_active: true,
					is_partial_or_standard:partialOrStandard,
					selected_rounds: [ ...selected_rounds.filter(roundId => 
						!filterOutGroup.includes(roundId)), id ]
				};
			});
		}

		setActive() {
			this.setState(state => {
				const { is_active, is_partial_or_standard } = state;
				const { is_classic } = this.props;
				const isActiveAlready = is_active && is_partial_or_standard === "standard";
				if(isActiveAlready){
					return{
						is_active: false,
						is_partial_or_standard: ""
					};
				}
				const isOppositeCurrentActive = is_active && is_partial_or_standard === "partial";
				const isActive = isOppositeCurrentActive || is_partial_or_standard === "";
				return {
					is_active: isActive,
					selected_rounds: is_classic ? BYE_ROUNDS : [],
					is_partial_or_standard: "standard"
				};
			});
		}
		setPartialActive() {

			this.setState(state => {
				const { is_active, is_partial_or_standard } = state;
				const { is_classic } = this.props;
				const isActiveAlready = is_active && is_partial_or_standard === "partial";
				if(isActiveAlready){
					return{
						is_active: false,
						is_partial_or_standard: ""
					};
				}
				const isOppositeCurrentActive = is_active && is_partial_or_standard === "standard";
				
				
				const isActive = (isOppositeCurrentActive || is_partial_or_standard === "");

				
				return {
					is_active: isActive,
					selected_rounds: is_classic ? PARTIAL_BYE_ROUNDS : [],
					is_partial_or_standard: "partial"
				};
			});
		}

		render() {
			const { bye_rounds, is_classic, ...rest } = this.props;
			const { selected_rounds, is_active, is_partial_or_standard } = this.state;

			return (
				<WrappedComponent
					{...rest}
					bye_rounds={this.bye_round_ids}
					bye_detector_selected={selected_rounds}
					bye_detector_active={is_active}
					is_partial_or_standard={is_partial_or_standard}
					setByeDetectorActive={this.setActive}
					setPartialActive={this.setPartialActive}
					toggleByeDetectorRound={this.toggleSelected}
				/>
			);
		}
	}

	const mapStateToProps = (state: Object) => {
		return {
			bye_rounds: selectors.rounds.getByeRounds(state),
		};
	};

	return connect(mapStateToProps)(WithByeDetector);
};

export default withByeDetector;