// @flow
import * as React from "react";
import _ from "lodash";
import { FantasyCoachModal } from "../../Modal";

type Props = {
}

type State = {
	modal_open: boolean
}

const withFantasyCoachModal = (WrappedComponent: any) => {
	class WithFantasyCoachModal extends React.PureComponent<Props, State> {
		constructor(props: Props) {
			super(props);

			_.bindAll(this, ["toggleModal"]);
		}

		state = {
			modal_open: false
		};

		toggleModal() {
			const { modal_open } = this.state;
			this.setState({
				modal_open: !modal_open
			});
		}

		render() {
			const { modal_open } = this.state;

			return <React.Fragment>
				{
					modal_open ?
						<FantasyCoachModal
							closeClick={this.toggleModal}
						/>
						: null
				}
				<WrappedComponent
					{...this.props}
					toggleFantasyCoachModal={this.toggleModal}
					fantasy_coach_modal_open={modal_open}
				/>
			</React.Fragment>;
		}
	};

	return WithFantasyCoachModal;
};

export default withFantasyCoachModal;