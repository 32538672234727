// @flow
import * as React from "react";
import styled from "styled-components";
import * as _ from "lodash";

import type { TPlayer } from "../../../../../modules/types";
import { StatusButton } from "../../../../../components";
import Injured from "../../../../../components/Icons/Injured";
import Bye from "../../../../../components/Icons/Bye";
import Keeper from "../../../../../components/Icons/Keeper";
import NotSelected from "../../../../../components/Icons/NotSelected";
import Uncertain from "../../../../../components/Icons/Uncertain";
import Available from "../../../../../components/Icons/Available";

const Wrapper = styled.div`
	border-radius: 2px;
	box-shadow: 0 0 5px 0 rgba(127,138,144,0.5);
	height: 100%;
	display: flex;
	align-items: center;
	flex-wrap: nowrap;
	color: #1D4073;
	overflow: hidden;
`;

const StatusWrapper = styled(StatusButton)`
	cursor: default;
	outline: none;
`;

const TeamItemLeftPart = styled.div`
	background: #FFF;
	width: 40%;
	flex: 1;
	position: relative;
	height: 100%;
	z-index: 2;
	
	${StatusWrapper} {
		margin: 0;
		position: absolute;
		right: 0;
		top: 50%;
		transform: translate(50%, -50%);
	}
	
	img {
		position: absolute;
		bottom: 0;
		left: 42px;
		width: 25px;
		display: block;
	}
`;

const TeamItemRightPart = styled.div`
	background: #FEFEFF;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 10px 0 20px;
	width: 60%;
	box-shadow: inset 18px 0 10px -10px rgba(0, 0, 0, .1);
	flex: 2;
	font-size: 12px;
	height: 100%;
	position: relative;
	z-index: 1;
	
	span {
		white-space: nowrap;
	}
`;

const PlayerName = styled.span`
	font-size: 14px;
	font-weight: bold;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
`;

const PickNumber = styled.div`
	background: #F8F8FA;
	position: relative;
	padding-left: 2px;
	width: 30px;
	min-width: 30px;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;

	&:after {
		content: '';
		position: absolute;
		width: 37%;
		height: 100%;
		left: 98%;
		top: 0;
		background-image: linear-gradient(105deg, #F8F8FA 40%, transparent 50%);
	}
`;

type Props = {
	player: TPlayer,
	pick: Number & String,
	is_keeper: boolean,
};

const statuses = {
	"injured": Injured,
	"is-bye": Bye,
	"not-playing": NotSelected,
	"uncertain": Uncertain,
	"available": Available,
};

const getStatusIcon = (status: string) => (
	statuses[status in statuses ? status : "available"]
);

export const TeamItem = ({ player, pick, is_keeper, ...rest }: Props) => {
	const StatusIcon = getStatusIcon(player.status);
	const playerFirstNameInitial = _.get(player, "first_name[0]");
	const playerFirstName = _.get(player, "first_name");
	const playerLastName =  _.get(player, "last_name");
	const playerPositionsName = _.get(player, "positions_name", []);
	return (
		<Wrapper {...rest}>
			<TeamItemLeftPart>
				<PickNumber>
					{is_keeper ? 
						<Keeper />
						: pick
					}
				</PickNumber>
				<img
					src={`${process.env.REACT_APP_PLAYERS_PATH + player.id}_450.webp`}
					alt={`${playerFirstNameInitial}. ${playerLastName}`}
				/>
				<StatusWrapper status={player.status}>
					<StatusIcon />
				</StatusWrapper>
			</TeamItemLeftPart>
			<TeamItemRightPart>
				<PlayerName>
					{`${playerFirstName} ${playerLastName}`}
				</PlayerName>
				<span>{ playerPositionsName.join(" / ")}</span>
			</TeamItemRightPart>
		</Wrapper>
	);
};

export default TeamItem;