// @flow
import React from "react";
import * as Sentry from "@sentry/react";
import styled from "styled-components";

type Props = {
	children: any,
	fallback?: any
}

type State = {
	error: boolean
}

// eslint-disable-next-line no-unused-vars
const FeedbackContainer = styled.div`
	margin: 100px auto;
	text-align: center;

	p {
		padding-bottom: 20px;
	}
`;

// eslint-disable-next-line no-unused-vars
const ReportFeedback = styled.a`
	border: 1px solid black;
	border-radius: 3px;
	padding: 3px;
	cursor: pointer;
	margin: 20px;
`;

export class ErrorBoundary extends React.Component<Props, State> {
	static getDerivedStateFromError(error: any) {
		return {
			error: true
		};
	}

	state = {
		error: false
	};

	componentDidCatch(error: Error, error_info: any) {
		Sentry.withScope(scope => {
			Object.keys(error_info).forEach(key => {
				scope.setExtra(key, error_info[key]);
			});
			Sentry.captureException(error);
		});

		this.setState({ error: true });
	}

	render() {
		const { fallback, children } = this.props;
		if (this.state.error) {
			//render fallback UI
			return fallback ? 
				<Sentry.ErrorBoundary 
					fallback={"An error has occurred"}
				>{fallback}</Sentry.ErrorBoundary> : null;
		}

		return children;
	}
}

export default ErrorBoundary;