// @flow
import * as React from "react";
import styled, { css } from "styled-components";
import _ from "lodash";
import * as numeral from "numeral";
import { Link, NavLink } from "react-router-dom";
import type { TPlayerWithFixture } from "../../modules/types";
import colors from "../../assets/css/colors";
import { StatusButton as StatusButtonUnstyled } from "..";
import { currencyFormat } from "../../utils/currencyFormat";
import Captain from "../Icons/Captain";
import ViceCaptain from "../Icons/ViceCaptain";
import Emergency from "../Icons/Emergency";
import Swap from "../Icons/Swap";
import Trash from "../Icons/Trash";
import Trade from "../Icons/Trade";
import Lock from "../Icons/Lock";
import { below } from "../../assets/css/media";
import CommandButton, { 
	getCommandButtonColors 
} from "../MyTeam/ListView/commandButton";
import { shouldHideTrash } from "../../pages/classic/myTeam/hideTrash";
import positionEmergencyButton from "../utils/positionEmergency";
import { isAnyTrue, isAllTrue } from "../../helpers";

const Positions = styled.p`
	text-transform: uppercase;
`;

const StatusButton = styled(StatusButtonUnstyled)`
	margin: 0;
	height: 10px;
	width: 10px;
`;

const FieldPlayerBackStyled = styled.div`
	padding: 5px 10px 0 10px;
	box-sizing: border-box;
	z-index: 3;
	position: relative;
	${below.field_view_mid`
		padding: 5px 5px 0;
	`};
`;

const NameBlock = styled.p`
	color: #1D4073;
	font-size: 12px;
	font-weight: 700;
	display: flex;
	align-items: center;
	button {
		margin-right: 5px;
	}
`;
const StatsBlock = styled.div`
	color: #1D4073;
	font-size: 10px;
	font-weight: 400;
	display: flex;
	align-items: center;
	margin: 6px 0;
	p {
		position: relative;
		&:after {
			content: '|';
			color: #CAD2D8;
			margin: 0 2px;
		}
		&:last-child {
			color: #7F8A90;
			&:after {
				content: '';
			}
		}
		position: relative;
		flex-basis: auto;
		height: 12px;
		overflow: hidden;
		text-overflow: ellipsis;
		${below.field_view_mid`
			font-size: 9px;
		`}
	}
	${below.field_view_mid`
		letter-spacing: -0.05em;
	`};
`;

const ButtonsBlock = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

/**
 * not sure we need background here
 * 
	2021-03-14
	?
 */
const ProjectedStats = styled.div`
	background: ${colors.coach};
	height: 20px;
	display: flex;
	align-items: center;
	border-radius: 1px;
	color: #fff;
	font-size: 10px;
	font-weight: 500;
	margin-bottom: 5px;
	width: 100%;
	padding: 0 5px;
	box-sizing: border-box;
	justify-content: center;
	p {
		margin-right: 4px;
		margin-bottom: -1px;
	}
`;

const CommandButtonLast = styled(CommandButton)`
	background: rgba(0,0,0,0);
`;

const InTeamButtonWrapper = styled.div`
  display: flex;
  button, a{
    margin-right: 3px;
  }
  :last-child{
    margin-right: 0px;
  }
`;

const TradeLink = styled(NavLink)`
	margin-left: ${({ trash }) => trash ? "0" : "auto"};
	background: rgba(0,0,0,0);
	display: ${({ hidden }) => hidden ? "none" : "flex"};
	border-radius: 50%;
	padding: 2px;

	transition: background-color 0.2s linear;
	> svg {
		transition: fill 0.2s linear;
    height: 20px;
    width: 20px;
	}

	&:hover {
		background-color: white;
		${({ trash }) => !trash && css`
			> svg {
				fill: #1b63a4;
			}
		`};
	}
`;

const FcLink = styled(Link)`
	color: white;
	text-decoration: none;
`;

const PlayerName = styled.span`
	cursor: pointer;
	margin-bottom: -2px;
`;

// const CommandButtonPosition = styled(CommandButton)`
// 	padding-top: 3px;
//     font-weight: bold;
// `;

const getPositions = player => {
	const positions = _.get(player, "positions_name", []);

	return _.map(positions, p => {
		if (p === "Ruck") {
			return "Ruc";
		}
		return p;
	}).join("/");
};

const getAverage = (player, isCustomScoring) => {
	const classicAvg = _.get(player, "stats.avg_points", "- -");
	const customAvg = _.get(player, "custom_stats.avg_points", "- -");
	const avg = isCustomScoring ? customAvg : classicAvg;

	return numeral(avg).format("00");
};

const getColor = (is_true, color_true, color_false) => {
	return is_true ? color_true : color_false;
};

type Props = {
	player: TPlayerWithFixture,
	is_bench: boolean,
	is_captain: boolean,
	is_vice: boolean,
	is_emergency: boolean,
	has_assistant_coach: boolean,
	removePlayer: Function,
	position: number | string,
	setCaptain: Function,
	setViceCaptain: Function,
	setEmergency: Function,
	is_team_started: boolean,
	substitutePlayer: Function,
	in_substitute: boolean,
	out_substitute: boolean,
	is_substitute: boolean,
	is_locked: boolean,
	openPopup: Function,
	is_first_round_with_partial_lockout: boolean,
	is_classic: boolean,
	trade_link: string,
	delist_link: string,
	has_captain: boolean,
	has_emergencies: boolean,
	setPlayerAction: Function,
	emergency_position: number,
	locked_personnel: any[],
	is_utility: boolean,
	utility_position: number,
  actual_round: Object,
  is_overflow?: boolean,
	customScoringEnabled?: boolean,
}
const hideSub = (is_substitute, in_substitute, out_substitute) => {
	return is_substitute && !in_substitute && !out_substitute;
};

export class FieldPlayerBack extends React.PureComponent<Props> {
	renderStatsBlock() {
		const { player, is_classic } = this.props;

		return <StatsBlock>
			<Positions>
				{getPositions(player)}
			</Positions>
			<p>
				{_.get(player.competition, "symbol")}
				{" "}
				{_.get(player.competition, "opponent.short_name")}
			</p>
			<p>
				{getAverage(player, Boolean(this.props.customScoringEnabled))}
			</p>
			{is_classic && <p>
				{currencyFormat({ input: player.cost })}
			</p>}
		</StatsBlock>;
	}
	renderProjStatsBlock() {
		const { player, is_classic, has_assistant_coach } = this.props;
		return <ProjectedStats>
			{has_assistant_coach ? (
				<React.Fragment>
					<p>
						Proj {_.get(player, "stats.proj_score", "- -")}
					</p>
					<p>
						Bye {_.get(player, "stats.bye_round_id", "- -")}
					</p>
					{is_classic && <p>
						BE {_.get(player, "stats.break_even", "- -")}
					</p>}
				</React.Fragment>
			) : (
				<p>
					<FcLink to="/coach-subscription">
						<Lock /> {/*Subscribe to Fantasy Coach*/}
					</FcLink>
				</p>
			)}

		</ProjectedStats>;
	}
	/**
	 * Once a player is locked, their emergency buttons
	 * only display if they are selected
	 */

	isEmergencyButtonHidden(emg_pos: number) {
		const {
			player,
			is_bench,
			is_emergency,
			is_substitute,
			is_locked,
			has_emergencies,
			emergency_position,
			locked_personnel,
		} = this.props;

		/**
		 * In general, emergency buttons are hidden:
		 *   - when substituing,
		 *   - when the player is not on the bench
		 *   - when the league doesn't use emergencies
		 *   - when the current emergency at this position is locked
		 */
		const selected = is_emergency && (emergency_position === emg_pos || emg_pos === 0);
		const player_pos = _.get(player, ["positions", 0], 0);
		const emergency_locked = (
			!selected &&
			locked_personnel.includes(`emergency.${emg_pos || player_pos}`)
		);
		const base_hidden = isAnyTrue([
			is_substitute, !is_bench, !has_emergencies, emergency_locked
		]);

		if (!is_locked) {
			return base_hidden;
		}

		return base_hidden || !selected;
	}
	renderEmergencyButtons(btn_colors: Object) {
		const {
			player,
			setEmergency,
			is_classic,
			player: { positions, id },
			is_utility,
			utility_position,
			is_emergency,
		} = this.props;

		const is_utility_case = is_utility && positions && positions.length > 1;

		if (!is_classic) {
			return this.renderDraftEmergencies(btn_colors);
		}

		if (is_utility_case) {
			return _.map(positions, position_id => {
				const is_this_is_position =
					parseInt(utility_position, 0) === parseInt(position_id, 0) && is_emergency;

				return (
					<CommandButton
						background={is_this_is_position ? "#B55DAE" : "#CAD2D8"}
						color="#F8F8FA"
						hover_bg="#B55DAE"
						hover_color="#FFFFFF"
						onClick={setEmergency}
						data-id={id}
						data-position_id={position_id}
						key={position_id}
						data-is_emergency={is_emergency}
						data-is_utility={true}
					>
						{positionEmergencyButton(position_id)}
					</CommandButton>
				);
			});
		}
		else {
			return <CommandButton
				{...btn_colors.emergency}
				onClick={setEmergency}
				data-id={player.id}
				is_hidden={this.isEmergencyButtonHidden(0)}
				data-is_emergency={is_emergency}
				data-is_utility={false}
				data-is_this_is_position={false}
			>
				<Emergency size={1} />
			</CommandButton>;
		}
	}
	renderDraftEmergencies(btn_colors: Object) {
		const {
			player,
			setEmergency,
			is_emergency,
			emergency_position,
			is_overflow
		} = this.props;

		const positions = _.get(player, "positions", []);
		const base_color = "#CAD2D8",
			  base_bg = "#F8F8FA",
			  active_color = "#fff";

		/**
		 * If a draft player is DPP, should show a button for each position
		 */
		if(is_overflow){
			return null;
		}
		if (positions.length > 1) {
			return _.map(positions, pos_id => {
				const selected = is_emergency && pos_id === emergency_position;
				const emg_colors = {
					background: getColor(selected, "#B55DAE", base_bg),
					color: getColor(selected, active_color, base_color),
					hover_bg: getColor(selected, base_bg, "#B55DAE"),
					hover_color: getColor(selected, base_color, active_color),
				};

				return <CommandButton
					key={pos_id}
					{...emg_colors}
					onClick={setEmergency}
					data-id={player.id}
					data-position={pos_id}
					is_hidden={this.isEmergencyButtonHidden(pos_id)}
					noMargin={true}
				>
					{ positionEmergencyButton(pos_id) }
				</CommandButton>;
			});
		}
		
		return <CommandButton {...btn_colors.emergency}
			onClick={setEmergency}
			data-id={player.id}
			is_hidden={this.isEmergencyButtonHidden(0)}
			noMargin={true}
		>
			<Emergency size={1} />
		</CommandButton>;
	}
	/**
	 * Once a player is locked, their captain buttons (i.e. C, VC)
	 * only display if they are selected
	 */

	isCaptainButtonHidden(type: 'C' | 'VC') {
		const {
			is_bench,
			is_captain,
			is_vice,
			is_substitute,
			is_locked,
			has_captain,
			locked_personnel,
			is_overflow
		} = this.props;

		/**
		 * In general, captain buttons are hidden:
		 *   - when substituing,
		 *   - when the player is on the bench
		 *   - when the league doesn't use captains
		 *   - when the captain/vc is already locked
		 */
		if(is_overflow){
			return true;
		}
		const selected = isAllTrue([type === "C", is_captain])  
			|| isAllTrue([type === "VC", is_vice]);
		const cap_locked = (
			!selected
			&& locked_personnel.includes(type === "C" ? "captain" : "vice_captain")
		);
		
		const base_hidden = isAnyTrue([is_substitute, is_bench, !has_captain, cap_locked]);

		if (!is_locked) {
			return base_hidden;
		}

		return base_hidden || !selected;
	}
	renderCaptainButtons(btn_colors: Object) {
		const {
			player,
			setCaptain,
			setViceCaptain,
		} = this.props;
		const hide_cap = this.isCaptainButtonHidden("C");
		const hide_vice = this.isCaptainButtonHidden("VC");

		return <React.Fragment>
			<CommandButton {...btn_colors.captain}
				onClick={setCaptain}
				data-id={player.id}
				data-locked={hide_cap}
				is_hidden={hide_cap}
				aria-label="Set Captain from from field"
			>
				<Captain size={1} />
			</CommandButton>
			<CommandButton {...btn_colors.vice}
				onClick={setViceCaptain}
				data-id={player.id}
				data-locked={hide_vice}
				is_hidden={hide_vice}
				aria-label="Set Vice Captain from from field"
			>
				<ViceCaptain size={1} />
			</CommandButton>
		</React.Fragment>;
	}

	renderButtonsBlock() {
		const {
			player,
			is_bench,
			is_captain,
			is_vice,
			is_emergency,
			position,
			substitutePlayer,
			in_substitute,
			out_substitute,
			is_substitute,
			is_locked,
			is_classic,
			is_utility,
			is_overflow
		} = this.props;

		const btn_colors = getCommandButtonColors(
			is_emergency, 
			is_captain, 
			is_vice,
			"#CAD2D8", 
			"#F8F8FA", 
			"#fff"
		);
		const hide_sub = is_locked ? true :
			hideSub(is_substitute, in_substitute, out_substitute);
		const isLockedClassic = is_classic && is_locked;
		return <ButtonsBlock>
			<InTeamButtonWrapper>
          
				{is_bench 
					? this.renderEmergencyButtons(btn_colors)
					: this.renderCaptainButtons(btn_colors)}

				<CommandButton
					aria-label="Swap Player from from field"
					onClick={substitutePlayer}
					data-id={player.id}
					data-position={position}
					data-is_bench={is_bench}
					data-is_utility={is_utility}
					is_hidden={hide_sub || isLockedClassic || is_overflow}
					{...btn_colors.base}
					background="#fff"
				>
					<Swap size={1} />
				</CommandButton>
			</InTeamButtonWrapper>
			
			<InTeamButtonWrapper>
				{is_classic
					? this.renderClassicTradeButtons()
					: this.renderDraftTradeButtons()
				}
			</InTeamButtonWrapper>
			
		</ButtonsBlock>;
	}
	renderClassicTradeButtons() {
		const {
			player,
			is_bench,
			is_captain,
			is_vice,
			is_emergency,
			removePlayer,
			position,
			is_team_started,
			is_substitute,
			is_locked,
			is_first_round_with_partial_lockout,
			actual_round
		} = this.props;

		const btn_colors = getCommandButtonColors(
			is_emergency, 
			is_captain, 
			is_vice, 
			"#CAD2D8", 
			"#F8F8FA", 
			"#fff"
		);
		const is_locked_or_in_sub = is_substitute || is_locked;

		const hasTeamPlayedInRound = () => {
			let match = {};
			actual_round.matches.forEach(fixture => {
				if(player.squad_id === fixture.home_squad_id || 
          player.squad_id === fixture.away_squad_id){
					match = fixture;
				}
			});
			return (match.status === "complete" && actual_round.status === "active");
		};
		// const is_players_team_locked = player.is_playing_now 
		const is_team_live = player.is_playing_now || hasTeamPlayedInRound();
		const hide_trade = is_locked_or_in_sub;
		const hide_trash = is_team_live ? true : shouldHideTrash(
			is_substitute, 
			is_locked, 
			is_team_started, 
			is_first_round_with_partial_lockout
		);

		return <React.Fragment>
			{is_team_started && !is_first_round_with_partial_lockout ? (
				<TradeLink
					hidden={hide_trade}
					{...btn_colors.base}
					to={`/classic/advanced-trades/${_.get(player, "id", "")}`}
				>
					<Trade size={1} />
				</TradeLink>
			) : (
				<CommandButtonLast 
					onClick={() => removePlayer({
						id: player.id,
						is_bench,
						is_captain,
						is_vice,
						is_emergency,
						position
					})}
					is_hidden={hide_trash}
					{...btn_colors.base}
					trash
				>
					<Trash size={1} color="#D92425" />
				</CommandButtonLast>
			)}
		</React.Fragment>;
	}
	renderDraftTradeButtons() {
		const {
			is_captain,
			is_vice,
			is_emergency,
			is_team_started,
			is_substitute,
			is_locked,
			is_first_round_with_partial_lockout,
			setPlayerAction,
			player,
		} = this.props;

		const btn_colors = getCommandButtonColors(
			is_emergency, 
			is_captain, 
			is_vice, 
			"#CAD2D8", 
			"#F8F8FA", 
			"#fff"
		);

		/*
		removed in AFD21-78 to allow for editing locked players.
		const is_locked_or_in_sub = is_substitute || is_locked;
		const hide_trade = is_locked_or_in_sub;
		*/
		const hide_trash = shouldHideTrash(
			is_substitute, 
			is_locked, 
			is_team_started, 
			is_first_round_with_partial_lockout,
			true
		);

		return <React.Fragment>
			<CommandButtonLast 
				onClick={() => setPlayerAction(player.id, "trade")}
				is_hidden={is_substitute}
				{...btn_colors.base}
			>
				<Trade size={1} />
			</CommandButtonLast>
			<CommandButton
				is_hidden={hide_trash}
				{...btn_colors.base}
				onClick={() => setPlayerAction(player.id, "delist")}
				trash
			>
				<Trash size={1} color="#D92425" />
			</CommandButton>
		</React.Fragment>;
	}
	renderNameBlock() {
		const { player, openPopup } = this.props;
		return <NameBlock>
			<StatusButton status={player.status} is_compressed is_bye={player.is_bye}/>
			<PlayerName onClick={openPopup}>
				{_.get(player, "first_name", [])[0]} {player.last_name}
			</PlayerName>
		</NameBlock>;
	}

	render() {

		return <FieldPlayerBackStyled>
			{ this.renderNameBlock() }
			{ this.renderStatsBlock() }
			{ this.renderProjStatsBlock() }
			{ this.renderButtonsBlock() }
		</FieldPlayerBackStyled>;
	};
};

export default FieldPlayerBack;