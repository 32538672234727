// @flow
import _ from "lodash";
import * as React from "react";
import styled from "styled-components";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import smoothscroll from "smoothscroll-polyfill";
import { above, below } from "../../../../assets/css/media";
import * as selectors from "../../../../modules/selectors";
import type { 
	TPlayersById, 
	TRootStore, 
	TRound, 
	TUser, 
	TPLeague, 
	TDraftTeamsById 
} from "../../../../modules/types";
import PlayerInfo from "../../../../components/PlayerInfo";
import AddPlayer from "../../../../components/Icons/AddPlayer";
import Trade from "../../../../components/Icons/Trade";
import Search from "../../../../components/Icons/Search";
import More from "../../../../components/Icons/More";
import Close from "../../../../components/Icons/Close";
import DelistCircle from "../../../../components/Icons/DelistCircle";
import { Select } from "../../../../components/Form";
import { CommandButton } from "../../../../components";
import {
	SearchField,
	SearchInputWrapper,
	SearchSubmitButton,
} from "../../../../components";
import { isMobile } from "../../../../utils";
import { getIsRegenerated } from "../../../../helpers";

smoothscroll.polyfill();


const StyledSearchSubmitButton = styled(SearchSubmitButton)`
	${below.phone`
		right: 50px;
	`}
`;

const StyledSearchInputWrapper = styled(SearchInputWrapper)`
	margin-right: 10px;
	position: relative;

	${above.tablet`
		min-width: 200px;
		width: 260px;
		margin-top: 5px;
	`};	

	${below.tablet`
		max-width: 95%;
	`};	
`;
const StyledCommandButton = styled(CommandButton)`
	> svg{
		height: 100%;
		width: 100%;

		&.filter{
			filter: invert(96%) sepia(8%) saturate(89%) hue-rotate(155deg) brightness(88%) 
				contrast(96%);
		}
	}

	${above.tablet`
		display: none;
	`}

	height: auto;
	width: 25px;
	margin-left: 20px;
`;

const SelectWrapper = styled.form`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-top: 20px;
	margin-bottom: 20px;

	.changed{
		background-color: #F0F3F7;
		color: #1D4073;
	}

	${below.tablet`
		flex-wrap: wrap;
		> div:not(:first-child) {
			flex-basis: 100%;
			margin-bottom: 10px;
		}
	`};
	
	span.resetFilters{
		max-width: 122px;
		color: #D92425;
		font-size: 12px;
		cursor: pointer;

		${below.tablet`
			margin-top: 32px;
		`}

		${above.desktop`
			line-height: 45px;
		`}
	}
`;

const SelectFiltersWrapper = styled.div`
	${below.tablet`
		display: flex;
		flex-flow: column;

		> div{
			margin-right: 0;
			min-width: 100%;
			max-width: 100%;
			max-width: 100%;
		}
	`}

	${above.tablet`
		display: flex;
		flex-flow: row;
		align-items: center;

		> div {
			min-width: 120px;
			max-width: 120px;
			margin-right: 10px;
		}
		.stats_type {
			max-width: 225px;
		}
	`}
`;

const Error = styled.div`
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-transform: uppercase;
`;



const ContentWrapper = styled.div`
	padding-bottom: 40px;
`;

const DraftTurnItem = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	
	&:nth-child(odd) {
		background: #F8F8FA;
	}
	
	& > div {
		box-sizing: border-box;
		flex: 1;
	}
`;

const DraftTurnInfo = styled.div`
	 min-width: 26%;
	 max-width: 26%;
	 text-align: center;
	 
	 p {
		color: #1D4073;
		font-family: SourceSansPro;
		font-size: 14px;
		font-weight: 700;
		margin-bottom: 3px;
	 }
	 
	 span {
	 	color: #1D4073;
		font-family: SourceSansPro;
		font-weight: 400;
	 	font-size: 12px;
	 }
`;

const PlayerTradeButtons = styled.div`
	min-width: 14%;
	max-width: 14%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	margin-right: 10px;

	
	svg {
		width: 25px;
		height: 25px;
	}
	
	a {
		display: inline-block;
		margin-left: 20px;
		
		&:first-child {
			margin-left: 0;
		}
	}
`;


type Props = {
	league: TPLeague,
	error: string,
	teams_by_id: TDraftTeamsById,
	draft_order: Array<Array<Object>>,
	current_pick: number,
	picks: Array<Object>,
	players_by_id: TPlayersById,
	user: TUser,
	actual_round: TRound,
	show_keepers: boolean,
}

type State = {
	current_round_id: number,
	filters: {
		search: string,
		team: number,
		round: number,
	},
	hideFilters: boolean,
}

class DraftOrderWidgetContainer extends React.Component<Props, State> {
	static defaultProps = {
		current_pick: 1
	};

	constructor(props: Props, state: State) {
		super(props, state);

		_.bindAll(this, [
			"onChangeRound",
			"onFilterChange"
		]);

		this.scrollLeft = this.onClickScrollControl.bind(this, -1);
		this.scrollRight = this.onClickScrollControl.bind(this, 1);
		this.goToTradeInPlayer = this.getLinkToDraftTradePlayerPage.bind(this, "add");
		this.goToTradeOutPlayer = this.getLinkToDraftTradePlayerPage.bind(this, "trade");
		this.goToTradeDelistPlayer = this.getLinkToDraftTradePlayerPage.bind(this, "delist");
	}

	state = {
		current_round_id: 1,
		filters: {
			search: "",
			team: 0,
			round: 0
		},
		hideFilters: isMobile()
	};

	componentDidUpdate(prevProps, prevState) {
		const
			league_is_diff = !_.eq(this.props.league, prevProps.league),
			round_is_diff = !_.eq(this.state.current_round_id, prevState.current_round_id);

		if (league_is_diff) {
			this.onChangeRound(1);
		}

		if(round_is_diff) {
			this.updateSliderPosition();
		}
	}

	getLinkToDraftTradePlayerPage(type, player) {
		const { league } = this.props;
		return `/draft/league/${league.id}/players/${player.id}/${type}`;
	}

	scrollLeft: Function;
	scrollRight: Function;
	list = React.createRef();
	goToTradeInPlayer: Function;
	goToTradeOutPlayer: Function;
	goToTradeDelistPlayer: Function;

	onChangeRound(id) {
		this.setState({ current_round_id: parseInt(id, 10) });
	}

	toggleFilters() {
		this.setState({
			hideFilters: !this.state.hideFilters
		});
	}

	handleSubmit(e) {
		e.preventDefault();
	}

	onFilterChange(e) {
		const { name, value } = e.target;

		if(name === "search"){
			this.setState({
				filters: {
					...this.state.filters,
					search: value
				}
			});
		}
		else{
			this.setState({
				filters: {
					...this.state.filters,
					[e.target.name]: Number(value),
				}
			});
		}
	};

	findKeptPlayersOwner(player) {
		const { teams_by_id } = this.props;
		const teamsArr = Object.keys(teams_by_id).map(teamID => teams_by_id[teamID]);
		const ownerTeam = teamsArr.find(team => {
			const team_keepers =	_.get(team, "keepers") !== null ? _.get(team, "keepers") : [];
			return team_keepers.find(playerA => playerA.id === player.id);
		});
		return _.get(ownerTeam, "id", 0);
	}
	findKeeperPlayerRound(player) {
		const acquiredType = player.acquired_type;
		const roundFetch = acquiredType.split(" ")[1];
		if(Number(roundFetch)){
			return Number(roundFetch);
		}
		return 1000;
	}

	get kept_players() {
		const { teams_by_id, league } = this.props;
		const { keeper, regenerated_from, uf_regenerated_from } = league;
		const isRegenerated = getIsRegenerated(regenerated_from,uf_regenerated_from);
		const isKeeperandRegen = Boolean(keeper) && isRegenerated;
		if(!isKeeperandRegen){
			return [];
		}
		const teamsKeeperPlayers = Object.keys(teams_by_id).map(teamID => {
			let keeperPlayers = _.get(teams_by_id[teamID], "keepers", []);
			if(keeperPlayers !== null){
				keeperPlayers.sort((playerA, playerB) => {
					return this.findKeeperPlayerRound(playerA) > 
						this.findKeeperPlayerRound(playerB) 
						? 1 : -1;
				});
			}
			return keeperPlayers;
		}).flat().filter(playerObj => !_.isEmpty(playerObj));

		// If team filter active, filter kept player by owner

		teamsKeeperPlayers.forEach(player => {
			player.owner = this.findKeptPlayersOwner(player);
		});
		
		return teamsKeeperPlayers;

	}

	get turns() {
		const { filters: { round, team } } = this.state;
		const { 
			draft_order,
			league,
			players_by_id,
			teams_by_id
		 } = this.props;
		const draftHistory = _.get(league, "draft_history",[]);
		const numTeams = _.get(league,"num_teams",0);
		const flattenedDraftOrder = draftHistory.length ? 
			draftHistory.map((item,turnIndex) => {
				const itemSelection = Object.keys(item).map(subItem => ({
					team_id:Number(subItem),
					player_id:item[subItem]
				}))[0];
				const teamDetails = _.get(teams_by_id,itemSelection.team_id,null);
				const pickNo = turnIndex + 1;
				return {
					...teamDetails,
					player_id:_.get(itemSelection,"player_id",0),
					pick:pickNo,
					round: Math.floor(turnIndex/numTeams) + 1
				};
			}) : draft_order.flat(1);
		const turns = flattenedDraftOrder.filter(item => item.round === round);

		// if round selected use limited draft order, if not use all
		const turnsToUse = round !== 0 ? turns : flattenedDraftOrder;

		const teamFilteredTurns = turnsToUse.filter(turn => turn.id === team);

		const filteredTeams = team === 0 ? turnsToUse : teamFilteredTurns;

		return filteredTeams.map(turn => {
			const player = _.get(players_by_id, turn.player_id);
			return { 
				...turn,
				player
			};
		});
	}

	get content() {
		const { error, show_keepers, teams_by_id } = this.props;

		if(error) {
			return <Error>{error}</Error>;
		}

		const turns = this.turns;
		const playerFeed = show_keepers ? this.kept_players : turns;


		const { user } = this.props;
		const search = this.state.filters.search;

		let searchFiltered = playerFeed.filter(turn => {
			const player = show_keepers ? turn : _.get(turn, "player");
			const playerFirstName = _.get(player, "first_name", "");
			const playerLastName = _.get(player, "last_name", "");
			const fullName = `${playerFirstName} ${playerLastName}`.toLowerCase();

			const isIncludedSearch = fullName.includes(search.toLowerCase());
			return isIncludedSearch;
		});

		if(_.isEmpty(turns) || _.isEmpty(_.first(turns).player)) {
			return <Error>Loading, please wait...</Error>;
		}
		
		if(_.isEmpty(searchFiltered)){
			return <Error>No players found</Error>;
		}

		if(show_keepers){
			// Get year and position the player was drafted from draft_pick_history new field
			// If werent picked at any point return Aquired mid season in what year?
			const team = this.state.filters.team;
			return searchFiltered.map(playerObj => {
				if(!playerObj || (team !== 0 && playerObj.owner !== team)) {
					return null;
				}
				const ownerTeamName = teams_by_id[playerObj.owner].name;
				return (
					<DraftTurnItem key={playerObj.id}>
						<DraftTurnInfo>
							<p>{playerObj.acquired_year} - {playerObj.acquired_type}</p>
							<span>
								{ownerTeamName}
							</span>
						</DraftTurnInfo>
						<PlayerInfo player={playerObj} is_classic={false} />
						<PlayerTradeButtons>
							{ this.getTradeButtons(playerObj) }
						</PlayerTradeButtons>
					</DraftTurnItem>
				);
			});
		}
		return searchFiltered.map(turn => {
			const player = _.get(turn, "player");
			player.owner = {user_id: turn.user_id};
			if(!player) {
				return null;
			}
			return (
				<DraftTurnItem key={turn.pick}>
					<DraftTurnInfo>
						<p>Pick {turn.pick}</p>
						<span>{ _.eq(turn.user_id, user.id) ? "You" : turn.name}</span>
					</DraftTurnInfo>
					<PlayerInfo player={player} is_classic={false} />
					<PlayerTradeButtons>
						{ this.getTradeButtons(player) }
					</PlayerTradeButtons>
				</DraftTurnItem>
			);
		});
	}

	isDraftTradeAvailable(player) {
		const
			{ actual_round, league } = this.props,
			{ lockout } = league;

		if (league.draft_status !== "complete") {
			return false;
		}

		if (league.status === "scheduled") {
			return true;
		}

		if (!lockout && actual_round.status !== "scheduled") {
			return false;
		}

		return !_.get(player, "locked");
	}

	getTradeButtons(player) {
		const
			{ user } = this.props,
			{ owner } = player,
			is_user_team = _.get(owner, "user_id") === user.id;

		if (!this.isDraftTradeAvailable(player)) {
			return null;
		}

		if (is_user_team) {
			return (
				<React.Fragment>
					<Link to={this.goToTradeDelistPlayer(player)}>
						<DelistCircle />
						{/* <IconCircled>
							<Delist />
						</IconCircled> */}
						
					</Link>
					<Link to={this.goToTradeOutPlayer(player)}>
						<Trade />
					</Link>
				</React.Fragment>
			);
		}

		if (owner) {
			return (
				<Link to={this.goToTradeOutPlayer(player)}>
					<Trade />
				</Link>
			);
		}

		return (
			<Link to={this.goToTradeInPlayer(player)}>
				<AddPlayer />
			</Link>
		);
	}

	onClickScrollControl(direction){
		const new_round = Math.max(
			1,
			Math.min(
				this.state.current_round_id + direction,
				_.size(this.props.draft_order)
			)
		);

		this.setState({ current_round_id: new_round });
	}

	updateSliderPosition(){
		const
			round_id = this.state.current_round_id,
			first_elem = document.getElementById("pick-item-control-1"),
			curr_element = document.getElementById(`pick-item-control-${round_id}`),
			list = this.list.current;

		if(curr_element && first_elem && list) {
			const
				start_offset = curr_element.offsetLeft - first_elem.offsetLeft,
				offset = start_offset - (list.clientWidth - curr_element.clientWidth) / 2;

			try {
				list.scroll({
					top: 0,
					left: offset,
					behavior: "smooth",
				});
			}
			catch(err) {
				list.scrollLeft = offset;
			}
		}
	}
	
	get filters() {
		const { filters, hideFilters } = this.state;
		const { teams_by_id, draft_order, show_keepers } = this.props;
		const
			round_ids = draft_order.map((round, index) => index + 1);
		
		return(
			<SelectWrapper onChange={this.onFilterChange} onSubmit={this.handleSubmit}>
				<StyledSearchInputWrapper>
					<SearchField
						type="text"
						name="search"
						component="input"
						placeholder="Search for a player"
						value={filters.search}
					/>
					<StyledSearchSubmitButton type="submit">
						<Search />
					</StyledSearchSubmitButton>
					<StyledCommandButton background="none" onClick={() => this.toggleFilters()}>
						{hideFilters ? <More /> : <Close className="filter" />}
					</StyledCommandButton>
				</StyledSearchInputWrapper>
				{!hideFilters && 
					<SelectFiltersWrapper>
						<div>
							<Select 
								name="team" 
								is_disabled={false} 
								value={filters.team}
							>
								<option value={0}>
									All Teams
								</option>
								{Object.keys(teams_by_id).map(id => (
									<option key={id} value={id}>{teams_by_id[id].name}</option>
								))}
							</Select>
						</div>
						{!show_keepers && 
							<div>
								<Select
									readOnly
									is_disabled={false} name="round" 
									value={filters.round}
								>
									<option value={0}>
									All Rounds
									</option>
									{round_ids.map((id, index) => (
										<option key={id} value={id}>{id}</option>
									))}
								</Select>
							</div>
						}
						
					</SelectFiltersWrapper>
				}
				
			</SelectWrapper>
		);

	};


	render() {
		return (
			<div>
				{ this.filters }
				<ContentWrapper>
					{ this.content }
				</ContentWrapper>
			</div>
		);
	}
}

const mapStateToProps = (state: TRootStore, props: Props) => ({
	user: selectors.getUser(state),
	league: selectors.leagueDraft.getLeague(state, props),
	error: _.get(state, "leagues.show_order.error", ""),
	teams_by_id: selectors.getTeamsById(state),
	draft_order: selectors.getChunkedDraftOrder(state, props),
	players_by_id: _.keyBy(
		selectors.players.getExtendedPlayersArrayWithOwners(state, props),
		"id"
	),
	actual_round: selectors.rounds.getActualRound(state),
});

export const DraftOrderWidget = withRouter(connect(
	mapStateToProps,
)(DraftOrderWidgetContainer));

export default DraftOrderWidget;