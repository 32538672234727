// @flow
import * as React from "react";

import { Field } from "redux-form";
import { FormField, FormSelect, FormRadio } from "../../../../../components";
import type {TRound} from "../../../../../modules/types";
import { LEAGUE_SIZE } from "../../../../../helpers/index";

import { league_tooltips } from "../../../../../helpers";

type Props = {
	rounds: TRound,
	league_type: string,
	league: {
		status: string,
		start_round: number
	}
}

const leagueStartRounds = (league, rounds) => {
	if(league.status !== "scheduled"){
		return [
			{
				text: `Round ${league.start_round}`,
				value: league.start_round
			}
		];
	}
	return rounds;
};

const leagueTooltipsName = {...league_tooltips.name, show_on_left:true};

export const LeagueSettings = ({ rounds, league_type, league }: Props) => (
	<div>
		<Field
			label='League name'
			type='text'
			name='name'
			placeholder='League name'
			component={FormField}
			tooltip={leagueTooltipsName}
		/>

		<FormRadio
			label='Privacy settings'
			name='privacy'
			elements={[
				{
					value: 0,
					text: "Private",
				},
				{
					value: 1,
					text: "Public",
				}
			]}
			tooltip={league_tooltips.privacy}
			is_disabled={league.status !== "scheduled"}
		/>

		<FormRadio
			label='League type'
			name='type'
			elements={[
				{
					value: "head_to_head",
					text: "Head to Head",
				},
				{
					value: "open",
					text: "Open",
				}
			]}
			is_string_value={true}
			width='50%'
			tooltip={league_tooltips.type}
			is_disabled={true}
		/>

		{league_type === "head_to_head" ? (
			<Field
				label='League size'
				name='size'
				options={LEAGUE_SIZE}
				component={FormSelect}
				tooltip={league_tooltips.size}
				is_disabled={league.status !== "scheduled"}
			/>
		) : null}

		<Field
			label='League start round'
			name='start_round'
			options={leagueStartRounds(league, rounds)}
			component={FormSelect}
			tooltip={league_tooltips.start_round}
			is_disabled={league.status !== "scheduled"}
		/>
	</div>
);

export default LeagueSettings;