// @flow
import * as React from "react";
import styled from "styled-components";
import { below } from "../../assets/css/media";
import colors from "../../assets/css/colors";
import FantasyCoach_BG_Mob from "../../assets/images/FantasyCoach_BG_mob.jpg";

import { ModalContainer } from "../../components";
import { CoachButtonLink } from "../CoachButton";
import { ModalContent } from "./ModalContainer";
import ModalBody from "./ModalBody";

const CoachButton = styled(CoachButtonLink)`
	width: 200px;
	box-sizing: border-box;
	font-weight: 200;
	color: ${colors.primary.light};
	margin: 40px auto 0;

	${below.phone`
		margin-top: 20px;
		width: 100%;
	`}
`;

const ModalText = styled.div`
	color: ${colors.primary.darkGrey};
	font-family: "SourceSansPro";
	font-size: 14px;
	line-height: 20px;
	text-align: center;

	/* negative margin-bottom to offset final p's margin-bottom */
	margin: 20px 0 0;
	${below.phone`
		margin-top: 10px;
	`}

	> p {
		margin-bottom: 20px;
	}
`;

const ModalTitle = styled.h2`
	color: ${colors.coach};
	font-size: 32px;
	text-align: center;

	${below.tablet`
		font-size: 24px;
	`}

	${below.phone`
		font-size: 16px;
	`}
`;

const FCImage = styled.img`
	width: 100%;
	max-width: 600px;
	margin: 0 auto;

	${below.phone`
		width: 110%;
		margin: 0 -4% 0 -6%;
	`}
`;

const HeaderContainer = styled.div`
	background-color: ${colors.coach};
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 300px;
	overflow: hidden;

	border-radius: 5px 5px 0 0;

	z-index: 0;

	${below.phone`
		height: 170px;
	`}

	@media screen and (max-width: 330px) {
		height: 160px;
	}
`;

const FantasyCoachModalWrapper = styled.div`
	${ModalContent} {
		box-sizing: border-box;
		padding-top: 340px;

		${below.tablet`
			max-width: unset;
		`}

		${below.phone`
			max-width: 300px;
			padding-top: 190px;
		`}

		@media screen and (max-width: 330px) {
			padding-top: 180px;
		}

		${ModalBody} {
			padding: 0 100px 50px;

			${below.phone`
				padding: 0 20px 30px;
			`}
		}
	}

	.close-button {
		z-index: 1;
		> svg {
			fill: white;
		}
		:hover {
			> svg {
				fill: ${colors.primary.accent};
			}
		}
	}
`;

type Props = {
	closeClick: Function,
};


export const FantasyCoachModal = ({ closeClick }: Props) => {
	return <FantasyCoachModalWrapper>
		<ModalContainer
			onClick={closeClick}
			container_background={colors.form.base}
		>
			<HeaderContainer>
				<FCImage src={FantasyCoach_BG_Mob} />
			</HeaderContainer>
			<ModalTitle>Would you like to subscribe to Fantasy Coach?</ModalTitle>
			<ModalText>
				<p>
					Coach is your premium Fantasy product fully integrated 
					across the site and app to provide you with the extra 
					stats and info needed to improve your team! It gives 
					you the best chance at winning your league and possibly 
					some great prizes!
				</p>
				<p>
					Check out all our key features for more information.
				</p>

			</ModalText>

			<CoachButton to="/coach-subscription">
				Subscribe to Fantasy Coach
			</CoachButton>
		</ModalContainer>
	</FantasyCoachModalWrapper>;
};

export default FantasyCoachModal;