// @flow
import * as React from "react";
import connect from "react-redux/es/connect/connect";
import * as _ from "lodash";
import { get, isEmpty } from "lodash";
import numeral from "numeral";

import type {
	TPlayer, 
	TRound, 
	TSquad, 
	TRoundWithMatches, 
	TMatch, 
	TVenue, 
	TRootStore,
	TPLeague
} from "../../modules/types";
import {getClubLogoIndigenous} from "../../utils/clubLogos";
import { isOffSeason } from "../../utils";
import colors from "../../assets/css/colors";
import { findMatch } from "../../helpers/rounds";
import { renderTableHeaderCell } from "../../helpers/tables";
import * as format from "../../helpers/numeralFormats";
import MATCH_STATS, { customMatchStats } from "../../utils/matchStats";
import {Exist} from "../";

import { THead, TBody } from "../TableX";
import * as selectors from "../../modules/selectors";
import FantasyCoachModal from "../Modal/FantasyCoachModal";
import Lock from "../Icons/Lock";
import * as actions from "../../modules/actions";
import { isAllTrue, isAnyTrue, score_settings } from "../../helpers";
import LockCircle from "../Icons/LockCircle";
import { SEASON_YEAR } from "../../modules/constants";
import type { TStat } from "./printStat";
import { printStat, printTotal, printPastStat } from "./printStat";
import PlayerOverview from "./playerOverview";
import {
	FINAL_ROUND,
	FIXTURE_CUSTOM_STATS,
	FIXTURE_STATS,
	CURRENT_YEAR,
	DEFAULT_VALUE
} from "./playerProfileStats/constants";
import {
	TEAM_WIDTH,
	VENUE_WIDTH,
	ROUND_WIDTH,
	StyledTabs,
	StyledTabItem,
	StatsWrapper,
	StatsTable,
	StyledTBody,
	PaywallOverlay,
	Tr,
	ThLogo,
	ThOpponentTeam,
	ThVenue,
	ThVenueName,
	ThStat,
	ThRound,
	ThTeam,
	Logo,
	TdLogo,
	TdTeam,
	TeamContainer,
	TeamName,
	TdVenue,
	TdStat,
	MatchTd,
	MatchStatContainer,
	MatchValue,
	MatchScore,
	TdRound,
	TdBye,
	FcLink,
	TdOpponentTeam,
	TdVenueName,
	TrTotals,


} from "./playerProfileStats/playerProfileStatsStyles";




const makeKey = (r_id, s) => `${r_id}-${s.id}`;

const skipFixtureHead = (
	fixture_active: boolean, 
	played: boolean, 
	current_round: number
) => {
	return fixture_active 
		&& (
			(played && current_round === 1)
			|| (!played && current_round === FINAL_ROUND)
		);
};
const isStatIncluded = (stat: string, compare: string) => {
	return compare.includes(stat) ? "absolute-group-right" : "";
};

const isAnyEmpty = (arr: Object[]) => {
	let isEmpty = false;
	arr.forEach(obj => {
		if(_.isEmpty(obj)){
			isEmpty = true;
		}
	});
	return isEmpty;
};

const widthDiff = (active_tab: string) => {
	return active_tab === "opposition" 
		? "27% + 40px" : "27%";
};


const getEndRound = (unplayed: boolean, current_round: number) => {
	return unplayed ? current_round : FINAL_ROUND + 1;
};

const isEitherTrue = (arg1, arg2) => {
	return arg1 || arg2;
};

const getGamesPlayedTooltip = showOpposition => {
	return showOpposition ? "against opponent" : "at venue";
};

type Props = {
	player: TPlayer,
	league: TPLeague,
	actual_round: TRound,
	squads: {[number]: TSquad},
	venues: {[number]: TVenue},
	has_coach: boolean,
	rounds: Array<TRoundWithMatches>,
	player_matches: Object,
	is_classic: boolean,
	past_years: Object,
	fetchPastYearPlayers: typeof actions.fetchPastYearPlayers,
	by_year: string,
	fetchPlayerVenueClassic: typeof actions.fetchPlayerVenueStats,
	fetchPlayerVenueDraft: typeof actions.fetchPlayerVenueStatsDraft,
	fetchPlayerOpponentClassic: typeof actions.fetchPlayerOppositionStats,
	fetchPlayerOpponentDraft: typeof actions.fetchPlayerOppositionStatsDraft,
	fetchPastYearRounds: typeof actions.fetchPastRounds,
	classic_venue_stats: typeof selectors.getPlayerVenueStatsClassic,
	draft_venue_stats: typeof selectors.getPlayerVenueStatsDraft,
	classic_opponent_stats: typeof selectors.getPlayerOpponentStatsClassic,
	draft_opponent_stats: typeof selectors.getPlayerOpponentStatsDraft,
	past_rounds_state: Object,
};

type State = {
	active_tab: string,
	show_fantasy_coach_modal: boolean,
}

class PlayerStatsComponent extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			active_tab: "playerOverview",
			show_fantasy_coach_modal: false,
		};

		_.bindAll(this, [
			"setActiveTab",
			"renderRoundRow",
			"renderTabs",
			"renderTable",
			"renderTableHead",
			"renderTableBody",
			"getRoundMatch",
			"toggleFantasyCoachModal",
			"statToCellInfo",
			"useCustomScoringStats"
		]);
	}

	componentDidMount() {
		
		this.getPlayerOpponentVenueData();
		
	}
	
	componentDidUpdate(prevProps) {
		const {rounds, player, fetchPastYearRounds, past_rounds_state} = this.props;
		if(get(prevProps, 'league.id') !== get(this.props, 'league.id')){
			this.getPlayerOpponentVenueData();
		}
		const isPlayerSeasonStarted = 
				_.get(rounds, '[0].status') !== 'scheduled' && get(player, 'locked');
		if(!isPlayerSeasonStarted 
			&& isEmpty(past_rounds_state[Number(SEASON_YEAR) - 1]) 
			&& prevProps.past_rounds_state !== past_rounds_state
		){
			fetchPastYearRounds(Number(SEASON_YEAR) - 1);
		}
	}

	getPlayerOpponentVenueData() {
		const { league } = this.props;
		const league_id = _.get(league, "id");
		const isCustomScoring = _.get(league, "custom_scoring_enabled", 0);
		if(!Boolean(isCustomScoring)){
			this.props.fetchPlayerVenueClassic();
			this.props.fetchPlayerOpponentClassic();
			return;
		}
		this.props.fetchPlayerVenueDraft(league_id);
		this.props.fetchPlayerOpponentDraft(league_id);
	}

	get statColWidth() {
		const { is_classic } = this.props;
		const stats = _.filter(this.activeStats.played, s => is_classic || !s.not_draft);
		const remainingWidth = 96 - TEAM_WIDTH - VENUE_WIDTH - ROUND_WIDTH;
		const width = remainingWidth / (stats.length);
		return `${width}%`;
	} 

	get activeStats() {
		const { league } = this.props;
		const leagueCustomScoring = _.get(league, "custom_scoring", {});

		const activeStatsObj = {
			"fixture": this.useCustomScoringStats() ? FIXTURE_CUSTOM_STATS : FIXTURE_STATS,
			"match": MATCH_STATS,
			"playerOverview": FIXTURE_STATS,
			"opposition": Boolean(_.get(league, "custom_scoring_enabled", false))
				?  {played: customMatchStats(leagueCustomScoring), unplayed: false} : MATCH_STATS,
			"venue": Boolean(_.get(league, "custom_scoring_enabled", false))
				?  {played: customMatchStats(leagueCustomScoring), unplayed: false}  : MATCH_STATS
		};
		return _.get(activeStatsObj, [this.state.active_tab], {});
	}

	setActiveTab({ currentTarget }) {
		const { tab } = currentTarget.dataset;
		this.setState({
			active_tab: tab
		});
	}

	toggleFantasyCoachModal() {
		const { show_fantasy_coach_modal } = this.state;
		this.setState({ show_fantasy_coach_modal: !show_fantasy_coach_modal });
	}

	getRoundMatch(round_id: number) {
		const { rounds, player, by_year, past_rounds_state, past_years} = this.props;
		const isPlayerSeasonStarted = 
			_.get(rounds, '[0].status') !== 'scheduled' && get(player, 'locked');
		const is_current_year = by_year === CURRENT_YEAR;
		if(isAnyTrue([!is_current_year, isPlayerSeasonStarted])){
			const matches_by_id = _.get(past_rounds_state, `${by_year}.matches_by_id`);
			const player_to_use = is_current_year ? player : 
				_.get(past_years, `[${by_year}][${player.id}]`, player);
			const round = _.get(past_rounds_state, `${by_year}.by_id.${round_id}`);
			if(!isAnyEmpty([matches_by_id, player_to_use, round])){
				const matches_in_round = round.matches.map(match_id => matches_by_id[match_id]);
				return matches_in_round
					.find(match => match.home_squad_id === player_to_use.squad_id 
						|| match.away_squad_id === player_to_use.squad_id);
			}
			
		}
		const round = _.find(this.props.rounds, { id: round_id });
		if (isAllTrue([player, rounds.length > 0, round])) {
			const player_squad = player.squad_id;
			return _.find(round.matches, m => {
				return [ m.home_squad_id, m.away_squad_id ].includes(player_squad);
			});
		}
	}

	isPlayerBye(round_id: number) {
		const { rounds, player, by_year, past_rounds_state, past_years } = this.props;
		const round = _.find(this.props.rounds, { id: round_id });
		const is_current_year = by_year === CURRENT_YEAR;
		if(!is_current_year){
			const player_to_use = _.get(past_years, `[${by_year}][${player.id}]`, player);
			const round = _.get(past_rounds_state, `${by_year}.by_id.${round_id}`);
			if(player_to_use && round){
				return _.indexOf(round.bye_squads, player_to_use.squad_id) !== -1;
			}
		}
		if (player && rounds.length > 0 && round) {
			return _.indexOf(round.bye_squads, player.squad_id) !== -1;
		}
	}

	getOpposition(match: TMatch) {
		const { player, squads, by_year, past_years } = this.props;
		const player_to_use = by_year !== CURRENT_YEAR 
			? _.get(past_years, `[${by_year}][${player.id}]`) : player;
		if (match && player_to_use) {
			const player_squad = player_to_use.squad_id;
			if (player_squad === match.away_squad_id) {
				return _.find(squads, { id: match.home_squad_id });
			}
			return _.find(squads, { id: match.away_squad_id });
		}
	}

	renderTabs() {
		const { active_tab } = this.state;
		const opp_active_class_name = active_tab === "opposition" ? " active-tab" : "";
		const opp_class_name = `coach-tab opposition ${opp_active_class_name}`;
		return (
			<StyledTabs>
				<StyledTabItem
					onClick={this.setActiveTab}
					data-tab="playerOverview"
					className={"playerOverview" 
						+ (active_tab === "playerOverview" ? " active-tab" : "")}>
					Player Overview
				</StyledTabItem>
				<Exist when={!isOffSeason()}>
					<StyledTabItem
						onClick={this.setActiveTab}
						data-tab="fixture"
						className={"fixture" + (active_tab === "fixture" ? " active-tab" : "")}>
						Fixture Stats
					</StyledTabItem>
				</Exist>
			
				<StyledTabItem
					onClick={this.setActiveTab}
					data-tab="match"
					className={"match" + (active_tab === "match" ? " active-tab" : "")}>
					Match Stats
				</StyledTabItem>
				<Exist when={!isOffSeason()}>
					<StyledTabItem
						onClick={this.setActiveTab}
						data-tab="opposition"
						className={opp_class_name}>
						Opposition Stats
					</StyledTabItem>
					<StyledTabItem
						onClick={this.setActiveTab}
						data-tab="venue"
						className={"coach-tab venue" + (
							active_tab === "venue" ? " active-tab" : "")}>
						Venue Stats
					</StyledTabItem>
				</Exist>
				
			</StyledTabs>
		);
	}

	getResponsiveColumns(mobile: boolean) {
		const { active_tab } = this.state;
		const { is_classic } = this.props;
		let tab_cols, mob_cols;
		if (active_tab === "fixture") {
			/* Fixture Stats */
			if (is_classic) {
				tab_cols = ["round", "opp-logo", "stat-0", "stat-1", "stat-4", "stat-6"];
				mob_cols = ["round", "opp-logo", "stat-0", "stat-1"];
			}
			else {
				tab_cols = ["round", "opp-logo", "stat-0", "stat-1", "stat-3"];
				mob_cols = ["round", "opp-logo", "stat-0"];
			}
		}
		else {
			/* Match Stats */
			tab_cols = ["round", "opp-logo", "TOG", "G", "B", "score"];
			mob_cols = ["round", "opp-logo", "G", "B", "score"];
		}

		return mobile ? mob_cols : tab_cols;
	}

	renderTable() {
		const { is_classic, player, actual_round, by_year, league, has_coach } = this.props;
		const { active_tab } = this.state;
		const { unplayed } = this.activeStats;
		const custom_scoring_enabled = _.get(league, "custom_scoring_enabled", false);
		const is_current_year = by_year === CURRENT_YEAR;
		const showTotals = !["opposition", "venue"].includes(active_tab);
		const showPaywallOverlay = !has_coach && !showTotals;
		const widthDifference = widthDiff(active_tab);

		return (
			<React.Fragment>
				<Exist when={active_tab === "playerOverview"}>
					<PlayerOverview
						is_classic={is_classic}
						player={player}
						by_year={by_year}
						actual_round={actual_round || {}}
						league={league}
						custom_scoring_enabled={custom_scoring_enabled}
					/>
				</Exist>
				<Exist when={active_tab !== "playerOverview"}>
					<StatsWrapper>
						<StatsTable
							tablet_columns={this.getResponsiveColumns(false)}
							mobile_columns={this.getResponsiveColumns(false)}
							show_border_bottom={false}
						>
							{/* Render the played tables all the time */}
							{this.renderTableHead(true)}
							
							<StyledTBody showRelative={showPaywallOverlay}>
								<Exist when={showPaywallOverlay}>
									<PaywallOverlay 
										onClick={this.toggleFantasyCoachModal}
										widthDifference={widthDifference}
									>
										<LockCircle />
									</PaywallOverlay>
								</Exist>
								{this.renderTableBody(true)}
							</StyledTBody>
							{/* Only render unplayed table if there's something specific to show */}
							{unplayed && is_current_year ? this.renderTableHead(false) : null}
							<TBody>
								{unplayed && is_current_year ? this.renderTableBody(false) : null}
								<Exist when={showTotals}>
									{this.renderTotals()}
								</Exist>
							</TBody>
						</StatsTable>
					</StatsWrapper>
				</Exist>
				
			</React.Fragment>
		);
	}

	renderTableHead(played: boolean) {
		const stats = [];
		const current_round = this.props.actual_round.id;
		const { is_classic, league, by_year } = this.props;
		const { active_tab } = this.state;
		// const showFixtureScore = 
		// 	isAllTrue([Number(by_year) !== CURRENT_YEAR, active_tab === 'fixture', played]);
		const currentAndSkipFixture = isAllTrue([ skipFixtureHead(
			active_tab === "fixture", 
			played, 
			current_round
		),
		Number(by_year) === CURRENT_YEAR
		]);
		
		if(this.useCustomScoringStats() && active_tab === "match"){
			stats.push({
				name: "% TOG", 
				id: "TOG", 
				tooltip: "Percentage of time spent on ground"
			});
			Object.keys(league.custom_scoring).forEach(i => {
				stats.push({ 
					id: i, 
					name: i, 
					tooltip: score_settings[i].title + ` (${league.custom_scoring[i]} points)`, 
					score_per: league.custom_scoring[i]
				});
			});
			stats.push({
				name: "Score", 
				id: "stats.score", 
				tooltip: "Total Score for Game"
			});
		}
		else{
			(played ? this.activeStats.played : this.activeStats.unplayed || []).forEach(i => { 
				stats.push(i);
			});
		}

		if (currentAndSkipFixture) {
			return null;
		}

		if(["opposition", "venue"].includes(this.state.active_tab)){
			const showOpposition = this.state.active_tab === "opposition";
			const tooltipCopy = getGamesPlayedTooltip(showOpposition);
			const gamesPlayed = {
				name: "GP",
				id: "GP",
				tooltip: `Games Played  ${tooltipCopy}`,
			};
			const newStats = [gamesPlayed, ...this.activeStats.played];
			
			return (
				<THead>
					<Tr>
				
						<Exist when={showOpposition}>
							<ThLogo
								is_fc={true}
								rel="opp-logo" 
								isUnplayed={!played} 
								className="absolute-left"
							>
								<span>Opp</span>
							</ThLogo>
							<ThOpponentTeam
								is_fc={true}
								rel="opp-name" 
								className="absolute-left-second"
								isUnplayed={!played}>
									Opposition
							</ThOpponentTeam >
						</Exist>
						
						<Exist when={!showOpposition}>
							<ThVenueName
								is_fc={true}
								rel="venue" 
								isUnplayed={!played} 
								className="absolute-left-venue"
							>Venue
							</ThVenueName>
						</Exist>

						{
							_(newStats)
								.filter(({ not_draft, id }) => {
									
									return is_classic || !not_draft;
								})
								.map(this.statToCellInfo)
								.map(cell_info => renderTableHeaderCell(
									{ ...cell_info, cell: { ...cell_info.cell, is_fc: true } },
									ThStat, 
									"", 
									{ statColWidth: this.statColWidth }
								))
								.value()
						}
						{/* <ThStat className="absolute-group-right" 
							is_fc={true} 
							width={this.statColWidth}
						>
							SCORE
						</ThStat> */}
					</Tr>
				</THead>
			);
		}

		return (
			<THead>
				<Tr>
					<ThRound rel="round" isUnplayed={!played} className="absolute-left">Rd</ThRound>
					{/* Header for Logo Row */}
					<ThLogo 
						rel="opp-logo" 
						isUnplayed={!played} 
						className="absolute-left-second-main" 
					>
						<span>Opp</span>
					</ThLogo>
					<ThTeam rel="opp-name" isUnplayed={!played}>Versus</ThTeam >
					<ThVenue rel="venue" isUnplayed={!played}>Venue</ThVenue>
						
					
					{
						_(stats)
							.filter(({ not_draft, id }) => {
								if(id.includes("score") && active_tab === "match"){
									return false;
								}
								return is_classic || !not_draft;
							} )
							.map(this.statToCellInfo)
							.map(cell => renderTableHeaderCell(
								cell, 
								ThStat, 
								"", 
								{ statColWidth: this.statColWidth }
							))
							.value()
					}
					<Exist when={active_tab !== "fixture"}>
						<ThStat className="absolute-group-right" style={{
							width: this.statColWidth
						}}>
						SCORE
						</ThStat> 
					</Exist>
					
				
				</Tr>
			</THead>
		);
	}

	statToCellInfo(stat: Object, i: number) {
		const { active_tab } = this.state;

		return {
			table: {
				order_by_desc: false,
				sort_key: ""
			},
			cell: {
				prop: stat.id,
				sort_key: stat.id,
				short_name: stat.name,
				rel: active_tab === "fixture" ? `stat-${i}` : stat.id,
				sortable: false,
				onClick: () => null,
				is_fc: stat.is_coach || false,
				...stat
			}
		};
	}

	teamCell(match: TMatch) {
		const {actual_round} = this.props;
		const opp = this.getOpposition(match);
		const clubLogo = getClubLogoIndigenous(actual_round.id, get(opp, 'id'));
		const logo = (
			<Logo
				src={clubLogo}
				alt={`${opp ? opp.name : ""} club logo`}
			/>);

		return (
			<React.Fragment>
				<TdLogo rel="opp-logo" className="absolute-left-second-main">
					{opp ? logo : ""}
				</TdLogo>
				<TdTeam rel="opp-name">
					<TeamContainer>
						<TeamName>{opp ? opp.name : ""}</TeamName>
					</TeamContainer>
				</TdTeam>
			</React.Fragment>
		);
	}

	venueCell(match: TMatch) {
		const { venues } = this.props;
		if (!_.isEmpty(venues) && match) {
			const venue = venues[match.venue_id];
			const venueShortName = _.get(venue, 'short_name', "");
			return (<TdVenue rel="venue">
				{venueShortName}
			</TdVenue>);
		}
		return <TdVenue rel="venue" />;
	}
	
	getPlayerToUse(){
		const {by_year, past_years, player} = this.props;
		const is_current_year = by_year === CURRENT_YEAR;
		const past_year_player = _.get(past_years, `[${by_year}][${player.id}]`, player);
		return is_current_year ? player : 
			past_year_player;
	}

	

	displayStatNotEmpty(stat: TStat, round_id: number | string) {
		const { player, player_matches, by_year, league, rounds } = this.props;
		const statClassName = 	isStatIncluded("score", stat.id);
		const is_current_year = by_year === CURRENT_YEAR;
		const player_to_use = this.getPlayerToUse();
		const games_played = _.get(player_to_use, "stats.games_played", 1);
		const key = makeKey(round_id, stat);
		const round_stats = is_current_year ? 
			player_matches[round_id] : 
			_.get(player.matches_by_year, `[${by_year}][${round_id}]`, player_matches[round_id]);
		const value = _.get(round_stats, stat.id, DEFAULT_VALUE);
		if (stat.id === "TOG") {
			return (
				<TdStat rel={stat.id} key={key}>
					<Exist when={round_id !== "all"}>
						{ numeral(value / 100).format(format.PERCENTAGE)}
					</Exist>
					<Exist when={round_id === "all"}>
						{ numeral((value/games_played) / 100).format(format.PERCENTAGE)}
					</Exist>
				</TdStat>
			);
		}
		else if (stat.id.includes("score")){
			const isCustomScoring = Boolean(_.get(league, "custom_scoring_enabled"));
			const isPlayerSeasonStarted = isAllTrue([
				_.get(rounds, '[0].status') !== 'scheduled',
				get(player, 'locked')
			]);
			const scoresToUse = isCustomScoring ?  
				player.custom_stats.scores : player_to_use.stats.scores;
			let scoreForRound = _.get(scoresToUse, round_id, DEFAULT_VALUE);
			if(isAllTrue([!isPlayerSeasonStarted, isCustomScoring])){
				const customScoringForumula = get(league, 'custom_scoring', {});
				let score = 0;
				Object.keys(customScoringForumula).forEach(statKey => {
					score += round_stats[statKey] * customScoringForumula[statKey];
				});
				scoreForRound = score;
			}
			if(isAllTrue([!isPlayerSeasonStarted, !isCustomScoring])){
				let score = 0;
				MATCH_STATS.played.forEach(match_stat => {
					if(["TOG", "stats.score"].includes(match_stat.id)){
						return;
					}
					score += round_stats[match_stat.id] * match_stat.score_per;
				});
				scoreForRound = score;
			}
			return <React.Fragment>
				<Exist when={isCustomScoring}>
					<TdStat rel={stat.id} key={key} className={statClassName}>
					
						<Exist when={round_id === "all"}>
							{_.get(player, "custom_stats.total_points", DEFAULT_VALUE)}
						</Exist>
						<Exist when={round_id !== "all"}>
							{scoreForRound}
						</Exist>
					
					</TdStat>
				</Exist>
				<Exist when={!isCustomScoring}>
					<TdStat rel={stat.id} key={key} className={statClassName}>
					
					
						<Exist when={round_id === "all"}>
							{_.get(player_to_use, "stats.total_points", DEFAULT_VALUE)}
						</Exist>
						<Exist when={round_id !== "all"}>
							{scoreForRound}
						</Exist>
					
					
					</TdStat>
				</Exist>
			</React.Fragment>;

		}
		const score = _.get(stat, "score_per", 0) * value;
		return (
			<MatchTd rel={stat.id} key={key}>
				<MatchStatContainer>
					<MatchValue>{value}</MatchValue>
					<MatchScore>
						<Exist when={Number.isNaN(score)}>
							— —
						</Exist>
						<Exist when={!Number.isNaN(score)}>
							{ score}
						</Exist>
					
					</MatchScore>
				</MatchStatContainer>
			</MatchTd>
		);
	}

	displayMatchStat(stat: TStat, round_id: number | string, row_id: any) {
		
		const statClassName = 
			isStatIncluded("score", stat.id);
		const { by_year, player } = this.props;
		const key = makeKey(round_id, stat);
		const { player_matches } = this.props;
		const playerMatchesByYear = _.get(player, `matches_by_year[${by_year}]`);
		const this_year = by_year === CURRENT_YEAR ;
		const emptyMatch = _.isEmpty(playerMatchesByYear) && !this_year;
		const thirdRule = isAllTrue([round_id < this.props.actual_round.id,stat.id === "TOG", round_id !== 0]);
		
		if(isAllTrue([!this_year,!emptyMatch]) 
        && playerMatchesByYear.hasOwnProperty(round_id)){
			return this.displayStatNotEmpty(stat, round_id);
		}

		if (_.has(player_matches, `${round_id}`)) {
			return this.displayStatNotEmpty(stat, round_id);
		}
		if (thirdRule) {
			return <TdStat rel={stat.id} key={key} className={statClassName}>DNP</TdStat>;
		}
		if(isAllTrue([this_year,emptyMatch])){
			return <TdStat 
				rel={stat.id} 
				key={key} 
				className={statClassName}>
				{DEFAULT_VALUE}
					
			</TdStat>;
		}
		return <TdStat rel={stat.id} key={key} className={statClassName}>{DEFAULT_VALUE}</TdStat>;
	}

	useCustomScoringStats(){
		const { league, by_year } = this.props;
		const is_current_year = by_year === process.env.REACT_APP_SEASON_YEAR;
		return is_current_year ? _.get(league, "custom_scoring_enabled", false) : false;
	}

	getStatsToUse(played) {
		return played ? this.activeStats.played : this.activeStats.unplayed;
	}


	renderRoundRow(round_id: number, played: boolean) {
		// LOGIC FOR AF22-241 to be written here
		// have round id, need the correct match from past years
		
		// can then get the right opposition / venue
		const match = this.getRoundMatch(round_id);
		const { player, is_classic, has_coach, league, by_year, past_years } = this.props;
		const { active_tab } = this.state;
		const playerID = _.get(player, player.id, 0);
		let round_cells, stat_cells;
		const stats = _.filter(
			this.getStatsToUse(played),
			({ not_draft }) => isEitherTrue(is_classic, !not_draft) 
		);
		const is_bye = this.isPlayerBye(round_id);
		let past_year_player = {};
		const is_current_year = by_year === CURRENT_YEAR;

		const playerFromPastYear = _.get(past_years, `[${by_year}][${playerID}]`, {});
		if(isAllTrue([
			!is_current_year,
			!_.isEmpty(playerFromPastYear)
		])){
			past_year_player = playerFromPastYear;
		}
		
		const activeStats = [];
		if(isAllTrue([this.useCustomScoringStats(), active_tab === "match"])){
			activeStats.push({
				name: "% TOG", 
				id: "TOG", 
				tooltip: "Percentage of time spent on ground"
			});
			Object.keys(league.custom_scoring).forEach(i => {
				activeStats.push({ 
					id: i, 
					name: i, 
					tooltip: score_settings[i].title + ` (${league.custom_scoring[i]} points)`, 
					score_per: league.custom_scoring[i]
				});
			});
			activeStats.push({
				name: "Score", 
				id: "custom_stats.score", 
				tooltip: "Total Score for Game"
			});
		}
		else{
			_.filter(
				played ? this.activeStats.played : this.activeStats.unplayed,
				({ not_draft }) => isAnyTrue([
					is_classic,
					!not_draft
				])
			).forEach(i => { 
				activeStats.push(i);
			});
		}

		if (is_bye) {
			round_cells = (
				<React.Fragment>
					<TdRound rel="round">{round_id}</TdRound>
					<TdBye rel="opp-logo">Bye</TdBye>
					<TdTeam rel="opp-name" />
					<TdVenue rel="venue"></TdVenue>
				</React.Fragment>
			);
			stat_cells = _.map(stats,  (stat, i) => (
				<TdStat 
					rel={`stat-${stat.id}`} 
					key={`${round_id}-${stat.id}`} 
				/>)
			);
		}
		else {
			round_cells = (
				<React.Fragment>
					<TdRound className="absolute-left">{round_id}</TdRound>
					{this.teamCell(match)}
					{this.venueCell(match)}
				</React.Fragment>
			);

			if (active_tab === "fixture") {
				
				stat_cells = _.map(
					stats,
					(stat, i) => {
						
						const is_coach_unsub = isAllTrue([
							stat.is_coach,
							!has_coach
						]);
						return (<TdStat
							rel={`stat-${i}`} key={`${round_id}-${stat.id}`}
							is_fc={stat.is_coach}>
							<Exist when={is_coach_unsub}>
								<FcLink onClick={this.toggleFantasyCoachModal}>
									<Lock color={colors.coach} />
								</FcLink>
							</Exist>
							<Exist when={!is_coach_unsub}>
								{ printStat(stat, player, round_id, match)}
							</Exist>
						</TdStat>);
					}
				);
				if(by_year !== CURRENT_YEAR){
					stat_cells = _.map(
						stats,
						(stat, i) => {
							return(
								<TdStat rel={`stat-${i}`} key={`${round_id}-${stat.id}`}>
									{printPastStat(stat, past_year_player, round_id)}
								</TdStat>
							);
						}
					);
				}
			}
			else {
				stat_cells = _.map(
					activeStats,
					stat => {
						
						return this.displayMatchStat(stat, round_id);
					} 
				);
			}
		}

		return (
			<Tr key={round_id}>
				{round_cells}
				{stat_cells}
			</Tr>
		);
	}

	renderOppositionRow(id: number) {
		const { 
			squads, 
			classic_opponent_stats, 
			draft_opponent_stats, 
			league, 
			player, 
			has_coach,
			actual_round
		} = this.props;
		const squad = squads[id];
		const isCustomScoring = Boolean(_.get(league, "custom_scoring_enabled", 0));
		const selectorData = 
			isCustomScoring 
				? draft_opponent_stats[player.id] 
				: classic_opponent_stats[player.id];
		const oppositionData = _.get(selectorData, id, {});
		const tooltipCopy = getGamesPlayedTooltip(true);
		const gamesPlayed = {
			name: "GP",
			id: "GP",
			tooltip: `Games Played  ${tooltipCopy}`,
		};
		const stats = [gamesPlayed, ...this.activeStats.played].map(stat => {
			if(!has_coach){
				return(
					<TdStat>
						-
					</TdStat>
				);
			}
			if(stat.id === "stats.score"){
				return <TdStat 
					rel={`stat-${stat.id}`} 
					key={stat.id} 
					is_fc={stat.is_coach} 
					className="absolute-group-right"
				>
					{
						oppositionData.score ? oppositionData.score : "-"
					}
				</TdStat>;
			}
			return <TdStat rel={`stat-${stat.id}`} key={stat.id} is_fc={stat.is_coach}>
				{
					oppositionData[stat.id] ? oppositionData[stat.id] : "-"
				}
			</TdStat>;
		});
		const clubLogo = getClubLogoIndigenous(actual_round.id, get(squad, 'id'));
		const logo = (
			<Logo
				src={clubLogo}
				alt={`${squad.name} club logo`}
			/>);
		

		return (
			<Tr key={squad.id}>
				
				<TdLogo rel="opp-logo" className="absolute-left">
					{squad ? logo : ""}
				</TdLogo>
				<TdOpponentTeam rel="opp-name" className="absolute-left-second">
					<TeamContainer>
						<TeamName>{squad ? squad.name : ""}</TeamName>
					</TeamContainer>
				</TdOpponentTeam>
				{stats}
			</ Tr>
		);
	}

	renderVenueRow(id: number) {
		const { 
			venues, 
			classic_venue_stats, 
			draft_venue_stats, 
			league, 
			player, 
			has_coach 
		} = this.props;
		const venue = venues[id];
		const isCustomScoring = Boolean(_.get(league, "custom_scoring_enabled", 0));
		const selectorData = 
			isCustomScoring 
				? draft_venue_stats[player.id] 
				: classic_venue_stats[player.id];
		const venueData = _.get(selectorData, id, {});
		const tooltipCopy = getGamesPlayedTooltip(true);
		const gamesPlayed = {
			name: "GP",
			id: "GP",
			tooltip: `Games Played  ${tooltipCopy}`,
		};
		const stats = [gamesPlayed, ...this.activeStats.played].map(stat => {
			if(!has_coach){
				return(
					<TdStat>
						-
					</TdStat>
				);
			}
			if(stat.id === "stats.score"){
				return <TdStat 
					rel={`stat-${stat.id}`} 
					key={stat.id} 
					is_fc={stat.is_coach} 
					className="absolute-group-right"
				>
					{
						venueData.score ? venueData.score : "-"
					}
				</TdStat>;
			}
			return <TdStat rel={`stat-${stat.id}`} key={stat.id} is_fc={stat.is_coach}>
				{
					venueData[stat.id] ? venueData[stat.id] : "-"
				}
			</TdStat>;
		});
		return(
			<Tr key={venue.id}>
				<TdVenueName rel="venue-name"  className="absolute-left-venue">
					<TeamContainer>
						<TeamName>{venue ? venue.name : ""}</TeamName>
					</TeamContainer>
				</TdVenueName>
				{stats}
			</ Tr>
		);

	}

	isPlayerMatchFinished() {
		const { actual_round, player } = this.props;
		const squad_id = _.get(player, "squad_id");
		const matches = _.get(actual_round, "matches", []);

		const match = findMatch(matches, squad_id);
		const status = _.get(match, "status", "scheduled");

		return status === "complete";
	}

	renderTableBody(played: boolean) {
		const { by_year, squads, venues, player } = this.props;
		let current_round = this.props.actual_round.id;
		if (isAnyTrue([
			!current_round,
			!player
		]) ) {
			return null;
		}
		const render_row = r_id => this.renderRoundRow(r_id, played);
		if(this.state.active_tab === "opposition"){
			return Object.keys(squads).map(squadID => this.renderOppositionRow(Number(squadID)));
		}
		if(this.state.active_tab === "venue"){
			return Object.keys(venues).map(venueID => this.renderVenueRow(Number(venueID)));
		}
		if( by_year !== CURRENT_YEAR){
			return _.map(_.range(1, FINAL_ROUND + 1), render_row);
		}

		// If the player's match has finished, show stats from it 
		// (rather than projections)
		if (this.isPlayerMatchFinished()) {
			current_round += 1;
		}

		if (played) {
			const end = getEndRound(this.activeStats.unplayed, current_round);
			return _.map(_.range(0, end), render_row);
		}
		return _.map(_.range(0, FINAL_ROUND + 1), render_row);
	}


	renderTotals() {
		const { is_classic, player, has_coach, league, by_year, past_years } = this.props;
		const { active_tab } = this.state;
		const is_current_year = by_year === CURRENT_YEAR;
		let past_year_player = {};
		const playerID = _.get(player, "id");
		if(!is_current_year){
			if(isAllTrue([
				!_.isEmpty(past_years),
				!_.isEmpty(past_years[by_year]),
				!_.isEmpty(_.get(past_years[by_year], `[${playerID}]`))
			])){
				past_year_player = past_years[by_year][playerID];
			}
			
		}
		const stats = [];

		if(!is_current_year){
			_.filter(
				this.activeStats.played,
				({ not_draft }) => is_classic || !not_draft
			).forEach(i => { 
				stats.push(i);
			});
		}
		else if(active_tab === "fixture"){
			_.filter(
				this.activeStats.played,
				({ not_draft }) => is_classic || !not_draft
			).forEach(i => { 
				stats.push(i);
			});
		}
		else{
			if(this.useCustomScoringStats()){
			
				stats.push({
					name: "% TOG", 
					id: "TOG", 
					tooltip: "Percentage of time spent on ground"
				});
				Object.keys(league.custom_scoring).forEach(i => {
					stats.push({ 
						id: i, 
						name: i, 
						tooltip: score_settings[i].title + ` (${league.custom_scoring[i]} points)`, 
						score_per: league.custom_scoring[i]
					});
				});
				stats.push({
					name: "Score", 
					id: "custom_stats.score", 
					tooltip: "Total Score for Game"
				});
			}
			else{

				_.filter(
					this.activeStats.played,
					({ not_draft }) => is_classic || !not_draft
				).forEach(i => { 
			
					stats.push(i);
				});
			}
		}
		
		return (
			<TrTotals>
				<TdRound rel="round">Total:</TdRound>
				<TdBye rel="opp-logo" />
				<TdTeam rel="opp-name" />
				<TdVenue rel="venue" />
				{_.map(stats, (stat, i) => {
					if (active_tab === "match")  {
						return this.displayMatchStat(stat, "all", "all");
					}
					return <TdStat rel={`stat-${i}`} key={stat.id} is_fc={stat.is_coach}>
						{
							stat.is_coach && !has_coach
								? (
									<FcLink onClick={this.toggleFantasyCoachModal}>
										<Lock color={colors.coach} />
									</FcLink>
								)
								: is_current_year ? printTotal(stat, player) :
									past_year_player !== {} ? printTotal(stat, past_year_player) :
										printTotal(stat, player)
						}
					</TdStat>;
				})}
			</TrTotals>
		);
	}

	render() {
		const { show_fantasy_coach_modal } = this.state;
		return (
			<React.Fragment>
				{
					show_fantasy_coach_modal ?
						<FantasyCoachModal
							closeClick={this.toggleFantasyCoachModal}
						/>
						: null
				}
				{ this.renderTabs() }
				{ this.renderTable() }
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: TRootStore, props: Props) => {
	const { player } = props;
	// if(!is_classic && !_.get(props, 'match.params.league_id')) {
	// 	return {};
	// }
	let player_matches = {};

	if (player && state.players.matches_by_id[player.id]) {
		player_matches = state.players.matches_by_id[player.id];
	}

	return ({
		// players,
		squads: selectors.squads.getSquadsById(state),
		venues: selectors.venues.getVenuesById(state),
		actual_round: selectors.rounds.getActualRound(state),
		past_years: state.players.past_years,
		has_coach: Boolean(selectors.getUser(state).assistant_coach),
		rounds: selectors.rounds.getRoundsWithMatches(state),
		classic_venue_stats: selectors.players.getPlayerVenueStatsClassic(state),
		draft_venue_stats: selectors.players.getPlayerVenueStatsDraft(state),
		classic_opponent_stats: selectors.players.getPlayerOpponentStatsClassic(state),
		draft_opponent_stats: selectors.players.getPlayerOpponentStatsDraft(state),
		player_matches,
		past_rounds_state: state.rounds.past_years
	});
};

const mapDispatchToProps = {
	fetchPastYearPlayers: actions.fetchPastYearPlayers,
	fetchPlayerVenueClassic: actions.fetchPlayerVenueStats,
	fetchPlayerVenueDraft: actions.fetchPlayerVenueStatsDraft,
	fetchPlayerOpponentClassic: actions.fetchPlayerOppositionStats,
	fetchPlayerOpponentDraft: actions.fetchPlayerOppositionStatsDraft,
	fetchPastYearRounds: actions.fetchPastRounds
};

export const PlayerStats = connect(
	mapStateToProps,
	mapDispatchToProps
)(PlayerStatsComponent);

export default PlayerStats;