// @flow
export * from "./api";
export * from "./isMobile";
export * from "./currencyFormat";
export * from "./passwordTest";
export * from "./GTM";
export * from "./sagaError";
export * from "./url";

export const isOffSeason = () => {
	if(JSON.parse(localStorage.getItem("a18c25c64-24db-44aa-a479-77915d02abbb") || "false")) {
		return false;
	}

	return JSON.parse( process.env.REACT_APP_OFF_SEASON || "false");
};