// @flow
import * as React from "react";
import styled from "styled-components";

import Tooltip from "../Form/Tooltip";
import colors from "../../assets/css/colors";

const Li = styled.li`
	font-size: 12px;
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 10px;
	> span {
		line-height: 1.2;
		margin-top: 4px;
	}
	
	&:hover {
		.tooltip-icon {
			background: ${colors.primary.accent};
			border: 1px solid #ffffff;
			color: #ffffff;
			svg {
				color: #ffffff;
				fill: #ffffff;
			}
		}
		.tooltip-text {
			display: block
		}
	}
`;

const IconWrapper = styled.div`
	display: flex;
	margin-right: 10px;
	font-size: 16px;
`;

type Props = {
	icon: React.Element<any>,
	description: string,
	tooltip: string,
}

const FooterKey = ({ icon, description, tooltip }: Props) => (
	<Li>
		<IconWrapper>{icon}</IconWrapper>
		<span>
			{description}
		</span>
		<Tooltip description={tooltip} />
	</Li>
);

export default FooterKey;