/* eslint max-len: 0 */
// @flow
import React from "react";
import SvgIconContainer from "../SvgIconContainer";

export default (
	{
		unit = "em",
		...rest
	}: Object = {}) => (
	<SvgIconContainer viewBox="0 0 24 24" {...rest} unit={unit}>
		<title>Edit</title>
		<path d="M0,19.0129875 L14.711687,4.30130049 L19.6986995,9.28831303 L4.98701253,24 L0,24
			L0,19.0129875 Z M23.5636342,5.42337831 L21.070128,7.91688458 L16.0831154,2.92987205
			L18.5766217,0.436365779 C19.1584427,-0.14545526 19.761034,-0.14545526 20.3844137,0.436365779
			L23.5636342,3.61558627 C24.1454553,4.23896595 24.1454553,4.84155727 23.5636342,5.42337831 Z"
		/>
	</SvgIconContainer>
);