/* eslint max-len: 0 */
// @flow
import React from "react";
import SvgIconContainer from "../SvgIconContainer";

export default (
	{
		unit = "em",
		...rest
	}: Object = {}) => (
	<SvgIconContainer viewBox="0 0 28 19" {...rest} unit={unit}>
		<title>Compare players</title>
		<defs>
			<path d="M29.0105,21.015 C27.57359,20.0365873 26.0245302,
			19.2340239 24.3965,18.6245 C24.0534024,18.484074 23.815742,
			18.1658475 23.7785,17.797 L23.705,16.813 C23.6944769,16.7049644 23.7341005,
			16.5980729 23.8125,16.523 C24.2265012,16.1315876 24.4728878,15.5950919 24.5,
			15.026 L24.5,14.756 C24.8295,14.756 25.1855,13.9225 25.1855,13.323 C25.1855,
			12.7235 24.9745,12.561 24.813,12.561 C24.7703207,12.5609307 24.7276978,
			12.5641065 24.6855,12.5705 C24.7854332,12.1210493 24.8407018,11.6628222 24.8505,
			11.2025 C24.8505,9.1905 24.5155,8.3745 23.5105,8.3745 C23.0116919,7.7871518 22.2698967,
			7.46265097 21.5,7.495 C18.4845,7.495 18.15,8.9595 18.15,11.2025 C18.1598771,
			11.6627885 18.2149753,12.1209915 18.3145,12.5705 C18.2723064,12.5640697 18.2296807,
			12.5608936 18.187,12.561 C18.026,12.561 17.815,12.7235 17.815,13.323 C17.815,
			13.9225 18.171,14.756 18.5005,14.756 L18.5005,15.026 C18.5320963,15.6005144 18.7913997,
			16.1387354 19.221,16.5215 C19.3046124,16.5976355 19.3469502,16.7090507 19.335,
			16.8215 L19.258,17.7995 C19.2135912,18.1753742 18.9626606,18.4948282 18.608,
			18.627 C18.1515,18.7945 17.588,19.021 16.9965,19.277 C17.5337171,19.5566124 18.051549,
			19.8719887 18.5465,20.221 C19.5386113,21.006545 20.0808116,22.2298609 19.9965,
			23.4925 L19.9965,25 C19.994972,25.1710979 19.9624463,25.3405024 19.9005,
			25.5 L29.5,25.5 C29.7761424,25.5 30,25.2761424 30,25 L30,23.505 C29.9885,
			22.6235 29.844,21.65 29.0105,21.015 Z M18.0105,21.015 C16.57359,20.0365873 15.0245302,
			19.2340239 13.3965,18.6245 C13.0535433,18.4838921 12.8159564,18.165764 12.7785,
			17.797 L12.705,16.813 C12.6944769,16.7049644 12.7341005,16.5980729 12.8125,
			16.523 C13.2265012,16.1315876 13.4728878,15.5950919 13.5,15.026 L13.5,14.756 C13.8295,
			14.756 14.1855,13.9225 14.1855,13.323 C14.1855,12.7235 13.9745,12.561 13.813,
			12.561 C13.7703207,12.5609307 13.7276978,12.5641065 13.6855,12.5705 C13.7854332,
			12.1210493 13.8407018,11.6628222 13.8505,11.2025 C13.8505,9.1905 13.5155,8.3745 12.51,
			8.3745 C12.0113016,7.78729354 11.2697241,7.46280727 10.5,7.495 C7.4845,7.495 7.15,
			8.9595 7.15,11.2025 C7.15979819,11.6628222 7.2150668,12.1210493 7.315,
			12.5705 C7.27263654,12.564087 7.22984602,12.5609112 7.187,12.561 C7.0255,
			12.561 6.815,12.7235 6.815,13.323 C6.815,13.9225 7.171,14.756 7.5005,14.756 L7.5005,
			15.026 C7.52761218,15.5950919 7.77399884,16.1315876 8.188,16.523 C8.26639949,
			16.5980729 8.30602308,16.7049644 8.2955,16.813 L8.222,17.797 C8.18454361,
			8.165764 7.94695671,18.4838921 7.604,18.6245 C5.97596975,19.2340239 4.42691002,
			20.0365873 2.99,21.015 C2.1565,21.6515 2.012,22.6235 2.0005,23.5055 L2.0005,
			25 C2.0005,25.2761424 2.22435763,25.5 2.5005,25.5 L18.5005,25.5 C18.7766424,
			25.5 19.0005,25.2761424 19.0005,25 L19.0005,23.505 C18.9885,22.6235 18.844,
			21.65 18.0105,21.015 Z" id="path-1" />
		</defs>
		<g stroke="none" strokeWidth="1" fillRule="evenodd">
			<g transform="translate(-136.000000, -11.000000)">
				<g transform="translate(134.000000, 4.000000)">
					<mask fill="white">
						<use xlinkHref="#path-1" />
					</mask>
					<use fillRule="nonzero" xlinkHref="#path-1" />
				</g>
			</g>
		</g>
	</SvgIconContainer>
);