import styled from "styled-components";
import { FlexDivColCenter } from "../../FlexDivColCenter";
import colors from "../../../assets/css/colors";

export const FormScoreWrap = styled.div`
    margin: 0 0 20px 0;
`;

export const FormScoreTitleWrap = styled.div`
    display: flex;
    height: 40px;
    justify-content: space-between;
`;

export const FormScoreTitle = styled(FlexDivColCenter)`
    color: #1D4073;
    font-family: "SourceSansPro";
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 12px;
`;

export const FlexFormScoreTitle = styled(FormScoreTitle)`
	display: flex;
	flex-direction: row;
`;

export const FormScoreTitleChild = styled(FormScoreTitle)`
	margin-left: 8px;
`; 

export const FormScoreTitleRightWrap = styled(FormScoreTitle)`
    margin-right: 30px;
`;

export const FormScoreTitleRightWrapScoring = styled(FormScoreTitle)`
    margin-right: 95px;
`;

export const FormFieldCheckboxWrapDiv = styled.div`
	color: ${({ is_disabled }) => (is_disabled ? "red" : "inherit")};
	display: flex;
	height: 40px;
	margin: 0;
	justify-content: space-between;
	width: 100%;
	padding: 0 10px;
	box-sizing: border-box;
	&:nth-child(even) {
		background-color: ${colors.form.base};
	}
`;

export const FieldWrapDiv = styled.div`
	display: flex;
	justify-content: inherit;
	width: 100%;
`;

export const FormInputCheckbox = styled.input``;

export const FormCheckboxLabel = styled.label`
	font-size: 12px;
	letter-spacing: 0;
	line-height: 20px;
	text-transform: initial;
	display: flex;
`;

export const FormCheckboxLabelSpan = styled.span`
	padding: 0 5px;
	color: ${colors.primary.primary};
	font-family: "SourceSansPro";
	font-size: 12px;
	font-weight: 600;
	letter-spacing: 0;

	display: flex;
	justify-content: center;
	flex-direction: column;
`;

export const PointsSelect = styled.select`
	border: 1px solid ${colors.form.borderColor};
	color: ${colors.secondary.accentGrey};
	height: 30px;
	width: 90px;
`;

export const LockedIconWrap = styled.div`
	background-color: #000;
	height: 20px;
	width: 20px;
	border-radius: 50%;
	text-align: center;
	& svg {
		margin-top: 2px;
	}
	
`;