/* eslint max-len: 0 */
// @flow
import React from "react";
import SvgIconContainer from "../SvgIconContainer";

import colors from "../../assets/css/colors";

export default (
	{
		unit = "em",
		...rest
	}: Object = {}) => (
	<SvgIconContainer viewBox="0 0 24 24" color={colors.buttons.cancel}  {...rest} unit={unit}>
		<title>Delist</title>
		<path d="M17.4086332,4.08955937 C17.5174994,4.07328702 17.6279377,4.10456159
			17.7145834,4.1762002 C17.8012291,4.24783881 17.8566357,4.35368472 17.868078,4.46942808
			L17.9935489,5.36685572 C18.0332399,5.60679935 17.8858126,5.83676468 17.6604975,5.88636769
			L6.58953788,7.87603995 C6.48122708,7.89204032 6.37141033,7.8609447 6.28511441,7.78983965
			C6.19881848,7.7187346 6.14339578,7.61367826 6.13147692,7.49861255 L6.00646734,6.60118491
			C5.96675029,6.36139633 6.11392927,6.13149999 6.33905742,6.08167294 L9.50212283,5.51333466
			L9.37711325,4.61590702 C9.29765754,4.13621706 9.59197125,3.67627747 10.0422934,3.57639482
			L13.2053588,3.00805653 C13.4215263,2.97646502 13.6405884,3.03858142 13.8128862,3.18032463
			C13.985184,3.32206784 14.0961243,3.53143263 14.1205582,3.76095828 L14.246029,4.65789765
			L17.4086332,4.08955937 Z M16.5833333,8.2923665 C16.813452,8.2923665 17,8.47007699
			17,8.68929398 L17,19.4122901 C17,20.289158 16.2538079,21 15.3333333,21 L8.66666667,21
			C7.74619208,21 7,20.289158 7,19.4122901 L7,8.68929398 C7,8.47007699 7.18654802,8.2923665
			7.41666667,8.2923665 L16.5833333,8.2923665 Z M10.0515609,19.0797311 L10.0515609,9.94523886
			C10.0515609,9.83563036 9.92530341,9.74677512 9.76955691,9.74677512 L9.20554895,9.74677512
			C9.04980245,9.74677512 8.92354497,9.83563036 8.92354497,9.94523886 L8.92354497,19.0797311
			C8.92354497,19.1893396 9.04980245,19.2781949 9.20554895,19.2781949 L9.76955691,19.2781949
			C9.92530341,19.2781949 10.0515609,19.1893396 10.0515609,19.0797311 Z M12.5895967,19.0797311
			L12.5895967,9.94523886 C12.5895967,9.83563036 12.4633392,9.74677512 12.3075927,9.74677512
			L11.7435848,9.74677512 C11.5878383,9.74677512 11.4615808,9.83563036 11.4615808,9.94523886
			L11.4615808,19.0797311 C11.4615808,19.1893396 11.5878383,19.2781949 11.7435848,19.2781949
			L12.3075927,19.2781949 C12.4633392,19.2781949 12.5895967,19.1893396 12.5895967,19.0797311 Z
			M15.1276325,19.0797311 L15.1276325,9.94523886 C15.1276325,9.83563036 15.0013751,9.74677512
			14.8456286,9.74677512 L14.2816206,9.74677512 C14.1258741,9.74677512 13.9996166,9.83563036
			13.9996166,9.94523886 L13.9996166,19.0797311 C13.9996166,19.1893396 14.1258741,19.2781949
			14.2816206,19.2781949 L14.8456286,19.2781949 C15.0013751,19.2781949 15.1276325,19.1893396
			15.1276325,19.0797311 Z" />
	</SvgIconContainer >
);