// @flow
import { createSelectorCreator, defaultMemoize } from "reselect";
import * as _ from "lodash";
import * as roundsSelector from "../rounds";
import { TDraftLineup, TRound, TRoundWithMatches } from "../../types";
import { getPlayerScoreByRound } from '../../../helpers/players';
import { getMatchUsingSquadId } from "../../../helpers/rounds";

const AVATAR_PATH = process.env.REACT_APP_AVATARS_PATH || "";

type PropsAvatar = {
	user_id: number,
	avatar_version: number
};

export const getAvatar = ({ user_id, avatar_version }: PropsAvatar) => {
	return user_id ? `${AVATAR_PATH}${user_id}.png?v=${avatar_version}` : "UserAvatar";
};

const createDeepEqualSelector = createSelectorCreator(
	defaultMemoize,
	_.isEqual
);
/* FROM COMPLEX UTILS - SHOULD PROBABLY JUST REFERENCE THEM, OR MOVE SELECTOR TO THERE */
const isNotPlay = points => ["DNP", "BYE"].includes(String(points).toUpperCase());
const getPointLastRound = ({ is_not_scored_yet, score = "DNP" }) => (
	is_not_scored_yet ? "" : score
);
const isNotScoredYet = ({ is_preseason, has_score, is_match_active, is_match_scheduled }) => {
	return is_preseason || is_match_scheduled || (is_match_active && !has_score);
};
const getPointsLastRound = (round, player_squad_match, player) =>  {
	if(_.isEmpty(_.values(round))) {
		return player;
	}

	const
		{ id, status } = round,
		is_preseason = _.eq(id, 1) && _.eq(status, "scheduled"),
		score = getPlayerScoreByRound(player, id),
		has_score = !_.isUndefined(score),
		match_status = _.get(player_squad_match, "status"),
		is_match_active = _.eq(match_status, "playing"),
		is_match_scheduled = _.eq(match_status, "scheduled"),
		is_not_scored_yet = isNotScoredYet({
			is_match_scheduled, is_match_active, is_preseason, has_score
		}),
		is_bye = _.get(round, "bye_squads", []).includes(player.squad_id),
		points_last_round = is_bye ? "BYE" : getPointLastRound({
			is_not_scored_yet,
			score
		});

	return {
		...player,
		is_playing_now: is_match_active && id > 0,
		is_dnp: isNotPlay(points_last_round),
		stats: {
			...player.stats,
			points_last_round,
		}
	};
};

const getSquads = state => state.squads.by_id;



const getSquadByes = createDeepEqualSelector(
	roundsSelector.getByeRounds,
	getSquads,
	(bye_rounds: Object, squads: Object) => {
		const squad_byes = {};
		_.forEach(squads, squad => {
			const round = _.find(bye_rounds, round => round && round.bye_squads.includes(squad.id));
			squad_byes[squad.id] = round && round.id;
		});
		return squad_byes;
	}
);

const getSquadMatches = createDeepEqualSelector(
	roundsSelector.getRoundsWithMatches,
	roundsSelector.getLastRound,
	getSquads,
	(rounds: TRoundWithMatches[], round: TRound, squads: Object) => {
		const squad_matches = {};
		_.forEach(squads, squad => {
			const match = getMatchUsingSquadId(squad.id, round.matches);
			squad_matches[squad.id] = match;
		});
		return squad_matches;
	}
);

export const getPlayerIdsInLineup = createDeepEqualSelector(
	state => state.teamsDraft.user_team.lineup,
	(lineup: TDraftLineup) => {
		/* Sort so that substitution doesn't cause re-calculation */
		return _.sortBy(_(lineup)
			.omit(["emergency", "captain", "vice_captain"])
			.reduce((all, pos) => [...all, ...pos], [])
		);
	}
);

export const getDraftTeamExtendedPlayersById = createDeepEqualSelector(
	getPlayerIdsInLineup,
	state => state.players.by_id,
	state => state.players.coach_by_id,
	state => state.squads.by_id,
	state =>  state.rounds_by_id,
	roundsSelector.getLastRound,
	getSquadByes,
	getSquadMatches,
	(
		lineup: number[], 
		by_id: Object, 
		coach_by_id: Object, 
		squads: Object, 
		rounds: {
			[id: number | string]: TRound,
		},
		round: TRound,
		squad_byes: Object,
		squad_matches: Object
	) => {
		const players_by_id = {};
		_(lineup).forEach(player_id => {
			const player = by_id[player_id] || {};
			const coach = coach_by_id[player_id] || {};
			const player_stats = {
				...player,
				stats: {
					...player.stats,
					...coach,
					bye_round_id: squad_byes[player.squad_id]
				}
			};

			players_by_id[player.id] = getPointsLastRound(
				round, 
				squad_matches[player.squad_id], 
				player_stats
			);
		});
		return players_by_id;
	}
);