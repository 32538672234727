// @flow
import styled from "styled-components";

export const SearchInputWrapper = styled.div`
	display: flex;
	position: relative;
	width: 100%;
	height: 40px;
	min-width: 200px;
`;

export default SearchInputWrapper;