export default () => {
	window.fbAsyncInit = () => {
		if (window.FB) {
			window.FB.init({
				appId: process.env.REACT_APP_FACEBOOK_APP_ID,
				autoLogAppEvents: true,
				xfbml: true,
				version: "v3.0"
			});
		}
	};

	((d, s, id) => {
		let js = {};
		const fjs = d.getElementsByTagName(s)[0];
		if (d.getElementById(id)) {
			return;
		}
		js = d.createElement(s);
		js.id = id;
		js.src = "https://connect.facebook.net/en_US/sdk.js";
		fjs.parentNode.insertBefore(js, fjs);
	})(document, "script", "facebook-jssdk");
};