// @flow
import React from "react";
import SvgIconContainer from "../SvgIconContainer";

export default (
	{
		unit = "em",
		...rest
	}: Object = {}) => (
	<SvgIconContainer viewBox="0 0 24 24" {...rest} unit={unit}>
		<g  stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<path 
				d="M6.90909091,5 L17.0909091,5 C18.6955664,5.00495158 19.9952076,
					6.34775017 20,8.00569352 L20,14.0170806 C19.9947363,15.5483058 18.8799756,
					16.8325471 17.4072727,17.0039885 C17.2254107,17.0264109 17.0890621,
					17.1867184 17.0909091,17.3759431 L17.0909091,20.6220921 C17.0917682,
					20.7747306 17.0031623,20.9127351 16.8667205,20.9712676 C16.7302788,
					21.0298001 16.5731238,20.9972251 16.4690909,20.8888474 L12.9418182,
					17.2406869 C12.8037093,17.1013774 12.6184664,17.0232023 12.4254545,
					17.0227741 L6.90909091,17.0227741 C5.30443365,17.0178225 4.00479244,
					15.6750239 4,14.0170806 L4,8.00569352 C4.00479244,6.34775017 5.30443365,
					5.00495158 6.90909091,5 L6.90909091,5 Z" 
				id="Bubble" fill="#FFFFFF" fillRule="nonzero" 
			/>
		</g>
	</SvgIconContainer>
);