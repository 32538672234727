// @flow
import _ from "lodash";

import { 
	getTeamProjectedScore, 
	getTeamScoreFromLadder, 
	getLiveDraftTeamScore } 
from "../../../helpers/team";
import { scoreflowToForm } from "../../../helpers/league";
import { TPLeague, TRound, TPlayersById, TClassicTeamsByID } from "../../../modules/types";

const createFixture = function(
	team_ids: [number, number],
	ladder_teams_by_id: TClassicTeamsByID,
	actual_round: TRound,
	players_by_id: TPlayersById,
	league: TPLeague,
	is_classic: boolean
) {
	const home_team_id = _.get(team_ids, "0");
	const away_team_id = _.get(team_ids, "1");
	const home_team = _.get(ladder_teams_by_id, home_team_id, {});
	const away_team = _.get(ladder_teams_by_id, away_team_id, {});
	const round_id = _.get(actual_round, "id");
	const is_active_match = actual_round.status === "active";
	const custom_scoring_enabled = _.get(league, "custom_scoring_enabled", 0);
	const home_scoring_players = 
    _.get(home_team, `scoring_players[${round_id}]`, { captain: 0, scoring_players: [] });
	const away_scoring_players = 
    _.get(away_team, `scoring_players[${round_id}]`, { captain: 0, scoring_players: [] });
  

	const home_points = is_classic ? getTeamScoreFromLadder({
		round: actual_round,
		team: home_team,
		players_by_id,
		is_active_match,
		is_classic,
	}) : getLiveDraftTeamScore({
		is_active_match,
		round: actual_round,
		team: home_team,
		players_by_id,
		custom_scoring_enabled,
		scoring_players: home_scoring_players
	});
	const away_points = is_classic ? getTeamScoreFromLadder({
		round: actual_round,
		team: away_team,
		players_by_id,
		is_active_match,
		is_classic,
	}) : getLiveDraftTeamScore({
		team: away_team,
		round: actual_round,
		players_by_id,
		custom_scoring_enabled,
		scoring_players: away_scoring_players,
		is_active_match
	});

	// get WLD for last 5 last 5
	const home_wld = scoreflowToForm(home_team, round_id, actual_round);
	const away_wld = scoreflowToForm(away_team, round_id, actual_round);


	const home_lineup_project_score = getTeamProjectedScore(
		home_team.lineup, 
		players_by_id, 
		league, 
		actual_round, 
		is_classic
	);
	const away_lineup_project_score = getTeamProjectedScore(
		away_team.lineup, 
		players_by_id,
		league, 
		actual_round,
		is_classic
	);

	const fixture = {
		home: {
			id: home_team.id,
			name: home_team.name,
			firstname: home_team.firstname,
			lastname: home_team.lastname,
			points: home_points,
			projected_points: home_lineup_project_score,
			lineup: home_team.lineup,
			rank: home_team.rank,
			user_id: home_team.user_id,
			avatar_version: home_team.avatar_version,
			record_last_five: home_wld
		},
		away: {
			id: away_team.id,
			name: away_team.name,
			firstname: away_team.firstname,
			lastname: away_team.lastname,
			points: away_points,
			projected_points: away_lineup_project_score,
			lineup: away_team.lineup,
			rank: away_team.rank,
			user_id: away_team.user_id,
			avatar_version: away_team.avatar_version,
			record_last_five: away_wld
		}
	};

	return fixture;
};

export default createFixture;