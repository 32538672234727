// @flow
import { createAction } from 'redux-act';

export const fetchPlayers = createAction('Fetch Players');
export const fetchPlayersSuccess = createAction('Fetch Players Success');
export const fetchPlayersFailed = createAction('Fetch Players Failed');

export const fetchCoachPlayers = createAction('Fetch Coach Players');
export const fetchCoachPlayersSuccess = createAction('Fetch Coach Players Success');
export const fetchCoachPlayersFailed = createAction('Fetch Coach Players Failed');

export const fetchFavourites = createAction('Fetch Favourites');
export const fetchFavouritesSuccess = createAction('Fetch Favourites Success');
export const fetchFavouritesFailed = createAction('Fetch Favourites Failed');

export const postAddToFavourites = createAction('Post Add To Favourites');
export const postAddToFavouritesSuccess = createAction('Post Add To Favourites Success');
export const postAddToFavouritesFailed = createAction('Post Add To Favourites Failed');

export const postRemoveFromFavourites = createAction('Post Add From Favourites');
export const postRemoveFromFavouritesSuccess = createAction('Post Add From Favourites Success');
export const postRemoveFromFavouritesFailed = createAction('Post Add From Favourites Failed');

export const fetchPastYearPlayers = createAction('Fetch Past Year Players');
export const fetchPastYearPlayersSuccess = createAction('Fetch Past Year Players Success');
export const fetchPastYearPlayersFailed = createAction('Fetch Past Year Players Failed');

export const fetchPastYearPlayerMatches = createAction('Fetch Past Year Player Matches');
export const fetchPastYearPlayerMatchesSuccess = createAction(
	'Fetch Past Year Player Matches Success'
);
export const fetchPastYearPlayerMatchesFailed = createAction(
	'Fetch Past Year Player Matches Failed'
);

export const fetchPlayerMatches = createAction('Fetch Player Matches');
export const fetchPlayerMatchesSuccess = createAction('Fetch Player Matches Success');
export const fetchPlayerMatchesFailed = createAction('Fetch Player Matches Failed');

export const fetchPlayersMatches = createAction('Fetch Array of Players Matches');
export const fetchPlayersMatchesSuccess = createAction('Fetch Array of Players Matches Success');
export const fetchPlayersMatchesFailed = createAction('Fetch Array of Players Matches Failed');

export const fetchAllStats = createAction('Fetch Array of All Players Season Stats');
export const fetchAllStatsSuccess = createAction('Fetch Array of All Players Season Stats Success');
export const fetchAllStatsFailed = createAction('Fetch Array of All Players Season Stats Failed');

export const fetchAllCustomStatsLoad = createAction(
	'Loadng State on Fetch Array of All Players Custom Season Stats'
);
export const fetchAllCustomStats = createAction('Fetch Array of All Players Custom Season Stats');
export const fetchAllCustomStatsSuccess 
    = createAction('Fetch Array of All Players Custom Season Stats Success');
export const fetchAllCustomStatsFailed 
    = createAction('Fetch Array of All Players Custom Season Stats Failed');

export const fetchAllCoachCustomStats
    = createAction('Fetch Array of All Players Custom Season Coach Stats');
export const fetchAllCoachCustomStatsSuccess 
    = createAction('Fetch Array of All Players Custom Season Coach Stats Success');
export const fetchAllCoachCustomStatsFailed 
    = createAction('Fetch Array of All Players Custom Season Coach Stats Failed');

export const fetchPlayerVenueStats = createAction("Fetch Player Venue Stats");
export const fetchPlayerVenueStatsSuccess = createAction("Fetch Player Venue Stats Success");
export const fetchPlayerVenueStatsFailed = createAction("Fetch Player Venue Stats Failed");

export const fetchPlayerVenueStatsDraft = createAction("Fetch Player Venue Stats Draft");
export const fetchPlayerVenueStatsDraftSuccess 
	= createAction("Fetch Player Venue Stats Draft Success");
export const fetchPlayerVenueStatsDraftFailed 
	= createAction("Fetch Player Venue Stats Draft Failed");

export const fetchPlayerOppositionStats 
	= createAction("Fetch Player Opposition Stats");
export const fetchPlayerOppositionStatsSuccess 
	= createAction("Fetch Player Opposition Stats Success");
export const fetchPlayerOppositionStatsFailed 
	= createAction("Fetch Player Opposition Stats Failed");

export const fetchPlayerOppositionStatsDraft 
	= createAction("Fetch Player Opposition Stats Draft");
export const fetchPlayerOppositionStatsDraftSuccess 
	= createAction("Fetch Player Opposition Stats Draft Success");
export const fetchPlayerOppositionStatsDraftFailed 
	= createAction("Fetch Player Opposition Stats Draft Failed");