// @flow
import * as React from "react";
import styled, { css } from "styled-components";
import _ from "lodash";
import { connect } from "react-redux";
import { below } from "../../assets/css/media";
import colors from "../../assets/css/colors";
import { isMobile } from "../../utils";
import * as selectors from "../../modules/selectors";
import {
	TeamForTeamToTeamTrade,
	PlayersOnTrade,
	MobileContentTabs,
	Tab,
	Tabs,
	TradeSectionWrapper,
	DraftFilters
} from "../";
import * as actions from "../../modules/actions";
import type { 
	TTeamsDraftTradesReducer, 
	TRootStore, 
	TPosition, 
	TPlayerStatusesReducer, 
	TCoachStatsListReducer,
	TPLeague
} from "../../modules/types";

// import * as actions from '../../modules/actions';
//import { MAX_TRADES } from '../../helpers/team';

const MAX_TRADES = 5;

type TFilters = {
	search: string,
	by_squad: string,
	by_position: string,
	by_status: string,
	by_dpp: boolean,
	by_favourites: boolean,
	by_drafted: boolean,
	by_fa: boolean,
	by_owner: string
}

type Props = {
	team_trade: TTeamsDraftTradesReducer,
	cancelTradeRequest: Function,
	league: TPLeague,
	//fetchMakeTrade: typeof actions.leagueDraft.fetchMakeTrade,
	// NOT SURE WHY THE ABOVE ISN'T WORKING, TEMP FIX BELOW
	fetchMakeTrade: Function,
	MAX_TRADES: number,
  squads: Array<Object>,
  positions: Array<TPosition>,
  playerStatuses: TPlayerStatusesReducer,
  coach_stats_list: TCoachStatsListReducer,
  fetchAllStats: Function,
};

type State = {
	team_id: number,
	team_trade: TTeamsDraftTradesReducer,
	show_confirm_modal: boolean,
	is_mobile: boolean,
	is_user: boolean,
	active_tab: string,
  filters: TFilters,
  active_stats_type: string,
};
type TId = {
	id: number
}
const TeamToTeamTradeWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	> div {
		background: ${props => props.is_user === true ?
		colors.form.base:colors.white};
		width: 49%;
	}

	${below.tablet`
		${css`
			width: 100%;
			justify-content: flex-start;

			> div {
				width: 100%;
				margin-right: 0px;
			}
		`}
	`}
`;

const TeamToTeamTradeWrapperOverflow = styled.div`
	width: 100%;
`;

export class TeamToTeamTradeComponent extends React.Component<Props, State> {
	static defaultProps = {
		MAX_TRADES: MAX_TRADES
	};

	constructor(props: Props, state: State) {
		super(props, state);

		_.bindAll(this, [
			"handleTeamSelect",
			"removeOutPlayer",
			"removeInPlayer",
			"addOutTrade",
			"addInTrade",
			"makeTeamToTeamTrade",
			"resize",
			"changeView",
			"handleTabSelect",
			"onFiltersChange",
			"onSearchSubmit",
			"setSelectedStatsType",
			"resetFilters",
		]);
	}

	state = {
		team_id: 0,
		team_trade: {
			old_player_id: [],
			new_player_id: []
		},
		filters: {
			search: "",
			by_squad: "",
			by_position: "",
			by_status: "",
			by_dpp: false,
			by_owner: "",
			by_favourites: false,
			by_drafted: false,
			by_fa: false
		},
		show_confirm_modal: false,
		is_mobile: false,
		is_user: true,
		active_tab: "your_team",
		active_stats_type: "general-stats",
	};

	componentDidMount() {
		const { team_trade, fetchAllStats } = this.props;
		window.addEventListener("resize", this.resize);
		this.resize();
		fetchAllStats();

		this.setState({
			team_id: _.get(team_trade, "owner_id", 0),
			team_trade
		});
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.resize);
	}

	formElementToKeyValueObject(form: any) {
		return Array.from(form.elements).reduce(this.accumulateFormInputsValueByName, {});
	}
	accumulateFormInputsValueByName(accumulator: Object, formElement: Object) {
		const { type, name, checked, value } = formElement;

		if(_.eq(type, "submit")) {
			return accumulator;
		}

		if (!name) {
			throw new Error("All form inputs should be have \"name\" attribute!");
		}

		accumulator[name] = _.eq(type, "checkbox") ? checked : value;

		return accumulator;
	}

	onFiltersChange(event: any) {
		const { filters } = this.state;
		const new_filters = this.formElementToKeyValueObject(event.currentTarget);

		this.setState({
			filters: {
				...filters,
				...new_filters
			}
		});
	}

	onSearchSubmit(event: any) {
		event.preventDefault();
	}

	setSelectedStatsType(event: any) {
		const { value } = event.target;
		this.setState({ active_stats_type: value });
	}

	resetFilters() {
		this.setState({
			filters: {
				search: "",
				by_squad: "",
				by_position: "",
				by_status: "",
				by_dpp: false,
				by_owner: "",
				by_favourites: false,
				by_drafted: false,
				by_fa: false
			},
			active_stats_type: "general-stats"
		});
	}

	removeOutPlayer({ id }: TId) {
		const { team_trade } = this.state;
		const { old_player_id } = team_trade;
		this.setState({
			team_trade: {
				...team_trade,
				old_player_id: _.xor(old_player_id, [id])
			}
		});
	}

	removeInPlayer({ id }: TId) {
		const { team_trade } = this.state;
		const { new_player_id } = team_trade;
		this.setState({
			team_trade: {
				...team_trade,
				new_player_id: _.xor(new_player_id, [id])
			}
		});
	}

	addOutTrade({ id }: TId) {
		const { MAX_TRADES } = this.props;
		const { team_trade } = this.state;
		const { old_player_id } = team_trade;
		const new_old_player_id = _.xor(old_player_id, [id]);

		if (new_old_player_id.length > MAX_TRADES) {
			return null;
		}

		this.setState({
			team_trade: {
				...team_trade,
				old_player_id: new_old_player_id
			}
		});
	}

	addInTrade({ id }: TId) {
		const { MAX_TRADES } = this.props;
		const { team_trade } = this.state;
		const { new_player_id } = team_trade;
		const new_new_player_id = _.xor(new_player_id, [id]);

		if (new_new_player_id.length > MAX_TRADES) {
			return null;
		}
		this.setState({
			team_trade: {
				...team_trade,
				new_player_id: new_new_player_id
			}
		});
	}

	makeTeamToTeamTrade() {
		const { league, fetchMakeTrade } = this.props;
		const { team_trade } = this.state;
		const { new_player_id, old_player_id } = team_trade;
		fetchMakeTrade({
			league_id: league.id,
			new_player_id,
			old_player_id
		});

		// cancelTradeRequest();
	}

	handleTeamSelect(event: Object) {
		const { value } = event.target,
			team_id = parseInt(value, 10);
		const { team_trade } = this.state;

		this.setState({
			team_id,
			team_trade: {
				...team_trade,
				new_player_id: []
			}
		});
	}

	resize() {
		this.setState({
			is_mobile: isMobile()
		});
	}

	changeView(is_user: boolean) {
		this.setState({ is_user });
	}
	handleTabSelect = (selected_tab: string) => {
		this.setState({
			active_tab: selected_tab
		});
	};
	getIsTabActiveClass = (tab: string) => this.state.active_tab === tab ? "active" : "";

	render() {
		const { 
			cancelTradeRequest, 
			league, 
			squads, 
			positions, 
			playerStatuses, 
			coach_stats_list 
		} = this.props;
		const { team_id, team_trade, is_mobile, is_user, active_stats_type, filters } = this.state;
		if (_.isEmpty(team_trade)) {
			return null;
		}
		const oppositionTeamText = "Opposition team";
		const yourTeamText = "Your Team";

		const hide_rfa = !league.trade_waivers;
		/*
		Changed in https://fanhub.atlassian.net/browse/AFD21-300
		const OppositionTeamTxt = team_id === 0?
			`Select a team to trade with` :
			`Add more player(s) from |OTHERTEAM| to this trade`
		*/
		return (
			<div>
				<PlayersOnTrade
					league={league}
					team={team_trade}
					type="team"
					cancelRequest={cancelTradeRequest}
					removeOutPlayer={this.removeOutPlayer}
					removeInPlayer={this.removeInPlayer}
					makeTeamToTeamTrade={this.makeTeamToTeamTrade}
				/>
				<DraftFilters
					is_trade_page
					handleChange={this.onFiltersChange}
					handleSubmit={this.onSearchSubmit}
					squads={squads}
					positions={positions}
					statuses={playerStatuses}
					hide_rfa={hide_rfa}
					is_hide_sort
					is_show_owner={false}
					selectOptions={[
						{ key: "general-stats",value: "General Stats" },
						...coach_stats_list
					]}
					onSelectChange={this.setSelectedStatsType}
					active_stats_type={active_stats_type}
					league={league}
					resetFilters={this.resetFilters}
					filters={filters}
				/>
				<TeamToTeamTradeWrapperOverflow>
					<MobileContentTabs>
						<Tabs>
							<Tab 
								is_active={this.state.active_tab === "your_team"} 
								onClick={() => this.handleTabSelect("your_team")}>
								{yourTeamText}
							</Tab>
							<Tab 
								is_active={this.state.active_tab === "opposition_team"} 
								onClick={() => this.handleTabSelect("opposition_team")}>
								{oppositionTeamText}
							</Tab>
						</Tabs>
					</MobileContentTabs>
					<TeamToTeamTradeWrapper is_user={is_user}>
						<TradeSectionWrapper 
							is_active={this.state.active_tab === "your_team"}>
							<TeamForTeamToTeamTrade
								league={league}
								is_user
								team_trade={team_trade}
								addOutTrade={this.addOutTrade}
								addInTrade={this.addInTrade}
								removeOutPlayer={this.removeOutPlayer}
								removeInPlayer={this.removeInPlayer}
								changeView={is_mobile && !is_user ? this.changeView : null}
								table_header_title={yourTeamText}
								active_stats_type={active_stats_type}
							/>
						</TradeSectionWrapper>
						
						<TradeSectionWrapper 
							is_active={this.state.active_tab === "opposition_team"}>
							<TeamForTeamToTeamTrade
								league={league}
								is_user={false}
								team_id={team_id}
								team_trade={team_trade}
								handleTeamSelect={this.handleTeamSelect}
								addOutTrade={this.addOutTrade}
								addInTrade={this.addInTrade}
								removeOutPlayer={this.removeOutPlayer}
								removeInPlayer={this.removeInPlayer}
								changeView={is_mobile && is_user ? this.changeView : null}
								table_header_title={oppositionTeamText}
								active_stats_type={active_stats_type}
							/>
						</TradeSectionWrapper>
						

					</TeamToTeamTradeWrapper>
				</TeamToTeamTradeWrapperOverflow>

			</div>

		);
	}
}
const mapStateToProps = (state: TRootStore, props) => {
	return {
		squads: selectors.squads.getSquads(state),
		positions: selectors.positions.getPositionsArray(state),
		playerStatuses: state.playerStatuses,
		coach_stats_list: state.coachStatsList,
	};
};
const mapDispatchToProps = {
	fetchMakeTrade: actions.teamsDraft.fetchMakeTrade,
	fetchAllStats: actions.fetchAllStats,
};
export const TeamToTeamTrade = connect(
	mapStateToProps,
	mapDispatchToProps,
)(TeamToTeamTradeComponent);

export default TeamToTeamTrade;