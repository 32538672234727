import styled, {css} from "styled-components";
import Table, { THead, Tr,Td, Th as ThUnstyled } from "../../../components/TableX";
import {
	ThStat as ThStatUnstyled, 
	OneColumnLayout as OneColumnLayoutUnstyled 
} from "../../../components";
import { below } from "../../../assets/css/media";
import colors from "../../../assets/css/colors";


export const TableHead = styled(THead)`
	.th-owner, .th-next, .th-stat-cell{
		background: linear-gradient(180deg,rgba(0,0,0,0.05) 0%,rgba(0,0,0,0) 100%);
	}
	.table-header-row{
    border-bottom: 2px solid #cad2d8;
		th
    .absolute-group{
      position: absolute;
      min-width: 380px;
      display: flex;
      flex-direction: row;
      z-index: 98;
      border-bottom: 0;
      th{
        border-bottom: none;
      }
    }
    .absolute-group-left{
      position: sticky;
      left: 0;
      min-width: 220px;
      background-color: white !important;
      display: flex;
      flex-direction: row;
      z-index: 96;
      border-bottom: 2px solid #cad2d8;;
      th{
        border-bottom: 2px solid #cad2d8;;
      }
      ${below.phone`
        min-width: 100px;
      `}
    }
    .absolute-group-right{
      position: sticky;
      right: 0;
      background-color: white !important;
      display: flex;
      flex-direction: row;
      z-index: 96;
      border-bottom: 2px solid #cad2d8;;
      th{
        border-bottom: 2px solid #cad2d8;
      }
      ${below.phone`
        min-width: 40px;
        width: 40px;
				
      `}
    }
  }
`;

export const TableWrapper = styled.div`
	width: 100%;
  overflow: auto hidden;
  position: relative;
	${below.tablet`
		tr{
			background-color: white;
		}
		tr:nth-child(even){
			background-color: #f8f8fa;
		}
	`}
	
`;

export const StyledTr = styled(Tr)`
	position: relative;
	z-index: 1;
	box-shadow: 0 0 0px rgba(0, 0, 0, 0);
	

	transition: transform 0.2s linear,
		box-shadow 0.2s linear;

	> td {
		transition: background-color 0.2s linear,
			border-radius 0.2s linear;
      min-width: 50px;
	}
  td.absolute-group{
    position: sticky;
    left: 0;
    min-width: 230px;
		z-index: 1;
	
    ${below.phone`
			background-color: inherit !important;
      min-width: 50px;
      width: 50px
    `}
  }
  th.absolute-group{
    background-color: inherit;
  }
  td.absolute-group-right{
    position: sticky;
    right: 0;
		z-index: 96;
    ${below.phone`
        min-width: 30px;
        width: 30px;
				background: ${({ isWhite }) => isWhite ? "white" : "#f8f8fa"} !important;
    `}
		.buttons-open-mob{
			${below.tablet`
				width: calc(100% + 50px);
				position: absolute;
				display: flex;
				align-items: center;
				height: 100%;
				justify-content: space-evenly;
				top: 0;
				right: 0px;
				box-shadow: -5px 3px 25px -10px #333;
				background: inherit;
			`}
		}
		.two-buttons-open-mob{
			${below.tablet`
				width: calc(100% + 100px);
				position: absolute;
				display: flex;
				align-items: center;
				height: 100%;
				justify-content: space-evenly;
				top: 0;
				right: 0px;
				box-shadow: -5px 3px 25p -10px #333;
				background: inherit;
			`}
		}
  }
  .player-info{
    box-shadow: ${({ is_scrollable }) => 
		is_scrollable ? "rgb(202 210 216) 15px 0px 15px -5px" : 0 }; 
  }
  .shadow-left {
    box-shadow: ${({ is_scrollable }) => 
		is_scrollable ? "rgb(202 210 216) -10px 0px 20px 0px" : 0}; 
    ${below.desktop`
      box-shadow: rgb(202 210 216) -10px 0px 20px 0px;
    `}
  }
	${below.tablet`
		.td-draft-owner, .td-next{
			background: linear-gradient(180deg, rgba(0,0,0,0.05) 0%, rgba(0,0,0,0) 100%) !important;
		}
	`}
`;

export const CostStatWrapper = styled.span`
	color: ${colors.primary.darkGrey};
	${({ is_positive }) => is_positive && `color: ${colors.success};`}
	${({ is_negative }) => is_negative && `color: ${colors.warning};`}
`;

export const ThStat = styled(ThStatUnstyled)`
	width: 40px;
`;

export const Th = styled(ThUnstyled)`
	text-align: center;
	
`;
export const TdStat = styled(Td)`
	width: 40px;
	height: 60px;
	position: relative;
	width: 50px;
	${below.tablet`
		background: linear-gradient(180deg, rgba(0,0,0,0.05) 0%, rgba(0,0,0,0) 100%) !important;
	`}
`;
export const TdAvatar = styled(Td)`
	width: 40px;
	padding: 0 10px;
	> div {
		width: ${({ is_compressed }) => is_compressed ? "25px" : "40px"};
		height: ${({ is_compressed }) => is_compressed ? "25px" : "40px"};
		${({ is_compressed }) => is_compressed && css`
			margin: 0 auto;
		`};
	}
`;
export const TdPlayer = styled(TdStat)`
	width: initial;
	padding: 0;
	${below.tablet`
		&.absolute-group{
			box-shadow: -20px 20px 15px 10px #333;
		}
	`}

	
	.player-info{
		margin: 0;
		margin-left: 0.5em;
	}
`;

export const FcLink = styled.a`
	color: ${colors.coach};
	cursor: pointer;
`;
export const AllStatsWrapper = styled.div`
	display: none;
	${below.tablet`
		display: block;
		margin-bottom: 0;
	`}
`;

export const StatsTable = styled(Table)`
	border-collapse: separate;

	@media screen and (min-width: 768px) and (max-width: 900px) {
		th[rel="owned"], td[rel="owned"] {
			display: none
		}
		td {
			font-size: 11px;
		}

		span.show-desktop {
			display: none;
		}

		span.show-mobile {
			display: inline-block;
		}
	}
	td.mobile-stat, th.mobile-stat {
		display: none;
	}
	${below.tablet`
		& td,
		& th {
			display: table-cell;
		}
	`};

	${({ is_compressed }) => is_compressed && css`
		td[rel="action"] {
			> a, > button {
				height: 20px;
				width: 20px;
				> svg {
					height: 20px;
					width: 20px;
				}
			}
		}
	`}
`;

export const OneColumnLayout = styled(OneColumnLayoutUnstyled)`
	.slide-fields > div:first-child {
		flex-basis: 0;
		display: none;
	}
	${below.desktop`
		> h1 {
			padding: 0;
			margin-left: 15px;
		}
	`}
	${below.tablet`
		.slide-fields > div:first-child {
			flex-basis: 100%;
			max-width: 100%;
			display: block;
			margin: 0;
		}
		.slide-fields > .default-container {
			display: none;
		}
	`}
`;