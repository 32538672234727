// @flow
import * as React from "react";
import styled from "styled-components";
import Close from "../Icons/Close";

const UtilityTooltipWrapper = styled.div`
	background: #198DE1;
	padding: 20px;
	border-radius: 5px;
	position: absolute;
	width: 67%;
	left: 5%;
	
	svg {
		position: absolute;
		right: 10px;
		top: 10px;
	}
	
	&:after {
		content: '';
		position: absolute;
		right: -10px;
		top: 45px;
		width: 20px;
		height: 20px;
		background: #198DE1;
		transform: rotate(45deg);
	}
`;

const Content = styled.div`
	color: #FFFFFF;
	font-family: "SourceSansPro";
	font-size: 14px;
	line-height: 20px;
`;

type IProps = {
	is_show: boolean,
	onClick: Function
}

export const UtilityTooltip = ({ is_show, onClick }: IProps) => {
	return is_show ? (
		<UtilityTooltipWrapper>
			<Close cursor="pointer" size='1.2' color='#ffffff' onClick={onClick} />
			<Content>
				You can select a ‘Utility’ player on your interchange,
				this player can be selected from any position. You must designate a
				position for dual position players should you select the utility as an
				emergency.
			</Content>
		</UtilityTooltipWrapper>
	) : null;
};

export default UtilityTooltip;