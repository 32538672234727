// @flow
import React from "react";
import * as Sentry from "@sentry/react";
import styled from "styled-components";

type Props = {
	children: any
}

type State = {
	error: boolean
}

// eslint-disable-next-line no-unused-vars
const FeedbackContainer = styled.div`
	margin: 100px auto;
	text-align: center;

	p {
		padding-bottom: 20px;
	}
`;

// eslint-disable-next-line no-unused-vars
const ReportFeedback = styled.a`
	border: 1px solid black;
	border-radius: 3px;
	padding: 3px;
	cursor: pointer;
	margin: 20px;
`;

export class AppErrorBoundary extends React.Component<Props, State> {
	static getDerivedStateFromError(error: any) {
		return {};
	}

	state = {
		error: false
	};

	componentDidCatch(error: Error, error_info: any) {
		console.error(error);
		Sentry.withScope(scope => {
			Object.keys(error_info).forEach(key => {
				scope.setExtra(key, error_info[key]);
			});
			Sentry.captureException(error);
		});
	}

	render() {
		// if (this.state.error) {
		// 	//render fallback UI
		// 	return (
		// 		<FeedbackContainer>
		// 			<p>Sorry. Something went wrong!</p>
		// 			<ReportFeedback role="button" 
		// 				onClick={() => Sentry.showReportDialog()} 
		// 				onKeyPress={event => {
		// 					if(event.key === 'Enter'){
		// 						Sentry.showReportDialog();
		// 					}
		// 				}}>
		// 				Report feedback
		// 			</ReportFeedback>
		// 		</FeedbackContainer>
		// 	);
		// }

		return <Sentry.ErrorBoundary fallback={"An error has occurred"}>
			{this.props.children}
		</Sentry.ErrorBoundary>;
	}
}

export default AppErrorBoundary;