// @flow
import _, { get } from "lodash";
import * as React from "react";
import styled, { css } from "styled-components";
import { connect } from "react-redux";
import { below } from "../../assets/css/media";
import { isMobile } from "../../utils/isMobile/index";
import Transactions from "../Icons/Transactions";
import Caution from "../Icons/Caution";
import * as actions from "../../modules/actions";
import colors from "../../assets/css/colors";
import {
	ButtonPrimary,
	withClassicPlayerModal,
	TradeInCellShow,
	TradeOutCellShow,
	TradeInCellClassic,
	TradeOutCell,
	ButtonAlert,
	ButtonAlertLink,
	ModalContainer,
	ModalInnerTitle,
	ButtonSecondary,
	ButtonDisabled,
	ButtonAccent,
	Exist,
	Tooltip
} from "../";
import * as selectors from "../../modules/selectors";
import type { TRound, TPlayersArrayWithFixtureById } from "../../modules/types";
import { isEditTradePage } from "../../helpers";
import { isPlayerStarted } from "./EditClassicUserTrades";

export const ConfirmModalContent = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 1em 2em 40px;
	${below.desktop`
		${css`
			margin: 1em;
		`}
	`}

	${below.tablet`
		${css`
			flex-direction: column;
		`}
	`}
	
	> div > div {
		margin-bottom: 1em;
	}
	
	> svg {
		margin: 0 50px;
	}

`;

const ModalInnerSubTitle = styled.p`
	color: #262424;
	font-family: "SourceSansPro";
	text-align: center;
	max-width: 60%;
	margin: 0 auto 1em;
	font-size: 14px;
	line-height: 1.42;
`;

const TradeCellsWrapper = styled.section`
	display: flex;
	align-items: center;
	margin-bottom: 1em;
	${below.desktop`
		${css`
			justify-content: space-between;
		`}
	`}
`;
const TradeColumn = styled.div`
	width: 440px;
	${below.desktop`
		${css`
			width: 49%;
			.player-table-cell {
				height: 50px;
			}
			.player-details-info {
				flex-direction: column;
			}
		`}
	`}
`;
const EmptyCell = styled.div`
	border-radius: 5px;
	box-shadow: 0 0 10px 0 rgba(127,138,144,0.5);
	color: ${({ inactive }) => inactive ? "#CAD2D8" : "#01285E"};;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 80px;
	margin: 0 0 10px;
	box-sizing: border-box;
	font-size: 14px;
	background: ${({ inactive }) => inactive ? "#f8f8fa" : "#fff"};
	
	&:last-child {
		margin-bottom: 0;
	}
	${below.desktop`
		${css`
			height: 50px;
		`}
	`}
`;

const DisabledEmptyCell = styled(EmptyCell)`
	color: rgb(127, 138, 144);
	text-align: center;
`;

const ButtonsWrapper = styled.div`
	margin: 0 50px;
	${below.desktop`
		${css`
			margin: 0;
			display: flex;
			justify-content: space-between;
			flex-direction: column;
			align-items: center;
			padding-bottom: 20px;
		`}
	`}
	button {
		width: 160px;
		height: 40px;
		display: block;
		margin: 2.5em 0 1em;
		${below.desktop`
			${css`
				margin: 0 auto .5em;
			`}
		`}
	}
`;
const UserTradesWrapper = styled.div`
	border-radius: 2px;
	background-color: #F8F8FA;
	width: 100%;
	box-sizing: border-box;
	padding: 30px ${({ is_compressed_padding }) => is_compressed_padding ? 15 : 50}px;
	margin-bottom: 20px;
	${({ is_compressed_padding }) => is_compressed_padding ? css`
		margin-top: 20px;
		${ButtonsWrapper} {
			margin: 0 15px;
		}
	` : ""}

	/* Don't show the player info value - only show price changes */
	.player-info > .player-details-info > .player-cost > p:first-child {
		display: none;
	}

	/* Full position names are too long - show shortened */
	.player-info > .player-details-info > .player-positions > span {
		display: none;
		&.show-mobile {
			display: inline;
		}
	}
	${({ short_names }) => short_names && css`
		.player-info > .player-details-info > .player-names {
			max-width: 90px;
		}
	`}
	
	${below.desktop`
		${css`
			padding: 10px;
		`}
	`}
`;
const ConfirmButtonsWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 20px;
	
	button {
		width: 200px;
		height: 40px;
		margin: 0 20px;
		
		${below.desktop`
			${css`
				width: 35%;
				font-size: 11px;
				height: 30px;
				padding-top: 9px;
			`}
		`}
	}
`;
const ErrorMessage = styled.p`
	border-radius: 2px;
	background-color: ${colors.warning};
	color: #fff;
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	font-size: 14px;
	strong {
		font-weight: 600;
	}
	${below.desktop`
		${css`
			display: inline-block;
			text-align: center;
			vertical-align: middle;
			box-sizing: border-box;
			padding: 20px 0 0;
		`}
	`}
	
`;
const SuccessMessage = styled(ErrorMessage)`
	background: ${colors.success};
`;
const DisabledDescription = styled.p`
	width: 160px;
	color: ${colors.secondary.accentGrey};
	font-size: 12px;
	font-family: "SourceSansPro";
	text-align: center;
`;

const TradeTitle = styled.p`
	font-family: "SourceSansPro";
	color: ${colors.secondary.accentGrey};
	font-size: 13px;
	margin-bottom: .5em;
	text-align: left;
	font-weight: 600;
`;

const EditTradeDisabledWrapper = styled.div`
	position: relative;
	.edit-trade-disabled{
		cursor: disabled;
	}
	.tooltip-icon, tooltip-text{
		display: none;
	}
	&:hover{
		.edit-tooltip{
			left: 100%;
			top: -42px;
			z-index: 10;
			.tooltip-text{
				display: block;
				
			}
		}
	}
`;

const EditTradeLink = styled(ButtonAlertLink)`
	${below.tablet`
		${css`
			width: 160px;
		`}
	`}
`;

type Props = {
	user_trades: Array<Object>,
	fetchMyClassicTrades: typeof actions.fetchMyClassicTrades,
	players_by_id: TPlayersArrayWithFixtureById,
	remaining_salary: number,
	removePlayerOutTrade: typeof actions.removePlayerOutTrade,
	removePlayerInTrade: typeof actions.removePlayerInTrade,
	resetTrade: typeof actions.resetClassicTrade,
	makeTrade: typeof actions.makeClassicTrade,
	actual_round: TRound,
	selected_round?: TRound,
	is_team_started: boolean,
	trade_ids: Array<number>,
	reverseTrade: typeof actions.reverseClassicTrade,
	trade_error: string | null,
	clearClassicTradeError: typeof actions.clearClassicTradeError,
	trades?: Array<Object>,
	short_names: boolean,
	is_static: boolean,
	initial_lineup: Object,
}
type State = {
	show_confirm_modal: boolean,
	show_reverse_modal: boolean,
	trade_ids: Array<number>,
	is_mobile: boolean,
}
class UserTradesComponent extends React.Component<Props, State> {
	static defaultProps = {
		is_static: false,
	};
	constructor() {
		super();

		_.bindAll(this, [
			"tradeIn",
			"tradeOut",
			"removeInPlayer",
			"removeOutPlayer",
			"resetTrade",
			"makeTrade",
			"reverseTrade",
			"getCompleteTradeButton",
			"isCouldBeReversed",
			"checkIsPlayerChangeAvailable",
			"handleConfirmModal",
			"handleReverseModal",
			"handleErrorModalClose",
			"resize"
		]);
	}
	state = {
		show_confirm_modal: false,
		show_reverse_modal: false,
		trade_ids: [],
		is_mobile: false,
		short_names: false,
	};
	componentDidMount(): void {
		const { fetchMyClassicTrades, trades } = this.props;
		if (!trades) {
			fetchMyClassicTrades();
		}
		window.addEventListener("resize", this.resize);
		this.resize();
	}
	componentWillUnmount() {
		window.removeEventListener("resize", this.resize);
		this.resetTrade(true);
	}
	resize() {
		this.setState({
			is_mobile: isMobile()
		});
	}
	tradeIn(trade, index) {
		const { actual_round } = this.props;
		const { is_mobile } = this.state;
		const props = {
			is_compressed: is_mobile
		};
		if (trade.in) {
			const player = this.props.players_by_id[trade.in];

			return <TradeInCellClassic
				key={index}
				player={player}
				with_cost={!is_mobile}
				removePlayer={this.removeInPlayer}
				is_classic
				{...props}
			/>;
		}
		if (!trade.id) {
			return this.emptyCell(index, true);
		}
		const player = this.props.players_by_id[trade.new_player_id];


		return <TradeInCellShow
			key={index}
			player={player}
			with_cost={!is_mobile}
			compressed={is_mobile}
			selected_round={actual_round}
			is_classic
		/>;
	}
	tradeOut(trade, index) {
		const { actual_round } = this.props;
		const { is_mobile } = this.state;
		const props = {
			is_compressed: is_mobile
		};
		if (trade.out) {
			const player = this.props.players_by_id[trade.out];

			return <TradeOutCell
				key={index}
				player={player}
				with_cost={!is_mobile}
				removePlayer={this.removeOutPlayer}
				is_compressed={is_mobile}
				is_classic={false}
				{...props}
			/>;
		}
		if (!trade.id) {
			return this.emptyCell(index);
		}
		const player = this.props.players_by_id[trade.old_player_id];

		return <TradeOutCellShow
			key={index}
			player={player}
			with_cost={!is_mobile}
			compressed={is_mobile}
			selected_round={actual_round}
			is_classic
		/>;
	}

	emptyCell(index, isLeft?) {
		const { is_mobile } = this.state;
		if (index === 2) {
			return (
				<DisabledEmptyCell key={index}>
					{is_mobile ?
						"Please complete at least one trade" :
						"Please complete at least one trade before making your third trade"
					}
				</DisabledEmptyCell>
			);
		}
		if (index === 3) {
			return (
				<DisabledEmptyCell key={index}>
					{is_mobile ?
						"Please complete at least two trades" :
						"Please complete at least two trades before making your fourth trade"
					}
				</DisabledEmptyCell>
			);
		}
		if(isLeft){
			return (
				<DisabledEmptyCell key={index}>
					Add a player to your team from the Player Pool
				</DisabledEmptyCell>
			);
		}
		return (
			<EmptyCell key={index}>
				{is_mobile ?
					"Add a player to trade" :
					"Add a player to the trade from your team"
				}
			</EmptyCell>
		);
	}

	get is_edit_page() {
		return isEditTradePage();
	}

	get trade_btn() {
		const is_no_trades = !this.getNumTrades("in") && !this.getNumTrades("out");

		if (is_no_trades) {
			return (
				<ButtonDisabled>
					No trade
				</ButtonDisabled>
			);
		}

		if (this.is_trade_invalid) {
			return (
				<React.Fragment>
					<ButtonDisabled>
						Invalid trade
					</ButtonDisabled>
					{this.reset_trade_button}
				</React.Fragment>
			);
		}

		return (
			<React.Fragment>
				<ButtonAccent onClick={this.handleConfirmModal}>
					Make trade
				</ButtonAccent>
				{this.reset_trade_button}
			</React.Fragment>
		);
	}

	getCompleteTradeButton(trade) {
		const { is_static, players_by_id, actual_round } = this.props;
		const is_could_be_reversed = this.isCouldBeReversed(trade);

		if (is_static) {
			return <div />;
		}
		const playerOne = get(players_by_id, trade.old_player_id, {});
		const playerTwo = get(players_by_id, trade.new_player_id, {});


		const isBothLocked = isPlayerStarted(actual_round, playerOne) 
			&& isPlayerStarted(actual_round, playerTwo);

		return <React.Fragment>
			<ButtonAlert
				data-ids={trade.id}
				onClick={this.handleReverseModal}
				disabled={!is_could_be_reversed}
			>
				Reverse trade
			</ButtonAlert>
			<Exist when={!isBothLocked}>
				<EditTradeLink 
					to={`/classic/edit-advanced-trades/${trade.id}`} 
					disabled={isBothLocked}
				>
					Edit Trade
				</EditTradeLink>
			</Exist>
			<Exist when={isBothLocked}>
				<EditTradeDisabledWrapper>
					<ButtonSecondary disabled={true} className="edit-trade-disabled">
						Edit Trade
					</ButtonSecondary>
					<Tooltip
						title={"Edit Trade"}
						description={"The ability to edit this trade "+
							"has been disabled because both players are "+
							"now locked out"}
						className="edit-tooltip"
					/>
				</EditTradeDisabledWrapper>
				
			</Exist>
			

			{!is_could_be_reversed && (
				<DisabledDescription>
					A player in this transaction
					is in lock out
				</DisabledDescription>
			)}
		</React.Fragment>;
	}

	getCompleteDoubleTradeButton(trades) {
		const is_could_be_reversed = !trades.map(this.isCouldBeReversed).includes(false);

		return (
			<React.Fragment>

				<ButtonAlert
					data-ids={trades.map(trade => trade.id)}
					onClick={this.handleReverseModal}
					disabled={!is_could_be_reversed}
				>
					Reverse trade
				</ButtonAlert>
				{!is_could_be_reversed && (
					<DisabledDescription>
						A player in this transaction
						is in lock out
					</DisabledDescription>
				)}
			</React.Fragment>

		);
	}
	get confirm_modal() {
		const { user_trades, players_by_id } = this.props;
		const { is_mobile } = this.state;
		const incomplete_trades = user_trades.filter(trade => !trade.id && (trade.in && trade.out));
		const props = {
			compressed: is_mobile
		};

		const TradeColumnModal = styled(TradeColumn)`
		${below.desktop`
		${css`
				.player-details-info {
					margin-left: 60px;
				}
			`}
		`}
		${below.tablet`
			${css`
				margin: 0 0 20px 0;
				width: 100%;
			`}
			& > p {
				margin-bottom: 20px;
			}
		`}
		`;
		return (
			<ModalContainer onClick={this.handleConfirmModal} container_background="#F8F8FA">
				<ModalInnerTitle>
					Please revise and confirm the following trade:
				</ModalInnerTitle>
				<ConfirmModalContent>
					<TradeColumnModal>
						<TradeTitle>
							You are looking to trade in:
						</TradeTitle>
						{incomplete_trades.map(({ in: id }) => (
							<TradeInCellShow
								key={id}
								player={players_by_id[id]}
								is_classic
								{...props}
							/>
						))}
					</TradeColumnModal>
					{!is_mobile && (<Transactions color={colors.primary.primary} size={3.5} />)}
					<TradeColumnModal>
						<TradeTitle>
							You are looking to trade out:
						</TradeTitle>
						{incomplete_trades.map(({ out: id }) => (
							<TradeOutCellShow
								key={id}
								player={players_by_id[id]}
								is_classic
								{...props}
							/>
						))}
					</TradeColumnModal>
				</ConfirmModalContent>
				<ConfirmButtonsWrapper>
					<ButtonAlert onClick={this.handleConfirmModal} inverted>
						CANCEL
					</ButtonAlert>
					<ButtonPrimary
						onClick={this.makeTrade}
					>
						CONFIRM OFFER
					</ButtonPrimary>
				</ConfirmButtonsWrapper>

			</ModalContainer>
		);
	}

	get reverse_modal() {
		const { user_trades, players_by_id } = this.props;
		const { trade_ids, is_mobile } = this.state;
		const trades = user_trades.filter(trade => trade_ids.includes(trade.id));
		return (
			<ModalContainer onClick={this.handleReverseModal} container_background="#F8F8FA">
				<Caution size={3} color={colors.warning} />
				<ModalInnerTitle>
					Are you sure you want to reverse this trade?
				</ModalInnerTitle>
				<ModalInnerSubTitle>
					As this was an advanced trade this action will impact all players involved,
					including those you swapped during the trade.
				</ModalInnerSubTitle>
				<ConfirmModalContent>
					<TradeColumn>
						{trades.map(({ new_player_id: id }) => (
							<TradeInCellShow
								key={id}
								player={players_by_id[id]}
								compressed={is_mobile}
								is_classic
							/>
						))}
					</TradeColumn>
					{!is_mobile && <Transactions color={colors.primary.primary} size={3.5} />}
					<TradeColumn>
						{trades.map(({ old_player_id: id }) => (
							<TradeOutCellShow
								key={id}
								player={players_by_id[id]}
								compressed={is_mobile}
								is_classic
							/>
						))}
					</TradeColumn>
				</ConfirmModalContent>
				<ConfirmButtonsWrapper>
					<ButtonSecondary inverted onClick={this.handleReverseModal}>
						{is_mobile ? "CANCEL" : "No, keep these players"}
					</ButtonSecondary>
					<ButtonAlert
						onClick={this.reverseTrade}
					>
						{is_mobile ? "CONFIRM" : "Yes, reverse this trade"}
					</ButtonAlert>
				</ConfirmButtonsWrapper>

			</ModalContainer>
		);
	}

	get reset_trade_button() {
		return (
			<ButtonAlert onClick={this.resetTrade} inverted>
				Reset trade
			</ButtonAlert>
		);
	}

	get error_message() {
		const trades_in_num = this.getNumTrades("in");
		const trades_out_num = this.getNumTrades("out");

		if (this.is_no_trades) {
			return null;
		}
		const is_num_trades_equal = this.is_num_trades_equal;
		if (!is_num_trades_equal) {
			const message = trades_in_num > trades_out_num ?
				"you need to add a player from your team to trade out." :
				"you need to add a player from the player pool.";
			return (
				<ErrorMessage>
					This trade is invalid,&nbsp;
					<strong>{message}</strong>
				</ErrorMessage>
			);
		}
		const { remaining_salary } = this.props;

		if (remaining_salary < 0) {
			return (
				<ErrorMessage>
					This trade is invalid,&nbsp;
					<strong>you have exceeded the salary cap.</strong>
				</ErrorMessage>
			);
		}

		if (!this.is_positions_valid) {
			return (
				<ErrorMessage>
					This trade is invalid,&nbsp;
					<strong>
						you must select player eligible for the empty positions on your team.
					</strong>
				</ErrorMessage>
			);
		}

		if (!this.is_trade_invalid) {
			return (
				<SuccessMessage>
					This trade is valid,&nbsp;
					<strong>
						please hit the 'Make trade' button to confirm
					</strong>
				</SuccessMessage>
			);
		}

		return null;
	}

	get error_modal() {
		const { trade_error } = this.props;

		return (
			<ModalContainer header_text="Error" onClick={this.handleErrorModalClose}>
				<ModalInnerTitle>
					{trade_error}
				</ModalInnerTitle>
			</ModalContainer>
		);
	}
	handleErrorModalClose() {
		const { clearClassicTradeError } = this.props;
		clearClassicTradeError();
	}

	getNumTrades(side) {
		const { user_trades } = this.props;

		return _.chain(user_trades)
			.map(trade => trade[side])
			.filter(_.identity)
			.size()
			.value();
	}
	isCouldBeReversed(trade) {
		const { old_player_id, new_player_id, swap_player_ids } = trade;
		const ids = [old_player_id, new_player_id].concat(swap_player_ids);
		const players_availability = ids.map(this.checkIsPlayerChangeAvailable);
		return !players_availability.includes(false);
	}
	get is_num_trades_equal() {
		return this.getNumTrades("in") === this.getNumTrades("out");
	}
	get is_no_trades() {
		return !this.getNumTrades("in") && !this.getNumTrades("out");
	}

	get trades_by_positions_valid() {
		const { players_by_id, initial_lineup } = this.props;
		const utility_id = _.get(initial_lineup, "bench.utility.id", 0);

		return this.filled_trades.filter(trade => {
			const { in: in_id, position, swap_position, out, swap_ids } = trade;
			const { positions } = players_by_id[in_id];

			return positions.includes(swap_position || position) ||
				(utility_id === out && !swap_ids.length) || _.last(swap_ids) === utility_id;
		});
	}

	get is_positions_valid() {
		return this.trades_by_positions_valid.length === this.filled_trades.length;
	}

	get filled_trades() {
		const { user_trades } = this.props;
		return _.chain(user_trades)
			.filter(trade => trade.in && trade.out)
			.value();
	}

	get is_trade_invalid() {
		const { remaining_salary } = this.props;

		const is_num_trades_not_equal = !this.is_num_trades_equal;
		const is_not_in_salary = remaining_salary < 0;
		return [is_num_trades_not_equal, is_not_in_salary, !this.is_positions_valid].includes(true);
	}

	checkIsPlayerChangeAvailable(player_id) {
		const { actual_round, is_team_started, players_by_id } = this.props;
		const player = players_by_id[player_id];
		const { status, lockout } = actual_round;
		const is_active = status === "active";

		if (!is_team_started) {
			return true;
		}

		if (is_active) {
			if (lockout === "full") {
				return false;
			}
			else {
				return !_.get(player, "locked", 1);
			}
		}

		return true;
	}

	removeOutPlayer({ id }) {
		const { user_trades } = this.props;
		const { removePlayerOutTrade } = this.props;

		const { position_index, is_bench, position } = _.find(user_trades, { out: id });
		removePlayerOutTrade({
			id: parseInt(id, 10),
			position_index,
			is_bench,
			position
		});
	}

	removeInPlayer({ id }) {
		const { removePlayerInTrade } = this.props;
		removePlayerInTrade({
			id: parseInt(id, 10)
		});
	}
	makeTrade() {
		this.props.makeTrade();
		this.handleConfirmModal();
	}
	resetTrade(clear_reset: boolean = false) {
		this.props.resetTrade({
			clear_reset: _.isBoolean(clear_reset) ? clear_reset : false
		});
	}

	reverseTrade() {
		const { trade_ids } = this.state;
		this.props.reverseTrade({ trade_ids });
		this.handleReverseModal();
	}

	handleConfirmModal() {
		const { show_confirm_modal } = this.state;
		this.setState({
			show_confirm_modal: !show_confirm_modal
		});
	}
	handleReverseModal(event) {
		const { show_reverse_modal } = this.state;

		const { currentTarget } = event || {};
		if (!currentTarget) {
			return this.setState({
				show_reverse_modal: !show_reverse_modal,
				trade_ids: []
			});
		}
		const { ids } = currentTarget.dataset;
		const trade_ids = ids ? JSON.parse(`[${ids}]`) : [];
		this.setState({
			show_reverse_modal: !show_reverse_modal,
			trade_ids
		});
	}

	get single_trades() {
		const { user_trades, trades, is_static } = this.props;
		const { is_mobile } = this.state;

		const single_trades =  _.filter(
			// Use the trades provided, if they're given
			trades !== undefined ? trades : user_trades,
			trade => trade.id
		);

		const trade_tense = !is_static && "have";

		return single_trades.length ? single_trades.map(trade => {
			const trade_in = <TradeColumn>
				<TradeTitle>
					You {trade_tense} traded in:
				</TradeTitle>
				{this.tradeIn(trade)}
			</TradeColumn>;

			const trade_out = <TradeColumn>
				<TradeTitle>
					You {trade_tense} traded out:
				</TradeTitle>
				{this.tradeOut(trade)}
			</TradeColumn>;

			const buttons = <ButtonsWrapper>
				{this.getCompleteTradeButton(trade)}
			</ButtonsWrapper>;

			return is_mobile
				? <React.Fragment key={trade.id}>
					<TradeCellsWrapper>
						{ trade_in }
						{ trade_out }
					</TradeCellsWrapper>
					{ buttons }
				</React.Fragment>
				: <TradeCellsWrapper key={trade.id}>
					{ trade_in }
					{ buttons }
					{ trade_out }
				</TradeCellsWrapper>;
		}) : null;
	}

	get incomplete_trades() {
		const { user_trades, trades } = this.props;

		const incomplete_trades = user_trades.filter(trade => !trade.id);

		if (trades || !incomplete_trades.length) {
			return null;
		}
		const { is_mobile } = this.state;

		if (is_mobile) {
			return (
				<React.Fragment>
					<TradeCellsWrapper>
						<TradeColumn>
							<TradeTitle>
								In:
							</TradeTitle>
							{incomplete_trades.map(this.tradeIn)}
						</TradeColumn>
						<TradeColumn>
							<TradeTitle>
								Out:
							</TradeTitle>
							{incomplete_trades.map(this.tradeOut)}
						</TradeColumn>
					</TradeCellsWrapper>
					<ButtonsWrapper>
						{incomplete_trades.length ? this.trade_btn : null}
					</ButtonsWrapper>
				</React.Fragment>
			);
		}

		return (
			<TradeCellsWrapper>
				<TradeColumn>
					<TradeTitle>
						You are looking to trade in:
					</TradeTitle>
					{incomplete_trades.map(this.tradeIn)}
				</TradeColumn>
				<ButtonsWrapper>
					{incomplete_trades.length ? this.trade_btn : null}
				</ButtonsWrapper>
				<TradeColumn>
					<TradeTitle>
						You are looking to trade out:
					</TradeTitle>
					{incomplete_trades.map(this.tradeOut)}
				</TradeColumn>

			</TradeCellsWrapper>
		);
	}

	render() {
		const { trade_error, trades, players_by_id, short_names } = this.props;
		const {
			show_confirm_modal,
			show_reverse_modal
		} = this.state;

		if (_.isEmpty(players_by_id)) {
			return null;
		}

		return (
			<UserTradesWrapper is_compressed_padding={trades} short_names={short_names}>
				{this.single_trades}
				{this.incomplete_trades}


				{this.error_message}
				{show_confirm_modal && this.confirm_modal}
				{show_reverse_modal && this.reverse_modal}

				{trade_error && this.error_modal}

			</UserTradesWrapper>
		);
	}
}
const mapStateToProps = state => {
	return {
		user_trades: selectors.getUserTrades(state),
		players_by_id: selectors.players.getExtendedPlayersById(state),
		remaining_salary: selectors.getMyClassicTeamRemainingSalary(state),
		actual_round: selectors.rounds.getActualRound(state),
		is_team_started: selectors.isMyTeamStarted(state),
		trade_error: selectors.getTradesError(state),
		initial_lineup: _.get(
			selectors.setUtilityPosition(state.teamsClassic.trades),
			"lineup",
			[]
		),
	};
};
const mapDispatchToProps = {
	fetchMyClassicTrades: actions.fetchMyClassicTrades,
	removePlayerOutTrade: actions.removePlayerOutTrade,
	removePlayerInTrade: actions.removePlayerInTrade,
	resetTrade: actions.resetClassicTrade,
	makeTrade: actions.makeClassicTrade,
	reverseTrade: actions.reverseClassicTrade,
	clearClassicTradeError: actions.clearClassicTradeError,
};
export const ClassicUserTrades = connect(
	mapStateToProps,
	mapDispatchToProps,
)(withClassicPlayerModal(UserTradesComponent));

export default ClassicUserTrades;