// @flow
import * as React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { bindAll } from "lodash";
import { below } from "../../assets/css/media";
import colors from "../../assets/css/colors";
import * as actions from "../../modules/actions";

import isAuthenticated from "../../utils/auth/isAuthenticated";
import { ModalContainer, 
	ModalContent,
	ModalBody, ModalOverlay,
} from "../../components/Modal";
import logo from "../../assets/images/home-logo.webp";
// $FlowFixMe



import TermsConditionsForm from "./TermsConditionsForm";


const ModalTitle = styled.h2`
	color: ${colors.primary.primary};
	font-size: 32px;
	text-align: center;
	margin-bottom: 20px;
	padding-top: 20px;  
	${below.tablet`
		font-size: 24px;
	`}

	${below.phone`
		font-size: 22px;
	`}
`;


const ProModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: grey;
	z-index: 2000;
	overflow: hidden;
	
	${ModalContent} {
		box-sizing: border-box;
		box-shadow: 1px 0px 12px 0 rgb(0,0,0,0.2);
		padding-top: 0;
		${below.tablet`
			max-width: unset;
		`}

		height: fit-content;
		${below.phone`
			height: fit-content;
			max-width: 320px;
			padding-top: 0;
			padding-left: 0;
			padding-right: 0;
		`}

		@media screen and (max-width: 330px) {
			padding-top: 0;
		}

		${ModalBody} {
			padding: 0 100px 0px;
			overflow: hidden;
			position: static;
			${below.desktop}{
				overflow: hidden;
			}
			${below.phone`
				padding: 0;
				max-width: 300px;
				margin: 0 auto;
			`}
		}
	}
  ${ModalOverlay}{
    background: rgba(0, 0, 0, 0.5);
    box-shadow: 1px 0px 12px 0 rgb(0,0,0,0.2);
    height: 100vh;
  }
  

	.close-button {
		z-index: 1;
		> svg {
			fill: ${colors.primary.primary};
		}
		:hover {
			> svg {
				fill: #333;
			}
		}
	}     
`;


const TwoLogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  img{
    height: 140px;
    ${below.phone`
      height: 80px;
    `}
  }
`;


type Props = {
	closeClick: Function,
	subscribeLeague: typeof actions.leagueDraft.subscribeLeague,
	clearSubscribeLeagueErrors: typeof actions.leagueDraft.clearSubscribeLeagueErrors,
	showDraftLeague: typeof actions.leagueDraft.showDraftLeague,
	subscribe: {
		error: string,
		is_pending: boolean,
	},
	league: {
		subscription: any,
		id: number,
	},
	onSubmit: Function,
	create?: boolean,
	leagueCreateSubmit: Function,
	createdID: number,
	changeFieldValue: Function,
  updateUser: typeof actions.updateUser,
  values: Object,
  success: boolean,
  logout: typeof actions.logout,
};

type State = {
	afl_tnc: boolean,
  toyota_sponsor: boolean,
}
// eslint-disable-next-line max-len
class TermsConditionsModalComponent extends React.Component<Props, State> {
	constructor(props: Props, state: State){
		super(props, state);
		bindAll(this, [
			"handleTermsChange",
			"handleSubmit",
			"closeClick"
		]);
	}
	state = {
  	afl_tnc: false,
  	toyota_sponsor: false,
	};
  

	handleTermsChange() {
  	this.setState({
  		afl_tnc: !this.state.afl_tnc
  	});
	}
	handleSubmit(values) {
  	const
  		{ updateUser } = this.props,
  		data = {
  			...values
  		};
      
  	updateUser(data);
  	setTimeout(() => window.location.reload(), 1000);
	}
  
	closeClick() {
  	this.props.logout();
	}
  

	get message(){
  	return "You have successfully accepted the new terms and conditions!";
	}


	render() {
  	return(
  		<ProModalWrapper>
  				<ModalContainer
  					onClick={this.closeClick}
  					container_background={colors.white}
  				>
  				<TwoLogoWrapper>
  					<img src={logo} alt="AFL Fantasy Presented by Toyota"/>
  				</TwoLogoWrapper>
  					<ModalTitle>Terms and Conditions</ModalTitle> 			
  					<TermsConditionsForm handleSubmit={this.handleSubmit}/>  
  				</ModalContainer>
  			</ProModalWrapper>
  	);
	}
	
};

const mapStateToProps = state => ({
	is_authorised: isAuthenticated(), 
	subscribe: state.leagues.subscribe,
	createdID: state.leagues.create.league_id,
	success: state.user.update.success
});

const mapDispatchToProps = {
	subscribeLeague: actions.leagueDraft.subscribeLeague,
	clearSubscribeLeague: actions.leagueDraft.clearSubscribeLeagueErrors,
	showDraftLeague: actions.leagueDraft.showDraftLeague,
	updateUser: actions.updateUser,
	logout: actions.logout,
};

export const TermsConditionsModal = connect(
	mapStateToProps,
	mapDispatchToProps)
(TermsConditionsModalComponent);

export default TermsConditionsModal;