// @flow
import React from "react";
import SvgIconContainer from "../SvgIconContainer";

export default (
	{
		unit = "em",
		title = "Sort by Draft Position to enable Drag & Drop",
		...rest
	}: Object = {}) => (
	<SvgIconContainer viewBox="0 0 18 11" {...rest} unit={unit}>
		<title>{ title }</title>
		<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g transform="translate(-3.000000, -6.000000)" fill="#CAD2D8" fillRule="nonzero">
				<path d="M9.49816335,14 C9.67526809,14.4246914 9.89613549,14.8265691 10.1551323,
				15.2 L3.6,15.2 C3.26862915,15.2 3,14.9313708 3,14.6 C3,14.2686292 3.26862915,
				14 3.6,14 L9.49816335,14 Z M9.01894482,11 C9.00639146,11.1650208 9,11.331764 9,
				11.5 C9,11.7364958 9.01263018,11.9700418 9.03725281,12.2 L3.6,12.2 C3.26862915,
				12.2 3,11.9313708 3,11.6 C3,11.2686292 3.26862915,11 3.6,11 L9.01894482,
				11 Z M10.0218179,8 C9.7814613,8.37542419 9.57863936,8.77718882 9.41864599,
				9.2 L3.6,9.2 C3.26862915,9.2 3,8.93137085 3,8.6 C3,8.26862915 3.26862915,
				8 3.6,8 L10.0218179,8 Z M15.5,6 C18.5481928,6 21,8.45180723 21,11.5 C21,
				14.5481928 18.5481928,17 15.5,17 C12.4518072,17 10,14.5481928 10,11.5 C10,
				8.45180723 12.4518072,6 15.5,6 Z M12.9117647,8.14338235 L18.8566176,
				14.0882353 C19.3792421,13.4022907 19.7058824,12.520362 19.7058824,
				11.5404412 C19.7058824,9.22129525 17.8113688,7.29411765 15.4595588,
				7.29411765 C14.512302,7.29411765 13.6303733,7.62075792 12.9117647,
				8.14338235 Z M18.0882353,14.8566176 L12.1433824,8.91176471 C11.6207579,
				9.6303733 11.2941176,10.512302 11.2941176,11.4595588 C11.2941176,
				13.8113688 13.2212952,15.7058824 15.5404412,15.7058824 C16.520362,
				15.7058824 17.4022907,15.3792421 18.0882353,14.8566176 Z"/>
			</g>
		</g>
	</SvgIconContainer>
);