// @flow
import styled from "styled-components";

const BoxDottedBorder = styled.div`
	box-sizing: border-box;
	width: 100%;
	background: #FFFFFF;
	border: 1px dashed #CAD2D8;
	border-radius: 5px;
	padding: 20px;
	margin: 10px 0;
`;

export default BoxDottedBorder;