// @flow
import styled from "styled-components";

const reverseRight = ({ reverse }) => reverse ? "right" : "left";

export const PlayerInfoShadow = styled.div`
	width: 56px;
	height: ${({ compressed }) => compressed ? "40px" : "60px"};
	overflow: hidden;
	position: absolute;
	top: 0;
	${reverseRight}: 12px;
	z-index: 0;

	&:${({ reverse }) => reverse ? "after" : "before"} {
		content: '';
		display: block;
		width: 28px;
		height: ${({ compressed }) => 
		compressed 
			? "calc(100% - 4px)" 
			: "calc(100% - 10px)"};
		background-size: contain;
		margin-${reverseRight}: -14px;
		position: absolute;
		${reverseRight}: 0;
		top: ${({ compressed }) => compressed ? "2px" : "5px"};
	}
`;

export default PlayerInfoShadow;