// @flow
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { Preloader } from "../components";
import { authCodeAfliD } from "../modules/actions";
import { REACT_APP_SSO_REDIRECT_URI } from "../modules/constants";
import { getAfliDHasExistingUserData, getAfliDLoadingStatus } from "../modules/selectors";

// eslint-disable-next-line complexity
function LoginCallbackPage () {
	const dispatch = useDispatch();
	const afliDLoadingStatus = useSelector(getAfliDLoadingStatus);
	const afliDHasExistingUserData = useSelector(getAfliDHasExistingUserData);
	const params = new URLSearchParams(window.location.search);
	const code = params.get("code") || "";
	const code_verifier = localStorage.getItem("code_verifier") || "";
	const nonce = localStorage.getItem("nonce") || "";
	
	React.useEffect(() => {
		dispatch(authCodeAfliD({
			code,
			code_verifier,
			nonce,
			redirect_url:REACT_APP_SSO_REDIRECT_URI
		}));
	}, [dispatch,code,nonce,code_verifier]);


	if (afliDLoadingStatus === "loading") {
		return <Preloader />;
	}

	if (afliDLoadingStatus === "success") {
		if(afliDHasExistingUserData) {
			return <Redirect to="/login/mini-rego" />;
		}
		else {
			return <Redirect to="/login/new-user-rego" />;
		}
	}

	if (afliDLoadingStatus === "fail") {
		return <Redirect to="/" />;
	}
	
	return null;
}

export default LoginCallbackPage;