// @flow
export const getById = (state: Object): Object =>
	state.leagues.rosters.ordered_ids.reduce(
		(obj, id) => {
			obj[id] = state.leagues.teams_by_id[id];
			return obj;
		},
		{}
	);

export const getOrdered = (state: Object): Array<Object> =>
	state.leagues.rosters.ordered_ids.map(id => state.leagues.teams_by_id[id]);

export const isPending = (state: Object): boolean => state.leagues.rosters.is_pending;

export const getError = (state: Object) => state.leagues.rosters.error;