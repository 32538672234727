// @flow
import _ from "lodash";
import React from "react";
import styled, { css } from "styled-components";
import { change, FieldArray, reduxForm } from "redux-form";
import { connect } from "react-redux";

import { ButtonPrimary, Label, TickMessage } from "../../../../components";
import { below } from "../../../../assets/css/media";

import EmailFields from "./EmailFields";

const FormWrapper = styled.form`
	display: block;
`;

export const FieldGroup = styled.div`
	width: 550px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;
	box-sizing: border-box;

	> div, > label {
		margin-right: 20px;
		flex: 1 1 0;
		margin-bottom: 0;

		> div:first-child {
			margin-top: 0;
		}

		input {
			height: 40px;
		}
	}
	> button {
		flex: 0 0 auto;
	}

	${({ is_labels }) => is_labels && css`
		padding-right: 40px;
		margin-bottom: 10px;
		label:nth-of-type(2){
			margin-left: 20px;
		}
	`}
	${below.phone`
		width: 100%;
	`}
`;



const ButtonsWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
`;

type State = {
	invites: number,
	invites_sent: boolean
}

type Props = {
	valid: boolean,
	handleSubmit: Function,
	submitting: boolean,
	submitSucceeded: boolean,
	reset: Function,
	status: {
		is_pending: boolean,
		result: {
			result: boolean
		}
	},
	changeFieldValue: Function,
	regen_invites: Array<Object>
};

class LeagueInviteEmailComponent extends React.Component<Props, State> {
	state = {
		invites: 1,
		invites_sent: false
	};

	componentDidUpdate(prev_props) {
		if (this.props.submitSucceeded) {
			const invites_sent = true;
			this.setState({ invites_sent });
			this.props.reset();
		}

		// reset sent message after 5s
		if(this.state.invites_sent) {
			setTimeout(() => {
				this.setState({ invites_sent: false });
			}, 5000);
		}

		const { regen_invites } = this.props;
		if(prev_props.regen_invites){
			if(
				prev_props.regen_invites.length === 0 &&
				regen_invites.length > 0
			) {
				this.setState({ invites: regen_invites.length });
				_.invoke(this.props, "changeFieldValue", "invites", regen_invites);
			}
		}
	}

	get is_valid() {
		return this.props.valid;
	}

	render() {
		const { handleSubmit, submitting } = this.props;
		const fields = [];
		for(let i = 0; i < this.state.invites; i++) {
			fields.push(i);
		}
		return (
			<FormWrapper onSubmit={handleSubmit}>
				<FieldGroup is_labels>
					<Label htmlFor="invites[0].firstname">
						First Name
					</Label>
					<Label htmlFor="invites[0].email" >
						Email Address
					</Label>
				</FieldGroup>
				<FieldArray name="invites" component={EmailFields} />
				<ButtonsWrapper>
					<ButtonPrimary disabled={ !this.is_valid || submitting }>
						{
							submitting ? "Sending" : "Send invites"
						}
					</ButtonPrimary>

					{this.state.invites_sent &&
						<TickMessage>Invitations Sent!</TickMessage>}
				</ButtonsWrapper>
			</FormWrapper>
		);
	}

}

export const LeagueInviteEmail = reduxForm({
	form: "league_invite",
	initialValues: {
		invites: [ {} ]
	}
})(LeagueInviteEmailComponent);


const mapDispatchToProps = dispatch => {
	return {
		changeFieldValue: function(field, value) {
			dispatch(change("league_invite", field, value));
		},
	};
};


export default connect(null, mapDispatchToProps)(LeagueInviteEmail);