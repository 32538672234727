import styled from "styled-components";

import colors from "../../../assets/css/colors";

export const IconWrapper = styled.div`
	color: ${colors.primary.primary};
	font-size: 38px;
	margin-right: 10px;

	svg {
		display: block;
	}
`;

export default IconWrapper;