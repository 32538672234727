// @flow
import * as React from "react";
import styled from "styled-components";

import Coming from "../Icons/Coming";

import {
	OneColumnLayout,
	AdsContainer,
	Ad,
	Footer
} from "../";

import colors from "../../assets/css/colors";
import { below } from "../../assets/css/media";

const OneColumnLayoutStyled = styled(OneColumnLayout)`
	border: 1px solid #ecf1f5;
	margin: 1em auto;
	margin-top: 2em;
	padding: 3em 2em 2em 2em;
	text-align: center;
	background: ${colors.form.base};
    max-width: 920px;
	${below.desktop`
		padding: 2em 0;
	`}
`;

const Title = styled.h2`
	margin-top: 20px;
	font-size: 42px;
	color: ${colors.primary.primary};
`;

const GoBack = styled.a`
	width: 200px;
	height: 40px;
	background: ${colors.primary.primary};
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid ${colors.primary.primary};
	color: #ffffff;
	font-size: 14px;
	line-height: 14px;
	cursor: pointer;
	border-radius: 2px;
	text-decoration: none;
	margin: 20px auto;
`;

const Description = styled.p`
	color: #000000;
	font-size: 14px;
	max-width: 390px;
	line-height: 20px;
	margin: 1em auto;
	font-family: SourceSansPro;
`;

export const HOCComingSoon = ({ children }: { children?: React.Node }) => {

	return (
		<React.Fragment>
			<AdsContainer>
				<Ad/>
			</AdsContainer>
			<OneColumnLayoutStyled>
				<Coming size={6} />
				<Title>…Coming soon</Title>
				<Description>
					{children || "Content will be added when available..."}
				</Description>
				<GoBack onClick={() => window.history.back()}>
					Go back
				</GoBack>
			</OneColumnLayoutStyled>
			<Footer />
		</React.Fragment>
	);
};