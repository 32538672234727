// @flow
import React from "react";
import styled, { css } from "styled-components";
import ordinal from "ordinal";
import * as _ from "lodash";
import { Link } from "react-router-dom";

import { below } from "../../assets/css/media";
import colors from "../../assets/css/colors";
import { TeamUserAvatar, WLD } from "../index";
import type {TTeam} from "../../modules/types";

import { getShortName } from "../../helpers/league";
import { isMobile } from "../../utils";
import renderCup from "./cup";
import { CupWrapper } from "./FixtureWrapper";

const TeamAvatarNameWrapper = styled.div`
	display: flex;
	flex-direction: ${({ reverse }) => reverse ? "row-reverse" : "row"};
	align-items: center;
	justify-content: flex-start;
	min-width: 240px; 
`;

const LadderRecordContent = styled.div`
	display: flex;
	flex-direction: ${({ reverse }) => reverse ? "row-reverse" : "row"};
	align-items: center;

`;
const WLDWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	align-self: ${({ reverse }) => reverse ? "flex-end" : "flex-start"};
`;

const TeamContent = styled.div`
	margin: ${({ right_side  }) => right_side ? "0 10px 0 0" : "0 0 0 10px"};
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	box-sizing: border-box;
	padding: 3px 0;

	${below.tablet`
		margin: 0px
	`};

	> a {
		color: unset;
		text-decoration: none;

		:hover {
			h5 {
				text-decoration: underline;
				text-decoration-color: ${colors.primary.primary};
			}
		}
	}

	${({ no_name }) => no_name && css`
		h5 {
			width: 130px;
			height: 16px;
			background-color: white;
			margin-bottom: 2px;
		}
		h6 {
			width: 80px;
			height: 14px;
			background-color: white;
		}
	`}
`;

const MobileContentWrapper = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	justify-content: space-between;
	height: 57px;
	a{
		text-decoration: none;
		${below.phone`
			margin: 0;
			text-align: ${({ right_side }) => right_side ? "right" : "left"};
		`}
	}
`;

export const Team = styled.div`
	width: auto;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	
	${below.tablet`
		min-width:96px;
		max-width:96px;
		`};
		${below.phone`
		min-width:96px;
		max-width:96px;
		
		`};
	& ${TeamContent} {
		align-items: ${({ right_side }) => right_side? "flex-end": "flex-start"};
		text-align: ${({ right_side }) => right_side? "right": "left"};
	}

	/* Team Name */
	h5 {
		font-size: ${({ compact }) => compact ? "14px" : "16px"};
		line-height: 1.4;
		color: ${colors.primary.primary};
		font-weight: 700;
		max-width: 120px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		@media screen and (max-width: 1200px) {
			max-width: 60px;
		}
		${below.desktop`
			max-width: 120px;
			font-size: 14px;
		`};
		@media screen and (max-width: 900px) {
			max-width: 60px;
		}
		${below.tablet`
			font-size: 12px;
			margin-bottom: 0.3em;
			max-width: 90px;
		`};
		${below.phone`
			font-size: 10px;
			max-width: 80px;
		`};
	}
	/* User Name */
	h6 {
		font-size: ${({ compact }) => compact ? "12px" : "14px"};
		line-height: 1.4;
		color: ${colors.secondary.accentGrey};
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 120px;
		font-weight: 500;
		@media screen and (max-width: 1200px) {
			max-width: 60px;
		}
		${below.desktop`
			max-width: 120px;
			font-size: 12px;
		`};
		@media screen and (max-width: 900px) {
			max-width: 60px;
		}
		${below.tablet`
			font-size: 10px;
			max-width: 90px;
		`};

		${below.phone`
			max-width: 80px;
		`};
	}

	${below.tablet`
		flex-direction: column;
		align-items: flex-start;
	`};
	${({ right_side }) => right_side && below.tablet`
		align-items: flex-end;
	`};


	${({ is_game_bar }) => is_game_bar && `
		h5 {
			width: 80px;
		}
	`}

	${below.desktop`
		${({ is_game_bar }) => is_game_bar && `
			h5 {
				width: 70px;
			}
		`}
	`};

	${({ is_game_bar }) => is_game_bar && below.tablet`
		display: none;
	`}
`;
const WLDMobile = styled.div`
	height: 3px;
	width: 12px;
	margin: 0px 1px;
	background: ${({ result }) => {
		if(result === "w") {
			return colors.primary.accentGreen;
		}
		else if(result === "l") {
			return "#D0021B";
		}
		else {
			return colors.secondary.accentGrey;
		}
	}};
`;

const TeamAvatar = styled.div`

	width: ${({ compact }) => compact ? "40px" : "40px"};
	height: ${({ compact }) => compact ? "40px" : "40px"};
	border-radius: 50%;
	position: relative;

	> * {
		display: block;
		width: 100%;
	}
	> ${CupWrapper} {
		display: none;

		${below.tablet`
			&.show-mobile {
				display: block;
				position: absolute;
				top: 50%;
				${({ reverse }) => reverse ? "right" : "left"}: calc(100% + 8px);
				transform: translateY(-50%);
				height: 12px;
				width: 12px;

				> svg {
					height: 12px;
					width: 12px;
				}
			}
		`}
	}
	${below.tablet`
		width: 30px;
		height: 30px;
	`};
`;


const LadderRecord = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: ${({ reverse }) => reverse ? "flex-end" : "flex-start"};
	min-width: 240px; 
	margin-top: 7px;
`;

const LadderPosition = styled.p`
	font-weight: 700;
	font-family: TitilliumUpright;
	color: ${colors.primary.primary};
	font-size: 12px;
	text-align: center;
	margin: ${({ right_side }) => right_side ? "0px 12px 0px 18px" : "0px 18px 0px 12px"};
	${({ right_side }) => right_side && `
		order: 6;

	`};
	${below.phone`
		margin: 0;
		text-align: ${({ right_side }) => right_side ? "right" : "left"};
	`}
`;


const TeamNameText = styled.p`
	font-family: TitilliumUpright;
	font-weight: 700;
	font-size: 12px;

	color: ${colors.primary.primary};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
	${below.phone`
		text-align: ${({ reverse }) => reverse ? "right" : "left"};
    text-overflow: ellipsis;
    overflow: hidden;
		${({ is_overview }) => is_overview && `
			padding-top: 5px;
		`}
	`}
`;
const UsersNameText = styled.p`
	font-family: SourceSansPro;
	font-weight: 400;
	font-size: 12px;
	color: ${colors.primary.primary};
	${below.phone`
		text-align: ${({ reverse }) => reverse ? "right" : "left"};
    text-overflow: ellipsis;
    overflow: hidden;
	`}
`;


type Props = {
	right_side?: boolean,
	name: string,
	firstname: string,
	lastname: string,
	rank: number | string,
	team_id?: number,
	record_last_five: Array<'w' | 'l' | 'd'>,
	compact?: boolean,
	is_game_bar?: boolean,
	user_id?: number,
	team: TTeam,
	hide_cup?: boolean,
	game_type: string,
	league_id: number,
	is_overview?: boolean,
}

export const FixtureTeam = ({
	name,
	firstname,
	lastname,
	right_side,
	rank,
	record_last_five,
	compact,
	is_game_bar,
	user_id,
	team,
	hide_cup = false,
	game_type,
	league_id,
	is_overview,
	...rest
}: Props) => {
	const team_user_id = _.get(team, "user_id", 0);
	const team_avatar_version = _.get(team, "avatar_version", 1);

	const team_id = _.get(team, "id");
	const team_url = game_type === "classic"
		? `/classic/team/user/${team_id}`
		: `/draft/league/${league_id}/team/${team_id}`;

	return <Team 
		right_side={right_side} 
		is_game_bar={is_game_bar} 
		compact={compact} 
		className="fixture-team">
		{!isMobile() ? <>
			<TeamAvatarNameWrapper reverse={right_side} className={"mobile"}>
				<TeamAvatar compact={compact} reverse={right_side} className="fixture-team-avatar">
					<TeamUserAvatar 
						{...rest} 
						user_id={team_user_id}
						firstname={firstname} 
						lastname={lastname} 
						avatar_version={team_avatar_version}
					/>
			
			
					{ renderCup(team, right_side, !hide_cup)}
				</TeamAvatar>
				<TeamContent 
					right_side={right_side} 
					is_game_bar={is_game_bar} 
					no_name={name === undefined}
				>
					<Link to={team_url}>
						<TeamNameText>
							{name}
						</TeamNameText>
						<UsersNameText>
							{getShortName({ firstname, lastname })}
						</UsersNameText>
					</Link>
			

				</TeamContent>
			</TeamAvatarNameWrapper>
		
			{!compact && (
				<LadderRecord reverse={right_side}>
					<LadderRecordContent reverse={right_side}>
						<div>
							<LadderPosition right_side={right_side}>
								{rank ? ordinal(rank): "TBA"}
							</LadderPosition>
						</div>
						<WLDWrapper>
							{_.map(record_last_five, (result, index) => (
								<WLD result={result} key={`${index}`} />
							))}
						
						</WLDWrapper>
					
					</LadderRecordContent>
			
				</LadderRecord>
			)}
		</> : 
			<MobileContentWrapper reverse={right_side}>
				<Link to={team_url}>
					<TeamNameText reverse={right_side} is_overview={is_overview}>
						{name}
					</TeamNameText>
					{!is_overview && 
						<UsersNameText reverse={right_side}>
							{getShortName({ firstname, lastname })}
						</UsersNameText>
					}
					
				</Link>
				<div>
					<LadderPosition right_side={right_side}>
						{rank ? ordinal(rank): "TBA"}
					</LadderPosition>
				</div>
				
				<WLDWrapper reverse={right_side}>
					{_.map(record_last_five, (result, index) => (
						<WLDMobile result={result} key={`${index}`} />
					))}
						
				</WLDWrapper>

			</MobileContentWrapper>}
		
	</Team>;
};

export default FixtureTeam;