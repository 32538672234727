// @flow
import styled from "styled-components";
import colors from "../../../assets/css/colors";

export const Label = styled.label`
	display: flex;
	min-height: 20px;
	align-items: center;
	color: ${({ is_error }) => is_error ? colors.errorColor : colors.primary.primary};
	font-size: 12px;
	font-family: "SourceSansPro";
	font-weight: 600;
	margin: 0;
    justify-content: space-between;
	opacity: ${({ is_disabled }) => is_disabled ? ".5" : "1"};
	margin-left: ${({ is_disabled }) => is_disabled ? "10px" : "0"};
	div {
		margin-right: ${({ is_disabled }) => is_disabled ? ".5em" : "0"};
	}

	> div.select-tooltip {
		margin-bottom: 4px;
	}
`;

export default Label;