// @flow
import * as React from "react";
import styled from "styled-components";
import colors from "../../assets/css/colors";
import { below } from "../../assets/css/media";
import { ModalIcon } from "./ModalContainer";

const ModalHeaderStyled = styled.div`
	background: #FFFFFF;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	box-sizing: border-box;
	border-radius: 5px 5px 0 0;
	padding: 0;
	margin: 0;
`;

const ModalHeaderTitle = styled.h2`
	color: ${colors.primary.primary};
	font-size: 32px;
	font-weight: 800;
	line-height: 1;
	text-align: center;
	margin-bottom: 0.625em;

	${below.tablet`
		font-size: 24px;
		max-width: 300px;
	`}

	@media only screen and (max-height: 400px) {
		font-size: 20px;
	}
`;

type Props = {
	header_text?: React.Node,
	is_sponsored?: boolean,
	top_icon?: Object,
	warning?: boolean,
};

export const ModalHeader = ({ 
	header_text, 
	is_sponsored, 
	top_icon, 
	warning = false 
}: Props) => (
	<ModalHeaderStyled>
		{ top_icon 
			? <ModalIcon warning={warning}>{top_icon}</ModalIcon>
			: null }
		{header_text ? (
			<ModalHeaderTitle>
				{header_text}
			</ModalHeaderTitle>
		) : null}

		{is_sponsored ? "" : null}
	</ModalHeaderStyled>
);

export default ModalHeader;