// @flow
import { createReducer } from "redux-act";
import * as actions from "../../actions";
import { LoadingStatus } from "../../constants";


const initialState = {
	loadingStatus:LoadingStatus.IDLE,
	news:{}
};

export const playerNews = createReducer({}, initialState);

playerNews.on(actions.fetchPlayerNews, state => {
	return {
		...state,
		loadingStatus:LoadingStatus.LOADING
	};
});
playerNews.on(actions.fetchPlayerNewsSuccess, (state, news) => ({
	...state,
	news,
	loadingStatus:LoadingStatus.SUCCESS
}));
playerNews.on(actions.fetchPLayerNewsFailed, (state, news) => ({
	...initialState,
	loadingStatus:LoadingStatus.ERROR
}));

export default playerNews;