// @flow
import React from "react";
import {TPlayer} from "../../../../modules/types";
import {IgnoreCheckbox} from "../../../../components";
import ArrowUpSmall from "../../../../components/Icons/ArrowUpSmall";
import ArrowDownSmall from "../../../../components/Icons/ArrowDownSmall";
import More from "../../../../components/Icons/More";
import Close from "../../../../components/Icons/Close";
import {Td} from "../../../../components/TableX";
import {
	MoreButtonWrapper,
	MobileActionsWrapper,
	PreDraftSwitchButton,
	DraftButtonWrapper,
	CloseButtonWrapper,
} from "../liveDraft/LiveDraftStyles";

type Props = {
    is_compressed: boolean,
    updatePlayerPreDraftListOrder: Function,
    pre_draft_list_size: number,
    show_kept_players: boolean,
    id: number,
    draft_order: number,
    is_ignored: boolean,
    isOpen: boolean,
    player: TPlayer,
    openActionsPlayerId: Function,
    onClickIgnore: Function,
    closeActionsPlayerId: Function,
    is_commissioner_type: boolean,
    draft_button: Object,
    filters: Object,
    is_my_turn: boolean
}


const maybeGet = (condition, onTrue, onFalse) => condition ? onTrue : onFalse;

export const MobilePlayerActions = (props: Props) => {
	const {
		is_compressed,
		updatePlayerPreDraftListOrder,
		pre_draft_list_size,
		show_kept_players,
		id,
		draft_order,
		is_ignored,
		isOpen,
		player,
		openActionsPlayerId,
		onClickIgnore,
		closeActionsPlayerId,
		is_commissioner_type,
		draft_button,
		filters,
		is_my_turn,
	} = props;

	if(show_kept_players){
		return null;
	}
	if(is_my_turn){
		return<Td is_compressed={is_compressed} className="last-right-td">
			<MoreButtonWrapper>
				{draft_button}
			</MoreButtonWrapper>
		</Td> ;
	}
	if(!isOpen){
		return <Td is_compressed={is_compressed} className="last-right-td">
			<MoreButtonWrapper onClick={() => openActionsPlayerId(id)}>
				<More />
			</MoreButtonWrapper>
		</Td>
		;
	}
	
	return(
		<Td is_compressed={is_compressed} className="last-right-td">
			<MobileActionsWrapper>
				<PreDraftSwitchButton
					up={true}
					disabled={is_ignored || draft_order === 1}
					data-order={parseInt(draft_order, 10) - 1}
					data-id={player.id}
					onClick={updatePlayerPreDraftListOrder}
					is_compressed={is_compressed}
				>
					<ArrowUpSmall />
				</PreDraftSwitchButton>
				<PreDraftSwitchButton
					disabled={is_ignored || pre_draft_list_size === draft_order}
					data-order={parseInt(draft_order, 10) + 1}
					data-id={player.id}
					onClick={updatePlayerPreDraftListOrder}
					is_compressed={is_compressed}
				>
					<ArrowDownSmall />
				</PreDraftSwitchButton>

				<DraftButtonWrapper is_compressed={is_compressed}>
					{maybeGet(
						is_commissioner_type,
						draft_button,
						maybeGet(
							(is_my_turn && !filters.by_ignored),
							draft_button,
							<IgnoreCheckbox
								onChange={onClickIgnore}
								checked={is_ignored}
								name={`ignore-${player.id}`}
								pre_draft={true}
							/>
						)
					)}
				</DraftButtonWrapper>
				<CloseButtonWrapper onClick={closeActionsPlayerId}>
					<Close />
				</CloseButtonWrapper>

			</MobileActionsWrapper>
		</Td>
        
	);
};