// @flow
import { createReducer } from 'redux-act';
// import _ from 'lodash';
// import mergeWith from 'lodash/mergeWith';
import uniq from 'lodash/uniq';
import keyBy from 'lodash/keyBy';
import sortBy from 'lodash/sortBy';
import { get, isEmpty } from 'lodash';
import * as actions from '../../actions';
import { LoadingStatus } from '../../constants';
import { arrayToObjectById } from "./../../../helpers";

// function customizer(obj_value, src_value) {
// 	if (!obj_value) {
// 		return src_value;
// 	}
// 	if (!src_value) {
// 		return obj_value;
// 	}
// }
const onSuccessWaiverRequest = (state, payload: any) => {
	const ordered_ids = sortBy(payload, 'user_position').map(item => item.id);
	return {
		...state,
		waiver_requests: {
			...state.waiver_requests,
			is_pending: false,
			ordered_ids,
			by_id: keyBy(payload, 'id'),
		},
	};
};

const initialShowFullOrder = {
	order: [],
	error: null,
	draft_full_order_status: "idle",
	keeper_pick_order: [],
};

const initialRegenerateShow = {
	regenerate_show: {
		is_pending: false,
		error: null,
		by_id: {}
	},
	regenerate_show_uf: {
		is_pending: false,
		error: null,
		by_id: {}
	}
};

export const initial_state = {
	create: {
		is_pending: false,
		error: null,
		league_id: 0,
	},
	create_uf: {
		is_pending: "idle",
		error: null,
		league_id: 0,
	},
	update: {
		is_pending: false,
		error: null,
		status:LoadingStatus.IDLE
	},
	show: {
		is_pending: false,
		error: null,
		league_id: null,
	},
	show_my: {
		is_pending: false,
		error: null,
		ordered_ids: [],
	},
	show_to_join: {
		is_pending: false,
		error: null,
		ordered_ids: [],
		offset: 0,
	},
	join_to: {
		is_pending: false,
		error: null,
		league_id: null,
		team_id: null,
	},
	show_teams: {
		is_pending: false,
		error: null,
		status:"idle",
	},
	remove: {
		is_pending: false,
		error: null,
		success: false,
		removedLeague: 0
	},
	invite_to: {
		is_pending: false,
		error: null,
		team_id: null,
	},
	ladder: {
		is_pending: false,
		error: null,
		ordered_ids: [],
	},
	leave: {
		is_pending: false,
		error: null,
		success: false
	},
	show_order: {
		is_pending: false,
		ordered_ids: [],
		ordered_ids_from_api: [],
		error: null,
		draft_order_status: "idle"
	},
	show_full_order: initialShowFullOrder,
	remove_team: {
		is_pending: false,
		error: null,
		success: false,
	},
	my_list: {
		is_pending: false,
		success: false,
		ordered_ids: [],
		error: null,
		by_id: {},
	},
	waiver_order: {
		is_pending: false,
		ordered_ids: [],
		error: null,
		by_id: {},
	},
	waiver_list: {
		is_pending: false,
		ordered_ids: [],
		error: null,
		by_id: {},
	},
	waiver_free: {
		is_pending: false,
		ordered_ids: [],
		error: null,
	},
	waiver_requests: {
		is_pending: false,
		ordered_ids: [],
		by_id: {},
		error: null,
	},
	rosters: {
		is_pending: false,
		error: null,
		ordered_ids: [],
		rosters_by_id: {},
	},
	transaction_history: {
		is_pending: false,
		error: null,
		result: []
	},
	regenerate_list: {
		is_pending: false,
		error: null,
		result: []
	},
	...initialRegenerateShow,
	by_id: {},
	teams_by_id: {},
	server_time_diff: 0,
	subscribe: {
		is_pending: false,
		error: null,
		success: false,
	},
	change_team_coach: {
		is_pending: false,
		error: null,
		success: false,
	},
	override_scores: {
		is_pending: false,
		error: null,
		success: false,
		team_scores: {},
		save_success: false,
	}
};

export const leagues = createReducer({}, initial_state);

// createDraftLeagueReducers
leagues.on(actions.leagueDraft.createDraftLeague, state => ({
	...state,
	create: {
		...state.create,
		is_pending: true,
		error: null,
	},
}));
leagues.on(actions.leagueDraft.createDraftLeagueSuccess, 
	(state, payload: any) => ({
		...state,
		create: {
			...state.create,
			is_pending: false,
			error: null,
			league_id: payload.id,
		},
	}));
leagues.on(actions.leagueDraft.createDraftLeagueFailed, 
	(state, payload) => ({
		...state,
		create: {
			...state.create,
			is_pending: false,
			error: payload,
		},
	}));
leagues.on(actions.leagueDraft.createDraftLeagueUF, state => ({
	...state,
	create_uf: {
		...state.create,
		is_pending: "loading",
		error: null,
	},
}));
leagues.on(actions.leagueDraft.createDraftLeagueUFSuccess, 
	(state, payload: any) => ({
		...state,
		create_uf: {
			...state.create,
			is_pending: "success",
			error: null,
			league_id: payload.id,
		},
		create: {
			...state.create,
			is_pending: false,
			error: null,
			league_id: payload.id,
		},
	}));
leagues.on(actions.leagueDraft.createDraftLeagueUFFailed, 
	(state, payload) => ({
		...state,
		create_uf: {
			...state.create,
			is_pending: "fail",
			error: payload,
		},
	}));
leagues.on(actions.leagueDraft.clearUFDraftLeagueCreateErrors, state => ({
	...state,
	create_uf: {
		...state.create_uf,
		is_pending: false,
		error: null
	}
}));

// updateDraftLeagueReducers
leagues.on(actions.leagueDraft.updateDraftLeague, (state,payload) => {
	return {
		...state,
		update: {
			...state.update,
			is_pending: true,
			error: null,
			status:LoadingStatus.LOADING
		},
		by_id: {
			...state.by_id,
			[payload.id]:{
				...state.by_id[payload.id],
				...payload,
				custom_scoring: payload.scoreValues
			}
		}
	};
});
leagues.on(actions.leagueDraft.updateDraftLeagueSuccess, state => {
	return {
		...state,
		update: {
			...state.update,
			is_pending: false,
			error: null,
			status:LoadingStatus.SUCCESS
		},
	};
});
leagues.on(actions.leagueDraft.updateDraftLeagueFailed, (state, payload) => ({
	...state,
	update: {
		...state.update,
		is_pending: false,
		error: payload,
		status:LoadingStatus.ERROR
	},
}));

// showMyDraftLeaguesReducers
leagues.on(actions.leagueDraft.showMyDraftLeagues, state => ({
	...state,
	show_my: {
		...state.show_my,
		is_pending: true,
		error: null,
	},
	remove: {
		...state.remove,
		removedLeague: 0
	}
}));

leagues.on(actions.leagueDraft.showMyDraftLeaguesSuccess, (state, payload: any) => {
	// const by_id = arrayToObjectById(payload, state.by_id);
	// const by_id = mergeWith(keyBy(payload, 'id'), state.by_id);

	const ordered_ids = payload.map(item => item.id);
	const by_id = payload.reduce((obj, item) => {
		if (obj[item.id]) {
			return { ...obj, [item.id]: { ...obj[item.id], ...item } };
		}
		return { ...obj, [item.id]: item };
	}, state.by_id);


	return {
		...state,
		show_my: {
			...state.show_my,
			is_pending: false,
			ordered_ids,
		},
		by_id: {
			...state.by_id,
			...by_id
		}
	};
});
leagues.on(actions.leagueDraft.showMyDraftLeaguesFailed, (state, payload) => ({
	...state,
	show_my: {
		...state.show_my,
		is_pending: false,
		error: payload,
	},
}));


// showDraftLeagueReducers
leagues.on(actions.leagueDraft.showDraftLeague, (state, payload: any) => ({
	...state,
	show: {
		...state.show,
		is_pending: true,
		error: null,
		league_id: payload.id
	},
}));

leagues.on(actions.leagueDraft.showDraftLeagueSuccess, (state, payload: any) => {
	const teams = payload.teams || [];
	const teams_by_id = teams.reduce((obj, item) => {
		if (obj[item.id]) {
			return { ...obj, [item.id]: { ...obj[item.id], ...item } };
		}
		return { ...obj, [item.id]: item };
	}, state.teams_by_id);
	// const teams_by_id = _.mergeWith(_.keyBy(teams, 'id'), state.teams_by_id, customizer);
	return {
		...state,
		show: {
			...state.show,
			is_pending: false,
			league_id: payload.id,
		},
		by_id: {
			...state.by_id,
			[payload.id]: {
				...state.by_id[payload.id],
				...payload,
				teams: teams.map(item => item.id),
			},
		},
		teams_by_id,
	};
});
leagues.on(actions.leagueDraft.postWaiverAddDropPlayers, state => ({
	...state,
	show: {
		...state.show,
		is_pending: true,
	}
}));
leagues.on(actions.leagueDraft.postWaiverAddDropPlayersSuccess, onSuccessWaiverRequest);
leagues.on(actions.leagueDraft.postWaiverAddDropPlayersFailed, (state, payload) => ({
	...state,
	show: {
		...state.show,
		is_pending: false,
		error: payload
	}
}));

leagues.on(actions.leagueDraft.showDraftLeagueFailed, (state, payload) => ({
	...state,
	show: {
		...state.show,
		is_pending: false,
		error: payload,
	},
}));


// showToJoinDraftLeaguesReducers
leagues.on(actions.leagueDraft.showToJoinDraftLeagues, state => ({
	...state,
	show_to_join: {
		...state.show_to_join,
		is_pending: true,
		error: null,
		ordered_ids: [],
		offset: 0,
	},
}));
leagues.on(actions.leagueDraft.showToJoinDraftLeaguesSuccess, 
	(state, { offset = 0, result }: any) => {
		const by_id = arrayToObjectById(result, state.by_id);
		const ordered_ids = result.map(item => item.id);
		return {
			...state,
			show_to_join: {
				...state.show_to_join,
				is_pending: false,
				ordered_ids,
				offset,
			},
			by_id,
		};
	});
leagues.on(actions.leagueDraft.showToJoinDraftLeaguesFailed, (state, payload) => ({
	...state,
	show_to_join: {
		...state.show_to_join,
		is_pending: false,
		error: payload,
	},
}));

// showToJoinLoadMoreDraftLeaguesReducers
leagues.on(actions.leagueDraft.showToJoinLoadMoreDraftLeagues, state => ({
	...state,
	show_to_join: {
		...state.show_to_join,
		is_pending: true,
		error: null,
	},
}));
leagues.on(actions.leagueDraft.showToJoinLoadMoreDraftLeaguesSuccess, 
	(state, { offset, result }: any) => {
		const by_id = arrayToObjectById(result, state.by_id);
		const ordered_ids = result.map(item => item.id);

		return {
			...state,
			show_to_join: {
				...state.show_to_join,
				is_pending: false,
				ordered_ids: uniq([
					...state.show_to_join.ordered_ids,
					...ordered_ids,
				]),
				offset,
			},
			by_id,
		};
	});
leagues.on(actions.leagueDraft.showToJoinLoadMoreDraftLeaguesFailed, (state, payload) => ({
	...state,
	show_to_join: {
		...state.show_to_join,
		is_pending: false,
		error: payload,
	},
}));


// removeDraftLeagueReducers
leagues.on(actions.leagueDraft.removeDraftLeague, state => ({
	...state,
	remove: {
		...state.remove,
		is_pending: true,
		error: null,
		success: false,
		removedLeague: 0
	},
}));
leagues.on(actions.leagueDraft.removeDraftLeagueFailed, (state, payload) => ({
	...state,
	remove: {
		...state.remove,
		is_pending: false,
		error: payload,
	},
}));
leagues.on(actions.leagueDraft.removeDraftLeagueSuccess, (state, payload) => ({
	...state,
	remove: {
		...state.remove,
		is_pending: false,
		error: null,
		success: true,
		removedLeague: payload
	},
	show_my: {
		...state.show_my,
		ordered_ids: state.show_my.ordered_ids.filter(item => item !== payload),
	},
	my_list: {
		...state.my_list,
		ordered_ids: state.my_list.ordered_ids.filter(item => item !== payload),
	},
	show_to_join: {
		...state.show_to_join,
		ordered_ids: state.show_to_join.ordered_ids.filter(item => item !== payload),
	},
}));


leagues.on(actions.leagueDraft.clearRemoveDraftLeagueErrors, state => ({
	...state,
	remove: {
		is_pending: false,
		error: null,
		success: false,
		removedLeague: 0
	},
}));


// joinToDraftLeagueReducers
leagues.on(actions.leagueDraft.joinToDraftLeague, state => ({
	...state,
	join_to: {
		...state.join_to,
		is_pending: true,
		error: null,
		team_id: null,
		league_id: null,
	},
}));
leagues.on(actions.leagueDraft.joinToDraftLeagueSuccess, (state, payload: any) => ({
	...state,
	join_to: {
		...state.join_to,
		is_pending: false,
		error: null,
		team_id: payload.team_id,
		league_id: payload.league_id,
	},
}));
leagues.on(actions.leagueDraft.joinToDraftLeagueFailed, (state, payload) => ({
	...state,
	join_to: {
		...state.join_to,
		is_pending: false,
		error: payload,
		team_id: null,
		league_id: null,
	},
}));
leagues.on(actions.leagueDraft.clearJoinToDraftLeagueErrors, state => ({
	...state,
	join_to: {
		is_pending: false,
		error: null,
		league_id: null,
		team_id: null,
	},
}));


// showTeamsDraftLeagueReducers
leagues.on(actions.leagueDraft.showTeamsDraftLeague, state => {
	return {
		...state,
		show_teams: {
			...state.show_teams,
			is_pending: true,
			error: null,
			status:"loading",
		},
		teams_by_id:{},
		// by_id:{},
	};
});
leagues.on(actions.leagueDraft.showTeamsDraftLeagueFailed, (state, payload) => ({
	...state,
	show_teams: {
		...state.show_teams,
		is_pending: false,
		error: payload,
		status:"failed",
	}
}));
leagues.on(actions.leagueDraft.showTeamsDraftLeagueSuccess, (state, payload: any) => {
	/**
	 * inital value was set to state.teams_by_id but 
	 * it was causing a duplicate team list in
	 * https://fanhub.atlassian.net/browse/AFD21-176
	 */
	
	const current_teams_by_id = { ...state.teams_by_id };
	let is_new_teams = false;

	const incoming_teams_by_id = payload.result.reduce((obj, item) => {
		if (obj[item.id]) {
			return { ...obj, [item.id]: { ...obj[item.id], ...item } };
		}
		return { ...obj, [item.id]: item };
	}, {});
	const payload_ids = payload.result.map(team => {
		return Number(team.id);
	});
	const isCurrentTeamsByIDKeys = Object.keys(current_teams_by_id);

	if(isCurrentTeamsByIDKeys.length === 0){
		is_new_teams = true;
	}
	if(Object.keys(incoming_teams_by_id).length > isCurrentTeamsByIDKeys.length){
		is_new_teams = true;
	}
	
	isCurrentTeamsByIDKeys.forEach(id => {
		const current_team = current_teams_by_id[id];
		if(!payload_ids.includes(Number(id))){
			is_new_teams = true;
		}
		Object.keys(incoming_teams_by_id).forEach(index => {
			const current_incoming_team = incoming_teams_by_id[index];
			if(index === id){
				Object.keys(current_incoming_team).forEach(property => {
					if(	current_team[property] !== current_incoming_team[property]){
						current_team[property] = current_incoming_team[property];
					}
				});
			}
	    });
	});
	const teams_by_id_value = is_new_teams ? incoming_teams_by_id : current_teams_by_id;
	
	return {
		...state,
		by_id: {
			...state.by_id,
			[payload.league_id]: {
				...state.by_id[payload.league_id],
				teams: payload.result.map(item => item.id),
			},
		},
		teams_by_id: teams_by_id_value,
		show_teams: {
			...state.show_teams,
			is_pending: false,
			error: null,
			status:"success",
		},
		// may need to do more here. API returns team_id. Will know more when we build out the UI.
	};
});


// inviteToDraftLeaguesReducers
leagues.on(actions.leagueDraft.inviteToDraftLeague, state => ({
	...state,
	invite_to: {
		...state.invite_to,
		is_pending: true,
	},
}));
leagues.on(actions.leagueDraft.inviteToDraftLeagueFailed, (state, payload) => ({
	...state,
	invite_to: {
		...state.invite_to,
		is_pending: false,
		error: payload,
	},
}));
leagues.on(actions.leagueDraft.inviteToDraftLeagueSuccess, (state, payload) => ({
	...state,
	invite_to: {
		...state.invite_to,
		is_pending: false,
		error: null,
		result: payload,
	},
}));


// ladderDraftLeaguesReducer
leagues.on(actions.leagueDraft.ladderDraftLeague, state => ({
	...state,
	ladder: {
		...state.ladder,
		is_pending: true,
		error: null,
	},
}));
leagues.on(actions.leagueDraft.ladderDraftLeagueFailed, (state, payload) => ({
	...state,
	ladder: {
		...state.ladder,
		is_pending: false,
		error: payload,
	},
}));
leagues.on(actions.leagueDraft.ladderDraftLeagueSuccess, (state, payload: any) => {
	const teams_by_id = payload.result.reduce((obj, item) => {
		if (obj[item.id]) {
			const existingLineup =get(state.teams_by_id[item.id], 'lineup', {});
			const incomingLineup = get(obj[item.id], 'lineup');
			const lineup = isEmpty(existingLineup) ? incomingLineup : existingLineup;
			return { 
				...obj, 
				[item.id]: { 
					...obj[item.id], 
					...item,
					lineup
				} 
			};
		}
		return { ...obj, [item.id]: item };
	}, state.teams_by_id);
	// const teams_by_id = _.mergeWith(_.keyBy(payload.result, 'id'),
	//	state.teams_by_id, customizer);

	return {
		...state,
		ladder: {
			...state.ladder,
			is_pending: false,
			error: null,
			ordered_ids: payload.result.map(item => item.id),
		},
		by_id: {
			...state.by_id,
			[payload.league_id]: {
				...state.by_id[payload.league_id],
				teams: payload.result.map(item => item.id),
			},
		},
		teams_by_id,
	};
});


// leaveDraftLeagueReducers
leagues.on(actions.leagueDraft.leaveDraftLeague, state => ({
	...state,
	leave: {
		...state.leave,
		is_pending: true,
		success: false
	},
}));
leagues.on(actions.leagueDraft.leaveDraftLeagueFailed, (state, payload) => ({
	...state,
	leave: {
		...state.leave,
		is_pending: false,
		error: payload,
	},
}));
leagues.on(actions.leagueDraft.leaveDraftLeagueSuccess, (state, payload) => ({
	...state,
	leave: {
		...state.leave,
		is_pending: false,
		error: null,
		success: true
	},
	my_list: {
		...state.my_list,
		ordered_ids: state.my_list.ordered_ids.filter(item => item !== payload),
	},
	show_my: {
		...state.show_my,
		ordered_ids: state.show_my.ordered_ids.filter(item => item !== payload),
	},
}));

// removeTeamDraftLeagueReducers
leagues.on(actions.leagueDraft.removeTeamDraftLeague, state => ({
	...state,
}));
leagues.on(actions.leagueDraft.removeTeamDraftLeagueFailed, (state, payload) => ({
	...state,
}));
leagues.on(actions.leagueDraft.removeTeamDraftLeagueSuccess, (state, payload: any) => {
	delete state.teams_by_id[payload.team_id];
	return {
		...state,
		remove_team: {
			...state.remove_team,
			success: true
		}
	};
});

// changeDraftOrderReducers
leagues.on(actions.leagueDraft.changeDraftOrder, (state,payload) => {
	return {
		...state,
		show_order: {
			...state.show_order,
			ordered_ids: payload.team,
			draft_order_status: "updating"
		},
	};
});
leagues.on(actions.leagueDraft.changeDraftOrderFailed, (state, payload) => ({
	...state,
	show_order: {
		...state.show_order,
		error: payload,
		draft_order_status: "failed"
	},
}));
leagues.on(actions.leagueDraft.changeDraftOrderSuccess, (state, payload: any) => {
	return {
		...state,
		show_order: {
			...state.show_order,
			ordered_ids: payload.draft_order,
			draft_order_status: "saved"
		},
	};
});


// showDraftOrderReducers
leagues.on(actions.leagueDraft.showDraftOrder, state => ({
	...state,
	show_order: {
		...state.show_order,
		is_pending: true,
	},
}));
leagues.on(actions.leagueDraft.showDraftOrderFailed, (state, payload) => ({
	...state,
	show_order: {
		...state.show_order,
		is_pending: false,
		error: payload,
		ordered_ids: [],
	},
}));
leagues.on(actions.leagueDraft.resetShowDraftOrder, (state, payload) => {
	return {
		...state,
		show_order: {
			is_pending: false,
			error: null,
			draft_order_status: "idle",
			ordered_ids: state.show_order.ordered_ids_from_api
		},
	};
});
leagues.on(actions.leagueDraft.showDraftOrderSuccess, (state, payload: any) => {
	const teams_by_id = payload.result.reduce((obj, item) => {
		if (obj[item.id]) {
			return { ...obj, [item.id]: { ...obj[item.id], ...item } };
		}
		return { ...obj, [item.id]: item };
	}, state.teams_by_id);

	const teams = payload.result.map(item => item.id);
	return {
		...state,
		show_order: {
			...state.show_teams,
			is_pending: false,
			error: null,
			ordered_ids: teams,
			ordered_ids_from_api: teams,
			draft_order_status: "idle"			
		},
		by_id: {
			...state.by_id,
			[payload.league_id]: {
				...state.by_id[payload.league_id],
				teams,
			},
		},
		teams_by_id,
	};
});

// saveCustomFullDraftOrderReducers
 
leagues.on(actions.leagueDraft.saveCustomFullDraftOrder, (state, payload) => {
	return {
		...state,
		show_full_order: {
			...state.show_full_order,
			order: payload.order,
			draft_full_order_status: "saving",
		},
	};
});

leagues.on(actions.leagueDraft.saveCustomFullDraftOrderFailed, (state, payload) => {
	return {
		...state,
		show_full_order: {
			...state.show_full_order,
			draft_full_order_status: "error",
			error: payload,
		},
	};
});

leagues.on(actions.leagueDraft.saveCustomFullDraftOrderSuccess, (state, payload: any) => {
	return {
		...state,
		show_full_order:{
			order: payload.result.custom_full_draft_order,
			error: null,
			draft_full_order_status: "saved"	
		}
	};
});
leagues.on(actions.leagueDraft.saveKeeperDraftPicks, (state, payload) => {
	return {
		...state,
		show_full_order: {
			...state.show_full_order,
			draft_full_order_status: "saving",
			keeper_pick_order: payload.order
		}
	};
});
leagues.on(actions.leagueDraft.saveKeeperDraftPicksSuccess, (state, payload) => {
	return {
		...state,
		show_full_order: {
			...state.show_full_order,
			draft_full_order_status: "saved",
			keeper_pick_order: payload.result
		}
	};
});
leagues.on(actions.leagueDraft.saveKeeperDraftPicksFailed, (state, payload) => {
	return {
		...state,
		show_full_order: {
			...state.show_full_order,
			draft_full_order_status: "error",
			error: payload.message
		}
	};
});



leagues.on(actions.leagueDraft.resetCustomFullDraftOrderStatus, state => {
	return {
		...state,
		show_full_order:{
			...state.show_full_order,
			error: null,
			draft_full_order_status: "idle"		
		}
	};
});

// showCustomFullDraftOrderReducers
 
leagues.on(actions.leagueDraft.showCustomFullDraftOrder, state => ({
	...state,
	show_full_order: {
		...initialShowFullOrder,
		draft_full_order_status: "loading",
	},
}));

leagues.on(actions.leagueDraft.showCustomFullDraftOrderFailed, (state, payload) => ({
	...state,
	show_full_order: {
		...initialShowFullOrder,
		draft_full_order_status: "error",
		error: payload,
	},
}));

leagues.on(actions.leagueDraft.showCustomFullDraftOrderSuccess, (state, payload: any) => ({
	...state,
	show_full_order:{
		order: payload.result,
		error: null,
		draft_full_order_status: "loaded"	
	}
}));

const onSuccessTeamUpdate = (state, payload: any) => {
	const { teams_by_id } = state;
	return {
		...state,
		teams_by_id: {
			...teams_by_id,
			[payload.id]: {
				...teams_by_id[payload.id],
				lineup: payload.lineup,
			},
		},
	};
};
leagues.on(actions.leagueDraft.userTeamUpdated, onSuccessTeamUpdate);
leagues.on(actions.leagueDraft.userTeamUpdatedRemovePlayer, onSuccessTeamUpdate);
leagues.on(actions.leagueDraft.forceTeamUpdate, onSuccessTeamUpdate);

// myList Reducers
leagues.on(actions.leagueDraft.myList, state => ({
	...state,
	my_list: {
		...state.my_list,
		is_pending: true,
	},
}));

leagues.on(actions.leagueDraft.myListSuccess, (state, payload: any) => ({
	...state,
	my_list: {
		...state.my_list,
		is_pending: false,
		success: true,
		ordered_ids: payload.map(item => item.id),
		by_id: arrayToObjectById(payload),
	},
}));

leagues.on(actions.leagueDraft.myListFailed, (state, payload) => ({
	...state,
	my_list: {
		...state.my_list,
		is_pending: false,
		success: false,
		error: payload,
	},
}));

// RFA Order Reducers
leagues.on(actions.leagueDraft.fetchWaiverOrder, state => ({
	...state,
	waiver_order: {
		...state.waiver_order,
		is_pending: true,
	},
}));

leagues.on(actions.leagueDraft.fetchWaiverOrderSuccess, (state, payload: any) => ({
	...state,
	waiver_order: {
		...state.waiver_order,
		is_pending: false,
		ordered_ids: payload.map(item => item.id),
		by_id: arrayToObjectById(payload),
	},
}));

leagues.on(actions.leagueDraft.fetchWaiverOrderFailed, (state, payload) => ({
	...state,
	waiver_order: {
		...state.waiver_order,
		is_pending: false,
		error: payload,
	},
}));

// RFA List Reducers
leagues.on(actions.leagueDraft.fetchWaiverList, state => ({
	...state,
	waiver_list: {
		...state.waiver_list,
		is_pending: true,
	},
}));

leagues.on(actions.leagueDraft.fetchWaiverListSuccess, (state, payload: any) => ({
	...state,
	waiver_list: {
		...state.waiver_list,
		is_pending: false,
		ordered_ids: payload.map(item => item.player_id),
		by_id: keyBy(payload, 'player_id'),
	},
}));

leagues.on(actions.leagueDraft.fetchWaiverListFailed, (state, payload) => ({
	...state,
	waiver_list: {
		...state.waiver_list,
		is_pending: false,
		error: payload,
	},
}));

// FA List Reducers
leagues.on(actions.leagueDraft.fetchWaiverFree, state => ({
	...state,
	waiver_free: {
		...state.waiver_free,
		is_pending: true,
	},
}));

leagues.on(actions.leagueDraft.fetchWaiverFreeSuccess, (state, payload: any) => ({
	...state,
	waiver_free: {
		...state.waiver_free,
		is_pending: false,
		ordered_ids: payload,
	},
}));

leagues.on(actions.leagueDraft.fetchWaiverFreeFailed, (state, payload) => ({
	...state,
	waiver_free: {
		...state.waiver_free,
		is_pending: false,
		error: payload,
	},
}));

leagues.on(actions.leagueDraft.clearDraftLeagueCreateErrors, state => ({
	...state,
	create: {
		is_pending: false,
		error: null,
	},
	
}));

leagues.on(actions.leagueDraft.clearUFDraftLeagueCreate, state => ({
	...state,
	create_uf: {
		is_pending: "idle",
		error: null,
		league_id: 0,
	},
	create: {
		...state.create,
		league_id: 0
	}

}));

leagues.on(actions.leagueDraft.clearDraftLeagueUpdateErrors, state => ({
	...state,
	update: {
		is_pending: false,
		error: null,
		status:LoadingStatus.IDLE
	},
}));

leagues.on(actions.leagueDraft.fetchWaiverRequests, state => ({
	...state,
	waiver_requests: {
		...state.waiver_requests,
		is_pending: true,
	},
}));

leagues.on(actions.leagueDraft.fetchWaiverRequestsSuccess, onSuccessWaiverRequest);

leagues.on(actions.leagueDraft.fetchWaiverRequestsFailed, (state, payload: any) => ({
	...state,
	waiver_requests: {
		...state.waiver_requests,
		is_pending: false,
		error: payload,
	},
}));

leagues.on(actions.leagueDraft.updatedWaiverRequests, onSuccessWaiverRequest);

leagues.on(actions.leagueDraft.fetchLeagueRosters, state => ({
	...state,
	rosters: {
		...state.rosters,
		is_pending: true,
		error: null
	},
}));

leagues.on(actions.leagueDraft.fetchLeagueRostersSuccess, (state, payload: any) => {
	const teams_by_id = payload.reduce((obj, item) => {
		if (obj[item.id]) {
			return { ...obj, [item.id]: { ...obj[item.id], ...item } };
		}
		return { ...obj, [item.id]: item };
	}, state.teams_by_id);

	const rosters_by_id = payload.reduce((obj, item) => {
		const team_lineup = item.lineup;
		return {
			...obj,
			[item.id]: team_lineup
		};
	}, {});

	return {
		...state,
		rosters: {
			...state.rosters,
			is_pending: false,
			ordered_ids: payload.map(team => team.id),
			rosters_by_id
		},
		teams_by_id
	};
});

leagues.on(actions.leagueDraft.fetchLeagueRostersFailed, (state, payload: any) => ({
	...state,
	rosters: {
		...state.rosters,
		is_pending: false,
		error: payload,
	},
}));

leagues.on(
	actions.leagueDraft.requestServerAndLocalTimeDiffSuccess,
	(state, server_time_diff: any) => ({ ...state, server_time_diff })
);

leagues.on(actions.leagueDraft.fetchTransactionHistory, state => ({
	...state,
	transaction_history: {
		...state.transaction_history,
		is_pending: true,
		error: null
	},
}));

leagues.on(actions.leagueDraft.fetchTransactionHistorySuccess, (state, payload: any) => ({
	...state,
	transaction_history: {
		...state.transaction_history,
		is_pending: false,
		result: payload
	},
}));

leagues.on(actions.leagueDraft.fetchTransactionHistoryFailed, (state, payload: any) => ({
	...state,
	transaction_history: {
		...state.transaction_history,
		is_pending: false,
		error: payload,
	},
}));

// Regen Leagues
leagues.on(actions.leagueDraft.regenerateListDraftLeagues, state => ({
	...state,
	regenerate_list: {
		is_pending: true,
		error: null,
		result: []
	}
}));
leagues.on(actions.leagueDraft.regenerateListDraftLeaguesSuccess, (state, payload: any) => ({
	...state,
	regenerate_list: {
		is_pending: false,
		error: null,
		result: payload
	}
}));
leagues.on(actions.leagueDraft.regenerateListDraftLeaguesFailed, (state, payload: any) => ({
	...state,
	regenerate_list: {
		is_pending: false,
		error: payload,
		result: []
	}
}));
leagues.on(actions.leagueDraft.resetRegenerateShow, state => ({
	...state,
	...initialRegenerateShow
}));
leagues.on(actions.leagueDraft.regenerateShowDraftLeague, state => ({
	...state,
	regenerate_show: {
		...state.regenerate_show,
		is_pending: true,
		error: null
	}
}));
leagues.on(actions.leagueDraft.regenerateShowDraftLeagueSuccess, (state, payload: any) => ({
	...state,
	regenerate_show: {
		is_pending: false,
		error: null,
		by_id: {
			...state.regenerate_show.by_id,
			[payload.id]: payload
		}
	}
}));
leagues.on(actions.leagueDraft.regenerateShowDraftLeagueFailed, (state, payload: any) => ({
	...state,
	regenerate_show: {
		...state.regenerate_show,
		is_pending: false,
		error: payload
	}
}));
leagues.on(actions.leagueDraft.regenerateShowDraftLeagueUF, state => ({
	...state,
	regenerate_show_uf: {
		...state.regenerate_show_uf,
		is_pending: true,
		error: null
	}
}));
leagues.on(actions.leagueDraft.regenerateShowDraftLeagueUFSuccess, (state, payload: any) => ({
	...state,
	regenerate_show_uf: {
		is_pending: false,
		error: null,
		by_id: {
			...state.regenerate_show_uf.by_id,
			[payload.id]: payload
		}
	}
}));
leagues.on(actions.leagueDraft.regenerateShowDraftLeagueUFFailed, (state, payload: any) => ({
	...state,
	regenerate_show_uf: {
		...state.regenerate_show_uf,
		is_pending: false,
		error: payload
	}
}));
leagues.on(actions.leagueDraft.subscribeLeague, state => ({
	...state,
	subscribe: {
		is_pending: true,
		error: null,
		success: false,
	},
}));
leagues.on(actions.leagueDraft.subscribeLeagueSuccess, (state, payload) => ({
	...state,
	subscribe: {
		...payload,
		is_pending: false,
		error: null,
		success: true,
	},
}));
leagues.on(actions.leagueDraft.subscribeLeagueFailed, (state, payload) => ({
	...state,
	subscribe: {
		is_pending: false,
		error: payload,
		success: false,
	},
}));
leagues.on(actions.leagueDraft.clearSubscribeLeagueErrors, state => ({
	...state,
	subscribe: {
		is_pending: false,
		error: null,
		success: false,
	},
}));
leagues.on(actions.leagueDraft.draftLeagueSettingsUpdate, (state, payload: any) => {
	return {
		...state,
		by_id: {
			...state.by_id,
			[payload.id]: {
				...state.by_id[payload.id],
				[payload.key]:payload.value
			},
		},
	};
});
leagues.on(actions.leagueDraft.changeTeamCoach, state => ({
	...state,
	change_team_coach: {
		is_pending: true,
		error: null,
		success: false,
	},
}));
leagues.on(actions.leagueDraft.changeTeamCoachSuccess, (state, payload) => ({
	...state,
	change_team_coach: {
		...payload,
		is_pending: false,
		error: null,
		success: true,
	},
	teams_by_id: {
		...state.teams_by_id,
		[payload.team_id]: {
			...state.teams_by_id[payload.team_id],
			lastname: payload.last_name,
			firstname: payload.first_name,
			name: payload.team_name,
			user_id: payload.user_id
		}
	}

}));
leagues.on(actions.leagueDraft.changeTeamCoachFailed, (state, payload) => ({
	...state,
	change_team_coach: {
		is_pending: false,
		error: payload,
		success: false,
	},
}));
leagues.on(actions.leagueDraft.changeTeamCoachClear, state => ({
	...state,
	change_team_coach: {
		is_pending: false,
		error: null,
		success: false,
	}
}));
leagues.on(actions.leagueDraft.draftLeagueTeamUpdate, (state, payload: any) => {
	return {
		...state,
		teams_by_id: {
			...state.teams_by_id,
			[payload.team_id]: {
				...state.teams_by_id[payload.team_id],
				...payload.value
			},
		},
	};
});
leagues.on(actions.leagueDraft.fetchTeamOverrideScores, state => ({
	...state,
	override_scores: {
		is_pending: true,
		error: null,
		success: false,
		team_scores: {},
		save_success: false,
	}
}));
leagues.on(actions.leagueDraft.fetchTeamOverrideScoresSuccess, (state, payload) => {
	const teams_scores_by_id = payload.teams.reduce((obj, item) => {
		if (obj[item.id]) {
			return { ...obj, [item.id]: { ...obj[item.id], ...item } };
		}
		return { ...obj, [item.id]: item };
	}, state.teams_by_id);
	return {
		...state,
		override_scores: {
			is_pending: false,
			error: null,
			success: true,
			team_scores: teams_scores_by_id,
			save_success: false,
		}
	};
});
leagues.on(actions.leagueDraft.fetchTeamOverrideScoresFailed, (state, payload) => ({
	...state,
	override_scores: {
		is_pending: false,
		error: payload,
		success: false,
		team_scores: {},
		save_success: false,
	}
}));
leagues.on(actions.leagueDraft.teamOverrideScoresUpdate, state => ({
	...state,
	override_scores: {
		is_pending: true,
		error: null,
		success: false,
		team_scores: state.override_scores.team_scores,
		save_success: false,
	} }
));
leagues.on(actions.leagueDraft.teamOverrideScoresUpdateSuccess, (state, payload) => {
	const teams_scores_by_id = payload.teams.reduce((obj, item) => {
		if (obj[item.id]) {
			return { ...obj, [item.id]: { ...obj[item.id], ...item } };
		}
		return { ...obj, [item.id]: item };
	}, state.teams_by_id);
	return {
		...state,
		override_scores: {
			is_pending: false,
			error: null,
			success: true,
			team_scores: teams_scores_by_id,
			save_success: true,
		}
	};
});
leagues.on(actions.leagueDraft.teamOverrideScoresUpdateFailed, (state, payload) => ({
	...state,
	override_scores: {
		is_pending: false,
		error: payload,
		success: false,
		team_scores: state.override_scores.team_scores,
		save_success: false,
	}
}));
leagues.on(actions.leagueDraft.clearTeamOverrideScores, state => ({
	...state,
	override_scores: {
		is_pending: false,
		error: null,
		success: false,
		team_scores: state.override_scores.team_scores,
		save_success: false,
	}
}));

export default leagues;