// @flow
import React from "react";
import SvgIconContainer from "../SvgIconContainer";

export default (
	{
		unit = "em",
		...rest
	}: Object = {}) => (
	<SvgIconContainer viewBox="0 0 16 16" {...rest} unit={unit}>
		<g id="Ladder/Icons/Hold" stroke="none" strokeWidth="1" fillRule="evenodd">
			<polygon id="Shape" fill="#C0C0C0" fillRule="nonzero" points="0 4 10 4 10 6 0 6" />
		</g>
	</SvgIconContainer>
);