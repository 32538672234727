// @flow
import styled from "styled-components";
import * as React from "react";
import { get } from "lodash";
import { below } from "../../assets/css/media";
import {
	PlayerTableCell,
	PlayerInfo, TradePlayerCost,
} from "../";
import {TPlayer} from "../../modules/types";
import PlayerAvatar from "../PlayerInfo/playerAvatar";
import PlayerInfoShadow from "../PlayerInfoShadow";
import CloseCircle from "../Icons/CloseCircle";
import { TradeCell } from "./TradeCell";
import { getPlayerCost } from "./playerCost";


const OutCellStyled = styled(TradeCell)`
	box-sizing: border-box;
	border: 1px solid #CAD2D8;
	padding: ${({ compressed }) => compressed ? "5px" : "0 10px"};
	${({isLocked}) => isLocked && `
		opacity: 0.7;
	`}
	.filled-icon {
		margin-left: auto;
		margin-right: 4em;
		${below.desktop`
			margin-right: 1em;
		`}
	}
	${below.desktop`
		padding: 0 0px;
		width: ${({ trade_item }) => trade_item === true ? "100%;" : "100%;"}
		.player-avatar{
			display: none;
			width: 45px
		}
		.player-info{
			margin: 0px;
			margin-left: 0px;

		}
		.player-details-status{
			margin-left: 0px;
			width: 25px;
			
		}
	`};
`;
const IconWrapper = styled.div`
	margin-top: 10px;
`;
type TCell = {
	player: TPlayer,
	compressed?: boolean,
	with_cost?: boolean,
	is_classic: boolean,
	selected_round?:  Object,
	status_show?: boolean,
	trade_item?: boolean,
};
export const TradeOutCellShow = ({ 
	player, 
	compressed = false, 
	with_cost = false, 
	selected_round,
	is_classic,
	status_show,
	trade_item
}: TCell) => {
	const awaySquadGetter = match => get(match, 'away_squad_id', 0); 
	const homeSquadGetter = match => get(match, 'home_squad_id', 0);
	const playerSquad = get(player, 'squad_id');
	const playerMatch = get(selected_round, 'matches', []).find(match => 
		[awaySquadGetter(match), homeSquadGetter(match)].includes(playerSquad));
	const isMatchScheduled = get(playerMatch, 'status', 'scheduled') === 'scheduled';

	return (
		<OutCellStyled 
			compressed={compressed} 
			trade_item={trade_item}
			isLocked={!isMatchScheduled}
		>

			{compressed && <React.Fragment>
				<PlayerAvatar player={player} />
				<PlayerInfoShadow compressed reverse />
			</React.Fragment>}

			<PlayerTableCell 
				width="auto" 
				height="60px" 
				justify="space-between"
				compressed={compressed}
			>
				{player && <PlayerInfo 
					is_compressed={compressed} 
					player={player} 
					is_classic={is_classic}
					status_show={status_show} 
				/>}
			</PlayerTableCell>
			{with_cost && (
				<TradePlayerCost margin="right">
					{ getPlayerCost(player, selected_round) }
				</TradePlayerCost>
			)}
			<IconWrapper>
				<CloseCircle size={2.25}/>
			</IconWrapper>
			
		</OutCellStyled>
	);
};

export default TradeOutCellShow;