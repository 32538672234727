// @flow
import { createAction } from "redux-act";

export const postPreDraftList = createAction("Post updated pre-draft list");
export const postPreDraftListSuccess = createAction(
	"Post updated pre-draft list success"
);
export const postPreDraftListFailed = createAction(
	"Post updated pre-draft list failed"
);

export const postResetPreDraftList = createAction("Post Reset pre-draft list");
export const postResetPreDraftListSuccess = createAction(
	"Post Reset pre-draft list success"
);
export const postResetPreDraftListFailed = createAction(
	"Post Reset pre-draft list failed"
);

export const makeCommissionerTrade = createAction(
	"Make Commisioner Trade"
);
export const makeCommissionerTradeSuccess = createAction(
	"Make Commisioner Trade Success"
);
export const makeCommissionerTradeFailed = createAction(
	"Make Commisioner Trade Failed"
);
export const clearCommissionerTradeState = createAction(
	"Clear Commisioner Trade state"
);

export const forceUpdatePreDraftList = createAction(
	"Local force update of pre-draft list"
);
export const clearUpdatePreDraftListSuccessFlag = createAction(
	"Clear update of pre-draft list success flag"
);

export const addPlayerToPreDraftList = createAction(
	"Add player to pre-draft list"
);
export const removePlayerFromPreDraftList = createAction(
	"Remove player from pre-draft list"
);
export const updatePlayerPreDraftListOrder = createAction(
	"Update player pre-draft list order"
);

export const fetchPreDraftList = createAction("Fetch pre-draft list");
export const fetchPreDraftSuccess = createAction(
	"Fetch pre-draft list success"
);
export const fetchPreDraftFailed = createAction("Fetch pre-draft list failed");

export const postPickedPlayer = createAction(
	"Post picked player during Draft process"
);
export const postPickedPlayerSuccess = createAction(
	"Post picked player during Draft process success"
);
export const postPickedPlayerFailed = createAction(
	"Post picked player during Draft process failed"
);

export const postAutoPickFlag = createAction("Toggle my team auto pick");
export const postAutoPickFlagSuccess = createAction(
	"Toggle my team auto pick success"
);
export const postAutoPickFlagFailed = createAction(
	"Toggle my team auto pick failed"
);

export const postTeamUpdate = createAction("Update team");
export const postTeamUpdateSuccess = createAction("Update team success");
export const postTeamUpdateFailed = createAction("Update team failed");

export const fetchSnapshot = createAction("Fetch team snapshot");
export const fetchSnapshotSuccess = createAction("Fetch team snapshot success");
export const fetchSnapshotFailed = createAction("Fetch team snapshot failed");

export const fetchLineups = createAction("Fetch league lineups");
export const fetchLineupsSuccess = createAction("Fetch league lineups success");
export const fetchLineupsFailed = createAction("Fetch league lineups failed");

export const fetchTeam = createAction("Fetch user team");
export const fetchTeamSuccess = createAction("Fetch user team success");
export const fetchTeamFailed = createAction("Fetch user team failed");
export const clearTeamErrors = createAction("Clear Team errors");

export const postRequestWaiver = createAction("Post Request Waiver");
export const postRequestWaiverSuccess = createAction(
	"Post Request Waiver success"
);
export const postRequestWaiverFailed = createAction(
	"Post Request Waiver failed"
);
export const postRequestWaiverClear = createAction("Post Request Waiver clear");

export const fetchMakeTrade = createAction("Fetch Make Trade");
export const fetchMakeTradeSuccess = createAction("Fetch Make Trade Success");
export const fetchMakeTradeFailed = createAction("Fetch Make Trade Failed");

export const fetchTrades = createAction("Fetch Trades");
export const fetchTradesSuccess = createAction("Fetch Trades Success");
export const forceTradesSuccess = createAction("Force Trade Success");
export const fetchTradesFailed = createAction("Fetch Trades Failed");

export const fetchMyTeams = createAction("Fetch My Draft Teams");
export const fetchMyTeamsSuccess = createAction("Fetch My Draft Teams Success");
export const fetchMyTeamsFailed = createAction("Fetch My Draft Teams Failed");

export const fetchRejectTrade = createAction("Fetch Reject Trade");
export const fetchRejectTradeFailed = createAction("Fetch Reject Trade Failed");

export const fetchAcceptTrade = createAction("Fetch Accept Trade");
export const fetchAcceptTradeFailed = createAction("Fetch Accept Trade Failed");

export const fetchRemovePlayer = createAction("Fetch Remove Player");
export const fetchRemovePlayerSuccess = createAction(
	"Fetch Remove Player Success"
);
export const fetchRemovePlayerFailed = createAction(
	"Fetch Remove Player Failed"
);

export const fetchBlockTrade = createAction("Fetch Block Trade");
export const fetchBlockTradeFailed = createAction("Fetch Block Trade Failed");

export const fetchApproveTrade = createAction("Fetch Approve Trade");
export const fetchApproveTradeFailed = createAction(
	"Fetch Approve Trade Failed"
);

export const fetchRemoveWaiverTrade = createAction("Fetch Remove Waiver Trade");
export const fetchRemoveWaiverTradeFailed = createAction(
	"Fetch Remove Waiver Trade Failed"
);

export const postOrderWaiverTrade = createAction("Post Order Waivers");
export const postOrderWaiverTradeSuccess = createAction(
	"Post Order Waivers Success"
);
export const postOrderWaiverTradeFailed = createAction(
	"Post Order Waivers Failed"
);

export const clearTeamsDraftErrors = createAction("Clear teamsDraft Errors");

export const fetchPlayersOnOfferList = createAction(
	"Fetch Player on Trade List"
);
export const fetchPlayersOnOfferListSuccess = createAction(
	"Fetch Player on Trade List Success"
);
export const fetchPlayersOnOfferListFailed = createAction(
	"Fetch Player on Trade List Failed"
);

export const fetchCancelPlayerOnOffer = createAction(
	"Fetch Cancel Player On Offer"
);
export const fetchCancelPlayerOnOfferSuccess = createAction(
	"Fetch Cancel Player On Offer Success"
);
export const fetchCancelPlayerOnOfferFailed = createAction(
	"Fetch Cancel Player On Offer Failed"
);

export const postSelectKeeper = createAction("Select Keeper");
export const postSelectKeeperSuccess = createAction("Select Keeper Success");
export const postSelectKeeperFailed = createAction("Select Keeper Failed");

export const removeKeeper = createAction("Remove Keeper");
export const removeKeeperSuccess = createAction("Remove Keeper Success");
export const removeKeeperFailed = createAction("Remove Keeper Failed");