// @flow
import * as React from "react";
import styled from "styled-components";
import * as _ from "lodash";

import { Select } from "../Form";
import colors from "../../assets/css/colors";
import { below } from "../../assets/css/media";

import { Label, Tooltip } from "../";

import CellsCondensed from "../Icons/CellsCondensed";
import CellsLarge from "../Icons/CellsLarge";

const HeaderWrapper = styled.div`
	width: 100%;
	height: 60px;
	display: flex;
	background: ${props => props.reverseColors === true ?
		colors.form.base:colors.primary.primary};
	color: ${props => props.reverseColors === true ?
		colors.primary.primary:colors.white};
	border-radius: 2px;
	justify-content: flex-end;
	align-items: center;
	box-sizing: border-box;
	padding: 0 10px;
	> div {
		height: 60px;
	}

	${below.tablet`
		border-radius: 0;
	`}
`;

const Title = styled.h3`
	font-family: TitilliumUpright;
	font-weight: 700;
	margin-right: auto;
	font-size: 16px;
	flex-grow: 1;
	${below.tablet`
		display: ${({ show_mob }) => show_mob ? "inline" : "none"};
		font-size: 14px;
	`}
`;

const ButtonsContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	${below.phone`
		display: none;
	`}
`;

const DraftHeaderButton = styled.button`
	background: transparent;
	width: 40px;
	height: 40px;
	padding: 0;
	border: none;
	border-radius: 2px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	margin-left: 5px;
	
	
	color: #fff;
	svg {
		width: 32px;
		height: 32px;
		g {
			fill: #496CA0;
		}
		${props => props.is_active === true && `
			g {
				fill: #fff;
			}
		`}
	}

	&:last-child {
		margin-left: 0px !important;
		${below.tablet`
			margin-left: 5px !important;
		`}
	}
`;

const NumberOfRowsContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-right: 16px;
	${below.tablet`
		display: none !important;
	`};
`;

const NumberOfRowsButton = styled.button`
	width: 60px;
	text-align: center;
	font-family: TitilliumUpright;
	font-weight: 700;
	color: ${colors.secondary.paleGrey};
	font-size: 14px;
	line-height: 2.14;
	background: transparent;
	border: none;
	position: relative;
	padding: 0;
	cursor: pointer;
	${({ is_active }) => is_active && `
		color: #fff;
		&:after {
			content: '';
			display: block;
			width: 30px;
			height: 2px;
			background: currentColor;
			position: absolute;
			top: 100%;
			left: 50%;
			transform: translateX(-50%);
		}
	`}
`;

// const SlidedownAreaInnerContainer = styled.div`
// 	padding: 20px 0;
// 	display: flex;
// 	align-items: center;

// 	${below.tablet`
// 		flex-direction: column;
// 		padding: 20px 10px;
// 	`}
// `;
const FieldContainer = styled.div`
	max-width: 300px;
	min-width: 200px;
	flex-basis: calc(50% - 10px);
	margin-right: 20px;

	${below.phone`
		margin-right: 0px;
		margin-bottom: 10px;
	`}
`;


// const AdFantasyCoachStyled = styled(AdFantasyCoach)`
// 	max-width: 600px;

// 	${below.tablet`
// 		margin-top: 10px;
// 	`}
// `;

// const SlideDownPanelContainer = styled.div`
// 	max-height: 0;
// 	overflow: hidden;
// 	box-sizing: border-box;
// 	transition: all 0.2s linear;
// 	/* background: ${colors.form.base}; */
// 	margin-bottom: 2px;
// 	${({ show_bottom_border }) => show_bottom_border
// 		? `border-bottom: 2px solid ${colors.secondary.accentGrey};`
// 		: ''}
// 	${({ is_open }) => is_open ? css`
// 		max-height: 300px;
// 	` : css`
// 		/* Fades out to white - nicer than shrinking/just disappearing */
// 		border-bottom: 2px solid #fff;
// 	`};

// 	> div > *:last-child {
// 		margin-right: 0;
// 	}
// `;

const StyledLabel = styled(Label)`
	margin-bottom: 5px;
	> .select-tooltip {
		margin-bottom: 4px;
	}
`;

type Props = {
	onSelectChange?: Function,
	children?: React.Node,
	selectOptions: Array<{
		key: string,
		value: string
	}>,
	active_stats_type?: string,
	title: string | React.Node,
	players_show_limit?: number,
	onPlayerShowLimitClick?: Function,
	is_compressed?: boolean,
	reverseColors?: boolean,
	onCompressedViewClick?: Function,
	middleArea: React.Node,
	panelArea: React.Node,
	panel_open_on_mount: boolean,
	show_bottom_border: boolean,
	show_fantasy_ad?: boolean,
	manage_keepers?: boolean,
	select_info?: {
		label?: string,
		title?: string,
		description?: string,
	},
	hide_stats_list?: boolean,
	team_to_team?: boolean
}

type State = {
	panel_open: boolean
};
export class DraftTableHeader extends React.PureComponent<Props, State> {
	static defaultProps = {
		middleArea: null,
		panelArea: null,
		isCoachActive: false,
		panel_open_on_mount: false,
		selectOptions: [
			{
				key: "general_stats",
				value: "General Stats"
			}
		],
		show_bottom_border: true
	};
	state = {
		panel_open: true
	};

	componentDidMount() {
		if(this.props.panel_open_on_mount) {
			this.setState({ panel_open: true });
		}
	}

	renderDefaultChildren() {
		const {
			onSelectChange,
			active_stats_type,
			selectOptions,
			panelArea,
			select_info,
			hide_stats_list
		 } = this.props;

		if (hide_stats_list) {
			return null;
		}
		const select_title = _.get(select_info, "title", "Preset Stats List");
		const select_label = _.get(select_info, "label", "Select a preset stats list");
		const select_desc = _.get(
			select_info, 
			"description", 
			"Change the stats displayed in the table"
		);

		return <React.Fragment>
			{panelArea ? (
				<FieldContainer>{panelArea}</FieldContainer>
			) : null }
			<FieldContainer className="default-container">
				<StyledLabel htmlFor="stats_type">
					{ select_label }
					<Tooltip
						title={select_title}
						description={select_desc} 
						className="select-tooltip"
					/>
				</StyledLabel>
				<Select
					name="stats_type"
					is_disabled={false}
					onChange={onSelectChange}
					value={active_stats_type}
				>
					{selectOptions.map(({ key, value }) => (
						<option key={key} value={key}>
							{value}
						</option>
					))}
				</Select>
			</FieldContainer>
		</React.Fragment>;
	}

	render() {
		const {
			title,
			players_show_limit,
			onPlayerShowLimitClick,
			is_compressed,
			onCompressedViewClick,
			middleArea,
			reverseColors,
			manage_keepers,
			team_to_team
		 } = this.props;

		return (
			<React.Fragment>
				<HeaderWrapper reverseColors={reverseColors}>
					<Title show_mob={manage_keepers || team_to_team}>
						{title}
					</Title>
					{middleArea}
					{players_show_limit && onPlayerShowLimitClick && (
						<NumberOfRowsContainer>
							<NumberOfRowsButton
								is_active={players_show_limit === 20}
								onClick={() => onPlayerShowLimitClick(20)}
							>
								20
							</NumberOfRowsButton>
							<NumberOfRowsButton
								is_active={players_show_limit === 50}
								onClick={() => onPlayerShowLimitClick(50)}
							>
								50
							</NumberOfRowsButton>
							<NumberOfRowsButton
								is_active={players_show_limit === 100}
								onClick={() => onPlayerShowLimitClick(100)}
							>
								100
							</NumberOfRowsButton>
						</NumberOfRowsContainer>
					)}

					<ButtonsContainer>
						{onCompressedViewClick && (
							<React.Fragment>
								<DraftHeaderButton
									is_active={is_compressed===true}
									onClick={() => onCompressedViewClick(true)}
									rel="compressed-view"
								>
									<CellsCondensed />
								</DraftHeaderButton>
								<DraftHeaderButton
									is_active={is_compressed === false}
									onClick={() => onCompressedViewClick(false)}
									rel="uncompressed-view"
								>
									<CellsLarge />
								</DraftHeaderButton>
							</React.Fragment>
						)}
					</ButtonsContainer>
				</HeaderWrapper>
			</React.Fragment>
		);
	}
}

export default DraftTableHeader;