// @flow
import * as React from "react";
import styled from "styled-components";
import { below } from "../../../../assets/css/media";

import {
	TableFlexCell,
	LeagueAvatar,
	LeagueName,
	ButtonPrimary,
} from "../../../../components";
import type { TPLeague } from "../../../../modules/types";

import OverflowHidden from "../../../../components/OverflowHidden";
import { isMobile } from "../../../../utils";


const LeagueAvatarStyled = styled(LeagueAvatar)`
	display: flex;
	justify-content: center;
	flex-direction: column;
`;

const DesktopTableFlexCell = styled(TableFlexCell)`
	color: #1D4073;
	margin: 0;
	text-align: center;
	width: 60px;
	${below.desktop`
		display: none;
	`}
`;

const LeagueNameFlex = styled(LeagueName)`
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 0 0 0 10px;
	${below.desktop`
		margin: 0;
	`}
`;

export const LeagueNameWrapper = styled(OverflowHidden)`
	display: flex;
	width: 300px;
	img {
		margin: 0px 30px 0 10px;
	}
	${below.tablet`
		display: flex;
		align-items: center;
		justify-content: center;

		.league-name {
			text-align: left;
		}

		p {
			margin-left: 10px;
		}

		${LeagueAvatarStyled} {
			width: 40px;
			height: 40px;
			margin: 0 0 0 10px;
			img {
				height: 100%;
				margin: 0;
				width: 100%;
			}
		}
	`}

	${below.small_phone`
		.league-name p {
			width: 100px;
		}
	`}
`;

const FlexDiv = styled.div`
	display: flex;
	justify-content: space-between;
`;

const ButtonPrimaryStyled = styled(ButtonPrimary)`
	min-width: 0;
	width: 140px;
	height: 40px;
	${below.phone`
		width: 100px;
		height: 30px;
		margin-right: 12px;
		padding: 0px;
	`}
`;

type Props = {
	league: TPLeague,
	handleLeagueJoinPopup: Function,
	handleLeagueSettingsPopup: Function,
	join: Function
};

// const JoinLeagueGridRow = styled(TableGridRow)`
// 	min-height: 80px;
// 	background: #fff;
// 	border: 1px solid #CAD2D8;
// 	${below.desktop`
// 		grid-template-columns: auto 80px 80px;
// 	`}
// `;
const LeagueRowWrap = styled(FlexDiv)`
	border: 1px solid #CAD2D8;
	height: 80px;
	margin: 0 0 10px 0;
	${below.desktop`
		height: 50px;
	`}
`;
const FlexButtonsDiv = styled(FlexDiv)`
	margin: 0 20px 0 0;
	width: 250px;
	justify-content: flex-end;
	${below.desktop`
		margin: 0;
		width: 110px;
	`}
`;

export const JoinLeagueRow = ({
	league, handleLeagueJoinPopup, handleLeagueSettingsPopup, join
}: Props) => {
	const isMobileActive = isMobile();
	const widthText = isMobileActive ? "150px" : "200px";
	return (
		<LeagueRowWrap 
			key={league.id} 
			columns="400px 180px auto" 
			className="playwright-mask-hidden"
			height="60px">
			<LeagueNameWrapper role="cell">
				<LeagueAvatarStyled is_classic league={league} size="60px" />
				<LeagueNameFlex
					className="league-name"
					name={league.name}
					width={widthText}
					lastname={league.lastname}
					firstname={league.firstname}
				/>
			</LeagueNameWrapper>
			<FlexDiv>
				<DesktopTableFlexCell role="cell">
				Teams <br /> <br />
					{league.num_teams}
				</DesktopTableFlexCell>
				<DesktopTableFlexCell role="cell">
				Size <br /> <br/>
					{league.size}
				</DesktopTableFlexCell>
				<DesktopTableFlexCell role="cell">
				Start <br /> <br/>
				Round {league.start_round}
				</DesktopTableFlexCell>
			</FlexDiv>
			<FlexButtonsDiv>
				<TableFlexCell role="cell">
					<ButtonPrimaryStyled
						disabled={league.type === "head_to_head" ? 
							league.num_teams === league.size : false}
						onClick={handleLeagueJoinPopup.bind(this, { id: league.id })}>
						Join League
					</ButtonPrimaryStyled>
				</TableFlexCell>
			</FlexButtonsDiv>
			
		</LeagueRowWrap>
	);
};

export default JoinLeagueRow;