import styled from "styled-components";
import { ModalIcon, TextModal } from "../../components";
import colors from "../../assets/css/colors";

const ErrorModal = styled(TextModal)`
	${ModalIcon} {
		svg {
			fill: ${colors.buttons.cancel};
		}
	}
`;

export default ErrorModal;