/* eslint max-len: 0 */
// @flow
import React from "react";
import SvgIconContainer from "../SvgIconContainer";

export default (
	{
		unit = "em",
		...rest
	}: Object = {}) => (
	<SvgIconContainer viewBox="0 0 24 24" {...rest} unit={unit}>
		<title>Emergency Forward</title>
		<polygon id="EF" points="8 6 15.8276923 6 15.8276923 8.65846154 11.1753846 8.65846154 11.1753846 10.9476923 15.1630769 10.9476923 15.1630769 13.6061538 11.1753846 13.6061538 11.1753846 18 8 18"></polygon>
	</SvgIconContainer>
);