// @flow
import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
// import { createLogger } from "redux-logger";

import reducers from "./modules/reducers";
import rootSaga from "./modules/sagas";

const middleware = [];

// Saga Middleware
const sagaMiddleware = createSagaMiddleware();
middleware.push(sagaMiddleware);

// if(process.env.NODE_ENV !== "production") {
// 	// Logging Middleware
// 	const logger = createLogger({
// 		level: "info",
// 		collapsed: true
// 	});
//
// 	middleware.push(logger);
// }

// Redux devtool
const composeEnhancers =
  typeof window === "object" &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
  process.env.NODE_ENV !== "production"
  	? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
  		// Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
  	})
  	: compose;

const enhancer = composeEnhancers(
	applyMiddleware(...middleware)
	// other store enhancers if any
);

export default createStore(reducers, enhancer);

sagaMiddleware.run(rootSaga);