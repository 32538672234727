// @flow
import * as React from "react";
import {connect} from "react-redux";
import _, { isEmpty } from "lodash";
import * as Sentry from "@sentry/browser";

import {Redirect, withRouter} from "react-router-dom";
import {formValueSelector} from "redux-form";
import moment from "moment";
import getLeagueStartRounds from "../../../modules/selectors/getLeagueStartRounds";
import Preloader from "../../Preloader";
import KeeperWarningModal from "../../KeeperWarningModal";
import * as actions from "../../../modules/actions";
import * as selectors from "../../../modules/selectors";
import {ConfirmationModal, TextModal} from "../../../components";
import type {TPLeague, TRound} from "../../../modules/types";
import {
	DEFAULT_FINALS_FORMAT, FINALS_LENGTHS, getIsRegenerated, isAllTrue, isAnyTrue
} from "../../../helpers";
import {Exist} from "../../Exist";

type Props = {
	createDraftLeague: typeof actions.leagueDraft.createDraftLeague,
	createDraftLeagueUF: typeof actions.leagueDraft.createDraftLeagueUF,
	createClassicLeague: typeof actions.createClassicLeague,
	updateDraftLeague: typeof actions.leagueDraft.updateDraftLeague,
	updateClassicLeague: typeof actions.updateClassicLeague,
	removeDraftLeague: typeof actions.leagueDraft.removeDraftLeague,
	removeClassicLeague: typeof actions.removeClassicLeague,
	clearUFDraftLeagueCreateErrors: typeof actions.leagueDraft.clearUFDraftLeagueCreateErrors,
	clearDraftLeagueCreateErrors: typeof actions.leagueDraft.clearDraftLeagueCreateErrors,
	clearDraftLeagueUpdateErrors: typeof actions.leagueDraft.clearDraftLeagueUpdateErrors,
	clearRemoveDraftLeagueErrors: typeof actions.leagueDraft.clearRemoveDraftLeagueErrors,
	clearClassicLeagueErrors: typeof actions.clearClassicLeagueErrors,
	league: TPLeague,
	getLeagueStartRounds: Function,
	rounds: Array<TRound>,
	selected_round_id: number,
	fetchRounds: typeof actions.fetchRounds,
	children: Function,
	toggleForm: Function,
	showDraftLeague: typeof actions.leagueDraft.showDraftLeague,
	showClassicLeague: typeof actions.showClassicLeague,
	from: string,
	match: {
		params: {
			regenerate_league_id: string | number,
		}
	},
	league_update: {
		error: string,
		is_pending: boolean,
		status: "idle" | "loading" | "success" | "error"
	},
	league_create: {
		error: string,
	},
	league_create_uf: {
		error: string,
	},
	leagues_classic_create: {
		error: string,
	},
	league_error?: string,
	league_id: number,
	leaguesClassic: {
		data: Object,
	},
	leagues_remove: Function,
	leagues_classic_remove: Function,
	setActiveDraftTab: typeof actions.setDraftTab,
	isFromApp?: boolean;
	clearUFDraftLeagueCreate: typeof actions.leagueDraft.clearUFDraftLeagueCreate,
	fetchClassicLadder: typeof actions.ladderClassicLeague,
};

type State = {
	file: Object,
	previewImg: string,
	is_show_advanced_settings: boolean,
	show_remove_modal: boolean,
	from: string,
	league_updated: boolean,
	show_keeper_warning_modal: boolean
};


const getSquadSize = values => {
	const dVal = values["DEF_value"] || 1;
	const mVal = values["MID_value"] || 1;
	const rVal = values["RUC_value"] || 1;
	const fVal = values["FWD_value"] || 1;
	const iVal = values["INT_value"] || 1;
	return `${dVal}-${mVal}-${rVal}-${fVal}/${iVal}`;
};

const scoreShowKeys = values => Object.keys(values).filter(item => item.includes("_show"));
const scoreKeys = values => Object.keys(values).filter(item => item.includes("_value"));
const scoreValues = values => scoreKeys(values).reduce((obj, item) => {
	const itemNoValue = item.replace("_value", "");
	return {
		...obj,
		[itemNoValue]: values[`${itemNoValue}_show`] === false ? 0 : Number(values[item]),
	};
}, {});

const subscribeKeys = 
	values => Object.keys(values).filter(item => ["plan","token"].includes(item));
const subscribeValues = values => subscribeKeys(values).reduce((obj, item) => ({
	...obj,
	[item]: values[item],
}), {});

const valuesToSend = values => Object.keys(values)
	.filter(item => ![
		...scoreShowKeys(values), 
		...scoreKeys(values),
		...subscribeKeys(values)
	].includes(item))
	.reduce(
		(obj, item) => ({
			...obj,
			[item]: values[item],
		}), { start_round: 0 } // setting start round key/value to fix flow error on line 225
	);

const getEmergencyLimit = (interchange:string, emergency_limit:number) => {
	const interchangeNumber = Number(interchange);
	return emergency_limit > interchangeNumber ? interchangeNumber:emergency_limit;
};

const getFinalsLength = (isFinals:number, finalsLength:number) => {
	if(isFinals) {
		return finalsLength > 0 ? finalsLength:FINALS_LENGTHS[DEFAULT_FINALS_FORMAT];
	}
	return 0;
};

class LeagueManager extends React.Component<Props, State> {
	constructor(props, state) {
		super(props, state);

		_.bindAll(this, [
			"handleCreateSubmit",
			"handleCreateUFSubmit",
			"handleClassicCreateSubmit",
			"handleUpdateSubmit",
			"handleClassicUpdateSubmit",
			"onChangeFile",
			"toggleForm",
			"removeLeague",
			"handleErrorModalClose",
			"confirmRemoveLeague",
			"closeErrorModal",
			"toggleKeeperModal",
		]);
	}

	state = {
		is_show_advanced_settings: false,
		previewImg: "",
		file: {},
		is_bye: 0,
		from: "create",
		league_updated: false,
		show_remove_modal: false,
		show_keeper_warning_modal: false,
	};

	componentDidMount() {
		const { fetchRounds, from, isFromApp, league } = this.props;

		if (from === "update") {
			const { league_id, showDraftLeague } = this.props;

			showDraftLeague({ id: league_id });
		}
		else if (from === "update_classic") {
			const { league_id, showClassicLeague, fetchClassicLadder } = this.props;
			if(isEmpty(league)){
				showClassicLeague({ id: league_id });
			}
			
			if(league.status !== "scheduled"){
				fetchClassicLadder({ league_id: league_id });
			}
			this.setState({
				from: "update_classic"
			});


		}
		else if(isFromApp){
			this.toggleForm(true);
		}

		fetchRounds();
	}

	componentDidUpdate() {
		const { leagues_remove } = this.props;
		const { show_remove_modal } = this.state;
		if(leagues_remove.error !== null && show_remove_modal) {
			this.setState({ show_remove_modal: false });
		}
	}

	componentWillUnmount() {
		if (this.props.from === "create_uf") {
			const { clearUFDraftLeagueCreate } = this.props;
			clearUFDraftLeagueCreate();
		}
	}

	handleCreateSubmit(values) {
		// draft_start is moment object (react-datetime return moment object)
		if (typeof values.draft_start !== "string") {
			values.draft_start = 
				moment(values.draft_start).tz("Australia/Melbourne")
					.format("YYYY-MM-DD[T]HH:mm:ss");
		}
		if (typeof values.keeper_deadline !== "string") {
			values.keeper_deadline = 
				moment(values.keeper_deadline).tz("Australia/Melbourne")
					.format("YYYY-MM-DD[T]HH:mm:ss");
		}
		

		const { file } = this.state;
		const { createDraftLeague } = this.props;
		const data_to_send = _.get(values, "keeper_num", 0) > 0 ? 
			values: _.omit({ ...values }, "keeper_num");
		const data = {
			...valuesToSend(data_to_send),
			avatar: file,
			scoreValues: scoreValues(values),
			squad_size: getSquadSize(values),
			emergency_limit: getEmergencyLimit(values["INT_value"],values["emergency_limit"]),
			subscribeValues: subscribeValues(values)
		};
		if(!_.isEmpty(subscribeValues(values)) && subscribeValues(values).plan === ""){
			this.props.setActiveDraftTab(0);
		}
		createDraftLeague(data);
	}

	handleCreateUFSubmit(values) {
		// draft_start is moment object (react-datetime return moment object)
		if (typeof values.draft_start !== "string") {
			values.draft_start = 
				values.draft_start.tz("Australia/Melbourne").format("YYYY-MM-DD[T]HH:mm:ss");
		}
		const { regenerate_league_id } = this.props.match.params;		
		const uf_regenerated_from = regenerate_league_id ? regenerate_league_id:0;
		// const { file } = this.state;
		const { createDraftLeagueUF } = this.props;
		const data_to_send = _.get(values, "keeper_num", 0) > 0 ? 
			values: _.omit({ ...values }, "keeper_num");
		const draftType = values.draft_type === 1 ? "live":values.draft_type;
		const data = {
			...valuesToSend(data_to_send),
			// avatar: file,
			scoreValues: scoreValues(values),
			squad_size: getSquadSize(values),
			subscribeValues: subscribeValues(values),
			emergency_limit: getEmergencyLimit(values["INT_value"],values["emergency_limit"]),
			draft_type:draftType,
			uf_regenerated_from
		};
		if(!_.isEmpty(subscribeValues(values)) && subscribeValues(values).plan === ""){
			this.props.setActiveDraftTab(0);
		}
		createDraftLeagueUF(data);
	}

	handleClassicCreateSubmit(values) {
		const { file } = this.state,
			{ createClassicLeague } = this.props,
			data = { ...values, avatar: file };

		createClassicLeague(data);
	}


	getDataToSend(values){
		return _.get(values, "keeper_num", 0) > 0 ? 
			values: _.omit({ ...values }, "keeper_num");
	}
	
	checkToDeleteStartRound(values){
		if(values && values.start_round){
			delete values.start_round;
		}
	}

	// eslint-disable-next-line complexity
	handleUpdateSubmit(values) {
		const { league, isFromApp } = this.props;
		if (typeof values.draft_start !== "string") {
			values.draft_start = 
				values.draft_start.tz("Australia/Melbourne").format("YYYY-MM-DD[T]HH:mm:ss");
		 }
		 if (typeof values.keeper_deadline !== "string") {
			values.keeper_deadline = 
				values.keeper_deadline.tz("Australia/Melbourne").format("YYYY-MM-DD[T]HH:mm:ss");
		 }

		const { file } = this.state;
		const { updateDraftLeague } = this.props;
		
		let values_to_send = valuesToSend(values);
		if(league.status === "playing") {
			this.checkToDeleteStartRound();
		}
		const skipCustomScoring = _.get(league, "draft_status") !== "scheduled";
		const data_to_send = this.getDataToSend(values_to_send);
		const data = {
			...data_to_send,
			avatar: file,
			scoreValues: scoreValues(values),
			squad_size: getSquadSize(values),
			subscribeValues: subscribeValues(values),
			skipCustomScoring,
			emergency_limit: getEmergencyLimit(values["INT_value"],values["emergency_limit"]),
			finals_length: getFinalsLength(values["finals"], values["finals_length"])
		};
		if(isAllTrue([!_.isEmpty(subscribeValues(values)), 
			_.get(subscribeValues(values), "plan") === ""])){
			this.props.setActiveDraftTab(0);
		}

		const updated_league = updateDraftLeague(data);

		const leagueWasntKeeper = league.keeper === 0;
		const leagueIsNowKeeper = values.keeper === 1;
		const isLeagueRegen = getIsRegenerated(league.regenerated_from,league.uf_regenerated_from);
		const isLeagueSizeDiff = league.num_teams !== league.old_league_size;

		if (!updated_league.error) {
			if(_.every(
				[
					leagueWasntKeeper, 
					leagueIsNowKeeper, 
					isLeagueRegen, 
					isLeagueSizeDiff
				]
			)){
				this.toggleKeeperModal();
				return;
			}
			this.setState({ league_updated: true });
		}
		if(isAllTrue([!updated_league.error, Boolean(isFromApp)])){
			setTimeout(() => {
				window.close();

			}, 3000);
		}

		
	}

	handleClassicUpdateSubmit(values) {
		const {
			league: { status },
			isFromApp
		} = this.props;
		let redundant_keys = [];

		if (status !== "scheduled") {
			redundant_keys = _.concat(redundant_keys, [
				"start_round",
				"type",
				"privacy",
				"league_size",
			]);
		}

		let data_to_send = _.omit({ ...values }, redundant_keys);

		const { file } = this.state,
			{ updateClassicLeague } = this.props,
			data = {
				...data_to_send,
				avatar: file,
			};

		const updated_league = updateClassicLeague(data);

		if (!updated_league.error) {
			this.setState({ league_updated: true });
		}
		if(!updated_league.error && isFromApp){
			window.close();
		}
	}

	removeLeague(e) {
		e.preventDefault();
		this.setState({ show_remove_modal: true });
	}

	get error_modal() {
		const { 
			league_update,
			league_create,
			league_create_uf,
			leagues_classic_create,
			from
		} = this.props;
		let error_text = "";
		if (from === "create") {
			error_text = league_create.error;
		}
		else if (from === "create_uf") {
			error_text = league_create_uf.error;
		}
		else if (from === "create_classic") {
			error_text = leagues_classic_create.error;
		}
		else if (from === "update") {
			error_text = league_update.error || "unable to remove league";
		}
		else {
			error_text = league_update.error;
		}
		return (
			<TextModal onClick={this.handleErrorModalClose} header_text="Error" text={error_text} />
		);
	}

	get reove_modal_text() {
		const {
			from,
			league: { name },
		} = this.props;

		return from === "update_classic"
			? `Are you sure you want to
			remove your Classic league ${name}?
			This action cannot be reversed once confirmed!`
			: `Are you sure you want to
			remove your Draft league ${name}?
			This action cannot be reversed once confirmed!`;
	}

	get remove_modal() {
		return (
			<ConfirmationModal
				header_text="League Settings"
				body_text={this.reove_modal_text}
				confirmClick={this.confirmRemoveLeague}
				cancelClick={this.closeErrorModal}
				closeClick={this.closeErrorModal}
			/>
		);
	}

	get updated_modal() {
		const {clearDraftLeagueUpdateErrors} = this.props;
		return (
			<TextModal
				onClick={() => {
					this.setState({ league_updated: false });
					clearDraftLeagueUpdateErrors();
				}}
				header_text="League Settings"
				text="Your League settings have now been updated!"
			/>
		);
	}

	closeErrorModal() {
		this.setState({ show_remove_modal: false });
	}

	toggleKeeperModal() {
		this.setState({
			show_keeper_warning_modal: !this.state.show_keeper_warning_modal
		});
	}

	confirmRemoveLeague() {
		const { removeDraftLeague, removeClassicLeague, league, from } = this.props;
		if(from === "update_classic") {
			removeClassicLeague({ id: league.id });
		}
		else {
			removeDraftLeague({ id: league.id });
		};
	}
	
	handleErrorModalClose() {
		const { from } = this.props;
		const {
			clearDraftLeagueCreateErrors,
			clearUFDraftLeagueCreateErrors,
			clearDraftLeagueUpdateErrors,
			clearClassicLeagueErrors,
			clearRemoveDraftLeagueErrors,
		} = this.props;

		this.setState({ league_updated: false });
		if (from === "create") {
			clearDraftLeagueCreateErrors();
		}
		else if(from === "create_uf") {
			clearUFDraftLeagueCreateErrors();
		}
		else if (from === "create_classic" || from === "update_classic") {
			clearClassicLeagueErrors();
		}
		else {
			clearDraftLeagueUpdateErrors();
			clearRemoveDraftLeagueErrors();
		}
	}

	getRoundOptions() {
		const { rounds } = this.props;
		return rounds.filter(round => round.id > 0).map(round => ({
			value: round.id,
			text: `Round ${round.id}`,
		}));
	}

	onChangeFile(e) {
		try {
			const reader = new FileReader(),
				file = e.target.files[0];

			reader.onload = e => {
				this.setState({ previewImg: _.get(e, "target.result", {}) });
			};
			if(file){
				reader.readAsDataURL(file);

				this.setState({ file });
			}
			
		}
		catch (error) {
			// Gather info to check what's going wrong here
			if (Sentry) {
				Sentry.withScope(scope => {
					e.persist();
					scope.setExtra("event", e);
					scope.setExtra("event.target.result", _.get(e, ["target", "result"]));
					scope.setExtra("event.target.files", _.get(e, ["target", "files"]));
					Sentry.captureException(error);
				});
			}
		}
	}

	toggleForm(is_show_advanced_settings) {
		this.setState({ is_show_advanced_settings });
	}

	get handler_name() {
		const { from } = this.props;

		return {
			create: this.handleCreateSubmit,
			create_uf: this.handleCreateUFSubmit,
			create_classic: this.handleClassicCreateSubmit,
			update: this.handleUpdateSubmit,
			update_classic: this.handleClassicUpdateSubmit,
		}[from];
	}

	get check_is_update() {
		const { league_updated } = this.state;
		const { league_update } = this.props;

		return league_updated && !league_update.is_pending && !league_update.error
			? this.updated_modal
			: null;
	}

	get keeper_warning_modal() {
		return <KeeperWarningModal onClose={this.toggleKeeperModal}/>;
	}
 	

	render() {
		const rounds_list = this.getRoundOptions();
		const { 
			league_update, 
			league_create,
			league_create_uf,
			leagues_classic_remove,
			leagues_remove, from } = this.props;
		const { show_remove_modal, show_keeper_warning_modal } = this.state;
		const isError = isAnyTrue([
			Boolean(league_update.error), 
			Boolean(league_create.error), 
			Boolean(league_create_uf.error), 
			leagues_remove.error
		]);
		if (isAnyTrue([
			leagues_remove.removedLeague > 0, 
			leagues_classic_remove.removedLeague > 0
		])) {
			return <Redirect to={`/${from === "update_classic" ? "classic" : "draft"}/leagues`} />;
		}

		if (_.isEmpty(rounds_list)) {
			return <Preloader />;
		}

		return (
			<React.Fragment>
				<Exist when={isError}>
					{this.error_modal}
				</Exist>
				<Exist when={show_remove_modal}>
					{this.remove_modal}
				</Exist>
				{this.check_is_update}
				<Exist when={show_keeper_warning_modal}>
					{this.keeper_warning_modal}
				</Exist>
				
				{
					// $FlowFixMe
					this.props.children({
						rounds_list,
						handleSubmit: this.handler_name,
						onChangeFile: this.onChangeFile,
						toggleForm: this.toggleForm,
						removeLeague: this.removeLeague,
						error_modal: this.error_modal,
						league: this.props.league,
						...this.props,
						...this.state,
					})
				}
			</React.Fragment>
		);
	}
}

const selector = formValueSelector("league_manager");

const mapStateToProps = (state, props) => {
	const league =
			props.from.includes("create")
				? {}
				: props.from === "update"
					? selectors.leagueDraft.getLeague(state, props)
					: selectors.leaguesClassic.show.getLeague(state, props);
	const ownProps = {
		is_bye: selector(state, "bye_rounds") !== 1,
		selected_round_id: selector(state, "start_round"),
		is_draft: props.is_draft,
		league
	};

	const rounds_by_id = state.rounds.by_id;
	const rounds_with_matches = selectors.rounds.getRoundsWithMatches(state);
	let selected_round_id = ownProps.selected_round_id
		? ownProps.selected_round_id
		: getLeagueStartRounds(state, ownProps).selected_round_id;
	if(selected_round_id === 0){
		selected_round_id = 1;
	}

	const selected_round = rounds_by_id[selected_round_id];

	return {
		league,
		league_update: props.from === "update" ? state.leagues.update : state.leaguesClassic.update,
		league_create: state.leagues.create,
		league_create_uf: state.leagues.create_uf,
		leagues_classic_create: state.leaguesClassic.create,
		leagues_classic_remove: state.leaguesClassic.remove,
		leagues_remove: state.leagues.remove,
		rounds: getLeagueStartRounds(state, ownProps).rounds,
		rounds_by_id,
		selected_round,
		selected_round_id,
		user: state.user.data,
		rounds_with_matches
	};
};

const mapDispatchToProps = {
	showDraftLeague: actions.leagueDraft.showDraftLeague,
	showClassicLeague: actions.showClassicLeague,
	createDraftLeague: actions.leagueDraft.createDraftLeague,
	createDraftLeagueUF: actions.leagueDraft.createDraftLeagueUF,
	createClassicLeague: actions.createClassicLeague,
	updateDraftLeague: actions.leagueDraft.updateDraftLeague,
	updateClassicLeague: actions.updateClassicLeague,
	removeDraftLeague: actions.leagueDraft.removeDraftLeague,
	removeClassicLeague: actions.removeClassicLeague,
	clearUFDraftLeagueCreateErrors: actions.leagueDraft.clearUFDraftLeagueCreateErrors,
	clearDraftLeagueCreateErrors: actions.leagueDraft.clearDraftLeagueCreateErrors,
	clearDraftLeagueUpdateErrors: actions.leagueDraft.clearDraftLeagueUpdateErrors,
	clearRemoveDraftLeagueErrors: actions.leagueDraft.clearRemoveDraftLeagueErrors,
	clearClassicLeagueErrors: actions.clearClassicLeagueErrors,
	fetchRounds: actions.fetchRounds,
	setActiveDraftTab: actions.setDraftTab,
	clearUFDraftLeagueCreate: actions.leagueDraft.clearUFDraftLeagueCreate,
	fetchClassicLadder: actions.ladderClassicLeague,
};

export const LeagueManagerComponent = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(LeagueManager)
);

export default LeagueManagerComponent;