// @flow
import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import * as selectors from "../../../modules/selectors";
import dataLayer from "../../../utils/GTM";
import type { TRound } from "../../../modules/types";

type Props = {
	children: React.Node,
	has_user: boolean,
	is_fb_login: boolean,
	actual_round: TRound,
	ignored_paths: Array<string>,
	location: {
		pathname: string
	}
}

class GtmPageViewDataLayerContainer extends React.Component<Props> {
	static defaultProps = {
		ignored_paths: [
			"/game-selection-favourite-redirect"
		]
	};

	componentDidMount(): void {
		this.onRouteChanged();
	}

	componentDidUpdate(prevProps): void {
		if (this.props.location !== prevProps.location) {
			this.onRouteChanged();
		}
	}

	get site_section() {
		const { location: { pathname } } = this.props;

		if(pathname === "/") {
			return "Home";
		}

		return pathname
			.split("/")
			.filter(str => str && _.isNaN(parseInt(str, 10)))
			.map(str => _.capitalize(_.replace(str, /-/gi, " ")))
			.join(" | ");
	}

	get game_type() {
		const { location: { pathname } } = this.props;

		if(pathname.includes("classic")) {
			return "classic";
		}

		if(pathname.includes("draft")) {
			return "draft";
		}

		return null;
	}

	get game_status() {
		const { actual_round } = this.props;

		if(_.isEmpty(actual_round)) {
			return null;
		}

		const { status, matches } = actual_round;

		if(_.eq(status, "scheduled")) {
			return "pre";
		}

		if(_.find(matches, { status: "playing" })) {
			return "live";
		}

		return "post";
	}

	get login_status() {
		return this.props.has_user ? "logged in" : "logged out";
	}

	get login_type() {
		const { has_user, is_fb_login } = this.props;

		if(has_user) {
			return is_fb_login ? "facebook" : "email";
		}

		return null;
	}

	isIgnoredPath(path) {
		return this.props.ignored_paths.includes(path);
	}

	onRouteChanged(): void {
		const { location: { pathname } } = this.props;

		if(this.isIgnoredPath(pathname)) {
			return;
		}

		dataLayer({
			pageName: pathname,
			loginType: this.login_type,
			loginStatus: this.login_status,
			siteSection: this.site_section,
			contentType: this.game_type,
			matchStatus: this.game_status,
		});
	}

	render() {
		return this.props.children;
	}
}

const mapStateToProps = state => ({
	has_user: selectors.hasUser(state),
	is_fb_login: selectors.isLoggedInViaFB(state),
	actual_round: selectors.rounds.getActualRound(state),
});

export const GtmPageViewDataLayer = withRouter(
	connect(mapStateToProps)(GtmPageViewDataLayerContainer)
);

export default GtmPageViewDataLayer;