// @flow
import moment from "moment/moment";
import { PASSWORD_REGEX } from "../../";
const errors = {};

const firstnameCheck = values => {
	errors.firstname = !values.firstname ? "First name is required" : null;
};

const lastnameCheck = values => {
	errors.lastname = !values.lastname ? "Last name is required" : null;
};

const emailCheck = values => {
	if(!values.email){
		errors.email ="Email address is required";
	}
	else if(values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
		errors.email =  "Invalid email address";
	}
	else {
		errors.email = null;
	}
};

const confirmEmailCheck = values => {

	if (!values.confirm_email) {
		errors.confirm_email = "Required";
	}
	else if(values.confirm_email !== values.email){
		errors.confirm_email = "Matching email is required";
	}
	else {
		errors.confirm_email = null;
	}
};

const passwordCheck = values => {
	if (!values.password) {
		errors.password = "Required";
	}
	else if(!PASSWORD_REGEX.test(values.password)) {
		errors.password = "Please enter a password for your "
			+ "AFL Fantasy account. This password should include "
			+ "at least eight characters, a mixture of upper and "
			+ "lower case letters, at least one special character "
			+ "(for example $ or !) and at least one number.";
	}
	else {
		errors.password = null;
	}
};

const confirmPasswordCheck = values => {
	if (!values.confirm_password) {
		errors.confirm_password = "Required";
	}
	else if(values.confirm_password !== values.password){
		errors.confirm_password = "Matching password is required";
	}
	else {
		errors.confirm_password = null;
	}
};

const teamNameCheck = values => {
	if (!values.team_name) {
		errors.team_name = "Team name is required";
	}
	else if(values.team_name.length > 30){
		errors.team_name = "Team name is too long";
	}
	else {
		errors.team_name = null;
	}
};

const supportedClubsCheck = values => {
	if (!values.supported_clubs) {
		errors.supported_clubs = "Supported team is required";
	}
	else {
		errors.supported_clubs = null;
	}
};

const stateCheck = values => {
	errors.state = !values.state ? "State is required" : null;
};

const mobileCheck = values => {
	if (!values.mobile) {
		errors.mobile = "Phone number is required";
	}
	else if(!/^(?=.*\d)[\d ]{9,15}$/.test(values.mobile)){
		errors.mobile = "Please enter a valid phone number, ensuring only numbers are used.";
	}
	else {
		errors.mobile = null;
	}
};

const birthdayCheck = values => {
	if(!values.birthday) {
		errors.birthday = "Date of Birth is required";
	}
	else if(!moment(values.birthday, "DD/MM/YYYY", true).isValid()) {
		errors.birthday = "Please enter a valid date of birth using DD/MM/YYYY";
	}
	else {
		errors.birthday = null;
	}

};

const genderCheck = values => {
	errors.gender = !values.gender ? "Gender is required" : null;
};

const privacyPolicyCheck = values => {
	errors.privacy_policy = !values.privacy_policy ?
		"Agreeing to Terms & Conditions is required" : null;
};

export const RegistrationValidate = (values: Object) => {

	// Step 1
	firstnameCheck(values);
	lastnameCheck(values);
	emailCheck(values);
	confirmEmailCheck(values);
	passwordCheck(values);
	confirmPasswordCheck(values);

	// Step 2
	teamNameCheck(values);
	supportedClubsCheck(values);
	stateCheck(values);
	mobileCheck(values);
	birthdayCheck(values);
	genderCheck(values);
	privacyPolicyCheck(values);

	return errors;
};

export const AccountValidate = (values: Object) => {
	teamNameCheck(values);
	firstnameCheck(values);
	lastnameCheck(values);
	emailCheck(values);
	supportedClubsCheck(values);
	stateCheck(values);
	mobileCheck(values);
	birthdayCheck(values);
	genderCheck(values);
};