// @flow
import _ from 'lodash';
import { createSelector } from "reselect";
import * as positionsUtils from '../../positions';
import leaguesDraftUtilSelectors from '../../leaguesDraft';
import * as leaguesDraftUtilHelpers from "../../../../helpers/league";
import * as playersSelectors from '../../players';

import type { PropsSelectedTeam, TPosition, TPositionsById } from '../../../types';
import { 
	getEmergencyIds, 
	getLineupPlayersCount, 
	getLineupPlayersHelper 
} from '../../../../helpers/complex/team';


const isDataReceivedForGetSelectedTeam = ({ show, teams_by_id, by_id }) => {
	return !show.league_id || _.isEmpty(teams_by_id) || _.isEmpty(by_id);
};

const isUserTeamForGetSelectedTeam = ({ selected_team_id, team, teamsDraft }) => {
	return !selected_team_id && team && !team.lineup && !_.isEmpty(teamsDraft.user_team);
};

export const getSelectedTeam = (
	{ leagues, teamsDraft }: PropsSelectedTeam, selected_team_id?: number
) => {
	const { teams_by_id, show, by_id } = leagues;

	if (isDataReceivedForGetSelectedTeam({ show, teams_by_id, by_id })) {
		return {};
	}

	const league = by_id[show.league_id];
	if (!league) {
		return {};
	}
	const team = teams_by_id[selected_team_id || league.team_id];
	if (isUserTeamForGetSelectedTeam({ selected_team_id, team, teamsDraft })) {
		return teamsDraft.user_team;
	}

	return team || {};
};

export const getTotalTeamPlayersCount = ({ leagues }: PropsSelectedTeam) => {
	const { show, by_id } = leagues;
	if (!show.league_id || _.isEmpty(by_id)) {
		return 0;
	}

	return leaguesDraftUtilHelpers.getSquadSize(by_id[show.league_id]);
};

export const getTeamPlayersCount = createSelector(
	getSelectedTeam,
	getTotalTeamPlayersCount,
	(selected_team: Object, total: number) => {
		const { lineup } = selected_team;
		const initial_score = {
			current: 0,
			total
		};

		if (_.isEmpty(lineup)) {
			return initial_score;
		}

		const current = getLineupPlayersCount({ lineup });

		return {
			current,
			total
		};
	}
);

export const getTeamObject = (state: Object) => (
	_.get(state, 'teamsDraft.user_team', {})
);

export const getLineupPlayers = createSelector(
	playersSelectors.getPlayersArrayWithFixture,
	getSelectedTeam,
	positionsUtils.getPositionsById,
	getLineupPlayersHelper
);

export const getPlayersFromLineup = createSelector(
	playersSelectors.getPlayersArrayWithFixture,
	getTeamObject,
	positionsUtils.getPositionsById,
	getLineupPlayersHelper
);

export const getUserTeamFromTeamsById = createSelector(
	leaguesDraftUtilSelectors.getTeamsById,
	leaguesDraftUtilSelectors.getLeague,
	(teams_by_id: Object, league: Object) => teams_by_id[league.team_id]
);

export const getUserPlayersFromLineup = createSelector(
	playersSelectors.getPlayersArrayWithFixture,
	getUserTeamFromTeamsById,
	positionsUtils.getPositionsById,
	getLineupPlayersHelper
);

export const getEmergency = createSelector(
	leaguesDraftUtilSelectors.getLeague,
	(state, props, team_id) => getSelectedTeam(state, team_id),
	positionsUtils.getPositionsById,
	(league: Object, selected_team: Object, positions_by_id: TPositionsById) => {
		if (_.isEmpty(league)) {
			return [];
		}
		const { emergency: is_emergency = 0 } = league;

		if (!is_emergency || _.isEmpty(selected_team) || _.isEmpty(positions_by_id)) {
			return [];
		}

		const { lineup } = selected_team;

		if (!lineup) {
			return [];
		}

		const { emergency = {} } = lineup;

		return _.map(emergency, value => value) || [];
	}
);

export const getEmergencyArray = createSelector(
	getTeamObject,
	(team: Object) => {
		if (_.isEmpty(team)) {
			return [];
		}
		const { lineup } = team;

		return getEmergencyIds(lineup);
	}
);

export const getEmptyLineup = createSelector(
	leaguesDraftUtilSelectors.getLeague,
	positionsUtils.getPositionsArray,
	(league: Object, positions: Array<TPosition>) => {
		if (_.isEmpty(league)) {
			return {};
		}

		return leaguesDraftUtilHelpers.getSquadSizeArray(league).reduce((acc, size, index) => {
			const key = _.get(positions[index], 'id', 'bench');
			acc[key] = _.range(0, size, 0);

			return acc;
		}, {});
	}
);

export const getTeamsWithFullLineup = createSelector(
	leaguesDraftUtilSelectors.getLeague,
	getEmptyLineup,
	state => _.get(state, 'leagues.teams_by_id', {}),
	(league, empty_lineup, teams_by_id) => {
		if (_.isEmpty(teams_by_id) || _.isEmpty(_.get(league, 'teams'))) {
			return {};
		}

		const teams = league.teams.reduce((acc, team_id) => {
			acc[team_id] = teams_by_id[team_id];
			return acc;
		}, {});

		_.each(teams, team => {
			const lineup = _.merge({}, empty_lineup, _.get(team, 'lineup', {}));
			_.set(team, 'lineup', lineup);
		});

		return teams;
	}
);

export const getTeamsWithFullLineupWithPlayers = createSelector(
	getTeamsWithFullLineup,
	playersSelectors.getExtendedPlayersArray,
	({ leagues }: Object) => _.get(leagues, 'show_order.ordered_ids'),
	(teams_by_id, players, draft_teams_ids) => {
		if (_.isEmpty(teams_by_id) || _.isEmpty(players)) {
			return {};
		}

		const teams = _.merge({}, _.pick(teams_by_id, draft_teams_ids));
		const players_by_id = _.keyBy(players, 'id');
		const getPlayerById = _.partial(_.get, players_by_id, _, 0);

		_.each(teams, team => {
			const lineup = _.omit(team.lineup, ['captain', 'vice_captain', 'emergency']);

			_.each(lineup, (line, key) => {
				team.lineup[key] = line.map(getPlayerById);
			});
		});

		return teams;
	}
);