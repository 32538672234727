import { isEmpty } from "lodash";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import colors from "../../assets/css/colors";
import { below } from "../../assets/css/media";
import { ButtonPrimary, CancelButton, ErrorField, Exist, InputItem } from "../../components";
import { 
	clearUserError, 
	linkAccountLogin, 
	linkNewAccount, 
	wipeFetchedAccountData 
} from "../../modules/actions";
import { 
	getIsLinkedAccount,
	getNewAccountInfo, 
	getUser, 
	getUserError, 
	isLeaguesHistoryReady, 
	isNewAccountLinked 
} from "../../modules/selectors";
import { AccountHistoryWidget } from "../login/aflID/AccountHistoryWidget";



const LinkedAccountWrap = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
    max-width: 440px;
    ${InputItem}{
        margin-bottom: 10px;
    }
`;
const LinkedAccountHeader = styled.h2`
	font-size: 18px;
	font-weight: 600;
	margin: 20px 0 10px 0;
	color: ${colors.primary.primary};
`;

const LinkedAccountP = styled.p`
	font-weight: 400;
	color: ${colors.primary.primary};
	font-family: SourceSansPro;
	font-size: 14px;
    margin-bottom: 10px;
`;

const LinkAccountButton = styled(ButtonPrimary)`
    width: 440px;
    margin: 10px 0;
    ${below.phone`
        width: 100%;
    `}
`;

const CancelLinkButton = styled(CancelButton)`
    width: 440px;
    background: white;
    color: red;
    border: red;
    ${below.phone`
        width: 100%;
    `}
`;


export const LinkNewAccount = () => {
	const dispatch = useDispatch();
	const currentUser = useSelector(getUser);
	const [email, setEmail] = useState("");
	const [password, setPw] = useState("");
	const error = useSelector(getUserError);
	const {user} = useSelector(getNewAccountInfo);
	const isLinkedSuccessfully = useSelector(isNewAccountLinked);
	const isLeaguesHistory = useSelector(isLeaguesHistoryReady);
	const isLinkedAccount = useSelector(getIsLinkedAccount);


	const isAccountExist = !isEmpty(user) && currentUser.id !== user.id && isLeaguesHistory;


	const handleChangeEmail = e => {
		setEmail(e.target.value);
	};
	const handleChangePw = e => {
		setPw(e.target.value);
	};

	const submit = e => {
		e.preventDefault();
		dispatch(clearUserError());
		dispatch(linkAccountLogin({email, password}));
	};

	const linkAccount = e => {
		e.preventDefault();
		dispatch(linkNewAccount({email, password}));
	};

	const cancel = e => {
		e.preventDefault();
		setEmail("");
		setPw("");
		dispatch(wipeFetchedAccountData());
		
	};

	
	if(isLinkedSuccessfully){
		return <LinkedAccountWrap>
			<LinkedAccountHeader>
                Successfully Linked Account
			</LinkedAccountHeader>
			<LinkedAccountP>
            You have successfully updated your account to be linked to {user.team_name}
			</LinkedAccountP>
		</LinkedAccountWrap>;
	}
	if(isLinkedAccount){
		return null;
	}
	
	return  <LinkedAccountWrap>
		<LinkedAccountHeader>
        Link a Different AFL Fantasy Account
		</LinkedAccountHeader>
		<LinkedAccountP>
        If you linked the wrong AFL Fantasy account, 
        you can use this page to change which AFL Fantasy account 
        is linked to your AFL iD. Simply enter the details of the AFL 
        Fantasy account you wish to link instead, and once we show 
        you some information about that account, confirm that it’s 
        the one you want to link
		</LinkedAccountP>
		<form onSubmit={isAccountExist ? linkAccount : submit}>
			<InputItem
				label="Email Address"
				type='text'
				name={"email"}
				value={email}
				required={true}
				tooltip={false}
				placeholder={"Email address of the account you're linking"}
				onChange={handleChangeEmail}
			/>
			<InputItem
				label="Password"
				type='password'
				name={"password"}
				required={true}
				value={password}
				tooltip={false}
				placeholder={"Password of the account you're linking"}
				onChange={handleChangePw}
			/>
			<Exist when={!isAccountExist}>
				<LinkAccountButton type="submit">
                Next
				</LinkAccountButton>
			</Exist>
        
           
			<Exist when={isAccountExist}>
				<AccountHistoryWidget />
			</Exist>

			<Exist when={isAccountExist}>

				<LinkAccountButton type="submit">
                Link this account
				</LinkAccountButton>
				<CancelLinkButton onClick={cancel}>
                Cancel
				</CancelLinkButton>
			</Exist>
        
		</form>
	
		

		<Exist when={error}>
			<ErrorField>
				{error}
			</ErrorField>
		</Exist>

		
    
	</LinkedAccountWrap>;
};