/* eslint max-len: 0 */
// @flow
import React from "react";
import SvgIconContainer from "../SvgIconContainer";

export default (
	{
		unit = "em",
		...rest
	}: Object = {}) => (
	<SvgIconContainer viewBox="0 0 24 24" {...rest} unit={unit}>
		<title>Not Selected</title>
		<path d="M12,3 C16.9879518,3 21,7.01204819 21,12 C21,16.9879518 16.9879518,21 12,21
        C7.01204819,21 3,16.9879518 3,12 C3,7.01204819 7.01204819,3 12,3 Z M7.76470588,6.50735294
        L17.4926471,16.2352941 C18.3478507,15.1128394 18.8823529,13.6696833 18.8823529,12.0661765
        C18.8823529,8.27121041 15.7822398,5.11764706 11.9338235,5.11764706 C10.383767,5.11764706
        8.94061086,5.65214932 7.76470588,6.50735294 Z M16.2352941,17.4926471 L6.50735294,7.76470588
        C5.65214932,8.94061086 5.11764706,10.383767 5.11764706,11.9338235 C5.11764706,15.7822398
        8.27121041,18.8823529 12.0661765,18.8823529 C13.6696833,18.8823529 15.1128394,18.3478507
        16.2352941,17.4926471 Z" fillRule="nonzero" />
	</SvgIconContainer>
);