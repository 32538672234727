// @flow
import * as React from "react";

import styled from "styled-components";
import colors from "../../../assets/css/colors";
import { below } from "../../../assets/css/media";

export const InputItem = styled.input`
	color: ${({ is_error, isComplete }) => is_error ? colors.errorColor : 
		isComplete ? colors.primary.primary:colors.form.textColor
};
	font-size: 13px;
	height: 40px;
	box-sizing: border-box;
	border: 1px solid ${({ is_error }) => is_error ? colors.errorColor : colors.form.borderColor};
	background-color: ${({ isComplete }) => isComplete ? colors.form.completedField : colors.white};
	width: 100%;
	padding-left: 1em;
	font-family: "SourceSansPro";

	&:disabled {
		opacity: .5;
	}

	::placeholder {
		color: ${({ is_error }) => is_error ? "red" : colors.secondary.accentGrey};
	}
	
	${below.desktop`
		padding-right: 3em;
	`}
`;

type Props = {
	placeholder: string,
	name: string,
	type: string,
	input?: Object,
	is_disabled?: boolean,
	is_error?: boolean,
	readOnly?: boolean,
	max_length?: number,
	autocomplete?: any,
	defaultValue?: string
};

export const Input = ({
	name,
	input,
	placeholder,
	type,
	is_disabled,
	is_error,
	max_length,
	readOnly,
	autocomplete,
	defaultValue
}: Props ) => (
	<InputItem
		{...input}
		placeholder={placeholder}
		name={name}
		type={type}
		disabled={is_disabled}
		is_error={is_error}
		readOnly={readOnly}
		defaultValue={input ? input.defaultValue : ""}
		maxLength={max_length || 40}
		autoComplete={autocomplete}
		isComplete={input && input.value !== "" ? true:false}
	/>
);

export default Input;