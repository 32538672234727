// @flow
import styled from "styled-components";
// import colors from "../../assets/css/colors";
import { below } from "../../assets/css/media";

export const PlayerCellWrapper = styled.div`
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	margin-bottom: 10px;
	/* padding: 32px 50px 30px; */
	box-sizing: border-box;

	> div {
		flex-basis: 48%;
	}
	${below.desktop`

	`};
	

`;

export default PlayerCellWrapper;