/* eslint max-len: 0 */
// @flow
import React from "react";
import SvgIconContainer from "../SvgIconContainer";

export default (
	{
		unit = "em",
		...rest
	}: Object = {}) => (
	<SvgIconContainer viewBox="0 0 16 16" {...rest} unit={unit}>
		<title>Injury Sub</title>
		<path d="M9.44618 3.2507L8.14815 6.95936L6.85011 3.2507C7.24727 3.47223 7.69342 3.59118 8.14815 3.5968C8.60288 3.59118 9.04902 3.47223 9.44618 3.2507ZM6.85185 12.1519H4V7.40235C4.56481 7.17594 4.99649 6.70562 5.17376 6.12352C5.35157 5.45116 5.22939 4.73475 4.83879 4.15933L6.21287 3L7.88889 7.78851V10.8555C7.88889 11.8491 7.6466 12.1519 6.85185 12.1519ZM12.2963 12.1519H9.44444C8.6497 12.1519 8.40741 11.8491 8.40741 10.8556V7.78852L10.0835 3.00005L11.4576 4.15938C11.067 4.7348 10.9448 5.45121 11.1226 6.12357C11.2999 6.70564 11.7315 7.17593 12.2963 7.40235V12.1519ZM10.604 8.34937H10.079C10.0065 8.34937 9.94773 8.40813 9.94773 8.48062V9.26812H9.16023C9.08774 9.26812 9.02898 9.32689 9.02898 9.39937V9.92437C9.02898 9.99686 9.08774 10.0556 9.16023 10.0556H9.94773V10.8431C9.94773 10.9156 10.0065 10.9744 10.079 10.9744H10.604C10.6765 10.9744 10.7352 10.9156 10.7352 10.8431V10.0556H11.5227C11.5952 10.0556 11.654 9.99686 11.654 9.92437V9.39937C11.654 9.32689 11.5952 9.26812 11.5227 9.26812H10.7352V8.48062C10.7352 8.40813 10.6765 8.34937 10.604 8.34937Z"/>
	</SvgIconContainer>
);