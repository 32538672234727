// @flow
import * as React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import _, { bindAll } from "lodash";
import { below } from "../../../../../assets/css/media";
import colors from "../../../../../assets/css/colors";
import { isMobile } from "../../../../../utils";
import AddPlayer from "../../../../../components/Icons/AddPlayer";
import * as actions from "../../../../../modules/actions";
import { ModalContainer, 
	ModalContent,
	ModalBody, ModalOverlay
} from "../../../../../components/Modal";
import type { TPLeague, TPlayer, TPlayersById, TDraftUserTeam } from "../../../../../modules/types";
import { SearchSubmitButton, ButtonPrimary } from "../../../../../components";
import Search from "../../../../../components/Icons/Search";
import SelectArrowDownSmall from "../../../../../assets/images/ArrowDownSmall.svg";
import { isAllTrue } from "../../../../../helpers";
import OverflowUtils from "../../leagueTeam/overflowUtils";

const POSITION_KEYS = ["1", "2", "3", "4", "bench"];

const getIDFromLineup = (lineup: Object) => {
	let id_arr = [];
	POSITION_KEYS.forEach(position => {
		const lineupArr = lineup[position] || [];
		lineupArr.forEach(id => {
			id_arr.push(id);
		});
	});
	return id_arr;
};

const SearchResultsWrapper = styled.div`
	width: 440px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	height: 25px;
	${below.phone`
		width: 300px;
		flex-direction: column;
		height: fit-content;
	`}
`;

const SearchResultButton = styled.button`
	height: 20px;
	width: fit-content;
	padding: 0px 11px;
	background-color: #7F8A90;
	border-radius: 2px;
	font-family: SourceSansPro;
	font-size: 12px;
	font-weight: 600;
	color: #fff;
	border: none;
	cursor: pointer;
	${below.phone`
		height: 40px;
		width: 100%;
		margin-bottom: 4px;
	`}
`;

const ProModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: grey;
	z-index: 2000;
	overflow: hidden;
	${ModalContainer} {
		${below.phone`
    		overflow-y: scroll;
		`}
	}
	${ModalContent} {
		box-sizing: border-box;
		box-shadow: 1px 0px 12px 0 rgb(0,0,0,0.2);
		padding-top: 0;
		${below.tablet`
			max-width: unset;
		`}

		overflow: scroll;
		height: 564px;
		${below.phone`
			height: 100vh;
			max-width: 340px;
			padding-top: 40px;
			padding-left: 0;
			padding-right: 0;
		`}

		@media screen and (max-width: 330px) {
			padding-top: 0;
		}

		${ModalBody} {
			padding: 40px 100px 0px;
			overflow: hidden;
			position: static;
			display: flex;
			flex-direction: column;
			align-items: center;
			

			${below.desktop}{
				overflow: hidden;
			}
			${below.phone`
				padding: 0;
				max-width: 302px;
				margin: 0 auto;
			`}
		}
	}
  ${ModalOverlay}{
    background: rgba(0, 0, 0, 0.5);
		box-shadow: 1px 0px 12px 0 rgb(0,0,0,0.2);
		height: 100vh;
  }
  

	.close-button {
		z-index: 1;
		> svg {
			fill: ${colors.primary.primary};
		}
		:hover {
			> svg {
				fill: #333;
			}
		}
	}     
`;

const SearchInputWrapper = styled.div`
	margin-top: 10px;
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: space-between;
	position: relative;
`;

const FormLabel = styled.label`
	font-family: SourceSansPro;
	font-weight: 600;
	font-size: 12px;
	color: ${colors.primary.primary};
	text-align: left;
	align-self: center;
	width: 440px;
	${below.phone`
		width: 300px;
	`}
`;

const ModalTitle = styled.h2`
	color: ${colors.primary.primary};
	font-size: 32px;
	text-align: center;
	margin-bottom: 10px;
	padding-top: 20px;  
	${below.tablet`
		font-size: 24px;
	`}

	${below.phone`
		font-size: 22px;
	`}
`;
const FieldWrapper = styled.div`
	margin-top: 15px;		
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 70px;
`;

const ImageWrapper = styled.div`
	svg{
		width: 60px;
		height: 60px;
		g{
			path{
				fill: ${colors.primary.primary}
			}
		}
	}
`;

const SearchInput = styled.input`
	height: 40px;
	width: 420px;
	border: 1px solid #cad2d8;
	font-family: SourceSansPro;
	font-weight: 600;
	font-size: 12px;
	padding: 0px;
	color: ${colors.primary.primary};
	padding: 0 10px;
	${below.phone`
		width: 280px;
	`}
`;

const SubTitle = styled.p`
	font-family: SourceSansPro;
	font-weight: 400;
	font-size: 14px;
	color: ${colors.primary.primary};
	width: 100%;
`;
const Controls = styled.div`
	display: flex;
	margin-top: 10px;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: space-between;
	border: 1px solid #CAD2D8;
	width: ${isMobile() ? "100%;" : ""};
	select {
		width: 440px;
		height: 40px;
		padding-left: 10px;
		color: #7F8A90;
		font-family: SourceSansPro;
		font-size: 12px;
		font-weight: 600;
		-webkit-appearance: none;
		-moz-appearance: none;
		-o-appearance: none;
		appearance: none;
		background-image: url(${SelectArrowDownSmall});
		background-position: 98% center;
		background-repeat: no-repeat;
		background-size: 15px;
		outline: none;
		${below.phone`
			width: 300px;
		`}
		option {
			width: 190px;
			height: 40px;
			color: #7F8A90;
			font-family: SourceSansPro;
			font-size: 12px;
		}

	}
`;

const SubmitButton = styled(ButtonPrimary)`
	font-family: SourceSansPro;
	font-size: 12px;
	font-weight: 600;
	color: #fff;
	width: 200px;
	margin-top: 40px;
	margin-bottom: 30px;
	${({ substituted }) => substituted && `
		&:disabled {
		background: ${colors.success};
		color: #fff;
		cursor: inherit;
		border-color: ${colors.secondary.paleGrey};
	}
	`}
`;

const CancelButton = styled.button`
	border: none;
	background: transparent;
	color: #D92425;
	font-family: SourceSansPro;
	font-size: 14px;
	font-weight: 400;
	text-decoration: none;
`;

const ControlPlayerItem = ({ player_name, current_player, ...rest }) => (
	<option className={current_player === player_name ? "active" : ""} {...rest}>
		{ player_name }
	</option>
);


type Props = {
  closeAddPlayerModal: Function,
	openAddPlayerModal: Function,
	player_id: number,
	team: TDraftUserTeam,
	league: TPLeague,
	players_by_id: TPlayersById,
	players: Array<TPlayer>,
	free_agents: Object,
	active_position: number,
	positions: Object,
	postRequestWaiver: Function,
	pending: boolean,
	substituted: boolean,
	updateMyTeam: Function,
	postRequestWaiverClear: Function,
	removePlayer: typeof actions.teamsDraft.fetchRemovePlayer,
	postWaiverAddDropPlayers: typeof actions.leagueDraft.postWaiverAddDropPlayers,
	postRequestWaiver: typeof actions.teamsDraft.postRequestWaiver,
	forceTeamUpdate: typeof actions.leagueDraft.forceTeamUpdate,
}
type State = {
	add_player_id: number,
	search_string: string,
	filters: {
		search: string,
		position: number
	},
	filtered_players: Array<Object>
}



class AddPlayerModalComponent extends React.Component<Props, State> {
	constructor(props: Props, state: State){
		super(props, state);
		bindAll(this, [
			"handleSearchChange",
			"onSelectHandleChange",
			"handleAddPlayer",
			"handleResetModal",
			"handleSubmit"
		]);
	}
	state = {
		add_player_id: 0,
		search_string: "",
		filters: {
			search: "",
			position: 0,
			by_fa: true
		},
		filtered_players: []
	};
	handleSearchChange(e){
		this.setState({
			filters:{ ...this.state.filters, search: e.target.value }
		});
	}
	handleAddPlayer(e){
		e.preventDefault();
		this.setState({
			...this.state,
			add_player_id: e.target.value,
			filters: { ...this.state.filters, search: e.target.getAttribute("data-name") }
		});
	}
	getPlayersFromTeam() {
		const { players_by_id, team } = this.props;
		const populated_players = [];
		const team_players = getIDFromLineup(team.lineup);
		team_players.forEach(player_id => {
			if(!player_id){
				return;
			}
			populated_players.push(players_by_id[player_id]);
		});
		populated_players.unshift({ id: 0, first_name: "Select a player to", last_name: "drop" });
		return populated_players;
	}
	onSelectHandleChange(e) {
		
		const { openAddPlayerModal } = this.props;
		openAddPlayerModal(Number(e.target.value));
		
	}
	handleResetModal() {
		const { closeAddPlayerModal, postRequestWaiverClear } = this.props;
		this.setState({
			add_player_id: 0,
			search_string: "",
			filters: {
				search: "",
				position: 0,
				by_fa: true
			},
			filtered_players: []
		});
		postRequestWaiverClear();
		closeAddPlayerModal();
	}
	
	handleSubmit() {
		const {
			team, 
			forceTeamUpdate, 
			player_id, 
			removePlayer, 
			postRequestWaiver, 
			postWaiverAddDropPlayers,
		} = this.props;
		const {add_player_id} = this.state;
		const lineupToUse = add_player_id ? this.new_lineup : this.straight_drop_new_lineup;
		if(player_id && add_player_id === 0){
			removePlayer({
				team_id: team.id, 
				player_id
			});
			forceTeamUpdate({
				lineup: lineupToUse,
				id: team.id,
			});
			this.handleResetModal();
			return;
		}
		if(player_id === 0 && add_player_id){
			postRequestWaiver({
				team_id: team.id,
				new_player_id: add_player_id,
				old_player_id: 0
			});
			forceTeamUpdate({
				lineup: lineupToUse,
				id: team.id,
			});
			this.handleResetModal();
			return;
		}
		postWaiverAddDropPlayers({
			team_id: team.id,
			player_in_id: add_player_id,
			player_out_id: player_id
		});


		forceTeamUpdate({
			lineup: lineupToUse,
			id: team.id,
		});
		this.handleResetModal();
	
	}
	get new_lineup() {
		const { player_id, team, active_position, league, positions} = this.props;
		const { add_player_id } = this.state;
		let new_lineup =  OverflowUtils.normaliseLineup(league, team.lineup, positions) ;
		let isReplaced = false;
		POSITION_KEYS.forEach(key => {
			
			if(new_lineup[key].length === 0){
				new_lineup[key].push(0);
			}
			new_lineup[key].forEach((id, index) => {
				
				if(id === player_id && !isReplaced){
					new_lineup[key][index] = add_player_id;
					isReplaced = true;
					return;
				}
				if(isAllTrue([
					id === 0, 
					Number(key) === active_position, 
					player_id === 0, 
					!isReplaced
				])){
					new_lineup[key][index] = add_player_id;
					isReplaced = true;
					return;
				}
				if(isAllTrue([
					id === 0,
					[0, "bench"].includes(active_position),
					player_id === 0,
					Boolean(add_player_id),
					!isReplaced
				])){
					new_lineup["bench"][index] = add_player_id;
					isReplaced = true;
					return;
				}
			});
		});
		return new_lineup;
	}

	get straight_drop_new_lineup()  {
		const { player_id, team } = this.props;
		const { add_player_id } = this.state;
		let new_lineup = team.lineup;
		POSITION_KEYS.forEach(key => {
			new_lineup[key].forEach((id, index) => {
				if(id === player_id && add_player_id === 0){
					new_lineup[key][index] = 0;
				}
			});
		});
		return new_lineup;
	}
	getFilteredPlayers() {
		const { players, free_agents, active_position } = this.props;
		const lower_case_search = this.state.filters.search.toLowerCase();
		let players_copy = players;
		let result = players_copy.filter(function(player){
			return free_agents.includes(player.id);
		});
		const active_position_filter = 
      		[0, "bench"].includes(active_position)  ? "none" : Number(active_position);

		if(active_position_filter !== "none"){
			result = result.filter(function(player){
				return player.positions.includes(active_position_filter);
			});
		}
		
		result = result.filter(function(player){
			return `${player.first_name} ${player.last_name}`.toLowerCase()
				.includes(lower_case_search);
			
		});
		return result.slice(0,4);
	}

	get search_input() {
		const { filters: { search } } = this.state;
		return(
			<FieldWrapper>
				<FormLabel>Player I want to add: </FormLabel>
				<SearchInputWrapper>
					<SearchInput type="text" onChange={this.handleSearchChange} value={search}/>
				
					<SearchSubmitButton type="submit">
						<Search size='3' />
					</SearchSubmitButton>
				</SearchInputWrapper>
				
		
			
			</FieldWrapper>
		);
	}

	get drop_select() {
		return(
			<FieldWrapper>
				<FormLabel>Player I want to drop: </FormLabel>
				<Controls>
					<select value={this.props.player_id} onChange={this.onSelectHandleChange}>
						{this.getPlayersFromTeam().map(player => (
							<ControlPlayerItem
								current_player={this.state.add_player_id}
								player_name={`${player.first_name} ${player.last_name}`}
								value={player.id}
							/>
						))}
					</select>
				</Controls>
				
			</FieldWrapper>
		);
	}

	get search_results() {
		return(
			<SearchResultsWrapper>
				{ this.getFilteredPlayers().map(player => (
					<SearchResultButton 
						key={`${player.id}-result`} 
						value={player.id} 
						data-name={`${player.first_name} ${player.last_name}`} 
						onClick={this.handleAddPlayer}>
						{player.first_name} {player.last_name}
					</SearchResultButton>
				))}
			</SearchResultsWrapper>
		
		);
	
	}
  

	render() {
  	const { pending, players } = this.props;

		const is_disabled = (pending);
		if(_.isEmpty(players)){
			return null;
		}
  	return(
  		<ProModalWrapper>
  			<ModalContainer onClick={this.handleResetModal}	container_background={colors.white}>
					<ImageWrapper>
						<AddPlayer />
					</ImageWrapper>
					
  				<ModalTitle>Player Drop/Add</ModalTitle>
					<SubTitle>
						Please select who you would like 
						to drop and who you would like to add
					</SubTitle>
					{this.drop_select}
					{this.search_input}
					{this.search_results}
					<SubmitButton 
						disabled={is_disabled} 
						onClick={this.handleSubmit}> 
						{"Confirm player change"}
					</SubmitButton>
					<CancelButton onClick={this.handleResetModal}>Cancel</CancelButton>
  			</ModalContainer>
  		</ProModalWrapper>
  	);
  	
	}
	
};

const mapStateToProps = state => ({
	free_agents: state.leagues.waiver_free.ordered_ids,
	pending: state.teamsDraft.is_team_saving,
	substituted: state.teamsDraft.waiver_requests === "substituted",
	positions: state.positions.by_id,
});

const mapDispatchToProps = {
	postRequestWaiver: actions.teamsDraft.postRequestWaiver,
	postRequestWaiverClear: actions.teamsDraft.postRequestWaiverClear,
	updateMyTeam: actions.teamsDraft.postTeamUpdate,
	removePlayer: actions.teamsDraft.fetchRemovePlayer,
	postWaiverAddDropPlayers: actions.leagueDraft.postWaiverAddDropPlayers,
	forceTeamUpdate: actions.leagueDraft.forceTeamUpdate
};

export const AddPlayerModal = connect(
	mapStateToProps,
	mapDispatchToProps)
(AddPlayerModalComponent);

export default AddPlayerModal;