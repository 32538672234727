// @flow
import _, { get } from "lodash";
import type { TRootStore } from "../../types";

export const getUser = (state: TRootStore) => _.get(state, "user.data");
export const isLoggedInViaFB = (state: TRootStore) => _.get(state, "user.login.is_fb_login");
export const getUserId = (state: TRootStore) => Boolean(_.get(state, "user.data.id"));
export const hasUser = (state: TRootStore) => Boolean(_.get(state, "user.data.id"));
export const getUserAvatar = ({ user }: TRootStore) => {
	const id = _.get(user, "data.id"),
		avatar_version = _.get(user, "data.avatar_version"),
		avatar_path = process.env.REACT_APP_AVATARS_PATH || "";

	return id ? `${avatar_path}${id}.png?v=${avatar_version}` : false;
};

export const isUserPending = ({ user }: TRootStore) => user.user.is_pending;
export const getUserError = ({ user }: TRootStore) => user.user.error;
export const getUserLoginError = ({ user }: TRootStore) => user.login.error;
export const getUserLoginPending = ({ user }: TRootStore) => user.login.is_pending;
export const getUserActivatePending = ({ user }: TRootStore) => user.activate.is_pending;
export const getUserActivateError = ({ user }: TRootStore) => user.activate.error;

export const getActivateUserError = ({ user }: TRootStore) => user.activate.error;

export const isUserActivateSuccess = ({ user }: TRootStore) => user.activate.success;
export const getAfliDIdentityToken = ({ user }: TRootStore) => user.afl_id.identity_token;
export const getAfliDLoadingStatus = ({ user }: TRootStore) => user.afl_id.loading_status;
export const getAfliDHasExistingUserData = ({ user }: TRootStore) => !_.isEmpty(user.afl_id.user);
export const getIsLinkedAccount = ({user}: TRootStore) => user.data.is_linked_account;
export const getAfliDIsLinked = ({ user }: TRootStore) => user.afl_id.is_linked;
export const isAflIdTokenPresent = ({user}: TRootStore) => user.afl_id.afl_account_id !== 0;
export const getAfliDUser = ({ user }: TRootStore) => user.afl_id.afl_account;
export const getAFliDUserClassicLeagueHistory = ({ user }: TRootStore) => 
	user.afl_id.leagues_history ? user.afl_id.leagues_history.classic_leagues_history:null;
export const getAFliDUserDraftLeagueHistory = ({ user }: TRootStore) => 
	user.afl_id.leagues_history ? user.afl_id.leagues_history.draft_leagues_history:null;
export const isLeaguesHistoryReady = ({user}: TRootStore) => Boolean(user.afl_id.leagues_history);
export const isForgotPasswordSuccess = ({ user }: TRootStore) => user.forgot_password.success;
export const isForgotPasswordPending = ({ user }: TRootStore) => user.forgot_password.is_pending;

export const isChangePasswordPending = ({ user }: TRootStore) => user.change_password.is_pending;
export const isChangePasswordSuccess = ({ user }: TRootStore) => user.change_password.success;
export const isChangePasswordError = ({ user }: TRootStore) => user.change_password.error;

export const isAlreadyLinkedError = ({user}: TRootStore) => user.checkEmail.error !== null;


export const getNewAccountInfo = ({user}: TRootStore) => 
	({user: user.afl_id.user, leagues_history: user.afl_id.leagues_history});
export const isNewAccountLinked = ({user}: TRootStore) => 
	get(user, 'afl_id.user.isSuccessfullyLinked');

export const getUltimateFootyData = ({user}: TRootStore) => user.uf_account;