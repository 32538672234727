// @flow
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNotificationDraftBar } from "../../modules/actions/index";
import { getDraftBar, getUserId } from "../../modules/selectors";
import GlobalNote from "../GlobalNote/index";

export const DraftBar = () => {
	const dispatch = useDispatch();
	React.useEffect(() => {
		dispatch(getNotificationDraftBar());
	}, [dispatch]);
	const bar_data = useSelector(getDraftBar);
	const id = useSelector(getUserId);

	return (
		<GlobalNote
			user_id={id}
			note_text={bar_data.content}
			should_show={bar_data.show}
		/>
	);
};