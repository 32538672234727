// @flow
import styled from "styled-components";

export const EmailFields = styled.div`
	display: flex;
	.form-field {
		flex-basis: 45%;
		
		&:first-child {
			margin-right: 1em;
		}
	}
`;

export default EmailFields;