// @flow
import * as React from "react";
import styled, { css } from "styled-components";
import _ from "lodash";
import Countdown from "react-countdown-now";
import colors from "../../assets/css/colors";
import { below } from "../../assets/css/media";
import { isMobile } from "../../utils";
import { Exist } from "../Exist";
import { isAllTrue } from "../../helpers";


const Unit = styled.div`
	font-size: ${({ small }) => small ? "20px" : "32px"};
	font-weight: bold;
	letter-spacing: 0.5px;
	line-height: 1;
	text-align: center;
	color: ${colors.primary.primary};
	font-family: SourceSansPro;
	font-weight: 600;
	${below.desktop`
		font-size: ${({ small }) => small ? "12px" : "20px"};
		font-family: TitilliumUpright;
		font-weight: 400;
		letter-spacing: 0;
	`}
`;

const Label = styled.div`
	color: ${colors.primary.primary};
	font-size: 8px;
	line-height: ${({ small }) => small ? "1.3" : "2"};
	text-align: center;
	margin-top: .25em;
	text-transform: ${({ is_uppercase }) => is_uppercase ? "uppercase" : "none"};

	${below.desktop`
		margin-top: 0;
	`}
`;

const CountdownTimerCell = styled.div`
	padding: .5em .5em 0 .5em;

	&:last-child {
		border: none;
	}

	${below.tablet`
		padding: 0;
	`}
`;

const CountdownTimerWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	${below.desktop} {
		justify-content: left;
	}
	${below.tablet`
		margin-top: 4px;
	`}

	${CountdownTimerCell} {
		${({ remove_outer_padding }) => remove_outer_padding && css`
			&:first-child {
				padding-left: 0;
			}

			&:last-child {
				padding-right: 0;
			}
		`}
	}
`;


const checkForMultiplyDateTimeLabel = unit => parseInt(unit, 10) === 1 ? "" : "s";

const renderer = (
	{
		days,
		hours,
		minutes,
		seconds,
		completed,
		label_type,
		popup,
		is_uppercase,
		hideHoursIfZero,
		showOnComplete,
		small,
		remove_outer_padding,
		...props
	}
) => {
	if([!showOnComplete, completed].every(flag => flag)) {
		// Render a completed state
		return null;
	}

	const label = {
		full: {
			days: "Day",
			hours: "Hour",
			minutes: "Minute",
			seconds: "Second"
		},
		short: {
			days: "Day",
			hours: "Hr",
			minutes: "Min",
			seconds: "Sec"
		}
	}[label_type || "short"];
	const isMobileAndNotPopup = isAllTrue([isMobile(), !popup]);
	// Render a countdown
	return (
		<CountdownTimerWrapper
			className={props.className}
			remove_outer_padding={remove_outer_padding}
		>	
			<Exist when={days}>
				<CountdownTimerCell>
					<Unit className="timer-unit" small={small}>
						{days}{isMobileAndNotPopup && ":"}
					</Unit>
					<Label is_uppercase={is_uppercase} className="timer-label" small={small}>
						{label.days}{checkForMultiplyDateTimeLabel(days)}
					</Label>
				</CountdownTimerCell>

			</Exist>
			<Exist when={!(hideHoursIfZero && hours === "00")}>
				<CountdownTimerCell>
					<Unit className="timer-unit" small={small}>
						{hours}{isMobileAndNotPopup && ":"}
					</Unit>
					<Label is_uppercase={is_uppercase} className="timer-label" small={small}>
						{label.hours}{checkForMultiplyDateTimeLabel(hours)}
					</Label>
				</CountdownTimerCell>

			</Exist>
			
			<CountdownTimerCell>
				<Unit className="timer-unit" small={small}>
					{minutes}
				</Unit>
				<Label is_uppercase={is_uppercase} className="timer-label" small={small}>
					{label.minutes}{checkForMultiplyDateTimeLabel(minutes)}
				</Label>
			</CountdownTimerCell>
			<Exist when={!days}>
				<CountdownTimerCell>
					<Unit className="timer-unit" small={small}>
						{isMobileAndNotPopup && ":"}{seconds} 
					</Unit>
					<Label is_uppercase={is_uppercase} className="timer-label" small={small}>
						{label.seconds}{checkForMultiplyDateTimeLabel(seconds)}
					</Label>
				</CountdownTimerCell>

			</Exist>
		</CountdownTimerWrapper>
	);
};

type State = {
	lastDelta: Object
}

type CProps = {

}

class InstantCountdown extends Countdown<CProps, State> {

	constructor(props: CProps) {
		super(props);
		this.state = {
			lastDelta: {}
		};
		_.bindAll(this, [
			"setLastDelta"
		]);
	}
	setLastDelta(delta: Object) {
		this.setState({
			lastDelta: delta
		});
	}
	setDeltaState(delta: Object) {
		if(this.state.completed && this.props.isKeeperCounter){
			window.location.reload(false);
		}
		if (!this.state.completed && delta.completed) {
			if (this.props.onComplete) {
				this.clearInterval();
				this.props.onComplete(delta);
			}
		}

		const isDeltaSame = JSON.stringify(delta) === JSON.stringify(this.state.lastDelta);
		if (isAllTrue([this.mounted, delta, !isDeltaSame])) {
			this.setState({ ...delta });
			this.setLastDelta(delta);
		}
		
	}
}

type Props = {
	date: string | Date,
	onComplete?: Function,
	label_type?: string,
	is_uppercase?: boolean,
	className?: string,
	hideHoursIfZero?: boolean,
	showOnComplete?: boolean,
	small?: boolean,
	remove_outer_padding?: boolean,
	isKeeperCounter?: boolean,
	popup?: boolean
}

export const CountdownTimer = ({
	date,
	onComplete,
	label_type,
	is_uppercase,
	className,
	hideHoursIfZero,
	showOnComplete,
	small,
	remove_outer_padding,
	isKeeperCounter,
	popup
}: Props) => {
	const to_date = new Date(date);

	return !isNaN(to_date.getTime()) ? (
		<InstantCountdown
			date={to_date}
			onComplete={onComplete}
			isKeeperCounter={isKeeperCounter}
			
			renderer={({ ...props }) => renderer({
				label_type,
				is_uppercase,
				hideHoursIfZero,
				className,
				showOnComplete,
				small,
				remove_outer_padding,
				...props
			})}
		/>
	) : null;
};

export default CountdownTimer;