// @flow
import * as React from "react";
import styled from "styled-components";
import {Redirect} from "react-router-dom";
import {compose} from "redux";
import {connect} from "react-redux";
import _ from "lodash";

import type {TRootStore} from "../../../modules/types/store";
import type {
	TCoachPlayerStatsById,
	TDraftTeamsById,
	TDraftUserTeam,
	TPLeague,
	TRound,
	TRoundWithMatches,
	TUser
} from "../../../modules/types";

import * as selectors from "../../../modules/selectors";
import * as actions from "../../../modules/actions";

import {getTeamProjectedScore} from "../../../helpers/team";

import JsonFetcher from "../../../components/utils/JsonFetcher";
import {AdsContainer} from "../../../components/AdsContainer";
import {
	Ad,
	ButtonPrimary,
	DraftTableHeader,
	Exist,
	LeagueGameBar,
	Preloader,
	StandardSidebar,
	withDraftPlayerModal,
} from "../../../components";
import {PageContentWrapper} from "../../../components/PageContentWrapper";
import {PageTitle} from "../../../components/PageTitle";
import {TwoColumnLayout} from "../../../components/TwoColumnLayout";
import {RoundSelector} from "../../../components/RoundSelector";
import {MatchCentreBar} from "../../../components/MatchCentreBar";
import {Footer} from "../../../components/Footer";
import withRoundSelector from "../../../components/utils/withRoundsSelector";

import ByeRoundMessage from "../../../components/LeagueByeRoundMessage";

import {isMobile} from "../../../utils";
import {dataLayer} from "../../../utils/GTM";
import {getLeagueRoundAliases} from "../../../helpers/league";

import withCoachesBox from "../../../components/utils/withCoachesBox";
import {
	initialScoreStat,
	matchup_field_stats as field_stats,
	SummaryPanel,
	TeamsComparisonTable
} from "../../../components/MatchUp";
import {isAllTrue, isAnyTrue} from "../../../helpers";
import MatchupHoldingMessage from "../../../components/MatchupHoldingMessage";
import { MatchupShare } from "../../../components/MatchupShare";
import {shareDynamicMatchupImg} from "../../../utils/social_share";

const MainWrapper = styled.div`
	margin-bottom: 50px;
`;
const TitleRoundWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

`;

const CenterDiv = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	button{
		width: 50%;
		margin-bottom: 20px;
	}
	width: 100%;
`;
const buildFantasyMatchupsUrlPathname = (league_id, round_id, home_team_id, away_team_id) => {
	const path_sections = [
		"/draft/league/",
		league_id,
		"/fantasy-matchups/",
		round_id
	];

	if (home_team_id && away_team_id) {
		path_sections.push(`/${home_team_id}`);
		path_sections.push(`/${away_team_id}`);
	}
	return path_sections.join("");
};
const getCompressedViewStatus = () => {
	if(localStorage.getItem("compressed_view")){
		return localStorage.getItem("compressed_view") === "true" ? true : false;
	}
	localStorage.setItem("compressed_view", "false");
	return false;
};
type Props = {
	selected_league_id: number,
	setSelectedRoundId: Function,
	round_id?: number,
	user_team: Object,
	selected_round_id: number,
	home_team_id?: number,
	away_team_id?: number,
	league_rounds: Array<TRound>,
	fetchLeague: Function,
	fetchTeam: typeof actions.teamsDraft.fetchTeam,
	fetchLeagueRosters: typeof actions.leagueDraft.fetchLeagueRosters,
	fetchAllCustomStats: typeof actions.fetchAllCustomStats,
	fetchAllCoachCustomStats: typeof actions.fetchAllCoachCustomStats,
  fetchPlayersMatches: typeof actions.fetchPlayersMatches,
	rosters: Object,
	round_id: number,
	league: TPLeague,
	teams_by_id: TDraftTeamsById,
	home_team_id: number,
	home_team: TDraftUserTeam,
	away_team_id: number,
	away_team: TDraftUserTeam,
	players: Object,
	coach_players_by_id: TCoachPlayerStatsById,
	positions: Array<Object>,
	has_assistant_coach: boolean,
	user: TUser,
	history: Object,
	actual_round: TRound,
	ladder_by_id: Object,
	fetchLadder: Function,
	data_has_loaded: boolean,
	rounds: Array<TRoundWithMatches>,
	showModalForPlayerId: typeof actions.showModalForPlayerId,
	custom_stats: Object,
  fetchRounds: typeof actions.fetchRounds,
  is_rounds_pending: boolean,

  home_team_roster: Object,
  away_team_roster: Object
};

type State = {
	active_stats_type: string,
	preloader: boolean,
	is_compressed: boolean,
	matchup_share_open: boolean
};

class MatchUpComponent extends React.Component<Props, State> {
	constructor(props) {
		super(props);

		_.bindAll(this, [
			"changeRound",
			"gotoPrevFixture",
			"gotoNextFixture",
			"setActiveStatsType",
			"handleMatchupToggle",
			"onCompressedViewClick",
			"setLocalStorageMatchup"
		]);
	}

	state = {
		active_stats_type: this.getInitialStat(),
		preloader: true,
		is_compressed: getCompressedViewStatus(),
		matchup_share_open: false,
	};

	componentDidMount() {
		const {
			selected_round_id,
			fetchPlayersMatches,
			round_id,
		} = this.props;
		if(this.props.league){
			this.fetchLeague();
		}

		if(selected_round_id){
			fetchPlayersMatches(selected_round_id);
		}

		if(selected_round_id || round_id){
			const roundToFetch = selected_round_id || round_id;
			this.fetchLeagueRosters(roundToFetch);
		}

		this.getPlayerStats();
		this.fetchLadder();
		
	}

	componentDidUpdate(prev_props,prev_State) {
		const {
			round_id,
			selected_round_id,
			data_has_loaded,
		} = this.props;	
		
		if(prev_props.round_id !== round_id){
			this.setState({
				active_stats_type: this.getInitialStat(),
			});
		}

		if (isAllTrue([
			this.state.preloader,
			data_has_loaded
		])) {
			this.setState({
				preloader: false,
				active_stats_type: this.getInitialStat()
			});
		}
		if(prev_props.league !== this.props.league){
			this.getPlayerStats();
		}

		if (isAllTrue([
			isAnyTrue([
				Boolean(round_id),
				Boolean(selected_round_id)
			]),
			(isAnyTrue([
				round_id !== prev_props.round_id,
				selected_round_id !== prev_props.selected_round_id
			]))
		])
		) {
			this.fetchLeagueRosters(round_id);

		}
	}

	get imageShareUrl() {
		const round_id = this.last_completed_round_id;
		const league_id = this.props.league.id;
		const team_id = _.get(this.props.user_team, 'id') ;

		if(!round_id || !league_id || !team_id){
			return "";
		}
		return shareDynamicMatchupImg({
			round_id,
			league_id: this.props.league.id,
			team_id: this.props.user_team.id,
			is_classic: false,
		});
	}

	handleMatchupToggle(bool: boolean) {
		if(bool){
			dataLayer({
				'event': 'Dynamic Share Matchup Draft Click' 
			});
		}
		this.setState({
			matchup_share_open: bool
		});
	}

	setLocalStorageMatchup() {
		const roundComplete = this.last_completed_round_id;
		localStorage.setItem(`matchupShareDraft-${roundComplete}`, "true");
		this.handleMatchupToggle(false);
	}

	isLocalStorageSetForRound() {
		const roundComplete = this.last_completed_round_id;
		return localStorage.getItem(`matchupShareDraft-${roundComplete}`); 
	}

	isShowMatchupShareOnLoad() {
		return !this.isLocalStorageSetForRound() && this.isMatchupShareAvailable();
	}

	isMatchupShareAvailable() {
		const leagueStartRoundId = this.props.league.start_round;
		const startRound = this.props.rounds.find(round => round.id === leagueStartRoundId);
		const status = _.get(startRound, 'status');
		const isStartRoundComplete = status === "complete";
		return isStartRoundComplete;
	}

	getInitialStat() {
		const { has_assistant_coach } = this.props;
		const round_started = _.get(this.round, "status", "scheduled") !== "scheduled";

		return initialScoreStat(round_started, has_assistant_coach);
	}

	getPlayerStats() {
	
		const {
			fetchAllCustomStats,
			fetchAllCoachCustomStats,
			league,
			has_assistant_coach,
		} = this.props;
		if(league && Boolean(league.custom_scoring_enabled)){
			fetchAllCustomStats(league.id);
			if(has_assistant_coach){
				fetchAllCoachCustomStats(league.id);
			}
		}
	}

	fetchLadder() {
		const { fetchLadder, selected_league_id, round_id } = this.props;
		fetchLadder({
			round: round_id,
			league_id: selected_league_id
		});
		
	}

	fetchLeagueRosters(round_id) {
		const { fetchLeagueRosters, selected_league_id } = this.props;
		fetchLeagueRosters({
			league_id: selected_league_id,
			round: round_id
		});
	}

	fetchLeague() {
		const { fetchLeague, selected_league_id } = this.props;

		fetchLeague({
			id: selected_league_id
		});
	}

	get round() {
		const {
			rounds,
			round_id
		} = this.props;

		return _.find(rounds, { id: round_id });
	}

	mergeTeamData(team, is_home_team) {
		const {
			ladder_by_id,
			home_team_roster,
			away_team_roster
		} = this.props;

		if(team) {
			return {
				...team,
				...ladder_by_id[team.id],
				lineup: is_home_team ? home_team_roster : away_team_roster
			};
		}
		else {
			return {};
		}
	}

	getTeamPlayerProjScores(team) {
		const {
			players,
			league,
		} = this.props;

		return getTeamProjectedScore(
			_.get(team, "lineup"),
			players,
			league,
			this.round,
			false
		);
	}

	get is_matchup_round_id_set() {
		const {
			round_id,
		} = this.props;

		return Boolean(round_id);
	}

	get is_round_id_set() {
		const {
			round_id,
		} = this.props;

		return Boolean(round_id);
	}

	get is_team_ids_set() {
		const {
			home_team_id,
			away_team_id,
		} = this.props;

		return Boolean(home_team_id) && Boolean(away_team_id);
	}

	get select_info() {
		return {
			title: "Scoring",
			label: "Scoring",
			description: "Scoring stat that will be shown"
		};
	}

	getRoundFixtures(round_id) {
		const { league } = this.props;
		return _.get(league, ["fixture", round_id], []);
	}

	get default_matchup_url() {
		const {
			league,
			selected_round_id,
			rosters,
			user
		} = this.props;

		if (_.isEmpty(rosters) === false) {
			const round_fixtures = this.getRoundFixtures(selected_round_id);

			// If no fixtures for selected_round then return
			// round url anyway without team ids for matchup
			if (_.isEmpty(round_fixtures) === true) {
				return buildFantasyMatchupsUrlPathname(
					league.id,
					selected_round_id,
				);
			}

			const user_fixture = round_fixtures.find(fixture => {
				const home_team = _.get(rosters, fixture[0]);
				const away_team = _.get(rosters, fixture[1]);

				return _.get(home_team, "user_id") === user.id ||
					_.get(away_team, "user_id") === user.id;
			});

			if (Array.isArray(user_fixture)) {
				const home_team_id = user_fixture[0];
				const away_team_id = user_fixture[1];

				return buildFantasyMatchupsUrlPathname(
					league.id,
					selected_round_id,
					home_team_id,
					away_team_id
				);
			}
		}

		return null;
	}

	get default_round_matchup_url() {
		const {
			league,
			round_id,
			rosters,
			user
		} = this.props;

		if (_.isEmpty(rosters) === false) {
			const round_fixtures = this.getRoundFixtures(round_id);
			const user_fixture = round_fixtures.find(fixture => {
				const home_team = _.get(rosters, fixture[0]);
				const away_team = _.get(rosters, fixture[1]);

				return _.get(home_team, "user_id") === user.id ||
					_.get(away_team, "user_id") === user.id;
			});

			if (Array.isArray(user_fixture)) {
				const home_team_id = user_fixture[0];
				const away_team_id = user_fixture[1];

				return buildFantasyMatchupsUrlPathname(
					league.id,
					round_id,
					home_team_id,
					away_team_id
				);
			}
		}

		return null;
	}

	onCompressedViewClick(open: boolean) {
		
		this.setState({
			is_compressed: open
		});
		const storageItem = String(open);
		localStorage.setItem("compressed_view", storageItem);
	}

	setActiveStatsType({ currentTarget }) {
		this.setState({
			active_stats_type: currentTarget.value
		});
	}

	changeRound(round_id) {
		const { history, league } = this.props;

		history.push({
			pathname: buildFantasyMatchupsUrlPathname(
				league.id,
				round_id
			)
		});
		this.fetchLeagueRosters(round_id);
	}

	get next_fixture() {
		const { round_id, home_team_id, away_team_id  } = this.props;
		const round_fixtures = this.getRoundFixtures(round_id);

		const current_fixture_index = round_fixtures.findIndex(fixture => {
			return fixture[0] === home_team_id &&
				fixture[1] === away_team_id;
		});
		const next_fixture = round_fixtures[current_fixture_index + 1];

		if (next_fixture) {
			return [
				next_fixture[0],
				next_fixture[1]
			];
		}

		return null;
	}

	get prev_fixture() {
		const { round_id, home_team_id, away_team_id  } = this.props;
		const round_fixtures = this.getRoundFixtures(round_id);

		const current_fixture_index = round_fixtures.findIndex(fixture => {
			return fixture[0] === home_team_id &&
				fixture[1] === away_team_id;
		});
		const prev_fixture = round_fixtures[current_fixture_index - 1];

		if (prev_fixture) {
			return [
				prev_fixture[0],
				prev_fixture[1]
			];
		}

		return null;
	}

	get last_completed_round_id() {
		const lastCompleteRoundArr = this.props.rounds.filter(round => round.status === "complete");
		const length = lastCompleteRoundArr.length;
		const lastRoundComplete = lastCompleteRoundArr[length - 1];
		const lastRoundCompleteId = _.get(lastRoundComplete, 'id');
		return lastRoundCompleteId;
	}

	gotoPrevFixture() {
		const { history, league, round_id } = this.props;

		const prev_fixture = this.prev_fixture;

		if (prev_fixture) {
			history.push({
				pathname: buildFantasyMatchupsUrlPathname(
					league.id,
					round_id,
					prev_fixture[0],
					prev_fixture[1]
				)
			});
		}
	}

	gotoNextFixture() {
		const { history, league, round_id } = this.props;

		const next_fixture = this.next_fixture;

		if (next_fixture) {
			history.push({
				pathname: buildFantasyMatchupsUrlPathname(
					league.id,
					round_id,
					next_fixture[0],
					next_fixture[1]
				)
			});
		}
	}

	get redirect_to_default_matchup_url() {
		return !this.is_team_ids_set && !this.is_round_id_set && this.default_matchup_url;
	}

	get redirect_to_default_round_matchup_url() {
		return !this.is_team_ids_set && this.is_round_id_set && this.default_round_matchup_url;
	}

	openPlayerPopup(player_id) {
		const { showModalForPlayerId } = this.props;
		showModalForPlayerId(player_id);
	}

	get is_league_bye_round() {
		const {
			league
		} = this.props;
		const round = this.round;

		if (league && round) {
			return Boolean(league.bye_rounds) ? false : round.is_bye;
		}

		return false;
	}

	renderMatchupSection() {
		const {
			league,
			home_team,
			away_team,
			players,
			coach_players_by_id,
			positions,
			round_id,
			has_assistant_coach,
			actual_round,
			rounds,
			user,
			custom_stats,
			
		} = this.props;
		const { active_stats_type, is_compressed } = this.state;
		if(_.isEmpty(this.round)){
			return null;
		}
		
		return (
			
			<React.Fragment>
				<Exist when={this.isMatchupShareAvailable()}>
					<CenterDiv>

					
						<ButtonPrimary
							aria-label={`Click Draft Matchup Dynamic Share`}
							id={"draft_matchup_dynamic_share"}
							name={`Click Draft Matchup Dynamic Share`}
							onClick={() => this.handleMatchupToggle(true)}
						>
							Share Most Recent Matchup
						</ButtonPrimary>
					</CenterDiv>
				</Exist>

				<SummaryPanel
					league={league}
					home_team={this.mergeTeamData(home_team, true)}
					home_team_proj_score={
						this.getTeamPlayerProjScores(home_team)
					}
					away_team={this.mergeTeamData(away_team, false)}
					away_team_proj_score={
						this.getTeamPlayerProjScores(away_team)
					}
					has_assistant_coach={has_assistant_coach}
					round={this.round}
					round_id={round_id}
					onClickNext={
						this.next_fixture && this.gotoNextFixture
					}
					onClickPrev={
						this.prev_fixture && this.gotoPrevFixture
					}
					players_by_id={players}
					coach_players_by_id={coach_players_by_id}
					actual_round={actual_round}
					user_id={user.id}
				/>
				
				
				<DraftTableHeader
					title={`${_.get(home_team, "name", "")} vs ${_.get(away_team, "name", "")}`}
					active_stats_type={active_stats_type}
					onSelectChange={this.setActiveStatsType}
					is_compressed={is_compressed}
					onCompressedViewClick={this.onCompressedViewClick}
					selectOptions={field_stats}
					
					select_info={this.select_info}
				/>
			
				<TeamsComparisonTable
					home_team={home_team}
					away_team={away_team}
					players={players}
					positions={positions}
					round={this.round}
					has_assistant_coach={has_assistant_coach}
					league={league}
					actual_round={actual_round}
					start_compressed={isMobile()}
					field_stats={field_stats}
					active_stats_type={active_stats_type}
					setActiveStatsType={this.setActiveStatsType}
					rounds={rounds}
					openPlayerPopup={this.openPlayerPopup}
					is_compressed={is_compressed}
					custom_stats={custom_stats}
					user_id={user.id}
					round_id={round_id}
				/>
				
				
			</React.Fragment>
						
		);
		
				
	}

	render() {
		const {
			league,
			league_rounds,
			round_id,
			user_team
		} = this.props;
		
		const final_aliases = getLeagueRoundAliases(league, league_rounds);
		const userTeamId = _.get(user_team, 'id');

		if (this.redirect_to_default_matchup_url) {
			return <Redirect to={this.default_matchup_url} />;
		}
		else if (this.redirect_to_default_round_matchup_url) {
			return <Redirect to={this.default_round_matchup_url} />;
		}

		return (
			<React.Fragment>
				<JsonFetcher
					fetch={["rounds", "players", "squads", "coach_players"]}
					interval={60000} /* Fetch new data once every 60s */
				/>

				<LeagueGameBar matchup_page no_fetch={true} />

				<AdsContainer>
					<Ad />
				</AdsContainer>

				<MatchCentreBar />

				<Exist when={this.state.matchup_share_open}>
					<MatchupShare
						is_classic={false}
						league_id={league.id}
						round_id={this.last_completed_round_id}
						team_id={userTeamId}
						league_name={league.name}
						handleCloseClick={this.setLocalStorageMatchup}
						imgUrl={this.imageShareUrl}
					/>
				</Exist>

				<PageContentWrapper>
					
					{this.state.preloader ? <Preloader />
						:<TwoColumnLayout>
							<MainWrapper>
								<TitleRoundWrapper>
									<PageTitle>
										Fantasy match ups for {league.name}
									</PageTitle>
									<RoundSelector
										selected={round_id}
										rounds={league_rounds}
										handleClick={this.changeRound}
										round_aliases={final_aliases}
									/>
								</TitleRoundWrapper>	
								<Exist when={["scheduled", "active"].includes(league.draft_status)}>
									<MatchupHoldingMessage league_id={league.id} />
								</Exist>
								<Exist when={league.draft_status === "complete"}>
									{this.is_league_bye_round ?
										<ByeRoundMessage 
											league_id={league.id} 
											is_classic={false} 
										/> :
										this.renderMatchupSection()
									}
								</Exist>
								
							</MainWrapper>
							<StandardSidebar />
						</TwoColumnLayout>
					}
				</PageContentWrapper>
				<Footer />
			</React.Fragment>
		);
	}
}
const mapStateToProps = (state: TRootStore, props) => {
	const {
		match: { params: { league_id, round_id, home_team_id, away_team_id } }
	} = props;

	const has_assistant_coach = Boolean(selectors.getUser(state).assistant_coach);
	const rounds = selectors.rounds.getRoundsWithMatches(state);
	const user = selectors.getUser(state);
	const teams = Object.keys(state.leagues.teams_by_id)
		.map(team_id => state.leagues.teams_by_id[team_id]);
	const league = selectors.leagueDraft.getLeague(state, props);
	const team = teams.find(team => team.user_id === user.id);
	const is_commissioner = league.commissioner === user.id;
	const team_loading_or_commish = !_.isEmpty(team) || is_commissioner;
	
	const loading_data = [
		!_.isEmpty(state.leagues.by_id),
		!state.leagues.ladder.is_pending,
		!state.players.is_pending,
		!state.rounds.is_pending,
		!state.squads.is_pending,
		!state.players.custom_stats_loading,
		!has_assistant_coach || !_.isEmpty(state.players.coach_by_id),
		!_.isEmpty(state.leagues.teams_by_id),
		!_.isEmpty(rounds),
		team_loading_or_commish
		// !_.isEmpty(team)
	];

	const homeTeamWithRoster = {
		...state.leagues.teams_by_id[home_team_id],
		lineup: state.leagues.rosters.rosters_by_id[home_team_id]
	};
	const awayTeamWithRoster = {
		...state.leagues.teams_by_id[away_team_id],
		lineup: state.leagues.rosters.rosters_by_id[away_team_id]
	};
	

	return {
		selected_league_id: league_id && parseInt(league_id, 10),
		round_id: round_id && parseInt(round_id, 10),
		home_team_id: home_team_id && parseInt(home_team_id, 10),
		away_team_id: away_team_id && parseInt(away_team_id, 10),
		home_team: homeTeamWithRoster,
		away_team: awayTeamWithRoster,
		home_team_roster: state.leagues.rosters.rosters_by_id[home_team_id],
		away_team_roster: state.leagues.rosters.rosters_by_id[away_team_id],
		teams_by_id: state.leagues.teams_by_id,
		ladder_by_id: selectors.leagues.ladder.getById(state),
		players: selectors.players.getExtendedPlayersById(state, props),
		coach_players_by_id: selectors.getCoachPlayersById(state),
		positions: selectors.positions.getPositionsArray(state),
		playerStatuses: state.playerStatuses,
		league,
		user: selectors.getUser(state),
		has_assistant_coach,
		league_rounds: selectors.complex.leagues.getLeagueRoundsWithMatches(state, props),
		rosters: selectors.leagues.rosters.getById(state),
		actual_round: selectors.rounds.getActualRound(state),
		data_has_loaded: _.every(loading_data),
		rounds,
		custom_stats: state.players.all_custom_stats,
		user_team: team
	};
};

const mapDispatchToProps = {
	fetchLeague: actions.leagueDraft.showDraftLeague,
	fetchLeagueRosters: actions.leagueDraft.fetchLeagueRosters,
	fetchLadder: actions.leagueDraft.ladderDraftLeague,
	fetchTeam: actions.teamsDraft.fetchTeam,
	fetchFavourites: actions.fetchFavourites,
	fetchPlayersMatches: actions.fetchPlayersMatches,
	fetchAllCustomStats: actions.fetchAllCustomStats,
	fetchAllCoachCustomStats: actions.fetchAllCoachCustomStats,
	showModalForPlayerId: actions.showModalForPlayerId,
	
};
export const MatchUp = compose(
	withDraftPlayerModal,
	withRoundSelector,
	withCoachesBox,
	connect(
		mapStateToProps,
		mapDispatchToProps
	),
)(MatchUpComponent);

export default MatchUp;