// @flow
import React from "react";
import SvgIconContainer from "../SvgIconContainer";

export default (
	{
		unit = "em",
		...rest
	}: Object = {}) => (
	<SvgIconContainer viewBox="0 0 14 14" {...rest} unit={unit}>
		<title>No Drop</title>
		<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g transform="translate(-904.000000, -843.000000)" fill="#CAD2D8" fillRule="nonzero">
				<g transform="translate(20.000000, 610.000000)">
					<g transform="translate(0.000000, 110.000000)">
						<g transform="translate(0.000000, 90.000000)">
							<g transform="translate(876.000000, 25.000000)">
								<g transform="translate(3.000000, 3.000000)">
									<path d="M12,5 C15.8795181,5 19,8.12048193 19,
									12 C19,15.8795181 15.8795181,19 12,19 C8.12048193,
									19 5,15.8795181 5,12 C5,8.12048193 8.12048193,
									5 12,5 Z M8.70588235,7.72794118 L16.2720588,
									15.2941176 C16.9372172,14.4210973 17.3529412,
									13.2986425 17.3529412,12.0514706 C17.3529412,
									9.09983032 14.9417421,6.64705882 11.9485294,
									6.64705882 C10.7429299,6.64705882 9.62047511,
									7.06278281 8.70588235,7.72794118 Z M15.2941176,
									16.2720588 L7.72794118,8.70588235 C7.06278281,
									9.62047511 6.64705882,10.7429299 6.64705882,
									11.9485294 C6.64705882,14.9417421 9.09983032,
									17.3529412 12.0514706,17.3529412 C13.2986425,
									17.3529412 14.4210973,16.9372172 15.2941176,
									16.2720588 Z"/>
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
		</g>
	</SvgIconContainer>
);