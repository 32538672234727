// @flow
import * as React from "react";
import styled from "styled-components";
import _ from "lodash";

import { below } from "../../assets/css/media";
import { 
	getTeamProjectedScore, 
	getTeamScoreFromLadder, 
	getLiveDraftTeamScore } from "../../helpers/team";
import { scoreflowToForm } from "../../helpers/league";
import PageSubTitle from "../PageSubTitle";
import FinalsBracket from "../FinalsBracket/index";
import ByeRoundMessage from "../LeagueByeRoundMessage";
import { 
	TPLeague, 
	TLadderTeam, 
	TRound, 
	TPlayersById, 
	TCoachPlayerStatsById,
	TTeam
} from "../../modules/types";

import NoFixtureRoundMessage from "./NoFixtureRoundMessage";
import Fixture from "./Fixture";
import EliminatedTeam from "./EliminatedTeam";



const FixtureStyled = styled(Fixture)`
	margin-bottom: ${({ compact }) => compact ? "5px" : "10px"};
	${below.tablet`
		margin-bottom: 5px;
	`}
	.fixture-team-avatar{
		${below.tablet`
			display: none;
		`}
	}
`;

const FixturesWrapper = styled.div`
	margin-top: 5px;
	margin-bottom: 20px;

	${below.tablet`
		margin: 5px 0 10px 0;
	`}
`;

export type TLeagueFinals = {
	[round_id: number]: string
};

type Props = {
	ladder_teams_by_id: {
		[teamID: string|number]: TLadderTeam
	},
	league: TPLeague,
	rounds_with_matches: Array<Object>,
	has_assistant_coach: boolean,
	coach_players_by_id: TCoachPlayerStatsById,
	selected_round_id?: number,
	is_league_bye_round: boolean,
	league_current_round_fixtures: Array<any>,
	compact?: boolean,
	actual_round: TRound,
	players_by_id: TPlayersById,
	is_classic: boolean,
	is_league_no_fixtures: boolean,
	finals?: TLeagueFinals,
	exclude_top_heading: boolean,
	league_fixture?: boolean,
	ladder_teams_ordered: Object,
  active_view?: string,
  setActiveViewToBracket?: Function,
  user_id: number,
	overview_page?: boolean
}

export class Fixtures extends React.Component<Props> {
	static defaultProps = {
		is_league_no_fixtures: false,
		is_classic: false,
		exclude_top_heading: false,
	};
	constructor(props: Props) {
		super(props);

		_.bindAll(this, [
			"renderEliminatedTeam",
		]);
	}
	get round() {
		const { rounds_with_matches, selected_round_id } = this.props;

		return _.find(rounds_with_matches, { id: selected_round_id });
	}
	get season_finished() {
		const { league } = this.props;
		const round = this.round;

		const status = _.get(round, "status");
		const id = _.get(round, "id", 0);

		const end_round = _.get(league, "end_round", 0);

		return id > end_round || (status === "complete" && id === end_round);
	}
	get is_grand_final() {
		const { league } = this.props;
		const id = _.get(this.round, "id", 0);
		const end_round = _.get(league, "end_round", 0);

		return id >= end_round;
	}
	get is_finals() {
		const { selected_round_id, finals } = this.props;
		return !_.isEmpty(_.get(finals, selected_round_id));
	}
	get fixture_teams() {
		const { ladder_teams_by_id, league_current_round_fixtures } = this.props;
		return _.map(league_current_round_fixtures, ([home, away]) => {
			return {
				home: _.get(ladder_teams_by_id, home),
				away: _.get(ladder_teams_by_id, away),
			};
		});
	}
	get finals_teams() {
		const { league } = this.props;
		const format = _.get(league, "finals_format", 0);

		return _(this.fixture_teams)
			.filter(({ home }) => _.get(home, "rank") <= format)
			.sort((a, b) => _.get(a.home, "rank") - _.get(b.home, "rank"))
			.map(({ home, away }) => [ _.get(home, "id", 0), _.get(away, "id", 0) ])
			.value();
	}
	get consolation_teams() {
		const { league } = this.props;
		const format = _.get(league, "finals_format", 0);

		return _(this.fixture_teams)
			.filter(({ home }) => _.get(home, "rank") > format)
			.map(({ home, away }) => [ _.get(home, "id", 0), _.get(away, "id", 0) ])
			.value();
	}
	get uneliminated_teams() {
		const { league_current_round_fixtures, league, 
			 ladder_teams_ordered, actual_round } = this.props;
		const actual_round_id = _.get(actual_round, "id", 0);

		const fixture = _.get(league, "fixture", {});
		
		if(league_current_round_fixtures.length === 0){
			const current_finalists = ladder_teams_ordered.slice(0, (league.finals_format));
			const arr = current_finalists.map(team => team.id);
			return arr;
		}

		const teams = _(fixture)
			.filter((_, round) => round >= actual_round_id)
			.reduce(
				(all, round_matches) => [...all, ..._.flatten(round_matches)],
				_.flatten(league_current_round_fixtures)
			);
		return _.uniq(teams);
	}
	get eliminated_teams() {
		const { ladder_teams_by_id, league_current_round_fixtures } = this.props;
		const finals_teams = this.uneliminated_teams;
		if(league_current_round_fixtures.length === 0){
			return _(ladder_teams_by_id)
				.map(t => t)
				.filter(({ id }) => !_.includes(finals_teams, id))
				.orderBy("rank_selected_round", "asc")
				.value();
		}
		return _(ladder_teams_by_id)
			.map(t => t)
			.filter(({ id }) => !_.includes(finals_teams, id))
			.orderBy("rank", "asc")
			.value();
	}
	checkLeagueWin(team_id: number) {
		const { ladder_teams_by_id, league_current_round_fixtures, league } = this.props;

		if (!this.is_finals) {
			return {};
		}

		const team = _.get(ladder_teams_by_id, team_id);
		const team_rank = _.get(team, "rank", 0);
		const is_spoon = team_rank === _.get(league, "size");
		const match = _.find(league_current_round_fixtures, match => _.includes(match, team_id));
		const format = _.get(league, "finals_format", 0);

		if (!this.season_finished || _.isEmpty(match)) {
			return {
				is_spoon
			};
		}

		const opp_id = match[0] === team_id ? match[1] : match[0];
		const opp_team = _.get(ladder_teams_by_id, opp_id);

		const is_consolation_match = team_rank > format;
		const is_winner = this.getWinner(team, opp_team);

		return {
			is_spoon,
			is_consolation: is_consolation_match && is_winner,
			is_premier: !is_consolation_match && is_winner,
		};
	}

	getWinner(team: TTeam, opp: Object) {
		const { selected_round_id } = this.props;
		const team_rank = _.get(team, "rank", 0);
		const opp_rank = _.get(opp, "rank", 0);

		const team_score = _.get(team, ["scoreflow", selected_round_id], 0);
		const opp_score = _.get(opp, ["scoreflow", selected_round_id], 0);

		if (team_score === opp_score) {
			return team_rank > opp_rank;
		}
		return team_score > opp_score;
	}
	getTeamInfo(id: number) {
		const {
			ladder_teams_by_id,
			league,
			selected_round_id = 1,
			actual_round = {},
			players_by_id,
			is_classic,
			// user_id
		} = this.props;
		const team = _.get(ladder_teams_by_id, id);
		const round = this.round;
		if (_.isEmpty(team) || _.isEmpty(round)) {
			return undefined;
		}


		// const defaultScoreValue = round.status !== 'scheduled' ? 0 : '--';
		const custom_scoring_enabled = _.get(league, "custom_scoring_enabled", false);
		const scoring_players = 
      _.get(team, `scoring_players[${round.id}]`, { captain: 0, players: [] });
		const is_active_match =
			selected_round_id === actual_round.id && actual_round.status === "active";

		// const points = is_classic ? user_id !== team.user_id ? 
		// 	_.get(team, `scoreflow[${round.id}]`, defaultScoreValue) : getTeamScoreFromLadder({
		// 		round,
		// 		team,
		// 		players_by_id,
		// 		is_active_match,
		// 		is_classic
		// 	}) : getLiveDraftTeamScore({
		// 	is_active_match,
		// 	team,
		// 	round,
		// 	players_by_id,
		// 	scoring_players,
		// 	custom_scoring_enabled,
		// });
		const points = is_classic ? getTeamScoreFromLadder({
			round,
			team,
			players_by_id,
			is_active_match,
			is_classic
		}) : getLiveDraftTeamScore({
			is_active_match,
			team,
			round,
			players_by_id,
			scoring_players,
			custom_scoring_enabled,
		});


		// get WLD for last 5 last 5
		const wld = scoreflowToForm(team, selected_round_id, actual_round);

		const lineup_project_score =
			getTeamProjectedScore(team.lineup, players_by_id, league, round, is_classic);
		return {
			id: team.id,
			name: team.name,
			firstname: team.firstname,
			lastname: team.lastname,
			selected_round_rank: 0,
			points: points,
			projected_points: lineup_project_score,
			lineup: team.lineup,
			rank: team.rank,
			user_id: team.user_id,
			avatar_version: team.avatar_version,
			record_last_five: wld,
			scoreflow: team.scoreflow,
			...this.checkLeagueWin(id)
		};
	}
	renderTeamsFixture(team_ids: [number, number], is_finals: boolean) {
		const {
			league,
			has_assistant_coach,
			compact,
			is_classic,
			overview_page
		} = this.props;
		const round = this.round;
		
		const fixture = {
			home: this.getTeamInfo(_.get(team_ids, 0, 0)),
			away: this.getTeamInfo(_.get(team_ids, 1, 0)),
		};

		return (
			<FixtureStyled
				key={team_ids.toString()}
				compact={compact}
				shadow={!compact}
				fixture={fixture}
				league={league}
				round={round}
				has_assistant_coach={has_assistant_coach}
				game_type={is_classic ? "classic" : "draft"}
				show_rank={is_finals}
				league_complete={this.season_finished}
				league_fixture={this.props.league_fixture}
				is_overview={overview_page}
			/>
		);
	}

	renderRegularSeason() {
		const { 
			league, 
			is_league_bye_round, 
			league_current_round_fixtures, 
			is_league_no_fixtures,
			compact,
			is_classic,
			active_view,
			finals,
			ladder_teams_by_id,
			ladder_teams_ordered,
			selected_round_id,
			rounds_with_matches,
			has_assistant_coach,
			actual_round,


		} = this.props;
		if(active_view === "bracket" && league.finals_format !== 2){
			return(
				<FinalsBracket 
					ladder_teams_by_id={ladder_teams_by_id}
					league_current_round_fixtures={league_current_round_fixtures}
					finals={finals}
					ladder_teams_ordered={ladder_teams_ordered}
					league={league}
					selected_round_id={selected_round_id}
					rounds_with_matches={rounds_with_matches}
					has_assistant_coach={has_assistant_coach}
					actual_round={actual_round}
				/>
			);
			
		}
		return (
			<FixturesWrapper compact={compact}>
				{is_league_no_fixtures ?
					<NoFixtureRoundMessage league_id={league.id} /> :
					null
				}

				{is_league_bye_round 
					? <ByeRoundMessage league_id={league.id} is_classic={is_classic} /> 
					: league_current_round_fixtures.map(
						fixture_team_ids => this.renderTeamsFixture(fixture_team_ids, false)
					)
				}
			</FixturesWrapper>
		);
	}

	renderFinalsFixtures() {
		const { compact, league_current_round_fixtures } = this.props;

		if(_.isEmpty(league_current_round_fixtures)){
			return this.renderNoFixtures();
		}
		return <FixturesWrapper compact={compact}>
			{_.map(
				this.finals_teams,
				fixture_team_ids => this.renderTeamsFixture(fixture_team_ids, true)
			)}
		</FixturesWrapper>;
	}

	renderConsolationFixtures() {
		const { 
			compact,
			ladder_teams_by_id,
			league_current_round_fixtures,
			finals,
			ladder_teams_ordered,
			league,
			selected_round_id,
			rounds_with_matches,
			has_assistant_coach,
			actual_round,
			active_view
		 } = this.props;

		const consolation_teams = this.consolation_teams;		

		if (_.isEmpty(consolation_teams)) {
			return null;
		}
		if(active_view === "bracket" && league.finals_format !== 2){
			return(
				<React.Fragment>
					{ this.renderConsolationHeading() }
					<FinalsBracket 
						ladder_teams_by_id={ladder_teams_by_id}
						league_current_round_fixtures={league_current_round_fixtures}
						finals={finals}
						ladder_teams_ordered={ladder_teams_ordered}
						league={league}
						selected_round_id={selected_round_id}
						rounds_with_matches={rounds_with_matches}
						has_assistant_coach={has_assistant_coach}
						actual_round={actual_round}
						consolation={true}
					/>
				</React.Fragment>
			);
		} 

		return <React.Fragment>
			{ this.renderConsolationHeading() }
			<FixturesWrapper compact={compact}>
				{_.map(
					consolation_teams,
					fixture_team_ids => this.renderTeamsFixture(fixture_team_ids, true)
				)}
			</FixturesWrapper>
		</React.Fragment>;
	}

	renderEliminatedTeam(team: TTeam) {
		const {
			league,
			has_assistant_coach,
			selected_round_id = 1,
			compact,
			actual_round = {},
			players_by_id,
			is_classic,
			ladder_teams_ordered,
			overview_page
		} = this.props;
		const round = this.round;


		const selected_team = _.find(ladder_teams_ordered, { "id" : team.id });
		const selected_round_rank = _.get(selected_team, "rank_selected_round", 0);


		const is_active_match =
			selected_round_id === actual_round.id && actual_round.status === "active";

		const points = getTeamScoreFromLadder({
			round,
			team,
			players_by_id,
			is_active_match,
			is_classic,
			default_value: 0
		});

		// get WLD for last 5 last 5
		const wld = scoreflowToForm(team, selected_round_id, actual_round);

		const project_score =
			getTeamProjectedScore(team.lineup, players_by_id, league, round, is_classic);

		const team_obj = {
			id: team.id,
			name: team.name,
			firstname: team.firstname,
			lastname: team.lastname,
			points: points,
			projected_points: project_score,
			lineup: team.lineup,
			rank: team.rank,
			user_id: team.user_id,
			avatar_version: team.avatar_version,
			record_last_five: wld,
			selected_round_rank: selected_round_rank,
			scoreflow: {},
			...this.checkLeagueWin(team.id)
		};
		const isFinalByeTeam = _.get(team, "final_bye_team", false);
		
		return <EliminatedTeam 
			key={team.id}
			compact={compact}
			shadow={!compact}
			team={team_obj}
			round={round}
			final_bye_team={isFinalByeTeam}
			has_assistant_coach={has_assistant_coach}
			show_rank
			league_complete={this.season_finished}
			game_type={is_classic ? "classic" : "draft"}
			league_id={_.get(league, "id")}
			in_league_page={overview_page}
		/>;
	}

	renderEliminatedTeams() {
		const { compact } = this.props;
		const eliminated_teams = this.eliminated_teams;
	

		if (_.isEmpty(eliminated_teams)) {
			return null;
		}
		return <React.Fragment>
			<PageSubTitle>Eliminated</PageSubTitle>
			<FixturesWrapper compact={compact}>
				{_.map(
					eliminated_teams,
					this.renderEliminatedTeam
				)}
			</FixturesWrapper>
		</React.Fragment>;
	}



	renderByeTeams() {
		const { compact, ladder_teams_by_id, league_current_round_fixtures } = this.props;

		const uneliminated_teams = this.uneliminated_teams;

		const flatCurrentRoundFixtures = !_.isEmpty(league_current_round_fixtures) 
			? league_current_round_fixtures.flat()
			: [];
		let isFixturesFull = true;
		league_current_round_fixtures.forEach(matchup => {
			if(matchup.length !== 2){
				isFixturesFull= false;
			}
		});
		
		const byeTeams = uneliminated_teams.filter(team => 
			!flatCurrentRoundFixtures.includes(team)).map(teamID => {
			return{
				...ladder_teams_by_id[teamID],
				final_bye_team: true
			};
		});
		if(!isFixturesFull || _.isEmpty(league_current_round_fixtures) || _.isEmpty(byeTeams)){
			return null;
		}

		
		return <React.Fragment>
			{this.renderByeHeading()}
			<FixturesWrapper compact={compact}>
				{_.map(
					byeTeams,
					this.renderEliminatedTeam
				)}
			</FixturesWrapper>
		</React.Fragment>
		;
	}

	renderFinalsHeading() {
		const { exclude_top_heading, finals, selected_round_id } = this.props;
		if (exclude_top_heading) {
			return null;
		}
		return <PageSubTitle>{ _.get(finals, selected_round_id, "Finals")}</PageSubTitle>;
	}

	renderConsolationHeading() {
		return <PageSubTitle>
			Consolation { this.is_grand_final ? "Final" : "Finals" }
		</PageSubTitle>;
	}

	renderByeHeading() {
		const { finals, selected_round_id } = this.props;
		return <PageSubTitle>{ _.get(finals, selected_round_id)} Bye</PageSubTitle>;
	}

	renderFinals() {
		const { 
			compact, 
			is_league_bye_round, 
			league, 
			is_classic,
			ladder_teams_by_id,
			league_current_round_fixtures,
			finals,
			ladder_teams_ordered,
			selected_round_id,
			rounds_with_matches,
			has_assistant_coach,
			actual_round,
			active_view
		} = this.props;

		

		if (is_league_bye_round) {
			return <FixturesWrapper compact={compact}>
				<ByeRoundMessage league_id={league.id} is_classic={is_classic} /> 
			</FixturesWrapper>;
		}

		return <React.Fragment>
			{ this.renderFinalsHeading() }
			{active_view === "bracket" && league.finals_format !== 2 ? 	<FinalsBracket 
				ladder_teams_by_id={ladder_teams_by_id}
				league_current_round_fixtures={league_current_round_fixtures}
				finals={finals}
				ladder_teams_ordered={ladder_teams_ordered}
				league={league}
				selected_round_id={selected_round_id}
				rounds_with_matches={rounds_with_matches}
				has_assistant_coach={has_assistant_coach}
				actual_round={actual_round}
			/> :  this.renderFinalsFixtures() }
		
			{ this.renderConsolationFixtures() }
			{this.renderByeTeams()}
			{ this.renderEliminatedTeams() }
		</React.Fragment>;
	}

	renderNoFixtures() {
		const { compact, league } = this.props;
		return <FixturesWrapper compact={compact}>
			<NoFixtureRoundMessage league_id={league.id} />
		</FixturesWrapper>;
	}

	render() {
		const { 
			is_league_no_fixtures, 
			is_league_bye_round, 
			league, 
			is_classic, 
			rounds_with_matches } = this.props;
		if(_.isEmpty(rounds_with_matches)){
			return null;
		}
		if(is_league_bye_round && is_classic){
			return <ByeRoundMessage league_id={league.id} is_classic={is_classic} />;
		}
		if (is_league_no_fixtures) {
			return this.renderNoFixtures();
		}
		if (this.is_finals) {
			return this.renderFinals();
		}
		return this.renderRegularSeason();
	}
}

export default Fixtures;