// @flow
import * as React from "react";

import Ad from "../Ad";
import WidgetNews from "../WidgetNews";
import WidgetSpacer from "../Widget/WidgetSpacer";
import AdFantasyCoach from "../AdFantasyCoach";

type Props = {
	children?: React.Node,
	children_at_top?: boolean
}

export const StandardSidebar = ({ children, children_at_top = false }: Props) => (
	<aside>
		<Ad type="mrec" />
		{ children_at_top && children && <WidgetSpacer /> }
		{ children_at_top && children }
		<WidgetSpacer />
		<WidgetNews />
		{ !children_at_top && children && <WidgetSpacer /> }
		{ !children_at_top && children }
		<AdFantasyCoach type="mrec" large_screen_only />
	</aside>
);

export default StandardSidebar;