import React from "react";
import styled from "styled-components";
import type { 
	TDraftUserTeam, 
} from "../../../../modules/types";
import { 
	TopControlsWrapper, 
	TeamName, 
	TeamViewControls, 
	FieldViewControlWrapper, 
} from "../../../../components/MyTeam/TopControls";
import Oval from "../../../../components/Icons/Oval";
import CellsCondensed from "../../../../components/Icons/CellsCondensed";
import { below } from "../../../../assets/css/media";
import { FlipToggle } from "../../../../components/Field";

const ButtonGroup = styled.div`
	display: flex;
	flex-direction:  row;
	justify-items: flex-end;
	
	button {
		height: 40px;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 10px;

		svg {
			margin-top: -3px;
		}
	}

	${below.phone`
		display: none;
	`};
`;

const NamesWrapper = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;

	.flip-cards-toggle-wrapper  {
		margin-top: -6px;
	}
`;

type Props = {
	team: TDraftUserTeam,
	setFieldView: Function,
	is_list_view: string,
	is_users_team: boolean,
	flip: boolean,
	handleFlip: Function,
	is_mobile: boolean,
	is_static: boolean,
}

export class TeamTopControls extends React.PureComponent<Props> {
	static defaultProps = {
		is_users_team: true
	};
	shouldNotShowFlipToggle() {
		const { is_list_view, is_mobile, is_static } = this.props;
		return is_mobile || is_list_view || is_static;
	}
	getTeamName() {
		const { team: { name } } = this.props;
		return `${name}`;
	}

	render() {
		const { 
			is_list_view, 
			setFieldView, 
			flip, 
			handleFlip, 
		} = this.props;

		return <TopControlsWrapper>
			<NamesWrapper>
				<TeamName>
					{this.getTeamName()}
				</TeamName>
				{!this.shouldNotShowFlipToggle() && (
					<FlipToggle
						checked={flip}
						onChange={handleFlip}
					/>
				)}
			</NamesWrapper>
			{<ButtonGroup>
				<TeamViewControls>
					<FieldViewControlWrapper
						className={is_list_view ? "" : "active"}
						onClick={() => setFieldView(true)}>
						<Oval size={2.5}/>
					</FieldViewControlWrapper>
					<FieldViewControlWrapper
						className={is_list_view ? "active" : ""}
						onClick={() => setFieldView(false)}>
						<CellsCondensed size={2.5} color={"white"}/>
					</FieldViewControlWrapper>
				</TeamViewControls>
			</ButtonGroup>}
		</TopControlsWrapper>;
	}
}

export default TeamTopControls;