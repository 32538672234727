// @flow
import React from "react";
import styled from "styled-components";
import * as _ from "lodash";
import type { CoachesBoxComment, TUser, TPLeague } from "../../modules/types";
import colors from "../../assets/css/colors";
import { below } from "../../assets/css/media";
import Comment from "./Comment";
import AddComment from "./AddComment";

const LoadMore = styled.p`
	text-decoration: underline;
	color: ${colors.secondary.accentGrey};
	font-size: 14px;
	text-align: center;
	cursor: pointer;
    margin-bottom: 10px;
`;

const CommentsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	width: 100%;
	min-height: 200px;
	/* padding-bottom: 30px; */
	flex-grow: 1;
	overflow: scroll;
`;

const ContentWrapper = styled.div`
	height: 100%;
	width: 100%;
	overflow: scroll;
	padding: ${({ opened }) => opened ? "20px 30px 0" : "0"};
	box-sizing: border-box;
	flex-direction: column;
    display: flex;
`;

const ChatBoxWrapper = styled.div`
	transition: all .15s linear;
	height: ${({ opened }) => opened ? "70vh" : "0px"};
	@media (max-height: 400px) {
		height: ${({ opened }) => opened ? "50vh" : "0px"};
	}
	width: ${({ opened }) => opened ? "500px" : "0px"};
	${below.tablet`
		width: calc(100vw - 50px);
	`};
	max-width: calc(100vw - 10px);
	background-color: ${colors.form.base};
	border-radius: 0 0 5px 5px;
	position: relative;
	box-sizing: border-box;
	display: flex;
	justify-content: space-between;
	flex-direction: column;

	position: absolute;
	top: 65px;
	right: 5px;
`;

const BottomShadow = styled.div`
	height: 50px;
	width: 100%;
	position: absolute;
	bottom: 0;
	left: 0;
	background: linear-gradient(#fff0, #fffa 60%, #ffff);
	border-radius: 0 0 5px 5px;
	${({ opened }) => !opened && "display: none;"}
`;

type Props = {
	opened: boolean,
	comments: CoachesBoxComment[],
	user: TUser,
	league: TPLeague,
	sendMessage: Function,
	clearErrors: Function,
	send_error: any,
	messages_available: boolean,
	loadMoreMessages: Function,
	clearUnread: Function,
	teams_by_id: { [number]: { id: number, name: string } }
};

type State = {
	anchored: boolean
}

let scroll_timeout;

export class ChatBox extends React.Component<Props, State> {
	constructor() {
		super();

		_.bindAll(this, [
			"handleScroll",
			"scrollToBottom"
		]);
	}
	state = {
		anchored: true
	};

	componentDidMount() {
		this.scrollToBottom();
	}
	
	componentDidUpdate(prev_props: Props) {
		if (!prev_props.opened && this.props.opened) {
			this.setState({
				anchored: true
			});
			this.scrollToBottom();
		}
		if (_.size(prev_props.comments) < _.size(this.props.comments)) {
			this.scrollToBottom();
		}
	}

	componentWillUnmount() {
		if (scroll_timeout) {
			clearTimeout(scroll_timeout);
		}
	}

	messagesContainer: HTMLDivElement | null;

	messagesEnd: HTMLDivElement | null;

	scrollToBottom() {
		/* If scroll timeout was going to happen, clear it and start timeout again */
		if (scroll_timeout) {
			clearTimeout(scroll_timeout);
		}
		/* Put scroll action in timeout so that content is rendered and transition is done */
		scroll_timeout = setTimeout(() => {
			const { anchored } = this.state;
			if (this.messagesEnd  && anchored) {
				this.messagesEnd.scrollIntoView({
					behavior: "smooth", block: "end", inline: "end" 
				});
			}
		}, 200);
	}

	handleScroll({ currentTarget }: any) {
		const { scrollHeight, scrollTop, clientHeight } = currentTarget;
		const anchored = scrollHeight <= scrollTop + clientHeight;

		if (anchored) {
			this.props.clearUnread();
		}

		this.setState({
			anchored
		});
	}

	render() {
		const { 
			opened, 
			comments, 
			user, 
			league, 
			sendMessage,
			clearErrors,
			send_error,
			messages_available,
			loadMoreMessages,
			teams_by_id,
		} = this.props;
		return <ChatBoxWrapper opened={opened}>
			<ContentWrapper opened={opened}>
				{opened && <React.Fragment>
					<AddComment 
						user={user} 
						league={league} 
						sendMessage={sendMessage}
						clearErrors={clearErrors}
						send_error={send_error}
					/>
					<CommentsWrapper 
						onScroll={this.handleScroll} 
						ref={el => this.messagesContainer = el}
					>
						{messages_available && 
							<LoadMore
								onClick={loadMoreMessages}
							>
								Load More
							</LoadMore>
						}
						{_.map(comments, comment => {
							return <Comment 
								key={comment.id} 
								teams_by_id={teams_by_id} 
								comment={comment}
							/>;
						})}
						{
							_.size(comments) === 0 
							&& <p>There are no messages from members of this league yet.</p>
						}
						{/* Phantom Element to scroll to */}
						<div 
							style={{ 
								float:"left", 
								clear: "both", 
								padding: "10px"
							}}
							ref={el => this.messagesEnd = el}
						>
						</div>
					</CommentsWrapper>
				</React.Fragment>}
			</ContentWrapper>
			<BottomShadow opened={opened} />
		</ChatBoxWrapper>;
	}
}

export default ChatBox;