// @flow
import styled from "styled-components";
import { below } from "../../../assets/css/media";
import colors from "../../../assets/css/colors";
import { SaveTeamButtonComponent } from "./saveTeam";

export const TopControlsWrapper = styled.div`
	height: 60px;
	border-radius: 2px;
	background-color: ${({ is_classic }) => 
		is_classic ? colors.secondary.accentGrey : colors.primary.primary};
	font-size: 12px;
	line-height: 1.16;
	display: flex;
	justify-content: space-between;
	align-items: center;

	${SaveTeamButtonComponent} {
		${below.phone`
			display: none;
		`};
	}
	${below.phone`
		padding-left: 10px;
	`};


`;

export default TopControlsWrapper;