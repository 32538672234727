// @flow
import * as _ from "lodash";
import { currencyFormat } from "../../utils";
import {TPlayer} from "../../modules/types";
// import * as React from 'react';
// import PlayerCost from '../PlayerCost';

export const getPlayerCost = (player: TPlayer, selected_round?: Object) => {
	const cost = _.get(player, "cost");

	const round_id = _.get(selected_round, "id");
	const input = _.get(player, ["stats", "prices", round_id], cost);

	// const diff =  cost - input;
	// if (round_id && diff) {
	// 	return <React.Fragment>
	// 		{ currencyFormat({ input }) }
	// 		<PlayerCost is_diff cost={diff} />
	// 	</React.Fragment>;
	// }

	return currencyFormat({ input });
};