/* eslint max-len: 0 */
// @flow
import React from "react";
import SvgIconContainer from "../SvgIconContainer";

export default (
	{
		unit = "em",
		...rest
	}: Object = {}) => (
	<SvgIconContainer viewBox="0 0 24 24" {...rest} unit={unit}>
		<title>Live</title>
		<path d="M1,6 L23,6 C23.5522847,6 24,6.44771525 24,7 L24,17 C24,17.5522847 23.5522847,18
        23,18 L1,18 C0.44771525,18 6.76353751e-17,17.5522847 0,17 L0,7 C-6.76353751e-17,6.44771525
        0.44771525,6 1,6 Z M7.11951533,14.9922893 L7.11951533,13.9348265 L5.21608225,13.9348265
        L5.21608225,9 L4,9 L4,14.9922893 L7.11951533,14.9922893 Z M8.23474696,14.9922893
        L9.4508292,14.9922893 L9.4508292,9 L8.23474696,9 L8.23474696,14.9922893 Z M14.0733125,9
        L13.0863472,13.9348265 L12.7955449,13.9348265 L11.826204,9 L10.6101218,9
        L11.9055137,14.9922893 L13.9763784,14.9922893 L15.2893948,9 L14.0733125,9 Z
        M16.4398752,14.9922893 L20,14.9922893 L20,13.9348265 L17.6559574,13.9348265
        L17.6559574,12.5248761 L19.5065173,12.5248761 L19.5065173,11.4674133 L17.6559574,11.4674133
        L17.6559574,10.0574628 L20,10.0574628 L20,9 L16.4398752,9 L16.4398752,14.9922893 Z"
		/>
	</SvgIconContainer>
);