// @flow
import * as React from "react";
import _ from "lodash";
import { Field } from "redux-form";
import { FormRadio, FormSelect } from "../../../../../components";
import type { TPLeague, TRoundsById, TRound } from "../../../../../modules/types";
import { 
	BYE_ROUNDS,
	FINALS_LENGTHS, 
	isAllTrue, 
	isAnyTrue, 
	LEAGUE_SIZE, 
	ROUNDS_IN_AFL_SEASON 
} from "../../../../../helpers/index";
import { getLeagueFinalFormat, league_tooltips, 
	getLeagueFinalFullNames } from "../../../../../helpers";
import { ProFormSelect } from "../../../../proSubscription";

type Props = {
	is_finals: boolean,
	league_size: any,
	rounds: TRound[],
	handleProOpen: Function,
	is_pro_sub: boolean,
	finals_start_round: number,
	change: Function,
  league: TPLeague,
  actual_round: TRound,
  rounds_by_id: TRoundsById,
  from: string,
  finals_length: number,
  finalsFormat: number,
  leagueSizeLive: number,
  leaguePlayByes: boolean,
  currentPlayTimes: string,
  currentStartRound: number,
  currentPlayFinals: number,
};


export const SeasonSettings = (props: Props) => {
	const { 
		league_size, 
		rounds, 
		handleProOpen, 
		finals_start_round,
		change,
		league,
		actual_round,
		rounds_by_id,
		finals_length,
		finalsFormat,
		leagueSizeLive,
		currentPlayFinals
	} = props;


	const is_create = !Boolean(league.id); 

	const is_league_scoring =  isAllTrue([
		!is_create,
		league.status !== "scheduled"
	]);
	const is_bye_rounds_started = isAllTrue([
		!is_create,
		rounds_by_id[BYE_ROUNDS[0]].status !== "scheduled"
	]);
	const is_actual_round_active = isAllTrue([
		!is_create,
		actual_round.status === "playing"
	]);
	const rounds_ordered = Object.keys(rounds_by_id).map(id => rounds_by_id[id]);
	const finals_names = getLeagueFinalFullNames(league, rounds_ordered);
	const is_finals = (!is_create && !_.isEmpty(finals_names[actual_round.id])) 
		|| currentPlayFinals === 1;
	const is_one_week_left = ROUNDS_IN_AFL_SEASON - actual_round.id >= 1;
	const finals_start_round_options = rounds.slice(0, rounds.length);
	const BYE_ROUNDS_LENGTH = BYE_ROUNDS.length;
	const FIRST_ROUND_POST_BYES = BYE_ROUNDS[BYE_ROUNDS_LENGTH - 1] + 1;


	const isLeagueSubscribed = ["full", "lite"].includes(league.subscription);
	const handlePlayTimes = props.currentPlayTimes === "twice" ? 2 : 1;
	const leaguePlayTimesOptions = () => {
		if(isAllTrue([
			!_.isEmpty(league), 
			league.status !== "scheduled"
		]) ){
			return [{
				value: league.play_times,
				text: league.play_times.charAt(0).toUpperCase() + league.play_times.slice(1)
			}];
		}
		const league_play_time_options = [
			{
				value: "flexible",
				text: "Flexible",
			},
			{
				value: "once",
				text: "Once",
			},
			{
				value: "twice",
				text: "Twice",
			},
		];
		const startRound = Number(props.currentStartRound);
		
		const currentPlayTimes = handlePlayTimes; 
		let newRoundEnd = startRound + currentPlayTimes*(leagueSizeLive -1);
		let newRoundEndTwice = startRound + 2*(leagueSizeLive -1);
		const isByeRounds = Boolean(props.leaguePlayByes);
		
		// If not playing by rounds then the finals end round + 4 to account BYE_ROUNDS related
		if(!isByeRounds){
			newRoundEnd +=BYE_ROUNDS_LENGTH;
			newRoundEndTwice += BYE_ROUNDS_LENGTH;
		}
		// if finals were going to be in bye rounds we delay it until round 15
		if(isAllTrue([
			BYE_ROUNDS.includes(newRoundEnd),
			!isByeRounds
		])){
			newRoundEnd = FIRST_ROUND_POST_BYES;
		}
		if(isAllTrue([
			BYE_ROUNDS.includes(newRoundEndTwice),
			!isByeRounds
		])){
			newRoundEndTwice = FIRST_ROUND_POST_BYES;
		}
		// if there are enough rounds left to play a finals with current format then change 
		const isRoundsRemainingAfterFinals = isAllTrue([
			(ROUNDS_IN_AFL_SEASON - newRoundEnd) >= FINALS_LENGTHS[finalsFormat],
			Boolean(currentPlayFinals)
		]);
		const isRoundsRemainingTwice = 
			(ROUNDS_IN_AFL_SEASON - newRoundEndTwice) >= FINALS_LENGTHS[finalsFormat];
		if(	isAnyTrue([
			(isAllTrue([!isRoundsRemainingTwice, Boolean(currentPlayFinals)])),
			leagueSizeLive > 10,
			newRoundEnd > ROUNDS_IN_AFL_SEASON,
			( isAllTrue([
				!isRoundsRemainingAfterFinals,
				Boolean(currentPlayFinals)
			]))
		])
		){
			league_play_time_options.pop();
			if( isAllTrue([
				props.currentPlayTimes !== "once",
				newRoundEnd > ROUNDS_IN_AFL_SEASON
			])){
				league_play_time_options.pop();
			}
			return league_play_time_options;
		}
		return league_play_time_options;

	};
	

	const finalsStartRound = (finals_length: number) => {
		if(!props.leaguePlayByes && BYE_ROUNDS.includes(Number(props.finals_start_round))){
			const firstAfterByeRound = BYE_ROUNDS[BYE_ROUNDS.length - 1] + 1; 
			change("finals_start_round", firstAfterByeRound);
		}
		const rounds_remaining_length = finals_start_round_options.length;
		let limited_finals_start_round_options = finals_start_round_options;
		if(finals_length > rounds_remaining_length){
			const length_to_cut = rounds_remaining_length - finals_length;
			for(let i = 0; i <= length_to_cut; i++){
				limited_finals_start_round_options.splice(-1,1);
			}
		} 

		return limited_finals_start_round_options;
	};
	/**
	 * 
	 * 8 - 4
	 * 6 - 3,4
	 * 5 - 4
	 * 4 - 2,3
	 * 2 - 1
	 * 
	 */
	const SUPPORTS_1_WEEK_FINALS = [2];
	const SUPPORTS_2_WEEK_FINALS = [4];
	const SUPPORTS_3_WEEK_FINALS = [4,6];
	const SUPPORTS_4_WEEK_FINALS = [8,6,5];
	const finalsWeeks = (finals_start_round:number, format: number) => [
		{ value: 1, text: "1 week", disabled:!SUPPORTS_1_WEEK_FINALS.includes(format) },
		{ 	
			value: 2, 
			text: "2 weeks", 
			disabled:(finals_start_round > (ROUNDS_IN_AFL_SEASON - 1) 
			|| !SUPPORTS_2_WEEK_FINALS.includes(format)) 
		},
		{ value: 3, text: "3 weeks", 
			disabled:finals_start_round > (ROUNDS_IN_AFL_SEASON - 2) 
			|| !SUPPORTS_3_WEEK_FINALS.includes(format) 
		},
		{ value: 4, text: "4 weeks", disabled:
			finals_start_round > (ROUNDS_IN_AFL_SEASON - 3 )
			|| !SUPPORTS_4_WEEK_FINALS.includes(format) 
		}
	];

	const handleFinalsStartRoundChange = event => {
		change("finals_start_round", event.target.value);
		const roundNo = Number(event.target.value);
		if(roundNo === ROUNDS_IN_AFL_SEASON) {
			change("finals_length", 1);
		}
		else if(roundNo === ROUNDS_IN_AFL_SEASON-1) {
			change("finals_length", 2);
		}
		else if(roundNo === ROUNDS_IN_AFL_SEASON-2) {
			change("finals_length", 3);
		}
	};

	const handlePlayTimesCaseTwice = event => {
		if(leagueSizeLive >10){
			return;
		}
		const startRound = Number(props.currentStartRound);
		let newRoundEnd = startRound + 2*(leagueSizeLive -1);
		const isByeRounds = Boolean(props.leaguePlayByes);
		// If not playing by rounds then the finals end round + 3 to account
		if(!isByeRounds){
			newRoundEnd +=BYE_ROUNDS_LENGTH;
		}
		// if It is now longer than available rounds then dont change anything
		if(newRoundEnd > ROUNDS_IN_AFL_SEASON){
			return;
		}
		// if finals were going to be in bye rounds we delay it until round 15
		if(isAllTrue([
			BYE_ROUNDS.includes(newRoundEnd),
			!isByeRounds
		])){
			newRoundEnd = FIRST_ROUND_POST_BYES;
		}
		// if there are enough rounds left to play a finals with current format then change 
		// const isRoundsRemainingAfterFinals = (23 - newRoundEnd) >= 
		// FINALS_LENGTHS[finalsFormat];
		if(newRoundEnd + 1 <= ROUNDS_IN_AFL_SEASON){
			change("finals_start_round", newRoundEnd + 1);
		}
		
		change("play_times", event.target.value);
	};

	const handleSelectChange = (event,name) => {
		if(name === "finals_start_round") {
			handleFinalsStartRoundChange(event);
		}
		if(name === "finals_format") {
			change("finals_format", event.target.value);
			const formatNo = Number(event.target.value);
			change("finals_start_round", (ROUNDS_IN_AFL_SEASON + 1) - FINALS_LENGTHS[formatNo]);
			change("finals_length", FINALS_LENGTHS[formatNo]);
		}
		if(isAllTrue([
			name==="play_times",
			event.target.value === "flexible"
		])){
			change("finals_start_round", (ROUNDS_IN_AFL_SEASON + 1) - FINALS_LENGTHS[finalsFormat]);
			change("play_times", event.target.value);
		}
		if(isAllTrue([
			name==="play_times",
			event.target.value === "once"
		])){
			const startRound = Number(props.currentStartRound);
			
			let newRoundEnd = startRound + (leagueSizeLive -1);
			const isByeRounds = Boolean(league.bye_rounds);
			if(isAllTrue([
				BYE_ROUNDS.includes(newRoundEnd),
				!isByeRounds
			])){
				newRoundEnd = FIRST_ROUND_POST_BYES;
			}
			change("finals_start_round", newRoundEnd);
			change("play_times", event.target.value);
		}
		if(isAllTrue([
			name==="play_times",
			event.target.value === "twice"
		]) ){
			handlePlayTimesCaseTwice(event);
		}
	};
	const startRoundSelectChange = (event,name) => {
		if(name === "start_round" && Number(event.target.value) === ROUNDS_IN_AFL_SEASON){
			change("finals", 0);
			change(name, event.target.value);
		}
		else{
			change(name, event.target.value);
		}
	};

	const handleRoundOptions = () => {
		if(league.status && league.status !== "scheduled"){
			return [{
				value: league.start_round,
				text: `Round ${league.start_round}`
			}];
		}
		return rounds;
	};
	return (
		<div>
			<Field
				label='League size'
				name='size'
				options={LEAGUE_SIZE}
				component={FormSelect}
				tooltip={league_tooltips.size}
				is_disabled={is_create ? false : (league.status !== "scheduled" 
        || league.draft_status !== "scheduled")}
			/>
		
			<Field
				label='League start round'
				name='start_round'
				options={handleRoundOptions()}
				component={FormSelect}
				tooltip={league_tooltips.start_round}
				is_disabled={is_league_scoring}
				handleSelectChange={startRoundSelectChange}
			/>
			<Field
				label='Play each other'
				name='play_times'
				options={leaguePlayTimesOptions()}
				component={FormSelect}
				tooltip={league_tooltips.play_times}
				is_disabled={is_league_scoring}
				handleSelectChange={handleSelectChange}
			/>
			<FormRadio
				label='Play bye rounds'
				name='bye_rounds'
				elements={[
					{
						value: 1,
						text: "On",
					},
					{
						value: 0,
						text: "Off",
					}
				]}
				tooltip={league_tooltips.bye_rounds}
				is_disabled={is_bye_rounds_started}
				
			/>
			<FormRadio
				label='Ladder Tiebreaker'
				name='rank_by'
				is_string_value={true}
				elements={[
					{
						value: "percentage",
						text: "Percentage",
					},
					{
						value: "points_for",
						text: "Points For",
					}
				]}
				tooltip={league_tooltips.ladder_tiebreaker}
				is_disabled={is_league_scoring}
			/>
			<Field
				label='Lockout'
				name='lockout'
				options={[
					{
						value:"0",
						text: "Standard Lockout",
					},
					{
						value:"1",
						text: "Rolling Lockout",
					},
					{
						value:"2",
						text: "Saturday Lockout",
					}
				]}
				component={FormSelect}
				tooltip={league_tooltips.lockout}
				is_disabled={is_actual_round_active}
			/>
		
			<FormRadio
				label='Play a finals series'
				name='finals'
				elements={[
					{
						value: 1,
						text: "Yes",
					},
					{
						value: 0,
						text: "No",
					}
				]}
				tooltip={league_tooltips.finals}
				is_disabled={false}
			/>

			<Field
				label='Finals format'
				name='finals_format'
				options={getLeagueFinalFormat({ league_size })}
				component={FormSelect}
				tooltip={league_tooltips.finals_format}
				is_disabled={!is_finals || !is_one_week_left}
				handleSelectChange={handleSelectChange}
			/>
		
			<Field
				label='Finals Start Round'
				name='finals_start_round'
				options={finalsStartRound(finals_length)}
				component={ProFormSelect}
				tooltip={league_tooltips.finals_start_round}
				is_disabled={!is_finals}
				is_pro_sub={isLeagueSubscribed}
				handleProOpen={handleProOpen}
				handleSelectChange={handleSelectChange}
			/>
		
			<Field
				label='Finals Length'
				name='finals_length'
				options={finalsWeeks(Number(finals_start_round), Number(finalsFormat))}
				component={ProFormSelect}
				tooltip={league_tooltips.finals_length}
				is_disabled={!is_finals}
				is_pro_sub={isLeagueSubscribed}
				handleProOpen={handleProOpen}
			/>
		
			<FormRadio
				label='Coaches box'
				name='coaches_box'
				elements={[
					{
						value: 1,
						text: "On",
					},
					{
						value: 0,
						text: "Off",
					}
				]}
				tooltip={league_tooltips.coaches_box}
				is_disabled={false}
			/>
		</div>
	);
};
//write out logic for finals 

export default SeasonSettings;