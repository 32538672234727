// @flow
import React from "react";
import SvgIconContainer from "../SvgIconContainer";

export default (
	{
		unit = "em",
		...rest
	}: Object = {}) => (
	<SvgIconContainer viewBox="0 0 30 30" {...rest} unit={unit}>
		<title>Swap</title>
		<path
			d="M19.375,7.5 C23.185241,7.5 26.25,10.564759 26.25,14.375 C26.25,18.185241
			23.185241,21.25 19.375,21.25 C15.564759,21.25 12.5,18.185241 12.5,14.375
			C12.5,10.564759 15.564759,7.5 19.375,7.5 Z M16.1397059,10.1792279
			L23.5707721,17.6102941 C24.2240526,16.7528634 24.6323529,15.6504525
			24.6323529,14.4255515 C24.6323529,11.5266191 22.264211,9.11764706
			19.3244485,9.11764706 C18.1403775,9.11764706 17.0379666,9.5259474 16.1397059,10.1792279
			 Z M22.6102941,18.5707721 L15.1792279,11.1397059 C14.5259474,12.0379666
			 14.1176471,13.1403775 14.1176471,14.3244485 C14.1176471,17.264211
			 16.5266191,19.6323529 19.4255515,19.6323529 C20.6504525,19.6323529
			 21.7528634,19.2240526 22.6102941,18.5707721 Z"
			fillRule="nonzero" />

		<path
			d="M11.2895462,15.181726 C11.3704408,16.0024255 11.5734493,16.7872578
			11.8801543,17.5178053 C11.4593381,17.9273656 11.0294694,18.3179985
			10.588125,18.6740625 C9.661875,19.41 8.39625,20.259375 6.841875,20.2734375
			L4.6875,20.2734375 L4.6875,18.2128125 L6.84375,18.2128125 C7.3640625,18.2128125
			7.9546875,17.9953125 8.611875,17.5678125 C9.2165625,17.176875 9.916875,16.5778125
			10.7503125,15.7359375 C10.9301398,15.5554354 11.10936,15.3706837 11.2895462,15.181726
			Z M9.68578125,12.9615 C9.54515625,12.8368125 9.41296875,12.72525 9.28734375,12.624
			C8.39109375,11.8880625 7.54921875,11.482125 6.92015625,11.482125 L4.68796875,
			11.482125 L4.68796875,9.4205625 L6.88734375,9.4205625 C7.82109375,9.4243125
			8.72953125,9.7365 9.74390625,10.4030625 C10.1732812,10.687125 10.6073437,11.0265
			11.0892187,11.4568125 C10.8201562,11.740875 10.5548437,12.0249375
			10.2932812,12.3080625 C10.0860937,12.5311875 9.88453125,12.749625 9.68578125,12.9615 Z"
			fillRule="evenodd" />

	</SvgIconContainer>
);