// @flow
import * as React from "react";
import styled from "styled-components";
import { useCallback,useEffect } from "react";
import { below } from "../../assets/css/media";
import colors from "../../assets/css/colors";
import ModalOverlay from "./ModalOverlay";
import ModalClose from "./ModalClose";
import ModalHeader from "./ModalHeader";
import ModalBody from "./ModalBody";

export const ModalImgWrap = styled.div``;
const ModalContentMain = styled.div`
	padding-bottom: 15px;
`;

export const ModalContent = styled.div`
	max-width: ${({ maxWidth }) => maxWidth > 0 ? `${maxWidth}px`: "799px"};
	width: 100%;
	background: ${({ container_background }) => container_background || "#ffffff"};
	margin: 50px 20px;
	position: relative;
	border-radius: 5px;
	box-shadow: 0 0px 20px 0 rgb(0,0,0,0.8);
	z-index: 150;
	display:flex;
	flex-direction: column-reverse;
	${below.tablet`
		max-width: 390px;
		margin: 10px;
		padding: 22px 5px;
	`}
`;

export const ModalIcon = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding-bottom: 20px;

	svg {
		height: 60px;
		width: 60px;
		color: ${({ warning }) => warning ? colors.warning : colors.primary.primary};
	}
`;

type Props = {
	header_text?: React.Node,
	is_sponsored?: boolean,
	onClick: Function,
	children: React.Node,
	top_icon?: Object,
	container_background?: string,
	warning?: boolean,
	modalImgContent?: React.Node,
	lastActiveElement?: Element | null,
	maxWidth?: number;
};

export const ModalContainer = ({
	header_text,
	is_sponsored,
	onClick,
	children,
	top_icon,
	container_background,
	warning = false,
	modalImgContent = null,
	lastActiveElement = null,
	maxWidth = 0,
	...rest
}: Props) => {
	const modalRef = React.useRef(null);
	const header = header_text ? (
		<ModalHeader 
			warning={warning} 
			header_text={header_text} 
			is_sponsored={is_sponsored} 
			top_icon={top_icon} 
		/>
	) : null;
	const handleUserKeyPress = useCallback(event => {
		const { key } = event;
		if(key === "Escape"){
			onClick();
		}
	}, [onClick]);
	useEffect(() => {
		window.addEventListener("keydown", handleUserKeyPress);
		return () => {
			window.removeEventListener("keydown", handleUserKeyPress);
		};
	}, [handleUserKeyPress]);
	useEffect(() => {
		const focusableModalElements = modalRef && modalRef.current 
			? modalRef.current.querySelectorAll(
				"input[type=\"text\"], button,a[href]"
			):null;
		const firstElement = focusableModalElements ? focusableModalElements[0] : null;
		if (firstElement && document.activeElement !== firstElement) {
			return (firstElement).focus();
		}
	}, [onClick, lastActiveElement]);
	return (
		<ModalOverlay 
			{...rest}
			role="dialog"
			aria-modal="true"
			ref={modalRef}
			onClick={onClick}>
			<ModalContent
				onClick={e => e.stopPropagation()}
				container_background={container_background}
				maxWidth={maxWidth}
			>
				<ModalContentMain>
					<ModalImgWrap>
						{modalImgContent}
					</ModalImgWrap>
					{ header }
					<ModalBody>
						{ children }
					</ModalBody>
				</ModalContentMain>
				<ModalClose onClick={onClick} />
			</ModalContent>
		</ModalOverlay>
	);
};

export default ModalContainer;