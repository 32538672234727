// @flow
import React from "react";
import * as _ from "lodash";
import styled from "styled-components";
import moment from "moment";

import { Td } from "../TableX";
import colors from "../../assets/css/colors";
import { getShortName } from "../../helpers/league";
import { isAllTrue } from "../../helpers";

export const StyledTd = styled(Td)`
	white-space: nowrap;
`;

export const OwnerTeamName = styled.p`
	font-family: TitilliumUpright;
	font-size: 14px;
	color: ${colors.primary.primary};
	font-weight: 700;
	line-height: 1;
	/* margin-bottom: 0.2em; */
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	max-width: 13em;
	vertical-align: middle;
	${({ is_compressed }) => is_compressed && `
		display: inline-block;
		margin-bottom: 0;
		transform: translateY(0.1em);
	`};
`;

export const OwnerName = styled.p`
	font-family: TitilliumUpright;
	font-size: 12px;
	color: ${colors.secondary.accentGrey};
	font-weight: 700;
	line-height: 1;
	margin: 0;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	max-width: 8em;
	${({ is_compressed }) => is_compressed && `
		display: inline-block;
		margin-bottom: 0;
		&:before {
			content: "";
		}
	`};
`;


type Props = {
	is_compressed: boolean,
	player?: {
		owner: {
			name: string,
			firstname: string,
			lastname: string
		},
		waiver: string,
		expiry: number
	},
	team?: {
		firstname: string,
		lastname: string,
		name: string
	}
};

export const TdDraftOwner = ({
	team,
	player,
	is_compressed
}: Props) => {
	if(team) {
		return (
			<StyledTd textAlign="left" className="td-draft-owner">
				<OwnerTeamName is_compressed={is_compressed}>
					{team.name}</OwnerTeamName>
				<OwnerName is_compressed={is_compressed}>
					{getShortName({ ...team })}</OwnerName>
			</StyledTd>
		);
	}
	const playerOwner = _.get(player, "owner", "");
	if(isAllTrue([Boolean(player), Boolean(playerOwner)])) {
		return (
			<StyledTd textAlign="left" className="td-draft-owner">
				<OwnerTeamName is_compressed={is_compressed}>
					{playerOwner.name}</OwnerTeamName>
				{!is_compressed && 
          	<OwnerName is_compressed={is_compressed}>
          		{getShortName(playerOwner)}</OwnerName>
				}
			
			</StyledTd>
		);
	}
	if(player) {
		return <StyledTd textAlign="left" className="td-draft-owner">
			<OwnerTeamName is_compressed={is_compressed}>
				{player.waiver}</OwnerTeamName>
			{(player.expiry && !is_compressed) && (
				<OwnerName is_compressed={is_compressed}>
					{moment(player.expiry).format("ddd h:mma")}
				</OwnerName>
			)}
		</StyledTd>;
	}

	return null;
};

export default TdDraftOwner;