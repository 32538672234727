// @flow
import React from "react";
import SvgIconContainer from "../SvgIconContainer";

export default (
	{
		unit = "em",
		...rest
	}: Object = {}) => (
	<SvgIconContainer viewBox="0 0 30 30" {...rest} unit={unit}>
		<title>Trophy</title>
		<path d="M29.8772727,1.975348 L27.4977273,0.0782100888 C27.3796368,-0.0168635747 
			27.2104673,-0.0261766414 27.0818182,0.0553135968 C26.0051547,0.757733287 
			24.7118778,1.08870696 23.4136364,0.994069768 C17.8203969,0.421876917 
			12.1796031,0.421876917 6.58636364,0.994069768 C5.28812216,1.08870696 
			3.99484531,0.757733287 2.91818182,0.0553135968 C2.78953273,-0.0261766414 
			2.62036318,-0.0168635747 2.50227273,0.0782100888 L0.122727273,1.975348 
			C0.0458385803,2.0392014 0.00115797389,2.13160962 0,2.22917196 
			C0.0681818182,4.47499074 1.11136364,10.3770522 7.84090909,12.5182013 
			C8.82954545,17.3205769 8.88409091,17.7392556 9.72272727,18.0342933 
			C11.6113636,18.7813731 12.0204545,19.3727568 12.0204545,22.8608738 
			C11.9659934,25.0251047 10.7894416,27.0205026 8.88409091,28.180056 
			C8.45156826,28.44182 8.22562421,28.9275261 8.31136364,29.4112331 
			C9.45,29.763839 12.8522727,30 15,30 C17.1477273,30 20.55,29.7612223 
			21.6886364,29.4112331 C21.7743758,28.9275261 21.5484317,28.44182 
			21.1159091,28.180056 C19.2105584,27.0205026 18.0340066,25.0251047 
			17.9795455,22.8608738 C17.9795455,19.3727568 18.3886364,18.7813731 
			20.2772727,18.0342933 C21.1159091,17.7392556 21.1704545,17.3205769 
			22.1590909,12.5182013 C28.8886364,10.3770522 29.9318182,4.47499074 
			30,2.22917196 C29.998842,2.13160962 29.9541614,2.0392014 29.8772727,1.975348 
			Z M3.42954545,1.74834563 C4.62272727,1.74834563 5.10681818,4.35658316 
			6.13636364,4.61694899 C6.25475734,4.65004856 6.34411951,4.74360658 
			6.36818182,4.8596518 C6.78409091,6.36427842 7.35681818,9.35259771 
			7.5,10.3515389 C3.19772727,9.7228667 0.211363636,1.74834563 
			3.42954545,1.74834563 Z M22.5,10.3515389 C22.6431818,9.35259771 
			23.2159091,6.3649326 23.6318182,4.8596518 C23.6558805,4.74360658 
			23.7452427,4.65004856 23.8636364,4.61694899 C24.8931818,4.35527479 
			25.3772727,1.74834563 26.5704545,1.74834563 C29.7886364,1.74834563 
			26.8022727,9.7228667 22.5,10.3515389 Z" id="path-1" />
	</SvgIconContainer>
);