// @flow
import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

import { showClassicLeague } from "../../modules/actions";

type Props = {
	match: {
		params: {league_id: string}
	},
	showClassicLeague: typeof showClassicLeague,
	show_state: Object,
	active_league: Object,
}


class RedirectOnInvalidClassicLeagueComponent extends React.Component<Props> {
	componentDidMount() {
		const { showClassicLeague, match} = this.props;

		// check league id is valid
		if(/^\d+$/.test(match.params.league_id) === false) {
			window.location = "/classic/leagues/join";
		}

		showClassicLeague({ id: match.params.league_id });
		
	}

	render() {
		const { show_state, match } = this.props;
		if(
			show_state.error !== null &&
			match.params.league_id === show_state.league_id &&
			!show_state.is_pending
		) {
			return <Redirect to="/classic/leagues/join" />;
		}
		return null;
	}
}

const mapStateToProps = (state, props) => {
	return({
		show_state: state.leaguesClassic.show,
		active_league: state.leaguesClassic.show.data
	});
};

const mapDispatchToProps = {
	showClassicLeague
};

const RedirectOnInvalidClassicLeague =
	connect(mapStateToProps, mapDispatchToProps)(RedirectOnInvalidClassicLeagueComponent);

export default RedirectOnInvalidClassicLeague;