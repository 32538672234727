// @flow
import React from "react";
import styled from "styled-components";
import colors from "../../../assets/css/colors";
import ButtonPrimary from "../../ButtonPrimary";
import Tick from "../../Icons/Tick";
import { below } from "../../../assets/css/media";

export const SaveTeamButtonComponent = styled(ButtonPrimary)`
	min-width: 8.5em;
	margin-left: 8px;

	${below.phone`
		display: none;
	`};
`;

SaveTeamButtonComponent.defaultProps = {
	...colors.buttons.save_button,
	className: ""
};

const saveButtonText = (is_team_saving, is_team_saved) => {
	if (is_team_saving) {
		return "Saving...";
	}

	return is_team_saved ? (
		<React.Fragment>Saved <Tick /></React.Fragment>
	) : "Save Team";
};

type Props = {
	onClick: Function,
	is_saving: boolean,
	is_saved: boolean,
	className?: string
}

export const SaveTeamButton = (props: Props) => {
	return <SaveTeamButtonComponent
		onClick={props.onClick}
		disabled={props.is_saving || props.is_saved}
		className={props.className}
	>
		{ saveButtonText(props.is_saving, props.is_saved) }
	</SaveTeamButtonComponent>;
};

export default SaveTeamButton;