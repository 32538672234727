// @flow
import * as React from "react";
import { change, formValueSelector, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import get from "lodash/get";
import bindAll from "lodash/bindAll";
import { getShortName } from "../../../../helpers/league";
import * as actions from "../../../../modules/actions";
import Arrow from "../../../../components/Icons/Arrow";
import { leagueValidate } from "../../../../utils/FormValidations/index";
import { ButtonPrimary, FormUploadImage } from "../../../../components";
import type {TRound} from "../../../../modules/types";
import colors from "../../../../assets/css/colors";
import { below } from "../../../../assets/css/media";
import { LeagueSettings } from "./forms/LeagueSettings";
import { AdvancedLeagueSettings } from "./forms/AdvancedLeagueSettings";

const CreateForm = styled.form`
	border: 1px solid #ecf1f5;
	margin: 1em 0;
	background: ${colors.form.base};
	scroll-behaviour: smooth;
`;

const FormItem = styled.div`
	margin: 0 3em;
	padding: 1em;
	background: ${colors.form.advanced_color};

	&.base-options{
		background: transparent;
		padding: 0;
	}

	${below.desktop`
		margin: 0 1em;
	`}

	button{
		padding: 1em 3em;
		font-size: 15px;
		font-weight: 600;
	}
`;

const SubTitle = styled.h4`
	display: flex;
	font-size: 22px;
	align-items: center;
	color: ${colors.primary.primary};
	font-weight: bold;
	margin: 1em 0 1em 0;

	div {
		margin-right: .5em;
	}
`;

const AdvancedSettings = styled.p`
	color: ${colors.form.textDarkColor};
	line-height: 1.5;
	font-size: 16px;
	max-width: 560px;
`;

const UploadAvatarWrapper = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	height: 130px;
	color: ${colors.form.textColor};

	picture {
		margin: 1em .5em .5em 0;
	}
`;

const LeagueName = styled.h3`
	color: ${colors.primary.primary};
	font-size: 28px;
	margin-bottom: .2em;
	line-height: 1.3;
	font-weight: bold;
	opacity: ${({ disabled }) => disabled ? ".15" : "1"};
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const AdvancedSettingsWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1em;
	border-radius: 3px;
	background: ${colors.form.advanced_color};
	border-radius: 3px 3px 0 0;
	box-shadow: inset 0px 15px 20px -10px #c7c7c7;

	.sub-title {
		margin-bottom: .5em;
	}
`;

const ToggleForm = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	background: ${colors.buttons.baseColor};
	width: 50px;
	height: 50px;
	border-radius: 0;
	color: #ffffff;
`;

const UserName = styled.div`
	color: ${colors.form.textColor};
	font-weight: bold;
`;

const ArrowBottom = styled(Arrow)`
	transform: rotate(178deg);
`;

const ButtonWrapper = styled.div`
	padding: 1em 0;
`;

type Props = {
	handleSubmit: Function,
	onChangeFile: Function,
	previewImg: string,
	league_name: string,
	user: {
		firstname: string,
		lastname: string,
	},
	is_show_advanced_settings: boolean,
	toggleForm: Function,
	league_type: string,
	submitting: boolean,
	is_finals: boolean,
	league_size: number,
	is_finals: boolean,
	rounds: TRound[],
	regenerated_leagues_by_id: Object,
	match: {
		params: {
			regenerate_league_id?: string
		}
	},
	fetchRegenerateLeague: typeof actions.regenerateShowClassicLeague,
	changeFieldValue: Function
};


class LeagueCreateFormComponent extends React.Component<Props> {
	constructor(props) {
		super(props);
		bindAll(this, ["populateWithRegenLeague", "scrollToForm"]);
	}

	componentDidMount() {
		const regenerated_leagues_by_id = get(this.props, "match.params.regenerate_league_id");
		// check if we have a regen league id && if it's in state,
		// YES populate form
		// NO fetch regen league => compDidUpdate will trigger once fetch is complete
		if(
			regenerated_leagues_by_id &&
			regenerated_leagues_by_id in this.props.regenerated_leagues_by_id
		) {
			this.populateWithRegenLeague(regenerated_leagues_by_id);
		}
		else {
			this.props.fetchRegenerateLeague({ id: regenerated_leagues_by_id });
		}

		setTimeout(() => {
			if(get(this.props, "match.params.regenerate_league_id")) {
				this.scrollToForm();
			}
		}, 2500);
	}

	componentDidUpdate(prev_props: Props) {
		const regenerated_leagues_by_id = get(this.props, "match.params.regenerate_league_id");

		// detect if there is a regen league id
		if(prev_props.match.params.regenerate_league_id !==
			regenerated_leagues_by_id) {
			// check to see if we have that league in our redux state
			if(regenerated_leagues_by_id &&
				regenerated_leagues_by_id
				in this.props.regenerated_leagues_by_id) {
				this.populateWithRegenLeague(regenerated_leagues_by_id);
			}
		}

		// I will trigger once fetch regen league is done
		if(
			!get(prev_props, `regenerated_leagues_by_id[${regenerated_leagues_by_id}]`) &&
			get(this.props, `regenerated_leagues_by_id[${regenerated_leagues_by_id}]`)
		) {
			this.populateWithRegenLeague(regenerated_leagues_by_id);
		}

		// scroll to form on regen league update
		if(
			get(prev_props, "match.params.regenerate_league_id") !==
			get(this.props, "match.params.regenerate_league_id")
		) {
			this.scrollToForm();
		}
	}

	form_ref = React.createRef();

	get refElement() {
		return this.form_ref.current;
	}

	scrollToForm() {
		if(this.form_ref) {
			const offsetTop = get(this.form_ref.current, "offsetTop");
			window.scrollTo(0, offsetTop + 50);
		}
	}

	populateWithRegenLeague(id: string) {
		const fields = this.props.regenerated_leagues_by_id[id];

		delete fields.start_round;
		delete fields.draft_start;
		delete fields.regenerated_from;

		this.props.changeFieldValue("regenerated_from", id);
		Object.keys(fields).forEach(field => {
			this.props.changeFieldValue(field, fields[field]);
		});
	}

	render() {
		const {
			handleSubmit,
			onChangeFile,
			previewImg,
			league_name,
			user,
			is_show_advanced_settings,
			toggleForm,
			league_type,
			submitting,
			rounds,
			league_size,
			is_finals
		} = this.props;
		return (
			<CreateForm onSubmit={handleSubmit} ref={this.form_ref}>
				<FormItem className='base-options'>
					<UploadAvatarWrapper>
						<FormUploadImage
							name='league_avatar'
							size="75px"
							onChange={onChangeFile}
							preview_img={previewImg ? previewImg : ""}
							add_text=''
						/>
						<div>
							{league_name ? (
								<LeagueName>{league_name}</LeagueName>
							) : (
								<LeagueName disabled={true}>League Name</LeagueName>
							)}
							<UserName>{getShortName(user)}</UserName>
						</div>
					</UploadAvatarWrapper>

					<LeagueSettings rounds={rounds} league_type={league_type} />

					{league_type === "head_to_head" ? (
						<AdvancedSettingsWrapper>
							<div>
								<SubTitle
									className='sub-title'
									is_inactive={is_show_advanced_settings}
								>
									Advanced settings
								</SubTitle>
								<AdvancedSettings>
									You can create a league via the basic settings
									above or fine tune your Classic League with our
									advanced settings.
								</AdvancedSettings>
							</div>
							<ToggleForm background={colors.buttons.baseColor} hide_border={true}>
								{!is_show_advanced_settings ?
									(
										<Arrow
											color={"#fff"}
											size="2"
											cursor='pointer'
											onClick={() => {
												toggleForm(true);
											}}
										/>
									) : (
										<ArrowBottom
											color={"#fff"}
											size="2"
											cursor='pointer'
											onClick={() => {
												toggleForm(false);
											}}
										/>
									)
								}
							</ToggleForm>
						</AdvancedSettingsWrapper>
					) : null }

				</FormItem>

				{is_show_advanced_settings && league_type === "head_to_head" ? (
					<FormItem>
						<AdvancedLeagueSettings is_finals={is_finals} league_size={league_size} />
					</FormItem>
				) : null}

				<FormItem className='base-options'>
					<ButtonWrapper>
						<ButtonPrimary disabled={submitting}>
							Create your league
						</ButtonPrimary>
					</ButtonWrapper>
				</FormItem>
			</CreateForm>
		);
	}
}


let LeagueCreateFormComp = reduxForm({
	form: "league_manager",
	validate: leagueValidate,
})(LeagueCreateFormComponent);

const selector = formValueSelector("league_manager");

const mapStateToProps = (state, ownProps) => {
	const
		league_size = selector(state, "size"),
		league_type = selector(state, "type");

	return {
		is_finals: selector(state, "finals") === 1,
		league_size,
		league_name: selector(state, "name"),
		league_type,
		regenerated_leagues_by_id: state.leaguesClassic.regenerate_show.by_id,
		initialValues: {
			name: "",
			privacy: 0,
			coaches_box: 1,
			type: "head_to_head",
			size: 16,
			start_round: ownProps.selected_round_id,
			finals: 1,
			finals_format: 8,
			play_times: "flexible",
			bye_rounds: 0,
			rank_by: "percentage",
		},
	};
};

const mapDispatchToProps = dispatch => {
	return {
		changeFieldValue: function(field, value) {
			dispatch(change("league_manager", field, value));
		},
		fetchRegenerateLeague: actions.regenerateShowClassicLeague
	};
};

export const LeagueCreateForm = withRouter(connect(
	mapStateToProps,
	mapDispatchToProps,
)(LeagueCreateFormComp));

export default LeagueCreateForm;