// @flow
import styled from "styled-components";
import colors from "../../assets/css/colors";

export const DeluxOnlyLabel = styled.div`
	border-radius: 2px;
	background-color: ${colors.black};
	color: ${colors.white};
	font-family: "SourceSansPro";
	font-size: 12px;
	font-weight: 600;
	letter-spacing: 0;
	line-height: 20px;
	text-align: center;
	height: 20px;
	padding: 0 5px;
`;