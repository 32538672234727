// @flow
import * as React from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import _, { get } from "lodash";
import Preloader from "../../Preloader";
import { isAuthenticated } from "../../../utils/auth";
import * as actions from "../../../modules/actions";
import * as selectors from "../../../modules/selectors";
import { TUser } from "../../../modules/types";
import { isOffSeason } from "../../../utils";
import TermsConditionsModal from "../../../components/TermsConditionsModal";
import { isAllTrue, isAnyTrue } from "../../../helpers";
import { IS_TERMS_CLOSED } from "../../../modules/constants";

const OFF_SEASON = isOffSeason();

type Props = {
	is_authenticated: boolean,
	fetchUser: Function,
	clearUserError: Function,
	children: React.Node,
	has_user: boolean,
	logout: typeof actions.logout,
	user: TUser,
	user_error?: {
		text: string,
		code: number
	},
	location: {
		pathname: string
	}
}
type State = {
  open_tc_modal: boolean
}

class FetchUserIfAuthenicated extends React.Component<Props, State> {
	constructor(props, state) {
		super(props, state);

		_.bindAll(this, [
			"handleTermsOpen",
			"handleTermsClose",
		]);
	}

	state = {
		open_tc_modal: false,
	};
	componentDidMount() {
		const { fetchUser, is_authenticated } = this.props;
		if (is_authenticated && !OFF_SEASON) {
			fetchUser();
		}
	}

	componentDidUpdate(prev_props) {
		const { has_user, clearUserError } = this.props;
		if (has_user !== prev_props.has_user) {
			clearUserError();
		}
	}
	handleTermsOpen() {
		this.setState({ open_tc_modal: true });
	}
	handleTermsClose() {
		this.setState({ open_tc_modal: false });
	}

	get is_recover_page() {
		const { location } = this.props;
		return location.pathname.indexOf("recover") !== -1;
	}

	get is_terms_conditions() {
		return window.location.pathname.includes("terms-conditions");
	}

	get should_redirect_to_recover() {
		const { user_error } = this.props;
		
		return user_error && user_error.code === 514 && !this.is_recover_page;
	}

	get should_redirect_to_mini_rego() {
		const {is_authenticated, user, has_user} = this.props;
		const userRecovered = get(user, 'recovered', 0);
		const isUserRecovered = userRecovered === 1;
		return has_user && !isUserRecovered && is_authenticated;
	}

	get should_open_tc_modal() {
		const { user_error } = this.props;

		if(IS_TERMS_CLOSED){
			return false;
		}
		
		return user_error && user_error.code === 514 && 
		user_error.text === "In order to continue playing you will need to re-accept the new T&Cs"
		&& !this.is_terms_conditions && !Boolean(this.props.user.tc);
	}
  

	isOffSeason() {
		// const { has_user } = this.props;

		// if (OFF_SEASON) {
		// 	if (has_user) {
		// 		this.logout();
		// 	}
		//
		// 	return <Redirect to="/" />;
		// }
		//
		//
		// return null;
	}

	// eslint-disable-next-line complexity
	render() {
		const { children, has_user, is_authenticated } = this.props;

		this.isOffSeason();

		if (isAnyTrue([!is_authenticated, OFF_SEASON])) {
			return children;
		}
		if(this.should_open_tc_modal){
			return  <TermsConditionsModal />;
		}
		if (isAnyTrue([is_authenticated, OFF_SEASON])) {
			return children;
		}
		if(isAllTrue([is_authenticated, this.is_terms_conditions])){
			return children;
		}
		if(this.should_open_tc_modal){
			return  <TermsConditionsModal />;
		}
		if(this.should_redirect_to_mini_rego){
			return <Redirect to="/login/mini-rego" />;
		}
		if (this.should_redirect_to_recover) {
			return <Redirect to="/recover" />;
		}


		return is_authenticated && 
			(isAnyTrue([
				has_user, 
				this.is_recover_page, 
			]) ) ? children : <Preloader />;
	}
}

const mapStateToProps = state => {
	const showMyError = selectors.leaguesClassic.show_my.getError(state);
	const showMyErrorObj = showMyError ? {
		code: 514,
		text: showMyError
	}:null;
	return {
		is_authenticated: isAuthenticated(),
		user_error: selectors.getUserError(state) || showMyErrorObj,
		has_user: selectors.hasUser(state),
		is_pending_user: selectors.isUserPending(state),
		user: selectors.getUser(state)
	};
};

const mapDispatchToProps = {
	fetchUser: actions.fetchUser,
	clearUserError: actions.clearUserError,
	logout: actions.logout,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FetchUserIfAuthenicated));