// @flow
import * as React from "react";
import styled from "styled-components";
import Datetime from "react-datetime";
import indexStyle from "react-datetime/css/react-datetime.css";

import { Label, FormGroup, Tooltip } from "../../Form";
import colors from "../../../assets/css/colors";
import ErrorField from "../ErrorField";

import ArrowLeft from "../../../assets/images/ArrowLeft.svg";
import ArrowRight from "../../../assets/images/ArrowRight.svg";
import ArrowUp from "../../../assets/images/ArrowUp.svg";
import ArrowDown from "../../../assets/images/ArrowDown.svg";

import Calendar from "../../../components/Icons/Calendar";
import Locked from "../../../components/Icons/Locked";

import { BorderedIcon } from "../../../components";
import LabelWrapper from "../LabelWrapper";
import { Exist } from "../../Exist";


const DatetimeWrapper = styled.div`
	position: relative;
	margin: 5px 0 0 0;
	width: 100%;

	svg {
		position: absolute;
		top: 10px;
		right: 10px;
		pointer-events: none;
	}
`;

const DatetimeStyled = styled(Datetime)`
	${indexStyle}
	display: flex;
	width: 100%;

	input {
		color: ${colors.form.textColor};
		cursor: pointer;
		font-weight: bold;
		font-size: 12px;
		height: 45px;
		border: 1px solid ${colors.form.borderColor};
		background-color: #FFFFFF;
		width: 100%;
		box-shadow: 2px 0px 9px 2px #efeeee;
		padding-left: 1em;
		font-family: "SourceSansPro";

		&:disabled {
			opacity: .5;
		}
	}

	.rdtPicker {
		top: 45px;
		border-radius: 5px;
		box-shadow: 0 0 10px 0 rgba(0,0,0,0.15);
		color: ${colors.primary.primary};
		left: 0;
	}

	td {
		text-align: center;
		vertical-align: middle;
	}

	.rdtPicker td.rdtActive {
		background-color: ${colors.primary.primary};
		color: #fff;
		border-radius: 5px;

		&:hover {
			background-color: #fff;
			color: ${colors.primary.dark};
		}
	}

	.rdtPicker td.rdtActive.rdtToday:before {
		border-bottom-color: ${colors.primary.dark};
	}

	.rdtPicker td.rdtToday:before {
		border-bottom-color: #fff;
	}



	.rdtNext span,
	.rdtPrev span
	 {
	}

	.rdtSwitch {
		font-weight: 700;
		vertical-align: middle;
	}

	.rdtTimeToggle {
		padding-top: 1em;
	}

	.rdtPicker .rdtTimeToggle,
	.rdtPicker .rdtTime .rdtSwitch {
		outline: none;
		border: none;
		color: #fff;
		border-radius: 3px;
		font-weight: bold;
		font-size: 14px;
		line-height: 1;
		padding: 0.6em 4em;
		height: 36px;
		cursor: pointer;
		width: auto;
		background: ${colors.primary.primary};
		&:hover {
			background: ${colors.primary.dark};
		}
	}

	thead td {
		padding-bottom: 10px;
	}

	thead > tr:first-child > th {
		padding: 10px 0;
	}

	.rdtPicker thead tr:first-child th:hover,
	.rdtPicker thead tr:first-child th:hover {
		background-color: transparent;
	}

	.rdtPrev span {
		height: 30px;
		width: 30px;
		text-indent: -999em;
		background-image: url(${ArrowLeft});
		background-size: contain;
		background-position: right center;
		background-repeat: no-repeat;
	}

	.rdtNext span {
		height: 30px;
		width: 30px;
		text-indent: -999em;
		background-image: url(${ArrowRight});
		background-size: contain;
		background-position: left center;
		background-repeat: no-repeat;
	}

	.rdtCounter .rdtBtn:first-child {
		text-indent: -999em;
		background-image: url(${ArrowUp});
		background-repeat: no-repeat;
		background-position: center center;
	}

	.rdtCounter .rdtBtn:last-child {
		text-indent: -999em;
		background-image: url(${ArrowDown});
		background-repeat: no-repeat;
		background-position: center center;
	}

	.rdtCounter .rdtBtn:hover {
		background-color: transparent;
	}

	.rdtPicker .rdtToday:before {
		border: 0;
		height: 5px;
		width: 5px;
		border-radius: 50%;
		background-color: currentColor;
	}

`;

type Props = {
	label: string,
	name: string,
	type: string,
	placeholder: string,
	defaultValue: number,
	is_disabled: boolean,
	input: Object,
	maxDate: string,
	isValidDate: Function,
	dateFormat?: string,
	timeFormat: any,
	readOnly: boolean,
	tooltip: {
		title: string,
		description: string,
	},
	meta: {
		touched: any,
		error: string,
		warning: string,
	},
};


export const FormDateField = ({
	label,
	name,
	type,
	placeholder,
	tooltip,
	defaultValue,
	is_disabled,
	input,
	maxDate,
	isValidDate,
	dateFormat,
	timeFormat,
	readOnly,
	meta: { touched, error, warning },
}: Props) => (
	<div>
		<LabelWrapper>
			<Exist when={is_disabled}>
				<BorderedIcon 
					padding='0' 
					hide_border={true} 
					background={colors.buttons.baseColor}
					size={1.5}
				>
					<Locked size={1.1} color='#ffffff' />
				</BorderedIcon>
			</Exist>
			<Label htmlFor={name} is_disabled={is_disabled}>{label}</Label>
			{tooltip ? (<Tooltip {...tooltip} />) : null}
		</LabelWrapper>

		{ touched && ((
			error && <ErrorField>{error}</ErrorField>) ||
			(warning && <ErrorField>{warning}</ErrorField>
			))
		}
		<FormGroup>
			<DatetimeWrapper>
				<DatetimeStyled
					name={name}
					type={type}
					placeholder={placeholder}
					defaultValue={defaultValue}
					dateFormat={dateFormat ? dateFormat : "ddd, D MMM YYYY"}
					timeFormat={timeFormat}
					isValidDate={current => isValidDate(current, maxDate)}
					{...input}
					inputProps={{
						disabled: is_disabled,
						readOnly: readOnly,
					}}
				/>
				<Calendar size="1.5" color={colors.form.arrow} />
			</DatetimeWrapper>
		</FormGroup>

	</div>
);

export default FormDateField;