// @flow
import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import * as _ from "lodash";
import { Redirect } from "react-router-dom";
import styled from "styled-components";
import JsonFetcher from "../../../components/utils/JsonFetcher";
import * as selectors from "../../../modules/selectors";
import * as actions from "../../../modules/actions";
import PartialByeDetectorControls from "../../../components/ByeDetector/partialByeControls";
import { isMobile } from "../../../utils";
import { 
	getLeagueRounds,
	getLeagueRoundAliases, 
	getLeagueScoringBasis 
} from "../../../helpers/league";
import { roundHasStarted } from "../../../helpers/stats/displayRound";
import { below } from "../../../assets/css/media";
import {
	Preloader,
	AdsContainer,
	Ad,
	MatchCentreBar,
	TwoColumnLayout,
	Footer,
	WidgetNews,
	WidgetRfaOrder,
	WidgetSpacer,
	withDraftPlayerModal,
	TeamInfoBlock,
	AdFantasyCoach,
	LeagueGameBar,
	PageContentWrapper,
	ButtonPrimaryLink,
	PoolSelect,
	ByeDetectorControls,
	PlayerActionModal,
} from "../../../components";
import withErrorModal from "../../../components/utils/withErrorModal";
import {
	TPosition,
	TRound,
	TPlayersArrayWithFixtureById,
	TDraftUserTeam,
	TPLeague,
	TPlayer,
	TRoundsWithMatches,
	TRoundsById,
	TUser,
} from "../../../modules/types";
import { LineupSetter } from "../../../components/MyTeam/lineupSetter";
import withRoundsSelector from "../../../components/utils/withRoundsSelector";
import withCoachesBox from "../../../components/utils/withCoachesBox";
import withByeDetector from "../../../components/utils/withByeDetector";
import withFantasyCoachModal from "../../../components/utils/withFantasyCoachModal";
import TeamTitle from "../../../components/TeamTitle";
import { Select } from "../../../components/Form";
import { isAllTrue, lockoutKey } from "../../../helpers";
import ListViewTeam from "./leagueTeam/listView";
import OverflowUtils from "./leagueTeam/overflowUtils";
import { TeamGameBar } from "./leagueTeam/teamGameBar";
import TeamTopControls from "./leagueTeam/teamTopControls";
import { EmptyEmergencyBanner, EmptyPositionBanner } from "./leagueTeam/banners";
import TransactionHistoryRFARow from "./transactions/transactionHistory/transactionHistoryRFARow";
import { PageSubTitleWithButton as PageSubTitleWithButtonUnstyled } from "./LeagueOverview";
import FieldViewTeam from "./leagueTeam/fieldView";


const POSITION_KEYS = [1,2,3,4,"bench"];

const emgPosition = (emg, p_id) => _.findKey(emg, id => id === p_id);

const teamSizeFromSquad = (squad_size: string) => {
	const team_size = squad_size.split("-");
	let number_lineup = 0;
	team_size.forEach(element => {
		if(element.includes("/")){
			const split_digits = element.split("/");
			number_lineup += Number(split_digits[0]);
			number_lineup += Number(split_digits[1]);
			return;
		}
		number_lineup += Number(element);
	});
	return number_lineup;
};

/**
 * Gives main content min-width of 980px before dropping below desktop size
 */
const FixedTwoColumnLayout = styled(TwoColumnLayout)`
	@media screen and (max-width: 1280px) {
		& > :last-child {
			display: none;
		}
		& > :first-child {
			width: 100%;
		}
	}
`;

const PageSubTitleWithButton = styled(PageSubTitleWithButtonUnstyled)`
	margin-top: 20px;
`;

// const SelectsWrapper = styled.div`
// 	display: flex;
// 	> div { 
// 		flex: 1 0 0px;
// 		margin: 0 20px 0 0;
// 	}
// 	> div:last-child {
// 		display: none;

// 		${below.desktop`
// 			display: block;
// 		`}
// 	}

// 	${below.phone`
// 		> div:last-child {
// 			margin: 0;
// 		}
// 	`}
// `;

const ControlsBlock = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-end;
	gap: 10px;
	box-sizing: border-box;
	width: 100%;
	padding-left: 20px;
	padding-right: 20px;
	> div {
		flex: 0 1 auto;
	}

	${below.desktop`
		width: 100%;
		padding: 20px;
    	padding-top: 0px;
		min-height: 60px;
		@supports (height: fit-content) {
			height: fit-content;
		}

		> div {
			flex: 2 1 0px;
			width: 100%;
			:first-child {
				flex: 3 1 0px;
			}
		}
	`}

	${below.tablet`
		label > span.long_name {
			display: none;
		}
		label > span.short_name {
			display: inline;
		}
	`}

	${below.phone`
		flex-direction: column;
		align-items: flex-start;
		> div:first-child {
			width: 100%;
		}

		> div:not(:first-child) {
			width: 45%;
		}

		padding-bottom: 0;
	`}
`;

const GameBarWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 10px;
	flex-wrap: wrap;
	background: #F8F8FA;

	padding: 12px 0 12px 10px;

	${below.desktop`
		flex-direction: column;
		align-items: center;
		height: auto;
		padding: 16px 0 16px 0;

		> div {
			width: 100%;
		}
	`};
`;


const PoolSelectWrapper = styled.div`
	box-sizing: border-box;
	margin: 0 20px 0px;
	width: 230px;

	${below.desktop`
		width: 100%;
	`};
`;

const ScoreByeDetectorWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	${below.desktop`
		flex-direction: column;
		padding: 10px;
		gap: 10px;
	`};
`;

const TitleAndRoundWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
`;

const StyledTeamTitle = styled(TeamTitle)`
	width: 54.3478261%;
	margin: 0;
`;

const StyledSelect = styled(Select)`
	width: 21.7391304%;
`;

const game_bar_stats = (is_current, statBasis) => [
	{
		key: `${statBasis}.avg_points`,
		value: "Average",
		short_name: "Avg"
	},
	{
		key: `${statBasis}.proj_score`,
		value: "Projected",
		is_coach: true,
		short_name: "Proj"
	},
	{
		key: `${statBasis}.points_last_round`,
		value: "Last Round",
		short_name: "Last"
	},
	{
		key: `${statBasis}.round_score`,
		value: is_current ? "Live" : "Final",
		short_name: "Score"
	},
];

const player_stats = [
	{ key: "stats.total_points", value: "Total Points", short_name: "Tot Pts" },
	{ key: "stats.bye_round_id", value: "Bye Round", short_name: "BYE", is_coach: true },
	{ 
		key: "stats.leagues_rostered", 
		value: "Teams Selected by %", 
		short_name: "TS%", 
		is_coach: true 
	},
	{ key: "stats.last_3_avg", value: "3 round average", short_name: "L3 AVG" },
	{ key: "stats.last_5_avg", value: "5 round average", short_name: "L5 AVG" }
];
const getFieldViewStatus = () => {
	if(localStorage.getItem("field_view")){
		return localStorage.getItem("field_view") === "true" ? true : false;
	}
	localStorage.setItem("field_view", "true");
	return true;
};
function removeEmptyBenchPlayers(arr, val) {
	let i = arr.length;
	while (i--) {
		if (arr[i] === val) {
			arr.splice(i, 1);
		}
	}
	return arr;
}


type Props = {
	match: {
		params: {league_id: string}
	},
	team: TDraftUserTeam,
	has_assistant_coach: boolean,
	data_loading_complete: boolean,
	showModalForPlayerId: typeof actions.showModalForPlayerId,
	positions: { [number]: TPosition },
	players_by_id: TPlayersArrayWithFixtureById,
	actual_round: TRound,
	player_ids: Array<number>,
	selected_round_id: number,
	selected_round: TRound,
	fetchTeam: Function,
	league_id: string,
	showDraftLeague: Function,
	league: TPLeague,
	updateMyTeam: Function,
	removePlayer: typeof actions.teamsDraft.fetchRemovePlayer,
	ladder_teams: Array<TDraftUserTeam>,
	fetchLadder: Function,
	rounds_ordered: Array<Object>,
	setSelectedRoundId: Function,
	is_team_saving: boolean,
	rounds: TRound[],
	ladder_by_id: Object,
	rounds_by_id: TRoundsById,
	show_modal: boolean,
	getModalElement: any,
	hideErrorModal: Function,
	showErrorModal: Function,
	clearErrors: Function,
	error_text: any,
	rounds_with_matches: Array<TRoundsWithMatches>,
	is_static: boolean,
	is_current: boolean,
	team_id: string,
	url_round_id?: string,
	user: TUser,
	fetchTransactionHistory: Function,
	transaction_history: Array<Object>,
	bye_rounds: number[],
	bye_detector_selected: number[],
	bye_detector_active: boolean,
	setByeDetectorActive: Function,
	toggleByeDetectorRound: Function,
	toggleFantasyCoachModal: Function,
	is_classic: boolean,
	fetchFavourites: typeof actions.fetchFavourites,
	fetchAllCustomStats: typeof actions.fetchAllCustomStats,
	fetchAllCoachCustomStats: typeof actions.fetchAllCoachCustomStats,
	is_partial_or_standard: string,
	setPartialActive: string,
}

type TPlayerAction = null | 'delist' | 'trade';

type State = {
	preloader: boolean,
	is_mobile: boolean,
	field_stat: string,
	mobile_stat: string,
	substitute_id: number,
	substitute_position: number,
	substitute_player_positions: Array<number>,
	substitute_is_bench: boolean,
	is_field_view: boolean,
	flip: boolean,
	modal_action_type: TPlayerAction,
	modal_action_player: null | number
};


class LeagueTeamComponent extends React.Component<Props, State> {
	constructor(props) {
		super(props);

		_.bindAll(this, [
			"removePlayer",
			"setCaptain",
			"setViceCaptain",
			"setEmergency",
			"substitutePlayer",
			"cancelSubstitute",
			"openPlayerPopup",
			"resize",
			"checkIsPlayerChangeAvailable",
			"changeFieldStat",
			"setFieldView",
			"areSubstitutesActive",
			"isOutSubstitute",
			"isInSubstitute",
			"closeErrorModal",
			"onMobileStatSelectChange",
			"handleFlipCards",
			"setPlayerAction",
			"handleChange",
			"handleTeamChange",
			"getTeamLink",
		]);
	}

	state = {
		preloader: true,
		is_mobile: isMobile(),
		field_stat: roundHasStarted(this.props.selected_round)
			? `${getLeagueScoringBasis(this.props.league)}.round_score`
			: `${getLeagueScoringBasis(this.props.league)}.proj_score`,
		substitute_id: 0,
		substitute_position: 0,
		substitute_player_positions: [],
		substitute_is_bench: false,
		is_field_view: getFieldViewStatus(),
		flip: false,
		mobile_stat: _.get(_.first(player_stats), "key"),
		modal_action_player: null,
		modal_action_type: null,
	};

	componentDidMount() {
		const {
			fetchTeam,
			fetchFavourites,
			league_id,
			showDraftLeague,
			fetchLadder,
			selected_round_id,
			league,
			team_id,
		} = this.props;

		this.getPlayerStats();
		fetchTeam({ league_id, id: team_id });
		fetchFavourites();
		showDraftLeague({ id: league_id });
		fetchLadder({
			round: selected_round_id,
			league_id
		});
		this.fetchTransactions();

		if (!_.isEmpty(league)) {
			this.setStartRound();
		}

		window.addEventListener("resize", this.resize);
		this.resize();
	}

	shouldComponentUpdate(next_props: Props) {
		return next_props.data_loading_complete;
	}

	componentDidUpdate(prev_props: Props) {
		const {
			show_modal,
			error_text,
			showErrorModal,
		} = this.props;

		if(isAllTrue([!prev_props.data_loading_complete, this.props.data_loading_complete])) {
			this.setState({ preloader: false });
			this.setStartRound();
			this.resetFieldStat();
			this.fetchTransactions();
		}
		if (!show_modal && error_text) {
			showErrorModal({
				modal_body: error_text
			});
		}
		if (!prev_props.bye_detector_active && this.props.bye_detector_active) {
			this.setState({
				mobile_stat: "stats.bye_round_id"
			});
		}

		if(prev_props.league !== this.props.league){
			this.getPlayerStats();
		}

		this.checkTeamRoundChanged(prev_props);
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.resize);
	}

	getPlayerStats() {
		const {
			fetchAllCustomStats,
			fetchAllCoachCustomStats,
			league,
			match: { params: { league_id } },
			has_assistant_coach,
		} = this.props;
		// eslint-disable-next-line max-len
		if(league && Boolean(league.custom_scoring_enabled)){
			fetchAllCustomStats(league_id);
			if(has_assistant_coach){
				fetchAllCoachCustomStats(league_id);
			}
		}
	}

	checkTeamRoundChanged(prev_props: Props) {
		const {
			selected_round_id,
			team_id,
		} = this.props;
		const team_changed = team_id !== prev_props.team_id;
		const round_changed = selected_round_id !== prev_props.selected_round_id;

		if (team_changed || round_changed) {
			this.fetchTeam();
			this.resetFieldStat();
			this.fetchTransactions();
		}
	}

	fetchTeam() {
		const {
			league_id,
			selected_round_id,
			fetchTeam,
			team_id
		} = this.props;

		fetchTeam({
			league_id,
			round: selected_round_id,
			id: team_id
		});
	}

	fetchTransactions() {
		const {
			fetchTransactionHistory,
			league_id,
			selected_round_id,
			team_id
		} = this.props;
		if (selected_round_id && league_id) {
			fetchTransactionHistory({
				league_id,
				round_id: selected_round_id,
				team_id
			});
		}
	}

	resetFieldStat() {
		this.setState({
			field_stat: roundHasStarted(this.props.selected_round)
				? `${getLeagueScoringBasis(this.props.league)}.round_score`
				: `${getLeagueScoringBasis(this.props.league)}.points_last_round`,
		});
	}

	setStartRound() {
		const { setSelectedRoundId, selected_round_id, league, url_round_id } = this.props;
		if (url_round_id) {
			try {
				const round_id = parseInt(url_round_id, 10);
				if (_.isNumber(round_id) && _.inRange(round_id, 1, 24)) {
					setSelectedRoundId(round_id);
					return;
				}
			}
			catch (e) {}
		}
		if (league.start_round && league.start_round > selected_round_id) {
			setSelectedRoundId(league.start_round);
		}
	}

	setPlayerAction(player_id: number | null, action: TPlayerAction) {
		this.setState({
			modal_action_type: action,
			modal_action_player: player_id,
		});
	}

	closeErrorModal() {
		this.props.clearErrors();
		this.props.hideErrorModal();
	}

	hasPlayerMatchStarted(player_id){
		const { players_by_id, actual_round } = this.props;

		const player = players_by_id[player_id];
		let has_player_started = false;

		actual_round.matches.forEach(match => {
			if(match.home_squad_id === player.squad_id || match.away_squad_id === player.squad_id){
				if(match.status !== "scheduled"){
					has_player_started = true;
				}
			}
		});
		return has_player_started;
	}
	

	checkIsPlayerChangeAvailable(player_id) {
		const { actual_round, players_by_id, league: { lockout: league_lockout } } = this.props;
		const player = players_by_id[player_id];
		const { status, lockout } = actual_round;
		const is_active = status === "active";
		const getLockoutKey = lockoutKey(this.props.league);
		
		if(getLockoutKey === "rolling"){
			return !this.hasPlayerMatchStarted(player_id);
		}

		if (league_lockout) {
			return !player || !player.locked;
		}

		if (is_active) {
			if (lockout === "full") {
				return false;
			}
			else {
				return !player || !player.locked;
			}
		}

		return true;
	}

	resize() {
		this.setState({
			is_mobile: isMobile()
		});
	}

	get json_list() {
		if (this.props.has_assistant_coach) {
			return ["rounds", "players", "venues", "squads", "coach_players"];
		}
		return ["rounds", "players", "venues", "squads"];
	}

	get league_current_round_fixtures() {
		const { league, selected_round_id } = this.props;
		return selected_round_id ?
			_.get(league, `fixture.${selected_round_id}`, []): [];
	}

	get finals_rounds() {
		const { league, rounds_ordered } = this.props;

		return getLeagueRoundAliases(league, rounds_ordered);
	}

	get player_stats() {
		const { is_current, league } = this.props;
		const statsBasis = getLeagueScoringBasis(league);
		const game_bar_keys = _.map(game_bar_stats(is_current, statsBasis), "key");
		return _.filter(player_stats, stat => {
			return !_.includes(game_bar_keys, stat.key);
		});
	}

	get mobile_stat() {
		const { mobile_stat } = this.state;
		return _.find(player_stats, { key: mobile_stat });
	}

	get score_stat() {
		const { is_current, league } = this.props;
		const { field_stat } = this.state;
		const statsBasis = getLeagueScoringBasis(league);
		return _.find(game_bar_stats(is_current, statsBasis), { key: field_stat });
	}

	handleFlipCards() {
		this.setState(state => ({
			flip: !state.flip
		}));
	}

	openPlayerPopup(player_id) {
		const { showModalForPlayerId } = this.props;
		showModalForPlayerId(player_id);
	}

	removePlayer({ id }: Object) {
		const { removePlayer, team } = this.props;
		removePlayer({
			player_id: id,
			team_id: team.id
		});
	}

	onMobileStatSelectChange({ currentTarget }: Object) {
		const mobile_stat = currentTarget.value;
		if (mobile_stat) {
			this.setState({
				mobile_stat
			});
		}
	}

	setCaptain(event) {
		const { team, updateMyTeam } = this.props;
		const { checkIsPlayerChangeAvailable } = this;
		LineupSetter.setCaptain({
			event,
			team,
			updateMyTeam,
			checkIsPlayerChangeAvailable,
			is_draft: true
		});
	}

	setViceCaptain(event) {
		const { team, updateMyTeam } = this.props;
		const { checkIsPlayerChangeAvailable } = this;
		LineupSetter.setViceCaptain({
			event,
			team,
			updateMyTeam,
			checkIsPlayerChangeAvailable,
			is_draft: true
		});
	}

	setEmergency(event: Object) {
		const { team, updateMyTeam, players_by_id } = this.props;
		const { currentTarget: { dataset: { id: id_string, position: pos_string } } } = event;


		const player_id = parseInt(id_string, 10);
		const player = players_by_id[player_id];

		if (_.isEmpty(player)) {
			return;
		}

		const position = pos_string ? parseInt(pos_string, 10) : player.positions[0];

		LineupSetter.setEmergencyDraft({
			player_id,
			position,
			team,
			lineup: this.lineup,
			updateMyTeam,
			checkIsPlayerChangeAvailable: this.checkIsPlayerChangeAvailable
		});
	}

	areSubstitutesActive() {
		return this.state.substitute_id !== 0;
	}
	isActiveSubstituteBench(){
		return (this.state.substitute_id !== 0 && this.state.substitute_is_bench);
	}

	isOutSubstitute(player: TPlayer, position_id: number) {
		const { substitute_id } = this.state;
		const is_subs = this.areSubstitutesActive();

		return is_subs && !_.isEmpty(player) && substitute_id === player.id;

	}

	isInSubstitute(player: TPlayer, position_id: number, position_index?: number) {
		const { substitute_position, substitute_player_positions } = this.state;

		const is_subs = this.areSubstitutesActive();
		const in_is_bench = position_id === undefined;
		const out_is_bench = isNaN(substitute_position);

		let in_position;
		let is_locked;

		const out_position = in_is_bench || substitute_player_positions.includes(position_id);
		if (_.isEmpty(player)) {
			// this empty player can become the in substitute if:
			//  - the player being subbed has a position that matches the empty spot
			//  - the empty spot is on the bench
			in_position = _.includes(substitute_player_positions, position_id)
				|| in_is_bench;
			is_locked = false;
		}
		else {
			in_position = out_is_bench || player.positions.includes(substitute_position);
			is_locked = !this.checkIsPlayerChangeAvailable(player.id);
		}
		return this.inSubstitute(is_subs, is_locked, in_position, out_position);
	}

	/*
	 * An In Substitution can happen if:
	 * - there is a substitution in process (out has to happen first)
	 * - The players aren't locked
	 * - The In sub position is valid
	 * - The Out sub position is valid
	 */
	inSubstitute(is_subs, is_locked, in_position, out_position) {
		return is_subs && !is_locked && in_position && out_position;
	}

	substitutePlayer({ currentTarget }: Object) {
		const {
			id: string_id,
			position: string_pos,
			position_index: string_pos_index,
			is_bench: string_is_bench
		} = currentTarget.dataset;

		const { players_by_id } = this.props;
		const id = parseInt(string_id, 10);
		const position = parseInt(string_pos, 10);
		const is_bench = string_is_bench? JSON.parse(string_is_bench): false;
		const { substitute_id, substitute_position, substitute_is_bench } = this.state;

		if (id === 0) {
			const position_index = parseInt(string_pos_index, 10);
			this.substituteEmptyPlayer({
				empty_in: { position, position_index },
				player_out: { id: substitute_id, position: substitute_position }
			});
		}

		if (substitute_id === id) {
			return this.cancelSubstitute();
		}
		if (substitute_id) {
			return this.tryToSubstitute({
				player_in: { id, position, is_bench },
				player_out: {
					id: substitute_id, position: substitute_position, is_bench: substitute_is_bench
				}
			});
		}

		const player = players_by_id[id];

		this.setState({
			substitute_id: id,
			substitute_position: position,
			substitute_player_positions: player.positions,
			substitute_is_bench: is_bench
		});
	}

	findLeader(id) {
		const { team: { lineup: { captain, vice_captain } } } = this.props;
		return _.findKey({ captain, vice_captain }, player_id => player_id === id);
	}

	substituteEmptyPlayer({ empty_in, player_out }) {
		const { team, updateMyTeam, players_by_id } = this.props;
		const { lineup } = this;
		const { emergency } = lineup;
		const player_leader = this.findLeader(player_out.id);
		const player_emg_pos = emgPosition(emergency, player_out.id);
		const player = players_by_id[player_out.id];
		const out_position = player_out.position || "bench";
		const in_position = empty_in.position || "bench";

		if (_.isEmpty(player)) {
			return;
		}

		// Move 0 into old player position
		const pos_row = lineup[out_position];
		const new_row = pos_row.map(id => id === player_out.id ? 0 : id);

		let new_lineup = {
			...lineup,
			[out_position]: [...new_row]
		};

		// Move player into old 0 position
		new_lineup[in_position][empty_in.position_index] = player_out.id;

		// Player is emergency and moving off bench
		if (isAllTrue([player_emg_pos, !empty_in.position])) {
			new_lineup = {
				...new_lineup,
				emergency: {
					...emergency,
					[player_emg_pos]: 0
				}
			};
		}

		// Player is captain/vc and moving to bench
		if (isAllTrue([player_leader, Boolean(empty_in.position)])) {
			new_lineup = {
				...new_lineup,
				[player_leader]: 0
			};
		}

		delete new_lineup.NaN;
		removeEmptyBenchPlayers(new_lineup.bench, 0);
		updateMyTeam({
			old_lineup: lineup,
			lineup: new_lineup,
			id: team.id
		});

		this.cancelSubstitute();
	}

	tryToSubstitute({ player_in, player_out }) {
		const { team, updateMyTeam, players_by_id } = this.props;
		const { lineup } = this;
		const { emergency } = lineup;
		const what_leader_out = this.findLeader(player_out.id);
		const what_leader_in = this.findLeader(player_in.id);
		const full_player_out = players_by_id[player_out.id];
		const full_player_in = players_by_id[player_in.id];

		if (_.isEmpty(full_player_in) || _.isEmpty(full_player_out)) {
			return;
		}

		const swaps = this.swapPlayersOnField({ player_in, player_out });

		let new_lineup = {
			...lineup,
			...swaps,
			emergency: this.swapEmergency(
				emergency,
				full_player_out,
				full_player_in
			)
		};

		if (what_leader_out && player_in.is_bench) {
			new_lineup = {
				...new_lineup,
				[what_leader_out]: player_in.id
			};
		}
		if (what_leader_in && player_out.is_bench) {
			new_lineup = {
				...new_lineup,
				[what_leader_in]: player_out.id
			};
		}
		updateMyTeam({
			old_lineup: lineup,
			lineup: new_lineup,
			id: team.id
		});

		this.cancelSubstitute();
	}

	swapPlayersOnField({ player_in, player_out }) {
		const { lineup } = this;
		const players_id = [player_in.id, player_out.id];
		const swapPlayersOnFound = player_id => {
			let found = players_id.includes(player_id),
				inverted_index = players_id.indexOf(player_id) ^ 1;

			return found ? players_id[inverted_index] : player_id;
		};

		const new_lineup = _.chain(lineup)
			.omit(["captain", "vice_captain", "emergency"])
			.mapValues(value => value.map(swapPlayersOnFound))
			.value();
		return { ...new_lineup };
	}

	swapEmergency(emergency, player_out, player_in) {
		const { lineup: { bench } } = this;
		const new_emg = { ...emergency };
		const out_pos = emgPosition(emergency, player_out.id);
		const in_pos = emgPosition(emergency, player_in.id);
		const out_on_bench = bench.indexOf(player_out.id) !== -1;
		const in_on_bench = bench.indexOf(player_in.id) !== -1;

		// Swapping inside of bench? Don't change either, I guess
		if (out_on_bench && in_on_bench) {
			return new_emg;
		}

		if (out_pos) {
			new_emg[out_pos] = 0;
			if (player_in.positions.includes(parseInt(out_pos, 10))) {
				new_emg[out_pos] = player_in.id;
			}
		}
		if (in_pos) {
			new_emg[in_pos] = 0;
			if (player_out.positions.includes(parseInt(in_pos, 10))) {
				new_emg[in_pos] = player_out.id;
			}
		}
		return { ...new_emg };
	}

	cancelSubstitute() {
		const { substitute_id } = this.state;
		if (substitute_id) {
			this.setState({
				substitute_id: 0,
				substitute_position: 0,
				substitute_player_positions: [],
				substitute_is_bench: false
			});
		}
	}

	renderTopControls() {
		const { team,  is_team_saving, team_id, is_static } = this.props;
		const { is_field_view, flip, is_mobile } = this.state;
		return <TeamTopControls
			team={team}
			is_list_view={!is_field_view}
			setFieldView={this.setFieldView}
			is_saving={is_team_saving}
			is_users_team={team_id === undefined}
			flip={flip}
			handleFlip={this.handleFlipCards}
			is_static={is_static}
			is_mobile={is_mobile}
			is_classic={false}
		/>;
	}

	setFieldView(is_field_view: boolean) {
		const storageItem = String(is_field_view);
		localStorage.setItem("field_view", storageItem);
		this.setState({
			is_field_view
		});
	}

	renderGameBar() {
		const {
			league,
			players_by_id,
			team,
			ladder_teams,
			selected_round_id,
			actual_round,
			rounds_with_matches,
			has_assistant_coach,
			selected_round,
			is_static,
			is_current,
			bye_rounds,
			bye_detector_selected,
			bye_detector_active,
			setByeDetectorActive,
			toggleByeDetectorRound,
		} = this.props;


		const actual_selected = selected_round_id === actual_round.id;

		const round_id =  actual_selected
			? actual_round.id
			: selected_round_id;

		const last_round_id = actual_round.id > 1 && actual_selected
			? round_id - 1
			: round_id;

		const last_round = _.find(rounds_with_matches, { id: last_round_id });
		
		return <TeamGameBar
			lineup={this.lineup}
			players_by_id={players_by_id}
			changeFieldStat={this.changeFieldStat}
			league={league}
			team={team}
			ladder_teams={ladder_teams}
			last_round={last_round}
			selected_round={selected_round}
			has_assistant_coach={has_assistant_coach}
			field_stat_value={this.state.field_stat}
			is_static={is_static}
			is_current={is_current}
			bye_rounds={bye_rounds}
			bye_detector_selected={bye_detector_selected}
			bye_detector_active={bye_detector_active}
			setByeDetectorActive={setByeDetectorActive}
			toggleByeDetectorRound={toggleByeDetectorRound}
		/>;
	}

	changeFieldStat({ currentTarget }: Object) {
		if (currentTarget.value) {
			this.setState({
				field_stat: currentTarget.value
			});
		}
	}

	renderField() {
		const {
			positions,
			players_by_id,
			has_assistant_coach,
			actual_round,
			player_ids,
			showModalForPlayerId,
			team,
			league,
			is_static,
			selected_round,
			is_current,
			bye_detector_selected,
			bye_detector_active,
			toggleFantasyCoachModal,
			user,
			rounds
		} = this.props;

		const { is_field_view, flip, is_mobile } = this.state;

		const is_my_team = team.user_id === user.id;

		const matches = selected_round ? selected_round.matches : [];
		const is_overflow = OverflowUtils.overflowAmount(league, this.lineup, positions) > 0;

		const props = {
			positions,
			players_by_id,
			has_assistant_coach,
			actual_round,
			player_ids,
			selected_round,
			showModalForPlayerId,
			rounds,
			team,
			league,
			removePlayer: this.removePlayer,
			setCaptain: this.setCaptain,
			setViceCaptain: this.setViceCaptain,
			setEmergency: this.setEmergency,
			substitutePlayer: this.substitutePlayer,
			cancelSubstitute: this.cancelSubstitute,
			openPlayerPopup: this.openPlayerPopup,
			is_mobile: this.state.is_mobile,
			field_stat: this.state.field_stat,
			areSubstitutesActive: this.areSubstitutesActive,
			substitute_is_bench: this.state.substitute_is_bench,
			substitute_id: this.state.substitute_id,
			isOutSubstitute: this.isOutSubstitute,
			isInSubstitute: this.isInSubstitute,
			matches,
			is_static,
			is_current,
			bye_detector_selected,
			bye_detector_active,
			mobile_stat: this.mobile_stat,
			score_stat: this.score_stat,
			toggleFantasyCoachModal,
			flip,
			setPlayerAction: this.setPlayerAction,
			is_classic: false,
			is_team_full: this.is_team_full,
			is_overflow: is_overflow,
			is_my_team
		};

		return is_field_view && !is_mobile
			? <FieldViewTeam
				{...props}
			/>
			: <ListViewTeam
				{...props}
			/>;
	}

	get lineup() {
		const { team: { lineup }, positions, league } = this.props;
		return OverflowUtils.normaliseLineup(league, lineup, positions);
	}
	get is_team_full() {
		const { team: { lineup }, league: { squad_size } } = this.props;
		let current_num_lineup = 0;
		if(lineup){
			POSITION_KEYS.forEach((position, index) => {
				if(lineup[position]){
					lineup[position].forEach(id => {
						if(id !== 0){
							current_num_lineup += 1;
						}
					});
				}
				
			});
		}
		const number_lineup = squad_size ? teamSizeFromSquad(squad_size) : -1;
		
		
		return current_num_lineup === number_lineup;
	}


	renderTeamInfo() {
		const { positions, is_static, league } = this.props;

		if (is_static) {
			return null;
		}

		return <TeamInfoBlock
			lineup={this.lineup}
			positions={positions}
			is_draft={true}
			has_captain={_.get(league, "captains")}
			has_emergencies={_.get(league, "emergency")}
			emergency_limit={_.get(league, "emergency_limit")}
		/>;
	}

	get has_overflow() {
		const { league, positions } = this.props;
		const { lineup } = this;

		const overflow = OverflowUtils.overflowAmount(league, lineup, positions);
		return overflow > 0;
	}

	renderWarningBanner() {
		const { league, positions } = this.props;
		const { lineup } = this;

		const overflow = OverflowUtils.overflowAmount(league, lineup, positions);
		// if (overflow > 0){
		// 	return <OverflowBanner />;
		// }

		const empty_pos = _(lineup)
			.omit(["bench", "emergency", "captain", "vice_captain"])
			.findKey((pos_row, key, ...rest) => pos_row.indexOf(0) !== -1);
		if (empty_pos && positions) {
			return <EmptyPositionBanner />;
		}

		if (overflow < 0) {
			return <EmptyEmergencyBanner />;
		}
		return null;
	}

	handleChange(e){
		const { setSelectedRoundId } = this.props;

		setSelectedRoundId(parseInt(e.target.value));
	}

	renderRoundSelector() {
		const { selected_round_id, rounds_ordered } = this.props;

		return <StyledSelect 
			name="round" 
			is_disabled={false} 
			value={selected_round_id}
			onChange={this.handleChange} 
		>
			{rounds_ordered.map(({ id }) => (
				<option key={id} value={id}>{`Round ${id}`}</option>
			))}
		</StyledSelect>;
	}
	
	getTeamLink(team: TDraftUserTeam) {
		const { user, is_classic, league_id } = this.props;

		const user_id = _.get(user, "id");

		const prefix = is_classic ? "/classic" : `/draft/league/${league_id}`;

		if (team.user_id === user_id) {
			return `${prefix}/team`;
		}

		return `${prefix}/team${is_classic ? "/user" : ""}/${team.id}`;
	}

	handleTeamChange(e){
		const { ladder_teams } = this.props;
		const team = _.filter(ladder_teams, { id: parseInt(e.target.value) })[0];
		window.location = this.getTeamLink(team);
	}

	renderTeamSelector() {
		const { ladder_teams, team } = this.props;

		return <StyledSelect 
			name="team" 
			is_disabled={false} 
			value={team.id}
			onChange={this.handleTeamChange} 
		>
			{_.map(ladder_teams, ladder_team => {
				return <option key={ladder_team.id} value={ladder_team.id}>{ladder_team.name}
				</option>;
			})}
		</StyledSelect>;
	}

	renderTitle() {
		const { team, user, ladder_teams, league_id } = this.props;
		return <StyledTeamTitle
			team={team}
			league_teams={ladder_teams}
			user={user}
			league_id={league_id}
			is_classic={false}
		/>;
	}

	renderTransactionsHeading() {
		const { league_id } = this.props;
		return (
			<PageSubTitleWithButton>
				Transactions
				{league_id && (
					<ButtonPrimaryLink to={`/draft/league/${league_id}/transactions`}>
						View all Transactions
					</ButtonPrimaryLink>
				)}
			</PageSubTitleWithButton>
		);
	}

	renderTransactions() {
		const { players_by_id, transaction_history } = this.props;
		const limit_transactions = transaction_history.slice(0, 3);

		return !_.isEmpty(transaction_history) &&<React.Fragment>
			{ this.renderTransactionsHeading() }
			<TransactionHistoryRFARow
				players_by_id={players_by_id}
				trade={limit_transactions}
				compressed
				league_team
			/>
		</React.Fragment>;
	}

	renderScoreSelect() {
		const {
			selected_round,
			is_current,
			league
		} = this.props;

		const statBasis = getLeagueScoringBasis(league);

		const round_started = roundHasStarted(selected_round);
		const game_stats = game_bar_stats(is_current, statBasis);

		const stats = round_started
			? game_stats
			: _.reject(game_stats, { 
				key: `${getLeagueScoringBasis(league)}.round_score` 
			}, selected_round);

		return <PoolSelectWrapper>
			<PoolSelect
				name="field_statistic"
				label=''
				onChange={this.changeFieldStat}
				value={this.state.field_stat}
				half_margin
				disabled={(!is_current)}
				no_label={true}
			>
				{stats.map(({ key, value }) => (
					<option value={key} key={key}>{value}</option>
				))}
			</PoolSelect>
		</PoolSelectWrapper>;
	}

	renderStatisticSelect() {
		const {
			is_static,
		} = this.props;

		const { is_mobile, mobile_stat } = this.state;

		if (!is_static || is_mobile) {
			return <PoolSelectWrapper is_mobile={is_mobile}>
				<PoolSelect
					name="mobile_statistic"
					label="Statistic"
					description={"Stat that will be shown"}
					onChange={this.onMobileStatSelectChange}
					value={mobile_stat}
					half_margin
				>
					{_.map(this.player_stats, ({ key, value }) => (
						<option value={key} key={key}>{value}</option>
					))}
				</PoolSelect>
			</PoolSelectWrapper>;
		}

		return null;
	}

	renderByeDetector() {
		const {
			bye_rounds,
			bye_detector_selected,
			bye_detector_active,
			setByeDetectorActive,
			toggleByeDetectorRound,
			is_static,
			has_assistant_coach,
			toggleFantasyCoachModal,
			is_partial_or_standard
		} = this.props;

		if (is_static) {
			return null;
		}

		return <ByeDetectorControls
			bye_rounds={bye_rounds}
			selected={bye_detector_selected}
			active={bye_detector_active}
			is_partial_or_standard={is_partial_or_standard}
			setActive={setByeDetectorActive}
			toggleRound={toggleByeDetectorRound}
			has_coach={has_assistant_coach}
			showFantasyCoachModal={toggleFantasyCoachModal}
			is_classic={false}
		/>;
	}

	renderPartialByeDetector() {
		const {
			bye_rounds,
			bye_detector_selected,
			bye_detector_active,
			setPartialActive,
			toggleByeDetectorRound,
			is_static,
			has_assistant_coach,
			toggleFantasyCoachModal,
			is_partial_or_standard
		} = this.props;

		if (is_static) {
			return null;
		}

		return <PartialByeDetectorControls
			bye_rounds={bye_rounds}
			selected={bye_detector_selected}
			active={bye_detector_active}
			is_partial_or_standard={is_partial_or_standard}
			setActive={setPartialActive}
			toggleRound={toggleByeDetectorRound}
			has_coach={has_assistant_coach}
			showFantasyCoachModal={toggleFantasyCoachModal}
			is_classic={false}
		/>;
	}

	renderMainContent() {

		const mobile_controls = <ControlsBlock>
			{this.renderPartialByeDetector() }
			{ this.renderByeDetector() }
			
		</ControlsBlock>;

		return <div>
			<TitleAndRoundWrapper>
				{ this.renderTitle() }
				{ this.renderRoundSelector() }
				
				{ this.renderTeamSelector() }
			</TitleAndRoundWrapper>
			{ this.renderWarningBanner() }
			{ this.renderTopControls() }
			<GameBarWrapper>
				<ScoreByeDetectorWrapper>
					
					{ this.renderScoreSelect() }
			
					{ this.renderGameBar() }
				</ScoreByeDetectorWrapper>
					
				
				{ mobile_controls }

				
        
				
			</GameBarWrapper>
			{ this.renderField() }
			{ this.renderTeamInfo() }
			{ this.renderTransactions() }
			<AdFantasyCoach type="leaderboard" small_screen_only />
		</div>;
	}

	renderAside() {
		return <aside>
			<Ad type="mrec" />
			<WidgetSpacer />
			<WidgetRfaOrder />
			<WidgetSpacer />
			<WidgetNews />
			<AdFantasyCoach type="mrec" large_screen_only />
		</aside>;
	}
	renderPlayerActionModal() {
		const { players_by_id, league, team, user, team: { players_margin } } = this.props;
		const { modal_action_player, modal_action_type } = this.state;
		if (modal_action_type === null || modal_action_player === null) {
			return null;
		}
		const is_my_team = team.user_id === user.id;
		const player = _.get(players_by_id, modal_action_player);
		return <PlayerActionModal
			players_margin={players_margin}
			closeClick={() => this.setState({ modal_action_type : null })}
			player={player}
			action={modal_action_type}
			removePlayer={this.removePlayer}
			is_my_team={is_my_team}
			delist_link={`/draft/league/${
				league.id
			}/players/${
				modal_action_player
			}/delist`}
			trade_link={`/draft/league/${
				league.id
			}/players/${
				modal_action_player
			}/trade`}
		/>;
	}

	render() {
		const {
			league,
			match:  { params: { league_id } },
			getModalElement,
		} = this.props;
		if (!_.isEmpty(league) && league.draft_status !== "complete") {
			return <Redirect to={`/draft/league/${league_id}/pre-draft`} />;
		}

		return (
			<React.Fragment>
				{ getModalElement(this.closeErrorModal) }
				{ this.renderPlayerActionModal() }
				<JsonFetcher
					fetch={this.json_list}
					interval={90000} /* Fetch new data once every 90s */
				/>
				{ this.state.preloader ? (<Preloader />): (
					<React.Fragment>
						<LeagueGameBar no_fetch={true} />
						<AdsContainer>
							<Ad />
						</AdsContainer>
						
						<PageContentWrapper className="playwright-mask-hidden">
							<FixedTwoColumnLayout>
								{ this.renderMainContent() }
								{ this.renderAside() }
							</FixedTwoColumnLayout>
						</PageContentWrapper>
						<MatchCentreBar />
						<Footer show_key />
					</React.Fragment>
				)}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state, props) => {
	const actual_round = selectors.rounds.getActualRound(state);
	const all_stats = state.players.all_stats;
		
	const { league_id } = props.match.params;

	const players_by_id = selectors.players.getExtendedPlayersById(state);
	Object.keys(players_by_id).map(id => {
		return({
			...players_by_id[id],
			custom_stats: {
				...players_by_id[id].custom_stats,
				..._.get(all_stats, id, {})
			}
		});
	});

	const has_assistant_coach = Boolean(selectors.getUser(state).assistant_coach);

	const team = state.teamsDraft.user_team;

	const league = selectors.leagueDraft.getLeague(state, props);

	const actual_round_id = _.get(actual_round, "id", 0);
	const rounds_with_matches = selectors.rounds.getRoundsWithMatches(state);
	const selected_round = _.find(rounds_with_matches, { id: props.selected_round_id });
	const team_id = _.get(props, "match.params.team_id");
	const url_round_id = _.get(props, "match.params.round_id");

	return {
		league_id,
		league,
		has_assistant_coach,
		data_loading_complete: _.every([true,
			!state.players.is_pending,
			!state.rounds.is_pending,
			!state.squads.is_pending,
			!state.venues.is_pending,
			!state.leagues.show.is_pending,
			!state.leagues.ladder.is_pending,
			!_.isEmpty(team),
		]),

		positions: state.positions.by_id,
		actual_round,
		players_by_id,
		last_round: selectors.rounds.getLastCompleteRound(state),
		ladder_teams: selectors.orderedLadderCalculated(state, props),
		ladder_by_id: selectors.leagues.ladder.getById(state),
		team,
		is_team_saving: state.teamsDraft.is_team_saving,
		error_text: state.teamsDraft.error,
		rounds_with_matches,
		rounds_ordered: getLeagueRounds(props.rounds_ordered, league),
		selected_round,
		is_static: actual_round_id > props.selected_round_id || team_id !== undefined,
		is_current: actual_round_id <= props.selected_round_id,
		team_id,
		url_round_id,
		user: selectors.getUser(state),
		transaction_history: selectors.getGroupedTransactions(state),
		rounds: selectors.rounds.getRounds(state)
	};
};
const mapDispatchToProps = {
	showModalForPlayerId: actions.showModalForPlayerId,
	fetchTeam: actions.teamsDraft.fetchTeam,
	showDraftLeague: actions.leagueDraft.showDraftLeague,
	updateMyTeam: actions.teamsDraft.postTeamUpdate,
	fetchLadder: actions.leagueDraft.ladderDraftLeague,
	removePlayer: actions.teamsDraft.fetchRemovePlayer,
	clearErrors: actions.teamsDraft.clearTeamsDraftErrors,
	fetchTransactionHistory: actions.leagueDraft.fetchTransactionHistory,
	fetchFavourites: actions.fetchFavourites,
	fetchAllCustomStats: actions.fetchAllCustomStats,
	fetchAllCoachCustomStats: actions.fetchAllCoachCustomStats,
};

export const LeagueTeam = compose(
	withRoundsSelector,
	withCoachesBox,
	withByeDetector,
	withDraftPlayerModal,
	withFantasyCoachModal,
	withErrorModal,
	connect(
		mapStateToProps,
		mapDispatchToProps
	),
)(LeagueTeamComponent);



export default LeagueTeam;