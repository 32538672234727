// @flow
import React from "react";
import styled from "styled-components";

const SQUAD_LOGOS_PATH = process.env.REACT_APP_SQUAD_LOGOS_PATH || "";

const Img = styled.img`
	display: inline-block;
	width: 100%;
`;


type Props = {
	squad_id: number
}

export const SquadLogo = ({ squad_id }: Props) => (
	<Img src={`${SQUAD_LOGOS_PATH}${squad_id}.png`} alt="AFL Club Logo" />
);

export default SquadLogo;