// @flow
import React from "react";
import SvgIconContainer from "../SvgIconContainer";

export const ConsolationTrophy = (
	{
		unit = "em",
		...rest
	}: Object = {}) => (
	<SvgIconContainer viewBox="0 0 24 24" {...rest} unit={unit}>
		<title>Spoon and Trophy</title>
		<g>
			<path d="M8.59557089,13.4386792 C7.75641449,13.6044625 5.77781881,13.6828984 
			3.95967892,11.4176059 C1.57333269,8.44436258 1,3.19836641 1,3.19836641 
			L4.08122408,3.19836641 C3.79607305,1.35639256 3.6906172,0 3.6906172,0 
			L20.3724439,0 C20.3724439,0 20.266988,1.35639256 19.981837,3.19836641 
			L22.5249376,3.19836641 L23,3.19836641 C23,3.19836641 22.4266673,8.44436258 
			20.0403211,11.4176059 C18.2560189,13.6407386 16.3171779,13.6065702 
			15.4524578,13.4478302 C15.3017871,13.5392644 15.1478811,13.6240623 
			14.9912095,13.7022238 L14.9912095,15.7193429 L13.3768391,15.7193429 
			L13.3768391,19.1901985 L15.2602712,19.1901985 L15.2602712,21.0559122 
			L16.3365181,21.0559122 L16.3365181,24 L7.72654301,24 L7.72654301,21.0559122 
			L8.80278989,21.0559122 L8.80278989,19.1901985 L10.6862219,19.1901985 
			L10.6862219,15.7193429 L9.07185161,15.7193429 L9.07185161,13.7022238 
			C8.90996588,13.621461 8.75103294,13.5336128 8.59557089,13.4386792 
			L8.59557089,13.4386792 Z M6.73037465,11.6901955 C5.61186707,10.0876015 
			4.87544857,7.43670013 4.40802335,5.06408015 L2.96846318,5.06408015 
			C3.3302907,6.67617813 3.98438429,8.95125479 4.99861451,10.2874539 
			C5.61113735,11.0944231 6.21231745,11.5005685 6.73037465,11.6901955 
			L6.73037465,11.6901955 Z M17.3557236,11.6569617 C17.8537526,11.454747 
			18.4225035,11.0501028 19.0013855,10.2874539 C20.0156157,8.95125479 
			20.6697093,6.67617813 21.0315368,5.06408015 L19.6550377,5.06408015 
			C19.1908291,7.42037265 18.4613168,10.0511297 17.3557236,11.6569617 
			L17.3557236,11.6569617 Z" />
		</g>
	</SvgIconContainer>
);
export default ConsolationTrophy;