// @flow
import * as React from "react";
import styled, { css } from "styled-components";
import Player from "../../../components/Icons/EmptyPlayer";
import Swap from "../../../components/Icons/Swap";
import Undo from "../../../components/Icons/Undo";
import Close from "../../../components/Icons/Close";
import colors, { lightenColor } from "../../../assets/css/colors";

const PlayerWrapper = styled.div`
	height: 50px;
	box-shadow: 0 0 10px 0 rgba(38,36,36,0.5);
	width: 100%;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	background-color: #fff;
	cursor: pointer;
	border-radius: 5px;
	padding: 0;

	position: relative;
	z-index: 1;
	
	${({ is_substitute }) => is_substitute ? css`
		border: 3px solid #198DE1;
		position: relative;
	` : "" }
`;
const EmptyAvatar = styled.div`
	flex-basis: 7%;
	align-self: flex-end;
	height: 90%;
	margin-left: 2px;

	> svg {
		height: 100%;
		width: auto;
	}
`;
const PositionLabel = styled.div`
	color: #1D4073;
	font-size: 12px;
	line-height: 1.17;
	margin-left: 10px;
	font-weight: 900;
`;
const ButtonsWrapper = styled.div`
	margin-left: auto;
	display: flex;
	align-items: center;
	justify-content: flex-end;
`;
const CommandButton = styled.button`
	display: ${({ is_hidden }) => is_hidden ? "none" : "block"};
	border: none;
	outline: none;
	background: rgba(0,0,0,0);
	padding: 0;
	margin-right: ${({ is_substitute }) => is_substitute ? "35px" : "8px" };
	&:last-child {
		margin-right: 5px;
	}
	height: 25px;
	width: 25px;
	> svg {
		height: 25px;
		width: 25px;
	}
	cursor: pointer;
`;

const CommandButtonUndo = styled(CommandButton)`
	background: #D92425;
	color: #fff;
	width: 25px;
	height: 25px;
	border-radius: 2px;

	transition: background-color 0.2s linear;

	> svg {
		height: 25px;
		width: 25px;
	}

	&:hover {
		background-color: ${lightenColor(colors.warning, 0.2)};
	}
`;

const AbsoluteButtonUndo = styled.button`
	position: absolute;
	top: -2px;
	right: -2px;
	border: none;
	color: #fff;
	background: #198DE1;
	border-radius: 0 0 0 5px;
	width: 29px;
	height: 29px;
	padding: 0;
	cursor: pointer;
`;

type Props = {
	position_id: number,
	position_full_name: string,
	onClick?: Function,
	is_bench?: boolean,
	position_index?: number,
	is_substitute?: boolean,
	out_substitute?: boolean,
	in_substitute?: boolean,
	substitutePlayer?: Function,
	removePlayerOnTrade: Function,
	previous_id?: number,
	trade_id?: number,
	is_utility?: boolean
};

export const TradePlayerEmpty = ({
	position_id,
	position_index,
	position_full_name,
	is_bench,
	onClick,
	is_substitute,
	in_substitute,
	substitutePlayer,
	removePlayerOnTrade,
	previous_id,
	trade_id,
	is_utility,
}: Props) => {
	return (
		<PlayerWrapper is_substitute={is_substitute} className="popped-up" z={position_index}>
			<EmptyAvatar>
				<Player />
			</EmptyAvatar>
			<PositionLabel>
				Swap or trade in a {position_full_name}
			</PositionLabel>

			<ButtonsWrapper>
				<CommandButton
					onClick={substitutePlayer}
					data-position={position_id}
					data-position_index={position_index}
					data-is_bench={is_bench}
					data-is_trade_out={true}
					data-id={previous_id}
					is_substitute={is_substitute}
					data-is_utility={is_utility}
				>
					<Swap size={2.5}/>
				</CommandButton>
				{is_substitute ? (
					<AbsoluteButtonUndo
						onClick={substitutePlayer}
						data-position={position_id}
						data-position_index={position_index}
						data-is_bench={is_bench}
						data-is_trade_out={true}
						data-id={previous_id}
						data-is_utility={is_utility}
					>
						<Close size={2} />
					</AbsoluteButtonUndo>
				) : (
					<CommandButtonUndo
						onClick={removePlayerOnTrade}
						data-position={position_id}
						data-position_index={position_index}
						data-previous_id={trade_id ||previous_id}
						data-is_bench={is_bench}
						data-is_utility={is_utility}
					>
						<Undo size={2} />
					</CommandButtonUndo>
				) }

			</ButtonsWrapper>
		</PlayerWrapper>
	);
};

export default TradePlayerEmpty;