// @flow
import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import * as _ from "lodash";

import type {
	TRootStore,
	TSquad,
	TRound
} from "../../modules/types";

import * as selectors from "../../modules/selectors";
import {getClubLogoIndigenous} from "../../utils/clubLogos";
import byeLogo from "../../assets/images/bye-light.svg";

const Container = styled.div`
	img {
		width: 30px;
		height: 30px;
	}

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
`;

// const NextBadge = styled.p`
// 	display: inline-flex;
// 	flex-direction: row;
// 	justify-content: center;
// 	align-items: center;
// 	width: 40px;
// 	height: 16px;
// 	border-radius: 1px;
// 	box-sizing: border-box;
// 	padding-top: 1px;
// 	background: ${colors.form.base};
// 	color: ${colors.secondary.accentGrey};
// 	font-size: 12px;
// 	font-family: TitilliumUpright;
// 	font-weight: 700;
// 	margin-top: ${({ is_compressed }) => is_compressed ? `0`: `5px`};
// 	span {
// 		display: block;
// 		font-size: 10px;
// 		color: ${colors.secondary.paleGrey};
// 		&:after {
// 			content: "\00a0\00a0";
// 			font-size: 0.8em;
// 		}
// 	}
// `;

const getExtendedMatch = (squad_id, matches, squads) => {
	// get the match
	const match = matches.find(match =>
		match.away_squad_id === squad_id || match.home_squad_id === squad_id);

	const home_squad = _.get(match, "home_squad_id");
	const away_squad = _.get(match, "away_squad_id");

	const is_home_team = home_squad === squad_id;
	const opponent_id = is_home_team ? away_squad : home_squad;

	return {
		...match,
		is_home_team,
		opponent: squads.find(s => s.id === opponent_id)
	};
};

// const nextMatchIsBye = (squad_id, actual_round) => {
// 	return actual_round.bye_squads.includes(squad_id);
// };

type Props = {
	is_compressed: boolean,
	squad_id: number,
	actual_round: TRound,
	squads: Array<TSquad>,
	className: string
};

const NextSquadOpponentComponent = ({
	is_compressed,
	squad_id,
	actual_round,
	squads,
	className
}:Props) => {
	
	const extended_match = getExtendedMatch(squad_id, actual_round.matches, squads);
	const opponentSquadID = _.get(extended_match, "opponent.id",0);
	const clubLogo = getClubLogoIndigenous(actual_round.id, opponentSquadID);
	const imgSrc = opponentSquadID ? clubLogo : byeLogo;
	// let prefix = extended_match.is_home_team ? 'V' : '@';
	// let team_abbr = _.get(extended_match, 'opponent.short_name');
	// if(nextMatchIsBye(squad_id, actual_round)) {
	// 	prefix = '-';
	// 	team_abbr = 'BYE';
	// }

	return (
		<Container className={className}>
			{!is_compressed && (
				<React.Fragment>
					<img src={imgSrc} alt="AFL club logo" />
				</React.Fragment>
			)}
		</Container>
	);
};

const mapStateToProps = (state: TRootStore, props) => ({
	squads: selectors.squads.getSquads(state),
	actual_round: props.actual_round || selectors.rounds.getActualRound(state),
});

export const NextSquadOpponent = connect(mapStateToProps)(NextSquadOpponentComponent);

export default NextSquadOpponent;