// @flow
import styled from "styled-components";
import { THead } from "../../components/TableX";
import { below } from "../../assets/css/media";

export const TableHead = styled(THead)`
  .table-header-row{
    border-bottom: 2px solid #cad2d8;
    .absolute-group{
      position: absolute;
      min-width: 380px;
      display: flex;
      flex-direction: row;
      z-index: 98;
      border-bottom: 0;

      th{
        border-bottom: none;
      }
      ${below.phone`
        min-width: 210px;
      `}
    }
    .absolute-group-left{
      position: sticky;
      left: 0;
      min-width: 220px;
      background-color: white;
      display: flex;
      flex-direction: row;
      z-index: 96;
      border-bottom: 2px solid #cad2d8;;
      th{
        border-bottom: 2px solid #cad2d8;;
      }
      ${below.phone`
        min-width: 100px;
      `}
    }
    .absolute-group-right{
      position: sticky;
      right: 0;
      background-color: white;
      display: flex;
      flex-direction: row;
      z-index: 96;
      border-bottom: 2px solid #cad2d8;;
      th{
        border-bottom: 2px solid #cad2d8;
      }
      ${below.phone`
        min-width: 40px;
        width: 40px;
      `}
      
    }
  }
`;

export default TableHead;