// @flow
import * as React from "react";
import * as _ from "lodash";
import ConsolationTrophy from "../Icons/ConsolationTrophy";
import Spoon from "../Icons/Spoon";
import TrophySpoon from "../Icons/TrophySpoon";
import Trophy from "../Icons/Trophy";
import { isAnyTrue } from "../../helpers";
import { CupWrapper } from "./FixtureWrapper";



type TTeam = {
	is_premier?: boolean,
	is_consolation?: boolean,
	is_spoon?: boolean,
};

const getIcon = (team?: TTeam) => {
	if (!team) {
		return null;
	}
	if (team.is_spoon && (isAnyTrue([Boolean(team.is_premier), Boolean(team.is_consolation)]))) {
		return <TrophySpoon />;
	}
	if (team.is_premier) {
		return <Trophy />;
	}
	if (team.is_consolation) {
		return <ConsolationTrophy />;
	}
	if (team.is_spoon) {
		return <Spoon />;
	}
	return null;
};

export const renderCup = (
	team?: TTeam, 
	reverse: boolean = false, 
	show_mobile: boolean = false
) => {
	const show = show_mobile 
		&& (_.get(team, "is_spoon") || _.get(team, "is_premier") || _.get(team, "is_consolation"));
	return <CupWrapper 
		{...team} reverse={reverse} show_mobile={show_mobile}
		className={show ? "show-mobile" : ""}
	>
		{ getIcon(team) }
	</CupWrapper>;
};

export default renderCup;