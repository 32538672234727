// @flow
import styled from "styled-components";

import colors from "../../assets/css/colors";

export const TableFlexRow = styled.div.attrs({
	role: "row"
})`
	width: 100%;
	display: flex;
	justify-content: space-between;
	height: ${({ height }) => height || "auto"};
	text-align: center;
	align-items: center;
	background: #fff;
	border-bottom: 1px solid ${colors.secondary.paleGrey};
	box-sizing: border-box;
	> div:first-child {
		display: flex;
		align-items: center;
		text-align: left;
	}
`;

export default TableFlexRow;