// @flow
import { call, put, takeLatest } from "redux-saga/effects";
import * as actions from "../../actions/index";

export const createSagas = (API: Object): Object => {
	function* fetchChecksums() {
		try {
			const result = yield call(API.checksums.fetch);
			yield put(actions.fetchChecksumsSuccess(result));
		}
		catch (e) {
			if (process.env.NODE_ENV !== 'test') {
				console.error(e);
			}
			yield put(actions.fetchChecksumsFailed(e.message));
		}
	}

	function* watch() {
		yield takeLatest(actions.fetchChecksums, fetchChecksums);
	}

	return {
		fetchChecksums,
		watch,
	};
};


export default createSagas;