// @flow
import React from "react";
import styled from "styled-components";
import * as _ from "lodash";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import MATCH_STATS from "../../utils/matchStats";
import { score_settings } from "../../helpers";
import * as selectors from "../../modules/selectors";
import colors from "../../assets/css/colors";
import LockCircle from "../Icons/LockCircle";

import * as actions from "../../modules/actions";

import Preloader from "../Preloader";
import {getClubLogoIndigenous} from "../../utils/clubLogos";
import { below } from "../../assets/css/media";
import type { TPLeague, TPlayer, TRound } from "../../modules/types";
import {
	SectionHeader,
	StatHeadItem,
	StatHeadRow,
	StatItem,
	StatItemRow,
} from "./PlayerPopupStyles";




const FcLink = styled.a`
	color: ${colors.coach};
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: flex-start;
`;


const FlexCol = styled.div`
    display: flex;
    flex-direction: column;
	.opponent-item{
		min-width: 80px;
	}
	${below.phone`
		overflow: auto;
		.hidden-mob{
			display: none;
		}
		.opponent-item{
			min-width: unset;
		}
	`}
	
`;



type MatchStatProps = {
    is_classic: boolean,
    league: TPLeague,
    rounds: TRound[],
    player: TPlayer,
    has_coach: boolean,
    actual_round: number,
    fullLink: string,
    player_matches: Object,
    fetchPlayerMatches: Function,
    fetchCoachStats: Function,
    squads: Object,
    classic_opponent_stats: [],
    draft_opponent_stats: [],
    fetchPlayerVenueClassic: typeof actions.fetchPlayerVenueStats,
	fetchPlayerVenueDraft: typeof actions.fetchPlayerVenueStatsDraft,
	fetchPlayerOpponentClassic: typeof actions.fetchPlayerOppositionStats,
	fetchPlayerOpponentDraft: typeof actions.fetchPlayerOppositionStatsDraft
}


class OppositionStatsComponent extends React.PureComponent<MatchStatProps> {
	constructor(props) {
		super(props);
		
		_.bindAll(this, [
			// 'openSubModal',
			// 'closeSubModal'
		]);
	}

	componentDidMount(){
		this.props.fetchPlayerMatches(this.props.player.id);
		this.props.fetchCoachStats();
		this.getPlayerOpponentVenueData();
	}

	getPlayerOpponentVenueData() {
		const { league } = this.props;
		const league_id = _.get(league, "id");
		const isCustomScoring = _.get(league, "custom_scoring_enabled", 0);
		if(!Boolean(isCustomScoring)){
			this.props.fetchPlayerOpponentClassic();
			return;
		}
		this.props.fetchPlayerOpponentDraft(league_id);
	}

	get stats() {
		const {league} = this.props;
		const stats = [];

		const gamesPlayed = {
			name: "GP",
			id: "GP",
			tooltip: "Games Played against opponent",
		};
		if(this.isLeagueCustomScoring){
			stats.push({
				name: "% TOG", 
				id: "TOG", 
				tooltip: "Percentage of time spent on ground"
			});
			Object.keys(league.custom_scoring).forEach(i => {
				stats.push({ 
					id: i, 
					name: i, 
					tooltip: score_settings[i].title + ` (${league.custom_scoring[i]} points)`, 
					score_per: league.custom_scoring[i]
				});
			});
			stats.push({
				name: "Score", 
				id: "stats.score", 
				tooltip: "Total Score for Game"
			});
		}
		else{
			MATCH_STATS.played.forEach(i => { 
				stats.push(i);
			});
		}
		return [gamesPlayed, ...stats];
	}
	get squadsToUse() {
		const playerSquad = this.props.player.squad_id;
		const squadsByID = this.props.squads;
		return Object.keys(squadsByID).map((squadID =>squadsByID[squadID]))
			.filter((squad => squad.id !== playerSquad));
	}
	
	get isLeagueCustomScoring() {
		return Boolean(this.props.league) && this.props.league.custom_scoring_enabled;
	}

	get table_body() {
		const { has_coach,
			draft_opponent_stats, 
			classic_opponent_stats,
			league,
			player
		} = this.props;
		if(has_coach){
			return this.squadsToUse.map((squad, index) => {
				return(
					<StatItemRow key={`${index}-stat-item-opposition`}>
						<StatItem>
							{this.getOpponentImage(squad)}
						</StatItem>
						<StatItem className="hidden-mob opponent-item">
							{squad.name}
						</StatItem>
						{this.stats.map(stat => {
							const isCustomScoring = 
								Boolean(_.get(league, "custom_scoring_enabled", 0));
							const selectorData = 
								isCustomScoring 
									? draft_opponent_stats[player.id] 
									: classic_opponent_stats[player.id];
							const oppositionData = _.get(selectorData, squad.id, {});
							return(
								
								this.displayOppositionStat(stat, oppositionData)
						
							);
						})}
					</StatItemRow>
				);
			});
		}
		return (
			<StatItemRow>
				<FcLink href={"/coach-subscription"} target="_blank">
					<LockCircle size={2}/>
				</FcLink>
			</StatItemRow>
			
		);
	}

	displayOppositionStat (stat: Object, oppositionData) {
		const {player} = this.props;
		
		const isMatchPlayed = Boolean(oppositionData["score"]) && oppositionData["score"] !==0;
		const playedDefault = isMatchPlayed ? 0 : "--";
		if(oppositionData[stat.id]){
			return  <StatItem rel={stat.id} key={`${stat.id}-${player.id}`}>
				{oppositionData[stat.id]}
			</StatItem>;
		}
		if(stat.id.includes("score")){
			const displayScore = oppositionData["score"] ? oppositionData["score"] : playedDefault;
			return  <StatItem rel={stat.id} key={`${stat.id}-${player.id}`}>
				{displayScore}
			</StatItem>;
		}
		return <StatItem rel={stat.id} key={`${stat.id}-${player.id}`}>{playedDefault}</StatItem>;
	};

	getOpponentImage(opponent) {
		const opponentID = opponent.id;
		const logo = getClubLogoIndigenous(this.props.actual_round, opponentID);
		return <img src={logo} alt="Opponent Logo"/>;
	}


	render() {
		const {
			player_matches, 
			player, 
		} = this.props;
		

        
		if(!player_matches[player.id]){
			return <Preloader />;
		}
		
        
		return(
			<FlexCol>
				<SectionHeader>
					<p>Opposition Stats</p>
				</SectionHeader>
				<StatHeadRow>
					<StatHeadItem className="hidden-mob">
						{" "}
					</StatHeadItem>
					<StatHeadItem className="opponent-item">
                        Opp
					</StatHeadItem>
					{this.stats.map((stat, index) => {
						return(
							<StatHeadItem key={`opposition-${index}-item`} is_fc={false}>
								{stat.name}
							</StatHeadItem>
						);
					})}
				</StatHeadRow>
				{this.table_body}
				
				
			</FlexCol>
		);
		
	}
}

const mapStateToProps = state => ({
	menu_open: state.uiMenu.open,
	league_id: state.leagues.show.league_id,
	player_matches: state.players.matches_by_id,
	squads: state.squads.by_id,
	classic_opponent_stats: selectors.players.getPlayerOpponentStatsClassic(state),
	draft_opponent_stats: selectors.players.getPlayerOpponentStatsDraft(state),
});

const mapDispatchToProps = {
	fetchPlayerMatches: actions.fetchPlayerMatches,
	fetchCoachStats: actions.fetchCoachPlayers,
	fetchPlayerOpponentClassic: actions.fetchPlayerOppositionStats,
	fetchPlayerOpponentDraft: actions.fetchPlayerOppositionStatsDraft
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OppositionStatsComponent));