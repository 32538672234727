// @flow
import React, {useState} from "react";
import {get} from "lodash";
import {useDispatch, useSelector} from "react-redux";

import { Redirect } from "react-router-dom";
import {
	Exist, 
	// InputItem,
	Preloader
} from "../../../components";
import {Landing} from "../../../components/Landing";
import { dataLayer } from "../../../utils/GTM";
import {
	// getAfliDIdentityToken,
	// getAfliDUser, 
	getUser, getUserActivateError, getUserActivatePending
} from "../../../modules/selectors";
import { activateUser } from "../../../modules/actions";
import { IS_TERMS_CLOSED } from "../../../modules/constants";
// import { getSkillLevel } from "../../../helpers";
import { isAuthenticated } from "../../../utils/auth";
import {
	AFLiDModalContentWrapper,
	AFLiDButonWrap,
	AFLIDButtonDark,
	CheckBoxLabel,
	CheckBoxWrap,
	// InputWrap,
	ModalCheckbox,
	ModalContentNoPadMob,
	// ModalInputWrap,
	ModalSpan,
	ModalWrap,
	PageTitleStyled,
	ErrorContent,
	// SelectWrap,
} from "./../afliDModalStyles";
import { AFLiDModalContentCheckToken } from "./AFLiDModalContentCheckToken";
import { ToyatoTerms } from "./ToyotaTerms";





function AfliDMiniRego() {
	// const afliDUser = useSelector(getAfliDUser);
	const dispatch = useDispatch();
	const user = useSelector(getUser);
	// const identity_token = useSelector(getAfliDIdentityToken);
	const userFirstName = get(user, 'firstname');
	const userActivateError = useSelector(getUserActivateError);
	const userActivateErrorMsg = get(userActivateError,"message","");
	const userActivatePending = useSelector(getUserActivatePending);
	// const userTeamName = get(user, 'team_name', "");
	// const [teamName,setTeamName] = useState(userTeamName);
	const [termscheckbox,setTermsCheckBox] = useState(false);
	const [sponsorcheckbox,setSponsorCheckBox] = useState(false);

	const isAuth = isAuthenticated();
	const userRecovered = get(user, 'recovered', 0);

	// const [skill_level, setSkillLevel] = useState("");

	// const onChangeSkillLevel = event => {
	// 	setSkillLevel(event.target.value);
	// };

	// const handleUpdateTeamName = event => {
	// 	setTeamName(event.target.value);
	// };
	const handleUpdateTerms = () => {
		setTermsCheckBox(!termscheckbox);
	};
	const handleUpdateSponsor = event => {
		setSponsorCheckBox(!sponsorcheckbox);
	};

	const handleGoogleEvent = () => {

		const origin = localStorage.getItem('original-click') || "";
		if(origin === ""){
			return;
		}
		dataLayer({
			'event': `${origin}-user-recovered`
		});
		localStorage.removeItem('original-click');
	};

	const createYourTeam = event => {
		event.preventDefault();
		const payload = {
			// team_name: teamName,
			terms: termscheckbox ? 1:0,
			// afl_identity_token: identity_token,
			toyota: sponsorcheckbox ? 1:0,
			// skill_level
		};
		dispatch(activateUser(payload)); // recover user (activate)
		handleGoogleEvent();
	};

	

	if(isAuth && userRecovered) {
		return <Redirect to="/game-selection" />;
	}

	const privacyPolicy = "privacy/privacy-statement/?ref=Net-Footer-Corp-Privacy";

	return (
		<React.Fragment>
			<Landing>
				<AFLiDModalContentCheckToken>
					<AFLiDModalContentWrapper>
						<PageTitleStyled>
							Update your preferences
						</PageTitleStyled>
						<ModalWrap>
							<form onSubmit={createYourTeam}>
								<ModalContentNoPadMob>
									<ModalSpan>
										Welcome back<strong>{userFirstName}</strong>
									</ModalSpan>
									<ModalSpan>Confirm some details to get started</ModalSpan>
								</ModalContentNoPadMob>
								{/* <ModalInputWrap>
									<InputWrap>
										<InputItem
											name="teamName"
											placeholder="Enter Your Team Name Here"
											type="name"
											required
											onChange={handleUpdateTeamName}
											value={teamName}
										/>
									</InputWrap>
								</ModalInputWrap>	 */}
								{/* <SelectWrap>
								<Select
									name="skill_level"
									onChange={onChangeSkillLevel}
									value={skill_level}
									required={true}
								>
									{getSkillLevel.map(item => (
										<option key={item.value} value={item.value}>
											{item.text}
										</option>
									))}
								</Select>
							</SelectWrap>
							*/}
								<ModalContentNoPadMob>
									<Exist when={!IS_TERMS_CLOSED}>
										<CheckBoxWrap>
											<ModalCheckbox
												type="checkbox"
												id="termscheckbox"
												name="termscheckbox"
												value={termscheckbox}
												required
												onChange={handleUpdateTerms}
											/>
											<CheckBoxLabel htmlFor="termscheckbox">
												I have read and accepted {" "} <a
													href="https://www.afl.com.au/privacy"
													target='_blank'
													rel="noopener noreferrer">
												AFL's Privacy Policy*
												</a>, {" "}<a href={
													"http://www.telstra.com.au/"
												+ privacyPolicy
												}
												target='_blank' rel="noopener noreferrer">
												Telstra's Privacy Policy</a> and the {" "} 
												<a href="/terms-conditions" target='_blank'>
												Terms & Conditions</a>. {" "}
												I also agree to receiving Tipping/Fantasy 
												emails about the competition.
											</CheckBoxLabel>
										</CheckBoxWrap>
									</Exist>
									
									<CheckBoxWrap>
										<ModalCheckbox
											type="checkbox"
											id="sponsorcheckbox"
											name="sponsorcheckbox"
											value={sponsorcheckbox}
											onChange={handleUpdateSponsor}
										/>
										<CheckBoxLabel htmlFor="sponsorcheckbox">
											<ToyatoTerms />
										</CheckBoxLabel>
									</CheckBoxWrap>

									<AFLiDButonWrap>
										<AFLIDButtonDark>Play Now</AFLIDButtonDark>
									</AFLiDButonWrap>
								</ModalContentNoPadMob>
								<Exist when={userActivateErrorMsg !== ""}>
									<ErrorContent>{userActivateErrorMsg}</ErrorContent>
								</Exist>
							</form>
						</ModalWrap>
					</AFLiDModalContentWrapper>
				</AFLiDModalContentCheckToken>
			</Landing>
			<Exist when={userActivatePending}>
				<Preloader />
			</Exist>
		</React.Fragment>
	);
}

export default AfliDMiniRego;