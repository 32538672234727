import { createReducer } from "redux-act";

import * as actions from "../../actions";

const initial_state = {
	open: false,
	pathname: window.location.pathname
};

export const uiMenu = createReducer({}, initial_state);

uiMenu.on(actions.uiMenu.openHamburgerMenu, state => ({
	...state,
	open: true
}));

uiMenu.on(actions.uiMenu.closeHamburgerMenu, state => ({
	...state,
	open: false
}));

uiMenu.on(actions.uiMenu.setPathname, (state, payload) => ({
	...state,
	pathname: payload
}));