// @flow
import styled from "styled-components";
import colors from "../../assets/css/colors";

export const SearchField = styled.input`
	font-family: SourceSansPro;
	font-weight: 600;
	background: #fff;
	height: 100%;
	width: 100%;
	font-size: 13px;
	line-height: 1.5;
	color: ${colors.primary.primary};
	outline: none;
	box-sizing: border-box;
	padding: 0 48px 0 12px;
	border-radius: 2px;
	border: 1px solid ${colors.secondary.paleGrey};
	
	&::placeholder {
		color: ${colors.secondary.accentGrey};
	}
`;



export default SearchField;