import _ from "lodash";
import { createSelector } from "reselect";
import type { TRootStore } from "../../types";
import { LoadingStatus } from "../../constants";

export const getPlayerNews = ({ playerNews }: TRootStore) => playerNews;

export const getPlayerSpecificNews = createSelector(
	getPlayerNews,
	news => (playerID, playerTeamID) => 
		_.get(news.news, `${playerTeamID}.${playerID}`)
);

export const isPlayerSpecificNewsIdle = createSelector(
	getPlayerNews,
	news => {
		const status = _.get(news, "loadingStatus", LoadingStatus.IDLE);
		return status === LoadingStatus.IDLE;
	}
);

export const isPlayerSpecificNewsLoading = createSelector(
	getPlayerNews,
	news => {
		const status = _.get(news, "loadingStatus", LoadingStatus.IDLE);
		return status === LoadingStatus.LOADING;
	}
);
export const isPlayerSpecificNewsSuccess = createSelector(
	getPlayerNews,
	news => {
		const status = _.get(news, "loadingStatus", LoadingStatus.IDLE);
		return status === LoadingStatus.SUCCESS;
	}
);