// @flow
import styled from "styled-components";

export const TeamPositionInfo = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: #7F8A90;
	font-size: 12px;
	margin-bottom: 15px;
	padding: 0 5px;
	
	b {
		font-weight: 600;
	}
`;

export default TeamPositionInfo;