// @flow
import * as React from "react";
import _ from "lodash";
import styled from "styled-components";
import Locked from "../../components/Icons/Locked";
import { BorderedIcon, FlexDiv, ErrorField, Exist } from "../../components";
import colors from "../../assets/css/colors";

import { Label, Select, FormGroup, Tooltip, LabelWrapper  } from "../../components/Form";
export const BorderIconWrap = styled.div`
    height: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
		cursor: pointer;
`;
const getOptions = options => {
	return _.map(options, option => (
		<option
			key={option.value}
			value={option.value}
			disabled={option.disabled}
			className={!option.value ? "hidden" : null}
		>
			{option.text}
		</option>
	));
};

const is_error = meta => {
	return !!(meta.touched && meta.error);
};

type Props = {
	label: string,
	name: string,
	options: Array<Object>,
	input: Object,
	is_disabled: boolean,
	meta: {
		touched: any,
		error: string,
		warning: string,
	},
	tooltip: {
		title: string,
		description: string,
  },
	handleProOpen: Function,
	is_pro_sub: boolean,
	handleSelectChange?: Function
};


export const ProFormSelect = ( {
	label,
	name,
	options,
	input,
	tooltip,
	is_disabled,
	meta: {
		touched,
		error,
		warning,
	},
	handleProOpen,
	is_pro_sub,
	handleSelectChange
}: Props ) => {
	const disableField = is_pro_sub === false;
	if(!is_pro_sub){
		return (
			<div>
				<LabelWrapper>
					<FlexDiv>
						<Exist when={disableField}>
							<BorderIconWrap onClick={handleProOpen}>
								<BorderedIcon 
									padding='0' 
									hide_border={true} 
									background={"#000"}
									size={1}
									className="disable-icon"
								>
									<Locked size={1.1} color='#ffffff' />
								</BorderedIcon>
							</BorderIconWrap>
						</Exist>
						<Label htmlFor={name} is_disabled={disableField}>{label}</Label>
					</FlexDiv>
					<Exist when={Boolean(tooltip)}>
						<Tooltip {...tooltip} />
					</Exist>
				
  
				</LabelWrapper>
  
				<Exist when={touched}>
					<Exist when={Boolean(error)}>
						<ErrorField>{error}</ErrorField>
					</Exist>
					<Exist when={Boolean(warning)}>
						<ErrorField>{warning}</ErrorField>
					</Exist>
				</Exist>
				<FormGroup>
					{handleSelectChange ? <Select
						{...input}
						onChange={value => handleSelectChange(value,input.name)}
						name={name}
						is_disabled={disableField}
						is_error={is_error({ touched, error })}
					>
						{getOptions(options)}
					</Select> : <Select
						{...input}
						name={name}
						is_disabled={disableField}
						is_error={is_error({ touched, error })}
					>
						{getOptions(options)}
					</Select>}
				</FormGroup>
			</div>
		);
    
    
	}
	return(
		<div>
			<LabelWrapper>
				<FlexDiv>
					<Exist when={is_disabled}>
						<BorderIconWrap>
							<BorderedIcon 
								padding='0' 
								hide_border={true} 
								background={colors.buttons.baseColor}
								size={1}
								className="disable-icon"
							>
								<Locked size={1.1} color='#ffffff' />
							</BorderedIcon>
						</BorderIconWrap>

					</Exist>
			
					<Label htmlFor={name} is_disabled={is_disabled}>{label}</Label>
				</FlexDiv>
				<Exist when={Boolean(tooltip)}>
					<Tooltip {...tooltip} />
				</Exist>
			</LabelWrapper>
			<Exist when={touched}>
				<Exist when={Boolean(error)}>
					<ErrorField>{error}</ErrorField>
				</Exist>
				<Exist when={Boolean(warning)}>
					<ErrorField>{warning}</ErrorField>
				</Exist>
			</Exist>

			<FormGroup>
				<Select
					{...input}
					name={name}
					is_disabled={is_disabled}
					is_error={is_error({ touched, error })}
				>
					{getOptions(options)}
				</Select>
			</FormGroup>
		</div>
	);
  
	
};
export default ProFormSelect;