/* eslint max-len: 0 */
// @flow
import React from "react";
import SvgIconContainer from "../SvgIconContainer";

export default (
	{
		unit = "em",
		...rest
	}: Object = {}) => (
	<SvgIconContainer viewBox="0 0 24 24" {...rest} unit={unit}>
		<title>Emergency Ruck</title>
		<path d="M10.1753846,11.4646154 L11.32,11.4646154 C12.6000064,11.4646154 13.24,10.9353899 13.24,9.87692308 C13.24,9.35999742 13.0769247,9.00307791 12.7507692,8.80615385 C12.4246138,8.60922978 11.9476954,8.51076923 11.32,8.51076923 L10.1753846,8.51076923 L10.1753846,11.4646154 Z M13.4615385,18 L11.3938462,13.9753846 L10.1753846,13.9753846 L10.1753846,18 L7,18 L7,6 L11.5415385,6 C12.1815417,6 12.7938432,6.06153785 13.3784615,6.18461538 C13.9630798,6.30769292 14.473844,6.5169216 14.9107692,6.81230769 C15.3476945,7.10769378 15.6953833,7.50461289 15.9538462,8.00307692 C16.212309,8.50154095 16.3415385,9.12615009 16.3415385,9.87692308 C16.3415385,10.7384658 16.1661556,11.4523049 15.8153846,12.0184615 C15.4646136,12.5846182 14.9938491,13.0276907 14.4030769,13.3476923 L17.0061538,18 L13.4615385,18 Z" id="ER"></path>
	</SvgIconContainer>
);