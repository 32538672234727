// @flow
import * as React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { Redirect } from "react-router-dom";
import { withRouter } from "react-router-dom";
import * as selectors from "../../../modules/selectors";
import colors from "../../../assets/css/colors";
import { loginToken } from "../../../modules/actions";

import Leagues from "../../../components/Icons/Leagues";

import type { TPLeague } from "../../../modules/types";

import {
	Footer,
	PageTitle,
	AdsContainer,
	Ad,
	PageContentWrapper,
	TwoColumnLayout,
	StandardSidebar,
	LeagueGameBar,
	Preloader,
} from "../../../components";
import { LeagueManagerComponent } from "../../../components/utils/LeagueManager/index";
import LeagueSettingsForm from "./leagueSettings/LeaguesSettingsForm";

const PageTitleStyled = styled(PageTitle)`
	font-size: 32px;
	display: flex;
	align-items: flex-end;

	svg{
		margin-right: .2em;
	}
`;

type Props = {
	rounds_list: Array<Object>,
	handleSubmit: Function,
	league: TPLeague,
	league_show:  {
		error: string,
		league_id: number,
	},
	match: {
		params: {
			league_id: number,
		}
	},
	user: {
		id: number,
	},
}

class ClassicLeagueSettingsComponent extends React.Component<Props> {
	componentDidMount() {
		window.scrollTo(0, 0);
		this.loginWithToken();
	}

	get check_is_from_app() {
		const params = new URLSearchParams(window.location.search);
		const from_app = params.get("from_app");
		return Boolean(from_app);

	}

	get app_league_id() {
		const params = new URLSearchParams(window.location.search);
		return params.get("league_id");
	}

	loginWithToken() {
		if(!this.check_is_from_app){
			return;
		}
		const params = new URLSearchParams(window.location.search);
		const token = params.get("token");
		loginToken(token);
	}

	checkAccessToLeague() {
		const {
			league_show: {
				league_id
			},
			user: {
				id: user_id
			},
			league,
		} = this.props;
	
		
		if(league && league.commissioner && league.commissioner !== user_id) {
			return <Redirect to={`/classic/league/${league_id}/about`} />;
		}
		else {
			return null;
		}
	}

	render() {
		const {
			match,
			league_show,
			user
		} = this.props;

		const leagueID = match.params.league_id;

		if(!user.id){
			return <Preloader />;
		}

		return (
			<div>
				{this.checkAccessToLeague()}
				{league_show.error ? <Redirect to='/classic/leagues' /> : null}
				<LeagueGameBar no_fetch={true} />
				<AdsContainer>
					<Ad/>
				</AdsContainer>
				<PageContentWrapper>
					<PageTitleStyled>
						<Leagues color={colors.title} size='1.8' />
									League settings
					</PageTitleStyled>
					<TwoColumnLayout>
						<LeagueManagerComponent
							from="update_classic"
							league_id={leagueID}
							isFromApp={this.check_is_from_app}
						>
							{({ rounds_list, handleSubmit, ...props }: Props) => (
								<LeagueSettingsForm
									{...props}
									rounds={rounds_list}
									onSubmit={handleSubmit}
								/>
							)}
						</LeagueManagerComponent>
						<StandardSidebar/>
					</TwoColumnLayout>
				</PageContentWrapper>
				<Footer />
			</div>
		);
	}
}

const mapStateToProps = (state, props) => ({
	league_show: state.leaguesClassic.show,
	user: state.user.data,
	league: selectors.leaguesClassic.show.getLeague(state, props),
});

const mapDispatchToProps = {
	loginToken
};

export const ClassicLeagueSettings = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(ClassicLeagueSettingsComponent)
);

export default ClassicLeagueSettings;