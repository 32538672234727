// @flow
import styled, { css } from "styled-components";
import { below } from "../../assets/css/media";

export const WrappedTradeIcon = styled.div`
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 100%;
	color: #fff;
	background: ${({ bg }) => bg};
	margin-right: 10px;
	
	${below.desktop`
		${css`
			width: 20px;
			height: 20px;
		`}
	`}
`;
export default WrappedTradeIcon;