// @flow
import React from "react";
import SvgIconContainer from "../SvgIconContainer";

export default (
	{
		unit = "em",
		...rest
	}: Object = {}) => (
	<SvgIconContainer viewBox="0 0 16 16" {...rest} unit={unit}>
		<g id="Ladder/Icons/Up" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<polygon
				id="Shape"
				fill="#00B700"
				transform="translate(5.000000, 5.000000)
				rotate(-180.000000)
				translate(-5.000000, -5.000000)"
				points="0 0 10 0 5 10"
			/>
		</g>
	</SvgIconContainer>
);