// @flow

export const ForgotPassword = (values: Object) => {
	const errors = {};

	if (!values.email) {
		errors.email = "Required";
	}
	else if(values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
		errors.email = "Invalid email address";
	}

	return errors;
};