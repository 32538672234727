// @flow
import React from "react";
import { withRouter, Link } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import styled, { css } from "styled-components";
import * as _ from "lodash";

import type {
	TRootStore,
	TRoundsWithMatches,
	TRound,
	TRoundsById,
	TSquad,
	ById,
	TPlayer,
	TPLeague,
	TMatch,
	TPosition,
	TUser
} from "../../modules/types";
import * as selectors from "../../modules/selectors";
import * as actions from "../../modules/actions";
import { below } from "../../assets/css/media";

import JsonFetcher from "../utils/JsonFetcher";
import { TwoColumnLayout } from "../TwoColumnLayout";
import StandardSidebar from "../StandardSidebar";
import WidgetRfaOrder from "../WidgetRfaOrder";
import Footer from "../Footer";
import MatchCentreBar from "../MatchCentreBar";
import AdsContainer from "../AdsContainer";
import LeagueGameBar from "../LeagueGameBar";
import Ad from "../Ad";
import Arrow from "../Icons/Arrow";
import { ChangePanelIcon } from "../MatchUp/summaryPanel";
import PageContentWrapper from "../PageContentWrapper";
import withRoundsSelector from "../utils/withRoundsSelector";
import withCoachesBox from "../utils/withCoachesBox";
import { PageTitle } from "../PageTitle";
import RoundSelector from "../RoundSelector";
import DraftTableHeader from "../DraftTableHeader";
import { Label, Select, Tooltip, FormCircleCheckbox } from "../Form";
import { getLeagueScoringBasis, isAllTrue } from "../../helpers";
import PlayersTable from "./playersTable";
import type { TFilterBy } from "./filterPlayersByMatch";
import filterPlayersByMatch from "./filterPlayersByMatch";
import MatchPanel from "./matchPanel";

const StyledLabel = styled(Label)`
	margin-bottom: 5px;
	${below.tablet`
		flex: 0 0 auto;
		margin-right: 10px;
	`}
`;

const FilterOption = styled.div`
  ${({ checkbox }) => checkbox && css`
    margin-top: 20px!important; /* Override DraftTableHeader styles */
    ${below.tablet`
      margin-top: 15px!important;
    `};
  `}
`;

const PageTitleRoundSelectorWrapper = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
`;

const FilterOptionsWrapper = styled.div`
  display: flex;
    flex-direction: row;
    justify-content: flex-start;
  align-items: center;
    width: 100%;
    max-width: unset;
    flex-basis: 100%;

  ${below.tablet`
    flex-direction: column;
    align-items: flex-start;
    padding-left: 10px;
    box-sizing: border-box;
  `};

  > ${FilterOption} {
    margin: 0 5px;
    > div {
      margin-right: 0;
    }
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }

    ${below.tablet`
      margin: 15px 0 0;
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      width: 100%;
      > div {
        flex: 1 0 0px;
        max-width: 250px;
      }
    `};
  }
`;

const SelectsWrapper = styled.div`
  display: flex;

  ${below.tablet`
    width: 100%;
  `}

  > div {
    flex: 1 0 0px;
    margin: 0 5px;
    min-width: 140px;
    > div {
      margin-right: 0;
    }
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
`;

const AlignLink = ({ right_align, ...props }) => <Link {...props} />;

const PanelLink = styled(AlignLink)`
  border: 0 none;
  background: transparent;
  margin: 0;
  padding: 0;

  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  text-decoration: none;

  ${({ right_align }) => right_align ? "left: -50%;" : "right: -50%;"}
`;

const MatchesPanel = styled.div`
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  padding: 10px 0;
    box-sizing: border-box;
    margin-top: -10px;
    margin-bottom: 10px;

  ${below.tablet`
    height: 80px;
    img {
      height: 50px;
      width: 50px;
    }
  `}

  > div {
    width: 80%;
    flex: 0 0 80%;
    transition: all 0.5s linear;
    height: 100%;

    transform: translate(${({ index }) => 12 - index * 100}%, 0);

    ${below.tablet`
      width: 90%;
      flex: 0 0 90%;
      transition: all 0.5s linear;
      height: 100%;

      transform: translate(${({ index }) => 6 - index * 100}%, 0);
    `}
  }
`;

const NavSection = styled.div`
  position: absolute;
  border: 0 none;
  background: #ffffff;
  box-shadow: 0 0 10px 0 rgba(127,138,144,0.25);
  border-radius: 5px;
  flex: 0 0 30px;
  z-index: 1;
  top: 0;
  height: 100%;
`;

const PrevFixture = styled(NavSection)`
  left: 5px;
`;

const NextFixture = styled(NavSection)`
  right: 5px;
`;

const MatchesPanelWrapper = styled.div`
  position: relative;
`;

const MainContent = styled.div``;

const FILTER_CHECKBOXES: { name:  string, label: string }[] = [
	{
		name: "unlisted",
		label: "Hide unowned players"
	},
	{
		name: "favourites",
		label: "Show your favouirites"
	},
	{
		name: "matchup",
		label: "Hide players not in your matchup"
	},
];

type Props = {
  is_classic: boolean,
  rounds_ordered: Array<Object>,
  rounds_by_id: TRoundsById,
  selected_round_id?: number,
  setSelectedRoundId: Function,
  rounds_with_matches: TRoundsWithMatches,
  squads: ById<TSquad>,
  players: ById<TPlayer>,
  league?: TPLeague,
  selected_round: TRound,
  selected_match: TMatch,
  round_matches: Array<TMatch>,
  match_index: number,
  league_id: number,
  showDraftLeague: Function,
  fetchTeams: Function,
  fetchWaiverList: Function,
  fetchWaiverFree: Function,
  fetchMyClassicTeam: Function,
  positions: Array<TPosition>,
  match_stats: ById<Object>,
  fetchPlayersMatches: Function,
  match: { params: { league_id?: string, match_id?: string } },
  history: Object,
  venues: ById<Object>,
  my_players: number[],
  user: TUser,
  draft_teams_by_id: ById<Object>,
  fetchAllCustomStats: Function,
  custom_stats: Object,
  actual_round: Object,
};

type State = {
  filters: TFilterBy,
  order_by: string,
  order_desc: boolean
};

export class MatchCentreComponent extends React.Component<Props, State> {
	static defaultProps = {
  	is_classic: false,
	};

	constructor()  {
  	super();

  	_.bindAll(this, [
  		"changeMatchupRound",
  		"setOrderBy"
  	]);
	}

	state = {
  	filters: {
  		squad_id: 0,
  		position: 0,
  		unlisted: false,
  		matchup: false,
  		favourites: false,
  	},
  	order_by: "stats.score",
  	order_desc: true
	};

	componentDidMount() {
  	const { is_classic } = this.props;

  	
  	if(!is_classic) {
  		this.getPlayerStats();
  		this.getDraftOwnerInfo();
  	}

  	this.setMatchInfo();
  	this.fetchMatchStats();
	}

	componentDidUpdate(prev_props: Props) {
  	const { players, selected_match } = this.props;
  	const {
  		players: prev_players,
  		selected_match: prev_selected_match,
  	} = prev_props;

  	if (!_.isEmpty(players) && _.isEmpty(prev_players)) {
  		this.fetchMatchStats();
  	}

  	if (!_.isEqual(selected_match, prev_selected_match)) {
  		this.fetchMatchStats();
  		this.setMatchInfo();
  	}
  	if(prev_props.league !== this.props.league){
  		this.getPlayerStats();
  	}
	}

	getDraftOwnerInfo() {
  	const {
  		fetchTeams,
  		fetchWaiverList,
  		fetchWaiverFree,
  		showDraftLeague,
  		league_id,
  		fetchAllCustomStats,
  		league
  	} = this.props;
	
	  if(league && Boolean(league.custom_scoring_enabled)){
  		fetchAllCustomStats(league_id);
	  }
  
  	showDraftLeague({
  		id: league_id
  	});
  	fetchTeams({
  		league_id: league_id,
  		details: 1
  	});

  	fetchWaiverList({
  		league_id
  	});
  	fetchWaiverFree({
  		league_id
  	});
	}

	getPlayerStats(){
	  const {league, fetchAllCustomStats, league_id} = this.props;
  	if(league && Boolean(league.custom_scoring_enabled)){
  		fetchAllCustomStats(league_id);
  	}
	}


	setMatchInfo() {
  	const { selected_match, setSelectedRoundId } = this.props;
  	const { filters } = this.state;
  	if (!_.isEmpty(selected_match)) {
  		setSelectedRoundId(selected_match.round);
  		this.setState({
  			filters: {
  				...filters,
  				squad_id: 0
  			}
  		});
  	}
	}

	fetchMatchStats() {
  	const { selected_match, fetchPlayersMatches } = this.props;
  	if (!_.isEmpty(selected_match)) {
  		fetchPlayersMatches(selected_match.round);
  	}
	}

	changeMatchupRound(new_round: number) {
  	const { rounds_with_matches } = this.props;
  	const round = _.find(rounds_with_matches, { id: new_round });
  	const new_match = _.first(_.get(round, "matches"), []);

  	if (!_.isEmpty(new_match)) {
  		this.props.history.push(this.getMatchLink(new_match.id));
  	}
	}

	setOrderBy(order_by: string) {
  	const { order_by: prev_order_by, order_desc } = this.state;
  	if (order_by === prev_order_by) {
  		this.setState({
  			order_desc: !order_desc
  		});
  	}
  	else {
  		this.setState({
  			order_by,
  			order_desc: true
  		});
  	}
	}


	get sort_order() {
  	const { 
  		match_stats, 
  		selected_round, 
  		players, 
  		squads, 
  		league, 
  		custom_stats, 
  		selected_match 
  	} = this.props;
  	const { order_by, order_desc } = this.state;
  	const ifOrderDesc = (a: any, b: any) => order_desc ? a : b;
  	const statsBasis = getLeagueScoringBasis(league);

  	const round_id = _.get(selected_round, "id");
  	const BOTTOM_OF_SORT  = ifOrderDesc(-1, 1) * (Number.MAX_SAFE_INTEGER - 1);

  	const matchStatus = _.get(selected_match, "status", "scheduled");

  	const getMatchStat = stat => (id: number) =>
  		_.get(match_stats, [id, round_id, stat], BOTTOM_OF_SORT);
  	const getPlayerStat = stat => (id: number) => {
  		if(league && league.custom_scoring_enabled === 1 && stat.includes("scores")){
				const playerStats = _.get(custom_stats, id, {});
  			return _.get(playerStats, stat, BOTTOM_OF_SORT);
  		}
  		const player = _.get(players, id);
      

  		return _.get(player, stat, BOTTOM_OF_SORT);
  	};

  	let primary_iteree = getMatchStat(order_by);
  	let name_order = "asc";
  	let primary_order = ifOrderDesc("desc", "asc");
  	const custom_scoring_enabled = _.get(league, "custom_scoring_enabled", 0) === 1;

  	if (order_by === "stats.score") {
  		primary_iteree = getPlayerStat(`stats.scores.${round_id}`);
  		if(custom_scoring_enabled){
  			primary_iteree = getPlayerStat(`scores.${round_id}`);
  		}
  	}
	
  	else if (isAllTrue([order_by === `${statsBasis}.avg_points`, matchStatus === "scheduled"])){
  		primary_iteree = getPlayerStat(`${statsBasis}.avg_points`);
  	}
  	else if (order_by === "player_name") {
  		// Already sort by player name, so make primary sort nothing
  		primary_iteree = () => 0;
  		// Update name order to match order_desc
  		name_order = ifOrderDesc("asc", "desc");
  	}
  	else if (order_by === "owner") {
  		// Just sort by the user_id
  		primary_iteree = (id: number) => _.get(this.owners, [id, "user_id"], BOTTOM_OF_SORT);
  	}
  	else if (order_by === "squad_id") {
  		primary_iteree = (id: number) => {
  			const player = _.get(players, id);
  			const squad_id = _.get(player, "squad_id");
  			return _.get(squads, [squad_id, "full_name"], "");
  		};
  		primary_order = ifOrderDesc("asc", "desc");
  	}

  	const iterees = [
  		primary_iteree,
  		getPlayerStat("last_name"),
  		getPlayerStat("first_name")
  	];

  	const orders = [
  		primary_order,
  		name_order,
  		name_order
  	];

  	// Returns arrays of sort iterees and orderings
  	// to be used by _.orderBy
  	return [
  		iterees,
  		orders,
  	];
	}

	get player_ids() {
  	const { selected_match, players } = this.props;
  	const { filters } = this.state;
  	const ids = filterPlayersByMatch(
  		selected_match,
  		players,
  		filters,
  		this.owners,
  		this.matchup_ids
  	);
  	return _.orderBy(ids, ...this.sort_order);
	}

	get matchup_ids() {
  	const { selected_match, league, draft_teams_by_id } = this.props;
  	if (!_.isEmpty(selected_match)) {
  		try {
  			const round_id = selected_match.round;
  			const team_id = parseInt(_.get(league, "team_id"), 10);
  			const matchup_teams = _.find(
  				_.get(league, ["fixture", round_id], []),
  				matchup => _.includes(matchup, team_id)
  			);

  			return _.map(
  				matchup_teams,
  				team_id => _.get(draft_teams_by_id, [team_id, "user_id"])
  			);
  		}
  		catch (e) {
  			return [];
  		}
  	}
  	return [];
	}

	get owners() {
  	const { my_players, is_classic, players, selected_match, user } = this.props;
  	const ids = filterPlayersByMatch(selected_match, players);
  	if (is_classic) {
  		return _.reduce(ids, (all, id) => {
  			if (_.includes(my_players, id)) {
  				return  {
  					...all,
  					[id]: {
  						...user,
  						user_id: user.id,
  					}
  				};
  			}
  			return all;
  		}, {});
  	}
  	else {
  		return _.reduce(ids, (all, id) => {
  			const owner = _.get(players, [id, "owner"]);
  			if (owner) {
  				return  {
  					...all,
  					[id]: {
  						...owner,
  					}
  				};
  			}
  			return all;
  		}, {});
  	}
	}

	get match_stats() {
  	const { match_stats, selected_round } = this.props;
  	const round_id =  _.get(selected_round, "id");
  	const round_started = _.get(selected_round, "status", "scheduled") !== "scheduled";

  	return _(this.player_ids)
  		.map(player_id => ({
  			player_id,
  			match_stats: round_started ? _.get(match_stats, [player_id, round_id], {}) : {}
  		}))
  		.reduce((all, { player_id, match_stats }) =>  {
  			return {
  				...all,
  				[player_id]: match_stats
  			};
  		}, {});
	}

	renderRoundSelector() {
  	const { rounds_ordered, selected_round_id } = this.props;

  	return <RoundSelector
  		rounds={rounds_ordered}
  		selected={selected_round_id}
  		handleClick={this.changeMatchupRound}
  	/>;
	}

	getMatchLink(match_id: number) {
  	const { league, is_classic } = this.props;
  	let prefix;

  	if (is_classic) {
  		prefix = "/classic";
  	}
  	else {
  		const league_id = _.get(league, "id", "");
  		prefix = `/draft/league/${league_id}`;
  	}

  	return `${prefix}/afl-matchups/${match_id}`;
	}

	renderMatchesPanel() {
  	const { selected_match, round_matches, match_index, venues, squads, actual_round } = this.props;
  	const next_id = _.get(round_matches, [match_index + 1, "id"], "");
  	const prev_id = _.get(round_matches, [match_index - 1, "id"], "");

  	return <MatchesPanelWrapper>
  		{match_index > 0 && (
  			<PrevFixture>
  				<PanelLink right_align to={this.getMatchLink(prev_id)}>
  					<ChangePanelIcon>
  						<Arrow direction="left" />
  					</ChangePanelIcon>
  				</PanelLink>
  			</PrevFixture>
  		)}
  		<MatchesPanel
  			index={match_index}
  		>
  			{_.map(round_matches, match => {
  				return <MatchPanel
  					match={match}
  					is_active={match === selected_match}
  					venues={venues}
  					key={match.id}
  					squads={squads}
						actual_round={actual_round}
  				/>;
  			})}
  		</MatchesPanel>
  		{match_index < _.size(round_matches) - 1 && (
  			<NextFixture>
  				<PanelLink to={this.getMatchLink(next_id)}>
  					<ChangePanelIcon>
  						<Arrow direction="right" />
  					</ChangePanelIcon>
  				</PanelLink>
  			</NextFixture>
  		)}
  	</MatchesPanelWrapper>;
	}

	renderFormHeader() {
  	return <DraftTableHeader
  		title={this.matchTitle()}
  		is_compressed={false}
  	>
  		{ this.renderFilterOptions() }
  	</DraftTableHeader>;
	}

	renderFilterOptions() {
  	return <FilterOptionsWrapper>
  		<SelectsWrapper>
  			{ this.renderClubOptions() }
  			{ this.renderPositionOptions() }
  		</SelectsWrapper>
  		{ this.renderCheckboxes() }
  	</FilterOptionsWrapper>;
	}

	renderClubOptions() {
  	const { selected_match } = this.props;
  	const { filters } = this.state;
  	return <FilterOption>
  		<StyledLabel htmlFor="club">
        Club
  			<Tooltip
  				title="Club to Display"
  				description={
  					"Change the club that players "
            + "displayed in the table are from"
  				}
  			/>
  		</StyledLabel>
  		<Select
  			name="club"
  			onChange={({ currentTarget }) => this.setState({
  				filters: {
  					...filters,
  					squad_id: parseInt(currentTarget.value, 10)
  				}
  			})}
  			value={filters.squad_id}
  		>
  			<option value={0}>
          Both
  			</option>
  			<option value={_.get(selected_match, "home_squad_id")}>
  				{_.get(selected_match, "home_squad_name")}
  			</option>
  			<option value={_.get(selected_match, "away_squad_id")}>
  				{_.get(selected_match, "away_squad_name")}
  			</option>
  		</Select>
  	</FilterOption>;
	}

	renderPositionOptions() {
  	const { positions } = this.props;
  	const { filters } = this.state;

  	return <FilterOption>
  		<StyledLabel htmlFor="position">
        Position
  			<Tooltip
  				title="Position to Display"
  				description={
  					"Change the position that players "
            + "displayed in the table play"
  				}
  			/>
  		</StyledLabel>
  		<Select
  			name="position"
  			onChange={({ currentTarget }) => this.setState({
  				filters: {
  					...filters,
  					position:parseInt(currentTarget.value, 10)
  				}
  			})}
  			value={filters.position}
  		>
  			<option value={0}>
          All
  			</option>
  			{ _.map(positions, position => (
  				<option key={position.id} value={position.id}>
  					{position.name}
  				</option>)
  			)}
  		</Select>
  	</FilterOption>;
	}

	renderCheckboxes() {
  	const { filters } = this.state;
  	const updateFilter = name => ({ currentTarget }) => {
  		this.setState({
  			filters: {
  				...filters,
  				[name]: currentTarget.checked
  			}
  		});
  	};
  	const makeCheckbox = ({ name, label }) => (<FilterOption key={name} checkbox>
  		<FormCircleCheckbox
  			id={name}
  			name={name}
  			value={filters[name]}
  			onChange={updateFilter(name)}
  			label={label}
  		/>
  	</FilterOption>);
  	return <React.Fragment>
  		{_.map(
  			_.filter(
  				FILTER_CHECKBOXES,
  				// If there's no matchup to filter by, hide the
  				// matchup checkbox
  				({ name }) => name !== "matchup" || !_.isEmpty(this.matchup_ids)
  			),
  			makeCheckbox)}
  	</React.Fragment>;
	}

	matchTitle() {
  	const { selected_match } = this.props;
  	const home = _.get(selected_match, "home_squad_name", "");
  	const away = _.get(selected_match, "away_squad_name", "");

  	if (home === "" || away === "") {
  		return "";
  	}

  	return `${home} v ${away}`;
	}

	renderPlayersTable() {
  	const {
  		players,
  		selected_round,
  		selected_match,
  		is_classic,
  		squads,
  		league,
  		custom_stats,
			actual_round
  	} = this.props;

  	const { order_by, order_desc } = this.state;

  	return <PlayersTable
  		round={selected_round}
  		match={selected_match}
			actual_round={actual_round}
  		players={players}
  		owners={this.owners}
  		player_ids={this.player_ids}
  		match_stats={this.match_stats}
  		is_classic={is_classic}
  		squads={squads}
  		setOrderBy={this.setOrderBy}
  		order_by={order_by}
  		order_desc={order_desc}
  		league={league}
  		custom_stats={custom_stats}
  	/>;
	}

	renderMainContent() {
  	return <React.Fragment>

  		{ this.renderMatchesPanel() }
  		{ this.renderFormHeader() }
  		{ this.renderPlayersTable() }
  	</React.Fragment>;
	}

	render() {
  	const { is_classic, league } = this.props;

  	return <React.Fragment>
  		<JsonFetcher
  			fetch={["players", "rounds", "squads", "venues"]}
  			interval={90000}
  		/>
  		<LeagueGameBar no_fetch={true} />
  		<AdsContainer>
  			<Ad />
  		</AdsContainer>
  		<MatchCentreBar />
  		<PageContentWrapper>
  			<TwoColumnLayout className="playwright-mask-hidden">
  				<MainContent>
  					<PageTitleRoundSelectorWrapper>
  						<PageTitle>
								AFL Matchups
  							{!_.isEmpty(league) && ` for ${_.get(league, "name")}`}
						
  						</PageTitle>
  						{ this.renderRoundSelector() }

  					</PageTitleRoundSelectorWrapper>

  					{ this.renderMainContent() }
  				</MainContent>
  				<StandardSidebar children_at_top>
  					{ !is_classic && <WidgetRfaOrder /> }
  				</StandardSidebar>
  			</TwoColumnLayout>
  		</PageContentWrapper>
  		<Footer show_key />
  	</React.Fragment>;
	}
};

const getMatchParam = (props, param) => {
	try {
		const raw_value = _.get(props, ["match", "params", param]);
		const value = parseInt(raw_value, 10);
		return _.isNumber(value) && !_.isNaN(value) ? value : undefined;
	}
	catch (e) {}

	return undefined;
};

const invalidMatch = match_id => _.isNil(match_id) || _.isNaN(match_id) || !_.isNumber(match_id);

const mapStateToProps = (state: TRootStore, props: Props) => {
	const league = props.is_classic
		? selectors.leaguesClassic.show.getLeague(state)
		: selectors.leagues.getLeague(state, props);


	const league_id = getMatchParam(props, "league_id");
	let match_id = getMatchParam(props, "match_id");
	const actual_round = selectors.rounds.getActualRound(state);

	if (invalidMatch(match_id)) {
		match_id = _.get(actual_round, ["matches", "0", "id"]);
	}

	const rounds_with_matches = selectors.rounds.getRoundsWithMatches(state);
	const selected_round = _.find(rounds_with_matches, r => _.find(r.matches, { id: match_id }));
	const round_matches = _.get(selected_round, "matches", []);
	const match_index = _.findIndex(round_matches, { id: match_id });
	const selected_match = _.get(round_matches, match_index);

	return {
		rounds_with_matches,
		selected_round,
		squads: state.squads.by_id,
		players: props.is_classic
			? selectors.players.getExtendedPlayersById(state)
			: selectors.players.getExtendedPlayersWithOwnersById(state, props),
		league,
		league_id,
		selected_match,
		round_matches,
		match_index,
		positions: selectors.positions.getPositionsArray(state),
		match_stats: state.players.matches_by_id,
		venues: state.venues.by_id,
		my_players: props.is_classic ? selectors.getMyClassicTeamPlayersIds(state) : [],
		user: selectors.getUser(state),
		draft_teams_by_id: props.is_classic ? [] : state.leagues.teams_by_id,
		custom_stats: state.players.all_custom_stats,
		actual_round
	};
};

const mapDispatchToProps = {
	showDraftLeague: actions.leagueDraft.showDraftLeague,
	fetchTeams: actions.leagueDraft.showDraftOrder,
	fetchWaiverList: actions.leagueDraft.fetchWaiverList,
	fetchWaiverFree: actions.leagueDraft.fetchWaiverFree,
	fetchPlayersMatches: actions.fetchPlayersMatches,
	fetchAllCustomStats: actions.fetchAllCustomStats,
	fetchMyClassicTeam: actions.fetchMyClassicTeam,
};

export const MatchCentre = compose(
	withRouter,
	withRoundsSelector,
	withCoachesBox,
	connect(
		mapStateToProps,
		mapDispatchToProps,
	)
)(MatchCentreComponent);

export default MatchCentre;