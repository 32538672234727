// @flow
import * as React from "react";
import { change, formValueSelector, reduxForm } from "redux-form";
import {get} from "lodash";
import bindAll from "lodash/bindAll";
import { connect } from "react-redux";
import styled from "styled-components";
import * as selectors from "../../../../modules/selectors";
import Arrow from "../../../../components/Icons/Arrow";
import { getShortName } from "../../../../helpers/league";
import { leagueValidate } from "../../../../utils/FormValidations/index";
import { ButtonPrimary, FormUploadImage } from "../../../../components";
import type { TPLeague, TRound } from "../../../../modules/types";
import colors, { lightenColor } from "../../../../assets/css/colors";
import { below } from "../../../../assets/css/media";
import { LeagueSettings } from "./forms/LeagueSettings";
import { AdvancedLeagueSettings } from "./forms/AdvancedLeagueSettings";


const CreateForm = styled.form`
	border: 1px solid #ecf1f5;
	margin: 1em 0;
	background: ${colors.form.base};
`;

const FormItem = styled.div`
	margin: 0 3em;
	padding: 1em;
	background: ${colors.form.advanced_color};

	&.base-options{
		background: transparent;
		padding: 0;
	}

	${below.desktop`
		margin: 0 1em;
	`}

	button{
		padding: 1em 3em;
		font-size: 15px;
		font-weight: 600;
	}
`;

const SubTitle = styled.h4`
	display: flex;
	font-size: 22px;
	align-items: center;
	color: ${colors.primary.primary};
	font-weight: bold;
	margin: 1em 0 1em 0;

	div {
		margin-right: .5em;
	}
`;

const AdvancedSettings = styled.p`
	color: ${colors.form.textDarkColor};
	line-height: 1.5;
	font-size: 16px;
	max-width: 560px;
`;

const UploadAvatarWrapper = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	height: 130px;
	color: ${colors.form.textColor};

	picture {
		margin: 1em .5em .5em 0;
	}
`;

const LeagueName = styled.h3`
	color: ${colors.primary.primary};
	font-size: 28px;
	margin-bottom: .2em;
	line-height: 1.3;
	font-weight: bold;
	opacity: ${({ disabled }) => disabled ? ".15" : "1"};
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const AdvancedSettingsWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1em;
	border-radius: 3px;
	background: ${colors.form.advanced_color};
	border-radius: 3px 3px 0 0;
	box-shadow: inset 0px 15px 20px -10px #c7c7c7;

	.sub-title {
		margin-bottom: .5em;
	}
`;

const ToggleForm = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	background: ${colors.buttons.baseColor};
	width: 50px;
	height: 50px;
	border-radius: 2px;
	color: #ffffff;

	:hover {
		background-color: ${lightenColor(colors.primary.primary, 0.1)};
	}
`;

const UserName = styled.div`
	color: ${colors.form.textColor};
	font-weight: bold;
`;

const ArrowBottom = styled(Arrow)`
	transform: rotate(178deg);
`;

const ButtonWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 1em 0;
`;

const RemoveLeagueButton = ({ removeLeague, league }) => {
	return league.status === "scheduled" ? (
		<ButtonPrimary onClick={removeLeague}>
			Remove league
		</ButtonPrimary>
	) : (
		<ButtonPrimary disabled={true}>
			Remove league
		</ButtonPrimary>
	);
};

type Props = {
	handleSubmit: Function,
	onChangeFile: Function,
	previewImg: string,
	league_name: string,
	user: {
		firstname: string,
		lastname: string,
	},
	is_show_advanced_settings: boolean,
	toggleForm: Function,
	league_type: string,
	submitting: boolean,
	is_finals: boolean,
	league_size: number,
	rounds: TRound,
	league: TPLeague,
	removeLeague: Function,
	rounds_with_matches: TRound[],
	changeFieldValue: Function,
};

type State = {
	is_show_advanced_settings: boolean,
	rescheduled_populated: boolean,
}

class LeagueCreateFormComponent extends React.Component<Props, State> {
	constructor(props) {
		super(props);
		this.state = {
			is_show_advanced_settings: false,
			rescheduled_populated: false,
		};
		bindAll(this, ["handleShowAdvancedSettings", "populateWithRescheduledLeague"]);
		
	}

	componentDidMount() {
		this.populateWithRescheduledLeague();
	}

	componentDidUpdate() {
		this.populateWithRescheduledLeague();
	}

	handleShowAdvancedSettings = () => {
		this.setState(prevState => ({
			is_show_advanced_settings: !prevState.is_show_advanced_settings,
		}));
	};


	populateWithRescheduledLeague() {
		const currentRound = this.props.rounds_with_matches.find(round => round.status === "scheduled");
		const id = get(currentRound, 'id');
		if(this.props.league.status === "scheduled" && this.props.league.start_round < id && !this.state.rescheduled_populated) {
			const fields = this.props.league;
			Object.keys(fields).forEach(field => {
				this.props.changeFieldValue(field, fields[field]);
			});
			this.setState({
				rescheduled_populated: true
			});
		}

		
	}

	render() {
		const {
			handleSubmit,
			onChangeFile,
			previewImg,
			league_name,
			user,
			league_type,
			submitting,
			rounds,
			league_size,
			is_finals,
			league,
			removeLeague,
		} = this.props;

		const { is_show_advanced_settings } = this.state;

		return (
			<CreateForm onSubmit={handleSubmit}>
				<FormItem className='base-options'>
					<UploadAvatarWrapper>
						<FormUploadImage
							name='league_avatar'
							size="75px"
							onChange={onChangeFile}
							preview_img={previewImg ? previewImg : ""}
							add_text=''
						/>
						<div>
							{!league_name ? (
								<LeagueName disabled={true}>League Name</LeagueName>
							) : (
								<LeagueName>{league_name}</LeagueName>
							)}
							<UserName>{getShortName(user)}</UserName>
						</div>
					</UploadAvatarWrapper>

					<LeagueSettings rounds={rounds} league_type={league_type} league={league} />

					{league_type === "head_to_head" ? (
						<AdvancedSettingsWrapper>
							<div>
								<SubTitle
									className='sub-title'
									is_inactive={is_show_advanced_settings}
								>
									Advanced settings
								</SubTitle>
								<AdvancedSettings>
									You can create a league via the basic settings
									above or fine tune your Classic League with our advanced settings.
								</AdvancedSettings>
							</div>
							<ToggleForm background={colors.primary.primary} hide_border={true}>
								{!is_show_advanced_settings ?
									(
										<Arrow
											color='white'
											size="2"
											cursor='pointer'
											onClick={this.handleShowAdvancedSettings}
										/>
									) : (
										<ArrowBottom
											color="white"
											size="2"
											cursor='pointer'
											onClick={this.handleShowAdvancedSettings}
										/>
									)
								}
							</ToggleForm>
						</AdvancedSettingsWrapper>
					) : null }

				</FormItem>

				{is_show_advanced_settings && league_type === "head_to_head" ? (
					<FormItem>
						<AdvancedLeagueSettings
							is_finals={is_finals}
							league_size={league_size}
							league={league}
						/>
					</FormItem>
				) : null}

				<FormItem className='base-options'>
					<ButtonWrapper>
						<ButtonPrimary disabled={submitting}>
							Update your league
						</ButtonPrimary>

						{RemoveLeagueButton({ removeLeague, league })}
					</ButtonWrapper>
				</FormItem>
			</CreateForm>
		);
	}
}

let LeagueCreateFormComp = reduxForm({
	form: "league_manager_settings",
	validate: leagueValidate,
})(LeagueCreateFormComponent);

const selector = formValueSelector("league_manager_settings");

const mapStateToProps = (state, props) => {
	const
		league = selectors.leaguesClassic.show.getLeague(state, props),
		league_size = selector(state, "size"),
		league_type = selector(state, "type");

	return {
		league,
		is_finals: selector(state, "finals") === 1,
		league_size,
		league_name: selector(state, "name"),
		league_type,
		initialValues: {
			id: league.id,
			name: league.name,
			type: league.type,
			start_round: league.start_round,
			privacy: league.privacy,
			play_times: league.play_times,
			finals: league.finals,
			bye_rounds: league.bye_rounds,
			rank_by: league.rank_by,
			size: league.size,
			finals_format: league.finals_format,
		},
	};
};


const mapDispatchToProps = dispatch => {
	return {
		changeFieldValue: function(field, value) {
			dispatch(change("league_manager_settings", field, value));
		},
	};
};

export const LeagueCreateForm = connect(
	mapStateToProps,
	mapDispatchToProps,
)(LeagueCreateFormComp);

export default LeagueCreateForm;