import { TClassicTeam } from "./teamClassic";
import { TExtendedDraftTeam } from "./teamsDraft";

export * from "./position";
export * from "./user";
export * from "./store";
export * from "./squad";
export * from "./player";
export * from "./rounds";
export * from "./league";
export * from "./teamClassic";
export * from "./teamsDraft";
export * from "./coachesBox";
export * from "./byId";
export * from "./venues";
export * from "./leaguesDraft";

export type TTeam = TClassicTeam | TExtendedDraftTeam;