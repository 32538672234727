// @flow
import * as React from "react";
import styled from "styled-components";
import type { TPlayersById } from "../../modules/types";
import {
	TradeInCell,
	TransactionPlayers,
	TransactionPlayersWrapper,
	ButtonAlert,
	ButtonAccent
} from "../";
import { TransactionButtonsWrapper } from "./TransactionButtonsWrapper";

type Props = {
	players_by_id: TPlayersById,
	trade: Object,
	rejectTrade: Function,
	acceptTrade: Function
}

const ButtonAccentMargin = styled(ButtonAccent)`
	margin-bottom: 10px;
`;

export const TradeToUser = ({ trade, players_by_id, rejectTrade, acceptTrade }: Props) => {
	const { id: trade_id, new_player_id, old_player_id, new_team_name, status } = trade;
	const highestNumberInTrade = Math.max(old_player_id.length, new_player_id.length);

	const trade_controls = status === "accepted" ? null : (
		<React.Fragment>
			<ButtonAccentMargin onClick={() => acceptTrade({ trade_id })}>
				Accept offer
			</ButtonAccentMargin>
			<ButtonAlert inverted onClick={() => rejectTrade({ trade_id })}>
				Reject offer
			</ButtonAlert>
		</React.Fragment>
	);
	return (
		<React.Fragment>
			<TransactionPlayersWrapper>
				<TransactionPlayers>
					<p>{new_team_name} has offered:</p>
					{new_player_id.map(
						id => <TradeInCell
							key={id} 
							player={players_by_id[id]} 
							is_classic={false}
						/>
					)}
				</TransactionPlayers>
				<TransactionButtonsWrapper 
					numTrades={highestNumberInTrade}
				>{trade_controls}</TransactionButtonsWrapper>
				<TransactionPlayers>
					<p>To you, for:</p>
					{old_player_id.map(id => (
						<TradeInCell 
							key={id} 
							player={players_by_id[id]} 
							is_classic={false}
						/>
					))}
				</TransactionPlayers>
			</TransactionPlayersWrapper>
			<TransactionButtonsWrapper show_mobile>{trade_controls}</TransactionButtonsWrapper>
		</React.Fragment>
	);
};

export default TradeToUser;