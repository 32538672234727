// @flow
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import * as selectors from "../../modules/selectors";
import { isOffSeason } from "../../utils";
import { isAuthenticated } from "../../utils/auth";

const IS_TERMS_CLOSED = process.env.REACT_APP_IS_TERMS_CLOSED || false;

const OFF_SEASON = isOffSeason();

const security_link = "https://www.telstra.com.au/cyber-security-and-safety";

const Nav = styled.nav`
	color: white;
	ul {
		padding: 0;
		margin: 0;
	}

	li {
		font-size: 24px;
		line-height: 1.1;
		color: white;
	}
`;

const StyledLink = styled(Link)`
	font-size: 14px;
	line-height: 1.1;
	color: white;
	font-weight: 200;
	text-decoration: none;
`;

const StyledAnchor = styled.a`
	font-size: 14px;
	line-height: 1.1;
	color: white;
	font-weight: 200;
	text-decoration: none;
`;

type Props = {
	right_menu_object: Array<Object>,
	left_menu_object: Array<Object>,
}

const privacy_link = `http://www.telstra.com.au/privacy/
	privacy-statement/?ref=Net-Footer-Corp-Privacy`;

const FooterNavComponent = ({ right_menu_object, left_menu_object }:Props) => {
	const items = OFF_SEASON ? [...left_menu_object] : [
		...left_menu_object,
		...right_menu_object,
		{
			to: "/contact-us",
			title: "Contact Us"
		},
		
	];
	if([false, "false"].includes(IS_TERMS_CLOSED)){
		items.push(	{
			to: isAuthenticated() 
				? "/account/terms-and-conditions"
				: "/terms-conditions",
			title: "Terms and Conditions"
		});
	}
	return (
		<Nav>
			<ul>
				{items.map((item, index) => {
					if(!item.non_link) {
						return (
							<li key={`${item.to}-${index}`}>
								<StyledLink to={item.to || "/"}>
									{item.title}
								</StyledLink>
							</li>
						);
					}
					else {
						return null;
					}
				})}
				<li>
					<StyledAnchor 
						href={privacy_link} 
						target="_blank" 
						rel="noopener noreferrer" 
						title="Privacy Policy">Privacy Policy</StyledAnchor>
				</li>
				<li>
					<StyledAnchor
						href={security_link}
						target="_blank" rel="noreferrer"
					>
						Online Security
					</StyledAnchor>
				</li>
			</ul>
		</Nav>
	);
};

const mapStateToProps = (state, props) => ({
	right_menu_object: selectors.uiMenu.getRightNav(state, props),
	left_menu_object: selectors.uiMenu.getLeftNav(state, props),
});


const FooterNav = withRouter(connect(mapStateToProps, null)(FooterNavComponent));

export default FooterNav;