import styled from "styled-components";
import React from "react";
import type { TPlayer } from "../../modules/types";
import { below } from "../../assets/css/media";
import getPlayerUrl from "../../utils/playerUrl";

export const PlayerAvatarWrapper = styled.div`
	height: 60px;
	width: ${({ edit_teams_view }) => edit_teams_view ? "45px;" : "55px;"}
	object-fit: contain;
	cursor: pointer;

	${({ short_opponent }) => short_opponent ? "margin-left: -20px;" : ""}

	img {
		max-width: 100%;
		height: 100%;
	}
	${below.phone`
		width: ${({ league_matchup }) => league_matchup ? "45px" : ""}
	`}
`;

type Props = {
	reverse?: boolean,
	is_compressed?: boolean,
	player: TPlayer,
	openPlayerPopup?: Function,
	edit_teams_view?: boolean,
	league_matchup?: boolean,
}

const PlayerAvatar = ({ reverse, player, is_compressed, 
	openPlayerPopup, edit_teams_view, league_matchup }: Props) => {
	if (is_compressed) {
		return null;
	}
	return (
		<PlayerAvatarWrapper
			reverse={reverse}
			className="player-avatar"
			onClick={openPlayerPopup}
			edit_teams_view={edit_teams_view}
			league_matchup={league_matchup}
		>
			<picture>
				<source
					srcSet={getPlayerUrl(player.id, true)}
					type="image/webp" />
				<img
					src={getPlayerUrl(player.id, true)}
					alt={`${player.first_name[0]}. ${player.last_name}`}
				/>
			</picture>
		</PlayerAvatarWrapper>
	);
};

export default PlayerAvatar;