// @flow
import styled from "styled-components";
import colors from "../../assets/css/colors";

export const EmptyMessage = styled.div`
	text-align: center;
	padding: 20px;
    width: 100%;
    margin: 10px auto;
    text-align: left;
    background: ${colors.secondary.lightGrey};
    box-sizing: border-box;
    border-radius: 3px;

	font-family: SourceSansPro;
`;

export default EmptyMessage;