// @flow
import * as React from "react";
import styled from "styled-components";

import {
	FormGroup,
	ButtonSecondary,
} from "../../../../components";

const FormGroupStyled = styled(FormGroup)`
	display: flex;

	button{
		flex-basis: 30%;
	}
	
	input{
		flex-basis: 45%;
	
		&:disabled {
			opacity: .5;
			margin-left: 0;
		}
	}
`;

type Props = {
	handleCopy: Function,
}
export const LeaguePin = ({ handleCopy }: Props) => (
	<div>
		<FormGroupStyled>
			<ButtonSecondary onClick={handleCopy}>
				Copy PIN number
			</ButtonSecondary>

		</FormGroupStyled>
	</div>
);

export default LeaguePin;