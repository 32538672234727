// @flow
import { createReducer } from "redux-act";
import * as _ from "lodash";
import * as actions from "../../actions";
import { arrayToObjectById } from "./../../../helpers";

const initial_state = {
	create: {
		is_pending: false,
		error: null,
	},
	update: {
		is_pending: false,
		error: null,
	},
	show: {
		is_pending: false,
		error: null,
		league_id: null,
		success: false,
	},
	show_my: {
		is_pending: false,
		error: null,
		ordered_ids: [],
	},
	show_to_join: {
		is_pending: false,
		error: null,
		ordered_ids: [],
		offset: 0,
	},
	join_to: {
		is_pending: false,
		error: null,
		league_id: null,
		team_id: null,
	},
	show_teams: {
		is_pending: false,
		error: null,
		ordered_ids: [],
		offset: 0
	},
	remove: {
		is_pending: false,
		error: null,
		removedLeague: 0
	},
	invite_to: {
		is_pending: false,
		error: null,
		team_id: null,
	},
	ladder: {
		is_pending: false,
		error: null,
		offset: 0,
		ordered_ids: [],
		is_load_more: false
	},
	leave: {
		is_pending: false,
		error: null,
	},
	show_order: {
		is_pending: false,
		ordered_ids: [],
		error: null,
	},
	my_list: {
		is_pending: false,
		ordered_ids: [],
		error: null,
		by_id: {},
	},
	waiver_order: {
		is_pending: false,
		ordered_ids: [],
		error: null,
		by_id: {},
	},
	waiver_list: {
		is_pending: false,
		ordered_ids: [],
		error: null,
		by_id: {},
	},
	waiver_free: {
		is_pending: false,
		ordered_ids: [],
		error: null,
	},
	waiver_requests: {
		is_pending: false,
		ordered_ids: [],
		by_id: {},
		error: null,
	},
	regenerate_list: {
		is_pending: false,
		error: null,
		result: []
	},
	regenerate_show: {
		is_pending: false,
		error: null,
		by_id: {}
	},
	by_id: {},
	teams_by_id: {},
	celebrities: {
		is_pending: false,
		error: null,
		by_id: {},
		add_pending: false
	},
	add_celebrities: {
		is_pending: false,
		error: null,
	},
	remove_team: {
		is_pending: false,
		error: null,
	},
	rosters: {
		is_pending: false,
		error: null,
		teams_by_id: {},
		ordered_ids: [],
	}
};

export const leaguesClassic = createReducer({}, initial_state);

leaguesClassic.on(actions.createClassicLeagueSuccess, (state, payload) => ({
	...state,
	create: {
		...state.create,
		is_pending: false,
		data: payload,
		error: null,
	},
}));

leaguesClassic.on(actions.createClassicLeagueFailed, (state, payload) => ({
	...state,
	create: {
		...state.create,
		is_pending: false,
		error: payload,
	},
}));

leaguesClassic.on(actions.clearClassicLeagueErrors, state => ({
	...state,
	create: {
		...state.create,
		is_pending: false,
		error: null,
	},
	update: {
		...state.update,
		is_pending: false,
		error: null,
	},
}));

leaguesClassic.on(actions.showClassicLeagueSuccess, (state, payload: any) => {
	const existing_league = state.by_id[payload.id];
	const merged_league = { ...existing_league, ...payload };
	const by_id = { ...state.by_id, [merged_league.id]: merged_league };
	let teams_array = payload.teams ? payload.teams : []; 
	const current_teams_by_id = state.teams_by_id;
	const teams_by_id = _.reduce(teams_array, (teams, team) => {
		return {
			...teams,
			[team.id]: {
				..._.get(teams, team.id, {}),
				...team,
				..._.get(current_teams_by_id, team.id, {}),
				scoreflow: team.scoreflow
			}
		};
	}, state.teams_by_id);

	
	// const teams_by_id = arrayToObjectById(teams_array, state.teams_by_id);
	const final_teams_by_id = _.isEmpty(teams_by_id) ? state.teams_by_id : teams_by_id;
	// fix teams by id here to match 
	return {
		...state,
		show: {
			...state.show,
			is_pending: false,
			data: payload,
			league_id: payload.id,
			success: true,
		},
		by_id,
		teams_by_id: final_teams_by_id
	};
});

leaguesClassic.on(actions.showTeamsClassic, state => ({
	...state,
	show_teams: {
		...state.show_teams,
		is_pending: true,
		error: null,
	},
}));

leaguesClassic.on(actions.clearTeamsClassic, state => ({
	...state,
	teams_by_id: {}
}));

leaguesClassic.on(
	actions.showTeamsClassicSuccess, 
	(state, { offset = 0, result, league_id }: any) => {
		const teams_by_id = _.reduce(result, (teams, team) => {
			return {
				...teams,
				[team.id]: {
					..._.get(teams, team.id, {}),
					...team
				}
			};
		}, state.teams_by_id);

		const new_ordered_ids = result.map(item => item.id);
		const old_ordered_ids = _.get(state, "show_teams.ordered_ids", []);
		const ordered_ids = _.uniq([...old_ordered_ids, ...new_ordered_ids]);

		const original_league = _.get(state, ["by_id", league_id], {});

		return {
			...state,
			show_teams: {
				...state.show_teams,
				is_pending: false,
				ordered_ids,
				offset,
			},
			teams_by_id,
			by_id: {
				...state.by_id,
				[league_id]: {
					...original_league,
					teams: ordered_ids
				}
			}
		};
	}
);

leaguesClassic.on(actions.showTeamsClassicFailed, (state, payload) => ({
	...state,
	show_teams: {
		...state.show_to_join,
		is_pending: false,
		error: payload,
		ordered_ids: [],
		offset: 0,
	},
}));

leaguesClassic.on(actions.howJoJoinClassicLeagues, state => ({
	...state,
	show_to_join: {
		...state.show_to_join,
		is_pending: true,
		error: null,
		ordered_ids: [],
		offset: 0,
	},
}));

leaguesClassic.on(actions.howJoJoinClassicLeaguesSuccess, (state, { offset = 0, result }: any) => {
	const by_id = arrayToObjectById(result, state.by_id);
	const ordered_ids = result.map(item => item.id);
	return {
		...state,
		show_to_join: {
			...state.show_to_join,
			is_pending: false,
			ordered_ids,
			offset,
		},
		by_id,
	};
});

leaguesClassic.on(actions.howJoJoinClassicLeaguesFailed, (state, payload) => ({
	...state,
	show: {
		...state.show,
		is_pending: false,
		error: payload,
		success: false,
	},
}));

leaguesClassic.on(actions.showClassicLeague, (state, payload) => ({
	...state,
	show: {
		...state.show,
		is_pending: true,
		error: null,
		league_id: payload.id
	},
}));

leaguesClassic.on(actions.showClassicLeagueFailed, (state, payload) => ({
	...state,
	show: {
		...state.show,
		is_pending: false,
		error: payload,
		success: false,
	},
}));

leaguesClassic.on(actions.showToJoinLoadMoreClassicLeagues, state => ({
	...state,
	show_to_join: {
		...state.show_to_join,
		is_pending: true,
		error: null,
	},
}));

leaguesClassic.on(actions.showToJoinLoadMoreClassicLeaguesSuccess,
	(state, { offset, result }: any) => {
		const by_id = arrayToObjectById(result, state.by_id);
		const ordered_ids = result.map(item => item.id);

		return {
			...state,
			show_to_join: {
				...state.show_to_join,
				is_pending: false,
				ordered_ids: _.uniq([
					...state.show_to_join.ordered_ids,
					...ordered_ids,
				]),
				offset,
			},
			by_id,
		};
	});

leaguesClassic.on(actions.showToJoinLoadMoreClassicLeaguesFailed, (state, payload) => ({
	...state,
	show_to_join: {
		...state.show_to_join,
		is_pending: false,
		error: payload,
	},
}));

leaguesClassic.on(actions.joinToClassicLeague, state => ({
	...state,
	join_to: {
		...state.join_to,
		is_pending: true,
		error: null,
		team_id: null,
		league_id: null,
	},
}));

leaguesClassic.on(actions.joinToClassicLeagueSuccess, (state, payload: any) => ({
	...state,
	join_to: {
		...state.join_to,
		is_pending: false,
		error: null,
		team_id: payload.team_id,
		league_id: payload.league_id,
	},
}));

leaguesClassic.on(actions.joinToClassicLeagueFailed, (state, payload) => 
	({
		...state,
		join_to: {
			...state.join_to,
			is_pending: false,
			error: payload,
			team_id: null,
			league_id: null,
		},
	}));

leaguesClassic.on(actions.inviteToClassicLeague, state => ({
	...state,
	invite_to: {
		...state.invite_to,
		is_pending: true,
	},
}));

leaguesClassic.on(actions.inviteToClassicLeagueFailed, (state, payload) => ({
	...state,
	invite_to: {
		...state.invite_to,
		is_pending: false,
		error: payload,
	},
}));

leaguesClassic.on(actions.inviteToClassicLeagueSuccess, (state, payload) => ({
	...state,
	invite_to: {
		...state.invite_to,
		is_pending: false,
		error: null,
		result: payload,
	},
}));

leaguesClassic.on(actions.updateClassicLeague, state => ({
	...state,
	update: {
		...state.update,
		is_pending: true,
		error: null,
	},
}));

leaguesClassic.on(actions.updateClassicLeagueSuccess, state => ({
	...state,
	update: {
		...state.update,
		is_pending: false,
		error: null,
	},
}));

leaguesClassic.on(actions.updateClassicLeagueFailed, (state, payload) => ({
	...state,
	update: {
		...state.update,
		is_pending: false,
		error: payload,
	},
}));

leaguesClassic.on(actions.leaveClassicLeague, state => ({
	...state,
	leave: {
		...state.leave,
		is_pending: true,
	},
}));

leaguesClassic.on(actions.leaveClassicLeagueFailed, (state, payload) => ({
	...state,
	leave: {
		...state.leave,
		is_pending: false,
		error: payload,
	},
}));

leaguesClassic.on(actions.leaveClassicLeagueSuccess, (state, payload) => ({
	...state,
	leave: {
		...state.leave,
		is_pending: false,
		error: null,
	},
	show_my: {
		...state.show_my,
		ordered_ids: state.show_my.ordered_ids.filter(item => item !== payload),
	},
}));

leaguesClassic.on(actions.showMyClassicLeagues, (state, payload) => ({
	...state,
	show_my: {
		...state.show_my,
		is_pending: true,
		error: null
	}
}));

leaguesClassic.on(actions.showMyClassicLeaguesSuccess, (state, payload) => {

	const ordered_ids = payload.map(item => item.id);
	const by_id = payload.reduce((obj, item) => {
		if (obj[item.id]) {
			return { ...obj, [item.id]: { ...obj[item.id], ...item } };
		}
		return { ...obj, [item.id]: item };
	}, state.by_id);

	return {
		...state,
		show_my: {
			...state.show_my,
			is_pending: false,
			error: null,
			ordered_ids,
		},
		by_id: {
			...state.by_id,
			...by_id
		}
	};
});

leaguesClassic.on(actions.showMyClassicLeaguesFailed, (state, payload) => ({
	...state,
	show_my: {
		...state.show_my,
		is_pending: false,
		error: payload
	}
}));

// ladderClassicLeaguesReducer
leaguesClassic.on(actions.ladderClassicLeague, state => ({
	...state,
	ladder: {
		...state.ladder,
		is_pending: true,
		error: null,
		offset: 0,
		is_load_more: false
	},
}));
leaguesClassic.on(actions.ladderClassicLeagueFailed, (state, payload) => ({
	...state,
	ladder: {
		...state.ladder,
		is_pending: false,
		error: payload,
	},
}));
leaguesClassic.on(actions.ladderClassicLeagueSuccess,
	(state, { offset = 0, league_id, result }: any ) => {
		const teams_by_id = result.reduce((all_teams, team) => {
			//keep scoreflow the same because we want this set by show league on success
			if(state.teams_by_id[team.id] && state.teams_by_id[team.id].scoreflow){
				team.scoreflow = state.teams_by_id[team.id].scoreflow;
			}
			
			if (team.id in all_teams) {
				return { ...all_teams, [team.id]: { ...all_teams[team.id], ...team } };
			}
			return { ...all_teams, [team.id]: team };
		}, state.teams_by_id);
		// Start with empty object (rather than old object) so that teams removed from league,
		// are removed from ladder

		return {
			...state,
			ladder: {
				...state.ladder,
				is_pending: false,
				error: null,
				ordered_ids: result.map(team => team.id),
				offset,
				is_load_more: result.length >= 100
			},
			by_id: {
				...state.by_id,
				[league_id]: {
					...state.by_id[league_id],
					teams: teams_by_id,
				},
			},
			teams_by_id,
		};
	});
// ladderClassicLeaguesReducer
leaguesClassic.on(actions.ladderLoadMoreClassicLeague, state => ({
	...state,
	ladder: {
		...state.ladder,
		is_pending: true,
		error: null,
	},
}));
leaguesClassic.on(actions.ladderLoadMoreClassicLeagueFailed, (state, payload) => ({
	...state,
	ladder: {
		...state.ladder,
		is_pending: false,
		error: payload,
	},
}));
leaguesClassic.on(actions.ladderLoadMoreClassicLeagueSuccess,
	(state, { offset, league_id, result }: any ) => {
		const teams_by_id = result.reduce((obj, item) => {
			if (obj[item.id]) {
				return { ...obj, [item.id]: { ...obj[item.id], ...item } };
			}
			return { ...obj, [item.id]: item };
		}, state.teams_by_id);
		const ordered_ids = result.map(item => item.id);
		// const teams_by_id = _.mergeWith(_.keyBy(result, 'id'),
		//	state.teams_by_id, customizer);

		return {
			...state,
			ladder: {
				...state.ladder,
				is_pending: false,
				error: null,
				ordered_ids: _.uniq([
					...state.ladder.ordered_ids,
					...ordered_ids,
				]),
				offset,
				is_load_more: result.length >= 100,
			},
			by_id: {
				...state.by_id,
				[league_id]: {
					...state.by_id[league_id],
					teams: result.map(item => item.id),
				},
			},
			teams_by_id,
		};
	});

leaguesClassic.on(actions.myListClassicLeagues, state => ({
	...state,
	my_list: {
		...state.my_list,
		is_pending: true,
	},
}));

leaguesClassic.on(actions.myListClassicLeaguesSuccess, (state, payload: any) => ({
	...state,
	my_list: {
		...state.my_list,
		is_pending: false,
		success: true,
		ordered_ids: payload.map(item => item.id),
		by_id: arrayToObjectById(payload),
	},
}));

leaguesClassic.on(actions.myListClassicLeaguesFailed, (state, payload) => ({
	...state,
	my_list: {
		...state.my_list,
		is_pending: false,
		sucesss: false,
		error: payload,
	},
}));

leaguesClassic.on(actions.removeClassicLeague, state => ({
	...state,
	remove: {
		...state.remove,
		is_pending: true,
		error: null,
		success: false,
		removedLeague: 0
	},
}));

leaguesClassic.on(actions.removeClassicLeagueFailed, (state, payload) => ({
	...state,
	remove: {
		...state.remove,
		is_pending: false,
		error: payload
	},
}));
leaguesClassic.on(actions.removeClassicLeagueSuccess, (state, payload) => {
	const updatedByID = Object.keys(state.by_id).filter(item => Number(item) !== payload)
		.reduce(
			(obj, id) => {
				return {
					...obj[id],
					[id]: state.by_id[id]
				};
			},
			{}
		);
	return {
		...state,
		remove: {
			...state.remove,
			is_pending: false,
			error: null,
			success: true,
			removedLeague: payload
		},
		show_my: {
			...state.show_my,
			ordered_ids: state.show_my.ordered_ids.filter(item => Number(item) !== payload),
		},
		my_list: {
			...state.my_list,
			ordered_ids: state.my_list.ordered_ids.filter(item => Number(item) !== payload),
		},
		show_to_join: {
			...state.show_to_join,
			ordered_ids: state.show_to_join.ordered_ids.filter(item => Number(item) !== payload),
		},
		by_id: updatedByID,
	};
});


// Regen Leagues
leaguesClassic.on(actions.regenerateListClassicLeagues, state => ({
	...state,
	regenerate_list: {
		is_pending: true,
		error: null,
		result: []
	}
}));
leaguesClassic.on(actions.regenerateListClassicLeaguesSuccess, (state, payload: any) => ({
	...state,
	regenerate_list: {
		is_pending: false,
		error: null,
		result: payload
	}
}));
leaguesClassic.on(actions.regenerateListClassicLeaguesFailed, (state, payload: any) => ({
	...state,
	regenerate_list: {
		is_pending: false,
		error: payload,
		result: []
	}
}));
leaguesClassic.on(actions.regenerateShowClassicLeague, state => ({
	...state,
	regenerate_show: {
		...state.regenerate_show,
		is_pending: true,
		error: null
	}
}));
leaguesClassic.on(actions.regenerateShowClassicLeagueSuccess, (state, payload: any) => ({
	...state,
	regenerate_show: {
		is_pending: false,
		error: null,
		by_id: {
			...state.regenerate_show.by_id,
			[payload.id]: payload
		}
	}
}));
leaguesClassic.on(actions.regenerateShowClassicLeagueFailed, (state, payload: any) => ({
	...state,
	regenerate_show: {
		...state.regenerate_show,
		is_pending: false,
		error: payload
	}
}));

leaguesClassic.on(actions.getCelebrities, (state, payload: any) => ({
	...state,
	celebrities: {
		...state.celebrities,
		is_pending: true,
		error: null,
	}
}));
leaguesClassic.on(actions.getCelebritiesFailed, (state, payload: any) => ({
	...state,
	celebrities: {
		...state.celebrities,
		is_pending: false,
		error: payload,
	}
}));
leaguesClassic.on(actions.getCelebritiesSuccess, (state, payload: any) => {
	return {
		...state,
		celebrities: {
			...state.celebrities,
			is_pending: false,
			error: null,
			by_id: {
				...state.celebrities.by_id,
				..._.keyBy(payload.result, team => team.id)
			}
		}
	};
});

leaguesClassic.on(actions.addCelebritiesToLeague, (state, payload: any) => ({
	...state,
	add_celebrities: {
		is_pending: true,
		error: null,
	}
}));
leaguesClassic.on(actions.addCelebritiesToLeagueFailed, (state, payload: any) => ({
	...state,
	add_celebrities: {
		is_pending: false,
		error: payload
	}
}));
leaguesClassic.on(actions.addCelebritiesToLeagueSuccess, (state, payload: any) => {
	return {
		...state,
		add_celebrities: {
			is_pending: false,
			error: null,
		}
	};
});

leaguesClassic.on(actions.removeTeamFromLeague, (state, payload: any) => ({
	...state,
	remove_team: {
		is_pending: true,
		error: null,
	}
}));
leaguesClassic.on(actions.removeTeamFromLeagueFailed, (state, payload: any) => ({
	...state,
	remove_team: {
		is_pending: false,
		error: payload
	}
}));
leaguesClassic.on(actions.removeTeamFromLeagueSuccess, (state, payload: any) => {
	return {
		...state,
		remove_team: {
			is_pending: false,
			error: null,
		}
	};
});

leaguesClassic.on(actions.getClassicLeagueRosters, (state, payload: any) => {
	return {
		...state,
		rosters: {
			...state.rosters,
			is_pending: true,
			error: null,
		}
	};
	
});
leaguesClassic.on(actions.getClassicLeagueRostersFailed, (state, payload: any) => ({
	...state,
	rosters: {
		...state.rosters,
		is_pending: false,
		error: payload
	}
}));
leaguesClassic.on(actions.getClassicLeagueRostersSuccess, (state, payload: any) => {
	const result_payload = payload.result;

	const teams_by_id = result_payload.reduce((obj, item) => {
		obj[item.id] = item;
		return obj;
	}, {});

	
	const round_id = payload.round_id;
	const insert_teams_by_id = result_payload.reduce((obj, item) => {
		if (obj[item.id]) {
			return { ...obj, [item.id]: { ...obj[item.id], ...item } };
		}
		return { ...obj, [item.id]: item };
	}, state.teams_by_id);
	const insert_round_team_object = {
		[round_id]: insert_teams_by_id,
	};

	return {
		...state,
		rosters: {
			is_pending: false,
			error: null,
			ordered_ids: _.map(result_payload, "id"),
			teams_by_id: teams_by_id,
			teams_by_round: {
				...state.rosters.teams_by_round, 
				...insert_round_team_object
			}
      
		},
	};
  
});

export default leaguesClassic;