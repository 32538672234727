// @flow
import * as React from "react";
import styled from "styled-components";
import { SwitchToggle } from "../../components";

const ToggleLabel = styled.label`
	color: white;
	font-size: 15px;
	line-height: 1.2;
	text-align: right;
	text-transform: uppercase;
	padding-top: 5px;
`;

const FlipTogglerWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px 20px;
  	-webkit-user-select: none; /* Chrome/Safari */        
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* IE10+ */
  	
  	.chat-on-toggle {
  		input + label {
  			background: #1D4073;
 			&:before {
				background: white;
			}
  		}
  	}
	
	div {
		text-align: center;
		margin-right: 0;
	}
	
	
	p {
		width: 100%;
		padding-top: 5px;
		margin-right: 8px;
		label {
			cursor: pointer;
		}
	}
`;

type Props = {
	onChange: Function,
	is_checked: boolean
}

export const ChatToggle = ({ onChange, is_checked }: Props) => (
	<FlipTogglerWrapper>
		<p>
			<ToggleLabel htmlFor="chat-toggle">
				Chat {is_checked ? "On" : "Off"}
			</ToggleLabel>
		</p>
		<SwitchToggle
			onChange={onChange}
			id="chat-toggle"
			name="chat-toggle"
			className="chat-on-toggle"
			value={is_checked}
		/>
	</FlipTogglerWrapper>
);

export default ChatToggle;