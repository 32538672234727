// @flow
import * as React from "react";
import styled from "styled-components";
import { RotateWrapper } from "../RotateWrapper";
import { Rotate } from "../Rotate";
import colors from "../../assets/css/colors";
import { below } from "../../assets/css/media";

const getBackground = (
	{ is_captain, is_vice_captain, is_emergency_enabled, is_emergency, background }
) => {
	if (is_captain) {
		return colors.player.captain;
	}
	if (is_vice_captain) {
		return colors.player.vice_captain;
	}

	if (is_emergency) {
		return colors.player.emergency;
	}

	if (is_emergency_enabled) {
		return colors.player.emergency;
	}

	return background || colors.title;
};

const RotatedLabelStyled = styled(RotateWrapper)`
	width: 20px;
	height: 60px;
	background-color: ${props => getBackground(props)};
	box-sizing: border-box;
	border-radius: ${({ border_radius }) => border_radius || 0};

	> div {
		box-sizing: border-box;
		color: #fff;
		width: 60px;
		height: 20px;
		font-size: 11px;
		letter-spacing: 0.5px;
		line-height: 1.54;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	${below.desktop`
		height: 50px;
		> div {
			width: 50px;
		}
	`}
`;


type Props = {
	text: string,
	rotate?: string
};

export const RotatedLabel = ({ text, rotate = "-90", ...props }: Props) => (
	<RotatedLabelStyled {...props}>
		<Rotate rotate={rotate}>
			{text}
		</Rotate>
	</RotatedLabelStyled>
);

export * from "../Rotate";
export default RotatedLabel;