// @flow
import * as React from "react";
import styled, { css } from "styled-components";
import moment from "moment";
import * as _ from "lodash";
import type { TPlayersById } from "../../../../../modules/types";
import { below } from "../../../../../assets/css/media";
import {
	TradeContent, TradeInCellShow,
	TransactionPlayers,
	TransactionPlayersWrapper,
	TradeOutCellShow,
	PlayerTableCell, TradeCell
} from "../../../../../components";
import TradeTitleWrapper from "../tradeTitleWrapper";
import TradeTitleStyled from "../tradeTitleStyled";
import TransactionsIcon from "../../../../../components/Icons/Transactions";
import colors from "../../../../../assets/css/colors";

const StyledTransactionPlayersWrapper = styled(TransactionPlayersWrapper)`
	background: transparent;
	justify-content: ${props => props.transactionType && props.transactionType === "Drop"
		? "flex-end":"space-between"};
	${below.phone`
		flex-direction: column
	`}
  margin-bottom: ${({ league_team }) => league_team ? "10px" : "0px"};
`;

const StyledTradeTitleWrapper = styled(TradeTitleWrapper)``;

const TradeTitleTransaction = styled(TradeTitleStyled)`
	font-size: 16px;
	margin-bottom: 15px;
`;

const StyledTradeContent = styled(TradeContent)`
	background: #F8F8FA;
`;

const TransactionHistoryDiv = styled.div`
	width: 100%;
	margin-top: 10px;
	background-color: #fff;
	${({ compressed }) => compressed ? css`
		${StyledTradeTitleWrapper}, ${StyledTransactionPlayersWrapper} {
			background: unset;
		}

		${TransactionPlayers} > div {
			background: #F0F3F7;
		}
	` : css`
		${TradeCell} {
			${below.phone`
				width: 100%;
				.player-details-info > p:first-of-type {
					width: 90px;
				}
			`}
		}
		${PlayerTableCell} {
			${below.phone`
				height: 50px;
				.favourite-button {
					display: none;
				}

				.player-details-status {
					height: 50px;
					> div {
						height: 50px;
						&:before, &:after {
							top: 0;
							height: 100%;
						}
					}
				}
			`}
		}
	`}
`;

const IconWrapper = styled.div`
	display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    align-self: stretch;
    margin-top: 25px;

	${below.tablet`
		padding: 0 5px;
	`};

	${below.phone`
		> svg {
			height: 10px;
			width: 10px;
		}
	`}
`;

type Props = {
	trade: Array<Object>,
	players_by_id: TPlayersById,
	compressed?: boolean,
	empty_state?: any,
  league_team?: boolean,
}
class TransactionHistoryRFARow extends React.Component<Props> {
	static defaultProps = {
		compressed: false
	};

	TradeTransaction(transaction: Object) {
		const { players_by_id, league_team } = this.props;
		const from_user_id = transaction.value[0].from_team_id;
		const to_user_id = transaction.value[0].to_team_id;
		const total_from_trade = transaction.value
			.map( trade => trade).filter( t => t.from_team_id === from_user_id);
		const total_to_trade = transaction.value
			.map( trade => trade).filter( t => t.from_team_id === to_user_id);
		return (
			<StyledTransactionPlayersWrapper league_team={league_team}>
				<TransactionPlayers>
					<p>
						{transaction.value[0].from} initiated and traded:
					</p>
					{total_from_trade.map(player =>
						<TradeInCellShow
							compressed={false}
							player={players_by_id[player.player_id]} 
							key={player.player_id} 
							is_classic={false}
							trade_item={true}
						/>
					)}
				</TransactionPlayers>
				<IconWrapper>
					<TransactionsIcon color={colors.primary.primary} size="2" />
				</IconWrapper>
				<TransactionPlayers>
					<p>
						With {transaction.value[0].to} for:
					</p>
					{total_to_trade.map(player =>
						<TradeOutCellShow
							compressed={false}
							player={players_by_id[player.player_id]} 
							key={player.player_id} 
							is_classic={false}
							trade_item={true}
						/>
					)}
				</TransactionPlayers>
			</StyledTransactionPlayersWrapper>
		);
	}

	AddDropTransaction(transaction: Object) {
		const { players_by_id, league_team } = this.props;
		const is_full_width = transaction.value.length >= 2;
		const transactionType = transaction.value[0].type;
		return (
			<StyledTransactionPlayersWrapper 
				transactionType={transactionType} 
				league_team={league_team}>
				{ transactionType === "Add" ?
					<TransactionPlayers>
						<p>
							{transaction.value[0].to} selected
							 the {transaction.value[0].from === "RFA's"
								&& "Restricted"} Free Agent:
						</p>
						<TradeInCellShow
							compressed={false}
							player={players_by_id[transaction.value[0].player_id]} 
							is_classic={false}
							trade_item={is_full_width}
						/>
					</TransactionPlayers> : null}
				{ transaction.value.length >= 2 ?
					<IconWrapper>
						<TransactionsIcon color={colors.primary.primary} size="2" />
					</IconWrapper>
					: null}
				{ transaction.value[1] ?
					<TransactionPlayers>
						<p>And delisted:</p>
						<TradeOutCellShow
							compressed={false}
							player={players_by_id[transaction.value[1].player_id]} 
							is_classic={false}
							trade_item={is_full_width}
						/>
					</TransactionPlayers> : null}
				{ transactionType === "Drop" ?
					<TransactionPlayers>
						<p>{transaction.value[0].from} delisted:</p>
						<TradeOutCellShow
							compressed={false}
							player={players_by_id[transaction.value[0].player_id]} 
							is_classic={false}
							trade_item={false}
						/>
					</TransactionPlayers> : null}
			</StyledTransactionPlayersWrapper>
		);
	}

	renderTransactionTitle(transaction: Object) {
		return (
			<TradeTitleTransaction>
				{moment(transaction.value[0].date_iso).format("DD MMMM YYYY") }
			</TradeTitleTransaction>
		);
	}

	renderTableRow(transaction: Object, index: number) {
		const { compressed } = this.props;
		return (
			<StyledTradeContent key={index} compressed={compressed}>
				<StyledTradeTitleWrapper compressed={compressed}>
					{this.renderTransactionTitle(transaction)}
				</StyledTradeTitleWrapper>
				{transaction["value"][0].type === "Trade"
					? this.TradeTransaction(transaction) : this.AddDropTransaction(transaction)}
				{/* <TransactionPlayersWrapper>
					<TransactionPlayers>
						<p>
							{_.get(from, '[0].from', '')}
						selected the {!is_fa && 'Restricted'} Free Agent{to.length > 1 ? 's' : ''}:
						</p>
						{from.map(transaction => (
							<TradeInCellShow
								key={_.uniqueId()}
								player={players_by_id[transaction.player_id]}
							/>
						))}
					</TransactionPlayers>
					<TransactionsIconWrapper>
						<TransactionsIcon size={2.5} />
					</TransactionsIconWrapper>

					{to.length && (
						<TransactionPlayers>
							<p>
								And delisted:
							</p>
							{to.map(transaction => (
								<TradeOutCellShow
									key={_.uniqueId()}
									player={players_by_id[transaction.player_id]}
								/>
							))}
						</TransactionPlayers>
					)}

				</TransactionPlayersWrapper> */}
			</StyledTradeContent>
		);
	}

	render() {
		const { trade, compressed, empty_state } = this.props;
		return (
			<TransactionHistoryDiv compressed={compressed}>
				{trade.map((transaction, index) => this.renderTableRow(transaction, index))}
				{_.isEmpty(trade) && empty_state}
			</TransactionHistoryDiv>
		);
	}
};

export default TransactionHistoryRFARow;