// @flow
import { createSelector } from "reselect";
import _ from "lodash";
import {BYE_ROUNDS} from "../../helpers";
import roundsSelector from "./rounds/roundsSelector";


const byeRoundSelector = (state, ownProps) => {
	const { rounds } = state,
		{ is_bye }= ownProps,
		bye_rounds = BYE_ROUNDS,
		{ ordered_ids, by_id } = rounds,
		ids_without_bye = ordered_ids.filter(id => !bye_rounds.includes(id))
	;

	return is_bye ? ids_without_bye.map(round_id => by_id[round_id]) : roundsSelector(state);
};
const isDraft = (state, props) => props.is_draft;

export default createSelector(byeRoundSelector, isDraft, (rounds, is_draft) => {
	if (rounds.length > 0) {
		const first_scheduled_round = _.find(rounds, { status: "scheduled" }) || _.last(rounds);
		let first_scheduled_round_id = first_scheduled_round.id;
		const league_start_rounds = _.filter(rounds, round => round.id >= first_scheduled_round_id);

		if (first_scheduled_round_id === 1 && !is_draft) {
			first_scheduled_round_id = 2;
		}

		return {
			rounds: league_start_rounds,
			selected_round_id: first_scheduled_round_id,
			selected_round: first_scheduled_round,
		};
	}
	return {
		rounds: [],
		selected_round_id: 1
	};
});