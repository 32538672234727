/* eslint max-len: 0 */
// @flow
import React from "react";
import SvgIconContainer from "../SvgIconContainer";

export default (
	{
		unit = "em",
		...rest
	}: Object = {}) => (
	<SvgIconContainer viewBox="0 0 16 16" {...rest} unit={unit}>
		<title>Info</title>
		<path fillRule="evenodd" clipRule="evenodd" d="M8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0ZM9.3546 5.77867H6.45272C6.24364 5.77477 6.06013 5.91717 6.01199 6.12067C5.96919 6.30155 6.04364 6.48721 6.19321 6.58958L6.25274 6.6239L6.92822 6.96164C7.08954 7.04118 7.18423 7.20934 7.173 7.38458L7.16374 7.45047L6.36384 11.1834C6.2514 11.7082 6.38209 12.2557 6.71945 12.6731C7.03086 13.0584 7.48758 13.2948 7.97813 13.329L8.10141 13.3333H9.3546C9.56078 13.3337 9.74016 13.1923 9.78782 12.9917C9.83018 12.8134 9.75858 12.63 9.61269 12.5265L9.55458 12.4917L9.18573 12.3081C8.85212 12.1398 8.6617 11.7836 8.70022 11.4183L8.71467 11.3269L9.7901 6.31638C9.81833 6.18496 9.78556 6.04782 9.70096 5.94336C9.63046 5.85632 9.53028 5.79976 9.42105 5.7835L9.3546 5.77867ZM9.21487 2.66739L9.11908 2.66791C8.61177 2.66782 8.15664 2.97974 7.97367 3.45291C7.79069 3.92607 7.91755 4.46305 8.29293 4.8043C8.66831 5.14556 9.21491 5.22082 9.66855 4.99371C10.1222 4.76661 10.3894 4.2839 10.3412 3.77889C10.3109 3.16738 9.81624 2.68852 9.21487 2.66739V2.66739Z"/>
	</SvgIconContainer>
);