// @flow
import * as React from "react";
import { reduxForm, Field } from "redux-form";
import Search from "../../components/Icons/Search";
import {
	SearchSubmitButton,
	SearchInputWrapper,
	Input,
} from "../../components";

type Props = {
	placeholder: string,
	field_name: string,
	handleSubmit: Function,
}

const SearchFormComponent = ({ placeholder, handleSubmit, field_name }: Props) => (
	<form onSubmit={handleSubmit} autoComplete="off">
		<SearchInputWrapper>
			<Field
				type="text"
				component={Input}
				name={field_name}
				placeholder={placeholder}
			/>
			<SearchSubmitButton type="submit">
				<Search size='3' />
			</SearchSubmitButton>
		</SearchInputWrapper>
	</form>
);
export const SearchForm = reduxForm({
	form: "search"
})(SearchFormComponent);

export default SearchForm;