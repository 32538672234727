// @flow
import * as React from "react";

import { Field } from "redux-form";
import { FormField, FormSelect, FormRadio } from "../../../../../components";
import { league_tooltips } from "../../../../../helpers";

export const LeagueSettings = (props: Object) => {
	const { league } = props;
	const is_create = league.id ? false : true;
	return (
		<div>
			<Field
				label="League name"
				type="text"
				name="name"
				placeholder="League name"
				component={FormField}
				tooltip={league_tooltips.name}
			/>

			<FormRadio
				label="Privacy settings"
				name="privacy"
				elements={[
					{
						value: 0,
						text: "Private",
					},
					{
						value: 1,
						text: "Public",
					},
				]}
				tooltip={league_tooltips.privacy}
				is_disabled={is_create ? false : league.status !== "scheduled" 
          || league.draft_status !== "scheduled"}
			/>

			<Field
				label="Draft type"
				name="draft_type"
				options={[
					{
						value: "live",
						text: "Live",
					},
					{
						value: "auto",
						text: "Auto",
					},
					{
						value: "commissioner",
						text: "Commissioner",
					},
				]}
				component={FormSelect}
				tooltip={league_tooltips.draft_type}
				is_disabled={is_create ? false : league.status !== "scheduled" 
          || league.draft_status === "complete"}
				className="redux-form-draft-type"
			/>
		</div>
	);
};

export default LeagueSettings;