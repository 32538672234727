// @flow
import * as React from "react";
import _, { get, isEmpty } from "lodash";
import * as moment from "moment";
import { connect } from "react-redux";
import * as Sentry from "@sentry/browser";

import type {
	rounds_types,
	TCoachStatsListReducer,
	TPlayer,
	TPlayersById,
	TPlayerStatusesReducer,
	TPosition,
	TRootStore,
	TSquad,
	TUser,
	TPLeague,
	TDraftTeamsById
} from "../../modules/types";

import JsonFetcher from "../utils/JsonFetcher";

import * as actions from "../../modules/actions";
import * as selectors from "../../modules/selectors";
import { score_settings, getLeagueFinalFullNames, getIsRegenerated } from "../../helpers";

const SEASON_YEAR = Number(process.env.REACT_APP_SEASON_YEAR) || 2024;

type TableColumns = Array<{
	key: string, prop: string, tooltip?: string, is_fc?: boolean, is_pro?: boolean
}>;

type TDraftProps = {
	match: {
		params: {
			league_id: string
		}
	},
	players: Array<TPlayer>,
	players_by_id: TPlayersById,
	squads: Array<TSquad>,
	showDraftLeague: typeof actions.leagueDraft.showDraftLeague,
	fetchLadder: Function,
	fetchCoachPlayers: typeof actions.fetchCoachPlayers,
	fetchAllCustomStats: typeof actions.fetchAllCustomStats,
	fetchAllCoachCustomStats: typeof actions.fetchAllCoachCustomStats,
	fetchFavourites: typeof actions.fetchFavourites,
	fetchPreDraftList: typeof actions.teamsDraft.fetchPreDraftList,
	postPreDraftList: typeof actions.teamsDraft.postPreDraftList,
	changeDraftOrder: typeof actions.leagueDraft.changeDraftOrder,
	addPlayerToPreDraftList: typeof actions.teamsDraft.addPlayerToPreDraftList,
	selectKeeper: typeof actions.teamsDraft.postSelectKeeper,
	removeKeeper: typeof actions.teamsDraft.removeKeeper,
	removePlayerFromPreDraftList: typeof actions.teamsDraft.removePlayerFromPreDraftList,
	updatePlayerPreDraftListOrder: typeof actions.teamsDraft.updatePlayerPreDraftListOrder,
	fetchAllStats: Function,
	is_pending_players: boolean,
	positions: Array<TPosition>,
	playerStatuses: TPlayerStatusesReducer,
	league: TPLeague,
	pre_draft_list_size: number,
	stats_options_list: TCoachStatsListReducer,
	actual_round: rounds_types.TRound,
	user: TUser,
	pub_nub_channel: string,
	pre_draft_list: Array<number>,
	has_assistant_coach: boolean,
	data_loading_complete: boolean,
	leagueDraftOrder: Array<number>,
	all_stats: Object,
  rounds_ordered: Array<Object>,
	selected_round_id: ?number,
	teams_by_id: TDraftTeamsById,
	draft_order_status:string
}

type TFilters = {
	search: string,
	by_squad: string,
	by_position: string,
	by_status: string,
	by_dpp: boolean,
	by_favourites: boolean,
	by_drafted: boolean,
	by_ignored: boolean,
}

type State = {
	sort_by: string,
	order_by_desc: boolean,
	filters: TFilters,
	players_show_limit: number,
	players_show_limit_step: number,
	is_coach_active: boolean,
	coachSelectValue: string,
	is_compressed: boolean,
	preloader: boolean,
  active_fixture_view: string,
	show_kept: boolean,
}

export type TDraftPassedProps = TDraftProps & State & {
	load_more_is_hidden: boolean,
	has_filters: boolean,
	players_filter: Function,
	table_keys: TableColumns,
	is_dnd_allowed: boolean,
	order_by_desc?: boolean,
	missed_draft_start: boolean,
	sort_by?: string,
	onSortColumnChange: Function,
	onToggleOrder: Function,
	onResetFilterClick: Function,
	isActiveTab: Function,
	onFiltersChange: Function,
	onFilterByPosition: Function,
	toggleKeptPlayers: Function,
	toggleDraftedPlayers: Function,
	show_kept_players: boolean,
	onLoadMoreClick: Function,
	onSearchSubmit: Function,
	onChangePlayerOrder: Function,
	updatePlayerPreDraftListOrder: Function,
	onSelectChange: Function,
	orderByComparator: Function,
	onCompressedViewClick: Function,
	onPlayerShowLimitClick: Function,
	onDrop: Function,
	onDropTeam: Function,
	onDragEnd: Function,
	onDragOver: Function,
	onDragEnter: Function,
	onDragStart: Function,
	onDragLeave: Function,
	onEnableDrag: Function,
	onDisableDrag: Function,
	all_stats: Object,
	teams_by_id: TDraftTeamsById,
	kept_players: Array<TPlayer>,
	kept_players_ids: Array<number>
}

const swapArrayElements = function (array = [], from = 0, to = 0) {
	const dest = array.slice();

	dest.splice(to, 0, dest.splice(from, 1)[0]);

	return dest;
};

const TOTAL_ROUNDS_SIZE = parseInt(process.env.REACT_APP_TOTAL_ROUNDS_SIZE, 10);

const getPubNubChannelName = props => (
	String(process.env.REACT_APP_PUBNUB_CHANNEL_PREFIX) + 
		`${SEASON_YEAR}_` + _.get(props, "match.params.league_id")
);

const getCompressedViewStatus = () => {
	if(localStorage.getItem("compressed_view")){
		return localStorage.getItem("compressed_view") === "true" ? true : false;
	}
	localStorage.setItem("compressed_view", "false");
	return false;
};

export const withDraftCore = (WrappedComponent: React.ComponentType<Object>) => {
	class DraftCore extends React.Component<TDraftProps, State> {
		static defaultProps = {
			players: [],
			pre_draft_list_size: 0,
		};

		constructor(props, state) {
			super(props, state);

			this.transfer_player_id = undefined;
			this.transfer_team_id = undefined;
			this.last_dnd_enter = undefined;
			this.initial_mouse_y_cord = 0;
			this.current_mouse_y_cord = 0;

			_.bindAll(this, [
				"onSortColumnChange",
				"onToggleOrder",
				"onResetFilterClick",
				"isActiveTab",
				"onFiltersChange",
				"onFilterByPosition",
				"onLoadMoreClick",
				"onSearchSubmit",
				"onChangePlayerOrder",
				"updatePlayerPreDraftListOrder",
				"onSelectChange",
				"orderByComparator",
				"onCompressedViewClick",
				"onPlayerShowLimitClick",
				"onDrop",
				"onDropTeam",
				"onDragStart",
				"onDragEnd",
				"onDragEnter",
				"onDragOver",
				"onResetAllFilters",
				"toggleKeptPlayers",
				"toggleDraftedPlayers"
			]);
		}

		state = {
			players_show_limit: 25,
			players_show_limit_step: 25,
			sort_by: "draft_order",
			order_by_desc: false,
			is_coach_active: false,
			is_compressed: getCompressedViewStatus(),
			coachSelectValue: "basic",
			preloader: true,
			active_fixture_view: "list",
			show_kept: false,
			filters: {
				search: "",
				by_squad: "",
				by_position: "",
				by_status: "",
				by_dpp: false,
				by_favourites: false,
				by_drafted: false,
				by_ignored: false,
			},
		};

		componentDidMount() {
			const {
				fetchCoachPlayers,
				fetchFavourites,
				fetchPreDraftList,
				showDraftLeague,
				match: { params: { league_id } },
				fetchAllStats,
				fetchLadder,
				actual_round
			} = this.props;

			if(_.isUndefined(league_id)) {
				throw new Error(
					"The \"match.params.league_id\" field is missed in props." +
					" Component should be wrapped by \"withRouter\" function" +
					" or be part of <Router> component"
				);
			}

			showDraftLeague({ id: league_id });
			
			fetchLadder({
				league_id: league_id,
				round: actual_round.id
			});
			fetchAllStats();
			
			fetchPreDraftList({ league_id });
			fetchFavourites();
			fetchCoachPlayers();
			this.getPlayerStats();
		}

		componentDidUpdate(prev_props) {
			const {league} = this.props;
			if(!prev_props.data_loading_complete && this.props.data_loading_complete) {
				this.setState({ preloader: false });
			}
			if(prev_props.league.id !== league.id){
				this.getPlayerStats();
			}
		}

		transfer_player_id: number | typeof undefined;
		transfer_team_id: number | typeof undefined;
		last_dnd_enter: Object | typeof undefined;
		initial_mouse_y_cord: number;
		current_mouse_y_cord: number;
		getPlayerStats() {
			const {
				fetchAllCustomStats,
				fetchAllCoachCustomStats,
				league,
				match: { params: { league_id } },
				has_assistant_coach,
			} = this.props;
			if(league && Boolean(league.custom_scoring_enabled)){
				fetchAllCustomStats(league_id);
				if(has_assistant_coach){
					fetchAllCoachCustomStats(league_id);
				}
			}
		}
		onSortColumnChange(column_value) {
			const { sort_by, order_by_desc } = this.state;
			const default_order = !_.includes(["stats.adp", "draft_order"], column_value);

			this.setState({
				sort_by: column_value,
				order_by_desc: _.eq(sort_by, column_value) ? !order_by_desc : default_order,
			});
		}

		onToggleOrder(value) {
			this.setState({
				order_by_desc: (
					_.isUndefined(value) ? !this.state.order_by_desc : value
				)
			});
		}

		onCompressedViewClick(is_compressed) {
			this.setState({ is_compressed });
			localStorage.setItem("compressed_view", is_compressed);
		}

		onPlayerShowLimitClick(players_show_limit_step) {
			this.setState({ players_show_limit_step });
		}
		
		toggleKeptPlayers(){
			const { show_kept } = this.state;
			this.setState({
				show_kept: !show_kept
			});
		}

		toggleDraftedPlayers(){
			const {  filters } = this.state;
			const { by_drafted } = filters;
			this.setState({
				filters:{
					...filters,
					by_drafted: !by_drafted
				}
			});
		}

		onResetFilterClick() {
			this.setState({
				sort_by: "stats.proj_avg",
				order_by_desc: true
			});
		}
		onResetAllFilters() {
			this.setState({
				sort_by: "draft_order",
				order_by_desc: false,
				filters: {
					search: "",
					by_squad: "",
					by_position: "",
					by_status: "",
					by_dpp: false,
					by_favourites: false,
					by_drafted: false,
					by_ignored: false,
				}
			});
		}

		get season_year() {
			const { actual_round } = this.props,
				today = new Date(),
				current_month = today.getMonth(),
				is_before_march = current_month < 2,
				current_year = today.getFullYear(),
				prev_year = current_year - 1;

			if(_.isEmpty(actual_round)) {
				return is_before_march ? prev_year : current_year;
			}

			const { id, status } = actual_round,
				is_old_season = id === 1 && status === "scheduled";

			return is_old_season && is_before_march ? prev_year : current_year;
		}

		get actual_round_id() {
			const { actual_round } = this.props;

			if(_.isEmpty(actual_round)) {
				return 1;
			}

			return actual_round.id;
		}

		get kept_players() {
			const { teams_by_id, league, players_by_id } = this.props;
			const { keeper, regenerated_from, uf_regenerated_from } = league;
			const isRegenerated = getIsRegenerated(regenerated_from,uf_regenerated_from);
			const isKeeperandRegen = Boolean(keeper) && isRegenerated;
			if(!isKeeperandRegen){
				return [];
			}
			const teamsKeeperPlayers = Object.keys(teams_by_id).map(teamID => {
				const keeperPlayers = _.get(teams_by_id[teamID], "keepers", []);
				const nonNullKeepersHandled = keeperPlayers === null ? [] : keeperPlayers;
				return typeof nonNullKeepersHandled === "string" 
					? JSON.parse(nonNullKeepersHandled) 
					: nonNullKeepersHandled;
			}).flat();
			const nonEmptyPlayers = teamsKeeperPlayers.filter(playerObj => !_.isEmpty(playerObj));
			return nonEmptyPlayers.map(player => {
				return {
					...player,
					...players_by_id[player.id]
				};
			}); 
	
		}
		get kept_players_ids() {
			const { teams_by_id, league } = this.props;
			const { keeper, regenerated_from, uf_regenerated_from } = league;
			const isRegenerated = getIsRegenerated(regenerated_from,uf_regenerated_from);
			const isKeeperandRegen = Boolean(keeper) && isRegenerated;
			if(!isKeeperandRegen){
				return [];
			}
			const teamsKeeperPlayers = Object.keys(teams_by_id).map(teamID => {
				const keeperPlayers = _.get(teams_by_id[teamID], "keepers", []);
				return typeof keeperPlayers === "string" 
					? JSON.parse(keeperPlayers) 
					: keeperPlayers;
			}).flat().filter(player => !_.isEmpty(player));
			return teamsKeeperPlayers.map(player => player.id);
		}

		get common_table_columns(){
			const { league } = this.props;
			const custom_scoring_enabled = _.get(league, "custom_scoring_enabled", false);
			const statsBasis = custom_scoring_enabled ? "custom_stats" : "stats";

			return [
				{ key: "GP", prop: `${statsBasis}.games_played`, tooltip: "Games Played" },
				{ key: "AVG", prop: `${statsBasis}.avg_points`, tooltip: "Average Points" },
				{ key: "TP", prop: `${statsBasis}.total_points`, tooltip: "Total Points" },
			];
		}

		get table_columns() {
			const { league } = this.props;

			const
				actual_round_id = this.actual_round_id,
				first_round_id = _.min([actual_round_id, TOTAL_ROUNDS_SIZE]),
				second_round_id = _.min([actual_round_id + 1, TOTAL_ROUNDS_SIZE]),
				third_round_id = _.min([actual_round_id + 2, TOTAL_ROUNDS_SIZE]);

			const custom_scoring_enabled = _.get(league, "custom_scoring_enabled", false);
			const statsBasis = custom_scoring_enabled ? "custom_stats" : "stats";

			const CoachesChoiceColumns = [
				...this.common_table_columns,
				{ key: "ADP", prop: "stats.adp", tooltip: "Average Draft Position" },
				{
					key: "% Owned",
					prop: "stats.leagues_rostered",
					tooltip: "% Owned in draft leagues",
					is_fc: true
				},
			]; 
			const isLiveDraftCoachesChoiceColumns = league.draft_status !== "active" ? 
				[
					...CoachesChoiceColumns,
					{
						key: `${SEASON_YEAR} Proj AVG`,
						prop: "stats.proj_avg",
						tooltip: "Projected Season Average",
						is_fc: false,
						is_pro: true
					},
				] : [ ...CoachesChoiceColumns ];

			const basicColumns = [
				...this.common_table_columns,
				{
					key: "L5 AVG",
					prop: `${statsBasis}.last_5_avg`,
					tooltip: "The previous 5 rounds average."
				},
				{ key: "ADP", prop: "stats.adp", tooltip: "Average Draft Position" },
			];

			const isLiveDraftbasicColumns = league.draft_status === "active" ? [
				...basicColumns, 
				{
					key: `${SEASON_YEAR} Proj AVG`,
					prop: "stats.proj_avg",
					tooltip: "Projected Season Average",
					is_fc: false,
					is_pro: true
				},
			] : [ ...basicColumns ];
			
			const scoringStats = () => {
				const customStats = [{
					key: "GP",
					prop: "stats.games_played",
					tooltip: "Games Played",
					is_fc: false
				}];
				if(league.custom_scoring && Object.keys(league.custom_scoring).length > 0){
					Object.keys(league.custom_scoring).forEach(i => {
						customStats.push({ 
							key: i,
							prop: i,
							tooltip: _.get(score_settings[i],"title", "" ), 
							is_fc: false
						});
					});
				}
				else{
					for(let i = 0; i < 9; i++){
						customStats.push({ 
							key: Object.keys(score_settings)[i],
							prop:  Object.keys(score_settings)[i],
							tooltip: _.get(
								score_settings[ Object.keys(score_settings)[i]],"title", "" 
							), 
							is_fc: false
						});
					}
					
				}
				return customStats;
			};
			return {
				"basic": isLiveDraftbasicColumns,
				"coach.coaches-choice": isLiveDraftCoachesChoiceColumns,
				"coach.consistency": [
					...this.common_table_columns,
					{ key: "L5 AVG PTS", prop: `${statsBasis}.last_5_avg`, tooltip: "L5 AVG PTS" },
					{
						key: "Cons Rating",
						prop: `${statsBasis}.consistency`,
						tooltip: "Consistency Rating",
						is_fc: true
					},
					{
						key: "20 < AVG",
						prop: `${statsBasis}.in_20_avg`,
						tooltip: "Games 20 < Avg",
						is_fc: true
					},
					{
						key: "20 > AVG",
						prop: `${statsBasis}.out_20_avg`,
						tooltip: "Games 20 > Avg",
						is_fc: true
					},
				],
				"coach.opposition": [
					...this.common_table_columns,
					{
						key: `Rd ${first_round_id} OPP AVG`,
						prop: `${statsBasis}.opp_avg_one_round_after_current`,
						tooltip: "Average against upcoming",
						is_fc: true
					},
					{
						key: `Rd ${second_round_id} OPP AVG`,
						prop: `${statsBasis}.opp_avg_two_rounds_after_current`,
						tooltip: "Average against upcoming opponent in 2 weeks",
						is_fc: true
					},
					{
						key: `Rd ${third_round_id} OPP AVG`,
						prop: `${statsBasis}.opp_avg_three_rounds_after_current`,
						tooltip: "Average against upcoming opponent in 3 weeks",
						is_fc: true
					},
					{
						key: "N3 OPP AVG",
						prop: `${statsBasis}.opp_avg_3_rounds`,
						tooltip: "Average of upcoming 3 opponents",
						is_fc: true
					},
				],
				"coach.venues": [
					...this.common_table_columns,
					{
						key: `Rd ${first_round_id} VEN AVG`,
						prop: `${statsBasis}.venue_avg_one_round_after_current`,
						tooltip: "Average at upcoming game venue",
						is_fc: true
					},
					{
						key: `Rd ${second_round_id} VEN AVG`,
						prop: `${statsBasis}.venue_avg_two_rounds_after_current`,
						tooltip: "Average at upcoming game venue in 2 weeks",
						is_fc: true
					},
					{
						key: `Rd ${third_round_id} VEN AVG`,
						prop: `${statsBasis}.venue_avg_three_rounds_after_current`,
						tooltip: "Average at upcoming game venue in 3 weeks",
						is_fc: true
					},
					{
						key: "N3 VEN AVG",
						prop: `${statsBasis}.venue_avg_3_rounds`,
						tooltip: "Average at upcoming 3 game venues",
						is_fc: true
					},
				],
				"coach.custom-stats": scoringStats()
			};
		};

		get missed_draft_start() {
			const { league } = this.props;

			return _.every([
				!_.isEmpty(league),
				this.league_scheduled,
				this.missed_start
			]);
		}

		get league_scheduled() {
			const { league } = this.props;
			const draft_status = _.get(league, "draft_status");

			return draft_status === "scheduled";
		}

		get missed_start() {
			const { league } = this.props;
			const draft_start = _.get(league, "draft_start");

			try {
				if (!draft_start) {
					return false;
				}

				// Give a 1 min buffer before saying draft has been missed
				const draft_moment = moment(draft_start).add(1, "m");
				const now = moment();

				return draft_moment.isBefore(now);
			}
			catch (e) {
				// If anything goes wrong, just return false
				return false;
			}
		}

		isActiveTab(sort_prop) {
			const { sort_by } = this.state;
			return _.eq(sort_by, sort_prop) ? "active" : "";
		}

		accumulateFormInputsValueByName(accumulator: Object, formElement: Object) {
			const { type, name, checked, value } = formElement;

			if (_.eq(type, "submit")) {
				return accumulator;
			}

			if (!name) {
				throw new Error("All form inputs should be have \"name\" attribute!");
			}

			accumulator[name] = _.eq(type, "checkbox") ? checked : value;

			return accumulator;
		}

		formElementToKeyValueObject(form) {
			return Array.from(form.elements).reduce(this.accumulateFormInputsValueByName, {});
		}

		onFiltersChange(event) {
			const { filters } = this.state;

			const {
				order_by_desc,
				...rest_filters
			} = this.formElementToKeyValueObject(event.currentTarget);

			if(!_.isUndefined(order_by_desc)) {
				this.onToggleOrder(order_by_desc);
			}

			this.setState({
				filters: {
					...filters,
					...rest_filters,
				},
			});
		}

		onFilterByPosition(position_key) {
			const { filters } = this.state;

			this.setState({
				filters: {
					...filters,
					by_position: position_key === "bench" ? "" : position_key
				}
			});
		}

		onLoadMoreClick() {
			const { players_show_limit, players_show_limit_step } = this.state;

			this.setState({
				players_show_limit: players_show_limit + players_show_limit_step,
			});
		}

		onSearchSubmit(event) {
			event.preventDefault();
		}

		updatePlayerPreDraftListOrder({ currentTarget }) {
			const { order, id } = currentTarget.dataset;
			const { league, updatePlayerPreDraftListOrder } = this.props;
			const new_order = parseInt(order, 10);

			if (!_.isFinite(new_order)) {
				return;
			}

			updatePlayerPreDraftListOrder({
				player_id: id,
				new_order: new_order,
				team_id: league.team_id,
			});
		}

		onChangePlayerOrder({ currentTarget }) {
			const { id } = currentTarget.dataset;
			const order = currentTarget.value;
			
			this.updatePlayerPreDraftListOrder({
				currentTarget: { dataset: { id, order } },
			});
		}

		onSelectChange({ currentTarget }){
			const coachSelectValue = currentTarget.value;
			this.setState({
				coachSelectValue,
				is_coach_active: coachSelectValue !== "basic",
			});
		}

		get table_keys() {
			const { is_coach_active, coachSelectValue } = this.state,
				key = is_coach_active ? `coach.${coachSelectValue}` : "basic";

			return _.get(this.table_columns, key, []);
		}

		get order_value(){
			const {order_by_desc} = this.state;
			return order_by_desc ? 1 : -1;
		}

		orderByComparator(player_one, player_two) {
			const	{ sort_by } = this.state;
			const { all_stats } = this.props;

			let scoring_value_one = 0;
			
			let scoring_value_two = 0;
			if(sort_by.length <= 3){
				scoring_value_one = _.get(all_stats[player_one.id], `[${sort_by}]`, 0);
				scoring_value_two = _.get(all_stats[player_two.id], `[${sort_by}]`, 0);
			}
			const value_a = sort_by.length <= 3 ? scoring_value_one : 
				Number(_.get(player_one, sort_by));
			const value_b = sort_by.length <= 3 ? scoring_value_two :
				Number(_.get(player_two, sort_by));
				
			const 
				order = this.order_value,
				minimal = Number.MAX_SAFE_INTEGER * order,
				getResult = (condition, min, value) => condition(value) ? min : value,
				isStringOrZero = val => _.isString(val) || _.eq(val, 0),
				getResultOrMin = _.partial(getResult, isStringOrZero, minimal * -1),
				a_result = getResultOrMin(value_a),
				b_result = getResultOrMin(value_b),
				is_bigger = a_result < b_result,
				is_less = a_result > b_result;
			if(sort_by)
				if (is_bigger) {
					return order;
				}

			if (is_less) {
				return -1 * order;
			}

			return 0;
		}

		get has_filters() {
			const filters = _.omit(this.state.filters, ["by_drafted", "stats_type"]);

			return _.size(_.values(filters).filter(_.identity));
		}

		get players_filter() {
			const { filters } = this.state;

			const {
				by_status,
				by_squad,
				by_favourites,
				by_position,
				by_ignored,
				by_dpp,
				search,
			} = filters;

			const maybeGetFilter = (value, filter) => value ? filter : _.identity;

			return _.overEvery([
				maybeGetFilter(by_status, _.matches({ status: by_status })),
				maybeGetFilter(by_squad, _.matches({ squad_id: parseInt(by_squad, 10) })),
				maybeGetFilter(by_ignored, _.matches({ draft_order: 0 })),
				maybeGetFilter(by_favourites, _.property("is_favourite")),
				maybeGetFilter(by_position, _.flow([
					_.property("positions"),
					_.partial(_.includes, _, parseInt(by_position, 10)),
				])),
				maybeGetFilter(by_dpp, ({ positions }) => _.eq(_.size(positions), 2)),
				maybeGetFilter(search, (({ first_name, last_name }) => (
					`${first_name} ${last_name}`.toLowerCase().includes(search.toLowerCase())
				))),
			]);
		}

		get load_more_is_hidden() {
			const { players_show_limit } = this.state;
			const {	players, is_pending_players } = this.props;

			return _.some([
				is_pending_players,
				_.gt(players_show_limit, _.size(players))
			], _.identity);
		}

		get placeholder_class_name() {
			return "dnd-placeholder";
		}

		get finals_names() {
			const {
				rounds_ordered,
				league
			} = this.props;

			return getLeagueFinalFullNames(league,  rounds_ordered);
		}

		get is_finals() {
			const { selected_round_id } = this.props;
			return !_.isEmpty(_.get(this.finals_names, selected_round_id));
		}

		insertAfter(elem, refElem) {
			const
				parent = refElem.parentNode,
				next = refElem.nextSibling;

			return next ? parent.insertBefore(elem, next) : parent.appendChild(elem);
		}

		insertBefore(elem, refElem) {
			const parent = refElem.parentNode;
			return parent.insertBefore(elem, refElem);
		}

		onDragOver(event: Object) {
			try {
				event.preventDefault();
				this.current_mouse_y_cord = event.pageY;
			}
			catch (error) {
				if (Sentry) {
					// Gather info to check what's going wrong here
					if (Sentry) {
						Sentry.withScope(scope => {
							event.persist();
							scope.setExtra("event", event);
							scope.setExtra("event.pageY", _.get(event, "pageY"));
							scope.setExtra("current_mouse_y_cord", this.current_mouse_y_cord);
							Sentry.captureException(error);
						});
					}
				}
			}
		}

		onDragEnter(event) {
			event.preventDefault();

			const target = event.currentTarget;
			const player_id = parseInt(target.dataset.playerId, 10);
			const team_id = parseInt(target.dataset.teamId, 10);

			this.last_dnd_enter = this.last_dnd_enter || target;

			const same_element = this.last_dnd_enter === target;
			const draggable_player = this.transfer_player_id === player_id;
			const draggable_team = this.transfer_team_id === team_id;

			if(same_element || draggable_player || draggable_team) {
				return;
			}

			const dnd_disabled = !JSON.parse(target.dataset.dragAndDropEnabled);

			this.removeOldPlaceholder(event);

			if(dnd_disabled) {
				return;
			}

			this.last_dnd_enter = target;

			const td = document.createElement("td");

			td.setAttribute("colspan", _.size(target.querySelectorAll("td")));
			td.setAttribute("height", target.clientHeight);

			const tr = document.createElement("tr");

			tr.appendChild(td);

			tr.className = this.placeholder_class_name;
			tr.dataset.dragAndDropEnabled = "true";
			tr.dataset.playerId = String(player_id);
			tr.dataset.teamId = String(team_id);

			tr.addEventListener("drop", this.onDrop, true);
			tr.addEventListener("dragover", this.onDragOver, true);
			tr.addEventListener("drop", this.onDropTeam, true);

			const is_drop_to_top = this.initial_mouse_y_cord >= this.current_mouse_y_cord;
			const insertMethod = is_drop_to_top ? this.insertBefore : this.insertAfter;

			insertMethod.call(this, tr, target);
		}

		removeOldPlaceholder(event) {
			const target = event.currentTarget;
			const parent = target.parentNode;
			const placeholder = parent.querySelector(`.${this.placeholder_class_name}`);

			if (placeholder) {
				placeholder.removeEventListener("drop", this.onDrop);
				placeholder.removeEventListener("drop", this.onDropTeam);
				placeholder.removeEventListener("dragover", this.onDragOver);
				parent.removeChild(placeholder);
				this.last_dnd_enter = undefined;
			}
		}

		onDragLeave(event) {
			event.preventDefault();
		}

		onDragEnd(event) {
			try {
				event.preventDefault();
				this.removeOldPlaceholder(event);
				this.last_dnd_enter = undefined;
				this.transfer_player_id = undefined;
				this.transfer_team_id = undefined;
				this.initial_mouse_y_cord = 0;
				this.current_mouse_y_cord = 0;
			}
			catch (error) {
				if (Sentry) {
					// Gather info to check what's going wrong here
					if (Sentry) {
						Sentry.withScope(scope => {
							event.persist();
							scope.setExtra("event", event);
							scope.setExtra("event.pageY", _.get(event, "pageY"));
							scope.setExtra("current_mouse_y_cord", this.current_mouse_y_cord);
							scope.setExtra("initial_mouse_y_cord", this.initial_mouse_y_cord);
							scope.setExtra("transfer_player_id", this.transfer_player_id);
							scope.setExtra("transfer_team_id", this.transfer_team_id);
							scope.setExtra("last_dnd_enter", this.last_dnd_enter);
							Sentry.captureException(error);
						});
					}
				}
			}
		}

		onDrop(event: Object) {
			event.preventDefault();

			const target = event.currentTarget;

			if (!JSON.parse(target.dataset.dragAndDropEnabled)) {
				return;
			}

			const dragged_player_id = this.transfer_player_id;
			const dropped_player_id = parseInt(target.dataset.playerId, 10);
			const is_same_player = _.eq(dragged_player_id, dropped_player_id);

			if (is_same_player) {
				return;
			}

			this.swapPlayers(dragged_player_id, dropped_player_id);
		}

		onDropTeam(event: Object) {
			event.preventDefault();

			const target = event.currentTarget;

			if (!JSON.parse(target.dataset.dragAndDropEnabled)) {
				return;
			}
			const dragged_team_id = this.transfer_team_id;
			const dropped_team_id = parseInt(target.dataset.teamId, 10);
			const is_same_team = _.eq(dragged_team_id, dropped_team_id);

			if (is_same_team) {
				return;
			}

			this.swapTeams(dragged_team_id, dropped_team_id);
		}

		swapTeams(dragged_team_id, dropped_team_id) {
			const { league: { id }, leagueDraftOrder, changeDraftOrder } = this.props;
			changeDraftOrder({
				id,
				team: swapArrayElements(
					leagueDraftOrder,
					_.indexOf(leagueDraftOrder, dragged_team_id),
					_.indexOf(leagueDraftOrder, dropped_team_id),
				),
			});
		}

		swapPlayers(dragged_player_id, dropped_player_id) {
			const { league: { team_id }, pre_draft_list, postPreDraftList } = this.props;
			postPreDraftList({
				team_id,
				list: swapArrayElements(
					pre_draft_list,
					_.indexOf(pre_draft_list, dragged_player_id),
					_.indexOf(pre_draft_list, dropped_player_id),
				),
			});
		}

		onDragStart(event) {
			try {
				const target = event.currentTarget;
				const player_id = target.dataset.playerId;
				const team_id = target.dataset.teamId;

				this.initial_mouse_y_cord = event.pageY;
				this.transfer_player_id = parseInt(player_id, 10);
				this.transfer_team_id = parseInt(team_id, 10);
				if(player_id) {
					event.dataTransfer.setData("text", player_id);
				}
				if(team_id) {
					event.dataTransfer.setData("text", team_id);
				}
			}
			catch (error) {
				if (Sentry) {
					// Gather info to check what's going wrong here
					if (Sentry) {
						Sentry.withScope(scope => {
							event.persist();
							scope.setExtra("event", event);
							scope.setExtra("event.pageY", _.get(event, "pageY"));
							scope.setExtra(
								"event.target.dataset",
								_.get(event, ["currentTarget", "dataset"])
							);
							scope.setExtra("transfer_player_id", this.transfer_player_id);
							scope.setExtra("transfer_team_id", this.transfer_team_id);
							scope.setExtra("initial_mouse_y_cord", this.initial_mouse_y_cord);
							Sentry.captureException(error);
						});
					}
				}
			}
		}

		onEnableDrag(event) {
			const target = event.currentTarget;
			const parent = target.closest("tr");

			if (parent) {
				const { dragAndDropEnabled } = parent.dataset;
				target.closest("tr").draggable = JSON.parse(dragAndDropEnabled);
			}
		}

		onDisableDrag(event) {
			const target = event.currentTarget;
			const parent = target.closest("tr");

			if (parent) {
				parent.draggable = false;
			}
		}

		render() {
			return (
				<React.Fragment>
					<JsonFetcher fetch={["venues", "players", "rounds", "squads", "coach_players"]} />
					<WrappedComponent
						{...this.props}
						sort_by={this.state.sort_by}
						filters={this.state.filters}
						players_show_limit={this.state.players_show_limit}
						players_show_limit_step={this.state.players_show_limit_step}
						is_coach_active={this.state.is_coach_active}
						coachSelectValue={this.state.coachSelectValue}
						is_compressed={this.state.is_compressed}
						preloader={this.state.preloader}
						is_dnd_allowed={!this.has_filters && this.state.sort_by === "draft_order"}
						load_more_is_hidden={this.load_more_is_hidden}
						toggleKeptPlayers={this.toggleKeptPlayers}
						toggleDraftedPlayers={this.toggleDraftedPlayers}
						show_kept_players={this.state.show_kept}
						has_filters={this.has_filters}
						players_filter={this.players_filter}
						table_keys={this.table_keys}
						order_by_desc={this.state.order_by_desc}
						onSortColumnChange={this.onSortColumnChange}
						onToggleOrder={this.onToggleOrder}
						onResetFilterClick={this.onResetFilterClick}
						isActiveTab={this.isActiveTab}
						onFiltersChange={this.onFiltersChange}
						onFilterByPosition={this.onFilterByPosition}
						onLoadMoreClick={this.onLoadMoreClick}
						onSearchSubmit={this.onSearchSubmit}
						onChangePlayerOrder={this.onChangePlayerOrder}
						updatePlayerPreDraftListOrder={this.updatePlayerPreDraftListOrder}
						onSelectChange={this.onSelectChange}
						orderByComparator={this.orderByComparator}
						onCompressedViewClick={this.onCompressedViewClick}
						onPlayerShowLimitClick={this.onPlayerShowLimitClick}
						onDrop={this.onDrop}
						onDropTeam={this.onDropTeam}
						onDragOver={this.onDragOver}
						onDragEnter={this.onDragEnter}
						onDragStart={this.onDragStart}
						onDragLeave={this.onDragLeave}
						onDragEnd={this.onDragEnd}
						onEnableDrag={this.onEnableDrag}
						onDisableDrag={this.onDisableDrag}
						missed_draft_start={this.missed_draft_start}
						onResetAllFilters={this.onResetAllFilters}
						is_finals={this.is_finals}
						kept_players={this.kept_players}
						kept_players_ids={this.kept_players_ids}
					/>
				</React.Fragment>
			);
		}
	}

	const mapStateToProps = (state: TRootStore, props) => {
		const actual_round_id = selectors.rounds.getActualRound(state).id;
		
		const pre_draft_list = selectors.teamDraft.getPreDraftList(state);
		const players = selectors.players.getPreDraftPlayers(state, props);
		const teams_by_id = selectors.getTeamsById(state);
		const league =  selectors.leagueDraft.getLeague(state, props);
		const leagueStartRound = get(league, 'start_round', 1);
		const user =  selectors.getUser(state); 
		const isTeamsEmptybutCommissioner = league.commissioner === user.id && isEmpty(teams_by_id);
		const pendingTeamsFalse = !_.isEmpty(teams_by_id) || isTeamsEmptybutCommissioner;
		const selected_round_id = actual_round_id > leagueStartRound 
			? actual_round_id : leagueStartRound;
		const coachStatsList = selected_round_id > 1 ? 
			state.coachStatsList:state.coachStatsList.filter(
				item => item.key !== "history"
			);
		return {
			user,
			leagueDraftOrder: state.leagues.show_order.ordered_ids,
			rounds_ordered: selectors.rounds.getRounds(state),
			players,
			players_by_id: _.keyBy(players, "id"),
			squads: selectors.squads.getSquads(state),
			teams_by_id,
			is_pending_players: state.players.is_pending,
			positions: selectors.positions.getPositionsArray(state),
			playerStatuses: state.playerStatuses,
			league,
			pre_draft_list,
			pre_draft_list_size: _.size(pre_draft_list),
			stats_options_list: [
				{ key: "basic", value: "General Stats" },
				...coachStatsList.filter(({ key }) => key !== "form"),
			],
			all_stats: state.players.all_stats,
			actual_round: selectors.rounds.getActualRound(state),
			pub_nub_channel: getPubNubChannelName(props),
			has_assistant_coach: Boolean(selectors.getUser(state).assistant_coach),
			data_loading_complete: _.every([true,
				!state.players.is_pending,
				!state.rounds.is_pending,
				!state.squads.is_pending,
				!state.venues.is_pending,
				!state.leagues.show_order.is_pending,
				!state.leagues.show.is_pending,
				pendingTeamsFalse,
				!_.isEmpty(getPubNubChannelName(props)),
				(Boolean(selectors.getUser(state).assistant_coach))?
					!_.isEmpty(state.players.coach_by_id): true
			])
		};
	};

	const mapDispatchToProps = {
		showDraftLeague: actions.leagueDraft.showDraftLeague,
		fetchFavourites: actions.fetchFavourites,
		fetchLadder: actions.leagueDraft.ladderDraftLeague,
		fetchCoachPlayers: actions.fetchCoachPlayers,
		fetchAllCustomStats: actions.fetchAllCustomStats,
		fetchAllCoachCustomStats: actions.fetchAllCoachCustomStats,
		fetchPreDraftList: actions.teamsDraft.fetchPreDraftList,
		addPlayerToPreDraftList: actions.teamsDraft.addPlayerToPreDraftList,
		selectKeeper: actions.teamsDraft.postSelectKeeper,
		removeKeeper: actions.teamsDraft.removeKeeper,
		removePlayerFromPreDraftList: actions.teamsDraft.removePlayerFromPreDraftList,
		updatePlayerPreDraftListOrder: actions.teamsDraft.updatePlayerPreDraftListOrder,
		postPreDraftList: actions.teamsDraft.postPreDraftList,
		changeDraftOrder: actions.leagueDraft.changeDraftOrder,
		fetchAllStats: actions.fetchAllStats,
	};

	return connect(
		mapStateToProps,
		mapDispatchToProps,
	)(DraftCore);
};

export default withDraftCore;