// @flow
import * as React from "react";
import styled from "styled-components";
import colors from "../../../../assets/css/colors";
import AddPlayer from "../../../../components/Icons/AddPlayer";

const ButtonContainer = styled.button`
	background: transparent;
	text-align: center;
	border: none;
	cursor: pointer;
	margin: 2px 5px;
	outline: none;
`;

export const DraftButton = (props: Object) => (
	<ButtonContainer {...props}>
		<AddPlayer
			size={2}
			color={colors.primary.accent}
		/>
	</ButtonContainer>
);

export default DraftButton;