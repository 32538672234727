// @flow
import styled from "styled-components";
import { TwoColumnLayoutWithSidebarWidgets } from "../../components";
import { below } from "../../assets/css/media";

export const DraftLayout = styled(TwoColumnLayoutWithSidebarWidgets)`
	padding: 0 calc(50% - 640px);

	${below.desktop`
		padding: 5px;
		& > :first-child {
			width: 100%;
		}
	`};
`;

DraftLayout.defaultProps = {
	sidebar_open: true
};

export default DraftLayout;