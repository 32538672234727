// @flow
import * as React from "react";
import * as _ from "lodash";
import styled from "styled-components";
import numeral from "numeral";

import colors from "../../assets/css/colors";
import { countPlayersWithMatchStatus } from "../../helpers/team";
import type { TRound, TPlayersById } from "../../modules/types";
import { below } from "../../assets/css/media";
import { isAllTrue } from "../../helpers";

const PlayersStatus = styled.div`
	font-size: 10px;
	color: #ffffff;
	border-radius: 3px;
	padding: 3px 5px;
	margin: 0 2px;
	font-family: TitilliumUpright;
	background: ${({ color }) => color};
	font-weight: lighter;

	.isMobile {
		display: none;
		${below.desktop`
			display: inline-block;
		`};
	}

	.isDesktop {
		${below.desktop`
			display: none;
		`};
	}
`;

const CountPlayers = styled.span`
	font-weight: bold;
`;

const PlayersInfoWrapper = styled.div`
	display: flex;
	flex: 1;
	flex-direction: ${({ right_align }) => right_align ? "row-reverse" : "row"};
	margin: 0 -2px;

	${below.desktop`
		justify-content: flex-start;
	`};
	${below.phone`
		display: none;
	`}

	${PlayersStatus} {
		${below.desktop`
			font-size: 12px;
			margin-right: ${({ right_align }) => right_align ? "0" : "3px"};
			margin-left: ${({ right_align }) => right_align ? "3px" : "0"};
		`};

		${below.tablet`
			font-size: 10px;
		`};
	}
`;

type Props = {
	opponent: Object,
	players_by_id: TPlayersById,
	round: TRound,
	right_align?: boolean,
  is_classic: boolean,
};

export const PlayersInfo = ({ opponent, right_align, players_by_id, round, is_classic }: Props) => {
	const to_omit = is_classic ? 
		["emergency", "captain", "vice_captain", "utility"] 
		: 
		["emergency", "captain", "vice_captain", "utility", "bench"];
	
	let player_id_to_remove = [];
	let player_ids = _(opponent.lineup)
		.omit(to_omit)
		.map(row => row)
		.reduce((row, all) => {
			return row.concat(all);
		}, []);
	if(!is_classic){
		const scoring_players = (opponent 
			&& opponent.scoring_players 
			&& opponent.scoring_players[round.id]) ? 
    		opponent.scoring_players[round.id].players : [];
		scoring_players.forEach(player_id => {
			if(!player_ids.includes(player_id)){
				player_ids.push(player_id);
			}
		});
		if(isAllTrue([round, round.matches])){
			round.matches.forEach(match => {
				player_ids.forEach(player_id => {
					const playerSquad = _.get(players_by_id, `${player_id}.squad_id`, 0);
					if((match.home_squad_id === playerSquad 
            || match.away_squad_id === playerSquad) 
            && match.status !== "scheduled"){
						if(!scoring_players.includes(player_id)){
							player_id_to_remove.push(player_id);
						}
					}
				});
			});
		}
		
    
		player_ids = player_ids.filter(function(id) {
			return player_id_to_remove.indexOf(id) === -1;
		});
	}
	const yet_to_play = countPlayersWithMatchStatus(player_ids, players_by_id, round, "scheduled");
	const playing_now = countPlayersWithMatchStatus(player_ids, players_by_id, round, "playing");
	const complete = countPlayersWithMatchStatus(player_ids, players_by_id, round, "complete");

	return <PlayersInfoWrapper right_align={right_align}>
		<PlayersStatus color={colors.buttons.cancel}>
			Completed <CountPlayers>{numeral(complete).format("00")}</CountPlayers>
		</PlayersStatus>
		<PlayersStatus color={colors.primary.accentGreen}>
			Live <CountPlayers>{numeral(playing_now).format("00")}</CountPlayers>
		</PlayersStatus>
		<PlayersStatus color={colors.secondary.accentGrey}>
			<span className="isDesktop">Yet to play</span>
			<span className="isMobile">YTP</span>&nbsp;
			<CountPlayers>{numeral(yet_to_play).format("00")}</CountPlayers>
		</PlayersStatus>
	</PlayersInfoWrapper>;
};

export default PlayersInfo;