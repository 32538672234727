// @flow
import * as React from "react";
import styled from "styled-components";
import colors from "../../../assets/css/colors";

import Tick from "../../Icons/Tick";
import { default_transition } from "../../../assets/css/vars";

const FormCircleCheckboxLabel = styled.label`
	font-size: 13px;
	line-height: 1.5;
	color: ${colors.primary.primary};
	margin-left: 5px;
	cursor: pointer;
	font-family: SourceSansPro;
	font-weight: 600;
	font-family: TitilliumUpright;
	font-weight: 400;
	letter-spacing: -0.03em;
	vertical-align: middle;
	margin-top: 0.1em;
`;

const FormCircleCheckboxStyled = styled.div`
	display: flex;
	align-items: center;
	margin-right: 1em;

	:hover .custom-checkbox {
		border-width: 2px;
		background-color: ${colors.form.base};
	}

	.custom-checkbox {
		position: relative;
		cursor: pointer;
		height: 16px;
		width: 16px;
		border-radius: 50%;
		box-sizing: border-box;
		border: 1px solid ${colors.primary.primary};
		background: #fff;
		display: flex;
		justify-content: center;
		align-items: center;
		${default_transition}

		

		> svg {
			color: #fff;
			width: 14px;
			height: 14px;
		}
	}

	input {
		visibility: hidden;
		width: 0;
		margin: 0;
		-webkit-user-select:none;
		-khtml-user-select:none;
		-moz-user-select:none;
		-o-user-select:none;
		user-select:none;
		
	}
	label{
		-webkit-user-select:none;
		-khtml-user-select:none;
		-moz-user-select:none;
		-o-user-select:none;
		user-select:none;
	}
	.active-check{
		background-color: ${colors.primary.primary} !important;
	}
`;

type Props = {
	id: string,
	name: string,
	checked?: boolean,
	onChange?: Function,
	label?: string,
	className?: string,
	disabled?: boolean,
	value?: boolean,
	isActive?: boolean
};

const getClassName = isActive => {
	return isActive ? "custom-checkbox active-check" : "custom-checkbox";
};
 
export const FormCircleCheckbox = ({
	name,
	id,
	checked,
	value,
	onChange,
	isActive,
	label,
	className,
	disabled = false,
}: Props) => (
	<FormCircleCheckboxStyled id={`id-${id}`} className={className}>
		<input type="checkbox"
			checked={value}
			id={id}
			name={name}
			onChange={onChange}
			disabled={disabled}
			readOnly
		/>
		<label className={getClassName(isActive)} htmlFor={id}>
			<Tick />
		</label>

		{label ? (<FormCircleCheckboxLabel htmlFor={id}>{label}</FormCircleCheckboxLabel>) : null}
	</FormCircleCheckboxStyled>
);

export default FormCircleCheckbox;