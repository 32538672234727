// @flow
import * as React from 'react';
import type { TPlayersById } from "../../modules/types";
import {
	TradeInCell,
	TransactionPlayers,
	TransactionPlayersWrapper,
	TransactionButtonsWrapper,
} from "../";
import { ButtonAlert } from "../../components";
import { isMobile } from "../../utils";

type Props = {
	players_by_id: TPlayersById,
	trade: Object,
	rejectTrade: Function
}

export const TradeFromUser = ({ trade, players_by_id, rejectTrade }: Props) => {
	const { 
		id: trade_id, 
		new_player_id, 
		old_player_id, 
		new_team_name, 
		old_team_name,
		status
	} = trade;
	const highestNumberInTrade = Math.max(old_player_id.length, new_player_id.length);
	const trade_controls = status === "accepted" ? null : <React.Fragment>
		<ButtonAlert inverted onClick={() => rejectTrade({ trade_id })}>
			Cancel offer
		</ButtonAlert>
	</React.Fragment>;
	return (
		<React.Fragment>
			<TransactionPlayersWrapper>
				<TransactionPlayers>
					<p>
						Requested from {isMobile() ? <br/> : null} {new_team_name}:
					</p>
					
					{new_player_id.map(
						id => (<TradeInCell 
							key={id} 
							player={players_by_id[id]} 
							is_classic={false}
						/>)
					)}
				 	
				</TransactionPlayers>
				<TransactionButtonsWrapper numTrades={highestNumberInTrade}>
					{trade_controls}
				</TransactionButtonsWrapper>
				<TransactionPlayers>
					<p>
						Offered from {isMobile() ? <br/> : null} {old_team_name}:
					</p>
					{old_player_id.map(id => (
						<TradeInCell
							key={id} 
							player={players_by_id[id]} 
							is_classic={false}
						/>
					))}
				</TransactionPlayers>
			</TransactionPlayersWrapper>
			<TransactionButtonsWrapper show_mobile>
				{trade_controls}
			</TransactionButtonsWrapper>
		</React.Fragment>
	);
};

export default TradeFromUser;