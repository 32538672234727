// @flow
import styled from "styled-components";

export const TeamBuilderLayout = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	max-width: 1280px;
	margin: 20px auto 50px;
	padding: 0 20px;
	box-sizing: border-box;

	@media only screen and (max-width: 1280px) and (min-width: 1080px) {
		padding: 0;
	}
`;

export default TeamBuilderLayout;