// @flow
import * as React from "react";

import type { TRoundWithMatches } from "../../modules/types";

import RoundPanel from "./RoundPanel";
import MatchPanel from "./MatchPanel";
import ByePanel from "./ByePanel";

type Props = {
	round: TRoundWithMatches;
	actual_round: TRoundWithMatches;
}

const RoundList = ({ round, actual_round }: Props) => (
	<React.Fragment>
		<RoundPanel round_id={round && round.id ? round.id : 0} />
		{round.matches.map(match => (
			<MatchPanel key={match.id} match={match} actual_round={actual_round}/>
		))}
		{round.is_bye === 1 && round.bye_squads && (
			<ByePanel bye_squads={round.bye_squads} />
		)}
	</React.Fragment>
);

export default RoundList;