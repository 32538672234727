// @flow
import styled from "styled-components";

import { below } from "../../assets/css/media";


export const universalPadding = "3vw";
export const widgetsOpenWidth = "22vw";

export const TwoColumnLayoutWithSidebarWidgets = styled.div`
	width: 100%;
	padding: 0 0 0 ${universalPadding};
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	box-sizing: border-box;

	> :first-child {
		width: calc(100% - 100px - ${universalPadding});
		transition: width 0.3s linear;
		${props => props.sidebar_open && `
			width: calc(78% - ${universalPadding});
		`};
		${props => props.sidebar_open && below.desktop`
			width: calc(100% - 100px - ${universalPadding});
		`}
	}

	> :last-child {
		width: 100px;
		transition: width 0.3s linear;
		${props => props.sidebar_open && `
			width: 22%;
		`};
		${props => props.sidebar_open && below.desktop`
			width: 100px;
			display: flex;
			align-items: flex-end;
		`}
	}
`;

export default TwoColumnLayoutWithSidebarWidgets;