// @flow
import * as _ from "lodash";
import * as numeral from "numeral";
import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import colors from "../../assets/css/colors";

import {
	Ad,
	AdsContainer,
	ButtonLoadMore,
	FantasyCoachModal,
	Footer,
	LeagueGameBar,
	MatchCentreBar,
	PageTitle,
	PlayerInfo,
	Select,
	StyledLabel,
	TdNext,
	TeamUserAvatar,
	ThToolTip,
	Tooltip,
	withClassicPlayerModal,
	StatsCentreFilters
} from "../../components";

import type { TPLeague } from "../../modules/types";

import ArrowSolid from "../../components/Icons/ArrowSolid";
import Lock from "../../components/Icons/Lock";
import PlayersTableHeader from "../../components/PlayersTableHeader/index";
import { TBody, Td, Tr } from "../../components/TableX";
import JsonFetcher from "../../components/utils/JsonFetcher";
import withCoachesBox from "../../components/utils/withCoachesBox";
import * as format from "../../helpers/numeralFormats";
import { coach_player_stats } from "../../helpers/stats";
import { renderTableHeaderCell } from "../../helpers/tables";
import { classicIsLocked } from "../../helpers/teamsClassic";
import * as actions from "../../modules/actions";
import * as selectors from "../../modules/selectors";

import type {
	TClassicTeam,
	TCoachStatsListReducer,
	TPlayer,
	TPlayerStatusesReducer,
	TPosition,
	TRootStore,
	TRound,
	TSquad,
	TUser,
} from "../../modules/types";
import { currencyFormat } from "../../utils";
import { isAuthenticated } from "../../utils/auth";
import {
	TableHead,
	TableWrapper,
	StyledTr,
	CostStatWrapper,
	ThStat,
	Th,
	TdStat,
	TdAvatar,
	TdPlayer,
	FcLink,
	AllStatsWrapper,
	StatsTable,
	OneColumnLayout
} from "./statsCentre/StatCentreStyled";
import { PlayerActionButtons } from "./statsCentre/PlayerActionButtons";
import {
	getCompressedViewStatus,
	TableColumn,
	basic_stats,
	getLastRoundId,
	getResult,
	isStringOrZero,
	tryConvertNumber,
	getIsWhite,
} from "./statsCentre/helpers";

const SEASON_YEAR = process.env.REACT_APP_SEASON_YEAR || "";



type Props = {
	squads: Array<TSquad>,
	positions: Array<TPosition>,
	players: Array<TPlayer>,
	playerStatuses: TPlayerStatusesReducer,
	coach_stats_list: TCoachStatsListReducer,
	fetchFavourites: typeof actions.fetchFavourites,
	fetchMyClassicTeam: typeof actions.fetchMyClassicTeam,
	fetchPastYearPlayers: typeof actions.fetchPastYearPlayers,
	my_players: Array<number>,
	user: TUser,
	team: TClassicTeam,
	remaining_salary: number,
	actual_round: TRound,
	available_positions: Array<number>,
	is_team_started: number,
	addPlayer: Function,
	past_years: Array<{[string]: TPlayer}>,
	has_assistant_coach: boolean,
	data_loading_complete: boolean,
	league: TPLeague,
	is_classic: boolean,
	is_authorized: boolean
};

type State = {
	players_show_limit: number,
	players_show_limit_step: number,
	sort_by: string,
	order_by_desc: boolean,
	buttons_open: Array<number>,
	filters: {
		search: string,
		by_squad: string,
		by_position: string,
		by_price: string,
		by_status: string,
		by_dpp: boolean,
		by_favourites: boolean,
		by_hide_your_players: boolean,
		by_year: string
	},
	compressed_view: boolean,
	active_stats_type: string,
	show_fantasy_coach_modal: boolean,
	mobile_stat: string,
	
};
class StatsCentreComponent extends React.Component<Props,State> {

	constructor(props) {
		super(props);
		_.bindAll(this, [
			"handleFilterChange",
			"handleLoadMoreClick",
			"isActiveTab",
			"onSortColumnChange",
			"orderByComparator",
			"getPlayerStat",
			"toggleFantasyCoachModal",
			"setMobileStat",
			"renderStat",
			"renderMobileStat",
			"onPresetChange",
			"tableColumnToCellInfo",
			"resetFilters",
			"handleButtons",
			"handleRemoveButton",
			"handleSwitchFilterChange",
			"accumulateFormInputsValueByName"
		]);
	}

	state = {
		players_show_limit: 50,
		players_show_limit_step: 20,
		sort_by: "stats.total_points",
		order_by_desc: true,
		buttons_open: [],
		filters: {
			search: "",
			by_squad: "",
			by_position: "",
			by_price: "",
			by_status: "",
			by_year: `${SEASON_YEAR}`,
			by_dpp: false,
			by_favourites: false,
			by_hide_your_players: false
		},
		compressed_view: getCompressedViewStatus(),
		show_fantasy_coach_modal: false,
		active_stats_type: "general-stats",
		mobile_stat: "stats.total_points",
	};

	componentDidMount() {
		const {
			fetchFavourites,
			fetchMyClassicTeam
		} = this.props;
		fetchFavourites();
		fetchMyClassicTeam();
	}

	shouldComponentUpdate(next_props) {
		return next_props.data_loading_complete;
	}

	componentDidUpdate() {
		// console.log(this.adjustProp(this.state.sort_by));
	}

	toggleFantasyCoachModal() {
		const { show_fantasy_coach_modal } = this.state;
		this.setState({ show_fantasy_coach_modal: !show_fantasy_coach_modal });
	}

	handleButtons(playerID) {
		const { buttons_open } = this.state;

		this.setState({ buttons_open: [...buttons_open, playerID] });
	}

	handleRemoveButton(playerID) {
		const { buttons_open } = this.state;
		const newButtonsArr = buttons_open.filter((id => playerID !== id));
		this.setState({ buttons_open: newButtonsArr });
	}
	handleSwitchFilterChange(name, value){
		this.setState({
			filters: {
				...this.state.filters,
				[name]: value
			}
		});
	}

	get table_keys(): TableColumn[] {
		const { actual_round, is_classic } = this.props;
		const { active_stats_type } = this.state;

		return active_stats_type === "general-stats" ?
			basic_stats(getLastRoundId(actual_round)) :
			coach_player_stats("stats", actual_round, is_classic)[active_stats_type];
	}

	get has_filters() {
		const filter_keys = Object.keys(this.state.filters);
		const active_filters = filter_keys.filter(key => this.state.filters[key]);
		return active_filters.length !== 0;
	}

	filterChecker(is_what, what) {
		return is_what ? what : _.identity;
	}

	get players_filter() {
		const {
			search,
			by_squad,
			by_position,
			by_price,
			by_status,
			by_dpp,
			by_favourites,
			by_hide_your_players
		} = this.state.filters;

		const byPlayerStatus = _.matches({ status: by_status });

		const bySquadId = _.matches({ squad_id: parseInt(by_squad, 10) });

		const byFavourites = _.property("is_favourite");

		const byHideYourPlayers = _.property("not_owned_by_user");

		const byPosition = _.flow([
			_.property("positions"),
			_.partial(_.includes, _, parseInt(by_position, 10))
		]);

		const price_string_split = by_price.split("-");
		const byPrice = ({ cost }) => (price_string_split.length > 1) ? _.inRange(
			parseInt(cost, 10),
			parseInt(price_string_split[0], 10),
			parseInt(price_string_split[1], 10)
		) : "";

		const byDPP = ({ positions }) => _.eq(_.size(positions), 2);

		const byName = (({ first_name, last_name }) => (
			`${first_name} ${last_name}`.toLowerCase().includes(search.toLowerCase())
		));

		return _.overEvery([
			this.filterChecker(by_status, byPlayerStatus),
			this.filterChecker(by_squad, bySquadId),
			this.filterChecker(by_favourites, byFavourites),
			this.filterChecker(by_hide_your_players, byHideYourPlayers),
			this.filterChecker(by_position, byPosition),
			this.filterChecker(by_dpp, byDPP),
			this.filterChecker(by_price, byPrice),
			this.filterChecker(search, byName),
		]);
	}

	get players_unsliced() {
		const { players } = this.props;
		if(this.has_filters) {
			return players.filter(this.players_filter)
				.sort(this.orderByComparator);
		}
		return players.sort(this.orderByComparator);
	}

	get players() {
		const { players_show_limit } = this.state;
		const players_show = this.players_unsliced;
		return players_show.slice(0, players_show_limit) || [];
	}

	get mobile_columns() {
		return [ this.state.mobile_stat ];
	}

	get tablet_columns() {
		const { actual_round } = this.props;
		const default_stats = [
			"stats.games_played",
			"stats.avg_points",
			`stats.scores.${getLastRoundId(actual_round)}`,
			"stats.last_3_avg"
		];

		return [
			...default_stats,
			_.includes(default_stats, this.state.mobile_stat)
				? "stats.total_points"
				: this.state.mobile_stat
		];
	}

	get current_mobile_stat() {
		const { mobile_stat } = this.state;
		return _.find(this.all_stats, { prop: mobile_stat });
	}

	get all_stats() {
		const { actual_round } = this.props;

		const stats_with_dupes = _.reduce(
			coach_player_stats,
			(all, stats) => [...all, ...stats],
			basic_stats(getLastRoundId(actual_round))
		);

		return _(stats_with_dupes)
			.uniqBy("prop")
			.uniqBy("key")
			.orderBy("is_fc", "desc")
			.value();
	}

	setMobileStat({ currentTarget }: Object) {
		const stat = currentTarget.value;

		this.setState({
			mobile_stat: stat,
		}, () => this.onSortColumnChange(stat));
	}

	getPlayerStat(player, prop, format_template = "0") {
		const adjusted_prop = this.adjustProp(prop);
		let value = _.get(player, adjusted_prop, "--");

		if(prop === "cost") {
			return currencyFormat({input: value});
		}
		if (prop === "stats.cost_divided_by_points") {
			return numeral(value).format(format.DEC_PRICE);
		}
		if (_.includes(prop, "owned_by")) {
			return numeral(value).format("0");
		}
		if (_.includes(["stats.cost_season_diff", "stats.cost_round_diff"], prop)) {
			if (!value || !_.isNumber(value)) {
				return "--";
			}
			return <CostStatWrapper
				is_positive={value > 0}
				is_negative={value < 0}
			>
				{ numeral(value / 1000).format("$0.[0]") + "k" }
			</CostStatWrapper>;
		}
		return numeral(value).format(format_template);
	}

	adjustProp(prop = "") {
		const { filters: { by_year } } = this.state;
		return (by_year !== `${SEASON_YEAR}`)? `past_years.${by_year}.${prop}`: prop;
	}

	orderByComparator(player_one, player_two) {
		const
			{ sort_by, order_by_desc } = this.state,
			order = order_by_desc ? 1 : -1,
			minimal = Number.MAX_SAFE_INTEGER * order,
			getResultOrMin = _.partial(getResult, isStringOrZero, minimal * -1, _, sort_by),
			adjusted_sort_by = this.adjustProp(sort_by),
			getValue = player => tryConvertNumber(_.get(player, adjusted_sort_by)),
			a_result = getResultOrMin(getValue(player_one)),
			b_result = getResultOrMin(getValue(player_two));

		if (a_result < b_result) {
			return order;
		}

		if (a_result > b_result) {
			return -1 * order;
		}

		return 0;
	}

	isActiveTab(sort_prop){
		const { sort_by } = this.state;
		return _.eq(sort_by, sort_prop) ? "active" : "";
	}

	onPresetChange({ currentTarget }: Object) {
		this.setState({
			active_stats_type: currentTarget.value,
			sort_by: "stats.avg_points"
		});
	}
	resetFilters() {
		this.setState({
			filters: {
				search: "",
				by_squad: "",
				by_position: "",
				by_price: "",
				by_status: "",
				by_year: `${SEASON_YEAR}`,
				by_dpp: false,
				by_favourites: false,
				by_hide_your_players: false
			},
			active_stats_type: "general-stats"
		});
	}
	onSortColumnChange(column_value) {
		const { has_assistant_coach } = this.props;
		const { sort_by, order_by_desc } = this.state;

		const keys = this.table_keys;
		const stat = _.find(keys, { prop: column_value }, {});
		const can_sort = has_assistant_coach || !_.get(stat, "is_fc", false);

		if (!can_sort) {
			// The user cannot sort by this column
			return;
		}

		const default_order = !_.includes(
			["stats.adp", "stats.cost_divided_by_points", "stats.break_even"],
			column_value
		);

		this.setState({
			sort_by: column_value,
			order_by_desc: _.eq(sort_by, column_value) ? !order_by_desc : default_order,
		});
	}

	handleFilterChange(e) {
		const { fetchPastYearPlayers, past_years } = this.props;
		const { filters } = this.state;
		const {
			by_year,
			...rest_filters
		} = this.formElementToKeyValueObject(e.currentTarget);
		const newFilters = {
			...filters,
			by_year,
			...rest_filters
		};
		const newCompareFilters = {
			by_year,
			...rest_filters
		};
		const currentApplicableState = {
			search: this.state.filters.search,
			by_squad: this.state.filters.by_squad,
			by_position: this.state.filters.by_position,
			by_price: this.state.filters.by_price,
			by_status: this.state.filters.by_status,
			by_year:this.state.filters.by_year,
			stats_type: this.state.active_stats_type
		};

		const shouldNotUpdate = _.isEqual(
			currentApplicableState, 
			newCompareFilters
		);

		if(shouldNotUpdate){
			return;
		}
		

		const alreadyFetched = past_years.hasOwnProperty(by_year);

		if(by_year !== `${SEASON_YEAR}` && !alreadyFetched) {
			if(!!by_year){
				fetchPastYearPlayers(by_year);
			}
		}

		this.setState({
			filters: {
				...newFilters
			}
		});
	}

	handleLoadMoreClick() {
		const { players_show_limit, players_show_limit_step } = this.state;

		this.setState({
			players_show_limit: players_show_limit + players_show_limit_step,
		});
	}

	formElementToKeyValueObject(form) {
		return Array.from(form.elements).reduce(this.accumulateFormInputsValueByName, {});
	}

	accumulateFormInputsValueByName(accumulator: Object, formElement: Object) {
		const { type, name, checked, value } = formElement;
		const noChangeArr = ["by_dpp", "by_favourites", "by_hide_your_players"];
		
		if(_.eq(type, "submit")) {
			return accumulator;
		}

		if (!name) {
			throw new Error("All form inputs should be have \"name\" attribute!");
		}

		if(noChangeArr.includes(name)){
			return accumulator;
		}

		accumulator[name] = _.eq(type, "checkbox") ?  checked : value;
		
		return accumulator;
	}

	tableColumnToCellInfo(column: TableColumn) {
		return {
			table: {
				order_by_desc: this.state.order_by_desc,
				sort_key: this.state.sort_by
			},
			cell: {
				...column,
				sort_key: column.prop,
				name: column.key,
				short_name: column.key,
				rel: column.prop,
				sortable: true,
				onClick: this.onSortColumnChange,
			}
		};
	}

	renderStatHeader({ prop, key, tooltip, is_fc }: TableColumn, className = "") {
		const { order_by_desc } = this.state;

		const is_active = this.isActiveTab(prop);
		const is_by_desc = prop === "stats.adp" ? !order_by_desc : order_by_desc;
		const is_top = is_active && !is_by_desc;
		const is_down = is_active && is_by_desc;

		return (
			<ThStat
				key={key}
				onClick={() => this.onSortColumnChange(prop)}
				is_fc={is_fc}
				rel={prop}
				className={`${className} ${this.isActiveTab(prop)}`}
			>
				<div className="highlighter" />
				<span className="column-name">
					{key}
				</span>
				{is_top ? <ArrowSolid direction="up" className="top" /> : null}
				{is_down ? <ArrowSolid direction="down" className='down' /> : null}
				{tooltip && <ThToolTip text={tooltip} />}
			</ThStat>
		);
	}

	renderStat({ is_fc, prop, format }: TableColumn, player: TPlayer, className?: string) {
		const { has_assistant_coach } = this.props;

		return <TdStat
			key={prop}
			rel={prop}
			is_fc={is_fc}
			className={className}
		>
			{is_fc && !has_assistant_coach ? (
				<FcLink onClick={this.toggleFantasyCoachModal}>
					<Lock color={colors.coach} />
				</FcLink>
			) : (
				<React.Fragment>
					{this.getPlayerStat(player, prop, format)}
				</React.Fragment>
			)}
		</TdStat>;
	}

	renderMobileStatHeader() {
		const cell_info = this.tableColumnToCellInfo(this.current_mobile_stat);

		return renderTableHeaderCell(
			cell_info,
			ThStat,
			"mobile-stat"
		);
	}
	renderMobileStat(player: TPlayer) {
		return this.renderStat(this.current_mobile_stat, player, "mobile-stat");
	}

	renderAllStatsSelect() {
		const { mobile_stat } = this.state;
		return <AllStatsWrapper>
			<StyledLabel htmlFor="mobile_stat">
				Statistic
				<Tooltip
					title="Statistic"
					description="Change the statistic displayed in the table" />
			</StyledLabel>
			<Select
				name="mobile_stat"
				is_disabled={false}
				onChange={this.setMobileStat}
				value={mobile_stat}
			>
				{_.map(this.all_stats, ({ key, name, tooltip, prop, is_fc = false }) => (
					<option key={key} value={prop}>
						{name || tooltip || key} {is_fc && "(FC)"}
					</option>
				))}
			</Select>
		</AllStatsWrapper>;
	}

	renderTableHead() {
		const {is_authorized} = this.props;
		return (
			<TableHead > 
				<Tr className="table-header-row">
					<Th rel="player" className="absolute-group-left">
						Player
					</Th>
					{is_authorized &&
						<Th textAlign="left" rel="owned" width="40px" className="th-owner">
							Owned
						</Th>
					}
					
					<Th rel="next" className="th-next">
						Next
					</Th>
					{_(this.table_keys)
						.map(this.tableColumnToCellInfo)
						.map(cell => renderTableHeaderCell(cell, ThStat))
						.value()
					}
					{ this.renderMobileStatHeader() }
					{is_authorized && 
						<Th rel="action" className="absolute-group-right">
							Action
						</Th>
					}
					
				</Tr>
			</TableHead>
		);
	}

	isPlayerLocked(player: TPlayer) {
		const {
			actual_round,
			remaining_salary,
			is_team_started,
			available_positions,
		} = this.props;

		const isLocked = classicIsLocked({
			player,
			remaining_salary,
			is_team_started,
			available_positions,
			actual_round
		});

		if (isLocked) {
			return actual_round.lockout === "full"
				? false
				: player.locked;
		}

		return false;
	}

	getTradeLink(player: TPlayer, side: string = "out") {
		return `/classic/advanced-trades/${player.id}/${side}`;
	}

	renderTableBody() {
		const { 
			user, 
			team, 
			is_team_started, 
			available_positions, 
			actual_round, 
			remaining_salary,
			is_authorized
		} = this.props;
		const { compressed_view, buttons_open } = this.state;

		return (
			<TBody>
				{this.players.map((player, index) => {
					const playerOpponent = _.get(player, "competition.opponent.id", 0);
					const isWhite = getIsWhite(index);
					return (
						<StyledTr
							key={player.id}
							className={player.is_owned_by_user && "is_owned_by_user"}
							isWhite={isWhite}
						>
							<TdPlayer rel="player" className="absolute-group">
								<PlayerInfo
									player={player}
									className="player-info"
									is_compressed={compressed_view}
									is_classic={false}
									state_center={true}
								/>
							</TdPlayer>
							{is_authorized && 
								<TdAvatar
									textAlign="left"
									rel="owned"
									is_compressed={compressed_view}
									className="td-draft-owner"
								>
									{player.is_owned_by_user && (
										<React.Fragment>
											<TeamUserAvatar
												user_id={user.id}
												firstname={user.firstname}
												lastname={user.lastname}
												avatar_version={user.avatar_version}
											/>
										</React.Fragment>
									)}
								</TdAvatar>
							}
							
							<TdNext
								squad_id={playerOpponent}
								is_compressed={compressed_view}
							/>
							{ this.table_keys.map(stat => this.renderStat(stat, player)) }
							{ this.renderMobileStat(player) }
							{is_authorized &&
								<Td rel="action" className="absolute-group-right">
									<PlayerActionButtons 
										player={player}
										team={team}
										is_team_started={is_team_started}
										buttons_open={buttons_open}
										handleButtons={this.handleButtons}
										isPlayerLocked={this.isPlayerLocked}
										getTradeLink={this.getTradeLink}
										handleRemoveButton={this.handleRemoveButton}
										actual_round={actual_round}
										remaining_salary={remaining_salary}
										available_positions={available_positions}
									/>
								</Td>
							}
							
						</StyledTr>
					);
				})}
			</TBody>
		);
	}

	

	handleFilterSubmit(e) {
		e.preventDefault();
	}

	render() {
		const {
			squads,
			positions,
			playerStatuses,
			coach_stats_list,
			league
		} = this.props;
		const {
			compressed_view,
			players_show_limit,
			show_fantasy_coach_modal,
			active_stats_type,
			filters
		} = this.state;

		const availableYears = _.range(SEASON_YEAR, 2014, -1);

		return (
			<React.Fragment>
				<JsonFetcher fetch={["rounds", "players", "squads", "coach_players", "venues"]} />
				<LeagueGameBar no_fetch={true} />
				<AdsContainer>
					<Ad id="stats" />
				</AdsContainer>
				{
					show_fantasy_coach_modal ?
						<FantasyCoachModal
							closeClick={this.toggleFantasyCoachModal}
						/>
						: null
				}
				<OneColumnLayout className="playwright-mask-hidden">
					<PageTitle>
						Stats Centre
					</PageTitle>
					<StatsCentreFilters
						handleSubmit={this.handleFilterSubmit}
						league={league}
						handleChange={this.handleFilterChange}
						handleSwitchFilterChange={this.handleSwitchFilterChange}
						squads={squads}
						filters={this.state.filters}
						positions={positions}
						is_authorized={this.props.is_authorized}
						selected_by_price={filters.by_price}
						statuses={playerStatuses}
						years={availableYears}
						resetFilters={this.resetFilters}
						onSelectChange={this.onPresetChange}
						active_stats_type={active_stats_type}
						selectOptions={[
							{ key: "general-stats",value: "General Stats" },
							...coach_stats_list
						]}
					/>
					<PlayersTableHeader
						title="All Players"
						is_compressed={compressed_view}
						onCompressedViewClick={bool => {
							this.setState({ compressed_view: bool });
							localStorage.setItem("compressed_view", `${bool}`);
						}}
					/>
					<TableWrapper>
						<StatsTable
							show_border_bottom={
								this.players_unsliced.length < this.state.players_show_limit
							}
							m
							is_compressed={compressed_view}
						>
							{this.renderTableHead()}
							{this.renderTableBody()}
						</StatsTable>

					</TableWrapper>
					
					{this.players_unsliced.length > players_show_limit && (
						<ButtonLoadMore onClick={this.handleLoadMoreClick}>
						Load more players
						</ButtonLoadMore>
					)}
				</OneColumnLayout>
				<MatchCentreBar />
				<Footer show_key />
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: TRootStore, props) => ({
	squads: selectors.squads.getSquads(state),
	is_classic: _.get(window.location, "pathname", "").includes("classic"),
	positions: selectors.positions.getPositionsArray(state),
	playerStatuses: state.playerStatuses,
	coach_stats_list: state.coachStatsList,
	players: selectors.playersForClassicPlayers(state, props),
	user: state.user.data,
	team: selectors.getMyClassicTeam(state),
	remaining_salary: selectors.getMyClassicTeamRemainingSalary(state),
	is_team_started: selectors.isMyTeamStarted(state),
	actual_round: selectors.rounds.getActualRound(state),
	available_positions: selectors.getAvailableTeamPositions(state),
	past_years: state.players.past_years,
	has_assistant_coach: Boolean(selectors.getUser(state).assistant_coach),
	is_authorized: isAuthenticated(),
	data_loading_complete: _.every([true,
		!state.players.is_pending,
		!state.rounds.is_pending,
		!state.squads.is_pending,
		!state.venues.is_pending,
		!state.teamsClassic.show_my.is_pending
	])
});
const mapDispatchToProps = {
	fetchFavourites: actions.fetchFavourites,
	fetchMyClassicTeam: actions.fetchMyClassicTeam,
	addPlayer: actions.addPlayerToMyClassicTeam,
	fetchPastYearPlayers: actions.fetchPastYearPlayers
};


const StatsCentre = compose(
	withClassicPlayerModal,
	withCoachesBox,
	connect(
		mapStateToProps,
		mapDispatchToProps
	)
)(StatsCentreComponent);

export default StatsCentre;