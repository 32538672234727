// @flow
import styled from "styled-components";
import * as React from "react";
import { connect } from "react-redux";

import { below, above } from "../../assets/css/media";

import coachLeaderboardSrc from "../../assets/images/fantasycoach_leaderboard_600x70@2x.jpg";
import coachMobileLeaderboardSrc from "../../assets/images/fantasycoach_mleaderboard_320x50@2x.jpg";
import coachMRECSrc from "../../assets/images/fantasycoach_mrec_300x250@2x.jpg";

import * as selectors from "../../modules/selectors";

import withFantasyCoachModal from "../utils/withFantasyCoachModal";
import { Exist } from "../Exist";

const AdWrapper = styled.div`
	text-align: center;
	${({ small_screen_only }) => small_screen_only && above.desktop`
		display: none;
	`}

	${({ large_screen_only }) => large_screen_only && below.desktop`
		display: none;
	`}
`;

const DesktopAd = styled.div`
	${({ is_half_width }) => !is_half_width && below.tablet`
		display: none;
	`};

	${({ is_half_width }) => is_half_width && below.field_view_mid`
		display: none;
	`};
`;

const MobileAd = styled.div`
	${({ is_half_width }) => !is_half_width && above.tablet`
		display: none;
	`};

	${({ is_half_width }) => is_half_width && above.field_view_mid`
		display: none;
	`};
`;

const CoachModalButton = styled.button`
	background: transparent;
	border: 0 none;
	margin: 0;
	padding: 0;
	cursor: pointer;
`;

const Img = styled.img`
	display: block;
	max-width: 100%;
`;

const LargeLeaderboardImg = styled(Img)`
	max-width: 600px;
`;

const SmallLeaderboardImg = styled(Img)`
	max-width: 320px;
`;

type Props = {
	className: string,
	type: 'leaderboard' | 'mrec',
	has_assistant_coach: boolean,
	small_screen_only: boolean,
	large_screen_only: boolean,
	is_half_width: boolean,
	toggleFantasyCoachModal: Function
}

type LeaderboardAdProps = {
	is_half_width: boolean
};

function LeaderbordAd ({is_half_width}: LeaderboardAdProps) {
	return <React.Fragment>
		<DesktopAd is_half_width={is_half_width}>
			<LargeLeaderboardImg src={coachLeaderboardSrc} />
		</DesktopAd>
		<MobileAd is_half_width={is_half_width}>
			<SmallLeaderboardImg src={coachMobileLeaderboardSrc} />
		</MobileAd>
	</React.Fragment>;
}

export class AdFantasyCoachComponent extends React.PureComponent<Props> {
	static defaultProps = {
		type: "leaderboard",
	};

	render() {
		const {
			className,
			type,
			has_assistant_coach,
			small_screen_only,
			large_screen_only,
			toggleFantasyCoachModal,
			is_half_width
		} = this.props;

		const is_mrec = type === "mrec";
		const is_leaderboard = type==="leaderboard";

		if (has_assistant_coach) {
			return null;
		}

		return (
			<AdWrapper
				className={className}
				type={type}
				small_screen_only={small_screen_only}
				large_screen_only={large_screen_only}
			>
				<CoachModalButton type="button" onClick={toggleFantasyCoachModal}>
					<Exist when={is_mrec}>
						<div><Img src={coachMRECSrc} /></div>
					</Exist>
					<Exist when={is_leaderboard}>
						<LeaderbordAd is_half_width={is_half_width} />
					</Exist>
				</CoachModalButton>
			</AdWrapper>
		);
	}
}

const mapStateToProps = state => ({
	has_assistant_coach: Boolean(selectors.getUser(state).assistant_coach),
});

export const AdFantasyCoach =
	connect(mapStateToProps)(withFantasyCoachModal(AdFantasyCoachComponent));

export default AdFantasyCoach;