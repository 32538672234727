// @flow

export default [
	{
		id: 1,
		name: "Defender",
		short_name: "Def",
		full_name: "Defenders",
	},
	{
		id: 2,
		name: "Midfielder",
		short_name: "Mid",
		full_name: "Midfielders",
	},
	{
		id: 3,
		name: "Ruck",
		short_name: "Ruck",
		full_name: "Rucks",
	},
	{
		id: 4,
		name: "Forward",
		short_name: "Fwd",
		full_name: "Forwards",
	}
];