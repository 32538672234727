// @flow
import type { Saga } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import {
	fetchOffSeasonPlayers,
	fetchOffSeasonPlayersSuccess,
	fetchOffSeasonRankings,
	fetchOffSeasonRankingsSuccess,
} from "../../actions/offSeason";

export const createSagas = (API: Object): Object => {

	function* fetchSeasonRankings(): Saga<void> {
		const result = yield call(API.off_season_rankings);
		yield put(fetchOffSeasonRankingsSuccess(result));
	}

	function* fetchSeasonPlayers(): Saga<void> {
		const result = yield call(API.off_season_players);
		yield put(fetchOffSeasonPlayersSuccess(result));
	}

	function* watch() {
		yield takeLatest(fetchOffSeasonRankings, fetchSeasonRankings);
		yield takeLatest(fetchOffSeasonPlayers, fetchSeasonPlayers);
	}

	return {
		fetchSeasonRankings,
		fetchSeasonPlayers,
		watch,
	};
};

export default createSagas;