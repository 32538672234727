// @flow
import * as React from "react";

import styled from "styled-components";
import colors from "../../../assets/css/colors";
import { below } from "../../../assets/css/media";

const TextareaField = styled.textarea`
	color: ${({ is_error }) => is_error ? colors.errorColor : colors.form.textColor};
	font-size: 13px;
	min-height: 200px;
	border: 1px solid ${({ is_error }) => is_error ? colors.errorColor : colors.form.borderColor};
	background-color: #ffffff;
	width: 100%;
	padding-left: 1em;
	font-family: "SourceSansPro";

	&:disabled {
		opacity: .5;
	}

	::placeholder {
		color: ${({ is_error }) => is_error ? "red" : colors.secondary.accentGrey};
	}

	${below.desktop`
		padding-right: 3em;
	`}
`;

type Props = {
	placeholder: string,
	input?: Object,
	is_disabled?: boolean,
	is_error?: boolean,
	readOnly?: boolean,
	max_length?: string,
};

export const Textarea = ({
	input,
	placeholder,
	is_disabled,
	is_error,
	max_length,
	readOnly,
}: Props ) => (
	<TextareaField
		{...input}
		placeholder={placeholder}
		disabled={is_disabled}
		is_error={is_error}
		readOnly={readOnly}
		maxLength={max_length || "200"}
	/>
);

export default Textarea;