import React from "react";
import styled from "styled-components";
import { ModalContainer, ButtonPrimary, ModalBody, ModalIcon } from "../../components";
import { below } from "../../assets/css/media";
import Caution from "../../components/Icons/Caution";
import colors from "../../assets/css/colors";

const StyledModal = styled(ModalContainer)`
	${below.desktop`
		margin: auto auto;
	`};

	${ModalBody} {
		padding: 0px 40px 50px 40px;
		text-align: center;
		> div {
			font-family: SourceSansPro;
			font-size: 14px;
			line-height: 1.4;
		}

		${below.desktop`
			height: auto;
		`};

		button {
			margin-top: 30px;
			text-align: center;
			min-width: 200px;
		}
	}

	${ModalIcon} {
		svg {
			color: ${colors.buttons.cancel};
		}
	}
`;

const ButtonPrimaryCancel = styled(ButtonPrimary)`
	background-color: #CF2223;
	border-color: #CF2223;

	:hover:not(:disabled) {
		background-color: #df3a3a;
	}
`;

const CenterButton = styled.div`
	display: flex;
	justify-content: center;
	button:first-child {
		margin-right: 30px;
	}
`;


const fc_cancel_msg = "This will cancel your Fantasy Coach subscription. "
	+ "You will still have access to the Fantasy Coach features until your "
	+ "subscription period expires.";

type Props = {
	cancelSubscription: Function,
	closeModal: Function
};

const CancelModal = (props: Props) => {
	const hazard_icon = <Caution />;

	return (
		<StyledModal
			onClick={props.closeModal}
			header_text='Cancel Fantasy Coach Subscription'
			top_icon={hazard_icon}
		>
			<div>{fc_cancel_msg}</div>
			<CenterButton>
				<ButtonPrimaryCancel onClick={props.closeModal}>
					No, I've changed my mind!
				</ButtonPrimaryCancel>
				<ButtonPrimary onClick={props.cancelSubscription}>
					Yes, cancel my Fantasy Coach subscription
				</ButtonPrimary>
			</CenterButton>
		</StyledModal>);
};

export default CancelModal;