import React, { useState } from "react";
import styled from "styled-components";

import { below } from "../../assets/css/media";
import { isMobile } from "../../utils";
import {dataLayer} from '../../utils/GTM';
import { 
	socialShare, handleMobileShare
} from "../../utils/social_share";
import { Exist } from "../Exist";
import FacebookIcon from "../Icons/FacebookIcon";
import { IconShare } from "../Icons/IconShare";
import TwitterIcon from "../Icons/TwitterIcon";
import { ModalContainer } from "../Modal";
import Preloader from "../Preloader";
import ButtonPrimary, { PrimaryButtonStyles } from "../ButtonPrimary";
import colors from "../../assets/css/colors";

const TeamShareContainer = styled.div`
	margin-right: 20px;
	margin-left: auto;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	p {
		font-size: 14px;
		color: #01285E;
		font-family: 'TitilliumUpright';
		font-weight: 400;
	}
	button {
		font-size: 1em;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 2px;
		border: 1px solid #CAD2D8;
		width: 41px;
		height: 41px;
		margin-left: 10px;
		cursor: pointer;
	}
	${below.phone`
		margin-right: 5px;
	`}
	
`;
const Facebook = styled.button`

`;
const Twitter = styled.button`
`;

const ShareBtn = styled.button`
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: transparent;
	border-radius: 2px;
	border: 1px solid ${colors.primary.primary};
	padding: 0;
	overflow: hidden;
	cursor: pointer;
	margin-left: 5px;
	${below.tablet`
		width: 100%;
	`}
`;

const ButtonRow = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
	${below.tablet`
		flex-direction: column;
		width: 50%;
	`}
	
`;
const DynamicContentWrapper = styled.div`
	display: flex;
	img{
		height: 600px;
		width: 600px;
	}
	padding: 16px;
	flex-direction: column;
	align-items: center;
	gap: 20px;
	${below.tablet`
		padding: 0;
		img{
			height: 280px;
			width: 280px;
		}
		button{
			width: 100%;
		}
	`}
`;

const StyledLink = styled.a`
	${PrimaryButtonStyles}
	text-decoration: none;
	font-size: 14px;
`;

const CopyButton = styled(ButtonPrimary)`
	width: 175px;
	${below.phone`
		width: 100%;
	`}
`;


const ShareTitle = styled.h1`
	font-size: 32px;
	font-family: 'TitilliumUpright';
	color: ${colors.primary.primary};
	font-weight: 600;
`;
type DynamicShareProps = {
	handleCloseClick: () => void;
	imgUrl: string;
	round_id: number;
	team_id: number
}

const DynamicShareModal = ({
	handleCloseClick,
	imgUrl,
	team_id,
	round_id
}: DynamicShareProps) => {
	const [copied, setCopied] = useState(false);
	const [loaded, setLoaded] = useState(false);
	const is_mobile = isMobile();


	const handleShare = async () => {
		const blob = await fetch(imgUrl).then(r => r.blob());
		const data = {
			files: [
				new File([blob], "file.png", {
					type: blob.type,
				}),
			],
		};
		try {
			if (!window.navigator.canShare(data)) {
				throw new Error("Can't share data.");
			}
			await window.navigator.share(data);
		}
		catch (err) {
			console.error(err.name, err.message);
		}
	};


	

	const copyImgToClipboard = async ()=> {
		// eslint-disable-next-line no-undef
		const item = new ClipboardItem({
			'image/png': (async () => {
			  const response = await fetch(imgUrl);
			  return await response.blob();
			})(),
		  });
		navigator.clipboard.write([item]);
		setCopied(true);
		setTimeout(() => {
			setCopied(false);
		}, 3000);
	};

	

	
	return <React.Fragment>
		<Exist when={!loaded}>
			<Preloader />
		</Exist>
		<div style={{display: loaded ? "flex" : "none"}}>
			<ModalContainer onClick={handleCloseClick}>
				<DynamicContentWrapper>
					<ShareTitle>
						Share Your Team
					</ShareTitle>
					<img src={imgUrl} alt="Team Share" onError={() => setLoaded(false)} onLoad={() => setLoaded(true)}/>
					<ButtonRow>
						<Exist when={is_mobile}>
							<ShareBtn onClick={handleShare}>
								<IconShare color={'#3C345D'} width={20} height={25} />
							</ShareBtn>
						</Exist>
						<Exist when={!is_mobile}>
							<CopyButton onClick={copyImgToClipboard} >
								{copied ? "Copied!" : "Copy Image for Share"}
							</CopyButton>
							
						</Exist>
						<StyledLink href={imgUrl} download="AFLTeam.png">
							Download Image
						</StyledLink>
						
						
						{/* <Exist when={!is_mobile}>
							<Facebook aria-label="share my team on Facebook" onClick={shareFb}><FacebookIcon color={"#01285E"} /></Facebook>
							<Twitter aria-label="share my team on X (Formerly Twitter)" onClick={shareTw}><TwitterIcon color={"#01285E"} /></Twitter>
						</Exist> */}
						
					</ButtonRow>
				</DynamicContentWrapper>
				
				
			</ModalContainer>
		</div>
			
	
		
	</React.Fragment> ;
};

type Props = {
	round_id: number,
	team_id: number,
	is_scheduled?: boolean,
	isTeamStarted: boolean,
	isTeamComplete: boolean,
};

export const TeamShare = ({
	round_id,
	team_id,
	is_scheduled=true,
	isTeamComplete,
	isTeamStarted
}: Props) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const share_data = {
		is_classic: true,
		team_id,
		round_id,
		share_type: "team"
	};

	const handleModalOpen = () => {
		dataLayer({
			'event': 'Dynamic Share Team Click' 
		});
		setIsModalOpen(true);

	};
	const handleModalClose = () => {
		setIsModalOpen(false);
	};


	const getImgShare = () => {
		return socialShare({
			isImgShare: true,
			team_id: team_id,
			share_type: "fb" 
		}) || "";
	};
	const shareFb = () => {
		socialShare({
			...share_data,
			sc_type: "fb",
			is_scheduled
		});
	};

	const shareTw = () => {
		socialShare({
			...share_data,
			sc_type: "tw",
			is_scheduled
		});
	};

	const handleShare = () => {
		handleMobileShare({
			...share_data,
			is_scheduled
		});
	};
	// add logic for team complete and start round not started yet
	if(!isTeamStarted && isTeamComplete){
		const imgUrl = getImgShare();
		return <React.Fragment>
			<ButtonPrimary 
				onClick={handleModalOpen} 
				style={{position: "absolute", right: "0"}} 
				aria-label={`Click Dynamic Share`}
				id={"team_dynamic_share"}
				name={`Click Dynamic Share`} >
				Share my team
			</ButtonPrimary>
			<Exist when={isModalOpen}>
				<DynamicShareModal 
					handleCloseClick={handleModalClose} 
					imgUrl={imgUrl} round_id={round_id} 
					team_id={team_id}
				/>
			</Exist>
			
		</React.Fragment> ;
	}
	

	return (
		<Exist when={Boolean(round_id)}>
			<TeamShareContainer>
				<p>Share my team</p>
				<Exist when={!isMobile()}>
					<Facebook aria-label="share my team on Facebook" onClick={shareFb}><FacebookIcon color={"#01285E"} /></Facebook>
					<Twitter aria-label="share my team on X (Formerly Twitter)" onClick={shareTw}><TwitterIcon color={"#01285E"} /></Twitter>
				</Exist>
				<Exist when={isMobile()}>
					<ShareBtn onClick={handleShare}>
						<IconShare color={'#3C345D'} width={20} height={25} />
					</ShareBtn>
				</Exist>
			</TeamShareContainer>
		</Exist>	
	);
};

export default TeamShare;