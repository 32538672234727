import React, { useState } from "react";
import styled from "styled-components";
import { Exist } from "../Exist";
import Preloader from "../Preloader";
import { ModalContainer } from "../Modal";
import { below } from "../../assets/css/media";
import { IconShare } from "../Icons/IconShare";
import ButtonPrimary, { PrimaryButtonStyles } from "../ButtonPrimary";
import colors from "../../assets/css/colors";
import { isMobile } from "../../utils";
// import { shareFbMatchup, shareTwitterMatchup } from "../../utils/social_share";
// import FacebookIcon from "../Icons/FacebookIcon";
// import TwitterIcon from "../Icons/TwitterIcon";


const StyledLink = styled.a`
	${PrimaryButtonStyles}
	text-decoration: none;
	font-size: 14px;
`;

const CopyButton = styled(ButtonPrimary)`
	width: 175px;
    font-size: 14px;
    height: 
	${below.phone`
		width: 100%;
	`}
`;


const ShareTitle = styled.h1`
	font-size: 22px;
	font-family: 'TitilliumUpright';
	color: ${colors.primary.primary};
	font-weight: 600;
    padding: 0 40px;
`;


const DynamicContentWrapper = styled.div`
	display: flex;
	img{
		height: 500px;
		width: 500px;
	}
	padding: 16px;
	flex-direction: column;
	align-items: center;
	gap: 20px;
	${below.tablet`
		padding: 0;
		img{
			height: 280px;
			width: 280px;
		}
		button{
			width: 100%;
		}
	`}
`;


const ButtonRow = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
	${below.tablet`
		flex-direction: column;
		width: 50%;
	`}
	
`;


// const SocialButton = styled.button`
//     ${PrimaryButtonStyles};
//     width: 40px;
//     height: 40px;
//     padding: 0;

// `;


const ShareBtn = styled.button`
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: transparent;
	border-radius: 2px;
	border: 1px solid ${colors.primary.primary};
	padding: 0;
	overflow: hidden;
	cursor: pointer;
	margin-left: 5px;
	${below.tablet`
		width: 100%;
	`}
`;

export const MatchupShare = ({
	handleCloseClick,
	is_classic,
	round_id,
	league_id,
	league_name,
	team_id,
	imgUrl,
}) => {
	

	const [copied, setCopied] = useState(false);
	const [loaded, setLoaded] = useState(false);
	const is_mobile = isMobile();
    
    
	const handleShare = async () => {
		const blob = await fetch(imgUrl).then(r => r.blob());
		const data = {
			files: [
				new File([blob], "file.png", {
					type: blob.type,
				}),
			],
		};
		try {
			if (!window.navigator.canShare(data)) {
				throw new Error("Can't share data.");
			}
			await window.navigator.share(data);
		}
		catch (err) {
			console.error(err.name, err.message);
		}
	};

	// const shareFb = () => {
	// 	shareFbMatchup({
	// 		round_id,
	// 		league_id,
	// 		team_id,
	// 		is_classic,
	// 	});
	// };
    
	// const shareTw = () => {
	// 	shareTwitterMatchup({
	// 		round_id,
	// 		league_id,
	// 		team_id,
	// 		is_classic,
	// 	});
	// };
    
        
    
	const copyImgToClipboard = async ()=> {
		// eslint-disable-next-line no-undef
		const item = new ClipboardItem({
			'image/png': (async () => {
				const response = await fetch(imgUrl);
				return await response.blob();
			})(),
		});
		navigator.clipboard.write([item]);
		setCopied(true);
		setTimeout(() => {
			setCopied(false);
		}, 3000);
	};
    
        
    
        
	return <React.Fragment>
		<Exist when={!loaded}>
			<Preloader />
		</Exist>
		<div style={{display: loaded ? "flex" : "none"}}>
			<ModalContainer onClick={handleCloseClick}>
				<DynamicContentWrapper>
					
					<img src={imgUrl} alt="Team Share" onError={() => setLoaded(false)} onLoad={() => setLoaded(true)}/>
					<ShareTitle>
                        Bragging rights or motivation for next time - why not share how you went in {league_name} this week?
					</ShareTitle>
					<ButtonRow>
						<Exist when={is_mobile}>
							<ShareBtn onClick={handleShare}>
								<IconShare color={'#3C345D'} width={20} height={25} />
							</ShareBtn>
						</Exist>
						<Exist when={!is_mobile}>
							<CopyButton onClick={copyImgToClipboard} >
								{copied ? "Copied!" : "Copy Image for Share"}
							</CopyButton>
                                
						</Exist>
						<StyledLink href={imgUrl} download="AFLMatchup.png">
                            Download Image
						</StyledLink>
                            
						{/*                             
						<Exist when={!is_mobile}>
							<SocialButton aria-label="share my team on Facebook" onClick={shareFb}><FacebookIcon color={"#fff"} /></SocialButton>
							<SocialButton aria-label="share my team on X (Formerly Twitter)" onClick={shareTw}><TwitterIcon color={"#fff"} /></SocialButton>
						</Exist> */}
                            
					</ButtonRow>
				</DynamicContentWrapper>
                    
                    
			</ModalContainer>
		</div>
                
        
            
	</React.Fragment> ;
	
    
};