// @flow
import * as React from "react";
import { connect } from "react-redux";
import { formValueSelector, reduxForm } from "redux-form";
import styled from "styled-components";
import { below } from "../../assets/css/media";

import {
	ButtonPrimary,
	FormRadio,
} from "../../components";

const ChangePasswordFormStyled = styled.form`
	box-sizing: border-box;
	margin-bottom: 50px;
`;

const FormItem = styled.div`
	margin: 10px 0;
	width: 440px;
	${below.phone`
		width: 100%;
	`};
	input{
		height: 40px;
	}
`;

const StyledButtonPrimary = styled(ButtonPrimary)`
	width: 140px;
	${below.phone`
		width: 100%;
	`};
`;


type Props = {
	handleSubmit: Function,
	notifications_off: boolean,
}

const CommunicationsFormComponent = ({ handleSubmit, notifications_off }: Props) => (
	<ChangePasswordFormStyled onSubmit={handleSubmit}>
		<FormItem>
			<FormRadio
				label='All Notifications'
				name='disable_notifications'
				elements={[
					{
						value: 1,
						text: "Off",
					},
					{
						value: 0,
						text: "On",
					}
				]}
			/>
		</FormItem>
		<FormItem>
			<FormRadio
				label='General Game Emails'
				name='game_emails'
				elements={[
					{
						value: 0,
						text: "Off",
					},
					{
						value: 1,
						text: "On",
					}
				]}
				is_disabled={notifications_off}
			/>
		</FormItem>
		<FormItem>
			<FormRadio
				label='Weekly Results Update'
				name='weekly_updates'
				elements={[
					{
						value: 0,
						text: "Off",
					},
					{
						value: 1,
						text: "On",
					}
				]}
				is_disabled={notifications_off}
			/>
		</FormItem>
		<FormItem>
			<FormRadio
				label='Weekly Reminder And Team Announcements'
				name='weekly_reminders'
				elements={[
					{
						value: 0,
						text: "Off",
					},
					{
						value: 1,
						text: "On",
					}
				]}
				is_disabled={notifications_off}
			/>
		</FormItem>

		<StyledButtonPrimary>
			Update
		</StyledButtonPrimary>
	</ChangePasswordFormStyled>
);
const CommunicationsReduxForm = reduxForm({
	form: "communications_form",
})(CommunicationsFormComponent);

const selector = formValueSelector("communications_form");

const mapStateToProps = state => {
	const {
		disable_notifications,
		game_emails,
		weekly_updates,
		weekly_reminders,
	} = state.user.data;

	return {
		notifications_off: selector(state, "disable_notifications") === 1,
		initialValues: {
			disable_notifications,
			game_emails,
			weekly_updates,
			weekly_reminders,
		},
	};
};

export const CommunicationsForm = connect(
	mapStateToProps,
	null,
)(CommunicationsReduxForm);

export default CommunicationsForm;