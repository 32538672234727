// @flow
import * as React from "react";
import styled from "styled-components";
import Close from "../Icons/Close";
import colors from "../../assets/css/colors";
import { default_transition } from "../../assets/css/vars";

const ModalCloseWrap = styled.div`
	button {
		float:right;
		margin: 10px 10px 0 0;
	}
`;

const ModalCloseStyled = styled.button`
	background:transparent;
	border: none;
	${default_transition};
	
	svg {
		${default_transition};
		margin:3px 0 0 0;
	}

	&:hover {
		> svg {
			fill: ${colors.primary.accent};
		}
	}
`;

type Props = {
	onClick: Function
};

export const ModalClose = ({ onClick }: Props) => (
	<ModalCloseWrap className='modal-close-wrap'>
		<ModalCloseStyled aria-label="Close" onClick={onClick} className="close-button">
			<Close aria-hidden="false" cursor="pointer" size='2' color={colors.primary.primary} />
		</ModalCloseStyled>
	</ModalCloseWrap>
);

export default ModalClose;