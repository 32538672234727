
/* eslint max-len: 0 */
// @flow
import React from "react";
import SvgIconContainer from "../SvgIconContainer";

export default (
	{
		unit = "em",
		viewbox = "24",
		color = "#4EA431",
		...rest
	}: Object = {}) => (
	<SvgIconContainer viewBox={`0 0 ${viewbox} ${viewbox}`} {...rest} unit={unit} color={color}>
		<title>Close Circle Logo</title>
		<path d="M8,0 C12.418278,0 16,3.581722 16,8 C16,12.418278 12.418278,16 8,16 C3.581722,16 0,12.418278 0,8 C0,3.581722 3.581722,0 8,0 Z M12,4 L6.22222222,9.77777778 L4,7.55555556 L2.66666667,8.88888889 L6.22222222,12.4444444 L13.3333333,5.33333333 L12,4 Z"></path>
	</SvgIconContainer >
);