// @flow
import React from "react";
import * as _ from "lodash";
import {
	TRoundWithMatches,
	TPlayersArrayWithFixtureById,
	TPosition,
	TDraftUserTeam,
	TPLeague,
	TMatch,
	TRound
} from "../../../../modules/types";
import { ListViewPlayerEmpty, ListViewPlayer } from "../../../../components";
import { matchStarted } from "../../../../helpers/rounds";
import {getClubLogoIndigenous} from "../../../../utils/clubLogos";
import { shouldDoubleScore } from "../../../../helpers/stats/displayRound";
import { 
	getIsLockedFromLockout, 
	getPartialByeRound, 
	isAllTrue, 
	lockoutKey 
} from "../../../../helpers";

type Props = {
	player_id: number,
	players_by_id: TPlayersArrayWithFixtureById,
	has_assistant_coach: boolean,
	selected_round: TRoundWithMatches,
	position: TPosition,
	team: TDraftUserTeam,
	league: TPLeague,
	is_mobile: boolean,
	is_bench: boolean,
	field_stat: string,
	position_index: number,
	removePlayer: Function,
	rounds: TRound[],
	setCaptain: Function,
	setViceCaptain: Function,
	setEmergency: Function,
	openPlayerPopup: Function,
	areSubstitutesActive: Function,
	isOutSubstitute: Function,
	isInSubstitute: Function,
	substitutePlayer: Function,
	cancelSubstitute: Function,
	matches: Array<TMatch>,
	locked_personnel: any[],
	is_static: boolean,
	is_current: boolean,
	bye_detector_selected: number[],
	bye_detector_active: boolean,
	mobile_stat: Object,
	setPlayerAction: Function,
	is_utility: boolean,
	odd: boolean,
	no_trade_buttons?: boolean,
	is_team_full?: boolean,
	is_classic?: boolean,
	edit_teams_view?: boolean,
	squads_by_id: Object,
	pointsLastRound: number|string,
	pointsLabel: string,
	openAddPlayerModal?: Function,
	closeAddPlayerModal?: Function,
  actual_round: TRound,
  is_overflow?: boolean,
  is_my_team?: boolean
};

type State = {

};



export class PlayerRow extends React.Component<Props, State> {
	get emergency_position() {
		const { team: { lineup }, player_id } = this.props;

		if (this.is_emergency) {
			return parseInt(_.findKey(lineup.emergency, id => id === player_id), 10);
		}
		return undefined;
	}

	get is_emergency() {
		const { team: { lineup }, player_id } = this.props;

		return player_id !== 0 && player_id !== undefined
			&& lineup.emergency
			&& _.some(_.map(lineup.emergency, id => id === player_id));
	}

	get player() {
		return this.props.players_by_id[this.props.player_id] || {};
	}
	
	hasPlayerMatchStarted(player_id: number){
		const { players_by_id, selected_round } = this.props;

		const player = players_by_id[player_id];
		let has_player_started = false;
		if(selected_round && selected_round.matches){
			selected_round.matches.forEach(match => {
				const homeSquadId = _.get(match, 'home_squad_id');
				const awaySquadId = _.get(match, 'away_squad_id');
				const playerSquad = _.get(player, "squad_id");
				if(homeSquadId === playerSquad 
          || awaySquadId === playerSquad){
					if(match.status !== "scheduled"){
						has_player_started = true;
					}
				}
			}) ;
			
		}
		return has_player_started;
		
	}

	hasRoundStarted() {
		const {selected_round} = this.props;
		return selected_round.status !== "scheduled";
	}
	

	render() {
		const {
			player_id,
			position,
			is_bench,
			removePlayer,
			setCaptain,
			setViceCaptain,
			setEmergency,
			substitutePlayer,
			cancelSubstitute,
			openPlayerPopup,
			has_assistant_coach,
			league,
			is_mobile,
			field_stat,
			areSubstitutesActive,
			isOutSubstitute,
			isInSubstitute,
			position_index,
			selected_round,
			players_by_id,
			team: { lineup, players_margin },
			matches,
			locked_personnel,
			is_static,
			is_current,
			bye_detector_selected,
			bye_detector_active,
			mobile_stat,
			setPlayerAction,
			rounds,
			odd,
			no_trade_buttons,
			is_team_full,
			is_classic,
			edit_teams_view,
			squads_by_id,
			pointsLastRound,
			pointsLabel,
			openAddPlayerModal,
			closeAddPlayerModal,
			actual_round,
			is_overflow,
			is_my_team
		} = this.props;
		if (!player_id) {
			const is_substitute = areSubstitutesActive();
			const out_substitute = isOutSubstitute(null, position.id, position_index);
			const in_substitute = isInSubstitute(null, position.id, position_index);
			return <ListViewPlayerEmpty
				leagueID={league.id}
				position_id={position.id}
				position_full_name={position.name}
				is_bench={is_bench}
				is_draft={true}
				position_index={position_index}
				is_substitute={is_substitute}
				out_substitute={out_substitute}
				in_substitute={in_substitute}
				substitutePlayer={substitutePlayer}
				is_team_full={is_team_full}
				is_classic={is_classic}
				edit_teams_view={edit_teams_view}
				openAddPlayerModal={openAddPlayerModal}
				closeAddPlayerModal={closeAddPlayerModal}
			/>;
		}

		const player = this.player;
		const is_substitute = areSubstitutesActive();
		const out_substitute = isOutSubstitute(player, position.id);
		const in_substitute = isInSubstitute(player, position.id);

		const match_not_started = !matchStarted({ matches, id: 0 }, player.squad_id);

		const captain_id = _.get(lineup, "captain");
		const captain = _.get(players_by_id, captain_id);
		const double_score = shouldDoubleScore(player, lineup, selected_round, captain);
		const player_bye = _.get(player, "stats.bye_round_id");
		const playerSquad = _.get(player, 'squad.id',0);
		const player_partial_bye = getPartialByeRound(playerSquad, rounds);
		
		const getSquadMatch = (matches, squad_id) => {
			// get the match
			const match = matches.find(match => 
				[match.away_squad_id, match.home_squad_id ].includes(squad_id));
			
			if(!match){
				return null;
			}
			const is_player_home = squad_id === match.home_squad_id;
			const opposition_id = is_player_home ? match.away_squad_id : match.home_squad_id;
			const clubLogoOpposition = getClubLogoIndigenous(actual_round.id, opposition_id);
			return {
				 opposition_logo: clubLogoOpposition,
				 home_team: squads_by_id[match.home_squad_id] ? 
				 	squads_by_id[match.home_squad_id].short_name : "",
				 away_team: squads_by_id[match.away_squad_id] ? 
				 	squads_by_id[match.away_squad_id].short_name : "",
				 is_home: is_player_home
			};
		};
		const isRoundStarted = actual_round.status === "active";
		let player_next_match;
		if(edit_teams_view){
			player_next_match = 
				getSquadMatch(_.get(selected_round, "matches"), player.squad_id);
		}
		const lockout = lockoutKey(league);
		let is_locked = false;
	
		const isPlayerLocked = player.locked 
			? player.locked : player.is_locked
				? player.is_locked : false;		

		if(lockout === "rolling") {
			is_locked = this.hasPlayerMatchStarted(player.id);
		}
		else if(isAllTrue([
			lockout === "standard",
			isRoundStarted
		])){
			is_locked = true;
		}
		else {
			is_locked = getIsLockedFromLockout(
				Boolean(isPlayerLocked),
				is_static,
				selected_round,
				edit_teams_view
			);
		}
		
		return <ListViewPlayer
			key={player_id}
			player={player}
			position_id={position.id}
			odd={odd}
			has_captain={league.captains}
			has_emergencies={league.emergency}
			is_locked={is_locked}
			is_bench={is_bench}
			is_captain={lineup.captain === player_id}
			is_vice={lineup.vice_captain === player_id}
			is_emergency={this.is_emergency}
			locked_personnel={locked_personnel}
			custom_scoring_enabled={league.custom_scoring_enabled}
			emergency_position={this.emergency_position}
			has_assistant_coach={has_assistant_coach}
			is_draft={true}
			is_mobile={is_mobile}
			player_partial_bye={player_partial_bye}
			league={this.props.league}

			selected_round={selected_round}
			delist_link={`/draft/league/${league.id}/players/${player_id}/delist`}
			trade_link={`/draft/league/${league.id}/players/${player_id}/trade`}

			is_substitute={is_substitute}
			out_substitute={out_substitute}
			in_substitute={in_substitute}
			is_team_started={false}
			player_stat_field={field_stat}

			removePlayer={removePlayer}
			setCaptain={setCaptain}
			setViceCaptain={setViceCaptain}
			setEmergency={setEmergency}
			substitutePlayer={substitutePlayer}
			cancelSubstitute={cancelSubstitute}
			openPlayerPopUp={openPlayerPopup}
			setPlayerAction={setPlayerAction}
			
			match_not_started={match_not_started}
			players_margin={players_margin}
			double_score={double_score}

			is_static={is_static}
			is_current={is_current}

			bye_detector_selected={bye_detector_selected}
			bye_detector_active={bye_detector_active}
			player_bye={player_bye}

			mobile_stat={_.get(mobile_stat, "key")}
			no_trade_buttons={no_trade_buttons}
			edit_teams_view={edit_teams_view}
			player_next_match={player_next_match}
			pointsLastRound={pointsLastRound}
			pointsLabel={pointsLabel}
			openAddPlayerModal={openAddPlayerModal}
			closeAddPlayerModal={closeAddPlayerModal}
			actual_round={actual_round}
			is_overflow={is_overflow}
			is_my_team={is_my_team}
		/>;
	}
};

export default PlayerRow;