// @flow
import * as React from "react";
import _ from "lodash";
import styled from "styled-components";
import Player from "../../../../../components/Icons/Player";

const Button = styled.button`
	background: #FFF;
	width: 100%;
	border: none;
	font-size: 12px;
	color: #CAD2D8;
	cursor: pointer;
	font-weight: 800;
	border-radius: 2px;
	height: 100%;
	padding: 0 0 0 35%;
	position: relative;
	text-align: left;
	outline: none;
`;

const DefaultPlayerIcon = styled(Player)`
	height: 100%;
	width: 30px;
	position: absolute;
	left: 42px;
	bottom: 0;
`;

type Props = {
	pos: string,
};

export const TeamItemEmpty = ({ pos, ...rest }: Props) => {
	const prefix = _.eq(pos, "bench") ? " player" : "";

	return (
		<Button {...rest}>
			<DefaultPlayerIcon />
			Draft a { pos.toLowerCase() + prefix }
		</Button>
	);
};

export default TeamItemEmpty;