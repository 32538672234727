// @flow
import * as React from "react";
import styled from "styled-components";
import colors from "../../assets/css/colors";
import "react-toggle/style.css";
import { default_transition } from "../../assets/css/vars";

const SwitchToggleLabel = styled.label`
	font-size: 13px;
	line-height: 1.5;
	color: ${colors.primary.primary};
	margin-left: 5px;
	cursor: pointer;
	font-family: SourceSansPro;
	font-weight: 600;
`;

const SwitchToggleStyled = styled.div`
	display: flex;
	align-items: center;
	margin-right: 1em;

	.custom-checkbox {
		position: relative;
		cursor: pointer;
		height: 20px;
		width: 40px;
		border-radius: 100px;
		transform: translate3d(0,0,0);
		/* box-shadow: inset 0 0 3px 1px rgb(0,0,0,0.2); */

		&:before {
			content: '';
			position: absolute;
			left: 2px;
			top: 2px;
			border-radius: 50%;
			display: block;
			height: 16px;
			width: 16px;
			background-color: #FFFFFF;
			/* 
			box-shadow: ${({ checked }) => checked ? "-2px" : "2px"} 0px 3px 1px rgba(0,0,0,0.2); 
			*/
			${default_transition};
			transform: translate(0);
		}
	}

	input {
		visibility: hidden;
		width: 0;
		margin: 0;

		&:checked + label {
			&:before {
				transform: translateX(20px);
			}
		}
	}
`;

type Props = {
	id: string,
	name: string,
	checked?: boolean,
	onChange?: Function,
	label?: string,
	className?: string,
	disabled?: boolean,
	value?: boolean,
};

export const SwitchToggle = ({
	name,
	id,
	checked,
	value,
	onChange,
	label,
	className,
	disabled = false,
}: Props) => (
	<SwitchToggleStyled id={`id-${id}`} className={className} checked={value}>
		<input type="checkbox"
			defaultChecked={checked}
			checked={value}
			id={id}
			name={name}
			onChange={onChange}
			disabled={disabled}
			readOnly
		/>
		<label className='custom-checkbox' htmlFor={id}>&nbsp;</label>

		{label ? (<SwitchToggleLabel htmlFor={id}>{label}</SwitchToggleLabel>) : null}
	</SwitchToggleStyled>
);

export default SwitchToggle;