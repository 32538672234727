// @flow
import _ from 'lodash';
import type { TPlayer } from '../../modules/types';

export const getStatsCostDividedByAveragePoints = (player: TPlayer) => {
	const { cost, stats } = player,
		{ avg_points } = stats;

	return parseInt(avg_points ? (cost / avg_points) : 0, 10);
};

export const getPlayerScoreByRound = (
	player: TPlayer,
	round_id: number,
	default_val: string | typeof undefined
) => {
	if (parseInt(round_id, 10) < 1) {
		default_val = '-';
	}

	return _.get(player, `stats.scores[${round_id}]`, default_val);
};

export const getPlayerCustomScoreByRound = (
	player: TPlayer,
	round_id: number,
	default_val: string | typeof undefined
) => {
	if (parseInt(round_id, 10) < 1) {
		default_val = '-';
	}

	return _.get(player, `custom_stats.scores[${round_id}]`, default_val);
};

export const getPlayerProjectedScoreByRound = (
	player: TPlayer,
	round_id: number,
	default_val: string | typeof undefined,
) => {
	if (parseInt(round_id, 10) < 1) {
		default_val = '-';
	}

	return _.get(player, `stats.proj_scores[${round_id}]`, default_val);
};

export const getPlayerCustomProjectedScoreByRound = (
	player: TPlayer,
	round_id: number,
	default_val: string | typeof undefined,
) => {
	if (parseInt(round_id, 10) < 1) {
		default_val = '-';
	}

	return _.get(player, `custom_stats.proj_scores[${round_id}]`, default_val);
};