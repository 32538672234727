// @flow
import * as _ from 'lodash';
import { createReducer } from 'redux-act';
import * as actions from '../../actions';
import { arrayToObjectById } from "./../../../helpers";

export const initial_state = {
	is_pending: false,
	custom_stats_loading:false,
	error: null,
	by_id: {},
	past_years: {},
	favourites: [],
	favourites_last_fetched: null,
	ordered_ids: [],
	coach_by_id: {},
	matches_by_id: {},
	venue_stats_classic: {},
	venues_stats_draft: {},
	player_opposition_classic: {},
	player_opposition_draft: {},
};

const onError = (state, payload) => ({
	...state,
	error: payload,
});

export const players = createReducer({}, initial_state);

players.on(actions.fetchPlayers, state => ({
	...state,
	is_pending: true,
	error: null,
}));

players.on(actions.fetchPlayersFailed, (state, payload) => ({
	...state,
	is_pending: false,
	error: payload,
}));

players.on(actions.fetchPlayersSuccess, (state, payload: any) => {
	const by_id = arrayToObjectById(payload, state.by_id);

	return {
		...state,
		is_pending: false,
		error: null,
		by_id,
		ordered_ids: payload.map(item => item.id),
	};
});

players.on(actions.fetchPastYearPlayersSuccess, (state, payload: any) => {
	const by_id = arrayToObjectById(payload.result);

	return {
		...state,
		past_years: {
			...state.past_years,
			[payload.year]: by_id
		}
	};
});

players.on(actions.fetchPastYearPlayerMatches, state => {
	return {
		...state,
		is_pending: true,
		error: null
	};
});

players.on(actions.fetchPastYearPlayerMatchesSuccess, (state, payload: any) => {
	return {
		...state,
		by_id: {
			...state.by_id,
			[payload.player_id]: {
				...state.by_id[payload.player_id],
				matches_by_year: {
					...state.by_id[payload.player_id].matches_by_year,
					[payload.year]: payload.result,
				}
        
			}
		}
	};
});
players.on(actions.fetchPastYearPlayerMatchesFailed, (state, payload) => {
	return {
		...state,
		is_pending: false,
		error: payload,
	};
});

players.on(actions.fetchPlayerMatchesSuccess, (state, payload: any) => {
	return {
		...state,
		matches_by_id: {
			...state.matches_by_id,
			[payload.player_id]: payload.result
		}
	};
});

players.on(actions.fetchPlayersMatchesSuccess, (state, { round_id, matches }: any) => {
	const result = _.reduce(matches, (all, match_stats, id) => {
		return {
			...all,
			[id]: {
				..._.get(all, id),
				[round_id]: match_stats,
			}
		};
	}, {});

	return {
		...state,
		matches_by_id: {
			...result
		}
	};
});


players.on(actions.fetchFavouritesSuccess, (state, {
	favourites, current_date_string
}: any) => ({
	...state,
	favourites,
	favourites_last_fetched: current_date_string
}));

players.on(actions.fetchFavouritesFailed, onError);

players.on(actions.postAddToFavouritesSuccess, (state: any, player_id) => ({
	...state,
	favourites: [
		...state.favourites,
		parseInt(player_id, 10),
	],
}));

players.on(actions.postAddToFavouritesFailed, onError);

players.on(actions.postRemoveFromFavouritesSuccess, (state: any, player_id) => {
	const favourites = state.favourites.slice();
	const player_index = favourites.indexOf(parseInt(player_id, 10));

	if (player_index !== -1) {
		favourites.splice(player_index, 1);
	}

	return { ...state, favourites };
});

players.on(actions.postRemoveFromFavouritesFailed, onError);
players.on(actions.fetchCoachPlayers, state => ({
	...state,
	is_pending: true,
	error: null,
}));
players.on(actions.fetchCoachPlayersSuccess, (state, coach_by_id) => ({
	...state,
	coach_by_id,
	is_pending: false,
}));

players.on(actions.fetchCoachPlayersFailed, onError);

players.on(actions.fetchAllStatsSuccess, (state, all_stats) => ({
	...state,
	all_stats,
}));

players.on(actions.fetchAllStatsFailed, onError);

players.on(actions.fetchAllCustomStatsLoad, state => ({
	...state,
	custom_stats_loading:true,
}));
players.on(actions.fetchAllCustomStatsSuccess, (state, all_custom_stats) => ({
	...state,
	custom_stats_loading:false,
	all_custom_stats,
}));

players.on(actions.fetchAllCustomStatsFailed, onError);

players.on(actions.fetchAllCoachCustomStatsSuccess, (state, all_coach_custom_stats) => ({
	...state,
	all_coach_custom_stats,
}));

players.on(actions.fetchAllCoachCustomStatsFailed, onError);

players.on(actions.fetchPlayerVenueStatsSuccess, (state, venue_stats_classic) => ({
	...state, 
	venue_stats_classic
}));

players.on(actions.fetchPlayerVenueStatsFailed, onError);

players.on(actions.fetchPlayerVenueStatsDraftSuccess, (state, venues_stats_draft) => ({
	...state,
	venues_stats_draft
}));

players.on(actions.fetchPlayerVenueStatsDraftFailed, onError);

players.on(actions.fetchPlayerOppositionStatsSuccess, (state, player_opposition_classic) => ({
	...state,
	player_opposition_classic
}));

players.on(actions.fetchPlayerOppositionStatsFailed, onError);

players.on(actions.fetchPlayerOppositionStatsDraftSuccess, (state, player_opposition_draft) => ({
	...state,
	player_opposition_draft
}));

export default players;