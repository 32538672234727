// @flow
import { createSelector } from "reselect";
import * as _ from "lodash";
import * as numeral from "numeral";
import * as roundUtils from "../rounds";
import { getPlayerScoreByRound } from "../../../helpers/players";

const playersSelector = state => state.players.by_id;
const roundsSelector = state => state.rounds.ordered_ids.map(id => state.rounds.by_id[id]);
const matchesSelector = state => state.rounds.matches_by_id;
const playerCoachStatsSelector = state => state.players.coach_by_id;

const getMatchesArray = (round, matches_by_id) => round ?
	round.matches.map(id => matches_by_id[id]) :
	[];

const findAverageForOppentInMatches = (player, matches, coach_stats) => {
	const match = matches.find(match =>
		match.home_squad_id === player.squad_id || match.away_squad_id === player.squad_id);

	if (!match) {
		return 0;
	}

	const squad_id = _.get(match, "home_squad_id") === player.squad_id
		? match.away_squad_id
		: match.home_squad_id;
	return _.get(coach_stats, [player.id, "opponents", squad_id], 0);
};

const findAverageForVenueInMatches = (player, matches, coach_stats) => {
	const match = matches.find(match =>
		match.home_squad_id === player.squad_id || match.away_squad_id === player.squad_id);

	if (!match) {
		return 0;
	}

	return _.get(coach_stats, [player.id, "venues", match.venue_id], 0);
};

export const coachStatsTransformed = createSelector(
	playersSelector,
	roundsSelector,
	matchesSelector,
	playerCoachStatsSelector,
	roundUtils.getActualRound,
	roundUtils.getByeRounds,
	(
		players_by_id,
		rounds_array,
		matches_by_id,
		coach_stats,
		actual_round,
		bye_rounds,
	) => {
		if (_.isEmpty(players_by_id) || _.isEmpty(rounds_array)) {
			return {};
		}

		const getRound = (next_round, number) => {
			if (!next_round) {
				return null;
			}

			return (next_round && next_round.id <= number)? null:
				rounds_array.find(({ id }) => id === (next_round.id - number));
		};
		const getNextRound = (next_round, number) => {
			if (!next_round) {
				return null;
			}

			return rounds_array.find(({ id }) => id === (next_round.id + number));
		};

		const next_round = rounds_array.find(round => round.status !== "complete");

		// If the round has started, use that as the last round score
		const last_round = getRound(next_round, 1);
		const round_2_prior = getRound(next_round, 2);
		const round_3_prior = getRound(next_round, 3);

		const round_in_2_weeks = getNextRound(next_round, 1);
		const round_in_3_weeks = getNextRound(next_round, 2);

		const getProjPrices = (next_round, round_in_2_weeks, proj_prices, cost) => {
			const proj_price_next_round = next_round ? _.get(proj_prices, next_round.id, 0) : null;
			const proj_price_change_next_round = proj_price_next_round &&
				proj_price_next_round - cost;
			const proj_price_change_next_two_rounds = (
				round_in_2_weeks && proj_price_next_round &&
				_.get(proj_prices, round_in_2_weeks.id, 0) - proj_price_next_round
			);

			return {
				proj_price_next_round,
				proj_price_change_next_round,
				proj_price_change_next_two_rounds
			};
		};

		return Object.keys(coach_stats).reduce((accumlator, player_id) => {
			const player = players_by_id[player_id];

			if (_.isEmpty(player)) {
				return {};
			}

			const opp_next_avg = findAverageForOppentInMatches(
				player,
				getMatchesArray(next_round, matches_by_id),
				coach_stats);

			const opp_2week_avg = findAverageForOppentInMatches(
				player,
				getMatchesArray(round_in_2_weeks, matches_by_id),
				coach_stats);

			const opp_3week_avg = findAverageForOppentInMatches(
				player,
				getMatchesArray(round_in_3_weeks, matches_by_id),
				coach_stats);

			// $FlowFixMe: Flow doesn't understand Math.round10, even when we have add polyfill.
			const opp_next3_avg = Math.round10((opp_next_avg+opp_2week_avg+opp_3week_avg)/3, -2);

			const venue_avg_one_round_after_current = findAverageForVenueInMatches(
				player,
				getMatchesArray(next_round, matches_by_id),
				coach_stats);

			const venue_avg_two_rounds_after_current = findAverageForVenueInMatches(
				player,
				getMatchesArray(round_in_2_weeks, matches_by_id),
				coach_stats);

			const venue_avg_three_round_after_current = findAverageForVenueInMatches(
				player,
				getMatchesArray(round_in_3_weeks, matches_by_id),
				coach_stats);

			const venue_avg_3_rounds_sum = venue_avg_one_round_after_current +
				venue_avg_two_rounds_after_current +
				venue_avg_three_round_after_current;

			// $FlowFixMe: Flow doesn't understand Math.round10, even when we have add polyfill.
			const venue_avg_3_rounds = Math.round10(venue_avg_3_rounds_sum / 3, -2);

			const { proj_prices } = coach_stats[player_id];

			const {
				proj_price_next_round,
				proj_price_change_next_round,
				proj_price_change_next_two_rounds
			} = getProjPrices(next_round, round_in_2_weeks, proj_prices, player.cost);

			const in_20_avg_percent =
				// $FlowFixMe: Flow doesn't understand Math.round10, even when we have add polyfill.
				Math.round10((coach_stats.in_20_avg / player.stats.games_played * 100), 10);

			const out_20_avg_percent =
				// $FlowFixMe: Flow doesn't understand Math.round10, even when we have add polyfill.
				Math.round10((coach_stats.out_20_avg / player.stats.games_played * 100), 10);

			const points_last_round = last_round &&
				 getPlayerScoreByRound(player, last_round.id);
			const points_2_rounds_prior = round_2_prior &&
				getPlayerScoreByRound(player, round_2_prior.id);
			const points_3_rounds_prior = round_3_prior &&
				getPlayerScoreByRound(player, round_3_prior.id);

			// $FlowFixMe: Flow doesn't understand Math.round10, even when we have add polyfill.
			const last_3_avg_vs_proj = Math.round10(player.stats.last_3_avg -
				coach_stats[player_id].last_3_proj_avg, -2);


			const owned_by = _.get(player, "stats.owned_by", 0);
			const bench_selections = _.get(player, "stats.selections_info.bc", 0);
			const start_selections = numeral(owned_by - bench_selections).format("0.00");

			const selections_info = player.stats.selections
				? {
					...player.stats.selections_info,
					start: start_selections
				}
				: null;

			const transfers = _.get(coach_stats, [player.id, "transfers"], {});

			const actual_round_id = _.get(actual_round, "id", 1);

			const traded_in_week = _.get(
				transfers, [actual_round_id, "in"], "-"
			);
			const traded_out_week = _.get(
				transfers, [actual_round_id, "out"], "-"
			);

			const player_bye = _.find(
				bye_rounds,
				round => _.includes(round.bye_squads, player.squad_id)
			);
			const bye_round_id = _.get(player_bye, "id");

			return {
				...accumlator,
				[player_id]: {
					...coach_stats[player_id],
					opp_next_avg,
					opp_2week_avg,
					opp_3week_avg,
					opp_next3_avg,
					venue_avg_one_round_after_current,
					venue_avg_two_rounds_after_current,
					venue_avg_three_round_after_current,
					venue_avg_3_rounds,
					proj_price_next_round,
					proj_price_change_next_round,
					proj_price_change_next_two_rounds,
					in_20_avg_percent,
					out_20_avg_percent,
					points_last_round,
					points_2_rounds_prior,
					points_3_rounds_prior,
					last_3_avg_vs_proj,
					selections_info,
					traded_in_week,
					traded_out_week,
					bye_round_id,
				}
			};
		}
		, {});
	});