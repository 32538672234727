import { get } from "lodash";
import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import { 
	getAfliDIdentityToken, 
	getAfliDIsLinked, 
	getUser, 
	isAflIdTokenPresent 
} from "../../../modules/selectors";
import { isAuthenticated } from "../../../utils/auth";

type IProps = {
	children: ReactNode;
}

export const AFLiDModalContentCheckToken: React.FC<IProps> = ({children}) => {
	const history = useHistory();
	const afliDIsLinked = useSelector(getAfliDIsLinked);
	const afliDIdentityToken = useSelector(getAfliDIdentityToken);
	const isAflIdTokenAvailable = useSelector(isAflIdTokenPresent);
	const user = useSelector(getUser);
	const userRecovered = get(user, 'recovered', 0);
	const isAuth = isAuthenticated();
	const isAuthAndNotRecovered = isAuth && !Boolean(userRecovered);
	useEffect(() => {
		if(isAuth && Boolean(userRecovered)){
			history.push("/game-selection");
		}

		if(afliDIsLinked || isAflIdTokenAvailable) {
			history.push("/login/mini-rego");
		}
		// if(!afliDIsLinked && isAflIdTokenAvailable ){
		// 	history.push("/login/no-match");
		// }
	}, [
		afliDIsLinked,history,
		isAflIdTokenAvailable,
		afliDIdentityToken,
		userRecovered,
		isAuth,
		isAuthAndNotRecovered
	]);

	if(afliDIdentityToken === "") {
		return <Redirect to="/" />;
	}
	return children;
};