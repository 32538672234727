// @flow
import styled from "styled-components";
import { below } from "../../assets/css/media";

export const Aside = styled.aside`
	padding: 0;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;

	${below.desktop`
		display: none !important;
	`};
`;

export default Aside;