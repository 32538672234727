// @flow
import React from "react";
import styled, { css } from "styled-components";
import empty_player_src from "../../assets/images/EmptyPlayer.png";
import PlayerInfoShadow from "../PlayerInfoShadow";
import colors from "../../assets/css/colors";
import { below } from "../../assets/css/media";

const Bars = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	> div {
		height: 15px;
		width: 60px;
		max-width: 60%;
		background-color: ${colors.secondary.lightGrey};
		margin: 2px;

		${below.tablet`
			width: 65%;
			max-width: 40px;
		`};

		${({ is_compressed }) => is_compressed && css`display: none;`}
	}
	> div:first-child {
		width: 100px;
		max-width: 90%;
		display: block;

		${below.tablet`
			width: 90%;
			max-width: 60px;
		`};
	}
`;

const EmptyAvatar = styled.div`
	position: relative;
	height: 60px;
	> img {
		height: 60px;
		width: 58px;
    	object-fit: cover;
	}
`;

const EmptyDetails = styled.div`
	width: 10px;
	${below.tablet`
		width: 25px;
	`};
	> div {
		${below.tablet`
			${({ reverse }) => reverse ? "right": "left"}: 2px;
		`}
	}
    height: 60px;
    margin-${({ reverse }) => reverse ? "right": "left"}: 10px;
	${below.tablet`
		margin-${({ reverse }) => reverse ? "left": "right"}: 2px;
	`};
	${below.phone`
		width: 0px;
		margin-${({ reverse }) => reverse ? "right": "left"}: 8px;
	`}
	position: relative;
`;

const EmptyWrapper = styled.div`
	display: flex;
	flex-direction: ${({ reverse }) => reverse ? "row-reverse" : "row"};
	align-items: center;
	justify-content: flex-start;
	flex-basis: 50%;

	margin: 0 0.5em;
	${below.tablet`
		margin: 0;
	`};


	${Bars} {
		align-items: ${({ reverse }) => reverse ? "flex-end" : "flex-start"};
	}


	${({ is_compressed }) => is_compressed && css`
		${EmptyAvatar} {
			display: none;
		}

		${EmptyDetails} {
			display: none;
		}
	`};
`;

type Props = {
	reverse: boolean,
	is_compressed: boolean,
}

export const EmptyPlayerInfo = ({ reverse = false, is_compressed = false }: Props) => {
	return <EmptyWrapper reverse={reverse} is_compressed={is_compressed}>
		<EmptyAvatar>
			<img src={empty_player_src} alt="Placeholder Player" />
		</EmptyAvatar>
		<EmptyDetails reverse={reverse}>
			<PlayerInfoShadow reverse={reverse} />
		</EmptyDetails>
		<Bars is_compressed={is_compressed}>
			<div />
			<div />
		</Bars>
	</EmptyWrapper>;
};

export default EmptyPlayerInfo;