// @flow
import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as moment from "moment";
import _ from "lodash";

import { 
	TPLeague, 
	TRound, 
	TUser, 
	TRoundsById,
	TPlayersById, 
	TCoachPlayerStatsById,
	TClassicTeamsByID
} from "../../modules/types";
import * as selectors from "../../modules/selectors";

import Fixture from "../Fixtures/Fixture";

import { getLeagueFinalFullNames, isAllTrue } from "../../helpers";
import createFixture from "./utils/createFixture";
import { game_types, getGameType } from "./utils/gameType";

import OpenLeague from "./yourTeamDetails/OpenLeague";
import LeagueYetToStart from "./yourTeamDetails/LeagueYetToStart";
import NotInAnyLeagues from "./yourTeamDetails/NotInAnyLeagues";
import YourSeasonFinished from "./yourTeamDetails/YourSeasonFinished";
import UnableToDetermineDetails from "./yourTeamDetails/UnableToDetermineDetails";
import Loading from "./yourTeamDetails/Loading";
import LeagueLateStart from "./yourTeamDetails/LeagueLateStart";

type Props = {
	game_type: 'classic' | 'draft',
	league: TPLeague,
	league_is_pending: boolean,
	actual_round: TRound,
	ladder_teams_by_id: TClassicTeamsByID,
	ladder_is_pending: boolean,
	rounds_by_id: TRoundsById,
	players_by_id: TPlayersById,
	players_is_pending: boolean,
	coach_players_by_id: TCoachPlayerStatsById,
	has_assistant_coach: boolean,
	my_leagues: Array<Object>,
	show_my_leagues_is_pending: boolean,
	showConfirmModal: Function,
	hideConfirmModal: Function,
	user: TUser,
	rounds_ordered: TRound[];
	display_mobile?: boolean;
};

class YourTeamDetails extends React.Component<Props> {
	get is_open_league() {
		const { league } = this.props;

		return _.get(league, "type") === "open";
	}

	get is_head_to_head_league() {
		const { league } = this.props;

		return _.get(league, "type") === "head_to_head";
	}

	get has_leagues() {
		const { my_leagues } = this.props;

		return _.size(my_leagues) > 0;
	}

	get should_show_loading() {
		const {
			league_is_pending,
			players_is_pending,
			show_my_leagues_is_pending
		} = this.props;

		return league_is_pending
			|| players_is_pending
			|| show_my_leagues_is_pending;
	}

	get should_show_not_in_any_leagues() {
		const { show_my_leagues_is_pending } = this.props;

		return !this.has_leagues && !show_my_leagues_is_pending;
	}

	get should_show_your_league_has_not_started() {
		const { league, actual_round } = this.props;

		return !_.isEmpty(league) &&
			!_.isEmpty(actual_round) &&
			_.get(league, "start_round") > _.get(actual_round, "id");
	}

	get should_show_head_to_head_season_is_finished() {
		
		return this.is_head_to_head_league &&
			!_.isEmpty(this.round_fixtures) &&
			!this.user_in_next_final &&
			!this.user_fixture_team_ids;
	}

	get should_show_classic_open_details() {
		const { league, game_type } = this.props;

		return game_type === game_types.CLASSIC &&
			((league && _.isEmpty(league)) || this.is_open_league);
	}

	get round_fixtures() {
		const { league, actual_round } = this.props;
		return _.get(league, ["fixture", _.get(actual_round, "id")]);
	}

	get next_round_fixtures() {
		const { league, actual_round } = this.props;
		return _.get(league, ["fixture", _.get(actual_round, "id") + 1]);
	}

	get user_in_next_final() {
		const { league } = this.props;
		const user_team_id = parseInt(_.get(league, "team_id"), 10);
		const next_round_check = 
			this.next_round_fixtures && 
				this.next_round_fixtures.find(team_ids =>
					team_ids.includes(user_team_id)
				); 
		return this.is_head_to_head_league 
			&& this.round_fixtures 
			&& next_round_check 
			&& this.is_finals;
	}

	get user_fixture_team_ids() {
		const { league } = this.props;
		const user_team_id = parseInt(_.get(league, "team_id"), 10);

		return this.round_fixtures ?
			this.round_fixtures.find(team_ids =>
				team_ids.includes(user_team_id)
			): null;
	}

	get finals_names() {
		const {
			rounds_ordered,
			league
		} = this.props;

		return getLeagueFinalFullNames(league,  rounds_ordered);
	}

	get is_finals() {
		const { actual_round } = this.props;
		const finals = this.finals_names;
		const actual_round_id = _.get(actual_round, "id");
		return !_.isEmpty(_.get(finals, actual_round_id));
	}

	get league_late_start() {
		const { league } = this.props;

		return _.every([
			!_.isEmpty(league),
			this.league_scheduled,
			this.missed_start
		]);
	}

	get league_scheduled() {
		const { league, game_type } = this.props;
		const league_status = _.get(league, "status");
		const draft_status = _.get(league, "draft_status");

		return game_type === "draft" 
			? draft_status === "scheduled" 
			: league_status === "scheduled";
	}

	get missed_start() {
		const { game_type } = this.props;
		if (game_type === "draft") {
			return this.draft_missed_start;
		}
		else {
			return this.classic_missed_start;
		}

	}

	get classic_missed_start() {
		const { league, actual_round } = this.props;
		const league_start_id = _.get(league, "start_round");
		const actual_round_started = _.get(actual_round, "status", "scheduled") !== "scheduled";
		const actual_round_id = _.get(actual_round, "id");

		if (actual_round_started) {
			return league_start_id <= actual_round_id;
		}
		return league_start_id < actual_round_id;
	}

	get draft_missed_start() {
		const { league } = this.props;
		const draft_start = _.get(league, "draft_start");

		try {
			if (!draft_start) {
				return false;
			}
	
			// Give a 1 min buffer before saying draft has been missed
			const draft_moment = moment(draft_start).add(1, "m");
			const now = moment();
	
			return draft_moment.isBefore(now);
		}
		catch (e) {
			// If anything goes wrong, just return false
			return false;
		}
	}

	get is_commissioner() {
		const { user, league } = this.props;

		const user_id = _.get(user, "id");
		const commish = _.get(league, "commissioner");
		return commish && commish === user_id;
	}

	getFixture(user_fixture_team_ids) {
		const {
			league,
			actual_round,
			ladder_teams_by_id,
			players_by_id,
			game_type,
		} = this.props;

		return createFixture(
			user_fixture_team_ids,
			ladder_teams_by_id,
			actual_round,
			players_by_id,
			league,
			game_type === "classic"
		);
	}


	getMainContent() {
		const {
			league,
			actual_round,
			has_assistant_coach,
			game_type,
			showConfirmModal,
			hideConfirmModal,
			display_mobile
		} = this.props;

		if (this.should_show_not_in_any_leagues) {
			return <NotInAnyLeagues game_type={game_type} />;
		}

		if (isAllTrue([
			this.should_show_your_league_has_not_started, 
			!this.user_fixture_team_ids
		])) {
			return <LeagueYetToStart game_type={game_type} league={league} />;
		}

		if (this.league_late_start) {
			const league_id = _.get(league, "id");
			return <LeagueLateStart
				is_commissioner={this.is_commissioner}
				is_draft={game_type === "draft"}
				league_id={league_id}
				showConfirmModal={showConfirmModal}
				hideConfirmModal={hideConfirmModal}
			/>;
		}

		if (this.should_show_head_to_head_season_is_finished) {
			return <YourSeasonFinished game_type={game_type} league={league} />;
		}

		if (this.should_show_classic_open_details) {
			return <OpenLeague {...this.props} display_mobile={display_mobile} />;
		}

		if (!this.user_fixture_team_ids) {
			return <UnableToDetermineDetails />;
		}

		const fixture = this.getFixture(this.user_fixture_team_ids);

		return <Fixture
			compact
			is_game_bar={!display_mobile}
			game_type={game_type}
			fixture={fixture}
			league={league}
			round={actual_round}
			has_assistant_coach={has_assistant_coach}
		/>;
	}


	render() {

		if (this.should_show_loading) {
			return <Loading />;
		}
		return this.getMainContent();

	}
};

const getGameTypeSelector = (game_type, selectors) => game_type
	? selectors[game_type]
	: null;
const getStateUsingSelector = (state, selector) => selector
	? selector(state)
	: null;

const game_type_ladder_teams_by_id_selectors = {
	[game_types.CLASSIC]: selectors.leaguesClassic.ladder.getById,
	[game_types.DRAFT]: selectors.leagues.ladder.getById
};

const game_type_ladder_is_pending_selectors = {
	[game_types.CLASSIC]: selectors.leaguesClassic.ladder.isPending,
	[game_types.DRAFT]: selectors.leagues.ladder.isPending
};

const game_type_show_my_leagues_is_pending_selectors = {
	[game_types.CLASSIC]: selectors.leaguesClassic.show_my.isPending,
	[game_types.DRAFT]: selectors.leagues.show_my.isPending
};

const game_type_my_leagues_selectors = {
	[game_types.CLASSIC]: selectors.leaguesClassic.show_my.getLeagues,
	[game_types.DRAFT]: selectors.leagues.show_my.getLeagues
};

const mapStateToProps = (state, props) => {
	const { location: { pathname } } = props;
	const game_type = getGameType(pathname);

	// ladder_teams_by_id
	const game_type_ladder_teams_by_id_selector =
		getGameTypeSelector(game_type, game_type_ladder_teams_by_id_selectors);
	const ladder_teams_by_id = getStateUsingSelector(state, game_type_ladder_teams_by_id_selector);

	// ladder_is_pending
	const game_type_ladder_is_pending_selector =
		getGameTypeSelector(game_type, game_type_ladder_is_pending_selectors);
	const ladder_is_pending = getStateUsingSelector(state, game_type_ladder_is_pending_selector);

	// show_my_leagues_is_pending
	const game_type_show_my_leagues_is_pending_selector =
		getGameTypeSelector(game_type, game_type_show_my_leagues_is_pending_selectors);
	const show_my_leagues_is_pending =
		getStateUsingSelector(state, game_type_show_my_leagues_is_pending_selector);

	// my_leagues
	const game_type_my_leagues_selector =
		getGameTypeSelector(game_type, game_type_my_leagues_selectors);
	const my_leagues = getStateUsingSelector(state, game_type_my_leagues_selector);
	const rounds_ordered = selectors.rounds.getRounds(state);

	return {
		game_type,
		ladder_teams_by_id,
		ladder_is_pending,
		players_by_id: selectors.players.getExtendedPlayersById(state),
		players_is_pending: state.players.is_pending,
		has_assistant_coach: Boolean(selectors.getUser(state).assistant_coach),
		show_my_leagues_is_pending,
		my_leagues,
		rounds_ordered,
		user: selectors.getUser(state)
	};
};

export default withRouter(connect(mapStateToProps)(YourTeamDetails));