// @flow

import * as React from "react";
import styled, { css } from "styled-components";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import type { TUser } from "../../modules/types/user";

import * as actions from "../../modules/actions";
import * as selectors from "../../modules/selectors";

import {
	Tooltip,
	Widget,
	WidgetBody,
	WidgetHeader,
	WidgetHeaderCloseBtn,
	WidgetHeaderTitle,
} from "../";
import { getShortName } from "../../helpers/league";

const getTurnNumberColor = ({ is_active }) => is_active ? "#198DE1" : "#1D4073";

const TurnNumber = styled.div`
	color: ${getTurnNumberColor};
	width: 24px;
	min-width: 24px;
	text-align: center;
	position: relative;
	font-family: SourceSansPro;
	font-weight: 600;
	padding-bottom: 1px;
`;

const TeamName = styled.div`
	margin-right: 5px;
	font-weight: 700;
`;

const UserName = styled.div`
	font-weight: 400;
`;

const TurnItemContainer = styled.div`
	margin: 0 0 5px;
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	font-size: 12px;
	font-weight: 700;
	background: #F8F8FA;
	border-bottom: 1px solid white;
	${({ is_active }) => is_active ? css`
		color: #198DE1;
	` : css`
		color: #1D4073;
	`}
`;

const ContentContainer = styled.div`
	padding: 10px 0px 0 0;
	border-radius: 0 0 3px 3px;

	.turn-item:last-child {
		margin-bottom: 0;
	}
`;

const TooltipText = styled.div`
	text-align: left;
`;

type Props = {
	match: {
		params: {
			league_id: string
		}
	},
	fetchWaiverOrder: Function,
	rfa_ids: Array<number>,
	rfa_teams: Object,
	user: TUser,
	onToggle: Function,
};

type State = {
	widget_open: boolean,
}

class WidgetRfaOrderComponent extends React.Component<Props, State> {
	constructor(props: Props, state: Object) {
		super(props, state);

		_.bindAll(this, [
			"onToggle",
		]);
	}

	state = {
		widget_open: true,
	};

	componentDidMount() {
		const { fetchWaiverOrder, match: { params: { league_id } } } = this.props;
		fetchWaiverOrder({ league_id });
	}

	onToggle() {
		this.setState({ widget_open: !this.state.widget_open });
	}

	get tooltip_text() {
		return (
			<TooltipText>
				<p>
					Displays your leagues current restricted free agent priority order.
					Depending on your league settings this will either update by
				</p>
				<ul>
					<li>
						- Reverse the ladder i.e. The Team that is positioned
						last on the ladder gets the first selection.
					</li>
					<li>
						- Last Pick to Bottom, rolling updates when a user claims a
						restricted free agent, they will then be sent to the bottom of the order.
					</li>
				</ul>
			</TooltipText>
		);
	}

	get content() {
		const { user } = this.props;

		return this.props.rfa_ids.map((id: number, index: number) => {
			const team = _.get(this.props.rfa_teams, id);

			if(!team) {
				return null;
			}

			const is_me = _.eq(team.user_id, user.id);

			return (
				<TurnItemContainer is_active={is_me} key={id}>
					<TurnNumber is_active={is_me}>{index + 1}</TurnNumber>
					<TeamName>{team.name}</TeamName>
					<UserName>{getShortName(team)}</UserName>
				</TurnItemContainer>
			);
		});
	}

	render() {
		const { widget_open } = this.state;

		return (
			<Widget>
				<WidgetHeader>
					<WidgetHeaderTitle>
						<span>RFA order</span>
						<Tooltip show_on_left children={this.tooltip_text} />
					</WidgetHeaderTitle>
					<WidgetHeaderCloseBtn open={widget_open} onClick={this.onToggle} />
				</WidgetHeader>
				<WidgetBody open={widget_open}>
					<ContentContainer>
						{ this.content }
					</ContentContainer>
				</WidgetBody>
			</Widget>
		);
	}
}

const mapStateToProps = state => ({
	rfa_ids: state.leagues.waiver_order.ordered_ids,
	rfa_teams: state.leagues.waiver_order.by_id,
	user: selectors.getUser(state),
});

const mapDispatchToProps = {
	fetchWaiverOrder: actions.leagueDraft.fetchWaiverOrder,
};

export const WidgetRfaOrder = connect(
	mapStateToProps,
	mapDispatchToProps,
)(withRouter(WidgetRfaOrderComponent));

export default WidgetRfaOrder;