// @flow 
import React from "react";
import * as _ from "lodash";
import { 
	Exist,
	PlayerActionButton,
	PlayerActionLink,

} from "../../../components";
import {TPlayer, TClassicTeam, TRound} from "../../../modules/types";
import { isMobile } from "../../../utils";
import colors from "../../../assets/css/colors";
import Close from "../../../components/Icons/Close";
import Stop from "../../../components/Icons/Stop";
import Trade from "../../../components/Icons/Trade";
import AddPlayer from "../../../components/Icons/AddPlayer";
import More from "../../../components/Icons/More";
import { classicIsLocked } from "../../../helpers";

const isPlayerLocked = (player: TPlayer, dataObj: {
    actual_round: TRound,
    remaining_salary: number,
    is_team_started: number,
    available_positions: number[]
})  => {
	const {
		actual_round,
		remaining_salary,
		is_team_started,
		available_positions,
	} = dataObj;

	const isLocked = classicIsLocked({
		player,
		remaining_salary,
		is_team_started,
		available_positions,
		actual_round
	});

	if (isLocked) {
		return actual_round.lockout === "full"
			? false
			: player.locked;
	}

	return false;
};

type Props = {
    player: TPlayer,
    is_team_started: number,
    buttons_open: Array<number>,
    team: TClassicTeam,
    handleRemoveButton: Function,
    handleButtons: Function,
    isPlayerLocked: Function,
    getTradeLink: Function,
    actual_round: TRound,
    remaining_salary: number,
    available_positions: number[]
}


const bothTrue = (value1, value2) => {
	return value1 && value2;
};


export const PlayerActionButtons = (props: Props) => {
	const {
		handleRemoveButton,
		player,
		is_team_started,
		buttons_open,
		team,
		handleButtons,
		getTradeLink,
		actual_round,
		remaining_salary,
		available_positions,
	} = props;

	const { week_trades_left } = team;
	const is_mobile = isMobile();
	const closeButton = () => {
		return(
			<PlayerActionButton onClick={_.partial(handleRemoveButton, player.id)}>
				<Close />
			</PlayerActionButton>
		);
	};

	const stopIcon = (
		<div className="buttons-open-mob">
			<PlayerActionButton disabled>
				<Stop color={colors.secondary.paleGrey} />
			</PlayerActionButton>
			<Exist when={is_mobile}>
				{closeButton()}
			</Exist>
		</div>
			
	);

		

	if(bothTrue(is_mobile, !buttons_open.includes(player.id))){
		return(
			<PlayerActionButton onClick={_.partial(handleButtons, player.id)}>
				<More />
			</PlayerActionButton>
		);
	}
	const dataObj = {
		is_team_started,
		actual_round,
		remaining_salary,
		available_positions
	};
	if (
	
		isPlayerLocked(player, dataObj)
			|| week_trades_left <= 0
			|| !is_team_started
	) {
		return stopIcon;
	}

	if(player.is_owned_by_user) {
		return (
			<div className="buttons-open-mob">
				<PlayerActionLink to={getTradeLink(player)}>
					<Trade color={colors.primary.primary} />
				</PlayerActionLink>
				<Exist when={is_mobile}>
					{closeButton()}
				</Exist>
			</div>
				
		);
	}

	return(
		<div className="buttons-open-mob">
			<PlayerActionLink to={getTradeLink(player, "in")}>
				<AddPlayer color={colors.primary.accent} />
			</PlayerActionLink>
			<Exist when={is_mobile}>
				{closeButton()}
			</Exist>
		</div>
	);
};