// @flow
import styled, { css } from "styled-components";
import colors from "../../assets/css/colors";
import { below } from "../../assets/css/media";

const reverseLeft = ({ reverse }) => reverse ? "left" : "right";

export const ActualPoints = styled.div`
	display: block;
	font-weight: ${({ is_game_bar }) => is_game_bar ? "bold;" : "bold;"}
	font-size: ${({ is_game_bar }) => is_game_bar ? "26px;" : "26px;"}

	font-family: SourceSansPro;
	line-height: ${({ is_game_bar }) => is_game_bar ? "1.2" : "36px"};
	color: ${({ loser }) => loser ? colors.secondary.accentGrey : colors.primary.primary};
	margin-bottom: ${({ is_game_bar }) => is_game_bar ? "5px;" : "0;"}
	${below.tablet`
		font-size: 16px;
	`};
`;


const cupColour = ({ is_consolation, is_spoon }) => is_consolation 
	? colors.secondary.accentGrey 
	: (is_spoon 
		? colors.primary.primary
		: colors.primary.accent);

const hideMobile = ({ 
	is_consolation, 
	is_premier, 
	is_spoon, 
	show_mobile 
}) => show_mobile && (is_consolation || is_premier || is_spoon) ? "block" : "none";

export const CupWrapper = styled.div`
	height: 30px;
	width: 30px;

	margin: 0 5px;
	margin-${reverseLeft}: 15px;

	${below.tablet`
		display: ${({ show_mobile }) => show_mobile ? "block" : "none"};

		> svg {
			display: ${hideMobile};
		}

		margin: 0;
	`}

	svg {
		height: 30px;
		width: 30px;

		fill: ${cupColour}

		${({ is_consolation, is_premier, is_spoon }) => 
		!(is_consolation || is_premier || is_spoon) && css`
			display: none;
		` }
	}
`;

export const Rank = styled.div`
	${({ show }) => show ? css`
		color: ${colors.primary.darkGrey};
		display: flex;
		align-items: center;
		justify-content: ${({ reverse }) => reverse ? "flex-end" : "flex-start"};
		border-${reverseLeft}: 2px solid ${colors.secondary.paleGrey};
		box-sizing: border-box;
		height: 100%;
		margin-${reverseLeft}: 10px;
		width: 40px;
		font-size: 15px;
	` : css`
		display: none;
	`}
	${({ compact }) => compact && `
		background: white;
		border-right: none;
		border-left: none;
		justify-content: center;
		color: #1D4073;
		font-size: 14px;
		font-family: SourceSansPro;
		font-weight: 600;
	`}
`;

export const Score = styled.div`
	text-align: center;
	font-family: TitilliumUpright;
	font-weight: bold;
	font-size: 30px;
	color: ${colors.game_bar_score};
	margin: 0 5px;
	display: flex;
	flex-direction: column;

	/* Projected score */
	> span {
		white-space: nowrap;
		color: #B89344;
		font-family: SourceSansPro;
		font-weight: 600;
		font-size: 12px;
	}

	${below.tablet`
		font-size: 10px;
	`};

	${({ is_game_bar }) => is_game_bar && below.tablet`
		display: none;
	`}
`;

export const Wrapper = styled.article`
	width: 100%;
	height: ${({ league_fixture }) => league_fixture ? "90px;" : "60px;"};
	background: #F0F3F7;
	border-radius: 0px;
	padding: ${({ league_fixture }) => league_fixture ? "6px 20px" : "6px 5px"};
	${({ compact }) => compact && `
		padding: 0px;
	`}
	box-sizing: border-box;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	border: 1px solid #CAD2D8;

	> a {
		flex: 0 1 10em;
		min-width: 80px;

		${below.tablet`
			flex-basis: auto;
			min-width: 30px;
		`}
	} 
	
	/* Fixture Team component */
	> div {
		flex: 0 1 ${({ compact }) => compact ? "180px" : "250px"};

		${below.tablet`
			flex: 0 1 90px;
		`}

		${below.phone`
			flex: 0 1 73px;
		`}

		${({ compact }) => !compact && css`
			@media screen and (max-width: 1200px) and (min-width: 1080px) {
				div.fixture-team-avatar {
					height: 38px;
					width: 38px;
				}
			}

			@media screen and (max-width: 850px) and (min-width: 768px) {
				div.fixture-team-avatar {
					height: 38px;
					width: 38px;
				}
			}
		`}
	}

	> ${CupWrapper} {
		flex: 0 0 30px;
		${below.tablet`
			flex: 0 0 20px;
			height: 20px;
			> svg {
				height: 20px;
				width: 20px;
			}
		`}
	}

	> ${Rank} {
		flex: 0 0 40px;
		${below.tablet`
			font-size: 10px;
			flex: 0 0 25px;
			height: 30px;
		`}

		${below.phone`
			display: none;
		`}
	}

	${below.tablet`
		height: 66px;
		padding: 5px 10px 0 px 10px;
		background-color: #F0F3F7;
		padding-bottom: 0px;
	`};
	${below.phone`
		padding: 5px 10px;
	`}

	${({ is_game_bar }) => is_game_bar && `
		border: 1px solid ${colors.secondary.paleGrey};
	`}

	${({ is_game_bar }) => is_game_bar && below.tablet`
		background: transparent;
		border: 0 none;
		justify-content: flex-end;
		> a {
			width: auto;
			margin-top: 0px;
		}
	`}
`;