// @flow
import { createSelector } from 'reselect';

import getLeague from './getLeague';
import getTeamsById from './getTeamsById';

const getCommissionerName = createSelector(
	getLeague,
	getTeamsById,
	(league, teams) => {

		if(!league) {
			return '';
		}

		const team_id = Object.keys(teams).find(id => teams[id].commissioner === league.user_id);

		if(!team_id) {
			return '';
		}

		return `${teams[team_id].firstname} ${teams[team_id].lastname}`;
	}
);

export default getCommissionerName;