import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import queryString from "query-string";

import styled from "styled-components";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { get } from "lodash";
import colors from "../assets/css/colors";

import {
	fetchPlayers,
	showModalForPlayerId,
} from "../modules/actions";
import { 
	ButtonLoadMore,
	Exist,
	Footer, 
	PageTitle, 
	PlayerCost, 
	PlayerDetails, 
	PlayerName, 
	SearchField, 
	SearchInputWrapper, 
	SearchSubmitButton, 
	StandardSidebar, 
	TwoColumnLayout, 
	withClassicPlayerModal 
} from "../components";
import Search from "../components/Icons/Search";
import above, { below } from "../assets/css/media";
import { getPlayers } from "../modules/selectors";
import positions from "../helpers/positions/index";
import PlayerAvatar from "../components/PlayerInfo/playerAvatar";


const StyledTwoColumn = styled(TwoColumnLayout)`
    padding-top: 3em;
    max-width: 1280px;
    margin: 0 auto;
	${below.tablet`
		padding: 10px;
	`};	
`;
const Wrapper = styled.div`

`;

const PageTitleStyled = styled(PageTitle)`
	font-size: 32px;
	display: flex;
	align-items: flex-end;

	svg{
		margin: 0 10px 8px 0;
	}
`;

const StyledSearchInputWrapper = styled(SearchInputWrapper)`
	margin-right: 10px;

	${above.tablet`
		min-width: 200px;
		width: 400px;
		margin-top: 5px;
	`};	

	${below.tablet`
		min-width: 100%;
		max-width: calc(100% - 38px);
		margin-right: 0;
	`};	
`;

const Container = styled.div`
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 5px;
    align-items: center;
    padding: 20px 0;
    div:nth-child(2n){
        background: ${colors};
    }
`;

const PlayerRow = styled.div`
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: ${colors.primary.light};
`;

const FlexColLeft = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 4px 0;
    div,button,p{
        line-height: 1;
        margin-bottom: 0;
    }
`;




const PlayerProfileSearchComponent = () => {
	const history = useHistory();
	const dispatch = useDispatch();

	const { search } = history.location;
	const { q } = queryString.parse(search);
	const [querySearch, setSearch] = useState(q || "");
	const [limit, setLimit] = useState(50);
	
	useEffect(() => {
		dispatch(fetchPlayers());
	}, [dispatch]);
	const players = useSelector(getPlayers);
	const filteredPlayers = () => {
		if(!players.length){
			return [];
		}
		return players.filter(player => {
			const firstName = player.first_name.toLowerCase();
			const lastName = player.last_name.toLowerCase();
			const fullName =  `${firstName} ${lastName}`;
			return firstName.includes(querySearch) 
            ||  lastName.includes(querySearch) 
            || fullName.includes(querySearch);
		} );
	};

	const playersArr = filteredPlayers();

	const limitedPlayers = playersArr.slice(0, limit);

	
	const handleSearchChange = e => {
		setSearch(e.target.value);
	};

	const handleLimitIncrease = () => {
		setLimit(limit + 50);
	};

	const handlePopup = player => {
        
		dispatch(showModalForPlayerId(player.id));
	};




	return (
		<React.Fragment>
        
			
			
			<StyledTwoColumn>
				<Wrapper>
					<PageTitleStyled>
						<Search />
                            Player Profile Search
					</PageTitleStyled>
					<StyledSearchInputWrapper>
						<SearchField
							type="search"
							name="search"
							placeholder="Search for a player"
							onChange={handleSearchChange}
							value={querySearch}
						/>
						<SearchSubmitButton type="submit">
							<Search />
						</SearchSubmitButton>
					</StyledSearchInputWrapper>

					<Container>

						{limitedPlayers.map(player => <PlayerRow key={player.id}>
							<PlayerAvatar player={player} />
							<FlexColLeft>
								<PlayerName
									status={player.status}
									onClick={() => handlePopup(player)}

									is_compressed={false}
								>
									<>
										{player.first_name}<span>{" "}</span>
                                    
										{player.last_name}
									</>
                                        
								</PlayerName>
								<PlayerDetails>
									<span>
										{get(player, 'positions', []).map(position => 
											positions.find(pos => 
												pos.id === position).short_name).join(" / ")}
									</span>
									
								</PlayerDetails>
								<PlayerCost cost={player.cost}/>


							</FlexColLeft>
							
						</PlayerRow> )}
                        
					</Container>
					<Exist when={playersArr.length > limit}>
						<ButtonLoadMore onClick={handleLimitIncrease}>
                            Load More
						</ButtonLoadMore>
					</Exist>
                    
				</Wrapper>
				<StandardSidebar />
					
			</StyledTwoColumn >
			
			<Footer />
		</React.Fragment>
	);
};

const PlayerProfileSearch = withClassicPlayerModal(PlayerProfileSearchComponent);

export default PlayerProfileSearch;