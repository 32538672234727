// @flow
import _ from "lodash";

export const isPending = (state: Object): boolean => state.leaguesClassic.show.is_pending;

export const getError = (state: Object) => state.leaguesClassic.show.error;

export const getLeague = (state: Object) => _.get(state, "leaguesClassic.show.data", {});

export const getLeagueById = (state: Object, id: number) => 
	_.get(state, `leaguesClassic.by_id$[${id}]`);