// @flow
import styled from "styled-components";
import { below } from "../../assets/css/media";
import { FieldPlayers } from "./fieldPlayers";

export const BenchPlayers = styled(FieldPlayers)`
	width: 164px;
	z-index: 2;
	margin-left: auto;
	margin-right: 18px;

	${below.field_view_large`
		width: 144px;
	`};
	${below.field_view_mid`
		width: 134px;
		margin-right: 10px;
	`};

	/* Remove side margins, so cards are correct width */
	> div {
		margin-left: 0;
		margin-right: 0;
	}
`;

export default BenchPlayers;