// @flow
import styled from "styled-components";
import colors from "../../assets/css/colors";

export const TableGridRow = styled.div.attrs({
	role: "row"
})`
	width: 100%;
	display: grid;
	grid-template-columns: ${({ columns }) => columns || "auto"};
	height: ${({ height }) => height || "auto"};
	text-align: center;
	align-items: center;
	background: ${colors.table.base};
	box-sizing: border-box;
	min-height: 120px;
	margin: .7em 0;
	border: 1px solid #ecf1f5;
	border-radius: 3px;
	
	> div:first-child {
		display: flex;
		align-items: center;
		text-align: left;
	}
`;

export default TableGridRow;