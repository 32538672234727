// @flow
import styled from "styled-components";
import { Link } from "react-router-dom";
import colors from "../../assets/css/colors";
import ButtonPrimary from "../ButtonPrimary";

export const ButtonSecondary = styled(ButtonPrimary)``;

ButtonSecondary.displayName = "ButtonSecondary";
ButtonSecondary.defaultProps = {
	...colors.buttons.secondary_button
};

export const ButtonSecondaryLink = styled(ButtonPrimary.withComponent(Link))`
	display: flex;
	align-items: center;
	justify-content: center;
	text-decoration: none;
`;

ButtonSecondaryLink.displayName = `${ButtonSecondaryLink.displayName || "Button"}Link`;

ButtonSecondaryLink.defaultProps = {
	...colors.buttons.secondary_button
};

export default ButtonSecondary;