// @flow
import * as React from "react";
import bindAll from "lodash/bindAll";
import { Link } from "react-router-dom";
import {
	reduxForm,
	Field,
	
} from "redux-form";
import _ from "lodash";
import {
	CardNumberElement,
	CardCVCElement,
	CardExpiryElement,
	injectStripe,
} from "react-stripe-elements";

import styled from "styled-components";

import Cookies from "js-cookie";
import colors from "../../assets/css/colors";
import { below } from "../../assets/css/media";
import { CoachSubscriptionValidate } from "../../utils/FormValidations";
import {
	ButtonPrimary, FormCheckbox,
	FormField,
	FormCircleRadio,
	Label,
	Tooltip,
	ErrorField,
	TextModal,
	ConfirmationModal,
	Exist
} from "../../components";
import Lock from "../../components/Icons/Lock";
import { isAllTrue, isAnyTrue } from "../../helpers";
import CoachList from "./coachList";


const CoachSubscriptionFormStyled = styled.form`
	background: ${colors.form.base};
	padding: 3em;
	box-sizing: border-box;

	${below.tablet`
		padding: 1em;
	`}

	.have-the-permission {
		margin-bottom: 0;
	}

	button {
		margin-bottom: 1em;
	}
	${({ isAccountPage }) => isAccountPage && `
		padding: 0;
		background: none;
		padding-top: 30px;
	`}
`;

const FormItem = styled.div`
	margin: 10px 0;
	width: 440px;
	${below.phone`
		width: 100%;
	`};
	input{
		height: 40px;
	}
`;


const CoachButton = styled(ButtonPrimary)`
	background: ${colors.coach};
	border-color: ${colors.coach};
	width: 200px;
`;

const UpdateButton = styled(ButtonPrimary)`
	width: 200px;
	margin-right: 20px;
`;

const CancelButton = styled(ButtonPrimary)`
	background-color: #CF2223;
	border-color: #CF2223;
	width: 200px;

	:hover:not(:disabled) {
		background-color: #df3a3a;
	}
`;

const ButtonWrapper = styled.div``;

export const TitlePage = styled.h3`
	color: ${colors.coach};
	font-size: 18px;
	margin: 1em 0 .5em 0;
`;

const FormSubtitle = styled.h3`
	color: ${colors.primary.primary};
	font-size: 18px;
	margin-bottom: 20px;
	margin-top: 16px;
`;

export const Description = styled.p`
	font-family: "SourceSansPro";
	font-size: 14px;
	color: ${colors.primary.darkGrey};
	line-height: 20px;
`;

const PlanDescription = styled(Description)`
	margin-bottom: 2em;
	margin-top: -1em;
`;

const CheckboxLabel = styled.p`
	font-size: 14px;
	color: ${colors.primary.darkGrey};
	font-family: "SourceSansPro";
`;

const PrivacyPolicyBlock = styled.div`
	margin: 1em 0 2em 0;
`;

const SubscriptionInfo = styled.div`
	margin: 1em 0 2em 0;

	.subscription-option {
		margin: .5em 0;
	}
`;

const CardNumberElementStyled = styled.div`
	padding-left: 1%;
	border: 1px solid ${colors.form.borderColor};
	background-color: #ffffff;
	width: 99%;
	font-family: "SourceSansPro";
	margin-top: .5em;
`;

const CardDetails = styled.div`
	display: flex;
	margin: 1em 0;

	.card_cvc {
		margin-left: 1em;
	}
`;

const NameWrapper = styled.div`
	display: flex;
`;

const CardExpiryStyled = styled.div`
	min-width: 190px;
	padding-left: 1em;
	border: 1px solid ${colors.form.borderColor};
	background-color: #ffffff;
	margin: 0 0 .5em 0;
	${below.tablet`
		min-width: 100px;
	`}
`;

const CardCVCEStyled = styled.div`
	min-width: 200px;
	padding-left: 1em;
	border: 1px solid ${colors.form.borderColor};
	background-color: #ffffff;
	margin-left: 15px;
	margin: 0 0 .5em 0;
	${below.tablet`
		min-width: 100px;
	`}
`;

const LinkStyled = styled.a`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 200px;
	height: 40px;
	line-height: .9;
	outline: none;
	border: none;
	background: ${colors.buttons.baseColor};
	border: 1px solid ${colors.buttons.baseColor};
	color: #ffffff;
	font-size: 14px;
	letter-spacing: 0.5px;
	cursor: pointer;
	border-radius: 3px;
	text-decoration: none;
	margin: 1em 0;
`;

const public_url = process.env.REACT_APP_PUBLIC_URL || "";

const styledElement = {
	base: {
		color: colors.secondary.accentGrey,
		lineHeight: "40px",
		fontWeight: 400,
		fontSize: "13px",
		"::placeholder": {
			color: colors.secondary.accentGrey,
		}
	},
	invalid: {
		color: colors.warning
	},
	fonts:[
		{
			src: `${public_url}/assets/fonts/
			sourcesans/SourceSansPro-Regular.woff2`,
			family: "SourceSansPro",
			style: "normal",
		}
	]
};

const StyledLock = styled(Lock)`
	& {
		width: 12px!important;
	}
`;

const UpgradeLabel = styled.span`
	padding-left: 8px;
	font-size: 13px;
	color: ${colors.coach};
	text-decoration: underline;
`;

const upgrade_label = <UpgradeLabel>Upgrade</UpgradeLabel>;

const lock_icon = <StyledLock />;

const def_elems = [
	{
		text: "2 Week Trial (Free)",
		value: "trial"
	},
	{
		text: "Monthly Subscription ($4.99)",
		value: "month"
	},
	{
		text: "Annual Subscription ($19.99)",
		value: "season"
	}
];

type Props = {
	submitData: Function,
	handleSubmit: Function,
	reset: Function,
	onCancelSubscription?: Function,
	onSubmit: Function,
	stripe: {
		createToken: Function,
	},
	is_active: boolean,
	cur_plan?: string,
	change: Function,
	isAccountPage?: boolean,
}

type State = {
	start_validation: boolean,
	card_expiry: {
		complete: boolean,
		error: {
			message: string,
		},
	},
	card_cvc: {
		complete: boolean,
		error: {
			message: string,
		},
	},
	card_number: {
		complete: boolean,
		error: {
			message: string,
		},
	},
	card_error: {
		message: string,
	},
	validation_pending: boolean,
	open:boolean,
		values:any,
};

class CoachSubscriptionFormComponent extends React.Component<Props, State> {
	constructor(props, state) {
		super(props, state);

		bindAll(this, [
			"handleErrorModalClose",
		]);
	}

	state = {
		start_validation: false,
		card_error: { message: "" },
		card_number: { complete: false,
			error: { message: "Please enter a valid card number" } },
		card_cvc: { complete: false,
			error: { message: "Please enter a valid CVV number" } },
		card_expiry: { complete: false,
			error: { message: "Please enter a valid expiry date using MM/YYYY" } },
		validation_pending: false,
		open:false,
		values:{},
	};

	componentDidMount() {
		// if (this.props.subscription && this.props.subscription.plan) {
		// 	// this.props.change('plan', cur_plan);
		// }
		const { change, cur_plan } = this.props;
		if (cur_plan) {
			change("plan", cur_plan);
		}
	}

	componentDidUpdate(old_props: Props) {
		const { cur_plan, change } = this.props;
		const { cur_plan: old_plan } = old_props;

		if (cur_plan && cur_plan !== old_plan) {
			change("plan", cur_plan);
		}
	}


	submit(data) {
		const { stripe, submitData } = this.props;
		const { plan, fullname } = data;

		this.setState({validation_pending: true});

		stripe.createToken({ name: fullname }).then(response => {
			if(response.error) {
				if(response.error.type !== "validation_error") {
					this.setState({ card_error: response.error });
				}
			}
			else {
				const { id } = response.token;
				submitData({ plan, token: id });
			}
		}).catch(error => {
			console.log(error);
		  }).finally(() => this.setState({ validation_pending: false }));
	}

	showModal() {
		const { stripe, submitData } = this.props;
		const payload = _.clone(this.state.values);
		const { plan, fullname } = payload;
		return (
			<ConfirmationModal
				header_text="Fantasy Coach Free Trial"
				body_text={`Your Free Trial will automatically turn into a monthly 
				subscription once the 2 week period is over. You will automatically
				 be charged once your subscription rolls over. You will not be charged 
				 if you cancel your Free Trial prior to it turning into a monthly subscription`}
				cancelClick={() =>this.setState({values:{},open:false})}
				closeClick={() => this.setState({values:{},open:false})}
				confirmClick={
					()=>{
						
						this.setState({
							...this.state,validation_pending: true,
						});
				
						stripe.createToken({ name: fullname }).then(response => {
							if(response.error) {
								if(response.error.type !== "validation_error") {
									this.setState({ card_error: response.error });
								}
							}
							else {
								const { id } = response.token;
								submitData({ plan, token: id });
							}
						}).catch(error => {
							console.log(error);
						  }).finally(() => this.setState({ validation_pending: false ,open:false}));
					}

				}
			/>
		);
	}

	

	handleErrorModalClose() {
		this.setState({ card_error: { message: "" } });
	}

	errorModal(message) {
		return (
			<TextModal
				onClick={this.handleErrorModalClose}
				header_text='Error'
				text={message}/>
		);
	}

	showError(error_text) {
		return (<ErrorField>{error_text}</ErrorField>);
	}

	stripeElementChange(element, name) {
		// $FlowFixMe
		this.setState({ [name]: element });
	}

	stripeValidation(element) {
		const { start_validation } = this.state;
		return start_validation && element && element.error && element.error.message
			? this.showError(element.error.message) : null;
	}

	get card_details() {
		const { card_cvc, card_expiry, start_validation } = this.state;
		const { card_error } = this.state;

		return (
			<CardDetails>
				{ card_error && card_error.message ? 
					this.errorModal(card_error.message) : null}		
				<div>
					<NameWrapper>
						<Label
							is_error={start_validation && card_expiry && !card_expiry.complete}
						>
							Card expiry date
						</Label>
					</NameWrapper>
					<CardExpiryStyled>
						<CardExpiryElement
							style={styledElement}
							name="card_expiry"
							placeholder='MM / YY'
							onChange={element => this.stripeElementChange(element, "card_expiry")}
						/>
					</CardExpiryStyled>
					{this.stripeValidation(card_expiry)}
				</div>
				<div className='card_cvc'>
					<NameWrapper>
						<Label is_error={start_validation && card_cvc && !card_cvc.complete}>
							CVV
						</Label>
						<Tooltip
							description='The CVV is the 3 digit
								number located on the back of your card.'
						/>
					</NameWrapper>
					<CardCVCEStyled>
						<CardCVCElement
							style={styledElement}
							name="card_cvc"
							placeholder='XXX'
							onChange={element => this.stripeElementChange(element, "card_cvc")}
						/>
					</CardCVCEStyled>
					{this.stripeValidation(card_cvc)}
				</div>
			</CardDetails>
		);
	}

	get stripe_form() {
		const { card_number, start_validation } = this.state;

		return (
			<div className="checkout">
				<FormItem>
					<NameWrapper>
						<Label is_error={start_validation && card_number && !card_number.complete}>
							Card number
						</Label>
						<Tooltip description='VISA or Mastercard only'/>
					</NameWrapper>
					{this.stripeValidation(card_number)}
				</FormItem>
				<FormItem>
					<CardNumberElementStyled>
						<CardNumberElement
							style={styledElement}
							name="card_number"
							placeholder='XXXX-XXXX-XXXX-XXXX'
							onChange={element => this.stripeElementChange(element, "card_number")}
						/>
					</CardNumberElementStyled>
				</FormItem>
				
				{this.card_details}
			</div>
		);
	}

	getCheckboxElements() {
		const { is_active, cur_plan } = this.props;

		if (is_active && cur_plan) {
			switch (cur_plan) {
				case "season":
					return [
						def_elems[0],
						def_elems[1],
						{
							...def_elems[2],
							icon: lock_icon
						}
					];
				case "month":
					return [
						{
							...def_elems[0],
							disabled: true
						},
						def_elems[1],
						{
							...def_elems[2],
							after: upgrade_label
						}
					];
				default:
					return [
						def_elems[0],
						{
							...def_elems[1],
							after: upgrade_label
						},
						{
							...def_elems[2],
							after: upgrade_label
						}
					];
			}
		}
		return def_elems;
	}

	updateButtons() {
		const cancelSub = this.props.onCancelSubscription
			? this.props.onCancelSubscription
			: () => null;
		return (<React.Fragment>
			<UpdateButton onClick={e => {
				e.preventDefault();
				this.setState({ start_validation: true });
			}}>
				Update Details
			</UpdateButton>
			<CancelButton type="button" onClick={cancelSub}>
				Cancel Fantasy Coach
			</CancelButton>
		</React.Fragment>);
	}

	creditCardForm() {
		const { is_active } = this.props;
		return (
			<React.Fragment>
				{ is_active
					&& <FormSubtitle>Update Your Credit or Debit Card Details</FormSubtitle>
				}
				<FormItem>
					<Field
						label='Cardholder’s name'
						type='text'
						name='fullname'
						placeholder='Fullname'
						component={FormField}
						tooltip={{
							name: "",
							description: "Use the same name as displayed on your credit card"
						}}
					/>
				</FormItem>
				

				{this.stripe_form}

				<Field
					label={
						<CheckboxLabel>
							I am over 18 or have the permission of my parent/guardian*
						</CheckboxLabel>
					}
					name='have_the_permission'
					type='checkbox'
					component={FormCheckbox}
					parse={value => value ? 1 : 0}
				/>

				<PrivacyPolicyBlock>
					<Field
						label={
							<CheckboxLabel>
								I have read
								and accept the&nbsp;
								<a href="/account/terms-and-conditions" target='_blank'>
								Terms & Conditions*</a>
							</CheckboxLabel>
						}
						name='privacy_policy'
						type='checkbox'
						component={FormCheckbox}
						parse={value => value ? 1 : 0}
					/>
				</PrivacyPolicyBlock>

				<ButtonWrapper>
					{
						is_active
							? this.updateButtons()
							: (<CoachButton
								disabled={this.state.validation_pending}
								onClick={() => {
									this.setState({ start_validation: true });
								}}>
								Purchase Fantasy Coach
							</CoachButton>)
					}
				</ButtonWrapper>
			</React.Fragment>
		);
	}

	getSkipLink() {
		if (!this.props.is_active) {

			const first_attempt_not_logged_user =
				Cookies.get("saved_first_attempt_not_logged_user_after_registration");

			Cookies.remove("saved_first_attempt_not_logged_user_after_registration");

			return (<p>
				<LinkStyled href={first_attempt_not_logged_user || "/game-selection"}>
					Skip this for now
				</LinkStyled>
			</p>);
		}
	}

	getPlanDescription() {
		const { cur_plan } = this.props;
		let desc_text = null;

		if (cur_plan === "season") {
			desc_text = <React.Fragment>
				You have purchased an Annual subscription to AFL Fantasy Coach
				for the current season. This will not rollover into the next
				season. If you would like to enquire further about your annual
				subscription, please <Link to="/contact-us">Contact Us</Link>.
			</React.Fragment>;
		}
		else if (cur_plan === "month") {
			desc_text = "You have a Monthly subscription to AFL Fantasy Coach "
				+ "for the current season. This subscription will continue "
				+ "throughout the remainder of the season until you cancel, "
				+ "upgrade to an annual subscription or the season completes. "
				+ "This subscription will be cancelled automatically after the "
				+ "completion of the regular season.";
		}

		else if (cur_plan === "trial") {
			desc_text = "You have a 2 Week Trial subscription to AFL Fantasy "
				+ "Coach for the current season. This subscription will "
				+ "continue through your trial period giving you full access "
				+ "to the Fantasy Coach features on both the website and the "
				+ "apps. Once your trial period expires, your Fantasy Coach "
				+ "subscription will automatically begin a monthly subscription. "
				+ "You can leave this to become a monthly subscription, upgrade "
				+ "it to an annual subscription or cancel it before the trial "
				+ "period completes.";
		}

		if (desc_text) {
			return <PlanDescription>{desc_text}</PlanDescription>;
		}
		return null;
	}

	getTitle() {
		return this.props.is_active
			? "You are currently subscribed to Fantasy Coach"
			: "Subscribe to Fantasy Coach";
	}

	preSubmit (data){
		this.setState({values:data,open:true});
	}

	render() {
		const { handleSubmit, is_active, cur_plan, isAccountPage } = this.props;
		const elements = this.getCheckboxElements();
		
		if(isAnyTrue([
			!is_active,
			(isAllTrue([
				Boolean(cur_plan),
				cur_plan !== "season"
			]) )
		]) ){
			return (
				<div>
					<CoachSubscriptionFormStyled
						onSubmit={handleSubmit(data => {
							data.plan ==='trial' ? 
								this.preSubmit(data) :
								this.submit(data);
						})}
						disabled={this.state.validation_pending}
						isAccountPage={isAccountPage}
					>
						<TitlePage>{ this.getTitle() }</TitlePage>
						<div>
							<Description>
							Coach is your premium Fantasy product fully
							integrated across the site and app to provide you
							with the extra stats and info needed to improve your team!
							It gives you the best chance at winning
							your league and possibly some great prizes!
							</Description>
						</div>
						<CoachList isAccountPage={isAccountPage} />
						<SubscriptionInfo>
							<FormCircleRadio
								name='plan'
								className='subscription-option'
								elements={elements}
								disabled={is_active && cur_plan === "season"}
							/>
						</SubscriptionInfo>
						<Exist when={this.state.open}>
							{this.showModal()}
						</Exist>
						{ this.getPlanDescription() }
						{this.creditCardForm()}
						{!isAccountPage && 
						this.getSkipLink()
						}

					</CoachSubscriptionFormStyled>
				</div>
			);
		}
		return(
			<div>
				<CoachSubscriptionFormStyled
					disabled={this.state.validation_pending}
					isAccountPage={isAccountPage}
				>
					<TitlePage>{ this.getTitle() }</TitlePage>
					<div>
						<Description>
							Coach is your premium Fantasy product fully
							integrated across the site and app to provide you
							with the extra stats and info needed to improve your team!
							It gives you the best chance at winning
							your league and possibly some great prizes!
						</Description>
					</div>
					<CoachList isAccountPage={isAccountPage} />
					<SubscriptionInfo>
						<FormCircleRadio
							name='plan'
							className='subscription-option'
							elements={elements}
							disabled={is_active && cur_plan === "season"}
						/>
					</SubscriptionInfo>
					<Exist when={this.state.open}>
						{this.showModal()}
					</Exist>
					{ this.getPlanDescription() }
					{this.updateButtons()}
					{!isAccountPage && 
						this.getSkipLink()
					}

				</CoachSubscriptionFormStyled>
			</div>
		);
		
	}
}

export const CoachSubscriptionForm = reduxForm(
	{	
		form:  "coach_subscription_form",
		initialValues: {
			plan: "season",
		},
		validate: CoachSubscriptionValidate,
	}
)(CoachSubscriptionFormComponent);



export default injectStripe(CoachSubscriptionForm);