// @flow
import * as React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import _ from "lodash";
import { compose } from "redux";
import * as selectors from "../../../modules/selectors";
import * as actions from "../../../modules/actions";
import { below, above } from "../../../assets/css/media";
import { TBody, THead, Tr, Th, Td } from "../../../components/TableX";
import {
	AdsContainer,
	ButtonAlert,
	ConfirmationModal,
	PageTitle,
	Footer,
	PageContentWrapper,
	StandardSidebar,
	TwoColumnLayout,
	CancelButton,
	ButtonLoadMore,
	LeagueGameBar,
} from "../../../components";
import Ad from "../../../components/Ad";
import TeamUserAvatar from "../../../components/Team/TeamUserAvatar";
import type { TPLeague, TClassicTeamsByID, TLadderTeam } from "../../../modules/types";

import colors from "../../../assets/css/colors";
import withErrorModal from "../../../components/utils/withErrorModal";
import { isAllTrue } from "../../../helpers";

const TeamUserAvatarStyled = styled(TeamUserAvatar)`
	width: 60px;
	height: 60px;
	padding: 0;
`;

const OrderDiv = styled.div`
	margin: 10px;
`;

const LeaveButton = styled(ButtonAlert)`
	font-weight: 300;
	font-size: 14px;
	width: 50%;
	${below.tablet`
		width: 100%;
	`}
`;

const RemoveButton = styled(CancelButton)`
	font-weight: 300;
	font-size: 14px;
	width: 50%;
	${below.tablet`
		width: 100%;
	`}
`;

const TeamRow = styled(Tr)`
	height: 80px;
`;

const ButtonTh = styled(Th)`
	font-size: 12px;
	line-height: 10px;
	text-align: center;
	width: 25%;
`;

const ThAvatar = styled(ButtonTh)`
	width: 80px;
	padding: 0;
`;

const TeamsBody = styled(TBody)`
	${Tr} {
		border-bottom: 1px solid #CAD2D8;
	}
`;

const TdAvatar  = styled(Td)`
	width: 80px;
	padding: 0;
`;

const TdButton  = styled(Td)`
	width: 25%;
	padding: 0;
`;


export const Table = styled.table`
	width: 100%;
`;

const TeamName = styled.div`
	font-size: 20px;
	line-height: 1.125;
	text-align: left;
	color: ${colors.primary.primary};
	text-overflow: ellipsis;
	overflow: hidden;
	max-width: 60px;
	font-weight: 400;

	${below.tablet`
		font-size: 14px;
	`}

	${above.phone`
		max-width: 150px;
	`}

	${above.tablet`
		max-width: 100px;
	`}

	${above.desktop`
		max-width: 200px;
	`}
`;

const UserName = styled.div`
	font-size: 16px;
	line-height: 1.28;
	text-align: left;
	color: ${colors.secondary.accentGrey};
	text-overflow: ellipsis;
	overflow: hidden;
	max-width: 60px;
	font-weight: 400;

	${above.phone`
		max-width: 150px;
	`}

	${above.tablet`
		max-width: 100px;
	`}

	${above.desktop`
		max-width: 200px;
	`}

	${below.phone`
		display: none;
	`}
`;

const Commish = styled(UserName)`
	font-size: 14px;
	text-align: center;
`;

type Props = {
	selected_league_id: number,
	is_pending_show: boolean,
	user_id: number,
	league: TPLeague,
	teams: Array<TLadderTeam>,
	teams_by_id: TClassicTeamsByID,
	error?: string,
	match: {
		params: {
			league_id: string
		}
	},
	leave_league: {
		error: any,
		is_pending: boolean
	},
	remove_team: {
		error: any,
		is_pending: boolean
	},
	showClassicLeague: Function,
	leaveClassicLeague: Function,
	removeTeamFromLeague: Function,
	showTeams: Function,
	getModalElement: Function,
	show_modal: boolean,
	hideErrorModal: Function,
	showErrorModal: Function,
	offset: number,
	clearTeams: Function,
}

const OFF_GROWTH = 20;

type State = {
	show_leave_modal: boolean,
	show_remove_modal: boolean,
	left_the_league: boolean,
	leave_league_success: boolean,
	remove_the_team: boolean,
	remove_id: number,
};

class LeagueDraftOrderComponent extends React.Component<Props, State> {

	constructor(props, state) {
		super(props, state);

		_.bindAll(this, [
			"leaveThisLeague",
			"removeFromLeague",
			"confirmLeaveleague",
			"confirmRemoveTeamleague",
			"closeLeaveModal",
			"closeRemoveModal",
			"loadMore"
		]);
	}

	state = {
		show_remove_modal: false,
		show_leave_modal: false,
		left_the_league: false,
		remove_the_team: false,
		leave_league_success: false,
		remove_id: 0,
	};

	componentDidMount() {
		const { selected_league_id, clearTeams } = this.props;
		this.fetchLeague();
		clearTeams();

		this.props.showClassicLeague({ id: selected_league_id });

		this.fetchTeams();
	}

	componentDidUpdate(prev_props: Props) {
		const {
			remove_team,
			leave_league,
			showErrorModal
		} = this.props;
		const { remove_team: old_remove, leave_league: old_leave } = prev_props;

		if (isAllTrue([!leave_league.is_pending, old_leave.is_pending, !leave_league.error])) {
			if (leave_league.error) {
				showErrorModal({
					modal_header: "Unable to Leave League",
					modal_body: leave_league.error
				});
			}
			else {
				window.location.replace("/classic/leagues/join");
			}
		}

		if (!remove_team.is_pending && old_remove.is_pending) {
			if (remove_team.error) {
				showErrorModal({
					modal_header: "Unable to Remove Team",
					modal_body: remove_team.error
				});
			}

			window.location.reload();
		}
	}

	fetchTeams(offset = 0) {
		const {
			showTeams,
			selected_league_id,
		} = this.props;

		showTeams({
			league_id: selected_league_id,
			offset,
		});
	}

	loadMore() {
		const { offset } = this.props;

		this.fetchTeams(offset + OFF_GROWTH);
	}

	confirmLeaveleague() {
		const { leaveClassicLeague, league: { id } } = this.props;
		leaveClassicLeague({ league_id: id });
		this.setState({ left_the_league: true, show_leave_modal: false });

	}

	confirmRemoveTeamleague() {
		const { removeTeamFromLeague, league: { id } } = this.props;
		const team_id = this.state.remove_id;
		removeTeamFromLeague({ league_id: id,  team_id: team_id });
		this.setState({ remove_the_team: true, show_remove_modal: false });
	}

	leaveThisLeague() {
		this.setState({ show_leave_modal: true });
	}

	removeFromLeague({ currentTarget }) {
		const { id } = currentTarget.dataset;
		this.setState({ show_remove_modal: true, remove_id: parseInt(id, 10) });
	}


	closeLeaveModal() {
		this.setState({ show_leave_modal: false });
	}

	closeRemoveModal() {
		this.setState({ show_remove_modal: false });
	}

	get leave_modal() {
		return (
			<ConfirmationModal
				header_text='Leave League?'
				body_text='Are you sure you want to leave this league ?'
				confirmClick={this.confirmLeaveleague}
				cancelClick={this.closeLeaveModal}
				closeClick={this.closeLeaveModal}
			/>
		);
	}

	get remove_modal() {
		return (
			<ConfirmationModal
				header_text='Remove Team?'
				body_text='Are you sure you want to remove this user from league ?'
				confirmClick={this.confirmRemoveTeamleague}
				cancelClick={this.closeRemoveModal}
				closeClick={this.closeRemoveModal}
			/>
		);
	}

	fetchLeague() {
		const { showClassicLeague, selected_league_id } = this.props;
		showClassicLeague({
			id: selected_league_id
		});
	}


	get table_header() {
		const {
			user_id,
			league
		} = this.props;

		return (
			<THead>
				<Tr>
					<ThAvatar>
						Team / Owner
					</ThAvatar>
					<Th />
					<ButtonTh>
						{user_id === league.commissioner ? "Remove From League" : null}
					</ButtonTh>
				</Tr>
			</THead>
		);
	}

	commissionerButton(team) {
		const {
			user_id,
			league
		} = this.props;
		if (user_id !== league.commissioner && team.user_id === league.commissioner) {
			return (
				<TdButton>
					<Commish>Commisioner</Commish>
				</TdButton>
			);
		}
		return <Td />;
	};

	 removeOrLeaveButtons(team) {
		const {
			user_id,
			league
		} = this.props;
		if (user_id !== team.user_id
			&& user_id === league.commissioner && league.status === "scheduled") {
			return (
				<TdButton>
					<RemoveButton
						data-id={team.id}
						onClick={this.removeFromLeague}>
						Remove
					</RemoveButton>
				</TdButton>
			);
		}
		else if (user_id === team.user_id && league.status === "scheduled") {
			return (
				<TdButton>
					<LeaveButton
						onClick={this.leaveThisLeague}>
						Leave league
					</LeaveButton>
				</TdButton>
			);
		}
		return (
			this.commissionerButton(team)
		);
	};

	get table_body() {
		const {
			teams,
		} = this.props;

		const teams_to_use = [...new Map(teams.map(item =>
			[item["id"], item])).values()];

		const cells = _.map(teams_to_use, (team, index) => {
			const draft_order = index;
			const is_ignored = !(draft_order + 1);
			return (
				<TeamRow
					className={is_ignored ? "is-ignored" : null}
					key={team.id}
				>
					<TdAvatar>
						<OrderDiv>
							<TeamUserAvatarStyled
								user_id={team.user_id}
								avatar_version={team.avatar_version}
								firstname={team.firstname}
								lastname={team.lastname}
							/>
						</OrderDiv>
					</TdAvatar>
					<Td>
						<TeamName>{team.name}</TeamName>
						<UserName>{team.firstname} {team.lastname}</UserName>
					</Td>
					{this.removeOrLeaveButtons(team)}

				</TeamRow>
			);
		});
		return <TeamsBody>{ cells }</TeamsBody>;
	}

	render() {
		const {
			league,
			getModalElement,
			hideErrorModal,
			offset,
			teams
		} = this.props;

		const {
			show_leave_modal,
			show_remove_modal,
		} = this.state;

		const show_load_more = _.size(teams) >= offset + OFF_GROWTH;

		return (
			<React.Fragment>
				<LeagueGameBar no_fetch={true} />
				<AdsContainer>
					<Ad />
				</AdsContainer>
				{show_leave_modal ? this.leave_modal : null}
				{show_remove_modal ? this.remove_modal : null}
				{ getModalElement(hideErrorModal) }
				<PageContentWrapper>
					<PageTitle>
						Teams in {league.name}
					</PageTitle>
					<TwoColumnLayout>
						<div>
							<Table>
								{ this.table_header }
								{ this.table_body }
							</Table>
							{
								show_load_more &&
								<ButtonLoadMore onClick={this.loadMore}>Load More</ButtonLoadMore>
							}
						</div>
						<StandardSidebar />
					</TwoColumnLayout>
				</PageContentWrapper>
				<Footer />
			</React.Fragment>
		);
	}
}
const mapStateToProps = (state, props) => {
	const { leaguesClassic: { teams_by_id } } = state;

	const selected_league_id = props.match.params.league_id;
	// const league_teams = 
	// _.get(state, ['leaguesClassic', 'by_id', selected_league_id, 'teams'], []);
	const teams = 
		Object.keys(teams_by_id).map(team_id => teams_by_id[team_id]);

	return {
		selected_league_id,
		league: selectors.leaguesClassic.getLeague(state, props),
		is_pending_show: selectors.leaguesClassic.show.isPending(state),
		user_id: selectors.getUser(state).id,
		leave_league: state.leaguesClassic.leave,
		remove_team: state.leaguesClassic.remove_team,
		teams_by_id,
		teams,
		offset: state.leaguesClassic.show_teams.offset
	};
};

const mapDispatchToProps = {
	showClassicLeague: actions.showClassicLeague,
	leaveClassicLeague: actions.leaveClassicLeague,
	removeTeamFromLeague: actions.removeTeamFromLeague,
	showTeams: actions.showTeamsClassic,
	clearTeams: actions.clearTeamsClassic
};

export const ClassicLeagueMembers = compose(
	withErrorModal,
	connect(
		mapStateToProps,
		mapDispatchToProps,
	)
)(LeagueDraftOrderComponent);

export default ClassicLeagueMembers;