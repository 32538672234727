/* eslint max-len: 0 */
// @flow
import React from "react";
import SvgIconContainer from "../SvgIconContainer";

import colors from "../../assets/css/colors";

export default (
	{
		unit = "em",
		...rest
	}: Object = {}) => (
	<SvgIconContainer viewBox="0 0 24 24" color={colors.primary.primary} {...rest} unit={unit}>
		<title>Trade</title>
		<path fill="#1D4073" d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0Zm3.167 6.18v1.96c0 .464-.366.84-.817.84h-4.9a.829.829 0 0 1-.817-.84V6.18C7.731 6.18 7 6.932 7 7.86v8.96c0 .928.731 1.68 1.633 1.68h6.534a1.61 1.61 0 0 0 1.155-.492c.306-.315.478-.742.478-1.188V7.86c0-.928-.731-1.68-1.633-1.68Zm-5.132 6.753a.138.138 0 0 1 .159-.014c.05.029.078.088.07.147v.954h2.044c.076 0 .136.063.136.14V15c0 .077-.06.14-.136.14h-2.044v.997a.145.145 0 0 1-.07.103.138.138 0 0 1-.159-.014l-1.622-1.514a.193.193 0 0 1 0-.266Zm3.567-2.253a.14.14 0 0 1 .16.014l1.614 1.514c.071.073.071.192 0 .266l-1.614 1.513a.14.14 0 0 1-.16.014.148.148 0 0 1-.071-.147V12.9h-2.04a.138.138 0 0 1-.135-.14v-.84c0-.077.06-.14.136-.14h2.039v-.997a.147.147 0 0 1 .071-.103ZM11.9 4.5c-.69 0-1.305.45-1.533 1.12h-.373a.276.276 0 0 0-.272.28v.28h-.544v1.68c0 .31.244.56.544.56h4.356c.3 0 .544-.25.544-.56V6.18h-.544V5.9a.276.276 0 0 0-.272-.28h-.373A1.627 1.627 0 0 0 11.9 4.5Zm0 .84c.451 0 .817.376.817.84 0 .464-.366.84-.817.84a.829.829 0 0 1-.817-.84c0-.464.366-.84.817-.84Z"/>
	</SvgIconContainer>
);