// @flow
import React from "react";

import _ from "lodash";
import { isMobile } from "../../../../utils";
import {
	DraftOrder,
	DragIcon,
	IgnoreCheckbox,
	PlayerInfo,
	TdStat,
	TdYourTeamExtended,
	DeluxOnlyLabel,
	Expander,
	// GoToApp,
} from "../../../../components";


import ArrowUpSmall from "../../../../components/Icons/ArrowUpSmall";
import ArrowDownSmall from "../../../../components/Icons/ArrowDownSmall";

import { TBody, Td, Tr } from "../../../../components/TableX";

import type {TDraftUserTeam} from "../../../../modules/types";

import colors from "../../../../assets/css/colors";
import Lock from "../../../../components/Icons/Lock";

import {
	PreDraftSwitchButton,
	PreDraftSwitchButtonsWrap,
	StyledTrRow,
	AbsoluteDiv,
	TdPlayerSticky,
	TdKeptBy,
	KeptByContent,
	TdShowAvatar,
	TdEmpty,
	FcLink,
} from "./PreDraftStyles";


const isEven = (index: number) => (index + 1) % 2 === 0;

type Props =  {
	is_pre_draft_list_successfully_saved?: boolean,
	resetQueue: Function,
	saveQueue: Function,
	showConfirmModal: Function,
	hideConfirmModal: Function,
	onResetAllFilters: Function,
	all_stats: Object,
	userTeam: TDraftUserTeam,
	addKeeper: Function,
	removeKeeper: Function,
    onChangePlayerOrder: Function,
    is_pending_players: boolean,
    pre_draft_list_size: number,
    isActiveTab: (arg: string) => string,
    show_kept_players: boolean,
    players: Object,
    updatePlayerPreDraftListOrder: Function,
    table_keys: Object,
    is_compressed: boolean,
    is_dnd_allowed: boolean,
    onDrop: Function,
    onDragEnd: Function,
    onDragLeave: Function,
    onDragOver: Function,
    onDragEnter: Function,
    onDragStart: Function,
    onEnableDrag: Function,
    onDisableDrag: Function,
    coachSelectValue: Object,
    league: Object,
    onClickIgnore: Function,
    getMobilePlayerActions: Function,
    has_assistant_coach: boolean,
    toggleFantasyCoachModal: Function,
    getPlayerSeasonStat: Function,
	teams_by_id: Object,
}

type StatCell = {
    is_fc: boolean,
    player: Object,
    playerAllStats: Object,
    prop: string,
    is_delux: boolean,
    is_pro: boolean,
}


export class PreDraftTableBody extends React.Component<Props> {

	constructor(props: Props) {
		super(props);

		

		_.bindAll(this, [
			
		]);
	}

	statCell({ is_fc, player, playerAllStats, prop, is_delux, is_pro }:StatCell ) {
		if(!this.props){
			return null;
		}
		const { has_assistant_coach, coachSelectValue } = this.props;

		if (is_pro && !is_delux) {
			return (
				<React.Fragment>
					<DeluxOnlyLabel>Deluxe only</DeluxOnlyLabel>
				</React.Fragment>
			);
		}

		return (
			is_fc && !has_assistant_coach ?
				(<FcLink onClick={this.props.toggleFantasyCoachModal}>
					<Lock color={colors.coach} />
				</FcLink>):	coachSelectValue === "custom-stats" ?
					this.props.getPlayerSeasonStat(player, playerAllStats, prop) :
					_.get(player, prop, "--")
		);
	}

	mobileActions(player: Object, is_ignored: boolean) {
		const { getMobilePlayerActions, is_compressed, show_kept_players } = this.props;
		const is_mobile = isMobile();
		return is_mobile ? 
			getMobilePlayerActions(player) :
			!show_kept_players ? 
				<Td is_compressed={is_compressed} className="last-right-td">
					<IgnoreCheckbox
						onChange={this.props.onClickIgnore}
						checked={is_ignored}
						name={`ignore-${player.id}`}
						pre_draft={true}
					/>
				</Td> : <TdEmpty />;
        
    
	}

	getTableMessage(message: string) {
		if(_.isEmpty(this.props.table_keys)){
			return null;
		}
		const size_of_predefined_columns = 3,
			total_columns_size = _.size(this.props.table_keys) + size_of_predefined_columns;

		return (
			<TBody>
				<Tr>
					<Td align="center" colSpan={total_columns_size}>
						<Expander>{message}</Expander>
					</Td>
				</Tr>
			</TBody>
		);
	}

	findKeptPlayersOwner(playerID: number) {
		const { teams_by_id } = this.props;
		const teamsArr = Object.keys(teams_by_id).map(teamID => teams_by_id[teamID]);
		const ownerTeam = teamsArr.find(team => {
			const team_keepers =	_.get(team, "keepers") !== null ? _.get(team, "keepers") : [];
			const this_keepers_parse = typeof team_keepers === "string" 
				? JSON.parse(team_keepers) 
				: team_keepers;
			return this_keepers_parse.find(player => player.id === playerID);
		});
		return _.get(ownerTeam, "name", "");
	}

	ownerTeam(player: Object) {
		const {show_kept_players} = this.props;
		return show_kept_players ? 
			this.findKeptPlayersOwner(player.id) : null;
	}

	getStickySection(player: Object, is_ignored: boolean, is_dnd_enabled: boolean) {
		const {
			show_kept_players,
			is_compressed,
			onEnableDrag,
			onDisableDrag,
			onChangePlayerOrder,
			pre_draft_list_size, 
			updatePlayerPreDraftListOrder,
		} = this.props;
		const owner_team = this.ownerTeam(player);
		const is_mobile = isMobile();

		const {draft_order} = player;

		return show_kept_players ? 
			<TdKeptBy>
				<KeptByContent>
                Kept by: <span>{owner_team}</span>
				</KeptByContent>
			</TdKeptBy>
			: 
			<React.Fragment>
				{!is_mobile &&
                <Td is_compressed={is_compressed} className="dnd">
                	<DragIcon
                		onMouseEnter={onEnableDrag}
                		onMouseLeave={onDisableDrag}
                		disabled={!is_dnd_enabled}
                	/>
                </Td>
				}
    
				<TdYourTeamExtended 
					is_compressed={is_compressed} 
					alignText="right"
				>
					<DraftOrder
						disabled={is_ignored}
						onChange={onChangePlayerOrder}
						name="draft_order"
						data-id={player.id}
						maxValue={pre_draft_list_size}
						value={draft_order || "-"}
						is_compressed={is_compressed}
					/>
					{!is_mobile && 
            <PreDraftSwitchButtonsWrap is_compressed={is_compressed}>
            	<PreDraftSwitchButton
            		up={true}
            		disabled={is_ignored || draft_order === 1}
            		data-order={parseInt(draft_order, 10) - 1}
            		data-id={player.id}
            		onClick={updatePlayerPreDraftListOrder}
            		is_compressed={is_compressed}
            	>
            		<ArrowUpSmall />
            	</PreDraftSwitchButton>
            	<PreDraftSwitchButton
            		disabled={is_ignored || pre_draft_list_size === draft_order}
            		data-order={parseInt(draft_order, 10) + 1}
            		data-id={player.id}
            		onClick={updatePlayerPreDraftListOrder}
            		is_compressed={is_compressed}
            	>
            		<ArrowDownSmall />
            	</PreDraftSwitchButton>
            </PreDraftSwitchButtonsWrap>
					}
    
				</TdYourTeamExtended>

			</React.Fragment>;
        
	}

	get table_body() {
		const { 
			is_pending_players, 	
			isActiveTab, 
		} = this.props;

		if(is_pending_players) {
			return this.getTableMessage("Loading, please wait...");
		}

		const players_list = this.props.players;

		if(!is_pending_players && _.isEmpty(players_list)) {
			return this.getTableMessage(
				"Sorry, there are no players who match this search query. " +
				"Please adjust your filters above and try again."
			);
		}

       

		const {
			table_keys,
			is_compressed,
			is_dnd_allowed,
			onDrop,
			onDragEnd,
			onDragLeave,
			onDragOver,
			onDragEnter,
			onDragStart,
			coachSelectValue,
			all_stats,
			league
		} = this.props;

		const is_delux = league.subscription === "full";
		const scrollable = coachSelectValue === "custom-stats";
		if(_.isEmpty(table_keys)){
			return null;
		}

		const cells = players_list.map((player, index) => {
			const { draft_order } = player;
			const is_ignored = !draft_order;
			const is_dnd_enabled = is_dnd_allowed && !is_ignored;
			const playerAllStats = _.get(all_stats, `${player.id}`, {});
			const is_even = isEven(index);
			return (
				<StyledTrRow
					is_odd={!is_even}
					index={index}
					draggable="false"
					data-drag-and-drop-enabled={is_dnd_enabled}
					data-player-id={player.id}
					onDrop={onDrop}
					onDragEnd={onDragEnd}
					onDragEnter={onDragEnter}
					onDragLeave={onDragLeave}
					onDragOver={onDragOver}
					onDragStart={onDragStart}
					className={is_ignored ? "is-ignored" : null}
					key={player.id}
					is_compressed={is_compressed}
					scrollable={scrollable}
				>	
					
					<AbsoluteDiv is_even={is_even}>
						<TdPlayerSticky>
							{this.getStickySection(player, is_ignored, is_dnd_enabled)}
						
						
							<TdShowAvatar is_compressed={is_compressed} pre_draft={true} 
								player_info={true} className="player-info-stat">
								<PlayerInfo
									className="player-info"
									player={player}
									is_compressed={is_compressed}
									is_classic={false}
									pre_draft={true}
								/>
							</TdShowAvatar>

						</TdPlayerSticky>
						
					</AbsoluteDiv>
				
					{table_keys && table_keys.map(({ key, prop, is_fc, is_pro }) => {
						return (
							<TdStat is_compressed={is_compressed}
								className={`player-stats ${isActiveTab(prop)}`}
								key={key}
								is_fc={is_fc}
							>

								{this.statCell({
									is_fc, player, playerAllStats, prop, is_delux, is_pro
								})}
							</TdStat>);
					})}
                   
					{this.mobileActions(player, is_ignored)}
				
				</StyledTrRow>
			);
		});

		return <TBody>{ cells }</TBody>;
	}

	render(){
		return (
			<React.Fragment>
				{this.table_body}
			</React.Fragment>
		);
	}
}




export default PreDraftTableBody;