// @flow
import * as React from "react";
import styled from "styled-components";

const BorderedIconStyled = styled.div`
	border: ${({ hide_border }) => hide_border ? "none" : "1px solid currentColor"};
	background: ${({ background }) => background || "transparent"};
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	width: ${({ size }) => size ? `${size}em` : "2em"};
	height: ${({ size }) => size ? `${size}em` : "2em"};
	transition: all 0.2s;
	box-sizing: border-box;

	margin-right: ${({ right_margin }) => right_margin ? "1em" : "0"};

	&:hover {
		background: ${({ hover }) => hover || null};
	}
	&:active {
		background: ${({ background }) => background || "transparent"};
	}

	> svg {
		padding: ${({ padding }) => padding || ".25em"};
		box-sizing: border-box;
	}
`;

export const BorderedIcon = ({ ...props }: Object) => (
	<BorderedIconStyled
		className={props.className}
		background={props.background}
		padding={props.padding}
		hide_border={props.hide_border}
		size={props.size}
		hover={props.hover}
		right_margin={props.right_margin}
	>
		{props.children}
	</BorderedIconStyled>
);

export default BorderedIcon;