// @flow
import * as React from "react";
import styled from "styled-components";
import {Redirect} from "react-router-dom";
import {compose} from "redux";
import {connect} from "react-redux";
import _, {isEmpty} from "lodash";

import type {TRootStore} from "../../../modules/types/store";
import type {
	ById,
	TClassicTeam,
	TClassicTeamsByID,
	TCoachPlayerStatsById,
	TPlayer,
	TPLeague,
	TRound,
	TRoundWithMatches,
	TUser
} from "../../../modules/types";

import * as selectors from "../../../modules/selectors";
import * as actions from "../../../modules/actions";
import {below} from "../../../assets/css/media";
import {getTeamProjectedScore} from "../../../helpers/team";
// import { getOpponentLineup } from '../../../helpers/teamsClassic/index';
import JsonFetcher from "../../../components/utils/JsonFetcher";
import {AdsContainer} from "../../../components/AdsContainer";
import {
	Ad,
	ButtonPrimary,
	DraftTableHeader,
	Exist,
	LeagueGameBar,
	Preloader,
	StandardSidebar,
	TabItem,
	Tabs,
	withClassicPlayerModal,
} from "../../../components";
import {PageContentWrapper} from "../../../components/PageContentWrapper";
import {PageTitle} from "../../../components/PageTitle";
import {TwoColumnLayout} from "../../../components/TwoColumnLayout";
import {RoundSelector} from "../../../components/RoundSelector";
import {MatchCentreBar} from "../../../components/MatchCentreBar";
import {Footer} from "../../../components/Footer";
import withRoundSelector from "../../../components/utils/withRoundsSelector";

import ByeRoundMessage from "../../../components/LeagueByeRoundMessage";

import {isMobile} from "../../../utils";
import {dataLayer} from "../../../utils/GTM";
import {getLeagueRoundAliases} from "../../../helpers/league";

import withCoachesBox from "../../../components/utils/withCoachesBox";
import {
	initialScoreStat,
	matchup_field_stats as field_stats,
	SummaryPanel,
	TeamsComparisonTable
} from "../../../components/MatchUp";
import UniqueComparisonTable from "../../../components/MatchUp/UniqueComparison";
import {isAllTrue, isAnyTrue} from "../../../helpers";
import { MatchupShare } from "../../../components/MatchupShare";
import {shareDynamicMatchupImg} from "../../../utils/social_share";


const MainWrapper = styled.div`
	margin-bottom: 50px;
`;

const CenterDiv = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	button{
		width: 50%;
		margin-bottom: 20px;
	}
	width: 100%;
`;

const TitleRoundWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
`;

const StyledTabItem = styled(TabItem)`
	margin-right: 10px;
	${below.phone`
		margin-right: 0;
		width: 50%;
	`}
`;


const buildFantasyMatchupsUrlPathname = (league_id, round_id, home_team_id, away_team_id) => {
	const path_sections = [
		"/classic/league",
		league_id,
		"matchup",
		round_id
	];

	if (home_team_id && away_team_id) {
		path_sections.push(home_team_id);
		path_sections.push(away_team_id);
	}
	return path_sections.join("/");
};
const getCompressedViewStatus = () => {
	if(localStorage.getItem("compressed_view")){
		return localStorage.getItem("compressed_view") === "true" ? true : false;
	}
	localStorage.setItem("compressed_view", "false");
	return false;
};

type Props = {
	selected_league_id: number,
	setSelectedRoundId: Function,
	round_id?: number,
	selected_round_id: number,
	home_team_id?: number,
	away_team_id?: number,
	league_rounds: Array<TRound>,
	fetchLeague: Function,
	fetchTeam: typeof actions.teamsDraft.fetchTeam,
	fetchLeagueRosters: typeof actions.getClassicLeagueRosters,
	rosters: Object,
	round_id: number,
	league: TPLeague,
	teams_by_id: TClassicTeamsByID,
	my_team: TClassicTeam,
	home_team_id: number,
	home_team: TClassicTeam,
	away_team_id: number,
	away_team: TClassicTeam,
	players: ById<TPlayer>,
	coach_players_by_id: TCoachPlayerStatsById,
	positions: Array<Object>,
	has_assistant_coach: boolean,
	user: TUser,
	history: Object,
	actual_round: TRound,
	fetchLadder: Function,
	data_has_loaded: boolean,
	rounds: Array<TRoundWithMatches>,
	is_rounds_pending: boolean,
	showModalForPlayerId: typeof actions.showModalForPlayerId,
  teams_by_round: Object,
  fetchRounds: Function,
  fetchPlayers: Function,
  home_team_roster: Object,
  away_team_roster: Object,
};

type State = {
	active_stats_type: string,
	preloader: boolean,
	show_unique: boolean,
	tab: string,
	is_compressed: boolean,
	matchup_share_open: boolean,
};

class MatchUpComponent extends React.Component<Props, State> {
	constructor(props) {
		super(props);

		_.bindAll(this, [
			"changeRound",
			"gotoPrevFixture",
			"gotoNextFixture",
			"setActiveStatsType",
			"setActiveTab",
			"onCompressedViewClick",
			"handleMatchupToggle",
			"setLocalStorageMatchup"
		]);
	}

	state = {
		active_stats_type: this.getInitialStat(),
		preloader: true,
		show_unique: true,
		tab: "head-to-head",
		is_compressed: getCompressedViewStatus(),
		matchup_share_open: false,
	};

	componentDidMount() {
		const {
			round_id,
			selected_round_id,
		} = this.props;
	

		this.fetchLeague();
		this.fetchLadder();

		if (round_id || selected_round_id) {
			this.fetchLeagueRosters(round_id || selected_round_id);
		}
	}


	// eslint-disable-next-line complexity
	componentDidUpdate(prev_props) {
		const {
			round_id,
			selected_round_id,
			data_has_loaded, actual_round
		} = this.props;

		if (isAnyTrue([
			Boolean(round_id),
			Boolean(selected_round_id)
		]) && isAnyTrue([
			round_id !== prev_props.round_id,
			selected_round_id !== prev_props.selected_round_id
		])
		) {
			this.fetchLeagueRosters(round_id || selected_round_id);
		}

		if (isAllTrue([this.state.preloader, data_has_loaded])) {
			this.setState({
				preloader: false,
				active_stats_type: this.getInitialStat()
			});
		}
		if(isAllTrue([
			this.state.active_stats_type === "proj_score",
			round_id < actual_round.id
		])){
			this.setState({
				active_stats_type: "round_points"
			});
		}
		if(isAllTrue([
			this.state.active_stats_type !== "proj_score",
			round_id === actual_round.id,
			actual_round.status === "scheduled"
		])){
			this.setState({
				active_stats_type: "proj_score"
			});
		}
		// if(isNumber(round_id) 
		// 	&& round_id !== prev_props.round_id 
		// 	&& isNumber(prev_props.round_id) 
		// 	&& round_id !== 0
		// ){
		// 	window.location.reload();
		// }
	}

	getInitialStat() {
		const { has_assistant_coach } = this.props;
		const round_started = _.get(this.round, "status", "scheduled") !== "scheduled";

		return initialScoreStat(round_started, has_assistant_coach);
	}

	fetchLadder() {
		const { fetchLadder, selected_league_id, round_id, selected_round_id } = this.props;
		fetchLadder({
			round: round_id || selected_round_id,
			league_id: selected_league_id
		});
	}

	fetchLeagueRosters(round_id) {
		const { fetchLeagueRosters, selected_league_id, league, actual_round } = this.props;
		const league_type = _.get(league, "type");
		const fetch_roster_payload = round_id !== actual_round.id ? {
			league_id: selected_league_id,
			round: Number(round_id),
		} : {
			league_id: selected_league_id,
		};
		if(league_type !== "open" ){
			fetchLeagueRosters(fetch_roster_payload);
		}
	

		// fetchTeam();
	}


	get imageShareUrl() {
		if(!this.props.rounds.length){
			return "";
		}
		const complete_id = this.last_completed_round_id;
		const league_id = this.props.league.id;
		const team_id = _.get(this.props.my_team, 'id') ;

		if(!complete_id || !league_id || !team_id){
			return "";
		}
		return shareDynamicMatchupImg({
			round_id: complete_id,
			league_id: this.props.league.id,
			team_id: this.props.my_team.id,
			is_classic: true,
		});
	}

	handleMatchupToggle(bool: boolean) {
		if(bool){
			dataLayer({
				'event': 'Dynamic Share Matchup Classic Click' 
			});
		}
		this.setState({
			matchup_share_open: bool
		});
	}

	setLocalStorageMatchup() {
		const roundComplete = this.last_completed_round_id;
		localStorage.setItem(`matchupShareClassic-${roundComplete}`, "true");
		this.handleMatchupToggle(false);
	}

	isLocalStorageSetForRound() {
		const roundComplete = this.last_completed_round_id;
		return localStorage.getItem(`matchupShareClassic-${roundComplete}`); 
	}

	isShowMatchupShareOnLoad() {
		return !this.isLocalStorageSetForRound() && this.isMatchupShareAvailable();
	}

	isMatchupShareAvailable() {
		const leagueStartRoundId = this.props.league.start_round;
		const startRound = this.props.rounds.find(round => round.id === leagueStartRoundId);
		const startRoundStatus = _.get(startRound, 'status');
		const isStartRoundComplete = startRoundStatus === "complete";
		// TODO: fix logic
		return isStartRoundComplete;
	}

	fetchLeague() {
		const { fetchLeague, selected_league_id } = this.props;
		fetchLeague({
			id: selected_league_id
		});
	}

	get last_completed_round_id() {
		const lastCompleteRoundArr = this.props.rounds.filter(round => round.status === "complete");
		const length = lastCompleteRoundArr.length;
		const lastRoundComplete = lastCompleteRoundArr[length - 1];
		const lastRoundCompleteId = _.get(lastRoundComplete, 'id');
		
		return lastRoundCompleteId;
	}

	setActiveStatsType({ currentTarget }) {
		this.setState({
			active_stats_type: currentTarget.value
		});
	}

	get round() {
		const {
			rounds,
			round_id
		} = this.props;

		return _.find(rounds, { id: round_id });
	}

	shouldShowTeams() {
		const {
			actual_round,
			league
		} = this.props;
		const round_id = _.get(actual_round, "id", 0);
		const status = _.get(actual_round,  "status",  "scheduled");
		const start_round = _.get(league, "start_round", 1);

		return round_id > start_round || (
			round_id === start_round
			&& status !== "scheduled"
		);
	}

	mergeTeamData(team, is_home) {
		const {
			user,
			my_team,
			home_team_roster,
			away_team_roster,
		} = this.props;

  	const team_user_id = _.get(team, "user_id");
		const user_id = _.get(user, "id");

		const show_teams = this.shouldShowTeams();

		if (team) {
			if (team_user_id === user_id) {
				const user_team = {
					...my_team,
					...team,
					lineup: is_home ? home_team_roster : away_team_roster
				};

				return selectors.setUtilityPosition(user_team);
			}

			if (show_teams) {
				team.lineup = is_home ? home_team_roster : away_team_roster;
				let team_with_secret_lineup = selectors.setUtilityPosition(team);
				return team_with_secret_lineup;
				// if(team_lineup_data[0] && team_lineup_data[1] && rounds[round_id-1] && players){
				// 	const lineup = 
				//     getOpponentLineup(
				//     	team_lineup_data[0], 
				//     	team_lineup_data[1], 
				//     	rounds[round_id-1], 
				//     	players);
				// 	if(team_with_secret_lineup && team_with_secret_lineup.lineup){
				// 		team_with_secret_lineup.lineup = lineup;
				// 	}
				// 	return selectors.setUtilityPosition(team_with_secret_lineup);
				// }
				
				
			}
			return _.omit(team, "lineup");
		}
		else {
			return {};
		}
	}

	getTeamPlayerProjScores(team) {
		const {
			players,
			league,
		} = this.props;

		return getTeamProjectedScore(
			_.get(team, "lineup"),
			players,
			league,
			this.round,
			true
		);
	}

	get is_matchup_round_id_set() {
		const {
			round_id,
		} = this.props;

		return Boolean(round_id);
	}

	get is_round_id_set() {
		const {
			round_id,
		} = this.props;

		return Boolean(round_id);
	}

	get is_team_ids_set() {
		const {
			home_team_id,
			away_team_id,
		} = this.props;

		return Boolean(home_team_id) && Boolean(away_team_id);
	}

	getRoundFixtures(round_id) {
		const { league } = this.props;
		return _.get(league, ["fixture", round_id], []);
	}

	get default_matchup_url() {
		const {
			league,
			selected_round_id,
			rosters,
			user
		} = this.props;

		if (_.isEmpty(rosters) === false) {
			const round_fixtures = this.getRoundFixtures(selected_round_id);

			// If no fixtures for selected_round then return
			// round url anyway without team ids for matchup
			if (_.isEmpty(round_fixtures) === true) {
				return buildFantasyMatchupsUrlPathname(
					league.id,
					selected_round_id,
				);
			}

			const user_fixture = round_fixtures.find(fixture => {
				const home_team = _.get(rosters, fixture[0]);
				const away_team = _.get(rosters, fixture[1]);

				return _.get(home_team, "user_id") === user.id ||
					_.get(away_team, "user_id") === user.id;
			});

			if (Array.isArray(user_fixture)) {
				const home_team_id = user_fixture[0];
				const away_team_id = user_fixture[1];

				return buildFantasyMatchupsUrlPathname(
					league.id,
					selected_round_id,
					home_team_id,
					away_team_id
				);
			}
		}

		return null;
	}

	get default_round_matchup_url() {
		const {
			league,
			round_id,
			rosters,
			user
		} = this.props;

		if (_.isEmpty(rosters) === false) {
			const round_fixtures = this.getRoundFixtures(round_id);
			const user_fixture = round_fixtures.find(fixture => {
				const home_team = _.get(rosters, fixture[0]);
				const away_team = _.get(rosters, fixture[1]);

				return _.get(home_team, "user_id") === user.id ||
					_.get(away_team, "user_id") === user.id;
			});

			if (Array.isArray(user_fixture)) {
				const home_team_id = user_fixture[0];
				const away_team_id = user_fixture[1];

				return buildFantasyMatchupsUrlPathname(
					league.id,
					round_id,
					home_team_id,
					away_team_id
				);
			}
		}

		return null;
	}

	changeRound(round_id) {
		const { history, league } = this.props;

		history.push({
			pathname: buildFantasyMatchupsUrlPathname(
				league.id,
				round_id
			)
		});
	}

	get next_fixture() {
		const { round_id, home_team_id, away_team_id  } = this.props;
		const round_fixtures = this.getRoundFixtures(round_id);

		const current_fixture_index = round_fixtures.findIndex(fixture => {
			return fixture[0] === home_team_id &&
				fixture[1] === away_team_id;
		});
		const next_fixture = round_fixtures[current_fixture_index + 1];

		if (next_fixture) {
			return [
				next_fixture[0],
				next_fixture[1]
			];
		}

		return null;
	}

	get prev_fixture() {
		const { round_id, home_team_id, away_team_id  } = this.props;
		const round_fixtures = this.getRoundFixtures(round_id);

		const current_fixture_index = round_fixtures.findIndex(fixture => {
			return fixture[0] === home_team_id &&
				fixture[1] === away_team_id;
		});
		const prev_fixture = round_fixtures[current_fixture_index - 1];

		if (prev_fixture) {
			return [
				prev_fixture[0],
				prev_fixture[1]
			];
		}

		return null;
	}

	gotoPrevFixture() {
		const { history, league, round_id } = this.props;

		const prev_fixture = this.prev_fixture;

		if (prev_fixture) {
			history.push({
				pathname: buildFantasyMatchupsUrlPathname(
					league.id,
					round_id,
					prev_fixture[0],
					prev_fixture[1]
				)
			});
		}
	}

	gotoNextFixture() {
		const { history, league, round_id } = this.props;

		const next_fixture = this.next_fixture;

		if (next_fixture) {
			history.push({
				pathname: buildFantasyMatchupsUrlPathname(
					league.id,
					round_id,
					next_fixture[0],
					next_fixture[1]
				)
			});
		}
	}

	setActiveTab({ currentTarget }) {
		const { tab } = currentTarget.dataset;
		this.setState({
			tab
		});
	}

	onCompressedViewClick(open: boolean) {
		this.setState({
			is_compressed: open
		});
		const storageItem = String(open);
		localStorage.setItem("compressed_view", storageItem);
	}

	get redirect_to_default_matchup_url() {
		return !this.is_team_ids_set && !this.is_round_id_set && this.default_matchup_url;
	}

	get redirect_to_default_round_matchup_url() {
		return !this.is_team_ids_set && this.is_round_id_set && this.default_round_matchup_url;
	}

	openPlayerPopup(player_id) {
		const { showModalForPlayerId } = this.props;
		showModalForPlayerId(player_id);
	}

	get is_league_bye_round() {
		const {
			league
		} = this.props;
		const round = this.round;

		if (league && round) {
			return Boolean(league.bye_rounds) ? false : round.is_bye;
		}

		return false;
	}

	get select_info() {
		return {
			title: "Scoring",
			label: "Scoring",
			description: "Scoring stat that will be shown"
		};
	}

	renderTabs() {
		const { tab } = this.state;
		return (
			<Tabs>
				<StyledTabItem
					onClick={this.setActiveTab}
					data-tab="head-to-head"
					className={tab === "head-to-head" ? "active-tab" : ""}>
					Head To Head
				</StyledTabItem>
				<StyledTabItem
					onClick={this.setActiveTab}
					data-tab="unique"
					className={tab === "unique" ? "active-tab" : ""}>
					Unique Scorers
				</StyledTabItem>
			</Tabs>
		);
	}

	getComparisonTableComponent() {
		const { tab } = this.state;
		return tab === "unique" ? UniqueComparisonTable : TeamsComparisonTable;
	}

	renderMatchupSection() {
		const {
			league,
			home_team,
			away_team,
			players,
			coach_players_by_id,
			positions,
			round_id,
			has_assistant_coach,
			actual_round,
			rounds,
			user
		} = this.props;
		const { active_stats_type, is_compressed } = this.state;

		const ComparisonTable  = this.getComparisonTableComponent();
		

		const home_team_merged = this.mergeTeamData(home_team, true);
		const away_team_merged = this.mergeTeamData(away_team, false);

		if(isEmpty(home_team_merged) || isEmpty(away_team_merged)){
			return null;
		}

		return (
			<React.Fragment>
				<SummaryPanel
					league={league}
					home_team={home_team_merged}
					home_team_proj_score={
						this.getTeamPlayerProjScores(home_team)
					}
					away_team={away_team_merged}
					away_team_proj_score={
						this.getTeamPlayerProjScores(away_team)
					}
					has_assistant_coach={has_assistant_coach}
					round={this.round}
					round_id={round_id}
					onClickNext={
						this.next_fixture && this.gotoNextFixture
					}
					onClickPrev={
						this.prev_fixture && this.gotoPrevFixture
					}
					players_by_id={players}
					coach_players_by_id={coach_players_by_id}
					actual_round={actual_round}
					user_id={user.id}
					rounds={rounds}
					is_classic
				/>
				
				<DraftTableHeader
					title={`${_.get(home_team, "name", "")} vs ${_.get(away_team, "name", "")}`}
					active_stats_type={active_stats_type}
					onSelectChange={this.setActiveStatsType}
					is_compressed={is_compressed}
					onCompressedViewClick={this.onCompressedViewClick}
					selectOptions={field_stats}
					show_bottom_border={false}
					select_info={this.select_info}
				/>
				{ this.renderTabs() }
				<ComparisonTable
					home_team={home_team_merged}
					away_team={away_team_merged}
					players={players}
					positions={positions}
					round={this.round}
					has_assistant_coach={has_assistant_coach}
					league={league}
					actual_round={actual_round}
					start_compressed={isMobile()}
					field_stats={field_stats}
					active_stats_type={active_stats_type}
					setActiveStatsType={this.setActiveStatsType}
					rounds={rounds}
					openPlayerPopup={this.openPlayerPopup}
					is_classic
					is_compressed={is_compressed}
					custom_stats={{}}
					user_id={user.id}
					round_id={round_id}
				/>
			</React.Fragment>
		);
	}

	render() {
		const {
			league,
			league_rounds,
			round_id,
			my_team
		} = this.props;

		const final_aliases = getLeagueRoundAliases(league, league_rounds);
		const userTeamId = _.get(my_team, 'id');
		if (this.redirect_to_default_matchup_url) {
			return <Redirect to={this.default_matchup_url} />;
		}
		else if (this.redirect_to_default_round_matchup_url) {
			return <Redirect to={this.default_round_matchup_url} />;
		}
		else if(league.type === "open"){
			return <Redirect to={`/classic/league/${league.id}`} />;
		}


		return (
			<React.Fragment>
				<JsonFetcher
					fetch={["rounds", "players", "squads", "coach_players"]}
					interval={60000} /* Fetch new data once every 60s */
				/>

				<LeagueGameBar matchup_page no_fetch={true} />

				<AdsContainer>
					<Ad />
				</AdsContainer>

				<MatchCentreBar />

				<Exist when={this.state.matchup_share_open}>
					<MatchupShare
						is_classic={true}
						league_id={league.id}
						round_id={this.last_completed_round_id}
						team_id={userTeamId}
						league_name={league.name}
						handleCloseClick={this.setLocalStorageMatchup}
						imgUrl={this.imageShareUrl}
					/>
				</Exist>

				<PageContentWrapper>
					{this.state.preloader ? <Preloader />
						:<TwoColumnLayout>
							<MainWrapper>
								<TitleRoundWrapper>
									<PageTitle>
									Fantasy match ups for {league.name}
									</PageTitle>
									<RoundSelector
										selected={round_id}
										rounds={league_rounds}
										handleClick={this.changeRound}
										round_aliases={final_aliases}
									/>
									
								</TitleRoundWrapper>
								<Exist when={this.isMatchupShareAvailable()}>
									<CenterDiv>
									
										<ButtonPrimary 
											aria-label={`Click Classic Matchup Dynamic Share`}
											id={"classic_matchup_dynamic_share"}
											name={`Click Classic Matchup Dynamic Share`}
											onClick={() => this.handleMatchupToggle(true)}
										>
											Share Most Recent Matchup
										</ButtonPrimary>
									</CenterDiv>
								</Exist>
								{this.is_league_bye_round ?
									<ByeRoundMessage league_id={league.id} is_classic /> :
									this.renderMatchupSection()
								}
							</MainWrapper>
							<StandardSidebar />
						</TwoColumnLayout>
					}
				</PageContentWrapper>
				<Footer />
			</React.Fragment>
		);
	}
}
const mapStateToProps = (state: TRootStore, props) => {
	const {
		match: { params: { league_id, round_id, home_team_id, away_team_id } }
	} = props;

	const has_assistant_coach = Boolean(selectors.getUser(state).assistant_coach);
	const rounds = selectors.rounds.getRoundsWithMatches(state);

	const teams = Object.keys(state.leaguesClassic.teams_by_id)
		.map(team_id => state.leaguesClassic.teams_by_id[team_id]);
	const user =  selectors.getUser(state);
	const team = teams.find(team => team.user_id === user.id);

	const loading_data = [
		!_.isEmpty(state.leaguesClassic.by_id),
		!state.leaguesClassic.ladder.is_pending,
		!state.players.is_pending,
		!state.rounds.is_pending,
		!state.squads.is_pending,
		!state.leaguesClassic.rosters.is_pending,
		!has_assistant_coach || !_.isEmpty(state.players.coach_by_id),
		!_.isEmpty(state.leaguesClassic.teams_by_id),
		!_.isEmpty(state.leaguesClassic.rosters.teams_by_id)
	
    
	];
	const default_home_roster = 
		_.get(state.leaguesClassic.rosters.teams_by_id, `[${home_team_id}].lineup`, {});
	const default_away_roster = 
		_.get(state.leaguesClassic.rosters.teams_by_id, `[${away_team_id}].lineup`, {});

	return {
		selected_league_id: league_id && parseInt(league_id, 10),
		round_id: round_id && parseInt(round_id, 10),
		home_team_id: home_team_id && parseInt(home_team_id, 10),
		away_team_id: away_team_id && parseInt(away_team_id, 10),
		home_team: _.get(state.leaguesClassic.teams_by_id, home_team_id),
		away_team: _.get(state.leaguesClassic.teams_by_id, away_team_id),
		home_team_roster: 
      _.get(state.leaguesClassic.rosters.teams_by_round,
      	`[${round_id}][${home_team_id}].lineup`, default_home_roster),
		away_team_roster: 
      _.get(state.leaguesClassic.rosters.teams_by_round, 
      	`[${round_id}][${away_team_id}].lineup`, default_away_roster),
		teams_by_id: state.leaguesClassic.teams_by_id,
		my_team: team,
		players: selectors.players.getExtendedPlayersById(state, props),
		coach_players_by_id: selectors.getCoachPlayersById(state),
		positions: selectors.positions.getPositionsArray(state),
		playerStatuses: state.playerStatuses,
		league: selectors.leaguesClassic.show.getLeague(state, props),
		user: selectors.getUser(state),
		is_rounds_pending: selectors.rounds.getRoundsIsPending(state),
		has_assistant_coach,
		league_rounds: selectors.leaguesClassic.getLeagueRounds(state, props),
		rosters: selectors.leaguesClassic.getRostersById(state),
		actual_round: selectors.rounds.getActualRound(state),
		data_has_loaded: _.every(loading_data),
		rounds,
		teams_by_round: state.leaguesClassic.rosters.teams_by_round
	};
};

const mapDispatchToProps = {
	fetchLeague: actions.showClassicLeague,
	fetchRounds: actions.fetchRounds,
	fetchPlayers: actions.fetchPlayers,
	fetchLeagueRosters: actions.getClassicLeagueRosters,
	fetchLadder: actions.ladderClassicLeague,
	fetchTeam: actions.fetchMyClassicTeam,
	fetchFavourites: actions.fetchFavourites,
	showModalForPlayerId: actions.showModalForPlayerId,
};
export const MatchUp = compose(
	withClassicPlayerModal,
	withRoundSelector,
	withCoachesBox,
	connect(
		mapStateToProps,
		mapDispatchToProps
	),
)(MatchUpComponent);

export default MatchUp;