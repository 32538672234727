// @flow
import * as React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import styled from "styled-components";

import {
	Select,
	Tooltip,
	Widget,
	WidgetBody,
	WidgetHeader,
	WidgetHeaderTitle,
} from "../../../../components";

import type { 
	TPositionsById, 
	TRootStore, 
	TPLeague, 
	TPlayer, 
	TPlayersById,
	TDraftTeamsById,
	TUser
} from "../../../../modules/types";
import * as selectors from "../../../../modules/selectors";
import { below } from "../../../../assets/css/media";
import colors from "../../../../assets/css/colors";
import { getIsRegenerated } from "../../../../helpers";
import TeamViewContent from "./teamViewWidget/teamViewContent";
import TeamPositionContent from "./teamViewWidget/teamPositionContent";
import TeamPositionInfo from "./teamViewWidget/teamPositionInfo";
import TeamItemEmpty from "./teamViewWidget/teamItemEmpty";
import TeamItem from "./teamViewWidget/teamItem";
import TeamViewSelectorContainer from "./teamViewWidget/teamViewSelectorContainer";


const StyledWidgetHeaderTitle = styled(WidgetHeaderTitle)`
	width: 100%;
	justify-content: space-between;
	border-radius: 0px;
`;

const StyledWidget = styled(Widget)`
	${below.phone`
		width: 100%;
		margin-top: 10px;
	`}
`;

const FlexCol = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;

`;

const FlexRow = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	font-family: SourceSansPro;
	padding: 0 5px;
	border-top: 1px solid rgba(184, 184, 184, 0.4);
	color: ${colors.primary.primary};
	height: fit-content;
	span{
		font-size: 12px;
		font-weight: 600;
		width: 60px;
		line-height: 30px;
	}
	p{
		margin 0 2px;
		line-height: 30px;
		font-size: 12px;
	}
	img{
		width: 25px;
		height: auto;
		margin-right: 2px;
	}
`;

type Props = {
	onToggle: Function,
	onChangeTeam?: Function  | typeof undefined,
	onFilterByPosition: Function,
	widget_open: boolean,
	title: string,
	positions: TPositionsById,
	league: TPLeague,
	teams: TDraftTeamsById,
	tooltip_msg?: string,
	picks: Array<Object>,
	commissioner_type?: boolean,
	teams_by_id: TDraftTeamsById,
	keeper_players: Array<TPlayer>,
	drafted_players: Array<TPlayer>,
	players_by_id: TPlayersById,
	user: TUser,
}

type State = {
	selected_team: number | typeof undefined,
	picks: Object,
};


class TeamViewWidgetContainer extends React.Component<Props, State> {
	static defaultProps = {
		tooltip_msg: "This is a summary of your drafted team as the live draft progresses.",
		commissioner_type: false,
	};

	state = {
		selected_team: undefined,
		picks: {}
	};

	componentDidMount() {
		const { picks } = this.props;
		this.setState({
			picks: _.reduce(picks, this.getPickedPlayerById, {})
		});
		_.bindAll(this, [
			"changeTeam"
		]);

	}

	componentDidUpdate(prevProps: Props) {
		const { picks } = this.props;


		if(_.size(prevProps.picks) !== _.size(picks)) {

			this.setState({
				picks: _.reduce(picks, this.getPickedPlayerById, {})
			});
		}
	}

	getPickedPlayerById(acc, { picked_player_id, pick }){
		if(picked_player_id) {
			_.extend(acc, {
				[picked_player_id]: {
					pick: pick - 1
				}
			});
		}

		return acc;
	}

	changeTeam(evt: Object) {
		this.setState({
			selected_team: evt.currentTarget.value
		});

		const { onChangeTeam } = this.props;

		if(onChangeTeam) {
			onChangeTeam(evt);
		}
	}

	get lineup(){
		const {	league, teams } = this.props;
		const { selected_team } = this.state;
		const current_team = selected_team || league.team_id || "";


		return _.toPairs(_.get(teams, `${current_team}.lineup`, {}));
	}

	get keepers() {
		const {	league, teams_by_id } = this.props;
		const { selected_team } = this.state;
		const current_team = selected_team || league.team_id || "";
		const keptPlayers = _.get(teams_by_id, `${current_team}.keepers`, []);
		
		return keptPlayers !== null ? keptPlayers : [];
	}

	get widget_content() {
		const {	positions, onFilterByPosition } = this.props;
		const getFromPosition = _.partial(_.get, positions);
		const { picks } = this.state;

		const keeper_list = this.keepers;
		const lineup = this.lineup.map(([key, line]) => {
			if(!line.length){
				return null;
			}
			const
				plural_pos_name = getFromPosition(`${key}.name`, key),
				players_list = line.map((player, index) => {
					const is_keeper = keeper_list.includes(player.id);
					return player ? (
						<li key={player.id}>
							<TeamItem
								player={player}
								is_keeper={is_keeper}
								pick={_.get(picks, `${player.id}.pick`, "")}
							/>
						</li>
					) : (
						<li key={index}>
							<TeamItemEmpty
								pos={plural_pos_name}
								onClick={() => onFilterByPosition(key)}
							/>
						</li>
					);
				}),
				
				full_pos_name = getFromPosition(`${key}.full_name`, "Interchange"),
				size_max_players = _.size(line),
				size_picked_players = _.size(line.filter(_.identity));

			return (
				<TeamPositionContent key={key}>
					<TeamPositionInfo>
						<b>{ full_pos_name }</b>
						<span>{ size_picked_players }/{ size_max_players }</span>
					</TeamPositionInfo>
					<ul>{ players_list }</ul>
				</TeamPositionContent>
			);
		});

		return (
			<React.Fragment>
				<TeamViewContent>
					{ lineup }
				</TeamViewContent>
			</React.Fragment>
		);
	}

	get select_by_teams() {
		const { league, teams } = this.props;

		const teams_arr = _.values(teams) || [];

		if(_.isEmpty(teams_arr)) {
			return null;
		}

		const
			{ selected_team } = this.state,
			current_team = selected_team || league.team_id,
			team_options =  teams_arr.map(team => (
				<option key={team.id} value={team.id}>
					{ _.eq(...[team.id, league.team_id].map(_.toNumber)) ? "My team" : team.name }
				</option>
			));

		return (
			<Select onChange={this.changeTeam} value={current_team}>
				{ team_options }
			</Select>
		);
	}

	get header_content(){
		const {
			title,
			tooltip_msg,
			commissioner_type,
		} = this.props;

		return (
			<React.Fragment>
				{ commissioner_type ? this.select_by_teams : (<span>{ title }</span>) }
				<Tooltip show_on_left description={tooltip_msg} />
			</React.Fragment>
		);
	}

	get view_team_selector() {
		const {	commissioner_type } = this.props;

		if(commissioner_type) {
			return null;
		}

		return (
			<TeamViewSelectorContainer>
				<div>
					<span>View another team</span>
					<Tooltip show_on_left description="Select a team that you want to view" />
				</div>
				{ this.select_by_teams }
			</TeamViewSelectorContainer>
		);
	}

	getPlayerPositions(player_pos){
		const { positions } = this.props;
		return player_pos.map(position => positions[position].short_name).join(" / ");
	}

	getPlayerRow(player_data){
		const { players_by_id } = this.props;
		const {player_id, pick_num} = player_data;
		const player = players_by_id[player_id];
		const PLAYERS_AVATAR_PATH =  (process.env.REACT_APP_PLAYERS_PATH || "");
		const avatar_url_webp = `${PLAYERS_AVATAR_PATH + player_id}_450.webp`;
		const player_positions = _.get(player, "positions", []);
		const playerPositionsName = this.getPlayerPositions(player_positions);
		const playerFirstName = _.get(player, "first_name");
		const playerLastName = _.get(player, "last_name");

		return <FlexRow>
			<span>Pick: {pick_num}</span>
			<img src={avatar_url_webp} alt={playerFirstName + playerLastName}/>
			
			<p>{playerFirstName +" " +playerLastName}</p>
			
			<p> - ({playerPositionsName})</p>
		</FlexRow>;
	}

	get team_keepers_content() {
		const {league, drafted_players} = this.props;
		const {keeper_draft_picks} = league;
		const isRegenerated = getIsRegenerated(league.regenerated_from,league.uf_regenerated_from);
		const isKeeperandRegen = Boolean(league.keeper) && isRegenerated;
		
		if(!keeper_draft_picks){
			return null;
		}
		const getPlayerPickNumber = player_id => 
			Object.keys(keeper_draft_picks).find(pick => 
				Number(keeper_draft_picks[pick]) === Number(player_id)
			);

		const undrafted_keepers = 
			this.keepers.filter(player => !drafted_players.includes(player.id)).map(player => {
				const pickNum = Number(getPlayerPickNumber(player.id));
				return({
					player_id: player.id,
					pick_num: pickNum
				});
			}).sort((playerA, playerB) => playerA.pick_num - playerB.pick_num > 0 ? 1 : -1);
		if(undrafted_keepers.length === 0 || !isKeeperandRegen){
			return null;
		}
		return <FlexCol>
			{undrafted_keepers.map(player => this.getPlayerRow(player))}
		</FlexCol>;
	}

	render() {
		const {
			widget_open,
			teams,
			league,
		} = this.props;

		// if([teams, league].some(_.isEmpty) || _.isEmpty(league.keeper_draft_picks)) {
		if([teams, league].some(_.isEmpty)) {
			return (
				<StyledWidget>
					<WidgetHeader open={widget_open}>
						<WidgetHeaderTitle>
							Loading, please wait...
						</WidgetHeaderTitle>
					</WidgetHeader>
				</StyledWidget>
			);
		}


		return (
			<StyledWidget>
				<WidgetHeader open={widget_open}>
					<StyledWidgetHeaderTitle>
						{ this.header_content }
					</StyledWidgetHeaderTitle>
				</WidgetHeader>
				<WidgetBody open={widget_open}>
					{ this.view_team_selector }
					{ this.team_keepers_content }
					{ this.widget_content }
				</WidgetBody>
			</StyledWidget>
		);
	}
}

const mapStateToProps = (state: TRootStore, props) => {
	const teamsFromSelector = 
		selectors.complex.team.getTeamsWithFullLineupWithPlayers(state, props);
	const teams_by_id =  selectors.getTeamsById(state);

	const teams = _.isEmpty(teamsFromSelector) ? teams_by_id : teamsFromSelector;
	return {
		positions: selectors.positions.getPositionsById(state),
		teams,
		teams_by_id,
		players_by_id: state.players.by_id,
		user: state.user.data
	};
};

export const TeamViewWidget = withRouter(
	connect(mapStateToProps)(TeamViewWidgetContainer),
);

export default TeamViewWidget;