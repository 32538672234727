// @flow
/* eslint max-len: 0 */
import React from "react";
import SvgIconContainer from "../SvgIconContainer";

export default (
	{
		unit = "em",
		...rest
	}: Object = {}) => (
	<SvgIconContainer viewBox="0 0 24 24" {...rest} unit={unit}>
		<g stroke="none" strokeWidth="1" fillRule="evenodd">
			<path d="M15.121671,8.07272727 C15.121671,6.59151515 13.9689866,5.38343434 12.5107172,5.29597872 L11.7703665,5.29090909 C10.2329006,5.29090909 8.98984296,6.53454545 8.98984296,8.07272727 L8.98984296,10.8545455 L6.7,10.8545455 L6.7,8.07272727 C6.7,5.29090909 8.95713091,3 11.7703665,3 L12.3411474,3 L12.3411474,3 C15.0803506,3 17.2923325,5.17192143 17.4068557,7.85416275 L17.411514,8.07272727 L17.411514,10.8545455 L17.8885446,10.8545455 C18.500091,10.8545455 18.9958473,11.3505325 18.9958473,11.9623636 L18.9958473,19.8921818 C18.9958473,20.5040129 18.500091,21 17.8885446,21 L6.10730263,21 C5.49575627,21 5,20.5040129 5,19.8921818 L5,11.9623636 C5,11.3505325 5.49575627,10.8545455 6.10730263,10.8545455 L15.121671,10.8545455 L15.121671,8.07272727 Z" fill="#B89344"></path>
		</g>
	</SvgIconContainer>
);