// @flow
import styled from "styled-components";
import colors from "../../assets/css/colors";
import { below } from "../../assets/css/media";

export const EmptyCell = styled.div`
	height: 60px;
	width: auto;
	background: #FFFFFF;
	border: dashed 1px rgba(218, 223, 225, 0.5);
	text-align: center;
	display: flex;
	justify-content: start;
	align-items: center;
	color: ${colors.secondary.paleGrey};
	font-family: TitilliumUpright;
	font-weight: 700;
	font-size: 14px;
	margin-bottom: 10px;
	&:last-child {
		margin-bottom: 0;
	}
	${({ faded }) => faded && `
		border: dashed 1px rgba(218, 223, 225, 0.3);
	`};
	${below.desktop`
		margin: 2px;
	`}
`;
export default EmptyCell;