// @flow
import * as React from "react";
import styled from "styled-components";
import { Select, Label, Tooltip } from "../";



const TeamSelectWrapper = styled.div`
	position: relative;
	width: 100%;
	margin: 0;
	label {
		margin-bottom: 5px;
	}
`;

const TeamSelectStyled = styled(Select)``;

type Props = {
	children: React.Node,
	value: number,
	handleTeamSelect?: Function,
	show_label?: boolean
}
export const TeamSelect = ({ children, handleTeamSelect, value, show_label }: Props) => (
	<TeamSelectWrapper>
		{show_label && (
			<Label htmlFor="select_a_team">
				Select a team
				<Tooltip title="Select a team" description="Select a Team" />
			</Label>
		)}
		<TeamSelectStyled name="select_a_team" onChange={handleTeamSelect} value={value}>
			{children}
		</TeamSelectStyled>
	</TeamSelectWrapper>
);

export default TeamSelect;