// @flow
import * as React from "react";
import styled from "styled-components";
import { SwitchToggle } from "../../../../components";

const AutoPickTogglerWrapper = styled.div`
	display: flex;
	align-items: center;
	flex-wrap: nowrap;
	
	& > div {
		margin: 0 0 0 0.5em;
	}
	
	.custom-checkbox {
		background: #CAD2D8;
		height: 20px;
		
		&:before {
			top: 2px;
			height: 16px;
			width: 16px;
		}
	}
  	
  	input:checked + .custom-checkbox {
        background: #1D4073;
    }
	
	label {
		cursor: pointer;
		font-size: 14px;
		font-weight: 600;
		margin-left: 5px;
		font-family: SourceSansPro;

	}
`;

type Props = {
	isAutoPickEnabled: boolean,
	onChange: Function,
}

export const AutoPickToggle = ({ isAutoPickEnabled, onChange }: Props) => (
	<AutoPickTogglerWrapper is_active={isAutoPickEnabled}>
		
		<SwitchToggle
			value={isAutoPickEnabled}
			onChange={onChange}
			id="auto_pick"
			name="auto_pick"
			className="auto-pick-toggle"
		/>
		<label className="toggle-state-text" htmlFor="auto_pick">
			{ isAutoPickEnabled ? "On" : "Off" }
		</label>
	</AutoPickTogglerWrapper>
);

export default AutoPickToggle;