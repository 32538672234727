// @flow
import React from "react";
import SvgIconContainer from "../SvgIconContainer";

export default (
	{
		unit = "em",
		...rest
	}: Object = {}) => (
	<SvgIconContainer viewBox="0 0 16 16" {...rest} unit={unit}>
		<g id="Ladder/Icons/Down" stroke="none" strokeWidth="1" fillRule="evenodd">
			<polygon id="Shape" fill="#D0021B" points="10 -8.8817842e-16 5 10 0 3.36468379e-16" />
		</g>
	</SvgIconContainer>
);