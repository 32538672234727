export type TSection = {
	id: string,
	name: string,
	source?: string
}

export const sections: {[string]: TSection} = {
	"fantasy-coach-terms-and-conditions": {
		id: "fantasy-coach-terms-and-conditions",
		name: "Fantasy Coach - Terms and Conditions"
	},
	"terms-and-conditions": {
		id: "terms-and-conditions",
		name: "Terms and Conditions"
	},
	"official-rules": {
		id: "official-rules",
		name: "Official Rules"
	},
	"classic-guidelines": {
		id: "game-guidelines",
		name: "Game Guidelines",
		source: "classic-guidelines"
	},
	"draft-guidelines": {
		id: "game-guidelines",
		name: "Game Guidelines",
		source: "draft-guidelines"
	},
	"prizes": {
		id: "prizes",
		name: "Prizes"
	},
	"faqs": {
		id: "faqs",
		name: "FAQs"
	},
};

export const draft_sections: TSection[] = [
	sections["draft-guidelines"],
	sections["faqs"],
	sections["official-rules"],
];

export const classic_sections: TSection[] = [
	sections["classic-guidelines"],
	sections["faqs"],
	sections["prizes"],
	sections["official-rules"],
];