// @flow
import styled from "styled-components";
import { ButtonPrimary } from "../ButtonPrimary";
import colors from "../../assets/css/colors";

export const ButtonAccent = styled(ButtonPrimary)``;

ButtonAccent.defaultProps = {
	...colors.buttons.accent
};

export default ButtonAccent;