// @flow
import * as _ from 'lodash';
export const getById = (state: Object): Object => {
	const in_league = state.leagues.ladder.ordered_ids;
	return _.pickBy(
		state.leagues.teams_by_id,
		team => _.includes(in_league, _.get(team, 'id'))
	);
};

export const getOrdered = (state: Object): Array<Object> =>
	state.leagues.ladder.ordered_ids.map(team_id => getById(state)[team_id]);

export const isPending = (state: Object): boolean => state.leagues.ladder.is_pending;

export const getError = (state: Object) => state.leagues.ladder.error;