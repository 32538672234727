// @flow
import { css } from "styled-components";

export const sizes = {
	field_view_large: 1290,
	field_view_mid: 1160,
	desktop: 1080,
	tablet: 768,
	phone: 576,
	small_phone: 321,
};

export const above = Object.keys(sizes).reduce((acc, label) => {
	acc[label] = (...args: any[]) => css`
	  @media (min-width: ${sizes[label]}px) {
		${css(...args)}
	  }
	`;
	return acc;
}, {});

export const below = Object.keys(sizes).reduce((acc, label) => {
	acc[label] = (...args: any[]) => css`
	  @media (max-width: ${sizes[label] - 1}px) {
		${css(...args)}
	  }
	`;
	return acc;
}, {});


export default above;