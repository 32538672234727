// @flow
import type { Saga } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import * as actions from "../../actions/index";

export const createSagas = (API: Object): Object => {

	function* fetchFAQs(): Saga<void> {
		try {
			const result = yield call(API.faqs);
			yield put(actions.fetchFAQsSuccess(result));
		}
		catch (e) {
			console.log(e.message);
		}
	}

	function* watch() {
		yield takeLatest(actions.fetchFAQs, fetchFAQs);
	}

	return {
		fetchFAQs,
		watch
	};
};

export default createSagas;