/* eslint max-len: 0 */
// @flow
import React from "react";
import SvgIconContainer from "../SvgIconContainer";

export default (
	{
		unit = "em",
		...rest
	}: Object = {}) => (
	<SvgIconContainer viewBox="0 0 24 24" {...rest} unit={unit}>
		<title>Emergency Midfielder</title>
		<path d="M6,6 L9.28615385,6 L11.1692308,10.9846154 L11.8338462,12.9415385 L11.9076923,12.9415385 L12.5723077,10.9846154 L14.3815385,6 L17.6676923,6 L17.6676923,18 L14.7138462,18 L14.7138462,14.4553846 C14.7138462,14.1476908 14.7292306,13.8092326 14.76,13.44 C14.7907694,13.0707674 14.8246152,12.6923096 14.8615385,12.3046154 C14.8984617,11.9169211 14.9415382,11.5384634 14.9907692,11.1692308 C15.0400002,10.7999982 15.0830767,10.4676938 15.12,10.1723077 L15.0461538,10.1723077 L14.0492308,12.9784615 L12.3876923,17.0215385 L11.2061538,17.0215385 L9.54461538,12.9784615 L8.58461538,10.1723077 L8.51076923,10.1723077 C8.54769249,10.4676938 8.59076898,10.7999982 8.64,11.1692308 C8.68923102,11.5384634 8.73230751,11.9169211 8.76923077,12.3046154 C8.80615403,12.6923096 8.83999985,13.0707674 8.87076923,13.44 C8.90153862,13.8092326 8.91692308,14.1476908 8.91692308,14.4553846 L8.91692308,18 L6,18 L6,6 Z" id="EM"></path>
	</SvgIconContainer>
);