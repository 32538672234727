// @flow
import type { Saga } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import * as actions from "../../actions/index";

type Action = {
	payload: Object
};

export const createSagas = (API: Object): Object => {

	function* contactUsSaga(action: Action): Saga<void> {
		try {
			const { result, errors } =
				yield call(API.contactUs, { ...action.payload });

			if (errors.length) {
				yield put(actions.contactUsFailed(errors[0].text));
			}
			else {
				yield put(actions.contactUsSuccess(result));
			}
		}
		catch (event) {
			console.log(event);
		}
	}

	function* watch() {
		yield takeLatest(actions.contactUs, contactUsSaga);
	}

	return {
		contactUsSaga,
		watch
	};
};

export default createSagas;