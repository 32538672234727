// @flow
import styled from "styled-components";
import { below } from "../../../assets/css/media";

export const ListViewField = styled.div`
	position: relative;
	margin: 0;
	${below.tablet`
		margin: 0 -5px 0 -10px;
	`};
`;

export default ListViewField;