// @flow
import React from "react";
import { withRouter, Link } from "react-router-dom";
import styled from "styled-components";
import { connect } from "react-redux";
import _ from "lodash";
import * as selectors from "../../modules/selectors";

import logoAflFantasy from "../../assets/images/plain-fantasy-logo.webp";
import logoAflDraft from "../../assets/images/plain-draft-logo.webp";
import logoAfllCassic from "../../assets/images/plain-classic-logo.webp";
import presented_toyota from "../../assets/images/presented-toyota.svg";

import colors from "../../assets/css/colors";
import { header_height } from "../../assets/css/vars";
import { below } from "../../assets/css/media";
import ProSubscriptionModal from "../../pages/proSubscription/ProSubscriptionModal";
import Hamburger from "../Hamburger";
import Menu from "../Menu";


const LogoWrapper = styled.div`
  width: 125px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 35px 0 20px;
  .toyota-sponsor{
    width: 85px;
    height: 30px;
  }
`;

// use div because telstra already using header
const HeaderWrapper = styled.div`
	width: 100%;
	height: 50px;
	background: ${colors.primary.primary};
	display: flex;
	flex-direction: row;
	justify-content: center;
	color: #fff;
	position: relative;
	position: sticky;
	left: 0;
	top: 0;
	z-index: 100;
	box-sizing: border-box;
	${props => props.menu_open && `
		&:after {
			content: '';
			display: block;
			width: 50px;
			height: 50px;
			position: absolute;
			top: 0;
			right: 0;
			background-color: #fff;
			z-index: 0;
		}
	`};
`;

const HeaderInner = styled.div`
	width: 100%;
	max-width: 1280px;
	height: ${header_height}px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	padding-right: 20px;
	box-sizing: border-box;
	${below.tablet`
		justify-content: space-between;
		position: relative;
		padding-right: 16px;
	`};
`;

const LogoContainer = styled(Link)`
	display: block;
	align-self: flex-start;
	margin: 5px 10px 5px 0px;
	height: 40px;
	position: relative;
	z-index: 101;
	img {
		display: block;
		height: 40px;
	}

	${below.desktop`
		width: 40px;
	`};

	${below.tablet`
		width: 30px;
		margin-top: 5px;
	`};
`;

type Location = {
	hash: string,
	key: string,
	pathname: string,
	search: string
}

type Props = {
	location: Location,
	menu_open: boolean,
	league_id: number,
  has_user: boolean,
};
type State = {
	show_subscription_modal: boolean
}

class Header extends React.PureComponent<Props, State> {
	constructor(props,state) {
		super(props, state);
		this.state = {
			show_subscription_modal: false
		};
		_.bindAll(this, [
			"openSubModal",
			"closeSubModal"
		]);
	}
	openSubModal() {
		this.setState({
			show_subscription_modal: true
		});
	}
	closeSubModal() {
		this.setState({
			show_subscription_modal: false
		});
	}
	get logo() {
		if (this.props.location.pathname.substr(0, 6) === "/draft") {
			return logoAflDraft;
		}
		else if (this.props.location.pathname.substr(0, 8) === "/classic") {
			return logoAfllCassic;
		}
		return logoAflFantasy;
	}
	get show_on_registration_contact() {
		const { location } = this.props;
		return ["/registration", "/contact-us"].includes(location.pathname) ? true : false;
	}

	get is_no_user_contact_page() {
		const { has_user } = this.props;
		return this.show_on_registration_contact && !has_user;
	}

	render() {
		const { league_id } = this.props;
		const league_object = {
			id: league_id,
			subscription: "",
		};
		return (
			<HeaderWrapper menu_open={this.props.menu_open}>
				{this.state.show_subscription_modal && 	
					<ProSubscriptionModal 
						closeClick={this.closeSubModal} 
						league={league_object}
						is_pro_sub={false}
					/>
				}
				<HeaderInner>
			
					<LogoWrapper>
						<LogoContainer to="/" title="AFL Fantasy Home">
          	   					 <img src={this.logo} alt="AFL Fantasy Logo" />
						</LogoContainer>
						
						<img 
							src={presented_toyota} 
							alt="Presented by Toyota" 
							className="toyota-sponsor"/>
							
					</LogoWrapper>
						
					
					
					<Menu menu_open={this.props.menu_open} 
						is_contact_page={this.is_no_user_contact_page}
						openSubModal={this.openSubModal} closeSubModal={this.closeSubModal}/>
					<Hamburger
						menu_open={this.props.menu_open}
						is_contact_page={this.show_on_registration_contact}
					/>
				</HeaderInner>
			</HeaderWrapper>
		);
	}
}

const mapStateToProps = state => ({
	menu_open: state.uiMenu.open,
	league_id: state.leagues.show.league_id,
	has_user: selectors.hasUser(state),
});

export default withRouter(connect(mapStateToProps)(Header));