import styled, {css} from "styled-components";
import colors from "../../../assets/css/colors";
import { below } from "../../../assets/css/media";
import {
	ListRow,
	ListRowLabel,
} from "../../../components";

export const TeamList = styled.div`
    ${ListRow} > ${ListRowLabel} {
        height: 47px;
    }
    ${ListRow}:first-child > ${ListRowLabel} {
        height: 50px;
    }
`;
export const TeamInfoWrapper = styled.div`
    display: flex;
    align-items: flex-end;
    background-color: ${colors.form.base};

    flex: 1;

    > div {
        flex: 3 1 0;

        &:last-child {
            margin-left: 20px;
            flex: 2 1 0;

            label > span.long_name {
                display: none;
            }

            label > span.short_name {
                display: inline;
            }
        }


        ${below.desktop`
            display: flex;
        `}
    }

    ${below.desktop`
        padding: 0;
        margin-top: 10px;

        > div:first-child {
            display: none;
        }
    `}

    @media screen and (max-width: 600px) {
        flex-direction: column;
        padding-top: 0;
        > div {
            width: 100%;
            margin: 10px 0 0 0;

            :last-child {
                margin-left: 0;
            }
        }
    }
`;

export const UserTeamInfo = styled.div`
    height: ${({ is_mobile }) => is_mobile ? "auto" : "190px"};
    box-sizing: border-box;
    background: #F8F8FA;
    padding: 10px 20px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    min-height: 190px;
    ${below.desktop`
        min-height: 180px;
    `}
    ${below.tablet`
        padding: 10px;
        min-height: 215px;
    `}
`;

export const ModalInnerSubTitle = styled.p`
    color: #262424;
    font-family: "SourceSansPro";
    text-align: center;
    max-width: 60%;
    margin: 0 auto 1em;
    font-size: 14px;
    line-height: 1.42;
`;
export const ConfirmButtonsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;

    button {
        width: 200px;
        height: 40px;
        margin: 0 20px;
        
        ${below.desktop`
            ${css`
                width: 35%;
                font-size: 11px;
                height: 30px;
                padding-top: 9px;
            `}
        `}
    }
`;