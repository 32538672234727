import styled from "styled-components";
import { 
	TdStat,
	
	TrRow,
	ButtonLoadMore,
	SwitchButton,
	Tabs,
	TabItem, 
} from "../../../../components";

import { below } from "../../../../assets/css/media";
import colors from "../../../../assets/css/colors";
import Table, {Td} from "../../../../components/TableX";

export const TdShowAvatar = styled(TdStat)`
	${below.phone`
		.player-avatar {
			display: inline;
		}
	`};
`;

export const TdKeptBy = styled(Td)`
	width: 100px;
`;

export const KeptByContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
	font-family: SourceSansPro;
	font-size: 14px;
	color: ${colors.primary.primary};
	font-weight: 700;
	height: 40px;
	span{
		font-size: 12px;
		font-weight: 400;
	}
	${below.phone`
		font-size: 10px;
	`}
`;

export const StyledTabs = styled(Tabs)`
	margin-top:10px;
	border-bottom: 2px solid #F0F3F7;
	justify-content: flex-start;
	${below.phone`
		justify-content: space-evenly;
	`}
`;
export const StyledTab = styled(TabItem)`
	justify-content: center;
	width: 100px;
	span{
		color: ${({ is_warning }) => !is_warning ? colors.primary.primary : "#D92425"};
		display: flex;
		margin-left: 5px;
		svg{
			height: 12px;
			width: 12px;
			margin-left: 2px;
		}
	}
	${below.phone`
		width: ${({ is_keeper }) => is_keeper ? "25%" : "50%"};
		white-space: nowrap;
	`}

`;


export const PreDraftTable = styled(Table)`
	${({ is_compressed }) => is_compressed && `
		.player-info{
			margin-left: 4px;
		}
	`}
	.align-center{
		.column-name{
			text-align: center;
		}
	}
	${below.tablet`
		& td,
		& th {
			position: relative;
			display: table-cell;
		}
	
		.last-right-th{
			position: sticky;
			right: -2px;
			min-width: 50px;
			background: white;
			box-shadow: -3px 2px 30px -17px #333;
			z-index: 2;
			text-transform: capitalize;
			font-family: SourceSansPro;
			font-weight: 600;
			padding-bottom: 2px;
		}
		.last-right-td {
			position: sticky;
			right: -2px;
			min-width: 50px;
			background: white;
			box-shadow: -3px 2px 30px -17px #333;
			z-index: 10;
		}
		.absolute-group th{
			height: 59px;

		}
		.align-center th{
			height: 56px;
		}
		.absolute-group{
			.column-name{
				span{
					text-transform: capitalize;
					font-family: SourceSansPro;
					font-weight: 600;
				}
			}
		}
	`};
`;

export const StyledTrRow = styled(TrRow)`
	
	${below.tablet`
		position: relative;
	`}
`;


export const FcLink = styled.a`
	color: ${colors.coach};
	cursor: pointer;
`;

export const PlayerTableContainer = styled.div`
	overflow-x: auto;
    width: 100%;
    overflow-y: hidden;
	th{

	}
	${below.desktop`
  	  overflow-y: hidden;
	`}
`;

export const StyledButtonLoadMore = styled(ButtonLoadMore)`
	width: 300px;
	height: 40px;
	font-family: SourceSansPro;
	font-weight: 500;
	background-color: #7F8A90;
	color: #fff;
	border-radius: 2px;
	box-shadow: none;
	text-transform: none;
	font-size: 12px;
	position: relative;
	left: 37%;
	margin-top: 20px;
	${below.desktop`
    width: 100%;
		left: 0;
	`}
`;

export const PreDraftSwitchButton = styled(SwitchButton)`
	height: 20px;
	width: ${({ is_compressed }) => is_compressed ? "26px;" : "20px;"};
	padding: 0;
	border-radius: 0;
	margin-top: ${({ is_compressed }) => is_compressed ? "2px;" : "" };
	svg{
		margin-top: ${({ is_compressed }) => is_compressed ? "0px;" : "2px;"};
		height: 20px;
		width: 20px;
		g {
			path{
				fill: #fff
			}
		}
	}
	${below.phone`
		height: 30px;
		width: 30px
	`}
`;
export const PreDraftSwitchButtonsWrap = styled.div`
	display:flex;
	margin-top: ${({ is_compressed }) => is_compressed ? "1px;" : ""};
	margin-left: ${({ is_compressed }) => is_compressed ? "2px" : ""};
	button{
		border-radius: 0px;
	}
`;

export const MoreButtonWrapper = styled.button`
	height: 100%;
	width: 100%;
	outline: none;
	border: none;
	padding: 0px;
	background: none;
	box-shadow: -8px 0px 35px -14px #333;
	svg{
		height: 20px;
		width: 20px;
	}
`;

export const CloseButtonWrapper = styled(MoreButtonWrapper)`
	height: 30px;
	box-shadow: 0;
	width: 30px;
	svg{
		height: 30px;
		width: 30px;
	}
`;
export const MobileActionsWrapper = styled.div`
	width: 190px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-evenly;
	position: absolute;
	top: 0px;
	height: 95%;
	right: 0px;
	background: white;
	box-shadow: -5px 5px 7px -7px #333;

`;

export const IgnoreButtonWrapper = styled.button`
	width: 40px;
	height: 40px;

	font-size: 10px;
	font-family: SourceSansPro;
	font-weight: 600;
	color: ${colors.primary.primary};
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
	border: none;
	background: none;
	label{
		margin: 0 0 0px 0;
	}
`;

export const AbsoluteDiv = styled.td`
	position: sticky !important;
	left: 0;
	background-color: ${({ is_even }) => is_even ? "#f8f8f8" : "#fff"};
	z-index: 2;
	
	${below.phone`
		max-width: 210px;
		box-shadow: 10px 0 14px -14px;
		.player-names{
			max-width: 70px;
		}
		input{
			height: 40px;
		}
	`}
`;

export const TdPlayerSticky = styled(Td)`
	${below.phone`
		max-width: 210px;
	`}
`;

export const TdEmpty = styled(Td)`

`;