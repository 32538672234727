// @flow
import * as React from "react";
import styled from "styled-components";
import * as _ from "lodash";
import *  as moment from "moment-timezone";

import type { TMatch } from "../../modules/types";
import colors from "../../assets/css/colors";
import { getClubLogoIndigenous } from "../../utils/clubLogos";
import ButtonMatchUp from "../ButtonMatchUp";
import { default_transition } from "../../assets/css/vars";
import { below } from "../../assets/css/media";

window.moment = moment;

const TotalScore = styled.div`
	color: ${({ loser }) => loser ? colors.secondary.accentGrey : colors.primary.darkGrey};
	font-size: ${({ small }) => small ? 13 : 36 }px;
	font-weight: 700;
	${below.tablet`
		font-size: ${({ small }) => small ? 10 : 20 }px;
		max-width: 100px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	`}
	${below.phone`
		> span {
			display: none;
		}
	`}
`;
const SubScore = styled.div`
	color: ${colors.secondary.accentGrey};
	font-size: 13px;
	line-height: 16px;
`;
const ScoreWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	flex: 1 0 0px;
`;
const LogoWrapper = styled.div`
	img {
		height: 100px;
		width: 100px;
	}
`;
const ResultDescription = styled.div`
	margin-top: 8px;
	font-size: 13px;
	color: ${colors.primary.darkGrey};

	${below.tablet`
		font-size: 10px;
	`}
`;
const ResultWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	a {
		cursor: default;
		${below.tablet`
			height: 20px;
			font-size: 10px;
			max-width: 80px;
    		min-width: auto;
		`}
	}
`;

const MatchPanelWrapper = styled.div`
	height: 100%;
	box-shadow: 0 0 10px 0 rgba(127,138,144,0.5);
	border-radius: 5px;
	opacity: ${({ is_active }) => is_active ? 1 : 0.5 };
	display: flex;
	justify-content: space-around;
	align-items: center;
	padding: 0 15px;
	box-sizing: border-box;
	${default_transition};
`;

const MarginWrapper = styled.div`
	padding: 0 5px;
	box-sizing: border-box;
`;

const getMatchTime = (match_time, venue_tz) => {
	if (_.isEmpty(venue_tz)) {
		return match_time 
			? moment(match_time).format("hh:mm A") 
			: "";
	}
	return match_time 
		? moment(match_time).tz(venue_tz).format("hh:mm A z") 
		: "";
};

type TGameClock = { 
	// The game's period (i.e. Q1)
	p: string, 
	// The seconds the current period has run for
	s: string 
};

const QUARTER_NAMES = {
	"1": "QT",
	"2": "HT",
	"3": "3QT",
	"4": "FT",
};

const getFinishedQuarterName = (period: string) => {
	return _.get(QUARTER_NAMES, _.last(period), period);
};

const getLiveMatchTime = (clock: TGameClock) => {
	if (_.isEmpty(clock)) {
		return "Live";
	}

	const seconds = _.get(clock, "s", "0");
	const period = _.get(clock, "p", "--");
	const quarter_time = moment(0, "m")
		.add(seconds, "s")
		.format("mm:ss");

	// seconds are -1 when quarted is over
	if (seconds === -1) {
		return getFinishedQuarterName(period);
	}

	return `${period} ${quarter_time}`;
};

type Props = {
	match: TMatch,
	is_active: boolean,
	venues: Object,
	squads: Object,
	actual_round: Object,
}

type State = {

}

export class MatchPanel extends React.Component<Props, State> {
	static defaultProps = { 
		is_active: false
	};
	renderMatchupButton() {
		const { match, venues } = this.props;
		const status = _.get(match, "status", "scheduled");
		let button_type = "";
		let button_text = "";

		if (status === "scheduled") {
			const match_time = _.get(match, "date");
			const venue_id = _.get(match, "venue_id");
			const venue = _.get(venues, venue_id);
			const venue_tz = _.get(venue, "timezone");
			button_text = getMatchTime(match_time, venue_tz);
		}
		else if (status === "playing") {
			const clock = _.get(match, "clock");

			button_type = "live";
			button_text = getLiveMatchTime(clock);
		}
		else if (status === "complete") {
			button_type = "full_time";
			button_text = "Full Time";
		}

		return <ButtonMatchUp type={button_type} to="#" no_arrow>
			{button_text}
		</ButtonMatchUp>;
	}
	renderResultDescription() {
		const { match } = this.props;
		const status = _.get(match, "status", "scheduled");
		let text = _.get(match, "venue_name", "");

		if (status !== "scheduled") {
			const lead = Math.abs(match.home_score - match.away_score);

			const leader = match.home_score > match.away_score
				? match.home_squad_name
				: match.away_squad_name;
			
			text = `${leader} by ${lead}`;

			if (lead === 0) {
				text = status !== "complete" ? "Match Drawn" : "Scores Even";
		   }

		}

		return <ResultDescription>
			{text}
		</ResultDescription>;
	}
	render() {
		const { match, is_active, actual_round } = this.props;

		if (_.isEmpty(match)) {
			return <div />;
		}

		const status = _.get(match, "status", "scheduled");
		const homeLogo = getClubLogoIndigenous(actual_round.id, match.home_squad_id);
		const awayLogo = getClubLogoIndigenous(actual_round.id, match.away_squad_id);
		return <MarginWrapper><MatchPanelWrapper is_active={is_active}>
			{/* Home logo */}
			<LogoWrapper>
				<img src={homeLogo} alt="Team logo" />
			</LogoWrapper>
			<ScoreWrapper>
				{status === "scheduled"
					? <TotalScore small>
						<span>{match.home_squad_name}</span>
					</TotalScore>
					:  <React.Fragment>
						<TotalScore 
							loser={match.home_score < match.away_score}
						>
							{match.home_score}
						</TotalScore>
						<SubScore>{match.home_goals}.{match.home_behinds}</SubScore>
					</React.Fragment>}
			</ScoreWrapper>
			{/* Home score */}
			{/* result */}
			<ResultWrapper>
				{ this.renderMatchupButton() }
				{ this.renderResultDescription() }
			</ResultWrapper>
			{/* Away score */}
			{/* Away logo */}
			<ScoreWrapper>
				{status === "scheduled"
					? <TotalScore small>
						<span>{match.away_squad_name}</span>
					</TotalScore>
					:  <React.Fragment>
						<TotalScore
							loser={match.away_score < match.home_score}
						>
							{match.away_score}
						</TotalScore>
						<SubScore>{match.away_goals}.{match.away_behinds}</SubScore>
					</React.Fragment>}
			</ScoreWrapper>
			<LogoWrapper>
				<img src={awayLogo} alt="Team logo" />
			</LogoWrapper>

		</MatchPanelWrapper></MarginWrapper>;
	};
}

export default MatchPanel;