// @flow
import * as React from "react";
import styled from "styled-components";
import { below } from "../../../../assets/css/media";
import colors from "../../../../assets/css/colors";
import type { TPLeague, TRound } from "../../../../modules/types";

import {
	TableGridRow,
	TableFlexCell,
	LeagueAvatar,
	LeagueName,
	ButtonSecondary,
} from "../../../../components";

import OverflowHidden from "../../../../components/OverflowHidden";
import { getTeamProjectedScore } from "../../../../helpers/team";
import Fixture from "../../../../components/Fixture";

const LeagueNameWrapper = styled(OverflowHidden)`
	img {
		margin: 0px 30px 0 10px;
	}
	${below.desktop`
		display: flex;
		align-items: center;
		justify-content: center;

		.league-name {
			text-align: left;
		}

		p {
			margin-left: 10px;
		}
	`}

	${below.small_phone`
		.league-name p {
			width: 100px;
		}
	`}
`;

const TableFlexCellStyled = styled(TableFlexCell)`
	flex-direction: column;
	text-transform: uppercase;

	p {
		font-size: 12px;
		text-transform: uppercase;
		margin-bottom: 1em;
		color: ${colors.secondary.accentGrey};
	}
`;

const LeagueOverview = styled.a`
	outline: none;
	border: none;
	background: ${colors.buttons.baseColor};
	border: 1px solid ${colors.buttons.baseColor};
	color: #ffffff;
	font-size: 14px;
	letter-spacing: 0.5px;
	line-height: 1;
	padding: 12px;
	cursor: pointer;
	width: 140px;
	border-radius: 2px;
	text-decoration: none;
`;

const isByeRoundForLeague = (league: TPLeague, round: TRound) => {
	const league_plays_bye_rounds = Boolean(league.bye_rounds);
	const is_bye_round = Boolean(round.is_bye);

	return is_bye_round && !league_plays_bye_rounds;
};

const JoinLeagueGridRow = styled(TableGridRow)`
	${below.desktop`
		grid-template-columns: auto 80px 80px;
	`}
`;

type Props = {
	league: TPLeague,
	is_commissioner?: boolean,
	user?: {
		id: number,
		firstname: string,
		lastname: string,
		avatar_version: number,
	},
	round?: TRound,
	players_by_id?: {},
	has_assistant_coach?: boolean,
	league_type?: string,
	handleLeagueSettingsPopup?: Function,
};

export class ShowLeagueRow extends React.Component<Props> {
	get getFixture() {
		const {
			league,
			user,
			round,
			players_by_id,
			has_assistant_coach,
		} = this.props;

		const is_bye_round_for_league = isByeRoundForLeague(league, round);

		if (
			league &&
			league.opponent &&
			!is_bye_round_for_league &&
			user &&
			players_by_id &&
			round
		) {
			const opponent = league.opponent;
			const home_lineup = league.my_lineup;
			const away_lineup = opponent.lineup;

			const home_lineup_project_score =
				getTeamProjectedScore(home_lineup, players_by_id, league, round, true);
			const away_lineup_project_score =
				getTeamProjectedScore(away_lineup, players_by_id, league, round, true);

			const fixture = {
				home: {
					id: league.team_id,
					name: league.team_name,
					firstname: user.firstname,
					lastname: user.lastname,
					points: league.team_points,
					projected_points: home_lineup_project_score,
					lineup: league.my_lineup,
					rank: league.rank,
					user_id: user.id,
					avatar_version: user.avatar_version
				},
				away: {
					id: opponent.team_id,
					name: opponent.team_name,
					firstname: opponent.firstname,
					lastname: opponent.lastname,
					points: opponent.score,
					projected_points: away_lineup_project_score,
					lineup: opponent.lineup,
					rank: opponent.rank,
					user_id: opponent.user_id,
					avatar_version: opponent.avatar_version
				}
			};

			return (
				<Fixture
					fixture={fixture}
					league={league}
					round={round}
					has_assistant_coach={has_assistant_coach}
					compact={true}
				/>
			);
		}

		return null;
	};

	render() {
		const {
			league,
			is_commissioner,
			league_type,
			handleLeagueSettingsPopup,
		} = this.props;
		return(
			<React.Fragment>
				<JoinLeagueGridRow
					key={league.id}
					columns="auto 80px 80px 80px 80px 80px 250px"
					height="60px"
				>
					<LeagueNameWrapper role="cell" className="hidden-mobile">
						<LeagueAvatar is_classic league={league} size="60px" />
						<LeagueName
							name={league.name}
							width="90%"
							lastname={league.lastname}
							firstname={league.firstname}
						/>
					</LeagueNameWrapper>
					<LeagueNameWrapper role="cell" className="hidden-desktop">
						<LeagueName
							className="league-name"
							name={league.name}
							width="90%"
							lastname={league.lastname}
							firstname={league.firstname}
						/>
					</LeagueNameWrapper>
					<TableFlexCellStyled role="cell" className="hidden-mobile">
						<p>Type</p>
						{league.type === "head_to_head" ? "H2H" : "Open"}
					</TableFlexCellStyled>
					<TableFlexCellStyled role="cell" className="hidden-mobile">
						<p>Teams</p>
						{ league.num_teams }
					</TableFlexCellStyled>
					<TableFlexCellStyled role="cell" className="hidden-mobile">
						<p>Size</p>
						{ league.type === "head_to_head" ? league.size : "--" }
					</TableFlexCellStyled>
					<TableFlexCellStyled role="cell" className="hidden-mobile">
						<p>Starting</p>
						ROUND { league.start_round }
					</TableFlexCellStyled>
					<TableFlexCellStyled role="cell" className="hidden-mobile">
						<p>RANKING</p>
						{ league.rank || "--" }
					</TableFlexCellStyled>
					<TableFlexCellStyled role="cell">
						{league_type !== "unscheduled" ? (
							<LeagueOverview
								href={
									is_commissioner ?
										`/classic/league/${league.id}/settings` :
										`/classic/league/${league.id}/about`
								}
								className="hidden-mobile"
							>
								League overview
							</LeagueOverview>
						) : (
							<ButtonSecondary
								onClick={() => {
									if(handleLeagueSettingsPopup) {
										handleLeagueSettingsPopup({ id: league.id });
									}
								}}>
								View league details
							</ButtonSecondary>
						)}
					</TableFlexCellStyled>
				</JoinLeagueGridRow>
				{league_type === "live" ? this.getFixture : null}
			</React.Fragment>
		);
	}
}

export default ShowLeagueRow;