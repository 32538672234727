// @flow
import styled from "styled-components";
import {below} from "../../assets/css/media";

export const PlayerImgContainer = styled.div`
	position: absolute;
	top: 0;
    left: 50%;
    transform: translate(-50%,-35%);
	height: 300px;

    img {
    	height: 100%;
	    display: block;
	    margin: 0 auto;
    }
    
    &:after {
			background: linear-gradient(to bottom,rgba(255,255,255,0) 90%,#fff 100%);
    	content: '';
    	display: block;
    	width: 100%;
    	height: 100%;
    	position: absolute;
    	left: 0;
    	top: 1px;
    }
	${below.phone`
		width: 300px;
	`}
`;

export default PlayerImgContainer;