// @flow
import * as React from "react";
import styled from "styled-components";

import colors from "../../assets/css/colors";
import { RotateWrapper } from "../RotateWrapper";
import { Rotate } from "../Rotate";

const LeagueTypeLabelStyled = styled(RotateWrapper)`
	width: 20px;
	height: 60px;
	background-color: ${({ background_color }) => background_color || "#C0C0C0"};
	border-bottom: 1px solid ${colors.primary.accent};
	box-sizing: border-box;

	> div {
		box-sizing: border-box;
		color: #fff;
		width: 60px;
		height: 20px;
		font-size: 11px;
		letter-spacing: 0.5px;
		line-height: 1;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
	}
`;


type Props = {
	text: string,
	rotate: string,
	background_color?: ?string,
	className?: string
};

export const LeagueTypeLabel = ({ text, rotate, background_color, className }: Props) => (
	<LeagueTypeLabelStyled background_color={background_color} className={className}>
		<Rotate rotate={rotate}>
			{text}
		</Rotate>
	</LeagueTypeLabelStyled>
);

export default LeagueTypeLabel;