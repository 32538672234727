// @flow
import React from "react";
import styled from "styled-components";
import colors from "../../assets/css/colors";
import LockCircle from "../Icons/LockCircle";
import { below } from "../../assets/css/media";
import { PlayerWrapper } from "./PlayerWrapper";
import AddCard from "./AddCard";

const AddPlayerWrapper = styled(PlayerWrapper)`
  display: flex;
  flex-direction: row;
  width: 620px;
	min-width: unset;
  position: relative;
  svg{
    position: absolute;
    cursor: pointer;
    width: 30px;
    height: 30px;
    left: 50%;
    margin-left: -15px;
    g path{
      fill: black;
    }
  }

	${below.phone`
		width: 50%;
		height: 90px;
	`}
`;

type Props = {
	has_coach: boolean,
	show: boolean,
	toggleFantasyCoachModal: Function
};

export const CompareAddPlayer = ({ has_coach, show, toggleFantasyCoachModal }: Props) => {
	return !show ? null : <AddPlayerWrapper>
		<AddCard
			color={colors.secondary.paleGrey}
			background={colors.form.base}
			show_plus={has_coach}
			toggleFantasyCoachModal={toggleFantasyCoachModal}
			borderLeft={true}
		/>
		<LockCircle onClick={toggleFantasyCoachModal}/>
		<AddCard
			color={colors.secondary.paleGrey}
			background={colors.form.base}
			show_plus={has_coach}
			toggleFantasyCoachModal={toggleFantasyCoachModal}
			borderRight={true}
		/>
	</AddPlayerWrapper>;
};

export default CompareAddPlayer;