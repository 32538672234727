// @flow
import * as React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import colors from "../../assets/css/colors";
import { below } from "../../assets/css/media";

import Bell from "../Icons/Bell";
import BellOutline from "../Icons/BellOutline";

const Container = styled.div`
	box-sizing: border-box;
	flex: 0 0 300px;
	display: flex;
	align-content: center;
	justify-content: center;

	${below.field_view_large`
		flex: 0 0 250px;
	`}

	${below.desktop`
		flex: 0 0 35px;
	`}
`;

const NotificationsLink = styled(Link)`
	display: flex;
	text-decoration: none;

	color: ${colors.primary.primary};
	&.has-notifications {
		color: ${colors.primary.accent};
	}
`;

const IconWrapper = styled.div`
	font-size: 30px;
	display: flex;
	align-items: center;
	svg{
		height: 20px
	}
`;

const Text = styled.div`
	margin-left: 2px;
	display: flex;
	align-items: center;
	font-size: 10px;
	margin-top: 2px;
	:hover {
		text-decoration: underline;
	}

	${below.desktop`
		display: none;
	`}
`;

const CountWrapper = styled.div`
	background: ${colors.primary.accent};
	color: ${colors.white};

	display: flex;
    align-items: center;
    justify-content: center;

	border-radius: 50%;
    height: ${({ big }) => big ? "30px" : "25px"};
    width:  ${({ big }) => big ? "30px" : "25px"};

	box-sizing: border-box;
	margin: 0 5px 4px;
    padding: 4px 2px 2px;
`;

type Props = {
	count: number,
	to: string,
	show_notifications?: boolean
}

const Notifications = ({ count, to, show_notifications }: Props) => {
	const has_notifications = count > 0;
	const is_singular = count === 1;

	return (
		<Container>
			{show_notifications ? (
				<NotificationsLink
					to={to}
					className={has_notifications ? "has-notifications" : ""}
				>
					<IconWrapper>
						{has_notifications ? <Bell /> : <BellOutline />}
					</IconWrapper>
					<Text>
						{has_notifications ? (
							<React.Fragment>
								You have
								<CountWrapper big={count >= 10}>{
									count > 99 ? "99+" : count
								}</CountWrapper>
								{is_singular ? "notification" : "notifications"}
							</React.Fragment>
						) : "You have no notifications"}
					</Text>
				</NotificationsLink>
			) : null}
		</Container>
	);
};

export default Notifications;