//@flow
import * as React from "react";
import styled from "styled-components";
import colors from "../../assets/css/colors";
import { default_transition } from "../../assets/css/vars";

const RoundItemWrapper = styled.button`
	height: 32px;
	width: 32px;
	border-radius: 50%;
	margin: 2px;
	border: none;
	padding: 0;
	background: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 15px;
	outline: none;
	cursor: ${({ disabled }) => disabled ? "default" : "pointer"};
	user-select: none;
	box-sizing: border-box;
	padding-top: 0.2em;
	${default_transition};
	&.selected{
		background: ${colors.primary.primary};
		color: #fff;
		:hover:not(:disabled) {
			background: ${colors.primary.accent};
		}
	}
	&.complete{
		color: ${colors.secondary.accentGrey};
		background: #fff;
	}
	&.scheduled, &.complete {
		&:hover:not(:disabled) {
			background: ${colors.secondary.paleGrey};
		}
	}
	${({ disabled }) => disabled ? `color: ${colors.secondary.paleGrey}` : ""}
`;

const FlexWrapper = styled.div`
	flex: 1 0 0px;
	max-width: 50px;

	display: flex;
    justify-content: center;
`;

type Props = {
	round_number?: number | null,
	round_status?: string | null,
	selected_round: number | null,
	selected_round_index: number | null,
	is_first: boolean,
	handleClick: Function,
	disabled?: boolean,
	round_alias?: any
}

export const RoundSelectorItem = ({
	selected_round, round_number, round_status,
	handleClick, selected_round_index, is_first, disabled, round_alias
}: Props) => {
	const active_class_name =
		selected_round === round_number ? "selected" : round_status;
	const first_one_class = is_first ? "first" : "";
	const class_name = active_class_name ? active_class_name + " " + first_one_class : "";
	return <FlexWrapper>
		<RoundItemWrapper
			key={round_number}
			className={class_name}
			onClick={disabled ? undefined : (() => handleClick(round_number))}
			selected_round={selected_round}
			index={selected_round_index}
			disabled={disabled}
		>
			{round_alias || round_number}
		</RoundItemWrapper>
	</FlexWrapper>;
};

RoundSelectorItem.defaultProps = {
	round_number: 1,
	selected_round: 1,
	round_status: "active"
};
export default RoundSelectorItem;