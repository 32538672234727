// @flow
import * as React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import styled from "styled-components";
import { redirectTo } from "../../../utils/url";
import * as actions from "../../../modules/actions";
import {
	Footer,
	PageTitle,
	ButtonLoadMore,
	SearchForm,
	TwoColumnLayout,
	Ad,
	PageContentWrapper,
	StandardSidebar,
	AdsContainer,
	ModalContainer
} from "../../../components";


import { below } from "../../../assets/css/media";
import colors from "../../../assets/css/colors";

import { RoundSelect } from "../league/LeagueEditPicks/LeagueEditPicksStyle";
import JoinLeagueLayout from "./leaguesJoin/JoinLeagueLayout";
import JoinLeagueList from "./leaguesJoin/JoinLeagueList";
import JoinLeaguePopup from "./leaguesJoin/JoinLeaguePopup";
import JoinLeagueSettingsPopup from "./leaguesJoin/JoinLeagueSettingsPopup";

const PageTitleStyled = styled(PageTitle)`
	font-size: 32px;
	display: flex;
	align-items: flex-end;

	svg{
		margin-right: .2em;
	}
`;

const NotFound = styled.div`
	color: ${colors.title};
`;

const ModalMsg = styled.div`
	margin: 20px 0;
`;

const SearchWrap = styled.div`
	display: flex;
	margin: 0 0 1em;
	${below.phone`
		flex-direction: column;
	`}
`;

const SearchFormFlex = styled.div`
	flex:1;
`;

const RoundSelectMargin = styled(RoundSelect)`
	margin: 0 20px;
	${below.phone`
		margin: 10px 0;
	`}
`;


type Props = {
	showToJoinDraftLeagues: Function,
	showToJoinLoading: boolean,
	fetchMyDraftLeagues: Function,
	showToJoinLoadMoreDraftLeagues: Function,
	clearJoinToDraftLeagueErrors: Function,
	joinToDraftLeague: typeof actions.leagueDraft.joinToDraftLeague,
	leagues_to_join: Array<Object>,
	is_load_more: boolean,
	join_to: Object,
	offset: number,
	search: string,
	history: {
		push: Function
	},
	match: {
		params: {
			code: string
		}
	},
	leagues: Object,
}

type State = {
	search_value: string,
	filter_size: string,
	filter_num_teams: string,
	is_show_join_popup: boolean,
	is_show_settings_popup: boolean,
	league_to_join: Object,
	showLoader: boolean
}

const OFF_GROWTH = 10;

class DraftLeaguesJoinPage extends React.Component<Props, State> {
	state = {
		search_value: "",
		filter_size: "",
		filter_num_teams: "",
		is_show_join_popup: false,
		is_show_settings_popup: false,
		league_to_join: {},
		showLoader: true
	};

	componentDidMount() {
		this.joinOnLink();
		this.triggerSearch();
	}

	componentDidUpdate(prev_props,prev_state) {
		const is_prev_league_id_null = prev_props.join_to.league_id === null,
			is_league_id_null = this.props.join_to.league_id === null,
			is_not_equal = prev_props.join_to.league_id !== this.props.join_to.league_id;

		if (is_prev_league_id_null && !is_league_id_null && is_not_equal) {
			redirectTo(`/draft/league/${this.props.join_to.league_id}/team-name/join`);
		}

		if (
			this.props.search !== prev_props.search ||
			this.state.filter_size !== prev_state.filter_size ||
			this.state.filter_num_teams !== prev_state.filter_num_teams
		) {
			this.triggerSearch();
		}
	}
	

	joinOnLink() {
		const {
			match: {
				params
			}
		} = this.props;

		if(params && params.code) {
			this.props.joinToDraftLeague({ code: params.code });
		}
	}

	triggerSearch() {
		this.setState({
			search_value: this.props.search
		});
		this.props.showToJoinDraftLeagues({ 
			offset: 0,
			size:this.state.filter_size,
			order_by:this.state.filter_num_teams,
			q: this.props.search
		});
	}

	join = id => {
		this.props.joinToDraftLeague({ league_id: id });
	};

	handleSearchSubmit(api_params) {
		// history.push decodes any encoded characters (i.e. '10%25' -> '10%')
		// Raw '%' chars break router on reloads/back navigation
		// Need to double encode everything as a work around
		const search = encodeURIComponent(encodeURIComponent(api_params.q)) || "";

		if(search && search !== "undefined") {
			this.props.history.push(`/draft/leagues/join/search/${search}`);
		}
		else {
			this.props.history.push("/draft/leagues/join");
		}
	};

	handleLeagueJoinPopup({ id }: Object) {
		const { leagues_to_join } = this.props,
			league_to_join = _.find(leagues_to_join, { id });

		this.setState({
			is_show_join_popup: true,
			league_to_join
		});
	}

	handleLeagueSettingsPopup({ id }: Object) {
		const { leagues_to_join } = this.props,
			league_to_join = _.find(leagues_to_join, { id });
		if(document.body){
			document.body.classList.add("modal-open");
		}
		this.setState({
			is_show_settings_popup: true,
			league_to_join
		});
	}

	handleLeagueJoinPopupHide() {
		this.setState({
			is_show_join_popup: false,
			league_to_join: {}
		});
	}

	handleLeagueSettingsPopupHide() {
		if(document.body){
			document.body.classList.remove("modal-open");
		}
		
		this.setState({
			is_show_settings_popup: false,
			league_to_join: {}
		});
	}

	loadMore() {
		const { offset } = this.props;

		this.props.showToJoinLoadMoreDraftLeagues({
			offset: offset + OFF_GROWTH,
			q: this.state.search_value
		});
	}

	handleUpdateOrderBy(e) {
		this.setState({
			filter_num_teams: e.target.value
		});
	}

	handleUpdateSize(e) {
		this.setState({
			filter_size: e.target.value
		});
	}

	render() {
		const { leagues_to_join, is_load_more, search, join_to } = this.props;
		const { is_show_join_popup, league_to_join, is_show_settings_popup } 
			= this.state;

	
		return (
			<React.Fragment>
				<AdsContainer>
					<Ad />
				</AdsContainer>
				<PageContentWrapper>
					<PageTitleStyled>
						Join a Draft League
					</PageTitleStyled>
					<TwoColumnLayout>
						<JoinLeagueLayout>
							<SearchWrap>
								<SearchFormFlex>
									<SearchForm
										placeholder=
											"Search for a Public League or enter your Private PIN"
										field_name="q"
										initialValues={{ q: search }}
										onSubmit={this.handleSearchSubmit.bind(this)}
										onChange={this.handleSearchSubmit.bind(this)}
									/>
								</SearchFormFlex>
								<RoundSelectMargin
									onChange={this.handleUpdateOrderBy.bind(this)}
									value={this.state.filter_num_teams}
								>
									<option value="">Teams Entered: High-Low</option>
									<option value="num_teams_desc">Highest</option>
									<option value="num_teams">Lowest</option>
								</RoundSelectMargin>
								<RoundSelect
									onChange={this.handleUpdateSize.bind(this)}
									value={this.state.filter_size}
								>
									<option value="">All Sizes</option>
									{[4,6,8,10,12,14,16,18,20].map(num => <option 
										key={num}
										value={num}>
										{num}
									</option>)}
								</RoundSelect>
							</SearchWrap>

							<JoinLeagueList
								handleLeagueJoinPopup={this.handleLeagueJoinPopup.bind(this)}
								handleLeagueSettingsPopup={
									this.handleLeagueSettingsPopup.bind(this)}
								leagues_list={leagues_to_join}
								join={this.join}
							/>

							{!leagues_to_join.length ? (
								<NotFound>
								Sorry, we couldn't find any Draft leagues
								that match that invite code or league name.
								This is the Draft Leagues page.
								Please check the code and the game type and try again.
								</NotFound>
							) : null}


							{is_show_join_popup ? (
								<JoinLeaguePopup
									onClick={this.handleLeagueJoinPopupHide.bind(this)}
									join={this.join}
									league={league_to_join}
								/>
							) : null}

							{is_show_settings_popup ? (
								<JoinLeagueSettingsPopup
									join={this.join}
									onClick={this.handleLeagueSettingsPopupHide.bind(this)}
									league={league_to_join}
								/>
							) : null}

							{is_load_more ? (
								<ButtonLoadMore onClick={this.loadMore.bind(this)}>
								View more leagues
								</ButtonLoadMore>
							) : null}
						</JoinLeagueLayout>
						<StandardSidebar />
					</TwoColumnLayout>
				</PageContentWrapper>
				<Footer />
				{join_to.error ? <ModalContainer
					onClick={this.props.clearJoinToDraftLeagueErrors}
					is_sponsored={true}
				>
					<ModalMsg>{join_to.error}</ModalMsg>
				</ModalContainer>:null}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state, props) => {
	const offset = state.leagues.show_to_join.offset;
	const leagues_to_join = state.leagues.show_to_join.ordered_ids || [];
	let search = props.match.params.search;
	if (search) {
		try {
			search = decodeURIComponent(search);
		} 
		catch (error) { /* Don't worry, I guess */}
	}
	return {
		leagues_to_join: leagues_to_join.map(
			league_id => state.leagues.by_id[league_id]
		),
		is_load_more: leagues_to_join.length >= offset + OFF_GROWTH,
		join_to: state.leagues.join_to,
		offset,
		search,
		showToJoinLoading: state.leagues.show_to_join.is_pending
	};
};

const mapDispatchToProps = {
	showToJoinDraftLeagues: actions.leagueDraft.showToJoinDraftLeagues,
	showToJoinLoadMoreDraftLeagues: actions.leagueDraft.showToJoinLoadMoreDraftLeagues,
	joinToDraftLeague: actions.leagueDraft.joinToDraftLeague,
	clearJoinToDraftLeagueErrors: actions.leagueDraft.clearJoinToDraftLeagueErrors,
};

export const DraftLeaguesJoin = connect(
	mapStateToProps,
	mapDispatchToProps
)(DraftLeaguesJoinPage);

export default DraftLeaguesJoin;