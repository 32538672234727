// @flow
import styled from "styled-components";
import { below } from "../../assets/css/media";

export const EmptyPlayerStyled = styled.div`
	width: 164px;
	height: 90px;
	margin: 10px 5px;
	box-sizing: border-box;
	border-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	flex-direction: column;
	cursor: pointer;
	z-index: 2;
	
	${below.field_view_large`
		width: 144px;
	`};

	${below.field_view_mid`
		width: 134px;
		margin: 10px ${({ is_draft }) => is_draft ? "5px" : "1px"};

	`};
`;

export default EmptyPlayerStyled;