// @flow
import styled from "styled-components";
import colors, { lightenColor } from "../../assets/css/colors";


export const UndoButton = styled.button`
	width: 30px;
	height: 30px;
	background: none;
	padding: 0;
	border: 0;
	border-radius: 2px;
	color: #fff;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	transition: background-color 0.2s linear;
	margin-right: 20px;

	svg {
		height: 22px;
		width: 22px;
		background-color: #7F8A90;
		border: none;
		border-radius: 15px;
	}

	:hover {
		background-color: ${lightenColor(colors.warning, 0.2)};
	}
`;

export default UndoButton;