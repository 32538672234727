/* eslint-disable max-len */
// @flow
import * as React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import styled from "styled-components";
import _, { get } from "lodash";
import { Redirect } from "react-router-dom";
import { below } from "../../../assets/css/media";
import { scoreflowToForm } from "../../../helpers/league";
import * as selectors from "../../../modules/selectors";
import * as actions from "../../../modules/actions";
import { withDraftCore, RoundSelector, PageTitle, Preloader, Exist } from "../../../components";
import type { TPLeague, TRoundsById, TRound, TPlayersById, TDraftTeamsById } from "../../../modules/types";
import {
	AdsContainer,
	Footer,
	PageContentWrapper,
	StandardSidebar,
	TwoColumnLayout,
	LeagueGameBar,
	ButtonFixtureMatchUp
} from "../../../components";
import Tick from "../../../components/Icons/Tick";
import Ad from "../../../components/Ad";
import FixtureTeam from "../../../components/Fixtures/FixtureTeam";
import { getLeagueRoundAliases } from "../../../helpers/league";
import colors from "../../../assets/css/colors";
import { isMobile } from "../../../utils";
import { isAllTrue, isAnyTrue, isRound7Started } from "../../../helpers";
import JsonFetcher from "../../../components/utils/JsonFetcher";

const PageHeaderWrapper = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	align-items: center;
	justify-content: space-between;
	${below.phone`
		.round-selector{
			width: 100%;
			select{
				width: 100%;
			}
		}
	`}
`;

const EditScoresWrapper = styled.div`
  margin-bottom: 20px;
`;

const MatchupsWrapper = styled.div`
  width: 100%;

`;

const StyledMatchupButton = styled(ButtonFixtureMatchUp)`
  a{
    ${below.phone`
      width: 30px;
    `}
  }
  
`;


const EditAvailableText = styled.div`
  font-family: SourceSansPro;
  text-align: center;
  width: 100%;
  margin-top: 50px;
  font-size: 24px;
  font-weight: 600;
  color: ${colors.primary.primary};
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: #F0F3F7;
`;

const Round6Note = styled(EditAvailableText)`
  font-size: 18px;
  line-height: 20px;
  height: fit-content;
  background: transparent;
  padding: 0 20px;
  margin-top: 20px;
`;
const ResetButton = styled.button`
  color: #D92425;
  font-family: SourceSansPro;
  font-size: 12px;
  font-weight: 400;
  outline: none;
  border: none;
  background-color: transparent;
  position: absolute;
  top: 30px;
  ${below.phone`
    width: 100px;
    padding-top: 9px;
  `}
  cursor: pointer;
  :hover{
    text-decoration: underline;
  }
`;

const SaveButton = styled.button`
  color: ${colors.primary.primary};
  font-family: SourceSansPro;
  font-size: 14px;
  font-weight: 400;
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
  :hover{
    text-decoration: underline;
  }
  margin: 0 21px 20px 0; 
  ${below.phone`
    margin: 0 5px 20px 0;
    width: 100px;
    
  `}
`;

const Matchup = styled.div`
  width: 100%;
  height: 89px;
  margin-bottom: 10px;
  background-color: #F0F3F7;
  border: 1px solid #CAD2D8;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  .fixture-team{
    ${below.phone`
      width: 75px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap
    `}
  }
  ${below.phone`
    .fixture-team:first-child{
      padding-left: 5px;
    } 
    .fixture-team:last-child{
      padding-right: 5px;
    } 
  `}
  
`;

const CenterButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  position: relative;
  a{
    width: 80px;
    ${below.phone`
      width: 30px;
    `}
  }
`;

const ControlsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${below.phone`
    width: 100%;
  `}
`;

const TeamInput = styled.input`
  width: 80px;
  height: 40px;
  color: ${colors.primary.primary};
  font-family: SourceSansPro;
  font-weight: ${({ is_original_score }) => is_original_score ? "400" : "700"};
  border: 1px solid #CAD2D8;
  border-radius: 0px;
  text-align: center;
  ${below.phone`
    width: 52px;
    height: 40px;
    font-size: 12px;
  `}
`;

const SaveButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  svg{
    margin-bottom: 18px;
    fill: ${colors.primary.accentGreen};
  }
`;


type Props = {
	selected_league_id: number,
	fetchLeague: typeof actions.leagueDraft.showDraftLeague,
	showTeamsDraftLeague: typeof actions.leagueDraft.showTeamsDraftLeague,
	draftLeagueTeamUpdate: typeof actions.leagueDraft.draftLeagueTeamUpdate,
	fetchDraftLadder: typeof actions.leagueDraft.ladderDraftLeague,
  clearTeamOverrideScores: Function,
  rounds_by_id: TRoundsById,
	user_id: number,
	league: TPLeague,
	teams: Array<TDraftTeamsById>,
	teams_by_id: TDraftTeamsById,
  teams_override_by_id: Object,
	error?: string,
	players_by_id: TPlayersById,
	players_arr: Array<Object>,
	fetchWaiverFree: Function,
  selected_round_id: number,
  rounds_ordered: Object,
  setSelectedRoundId: Function,
  fetchTeamOverrideScores: Function,
  ladder_teams_by_id: TDraftTeamsById,
  actual_round: TRound,
  teamOverrideScoresUpdate: Function,
  save_success: boolean,
	match: {
		params: {
			league_id: string
		}
	},

}


type State = {
	current_edit_team_id: number,
  chosen_round_id: number,
  team_scores: Object,
};

class EditScoresComponent extends React.Component<Props, State> {

	constructor(props, state) {
		super(props, state);

		_.bindAll(this, [
			"handleEditScoreChange",
			"handleSaveScores",
			"handleChosenRoundId"
		]);
	}

	state = {
		current_edit_team_id: 0,
		chosen_round_id: 1,
		team_scores: this.props.teams_override_by_id,
		...this.props.teams_override_by_id,

	};
	componentDidMount() {
		const { league_id } = this.props.match.params;
    
		const { fetchTeamOverrideScores, 
			fetchLeague,
			fetchDraftLadder
		} = this.props;
		fetchLeague({ id: league_id });
		fetchTeamOverrideScores({ league_id: league_id, round_id: this.state.chosen_round_id });
		fetchDraftLadder({league_id});
	}
    
    

	componentDidUpdate(prev_props, prevState) {
		const { selected_league_id } = this.props;
		
		let currentFixtureStart = 1;
		if(!_.isEmpty(this.props.league.fixture)){
			currentFixtureStart = Number(Object.keys(this.props.league.fixture)[0]);
		}
		


		if(isAllTrue([
			this.state.chosen_round_id !== currentFixtureStart ,
			this.state.chosen_round_id === 1,
		])){
			this.setState({
				chosen_round_id: currentFixtureStart
			});
		}
		if (
			selected_league_id !== prev_props.selected_league_id
		) {
			this.fetchLeague();
			this.fetchTeamOverrideScores();
		}
		else if (
			this.state.chosen_round_id !== prevState.chosen_round_id
		) {
			this.fetchTeamOverrideScores();
		}
		if(prev_props.teams_override_by_id !== this.props.teams_override_by_id){
			const { teams_override_by_id } = this.props;
			const { chosen_round_id } = this.state;
			
			let initialState = {};
			// map through, store an object for each id in team_scores, 
			// {current_score: number, is_original: boolean}
			// const isLengthTeamNum = length === league.size;
			if(isAllTrue([
				// isLengthTeamNum,
				teams_override_by_id
			]) ){
				Object.keys(teams_override_by_id).map(team_id => {
					const team = teams_override_by_id[team_id];
					if(team.original_scoreflow === null){
						team.original_scoreflow = [];
					}
					const scoreOverightRound = get(team.scoreflow_overrides, chosen_round_id);
					const scoreOriginalRound = get(team.original_scoreflow, chosen_round_id);
					const isOveride = isAnyTrue([Boolean(scoreOverightRound), scoreOverightRound === 0]);
					const isOriginalScore = isAnyTrue([Boolean(scoreOriginalRound), scoreOriginalRound === 0]);
					const is_override  = (isOveride && isOriginalScore) && (team.scoreflow_overrides[chosen_round_id] !==  team.original_scoreflow[chosen_round_id]) ? 
						true : false;
				
					initialState[team_id] = {
						current_score: is_override 
							? get(teams_override_by_id[team_id].scoreflow_overrides, chosen_round_id)  
							: get(teams_override_by_id[team_id].original_scoreflow,chosen_round_id),
						is_original_score: !is_override 
					};
					return true;
				});
				this.setState({
					team_scores: initialState
				});
			}
			
			
		}

	}



	fetchTeamOverrideScores() {
		const { fetchTeamOverrideScores, selected_league_id } = this.props;
		const { chosen_round_id } = this.state;
		fetchTeamOverrideScores({
			league_id: selected_league_id,
			round: chosen_round_id
		});
	}

	
	

	fetchLeague() {
		const { fetchLeague, selected_league_id } = this.props;
		fetchLeague({
			id: selected_league_id
		});
	}
  
	handleEditScoreChange(e) {
		const { clearTeamOverrideScores } = this.props;
		clearTeamOverrideScores();
		let new_state = this.state.team_scores;
		const new_score = e.target.value;
		if(new_score === ""){
			new_state[e.target.name].current_score = 0;
			new_state[e.target.name].is_original_score = false;
			this.setState({
				team_scores: new_state
			});
		}
		const allowed_values = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "-", ""];
		const split_new_score = new_score.split("");
		split_new_score.forEach(char => {
			if(!allowed_values.includes(char)){
				return;
			}
		});
    
		if(!Number(new_score) && new_score !== "-"){
			return;
		}
		
		new_state[e.target.name].current_score = new_score;
		new_state[e.target.name].is_original_score = false;
		this.setState({
			team_scores: new_state
		});
	}

	resetScores(fixture: Array<number>) {
		const {  teams_override_by_id } = this.props;
		const { chosen_round_id } = this.state;
		const home_original_score = teams_override_by_id[fixture[0]].original_scoreflow[chosen_round_id];
		const away_original_score = teams_override_by_id[fixture[1]].original_scoreflow[chosen_round_id];
		let new_state = this.state.team_scores;
		new_state[fixture[0]].current_score = home_original_score;
		new_state[fixture[0]].is_original_score = true;
		new_state[fixture[1]].current_score = away_original_score;
		new_state[fixture[1]].is_original_score = true;

		this.setState({
			team_scores: new_state
		});
	}
	handleSaveScores(e) {
		e.preventDefault();
		const { team_scores, chosen_round_id } = this.state;
		const { league, teamOverrideScoresUpdate, clearTeamOverrideScores } = this.props;
		clearTeamOverrideScores();
		let teams = {};
		Object.keys(team_scores).forEach(team_id => {
			if(!team_scores[team_id].is_original_score){
				const value = team_scores[team_id].current_score === "-" ? 0 : Number(team_scores[team_id].current_score);
				teams[team_id] = value;
			}
		});
		teamOverrideScoresUpdate({ 
			league_id: league.id, 
			round_id: chosen_round_id, 
			teams: teams 
		});
	}
	handleChosenRoundId(id: number){
		this.setState({
			chosen_round_id: id,
		});
	}
  


	get league_rounds() {
		const {
			rounds_ordered,
			league
		} = this.props;

		if (rounds_ordered && league && league.start_round && league.end_round) {
			return rounds_ordered.filter(round =>
				(round.id >= league.start_round && round.id <= league.end_round && round.status==="complete")
			);
		}

		return rounds_ordered;
	}
	get finals_rounds() {
		const {
			rounds_ordered,
			league
		} = this.props;

		return getLeagueRoundAliases(league, rounds_ordered);
	}
	get league_selected_round() {
		const { league } = this.props;
		const { chosen_round_id } = this.state;
		if (chosen_round_id && league && this.league_rounds.length) {
			const start_round = _.first(this.league_rounds).id;
			const end_round = _.last(this.league_rounds).id;

			if ( chosen_round_id < start_round) {
				return start_round;
			}
			else if (chosen_round_id > end_round) {
				return end_round;
			}

			return chosen_round_id;
		}

		return  null;
	}

	get edit_available() {
		const { league, rounds_by_id } = this.props;
		return rounds_by_id[league.start_round].status === "complete";
	}

	get last_completed_round() {
		const { league, rounds_ordered } = this.props;
		let round_id = 0;
		rounds_ordered.forEach(round => {
			if(round.status === "complete"){
				round_id = round.id;
			}
		});
		return league.start_round <= round_id ? round_id : null;
	}

	getTeamData(id: number) {
		const { 
			teams_override_by_id, 
			ladder_teams_by_id,  
			actual_round, 
			selected_league_id 
		} = this.props;
		const { chosen_round_id } = this.state;
		let data = {};
		data.name = ladder_teams_by_id[id].name;
		data.firstname = ladder_teams_by_id[id].firstname;
		data.lastname = ladder_teams_by_id[id].lastname;
		data.user_id = ladder_teams_by_id[id].user_id;
		data.rank = ladder_teams_by_id[id].rank;
		data.record_last_five = scoreflowToForm(
			ladder_teams_by_id[id], 
			chosen_round_id, 
			actual_round
		);
		data.is_game_bar = false;
		data.team = ladder_teams_by_id[id];
		data.game_type = "draft";
		data.league_id = selected_league_id;
		data.original_scoreflow = teams_override_by_id[id].original_scoreflow;
		data.scoreflow_overrides = teams_override_by_id[id].scoreflow_overrides;
		return data;
	}

	render() {
		const {
			league,
			user_id,
			teams_override_by_id,
			ladder_teams_by_id,
			rounds_by_id,
			save_success,
			actual_round,
		} = this.props;
		const { chosen_round_id } = this.state;
		const { start_round, fixture } = league;
		const chosenFixture = _.get(fixture, `[${chosen_round_id}]`, {});
		const isFirstRoundFinished = 
			_.get(rounds_by_id, `[${start_round}].status`, "scheduled") === "complete";
		const isRound7Start = isRound7Started(actual_round);
		if(_.isEmpty(league.fixture)){
			return <Redirect to={`/draft/league/${league.id}`} />;
		}
		if(
			isAnyTrue([
				_.isEmpty(teams_override_by_id),
				_.isEmpty(ladder_teams_by_id),
				_.isEmpty(this.state.team_scores),
				_.isEmpty(rounds_by_id),
				_.isEmpty(chosenFixture),
				_.isEmpty(actual_round)
			])
		){
			return <Preloader />;
		}

		if(isAllTrue([
			isAnyTrue([
				user_id !== league.commissioner, 
				!["full", "lite"].includes(league.subscription),
				league.draft_status !== "complete",
				!isFirstRoundFinished,
			]),
			isRound7Start
		]) 
		){
			return <Redirect to={`/draft/league/${league.id}/about`} />;
		}
		
		
		return (
			<React.Fragment>
				<JsonFetcher fetch={["rounds", "players", "squads"]}/>
				<LeagueGameBar no_fetch={true} />
				<AdsContainer>
					<Ad />
				</AdsContainer>
				<PageContentWrapper>
					<TwoColumnLayout>
						<EditScoresWrapper>
							<PageHeaderWrapper>
								<PageTitle>
							Edit Scores for {get(league, 'name')} 
								</PageTitle>
								<ControlsWrapper>
									{this.edit_available && <>
										<SaveButtonWrapper>
											{save_success && <Tick />}
											<SaveButton 
												onClick={this.handleSaveScores}>Save scores</SaveButton>
										</SaveButtonWrapper>

										
										<RoundSelector
											selected={this.state.chosen_round_id}
											rounds={this.league_rounds}
											handleClick={this.handleChosenRoundId}
											round_aliases={this.finals_rounds}
											className="round-selector"
										/>
									</>}
									
								</ControlsWrapper>
								

							</PageHeaderWrapper>
							<Exist when={!isRound7Start}>
								<Round6Note>
									As a result of Opening Round impacting fixtures, all leagues have access to this tool until Round 6, after which point continued use of this tool will require a Draft Pro subscription
								</Round6Note>  
							</Exist>
							{!this.edit_available ? 
								<EditAvailableText> Please come back after Round {league.start_round} has completed to edit scores</EditAvailableText> :
								
								<MatchupsWrapper>
									{league.fixture[chosen_round_id].map(fixture => {					
										const home_team = this.state.team_scores[fixture[0]];		
										const away_team = this.state.team_scores[fixture[1]];
										if(!_.has(home_team, "is_original_score") 
											|| !_.has(away_team, "is_original_score") ){
											return null;
										}
										const is_reset = !home_team.is_original_score 
                  							|| !away_team.is_original_score;

										const matchUpLink = 
										`/draft/league/${league.id}/fantasy-matchups/${chosen_round_id}/${fixture[0]}/${fixture[1]}`;
										return(
											<Matchup key={`matchup-${fixture}`}>
                      
												<FixtureTeam {...this.getTeamData(fixture[0])} />
												<TeamInput 
													type={"text"} 
													name={fixture[0]}
													value={home_team.current_score} 
													is_original_score={home_team.is_original_score}
													onChange={this.handleEditScoreChange}
													pattern="[0-9]*"
												/>
												<CenterButtonsWrapper>
													<StyledMatchupButton 
														is_fixture={true}
														to={matchUpLink}
														type={""}
														is_game_bar={false}
													>
														{isMobile() ? "VS" : "Matchup"}
													</StyledMatchupButton>
													{is_reset &&
														<ResetButton onClick={() => 
															this.resetScores(fixture)}>Reset scores</ResetButton>
													}
												</CenterButtonsWrapper>
											
												<TeamInput 
													type={"text"} 
													name={fixture[1]}
													value={away_team.current_score} 
													is_original_score={away_team.is_original_score}
													onChange={this.handleEditScoreChange}
													pattern="[0-9]*"
												/>
												<FixtureTeam 
													right_side={true} 
													{...this.getTeamData(fixture[1])} 
											 />
											</Matchup>
										);
									
									})}
								</MatchupsWrapper>
              
							}
							
						</EditScoresWrapper>
						
						<StandardSidebar />
					</TwoColumnLayout>
				</PageContentWrapper>
				<Footer />
			</React.Fragment>
		);
	}
}
const mapStateToProps = (state, props) => {
	const teams_override_by_id = state.leagues.override_scores.team_scores;
	const players_by_id = selectors.players.getExtendedPlayersById(state);
	const players_arr = selectors.players.getExtendedPlayersArrayWithOwners(state, props);
	const ladder_teams_by_id = selectors.leagues.ladder.getById(state);
	const selected_league_id = props.match.params.league_id;
	const save_success = state.leagues.override_scores.save_success;
	const teams = Object.keys(state.leagues.teams_by_id)
		.map(team_id => state.leagues.teams_by_id[team_id]);
	return {
		selected_league_id,
		league: selectors.leagues.getLeague(state, props),
		user_id: selectors.getUser(state).id,
		teams_override_by_id,
		teams,
		players_by_id,
		players_arr,
		ladder_teams_by_id,
		actual_round: selectors.rounds.getActualRound(state),
		rounds_ordered: selectors.rounds.getRounds(state),
		rounds_by_id: selectors.rounds.getRoundsById(state),
		save_success,
	};
};

const mapDispatchToProps = {
	fetchLeague: actions.leagueDraft.showDraftLeague,
	showTeamsDraftLeague: actions.leagueDraft.showTeamsDraftLeague,
	fetchDraftLadder: actions.leagueDraft.ladderDraftLeague,
	draftLeagueTeamUpdate: actions.leagueDraft.draftLeagueTeamUpdate,
	fetchWaiverFree: actions.leagueDraft.fetchWaiverFree,
	fetchTeamOverrideScores: actions.leagueDraft.fetchTeamOverrideScores,
	teamOverrideScoresUpdate: actions.leagueDraft.teamOverrideScoresUpdate,
	clearTeamOverrideScores: actions.leagueDraft.clearTeamOverrideScores,
};



export const EditScores = compose(
	withDraftCore,
	connect(
		mapStateToProps,
		mapDispatchToProps
	)
)(EditScoresComponent);

export default EditScores;