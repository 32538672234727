import type { TStatsTemplate } from "../components/PlayerProfile/printStat";
import { getStatKeyName } from "../helpers/league/index";
export const MATCH_STATS: TStatsTemplate = {
	played: [
		{
			name: "% TOG",
			id: "TOG",
			tooltip: "Percentage of time spent on ground",
		},
		{
			name: "K",
			id: "K",
			tooltip: "Kicks (3 points)",
			score_per: 3
		},
		{
			name: "H",
			id: "H",
			tooltip: "Handballs (2 points)",
			score_per: 2
		},
		{
			name: "M",
			id: "M",
			tooltip: "Marks (3 points)",
			score_per: 3
		},
		{
			name: "T",
			id: "T",
			tooltip: "Tackles (4 points)",
			score_per: 4
		},
		{
			name: "FF",
			id: "FF",
			tooltip: "Free Kicks For (1 points)",
			score_per: 1
		},
		{
			name: "FA",
			id: "FA",
			tooltip: "Free Kicks Against (-3 points)",
			score_per: -3
		},
		{
			name: "HO",
			id: "HO",
			tooltip: "Hitouts (1 points)",
			score_per: 1
		},
		{
			name: "G",
			id: "G",
			tooltip: "Goals (6 points)",
			score_per: 6
		},
		{
			name: "B",
			id: "B",
			tooltip: "Behinds (1 points)",
			score_per: 1
		},
		{
			name: "Score",
			id: "stats.score",
			tooltip: "Total Score for Game"
		},
	]
};

export const customMatchStats = (custom_stats: Object) => {
	const STATS = Object.keys(custom_stats).map(function(key, index) {
		return (
			{
				name: key,
				id: key,
				tooltip: `${getStatKeyName(key)} (${custom_stats[key]} points)`,
				score_per: custom_stats[key]
			}
		);
	});
	STATS.unshift({
		name: "% TOG",
		id: "TOG",
		tooltip: "Percentage of time spent on ground",
	});
	STATS.push({
		name: "Score",
		id: "stats.score",
		tooltip: "Total Score for Game"
	});
	
	return STATS;
};


export default MATCH_STATS;