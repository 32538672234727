import React, {ReactNode} from "react";
import styled from "styled-components";

import { below } from "../../assets/css/media";

import logo from "../../assets/images/home-logo.webp";
import HomeBackground from "../../assets/images/home-bg-2024.jpg";
import winHilux from "../../assets/images/win-hilux.webp";
import homeHilux from "../../assets/images/home-hilux2024.png";
import HomeBackgroundMobile from "../../assets/images/home-bg-mobile.webp";
import AFLiDModal from "../../pages/login/aflIDModal";
import {ScreenReaderOnly} from "./../ScreenReaderOnly";
import {OneColumnLayout} from "./../OneColumnLayout";
import {Footer} from "./../Footer";

type IProps = {
	children: ReactNode;
	title:string;
}

const LoginWrapper = styled.div`
	background-color: #001431;
	background-image: url(${HomeBackground});
	background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
	padding: 5px 0 20%;

  	// @media (min-width: 1550px){
	// 	background-size: 1750px auto;
	// 	background-position: top -100px center;
	// }
	${below.desktop`
		background-image: url(${HomeBackgroundMobile});
		background-size: cover;
		background-position: top;
		min-height: 630px;
		padding: 5px 0 20px;
	`};
`;

const LogoContainer = styled.div`
	display:flex;
	margin: 0 0 90px;
	max-width: 695px;
	img {
		display: block;
		margin: 0 auto;
		height: 215px;
	}
	img.hilux-left{
		height: 250px;
		width: auto;
	}
	img.hilux-absolute{
		height: auto;
		width: 400px;
	}

	${below.tablet`
		margin: 0 0px 40px;
		img {
			height: 150px;
		}
		img.hilux-left{
			height: 125px;
		}
		img.hilux-absolute{
			height: 125px;
			width: auto;
		}
	`};
	${below.phone`
		> div > div {
			margin: 0;
		}
	    justify-content: center;
		margin: 40px 0 20px 0px;
		width: calc(100% - 40px);
		img {
			height: 100px;
		}
		img.hilux-left{
			height: 100px;
		}
		img.hilux-absolute{
			height: 75px;
			margin: 15px 0 0 0;
		}
	`};
`;

const LogoContainerItem = styled.div`
	display:flex;
	justify-content: center;
    min-width: 210px;
	width: 210px;
	>div {
		margin: auto;
	}
	${below.tablet`
    	min-width: 100px;
	`};

`;

const OneColumnLayoutStyled = styled(OneColumnLayout)`
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
  ${below.phone`
    margin: 0px auto 50px;
  `}
`;

export const Landing: React.FC<IProps> = ({title="",children}) => (
	<React.Fragment>
		<LoginWrapper id="login-root">
			<ScreenReaderOnly>
				<h1>{title}</h1>
			</ScreenReaderOnly>
			<OneColumnLayoutStyled>
				<LogoContainer>
					<LogoContainerItem>
						<div>
							<img src={winHilux} alt="Win A Hilux" className='hilux-left'/>
						</div> 
					</LogoContainerItem>
					<LogoContainerItem>
						<div>
							<img src={logo} alt="AFL Logo" />
						</div>
					</LogoContainerItem>
					<LogoContainerItem>
						<div>
							<img src={homeHilux} alt="Hilux" className='hilux-absolute' />
						</div>
					</LogoContainerItem>
				</LogoContainer>
				{children}
			</OneColumnLayoutStyled >
		</LoginWrapper>
		<AFLiDModal />
		<Footer />
	</React.Fragment>
);

export default Landing;