export type TSection = {
	id: string,
	name: string,
	source?: string
}

export const sections = [
	{
		id: "my-account",
		name: "My Account"
	},
	{
		id: "communications",
		name: "Communications",
	},
	{
		id: "coach-subscription",
		name: "Fantasy Coach"
	},
	{
		id: "pro-subscription",
		name: "Pro Subscription"
	},
	{
		id: "your-history",
		name: "Your History"
	},
	{
		id: "classic-history",
		name: "Season History"
	},
	{
		id: "contact-us",
		name: "Contact Us"
	},
	{
		id: "terms-and-conditions",
		name: "Terms and Conditions"
	},
	{
		id: "fantasy-coach-terms",
		name: "Fantasy Coach Terms"
	},
	{
		id: "logout",
		name: "Logout"
	}
];