import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

import colors from "../../assets/css/colors";
import { below, above } from "../../assets/css/media";

const FormItemWrapper = styled.div`
	${({ summary_bar, right_side }) => (summary_bar && right_side) && `
		margin-right: 16px;
	`}
	${({ summary_bar, right_side }) => (summary_bar && !right_side) && `
		margin-left: 18px;
	`}
	${below.phone`
		margin-right: 0px;
		margin-left: 0px;
		.show-desktop{
			display: none;
		}
	`};
	${above.phone`
		.show-mobile{
			display: none;
		}
	`}
`;

const FormItem = styled.div`
	display: inline-block;
	margin-right: 2px;

	${props => props.condensed 
		? css`
			${props => props.children === "W" && `color:  ${colors.primary.accentGreen};`}
			${props => props.children === "L" && `color:  ${colors.warning};`}
			${props => props.children === "D" && "color:  #5d5d5d;"}
			font-size: 12px;
			font-family: TitilliumUpright;
			font-weight: 700;
		`
		: css`
			${props =>props.children === "W" && `background-color:  ${colors.primary.accentGreen};`}
			${props => props.children === "L" && `background-color:  ${colors.warning};`}
			${props => props.children === "D" && "background-color:  #5d5d5d;"}

			width: 1.25rem;
			height: 1.25rem;
			line-height: 1.25rem;

			border-radius: 3px;
			margin: 0 0.0625rem;
			padding: 1px 1px 0;

			font-weight: 600;
			color: #ffffff;
			font-size: 10px;
			
		`};
	${below.phone`
		${props => props.wld === "W" && `background-color:  ${colors.primary.accentGreen};`}
		${props => props.wld === "L" && `background-color:  ${colors.warning};`}
		${props => props.wld === "D" && "background-color:  #5d5d5d;"}
		content: none;
		height: 3px;
		width: 12px;
		margin: 0px 1px;
	`};
	
`;

const TeamForm = ({ form, condensed, summary_bar, right_side }) => {
	const formSplit = [...form.toUpperCase()];

	return (
		<FormItemWrapper summary_bar={summary_bar} right_side={right_side}>
			{formSplit.map((formItem, index) => {
				const key = `${index}-${formItem}`;
				return(
					<React.Fragment key={key}> 
						<FormItem className="show-desktop" 
							summary_bar={summary_bar} 
							condensed={condensed} 
							key={key} >
							{formItem}
						</FormItem> 
						<FormItem 
							className="show-mobile" 
							summary_bar={summary_bar} 
							condensed={condensed} 
							key={key + "-1"} 
							wld={formItem}  /> 
					</React.Fragment>
				);
				
			})}
		</FormItemWrapper>
	);
};

TeamForm.propTypes = {
	form: PropTypes.string
};

export default TeamForm;