// @flow
import * as React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { noop } from "lodash";
import { EmptyPlayerStyled } from "..";
import Swap from "../Icons/Swap";
import AddPlayer from "../Icons/AddPlayer";
import { onDragEnter, onDragLeave, onDragOver } from "../../utils/drag_and_drop";

type Props = {
	pos_name: string,
	onClick: Function,
	in_substitute: boolean,
	pos_id: number | string,
	substitutePlayer: Function,
	pureSubstitutePlayer: Function,
	index: number,
	is_bench: boolean,
	is_draft?: boolean,
	free_agent_link?: string,
	is_utility?: boolean,
	is_team_full?: boolean,
}

const CardLink = styled(Link)`
	text-decoration: none;
`;

const EmptyPlayerSwapWrapper = styled(EmptyPlayerStyled)`
	z-index: 2;
	border: 1px dashed #FFFFFF;
	background-color: rgba(255,255,255,0.4);
	
`;


const SwapAndTradeWrapper = styled.div`
	background: #fff;
	border-radius: 50%;
	width: 24px;
	height: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const EmptyPlayer = ({
	pos_name = "player",
	pos_id,
	onClick,
	in_substitute,
	substitutePlayer,
	index,
	is_bench,
	is_utility = false,
	is_draft = false,
	free_agent_link = "",
	pureSubstitutePlayer,
	is_team_full
}: Props) => {
	const onDropPlayer = () => pureSubstitutePlayer({
		position: pos_id,
		index,
		is_bench,
		is_utility,
	});

	if (in_substitute || is_team_full) {
		return (
			<EmptyPlayerSwapWrapper
				onClick={substitutePlayer}
				data-position={pos_id}
				data-id={0}
				data-index={index}
				data-position_index={index}
				data-is_bench={is_bench}
				data-is_utility={is_utility}
				onDrop={onDropPlayer}
				onDragOver={onDragOver}
				onDragEnter={onDragEnter}
				onDragLeave={onDragLeave}
			>
				<SwapAndTradeWrapper>
					<Swap size={1.5} />
				</SwapAndTradeWrapper>
				
			</EmptyPlayerSwapWrapper>
		);
	}
	const card = <EmptyPlayerSwapWrapper is_draft={is_draft} onClick={onClick}>
		<SwapAndTradeWrapper >
			<AddPlayer size="1.5" />
		</SwapAndTradeWrapper>
	</EmptyPlayerSwapWrapper>;

	if (is_draft) {
		return <CardLink to={free_agent_link}>{card}</CardLink>;
	}
	return card;
};

EmptyPlayer.defaultProps = {
	pureSubstitutePlayer: noop
};

export default EmptyPlayer;