/* eslint max-len: 0 */
// @flow
import React from "react";
import SvgIconContainer from "../SvgIconContainer";

export default (
	{
		unit = "em",
		...rest
	}: Object = {}) => (
	<SvgIconContainer viewBox="0 0 24 24" {...rest} unit={unit}>
		<title>Caution</title>
		<path d="M12,0.99999174 C12.670398,0.997904529 13.3416682,1.32858851 13.7246805,1.99206361 L20.9126703,14.4422354 L20.9000362,14.4417529 L23.7312746,19.3456011 C24.4948742,20.6683503 23.5402032,22.3217869 22.0130041,22.3217869 L1.9869959,22.3217869 C0.459796785,22.3217869 -0.494874179,20.6683503 0.268725378,19.3456011 L3.09996388,14.4417529 L3.08732974,14.4422354 L10.2753195,1.99206361 C10.6583318,1.32858851 11.329602,0.997904529 12,0.99999174 Z M12.006067,17.2829218 C11.0929035,17.2829218 10.3526304,18.0231948 10.3526304,18.9363583 C10.3526304,19.8495219 11.0929035,20.5897949 12.006067,20.5897949 C12.9195736,20.5897949 13.6595036,19.8495219 13.6595036,18.9363583 C13.6595036,18.0231948 12.9192305,17.2829218 12.006067,17.2829218 Z M12.2455066,5.70817951 L11.7666274,5.70817951 C10.9934228,5.70817951 10.3738987,6.34862891 10.3996264,7.12149046 L10.639066,14.307422 C10.6637646,15.0446077 11.2681952,15.6294852 12.006067,15.6294852 C12.7439388,15.6294852 13.3483694,15.0446077 13.373068,14.307422 L13.6125076,7.12149046 C13.6382353,6.34862891 13.0187111,5.70817951 12.2455066,5.70817951 Z" id="Shape" fill="#D92425"></path>
	</SvgIconContainer >
);