// @flow
import * as React from "react";
import styled from "styled-components";
import { Label, Select, Tooltip } from "../";
import Locked from "../Icons/Locked";
import colors from "../../assets/css/colors";
import { Exist } from "../Exist";

const DisabledWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	height: 38px;
	width: 38px;

	background-color: ${colors.primary.primary};
	border-radius: 50%;

	> svg {
		width: 28px;
		height: 28px;
		font-size: 28px;
		fill: white;
	}

	margin-right: 10px;
`;
const FormGroup = styled.div`
    display: flex;
    align-items: center;

	> div {
		flex: 1 0 0px;
	}

	> ${DisabledWrapper} {
		flex: 0 0 auto;
	}
`;

const PoolSelectWrapper = styled.div`
	label {
		> .select-tooltip {
			margin-bottom: 4px;
		}
	}
`;

type Props = {
	name: string,
	label: string,
	description?: string,
	value?: string,
	children: React.Node,
	onChange: Function,
	half_margin?: boolean,
	disabled?: boolean,
	show_on_left?: boolean,
	no_label?: boolean,
	show_on_right?: boolean,
	isShort?: boolean
};

export const PoolSelect = ({
	name, label, description, children,
	value, onChange, half_margin, disabled = false,
	show_on_left, no_label, show_on_right, isShort
}: Props) => (
	<PoolSelectWrapper>
		<Exist when={!no_label}>
			<Label htmlFor={name}>
				{label}
				{description && 
					<Tooltip 
						className="select-tooltip" 
						description={description} 
						show_on_left={show_on_left}
						show_on_right={show_on_right}
						isShort = {isShort}
					/>}
			</Label>
		</Exist>
		
		<FormGroup half_margin={half_margin}>
			{disabled && <DisabledWrapper>
				<Locked color='#ffffff' />
			</DisabledWrapper>}
			<Select 
				name={name} 
				value={value} 
				onChange={onChange} 
				is_disabled={disabled} 
				half_margin={half_margin}>
				{children}
			</Select>
		</FormGroup>
	</PoolSelectWrapper>
);

export default PoolSelect;