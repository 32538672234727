import { get, omit } from "lodash";

export const getPositionOfPlayerinLineup = (player_id, lineup) => {
	const isDef = get(lineup, '1', []).includes(player_id);
	const isMid = get(lineup, '2', []).includes(player_id);
	const isRuck = get(lineup, '3', []).includes(player_id);
	const isFwd = get(lineup, '4', []).includes(player_id);

	if(isDef){
		return 1;
	}
	if(isMid){
		return 2;
	}
	if(isRuck){
		return 3;
	}
	if(isFwd){
		return 4;
	}

	const isUtil = get(lineup, 'bench.utility.id', 0) === player_id;

	if(isUtil){
		return 'utility';
	}

	const bench = get(lineup, 'bench', {});
	const bench_players = omit(bench, ['utility', 'emergency']);
	const isBench = Object.keys(bench_players).find(benchKey => {
		return bench[benchKey].includes(player_id);
	});

	return Boolean(isBench) ? 'bench' : 0;

};

export const convertMadeTradeToPending = (trade, lineup) => {
	const {
		new_player_id,
		old_player_id,
	} = trade;
	const position = getPositionOfPlayerinLineup(new_player_id, lineup);

	const is_bench = position === 'bench' || position === "utility";
    
 	return{
		in: Number(new_player_id),
		out: Number(old_player_id),
		is_bench,
		position,
		in_position: position,
		position_index: position,
		// new_player_id: Number(new_player_id),
		// old_player_id: Number(old_player_id),
		id: trade.id,
		swap_ids: [],
		double_id: trade.double_id,
		swap_formations: []
	};
};