// @flow
import * as React from "react";
import { range } from "lodash";
import moment from "moment";

import { Field } from "redux-form";
import { Exist, FormDateField, FormRadio, FormSelect } from "../../../../../components/index";
import { 
	getIsRegenerated, 
	isAnyTrue, 
	league_tooltips 
} from "../../../../../helpers/index";
import type { TPLeague } from "../../../../../modules/types";

type Props = {
	draft_status: string,
	keeper: number,
	leagueSize: number,
	league: TPLeague,
	league_draft_start: string,
	squadSize: number,
	regenerated_from: number
};
// const SEVEN_DAYS = 7;
// const THREE_DAYS = 3;
const ONE_HOUR = 1;

export const KeeperSettings =  (props: Props) => {
	const { keeper, league, league_draft_start, squadSize, regenerated_from } = props;
	// const { draft_status } = league;
	const draft_start_moment = moment(league_draft_start);
	const moment_now = moment();
	// const diff = moment(draft_start_moment).diff(moment_now, "days");
	const isLessOneHour = moment(draft_start_moment).diff(moment_now, "hours") < ONE_HOUR;

	const regeneratedFrom = league.regenerated_from || regenerated_from;

	// const isWithin7DaysUntilDraft =  (diff) <= SEVEN_DAYS 
	// 	|| (isAllTrue([
	// 		draft_status,
	// 		draft_status !== "scheduled"
	// 	]));
	// const isWithin3DaysOfDraft = diff <= THREE_DAYS;
	const isRegenLeague = getIsRegenerated(regeneratedFrom,league.uf_regenerated_from);
	// TODO - F2P1-23029 unhide keeper deadline selection once BE released
	
	const oneHourBefore = draft_start_moment.subtract(1, 'hour');
	const maxDate = oneHourBefore;
	const deadlineMoment = moment(league.keeper_deadline).toDate();
	const getValidDatesLeague = (currentData, max) => {
		return currentData.isBefore(oneHourBefore);
	};
	
	// Disable any regenerated league so users have a cutoff time before draft to 
	// make their keeper selections if they decide to do so
	// Newly created leagues this will never be disabled as it wont apply until the next
	// League year for when they make their keeper selections
	// const disableEnableKeeper = isWithin7DaysUntilDraft && isRegenLeague;

	const disableKeeperSettings = isAnyTrue([isLessOneHour,
		league.draft_status === "complete",
		!Boolean(keeper)]);

	return <div>
		<FormRadio
			label='Enable keeper league'
			name='keeper'
			elements={[
				{
					value: 1,
					text: "Yes",
				},
				{
					value: 0,
					text: "No",
				}
			]}
			tooltip={league_tooltips.keeper}
			is_disabled={false}
		/>
		<Field
			label='Number of Keepers'
			name='keeper_num'
			component={FormSelect}
			options={
				range(1, squadSize + 1)
					.map(num => ({ value: Number(num), text: num }))
			}
			tooltip={league_tooltips.keeper_num}
			is_disabled={disableKeeperSettings}
		/>
		{/* exist should have isRegenLeague instead of HIDE_KEEPER_DEADLINE */}
		<Exist when={isRegenLeague}> 
			<Field
				label='Keeper Selection Deadline'
				type='text'
				name='keeper_deadline'
				placeholder='Keeper Selection Deadline'
				isValidDate={getValidDatesLeague}
				component={FormDateField}
				defaultValue={deadlineMoment}
				timeFormat='h:mmA'
				readOnly={true}
				maxDate={maxDate}
				tooltip={league_tooltips.keeper_deadline}
				is_disabled={disableKeeperSettings || !isRegenLeague} 
			/>
		</Exist>
		
		
	</div>;
};

export default KeeperSettings;