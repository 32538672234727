// @flow
import * as _ from "lodash";

type TFieldPlayers = {
	players: number[],
	captain?: number,
	vice_captain?: number,
	is_vc_unique?: boolean,
	emergency?: {[position: number]: number[]}
}

export const getField = (team: any) => {
	const lineup = _.get(team, "lineup");
	return _(lineup)
		.omit("captain", "vice_captain", "bench")
		.reduce((field, pos_group) => [...field, ...pos_group], []);
};

export const getBench = (team: any) => {
	const lineup = _.get(team, "lineup.bench");
	const new_lineup = _.omit(lineup, ["emergency", "utility"]);
	const flat_bench_arr = [];
	Object.keys(new_lineup).forEach(pos => {
		if(typeof new_lineup[pos] === "number"){
			flat_bench_arr.push(new_lineup[pos]);
			return;
		}
		const newLineupArr = _.get(new_lineup, `[${pos}]`, []);
		if(newLineupArr.length){
			newLineupArr.forEach(player_id => {
				flat_bench_arr.push(player_id);
			});
		}
	});
	return flat_bench_arr;
};

const getRole = (player_id: number, team: TFieldPlayers): string | null => {
	if (player_id === _.get(team, "captain")) {
		return "captain";
	}
	if (player_id === _.get(team, "vice_captain")) {
		return "vice_captain";
	}
	const emg_pos = _.findKey(_.get(team, "bench.emergency"), pos => _.includes(pos, player_id));
	if (emg_pos) {
		return `emergency.${emg_pos}`;
	}
	return null;
};

/**
 * If the player is vc, he's only unique if VCs are unique, or he has a
 * different role on the other team
 *
 * VCs are the same as normal players, unless the VCs are marked as unique (i.e. one captain is DNP)
 */
const isUniqueVC = (role: string | null, other_role: string | null, team: TFieldPlayers) => {
	return _.includes([null, "vice_captain"], other_role)  && team.is_vc_unique;
};

const isUniqueRole = (role: string | null, other_role: string | null, team: TFieldPlayers) => {
	/**
	 * If player is captain, he's unique unless he's the other team's captain
	 */
	if (role === "captain") {
		return role !== other_role;
	}
	/**
	 * If player is an emergency, he's unique unless he's an emergency in the
	 * same postion on the other team
	 */
	if ((role || "").startsWith("emergency")) {
		return role !== other_role;
	}

	if (role === "vice_captain") {
		return isUniqueVC(role, other_role, team);
	}

	/**
	 * If the player has no role, he's only unique if he's a VC on the other
	 * team and VCs are unique, or he has another role on the other team
	 */
	if (role === null) {
		return other_role === "vice_captain"
			? team.is_vc_unique
			: other_role  !== null;
	}
};

const isUnique = (player_id: number, team: TFieldPlayers, other_team: TFieldPlayers) => {
	const opp_has_player = _.includes(other_team.players, player_id);

	const role = getRole(player_id, team);
	const opp_role = getRole(player_id, other_team);

	return !opp_has_player || isUniqueRole(role, opp_role, team);
};

export const getFields = (home: TFieldPlayers, away: TFieldPlayers, should_be_unique: boolean) => {
	const home_players = _.filter(home.players, player_id => {
		return should_be_unique === isUnique(player_id, home, away);
	});

	const away_players = _.filter(away.players, player_id => {
		return should_be_unique === isUnique(player_id, away, home);
	});

	return {
		home: home_players,
		away: away_players
	};
};