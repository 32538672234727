// @flow
export const game_types = {
	CLASSIC: "classic",
	DRAFT: "draft"
};

export const getGameType = (pathname: string) => {
	if (pathname.startsWith("/draft")) {
		return game_types.DRAFT;
	}
	else if (pathname.startsWith("/classic")) {
		return game_types.CLASSIC;
	}

	return null;
};