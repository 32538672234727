// @flow
import * as React from "react";
import styled, { css } from "styled-components";
import * as _ from "lodash";
import { connect } from "react-redux";
import { below } from "../../assets/css/media";
import colors from "../../assets/css/colors";
import * as selectors from "../../modules/selectors";
import * as actions from "../../modules/actions";
// import Transactions from '../Icons/Transactions';
import {
	ButtonPrimary,
	PlayerTableCell,
	ModalContainer,
	ModalInnerTitle,
	TradeInCell,
	PlayerCellWrapper,
	EmptyCell,
	TradeWrapper,
} from "../";
import {TPLeague, TPlayersById} from "../../modules/types";
// import ButtonAccent from '../ButtonAccent';
// import CancelButton from '../CancelButton';
// import TradeInCellShow from './TradeInCellShow';
// import TradeOutCellShow from './TradeOutCellShow';

const mql = window.matchMedia("(max-width: 800px)");

let mobileView = mql.matches;

const confirmText = () => {
	return mobileView ? 
		"Confirm the following offer" 
		: 
		"Please revise and confirm the following offer:";
};


const MAX_TRADES = 10;

type Props = {
	type: string,
	fa: {
		new_player_id: number,
		old_player_id: number
	},
	team: {
		new_player_id: Array<number>,
		old_player_id: Array<number>
	},
	players_by_id: TPlayersById,
	cancelRequest: Function,
	removeOutPlayer: Function,
	makeTeamToTeamTrade: Function,
	removeInPlayer: Function,
	clearTradeErrors: Function,
	// postRequestWaiver: typeof actions.leagueDraft.postRequestWaiver,
	// NOT SURE WHY THE ABOVE ISN'T WORKING, TEMP FIX BELOW
	postRequestWaiver: Function,
	league: TPLeague,
	waiver_requests: string,
	// /postRequestWaiverClear: typeof actions.leagueDraft.postRequestWaiverClear,
	// NOT SURE WHY THE ABOVE ISN'T WORKING, TEMP FIX BELOW
	postRequestWaiverClear: Function,
	reFetchData: Function,
	trade_error: boolean | string,
	MAX_TRADES: number,
	removePlayer: typeof actions.teamsDraft.fetchRemovePlayer,
	user_team: {
		players_margin: number,
		id: number,
	},
	fetchTeam: typeof actions.teamsDraft.fetchTeam,
	playerID?: string,
}

type State = {
	show_confirm_modal: boolean,
	active_tab: string,
};

const CancelTradeButton = styled.button`
	background: none;
	border: none;
	color: #D92425;
	cursor: pointer;
	&:hover{
		text-decoration: underline;
	}
	${below.desktop`
		display: none;
	`}
`;

const TradeModalContainer = styled(ModalContainer)`
	@media (max-width: 780px){
		left: 0;
	}
`;


const ButtonsWrapper = styled.div`
	/* display: flex;
	flex-direction: row;
	justify-content: start; */
	width: fit-content;
	/* flex-basis: 160px;
	margin-bottom: 30px; */
	align-items: center;
	z-index: 50;
	button {
		font-family: SourceSansPro;
		font-size: 12px;
		width: 140px;
		height: 40px;
		margin: 5px 0;
		border-radius: 2px;
		${below.desktop`
			width: 196%;
			height: 45px;
		`};
		${below.tablet`
			margin: 0 0px;
		`};
	}
	${below.desktop`
			width: 50vw;
			
	`};
	${below.tablet`
		flex-direction: row;
		margin-top: 12px;
	`};
`;

const TradeButtonPrimary = styled(ButtonPrimary)`
	${below.desktop`
		width: 100%;
	`}
`;
const SeparatorDiv = styled.div`
	padding-left: 5px;
`;


const ModalButtons = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	margin-bottom: 30px;
	${below.desktop`
		margin-top: 30px;
		margin-bottom: 10px;
	`};

	> button {
		width: 200px;
		margin: 5px 10px;
		padding: 12px 0;

		${below.desktop`
			width: 200px;

		`};
		${below.tablet`

			margin: 10px 5px;
		`};
	}
`;

const ConfirmModalContent = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 1em 2em 40px;
	${below.desktop`
		${css`
			margin: 1em 4px;
		`}
	`}
`;
const TradeCells = styled.div`
	flex-basis: 49%;
	height: 100%;
	margin: 0;
	align-self: flex-start;
	${below.desktop`
		flex-basis: 49%;
	`}
	> p {
		font-family: SourceSansPro;
		text-align: ${({ reverse }) => reverse ? "right" : "left"};
		color: ${colors.primary.darkGrey};
		font-size: 14px;
		font-weight: 400;
		margin-bottom: 10px;
	}
	> div {
		background-color: #F0F3F7;
		margin-bottom: 10px;
		&:last-child {
			margin-bottom: 0;
		}
		${below.desktop`
			${css`
				width: 100%;
			`}
		`}

	}
	${below.desktop`
		${css`
			${PlayerTableCell} {
				min-width: 100px;
				flex-basis: 99%;
			}
		`}
	`}
`;

const TradeCellHeading = styled.p`
	font-size: 14px;
	line-height: 1.43;
	margin-bottom: 0.57em;
	font-weight: 400;
	font-family: SourceSansPro;
	color: #1D4073;
	padding-left: 4px;
`;

// const BorderedIconStyled = styled(BorderedIcon)`
// 	font-size: 2em;
// `;
class PlayersOnTradeComponent extends React.Component<Props, State> {
	static defaultProps = {
		players: [],
		type: "fa",
		fa: {
			new_player_id: 0,
			old_player_id: 0
		},
		team: {
			new_player_id: [0],
			old_player_id: [0]
		},
		MAX_TRADES: MAX_TRADES
	};

	constructor(props, state) {
		super(props, state);

		_.bindAll(this, [
			"makeFARequest",
			"handleErrorModalClose",
			"handleConfirmModal",
			"handleTabSelect",
			"removePlayer"
		]);
	}

	state = {
		show_confirm_modal: false,
		active_tab: "your_team",
	};

	componentDidUpdate(prev_props) {
		if (!_.isEmpty(this.props.waiver_requests)) {
			const { postRequestWaiverClear, reFetchData } = this.props;
			postRequestWaiverClear();
			if(_.isFunction(reFetchData)){
				reFetchData();
			}
		}
	}
	handleTabSelect = selected_tab => {
		this.setState({
			active_tab: selected_tab
		});
	};
	getIsTabActiveClass = tab => this.state.active_tab === tab ? "active" : "";

	get requests() {
		const { type } = this.props;

		if (type === "fa") {
			return this.fa_requests;
		}
		return this.team_requests;
	}

	//FA
	get fa_requests() {
		return (
			<React.Fragment>
				{this.fa_player_cells}
			</React.Fragment>
		);
	}
	get fa_player_cells() {
		const {
			players_by_id,
			fa: { new_player_id, old_player_id },
			removeOutPlayer,
			removeInPlayer
		} = this.props;
		const new_player = players_by_id[new_player_id],
			old_player = players_by_id[old_player_id];
		if (_.isEmpty(players_by_id)) {
			return <div>Loading</div>;
		}

		return (
			<PlayerCellWrapper>
				
				<TradeWrapper>
					<TradeCellHeading>You are offering:</TradeCellHeading>
					{old_player_id ? (
						<TradeInCell 
							is_compressed={false}
							player={old_player} 
							removePlayer={removeOutPlayer} 
							is_classic={false}
						/>
					) : (
						<EmptyCell/>
					)}
					<EmptyCell>{this.fa_buttons} </EmptyCell>
				</TradeWrapper>
				<TradeWrapper>
					<TradeCellHeading>You have requested:</TradeCellHeading>
					{new_player_id ? (
						<TradeInCell 
							is_compressed={false}
							player={new_player} 
							removePlayer={removeInPlayer} 
							is_classic={false} 
						/>
					) : (
						<EmptyCell />
					)}
					<EmptyCell />
				</TradeWrapper>
			</PlayerCellWrapper>
		);
	}
	get fa_buttons() {
		const { fa: { new_player_id }, cancelRequest } = this.props;
		// let is_request_disabled = [new_player_id, old_player_id].includes(0);
		// if(user_team && user_team.players_margin < 0 && new_player_id && !old_player_id) {
		//	is_request_disabled = false;
		// }
		const action = new_player_id === 0 ? 
			this.removePlayer:this.makeFARequest;

		return (
			<ButtonsWrapper>
				<TradeButtonPrimary onClick={action}>
					Make request
				</TradeButtonPrimary>
				<CancelTradeButton onClick={cancelRequest}>
					Cancel request
				</CancelTradeButton>
			</ButtonsWrapper>
		);
	}
	// Team to team
	get team_requests() {
		
		const { players_by_id } = this.props;
		if (_.isEmpty(players_by_id)) {
			return <div>Loading</div>;
		}

		return (
			<React.Fragment>
				{this.team_player_row}
			</React.Fragment>
		);
	}

	get team_player_row() {
		
		const {
			team: { new_player_id, old_player_id },
			players_by_id,
			removeInPlayer,
			removeOutPlayer
		} = this.props;

		const zipped = _.zip(new_player_id, old_player_id);
		return (
			<div>
				<PlayerCellWrapper>
					<TradeWrapper >
						<TradeCellHeading>You are offering: </TradeCellHeading>
						{zipped.length === 0 ? <EmptyCell /> : null}
						{zipped.map((a, i) => {
							return (a[1] !== undefined)? (
								<TradeInCell
									key={i}
									player={players_by_id[a[1]]}
									removePlayer={removeOutPlayer} 
									is_classic={false}
								/>
							):(
								<EmptyCell> 
								</EmptyCell>
							);
						})}
						<EmptyCell faded> 
							{this.team_buttons}
						</EmptyCell>
					</TradeWrapper>
					<TradeWrapper >
						<TradeCellHeading>You have requested:</TradeCellHeading>
						{zipped.length === 0 ? <EmptyCell /> : null}
						{zipped.map((a, i) => {
							return (a[0] !== undefined)? (
								<TradeInCell
									key={i}
									player={players_by_id[a[0]]}
									removePlayer={removeInPlayer} 
									is_classic={false}
									right
								/>
							):(
								<EmptyCell key={i}/>
							);
						})}
						<EmptyCell faded/>
					</TradeWrapper>
					
				</PlayerCellWrapper>
			</div>
			
		);
	}

	get team_buttons() {
		const { team: { new_player_id, old_player_id }, cancelRequest } = this.props;
		const new_players_size = _.size(new_player_id);
		const old_players_size = _.size(old_player_id);
		const is_request_enabled = new_players_size && old_players_size;
		return (
			<ButtonsWrapper>
				<ButtonPrimary
					disabled={!is_request_enabled}
					onClick={this.handleConfirmModal}
					className={is_request_enabled ? "" : "hidden-mobile"}
					width="140px"
				>
					Make offer
				</ButtonPrimary>
				<CancelTradeButton onClick={cancelRequest}>
					Cancel offer
				</CancelTradeButton>
			</ButtonsWrapper>
		);
	}
	get confirm_modal() {
		const { team, players_by_id, makeTeamToTeamTrade } = this.props;
		const { new_player_id, old_player_id } = team;
		return (
			<TradeModalContainer 
				onClick={this.handleConfirmModal} 
				container_background="#F8F8FA"
			>
				<ModalInnerTitle>
					{confirmText()}
				</ModalInnerTitle>
				<ConfirmModalContent>
					<TradeCells>
						
						{mobileView ? null : <p>You have offered:</p>}
						
						{old_player_id.map(id => (
					
							<TradeInCell modal={"minus"}
								key={id}
								player={players_by_id[id]}
								is_classic={false}
								is_compressed={false}
								status_show={true}
							/>
						))}
					</TradeCells>
					{mobileView ? <SeparatorDiv /> : null}
					<TradeCells>
						{mobileView ? null : <p>You have requested:</p>}
						{new_player_id.map(id => (
							<TradeInCell modal={"plus"}
								key={id} 
								player={players_by_id[id]} 
								is_classic={false} 
								status_show={true}
							/>
						))}
					</TradeCells>
					
				</ConfirmModalContent>
				<ModalButtons>
					<ButtonPrimary
						onClick={makeTeamToTeamTrade}
					>
						Propose Trade
					</ButtonPrimary>
					<CancelTradeButton onClick={this.handleConfirmModal}>
						Cancel
					</CancelTradeButton>
					
				</ModalButtons>

			</TradeModalContainer>
		);
	}
	get error_modal() {
		const { trade_error } = this.props;

		return (
			<ModalContainer header_text="Error" onClick={this.handleErrorModalClose}>
				<ModalInnerTitle>
					{trade_error}
				</ModalInnerTitle>
			</ModalContainer>
		);
	}
	handleConfirmModal() {
		const { show_confirm_modal } = this.state;
		this.setState({
			show_confirm_modal: !show_confirm_modal
		});
	}
	handleErrorModalClose() {
		const { clearTradeErrors } = this.props;
		clearTradeErrors();
		//this.handleConfirmModal();
	}
	makeFARequest() {
		const { postRequestWaiver, league, fa, fetchTeam } = this.props;
		const { team_id } = league;
		postRequestWaiver({
			...fa,
			team_id
		});
		fetchTeam({ league_id: league.id });
	}

	removePlayer() {
		const { removePlayer, user_team, playerID } = this.props;
		removePlayer({
			player_id: Number(playerID),
			team_id: user_team.id
		});
	}

	//helpers
	get team_requests_count() {
		const { team: { new_player_id, old_player_id } } = this.props;

		return _.max([_.size(new_player_id), _.size(old_player_id)]);
	}

	render() {
		const { trade_error } = this.props;
		const { show_confirm_modal } = this.state;
		if(trade_error){
			return this.error_modal;
			
		}
		return (
			<div>
				{this.requests}
				{show_confirm_modal && this.confirm_modal}
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		players_by_id: selectors.players.getExtendedPlayersById(state),
		waiver_requests: state.teamsDraft.waiver_requests,
		trade_error: state.teamsDraft.error,
	};
};

const mapDispatchToProps = {
	postRequestWaiver: actions.teamsDraft.postRequestWaiver,
	postRequestWaiverClear: actions.teamsDraft.postRequestWaiverClear,
	clearTradeErrors: actions.teamsDraft.clearTeamErrors,
	fetchTeam: actions.teamsDraft.fetchTeam,
	removePlayer: actions.teamsDraft.fetchRemovePlayer,
};

export const PlayersOnTrade = connect(
	mapStateToProps,
	mapDispatchToProps,
)(PlayersOnTradeComponent);


export default PlayersOnTrade;