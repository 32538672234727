// @flow

import { get } from "lodash";
import type { TRootStore } from "../../types";



export const getFaqs = ({ help }: TRootStore) => {
	const sections = get(help.faqs, 'sections');
	const articles = get(help.faqs, 'articles');

	if(!sections || !articles){
		return [];
	}

	const sectionToOmit = window.location.pathname.includes('classic') ? 'Draft' : 'Classic';

	const sectionIdToOmit = sections.find(section => section.name.includes(sectionToOmit));
	return articles.filter(article => 
		article.zendesk_section_id !== sectionIdToOmit.zendesk_section_id);
};

export const getFaqCats = ({help}: TRootStore) => {
	const sections = get(help.faqs, 'sections');
	const sectionToOmit = window.location.pathname.includes('classic') 
		? 'Draft' : 'Classic';

	return sections.filter(section => !section.name.includes(sectionToOmit));
};