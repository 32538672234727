import React from "react";
import * as _ from "lodash";
import styled from "styled-components";
import numeral from "numeral";
import { ListViewField, ListViewHeader, ListRow, ListRowLabel } from "../../../../components";
import { 
	TPosition, 
	TRound,
	TRoundWithMatches, 
	TPlayersArrayWithFixtureById, 
	TDraftUserTeam,
	TPLeague,
	TMatch
} from "../../../../modules/types";
import { below } from "../../../../assets/css/media";
import colors from "../../../../assets/css/colors";
import { isMobile } from "../../../../utils";
// import { findMatch } from "../../../../helpers/rounds";
import { isAllTrue, isAnyTrue } from "../../../../helpers";
import { displayRoundLabel, displayRoundScore } from "../../../../helpers/stats/displayRound";
import { findMatch } from "../../../../helpers/rounds";
import OverflowUtils from "./overflowUtils";
import PlayerRow from "./playerRow";


const DEFAULT_VALUE = "- -";
numeral.nullFormat(DEFAULT_VALUE);


const isPlayerMatchFinished = (actual_round, player) => {
	const squad_id = _.get(player, "squad_id");
	const matches = _.get(actual_round, "matches", []);

	const match = findMatch(matches, squad_id);
	const status = _.get(match, "status", "scheduled");

	return status === "complete";
};

const getDefaultScoreValue = (round_id: number) => {
	return round_id >= 1 ? "DNP" : "- -";
};

const getScoreStat = (customScoringEnabled, round_id) => {
	return customScoringEnabled ? `custom_stats.scores.${round_id}` : `stats.scores.${round_id}`;
};
const getProjScoreValue = (has_assistant_coach, projected) => {
	return has_assistant_coach ? projected : "";
};

const getPointsLastRound = (
	field_stat, 
	player,
	has_assistant_coach,
	selected_round,
	customScoringEnabled,
	actual_round,
) => {
	const { is_playing_now } = player;
	const selected_round_id = _.get(selected_round, "id", 0);
	const is_current_round = selected_round_id === actual_round.id;
	const is_playing_this_round = isAllTrue([Boolean(is_playing_now), is_current_round]);
	let score_value = 0;
	let label: React.Node, opts: Object;
	
	if (field_stat.includes("proj_score")) {
		let projected = _.get(player, field_stat, DEFAULT_VALUE);
		if(customScoringEnabled){
			projected = _.get(player, 'custom_stats.proj_score_actual_round', DEFAULT_VALUE);
		}

		score_value = getProjScoreValue(has_assistant_coach,projected);
		opts = {
			is_coach: true,
		};
		label = "projected";
		if(isPlayerMatchFinished(actual_round, player)){
			score_value = displayRoundScore(
				player, 
				selected_round, 
				customScoringEnabled, 
				has_assistant_coach
			);
			label = "FINAL";
			opts = {
				...opts,
				is_coach: false
			};
		}
	}
	else if (field_stat.includes("points_last_round")) {
		const round_id = _.get(selected_round, "id", 0) - 1;
		const default_score_value = getDefaultScoreValue(round_id);
		const score_stat = getScoreStat(customScoringEnabled, round_id);
		score_value = _.get(player, score_stat, default_score_value);
		const is_dnp = score_value === "DNP";
		label = "LAST ROUND";
		opts = {
			is_dnp,
		};
	}
	else if (isAnyTrue([
		field_stat.includes("round_score"),
		field_stat.includes("live")
	])) {
		score_value = displayRoundScore(
			player, 
			selected_round, 
			customScoringEnabled, 
			has_assistant_coach
		);
		const is_dnp = score_value === "DNP";
		label = displayRoundLabel(player, selected_round, score_value, has_assistant_coach);
		const checkIsLive = isAllTrue([is_playing_this_round, !is_dnp]);
		opts = {
			is_live: checkIsLive,
			is_dnp,
			is_coach: isAllTrue([!checkIsLive, label !== "FINAL", has_assistant_coach]),
		};
	}
	else if(field_stat.includes("avg")){
			
		// Average
		score_value = numeral(_.get(player, field_stat)).format("0");
		label = "AVERAGE";
		opts = {
			is_ave: true,
		};
		
		
	}
	return {score_value, label, opts};
};


const OverflowDividerWrapper = styled.div`
	color: white;
	text-align: left;
	background-color: ${colors.buttons.cancel};
	border-radius: 2px;
	font-weight: 200;
	padding: 8px 0px 5px 10px;
	margin: 18px 0 8px;
`;

const StyledListRowLabel = styled(ListRowLabel)`
	min-width: 320px;
	color: #1D4073;
	padding-left: 1em;
	font-family: SourceSansPro;
	font-weight: 600;
  border-bottom: 2px solid #CAD2D8;
	${below.tablet`
		min-width: 158px;
    padding-left: 0px;
	`}
`;

// const DefenderHeaderLabel = styled.div`
// 	display: flex;
// 	flex-direction: row;
// 	align-items: center;
// 	justify-content: space-between;
// 	div{
// 		display: flex;
// 		flex-direction: row;
// 		align-items: center;
// 	}
// `;

// const ActionsLabel = styled.p`
// 	color: #1D4073;
// 	font-family: SourceSansPro;
// 	font-weight: 600;
// 	font-size: 12px;
// 	margin-right: 1em;
// `;

// const NextLabel = styled(ActionsLabel)`
// 	margin-right: 22px;
// `;

// const DefenderLabel = styled(ActionsLabel)`
// 	margin-left: 1em;
// `;


/*
const DefenderPositionWrapper = styled.div`
	height: 50px;
	display: flex;
	flex-direction: row;
	align-items: center;
`;
*/

const OverflowDivider = props => <OverflowDividerWrapper {...props}>
	Overflow</OverflowDividerWrapper>;

type Props = {
	team: TDraftUserTeam,
	league: TPLeague,
	positions: { [number]: TPosition },
	players_by_id: TPlayersArrayWithFixtureById,
	has_assistant_coach: boolean,
	actual_round: TRound,
	player_ids: Array<number>,
	selected_round: TRoundWithMatches,
	is_mobile: boolean,
	field_stat: string,
	showModalForPlayerId : Function,
	removePlayer: Function,
	setCaptain: Function,
	setViceCaptain: Function,
	setEmergency: Function,
	openPlayerPopup: Function,
	areSubstitutesActive: Function,
	isOutSubstitute: Function,
	isInSubstitute: Function,
	substitutePlayer: Function,
	cancelSubstitute: Function,
	substitute_is_bench: boolean,
	substitute_id: number,
	matches: Array<TMatch>,
	is_static: boolean,
	is_current: boolean,
	bye_detector_selected: number[],
	bye_detector_active: boolean,
	mobile_stat: Object,
	score_stat: Object,
	setPlayerAction: Function,
	no_trade_buttons?: boolean,
	is_team_full?: boolean,
	is_classic: boolean,
	edit_teams_view?: boolean,
	squads_by_id?: Object,
	openAddPlayerModal?: Function,
	closeAddPlayerModal?: Function,
  is_overflow?: boolean,
  is_my_team?: boolean,
};

type State = {

};

export class ListViewTeam extends React.Component<Props, State> {
	constructor() {
		super();

		_.bindAll(this, [
			"renderPositionGroup",
			"renderPlayer"
		]);
	}

	playerRowRef = React.createRef();


	/*
	 * Returns a list of the different personnel who are locked
	 * i.e. [ 'captain', 'emergency.1', 'emergency.2' ]
	 */
	get locked_personnel() {
		const { positions, team: { lineup }, players_by_id } = this.props;

		// Checks if the player at the given path is locked
		const getPersonnelLocked = path => (
			Boolean(_.get(players_by_id, [_.get(lineup, path), "locked"], false))
		);

		const personnel_paths = [
			"captain", "vice_captain", 
			..._.map(positions, p => `emergency.${p.id}`)
		];

		return _(personnel_paths)
			.map(path => {
				const locked = getPersonnelLocked(path);

				return { path, locked };
			})
			.filter(({ locked }) => locked)
			.map(({ path }) => path)
			.value();
	}

	get max_squad_size() {
		return OverflowUtils.maxSquadSize(this.props.league);
	}

	get actual_squad_size() {
		const { team: { lineup }, positions } = this.props;
		return OverflowUtils.actualSquadSize(lineup, positions);
	}

	get lineup() {
		const { team: { lineup }, positions, league } = this.props;
		return OverflowUtils.normaliseLineup(league, lineup, positions);
	}

	get lineup_array(){
		const { team: { lineup } } = this.props;
		const lineUpClone = _.cloneDeep(lineup);
		delete lineUpClone["captain"];
		delete lineUpClone["vice_captain"];
		delete lineUpClone["emergency"];

		const lineUpArray = [];
		Object.keys(lineUpClone).forEach(i => {
			lineUpClone[i].forEach(j => {
				lineUpArray.push(j);
			});
		});
		return lineUpArray;
	}
	get defender_position_group() {
		return null;
		
		// (
		// 	<DefenderPositionWrapper>
		// 		<StyledListRowLabel>
		// 	Defenders
		// 		</StyledListRowLabel>
		// 		<StyledListRowLabel>Next</StyledListRowLabel>
		// 	</DefenderPositionWrapper>
		// );  
		
		
		
		
		
	}
	renderPositionGroup(position: TPosition, index: number) {
		if(isMobile() && position.id === 1){
			return(
				<ListRow key={position.id} >
					{
						_.map(this.lineup[position.id], 
							_.partial(this.renderPlayer, _, _, false, position))
					}
					
				</ListRow>
			);
		}
		return <ListRow key={position.id}>
			 <StyledListRowLabel>
				{position.full_name}
			</StyledListRowLabel>
			{
				_.map(this.lineup[position.id], _.partial(this.renderPlayer, _, _, false, position))
			}
		</ListRow>;
	}

	renderPlayer(player_id: number, index: number, is_bench: boolean, position: TPosition) {
		const {
			team,
			players_by_id,
			league,
			removePlayer,
			setCaptain,
			setViceCaptain,
			setEmergency,
			substitutePlayer,
			cancelSubstitute,
			openPlayerPopup,
			is_mobile,
			has_assistant_coach,
			field_stat,
			areSubstitutesActive,
			rounds,
			isOutSubstitute,
			isInSubstitute,
			selected_round,
			matches,
			is_static,
			is_current,
			bye_detector_selected,
			bye_detector_active,
			mobile_stat,
			setPlayerAction,
			no_trade_buttons,
			is_team_full,
			is_classic,
			edit_teams_view,
			squads_by_id,
			openAddPlayerModal,
			closeAddPlayerModal,
			actual_round,
			is_overflow,
			is_my_team,

			
		} = this.props;
		const playerByID = _.get(players_by_id, [player_id], {});
		const isCustomScoring = _.get(league, "custom_scoring_enabled");
		
		
		const playerIndex = this.lineup_array.indexOf(player_id);
		
		const pointsLabelOptsObj = 
			getPointsLastRound(
				field_stat, 
				playerByID, 
				has_assistant_coach, 
				selected_round,
				isCustomScoring, 
				actual_round 
			);
		const score = pointsLabelOptsObj.score_value;
		const pointsLabel = pointsLabelOptsObj.label;

		
		const pointsLastRound = 
			playerByID 
				? score :"-";

		return <PlayerRow 
			key={`${position ? position.id : "bench"}-${index}`}
			odd={playerIndex % 2 !== 0}
			player_id={player_id}
			players_by_id={players_by_id}
			position={position}
			team={team}
			league={league}
			is_bench={is_bench}
			removePlayer={removePlayer}
			setCaptain={setCaptain}
			setViceCaptain={setViceCaptain}
			setEmergency={setEmergency}
			substitutePlayer={substitutePlayer}
			cancelSubstitute={cancelSubstitute}
			openPlayerPopup={openPlayerPopup}
			rounds={rounds}
			is_mobile={is_mobile}
			has_assistant_coach={has_assistant_coach}
			field_stat={field_stat}
			areSubstitutesActive={areSubstitutesActive}
			isOutSubstitute={isOutSubstitute}
			isInSubstitute={isInSubstitute}
			position_index={index}
			selected_round={selected_round}
			matches={matches}
			locked_personnel={this.locked_personnel}
			is_static={is_static}
			is_current={is_current}
			bye_detector_selected={bye_detector_selected}
			bye_detector_active={bye_detector_active}
			mobile_stat={mobile_stat}
			setPlayerAction={setPlayerAction}
			is_utility={false}
			no_trade_buttons={no_trade_buttons}
			is_team_full={is_team_full}
			is_classic={is_classic}
			edit_teams_view={edit_teams_view}
			squads_by_id={squads_by_id}
			pointsLastRound={pointsLastRound}
			pointsLabel={pointsLabel}
			openAddPlayerModal={openAddPlayerModal}
			closeAddPlayerModal={closeAddPlayerModal}
			actual_round={actual_round}
			is_overflow={is_overflow}
			is_my_team={is_my_team}
		/>;
	}

	renderBench() {
		const overflow_size = this.actual_squad_size - this.max_squad_size;
		const { bench } = this.lineup;
		if(this.props.substitute_id !== 0 && !this.props.substitute_is_bench){
			bench.push(0);
		}

		return <ListRow key="bench">
			<StyledListRowLabel key="bench-pos">
				Bench
			</StyledListRowLabel>
			{
				overflow_size > 0 
					? <React.Fragment>
						{ 
							_.map(
								_.slice(bench, 0, bench.length - overflow_size), 
								_.partial(this.renderPlayer, _, _, true, {})
							) 
						}
						<OverflowDivider />
						{ 
							_.map(
								_.slice(bench, bench.length - overflow_size), 
								_.partial(this.renderPlayer, _, _, true, {})
							) 
						}
					</React.Fragment>
					: _.map(bench, _.partial(this.renderPlayer, _, _, true, {}))
			}
		</ListRow>;
	}

	render() {
		const {
			positions,
			mobile_stat,
			score_stat,
			edit_teams_view
		} = this.props;
		const syncScrollContainers = document.getElementsByClassName("scrollable-group");
		if(syncScrollContainers.length > 0){
			const scrollingDivs = 
				Array.prototype.filter.call(syncScrollContainers, function(testElement) {
					return testElement.nodeName === "DIV";
				});
	
			function scrollAll(scrollLeft) {
				scrollingDivs.forEach(function(element, index, array) {
					element.scrollLeft = scrollLeft;
				});
			}
	
			scrollingDivs.forEach(function(element, index, array) {
				element.addEventListener("scroll", function(e) {
					scrollAll(e.target.scrollLeft);
				});
			});
		}
	
	

		const player_ids = _(positions).map(p => this.lineup[p.id]).flatten().value();
		return <ListViewField>
			{edit_teams_view ? 
				null : 
				<ListViewHeader 
					player_ids={player_ids} 
					is_draft={true} 
					mobile_stat={mobile_stat}
					score_stat={score_stat}
					edit_teams_view={edit_teams_view}
					playerRowRef={this.playerRowRef}
				/>}
			{ _.map(positions, this.renderPositionGroup) }
			{ this.renderBench() }
      
		</ListViewField>;
	}
};

export default ListViewTeam;