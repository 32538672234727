// @flow
import React from "react";
import SvgIconContainer from "../SvgIconContainer";

export default (
	{
		unit = "em",
		...rest
	}: Object = {}) => (
	<SvgIconContainer viewBox="0 0 24 24" {...rest} unit={unit}>
		<title>Wooden Spoon</title>
		<g>
			<path d="M0.95856,22.00992 L14.74488,8.2788 C15.38232,7.13472 
				15.8616,3.86784 18.0696,1.66032 C19.22592,0.50424 21.63288,-0.86784 
				23.21712,0.71736 C24.80184,2.30136 23.43,4.70856 22.27344,5.86464 
				C20.06592,8.07216 16.79904,8.55168 15.6552,9.1896 L1.8924,22.944 
				C1.2336,23.60304 0.7236,24.16032 0.24864,23.6856 C-0.22656,23.21064 
				0.29976,22.66872 0.95856,22.00992 Z M17.23776,6.696 C16.0524,5.51088 
				18.11184,2.71392 18.11184,2.71392 C16.60296,4.22256 16.07616,6.14136 
				16.9344,6.9996 C17.79216,7.85736 19.7112,7.3308 21.21984,5.82216 
				C21.21984,5.8224 18.42336,7.88184 17.23776,6.696 Z" id="Spoon" />
		</g>
	</SvgIconContainer>
);