import { createReducer } from "redux-act";

import * as actions from "../../actions";

const initial_state = {
	success: false,
	error: null,
};

export const contact = createReducer({}, initial_state);

contact.on(actions.contactUsSuccess, state => ({
	...state,
	success: true,
}));

contact.on(actions.contactUsFailed, (state, payload) => ({
	...state,
	error: payload,
}));

contact.on(actions.contactUsClear, state => ({
	...state,
	error: null,
	success: false,
}));