// @flow
import styled from "styled-components";

export const ErrorField = styled.div`
    color: red;
	margin-bottom: .85em;
	font-size: 12px;
	font-weight: bold;
`;

export default ErrorField;