// @flow
import React from "react";
import * as _ from "lodash";
import { ListViewPlayer } from "../../../components";
import {
	TPlayersById,
	TRound
} from "../../../modules/types";

type Props = {
    players_by_id: TPlayersById,
    has_assistant_coach: boolean,
    is_mobile: boolean,
    openPopup: Function,
    actual_round: TRound,
    mobile_stat: string,
    score_stat: string,
    params: Object,
    substitute_id: number,
    substitute_position: number,
    checkIsPlayerChangeAvailable: Function,
    inSubstitute: Function,
    removePlayerInTrade: Function,
    removePlayerOnTrade: Function,
    substitutePlayer: Function,
    cancelSubstitute: Function,
    is_empty_cell: boolean
}




export const ShowTradeInPlayer = (props: Props) => {
	
	const {
		players_by_id,
		has_assistant_coach,
		is_mobile,
		openPopup,
		actual_round,
		mobile_stat,
		score_stat,
		params,
		substitute_id,
		substitute_position,
		checkIsPlayerChangeAvailable,
		inSubstitute,
		removePlayerInTrade,
		removePlayerOnTrade,
		substitutePlayer,
		cancelSubstitute,
		is_empty_cell
	} = props;
	const {
		player_id,
		id,
		is_bench,
		captain,
		vice_captain,
		emergency,
		index,
		trade_id,
		trade,
        
	} = params;

	const player = players_by_id[player_id];
	const player_positions = _.get(player, "positions", []);
	const is_emergency = emergency.includes(trade.out);
	const is_locked = !checkIsPlayerChangeAvailable(player_id);
	const is_subs = substitute_id !== 0;
	const out_substitute = is_subs && substitute_id === trade.out;
	const in_position = _.includes(player_positions, substitute_position);
	const in_substitute = inSubstitute(is_subs, is_locked, in_position, true);
	const is_in_swap = !_.isEmpty(trade);



	return (
		<ListViewPlayer
			key={player_id}
			player={player}
			position_id={id}
			is_locked={is_locked}
			is_captain={captain === player_id}
			is_team_started={false}
			is_vice={vice_captain === player_id}
			is_bench={is_bench}
			removePlayer={removePlayerInTrade}
			removePlayerOutTrade={removePlayerOnTrade}
			setCaptain={() => null}
			setViceCaptain={() => null}
			setEmergency={() => null}
			is_substitute={false}
			out_substitute={out_substitute}
			in_substitute={in_substitute}
			substitutePlayer={substitutePlayer}
			cancelSubstitute={cancelSubstitute}
			position_index={index}
			is_emergency={is_emergency}
			has_assistant_coach={has_assistant_coach}
			openPlayerPopUp={openPopup}
			is_mobile={is_mobile}
			is_trade_view
			is_trade_view_team
			is_in_swap={is_in_swap}
			trade_id={trade_id}
			is_empty_cell={is_empty_cell}
			trade={trade}
			selected_round={actual_round}
			no_padding
			mobile_stat={_.get(mobile_stat, "key")}
			player_stat_field={_.get(score_stat, "key")}
		/>
	);
};