// @flow
import { createAction } from "redux-act";

export const fetchMyClassicTeam = createAction("Fetch My Classic Team");
export const fetchMyClassicTeamSuccess = createAction("Fetch My Classic Team Success");
export const fetchMyClassicTeamFailed = createAction("Fetch My Classic Team Failed");

export const fetchClassicTeamSnapshot = createAction("Fetch  Classic Team Snapshot");
export const fetchClassicTeamSnapshotSuccess = createAction("Fetch  Classic Team Snapshot Success");
export const fetchClassicTeamSnapshotFailed = createAction("Fetch  Classic Team Snapshot Failed");

export const addPlayerToMyClassicTeam = createAction("Add Player To Classic Team");
export const addPlayerToMyClassicTeamSuccess = createAction("Add Player To Classic Team Success");
export const addPlayerToMyClassicTeamFailed = createAction("Add Player To Classic Team Failed");

export const removePlayerFromMyClassicTeam = createAction(
	"Remove Player To Classic Team"
);
export const removePlayerFromMyClassicTeamSuccess = createAction(
	"Remove Player To Classic Team Success"
);
export const removePlayerFromMyClassicTeamFailed = createAction(
	"Remove Player To Classic Team Failed"
);

export const saveMyClassicTeam = createAction("Save My Classic Team");
export const saveMyClassicTeamSuccess = createAction("Save My Classic Team Success");
export const saveMyClassicTeamFailed = createAction("Save My Classic Team Failed");

export const updateMyClassicTeam = createAction("Update My Classic Team");
export const forceUpdateMyClassicTeam = createAction("Force Update My Classic Team");

export const autoFillMyClassicTeam = createAction("Auto fill My Classic Team");
export const autoFillMyClassicTeamSuccess = createAction("Auto fill My Classic Team Success");
export const autoFillMyClassicTeamFailed = createAction("Auto fill My Classic Team Failed");

export const clearMyClassicTeam = createAction("Clear My Classic Team");
export const clearMyClassicTeamSuccess = createAction("Clear My Classic Team Success");
export const clearMyClassicTeamFailed = createAction("Clear My Classic Team Failed");

export const clearErrorsMyTeam = createAction("Clear Errors My Classic Team");

export const fetchMyClassicTrades = createAction("Fetch My Classic Trades");
export const fetchMyClassicTradesSuccess = createAction("Fetch My Classic Trades Success");
export const fetchMyClassicTradesFailed = createAction("Fetch My Classic Trades Failed");

export const addPlayerOutTrade = createAction("Add Classic Player Out Trade");
export const removePlayerOutTrade = createAction("Remove Classic Player Out Trade");
export const setInitialLineup = createAction("Set Initial Lineup");
export const playerOutTradeFailed = createAction("Add/Remove Classic Player Out Trade Failed");

export const addPlayerInTrade = createAction("Add Classic Player In Trade");
export const removePlayerInTrade = createAction("Remove Classic Player In Trade");

export const updatePlayersOnTrade = createAction("Update Players On Trade");
export const updatePlayersOnTradeSuccess = createAction("Update Players On Trade Success");

export const resetClassicTrade = createAction("Reset Classic Trade");
export const resetClassicTradeSuccess = createAction("Reset Classic Trade Success");
export const resetClassicTradeFailed = createAction("Reset Classic Trade Failed");

export const makeClassicTrade = createAction("Make Classic Trade");

export const reverseClassicTrade = createAction("Reverse Classic Trade");
export const rollbackTeam = createAction("Rollback Classic Trade");

export const swapPlayersOutTrade = createAction("Swap Players Out Trade");

export const makeClassicTradeError = createAction("Show Classic Trade Error");
export const clearClassicTradeError = createAction("Clear Classic Trade Error");

export const fetchRankings = createAction("Fetch Rankings");
export const fetchRankingsSuccess = createAction("Fetch Rankings Success");
export const fetchRankingsFailed = createAction("Fetch Rankings Failed");

export const loadMoreRankings = createAction("Load More Rankings");
export const loadMoreRankingsSuccess = createAction("Load More Rankings Success");
export const loadMoreRankingsFailed = createAction("Load More Rankings Failed");

export const fetchClassicTeam = createAction("Fetch A User's Classic Team");
export const fetchClassicTeamSuccess = createAction("Fetch A User's Classic Team Success");
export const fetchClassicTeamFailed = createAction("Fetch A User's Classic Team Failed");

export const fetchTradeHistory = createAction("Fetch Classic Trades History");
export const fetchTradeHistoryFailed = createAction("Fetch Classic Trades History Failed");
export const fetchTradeHistorySuccess = createAction("Fetch Classic Trades History Success");

export const addFillerPositionByTradeUser = createAction("Add Filler Position By TradeUser");
export const removeFillerPositionByTradeUser = createAction("Remove Filler Position By TradeUser");

export const fetchSeasonHistory = createAction("Fetch Season History");
export const fetchSeasonHistorySuccess = createAction("Fetch Season History Success");
export const fetchSeasonHistoryFailed = createAction("Fetch Season History Failed");

export const editClassicTrade = createAction("Edit Classic Trade");
export const editClassicTradeSuccess = createAction("Edit Classic Trade Success");
export const editClassicTradeFailed = createAction("Edit Classi Trades Failed");
export const clearSuccessEditTrade = createAction("Clear Success Edit Trade");
export const updatePlayerSwap = createAction("Update Player Swap");

export const updateCompletedTrade = createAction("Remove Specific Trade");
export const initialUpdateOfCompletedTrades = createAction("Initial Update of Completed");

export const addPlayerInTradeAndUpdateTeam = createAction("Add Player In Trade And Update Team");