import React from "react";
import styled from "styled-components";
import bindAll from "lodash/bindAll";
import { get } from "lodash";
import {
	AdsContainer,
	Ad,
	Footer,
	PageContentWrapper,
	PageTitle,
	StandardSidebar,
	TwoColumnLayout
} from "../../components";

import clubLogos from "../../utils/clubLogos";

import { JSON_URL, LoadingStatus, SEASON_YEAR } from "../../modules/constants";

const ArticleP = styled.p`
	margin: 10px;
	line-height:20px;
`;

const ArticlePSmall = styled(ArticleP)`
	font-size: 12px;
	margin: 0 10px;
`;

const ArticleImg = styled.img`
	max-width: 50px;
`;

const ArticleUL = styled.ul`
    display: flex;
    flex-wrap: wrap;
`;
const ArticleLi = styled.li`
    button {
        background: transparent;
        border: none;
    }
`;

const TeamLogo = styled.img`max-width: 50px`;
const SHOW_PROD_DATA = window.location.search.includes("prod=true") ? true:false;
const PROD_URL = 
	`https://fantasy.afl.com.au/data/afl/news/${SEASON_YEAR}/player-preview-debug.json`;
const PLAYER_NEWS_JSON = SHOW_PROD_DATA ? 
	PROD_URL:`${JSON_URL}news/${SEASON_YEAR}/player-preview.json`;
const IS_UAT = process.env.REACT_APP_GTM_ENVIRONMENT === "uat";

const PlayerNewsList = ({
	current_team,
	news_data,
	updateCurrentTeam
}) => {
	const getPlayerPic = player => `${process.env.REACT_APP_PLAYERS_PATH + player}.webp`;
	const getPlayerLabel = player => get(news_data[current_team][player],"label","");
	return <ArticleUL>
		{Object.keys(news_data).map(team => 
			<ArticleLi key={team}>
				<button onClick={() => updateCurrentTeam(team)}>
					<TeamLogo src={clubLogos[team]} alt="Team logo" />
				</button>
			</ArticleLi>
		)}
		{news_data[current_team] ? <ul>
			{Object.keys(news_data[current_team]).map(player => 
				<li key={player}>
					<ArticleImg src={getPlayerPic(player)} alt={getPlayerLabel(player)} />
					<ArticleP>{news_data[current_team][player].text}</ArticleP>
					{SHOW_PROD_DATA ? <>
						<ArticlePSmall>
							Player ID: {news_data[current_team][player].id}
						</ArticlePSmall>
						<ArticlePSmall>
							Template used: {news_data[current_team][player].template_id}
						</ArticlePSmall>
					</>:null}
				</li>
			)}
		</ul>:null}
	</ArticleUL>;
};

export class PlayerNews extends React.Component {
	constructor() {
		super();

		bindAll(this, [
			"updateCurrentTeam"
		]);
	}
	state = {
		news_data:null,
		current_team: 10,
		news_loading_state:LoadingStatus.IDLE,
	};

	componentDidMount() {
		if(IS_UAT) {
			fetch(PLAYER_NEWS_JSON)
				.then(res => {
					this.setState({
						news_loading_state: LoadingStatus.LOADING
					});
					return res.json();
				})
				.then(
					result => {
						this.setState({
							news_data: result,
							news_loading_state: LoadingStatus.SUCCESS
						});
					},
					error => {
				  		this.setState({
							news_data: null,
							news_loading_state: LoadingStatus.ERROR
				  		});
					}
			  )
				.catch(function(err) {
					console.log("Fetch Error :-S", err);
				});
		}
	}
	updateCurrentTeam(newTeamID) {
		this.setState({ current_team:newTeamID });
	}
	render() {
		const { current_team, news_data, news_loading_state } = this.state;
		return (
			<React.Fragment>
				<AdsContainer>
					<Ad />
				</AdsContainer>

				<PageContentWrapper>
					<PageTitle>
						Player News
					</PageTitle>
					<TwoColumnLayout>
						{IS_UAT && news_loading_state === LoadingStatus.SUCCESS ? 
							<PlayerNewsList 
								current_team={current_team} 
								news_data={news_data}
								updateCurrentTeam={this.updateCurrentTeam}
							/>:<div/>
						}
						<StandardSidebar />
					</TwoColumnLayout>
				</PageContentWrapper>

				<AdsContainer>
					<Ad />
				</AdsContainer>

				<Footer />
			</React.Fragment>
		);
	}
}


export default PlayerNews;